import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _set from "lodash/set";
import _forEach from "lodash/forEach";
import _has from "lodash/has";
import _some from "lodash/some";
import _compact from "lodash/compact";
import _flattenDeep from "lodash/flattenDeep";
import _map from "lodash/map";
import _filter from "lodash/filter";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment-timezone';
export var getSpecialHoursDays = function getSpecialHoursDays(specialHours, timezone) {
  var upcomingSpecialHours = _filter(specialHours, function (specialHoursItem) {
    var specialHoursItemEndDate = moment.tz(specialHoursItem.endDate, timezone);
    var today = moment().tz(timezone).startOf('day');
    return today.isSameOrBefore(specialHoursItemEndDate);
  });

  var upcomingSpecialHoursPerDay = _map(upcomingSpecialHours, 'hoursPerDay');

  var flattenedSpecialHoursPerDay = _flattenDeep(upcomingSpecialHoursPerDay);

  return _compact(flattenedSpecialHoursPerDay);
};
export var getEffectiveHours = function getEffectiveHours(pickupHours, deliveryHours, specialHours, timezone) {
  /**
   * When using updated ordering/hours settings, the top-level effectiveHours
   * object is irrelevant, as effectiveHours object nested in hoursSettings
   * are used.
   * It would be possible to wrangle the data to make this work, but the field
   * isn't used anyway. -- RTL, 8/6/2021
   */
  var usingUpdatedHoursSettings = _some(specialHours, function (specialHoursSet) {
    return _has(specialHoursSet, 'specialOperatingHours') && !_has(specialHoursSet, 'hoursPerDay');
  });

  if (!specialHours || usingUpdatedHoursSettings) {
    return {
      pickupHours: pickupHours,
      deliveryHours: deliveryHours
    };
  }

  var effectivePickupHours = {};
  var effectiveDeliveryHours = {};
  var specialHoursDays = getSpecialHoursDays(specialHours, timezone);

  var specialHoursThisWeek = _filter(_compact(specialHoursDays), function (_ref) {
    var date = _ref.date;
    return moment.tz(date, timezone).isSame(moment().tz(timezone), 'week');
  });

  if (specialHoursThisWeek) {
    _forEach(specialHoursThisWeek, function (day) {
      _set(effectivePickupHours, moment.tz(day.date, timezone).format('dddd'), day.pickupHours);

      _set(effectiveDeliveryHours, moment.tz(day.date, timezone).format('dddd'), day.deliveryHours);
    });
  }

  return {
    pickupHours: _objectSpread(_objectSpread({}, pickupHours), effectivePickupHours),
    deliveryHours: _objectSpread(_objectSpread({}, deliveryHours), effectiveDeliveryHours)
  };
};