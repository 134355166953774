import styled, { css } from 'styled-components';

import { buttonResetStyles } from 'src/dispensary/core-menu/components/header/header.styles';

export const HeaderWrapper = styled.div`
  background: ${({ theme }) => theme.customized.colors.navBar};
  border-bottom: 1px solid #c8d2db;
  color: ${({ theme }) => (theme.customized.utils.isNavBarDark ? '#fff' : '#485055')};
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;
  position: relative;
  width: 100%;
`;

const buttonStyles = css`
  color: currentColor;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: ${20 / 14};
  padding: 8px 8px 8px 0;
`;

export const NavLink = styled.a`
  ${buttonStyles}
`;

export const NavButton = styled.button`
  ${buttonResetStyles}
  ${buttonStyles}

  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

export const Nav = styled.nav`
  align-items: center;
  display: flex;
  flex: 1;
  gap: 20px;
  min-height: 68px;
`;

export const UtilityNav = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
`;

export const Divider = styled.div`
  border-left: 1px solid ${({ theme }) => (theme.customized.utils.isNavBarDark ? '#2c2c2c' : '#c8d2db')};
  height: 36px;
`;

export const InBetweenMq = styled.div`
  @media (min-width: 880px) and (max-width: 1019px) {
    display: none;
  }

  @media (min-width: 1020px) {
    align-items: center;
    display: flex;
    gap: 20px;
  }
`;
