import { useApolloClient, useMutation } from '@apollo/react-hooks';
import React, { useState } from 'react';
import styled from 'styled-components';
import { object, string } from 'yup';

import useCart from 'hooks/use-cart';
import useErnie from 'shared/hooks/use-ernie';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import useUser from 'hooks/use-user';
import { useModals, ModalNames } from 'components/modals';

import { userExistsCheck } from 'shared/graphql/user/queries';
import sendPasswordResetEmailV2 from 'shared/graphql/password/mutations/send-password-reset-email-v2.gql';

import ArrowLeft from 'src/assets/arrow-left';
import { Button } from 'components/core';
import { LoginOptionsContainer, SecondaryOptionText as LoginText } from '../components';
import { FormTextInput } from '../../form-inputs';
import Form from '../form';
import { emailRegEx } from '../form-utils';

const resetPasswordFormValidation = (t) =>
  object().shape({
    email: string()
      .required(t('form.emailRequired', 'email is required'))
      .matches(emailRegEx, t('form.validEmail', 'please enter a valid email address')),
  });

export default function ResetPasswordForm(props) {
  const { onClose, onShowEmbeddedError } = props;

  const [isLoading, setIsLoading] = useState(false);
  const apolloClient = useApolloClient();
  const user = useUser();
  const UI = useUI();
  const { isMobileEcommApp } = UI;
  const { openModal } = useModals();
  const { t } = useTranslation();
  const showErnie = useErnie();
  const cart = useCart();

  async function checkIfUserExists(email) {
    try {
      const result = await apolloClient.query({
        query: userExistsCheck,
        variables: { input: { email } },
      });

      const { exists, _id } = result.data.userExistsCheck;

      return _id ? exists && user.id !== _id : exists;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  const [handleSendPasswordReset] = useMutation(sendPasswordResetEmailV2);

  const handleSubmitEmail = async ({ email }) => {
    setIsLoading(true);
    const onFail = () => showErnie(t('resetPassword.fail', 'Something went wrong. Please contact support.'), `danger`);

    try {
      if (UI.isVariant(`embedded`)) {
        const doesExist = await checkIfUserExists(email);

        if (!doesExist) {
          return onShowEmbeddedError();
        }
      }

      const response = await handleSendPasswordReset({ variables: { email } });

      const { success } = response.data.sendPasswordResetEmailV2;

      if (success) {
        showErnie(
          t('resetPassword.success', 'An email has been sent with instructions on how to reset your password.'),
          `success`
        );

        onClose();

        if (cart.isGuestOrder && !isMobileEcommApp) {
          openModal(ModalNames.login);
        }
      } else {
        onFail();
      }
    } catch (err) {
      console.error(err);
      onFail();
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginAgain = () => {
    onClose();
    if (!isMobileEcommApp) {
      openModal(ModalNames.login);
    }
  };

  return (
    <Form onSubmit={handleSubmitEmail} validationSchema={resetPasswordFormValidation(t)}>
      <FormTextInput
        name='email'
        label={t('resetPassword.emailAddress', 'Email Address')}
        variant='filled'
        autoComplete='email'
        required
      />

      <StyledLoginOptionsContainer>
        <StyledButton type='submit' loading={isLoading}>
          {t('forgotPasswordModal.submit', 'Submit')}
        </StyledButton>

        <LoginText href='#' onClick={handleLoginAgain}>
          <StyledBackArrow height={11} width={15} />
          {t('forgotPasswordModal.loginAgain', 'Log in again')}
        </LoginText>
      </StyledLoginOptionsContainer>
    </Form>
  );
}

const StyledLoginOptionsContainer = styled(LoginOptionsContainer)`
  // this is necessary to produce an intuitive tab focus order
  // e.g. input -> Submit -> Login Again
  display: flex;
  flex-direction: row-reverse;
`;

const StyledBackArrow = styled(ArrowLeft)`
  margin-right: 6px;
`;

export const StyledButton = styled(Button)`
  width: 95px;
`;
