import React from 'react';
import styled from 'styled-components';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { DialogHeading } from 'src/components/modals/components/simple-dialog';
import { Button, TextButton } from 'src/components/core';
import { getTermsOfUse } from 'src/components/loyalty/loyalty.helpers';

type TermsProps = {
  onClose: () => void;
  programName: string;
  createUserAndPin: () => void;
};

export const Terms = ({ onClose, programName, createUserAndPin }: TermsProps): JSX.Element => {
  const { dispensary } = useDispensary();

  return (
    <>
      <StyledHeading fontSize={20}>Terms of Use</StyledHeading>

      <Content>{getTermsOfUse(dispensary.name, programName)}</Content>

      <Button onClick={createUserAndPin} mb={16} width='264px'>
        Agree & Continue
      </Button>

      <StyledTextButton onClick={onClose}>Cancel</StyledTextButton>
    </>
  );
};

const StyledHeading = styled(DialogHeading)`
  margin-bottom: 24px;
`;

const Content = styled.p`
  color: #646d72;
  margin-bottom: 32px;
`;

const StyledTextButton = styled(TextButton)`
  font-weight: 600;
`;
