import React, { useRef, useImperativeHandle, RefObject } from 'react';

import { useIntersectionObserver } from 'src/hooks/use-intersection-observer';

import {
  CardContainer,
  GradientLeft,
  GradientRight,
  Slider,
  SliderContainer,
  Viewport,
} from './hybrid-carousel-slider.styles';

import { CarouselTheme } from '../hybrid-carousel';
import { HybridCarouselButtons } from '../hybrid-carousel-buttons';

type HybridSliderProps = {
  carouselPadding?: number;
  carouselTheme?: CarouselTheme;
  children: React.ReactNode[];
  className?: string;
  fullWidth?: boolean;
};

export type HybridSliderRef = {
  viewportRef: RefObject<HTMLDivElement>;
  cardsWrapperRef: RefObject<HTMLDivElement>;
};

export const HybridSlider = ({
  children,
  className,
  carouselTheme,
  carouselPadding = 20,
  fullWidth = false,
}: HybridSliderProps): JSX.Element => {
  const sliderRef = useRef<HybridSliderRef>(null);
  const viewportRef = useRef<HTMLDivElement>(null);
  const cardsWrapperRef = useRef<HTMLDivElement>(null);
  const startWatcherRef = useRef<HTMLDivElement>(null);
  const startWatcher = useIntersectionObserver(startWatcherRef);
  const endWatcherRef = useRef<HTMLDivElement>(null);
  const endWatcher = useIntersectionObserver(endWatcherRef);

  useImperativeHandle(
    sliderRef,
    () => ({
      viewportRef,
      cardsWrapperRef,
    }),
    []
  );

  return (
    <SliderContainer className={className} ref={sliderRef} {...carouselTheme}>
      <Slider role='group' aria-roledescription='carousel'>
        <Viewport ref={viewportRef} carouselPadding={carouselPadding}>
          <GradientLeft
            isVisible={!startWatcher?.isIntersecting}
            data-testid='carousel-gradient-left'
            {...carouselTheme}
          />

          <CardContainer
            ref={cardsWrapperRef}
            fullWidth={fullWidth}
            carouselPadding={carouselPadding}
            buttonsVisible={!startWatcher?.isIntersecting || !endWatcher?.isIntersecting}
          >
            <div className='carousel-start-watcher' ref={startWatcherRef} />

            {children}

            <div className='carousel-end-watcher' ref={endWatcherRef} />
          </CardContainer>

          <GradientRight
            isVisible={!endWatcher?.isIntersecting}
            data-testid='carousel-gradient-right'
            {...carouselTheme}
          />
        </Viewport>
        <HybridCarouselButtons startWatcher={startWatcher} endWatcher={endWatcher} sliderRef={sliderRef} />
      </Slider>
    </SliderContainer>
  );
};
