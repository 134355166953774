import { useFlags } from 'launchdarkly-react-client-sdk';

type ConsentManagement = {
  isConsentManagementEnabled: boolean;
  consentManagementRegions: string[];
};

export function useConsentManagement(): ConsentManagement {
  const flags = useFlags();
  const { enabled: isConsentManagementEnabled = false, regions: consentManagementRegions = [] } =
    flags['growth.ads.consent-management.rollout'] ?? {};
  return {
    isConsentManagementEnabled,
    consentManagementRegions,
  };
}
