import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _forEach from "lodash/forEach";
import _isNil from "lodash/isNil";
import _reduce from "lodash/reduce";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import { sortSpecials, isCumulative } from 'shared/order/utilities';
import { productSatisfiesSaleWeight } from 'shared/helpers/specials';
export var calculateSaleDiscounts = function calculateSaleDiscounts(_ref) {
  var applyStowedDiscounts = _ref.applyStowedDiscounts,
      compositePrice = _ref.compositePrice,
      credit = _ref.credit,
      discountTaxOrder = _ref.discountTaxOrder,
      detail = _ref.detail,
      originalCompositePrice = _ref.originalCompositePrice,
      medicalOrder = _ref.medicalOrder,
      productTotal = _ref.productTotal,
      receiptDetail = _ref.receiptDetail,
      specialData = _ref.specialData,
      globalSpecialsSettings = _ref.globalSpecialsSettings,
      stowDiscounts = _ref.stowDiscounts,
      stowedDiscounts = _ref.stowedDiscounts;
  // Gather required product attributes for productSatisfiesSaleWeight
  var product = {
    brandId: detail.productBrandId,
    brandName: detail.productBrand,
    type: detail.productCategory,
    subcategory: detail.productSubcategory,
    _id: detail.id,
    enterpriseProductId: detail.enterpriseProductId,
    option: detail.option
  };
  var saleSpecials; // if we don't have any special restrictions, don't worry about weights, they all apply then

  var hasApplicableSpecial = specialData !== null && specialData !== void 0 && specialData.saleSpecials ? productSatisfiesSaleWeight(product, specialData.saleSpecials, detail.option) : true;

  if (hasApplicableSpecial) {
    if (applyStowedDiscounts) {
      saleSpecials = _reduce(stowedDiscounts, function (saleDiscounts, discount) {
        if (discount.type === 'sale') {
          saleDiscounts.push(_objectSpread(_objectSpread({}, discount.discountData), {}, {
            _quantityToApply: discount.quantityToApply || 0
          }));
        }

        return saleDiscounts;
      }, []);
    } else {
      saleSpecials = (specialData === null || specialData === void 0 ? void 0 : specialData.saleSpecials) || []; // Legacy special data support

      if (saleSpecials.length < 1 && (!_isNil(specialData === null || specialData === void 0 ? void 0 : specialData.discount) || !_isNil(specialData === null || specialData === void 0 ? void 0 : specialData.productDiscounts))) {
        var _specialData$productD;

        var inProductDiscounts = !_isNil(specialData === null || specialData === void 0 ? void 0 : (_specialData$productD = specialData.productDiscounts) === null || _specialData$productD === void 0 ? void 0 : _specialData$productD[detail.id]);
        saleSpecials.push({
          discount: inProductDiscounts ? specialData.productDiscounts[detail.id] : specialData === null || specialData === void 0 ? void 0 : specialData.discount,
          menuType: "both",
          percentDiscount: !_isNil(specialData === null || specialData === void 0 ? void 0 : specialData.percentDiscount) ? specialData.percentDiscount : inProductDiscounts
        });
      }
    }
  }

  saleSpecials = sortSpecials(saleSpecials, {
    // Partial product object here from the detail data
    brandId: detail.productBrandId,
    brandName: detail.productBrand,
    _id: detail.id,
    subcategory: detail.productSubcategory,
    type: detail.productCategory
  }); // credit has not been modified at this point

  _forEach(saleSpecials, function (saleSpecial) {
    var stackingBehaviorIsCumulative = isCumulative(saleSpecial.stackingBehavior, globalSpecialsSettings.stackingBehavior);
    var startingPrice = stackingBehaviorIsCumulative ? originalCompositePrice : compositePrice;
    var individualPrice = startingPrice.div(detail.quantity);
    var specialMenuType = saleSpecial.menuType || "both"; // should we apply this special to the product?

    var specialAppliesToWeight = saleSpecial && productSatisfiesSaleWeight(product, [saleSpecial], detail.option);

    if (specialAppliesToWeight && (specialMenuType === "both" || specialMenuType === "medical" && medicalOrder || specialMenuType === "recreational" && !medicalOrder)) {
      var quantityToApply = !_isNil(saleSpecial === null || saleSpecial === void 0 ? void 0 : saleSpecial._quantityToApply) ? saleSpecial._quantityToApply : detail.quantity || 0;
      var additionalCredit = Big(0);
      var individualDiscount;

      if (saleSpecial !== null && saleSpecial !== void 0 && saleSpecial.percentDiscount) {
        individualDiscount = individualPrice.times(Big((saleSpecial === null || saleSpecial === void 0 ? void 0 : saleSpecial.discount) || 0).div(100));
      } else if (saleSpecial !== null && saleSpecial !== void 0 && saleSpecial.targetPrice) {
        individualDiscount = individualPrice.minus(Big((saleSpecial === null || saleSpecial === void 0 ? void 0 : saleSpecial.discount) || 0));
      } else {
        individualDiscount = Big((saleSpecial === null || saleSpecial === void 0 ? void 0 : saleSpecial.discount) || 0);
      }

      additionalCredit = additionalCredit.add(individualDiscount.times(quantityToApply));

      if (stowDiscounts) {
        stowedDiscounts.push({
          applicableQuantity: detail.quantity,
          discountData: saleSpecial,
          individualDiscount: individualDiscount,
          key: saleSpecial.specialId,
          type: "sale"
        });
      } else {
        compositePrice = compositePrice.minus(additionalCredit.round(2, 1)); // Depending on tax config, add the credit into the total. This is to make the subtotal on the checkout ticket
        // look more like the menu.

        if (discountTaxOrder === 'taxesFirst' || Big(detail.basePriceMult, "Base Price Mult").eq(1)) {
          credit = credit.plus(additionalCredit.round(2, 1));
        } else {
          productTotal = productTotal.minus(additionalCredit.round(2, 1));
        }

        var specialSettings = {
          discountStacking: saleSpecial.discountStacking,
          stackingBehavior: saleSpecial.stackingBehavior,
          nonStackingBehavior: saleSpecial.nonStackingBehavior,
          settingsDerivedFrom: saleSpecial.settingsDerivedFrom
        };

        if (receiptDetail && additionalCredit.gt(0)) {
          receiptDetail.addDiscount({
            type: "sale",
            value: additionalCredit.times(100),
            id: saleSpecial.specialId,
            name: saleSpecial.specialName,
            additionalAttributes: {
              specialSettings: specialSettings
            }
          });
        }
      }
    }
  });

  return {
    compositePrice: compositePrice,
    credit: credit,
    productTotal: productTotal,
    stowedDiscounts: stowedDiscounts
  };
};