import React, { useState, useEffect } from 'react';
import { MenuTypes } from 'types/graphql';

import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';
import { StoreTypeBottomDrawer } from './store-type-bottom-drawer';

type StoreTypeBottomDrawerControllerProps = {
  open: boolean;
  onClose: () => void;
};

export function StoreTypeBottomDrawerController({ open, onClose }: StoreTypeBottomDrawerControllerProps): JSX.Element {
  const { queryParams, setQueryParams } = useDispensariesQueryParams();
  const [isMedSelected, setIsMedSelected] = useState<boolean>(queryParams.medical);
  const [isRecSelected, setIsRecSelected] = useState<boolean>(queryParams.recreational);

  useEffect(() => {
    if (!!queryParams.medical && !!queryParams.recreational) {
      setQueryParams({ medical: false, recreational: false });
    }
  }, [queryParams, setQueryParams]);

  useEffect(() => {
    if (open) {
      setIsMedSelected(queryParams.medical);
      setIsRecSelected(queryParams.recreational);
    }
  }, [open, queryParams.medical, queryParams.recreational]);

  function handleSave(): void {
    setQueryParams({ medical: isMedSelected, recreational: isRecSelected });
    onClose();
  }

  function handleSelect(storeType: MenuTypes): void {
    if (storeType === MenuTypes.medical) {
      if (!isMedSelected) {
        setIsRecSelected(false);
      }
      setIsMedSelected(!isMedSelected);
    } else {
      if (!isRecSelected) {
        setIsMedSelected(false);
      }
      setIsRecSelected(!isRecSelected);
    }
  }

  return (
    <StoreTypeBottomDrawer
      medSelected={isMedSelected}
      onClose={onClose}
      onSave={handleSave}
      onSelect={handleSelect}
      open={open}
      recSelected={isRecSelected}
    />
  );
}
