import { parseProduct, Product } from 'utils/helpers/product';
import { GenericCategory } from '../generic-category';
import { GenericProduct, GenericEffect, GenericPriceOption } from '../generic-product';
import { transformCategory, transformEffects } from '../generic-product/transformers';
import { getDefaultProductImage } from '../generic-product/utils';
import { valueOrNull } from '../helpers';

import { transformOptions } from './transformers';
import { DispensaryProduct } from './data.types';

type ValidDispensaryProduct = Product & { id: string; name: string };

export class GenericDispensaryProduct extends GenericProduct<ValidDispensaryProduct> {
  readonly image: string | null;
  readonly defaultImage: string;
  readonly category: GenericCategory | null;
  readonly effects: GenericEffect[];
  readonly options: GenericPriceOption[];

  constructor(data: DispensaryProduct) {
    const { id, Name: name } = data;

    if (!id || !name) {
      throw new Error(`Invalid product data: ({ id: ${String(id)}, name: ${String(name)} }))`);
    }

    super(parseProduct({ ...data, id, name }));

    const { type, effects, Image, Options, Prices } = data;

    this.category = transformCategory(type);
    this.effects = transformEffects(effects ? Object.keys(effects) : effects);
    this.image = valueOrNull(Image);
    this.defaultImage = getDefaultProductImage(this.category, this.strainType);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.options = transformOptions({ Options, Prices });
  }

  static isinstance(instance: unknown): instance is GenericDispensaryProduct {
    return instance instanceof GenericDispensaryProduct;
  }
}
