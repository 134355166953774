import React from 'react';
import styled from 'styled-components';
import { isBlank } from 'underscore.string';
import { useTheme } from '@material-ui/core/styles';

import { VisuallyHidden } from 'src/components/core/visually-hidden';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import SvgBackArrow from 'assets/back-arrow';
import { mediaSizes } from 'theme';

import Imgix from 'shared/components/imgix';

export default function StoreFrontHeader() {
  const { dispensary } = useDispensary();
  const UI = useUI();
  const { t } = useTranslation();
  const theme = useTheme();

  if (!dispensary) {
    return null;
  }

  const linkColor = dispensary.embedBackColor || theme.palette.grey[60];
  const returnLink = UI.isKiosk ? '#' : dispensary.embedBackUrl || 'https://www.dutchie.com'; // eslint-disable-line

  return (
    <Container brandColor={dispensary.embeddedBrandColor}>
      <InnerContainer>
        <a href={returnLink}>
          <VisuallyHidden>{t('common.core-menu.return-home', 'return to dispensary home page')}</VisuallyHidden>
          <Logo
            customParams={{ q: 100, dpr: 3 }}
            fit='clip'
            generateSrcSet={false}
            height={44}
            htmlAttributes={{ alt: t('common.core-menu.logo', 'dispensary logo') }}
            src={dispensary.embeddedLogoImage || dispensary.logoImage}
          />
        </a>
        {!UI.isKiosk && (
          <a href={returnLink}>
            <LinkContainer>
              <>
                <SvgBackArrow height={9.6} fill={linkColor} />
                <BackLink color={linkColor}>{t('common.back-home', 'Back home')}</BackLink>
              </>
            </LinkContainer>
          </a>
        )}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  background-color: ${(props) => (!isBlank(props.brandColor) ? props.brandColor : '#ffffff') /* eslint-disable-line */};
  border-bottom: 1px solid #c5d0d7;
  display: flex;
  height: 72px;
  top: 0;
  width: 100%;
  z-index: 10;

  @media (max-width: ${mediaSizes.largePhone}px) {
    padding: 0 2.5%;
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
`;

const Logo = styled(Imgix)`
  margin-left: 2px;
  cursor: pointer;
  max-width: 100%;
`;

const LinkContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const BackLink = styled.p`
  color: ${(props) => props.color};
  cursor: pointer;
  font-size: 13px;
  margin-left: 6px;
`;
