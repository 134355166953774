import React from 'react';
import styled, { css } from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useTranslation from 'hooks/use-translation';
import useUI from 'shared/hooks/use-ui';

import { formatAddress } from 'shared/helpers/address';
import { AnchorButton } from 'components/core/button';
import { Typography } from 'src/typography';
import { OrderingHours } from './store-ordering-hours';

const OrderingHoursDescription = ({ t }) => (
  <Description>
    {t('dispensary.storeInfo.description', 'Hours below are online ordering hours and may not reflect store hours.')}
  </Description>
);

export default function StoreContent({ dispensary }) {
  const { location } = dispensary;
  const { t } = useTranslation();
  const { isMobileEcommApp } = useUI();

  const addressLine = formatAddress(dispensary.location);
  const googleMapsDirectionsUrl = `https://www.google.com/maps?q=${encodeURIComponent(addressLine)}`;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));
  const hideLocation = !!dispensary?.storeSettings.hideAddressFromDutchieMain;
  const hideHours = !!dispensary?.featureFlags.hideStoreHours;

  return (
    <SectionContent hideLocation={hideLocation} isMobileEcommApp={isMobileEcommApp}>
      <TwoColumn isMobile={isMobile}>
        {!hideHours && (
          <OrderingHoursContainer isMobile={isMobile}>
            <SectionTitle size='small' tag='h3'>
              {(props) => props.t('dispensary.storeInfo.orderingHours', 'Ordering Hours')}
            </SectionTitle>
            <OrderingHours dispensary={dispensary}>
              <OrderingHoursDescription t={t} />
            </OrderingHours>
          </OrderingHoursContainer>
        )}
        {!hideLocation && (
          <AddressContainer>
            <Title size='small' tag='h2'>
              {(props) => props.t('common.location', 'Location')}
            </Title>
            <AddressFormat>
              {location.ln1 && location.ln1} {location.ln2 && location.ln2} {location.city}, {location.state}
            </AddressFormat>

            <Directions
              href={googleMapsDirectionsUrl}
              target='_blank'
              rel='noopener noreferrer'
              variant='outline'
              size='large'
            >
              {t('dispensary.storeInfo.directions', 'Directions')}
            </Directions>
          </AddressContainer>
        )}
      </TwoColumn>
    </SectionContent>
  );
}

const AddressContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: none;

  @media (min-width: 960px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-basis: 40%;
  }
`;

const AddressFormat = styled.div`
  text-align: left;
  font-size: 13px;
  line-height: 145%;
  flex-basis: 100%;
`;

const Directions = styled(AnchorButton)`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 13px;
  font-weight: normal;
  padding: 0;
  text-transform: ${({ theme }) => theme.customized.textTransforms.links};

  @media (min-width: 960px) {
    border-radius: ${({ theme }) => theme.customized.radius.buttons};
    border: 2px solid ${({ theme }) => theme.customized.colors.buttonsLinks};
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    margin-top: 13px;
    padding: 8px 15px;
    text-transform: ${({ theme }) => theme.customized.textTransforms.buttons};
    width: fit-content;
  }
`;

const Title = styled(Typography.Heading)`
  flex-basis: 100%;
  margin-bottom: 10px;
`;

const TwoColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  justify-content: center;

  @media (min-width: 960px) {
    justify-content: space-between;
  }
`;

const OrderingHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${({ isMobile }) => (isMobile ? `auto` : `60%`)};
  max-width: ${({ isMobile }) => (isMobile ? `315px` : `unset`)};
`;

const SectionContent = styled.div`
  margin-top: 50px;
  ${({ isMobileEcommApp }) =>
    isMobileEcommApp
      ? css`
          margin-bottom: 50px;
        `
      : null}
  width: 100%;

  @media (min-width: 960px) {
    margin-top: ${({ hideLocation }) => (hideLocation ? `0px` : `40px`)};
  }
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 165%;
  color: ${({ theme }) => theme.colors.grey[35]};
  margin-bottom: 25px;
`;

const SectionTitle = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin-bottom: 10px;
  margin-top: 0;
`;
