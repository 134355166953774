import _flatMap from "lodash/flatMap";
import _uniqBy from "lodash/uniqBy";
import _forEach from "lodash/forEach";
import { orderProductsForDiscountPrecedence } from '../utilities';
import { getAvailableQuantity } from './common';
/* This check will tell us if the accumulated quantity has met the minimum or has exceeded the maximum
 * which is our end condition. When items for a price is disabled, we want to stop accumulating as soon as
 * the minimum is met but when it is enabled we want to keep going until the max is hit or we run out of products
 */

var stopAccumulating = function stopAccumulating(accumulatedQuantity, minimumQuantity, maximumQuantity, accumulateToMaximum) {
  return accumulatedQuantity >= minimumQuantity && !accumulateToMaximum || accumulatedQuantity > maximumQuantity;
};

var checkItem = function checkItem(_ref) {
  var accumulateToMaximum = _ref.accumulateToMaximum,
      accumulatedQuantity = _ref.accumulatedQuantity,
      bogoCalcsData = _ref.bogoCalcsData,
      discountBundle = _ref.discountBundle,
      _ref$discountToCartAp = _ref.discountToCartApplicable,
      discountToCartApplicable = _ref$discountToCartAp === void 0 ? false : _ref$discountToCartAp,
      item = _ref.item,
      itemsForAPrice = _ref.itemsForAPrice,
      limitOne = _ref.limitOne,
      minimumQuantity = _ref.minimumQuantity,
      maximumQuantity = _ref.maximumQuantity,
      pendingRewardsSatisfiers = _ref.pendingRewardsSatisfiers,
      satisfiers = _ref.satisfiers,
      specialId = _ref.specialId;
  var assignedAnItem = false;

  if (stopAccumulating(accumulatedQuantity, minimumQuantity, maximumQuantity, accumulateToMaximum)) {
    return {
      assignedAnItem: assignedAnItem,
      accumulatedQuantity: accumulatedQuantity,
      satisfiers: satisfiers
    };
  }

  var availableQuantity = getAvailableQuantity({
    item: item,
    bogoCalcsData: bogoCalcsData,
    discountToCartApplicable: discountToCartApplicable,
    pendingConditionsSatisfiers: satisfiers,
    pendingRewardsSatisfiers: pendingRewardsSatisfiers,
    excludeDefeatedRewardsSatisfiers: (itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.enabled) || (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.enabled),
    specialId: specialId
  });

  if (availableQuantity > 0) {
    /*
      when items for a price is enabled we want to use whichever is greater
      between the max and the available quantity, whereas when it's disabled
      we want to use the minimum quantity required to meet the minimum
     */
    var quantityNeededToSatisfyRange = accumulateToMaximum ? Math.max(maximumQuantity - accumulatedQuantity, 0) : Math.max(minimumQuantity - accumulatedQuantity, 0); // on the first pass to ensure our product conditions are met, limit one will be true

    var quantityToUse = Math.min(limitOne ? 1 : availableQuantity, quantityNeededToSatisfyRange);

    if (quantityToUse > 0) {
      if (!satisfiers[item.key]) {
        satisfiers[item.key] = {
          item: item,
          quantity: quantityToUse
        };
      } else {
        satisfiers[item.key].quantity += quantityToUse;
      } // add the quantity used to our accumulated amount


      accumulatedQuantity += quantityToUse;
    }

    assignedAnItem = true;
  }

  return {
    assignedAnItem: assignedAnItem,
    accumulatedQuantity: accumulatedQuantity,
    satisfiers: satisfiers
  };
};
/*
  Our goal is to check if the cart satisfies the total quantity condition.
  With our quantity operators, equal to and greater than, we end up with
  a range that we need to check - for equal to, the min and max are the same.
  For greater than we want to make sure we at least hit the minimum amount required
  which is the quantity + 1. The maximum quantity requirement is a little more complicated
  because a max isn't required AND can be infinity if used with items for a price.
  If the user has configured a max quantity we honor that with the maxQuantityConfig value,
  this is only trumped by items for a price.
 */


var getMinimumAndMaximumQuantity = function getMinimumAndMaximumQuantity(totalQuantity) {
  var accumulateToMaximum = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  var _ref2 = totalQuantity || {},
      maxQuantityConfig = _ref2.maxQuantity,
      quantity = _ref2.quantity,
      quantityOperator = _ref2.quantityOperator;

  var defaultQuantity = Number(quantity !== null && quantity !== void 0 ? quantity : 0);
  var minimumQuantity = defaultQuantity;
  var maximumQuantity = defaultQuantity;

  if (quantityOperator === 'greaterThan') {
    minimumQuantity = defaultQuantity + 1;

    if (accumulateToMaximum) {
      maximumQuantity = maxQuantityConfig !== null && maxQuantityConfig !== void 0 ? maxQuantityConfig : Infinity;
    } else {
      maximumQuantity = defaultQuantity + 1;
    }
  }

  return {
    minimumQuantity: minimumQuantity,
    maximumQuantity: maximumQuantity
  };
}; // TODO: DRY this thing up!


var checkTotalQuantity = function checkTotalQuantity(_ref3) {
  var bogoCalcsData = _ref3.bogoCalcsData,
      conditions = _ref3.conditions,
      discountBundle = _ref3.discountBundle,
      items = _ref3.items,
      itemsForAPrice = _ref3.itemsForAPrice,
      itemToConsiderLast = _ref3.itemToConsiderLast,
      logicOperator = _ref3.logicOperator,
      pendingRewardsSatisfiers = _ref3.pendingRewardsSatisfiers,
      specialId = _ref3.specialId,
      specialsSettings = _ref3.specialsSettings,
      totalQuantity = _ref3.totalQuantity;

  /*
    In most cases we stop counting once the minimum is hit, but in the case
    of items for a price and when the special is from the POS (LLx) we
    want to keep counting until the maximum is hit
   */
  var advancedReward = (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.enabled) || (itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.enabled);
  var accumulateToMaximum = (totalQuantity === null || totalQuantity === void 0 ? void 0 : totalQuantity.quantityOperator) === 'greaterThan' && advancedReward || (totalQuantity === null || totalQuantity === void 0 ? void 0 : totalQuantity.accumulateToMaximum);

  var _getMinimumAndMaximum = getMinimumAndMaximumQuantity(totalQuantity, accumulateToMaximum),
      minimumQuantity = _getMinimumAndMaximum.minimumQuantity,
      maximumQuantity = _getMinimumAndMaximum.maximumQuantity;

  var eligibleItems;
  var accumulatedQuantity = 0;
  var satisfied = false;
  var satisfiers = {};
  /* Total Quantity with product conditions (need to satisfy underlying conditions first)
   * we need to go through each of the "underlying" conditions and their eligible items
   * to make sure those condition(s) are met first before we actually check the total
   * quantity condition is met. This is often referred to as the 'first pass'.
   */

  if (conditions.length > 0) {
    var underlyingConditionsSatisfied = logicOperator !== 'and'; // AND path only

    if (!underlyingConditionsSatisfied) {
      _forEach(conditions, function (condition) {
        var assignedAnItem;
        var considerLast;

        _forEach(condition.eligibleItems, function (item) {
          if (item.key !== (itemToConsiderLast === null || itemToConsiderLast === void 0 ? void 0 : itemToConsiderLast.key)) {
            var _checkItem = checkItem({
              bogoCalcsData: bogoCalcsData,
              discountBundle: discountBundle,
              discountToCartApplicable: condition.discountToCartApplicable,
              item: item,
              itemsForAPrice: itemsForAPrice,
              limitOne: true,
              pendingRewardsSatisfiers: pendingRewardsSatisfiers,
              accumulatedQuantity: accumulatedQuantity,
              satisfiers: satisfiers,
              specialId: specialId,
              minimumQuantity: minimumQuantity,
              maximumQuantity: maximumQuantity,
              accumulateToMaximum: accumulateToMaximum
            });

            assignedAnItem = _checkItem.assignedAnItem;
            accumulatedQuantity = _checkItem.accumulatedQuantity;
            satisfiers = _checkItem.satisfiers;
          } else {
            considerLast = item;
          }

          if (assignedAnItem) {
            return false; // breaks early
          }
        });

        if (!assignedAnItem && considerLast) {
          var _checkItem2 = checkItem({
            bogoCalcsData: bogoCalcsData,
            item: considerLast,
            limitOne: true,
            pendingRewardsSatisfiers: pendingRewardsSatisfiers,
            accumulatedQuantity: accumulatedQuantity,
            satisfiers: satisfiers,
            specialId: specialId,
            minimumQuantity: minimumQuantity,
            maximumQuantity: maximumQuantity,
            accumulateToMaximum: accumulateToMaximum
          });

          assignedAnItem = _checkItem2.assignedAnItem;
          accumulatedQuantity = _checkItem2.accumulatedQuantity;
          satisfiers = _checkItem2.satisfiers;
        } // If a single item can not be assigned for any AND condition, underlyingConditionsSatisfied is false


        if (!assignedAnItem) {
          underlyingConditionsSatisfied = false;
          return false; // breaks early
        } // if we get here and we have assigned an item we know our and condition has been met


        underlyingConditionsSatisfied = true;
      });
    }

    if (underlyingConditionsSatisfied) {
      if (stopAccumulating(accumulatedQuantity, minimumQuantity, maximumQuantity, accumulateToMaximum)) {
        return {
          satisfied: true,
          satisfiers: satisfiers
        };
      }

      var uniqueConditions = _uniqBy(_flatMap(conditions, 'eligibleItems'), 'key');

      eligibleItems = orderProductsForDiscountPrecedence(uniqueConditions, specialsSettings, advancedReward ? 'rewards' : 'conditions');
    } else {
      return {
        satisfied: false,
        satisfiers: {}
      };
    }
  } else {
    // Total Quantity without product conditions (all items are eligible)
    eligibleItems = items;
  }

  var considerLast;
  /*
    Once the product conditions have been met as a pre-requisite, we can proceed
    to check that the remaining products still meet the total quantity conditions.
    This is often referred to as the 'second pass'.
   */

  _forEach(eligibleItems, function (item) {
    if (item.key !== (itemToConsiderLast === null || itemToConsiderLast === void 0 ? void 0 : itemToConsiderLast.key)) {
      var _checkItem3 = checkItem({
        bogoCalcsData: bogoCalcsData,
        discountBundle: discountBundle,
        item: item,
        itemsForAPrice: itemsForAPrice,
        pendingRewardsSatisfiers: pendingRewardsSatisfiers,
        accumulatedQuantity: accumulatedQuantity,
        satisfiers: satisfiers,
        specialId: specialId,
        minimumQuantity: minimumQuantity,
        maximumQuantity: maximumQuantity,
        accumulateToMaximum: accumulateToMaximum
      });

      accumulatedQuantity = _checkItem3.accumulatedQuantity;
      satisfiers = _checkItem3.satisfiers;
    } else {
      considerLast = item;
    }

    if (stopAccumulating(accumulatedQuantity, minimumQuantity, maximumQuantity, accumulateToMaximum)) {
      return false; // breaks early
    }
  });

  if (!stopAccumulating(accumulatedQuantity, minimumQuantity, maximumQuantity, accumulateToMaximum) && considerLast) {
    var _checkItem4 = checkItem({
      bogoCalcsData: bogoCalcsData,
      discountBundle: discountBundle,
      item: considerLast,
      itemsForAPrice: itemsForAPrice,
      pendingRewardsSatisfiers: pendingRewardsSatisfiers,
      accumulatedQuantity: accumulatedQuantity,
      satisfiers: satisfiers,
      specialId: specialId,
      minimumQuantity: minimumQuantity,
      maximumQuantity: maximumQuantity,
      accumulateToMaximum: accumulateToMaximum
    });

    accumulatedQuantity = _checkItem4.accumulatedQuantity;
    satisfiers = _checkItem4.satisfiers;
  }
  /*
    this is our true end condition - when our accumulated quantity from our cart products
    has fallen on or between our min max range and we have already considered out items
    for a price additional runs if they were needed
   */


  if (accumulatedQuantity >= minimumQuantity && accumulatedQuantity <= maximumQuantity) {
    satisfied = true;
  } else {
    satisfied = false;
    satisfiers = {};
  }

  return {
    satisfied: satisfied,
    satisfiers: satisfiers
  };
};

export default checkTotalQuantity;