import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["backdropComponent", "children"];
var __jsx = React.createElement;
import React from 'react';
import MuiModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import styled from 'styled-components';
export var ModalWrapper = function ModalWrapper(props) {
  var backdropComponent = props.backdropComponent,
      children = props.children,
      rest = _objectWithoutProperties(props, _excluded);

  return (// eslint-disable-next-line no-use-before-define
    __jsx(MuiModal, _extends({}, rest, {
      backdropComponent: backdropComponent || DefaultBackdropComponent
    }), children)
  );
};
var DefaultBackdropComponent = styled(Backdrop).withConfig({
  displayName: "v2-wrapper__DefaultBackdropComponent",
  componentId: "sc-1op7gcz-0"
})(["&.MuiBackdrop-root{background-color:", ";}"], function (_ref) {
  var _theme$colors;

  var theme = _ref.theme;
  return theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.greyOverlay;
});