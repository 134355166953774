import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { isAnalyticsDebugMode } from 'utils/analytics/helpers/is-enabled';
import { isBrowser as useIsBrowser } from 'utils/misc-utils';

/**
 * @fileOverview GoogleAnalytics event capture code for marketing pages.
 *
 * THIS IS NOT A NORMAL MONITOR... most monitors are simple, this does way more than a
 * monitor should.
 *
 * Note:
 *  - you can enable CLI debug by adding ?debugAnalytics
 *  - the event label is generated by traversing the tree and capturing the text of the
 *    <a> tag in the parent chain as well as any data-ga-label elements. e.g.
 *         <html>
 *           <div>
 *             <div data-ga-label="the HeAder">
 *               <span>
 *                 Boop
 *                 <div data-ga-label="foo_section">
 *                   <a>
 *                     Foo bar bing
 *                       <span>
 *                         <img>
 *          clicking on the img will generate a label: the-header.foo-section.foo-bar-bing
 */

const whitelistedPaths: RegExp[] = [
  /^\/$/,
  /^\/about$/,
  /^\/careers$/,
  /^\/news$/,
  /^\/blog$/,
  /^\/blog\//,
  /^\/dispensaries\/demo$/,
  /^\/dispensary$/,
  /^\/dispensary\/(ecommerce|features|getstarted|payments|integrations|pos)$/,
  /^\/dispensary\/features$/,
  /^\/plus$/,
];

const MAX_LABEL_LENGTH = 20;
const sanitize = (s: string): string => {
  const sanitized = s
    .trim()
    .replace(/[^a-zA-Z0-9]+/gi, '-')
    .toLowerCase()
    .substr(0, MAX_LABEL_LENGTH);
  return sanitized.length > 0 ? sanitized : '??';
};

const log = (gaPayload: any): void => {
  if (!isAnalyticsDebugMode()) {
    return;
  }

  console.info(
    `%cDUTCHIE_ANALYTICS%c Google Analytics %o`,
    'font-weight: bold; color: #FF3E51; background-color: #153F66; padding: 2px 4px;',
    '',
    gaPayload
  );
};

const getLabel = (target: HTMLElement): string => {
  const labelParts: string[] = [];

  let parentElement: HTMLElement | null = target;
  while (parentElement) {
    if (!(parentElement instanceof HTMLElement)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const ariaLabel: string | null = (parentElement as any)?.ariaLabel ?? null;
    if (parentElement.dataset.gaLabel) {
      labelParts.push(sanitize(parentElement.dataset.gaLabel));
    } else if (ariaLabel) {
      labelParts.push(sanitize(ariaLabel));
    } else if (['a', 'button'].includes(parentElement.tagName.toLowerCase())) {
      labelParts.push(sanitize(parentElement.innerText));
    }

    parentElement = parentElement.parentElement;
  }

  return labelParts.length > 0 ? labelParts.reverse().join('.') : 'text';
};

const clickHandler = (e: MouseEvent): void => {
  const { target } = e;

  if (!(target instanceof HTMLElement) || !(typeof window.ga === 'function')) {
    return;
  }

  const payload = {
    hitType: 'event',
    eventCategory: 'marketing',
    eventAction: 'click',
    eventLabel: getLabel(target),
  };

  log(payload);
  window.ga('send', payload);
};

export const useMarketingGoogleAnalyticsMonitor = (): void => {
  const { pathname } = useRouter();
  const isBrowser = useIsBrowser();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!isBrowser || !whitelistedPaths.find((regex) => regex.test(pathname)) || !window) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    window.document.body.addEventListener('click', clickHandler);

    return () => {
      window.document.body.removeEventListener('click', clickHandler);
    };
  }, [pathname, isBrowser]);
};
