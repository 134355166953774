import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import { Clickable } from 'shared/components';
import CheckboxInput from '../checkbox-input';

type Option = {
  key: string;
  value: string;
  label: string;
  queryValue?: string;
};

type CheckboxGroupProps = {
  options: Option[];
  selectedOptions: string[] | null;
  onSelect: (value: string) => void;
};

function setupViewMoreExpanded(options: Option[], selectedOptions: string[] | null): boolean {
  const optionsPastSix = options.slice(6);
  return optionsPastSix.some((option) => selectedOptions?.includes(option.queryValue ?? option.value));
}

export function CheckboxGroup({ options, selectedOptions, onSelect }: CheckboxGroupProps): JSX.Element {
  const initiallyExpanded = useMemo(() => setupViewMoreExpanded(options, selectedOptions), [options, selectedOptions]);

  const [expanded, setExpanded] = useState(initiallyExpanded);
  const { t } = useTranslation();

  const firstSixOptions = options.slice(0, 6);
  const optionsToRender = expanded ? options : firstSixOptions;

  return (
    <CheckboxesContainer>
      {optionsToRender.map((option) => (
        <li key={option.key}>
          <CheckboxInput
            onClick={() => onSelect(option.queryValue ?? option.value)}
            checked={!!selectedOptions?.includes(option.queryValue ?? option.value)}
            option={option}
          />
        </li>
      ))}
      {options.length > 6 && (
        <li>
          <ViewMore onClick={() => setExpanded(!expanded)}>
            {expanded ? t('common.view-less', 'View Less') : t('common.view-more', 'View More')}
          </ViewMore>
        </li>
      )}
    </CheckboxesContainer>
  );
}

const CheckboxesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  list-style: none;
  margin: 11px 0 0 0;
`;

const ViewMore = styled(Clickable)`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  margin-top: 10px;
  display: inline-block;
  font-size: 14px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 13px;
  }
`;
