import React from 'react';
import styled from 'styled-components';
import useTranslation from 'hooks/use-translation';

type ComponentProps = {
  bankAccountDetails: {
    accountAlias: string;
    accountGuid: string;
    bankName: string;
    lastDigits: string;
    availableBalance: number;
  };
};

const formatCurrency = new Intl.NumberFormat(`en-US`, {
  style: `currency`,
  currency: 'USD',
});

export const BankAccountDetails = ({ bankAccountDetails }: ComponentProps): JSX.Element => {
  const { Trans } = useTranslation();
  return (
    <Container>
      <div>
        <Title>{bankAccountDetails.accountAlias}</Title>
        <SubDetails>
          <Trans i18nKey='dutchiePay.accountEnding'>Account ending in {bankAccountDetails.lastDigits}</Trans>
        </SubDetails>
      </div>
      <SubDetails>{formatCurrency.format(+bankAccountDetails.availableBalance)}</SubDetails>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 2px;
`;

const SubDetails = styled.p`
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 13px;
`;
