import {
  ArrayParam,
  BooleanParam,
  StringParam,
  NumberParam,
  withDefault,
  encodeDelimitedArray,
  decodeDelimitedArray,
  QueryParamConfig,
} from 'use-query-params';
import { useRouter } from 'next/router';

import useQueryParamsHookFactory from 'shared/hooks/use-query-params-hook-factory';
import { GenericCategoryRoute, isGenericCategoryRoute } from 'src/generics/data/generic-category';

import { getGenericCategoryRouteInfo } from 'shop/utils/categories';

const defaultPotency: [number, number] = [0, 50];

function getPotencyRange(category: GenericCategoryRoute | undefined): [number, number] {
  if (!isGenericCategoryRoute(category)) {
    return defaultPotency;
  }

  const { potencyRanges = defaultPotency } = getGenericCategoryRouteInfo(category);

  return potencyRanges;
}

function PotencyRangeParam(defaultValue: string[]): QueryParamConfig<string[]> {
  return {
    encode(array) {
      if (Array.isArray(array) && array.length === 2) {
        return encodeDelimitedArray(array, ',');
      }
      return encodeDelimitedArray(defaultValue, ',');
    },

    decode(arrayStr) {
      const range = decodeDelimitedArray(arrayStr, ',');
      if (Array.isArray(range) && range.length === 2) {
        return [String(range[0]), String(range[1])];
      }
      return defaultValue;
    },
  };
}

function useDefaultPotencyRange(query): [string, string] {
  const [min, max] = getPotencyRange(query.category);

  return [String(min), String(max)];
}

type Defaults = {
  brands: string[];
  distance: number;
  distActive: boolean;
  page: number;
  subcategories?: string[];
  straintypes: string[];
  weight?: string;
  potencyTHC: string[];
  potencyCBD: string[];
};

function useDefaults(query): Defaults {
  const potencyRange = useDefaultPotencyRange(query);

  return {
    brands: [],
    distance: 15,
    distActive: false,
    page: 1,
    subcategories: [],
    straintypes: [],
    weight: undefined,
    potencyTHC: potencyRange,
    potencyCBD: potencyRange,
  };
}

type Config = {
  brands: typeof ArrayParam;
  distance: typeof NumberParam;
  distActive: typeof BooleanParam;
  page: typeof NumberParam;
  subcategories: typeof ArrayParam;
  straintypes: typeof ArrayParam;
  weight: typeof StringParam;
  potencyTHC: typeof ArrayParam;
  potencyCBD: typeof ArrayParam;
};

function useConfig({
  brands,
  distance,
  distActive,
  page,
  subcategories,
  straintypes,
  weight,
  potencyTHC,
  potencyCBD,
}: Defaults): Config {
  return {
    brands: withDefault(ArrayParam, brands),
    distance: withDefault(NumberParam, distance),
    distActive: withDefault(BooleanParam, distActive),
    page: withDefault(NumberParam, page),
    subcategories: withDefault(ArrayParam, subcategories),
    straintypes: withDefault(ArrayParam, straintypes),
    weight: withDefault(StringParam, weight),
    potencyTHC: withDefault<string[], string[]>(PotencyRangeParam(potencyTHC), potencyTHC),
    potencyCBD: withDefault<string[], string[]>(PotencyRangeParam(potencyCBD), potencyCBD),
  };
}

export type UseMarketplaceQueryParamsReturn = ReturnType<typeof useQueryParamsHookFactory> & {
  isReady: boolean;
};

export type MarketplaceQueryParams = UseMarketplaceQueryParamsReturn['queryParams'];

export function useMarketplaceQueryParams(): UseMarketplaceQueryParamsReturn {
  const { isReady, query } = useRouter();

  const defaults = useDefaults(query);
  const config = useConfig(defaults);
  const value = useQueryParamsHookFactory(config, defaults);

  return { ...value, isReady };
}
