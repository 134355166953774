/* eslint-disable max-len */
import React from 'react';

function SvgInstagram(props) {
  return (
    <svg height={22} width={22} fill='currentColor' {...props}>
      <g fillRule='evenodd'>
        <path d='M10.936.007c-2.97 0-3.343.012-4.509.066C5.263.126 4.468.31 3.772.58a5.361 5.361 0 00-1.937 1.259 5.349 5.349 0 00-1.26 1.933c-.271.694-.456 1.487-.51 2.65C.014 7.584 0 7.956 0 10.92s.013 3.336.066 4.5c.053 1.162.238 1.955.508 2.65.28.717.653 1.325 1.261 1.932s1.218.98 1.937 1.26c.696.269 1.49.453 2.655.506 1.166.054 1.539.066 4.509.066s3.342-.012 4.509-.066c1.164-.053 1.958-.237 2.654-.507a5.362 5.362 0 001.937-1.259 5.348 5.348 0 001.261-1.933c.27-.694.456-1.487.509-2.65s.066-1.535.066-4.499-.013-3.336-.066-4.5c-.053-1.162-.238-1.955-.509-2.65a5.349 5.349 0 00-1.261-1.932 5.36 5.36 0 00-1.937-1.26C17.403.31 16.61.127 15.445.074c-1.167-.054-1.54-.066-4.51-.066m0 1.966c2.92 0 3.267.011 4.42.064 1.066.049 1.645.226 2.03.376.51.198.875.434 1.258.816.382.382.62.746.818 1.255.15.385.328.963.376 2.027.053 1.15.064 1.496.064 4.41s-.011 3.26-.064 4.41c-.048 1.064-.226 1.642-.376 2.027-.199.51-.436.873-.818 1.255a3.389 3.389 0 01-1.258.816c-.385.15-.964.327-2.03.376-1.153.052-1.499.064-4.42.064-2.92 0-3.265-.012-4.418-.064-1.066-.049-1.646-.226-2.03-.376a3.389 3.389 0 01-1.258-.816 3.381 3.381 0 01-.818-1.255c-.15-.385-.328-.963-.377-2.027-.052-1.15-.064-1.496-.064-4.41s.012-3.26.064-4.41c.049-1.064.227-1.642.377-2.027.198-.51.435-.873.818-1.255a3.389 3.389 0 011.257-.816c.385-.15.965-.327 2.03-.376 1.154-.053 1.5-.064 4.42-.064' />
        <path d='M10.94 14.557a3.617 3.617 0 110-7.235 3.617 3.617 0 010 7.235m0-9.19a5.573 5.573 0 100 11.145 5.573 5.573 0 000-11.146m7.223-.309a1.342 1.342 0 11-2.683 0 1.342 1.342 0 012.683 0' />
      </g>
    </svg>
  );
}

export default SvgInstagram;
