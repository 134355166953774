import { useMutation } from '@apollo/react-hooks';
import React from 'react';

import { Button } from 'shared/components';
import useStores from 'shared/hooks/use-stores';

import generateHypurRedirectV3 from 'checkout/components/generate-hypur-redirect-v3.gql';

export default function ConnectHypur(props) {
  const { dispensaryId } = props;
  const [loading, setLoading] = React.useState(false);
  const { apolloClient, UI } = useStores();
  const embedded = UI.isVariant(`embedded`) || UI.isVariant(`store-front`);

  const [redirectUrl, setRedirectUrl] = React.useState(null);
  const [handleGenerateHypurRedirect] = useMutation(generateHypurRedirectV3, {
    client: apolloClient,
    variables: {
      dispensaryId,
      embedded,
      hostname: window.location.hostname,
    },
  });

  // NOTE:
  // window.open cannot be in an async function or it triggers Safari's popup blocker
  // We're grabbing the redirect URL here and using setState so we can use a regular function for the onClick
  React.useEffect(() => {
    const handleGetRedirect = async () => {
      setLoading(true);
      try {
        const response = await handleGenerateHypurRedirect();
        const { url } = response.data.generateHypurRedirectV3;
        setRedirectUrl(url);
        setLoading(false);
      } catch (error) {
        console.error(error);
        UI.showErnie('Something went wrong, please try again.', 'danger');
        setLoading(false);
      }
    };
    handleGetRedirect();
  }, []);

  const handleClick = React.useCallback(() => {
    if (embedded) {
      window.open(redirectUrl);
    } else {
      window.location.href = redirectUrl;
    }
  }, [embedded, redirectUrl]);

  return (
    <Button mt='25px' height='33px' mr='15px' onClick={handleClick} loading={loading}>
      Connect Hypur account
    </Button>
  );
}
