import React from 'react';
import styled from 'styled-components';
import { ComboboxOption, ComboboxOptionProps } from '@reach/combobox';

import { LocationObject } from 'shared/helpers/mapbox';
import SvgPinIcon from 'src/assets/pin-icon';
import { useAddressAutocomplete } from '../context';
import { AddressText } from './address-text';

type AddressAutocompleteOptionProps = Omit<ComboboxOptionProps, 'value'> & {
  wrapText?: boolean;
  suggestion: LocationObject;
  index: number;
};

export function AddressAutocompleteOption({
  suggestion,
  wrapText,
  ...props
}: AddressAutocompleteOptionProps): JSX.Element {
  const { displayValue } = useAddressAutocomplete();

  return (
    <StyledComboboxOption value={suggestion.address} key={suggestion.address} {...props}>
      <Container>
        <StyledPinIcon />
        <AddressText wrapText={wrapText} suggestion={suggestion} searchStr={displayValue} />
      </Container>
    </StyledComboboxOption>
  );
}

const Container = styled.div`
  display: flex;
`;

const StyledComboboxOption = styled(ComboboxOption)`
  margin-top: 22px;
  border-radius: 5px;
  padding: 10px 8px;
  white-space: normal;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 8px 12px;
  }

  :hover {
    background: ${({ theme }) => theme.colors.primaryGrey};
    cursor: pointer;
  }
`;

const StyledPinIcon = styled(SvgPinIcon)`
  margin: 0px 14px 0 0;
  height: 21px;
  fill: ${({ theme }) => theme.colors.grey[70]};
`;
