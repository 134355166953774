import { ComboboxInput, ComboboxInputProps } from '@reach/combobox';
import React, { HTMLProps } from 'react';
import styled from 'styled-components';

import { VisuallyHidden } from 'src/components/core/visually-hidden';
import useTranslation from 'src/hooks/use-translation';

import { useAddressAutocomplete } from '../context';

type AddressAutocompleteInputProps = {
  withClearButton?: boolean;
  withPinAdornment?: boolean;
};

export function AddressAutocompleteInput({
  onChange,
  ...props
}: AddressAutocompleteInputProps & ComboboxInputProps & HTMLProps<HTMLInputElement>): JSX.Element {
  const { t } = useTranslation();
  const { displayValue, handleInputChange, inputRef } = useAddressAutocomplete();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    onChange?.(e);
    handleInputChange(e);
  }

  return (
    <>
      <VisuallyHidden>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor='enter-your-address-input'>
          {t('homepage.enter-address-input-label', 'Enter your address to search for dispensaries near you')}
        </label>
      </VisuallyHidden>
      <StyledComboboxInput
        ref={inputRef}
        id='enter-your-address-input'
        data-testid='enter-your-address-input'
        aria-label={t('common.enterYourAddress', 'Enter your address')}
        autoComplete='off'
        onChange={handleChange}
        value={displayValue}
        {...props}
      />
    </>
  );
}

const StyledComboboxInput = styled(ComboboxInput)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.grey[25]};

  ::placeholder {
    color: ${({ theme }) => theme.colors.grey[35]};
  }
`;
