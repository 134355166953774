import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { tracker } from '..';

export const usePathMonitor = (waitToTrigger): void => {
  const router = useRouter();
  const concretePath = router.asPath;
  const normalizedPath = router.pathname;

  useEffect(() => {
    if (!waitToTrigger) {
      tracker.setContext({ activePath: { normalized: normalizedPath, concrete: concretePath } });
    }
  }, [waitToTrigger, concretePath, normalizedPath]);
};
