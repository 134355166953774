import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import FormTextInput from './form-text-input';

const formatPropsMap = {
  phone: {
    format: '(###) ###-####',
    placeholder: '(___) ___-____',
    mask: '_',
    type: `tel`,
  },
  date: {
    format: '##/##/####',
    placeholder: `mm/dd/yyyy`,
    mask: [`m`, `m`, `d`, `d`, `y`, `y`, `y`, `y`],
    type: `tel`,
  },
};

const NumberInput = ({ className: classNameFromProps, empty, value, ...props }) => {
  const [focused, setFocused] = useState(false);
  const className = empty ? `${classNameFromProps} empty` : classNameFromProps;
  return (
    <NumberFormat
      {...props}
      customInput={FormTextInput}
      className={className}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      InputLabelProps={{ shrink: focused || !!value }}
      value={value}
    />
  );
};

const FormFormattedInput = ({ format, ...props }) => {
  if (!format) {
    console.error(`FormFormattedInput requires a format prop`);
  }
  const { name } = props;
  const { getValues, setValue, control } = useFormContext();
  const value = getValues(name);
  const hasValue = !!value && value !== formatPropsMap[format].placeholder;
  const formatProps = formatPropsMap[format];

  const handleAutoFill = ({ value: autoFillValue }) => {
    if (value && value !== autoFillValue) {
      setValue(props.name, autoFillValue);
    } else {
      setValue(props.name, getValues(props.name));
    }
  };

  return (
    <Controller
      as={NumberInput}
      {...props}
      {...formatProps}
      value={value}
      name={name}
      control={control}
      controlled
      onValueChange={handleAutoFill}
      empty={!hasValue}
    />
  );
};

export default FormFormattedInput;
