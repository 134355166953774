import React from 'react';
import styled from 'styled-components';

const DispensaryHeader = ({ dispensary }) => {
  const { address, logoImage, name } = dispensary;

  return (
    <Header>
      <LogoImage alt={name} src={logoImage} />

      <Dispensary>
        <Name>{name}</Name>
        <Address>{address}</Address>
      </Dispensary>
    </Header>
  );
};

const Header = styled.div`
  text-align: left;
  width: 100%;
  display: flex;
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-bottom: 30px;
`;

const LogoImage = styled.img`
  height: 65px;
  width: auto;
  flex-basis: 65px;
  margin-right: 22px;
`;

const Dispensary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
`;

const Address = styled.div`
  margin-top: 2px;
  font-weight: normal;
  font-size: 13px;
  line-height: 165%;
`;

export default DispensaryHeader;
