/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { GqlSpecials } from 'types/graphql';

export type ConditionV3dot5 = {
  _id: string;
  brandIds?: string[];
  brandNames?: string[];
  categoryIds?: string[];
  categoryNames?: string[];
  exclusions?: ExclusionV3dot5[];
  inventoryTags?: any[];
  productIds?: string[];
  productGroup: ProductGroupV3dot5;
  productTags?: any[];
  quantity: number; // Essentially dictates the fields in a particular exclusion object
  strainIds?: string[];
  subcategoryIds?: string[];
  subcategoryNames?: string[];
  vendorIds?: string[];
  weights?: string[]; // ? should this be an array of Weight objects?
  // weights: Weight[];
  weightOperator?: WeightOperator; // Specific to e-comm
};

type DayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type DiscountTypeV3dot5 = 'dollarDiscount' | 'percentDiscount' | 'targetPrice';

export type ExclusionV3dot5 = {
  _id?: string;
  brandIds?: string[];
  brandNames?: string[];
  categoryIds?: string[];
  categoryNames?: string[];
  inventoryTags?: any[];
  productIds?: string[];
  productGroup: ProductGroupV3dot5; // Essentially dictates the fields in a particular exclusion object
  productTags?: string[];
  strainIds?: string[];
  subcategoryIds?: string[];
  subcategoryNames?: string[];
  vendorIds?: string[];
  weights?: string[];
  weightOperator?: WeightOperator; // Specific to e-comm
};

export type RewardV3dot5 = {
  _id: string;
  brandIds?: string[];
  brandNames?: string[];
  categoryIds?: string[];
  categoryNames?: string[];
  discountAmount: number;
  discountType: DiscountTypeV3dot5;
  exclusions?: ExclusionV3dot5[];
  inventoryTags?: any[];
  productIds?: string[];
  productGroup: ProductGroupV3dot5; // Essentially dictates the fields in a particular exclusion object
  productTags?: any[];
  quantity: number;
  strainIds?: string[];
  subcategoryIds?: string[];
  subcategoryNames?: string[];
  vendorIds?: string[];
  weights?: string[];
  weightOperator?: WeightOperator; // Specific to e-comm
};

export enum ProductGroupPropertyKey {
  inventoryTags = 'inventoryTags',
  brandIds = 'brandIds',
  brandNames = 'brandNames',
  categoryIds = 'categoryIds',
  categoryNames = 'categoryNames',
  productIds = 'productIds',
  productTags = 'productTags',
  subcategoryIds = 'subcategoryIds',
  subcategoryNames = 'subcategoryNames',
  strainIds = 'strainIds',
  vendorIds = 'vendorIds',
}

export type ProductGroupV3dot5 = 'all' | 'brand' | 'brands' | 'categories' | 'category' | 'individual';

export type SaleDiscountV3dot5 = {
  _id: string;
  brandIds?: string[];
  brandNames?: string[];
  categoryIds?: string[];
  categoryNames?: string[];
  discountAmount: number;
  discountType: DiscountTypeV3dot5;
  exclusions?: ExclusionV3dot5[];
  inventoryTags?: any[];
  productGroup: ProductGroupV3dot5;
  productIds?: string[];
  productTags?: any[];
  strainsIds?: string[];
  subcategoryIds?: string[];
  subcategoryNames?: string[];
  vendorIds?: string[];
  weights?: string[];
  weightOperator?: WeightOperator; // Specific to e-comm
};

export type SpecialItemV3dot5 = ConditionV3dot5 | ExclusionV3dot5 | RewardV3dot5 | SaleDiscountV3dot5;

export type SpecialV3dot5 = Omit<GqlSpecials, 'bogoConditions' | 'bogoRewards'> & {
  bogoConditions?: ConditionV3dot5[];
  bogoRewards?: RewardV3dot5[];
  saleDiscounts?: SaleDiscountV3dot5[];
};

export type ScheduleV4 = {
  days: DayIndex[];
  endStamp: string;
  endTime: string;
  startTime: string;
  timezone: string;
};

type Weight = {
  amount: number;
  unit: WeightUnitsOfMeasure;
};

export type WeightOperator = 'equalTo' | 'greaterThan' | 'greaterThanEqualTo';

export type WeightUnitsOfMeasure = 'g' | 'gC' | 'mg' | 'oz';
