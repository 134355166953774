import _slicedToArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _toConsumableArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _isFunction from "lodash/isFunction";
import _isNaN from "lodash/isNaN";
import _every from "lodash/every";
import _keys from "lodash/keys";
import _forEach from "lodash/forEach";
import _values from "lodash/values";
import _omit from "lodash/omit";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";
import _intersectionBy from "lodash/intersectionBy";
import _some from "lodash/some";
import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";
import _set from "lodash/set";
import _get from "lodash/get";
import _includes from "lodash/includes";
import _find from "lodash/find";
import _map from "lodash/map";
import _reduce from "lodash/reduce";
var _excluded = ["_id", "enterpriseSpecialId"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';
import { convertDate } from 'shared/helpers/date-time'; // eslint-disable-next-line import/no-cycle

import { getProductWeight, saleSatisfiesWeightCondition } from './products'; // eslint-disable-next-line import/no-cycle

import { eligibleProductOptionsForRestriction } from './specials-3-5';
var LA_TIMEZONE = 'America/Los_Angeles';
export var currentSpecialsFilter = function currentSpecialsFilter(special) {
  var _special$recurring, _special$recurring2;

  // Analogous to currentParams Mongo-based logic
  var now = moment().toDate();
  var basicFilters = special.active && !special.complete && special.version >= 2;
  var recurringFilters = !special.isRecurring && special.endStamp > now || special.isRecurring && (!((_special$recurring = special.recurring) !== null && _special$recurring !== void 0 && _special$recurring.setEndDate) || ((_special$recurring2 = special.recurring) === null || _special$recurring2 === void 0 ? void 0 : _special$recurring2.endDate) > now);
  return basicFilters && recurringFilters;
};
export var days = moment.weekdays(false);
export var dayAbbreviations = [{
  key: 1,
  day: "Mon"
}, {
  key: 2,
  day: "Tue"
}, {
  key: 3,
  day: "Wed"
}, {
  key: 4,
  day: "Thu"
}, {
  key: 5,
  day: "Fri"
}, {
  key: 6,
  day: "Sat"
}, {
  key: 0,
  day: "Sun"
}];
export var defaultAdvancedConditionOptions = function defaultAdvancedConditionOptions(type) {
  var weight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Any Weight';
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
    enabled: true
  }, type === 'quantity' ? {
    quantity: ''
  } : {}), type === 'maxQuantity' ? {
    maxQuantity: ''
  } : {}), type === 'quantity' ? {
    quantityOperator: 'equalTo'
  } : {}), type === 'weight' ? {
    weight: weight
  } : {}), type === 'weight' ? {
    weightOperator: 'equalTo'
  } : {}), type === 'spend' ? {
    minimumSpend: 0,
    maximumSpend: 0
  } : {}), type === 'spend' ? {
    spendOperator: 'greaterThanEqualTo'
  } : {});
}; // Used as reducer to extract .exclusions from an object (Relevant to special v3.5)

var extractExclusions = function extractExclusions(prev, curr) {
  var _curr$exclusions;

  if ((curr === null || curr === void 0 ? void 0 : (_curr$exclusions = curr.exclusions) === null || _curr$exclusions === void 0 ? void 0 : _curr$exclusions.length) > 0) {
    return prev.concat(curr.exclusions);
  }

  return prev;
};

export var extractSaleExclusions = function extractSaleExclusions(special) {
  var _special$saleDiscount, _special$excludedProd;

  // v3.5
  if ((special === null || special === void 0 ? void 0 : (_special$saleDiscount = special.saleDiscounts) === null || _special$saleDiscount === void 0 ? void 0 : _special$saleDiscount.length) > 0) {
    return _reduce(special.saleDiscounts, extractExclusions, []);
  } // v3.0


  return (special === null || special === void 0 ? void 0 : (_special$excludedProd = special.excludedProducts) === null || _special$excludedProd === void 0 ? void 0 : _special$excludedProd.length) > 0 ? _toConsumableArray(special.excludedProducts) : [];
};
export var getDaysList = function getDaysList(daysArr) {
  var dayList = _map(daysArr, function (key) {
    return _find(dayAbbreviations, ["key", key]);
  }); // eslint-disable-next-line lodash/prefer-lodash-method


  return dayList.sort(function (a, b) {
    return a.key - b.key;
  }).map(function (d) {
    return d.day;
  }).join(', ');
};
export var specialIsRecurring = function specialIsRecurring(special) {
  if (!special.recurring) {
    return false;
  }

  return special.isRecurring;
};
export var specialHasAdvancedConditions = function specialHasAdvancedConditions(special) {
  var _special$bogoConditio, _special$totalSpend, _special$totalWeight, _special$totalQuantit;

  return special.specialType === 'bogo' && (!special.bogoConditions || ((_special$bogoConditio = special.bogoConditions) === null || _special$bogoConditio === void 0 ? void 0 : _special$bogoConditio.length) === 0) && (((_special$totalSpend = special.totalSpend) === null || _special$totalSpend === void 0 ? void 0 : _special$totalSpend.enabled) || ((_special$totalWeight = special.totalWeight) === null || _special$totalWeight === void 0 ? void 0 : _special$totalWeight.enabled) || ((_special$totalQuantit = special.totalQuantity) === null || _special$totalQuantit === void 0 ? void 0 : _special$totalQuantit.enabled));
};
export var specialHasRequiredProducts = function specialHasRequiredProducts(special, filteredProducts) {
  // if filtered products is undefined, skip validation and return true
  if (!filteredProducts) {
    return true;
  } // filtered products are serialized with special data
  // reduce the set of specials tied to products and compare with input special


  var specialHasProducts = filteredProducts.some(function (product) {
    var specials = [].concat(_toConsumableArray(product.specialData.bogoSpecials || []), _toConsumableArray(product.specialData.saleSpecials || []));
    return specials.some(function (productSpecial) {
      return productSpecial.specialId === special._id;
    });
  }); // if the special is for min spend and there are no other conditions, it applies to all products

  return specialHasAdvancedConditions(special) || specialHasProducts;
};
export var getAdjustedRecurringConfig = function getAdjustedRecurringConfig(_ref) {
  var recurringDays = _ref.days,
      endTime = _ref.endTime,
      startTime = _ref.startTime;
  var timezone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : LA_TIMEZONE;
  var dateFormat = 'yyyy-MM-DD';
  var timeFormat = 'hh:mm A';
  var dateTimeFormat = "".concat(dateFormat, " ").concat(timeFormat);
  var today = moment.tz(timezone).format(dateFormat);
  /**
   * The reason we need to include the date here is because for some reason without it, the time is created for
   * the next day in some cases, and thus will cause the specialIsActive result to be incorrect.
   */

  var specialStartToday = moment.tz("".concat(today, " ").concat(startTime), dateTimeFormat, timezone);
  var specialEndToday = moment.tz("".concat(today, " ").concat(endTime), dateTimeFormat, timezone);
  var startDays = recurringDays;
  var endDays = recurringDays; // if the end time is earlier than the start time,
  // that probably means the end time is on the next calendar day
  // OR it means the start time is on the previous calendar day
  // Example: enterprise has set a special to be 9:00 AM - 11:45 PM Pacific time
  // this would be 12:00 PM - 2:45 AM for an east coast dispensary.

  if (specialEndToday.isBefore(specialStartToday) && endTime && startTime) {
    // Dispensary is in a more western timezone than what the enterprise selected
    // move start days back
    if (specialStartToday.hour() > 12) {
      startDays = _map(startDays, function (day) {
        return day === 0 ? 6 : day - 1;
      });
      specialStartToday = specialStartToday.add(-1, 'd');
    } // Dispensary is in a more eastern timezone than what the enterprise selected
    // move end days forward
    else {
        endDays = _map(endDays, function (day) {
          return day === 6 ? 0 : day + 1;
        });
        specialEndToday = specialEndToday.add(1, 'd');
      }
  }

  return {
    endDays: endDays,
    endTime: specialEndToday,
    startDays: startDays,
    startTime: specialStartToday
  };
};

var recurringSpecialIsActiveNow = function recurringSpecialIsActiveNow(special) {
  var timezone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : LA_TIMEZONE;
  var now = moment();
  var _special$recurring3 = special.recurring,
      setEndDate = _special$recurring3.setEndDate,
      endDate = _special$recurring3.endDate;

  if (!setEndDate || now.isBefore(endDate)) {
    var _special$recurring4, _special$recurring5, _special$recurring6, _special$recurring7;

    var _getAdjustedRecurring = getAdjustedRecurringConfig(special.recurring, timezone),
        endDays = _getAdjustedRecurring.endDays,
        endTime = _getAdjustedRecurring.endTime,
        startDays = _getAdjustedRecurring.startDays,
        startTime = _getAdjustedRecurring.startTime;

    if (!_includes([].concat(_toConsumableArray(endDays), _toConsumableArray(startDays)), Number(now.format("d")))) {
      return false;
    } // Support for "open all day" if special was generated by LLx


    if (special.source === 'POS' && !((_special$recurring4 = special.recurring) !== null && _special$recurring4 !== void 0 && _special$recurring4.startTime) && !((_special$recurring5 = special.recurring) !== null && _special$recurring5 !== void 0 && _special$recurring5.endTime)) {
      return true;
    }

    if (!((_special$recurring6 = special.recurring) !== null && _special$recurring6 !== void 0 && _special$recurring6.startTime) || !((_special$recurring7 = special.recurring) !== null && _special$recurring7 !== void 0 && _special$recurring7.endTime)) {
      return false;
    }

    return now.isBetween(startTime, endTime);
  }

  return false;
};

export var specialIsActive = function specialIsActive(special) {
  var timezone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : LA_TIMEZONE;

  if (special.active) {
    if (specialIsRecurring(special)) {
      return recurringSpecialIsActiveNow(special, timezone);
    }

    if (!special.startStamp || !special.endStamp) {
      return false;
    }

    var now = moment();
    var nowIsSameOrAfterStart = now.isSameOrAfter(moment(special.startStamp));
    var nowIsSameOrBeforeEnd = now.isSameOrBefore(moment(special.endStamp));
    return nowIsSameOrAfterStart && nowIsSameOrBeforeEnd;
  }

  return false;
};
export var specialStarted = function specialStarted(special) {
  var now = moment();
  var stampToUse = getEarliestStartStamp(special);
  return now.isAfter(moment(stampToUse));
};
export var getCloseDate = function getCloseDate() {
  var hoursType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "pickupHours";
  var dispensary = arguments.length > 1 ? arguments[1] : undefined;
  var dispensaryTimezone = dispensary.timezone || LA_TIMEZONE;
  var today = days[new Date().getDay()];

  var hours = _get(dispensary, hoursType, "8:00 PM");

  return convertDate(hours[today].end, dispensaryTimezone);
};
export var getLatestOpenTime = function getLatestOpenTime(dispensary) {
  var lastPickupTime = getCloseDate("pickupHours", dispensary);
  var lastDeliveryTime = getCloseDate("deliveryHours", dispensary);
  return moment.max([lastPickupTime, lastDeliveryTime]);
};
export var addDays = function addDays(date, daysProp) {
  var result = new Date(date);
  result.setDate(result.getDate() + daysProp);
  return result;
};
export var specialIsPending = function specialIsPending(special) {
  var now = moment();
  var stampToUse = getEarliestStartStamp(special);
  return special.active && now.isBefore(stampToUse);
};
export var segmentCurrentAndPendingSpecials = function segmentCurrentAndPendingSpecials() {
  var specials = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return _reduce(specials, function (accumulator, special) {
    accumulator[specialIsPending(special) ? 1 : 0].push(special);
    return accumulator;
  }, [[], []]);
};
export var parseDates = function parseDates(special) {
  var endDay = special.endDay,
      endStamp = special.endStamp,
      schedule = special.schedule,
      startDay = special.startDay,
      startStamp = special.startStamp,
      isRecurring = special.isRecurring,
      recurring = special.recurring,
      version = special.version;
  var specialObj = special;

  if (version === 4) {
    _set(specialObj.schedule, "endStamp", schedule.endStamp && moment(schedule.endStamp, ["x", moment.ISO_8601]).toDate());

    _set(specialObj.schedule, "startStamp", schedule.startStamp && moment(schedule.startStamp, ["x", moment.ISO_8601]).toDate());
  }

  _set(specialObj, "endDay", endDay && moment(endDay, ["x", moment.ISO_8601]).toDate());

  _set(specialObj, "endStamp", endStamp && moment(endStamp, ["x", moment.ISO_8601]).toDate());

  _set(specialObj, "startDay", startDay && moment(startDay, ["x", moment.ISO_8601]).toDate());

  _set(specialObj, "startStamp", startStamp && moment(startStamp, ["x", moment.ISO_8601]).toDate());

  if (isRecurring && recurring !== null && recurring !== void 0 && recurring.setEndDate) {
    _set(specialObj, "recurring.endDate", moment(recurring === null || recurring === void 0 ? void 0 : recurring.endDate, ["x", moment.ISO_8601]).toDate());
  }

  return specialObj;
}; // Currently not used by anything, but if it is at some point, just uncomment and import!
// export const decodeBrandName = (brandName) =>
//   `${brandName}`
//     .replace('\\u002e', '.')
//     .replace('\\u0024', '$')
//     .replace('\\\\', '\\');
// Used because Mongo does not allow keys with \, $, or ., and encodeURI / encodeURIComponent does not encode all of
// those characters: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent

export var encodeBrandName = function encodeBrandName(brandName) {
  return !_isEmpty(brandName) ? "".concat(brandName).replace(/\//g, '\\\\').replace(/\$/g, "\\u0024").replace(/\./g, "\\u002e") : '';
};
export var isDiscountApplied = function isDiscountApplied(costBreakdown) {
  var _costBreakdown$receip3;

  var _costBreakdown$receip = costBreakdown === null || costBreakdown === void 0 ? void 0 : (_costBreakdown$receip3 = costBreakdown.receipt) === null || _costBreakdown$receip3 === void 0 ? void 0 : _costBreakdown$receip3.summary,
      _costBreakdown$receip2 = _costBreakdown$receip.discounts,
      discounts = _costBreakdown$receip2 === void 0 ? 0 : _costBreakdown$receip2;

  return discounts > 0;
};
export var compareProductWeightToConditionWeight = function compareProductWeightToConditionWeight(condition, option) {
  if (_isNil(condition.weight) || condition.weight === 'Any Weight' || condition.weight === 'N/A') {
    return true;
  }

  var conditionWeight = getProductWeight(condition.weight);
  var detailWeight = getProductWeight(option);
  return condition.weightOperator === 'greaterThan' ? detailWeight > conditionWeight : detailWeight === conditionWeight;
};
export var hasSaleSpecialForMenuType = function hasSaleSpecialForMenuType(_ref2) {
  var product = _ref2.product,
      medicalOrder = _ref2.medicalOrder,
      menuType = _ref2.menuType;
  var isMedicalOrder = !_isNil(medicalOrder) ? medicalOrder === true : menuType === 'med' || menuType === 'medical';

  if (isMedicalOrder) {
    return !_isEmpty(product.medicalSpecialPrices);
  }

  return !_isEmpty(product.recSpecialPrices);
};
export var specialMatchesMenuType = function specialMatchesMenuType(special, menuType) {
  return !special.menuType || special.menuType === 'both' || special.menuType === 'medical' && menuType === 'med' || special.menuType === 'recreational' && menuType === 'rec';
};
export var productHasSaleSpecial = function productHasSaleSpecial(_ref3) {
  var product = _ref3.product,
      medicalOrder = _ref3.medicalOrder,
      menuType = _ref3.menuType;
  var hasOriginalSaleSpecial = hasSaleSpecialForMenuType({
    product: product,
    medicalOrder: medicalOrder,
    menuType: menuType
  });
  var hasWeightedSpecial = productHasWeightOnSale(product);
  return hasOriginalSaleSpecial && hasWeightedSpecial;
};
export function productHasWeightOnSale(product) {
  var formattedOptions = product.formattedOptions;
  return _some(formattedOptions || [], function (productWeight) {
    var _product$specialData;

    return (_product$specialData = product.specialData) !== null && _product$specialData !== void 0 && _product$specialData.saleSpecials ? productSatisfiesSaleWeight(product, product.specialData.saleSpecials, productWeight) : true;
  });
}
export function productIsExcludedFromOffer(product, currentSpecialId) {
  var _product$specialData2, _currentSpecial$exclu, _currentSpecial$exclu2, _currentSpecial$exclu3, _currentSpecial$exclu4, _currentSpecial$exclu5, _currentSpecial$exclu6, _currentSpecial$items;

  var currentSpecial = _find(product === null || product === void 0 ? void 0 : (_product$specialData2 = product.specialData) === null || _product$specialData2 === void 0 ? void 0 : _product$specialData2.bogoSpecials, ['specialId', currentSpecialId]);

  var productIsExcludedFromConditionsAndRewards = (currentSpecial === null || currentSpecial === void 0 ? void 0 : (_currentSpecial$exclu = currentSpecial.excludedProducts) === null || _currentSpecial$exclu === void 0 ? void 0 : (_currentSpecial$exclu2 = _currentSpecial$exclu.conditions) === null || _currentSpecial$exclu2 === void 0 ? void 0 : _currentSpecial$exclu2.length) > 0 && (currentSpecial === null || currentSpecial === void 0 ? void 0 : (_currentSpecial$exclu3 = currentSpecial.excludedProducts) === null || _currentSpecial$exclu3 === void 0 ? void 0 : (_currentSpecial$exclu4 = _currentSpecial$exclu3.rewards) === null || _currentSpecial$exclu4 === void 0 ? void 0 : _currentSpecial$exclu4.length) > 0;
  var productIsExcludedFromConditionsAndIFAPEnabled = (currentSpecial === null || currentSpecial === void 0 ? void 0 : (_currentSpecial$exclu5 = currentSpecial.excludedProducts) === null || _currentSpecial$exclu5 === void 0 ? void 0 : (_currentSpecial$exclu6 = _currentSpecial$exclu5.conditions) === null || _currentSpecial$exclu6 === void 0 ? void 0 : _currentSpecial$exclu6.length) > 0 && (currentSpecial === null || currentSpecial === void 0 ? void 0 : (_currentSpecial$items = currentSpecial.itemsForAPrice) === null || _currentSpecial$items === void 0 ? void 0 : _currentSpecial$items.enabled);
  var excludedProductsExist = productIsExcludedFromConditionsAndRewards || productIsExcludedFromConditionsAndIFAPEnabled;

  if (excludedProductsExist) {
    var _currentSpecial$items2;

    var excludedProducts = currentSpecial.excludedProducts;
    var conditions = excludedProducts.conditions,
        rewards = excludedProducts.rewards; // if IFAP is enabled rewards exclusions will be undefined so we only check conditions
    // otherwise we only exclude products that are in both exclusion lists

    var excludedProductsToCheck = currentSpecial !== null && currentSpecial !== void 0 && (_currentSpecial$items2 = currentSpecial.itemsForAPrice) !== null && _currentSpecial$items2 !== void 0 && _currentSpecial$items2.enabled ? conditions : _intersectionBy(conditions, rewards, '_id');
    return _some(excludedProductsToCheck, function (_ref4) {
      var _id = _ref4._id;
      return product._id === _id;
    });
  }

  return false;
}
export var productHasAtLeastOneQualifyingOption = function productHasAtLeastOneQualifyingOption(product, offerId) {
  var _product$specialData3;

  var matchingOffer = _find((_product$specialData3 = product.specialData) === null || _product$specialData3 === void 0 ? void 0 : _product$specialData3.bogoSpecials, function (_ref5) {
    var specialId = _ref5.specialId;
    return specialId === offerId;
  });

  var qualifyingOptions = _filter(product.formattedOptions || product.Options, function (option) {
    return _includes(matchingOffer === null || matchingOffer === void 0 ? void 0 : matchingOffer.qualifyingOptions, option);
  });

  return qualifyingOptions.length > 0;
};
export function productIsPartOfActiveSpecial(product, menuType) {
  var _product$specialData4, _filter2, _product$specialData5;

  if (!((_product$specialData4 = product.specialData) !== null && _product$specialData4 !== void 0 && _product$specialData4.bogoSpecials)) {
    return false;
  }

  return ((_filter2 = _filter(((_product$specialData5 = product.specialData) === null || _product$specialData5 === void 0 ? void 0 : _product$specialData5.bogoSpecials) || [], function (special) {
    return specialMatchesMenuType(special, menuType);
  })) === null || _filter2 === void 0 ? void 0 : _filter2.length) > 0 && productQualifiesForAtLeastOneOffer(product.specialData.bogoSpecials, product);
}
export function productQualifiesForAtLeastOneOffer(offers, product) {
  return _some(offers, function (offer) {
    return !productIsExcludedFromOffer(product, offer === null || offer === void 0 ? void 0 : offer.specialId) && productHasAtLeastOneQualifyingOption(product, offer === null || offer === void 0 ? void 0 : offer.specialId);
  });
}
export function isNotSolitaryMinimumSpendOffer(offers) {
  return _some(offers, function (_ref6) {
    var totalSpend = _ref6.totalSpend;
    return !(totalSpend !== null && totalSpend !== void 0 && totalSpend.enabled);
  });
}
export var filterPastSpecials = function filterPastSpecials(special) {
  var _special$recurring8, _special$recurring9;

  // Analogous to "past" Mongo-based logic
  var now = moment().toDate();
  var basicFilters = special.active;
  var optionalFilters = special.complete || !special.isRecurring && special.endStamp < now || special.isRecurring && ((_special$recurring8 = special.recurring) === null || _special$recurring8 === void 0 ? void 0 : _special$recurring8.setEndDate) && ((_special$recurring9 = special.recurring) === null || _special$recurring9 === void 0 ? void 0 : _special$recurring9.endDate) < now;
  return basicFilters && optionalFilters;
};
export function filterProductsBasedOnSpecials(serializedProducts, pricingType, specialId, specialType) {
  return _filter(serializedProducts, function (product) {
    var _product$specialData6, _product$specialData7;

    if (_isEmpty(product === null || product === void 0 ? void 0 : product.specialData)) {
      return false;
    }

    var viewingIndividualSpecialsPage = !_isNil(specialId);

    var offerSpecialsMatchingMenu = _filter(((_product$specialData6 = product.specialData) === null || _product$specialData6 === void 0 ? void 0 : _product$specialData6.bogoSpecials) || [], function (special) {
      return specialMatchesMenuType(special, pricingType);
    });

    var saleSpecialsMatchingMenu = _filter(((_product$specialData7 = product.specialData) === null || _product$specialData7 === void 0 ? void 0 : _product$specialData7.saleSpecials) || [], function (special) {
      return specialMatchesMenuType(special, pricingType);
    });

    if (viewingIndividualSpecialsPage) {
      if (specialType === 'sale') {
        var productIsOnSale = productHasSaleSpecial({
          product: product,
          menuType: pricingType
        });
        return _some(saleSpecialsMatchingMenu, function (special) {
          return (special === null || special === void 0 ? void 0 : special.specialId) === specialId && productIsOnSale;
        });
      }

      var isNotExcluded = !productIsExcludedFromOffer(product, specialId);
      var productQualifies = productHasAtLeastOneQualifyingOption(product, specialId);
      return _some(offerSpecialsMatchingMenu, function (special) {
        return (special === null || special === void 0 ? void 0 : special.specialId) === specialId && isNotExcluded && productQualifies;
      });
    }

    var currentProductHasSaleSpecial = saleSpecialsMatchingMenu.length > 0 && productHasSaleSpecial({
      product: product,
      menuType: pricingType
    });
    var currentProductQualifiesForAtLeastOneOffer = offerSpecialsMatchingMenu.length > 0 && productQualifiesForAtLeastOneOffer(offerSpecialsMatchingMenu, product);
    return currentProductHasSaleSpecial || currentProductQualifiesForAtLeastOneOffer;
  });
}
export var sortSpecialCards = function sortSpecialCards(specials) {
  return _sortBy(specials, ['displayRank', 'endStamp', "recurring.endDate"]);
};
export var getSpecialsBasePath = function getSpecialsBasePath(_ref7) {
  var dispensaryId = _ref7.dispensaryId,
      enterpriseId = _ref7.enterpriseId,
      scope = _ref7.scope;
  var isEnterpriseSpecial = scope === 'enterprise';
  var dispensaryOrEnterpriseId = isEnterpriseSpecial && enterpriseId ? enterpriseId : dispensaryId;
  var dispensaryOrEnterpriseText = isEnterpriseSpecial && enterpriseId ? 'enterprise' : 'dispensaries';
  return "/".concat(dispensaryOrEnterpriseText, "/").concat(dispensaryOrEnterpriseId, "/promote/specials");
};
export var getEditUrl = function getEditUrl(_ref8) {
  var specialId = _ref8._id,
      enterpriseSpecialId = _ref8.enterpriseSpecialId,
      special = _objectWithoutProperties(_ref8, _excluded);

  return "".concat(getSpecialsBasePath(special), "/edit/").concat(enterpriseSpecialId || specialId);
};
export var getCurrentStepIndex = function getCurrentStepIndex(isReadOnly, SpecialsState) {
  var stepIndex = 1;

  if (isReadOnly) {
    var specialSteps = _reduce(SpecialsState.completedSteps, function (result, value, key) {
      if (value) {
        result.push(key);
      }

      return result;
    }, []);

    stepIndex = specialSteps.length - 1;
  } // This is used to reset the index to 0 when customizing Discount SYnc POS Sales,
  // since we only show one step, Menu Design


  if (SpecialsState.isPOSSpecial) {
    stepIndex = 0;
  }

  return stepIndex;
};
export var getEarliestStartStamp = function getEarliestStartStamp(special) {
  if (special.earliestStartStamp) {
    return special.earliestStartStamp;
  }

  return special.startStamp;
};
export var includeSettingsStep = function includeSettingsStep(_ref9) {
  var globalSpecialsSettings = _ref9.globalSpecialsSettings,
      special = _ref9.special;
  return globalSpecialsSettings.enableIndividualDiscountStacking || globalSpecialsSettings.enableIndividualSpecialPrecedence || !_isNil(special.discountStacking) || !_isNil(special.discountPrecedence);
};
export var isSettingsStepComplete = function isSettingsStepComplete(special) {
  return !_isNil(special.discountStacking) || !_isNil(special.discountPrecedence);
};
export var removePOSOnlyFieldsFromBogo = function removePOSOnlyFieldsFromBogo(special) {
  var bogoConditions = special.bogoConditions,
      bogoRewards = special.bogoRewards;
  var fieldsToOmit = ['requiresSingleSku', 'totalQuantity', 'totalWeight', 'totalSpend', 'discountAmount', 'discountType', 'applicationRules'];
  special.bogoConditions = _map(bogoConditions, function (condition) {
    return _omit(condition, fieldsToOmit);
  });
  special.bogoRewards = _map(bogoRewards, function (reward) {
    return _omit(reward, fieldsToOmit);
  });
};
export var productSatisfiesSaleWeight = function productSatisfiesSaleWeight(product) {
  var saleSpecials = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var formattedWeight = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "N/A";
  var withSpecialData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  // If there are no sale specials, return early
  if (saleSpecials === null || (saleSpecials === null || saleSpecials === void 0 ? void 0 : saleSpecials.length) < 1) {
    return withSpecialData ? {
      appliedSpecials: [],
      result: false
    } : false;
  }

  var _reduce2 = _reduce(saleSpecials, function (result, special) {
    if (!_isEmpty(special === null || special === void 0 ? void 0 : special.saleDiscounts)) {
      result.threeDotFiveSales.push(special); // 3.5 special
    } else if (_values(special === null || special === void 0 ? void 0 : special.specialRestrictions).length > 0) {
      result.salesWithRestrictions.push(special); // < 3.5 special
    } else {
      result.salesWithoutRestrictions.push(special); // < 3.5 special
    }

    return result;
  }, {
    threeDotFiveSales: [],
    salesWithRestrictions: [],
    salesWithoutRestrictions: []
  }),
      salesWithRestrictions = _reduce2.salesWithRestrictions,
      salesWithoutRestrictions = _reduce2.salesWithoutRestrictions,
      threeDotFiveSales = _reduce2.threeDotFiveSales; // If we have a < 3.5 sale without specialRestrictions, then the sale can apply as normal


  if (salesWithoutRestrictions.length > 0) {
    return withSpecialData ? {
      appliedSpecials: _toConsumableArray(saleSpecials),
      result: true
    } : true;
  }

  var appliedSpecials = [];
  var formattedWeightInGrams = getProductWeight(formattedWeight);
  var hasApplicableSpecial; // For 3.5 sales

  if (threeDotFiveSales.length > 0) {
    _forEach(threeDotFiveSales, function (special) {
      var _special$eligibleProd, _special$saleDiscount2;

      // 3.5 sales should already have this property after calculation in the serializer
      var eligibleProductOptions = (_special$eligibleProd = special === null || special === void 0 ? void 0 : special.eligibleProductOptions) !== null && _special$eligibleProd !== void 0 ? _special$eligibleProd : eligibleProductOptionsForRestriction(product, (_special$saleDiscount2 = special.saleDiscounts) === null || _special$saleDiscount2 === void 0 ? void 0 : _special$saleDiscount2[0], 'inclusion', special, special.useActiveBatchTags);

      _forEach(eligibleProductOptions, function (productOption) {
        // Try to match on the string option itself first before weight conversion
        if (formattedWeight === productOption || formattedWeightInGrams === getProductWeight(productOption)) {
          appliedSpecials.push({
            specialId: special._id,
            weight: productOption
          });
          return false;
        }

        return true; // consistent return
      });
    });
  } // For < 3.5 sales with specialRestrictions


  if (salesWithRestrictions.length > 0) {
    var salesType = getSalesType(product);

    var specialRestrictions = _map(salesWithRestrictions, function (s, i) {
      return _defineProperty({}, s._id || s.specialId || i, s.specialRestrictions);
    });

    var hasMatchingRestriction = false;

    _forEach(specialRestrictions, function (special) {
      var specialId = _keys(special)[0];

      var restrictions = Object.entries(_values(special)[0] || {});

      _forEach(restrictions, function (_ref11) {
        var _key$split;

        var _ref12 = _slicedToArray(_ref11, 2),
            key = _ref12[0],
            condition = _ref12[1];

        // if we found a matching restriction, see if this product weight applies
        var enterpriseKey = key !== null && key !== void 0 && key.startsWith('EPID_') ? (_key$split = key.split('EPID_')) === null || _key$split === void 0 ? void 0 : _key$split[1] : key;

        if (_includes(salesType, key) || _includes(salesType, enterpriseKey)) {
          hasMatchingRestriction = true;

          var weights = _map(condition.weights || [condition.weight], function (conditionWeight) {
            return getProductWeight(conditionWeight);
          });

          if (saleSatisfiesWeightCondition(formattedWeightInGrams, condition.weightOperator, weights)) {
            if (!_includes(appliedSpecials, specialId)) {
              appliedSpecials.push({
                specialId: specialId,
                weight: condition.weight
              });
            }
          }
        }
      });
    });

    hasApplicableSpecial = appliedSpecials.length > 0 || !hasMatchingRestriction;
  } else {
    hasApplicableSpecial = appliedSpecials.length > 0;
  }

  return withSpecialData ? {
    appliedSpecials: appliedSpecials,
    result: hasApplicableSpecial
  } : hasApplicableSpecial;
};
export var getSalesType = function getSalesType(product) {
  var _product$brand, _product$brand2, _product$enterprisePr;

  return [product.productBrandId || product.brandId || (product === null || product === void 0 ? void 0 : (_product$brand = product.brand) === null || _product$brand === void 0 ? void 0 : _product$brand.id) || product.productBrandName || product.brandName || (product === null || product === void 0 ? void 0 : (_product$brand2 = product.brand) === null || _product$brand2 === void 0 ? void 0 : _product$brand2.name), product.productCategory || product.type || product.category, product.productSubcategory || product.subcategory, product._id || product.id, !product.subcategory ? "uncategorized_".concat(product.productCategory || product.type || product.category) : null, (_product$enterprisePr = product === null || product === void 0 ? void 0 : product.enterpriseProductId) !== null && _product$enterprisePr !== void 0 ? _product$enterprisePr : null];
};
export var getSpecialBadgeType = function getSpecialBadgeType() {
  var product = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var saleSpecials = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var weights = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var satisfies = [];
  var appliedSpecials = [];

  _forEach(weights, function (weight) {
    var data = productSatisfiesSaleWeight(product, saleSpecials, weight, true);

    if (data.result) {
      satisfies.push(true);

      _forEach(data.appliedSpecials, function (special) {
        if (special !== null && special !== void 0 && special.specialId && !_find(appliedSpecials, ["specialId", special.specialId])) {
          appliedSpecials.push(_objectSpread({}, special));
        }
      });
    } else {
      satisfies.push(false);
    }
  });

  var uniqueWeights = _map(appliedSpecials, "weight");

  if (_every(satisfies, Boolean) && (uniqueWeights.length === 0 || satisfies.length === uniqueWeights.length)) {
    return "pill";
  }

  if (_some(satisfies, Boolean)) {
    return "label";
  }

  return "none";
};
export var getProductKeyFromRule = function getProductKeyFromRule(rule) {
  var _ref13, _ref14, _ref15, _rule$enterpriseProdu, _rule$enterpriseProdu2, _rule$productIds;

  return (_ref13 = (_ref14 = (_ref15 = (_rule$enterpriseProdu = rule === null || rule === void 0 ? void 0 : (_rule$enterpriseProdu2 = rule.enterpriseProductIds) === null || _rule$enterpriseProdu2 === void 0 ? void 0 : _rule$enterpriseProdu2[0]) !== null && _rule$enterpriseProdu !== void 0 ? _rule$enterpriseProdu : rule === null || rule === void 0 ? void 0 : rule.enterpriseProductId) !== null && _ref15 !== void 0 ? _ref15 : rule === null || rule === void 0 ? void 0 : (_rule$productIds = rule.productIds) === null || _rule$productIds === void 0 ? void 0 : _rule$productIds[0]) !== null && _ref14 !== void 0 ? _ref14 : rule === null || rule === void 0 ? void 0 : rule.productId) !== null && _ref13 !== void 0 ? _ref13 : rule._id;
}; // item accepts either a product object, cart-item store object, or breakdownItem object from cart calculations

export var formatDiscountForDisplay = function formatDiscountForDisplay() {
  var _workableItem, _workableItem2, _workableItem3, _workableItem3$specia, _ref16, _workableItem4, _workableItem5;

  var originalPrice = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var discountedPrice = arguments.length > 1 ? arguments[1] : undefined;
  var item = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var type = arguments.length > 3 ? arguments[3] : undefined;
  var weightOverride = arguments.length > 4 ? arguments[4] : undefined;
  var // For specific sale weights, otherwise it's derived from the item itself
  isMobile = arguments.length > 5 ? arguments[5] : undefined;

  if (_isNil(discountedPrice)) {
    return null; // Return early if there is no discount
  }

  var startingPrice = parseFloat("".concat(originalPrice));
  var reducedPrice = parseFloat("".concat(discountedPrice));

  if (_isNaN(startingPrice) || _isNaN(reducedPrice)) {
    return null; // Return early if startingPrice or reducedPrice are NaN
  }

  reducedPrice = reducedPrice <= 0 ? 0 : reducedPrice;

  if (reducedPrice >= startingPrice) {
    return null; // Return early if reducedPrice would result in inverse discount
  }

  var formattedDiscount = 'Discounted!';
  var workableItem = item; // If item is a cart-item store object, grab the breakdownItem

  if (_isFunction((_workableItem = workableItem) === null || _workableItem === void 0 ? void 0 : _workableItem.getBreakdownItem)) {
    workableItem = workableItem.getBreakdownItem();
  }

  var bogoSavings = (_workableItem2 = workableItem) === null || _workableItem2 === void 0 ? void 0 : _workableItem2.bogoSavings;
  var saleSpecials = (_workableItem3 = workableItem) === null || _workableItem3 === void 0 ? void 0 : (_workableItem3$specia = _workableItem3.specialData) === null || _workableItem3$specia === void 0 ? void 0 : _workableItem3$specia.saleSpecials;
  var weight = (_ref16 = weightOverride !== null && weightOverride !== void 0 ? weightOverride : (_workableItem4 = workableItem) === null || _workableItem4 === void 0 ? void 0 : _workableItem4.option) !== null && _ref16 !== void 0 ? _ref16 : (_workableItem5 = workableItem) === null || _workableItem5 === void 0 ? void 0 : _workableItem5.Option;
  var formatInDollars;

  if (type === 'bogo' || !_isEmpty(bogoSavings)) {
    // Since offers are conditional and always displayed on the cart or at checkout, we don't have to concern ourselves
    // with weights / menu display elsewhere and can rely on the receipt data alone
    formatInDollars = _some(bogoSavings === null || bogoSavings === void 0 ? void 0 : bogoSavings.individual, function (discount) {
      return !discount.displayAsPercentDiscount && (discount.dollarDiscount || discount.targetPrice);
    });
    var suffix = isMobile ? 'discount!' : 'discount applied!';
    formattedDiscount = formatInDollars ? "$".concat((startingPrice - reducedPrice).toFixed(2), " ").concat(suffix) : "".concat(((1 - reducedPrice / startingPrice) * 100.0).toFixed(0), "% ").concat(suffix);
  } else if (type === 'sale' || !_isEmpty(saleSpecials)) {
    formatInDollars = _some(saleSpecials, function (special) {
      if (weight) {
        return productSatisfiesSaleWeight(workableItem, [special], weight) && (special.dollarDiscount || special.targetPrice);
      }

      return special.dollarDiscount || special.targetPrice;
    });
    formattedDiscount = formatInDollars ? "$".concat((startingPrice - reducedPrice).toFixed(2), " off") : "".concat(((1 - reducedPrice / startingPrice) * 100.0).toFixed(0), "% off");
  }

  return formattedDiscount;
};
export var specialsLogger = function specialsLogger(_ref17) {
  var logger = _ref17.logger,
      data = _ref17.data,
      _ref17$level = _ref17.level,
      level = _ref17$level === void 0 ? 'debug' : _ref17$level;
  var calculationsLogger = logger !== null && logger !== void 0 ? logger : console;

  switch (level) {
    case 'debug':
      calculationsLogger.debug(data);
      break;

    case 'info':
      calculationsLogger.info(data);
      break;

    case 'warn':
      calculationsLogger.warn(data);
      break;

    case 'error':
      calculationsLogger.error(data);
      break;

    default:
      calculationsLogger.debug(data);
  }
};
export * from './specials/excluded-products';
export { default as excludedProducts } from './specials/excluded-products';