import React from 'react';
import styled from 'styled-components';

const TabLabel = ({ icon: Icon, label }) => (
  <StyledLabel>
    {Icon && <Icon />}
    {label}
  </StyledLabel>
);

export default TabLabel;

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;

  > svg {
    margin-right: 19px;
  }
`;
