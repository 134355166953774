import React from 'react';
import styled, { css } from 'styled-components';

import useTranslation from 'hooks/use-translation';

import DeliveryIcon from 'assets/delivery-icon';
import { heading5, heading4, heading3, paragraph, subparagraph, smallSubtext } from 'components/core/typography';
import { DesktopOnly, MobileOnly } from 'shared/components';

export function DispensaryOrderingDetails({
  deliveryFee,
  highInMinutes,
  isOpen,
  isPickup,
  lowInMinutes,
  milesFromUser,
  dispensaryName,
  offeringString,
  showEstimates,
  showDeliveryFee,
  showFreeDeliveryIcon,
  variant = `default`,
}): JSX.Element {
  const { t } = useTranslation();

  const VARIANT_STYLE_MAP = {
    default: {
      offeringInfo: css`
        ${heading5};
      `,
      name: css`
        min-height: 24px;
        ${heading3};
      `,
      serviceInfo: css`
        ${paragraph};
      `,
    },
    small: {
      offeringInfo: css`
        ${smallSubtext};
      `,
      name: css`
        ${heading4};
        line-height: 19px;
      `,
      serviceInfo: css`
        ${subparagraph};
      `,
    },
    xs: {
      offeringInfo: css`
        ${smallSubtext};
        line-height: 15px;
      `,
      name: css`
        ${paragraph};
        line-height: 13px;
      `,
      serviceInfo: css`
        font-size: 10px;
        line-height: 10px;
      `,
    },
  };

  return (
    <div>
      <Name variantStyles={VARIANT_STYLE_MAP[variant].name}>{dispensaryName}</Name>
      <OfferingInfoContainer variantStyles={VARIANT_STYLE_MAP[variant].serviceInfo} className='offering-info-container'>
        <span>
          {offeringString}
          <Bullet />
          <DesktopOnly>
            {milesFromUser === 1
              ? t('dispensary.mileAway', '1 Mile away')
              : t('dispensary.milesAway', '{{milesFromUser}} Miles away', { milesFromUser })}
          </DesktopOnly>
          <MobileOnly>
            {milesFromUser === 1
              ? t('dispensary.mile', '1 Mile')
              : t('dispensary.miles', '{{milesFromUser}} Miles', { milesFromUser })}
          </MobileOnly>
        </span>
      </OfferingInfoContainer>
      {isOpen && (
        <ServiceInfoContainer variantStyles={VARIANT_STYLE_MAP[variant].serviceInfo} className='service-info-container'>
          {showDeliveryFee && <span>{t(`{{ deliveryFee }} delivery fee `, { deliveryFee })}</span>}
          {showFreeDeliveryIcon && (
            <FreeDeliveryContainer>
              <StyledDeliveryIcon />
              <FreeDelivery>{t('common.freeDelivery', 'free delivery')}</FreeDelivery>
            </FreeDeliveryContainer>
          )}
          {showEstimates && (
            <>
              {(showDeliveryFee || showFreeDeliveryIcon) && <Bullet />}
              <span>
                <DesktopOnly>
                  {isPickup &&
                    t('deliveryInfo.readyInMins', `Ready in {{lowInMinutes}}-{{highInMinutes}} {{ timeUnit }}`, {
                      lowInMinutes,
                      highInMinutes,
                      timeUnit: 'mins',
                    })}
                  {!isPickup &&
                    t('deliveryInfo.estimateInfo', `{{ lowInMinutes }}-{{ highInMinutes }} {{ timeUnit }}`, {
                      lowInMinutes,
                      highInMinutes,
                      timeUnit: 'mins',
                    })}
                </DesktopOnly>
                <MobileOnly>
                  {isPickup &&
                    t('deliveryInfo.estimateInfo', `{{ lowInMinutes }}-{{ highInMinutes }} {{ timeUnit }}`, {
                      lowInMinutes,
                      highInMinutes,
                      timeUnit: 'mins',
                    })}
                  <span>
                    {!isPickup &&
                      t('deliveryInfo.estimateInfo', `{{ lowInMinutes }}-{{ highInMinutes }} {{ timeUnit }}`, {
                        lowInMinutes,
                        highInMinutes,
                        timeUnit: 'mins',
                      })}
                  </span>
                </MobileOnly>
              </span>
            </>
          )}
        </ServiceInfoContainer>
      )}
    </div>
  );
}

const Bullet = (): JSX.Element => <>&nbsp;•&nbsp;</>;

const Name = styled.p`
  font-weight: 700;
  ${({ theme: { breakpoints, colors }, variantStyles }) => css`
    color: ${colors.primaryBlack};
    ${variantStyles}
    ${breakpoints.down(`xs`)} {
      min-height: 19px;
      ${heading4};
    }
  `}
`;

const ServiceInfoContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  height: 15px;
  margin-top: 5px;
  ${({ theme: { breakpoints, colors }, variantStyles }) => css`
    color: ${colors.grey[45]};
    ${variantStyles};
    ${breakpoints.down(`xs`)} {
      ${subparagraph}
      height: 18px;
    }
  `}
`;

const StyledDeliveryIcon = styled(DeliveryIcon)`
  margin-right: 6px;
  height: 17px;
  ${({ theme: { breakpoints } }) => breakpoints.down(`xs`)} {
    height: 15px;
  }
`;

const FreeDeliveryContainer = styled.span`
  display: flex;
  align-items: center;
`;

const FreeDelivery = styled.span`
  color: ${({ theme: { colors } }) => colors.primaryOrange};
`;

const OfferingInfoContainer = styled.div`
  display: flex;
  align-items: center;
  ${heading5}
  font-weight: normal;
  height: 25px;
  span {
    display: inherit;
  }
  ${({ theme: { breakpoints, colors }, variantStyles }) => css`
    ${variantStyles};
    color: ${colors.grey[30]};
    ${breakpoints.down(`xs`)} {
      ${paragraph}
      height: fit-content;
      margin-top: 4px;
      flex-wrap: wrap;
      display: inline;
    }
  `}
`;
