import React from 'react';
import styled from 'styled-components';

import Wrapper from 'components/wrapper';
import MetaTags from 'components/meta-tags';

export function LayoutWrapper(props): JSX.Element {
  const { children, ...otherProps } = props;

  return (
    <Container {...otherProps}>
      <MetaTags {...props} />
      {children}
    </Container>
  );
}

const Container = styled(Wrapper)`
  width: 100%;
  height: unset;

  .layout-wrapper {
    min-height: 600px;
  }
`;
