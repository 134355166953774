/**
 * e.g.
 *
 *  PayloadHandlers<{
 *    doApple: Apple;
 *    doBanana: Banana;
 *  }>
 *
 * becomes
 *
 *  {
 *    doApple: (payload: Apple) => void;
 *    doBanana: (payload: Banana) => void;
 *  }
 */
export type PayloadHandlers<Payload, Context> = {
  [Property in keyof Payload]: (payload: Payload[Property], context: Context) => void;
};

export type Node<Payload, Context> = PayloadHandlers<Payload, Context> & {
  onContextChange?: (change: Partial<Context>, newContext: Context, oldContext: Context) => void;
};

export type ContextSetter<Context extends Record<string, unknown>> = {
  setContext(context: Partial<Context>): void;
};

/**
 * e.g.
 *
 *  const fanOutFn: FanOutFn<{
 *    doApple: Apple;
 *    doBanana: Banana;
 *  }> = ...;
 *
 *  fanOutFn('doApple'); // returns (payload: Apple) => void
 */
export type FanOutFn<Payload> = <Property extends keyof Payload>(
  method: Property
) => (payload: Payload[Property]) => void;

/**
 * Creates an object that fans out method calls to each of the payload-handling nodes.
 *
 * E.g.
 * // additional/contextual information passed through with each call
 * type AppleBananaContext = { grocerName: string };
 * // can be inferred by createFanOut, but put here for completeness
 * type AppleBananaHandler = PayloadHandlers<{ doApple: Apple, doBanana: Banana }, AppleBananaContext>;
 *
 * const node1: AppleBananaHandler = {
 *   doApple: (apple: Apple, context: AppleBananaContext) => void,
 *   doBanana: (banana: Banana, context: AppleBananaContext) => void,
 * };
 *
 * const node2: AppleBananaHandler = {
 *   doApple: (apple: Apple, context: AppleBananaContext) => void,
 *   doBanana: (banana: Banana, context: AppleBananaContext) => void,
 * };
 *
 * const onAllNodes = createFanOut((fanOut): AppleBananaHandler => ({
 *   doApple: fanOut('doApple'),
 *   doBanana: fanOut('doBanana'),
 * }), { grocerName: 'Green Fingers' }, [node1, node2]);
 *
 * const a1 = new Apple();
 *
 * // calls node1.doApple(a1, { grocerName: 'Green Fingers' }) and node2.doApple(a1, { grocerName: 'Green Fingers' })
 * onAllNodes.doApple(a1);
 *
 * // updates context passed to future calls (and calls onContextChange fn if present on a node)
 * onAllNodes.setContext({ grocerName: 'Green Toes' });
 */
export const createFanOut = <MethodPayloads, Context extends Record<string, unknown>>(
  factory: (fanOutFn: FanOutFn<MethodPayloads>) => PayloadHandlers<MethodPayloads, void>,
  initialContext: Context,
  nodes: Node<MethodPayloads, Context>[]
): ContextSetter<Context> & PayloadHandlers<MethodPayloads, void> => {
  let context: Context = initialContext;
  const fanOut: FanOutFn<MethodPayloads> = (method) => (payload) => {
    nodes.forEach((node) => {
      try {
        node[method].bind(node)(payload, context);
      } catch (e) {
        console.error('Tracker Error when handling %s:', method, e);
      }
    });
  };
  const methodPayloads = factory(fanOut);
  return {
    ...methodPayloads,
    setContext(updates: Partial<Context>) {
      const mergedContext = { ...context, ...updates };
      nodes.forEach((node) => {
        try {
          node.onContextChange?.(updates, mergedContext, context);
        } catch (e) {
          console.error('Tracker Error when handling context change:', e);
        }
      });
      context = mergedContext;
    },
  };
};
