import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { CANCEL, CONTINUE, DESCRIPTION, NOW_SHOPPING } from './shopping-with.constants';
import { ShoppingWithProps } from './shopping-with.types';

import {
  Button,
  DialogBanner,
  DialogContainer,
  DialogHeading,
  DialogImage,
  DialogText,
  TextButton,
} from '../components/simple-dialog';

export const ShoppingWith = ({
  buttonSize,
  dispensary,
  handleContinue,
  onClose,
}: ShoppingWithProps): JSX.Element | null => {
  const { t } = useTranslation();

  const { logoImage, name } = dispensary;

  return (
    <>
      <StyledDialogContainer>
        <DialogBanner>{t(NOW_SHOPPING.key, NOW_SHOPPING.default)}</DialogBanner>

        {logoImage && <StyledDialogImage src={logoImage} alt={name} />}

        <DialogHeading>{name}</DialogHeading>

        <DialogText>{t(DESCRIPTION.key, DESCRIPTION.default)}</DialogText>

        <Button size={buttonSize} onClick={handleContinue} autoFocus>
          {t(CONTINUE.key, CONTINUE.default)}
        </Button>
        <TextButton onClick={onClose}>{t(CANCEL.key, CANCEL.default)}</TextButton>
      </StyledDialogContainer>
    </>
  );
};

const StyledDialogContainer = styled(DialogContainer)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 15px;
  }
`;

const StyledDialogImage = styled(DialogImage)`
  max-height: 125px;
`;
