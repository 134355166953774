import React, { forwardRef } from 'react';
import styled from 'styled-components';

import useCart from 'hooks/use-cart';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';

import { useModals } from 'components/modals';
import { ModalSecondaryHeader, ModalCopy, ModalButton } from 'shared/modals';
import { ModalExitIcon, ModalContainer } from 'components/core';
import { randomFunCannabisFacts } from 'shared/helpers/utils';

export const EmptyCartModal = forwardRef(({ onClose, containerProps, data }, ref) => {
  const { t } = useTranslation();
  const Cart = useCart();
  const UI = useUI();
  const Modals = useModals();

  const handleContinue = () => {
    const cName = Cart?.dispensary?.cName;
    Cart.setAutoCheckoutAfterBankLinkReconnect(false);
    if (UI.isPlus) {
      const plusDefaultReturnUrl = Cart?.order?.dispensary?.embedBackUrl;
      if (data?.clearLastServerCheckoutItem) {
        data.clearLastServerCheckoutItem();
      }

      if (!plusDefaultReturnUrl) {
        window.history.back();
      }

      window.location.href = `${plusDefaultReturnUrl}`;
    } else {
      Cart.clearOrder();
      Modals.closeModal();
      UI.showMenu(cName, true);
    }
  };

  return (
    <StyledModalContainer maxWidth={505} ref={ref} {...containerProps}>
      <ModalSecondaryHeader>{t('emptyCart.noMoreItems', 'There are no more items in your cart')}</ModalSecondaryHeader>
      <StyledModalCopy maxWidth={350}>
        {t('emptyCart.funFactPrefix', 'Fun Fact: ')}
        <strong>
          {t('emptyCart.funFact', {
            defaultValue: '{{randomFact}}',
            randomFact: randomFunCannabisFacts(),
          })}
        </strong>
      </StyledModalCopy>
      <ModalButton width='auto' onClick={handleContinue}>
        {t('emptyCart.keepShopping', 'Keep Shopping')}
      </ModalButton>
      <ModalExitIcon onClick={onClose} />
    </StyledModalContainer>
  );
});

const StyledModalCopy = styled(ModalCopy)`
  margin: 0 auto;
`;

const StyledModalContainer = styled(ModalContainer)`
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    padding-top: 60px;
    width: 100%;
    max-width: 100%;
  }
`;
