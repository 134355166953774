import { observer } from 'mobx-react-lite';

import { withCompositeHook } from 'src/hoc';
import { withModalControlsHook } from 'components/modals/with-modal-controls-hook';

import { AddToCart } from './add-to-cart';
import { AddToCartDataParam, AddToCartModalProps, UseAddToCartControlsReturn } from './add-to-cart.types';
import { useAddToCartControls } from './use-add-to-cart-controls';

const AddToCartController = observer(
  withCompositeHook<AddToCartModalProps, UseAddToCartControlsReturn>(AddToCart, useAddToCartControls)
);

export const [AddToCartModalController, useAddToCartModal] = withModalControlsHook<AddToCartDataParam>(
  AddToCartController
);
