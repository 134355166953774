import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { focusStyles } from 'src/theme';
import { Card } from 'components/core';

const productCardContainerStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 19px 16px;
  width: 100%;
`;

export const Item = styled.div`
  padding: ${({ gutter }) => `10px ${String(gutter)}px 0 0`};
  margin-right: auto;

  /* The last child of the container is the IO watcher div, so we need to target the second-to-last */
  &:nth-last-child(2) {
    padding-right: 15px;
  }

  .focus-visible {
    ${focusStyles}
  }
`;

export const Container = styled(Card)`
  ${productCardContainerStyles}
`;

export const ChipContainer = styled.div`
  padding-top: 5px;
  ${space}
`;

export const PriceSection = styled.div`
  color: ${({ primaryColor }) => primaryColor};
  font-size: 14px;
  line-height: 15px;
  padding-bottom: 7px;
`;

export const StyledContainer = styled(Container)`
  display: block;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.1);
  padding: ${({ dimensions }) => `${String(dimensions.padding)}px`};

  &:hover {
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.2);
  }
`;
