/* eslint-disable max-len */
import React from 'react';

function StoreInfoPaymentIcon(props) {
  return (
    <svg width='36' height='24' viewBox='0 0 36 24' fill='none' {...props}>
      <path
        d='M8.57365 0H34.3303C34.8086 0 35.2 0.383124 35.2 0.851387V15.2256C35.2 15.6939 34.8086 16.077 34.3303 16.077H8.57365C8.09533 16.077 7.70398 15.6939 7.70398 15.2256V0.851387C7.70398 0.383124 8.09533 0 8.57365 0Z'
        fill='white'
      />
      <path
        d='M34.3303 0H8.57365C8.09533 0 7.70398 0.383124 7.70398 0.851387V15.2256C7.70398 15.6939 8.09533 16.077 8.57365 16.077H34.3303C34.8086 16.077 35.2 15.6939 35.2 15.2256V0.851387C35.2 0.383124 34.8086 0 34.3303 0Z'
        fill='#EAEFF2'
      />
      <path
        d='M34.3303 0H8.57365C8.09533 0 7.70398 0.383124 7.70398 0.851387V15.2256C7.70398 15.6939 8.09533 16.077 8.57365 16.077H34.3303C34.8086 16.077 35.2 15.6939 35.2 15.2256V0.851387C35.2 0.383124 34.8086 0 34.3303 0ZM33.4606 14.3743H9.44331V1.70277H33.4606V14.3743Z'
        fill='#64737F'
      />
      <path
        d='M4.3715 3.96143H30.1281C30.6064 3.96143 30.9978 4.34455 30.9978 4.81281V19.1871C30.9978 19.6553 30.6064 20.0385 30.1281 20.0385H4.3715C3.89318 20.0385 3.50183 19.6553 3.50183 19.1871V4.81281C3.50183 4.34455 3.89318 3.96143 4.3715 3.96143Z'
        fill='white'
      />
      <path
        d='M30.1281 3.96143H4.3715C3.89318 3.96143 3.50183 4.34455 3.50183 4.81281V19.1871C3.50183 19.6553 3.89318 20.0385 4.3715 20.0385H30.1281C30.6064 20.0385 30.9978 19.6553 30.9978 19.1871V4.81281C30.9978 4.34455 30.6064 3.96143 30.1281 3.96143Z'
        fill='#EAEFF2'
      />
      <path
        d='M30.1281 3.96143H4.3715C3.89318 3.96143 3.50183 4.34455 3.50183 4.81281V19.1871C3.50183 19.6553 3.89318 20.0385 4.3715 20.0385H30.1281C30.6064 20.0385 30.9978 19.6553 30.9978 19.1871V4.81281C30.9978 4.34455 30.6064 3.96143 30.1281 3.96143ZM29.2584 18.3357H5.24116V5.6642H29.2584V18.3357Z'
        fill='#64737F'
      />
      <path
        d='M0.869667 7.92297H26.6263C27.1046 7.92297 27.496 8.3061 27.496 8.77436V23.1486C27.496 23.6169 27.1046 24 26.6263 24H0.869667C0.39135 24 0 23.6169 0 23.1486V8.77436C0 8.3061 0.39135 7.92297 0.869667 7.92297Z'
        fill='white'
      />
      <path
        d='M26.6263 7.92297H0.869667C0.39135 7.92297 0 8.3061 0 8.77436V23.1486C0 23.6169 0.39135 24 0.869667 24H26.6263C27.1046 24 27.496 23.6169 27.496 23.1486V8.77436C27.496 8.3061 27.1046 7.92297 26.6263 7.92297ZM25.7566 22.2972H1.73933V9.62575H25.7566V22.2972Z'
        fill='#64737F'
      />
      <path
        d='M5.85624 16.7917H8.01592C8.49424 16.7917 8.88559 16.4086 8.88559 15.9403C8.88559 15.472 8.49424 15.0889 8.01592 15.0889H5.85624C5.37792 15.0889 4.98657 15.472 4.98657 15.9403C4.98657 16.4086 5.37068 16.7917 5.85624 16.7917Z'
        fill='#64737F'
      />
      <path
        d='M19.4805 16.7917H21.6402C22.1185 16.7917 22.5099 16.4086 22.5099 15.9403C22.5099 15.472 22.1185 15.0889 21.6402 15.0889H19.4805C19.0022 15.0889 18.6108 15.472 18.6108 15.9403C18.6108 16.4086 19.0022 16.7917 19.4805 16.7917Z'
        fill='#64737F'
      />
      <path
        d='M13.0673 16.8059H14.4298C14.7704 16.8059 15.0531 17.0755 15.0531 17.4161C15.0531 17.7566 14.7777 18.0262 14.4298 18.0262H11.9875C11.5092 18.0262 11.1178 18.4093 11.1178 18.8776C11.1178 19.3459 11.5092 19.729 11.9875 19.729H12.8789V20.2895C12.8789 20.7577 13.2702 21.1409 13.7486 21.1409C14.2269 21.1409 14.6182 20.7577 14.6182 20.2895V19.7219C15.8285 19.6297 16.7924 18.6293 16.7924 17.4161C16.7924 16.139 15.7343 15.1031 14.4298 15.1031H13.0673C12.7267 15.1031 12.444 14.8335 12.444 14.493C12.444 14.1595 12.7194 13.8828 13.0673 13.8828H15.4661C15.9445 13.8828 16.3358 13.4997 16.3358 13.0314C16.3358 12.5631 15.9445 12.18 15.4661 12.18H14.6182V11.6408C14.6182 11.1726 14.2269 10.7894 13.7486 10.7894C13.2702 10.7894 12.8789 11.1726 12.8789 11.6408V12.1942C11.6686 12.2864 10.7047 13.2868 10.7047 14.5001C10.7047 15.77 11.7628 16.8059 13.0673 16.8059Z'
        fill='#64737F'
      />
    </svg>
  );
}

export default StoreInfoPaymentIcon;
