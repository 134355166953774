/* eslint-disable max-len */
import React from 'react';

export const EmptyBrandsImage = (): JSX.Element => (
  <svg width='93' height='89' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M89.407 35.537 51.995 73.155c-1.914 2.214-7.292 1.914-9.506 0L17.3 51.452a5.299 5.299 0 0 1-.543-7.473L51.995 3.204a5.3 5.3 0 0 1 7.474-.546l29.395 25.404a5.3 5.3 0 0 1 .543 7.475Z'
      fill='#EAEFF2'
    />
    <path
      d='m89.407 35.535-22.985 22.72L30.79 27.735 51.994 3.202a5.299 5.299 0 0 1 7.475-.546L88.863 28.06a5.3 5.3 0 0 1 .544 7.475Z'
      fill='#fff'
    />
    <path
      d='M71.581 23.196 50.974 73.867c-.93 2.774-2.143 2.68-3.24 3.762L10.9 65.275a5.299 5.299 0 0 1-3.338-6.709L24.699 7.471a5.3 5.3 0 0 1 6.71-3.34l36.834 12.354a5.3 5.3 0 0 1 3.338 6.71Z'
      fill='#EAEFF2'
    />
    <path
      d='M71.58 23.196 61.27 53.94 14.387 38.216 24.7 7.472a5.299 5.299 0 0 1 6.71-3.34l36.834 12.354a5.3 5.3 0 0 1 3.338 6.71Z'
      fill='#EAEFF2'
    />
    <path
      d='M51.413 18.43v53.893a5.299 5.299 0 0 1-5.298 5.299H7.263a5.299 5.299 0 0 1-5.298-5.299V18.431c0-2.928 2.372-5.3 5.298-5.3h38.852a5.299 5.299 0 0 1 5.298 5.3Z'
      fill='#fff'
    />
    <path
      d='M51.413 18.43v32.428H1.965V18.431c0-2.928 2.372-5.3 5.298-5.3h38.852a5.299 5.299 0 0 1 5.298 5.3Z'
      fill='#EAEFF2'
    />
    <path
      d='m26.69 18.076 4.24 8.593 9.483 1.378-6.862 6.69 1.62 9.444-8.482-4.46-8.482 4.46 1.62-9.445-6.862-6.688 9.483-1.378 4.241-8.594Z'
      fill='#fff'
    />
    <path
      d='m89.757 27.026-4.603-3.978a1.367 1.367 0 1 0-1.787 2.068l4.602 3.978a3.937 3.937 0 0 1 .404 5.547L50.26 72.805a1.367 1.367 0 0 0 2.069 1.788L90.44 36.428a6.674 6.674 0 0 0-.684-9.402Z'
      fill='#405D67'
    />
    <path
      d='M46.034 9.62c.572.494 1.435.43 1.928-.14l5.066-5.385a3.907 3.907 0 0 1 2.69-1.35 3.904 3.904 0 0 1 2.857.946l25.4 21.973a1.364 1.364 0 0 0 1.928-.14 1.367 1.367 0 0 0-.14-1.928l-25.4-21.973A6.626 6.626 0 0 0 55.518.018a6.622 6.622 0 0 0-4.56 2.29l-5.065 5.384a1.367 1.367 0 0 0 .14 1.928Z'
      fill='#405D67'
    />
    <path
      d='M68.677 15.19 31.128 2.48c-4.36-1.587-6.741.914-9.333 8.13l-.486 1.448a1.367 1.367 0 0 0 2.592.87l.486-1.449c1.755-3.976 2.649-7.638 6.587-6.05l36.834 12.354a3.937 3.937 0 0 1 2.477 4.979L49.932 73.856a1.367 1.367 0 0 0 2.592.87L72.877 23.63a6.674 6.674 0 0 0-4.2-8.44Z'
      fill='#405D67'
    />
    <path
      d='M46.114 11.764H7.264A6.674 6.674 0 0 0 .597 18.43v53.893a6.674 6.674 0 0 0 6.667 6.666h38.85a6.674 6.674 0 0 0 6.667-6.666V18.43a6.674 6.674 0 0 0-6.667-6.666Zm3.933 60.559a3.937 3.937 0 0 1-3.933 3.932H7.264a3.937 3.937 0 0 1-3.933-3.932V18.43a3.937 3.937 0 0 1 3.933-3.932h38.85a3.937 3.937 0 0 1 3.933 3.932v53.893Z'
      fill='#405D67'
    />
    <path
      d='M46.826 49.49H6.552a1.367 1.367 0 0 0 0 2.734h40.274a1.367 1.367 0 0 0 0-2.733ZM40.453 57.309H23.526a1.367 1.367 0 0 0 0 2.733h16.927a1.367 1.367 0 0 0 0-2.733ZM12.925 60.042h6.045a1.367 1.367 0 0 0 0-2.733h-6.044a1.367 1.367 0 0 0 0 2.733ZM40.454 62.534H12.925a1.367 1.367 0 0 0 0 2.734h27.529a1.367 1.367 0 0 0 0-2.734ZM40.454 67.759H12.925a1.367 1.367 0 0 0 0 2.734h27.529a1.367 1.367 0 0 0 0-2.734ZM18.358 35.212l-1.499 8.736a1.367 1.367 0 0 0 1.983 1.441l7.846-4.125 7.846 4.125a1.367 1.367 0 0 0 1.983-1.44l-1.498-8.737 6.347-6.187a1.367 1.367 0 0 0-.758-2.332l-8.771-1.274-3.923-7.949a1.367 1.367 0 0 0-2.452 0L21.54 25.42l-8.772 1.274a1.366 1.366 0 0 0-.757 2.332l6.348 6.187Zm4.286-7.191c.445-.065.83-.345 1.029-.748l3.015-6.11 3.015 6.11c.2.403.584.683 1.03.748l6.742.98-4.879 4.755c-.322.314-.47.766-.393 1.21l1.151 6.715-6.03-3.17a1.368 1.368 0 0 0-1.272 0l-6.03 3.17 1.151-6.715a1.367 1.367 0 0 0-.393-1.21L15.902 29l6.742-.98Z'
      fill='#405D67'
    />
    <path
      d='M78.64 86.435a3.326 3.326 0 0 1-4.703 0L62.963 75.46l-1.799-1.8 4.695-4.71 1.8 1.798L78.64 81.732c1.299 1.299 1.299 3.404 0 4.703Z'
      fill='#BCCAD2'
    />
    <path
      d='M75.613 84.759 62.84 71.985l3.022-3.032-.003-.003-4.696 4.711 12.774 12.774a3.326 3.326 0 0 0 5.387-.993 3.326 3.326 0 0 1-3.71-.683Z'
      fill='#9CAAB4'
    />
    <path
      d='M50.306 77.211c10.531 0 19.068-8.537 19.068-19.067 0-10.531-8.537-19.068-19.068-19.068-10.53 0-19.068 8.537-19.068 19.068 0 10.53 8.537 19.067 19.068 19.067Z'
      fill='#9CAAB4'
    />
    <path d='M67.659 70.748a19.24 19.24 0 0 1-4.696 4.712l-1.798-1.8 4.695-4.71 1.799 1.798Z' fill='#9CAAB4' />
    <path
      d='M59.84 67.677a13.458 13.458 0 0 1-11.274 3.836 13.418 13.418 0 0 1-7.793-3.836c-5.266-5.266-5.266-13.802 0-19.068a13.42 13.42 0 0 1 7.793-3.837c4.005-.518 8.198.76 11.274 3.837 5.266 5.266 5.266 13.802 0 19.068Z'
      fill='#F4F4F4'
    />
    <path
      d='M56.36 67.678a13.415 13.415 0 0 1-7.794 3.836 13.418 13.418 0 0 1-7.793-3.836c-5.266-5.266-5.266-13.803 0-19.068a13.42 13.42 0 0 1 7.793-3.837 13.417 13.417 0 0 1 7.793 3.837c5.265 5.265 5.265 13.802 0 19.068Z'
      fill='#fff'
    />
    <path
      d='M63.289 74.739a.741.741 0 0 0-1.048 1.047l6.438 6.438a.74.74 0 0 0 1.048 0 .741.741 0 0 0 0-1.047l-6.438-6.438Z'
      fill='#405D67'
    />
    <path
      d='m79.164 81.208-11.18-11.18a.741.741 0 0 0-1.048 1.047l11.18 11.18a2.588 2.588 0 0 1 0 3.656 2.588 2.588 0 0 1-3.655 0l-3.105-3.105a.741.741 0 0 0-1.047 1.048l3.104 3.105a4.054 4.054 0 0 0 2.876 1.189 4.054 4.054 0 0 0 2.875-1.19 4.071 4.071 0 0 0 0-5.75Z'
      fill='#405D67'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m64.955 69.434.828-.798.829-.798.434.45a3528.266 3528.266 0 0 0 4.932 5.108c2.878 2.974 6.13 6.321 7.656 7.847.989.989 1.407 2.165 1.347 3.322-.057 1.127-.561 2.153-1.266 2.92-.703.763-1.669 1.338-2.737 1.481-1.107.148-2.258-.18-3.19-1.112L60.753 74.818l.813-.814.814-.813 13.037 13.037c.41.41.841.513 1.256.458.453-.06.95-.325 1.35-.76.397-.431.636-.963.662-1.478.025-.484-.132-1.033-.677-1.578-1.542-1.542-4.809-4.905-7.682-7.874a3519.6 3519.6 0 0 1-4.936-5.111l-.434-.451Z'
      fill='#405D67'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.678 75.921c9.74 0 17.638-7.897 17.638-17.638 0-9.742-7.897-17.638-17.638-17.638-9.742 0-17.639 7.896-17.639 17.638 0 9.741 7.897 17.638 17.639 17.638Zm0 2.3c11.012 0 19.939-8.926 19.939-19.938 0-11.012-8.927-19.939-19.94-19.939-11.011 0-19.938 8.927-19.938 19.939s8.927 19.939 19.939 19.939Z'
      fill='#405D67'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.678 70.553c6.776 0 12.27-5.494 12.27-12.27 0-6.777-5.494-12.27-12.27-12.27-6.777 0-12.27 5.493-12.27 12.27 0 6.776 5.493 12.27 12.27 12.27Zm0 2.3c8.047 0 14.57-6.523 14.57-14.57s-6.523-14.571-14.57-14.571-14.571 6.524-14.571 14.57c0 8.048 6.523 14.571 14.57 14.571Z'
      fill='#405D67'
    />
  </svg>
);
