/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { LDFlagValue, LDEvaluationDetail, LDEvaluationReason } from 'launchdarkly-js-client-sdk';

import { tracker } from 'src/utils/analytics';

/** To Track a LaunchDarkly Experiment:
 * 1. Add the experiment flag and its custom metrics to the EXPERIMENT_FLAGS and ExperimentMetrics objects below
 * 2. Call 'const { value, track } = useExperiment(flag);' in the component(s) where the experiment is applied
 * 3. Use 'value' to handle the experiment variations in the component(s)
 * 4. Call 'track(metric-name);' to report an experiment conversion event to LaunchDarkly
 * 5. Revert steps 1 - 4 once the experiment has ended
 */

export const EXPERIMENT_FLAGS: string[] = ['growth.ads.show_sponsored_products_in_carousels.experiment'];

// Map of experimental flags to their corresponding custom LD experiment metrics
// eg. {'flag': 'metric'} or {'flag': 'metric-name-1' | 'metric-name-2'}, etc.
export type ExperimentMetrics = {
  'sample-feature-change-flag.experiment': 'metric-name-1' | 'metric-name-2'; // Do not remove this line
  'growth.ads.show_sponsored_products_in_carousels.experiment':
    | 'growth.ads.carousel_experiment.add_to_cart'
    | 'growth.ads.carousel_experiment.click'
    | 'growth.ads.carousel_experiment.impression';
};

export type ExperimentFlag = keyof ExperimentMetrics;

type ExperimentVariationDetail = LDEvaluationDetail & {
  reason?: LDEvaluationReason & { inExperiment?: boolean };
};

export type ExperimentTracker<Property extends ExperimentFlag> = (
  metric: ExperimentMetrics[Property],
  data?: any,
  metricValue?: number
) => void;

export type UseExperimentReturn = {
  loading: boolean;
  trackImpression: (source: string) => void;
  trackMetric: ExperimentTracker<ExperimentFlag>;
  value: LDFlagValue;
};

export function useExperiment<Flag extends keyof ExperimentMetrics>(featureFlag: Flag): UseExperimentReturn {
  const client = useLDClient();
  const flags = useFlags();
  const value = flags[featureFlag];

  const trackMetric = useCallback(
    (metric: ExperimentMetrics[Flag], data?: any, metricValue?: number) => {
      if (!client) {
        console.error(
          `Tracking the '${metric}' event for ${featureFlag} was attempted before the LaunchDarkly client was ready.`
        );

        return;
      }

      const { reason = null } = client.variationDetail(featureFlag) as ExperimentVariationDetail;
      if (!reason?.inExperiment) {
        console.error(
          `Tracking the '${metric}' event for ${featureFlag} was attempted for a user that isn't in the experiment.`
        );
        return;
      }

      client.track(metric, data, metricValue);
    },
    [client, featureFlag]
  );

  const trackImpression = useCallback(
    (source: string): void => {
      if (!client) {
        return;
      }

      const { reason } = client.variationDetail(featureFlag) as ExperimentVariationDetail;
      const { inExperiment = false } = reason ?? {};

      if (inExperiment) {
        tracker.experimentImpression({ flag: featureFlag, value, source });
      }
    },
    [client, featureFlag, value]
  );

  return {
    loading: !client,
    trackMetric,
    trackImpression,
    value,
  };
}
