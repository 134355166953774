export * from './age-restrictions';
export * from './aria';
export * from './content';
export * from './dispensaries';
export * from './ernie';
export * from './forms';
export * from './orders';
export * from './products';
export * from './pusher';
export * from './specials';
export * from './taxes';
export * from './time';
export var DELIVERY = 'Delivery';
export var PICKUP = 'Pickup';
export var ORDER_TYPE_CURBSIDE_PICKUP = 'curbsidePickup';
export var ORDER_TYPE_DRIVE_THRU_PICKUP = 'driveThruPickup';
export var ORDER_TYPE_IN_STORE_PICKUP = 'inStorePickup';
export var MENU_TYPE_REC = 'rec';
export var MENU_TYPE_MED = 'med';
export var logoPlaceholder = 'https://s3-us-west-2.amazonaws.com/dutchie-images/logo-placeholder.png';
export var LEGACY_AWS_SOURCE = 'https://s3-us-west-2.amazonaws.com/dutchie-images/';
export var IMGIX_SOURCE = 'https://images.dutchie.com/';
export var MAPBOX_TOKEN = 'pk.eyJ1IjoiZHV0Y2hpZS1lbmciLCJhIjoiY2tkNjlxaGNlMG51ajJ4bzhjbTk5cG5zNyJ9.FEj8yCJEq36aac-v-N227w';
export var DUTCHIE_IFRAME_ID = 'dutchie--embed__iframe';
export var DUTCHIE_MAIN_STORED_CART_KEY = 'dutchieMain';
export var LOCAL_STORAGE_STORED_CART_KEY = 'stored-cart';
export var MOST_RECENT_DISPO_KEY = 'mostRecentDispo';
export var NUMERALS_DOLLAR_FORMAT = '$0,0.00';
export var DEFAULT_INPUT_MAX_LENGTH = 100;