import { useEffect } from 'react';
import { NextRouter } from 'next/router';

import useUI from 'hooks/use-ui';
import { GqlDispensaries } from 'types/graphql';

type UseRedirectIfAgeVerificationNotApplicableProps = {
  dispensary: GqlDispensaries | null;
  router: NextRouter;
  href: string;
};

export function useRedirectIfAgeVerificationNotApplicable({
  dispensary,
  router,
  href,
}: UseRedirectIfAgeVerificationNotApplicableProps): void {
  const { isEmbedded, isStoreFront } = useUI();
  const isAgeVerificationSettingEnabled = dispensary?.storeSettings.hardAgeGateAgeVerification ?? false;

  const isWrongVariant = !isEmbedded && !isStoreFront;

  useEffect(() => {
    if (isWrongVariant || !isAgeVerificationSettingEnabled) {
      void router.replace(href);
    }
    // we don't want router here or we get infinite renders when we push to the router stack
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href, isAgeVerificationSettingEnabled, isWrongVariant]);
}
