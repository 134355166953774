import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { BottomDrawer } from 'components/core/bottom-drawer';
import { CheckboxGroup } from 'components/core/checkbox-group/checkbox-group';
import { Button, TextButton } from 'components/core';

import { DISPENSARIES_FILTER_OPTIONS } from 'src/dispensaries/constants';
import { DispensaryListFiltersBottomDrawerProps } from './types';

export function DispensaryListFiltersBottomDrawer({
  open,
  onClearFilters,
  onClose,
  onSave,
  onSelect,
  orderType = 'pickup',
  selectedOptions = [],
}: DispensaryListFiltersBottomDrawerProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <BottomDrawer open={open} onClose={onClose} heading={t('filters', 'Filters')}>
      {!!selectedOptions.length && (
        <StyledTextButton onClick={onClearFilters}>{t('filters.clearAll', 'Clear all')}</StyledTextButton>
      )}
      <CheckboxContainer>
        <CheckboxGroup
          options={DISPENSARIES_FILTER_OPTIONS[orderType]}
          selectedOptions={selectedOptions}
          onSelect={onSelect}
        />
      </CheckboxContainer>
      <Button fontSize={13} size='large' onClick={onSave}>
        {t('save', 'Save')}
      </Button>
    </BottomDrawer>
  );
}

const StyledTextButton = styled(TextButton)`
  position: absolute;
  top: 30px;
  right: 50px;
  font-size: 12px;
  font-weight: 700;
`;

const CheckboxContainer = styled.div`
  margin: 4px 0 24px;
`;
