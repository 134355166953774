import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import { EmptyBrandsImage } from 'assets/empty-brands';
import { Button } from 'components/core';
import { BrandsFilterEmptyStateProps } from './brands-filter-drawer.types';

export const BrandsFilterEmptyState = ({ handleClearSearch }: BrandsFilterEmptyStateProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <EmptyBrandsImage />
      <Header>{t('brandsFilterEmptyState.header', 'Sorry, there are no brands that match your search.')}</Header>

      <Subtext>
        {t(
          'brandsFilterEmptyState.subtext',
          'We can’t seem to find any brands that match your search. Please try again.'
        )}
      </Subtext>

      <Button variant='outlinePrimary' onClick={handleClearSearch}>
        {t('brandsFilterEmptyState.cta', 'CLEAR SEARCH')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 85px 35px;
`;

const Header = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin-bottom: 15px;
`;

const Subtext = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-bottom: 30px;
`;
