import geocodingService from '@mapbox/mapbox-sdk/services/geocoding';

import { buildLocationObjectFromMapboxFeature, LocationObject } from 'shared/helpers/mapbox';
import { MAPBOX_TOKEN } from 'shared/constants';

type GetLocationsFromCoordinatesParams = {
  latitude: number;
  longitude: number;
};

export type GetLocationsFromCoordinatesResults = {
  data: LocationObject[];
  status: number;
};

const geocodingClient = geocodingService({ accessToken: MAPBOX_TOKEN });

/**
 * To view possible request options, look at the config object here:
 * https://github.com/mapbox/mapbox-sdk-js/blob/master/docs/services.md#reversegeocode
 *
 * Note: the current default limit is set to 10. If anything above 1 is used, types must
 * only have one value, otherwise the api will fail
 *
 * formatResults will make use of buildLocationObjectFromMapboxFeature to return a list
 * of suggestions formatted to the location object style we use in our apps
 */
export const getLocationsFromCoordinates = async ({
  latitude,
  longitude,
}: GetLocationsFromCoordinatesParams): Promise<GetLocationsFromCoordinatesResults> => {
  try {
    const request = geocodingClient.reverseGeocode({
      types: ['address'],
      limit: 10,
      query: [longitude, latitude],
    });

    const response = await request.send();

    if (response.statusCode !== 200) {
      console.error('Problem attempting to get Mapbox location suggestions: ', response);
      return { data: [], status: response.statusCode };
    }

    const features = response.body?.features ?? [];

    return {
      data: features.map((feature) => buildLocationObjectFromMapboxFeature(feature)),
      status: response.statusCode,
    };
  } catch (error) {
    console.error('Problem attempting to get Mapbox location suggestions: ', error);
    return { data: [], status: 500 };
  }
};

export default getLocationsFromCoordinates;
