import { CategoryPotencyRanges, DefaultImages } from 'shared/constants';

import { GenericCategoryRoute } from 'src/generics/data';
import { CategoryInfo } from 'shop/types';

export const genericCategoryRouteInfo: Record<Exclude<GenericCategoryRoute, 'n-a'>, CategoryInfo> = {
  [GenericCategoryRoute.accessories]: {
    translation: ['categories.accessories', 'Accessories'],
    imgSrc: DefaultImages.Accessories,
  },
  [GenericCategoryRoute.edible]: {
    translation: ['categories.edibles', 'Edibles'],
    potencyRanges: CategoryPotencyRanges.edibles,
    imgSrc: DefaultImages.Edible,
  },
  [GenericCategoryRoute.apparel]: {
    translation: ['categories.apparel', 'Apparel'],
    imgSrc: DefaultImages.Apparel,
  },
  [GenericCategoryRoute.cbd]: {
    translation: ['categories.cbd', 'CBD'],
    potencyRanges: CategoryPotencyRanges.cbd,
    imgSrc: DefaultImages.CBD,
  },
  [GenericCategoryRoute.clones]: {
    translation: ['categories.clones', 'Clones'],
    imgSrc: DefaultImages.Clones,
  },
  [GenericCategoryRoute.concentrate]: {
    translation: ['categories.concentrates', 'Concentrates'],
    potencyRanges: CategoryPotencyRanges.concentrates,
    imgSrc: DefaultImages.Concentrate,
  },
  [GenericCategoryRoute.flower]: {
    translation: ['categories.flower', 'Flower'],
    potencyRanges: CategoryPotencyRanges.flower,
    imgSrc: DefaultImages.Flower,
  },
  [GenericCategoryRoute.preRolls]: {
    translation: ['categories.preRolls', 'Pre-Rolls'],
    potencyRanges: CategoryPotencyRanges['pre-rolls'],
    imgSrc: DefaultImages['Pre-Rolls'],
  },
  [GenericCategoryRoute.seeds]: {
    translation: ['categories.seeds', 'Seeds'],
    imgSrc: DefaultImages.Seeds,
  },
  [GenericCategoryRoute.tincture]: {
    translation: ['categories.tinctures', 'Tinctures'],
    potencyRanges: CategoryPotencyRanges.tinctures,
    imgSrc: DefaultImages.Tincture,
  },
  [GenericCategoryRoute.topicals]: {
    translation: ['categories.topicals', 'Topicals'],
    potencyRanges: CategoryPotencyRanges.topicals,
    imgSrc: DefaultImages.Topicals,
  },
  [GenericCategoryRoute.vaporizers]: {
    translation: ['categories.vaporizers', 'Vaporizers'],
    potencyRanges: CategoryPotencyRanges.vaporizers,
    imgSrc: DefaultImages.Vaporizers,
  },
  [GenericCategoryRoute.orals]: {
    translation: ['categories.orals', 'Orals'],
    imgSrc: DefaultImages.Oral,
  },
};
