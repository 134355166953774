import React from 'react';
import styled from 'styled-components';
import { Product } from 'utils/helpers/product';

import { OptionCard } from './option-card';

export type OptionsListProps = {
  options: Array<Product>;
  selectedWeight: string | null;
};

export function OptionsList({ options, selectedWeight }: OptionsListProps): JSX.Element {
  return (
    <List>
      {options.map((product) => (
        <OptionCard product={product} key={product.id} weight={selectedWeight} />
      ))}
    </List>
  );
}

const List = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 15px;
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;
