import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { ModalContainer, ModalExitIcon, Button } from 'components/core';

import useTranslation from 'hooks/use-translation';

import { Clickable } from 'shared/components';
import { ModalContent } from 'components/modals/components';

const OutsideDeliveryAreaModal = forwardRef(
  ({ onClose, onDispensaryListRedirect, dispensaryName, containerProps }, ref) => {
    const { t } = useTranslation();

    return (
      <ModalContainer ref={ref} padding={0} width={505} {...containerProps}>
        <ModalExitIcon onClick={onClose} />
        <ModalContent pt={32} pb={39}>
          <HeaderText>{t('outsideDeliveryAreaModal.header', `You're outside the delivery area!`)}</HeaderText>

          <SorryText>
            {t('outsideDeliveryAreaModal.sorry', `We're sorry, ${dispensaryName} doesn't deliver to your address`)}
          </SorryText>

          <Button onClick={onClose}>{t('outsideDeliveryAreaModal.continue', 'Continue as pickup')}</Button>

          <SecondaryTextButton onClick={onDispensaryListRedirect}>
            {t('outsideDeliveryAreaModal.viewStores', `View stores that deliver to me`)}
          </SecondaryTextButton>
        </ModalContent>
      </ModalContainer>
    );
  }
);

export default OutsideDeliveryAreaModal;

const HeaderText = styled.h4`
  font-size: 16px;
  padding-bottom: 12px;
`;

const SorryText = styled.div`
  color: ${({ theme }) => theme.colors.grey[35]};
  font-size: 13px;
  padding-bottom: 26px;
`;

const SecondaryTextButton = styled(Clickable)`
  display: block;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 13px;
  margin: 23px auto 0;
  cursor: pointer;
`;
