/* eslint-disable max-len */
import React from 'react';

const Incompleted = (props) => (
  <svg height='72' viewBox='0 0 72 72' width='72' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='36' cy='36' fill='#fff' fillRule='evenodd' r='34' stroke='#cdd5d5' strokeWidth='4' />
  </svg>
);

export default Incompleted;
