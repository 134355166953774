import React from 'react';
import useTranslation from 'hooks/use-translation';

import { ProductRecommendationSection } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { Category } from 'src/generics/data';
import { ProductCarousel } from 'src/components/product-carousel';

import { MIN_PRODUCTS } from './often-purchased-with-carousel.constants';
import { useOftenPurchasedWithCarousel } from './use-often-purchased-with-carousel';

export type OftenPurchasedWithCarouselProps = {
  productId: string;
  category: Category;
};

export function OftenPurchasedWithCarousel(props: OftenPurchasedWithCarouselProps): JSX.Element {
  const { t } = useTranslation();
  const carouselProps = useOftenPurchasedWithCarousel(props);

  return (
    <ProductCarousel
      heading={t(`common.oftenPurchasedWith`, `Often bought with`)}
      minimumProducts={MIN_PRODUCTS}
      trackerSection={ProductRecommendationSection.oftenPurchasedWith}
      {...carouselProps}
    />
  );
}
