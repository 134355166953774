import moment from 'moment';
/* eslint func-names: 0 */

export function beforeToday(format, message) {
  return this.test({
    message: message,
    test: function test(value) {
      var date = moment(value, format);

      if (!date.isValid()) {
        return this.createError({
          message: message
        });
      }

      if (moment().isAfter(date)) {
        return false;
      }

      return true;
    }
  });
}
export function isLegalAge(format, message) {
  return this.test({
    message: message,
    test: function test(value) {
      var date = moment(value, format, true);

      if (!date.isValid()) {
        return this.createError({
          message: message
        });
      }

      if (moment().diff(date, 'years') < 18) {
        return false;
      }

      return true;
    }
  });
}