import React, { ReactNode } from 'react';
import { ComboboxPopover, ComboboxPopoverProps } from '@reach/combobox';

type AddressAutocompletePopoverProps = ComboboxPopoverProps & {
  children: ReactNode;
};
export function AddressAutocompletePopover({ children, ...props }: AddressAutocompletePopoverProps): JSX.Element {
  return (
    <ComboboxPopover portal={false} {...props}>
      {children}
    </ComboboxPopover>
  );
}
