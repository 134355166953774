import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'components/carousel-slider/helpers';
import { CARD_SIZES } from 'components/product-card/product-card.constants';
import Skeleton from './loading-skeleton';

const productCardLoadingSkeleton = (props) => {
  const { className } = props;
  const imgSize = className === `homepage` ? 170 : 160;

  return (
    <SkeletonWrapper dimensions={CARD_SIZES.default}>
      <Skeleton width={imgSize} height={imgSize} />
      <SkeletonContentContainer className={className}>
        <Skeleton width={44} height={8} />
        <Skeleton width={137} height={10} />
        <Skeleton width={94} height={8} />
        <Inline>
          <Skeleton width={59} height={8} mr={10} />
          <Skeleton width={59} height={8} />
        </Inline>
      </SkeletonContentContainer>
    </SkeletonWrapper>
  );
};

export default productCardLoadingSkeleton;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 288px;
  min-width: ${({ dimensions }) => dimensions.desktop}px;
  @media (min-width: ${breakpoints.mobile}px) {
    padding: ${({ dimensions }) => dimensions.padding}px;
  }
  @media (max-width: ${breakpoints.mobile}px) {
    max-width: ${({ dimensions }) => dimensions.mobile}px;
  }
`;

const SkeletonContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 160px;
  height: 104px;
  margin-top: 30px;

  &.homepage {
    width: 175px;
  }
`;

const Inline = styled.div`
  display: flex;
`;
