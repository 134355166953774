import React, { useState, useEffect, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { StyledTextInput } from './components';
import { getErrors } from './form-input-utils';

/**
 * @type {React.FC<{
 * name?: string;
 * type?: string;
 * controlled?: boolean;
 * label?: string;
 * variant?: string;
 * defaultValue?: string;
 * autoComplete?: string;
 * required?: boolean;
 * disabled?: boolean;
 * autoFocus?: boolean;
 * hideFieldset?: boolean;
 * onChange?: (e: any) => void;
 * }>}
 */
const FormTextInput = forwardRef((props, ref) => {
  const [shouldShrink, setShouldShrink] = useState(false);
  const [focused, setFocused] = useState(false);
  const {
    controlled = false,
    name,
    type,
    label: labelFromProps,
    variant,
    onChange,
    hideFieldset = false,
    ...otherProps
  } = props;
  const { control, errors, register, watch } = useFormContext();
  const { errorLabel, hasError } = getErrors({ name, errors });
  const inputValue = watch(name);
  const label = hasError ? errorLabel : labelFromProps;
  useEffect(() => {
    // async default data doesn't shrink the input label, and so we have to control it manually:
    // https://github.com/mui-org/material-ui/issues/17018
    setShouldShrink(focused || !!inputValue);
  }, [focused, inputValue]);

  return (
    <StyledTextInput
      id={name}
      onChange={onChange}
      ref={ref}
      name={name}
      type={type}
      label={label}
      error={hasError}
      variant={variant}
      inputRef={!controlled && register}
      control={controlled && control}
      InputLabelProps={{ shrink: shouldShrink }}
      addLabelOffset={shouldShrink}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      hideFieldset={hideFieldset}
      {...otherProps}
    />
  );
});

export default FormTextInput;
