import { GenericCategoryRoute } from 'src/generics/data';

import ROUTES from 'src/routes';

const { SHOP, SHOP_DISPENSARY, SHOP_PRODUCTS } = ROUTES;

/* Dispensary Routes */
export function getPathToShopDispensaryCategory(dispensaryCName: string, category: GenericCategoryRoute): string {
  return `${SHOP_DISPENSARY}/${dispensaryCName}/products/${category}`;
}

export function getPathToShopDispensaryProduct(dispensaryCName: string, productCName: string): string {
  return `${SHOP_DISPENSARY}/${dispensaryCName}/product/${productCName}`;
}

/* Marketplace Routes */
export function getPathToShopMarketplaceCategory(category: GenericCategoryRoute): string {
  return `${SHOP_PRODUCTS}/${category}`;
}

export function getPathToShopMarketplaceProduct(category: GenericCategoryRoute, productId: string): string {
  return `${SHOP}/${category}/${productId}`;
}
