import React from 'react';
import styled, { useTheme } from 'styled-components';

import SvgChevronIcon from 'assets/chevron-icon';
import { Clickable } from 'shared/components';

type BackButtonProps = {
  copy: string;
  onClick: () => void;
};

export function BackButton({ copy, onClick }: BackButtonProps): JSX.Element {
  const { customized } = useTheme();

  return (
    <ButtonContainer onClick={onClick}>
      <SvgChevronIcon fill={customized.colors.buttonsLinks} height='7px' />
      <BackCopy>{copy}</BackCopy>
    </ButtonContainer>
  );
}

const ButtonContainer = styled(Clickable)`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  svg {
    margin: 4px 10px 0 0;
    transform: rotate(90deg);
  }
`;

const BackCopy = styled.span`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 13px;
  font-weight: bold;
`;
