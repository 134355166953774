import React, { forwardRef } from 'react';
import styled from 'styled-components';

import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

import {
  getAfterHoursWindow,
  getCombinedV2PickupHoursWindow,
  formatAfterHoursEstimate,
} from 'shared/helpers/order-estimates';
import { getFirstAvailablePickupOption } from 'shared/helpers/dispensaries';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { Button, TextButton, ModalContainer } from 'components/core';
import { ModalWrapper } from 'shared/modals/v2-wrapper';
import ClosedButSign from 'assets/closed-but-sign';
import ClosedButInfoV2 from './closed-but-info-v2';

const ClosedButModal = forwardRef((props, ref) => {
  const { open, onClose, containerProps } = props;
  const { t } = useTranslation();
  const UI = useUI();
  const Cart = useCart();
  const useDispensaryData = useDispensary();
  const { dispensary } = props.dispensary ? props : useDispensaryData;
  const { orderTypesConfigV2 } = dispensary;

  const openInfo = openInfoForDispensary(dispensary, { previewMode: UI.previewMode });

  const formattedPickupEstimate =
    orderTypesConfigV2?.offerAnyPickupService &&
    (orderTypesConfigV2?.inStorePickup.enableAfterHoursOrdering ||
      orderTypesConfigV2?.curbsidePickup.enableAfterHoursOrdering ||
      orderTypesConfigV2?.driveThruPickup.enableAfterHoursOrdering) &&
    formatAfterHoursEstimate(getCombinedV2PickupHoursWindow(openInfo, dispensary));

  const formattedDeliveryEstimate =
    orderTypesConfigV2?.offerDeliveryService &&
    orderTypesConfigV2?.delivery.enableAfterHoursOrdering &&
    orderTypesConfigV2?.delivery.enableScheduledOrdering &&
    formatAfterHoursEstimate(getAfterHoursWindow(openInfo, 'delivery'));

  const storeHoursDeliveryOnly =
    orderTypesConfigV2?.offerDeliveryService &&
    !(orderTypesConfigV2?.delivery.enableAfterHoursOrdering && orderTypesConfigV2?.delivery.enableScheduledOrdering);

  // Methods
  const onHandleContinue = () => {
    const deliveryEstimate = formattedDeliveryEstimate;
    if (deliveryEstimate) {
      Cart.orderType = 'delivery';
    } else {
      // Default to in-store pickup if available
      Cart.orderType = getFirstAvailablePickupOption(dispensary);
    }
    onClose(true);
  };

  const handleOnClose = () => {
    onClose(false);
  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <StyledModalContainer ref={ref} width={495} height='auto' {...containerProps}>
        <ModalContent data-test='closed-but-modal'>
          <ClosedButSign />

          <DispensaryClosedText>
            {t('closedBut.closedBut', "We're closed, but you can still place an order!")}
          </DispensaryClosedText>
          <ClosedButInfoV2
            formattedPickupEstimate={formattedPickupEstimate}
            formattedDeliveryEstimate={formattedDeliveryEstimate}
            storeHoursDeliveryOnly={storeHoursDeliveryOnly}
          />
          <StyledButton onClick={onHandleContinue} autoFocus>
            {t('common.continue', 'Continue')}
          </StyledButton>
          <StyledTextButton tabIndex={0} onClick={handleOnClose}>
            {t('common.noThanks', 'No thanks')}
          </StyledTextButton>
        </ModalContent>
      </StyledModalContainer>
    </ModalWrapper>
  );
});

export default ClosedButModal;

const StyledModalContainer = styled(ModalContainer)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 31px 30px 45px;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 40px;
  padding-top: 20px;
  padding-bottom: 20px;

  ${({ theme }) => theme?.breakpoints?.down('sm')} {
    padding: 20px 0;
  }
`;

const DispensaryClosedText = styled.h3`
  font-weight: bold;
  font-size: 22px;
  margin-top: 38px;
  margin-bottom: 25px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const StyledTextButton = styled(TextButton)`
  margin-top: 20px;
  width: 100%;
`;
