import { useCallback } from 'react';

import useUser from 'src/hooks/use-user';
import { useMemoArray } from 'hooks/use-memo-array';

import {
  useProductRecommendations,
  GetRecommendations,
  GetRecommendationsFallback,
  Options,
} from './use-product-recommendations';

import { getDispensaryFilter } from './recommendations.utils';
import { Scenarios } from './recommendations.types';

export function usePersonalizedItems(
  getRecommendationsFallback: GetRecommendationsFallback,
  itemIds: string[],
  scenario: Scenarios,
  options: Options
): ReturnType<typeof useProductRecommendations> {
  const { limit } = options;

  const { id: userId } = useUser();

  const itemIdsMemo = useMemoArray(itemIds);

  const getRecommendations = useCallback<GetRecommendations>(
    (client, dispensary) =>
      client.getPersonalizedItems(userId ?? `anonymous`, itemIdsMemo, {
        scenario,
        amt: limit,
        filters: [getDispensaryFilter(dispensary)],
      }),
    [userId, itemIdsMemo, scenario, limit]
  );

  return useProductRecommendations(getRecommendations, getRecommendationsFallback, {
    ...options,
    excludeProducts: itemIds,
  });
}
