import React, { useState, useEffect } from 'react';

import { ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY } from 'shared/constants';

import { useDispensariesQueryParams } from 'src/hooks/use-dispensaries-query-params';
import useSearchPreferences from 'hooks/use-search-preferences';

import { OrderTypeBottomDrawer } from './order-type-bottom-drawer';

type OrderTypeBottomDrawerControllerProps = {
  open: boolean;
  onClose: () => void;
};

export function OrderTypeBottomDrawerController({ open, onClose }: OrderTypeBottomDrawerControllerProps): JSX.Element {
  const SearchPreferences = useSearchPreferences();

  const { setQueryParams } = useDispensariesQueryParams();

  const [orderType, setOrderType] = useState(SearchPreferences.orderType);

  useEffect(() => {
    setOrderType(SearchPreferences.orderType);
  }, [SearchPreferences.orderType]);

  function handleSave(): void {
    SearchPreferences.setOrderType(orderType);

    if (SearchPreferences.isPickup) {
      setQueryParams({ pickup: true, delivery: false });
    } else {
      setQueryParams({ pickup: false, delivery: true });
    }
    onClose();
  }

  function handleClose(): void {
    onClose();
    if (orderType !== SearchPreferences.orderType) {
      setOrderType(SearchPreferences.orderType);
    }
  }

  return (
    <OrderTypeBottomDrawer
      open={open}
      pickupSelected={orderType === ORDER_TYPE_PICKUP}
      deliverySelected={orderType === ORDER_TYPE_DELIVERY}
      onPickupClick={() => setOrderType(ORDER_TYPE_PICKUP)}
      onDeliveryClick={() => setOrderType(ORDER_TYPE_DELIVERY)}
      onSave={handleSave}
      onClose={handleClose}
    />
  );
}
