import { useState, useEffect, useCallback } from 'react';
import { ApolloError } from '@apollo/client';

import PublicEnv from 'shared/utils/public-env';
import useStores from 'shared/hooks/use-stores';
import { PusherConfig } from 'shared/constants';

import { useCreateInstoreCartUrlMutation } from 'types/graphql';
import { useCountdownTimer } from 'src/hooks/use-countdown-timer';

function buildInstoreCartUrl(urlString: string): string {
  const consumerUrlString = PublicEnv.consumerUrl;
  const url = new URL(urlString);
  const newUrl = new URL(url.pathname + url.search + url.hash, consumerUrlString);

  return newUrl.href;
}

type UseKioskQrCodeModalParams = {
  onClose: () => void;
};

type UseKioskQrCodeModalReturn = {
  instoreCartUrl: string | null;
  handlePayLater: () => void;
  orderNumber: string | null;
  isAnonymousKioskCheckout: boolean;
  loading: boolean;
  error?: ApolloError;
  handleOnClose: () => void;
  secondsLeft: number;
};

export function useKioskQrCodeModal({ onClose }: UseKioskQrCodeModalParams): UseKioskQrCodeModalReturn {
  const { Cart, UI, pusher } = useStores();
  const [instoreCartUrl, setInstoreCartUrl] = useState<string | null>(null);
  const [createInstoreCartUrl, { loading: mutationLoading, error }] = useCreateInstoreCartUrlMutation();

  const isAnonymousKioskCheckout = Cart?.isAnonymousKioskCheckout ?? false;
  const orderNumber = Cart?.anonymousOrderId ?? null;
  const loading = (!error && mutationLoading) || !instoreCartUrl || !Cart.serverOrderId;

  const handlePayLater = (): void => {
    onClose();
    Cart.clearOrder();
    UI.showKioskOrderSuccess = true;
    UI.showMenu(UI.embeddedCName);
  };

  const handleOnClose = useCallback((): void => {
    onClose();
    Cart.clearOrder();
    Cart.setAnonymousOrderId('');
    Cart.setIsAnonymousKioskCheckout(false);
    UI.showMenu(UI.embeddedCName);
  }, [Cart, UI, onClose]);

  const handleDidReceiveCartLoaded = useCallback(() => {
    onClose();
    Cart.clearOrder();
    UI.updateShowDutchiePayKioskOrderSuccess(true);
    UI.showMenu(UI.embeddedCName);
  }, [Cart, UI, onClose]);

  const { secondsLeft, startTimer } = useCountdownTimer({
    initialSeconds: 60,
    onTimerStop: handleOnClose,
  });

  useEffect(() => {
    if (loading || error) {
      return;
    }

    startTimer();
  }, [loading, error, startTimer]);

  useEffect(() => {
    if (Cart.serverOrderId) {
      const instoreCartChannel = pusher.subscribe(PusherConfig.InstoreCart.CHANNEL(Cart.serverOrderId), 'cart_loaded');
      instoreCartChannel.bind('cart_loaded', handleDidReceiveCartLoaded);

      return () => {
        pusher.unsubscribe(PusherConfig.InstoreCart.CHANNEL(Cart.serverOrderId));
        instoreCartChannel.unbind('cart_loaded', handleDidReceiveCartLoaded);
      };
    }

    return undefined;
  }, [Cart.serverOrderId, handleDidReceiveCartLoaded, pusher]);

  useEffect(() => {
    const createCartUrl = async (): Promise<void> => {
      if (!Cart.serverOrderId || instoreCartUrl || error) {
        return;
      }
      await createInstoreCartUrl({
        variables: {
          orderId: Cart.serverOrderId,
        },
      })
        .then((result) => {
          const instoreCartUrlResult = result.data?.createInstoreCartUrl?.instoreCartUrl ?? null;

          if (instoreCartUrlResult) {
            const instoreCartUrlWithConsumerBase = buildInstoreCartUrl(instoreCartUrlResult);
            setInstoreCartUrl(buildInstoreCartUrl(instoreCartUrlWithConsumerBase));
          }
        })
        .catch((e) => {
          console.error(e);
        });
    };

    void createCartUrl();
  }, [Cart.serverOrderId, createInstoreCartUrl, error, instoreCartUrl]);

  return {
    instoreCartUrl,
    handlePayLater,
    orderNumber,
    isAnonymousKioskCheckout,
    loading,
    error,
    handleOnClose,
    secondsLeft,
  };
}
