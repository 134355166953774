import { useCallback, useEffect, useState } from 'react';

import useUI from 'hooks/use-ui';
import useViewport from 'hooks/use-viewport';

import { getPrivacyModalOffset } from '../helpers';

type Viewport = {
  height: number;
  width: number;
  parentOffset?: number;
};

type ModalOffset = number | null;

export function usePrivacyModalSetup(isReady: boolean): void {
  const UI = useUI();
  const viewport: Viewport = useViewport();
  const [isModalShownListenerAdded, setIsModalShownListenerAdded] = useState(false);
  const [isOverlayListenerAdded, setIsOverlayListenerAdded] = useState(false);
  const [modalOffset, setModalOffset] = useState<ModalOffset>(null);

  const { isIframe, iframeHeight } = UI;
  const { parentOffset = 0, height: viewportHeight, width: viewportWidth } = viewport;

  // Dismiss the privacy modal on background overlay click
  const handleModalDismiss = useCallback((): void => {
    const modalCloseButton = document.querySelector<HTMLElement>('.fides-close-button');
    modalCloseButton?.click();
  }, []);

  // Attach the calculated offset to the privacy modal DOM element
  const handleModalOffset = useCallback((): void => {
    const privacyModal = document.querySelector<HTMLElement>('.fides-modal-content');

    if (privacyModal && modalOffset) {
      privacyModal.style.top = `${modalOffset}px`;
    }
    if (privacyModal && isIframe && !privacyModal.classList.contains('--iframe')) {
      privacyModal.classList.add('--iframe');
    }
  }, [isIframe, modalOffset]);

  // Attach the background overlay event listener
  const addOverlayListener = useCallback(() => {
    if (isOverlayListenerAdded) {
      return;
    }

    const fidesModalOverlay = document.querySelector<HTMLElement>('.fides-modal-overlay');
    if (fidesModalOverlay) {
      fidesModalOverlay.addEventListener('click', handleModalDismiss);
      setIsOverlayListenerAdded(true);
    }
  }, [handleModalDismiss, isOverlayListenerAdded]);

  // Calculate the appropriate privacy modal offset
  useEffect(() => {
    if (!isReady) {
      return;
    }

    const top = getPrivacyModalOffset({ iframeHeight, isIframe, parentOffset, viewportHeight, viewportWidth });
    setModalOffset(top);
  }, [isReady, iframeHeight, isIframe, parentOffset, viewportHeight, viewportWidth]);

  // Apply the offset to the privacy modal
  useEffect(() => {
    if (!isReady) {
      return;
    }

    handleModalOffset();
  }, [isReady, handleModalOffset]);

  // Wait for the FidesUIShown event before attaching the overlay listener
  useEffect((): (() => void) | void => {
    if (isReady && !isModalShownListenerAdded) {
      window.addEventListener('FidesUIShown', addOverlayListener);
      setIsModalShownListenerAdded(true);
    }

    return () => {
      if (isModalShownListenerAdded) {
        window.removeEventListener('FidesUIShown', addOverlayListener);
      }

      const fidesModalOverlay = document.querySelector<HTMLElement>('.fides-modal-overlay');
      if (fidesModalOverlay && isOverlayListenerAdded) {
        fidesModalOverlay.removeEventListener('click', handleModalDismiss);
      }
    };
  }, [isReady, addOverlayListener, handleModalDismiss, isModalShownListenerAdded, isOverlayListenerAdded]);
}
