import styled from 'styled-components';
import { space, typography } from 'styled-system';

import { TextButton } from 'components/core';

export const StyledHeader = styled.h3`
  padding: 0 20px 0 0;
  font-weight: bold;
  font-size: 26px;
  margin-top: 40px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.grey[25]};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 20px;
  }

  ${typography}
  ${space}
`;

export const StyledSubtext = styled.p`
  padding: 15px 25px 0;
  line-height: 175%;
  color: ${({ theme }) => theme.colors.grey[35]};
  font-size: 14px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 13px;
  }
  ${space}
`;

export const StyledTextButton = styled(TextButton)`
  padding-top: 25px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  ${typography}
  ${space}
`;

export const EmptyStateContainer = styled.div`
  ${(props) =>
    !props.showExpiredSpecial &&
    `
      padding-top: 50px;
    `}
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    text-align: center;
  }
`;
