import React from 'react';
import styled from 'styled-components';
import _times from 'lodash/times';
import { layout } from 'styled-system';

import { DesktopOnly, MobileOnly } from 'shared/components';
import { responsive, StyledCarousel } from 'src/components/category-tiles-carousel';
import { LoadingSkeleton } from 'components/core/loading-skeletons';

const CategorySkeletonCarousel = () => (
  <Container>
    <MobileOnly mediaQuery='xs'>
      <Grid>
        {_times(6, (key) => (
          <Card width='100%' maxWidth='100%' height='100%' key={key} />
        ))}
      </Grid>
    </MobileOnly>
    <DesktopOnly mediaQuery='sm'>
      <StyledCarousel
        displayGradient={false}
        itemClass='carousel-item'
        partialVisible={false}
        position='relative'
        // eslint-disable-next-line no-use-before-define
        responsive={responsive}
        variant='embedded'
        // eslint-disable-next-line lodash/prefer-noop
        onMouseOver={() => {}}
      >
        {_times(6, (key) => (
          <Card justifyContent='space-evenly' key={key} />
        ))}
      </StyledCarousel>
    </DesktopOnly>
  </Container>
);

export default CategorySkeletonCarousel;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Card = styled(LoadingSkeleton)`
  justify-content: flex-start;
  padding: 15px;
  width: 154px;
  height: 132px;
  ${layout}

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 25px;
    width: 217px;
    height: 185px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(125px, 35vw);
  row-gap: 16.5px;
  column-gap: 18px;
`;
