import { ApolloLink } from 'apollo-link';

import { isBrowser } from 'utils/misc-utils';
import { Session } from './session';

export const apolloSessionLink = new ApolloLink((operation, forward) => {
  if (!isBrowser()) {
    return forward(operation);
  }

  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      ...Session.getInstance().header,
    },
  }));

  return forward(operation);
});
