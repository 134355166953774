import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { WidthProps, HeightProps, SpaceProps, SizeProps } from 'styled-system';
import buttonStyles from './styles';

type StyleProps = HeightProps & SizeProps & SpaceProps & WidthProps;

type AnchorButtonProps = StyleProps & {
  className?: string;
  children: React.ReactNode;
  size?: string;
  variant?: string;
  onClick?: () => void;
  href?: string;
};

export const AnchorButton = forwardRef((props: AnchorButtonProps, ref) => {
  const { className, children, size = `medium`, variant = `primary`, href, ...buttonProps } = props;
  return (
    <StyledAnchor ref={ref} size={size} variant={variant} className={className} href={href} {...buttonProps}>
      {children}
    </StyledAnchor>
  );
});

const StyledAnchor = styled.a`
  ${buttonStyles}
`;
