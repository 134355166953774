import React from 'react';
import styled, { keyframes } from 'styled-components';

export function EllipsisLoader(): JSX.Element {
  return (
    <LoadingContainer>
      <Ellipsis />
      <Ellipsis />
      <Ellipsis />
      <Ellipsis />
    </LoadingContainer>
  );
}

const Ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const Ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
}
`;

const Ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }`;

const Ellipsis = styled.div``;

const LoadingContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  ${Ellipsis} {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.blueGrey[80]};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    :nth-child(1) {
      left: 8px;
      animation: ${Ellipsis1} 0.6s infinite;
    }
    :nth-child(2) {
      left: 8px;
      animation: ${Ellipsis2} 0.6s infinite;
    }
    :nth-child(3) {
      left: 32px;
      animation: ${Ellipsis2} 0.6s infinite;
    }
    :nth-child(4) {
      left: 56px;
      animation: ${Ellipsis3} 0.6s infinite;
    }
  }
`;
