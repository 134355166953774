import React from 'react';
import styled from 'styled-components';

import { Dropdown } from 'components/core';
import useTranslation from 'src/hooks/use-translation';
import useSwitchMenuType from 'src/hooks/use-switch-menu-type';
import { useObserver } from 'mobx-react-lite';
import useCart from 'hooks/use-cart';

export default function MenuTypeModal({ onClose }) {
  const { t } = useTranslation();
  const cart = useCart();
  const menuType = useObserver(() => cart.menuType);
  const switchMenuType = useSwitchMenuType();

  function handleClick(type) {
    if (menuType !== type) {
      switchMenuType(type);
    }
    onClose();
  }

  return (
    <StyledDropdown>
      <LinkList>
        <ListItemContainer>
          <LinkContainer onClick={() => handleClick(`rec`)}>
            <Anchor selected={menuType === `rec`}>{t('common.recreational', 'Recreational')}</Anchor>
          </LinkContainer>
        </ListItemContainer>
        <ListItemContainer>
          <LinkContainer onClick={() => handleClick(`med`)}>
            <Anchor selected={menuType === `med`}>{t('common.medical', 'Medical')}</Anchor>
          </LinkContainer>
        </ListItemContainer>
      </LinkList>
    </StyledDropdown>
  );
}

const Anchor = styled.a`
  ${({ selected }) => selected && `font-weight: bold !important`};
  font: inherit;
`;

const LinkList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 19px 0 24px 0;

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
  }
`;

const ListItemContainer = styled.div`
  width: 100%;
  padding: 0;
  height: 42px;
  display: flex;
  align-items: center;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryGrey};
  }
`;

const LinkContainer = styled.li`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[25]};
  font-size: 14px;
  font-weight: ${(props) => (props.selected ? `bold` : `normal`)};
  line-height: 2;
  padding: 0 30px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledDropdown = styled(Dropdown)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  position: relative;
  border-radius: 8px;
  /* width: 187px; */
`;
