import React from 'react';

import useUser from 'src/hooks/use-user';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { DesktopOnly, MobileOnly } from 'shared/components';

import { ModalContainer, BottomDrawer, ModalExitIcon } from 'src/components/core';
import { withModalControlsHook, ModalComponentProps } from 'src/components/modals/with-modal-controls-hook';
import { CompleteAccount } from './complete-account';

const CompleteAccountModal = ({ onClose: closeModal, containerProps }: ModalComponentProps): JSX.Element => {
  const User = useUser();
  const logoutAndClose = (): void => {
    User.logout();
    closeModal();
  };

  return (
    <>
      <DesktopOnly mediaQuery='sm'>
        <ModalWrapper open>
          <ModalContainer width='470px' p='32px' textAlign='left' {...containerProps}>
            <ModalExitIcon onClick={logoutAndClose} />
            <CompleteAccount close={closeModal} />
          </ModalContainer>
        </ModalWrapper>
      </DesktopOnly>
      <MobileOnly mediaQuery='xs'>
        <BottomDrawer open onClose={logoutAndClose} shouldCalculateIframeBottom {...containerProps}>
          <CompleteAccount close={closeModal} />
        </BottomDrawer>
      </MobileOnly>
    </>
  );
};

const [CompleteAccountController, useCompleteAccountModal] = withModalControlsHook(CompleteAccountModal);

export { useCompleteAccountModal, CompleteAccountController };
