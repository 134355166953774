import _isBoolean from "lodash/isBoolean";
import _includes from "lodash/includes";
import _isObject from "lodash/isObject";
import _isUndefined from "lodash/isUndefined";

/**
 * Launch Darkly is user-centric, so we're using JSON feature flags to enable flagging features by dispensary.
 * This helper checks if a dispensary has a feature enabled, or warns if the value is not an object.
 *
 * type DispensaryFeatureFlagValue {
 *   enabledForAll: Boolean
 *   enabledForDispensaries?: string[]
 *   variant: string
 * }
 *
 * @param {string} key
 * @param {(Object|boolean)} featureFlagValue
 * @param {string} dispensaryId
 * @param {boolean} defaultValue
 */
export default function evaluateDispensaryFeatureFlag(key, featureFlagValue, dispensaryId) {
  var defaultValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  if (!_isUndefined(featureFlagValue)) {
    if (_isObject(featureFlagValue)) {
      if (featureFlagValue !== null && featureFlagValue !== void 0 && featureFlagValue.disabledForDispensaries && _includes(featureFlagValue.disabledForDispensaries, dispensaryId)) {
        return false;
      }

      if (featureFlagValue !== null && featureFlagValue !== void 0 && featureFlagValue.enabledForAll) {
        return true;
      }

      if (!dispensaryId) {
        return defaultValue;
      }

      return _includes(featureFlagValue === null || featureFlagValue === void 0 ? void 0 : featureFlagValue.enabledForDispensaries, dispensaryId);
    }

    if (_isBoolean(featureFlagValue)) {
      return featureFlagValue;
    }
  }

  return defaultValue;
}
/**
 * Helper for checking flags in a class component
 *
 * @param {string} key: launch darkly key for feature flag
 * @param {Object} flags: launch darkly flags use withLDConsumer to access this via props (props.flags)
 * @param {string} dispensaryId
 * @param {boolean} defaultValue default returned if dispensaryId is not provided or proper LD response is not received
 */

export function flagIsEnabledForDispensary(key, flags, dispensaryId) {
  var defaultValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var featureFlagValue = flags[key];
  return evaluateDispensaryFeatureFlag(key, featureFlagValue, dispensaryId, defaultValue);
}