import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { Button } from 'components/core';
import { bold } from 'components/core/typography';

import { DispensaryInfo } from '../../components/dispensary-info';
import { StoreOptionsProps } from '../../select-store.types';

export function StoreOptions({ dispensaries, selectedStore, handleSelect }: StoreOptionsProps): JSX.Element {
  const { t } = useTranslation();
  const dispensariesToDisplay = selectedStore ? dispensaries.filter(({ id }) => id !== selectedStore.id) : dispensaries;

  return (
    <Container>
      <Header>{t('other-stores', 'Other Stores')}</Header>
      <Dispensaries>
        {dispensariesToDisplay.map((dispensary) => (
          <StyledListItem key={dispensary.id}>
            <DispensaryInfo dispensary={dispensary} isDesktop />
            <SelectButton variant='outlinePrimary' onClick={() => handleSelect(dispensary)}>
              {t('select', 'select')}
            </SelectButton>
          </StyledListItem>
        ))}
      </Dispensaries>
    </Container>
  );
}

const Container = styled.div`
  padding: 0px 11px;
  overflow-y: auto;
`;

const Header = styled.div`
  font-size: 13px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[45]};
  ${bold};
  text-align: left;
  padding: 10px 39px 0px;
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  padding: 17px 0px;
  justify-content: space-between;
  align-items: center;
  margin: 0px 36px;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  }
`;

const SelectButton = styled(Button)`
  text-transform: uppercase;
`;

const Dispensaries = styled.div`
  overflow: scroll;
`;
