import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';

import useTranslation from 'hooks/use-translation';
import useInstoreCart from 'hooks/use-instore-cart';

import { centsToDollars } from 'src/payments/utils';
import { DutchiePayTextBody } from 'src/payments/components';
import { DutchiePayInfoModal } from '../info-modal';

type InstoreTaxesModalProps = {
  onCloseModal: () => void;
};

export const DutchiePayInstoreTaxesModal = forwardRef<JSX.Element, InstoreTaxesModalProps>(({ onCloseModal }, ref) => {
  const { t } = useTranslation();
  const InstoreCart = useInstoreCart();
  const totalTaxes = useObserver(() => InstoreCart.totalTaxes);
  const taxes = useObserver(() => InstoreCart.taxes);

  return (
    <DutchiePayInfoModal ref={ref} title={t('dutchiePay.modals.info.taxes.header', 'Taxes')} onClose={onCloseModal}>
      <TaxBreakdownContainer>
        {taxes.map((taxItem) => (
          <TaxItem key={taxItem.name}>
            <Name>{taxItem.name}</Name>
            <Text>{centsToDollars(taxItem.valueCents)}</Text>
          </TaxItem>
        ))}

        <TaxItem data-testid='instore-cart-taxes-modal.total-taxes'>
          <Name>{t('dutchiePay.modals.info.taxes.total-taxes', 'Total Taxes')}</Name>
          <TextBold>{centsToDollars(totalTaxes)}</TextBold>
        </TaxItem>
      </TaxBreakdownContainer>
      <FooterText>
        {t(
          'dutchiePay.modals.body.instore-cart.taxes-description',
          'Taxes are set by {{ dispoName }} to follow state compliance regulations, ' +
            'and may include both state and local taxes. ' +
            'Discounts for this dispensary are applied before taxes.',
          { dispoName: InstoreCart.dispensary.name }
        )}
      </FooterText>
    </DutchiePayInfoModal>
  );
});

const Text = styled(DutchiePayTextBody)`
  margin-bottom: 0;
  font-size: 13px;
`;

const Name = styled(Text)`
  text-transform: capitalize;
`;

const TextBold = styled(Text)`
  font-weight: 700;
`;

const FooterText = styled(DutchiePayTextBody)`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

const TaxItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TaxBreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
