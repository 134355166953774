/* eslint-disable max-len */
import React from 'react';

const SvgHomeIcon = (props) => (
  <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M22.0048 9.614C19.2268 7.152 16.4518 4.685 13.6548 2.243C13.2888 1.931 12.7078 1.93 12.3378 2.253L1.65982 11.684C1.14982 12.093 0.323818 11.927 0.0728179 11.315C-0.0601821 10.991 -0.00818199 10.6 0.205818 10.323C0.256818 10.256 0.274818 10.243 0.335818 10.184L11.0168 0.75C11.5548 0.28 12.2588 0.012 12.9688 0C13.0018 0 13.0018 0 13.0348 0C13.7458 0.012 14.4478 0.28 14.9858 0.75L25.6678 10.184C25.8908 10.401 25.9208 10.494 25.9688 10.661C26.1548 11.32 25.4768 12.085 24.7608 11.904C24.6078 11.865 24.4698 11.785 24.3438 11.684C24.2308 11.584 24.1188 11.485 24.0058 11.385V22.941C23.9958 24.455 22.7268 25.858 21.1538 25.936C21.1158 25.938 21.0788 25.939 21.0418 25.939C15.6928 25.972 10.3448 25.939 4.99582 25.939C3.42982 25.93 2.00782 24.576 1.99782 22.941V9.18505C2.01082 8.90005 2.05182 8.82305 2.13182 8.68405C2.43382 8.16205 3.25582 8.02705 3.70582 8.47705C3.84982 8.62205 3.94782 8.81005 3.98382 9.01105C3.99682 9.08705 3.99482 9.10705 3.99882 9.18505C3.99882 12.0561 3.98082 20.079 3.99882 22.951C4.00782 23.465 4.44582 23.929 4.98682 23.939C8.65882 23.961 12.3308 23.969 16.0028 23.96V13.935H10.0008V24.4771C9.99182 24.7291 9.95782 24.8041 9.88882 24.937C9.58082 25.531 8.60982 25.659 8.18282 25.0541C8.09082 24.923 8.03082 24.7711 8.00982 24.6131C8.00082 24.5531 8.00182 24.5371 7.99982 24.4771V12.935C8.00682 12.725 8.02582 12.69 8.05782 12.6C8.17982 12.256 8.49982 11.994 8.86382 11.944C8.92482 11.936 8.93982 11.937 9.00082 11.935H17.0028C17.0258 11.935 17.0488 11.936 17.0718 11.937C17.2798 11.958 17.3138 11.979 17.4018 12.017C17.7568 12.171 17.9898 12.543 18.0038 12.935V23.954C19.0078 23.95 20.0128 23.945 21.0168 23.939C21.5378 23.929 22.0018 23.481 22.0048 22.935V9.614Z'
      fill='#969EA5'
    />
  </svg>
);

export default SvgHomeIcon;
