import React from 'react';
import styled from 'styled-components';
import { space, height, width } from 'styled-system';

export default function Divider(props) {
  return <StyledDivider {...props} />;
}

const StyledDivider = styled.span`
  border-left: ${({ _color, theme }) => `1px solid ${_color || theme.colors.blueGrey[95]}`};
  width: 1px;
  height: 100%;
  max-height: 100%;
  display: block;
  ${space}
  ${height}
  ${width}
`;
