import React from 'react';
import { useObserver } from 'mobx-react-lite';

import useProduct from 'hooks/use-product';
import { imageToUse } from 'shared/helpers/products';
import JsonLDScript from './json-ld';

import Meta from './meta';

export default function ProductTags({ url }) {
  const { product } = useProduct();
  const image = useObserver(() => (product ? imageToUse(product) : null));

  if (!product) {
    return null;
  }

  return (
    <>
      <Meta property='og:image' content={image} />
      <JsonLDScript variant='product' jsonLdData={{ ...product, url }} />
    </>
  );
}
