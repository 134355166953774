import { useMemo, useState } from 'react';
import { GqlIpInfo, useGeolocateConsumerQuery } from 'types/graphql';

import { useIsFirstRender } from 'hooks/use-is-first-render';

export default function useGeoinfo(): [GqlIpInfo | null, (region: string) => void, boolean] {
  const isFirstRender = useIsFirstRender();
  const [regionOverride, setRegionOverride] = useState<string | null>(null);

  const { data, loading } = useGeolocateConsumerQuery({ skip: process.env.NODE_ENV === 'test' || isFirstRender });
  const geoinfo = data?.geolocate;
  const geoinfoWithRegionOverride = useMemo(
    () => (geoinfo && regionOverride ? { ...geoinfo, region: regionOverride } : geoinfo),
    [geoinfo, regionOverride]
  );

  function setRegion(region: string): void {
    setRegionOverride(region);
  }
  return [geoinfoWithRegionOverride ?? null, setRegion, loading];
}
