import { useMemo } from 'react';
import { removeTypename } from 'shared/helpers/utils';

import { parseProduct, Product } from 'utils/helpers/product';
import useOrderType from 'hooks/use-order-type';
import { useAddressBounds } from 'shop/hooks/use-address-bounds';

import { GraphqlError } from 'src/errors';
import { useGetBuyingOptionsQuery, GqlGetBuyingOptionsQueryVariables, OrderType } from 'types/graphql';

type UseGetBuyingOptionsReturn = {
  buyingOptions: Product[] | undefined;
  loading: boolean;
};

enum SortBy {
  time = 'time',
  distance = 'distance',
}

function useVariables(productId: string): GqlGetBuyingOptionsQueryVariables | undefined {
  const { getOrderType } = useOrderType();
  const orderType = getOrderType();
  const bounds = useAddressBounds();

  const sortBy = orderType === OrderType.delivery ? SortBy.time : SortBy.distance;

  if (!bounds) {
    return undefined;
  }

  return {
    marketplaceProductId: productId,
    bounds,
    sortBy,
  };
}

export function useGetBuyingOptions(productId: string): UseGetBuyingOptionsReturn {
  const variables = useVariables(productId);

  const { data, error, loading } = useGetBuyingOptionsQuery({ variables, skip: !variables });

  if (error) {
    throw new GraphqlError(error);
  }

  const buyingOptions = useMemo(
    () =>
      removeTypename(data?.getProductBuyingOptions?.buyingOptions.map((option) => parseProduct(option.product)) ?? []),
    [data?.getProductBuyingOptions?.buyingOptions]
  );
  return { buyingOptions, loading };
}
