import React from 'react';

import useTranslation from 'hooks/use-translation';
import useUser from 'hooks/use-user';
import useErnie from 'shared/hooks/use-ernie';

import { useModals, ModalNames } from 'components/modals';

import MyAccountPopover from './my-account-popover';

const MyAccountPopoverWrapper = ({ isLoggedIn, anchorEL }) => {
  const { t } = useTranslation();
  const User = useUser();
  const { openModal } = useModals();
  const ernie = useErnie();

  const handleOptionClick = (value) => {
    openModal(value === 'login' ? ModalNames.login : ModalNames.signUp);
  };

  const handleOnLogout = () => {
    User.logout();
    ernie(t('ernie.logout', "You've been successfully logged out."), 'success');
  };

  return (
    <MyAccountPopover
      isLoggedIn={isLoggedIn}
      onOptionClick={handleOptionClick}
      onLogout={handleOnLogout}
      anchorEL={anchorEL}
    />
  );
};

export default MyAccountPopoverWrapper;
