import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _find from "lodash/find";
import _values from "lodash/values";
import _isFinite from "lodash/isFinite";
import _lowerCase from "lodash/lowerCase";
import _sum from "lodash/sum";
import _get from "lodash/get";
import _filter from "lodash/filter";
import _reduce from "lodash/reduce";
import _uniq from "lodash/uniq";
import _keys from "lodash/keys";
import _map from "lodash/map";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { checkLimits } from 'shared/helpers/check-limits';
import { getLimitPerCustomer, optionValMap, categorySingularize } from 'shared/helpers/products';
import { ERR_OVER_CATEGORY_LIMIT, ERR_OVER_PURCHASE_LIMIT } from 'shared/checkout/actions/validate-cart';

function checkCategoryLimits(_ref) {
  var cart = _ref.cart,
      dispensary = _ref.dispensary;
  var categoryLimitsEnabled = dispensary.categoryLimitsEnabled,
      categoryLimits = dispensary.categoryLimits;

  var productTypes = _map(_keys(cart), function (k) {
    return cart[k].product.type;
  });

  var quantities = _map(_keys(cart), function (k) {
    return cart[k].quantity;
  });

  var options = _map(_keys(cart), function (k) {
    return cart[k].option;
  });

  if (categoryLimitsEnabled) {
    /* eslint-disable */
    var _iterator = _createForOfIteratorHelper(_uniq(productTypes)),
        _step;

    try {
      var _loop = function _loop() {
        var type = _step.value;

        var indexes = _reduce(productTypes, function (acc, val, index) {
          return val === type ? [].concat(_toConsumableArray(acc), [index]) : acc;
        }, []);

        var _filter2 = _filter(categoryLimits, function (c) {
          return c.name === type;
        }),
            _filter3 = _slicedToArray(_filter2, 1),
            _filter3$ = _filter3[0];

        _filter3$ = _filter3$ === void 0 ? {} : _filter3$;
        var _filter3$$value = _filter3$.value,
            limitForType = _filter3$$value === void 0 ? false : _filter3$$value;

        var amnts = _map(indexes, function (i) {
          if (type === 'Flower') {
            return _get(optionValMap, options[i], 0.0) * parseInt(quantities[i], 10);
          }

          return parseInt(quantities[i], 10);
        });

        if (limitForType && parseFloat(limitForType) < _sum(amnts)) {
          var singularType = _lowerCase(categorySingularize(type));

          var message = "Sorry, we only allow the purchase of ".concat(limitForType, "g of ").concat(singularType, " per customer.");

          if (type !== 'Flower') {
            message = "Sorry, we only allow the purchase of ".concat(limitForType, " ").concat(singularType, " items per customer.");
          }

          return {
            v: {
              withinLimits: false,
              targetTypes: type,
              message: message
            }
          };
        }
      };

      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _ret = _loop();

        if (typeof _ret === "object") return _ret.v;
      }
      /* eslint-enable */

    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }

  return {
    withinLimits: true
  };
}

function checkLimitPerCustomer(_ref2) {
  var newItemId = _ref2.newItemId,
      cart = _ref2.cart;
  var _cart$newItemId = cart[newItemId],
      product = _cart$newItemId.product,
      option = _cart$newItemId.option,
      quantity = _cart$newItemId.quantity;
  var limitPerCustomer = getLimitPerCustomer(product, option);

  if (_isFinite(limitPerCustomer) && limitPerCustomer < quantity) {
    return {
      withinLimits: false,
      message: "We're sorry, this item has a limit of ".concat(limitPerCustomer, " per customer")
    };
  }

  return {
    withinLimits: true
  };
}

export function checkAllLimits(_ref3) {
  var newItemId = _ref3.newItemId,
      itemBeingAdded = _ref3.itemBeingAdded,
      _ref3$itemBeingRemove = _ref3.itemBeingRemoved,
      itemBeingRemoved = _ref3$itemBeingRemove === void 0 ? null : _ref3$itemBeingRemove,
      existingItems = _ref3.existingItems,
      medical = _ref3.medical,
      dispensary = _ref3.dispensary,
      customerState = _ref3.customerState,
      _ref3$changingQuantit = _ref3.changingQuantity,
      changingQuantity = _ref3$changingQuantit === void 0 ? false : _ref3$changingQuantit,
      _ref3$complianceOnly = _ref3.complianceOnly,
      complianceOnly = _ref3$complianceOnly === void 0 ? false : _ref3$complianceOnly;

  var updatedCart = _objectSpread({}, existingItems);

  if (newItemId && itemBeingAdded) {
    var itemsInCart = _values(existingItems || {});

    var matchingProduct = _find(itemsInCart, {
      id: itemBeingAdded.product.id,
      option: itemBeingAdded.option
    });

    var newQuantity = Number(itemBeingAdded.quantity);

    if (matchingProduct && !changingQuantity) {
      newQuantity += Number(matchingProduct.quantity);
    }

    updatedCart = _objectSpread(_objectSpread({}, existingItems), {}, _defineProperty({}, newItemId, _objectSpread(_objectSpread({}, itemBeingAdded), {}, {
      quantity: newQuantity
    })));
  } // if we're simultaneously removing an item (ie. changing weights)
  // make sure we factor that in before we check limits


  if (itemBeingRemoved) {
    delete updatedCart[itemBeingRemoved];
  }

  var withinDispensaryCategoryLimits = checkCategoryLimits({
    cart: updatedCart,
    dispensary: dispensary
  });

  if (!withinDispensaryCategoryLimits.withinLimits) {
    return _objectSpread(_objectSpread({}, withinDispensaryCategoryLimits), {}, {
      code: ERR_OVER_CATEGORY_LIMIT
    });
  }

  var withinLegalPurchasingLimits = checkLimits({
    medical: medical,
    state: dispensary.location.state,
    cart: updatedCart,
    purchaseState: customerState
  });

  if (!dispensary.storeSettings.disablePurchaseLimits && !withinLegalPurchasingLimits.withinLimits) {
    return _objectSpread(_objectSpread({}, withinLegalPurchasingLimits), {}, {
      code: ERR_OVER_PURCHASE_LIMIT
    });
  }

  if (complianceOnly) {
    return {
      withinLimits: true
    };
  }

  if (newItemId) {
    var withinDispensaryItemLimits = checkLimitPerCustomer({
      newItemId: newItemId,
      cart: updatedCart
    });

    if (!withinDispensaryItemLimits.withinLimits) {
      return withinDispensaryItemLimits;
    }
  }

  return {
    withinLimits: true
  };
}