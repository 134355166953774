/**
 * @fileOverview See {@link ../README.md}'s "/trackers/*" section for details.
 */

import { ContextSetter, PayloadHandlers } from 'utils/analytics/helpers/fan-out';
import { AggregateTrackerConfig, createAggregateTracker } from './aggregate-tracker';
import { createDelayedCallProxy } from '../helpers/delayed-call-proxy';
import { AnalyticsContext, Events } from '../events';

/**
 * The delayed call tracker allows tracking events to be captured + queued before
 * initTracker is called.
 */
const delayedCallAggregateTracker = createDelayedCallProxy(createAggregateTracker());

/**
 * An object that looks like:
 * {
 *   // method for each Event in Events
 *   addedProductToCart: (payload: Events['addedProductToCart'], context: AnalyticsContext) => void;
 *   placedOrder: (payload: Events['placedOrder'], context: AnalyticsContext) => void;
 *   ...
 *
 *   // to set the context passed to all event handlers
 *   setContext: (context: AnalyticsContext) => void
 * }
 */
export const tracker: ContextSetter<AnalyticsContext> & PayloadHandlers<Events, void> = delayedCallAggregateTracker;

export const initTracker = (config: AggregateTrackerConfig): void => {
  const newTracker = createAggregateTracker(config);
  delayedCallAggregateTracker.updateClient(newTracker);
};
