/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export const Loyalty = (props: SVGAttributes<unknown>): JSX.Element => (
  <svg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='36' cy='36' r='36' fill='#D3EDFF' />
    <g clipPath='url(#clip0_3922_57004)'>
      <path d='M24.6754 38.3335L18.167 46.2872L24.3193 47.5177L26.7803 54.9005L33.328 44.5121' fill='#8BC6EF' />
      <path
        d='M24.6754 38.3335L18.167 46.2872L24.3193 47.5177L26.7803 54.9005L33.328 44.5121'
        stroke='#0B99E6'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M45.4868 38.3335L51.9951 46.2872L45.8428 47.5177L43.3818 54.9005L36.8341 44.5121' fill='#8BC6EF' />
      <path
        d='M45.4868 38.3335L51.9951 46.2872L45.8428 47.5177L43.3818 54.9005L36.8341 44.5121'
        stroke='#0B99E6'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M36.0635 18.3752L39.1437 20.7393L43.0943 20.5513C43.3793 20.5379 43.6606 20.6179 43.8914 20.7781C44.1223 20.9384 44.2888 21.1692 44.3634 21.4323L45.3974 25.0804L48.7093 27.1514C48.9498 27.3012 49.1292 27.5253 49.2175 27.7862C49.3058 28.0472 49.2977 28.3292 49.1946 28.5851L47.7854 32.1089L49.1946 35.6391C49.2959 35.8941 49.303 36.1744 49.2148 36.4337C49.1265 36.6929 48.9482 36.9157 48.7093 37.0649L45.3974 39.1359L44.3634 42.784C44.2888 43.0471 44.1223 43.2779 43.8914 43.4382C43.6606 43.5984 43.3793 43.6784 43.0943 43.6649L39.1437 43.477L36.0635 45.8474C35.8411 46.0182 35.5643 46.1113 35.2789 46.1113C34.9935 46.1113 34.7167 46.0182 34.4943 45.8474L31.4141 43.4722L27.4634 43.6602C27.1782 43.6737 26.8967 43.5938 26.6656 43.4335C26.4345 43.2733 26.2676 43.0424 26.1927 42.7792L25.1604 39.1311L21.8484 37.0601C21.6089 36.9115 21.43 36.6888 21.3416 36.4293C21.2533 36.1698 21.2609 35.8892 21.3631 35.6344L22.7706 32.1089L21.3631 28.5771C21.2591 28.3213 21.2505 28.039 21.3389 27.7778C21.4273 27.5167 21.6073 27.2926 21.8484 27.1434L25.1604 25.0724L26.1927 21.4243C26.2676 21.1611 26.4345 20.9302 26.6656 20.77C26.8967 20.6098 27.1782 20.5298 27.4634 20.5434L31.4141 20.7393L34.4943 18.3752C34.7167 18.2044 34.9935 18.1113 35.2789 18.1113C35.5643 18.1113 35.8411 18.2044 36.0635 18.3752Z'
        fill='#D3EDFF'
        stroke='#0B99E6'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M34.6476 25.1098C34.9067 24.593 35.6504 24.593 35.9095 25.1098L37.6556 28.5934C37.7582 28.7981 37.9553 28.94 38.1834 28.9735L42.0653 29.5431C42.6412 29.6276 42.8711 30.3288 42.4552 30.7328L39.6525 33.4554C39.4878 33.6153 39.4125 33.845 39.4509 34.0704L40.1039 37.9061C40.2008 38.4751 39.5991 38.9085 39.083 38.6413L35.6047 36.8403C35.4004 36.7345 35.1567 36.7345 34.9524 36.8403L31.474 38.6413C30.958 38.9085 30.3563 38.4751 30.4531 37.9061L31.1062 34.0704C31.1446 33.845 31.0693 33.6153 30.9046 33.4554L28.1018 30.7328C27.686 30.3288 27.9158 29.6276 28.4918 29.5431L32.3737 28.9735C32.6018 28.94 32.7989 28.7981 32.9015 28.5934L34.6476 25.1098Z'
        fill='white'
      />
    </g>
    <circle cx='50.25' cy='12.75' r='2.25' fill='#0B99E6' />
    <circle cx='11.25' cy='38.25' r='2.25' fill='#0B99E6' />
    <circle cx='30.001' cy='63' r='1.5' fill='#0B99E6' />
    <path d='M19.5 12V18M16.5 15L22.5 15' stroke='#0B99E6' strokeWidth='1.5' strokeLinecap='round' />
    <path d='M60 37.5V43.5M57 40.5L63 40.5' stroke='#0B99E6' strokeWidth='1.5' strokeLinecap='round' />
    <defs>
      <clipPath id='clip0_3922_57004'>
        <rect width='37.5' height='42' fill='white' transform='translate(16.5 15)' />
      </clipPath>
    </defs>
  </svg>
);
