import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import useColors from 'hooks/use-colors';

import { DesktopOnly, MobileOnly, Clickable } from 'shared/components';
import PinIcon from 'assets/pin-icon';
import ChevronIcon from 'assets/chevron-icon';
import { OrderTypes } from 'state/search-preferences';
import { useAddressTextButton } from './use-address-text-button';

export type AddressTextButtonProps = {
  onClick: () => void;
  address?: string | null;
  orderType: OrderTypes;
};

export const AddressTextButton = observer(({ onClick, address, orderType }: AddressTextButtonProps) => {
  const colors = useColors();

  const { addressCopy, desktopOrderTypeCopy, isMarketplace, mobileOrderTypeCopy } = useAddressTextButton({
    address,
    orderType,
  });

  return (
    <Container onClick={onClick}>
      {!isMarketplace && (
        <DesktopOnly mediaQuery='sm'>
          <PinIcon fill={colors.green[95]} height='22px' />
        </DesktopOnly>
      )}
      <TextContainer isMarketplace={isMarketplace}>
        <OrderTypeText>
          <DesktopOnly>{desktopOrderTypeCopy}</DesktopOnly>
          <MobileOnly>{mobileOrderTypeCopy}</MobileOnly>
        </OrderTypeText>
        <AddressText data-testid='location-address'>{addressCopy}</AddressText>
      </TextContainer>
      {!isMarketplace && <StyledChevronIcon />}
    </Container>
  );
});

const Container = styled(Clickable)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 9px 20px;
    background: #061625;
  }
`;

const OrderTypeText = styled.b`
  margin-right: 5px;
  white-space: nowrap;
  font-size: 14px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 12px;
  }
`;

const AddressText = styled.p`
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 12px;
    display: inline;
  }
`;

const StyledChevronIcon = styled(ChevronIcon)`
  height: 11px;
  width: 11px;
  margin-left: 10px;
  fill: ${({ theme }) => theme.colors.green[90]};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 17px;
  }
`;

const TextContainer = styled.div`
  align-items: center;
  white-space: nowrap;
  max-width: ${({ isMarketplace }) => isMarketplace && `120px`};
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-left: 10px;
    text-align: start;
  }
`;
