import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import { TypographyNode } from '../types';

type Props = {
  className?: string;
  styles: string;
  children: TypographyNode;
  as?: string;
};

const Component = styled.p``;

export const Typography = ({ children, className, styles, as, ...props }: Props): JSX.Element | null => {
  const translation = useTranslation();

  return (
    <Component className={className} css={styles} as={as} {...props}>
      {typeof children === 'function' ? children(translation) : children}
    </Component>
  );
};
