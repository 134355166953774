import React from 'react';
import _ from 'lodash';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useRouter } from 'next/router';

import usePaths from 'hooks/use-paths';
import { getPersistedValue } from 'shared/utils/persisted-values';

import ROUTES from 'src/routes';

import { tracker } from 'src/utils/analytics';
import { MenuNavMobile } from './menu-nav-mobile';

const MenuNavMobileWrapper = ({ toggleCartView, ...props }) => {
  const router = useRouter();
  const { dispensary } = useDispensary();
  const { href } = usePaths({ dispensary });
  if (!dispensary) {
    return null;
  }
  const { name: dispensaryName } = dispensary;
  const isMenuPage = router.route === ROUTES.DISPENSARY_CNAME;

  const handleIconClick = (icon) => {
    if (icon === `home`) {
      tracker.gaGTMClickEvent({
        eventAction: `main menu`,
        eventCategory: `navigation click`,
        eventLabel: `home`,
      });
      router.push(href);
    } else if (icon === `back`) {
      const query = {};
      if (getPersistedValue(`dispensariesSearchParams`)) {
        _.forEach(getPersistedValue(`dispensariesSearchParams`), (val, key) => (query[key] = val === true ? 1 : val));
      }
      router.push({ pathname: ROUTES.DISPENSARIES, query });
    } else {
      toggleCartView();
    }
  };

  return (
    <MenuNavMobile dispensaryName={dispensaryName} isMenuPage={isMenuPage} onIconClick={handleIconClick} {...props} />
  );
};

export default MenuNavMobileWrapper;
