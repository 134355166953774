import React from 'react';
import styled from 'styled-components';

import { SmallLoader } from 'shared/components';

export default function GlobalFallback() {
  // it's a spinner.
  return (
    <Content>
      <SmallLoader />
    </Content>
  );
}

const Content = styled.div`
  margin-top: 200px;
`;
