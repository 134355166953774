export type Dimensions = {
  mobile: number;
  desktop: number;
  padding: number;
  gutter: number;
};

export enum CardSizes {
  default = 'default',
  small = 'small',
}

export const CARD_SIZES = {
  default: {
    mobile: 138,
    desktop: 170,
    padding: 16,
    gutter: 0,
  },
  small: {
    mobile: 88,
    desktop: 106.5,
    padding: 16,
    gutter: 12,
  },
};
