import _trim from "lodash/trim";
import _replace from "lodash/replace";
import _includes from "lodash/includes";
var LEGACY_STRING = 'https://s3-us-west-2.amazonaws.com/dutchie-images/';
var IMGIX_SOURCE = 'https://images.dutchie.com/';
export var ensureSecureUrlProtocol = function ensureSecureUrlProtocol(src) {
  if (_includes(src, 'http://')) {
    src = _replace(src, 'http://', 'https://');
  }

  return src;
};
export var replaceAWSSourceWithImgix = function replaceAWSSourceWithImgix(src) {
  src = _trim(src || ''); // Replace S3 with imgix.

  if (_includes(src, LEGACY_STRING)) {
    src = _replace(src, LEGACY_STRING, IMGIX_SOURCE);
  }

  return ensureSecureUrlProtocol(src);
};