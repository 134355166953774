import React from 'react';
import useTranslation from 'hooks/use-translation';

import { ProductRecommendationSection } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { ProductCarousel } from 'src/components/product-carousel';
import { useRecentlyViewedCarousel } from './use-recently-viewed-carousel';

export type RecentlyViewedCarouselProps = {
  dispensaryId: string;
  userId: string;
};

export function RecentlyViewedCarousel({ dispensaryId, userId }: RecentlyViewedCarouselProps): JSX.Element | null {
  const { t } = useTranslation();
  const carouselProps = useRecentlyViewedCarousel({
    dispensaryId,
    userId,
  });
  const { products } = carouselProps;

  if (!products.length) {
    return null;
  }

  return (
    <ProductCarousel
      heading={t(`common.recentlyViewed`, `Recently Viewed Items`)}
      trackerSection={ProductRecommendationSection.recentlyViewed}
      {...carouselProps}
    />
  );
}
