import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { Card, GreyCapsText } from 'components/core';
import _ProductImage from 'components/product-image';
import { breakpoints } from 'components/carousel-slider/helpers';

export const productCardContainerStyles = css`
  padding: 19px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  @media (min-width: 600px) {
    max-width: 198px;
  }
`;

export const Container = styled(Card)`
  ${productCardContainerStyles}
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
`;

export const ChipContainer = styled.div`
  padding-top: 5px;
  ${space}
`;

export const productCardImageStyles = css`
  margin-bottom: 12px;
  border-radius: 3px;
`;

export const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ProductImage = styled(_ProductImage)`
  ${productCardImageStyles}
  min-height: ${({ height }) => height && `${String(height)}px`};

  ${({ dimensions }) =>
    dimensions &&
    css`
      width: ${dimensions.mobile}px;
      height: ${dimensions.mobile}px;
      min-height: ${dimensions.mobile}px;

      @media (min-width: ${breakpoints.tablet}px) {
        width: ${dimensions.desktop}px;
        height: ${dimensions.desktop}px;
        min-height: ${dimensions.desktop}px;
      }
    `}
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${({ dimensions }) =>
    dimensions &&
    css`
      max-width: ${dimensions.mobile}px;

      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${dimensions.desktop}px;
      }
    `}
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  padding-bottom: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Brand = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 7px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const StyledGreyCapsText = styled(GreyCapsText)`
  font-size: 11px;
  font-weight: bold;
  line-height: 150%;
  text-transform: none;
`;

export const FlowerGreyCapsText = styled(StyledGreyCapsText)`
  display: block;
  line-height: 125%;
  padding-bottom: 6px;

  &:first-child {
    padding-top: 1px;
    text-transform: capitalize !important;
  }

  &:last-child {
    padding-bottom: 0;
    text-transform: uppercase !important;
  }
`;

export const BadgesContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  padding-top: 4px;
`;

export const PriceSection = styled.div`
  color: ${({ primaryColor, specialsColor, theme }) =>
    specialsColor ? theme.colors.red[60] : primaryColor ?? theme.customized.colors.buttonsLinks};
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 7px;
`;
