import { useCallback } from 'react';

import useUI from 'hooks/use-ui';
import useCart from 'hooks/use-cart';
import useSearchPreferences from 'hooks/use-search-preferences';
import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';

import { ORDER_TYPE_PICKUP } from 'shared/constants';
import ROUTES from 'src/routes';
import routeTo from 'utils/route-to';

import { OnSaveLocationSettings, OnSaveLocationSettingsParams } from 'components/location-settings-popover';

export function useSaveLocationSettings(): OnSaveLocationSettings {
  const { route, toggleLocationSettingsDropdown } = useUI();
  const { updateAddress } = useCart();
  const SearchPreferences = useSearchPreferences();
  const { setQueryParams } = useDispensariesQueryParams();

  const saveLocationSettings = useCallback(
    ({ address, orderType, distance }: OnSaveLocationSettingsParams): void => {
      updateAddress({ location: address });
      SearchPreferences.setOrderType(orderType);
      window.scrollTo(0, 0);

      if (route !== ROUTES.DISPENSARIES) {
        routeTo({ url: ROUTES.DISPENSARIES, queryParams: { distance } });
      } else if (orderType === ORDER_TYPE_PICKUP) {
        setQueryParams({ distance });
      }

      toggleLocationSettingsDropdown();
    },
    [SearchPreferences, route, setQueryParams, toggleLocationSettingsDropdown, updateAddress]
  );

  return saveLocationSettings;
}
