import { useProductOptionsState } from 'hooks/use-product-options-state';
import { useProductOffering } from 'hooks/use-product-offering';

import { BadgeType } from '../cart-item-details';
import { SelectWeightControllerProps, UseSelectWeightReturn } from './select-weight.types';

export function useSelectWeight({ product }: SelectWeightControllerProps): UseSelectWeightReturn {
  const {
    handleSetSelectOption,
    handleQuantityDecrement,
    handleQuantityIncrement,
    hasQuantityRemaining,
    options,
    quantity,
    selectedOption,
  } = useProductOptionsState(product);
  const { getProductHasOffers } = useProductOffering();

  const disableIncrementing = !hasQuantityRemaining;
  const disableDecrementing = quantity === 1;

  const badge = getProductHasOffers(product) ? { type: BadgeType.offer, text: `Special Offer` } : undefined;

  return {
    badge,
    disableDecrementing,
    disableIncrementing,
    handleQuantityDecrement,
    handleQuantityIncrement,
    handleSetSelectOption,
    options,
    quantity,
    selectedOption,
  };
}
