import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import { Option, Select } from 'src/components/core';
import useTranslation from 'src/hooks/use-translation';

type QuantitySelectProps = {
  onQuantityChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  quantity: number;
  quantitiesAvailableForSelection: number[];
  children?: React.ReactNode;
};

export function QuantitySelect({
  onQuantityChange,
  quantity,
  quantitiesAvailableForSelection,
  children,
}: QuantitySelectProps): JSX.Element {
  const { t } = useTranslation();

  const useQuantitySelectStyles = makeStyles({
    paper: {
      width: 70,
      marginTop: 2,
    },
    list: {
      '& li': {
        height: `35px`,
        minHeight: `0`,
        paddingLeft: `23px`,
      },
    },
  });

  const quantitySelectClasses = useQuantitySelectStyles();

  return (
    <StyledSelect
      SelectDisplayProps={{
        'aria-label': t('common.quantity', 'Quantity'),
        'data-testid': `quantity-select`,
      }}
      onChange={onQuantityChange}
      value={quantity}
      MenuProps={{ classes: { paper: quantitySelectClasses.paper, list: quantitySelectClasses.list } }}
    >
      {quantitiesAvailableForSelection.map((num) => (
        <Option key={num} value={num} data-testid='quantity-select-option'>
          <OptionText>{num}</OptionText>
        </Option>
      ))}

      {children}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  border-radius: ${({ theme }) => theme.customized.radius.buttons} !important;
  height: 32px;
  width: 55px;

  .MuiSelect-root {
    left: -7px;
    position: relative;
  }

  > svg {
    width: 10px;
  }
`;

const OptionText = styled.div`
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
