import {
  Maybe,
  GqlProducts,
  GqlProductPotency as OrigGqlProductPotency,
  GqlBrand as OrigGqlBrand,
} from 'types/graphql';

export type GqlDescription = GqlProducts['description'];
export type GqlStrainType = GqlProducts['strainType'];
export type GqlProductPotency = Maybe<OrigGqlProductPotency>;
export type GqlBrand = Maybe<Pick<OrigGqlBrand, 'description' | 'imageUrl' | 'name'>>;

export enum StrainType {
  oneToOne = '1 to 1',
  twoToOne = '2 to 1',
  fiveToOne = '5 to 1',
  tenToOne = '10 to 1',
  twentyToOne = '20 to 1',
  fiftyToOne = '50 to 1',
  highCbd = 'High CBD',
  hybrid = 'Hybrid',
  indica = 'Indica',
  indicaHybrid = 'Indica-Hybrid',
  nA = 'N/A',
  sativa = 'Sativa',
  sativaHybrid = 'Sativa-Hybrid',
  seed = 'Seed',
  thc = 'THC',
}

export enum Effect {
  body = 'Body',
  calm = 'Calm',
  clearMind = 'Clear Mind',
  clearMind2 = 'Clear-Mind',
  comfort = 'Comfort',
  creative = 'Creative',
  energetic = 'Energetic',
  euphoric = 'Euphoric',
  focused = 'Focused',
  happy = 'Happy',
  inspired = 'Inspired',
  joints = 'Joints',
  medicinal = 'Medicinal',
  mind = 'Mind',
  muscles = 'Muscles',
  painRelief = 'Pain_Relief',
  painRelief2 = 'Pain-Relief',
  relaxed = 'Relaxed',
  skinHealth = 'Skin Health',
  skinHealth2 = 'Skin-Health',
  sleepy = 'Sleepy',
  uplifted = 'Uplifted',
}
