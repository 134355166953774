import { useFormContext } from 'react-hook-form';

// useFormContext can return null; but it's not typed as such
export type FormContext = ReturnType<typeof useFormContext> | null;

export function reactFormIsComplete(formContext: FormContext, requiredFieldKeys: string[]): boolean {
  if (requiredFieldKeys.length === 0) {
    return true;
  }
  if (formContext == null) {
    throw new Error(
      `DutchiePayButton: FormContext is null but requiredFieldKeys are specified. ` +
        `Did you forget to wrap the button in a <Form />?`
    );
  }

  const { errors, formState } = formContext;
  const { dirtyFields, isDirty } = formState;
  const allPopulated = requiredFieldKeys.every((field) => Object.keys(dirtyFields).includes(field));

  return isDirty && allPopulated && Object.keys(errors).length === 0;
}
