import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers';
import _ from 'lodash';

const Form = ({
  children,
  onSubmit,
  defaultValues = {},
  validationSchema,
  watch = false,
  mode = `onSubmit`,
  prefilledValues = {},
  ...props
}) => {
  const formArgs = {
    mode,
    defaultValues,
  };

  if (validationSchema) {
    formArgs.resolver = yupResolver(validationSchema);
  }

  const methods = useForm(formArgs);

  useEffect(() => {
    if (!_.isEmpty(prefilledValues)) {
      _.forEach(Object.entries(prefilledValues), ([name, value]) => {
        if (value) {
          methods.setValue(name, value, { shouldDirty: true, shouldValidate: true });
        }
      });
    }
  }, [methods.setValue, prefilledValues]);

  if (watch) {
    methods.watch();
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} onReset={() => methods.reset(defaultValues)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
