import { useEffect } from 'react';

import useUI from 'hooks/use-ui';

import { tracker } from '..';

export const useProductPositionMonitor = (): void => {
  const UI = useUI();

  useEffect(() => {
    tracker.setContext({
      activeProductPosition: UI?.productPosition?.productIndex,
    });
  }, [UI?.productPosition?.productIndex]);
};
