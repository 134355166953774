import React from 'react';
import { ModalContainer } from 'shared/modals/v2-container';
import styled, { css } from 'styled-components';
import { ModalContent as _ModalContent } from 'components/modals/components';
import CreditCardIcon from 'assets/credit-card-icon';
import { Typography } from 'src/typography';
import useTranslation from 'hooks/use-translation';
import { ModalObserverRenderProps } from 'components/modals/modal-observer/modal-observer.types';
import LoadingSpinner from 'assets/loader/spinner';

export const RethinkPayOrderProcessingModal = ({ containerProps }: ModalObserverRenderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ModalContainer padding={0} width={420} {...containerProps}>
      <ModalContent>
        <IconContainer>
          <Loader>
            <LoadingSpinner size={13} />
          </Loader>
          <CreditCardIcon />
        </IconContainer>
        <Heading>{t('common.processingPayment', 'Processing your payment')}</Heading>
        <StyledText size='medium'>
          {t('common.dontCloseBrowser', "Please don't close your browser while we are processing your payment")}
        </StyledText>
      </ModalContent>
    </ModalContainer>
  );
};
const ModalContent = styled(_ModalContent)`
  ${({ theme: { spaces } }) => css`
    padding: ${spaces[14]} ${spaces[10]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${spaces[4]};
  `}
`;

const Heading = styled.h2`
  ${({ theme: { colors } }) => css`
    font-size: 18px;
    color: ${colors.dutchiePayBlack};
  `}
`;

const StyledText = styled(Typography.Body)`
  ${({ theme: { colors } }) => css`
    color: ${colors.grey[35]};
  `}
`;

const Loader = styled.div`
  ${({ theme: { customized } }) => css`
    background: ${customized.colors.buttonsLinks};
    height: 22px;
    width: 22px;
    border-radius: 22px;
    position: absolute;
    right: -9px;
    top: -9px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const IconContainer = styled.div`
  position: relative;
`;
