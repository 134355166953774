import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

type TextCarouselProps = {
  steps: string[];
};

export const TextCarousel = ({ steps }: TextCarouselProps): JSX.Element => {
  const [stepNumber, setStepNumber] = useState(0);
  const finalStep = steps.length - 1;
  useEffect(() => {
    const interval = setInterval(() => {
      setStepNumber((item) => (item < finalStep ? stepNumber + 1 : finalStep));
    }, 2500);

    return () => clearInterval(interval);
  }, [finalStep, stepNumber]);

  return <Text>{steps[stepNumber]}</Text>;
};

const Text = styled.h3`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 18px;
`;
