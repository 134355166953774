import moment from 'moment';

import { GqlDispensaries } from 'types/graphql';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import usePaths from 'src/hooks/use-paths';

import {
  openInfoForPickup as getOpenInfoForPickup,
  acceptingAfterHoursForDelivery,
  acceptingAfterHoursForPickup,
} from 'shared/helpers/dispensaries';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import useUI from 'src/hooks/use-ui';
import { useObserver } from 'mobx-react-lite';
import { useMemo } from 'react';

type UseStoreStatusProps = {
  dispensary: GqlDispensaries;
};

type UseStoreStatusReturn = {
  infoHref: string;
  isOpen: boolean;
  orderingType: string | null;
  isAcceptingPreOrder: boolean;
  isComingSoon: boolean;
  openClosedText: string;
};

export const useStoreStatus = ({ dispensary }: UseStoreStatusProps): UseStoreStatusReturn => {
  const { href: infoHref } = usePaths({ info: true });
  const UI = useUI();
  const isKiosk = useObserver(() => UI.isKiosk);
  const isCheckOnDuty = useDispensaryFlag(`dynamic-delivery-on-duty-check`, dispensary.id);

  const memoizedStoreStatus = useMemo(() => {
    const { status, orderTypesConfigV2 } = dispensary;
    const openInfo = openInfoForDispensary(dispensary, {});
    const openInfoForPickup = getOpenInfoForPickup(openInfo).info;
    const openInfoForDelivery = openInfo.delivery;
    const offerAnyPickupService = orderTypesConfigV2?.offerAnyPickupService;
    const offerDelivery = orderTypesConfigV2?.offerDeliveryService;

    if (status !== 'open') {
      return {
        isOpen: false,
        openClosedText: 'Coming soon',
        orderingType: 'Online ordering',
        isAcceptingPreOrder: false,
        isComingSoon: true,
      };
    }

    const getOrderingType = ({
      isOpen,
      openForDelivery,
      openForPickup,
      isAcceptingPreOrder,
      nextOpensAt,
    }): string | null => {
      if (isOpen) {
        if (isKiosk) {
          return 'Pickup';
        }
        if (openForDelivery && openForPickup) {
          return 'Delivery + Pickup';
        }
        if (openForPickup) {
          return 'Pickup';
        }
        if (openForDelivery) {
          return 'Delivery';
        }
      } else {
        if (isAcceptingPreOrder) {
          return 'Available for pre-order';
        }

        if (nextOpensAt) {
          return nextOpensAt;
        }
      }

      return null;
    };

    const offersPickupAndDelivery = offerAnyPickupService && offerDelivery;
    const onlyOffersPickup = offerAnyPickupService && !offerDelivery;
    const onlyOffersDelivery = offerDelivery && !offerAnyPickupService;

    const openForPickup = openInfoForPickup.isOpen;
    let openForDelivery = openInfoForDelivery.isOpen;
    const nearbyEnabled = dispensary.temporalLocation?.nearbyEnabled;

    if (isCheckOnDuty && nearbyEnabled) {
      openForDelivery = openInfoForDelivery.isOpen && dispensary.temporalLocation?.onDuty !== false;
    }

    const isOpen = openForPickup || openForDelivery;
    const openClosedText = isOpen ? 'Open' : 'Closed';
    const isAcceptingPreOrder = acceptingAfterHoursForDelivery(dispensary) || acceptingAfterHoursForPickup(dispensary);

    const pickupReopenMoment = openInfoForPickup.nextService.openMoment;
    const deliveryReopenMoment = openInfoForDelivery.nextService.openMoment;

    let reopenDate;
    if (offersPickupAndDelivery) {
      reopenDate = pickupReopenMoment?.isBefore(deliveryReopenMoment) ? pickupReopenMoment : deliveryReopenMoment;
    } else if (onlyOffersPickup) {
      reopenDate = pickupReopenMoment;
    } else if (onlyOffersDelivery) {
      reopenDate = deliveryReopenMoment;
    }

    if (!reopenDate) {
      return {
        isOpen,
        orderingType: null,
        isAcceptingPreOrder: !!isAcceptingPreOrder,
        isComingSoon: false,
        openClosedText,
      };
    }

    const reopenHour = parseInt(reopenDate.format('H'), 10);
    const today = moment();
    const currentTime = parseInt(moment().format('H'), 10);
    const tomorrow = today.add(1, 'days');
    const orderingReopens = `Ordering reopens ${String(reopenDate.format(`M/DD`))} at ${String(
      reopenDate.format(`h:mma`).replace(':00', '')
    )}`;

    const orderingReopensToday = `Ordering reopens at ${String(reopenDate.format(`h:mma`).replace(':00', ''))}`;

    const nextOpensAt =
      reopenDate !== today && ((reopenDate === tomorrow && reopenHour > currentTime) || reopenDate.isAfter(tomorrow))
        ? orderingReopens
        : orderingReopensToday;

    return {
      isOpen,
      orderingType: getOrderingType({ isOpen, openForDelivery, openForPickup, isAcceptingPreOrder, nextOpensAt }),
      isAcceptingPreOrder: !!isAcceptingPreOrder,
      isComingSoon: false,
      openClosedText,
    };
  }, [dispensary, isCheckOnDuty, isKiosk]);

  // TODO do we need to accept omitting the info link?
  return {
    infoHref,
    isOpen: memoizedStoreStatus.isOpen,
    orderingType: memoizedStoreStatus.orderingType,
    isAcceptingPreOrder: memoizedStoreStatus.isAcceptingPreOrder,
    isComingSoon: memoizedStoreStatus.isComingSoon,
    openClosedText: memoizedStoreStatus.openClosedText,
  };
};
