import React, { ChangeEvent, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useApolloClient } from '@apollo/react-hooks';

import useCart from 'hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useGeoinfo from 'hooks/use-geoinfo';
import useUI from 'hooks/use-ui';
import useUser from 'hooks/use-user';
import useTranslation from 'hooks/use-translation';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';

import { CO } from 'components/forms/sign-up-form/medical-card-form';
import { LegalFooter, DutchiePayHeader, DutchiePayTextBody } from 'src/payments/components';
import { ViewTextProps } from 'src/payments/dutchie-pay/signup';
import { ERNIE_TIMEOUTS, ERNIE_TYPES } from 'shared/constants';
import { consumerSignup } from 'shared/actions/users';
import useErnie from 'shared/hooks/use-ernie';
import { SignUpForm, SignUpFormProps } from './sign-up-form';

export function SignUpFormWrapper({ titleText, subtitleText }: ViewTextProps): JSX.Element {
  const [showMedicalCardForm, setShowMedicalCardForm] = useState(false);
  const UI = useUI();
  const Cart = useCart();
  const apolloClient = useApolloClient();
  const { dispensary } = useDispensary();
  const showErnie = useErnie();
  const User = useUser();
  const [geoinfo] = useGeoinfo();
  const country = geoinfo?.country;
  const { t } = useTranslation();
  const DutchiePayEnrollment = useDutchiePayEnrollment();

  const ageCheck = (e: ChangeEvent<HTMLInputElement>): void => {
    const birthday = e.target.value;
    const formattedBirthday = moment(birthday, `MM/DD/YYYY`, true);

    if (!formattedBirthday.isValid()) {
      return;
    }

    const yearDiff = moment().diff(formattedBirthday, `years`);

    if (!showMedicalCardForm && yearDiff >= 18 && yearDiff < 21 && country !== 'CA') {
      setShowMedicalCardForm(true);
    }

    if (moment().diff(formattedBirthday, `years`) < 18) {
      showErnie(
        t('signUp.ageGate', 'You must be 18 years of age or older to use dutchie'),
        ERNIE_TYPES.DANGER,
        ERNIE_TIMEOUTS.LONG
      );
      setShowMedicalCardForm(false);
    }

    if (showMedicalCardForm && yearDiff >= 21) {
      setShowMedicalCardForm(false);
    }
  };

  const handleFormSubmit = async ({
    firstName,
    lastName,
    password,
    phoneNumber,
    birthday,
    medicalCardNumber,
    expirationDate,
    state,
  }: SignUpFormProps): Promise<void> => {
    try {
      const profile = {
        email: DutchiePayEnrollment.userEmail,
        password,
        profile: {
          firstName: _.trim(firstName),
          lastName: _.trim(lastName),
          phone: phoneNumber,
          birthday,
          textNotifications: true,
          emailNotifications: true,
          medicalCard: showMedicalCardForm
            ? {
                number: state !== CO ? medicalCardNumber : '',
                expirationDate,
                state,
              }
            : null,
        },
      };

      const userCreationData = {
        dispensaryId: dispensary?.id,
        embedded: UI.isEmbedded || UI.isStoreFront,
      };
      const { success, error } = await consumerSignup(apolloClient, User, Cart, UI, profile, userCreationData);

      if (success) {
        DutchiePayEnrollment.signUpStepCompleted();
      } else if (error.message) {
        showErnie(error.message, ERNIE_TYPES.DANGER, ERNIE_TIMEOUTS.LONG);
      } else {
        showErnie(
          t('signUp.fail', 'We are unable to create your account. Please contact support.'),
          ERNIE_TYPES.DANGER,
          ERNIE_TIMEOUTS.LONG
        );
      }
    } catch (err) {
      console.error(err.message);

      showErnie(err.message, ERNIE_TYPES.DANGER, ERNIE_TIMEOUTS.LONG);
    }
  };

  return (
    <>
      <DutchiePayHeader>{titleText ?? t(`dutchiePay.signupStep`, 'Sign up')}</DutchiePayHeader>
      <DutchiePayTextBody>
        {subtitleText ?? t('dutchiePay.enterYourInfo', 'Enter the info below to create your account.')}
      </DutchiePayTextBody>

      <SignUpForm
        onFormSubmit={handleFormSubmit}
        ageCheck={ageCheck}
        showMedicalCardForm={showMedicalCardForm}
        userEmail={DutchiePayEnrollment.userEmail}
      />

      <LegalFooter />
    </>
  );
}
