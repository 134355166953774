/* eslint-disable prefer-destructuring */
import React, { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { color } from 'styled-system';
import { useObserver } from 'mobx-react-lite';

import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import { useColors } from 'src/contexts/colors';

export const OPEN = 'OPEN';
export const CLOSED = 'CLOSED';
export const COMING_SOON = 'Online Ordering Coming Soon!';
export const PREORDER_AVAILABLE = 'Available for pre-order';

function OpenInfoText({
  acceptingAfterHoursForDelivery,
  acceptingAfterHoursForPickup,
  dispensaryIsActive = true,
  nextOpensAt,
  offersDelivery,
  offersPickup,
  openForDelivery,
  openForPickup,
  ...props
}) {
  const { t } = useTranslation();
  const colors = useColors();
  const { inline } = props;
  const UI = useUI();
  const isKiosk = useObserver(() => UI.isKiosk);

  const serviceTypeToDisplay = useMemo(() => {
    if (isKiosk) {
      // if they're open and it's kiosk, just always say pickup
      return t('openInfoText.pickup', 'Pickup');
    }
    if (openForDelivery && openForPickup) {
      return t('openInfoText.deliveryAndPickup', 'Delivery + Pickup');
    }
    if (openForDelivery) {
      return t('openInfoText.delivery', 'Delivery');
    }
    if (openForPickup) {
      return t('openInfoText.pickup', 'Pickup');
    }
    return undefined;
  }, [isKiosk, openForDelivery, openForPickup, t]);

  if (!dispensaryIsActive) {
    return (
      <OpenInfoContainer>
        <OpenStatusText color={colors.green[55]}>{t('openInfoText.comingSoon', COMING_SOON)}</OpenStatusText>
      </OpenInfoContainer>
    );
  }

  const acceptingPreOrder = acceptingAfterHoursForDelivery || acceptingAfterHoursForPickup;
  const openStatus = openForPickup || openForDelivery ? OPEN : CLOSED;
  const openText = t('openInfoText.open', OPEN);
  const closedText = t('openInfoText.closed', CLOSED);

  let openStatusTextColor = colors.primaryBlack;

  if (openStatus === OPEN && inline) {
    openStatusTextColor = colors.lime[45];
  } else if (openStatus === OPEN) {
    openStatusTextColor = colors.green[55];
  } else if (openStatus === CLOSED && acceptingPreOrder) {
    openStatusTextColor = colors.orange[65];
  } else if (openStatus === CLOSED) {
    openStatusTextColor = colors.red[55];
  }

  return (
    <OpenInfoContainer {...props}>
      <OpenStatusText color={openStatusTextColor}>{openStatus === OPEN ? openText : closedText}</OpenStatusText>
      {openStatus === OPEN && <span>{serviceTypeToDisplay}</span>}
      {acceptingPreOrder && openStatus === CLOSED && (
        <span>{t('openInfoText.availableForPreOrder', PREORDER_AVAILABLE)}</span>
      )}
      {!acceptingPreOrder && openStatus === CLOSED && nextOpensAt && <span>{nextOpensAt}</span>}
    </OpenInfoContainer>
  );
}

const OpenInfoContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[30]};
  white-space: nowrap;
  line-height: 125%;

  ${({ inline }) =>
    inline &&
    css`
      display: block;

      span:first-child {
        margin-right: 5px;
      }

      span:nth-child(2) {
        color: ${({ theme }) => theme.colors.grey[45]};
      }
    `}
`;

const OpenStatusText = styled.div`
  ${color}
  display: inline-block;
  padding-right: 6px;
`;

export default memo(OpenInfoText);
