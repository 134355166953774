import React from 'react';
import { useMyAccountPopover } from './use-my-account-popover';
import { MyAccountPopover } from './my-account-popover';

type MyAccountPopoverControllerProps = {
  isLoggedIn: boolean;
  anchorEl: HTMLButtonElement | null;
};

export function MyAccountPopoverController({
  isLoggedIn,
  anchorEl,
}: MyAccountPopoverControllerProps): JSX.Element | null {
  const { handleOptionClick, handleOnLogout, popoverProps, userDisplayName } = useMyAccountPopover({
    anchorEl,
  });

  return (
    <MyAccountPopover
      popoverProps={popoverProps}
      anchorEl={anchorEl}
      isLoggedIn={isLoggedIn}
      handleOnLogout={handleOnLogout}
      handleOptionClick={handleOptionClick}
      userDisplayName={userDisplayName}
    />
  );
}
