import React from 'react';
import styled, { css } from 'styled-components';
import { TextButton } from 'components/core';
import { space, SpaceProps } from 'styled-system';
import useTranslation from 'hooks/use-translation';
import { ArrowIcon, DutchiePayButton, DutchiePayButtonProps } from 'src/payments/components/index';
import { Clickable } from 'shared/components';
import { Merge } from 'shared/utils/type-utils';

export * from './confirmation-button';

export const DutchiePayTextButton = styled(TextButton)`
  font-size: 14px;
  padding: 0;
  ${space}
`;

export type DutchiePayButtonContainerProps = Merge<
  DutchiePayButtonProps,
  {
    showBackLink?: boolean;
    onBackLinkClick?: () => void;
    onClick?: (...props: unknown[]) => void;
    buttonText?: string;
  }
> &
  SpaceProps;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: ${(props) => (props.showBackLink ? '70%' : '100%')};
  }

  ${space}
`;

export const DutchiePayButtonContainer = ({
  showBackLink,
  onBackLinkClick,
  requiredFieldKeys,
  onClick,
  buttonText = `continue`,
  ...props
}: DutchiePayButtonContainerProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ButtonContainer showBackLink={showBackLink}>
      {showBackLink && (
        <BackLink aria-label='back' type='button' onClick={onBackLinkClick}>
          <ArrowIcon />
          {t('common.back', 'Back')}
        </BackLink>
      )}
      <DutchiePayButton aria-label='continue' requiredFieldKeys={requiredFieldKeys} onClick={onClick} {...props}>
        {t('common.continue', '{{buttonText}}', { buttonText })}
      </DutchiePayButton>
    </ButtonContainer>
  );
};

const BackLink = styled(Clickable)`
  ${({ theme: { colors } }) => css`
    color: ${colors.grey[45]};
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    width: unset;

    &:hover {
      color: ${colors.grey[30]};
      cursor: pointer;
    }
  `}
`;
