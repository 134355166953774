import React from 'react';
import styled from 'styled-components';

import ProductSkeletonCarousel from './product-skeleton-carousel';

const ProductCarouselLoadingState = (props) => (
  <Container>
    <ProductSkeletonCarousel {...props} />
  </Container>
);

export default ProductCarouselLoadingState;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;
