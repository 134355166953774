export var MILLIGRAMS = 'MILLIGRAMS';
export var MILLILITERS = 'MILLILITERS';
export var GRAMS = 'GRAMS';
export var MILLIGRAMS_PER_GRAM = 1000;
export var MILLILITERS_PER_OZ = 29.5735;
export var GRAMS_PER_OUNCE = 28;
export var FLOWER_TYPES = ['Flower', 'Pre-Rolls'];
export var CONCENTRATE_TYPES = ['Concentrate', 'Vaporizers'];
export var EDIBLE_TYPES = ['Edible'];
export var TINCTURE_TYPES = ['Tincture'];
export var TOPICAL_TYPES = ['Topicals'];
export var WEIGHTED_TYPES = ['Flower', 'Pre-Rolls', 'Concentrate', 'Vaporizers'];