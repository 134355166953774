import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';

type UseHidePotenciesReturn = {
  menuHidePotencies: boolean;
};

export function useHidePotencies(): UseHidePotenciesReturn {
  const { dispensary } = useDispensary();
  const menuHidePotencies = useDispensaryFlag(`menu.hide-potencies`, dispensary?.id);
  return { menuHidePotencies };
}
