type Option = {
  key?: string;
  label?: string;
  id?: string;
  name: string;
};

export const sortByName = (a: Option, b: Option): number => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};
