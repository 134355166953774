import React from 'react';

function StoreInfoMenusIcon(props) {
  return (
    <svg width='39' height='39' viewBox='0 0 39 39' fill='none' {...props}>
      <rect x='4.68005' y='7.80005' width='29.64' height='19.5' fill='white' />
      <path
        d='M34.5786 28.4353C34.076 28.4353 33.6647 28.0221 33.6647 27.517V9.3714C33.6647 8.65972 33.0889 8.07659 32.376 8.07659H6.62565C5.91735 8.07659 5.34156 8.65513 5.34156 9.3714V27.5216C5.34156 28.0267 4.93029 28.4399 4.42762 28.4399C3.92495 28.4399 3.51367 28.0267 3.51367 27.5216V9.3714C3.51367 7.64499 4.91201 6.23999 6.62565 6.23999H32.376C34.0942 6.23999 35.4926 7.64499 35.4926 9.3714V27.5216C35.4926 28.0267 35.0813 28.4353 34.5786 28.4353Z'
        fill='#64737F'
      />
      <path
        d='M35.319 31.98H3.68739C1.41167 31.98 0.310368 29.4684 0.0179056 27.9808C-0.0369311 27.7099 0.0361845 27.4298 0.209834 27.2186C0.383483 27.0074 0.643957 26.8834 0.913571 26.8834H38.0882C38.3624 26.8834 38.6229 27.0074 38.792 27.2186C38.9656 27.4298 39.0342 27.7099 38.9839 27.9808C38.696 29.4684 37.5947 31.98 35.319 31.98Z'
        fill='white'
      />
      <path
        d='M35.319 31.98H3.68739C1.41167 31.98 0.310368 29.4684 0.0179056 27.9808C-0.0369311 27.7099 0.0361845 27.4298 0.209834 27.2186C0.383483 27.0074 0.643957 26.8834 0.913571 26.8834H38.0882C38.3624 26.8834 38.6229 27.0074 38.792 27.2186C38.9656 27.4298 39.0342 27.7099 38.9839 27.9808C38.696 29.4684 37.5947 31.98 35.319 31.98ZM2.17481 28.72C2.49012 29.4225 2.99279 30.1434 3.68739 30.1434H35.319C36.041 30.1434 36.53 29.3996 36.827 28.72H2.17481Z'
        fill='#64737F'
      />
      <rect x='7.80005' y='10.92' width='6.24' height='5.46' rx='1' fill='#BCCAD2' />
      <rect x='7.80005' y='18.72' width='6.24' height='5.46' rx='1' fill='#BCCAD2' />
      <rect x='16.38' y='10.92' width='6.24' height='5.46' rx='1' fill='#BCCAD2' />
      <rect x='16.38' y='18.72' width='6.24' height='5.46' rx='1' fill='#BCCAD2' />
      <rect x='24.96' y='10.92' width='6.24' height='5.46' rx='1' fill='#BCCAD2' />
      <rect x='24.96' y='18.72' width='6.24' height='5.46' rx='1' fill='#BCCAD2' />
    </svg>
  );
}

export default StoreInfoMenusIcon;
