import React from 'react';
import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { RelinkBankAccountModal } from 'components/modals/dutchie-pay/relink-bank-account-modal';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { ModalObserver } from '../modal-observer';

export const DutchiePayRelinkBankAccountModal = ({ name }: { name: string }): JSX.Element => {
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  return (
    <ModalObserver name={name}>
      {(props) => {
        const { onClose, data } = props;
        const isInstoreCart = data as boolean;
        const onCloseModal = (): void => {
          DutchiePayEnrollment.setAutoCheckoutAfterBankLinkReconnect(false);
          onClose();
        };
        return (
          <ModalWrapper {...props} onClose={onCloseModal}>
            <RelinkBankAccountModal
              {...props}
              onClose={onCloseModal}
              triggerOnClose={onClose}
              isInstoreCart={isInstoreCart}
            />
          </ModalWrapper>
        );
      }}
    </ModalObserver>
  );
};
