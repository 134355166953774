import React, { createContext, useEffect, useState, useCallback } from 'react';
import defaults from 'lodash/defaults';

import createContextHelpers from 'utils/create-context-helpers';

const ViewportContext = createContext();
export const { useViewport, withViewport } = createContextHelpers(ViewportContext, 'viewport');

export const ViewportProvider = ({ children }) => {
  const [viewportState, setViewportState] = useState({ height: 0, width: 0, offset: 0, offsetTop: 0, parentOffset: 0 });
  const [disabled, setDisabled] = useState(false);
  function updateViewport() {
    setViewportState({
      ...viewportState,
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  useEffect(() => {
    updateViewport();
  }, []);

  useEffect(() => {
    if (disabled) {
      return undefined;
    }
    const handleWindowResize = updateViewport;
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [disabled]);

  const setViewport = useCallback(
    (viewport) => {
      if (!disabled) {
        setDisabled(true);
      }

      setViewportState(defaults(viewport, viewportState));
    },
    [disabled]
  );

  viewportState.setViewport = setViewport;

  // eslint-disable-next-line react/jsx-filename-extension
  return <ViewportContext.Provider value={viewportState}>{children}</ViewportContext.Provider>;
};
