import styled from 'styled-components';

import { sharedButtonStyles } from '../../carousel-slider/carousel-buttons';

export const ButtonPrev = styled.button`
  ${sharedButtonStyles}
  left: 10px;
`;

export const ButtonNext = styled.button`
  ${sharedButtonStyles}
  right: 10px;
`;
