import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import NumberFormat from 'react-number-format';

import { Clickable } from 'shared/components';
import { TextInput, Button } from 'components/core';
import { SignUpText } from 'components/modals/components';
import { paragraph, subparagraph } from 'components/core/typography';
import { Typography } from 'src/typography';

export { CheckboxInput } from 'components/core';

export const StyledTextInput = styled(TextInput)`
  background: ${({ theme }) => theme.colors.primaryGrey};
  border: ${({ theme }) => `1px solid ${theme.colors.grey[80]}`};
  color: ${({ theme }) => theme.colors.grey[30]} !important;
  border-radius: 6px !important;
  height: 55px;
  ${space}

  & > input {
    padding-top: 30px;
    padding-left: 25px;
    font-size: 16px;
    font-weight: bold;
  }

  &.Mui-focused {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.blue[55]};
    box-shadow: ${({ theme }) => `0px 1px 5px ${theme.colors.blueShadow}`};
    background-color: ${({ theme }) => theme.colors.white};
  }

  &.empty:not(.Mui-focused) {
    color: transparent !important;
  }

  &.Mui-error {
    border: 1px solid ${({ theme }) => theme.colors.red[60]};
    &.MuiFormLabel-root {
      color: ${({ theme }) => `${theme.colors.red[60]} !important`};
    }
  }

  input:-webkit-autofill {
    border-color: ${({ theme }) => theme.colors.blue[55]};
    padding-top: 23px;
    padding-bottom: 11px;
  }
`;

const InputWithPlaceholder = (props) => {
  const { value } = props;
  return <StyledTextInput className={!value.length ? 'empty' : null} {...props} />;
};

export const DateInput = (props) => (
  <NumberFormat
    displayType='input'
    customInput={InputWithPlaceholder}
    format='##/##/####'
    placeholder='mm/dd/yyyy'
    mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']}
    {...props}
  />
);

export const PhoneNumberInput = (props) => (
  <NumberFormat
    displayType='input'
    customInput={InputWithPlaceholder}
    format='(###) ###-####'
    placeholder='(___) ___-____'
    mask='_'
    {...props}
  />
);

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${space};
`;

export const ChangePassword = styled(Clickable)`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 13px;
  text-transform: ${({ theme }) => theme.customized.textTransforms.links};
`;

export const Heading = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 37px 0;
`;

export const StyledButton = styled(Button)`
  margin-top: 50px;
  width: 83px;
  height: 39px;
  padding: 13px 15px;
`;

export const LoginOptionsContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  display: flex;
`;

export const SecondaryOptionText = styled(SignUpText)`
  display: inline-flex;
  align-items: center;
  margin-left: 3px;
  font-weight: bold;
  font-size: ${({ larger }) => (larger ? paragraph : subparagraph)};
  ${space}
  &:hover {
    text-decoration: none;
  }
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;
