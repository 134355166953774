import styled from 'styled-components';
import { fontSize, lineHeight, space } from 'styled-system';

export default styled.span`
  color: ${({ theme }) => theme.colors.grey[60]};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  ${fontSize}
  ${space}
  ${lineHeight}
`;
