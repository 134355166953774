import React, { ReactElement } from 'react';

import { Info as InfoIcon } from 'src/svg/info';
import { Warning as WarningIcon } from 'src/svg/warning';
import { ErrorExclamation as ErrorIcon } from 'src/svg/error-exclamation';

import { Container, Content, IconContainer } from './inline-banner.styles';

type InlineBannerProps = {
  border?: boolean;
  children?: ReactElement | string;
  description?: string;
  padding?: string;
  severity?: 'error' | 'info' | 'warning';
  title?: string;
};

export function InlineBanner({
  children,
  title,
  description,
  severity = 'info',
  padding = `16px`,
  border = true,
}: InlineBannerProps): JSX.Element | null {
  const Icon = (): JSX.Element => {
    switch (severity) {
      default:
      case 'info':
        return <InfoIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningIcon />;
    }
  };

  return (
    <Container severity={severity} padding={padding} border={border}>
      <IconContainer>
        <Icon />
      </IconContainer>
      <Content>
        {title && <strong>{title}</strong>}
        {description && <p>{description}</p>}
        {children}
      </Content>
    </Container>
  );
}
