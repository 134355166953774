import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import { SmallLoader } from 'shared/components';
import useDelayedRender from 'shared/hooks/use-delayed-render';

import {
  BrandsPageEmptyState,
  CartEmptyState,
  CategoryPageEmptyState,
  SpecialsPageEmptyState,
  OrdersEmptyState,
  MarketplaceProductListEmptyState,
} from './empty-page-states';

import {
  CategoriesCarouselLoadingState,
  CategoryPageLoadingState,
  FilterSidebarLoadingState,
  HybridCarouselLoadingState,
  PersonalizedCarouselLoadingState,
  ProductCardsGridLoadingState,
  ProductCarouselLoadingState,
  ProductPageLoadingState,
} from './loading-page-states';

// TODO: clean this up!
// Maybe scan some router props for route so the 'page' prop doesn't have to be passed in
// i.e if route === embedded-menu[cName] return HomePageLoadingState
const EMPTY_STATE_MAP = {
  brands: (props) => <BrandsPageEmptyState {...props} />,
  cart: (props) => <CartEmptyState {...props} />,
  category: (props) => <CategoryPageEmptyState {...props} />,
  marketplace_category: (props) => <MarketplaceProductListEmptyState {...props} />,
  specials: (props) => <SpecialsPageEmptyState {...props} />,
  orders: (props) => <OrdersEmptyState {...props} />,
  bogo_special: (props) => <SpecialsPageEmptyState {...props} />,
  sale_special: (props) => <SpecialsPageEmptyState {...props} />,
  marketplace_search: (props) => <MarketplaceProductListEmptyState {...props} />,
  /// @TODO: add dispensaries empty state
};

const LOADING_STATE_MAP = {
  categoriesCarousel: (props) => <CategoriesCarouselLoadingState {...props} />,
  category: (props) => <CategoryPageLoadingState {...props} />,
  collection: (props) => <CategoryPageLoadingState {...props} />,
  specials: (props) => <CategoryPageLoadingState {...props} />,
  items_on_sale: (props) => <CategoryPageLoadingState {...props} />,
  sale_special: (props) => <CategoryPageLoadingState {...props} />,
  bogo_special: (props) => <CategoryPageLoadingState {...props} />,
  marketplace_category: () => <ProductCardsGridLoadingState />,
  brands: (props) => <CategoryPageLoadingState {...props} />,
  filterSideBar: (props) => <FilterSidebarLoadingState {...props} />,
  product: (props) => <ProductPageLoadingState {...props} />,
  productCarousel: (props) => <ProductCarouselLoadingState {...props} />,
  hybridCarousel: (props) => <HybridCarouselLoadingState {...props} />,
  personalizedCarousel: (props) => <PersonalizedCarouselLoadingState {...props} />,
  default: (props) => <SmallLoader {...props} />,
  /// @TODO: add dispensaries loading state
};
/**
 * @type any
 */
const EmptyLoadingState = ({
  isLoading,
  isEmpty,
  page,
  children,
  emptyStateMap = EMPTY_STATE_MAP,
  loadingStateMap = LOADING_STATE_MAP,
  delay = 100,
  ...props
}) => {
  const delayedRender = useDelayedRender(delay);
  const { highPerformanceMode } = useTheme();

  if (isLoading && loadingStateMap[page]) {
    if (highPerformanceMode) {
      return delayedRender(() => loadingStateMap[page](props));
    }
    return loadingStateMap[page](props);
  }

  if (isEmpty && !isLoading && emptyStateMap[page]) {
    return delayedRender(() => emptyStateMap[page](props));
  }

  return children;
};

EmptyLoadingState.defaultProps = {
  emptyStateMap: EMPTY_STATE_MAP,
  loadingStateMap: LOADING_STATE_MAP,
};

export default EmptyLoadingState;
