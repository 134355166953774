import React, { useState } from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { DesktopOnly } from 'shared/components';
import { heading4 } from 'components/core/typography';

export default function SkipToMain() {
  const [focused, setFocused] = useState(false);

  const { t } = useTranslation();

  const handleFocus = () => setFocused(true);

  const handleBlur = () => setFocused(false);

  return (
    <StyledDesktopOnly focused={focused}>
      <SkipToMainStyles tabIndex={0} onFocus={handleFocus} onBlur={handleBlur} href='#main-content'>
        {t('common.skipNavigation', 'Skip Navigation')}
      </SkipToMainStyles>
    </StyledDesktopOnly>
  );
}

const SkipToMainStyles = styled.a`
  ${heading4}
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const StyledDesktopOnly = styled(DesktopOnly)`
  order: 2;

  ${({ focused }) =>
    !focused &&
    `
    width:1px;
    height:1px;
    overflow:hidden;
    z-index:-999;
  `}
`;
