import React from 'react';

import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { EnrollmentSteps } from 'src/payments/constants';
import useStores from 'shared/hooks/use-stores';

import { PasswordForm } from './password-form';
import { useSubmitPassword } from './use-submit-password';

type PasswordFormWrapperProps = {
  showToolTip: boolean;
};

export function PasswordFormWrapper({ showToolTip }: PasswordFormWrapperProps): JSX.Element {
  const { UI } = useStores();
  const dutchiePayEnrollment = useDutchiePayEnrollment();

  const { submitPassword } = useSubmitPassword();

  const onForgotPassword = (): void => dutchiePayEnrollment.resetPasswordClicked();

  const onBackClick = (): void => dutchiePayEnrollment.setCurrentStep(EnrollmentSteps.emailForm);

  const onLearnMore = (): void => UI.openDutchiePayLearnMoreModal();

  return (
    <PasswordForm
      userEmail={dutchiePayEnrollment.userEmail}
      onSubmit={submitPassword}
      onForgotPassword={onForgotPassword}
      onLearnMore={onLearnMore}
      showToolTip={showToolTip}
      onBackClick={onBackClick}
    />
  );
}
