import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';

import PlusSymbol from 'assets/plus-symbol';
import { SmallLoader } from 'shared/components/loading';
import { useColors } from 'contexts/colors';
import { Clickable } from 'shared/components';
import useTranslation from 'hooks/use-translation';
import { odometerStyles } from './odometer-styles';
import { CircleButton } from '../core/button';

const Odometer = dynamic<{ value: number; duration: number }>(() => import('react-odometerjs'), {
  ssr: false,
});

const ABOUT_TO_CLOSE_DURATION = 450;
const CONTENT_OPACITY_TRANSITION_DURATION = 200;
const IDLE_DURATION = 2000;
const ODOMETER_TRANSITION_DURATION = 200;
const WIDTH_TRANSITION_DURATION = 200;

type QuantityControlsControllerProps = {
  disableIncrementing: boolean;
  isVisible: boolean;
  onDecrement: () => void;
  onIncrement: () => void;
  quantity: number;
};

export function QuantityControlsController({
  disableIncrementing: disableIncrementingFromProps,
  isVisible,
  onDecrement,
  onIncrement,
  quantity,
  ...props
}: QuantityControlsControllerProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [isCollapsing, setIsCollapsing] = useState(false);
  const [isAboutToCollapse, setIsAboutToCollapse] = useState(false);

  const closeIfIdleTimeout = useRef<number>();
  const expandTimeout = useRef<number>();
  const collapseTimeout = useRef<number>();
  const isAboutToCollapseTimeout = useRef<number>();

  function expand(): void {
    setIsExpanding(true);
    expandTimeout.current = window.setTimeout(() => {
      setIsExpanding(false);
      setIsExpanded(true);
      startIdleTimer(IDLE_DURATION);
    }, WIDTH_TRANSITION_DURATION);
  }

  function collapse(): void {
    setIsExpanded(false);
    setIsCollapsing(true);
    collapseTimeout.current = window.setTimeout(() => {
      setIsCollapsing(false);
      setIsAboutToCollapse(false);
    }, WIDTH_TRANSITION_DURATION);
  }

  const handleCollapse = useCallback(() => {
    setIsAboutToCollapse(true);
    isAboutToCollapseTimeout.current = window.setTimeout(collapse, ABOUT_TO_CLOSE_DURATION);
  }, []);

  const clearTimeouts = useCallback(() => {
    clearTimeout(closeIfIdleTimeout.current);
    clearTimeout(expandTimeout.current);
    clearTimeout(collapseTimeout.current);
    clearTimeout(isAboutToCollapseTimeout.current);
  }, []);

  function resetState(): void {
    setIsCollapsing(false);
    setIsExpanding(false);
    setIsExpanded(false);
  }

  const startIdleTimer = useCallback(
    (duration) => {
      closeIfIdleTimeout.current = window.setTimeout(handleCollapse, duration);
    },
    [handleCollapse]
  );

  const endIdleTimer = (): void => clearTimeout(closeIfIdleTimeout.current);

  function handleDecrement(e: React.MouseEvent | React.TouchEvent): void {
    if (disableDecrementing) {
      return;
    }
    e.stopPropagation();
    onDecrement();
    endIdleTimer();
    startIdleTimer(IDLE_DURATION);
  }

  function handleIncrement(e: React.MouseEvent | React.TouchEvent): void {
    if (disableIncrementing) {
      return;
    }
    e.stopPropagation();
    onIncrement();
    endIdleTimer();
    startIdleTimer(IDLE_DURATION);
  }

  // Clear all timeouts when component unmounts
  useEffect(() => clearTimeouts, [clearTimeouts]);

  // Clear state in case this component is rendered but hidden.
  useEffect(() => {
    if (!isVisible) {
      resetState();
      clearTimeouts();
    }
  }, [clearTimeouts, isVisible]);

  const disableIncrementing = disableIncrementingFromProps || !isExpanded || isAboutToCollapse;
  const disableDecrementing = !isExpanded || isAboutToCollapse;

  return (
    <QuantityControls
      disableIncrementing={disableIncrementing}
      disableDecrementing={disableDecrementing}
      isAboutToCollapse={isAboutToCollapse}
      isCollapsing={isCollapsing}
      isExpanded={isExpanded}
      isExpanding={isExpanding}
      onClick={!isExpanded ? expand : undefined}
      onDecrement={handleDecrement}
      onIncrement={handleIncrement}
      quantity={quantity}
      {...props}
    />
  );
}

export type QuantityControlsProps = {
  disableIncrementing: boolean;
  disableDecrementing: boolean;
  isAboutToCollapse: boolean;
  isCollapsing: boolean;
  isExpanded: boolean;
  isExpanding: boolean;
  onClick?: () => void;
  onDecrement: (e: React.MouseEvent | React.TouchEvent) => void;
  onIncrement: (e: React.MouseEvent | React.TouchEvent) => void;
  quantity: number;
};

export function QuantityControls({
  disableIncrementing,
  disableDecrementing,
  isAboutToCollapse,
  isCollapsing,
  isExpanded,
  isExpanding,
  onClick,
  onDecrement,
  onIncrement,
  quantity,
  ...props
}: QuantityControlsProps): JSX.Element {
  const colors = useColors();
  const { t } = useTranslation();
  const isTransitioning = isExpanding || isCollapsing;

  function handleContainerKeyPress(e: React.KeyboardEvent): void {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick?.();
    }
  }

  return (
    <Container
      title={
        !isExpanded
          ? t('expand-quantity-controls', 'Expand Quantity Controls')
          : t('quantity-controls', 'Quantity Controls')
      }
      aria-label={
        !isExpanded
          ? t('expand-quantity-controls', 'Expand Quantity Controls')
          : t('quantity-controls', 'Quantity Controls')
      }
      isExpanded={isExpanded}
      isExpanding={isExpanding}
      isAboutToCollapse={isAboutToCollapse}
      isCollapsing={isCollapsing}
      onClick={onClick}
      role={!isExpanded ? 'button' : undefined}
      onKeyPress={handleContainerKeyPress}
      tabIndex={0}
      {...props}
    >
      <CollapsedContentContainer show={!isExpanded && !isTransitioning} hidden={isExpanded}>
        <QuantityButton quantity={quantity} />
      </CollapsedContentContainer>
      <ExpandedControlsContainer show={isExpanded && !isTransitioning} hidden={!isExpanded}>
        <MinusButton
          aria-disabled={disableDecrementing}
          aria-label={t('decrement-quantity', 'decrement quantity')}
          disabled={disableDecrementing}
          onClick={onDecrement}
        >
          <MinusSymbol />
        </MinusButton>
        {!isAboutToCollapse && (
          <ExpandedQuantity aria-label={t('quantity', 'quantity')}>
            <Odometer value={quantity} duration={ODOMETER_TRANSITION_DURATION} />
          </ExpandedQuantity>
        )}
        {isAboutToCollapse && <StyledSpinner height={16} />}
        <PlusButton
          aria-disabled={disableIncrementing}
          aria-label={t('increment-quantity', 'increment quantity')}
          disabled={disableIncrementing}
          onClick={onIncrement}
        >
          <PlusSymbol fill={colors.primaryBlack} />
        </PlusButton>
      </ExpandedControlsContainer>
    </Container>
  );
}

const Container = styled.div<{ isExpanded: boolean; isExpanding: boolean }>`
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  font-size: 13px;
  cursor: pointer;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme, isExpanded, isExpanding, isAboutToCollapse, isCollapsing }) =>
    /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
    (isExpanded || isExpanding || isAboutToCollapse || isCollapsing) && `0 1px 6px ${theme.colors.basicShadow}`};
  color: ${({ theme }) => theme.colors.primaryBlack};
  width: ${({ isExpanded, isExpanding }) => (isExpanded || isExpanding ? `102px` : `40px`)};
  justify-content: center;
  transition: width ${WIDTH_TRANSITION_DURATION}ms 100ms;

  * {
    transition: opacity ${CONTENT_OPACITY_TRANSITION_DURATION}ms;
  }

  ${odometerStyles}
`;

const contentTransitionStyles = css`
  width: ${({ show }) => (show ? `100%` : `inherit`)};
  opacity: ${({ show }) => (show ? 1 : 0)};
  position: ${({ show }) => (show ? `inherit` : `absolute`)};
`;

const CollapsedContentContainer = styled.div`
  ${contentTransitionStyles}
`;

const ExpandedControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  ${({ show }) =>
    show &&
    css`
      height: 100%;
      z-index: 1;
    `};
  ${contentTransitionStyles}
`;

const ExpandedQuantity = styled.b`
  min-width: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MinusSymbol = styled.div`
  width: 9.75px;
  height: 2px;
  padding-left: 12px;
  margin-right: 10px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.primaryBlack};
`;

const disabledOpacityStyles = css`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      transition: none;
    `}
`;

const MinusButton = styled(Clickable)<{ disabled: boolean }>`
  padding-left: 12px;
  margin-right: 10px;
  height: 100%;
  ${disabledOpacityStyles}
`;

const PlusButton = styled(Clickable)<{ disabled: boolean }>`
  padding-right: 12px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  svg {
    margin-bottom: 1px;
    margin-left: 10px;
    ${disabledOpacityStyles}
  }
`;

const StyledSpinner = styled(SmallLoader)`
  &&& * {
    opacity: 0.2;
    border-color: ${({ theme }) => theme.colors.primaryBlack};
    border-bottom-color: transparent;
  }
`;

const QuantityButton = styled(CircleButton)`
  width: 40px;
  height: 40px;
`;
