import { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import TagManager from 'react-gtm-module';
import _once from 'lodash/once';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'hooks/use-ui';
import { isBrowser } from 'utils/misc-utils';
import { embeddedEventDispatcher } from 'utils/analytics/helpers/gtag';

export function pushGTMDataLayer(payload) {
  if (window.__DEBUG_GTM) {
    console.log('[dutchie] GTM DataLayer: ', payload);
  }
  if (window.dataLayer) {
    window.dataLayer.push(payload);
  }
  embeddedEventDispatcher('dataLayer', 'push', payload);
}

const initDispensaryTracker = _once((id, unrestrictedTags = false) => {
  let dataLayerConfig = {};
  if (!unrestrictedTags) {
    dataLayerConfig = {
      'gtm.whitelist': ['google', 'customPixels', 'flc', 'fls', 'twitter_website_tag'],
    };
  }

  TagManager.initialize({
    gtmId: id,
    dataLayer: {
      ...dataLayerConfig,
    },
  });
});

export function useDispensaryTagManager() {
  const UI = useUI();
  const { dispensary } = useDispensary();
  const shouldUseDispensaryGTM = useObserver(() => UI.isEmbedded || UI.isStoreFront || UI.isPlus);

  return useEffect(() => {
    if (!isBrowser() || !shouldUseDispensaryGTM) {
      return;
    }
    if (dispensary) {
      if (dispensary?.googleTagManager?.gtmID) {
        // eslint-disable-next-line camelcase
        initDispensaryTracker(dispensary.googleTagManager.gtmID, dispensary.googleTagManager.flags?.unrestricted_tags);
      }
      // Push Identifying Data to Datalayer for Retailer
      pushGTMDataLayer({
        retailerId: dispensary.id,
        retailerName: dispensary.name,
        retailerAddress: dispensary.address,
      });
    }
  }, [dispensary?.googleTagManager, shouldUseDispensaryGTM]);
}
