/* eslint-disable max-len */
import React from 'react';
import { getColors } from 'src/theme/colors';

type IconProps = {
  color?: string;
};

export function DutchieWordmark({ color = 'black', ...restOfProps }: IconProps): JSX.Element {
  const colors = getColors();

  return (
    <svg
      fill={colors[color] || color}
      width='89'
      height='20'
      viewBox='0 0 89 20'
      xmlns='http://www.w3.org/2000/svg'
      {...restOfProps}
    >
      <title>Dutchie</title>
      <path d='M0 12.8953C0 8.69051 2.45099 5.79971 6.55729 5.79971C8.29493 5.79971 9.77648 6.50655 10.5813 7.54869V0.851827C10.5813 0.634338 10.755 0.462158 10.9745 0.462158H13.645C13.8645 0.462158 14.0383 0.634338 14.0383 0.851827V19.3837C14.0383 19.6012 13.8645 19.7734 13.645 19.7734H10.9745C10.755 19.7734 10.5813 19.6012 10.5813 19.3837V18.1966C9.89537 19.284 8.3498 19.9999 6.61216 19.9999C2.45098 19.9909 0 17.1001 0 12.8953ZM10.7916 12.8953C10.7916 10.4123 9.32836 8.77206 7.09687 8.77206C4.89282 8.77206 3.42954 10.4123 3.42954 12.8953C3.42954 15.3783 4.89282 17.0185 7.09687 17.0185C9.32836 17.0185 10.7916 15.3783 10.7916 12.8953Z' />
      <path d='M28.4606 19.7735H25.7902C25.5707 19.7735 25.3969 19.6013 25.3969 19.3838V17.7073C24.519 19.0485 22.9459 20 20.9888 20C17.9891 20 15.9222 18.0336 15.9222 15.034V6.42507C15.9222 6.20758 16.096 6.0354 16.3155 6.0354H18.986C19.2055 6.0354 19.3792 6.20758 19.3792 6.42507V14.2456C19.3792 15.7318 20.5956 16.9733 22.0954 16.9461C24.1989 16.9099 25.3878 15.1156 25.3878 12.0617V6.42507C25.3878 6.20758 25.5615 6.0354 25.781 6.0354H28.4515C28.671 6.0354 28.8448 6.20758 28.8448 6.42507V19.3929C28.8539 19.6013 28.6801 19.7735 28.4606 19.7735Z' />
      <path d='M37.2586 19.9909C34.4509 19.9909 32.3566 18.4866 32.3566 15.2695V8.91704H30.4178C30.1983 8.91704 30.0245 8.74486 30.0245 8.52737V6.42497C30.0245 6.20748 30.1983 6.0353 30.4178 6.0353H32.3566V2.39235C32.3566 2.17486 32.5304 2.00269 32.7499 2.00269H35.4203C35.6398 2.00269 35.8136 2.17486 35.8136 2.39235V6.0353H38.9139C39.1334 6.0353 39.3072 6.20748 39.3072 6.42497V8.52737C39.3072 8.74486 39.1334 8.91704 38.9139 8.91704H35.8136V14.9524C35.8136 16.2664 36.5269 16.9732 37.6061 16.9732C37.9719 16.9732 38.3835 16.937 38.7676 16.8191C39.0054 16.7466 39.2523 16.9098 39.2706 17.1544L39.4535 19.3293C39.4718 19.5196 39.3529 19.6918 39.17 19.7371C38.5755 19.9093 37.9353 19.9909 37.2586 19.9909Z' />
      <path d='M39.9016 12.8954C39.9016 8.6906 42.7916 5.7998 46.9528 5.7998C50.3457 5.7998 52.7784 7.46723 53.4003 10.1496C53.446 10.3671 53.3089 10.5846 53.0894 10.6208L50.4646 11.1283C50.2634 11.1645 50.0714 11.0467 50.0165 10.8474C49.6507 9.59681 48.5532 8.77216 47.0717 8.77216C44.9225 8.77216 43.5232 10.3852 43.5232 12.8954C43.5232 15.4056 44.9316 17.0186 47.0717 17.0186C48.5624 17.0186 49.5958 16.2846 50.0073 15.0159C50.0714 14.8256 50.2634 14.6987 50.4646 14.7441L53.0894 15.2696C53.3089 15.315 53.4552 15.5324 53.4003 15.7499C52.7236 18.4051 50.2909 19.991 46.9619 19.991C42.7916 19.991 39.9016 17.1002 39.9016 12.8954Z' />
      <path d='M55.2294 0.453125H57.8999C58.1193 0.453125 58.2931 0.625304 58.2931 0.842794V8.14681C59.1985 6.64251 60.7715 5.79974 62.7012 5.79974C65.8107 5.79974 67.8775 7.7662 67.8775 10.7657V19.3747C67.8775 19.5922 67.7038 19.7644 67.4843 19.7644H64.8138C64.5943 19.7644 64.4206 19.5922 64.4206 19.3747V11.6085C64.4206 10.1133 63.2134 8.86272 61.7044 8.85365C59.5369 8.83553 58.3114 10.6661 58.3114 13.7925V19.3747C58.3114 19.5922 58.1376 19.7644 57.9182 19.7644H55.2477C55.0282 19.7644 54.8544 19.5922 54.8544 19.3747V0.851858C54.827 0.634368 55.0099 0.453125 55.2294 0.453125Z' />
      <path d='M69.1488 2.1024C69.1488 0.933392 70.0543 0 71.2981 0C72.5694 0 73.5023 0.924329 73.5023 2.1024C73.5023 3.29859 72.5694 4.23198 71.2981 4.23198C70.0634 4.23198 69.1488 3.30765 69.1488 2.1024ZM69.9628 6.0172H72.6335C72.853 6.0172 73.0267 6.18938 73.0267 6.40687V19.3747C73.0267 19.5922 72.853 19.7643 72.6335 19.7643H69.9628C69.7433 19.7643 69.5695 19.5922 69.5695 19.3747V6.41594C69.5604 6.19845 69.7433 6.0172 69.9628 6.0172Z' />
      <path d='M87.8146 13.8016H77.8826C78.1844 15.8768 79.5379 17.1002 81.5134 17.1002C83.0041 17.1002 84.1015 16.4568 84.5496 15.324C84.6228 15.1428 84.824 15.0431 85.0161 15.0975L87.4945 15.759C87.7231 15.8224 87.8512 16.058 87.7688 16.2755C86.8726 18.6226 84.5405 19.991 81.4585 19.991C76.8583 19.991 74.4073 16.4749 74.4073 12.8954C74.4073 9.31588 76.6663 5.7998 81.2664 5.7998C85.9489 5.7998 88.2353 9.23432 88.2353 12.4332C88.2353 12.7595 88.2261 13.131 88.2078 13.4301C88.1987 13.6385 88.0249 13.8016 87.8146 13.8016ZM84.5222 11.2914C84.3576 9.65118 83.2784 8.4278 81.2756 8.4278C79.5654 8.4278 78.3582 9.43369 77.9741 11.2914H84.5222Z' />
    </svg>
  );
}
