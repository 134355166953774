import React from 'react';

type SvgCheckProps = {
  width?: number;
  height?: number;
};

export const CheckIcon = ({ width = 18, height = 18 }: SvgCheckProps): JSX.Element => (
  <svg width={width} height={height} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>Check Icon</title>
    <path
      d='m4.571 9.143 2.343 1.874a.548.548 0 0 0 .457.114.571.571 0 0 0 .389-.274l3.669-6.286'
      stroke='#00A47C'
      strokeWidth='1.143'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 15.428A7.429 7.429 0 1 0 8 .571a7.429 7.429 0 0 0 0 14.857Z'
      stroke='#00A47C'
      strokeWidth='1.143'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
