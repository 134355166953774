import React, { useEffect, useRef } from 'react';
import { useObserver } from 'mobx-react-lite';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import useCart from 'hooks/use-cart';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import { useScrollRestoration } from 'hooks/use-scroll-restoration';
import useViewport from 'hooks/use-viewport';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import StatusMenuSelect from 'src/dispensary/core-menu/home/components/status-menu-select';

import { CartButton } from 'components/cart';
import { Subheader, VerticalDivider } from 'components/core';
import MenuTypeDropdown from 'components/menu-type-dropdown';
import SkipToMain from 'components/skip-to-main';
import { DesktopOnly, MobileOnly } from 'shared/components';
import { ARIA_ROLES } from 'shared/constants';
import Search from 'src/dispensary/components/dispensary-header/search';
import ROUTES from 'src/routes';

import StoreStatus from './store-status';
import NavLinks from './nav-links';
import { MyAccountLink } from './my-account-link';

export default function CoreMenuHeader({ dispensary, ...otherProps }) {
  const router = useRouter();
  const cart = useCart();
  const { t } = useTranslation();
  const UI = useUI();
  const viewport = useViewport();
  const { queryParams } = useProductQueryParams();
  const myAccountEnabled = !UI.isKiosk && !dispensary?.featureFlags?.hideMyAccount;

  const cartButtonRef = useRef();
  useEffect(() => {
    UI.setCartButtonRef(cartButtonRef);
    return () => UI.clearCartButtonRef();
  }, [UI]);

  const itemCount = useObserver(() => cart.itemCount);
  const recDispensary = useObserver(() => dispensary.recDispensary);
  const medicalDispensary = useObserver(() => dispensary.medicalDispensary);
  const showMenuTypeDropdown = recDispensary && medicalDispensary;
  const isProductPage = router.route === `${ROUTES.EMBEDDED_MENU_CNAME}${ROUTES.PRODUCT_ID}`;

  // used to short circuit the scroll position restorer when changing search terms
  const searchQueryHasChanged =
    UI.previousSearchTerm && queryParams.search && queryParams.search !== UI.previousSearchTerm;
  // we restore scroll position on back navigation
  useScrollRestoration({ router, viewport, skip: searchQueryHasChanged });

  return (
    <Container>
      <StyledHeader {...otherProps} isDutchieMain={UI.isDutchieMain}>
        <SkipToMain />
        <Left>
          <NavLinks role={ARIA_ROLES.NAV} dispensary={dispensary} />
          <DesktopOnly>
            <Search />
          </DesktopOnly>
        </Left>

        <Right>
          <StyledDesktopOnly display='flex'>
            <StoreStatus dispensary={dispensary} />
          </StyledDesktopOnly>

          <StyledDesktopOnly display='flex'>
            <>
              <HeaderDivider mx='22px' />
              <MenuTypeContainer role={ARIA_ROLES.REGION}>
                <MenuLabel aria-label='menu type'>{t('common.menu', 'Menu')}</MenuLabel>
                {showMenuTypeDropdown && <MenuTypeDropdown aria-labelledby='menu type' />}
                {!showMenuTypeDropdown && (
                  <span aria-labelledby='menu type'>
                    {recDispensary ? t('common.recreational', 'Recreational') : t('common.medical', 'Medical')}
                  </span>
                )}
              </MenuTypeContainer>
              {myAccountEnabled && <HeaderDivider ml='-8px' />}
            </>
          </StyledDesktopOnly>

          <MobileOnly>
            <Search />
          </MobileOnly>

          {myAccountEnabled && <MyAccountLink />}

          {/* desktop cart */}
          {!UI.viewOnlyMode && (
            <CartButton
              ref={cartButtonRef}
              onClick={UI.openCartDrawer}
              variant={itemCount === 0 ? 'grey' : 'blue'}
              itemCount={itemCount}
            />
          )}
        </Right>
      </StyledHeader>
      {!isProductPage && (
        <MobileOnly>
          <StatusMenuSelect dispensary={dispensary} m='0' />
        </MobileOnly>
      )}
    </Container>
  );
}

const MenuLabel = styled.label`
  color: ${({ theme }) => theme.colors.grey[75]};
  text-transform: uppercase;
`;

const Left = styled.div`
  display: flex;
  order: 0;
`;

const Right = styled.div`
  display: flex;
  order: 3;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
`;

const StyledDesktopOnly = styled(DesktopOnly)`
  align-items: center;
`;

const StyledHeader = styled(Subheader)`
  background: ${({ theme, isDutchieMain }) => (isDutchieMain ? theme.colors.white : theme.customized.colors.navBar)};
  border-top: none;
  padding: 0 20px;
  z-index: 5;
  flex-grow: 0;
  border-bottom: 1px solid #d6dadd;
  box-shadow: none;

  @media (min-width: 1240px) {
    padding: 0;
  }
`;

const HeaderDivider = styled(VerticalDivider)`
  border-color: ${({ theme }) => theme.colors.blueGrey[80]};
  height: 34px;
`;

const MenuTypeContainer = styled.div`
  height: 44px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 0 2px;
  margin-right: 30px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[30]};
`;

const Container = styled.div`
  border-bottom: 1px solid #d7e4eb;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.038);
`;
