import React from 'react';

export function DutchiePayMobileQrIcon(): JSX.Element {
  return (
    <svg width='152' height='153' viewBox='0 0 152 153' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2398_19118)'>
        <circle opacity='0.08' cx='76' cy='76.5' r='76' fill='#8DA1B4' />
        <circle opacity='0.1' cx='76' cy='76.5' r='61' fill='#446583' />
        <g filter='url(#filter0_d_2398_19118)'>
          <rect x='41.0503' y='10.6035' width='69.9496' height='132.06' rx='10.1376' fill='white' />
          <rect x='41.5503' y='11.1035' width='68.9496' height='131.06' rx='9.63763' stroke='#A0A8AB' />
        </g>
        <g filter='url(#filter1_d_2398_19118)'>
          <rect
            x='24.1406'
            y='106.5'
            width='102.585'
            height='21.7991'
            rx='10.8996'
            fill='#00A47C'
            shapeRendering='crispEdges'
          />
          <path
            d='M67.433 120.4V113.54H70.2748C71.6956 113.54 72.607 114.383 72.607 115.686C72.607 116.99 71.6956 117.832 70.2748 117.832H68.9813V120.4H67.433ZM68.9813 116.509H70.118C70.7059 116.509 71.0587 116.196 71.0587 115.686C71.0587 115.177 70.7059 114.863 70.118 114.863H68.9813V116.509ZM75.4022 115.569L74.6476 117.695H76.1567L75.4022 115.569ZM72.0705 120.4L74.6672 113.54H76.1273L78.7241 120.4H77.1268L76.6271 118.989H74.1773L73.6775 120.4H72.0705ZM80.1812 120.4V117.568L77.8588 113.54H79.5051L80.9554 116.117L82.4154 113.54H84.0519L81.7295 117.568V120.4H80.1812Z'
            fill='white'
          />
        </g>
        <path
          d='M91.1967 12.4125C91.1967 14.7968 89.2638 16.7297 86.8795 16.7297L65.171 16.7297C62.7866 16.7297 60.8538 14.7968 60.8538 12.4125L60.8538 11.1103L91.1967 11.1103L91.1967 12.4125Z'
          fill='#F2F3F4'
          stroke='#A0A8AB'
          strokeWidth='1.01376'
        />
        <rect x='51.9033' y='47.5' width='48.6774' height='48.6774' rx='5.54992' fill='white' />
        <path
          d='M62.5518 58.1479H68.6364V64.2326H62.5518V58.1479Z'
          stroke='#8DA1B4'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M62.5518 79.4446H68.6364V85.5293H62.5518V79.4446Z'
          stroke='#8DA1B4'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.8477 58.1479H89.9323V64.2326H83.8477V58.1479Z'
          stroke='#8DA1B4'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M62.5518 73.3599H74.7211V76.4022'
          stroke='#8DA1B4'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.8052 76.4023V85.5294H89.9322V76.4023H86.8899'
          stroke='#8DA1B4'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.7207 82.4866V85.5289'
          stroke='#8DA1B4'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.7207 58.1479V67.275H77.763'
          stroke='#8DA1B4'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.8477 70.3174H89.9323'
          stroke='#8DA1B4'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M54.043 59.9993V52.5995C54.043 51.8145 54.3548 51.0617 54.9099 50.5066C55.465 49.9515 56.2178 49.6396 57.0028 49.6396H64.4025'
          stroke='#A0A8AB'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.0815 49.6396H95.4812C96.2662 49.6396 97.0191 49.9515 97.5742 50.5066C98.1293 51.0617 98.4411 51.8145 98.4411 52.5995V59.9993'
          stroke='#A0A8AB'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.4411 83.6782V91.078C98.4411 91.863 98.1293 92.6158 97.5742 93.1709C97.0191 93.726 96.2662 94.0378 95.4812 94.0378H88.0815'
          stroke='#A0A8AB'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M64.4025 94.0378H57.0028C56.2178 94.0378 55.465 93.726 54.9099 93.1709C54.3548 92.6158 54.043 91.863 54.043 91.078V83.6782'
          stroke='#A0A8AB'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_2398_19118'
          x='25.9749'
          y='0.352274'
          width='100.1'
          height='162.211'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4.82411' />
          <feGaussianBlur stdDeviation='7.53768' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.6 0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0.3 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2398_19118' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2398_19118' result='shape' />
        </filter>
        <filter
          id='filter1_d_2398_19118'
          x='-23.7165'
          y='73.3681'
          width='198.299'
          height='117.513'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='14.7253' />
          <feGaussianBlur stdDeviation='23.9286' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.6 0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0.3 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2398_19118' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2398_19118' result='shape' />
        </filter>
        <clipPath id='clip0_2398_19118'>
          <rect width='152' height='152' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
