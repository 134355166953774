import { useRouter } from 'next/router';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useProduct from 'hooks/use-product';
import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import { useDispensaryCategory } from 'hooks/use-dispensary-category';
import useDispensarySpecials from 'src/dispensary/hooks/use-dispensary-specials';

import { GqlDispensaries as Dispensary, GqlBrand as Brand, GqlSpecials as Special } from 'types/graphql';
import { AllSubcategories } from 'shared/constants';
import { isMedOnly, isRecOnly } from 'shared/core/helpers/dispensaries';
import { getBrand } from 'src/utils/get-brand';

type Subcategory = {
  key: string;
  label: string;
  value: string;
};

const getSubcategoryList = (subcategories: Subcategory[]): string => {
  let subcategoryList = '';
  subcategories.sort((a, b) => a.label.localeCompare(b.label));
  const truncatedSubcategories = subcategories.slice(0, 5);

  truncatedSubcategories.forEach((subcategory, index) => {
    if (index === truncatedSubcategories.length - 1) {
      subcategoryList += `and ${subcategory.label.toLowerCase()}`;
    } else {
      subcategoryList += `${subcategory.label.toLowerCase()}, `;
    }
  });

  return subcategoryList;
};

const getMenuTypes = (dispensary: Dispensary | undefined): string => {
  if (!dispensary) {
    return '';
  }

  if (isMedOnly(dispensary)) {
    return `medicinal`;
  }

  if (isRecOnly(dispensary)) {
    return `recreational`;
  }

  return `recreational and medicinal`;
};

const getBrandName = (brands: Brand[], brandId: string[] | string | undefined): string | undefined => {
  if (typeof brandId !== 'string') {
    return undefined;
  }

  return getBrand({ brands, brandId }).brand?.name ?? `Brands`;
};

const getSpecialName = (specials: Special[], specialId: string[] | string | undefined): string | undefined => {
  if (!specialId) {
    return undefined;
  }

  const special = specials.filter((s) => s._id === specialId)[0] ?? {};
  return special.menuDisplayName ?? undefined;
};

type UseMetaInfoReturn = {
  dispensaryName?: string;
  dispensaryStreet?: string;
  dispensaryCity?: string;
  dispensaryState?: string;
  dispensaryZip?: string;
  productName?: string;
  productBrand?: string;
  brandName?: string | false;
  productCategory?: string;
  productSubcategory?: string;
  productSubcategories?: string;
  menuTypes?: string;
  specialName?: string;
  searchTerm: string[] | string | undefined;
};

export const useMetaInfo = (): UseMetaInfoReturn => {
  const {
    query: { brandId, subcategory, specialId, search },
  } = useRouter();
  const { dispensary } = useDispensary();
  const { product } = useProduct();
  const { brands, subcategories } = useMenuFilterOptions();
  const category = useDispensaryCategory();
  const { specials } = useDispensarySpecials();

  return {
    dispensaryName: dispensary?.name,
    dispensaryStreet: dispensary?.location?.ln1,
    dispensaryCity: dispensary?.location?.city,
    dispensaryState: dispensary?.location?.state,
    dispensaryZip: dispensary?.location?.zipcode,
    productName: product?.name,
    productBrand: product?.brandName ?? product?.brand?.name,
    brandName: getBrandName(brands, brandId),
    productCategory: category?.label ?? product?.type,
    productSubcategory: AllSubcategories.find((sc) => sc.value === subcategory)?.label,
    productSubcategories: getSubcategoryList(subcategories as Subcategory[]),
    menuTypes: getMenuTypes(dispensary),
    specialName: getSpecialName(specials, specialId),
    searchTerm: search,
  };
};
