'use strict';

var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.createConfig = void 0;

var _defineProperty2 = _interopRequireDefault(require('@babel/runtime/helpers/defineProperty'));

var _defaultConfig = require('./default-config');

var _utils = require('../utils');

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly)
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    keys.push.apply(keys, symbols);
  }
  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2['default'])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }
  return target;
}

var deepMergeObjects = ['backend', 'detection'];

var dedupe = function dedupe(names) {
  return names.filter(function (v, i) {
    return names.indexOf(v) === i;
  });
};

var STATIC_LOCALE_PATH = 'static/locales';

var createConfig = function createConfig(userConfig) {
  if (typeof userConfig.localeSubpaths === 'string') {
    throw new Error('The localeSubpaths option has been changed to an object. Please refer to documentation.');
  }
  /*
    Initial merge of default and user-provided config
  */

  var combinedConfig = _objectSpread(_objectSpread({}, _defaultConfig.defaultConfig), userConfig);
  /*
    Sensible defaults to prevent user duplication
  */

  combinedConfig.allLanguages = dedupe(combinedConfig.otherLanguages.concat([combinedConfig.defaultLanguage]));
  combinedConfig.whitelist = combinedConfig.allLanguages;
  var allLanguages = combinedConfig.allLanguages,
    defaultLanguage = combinedConfig.defaultLanguage,
    localeExtension = combinedConfig.localeExtension,
    localePath = combinedConfig.localePath,
    localeStructure = combinedConfig.localeStructure;

  if ((0, _utils.isServer)()) {
    var fs = eval("require('fs')");

    var path = require('path');

    var serverLocalePath = localePath;
    /*
      Validate defaultNS
      https://github.com/isaachinman/next-i18next/issues/358
    */

    if (typeof combinedConfig.defaultNS === 'string') {
      var defaultFile = '/'.concat(defaultLanguage, '/').concat(combinedConfig.defaultNS, '.').concat(localeExtension);
      var defaultNSPath = path.join(process.cwd(), localePath, defaultFile);
      var defaultNSExists = fs.existsSync(defaultNSPath);

      if (!defaultNSExists) {
        /*
          If defaultNS doesn't exist, try to fall back to the deprecated static folder
          https://github.com/isaachinman/next-i18next/issues/523
        */
        var staticDirPath = path.join(process.cwd(), STATIC_LOCALE_PATH, defaultFile);
        var staticDirExists = fs.existsSync(staticDirPath);

        if (staticDirExists) {
          (0, _utils.consoleMessage)(
            'warn',
            'next-i18next: Falling back to /static folder, deprecated in next@9.1.*',
            combinedConfig
          );
          serverLocalePath = STATIC_LOCALE_PATH;
        } else if (process.env.NODE_ENV !== 'production') {
          throw new Error('Default namespace not found at '.concat(defaultNSPath));
        }
      }
    }
    /*
      Set server side backend
    */

    combinedConfig.backend = {
      loadPath: path.join(
        process.cwd(),
        ''.concat(serverLocalePath, '/').concat(localeStructure, '.').concat(localeExtension)
      ),
      addPath: path.join(
        process.cwd(),
        ''.concat(serverLocalePath, '/').concat(localeStructure, '.missing.').concat(localeExtension)
      ),
      /*
        Set server side preload (languages and namespaces)
      */
    };
    combinedConfig.preload = allLanguages;

    if (!combinedConfig.ns) {
      var getAllNamespaces = function getAllNamespaces(p) {
        return fs.readdirSync(p).map(function (file) {
          return file.replace('.'.concat(localeExtension), '');
        });
      };

      combinedConfig.ns = getAllNamespaces(
        path.join(process.cwd(), ''.concat(serverLocalePath, '/').concat(defaultLanguage))
      );
    }
  } else {
    var clientLocalePath = localePath;
    /*
      Remove public prefix from client site config
    */

    if (localePath.startsWith('public/')) {
      clientLocalePath = localePath.replace(/^public\//, '');
    }
    /*
      Set client side backend
    */

    combinedConfig.backend = {
      loadPath: '/'.concat(clientLocalePath, '/').concat(localeStructure, '.').concat(localeExtension),
      addPath: '/'.concat(clientLocalePath, '/').concat(localeStructure, '.missing.').concat(localeExtension),
    };
    combinedConfig.ns = [combinedConfig.defaultNS];
  }
  /*
    Set fallback language to defaultLanguage in production
  */

  if (!userConfig.fallbackLng) {
    combinedConfig.fallbackLng = process.env.NODE_ENV === 'production' ? combinedConfig.defaultLanguage : false;
  }
  /*
    Deep merge with overwrite - goes last
  */

  deepMergeObjects.forEach(function (obj) {
    if (userConfig[obj]) {
      combinedConfig[obj] = _objectSpread(_objectSpread({}, _defaultConfig.defaultConfig[obj]), userConfig[obj]);
    }
  });
  return combinedConfig;
};

exports.createConfig = createConfig;
