/* eslint-disable max-len */
import React from 'react';

function SvgTwitter(props) {
  return (
    <svg width={26} height={21} fill='currentColor' {...props}>
      <path
        fillRule='evenodd'
        d='M8.114 20.64c9.735 0 15.06-7.942 15.06-14.828 0-.226 0-.45-.015-.674A10.68 10.68 0 0025.8 2.44c-.966.422-1.99.698-3.04.82A5.245 5.245 0 0025.087.378a10.708 10.708 0 01-3.361 1.265c-2.005-2.099-5.358-2.2-7.49-.227a5.165 5.165 0 00-1.531 4.98C8.449 6.186 4.484 4.206 1.795.95c-1.404 2.381-.687 5.427 1.64 6.957a5.317 5.317 0 01-2.403-.653v.066c0 2.481 1.777 4.618 4.247 5.11a5.36 5.36 0 01-2.39.089c.693 2.123 2.68 3.577 4.945 3.619A10.727 10.727 0 010 18.299a15.157 15.157 0 008.114 2.337'
        {...props}
      />
    </svg>
  );
}

export default SvgTwitter;
