import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { useApolloClient } from '@apollo/react-hooks';
import useUser from 'hooks/use-user';
import { recordUserAcceptance as recordUserAcceptanceMutation } from 'src/payments/graphql/mutations/record-user-acceptance.gql';
import { EntityTypes } from 'types/graphql';
import { useStores } from 'contexts/stores';
import { ShowEnrollment } from 'src/payments/graphql/queries/show-enrollment.gql';
import {
  ENROLLMENT_EVENTS,
  EVENT_ACTIONS,
  useDutchiePayAnalytics,
} from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import useErnie from 'shared/hooks/use-ernie';
import { formatPaymentsError } from 'src/payments/hooks/use-payments-error/use-payments-error';
import { EnrollmentSteps } from 'src/payments/constants';
import { useDutchiePay } from 'src/payments/hooks';

type RecordUserAcceptanceResult = {
  recordUserAcceptance: () => Promise<boolean>;
};

export const useRecordUserAcceptance = (): RecordUserAcceptanceResult => {
  const apolloClient = useApolloClient();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { dutchiePayEventTracker } = useDutchiePayAnalytics();
  const { whiteLabelConfig } = useDutchiePay();

  const showErnie = useErnie();
  const { Cart } = useStores();
  const User = useUser();

  const recordUserAcceptance = async (): Promise<boolean> => {
    DutchiePayEnrollment.userAcceptanceRequested();
    const recordUserAcceptanceProps = {
      // TODO: once we have the ToC api up, that'll provide us with
      // an acceptanceDocumentId to pass along here
      acceptanceDocumentId: `v1`,
      entityId: User.id,
      entityType: EntityTypes.user,
      userId: User.id,
    };

    try {
      const response = await apolloClient.mutate({
        mutation: recordUserAcceptanceMutation,
        variables: recordUserAcceptanceProps,
        refetchQueries: [{ query: ShowEnrollment }],
        awaitRefetchQueries: true,
      });

      const success = response.data?.recordUserAcceptance?.success;
      if (success) {
        if (DutchiePayEnrollment.autoCheckoutAfterBankLinkReconnect) {
          Cart.setAutoCheckoutAfterBankLinkReconnect(true);
        }
        DutchiePayEnrollment.userAcceptanceSucceeded();
      } else {
        dutchiePayEventTracker(ENROLLMENT_EVENTS.ENROLLMENT_STEP, {
          step: EnrollmentSteps.terms,
          action: EVENT_ACTIONS.FAILED,
        });
        throw new Error(`Failed to record acceptance of terms of service`);
      }

      return success;
    } catch (err) {
      DutchiePayEnrollment.termsOfServiceError();
      const { errorMarkdown, ernieType, ernieTimeout } = formatPaymentsError(
        err,
        whiteLabelConfig.brandedDutchiePayName
      );
      showErnie(errorMarkdown, ernieType, ernieTimeout);
      console.error(err);
      dutchiePayEventTracker(ENROLLMENT_EVENTS.ENROLLMENT_STEP, {
        step: EnrollmentSteps.terms,
        action: EVENT_ACTIONS.FAILED,
      });
      return false;
    }
  };

  return {
    recordUserAcceptance,
  };
};
