import CartItem from 'src/state/cart-item';
import { DEFAULT_CURRENCY, GaV4CartItem } from 'utils/analytics/clients/ga-v4-client';

// TODO: investigate whether this is a GQL type
type Product = { id: string; name: string; type: string };

export const cartItemToGaV4CartItem = (cartItem: CartItem): GaV4CartItem => ({
  ...productToGaV4CartItem(cartItem.product),
  itemCategory2: cartItem.product.subcategory,
  itemVariant: cartItem.option,
  itemBrand: cartItem.product.brand?.name || cartItem.product.brandName,
  quantity: cartItem.quantity,
  price: cartItem.unitPrice,
  currency: DEFAULT_CURRENCY,
  itemListName: cartItem.trackerSource,
});

export const productToGaV4CartItem = (product: Product): GaV4CartItem => ({
  itemId: product.id,
  itemName: product.name,
  itemCategory: product.type,
});
