import { useEffect, useState } from 'react';

import useUI from 'hooks/use-ui';
import { tracker } from '..';

export const useMenuVariantMonitor = (): void => {
  const [variant, setVariant] = useState();
  const UI = useUI();

  useEffect(() => {
    setVariant(UI?.variant);
  }, [UI?.variant]);

  useEffect(() => {
    tracker.setContext({ activeMenuVariant: UI?.variant });
  }, [UI?.variant, variant]);
  return variant;
};
