import { useState } from 'react';
import { useRouter } from 'next/router';

import { getPathToShopDispensaryCategory, getPathToShopMarketplaceCategory } from 'utils/helpers/route';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useClearYourCartModal } from 'components/modals/clear-your-cart';
import useCart from 'hooks/use-cart';
import { useCategoryRouteParam } from 'shop/hooks/use-category-route-param';
import { UseSelectStoreReturn, Dispensary } from './select-store.types';

export function useSelectStore(): UseSelectStoreReturn {
  const { dispensary: dispensaryFromRoute } = useDispensary();

  const [selectedStore, setSelectedStore] = useState<Dispensary | null>(dispensaryFromRoute);
  const [hasInteracted, setHasInteracted] = useState(false);

  const handleClearSelection = (): void => {
    setSelectedStore(null);
    setHasInteracted(true);
  };

  const handleSelect = (dispensary: Dispensary): void => {
    setSelectedStore(dispensary);
    setHasInteracted(true);
  };

  const handleSaveClick = useHandleSaveClick(selectedStore);

  return {
    selectedStore,
    handleClearSelection,
    handleSaveClick,
    hasInteracted,
    handleSelect,
  };
}

function useHandleSaveClick(selectedStore?: Dispensary | null): () => void {
  const { open: openClearYourCartModal } = useClearYourCartModal();
  const { dispensary: dispensaryFromCart } = useCart();
  const pushToCategoryRoute = usePushToCategoryRoute(selectedStore?.cName);
  const newDispensarySelected = dispensaryFromCart?.cName && dispensaryFromCart.cName !== selectedStore?.cName;

  return async () => {
    if (!newDispensarySelected) {
      pushToCategoryRoute();
      return;
    }

    const { cartCleared } = await openClearYourCartModal();

    if (cartCleared) {
      pushToCategoryRoute();
    }
  };
}

function usePushToCategoryRoute(dispensaryCname?: string | null): () => void {
  const { push: pushRoute } = useRouter();
  const { category } = useCategoryRouteParam();

  if (!category) {
    throw new Error(`missing category`);
  }

  const destinationRoute =
    typeof dispensaryCname === 'string'
      ? getPathToShopDispensaryCategory(dispensaryCname, category)
      : getPathToShopMarketplaceCategory(category);

  return () => {
    void pushRoute(destinationRoute);
  };
}
