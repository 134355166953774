import { useApolloClient } from '@apollo/react-hooks';
import { useRouter } from 'next/router';

import useMobxDataBind from 'hooks/use-mobx-data-bind';
import useStores from 'shared/hooks/use-stores';
import useUI from 'hooks/use-ui';

export default function useBackwardsCompatibility() {
  // reverse compatibility for shared checkout components
  // the "stores" object is stable, so mutating it here works
  const stores = useStores();
  const apolloClient = useApolloClient();
  const router = useRouter();
  const UI = useUI();

  if (router?.query?.cName) {
    UI.setEmbeddedCName(router.query.cName);
  }

  useMobxDataBind(stores.Cart, UI, `address`, `currentAddress`);

  stores.apolloClient = apolloClient;
  // jest tests don't have the right UI all the time
  if (UI?.openOrderTypeModal && stores.Cart) {
    stores.Cart.openOrderTypeModal = UI.openOrderTypeModal;
  }

  return stores;
}
