import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';

import { DEFAULT_INPUT_MAX_LENGTH } from 'shared/constants';
import { RadioGroup, Checkbox } from 'shared/components';
import { isRecMed } from 'shared/core/helpers/dispensaries';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import useTranslation from 'hooks/use-translation';
import useCart from 'hooks/use-cart';
import useSwitchMenuType from 'src/hooks/use-switch-menu-type';
import { FormTextInput, FormPhoneNumberInput, FormDateInput } from './form-input';
import { Header, InputContainer, InputLabel, Star } from './components';

export default function OrderInformation(props) {
  const { dispensary, isAnonymousCheckout, setIsAnonymousCheckout } = props;
  const { t } = useTranslation();
  const emailRequired = dispensary.storeSettings?.requireEmailAddressForGuestCheckout;
  const phoneIsRequired = dispensary?.orderTypesConfigV2.kiosk?.phoneRequired;
  const fullNameOnly = dispensary?.orderTypesConfigV2.kiosk?.fullNameOnly;
  const shouldShowBirthdateField = dispensary?.orderTypesConfigV2.kiosk?.showBirthdateField;
  const shouldShowNotesField = dispensary?.orderTypesConfigV2.kiosk?.notesField;
  const hidePhoneField = dispensary?.orderTypesConfigV2.kiosk?.hidePhoneField;
  const hideEmailField = dispensary?.orderTypesConfigV2.kiosk?.hideEmailField;
  const cart = useCart();
  const orderType = useObserver(() => (cart.isMedical ? 'med' : 'rec'));
  const switchMenuType = useSwitchMenuType();
  const useAnonymousKioskCheckout = dispensary?.orderTypesConfigV2.kiosk?.anonymousCheckout && orderType === 'rec';

  const fullNameText = t('order-information.full-name', 'Full Name');
  const firstNameText = t('order-information.first-name', 'First Name');
  const lastNameText = t('order-information.last-name', 'Last Name');
  const anonymousCheckoutText = t('order-information.anonymous-checkout', 'Anonymous Checkout');

  useEffect(() => {
    if (orderType === 'med') {
      setIsAnonymousCheckout(false);
    }
  }, [orderType, setIsAnonymousCheckout]);

  return (
    <Container>
      <HeaderLine>
        <Header>Order Information</Header>
        {!isAnonymousCheckout && <Required>*Required</Required>}
      </HeaderLine>
      {useAnonymousKioskCheckout && (
        <AnonymousCheckoutContainer isAnonymousCheckout={isAnonymousCheckout}>
          <Checkbox
            id='anonymous-kiosk-checkout'
            name='anonymous'
            value={isAnonymousCheckout}
            onChange={(e) => {
              setIsAnonymousCheckout(e.target.checked);
            }}
            borderColor='#caced4'
            backgroundColor='#f3f6f8'
          />
          <AnonymousLabel>{anonymousCheckoutText}</AnonymousLabel>
        </AnonymousCheckoutContainer>
      )}
      {!isAnonymousCheckout && (
        <InputContainer>
          <InputLabel>
            Name:<Star>*</Star>
          </InputLabel>
          <NameBox>
            <FormTextInput
              required={!isAnonymousCheckout}
              name='firstName'
              label={fullNameOnly ? fullNameText : firstNameText}
              inputProps={{ maxLength: DEFAULT_INPUT_MAX_LENGTH }}
            />
            {!fullNameOnly && (
              <FormTextInput
                required={!isAnonymousCheckout}
                label={lastNameText}
                name='lastName'
                inputProps={{ maxLength: DEFAULT_INPUT_MAX_LENGTH }}
              />
            )}
          </NameBox>
        </InputContainer>
      )}
      {!hidePhoneField && !isAnonymousCheckout && (
        <InputContainer>
          <InputLabel>Phone:{phoneIsRequired && <Star>*</Star>}</InputLabel>
          <FormPhoneNumberInput required={phoneIsRequired && !isAnonymousCheckout} name='phone' />
        </InputContainer>
      )}
      {!hideEmailField && !isAnonymousCheckout && (
        <InputContainer>
          <InputLabel>Email:{!!emailRequired && <Star>*</Star>}</InputLabel>
          <FormTextInput label='Email address' name='email' inputProps={{ maxLength: 300 }} />
        </InputContainer>
      )}
      {shouldShowBirthdateField && !isAnonymousCheckout && (
        <InputContainer>
          <InputLabel>
            Birthday:<Star>*</Star>
          </InputLabel>
          <FormDateInput required={!isAnonymousCheckout} name='birth' />
        </InputContainer>
      )}
      {shouldShowNotesField && (
        <InputContainer>
          <InputLabel>Notes:</InputLabel>
          <FormTextInput name='notes' id='specialInstructions' inputProps={{ maxLength: 500 }} />
        </InputContainer>
      )}
      {isRecMed(dispensary) && (
        <InputContainer>
          <InputLabel>
            Order Type:<Star>*</Star>
          </InputLabel>

          <RadioGroup
            options={[
              {
                label: 'Recreational',
                value: 'rec',
              },
              {
                label: 'Medical',
                value: 'med',
              },
            ]}
            display='inline-block'
            defaultValue={orderType}
            onChange={switchMenuType}
          />
        </InputContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 0 45px;
`;

const HeaderLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & + div {
    margin-top: 0px;
  }
`;

const Required = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.grey[60]};
  margin-bottom: 30px;
`;

const NameBox = styled.div`
  display: flex;
  width: 100%;

  && > * {
    margin-right: 15px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

const AnonymousCheckoutContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin: ${(props) => (props.isAnonymousCheckout ? '0px 0px 15px 0px' : '15px 80px 15px 60px')};
  height: 30px;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    flex-direction: row;
    margin: 0;
  }
`;

const AnonymousLabel = styled.div`
  flex: auto 0 0;
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[45]};
`;
