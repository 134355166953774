import React from 'react';

import useTranslation from 'hooks/use-translation';
import { Notification } from 'src/components/notification';

export function CreditAuthNotification(): JSX.Element {
  const { t } = useTranslation();
  const creditAuthNotification = {
    headline: t('payments.creditAuthHeadline', '24 hour card authorization hold.'),
    message: t(
      'payments.creditAuthMessage',
      'To avoid any inconvenience or cancellation of your purchase, please pickup your order within 24 hours after it is ready'
    ),
  };

  return (
    <Notification>
      <strong>{creditAuthNotification.headline}</strong> {creditAuthNotification.message}
    </Notification>
  );
}
