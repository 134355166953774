import React, { HTMLProps } from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

import { Clickable } from 'shared/components';
import { ARIA_ROLES } from 'shared/constants';
import LocationIcon from 'assets/location-icon';

import { useColors } from 'src/contexts/colors';
import useTranslation from 'src/hooks/use-translation';
import { useAddressAutocomplete } from '../context';

export function AddressAutocompleteGeolocateButton(props: HTMLProps<HTMLButtonElement>): JSX.Element {
  const colors = useColors();
  const { t } = useTranslation();
  const { geolocationLoading, handleGeolocation } = useAddressAutocomplete();

  return (
    <Button {...props} onClick={handleGeolocation}>
      {geolocationLoading ? (
        <ClipLoader color={colors.grey[45]} size={21} />
      ) : (
        <>
          <LocationIcon
            role={ARIA_ROLES.IMG}
            title={t('common.location-marker', 'Location Marker')}
            height='19px'
            width='19px'
          />
          {t('common.useCurrentLocation', 'Use Current Location')}
        </>
      )}
    </Button>
  );
}

const Button = styled(Clickable)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryGrey};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.grey[45]};
  cursor: pointer;
  display: flex;
  font-weight: bold;
  border: 0;
  width: 100%;
  font-size: 13px;
  padding: 11px 13px;
  svg {
    margin-right: 10px;
  }
  div {
    margin: 0 auto;
  }
`;
