import React, { memo, useRef } from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import { useRouter } from 'next/router';

import useTranslation from 'src/hooks/use-translation';
import usePaths from 'hooks/use-paths';
import useProductQueryParams from 'hooks/use-product-query-params';

import ChevronIcon from 'assets/chevron-icon';
import { heading4 } from 'components/core/typography';
import { Button, HoverMenu } from 'components/core';
import { useColors } from 'contexts/colors';

import ROUTES from 'src/routes';
import { tracker } from 'src/utils/analytics';

const { DISPENSARY_CNAME, PRODUCTS_CATEGORY } = ROUTES;

function CategoriesDropdown({ categories, currentCategory, isDutchieMain }) {
  const popupState = usePopupState({ variant: `popover`, popupId: `categoriesMenu` });
  const { t } = useTranslation();
  const { href: baseHref, route: baseRoute } = usePaths();
  const { queryParams } = useProductQueryParams();
  const router = useRouter();
  const buttonRef = useRef(null);
  const colors = useColors();

  const categoriesActive = router.route === `${DISPENSARY_CNAME}${PRODUCTS_CATEGORY}` && !queryParams.search;
  const StyledButton = isDutchieMain ? MainStyledButton : EmbeddedStyledButton;
  const chevronColor = categoriesActive ? colors.primaryGreen : colors.grey[30];

  const onCategorySelect = (category) => {
    popupState.close();
    tracker.gaGTMClickEvent({
      eventCategory: `navigation click`,
      eventAction: `main menu`,
      eventLabel: `categories > ${category.key}`,
    });
    router.push(`${baseRoute}/products/[category]`, `${baseHref}/products/${category.key}`);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      popupState.toggle(e.target);
    }
  };

  // direct focus back to the button upon popup close - https://www.w3.org/TR/wai-aria-practices-1.1/examples/menu-button/menu-button-links.html
  const handleExit = () =>
    _.defer(() => {
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    });

  return (
    <>
      <ButtonContainer active={categoriesActive}>
        <StyledButton
          {...bindHover(popupState)}
          onKeyDown={handleEnterKey}
          isOpen={popupState.isOpen}
          active={categoriesActive}
          ref={buttonRef}
          tabIndex={0}
          noDim
        >
          {t('common.category_plural', 'Categories')}
          <StyledChevronIcon height='6px' width='11px' fill={isDutchieMain ? chevronColor : undefined} />
        </StyledButton>
      </ButtonContainer>
      <StyledHoverMenu
        {...bindMenu(popupState)}
        mt={isDutchieMain ? 22 : 28}
        onExit={handleExit}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        options={categories}
        activeOptionKey={currentCategory?.key}
        onOptionSelect={onCategorySelect}
      />
    </>
  );
}

export default memo(CategoriesDropdown);

const StyledHoverMenu = styled(HoverMenu)`
  ${space}
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 20px;

  ${({ active }) =>
    active &&
    css`
      border-bottom: ${({ theme }) => `2px ${theme.colors.primaryGreen} solid`};
    `}
`;

const buttonStyles = css`
  ${heading4}

  padding: 0;
  border-radius: 0;
  text-transform: capitalize;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;

  color: ${({ theme }) => theme.colors.primaryBlack};

  ::after {
    position: absolute;
    width: 160px;
    top: 100%;
    left: 50%;
    margin-left: -80px;
    background: transparent;
    content: '';
    height: ${({ isOpen }) => (isOpen ? `30px` : `0`)};
  }
`;

const EmbeddedStyledButton = styled(Button)`
  ${buttonStyles}
`;

const MainStyledButton = styled(Button)`
  ${buttonStyles}
  line-height: 25px;
  color: ${({ active, theme }) => (active ? theme.colors.primaryGreen : theme.colors.grey[30])};

  ${({ active }) =>
    active &&
    css`
      margin-bottom: -2px;
    `}
`;

const StyledChevronIcon = styled(ChevronIcon)`
  &&& {
    margin: 2px 0 0 8px;
    margin-right: 0;
  }
`;
