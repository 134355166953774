import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { BottomDrawer } from 'components/core/bottom-drawer';
import { CheckboxGroup } from 'components/core/checkbox-group/checkbox-group';
import { Button } from 'components/core';
import { StrainTypeFilterDrawerProps } from './strain-type-filter-drawer.types';

export function StrainTypeFilterDrawer({
  open,
  onClose,
  onSave,
  onSelect,
  options,
  selectedOptions = [],
}: StrainTypeFilterDrawerProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <BottomDrawer open={open} onClose={onClose} heading={t('strain-type', 'Strain Type')}>
      <CheckboxContainer>
        <CheckboxGroup options={options} selectedOptions={selectedOptions} onSelect={onSelect} />
      </CheckboxContainer>
      <Button fontSize={13} size='large' onClick={onSave}>
        {t('save', 'Save')}
      </Button>
    </BottomDrawer>
  );
}

const CheckboxContainer = styled.div`
  margin: 4px 0 24px;
`;
