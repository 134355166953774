import React, { forwardRef, useMemo } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useRouter } from 'next/router';

import { mediaQueries } from 'shared/styles';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useIframeHeightOverride from 'hooks/use-iframe-height-override';
import useOrderForConsumerView from 'hooks/use-order-for-consumer-view';
import useViewport from 'hooks/use-viewport';

import { ModalContainer, ModalExitIcon } from 'components/core';
import EmptyLoadingState from 'components/empty-loading-state';

import OrderStatus from './order-status';

const getIframeHeightOnMobileIframe = (isMobile, isIframe, height, offsetTop) => {
  if (isMobile && isIframe) {
    return height - offsetTop;
  }

  return null;
};

export const OrderStatusModal = forwardRef(({ containerProps, onClose }, ref) => {
  const { order, loading: orderLoading } = useOrderForConsumerView();
  const { dispensary, loading: dispensaryLoading } = useDispensary(order?.dispensary._id);
  const { height: viewportHeight, offsetTop: viewportOffsetTop } = useViewport();

  const isIframe = containerProps?.isIframe;
  const showLoadingSpinner = orderLoading || dispensaryLoading || !order;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));
  const iframeHeightOverrideValue = useMemo(
    () => getIframeHeightOnMobileIframe(isMobile, isIframe, viewportHeight, viewportOffsetTop),
    [isMobile, isIframe, viewportHeight, viewportOffsetTop]
  );
  const { query } = useRouter();

  // Ensures that the only scrollable area while the dialog is visible is the dialog [ENG-2444]
  useIframeHeightOverride(iframeHeightOverrideValue);

  return (
    <StyledModalContainer isOpen onClose={onClose} ref={ref} maxWidth='900px' width='80vw' p={0} {...containerProps}>
      <OrderContainer showLoadingSpinner={showLoadingSpinner}>
        {query.hideClose !== 'true' && <ModalExitIcon onClick={onClose} />}
        <EmptyLoadingState isLoading={showLoadingSpinner} page='default'>
          <OrderStatus order={order} dispensary={dispensary} onClose={onClose} />
        </EmptyLoadingState>
      </OrderContainer>
    </StyledModalContainer>
  );
});

const OrderContainer = styled.div`
  display: flex;
  text-align: left;
  align-items: ${({ showLoadingSpinner }) => (showLoadingSpinner ? `center` : `unset`)};
  justify-content: ${({ showLoadingSpinner }) => (showLoadingSpinner ? `center` : `flex-start`)};
  overflow: hidden;
  height: 100%;
  min-height: 511px;
  max-width: 1150px;
  @media ${mediaQueries.largePhone} {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  min-width: fit-content;
  width: 90vw;
  ${({ theme }) => theme?.breakpoints?.down('sm')} {
    max-height: unset;
    overflow: scroll !important;
    top: 40px;
    height: 100%;
    width: 100%;
    transform: none;
    margin: 0;
    &&& {
      top: 0;
      left: 0;
      transform: none;
    }
  }
`;
