/* eslint-disable max-len */
import React from 'react';

const Completed = (props) => (
  <svg height='72' viewBox='0 0 72 72' width='72' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g fill='none' fillRule='evenodd' transform='translate(2 2)'>
      <circle cx='34' cy='34' fill='#afb0b0' r='34' stroke='#afb0b0' strokeWidth='4' />
      <path
        d='m23.726087 28.8651685-6.726087 6.6460675 12.6391304 12.488764 21.3608696-21.1067416-4.8782609-4.8932584-16.3347826 13.3651685z'
        fill='#fff'
        fillRule='nonzero'
      />
    </g>
  </svg>
);

export default Completed;
