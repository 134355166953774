import React from 'react';
import styled from 'styled-components';
import { ComboboxList, ComboboxListProps } from '@reach/combobox';

import useTranslation from 'src/hooks/use-translation';
import { useAddressAutocomplete } from '../context';
import { AddressAutocompleteOption } from './option';

type Props = ComboboxListProps & { wrapText?: boolean };

export function AddressAutocompleteOptionsList({ wrapText, ...props }: Props): JSX.Element {
  const { t } = useTranslation();
  const { suggestions } = useAddressAutocomplete();

  return (
    <StyledComboboxList
      aria-hidden={suggestions.length === 0}
      aria-label={t('common.selectAnAddress', 'Select an address')}
      {...props}
    >
      {suggestions.map((suggestion, i) => (
        <AddressAutocompleteOption
          wrapText={wrapText}
          key={suggestion.address}
          suggestion={suggestion}
          index={i}
          data-testid='address-autocomplete-option'
        />
      ))}
    </StyledComboboxList>
  );
}

const StyledComboboxList = styled(ComboboxList)`
  white-space: pre;
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;

  [aria-selected='true'] {
    background: ${({ theme }) => theme.colors.primaryGrey};
    box-shadow: ${({ theme }) => theme.shadows.focusVisible};
  }
`;
