import { observable, action, computed, makeObservable } from 'mobx';

import { ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY } from 'shared/constants';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';

export type OrderTypes = typeof ORDER_TYPE_DELIVERY | typeof ORDER_TYPE_PICKUP;

const DEFAULT_DISTANCE = 15;
const DEFAULT_ORDER_TYPE = ORDER_TYPE_PICKUP;

const PERSISTED_BASE_KEY = 'search';
const PERSISTED_DISTANCE_KEY = `${PERSISTED_BASE_KEY}/distance`;
const PERSISTED_ORDER_TYPE_KEY = `${PERSISTED_BASE_KEY}/orderType`;

export default class SearchPreferencesStore {
  @observable isInitialized = false;
  @observable distance = DEFAULT_DISTANCE;
  @observable orderType: OrderTypes = DEFAULT_ORDER_TYPE;

  constructor() {
    makeObservable(this);
  }

  @computed
  get isPickup(): boolean {
    return this.orderType === ORDER_TYPE_PICKUP;
  }

  @action
  initialize = (): void => {
    if (!this.isInitialized) {
      this.setDistance(getPersistedValue(PERSISTED_DISTANCE_KEY, DEFAULT_DISTANCE));
      this.setOrderType(getPersistedValue(PERSISTED_ORDER_TYPE_KEY, DEFAULT_ORDER_TYPE));
      this.isInitialized = true;
    }
  };

  @action
  setOrderType = (orderType: OrderTypes): void => {
    this.orderType = orderType;
    setPersistedValue(PERSISTED_ORDER_TYPE_KEY, this.orderType);
  };

  @action
  setDistance = (value: number): void => {
    this.distance = value;
    setPersistedValue(PERSISTED_DISTANCE_KEY, this.distance);
  };
}
