import { formatCurrency } from 'shared/helpers/utils';
import { getPotencyStrings, Product } from 'src/utils/helpers/product';

import { useAddBuyingOptionToCart } from '../../hooks/use-add-buying-option-to-cart';
import { getPriceForBuyingOption } from '../../helpers';

type UseOptionCardProps = {
  product: Product;
  weight: string | null;
};

type UseOptionCardReturn = {
  cbdRange: string | null;
  handleClickAddToCart: () => void;
  basePriceToDisplay: string;
  thcRange: string | null;
  showMetaInfoSection: boolean;
};

export function useOptionCard({ product, weight }: UseOptionCardProps): UseOptionCardReturn {
  const { handleAddToCart } = useAddBuyingOptionToCart();

  const { CBD: cbdRange, THC: thcRange } = getPotencyStrings({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    CBDContent: product.CBDContent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    THCContent: product.THCContent,
  });

  const { basePrice } = getPriceForBuyingOption({ product, weightOption: weight });

  const handleClickAddToCart = (): void => handleAddToCart({ product, quantity: 1, weight });

  const showMetaInfoSection = !!product.strainType || !!cbdRange || !!thcRange;

  return {
    cbdRange,
    handleClickAddToCart,
    basePriceToDisplay: formatCurrency(basePrice),
    thcRange,
    showMetaInfoSection,
  };
}
