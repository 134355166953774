import { useRef, useEffect } from 'react';
import isDeepEqualReact from 'fast-deep-equal/react';

export function useMemoArray<T>(arr: Array<T>): Array<T> {
  const refArr = useRef(arr);

  useEffect(() => {
    if (!isDeepEqualReact(refArr.current, arr)) {
      refArr.current = arr;
    }
  }, [arr]);

  return refArr.current;
}
