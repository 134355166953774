import { useEffect } from 'react';

import { ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY } from 'shared/constants';

import useSearchPreferences from 'hooks/use-search-preferences';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

export default function useSearchPreferencesController(): void {
  const { setOrderType } = useSearchPreferences();
  const { dispensary } = useDispensary();

  const hasSingleOrderType = !(dispensary?.offerAnyPickupService && dispensary?.offerDelivery);

  useEffect(() => {
    if (dispensary && hasSingleOrderType) {
      if (dispensary.offerAnyPickupService) {
        setOrderType(ORDER_TYPE_PICKUP);
      } else {
        setOrderType(ORDER_TYPE_DELIVERY);
      }
    }
  }, [setOrderType, dispensary, hasSingleOrderType]);
}
