import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import useCart from 'hooks/use-cart';

import { BottomDrawer } from 'components/core';
import { BottomDrawerProps, ElevatedSection } from 'components/core/bottom-drawer';
import { Search } from 'shop/components/navigation/global-navigation/search';
import { Clickable } from 'shared/components';

export type MarketplaceMobileSearchDrawerProps = Omit<BottomDrawerProps, 'children' | 'heading' | 'subheading'> & {
  onClose: () => void;
  open: boolean;
};

export function MarketplaceMobileSearchDrawer({ onClose, open }: MarketplaceMobileSearchDrawerProps): JSX.Element {
  const { t } = useTranslation();
  const Cart = useCart();

  const storeCopy = Cart.dispensary ? Cart.dispensary.name : t('info.storyCopy', 'All Nearby Stores');

  // @TODO wire up actual clear your cart modal
  const handleOpenClearYourCartModal = (): void => console.log(`opening clear cart modal...`);

  return (
    <StyledBottomDrawer fullHeight open={open} onClose={onClose} hideExitIcon>
      <ElevatedSection>
        <Search />
        <CancelButton onClick={onClose}>{t('cancelButton', 'Cancel')}</CancelButton>
        <HeaderContainer>
          <SearchingCopy>{t('info.searchingCopy', 'Searching')}</SearchingCopy>
          <StoreInfoContainer>
            <StoreCopy>{storeCopy}</StoreCopy>
            {!!Cart.dispensary && (
              <SearchAllStores onClick={handleOpenClearYourCartModal}>
                {t('info.searchAllStores', 'Search all stores')}
              </SearchAllStores>
            )}
          </StoreInfoContainer>
        </HeaderContainer>
      </ElevatedSection>
    </StyledBottomDrawer>
  );
}

const StyledBottomDrawer = styled(BottomDrawer)`
  .MuiPaper-root {
    padding-top: 15px;
  }
`;

const CancelButton = styled(Clickable)`
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  position: absolute;
  top: 32px;
  right: 25px;
`;

const HeaderContainer = styled.div`
  padding: 15px 0 20px;
`;

const SearchingCopy = styled.h3`
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey[45]};
  padding-bottom: 2px;
  text-transform: uppercase;
`;

const StoreInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StoreCopy = styled.h3`
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const SearchAllStores = styled(Clickable)`
  font-weight: bold;
  font-size: 11px;
  line-height: 11px;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;
