// TODO: move all of these into route helpers: https://dutchie.atlassian.net/browse/ENG-4500
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'hooks/use-ui';
import { useRouter } from 'next/router';
import { sanitizeUrl } from '@braintree/sanitize-url';

import {
  addMenuSectionRoutes,
  addDispensaryRoutes,
  addProductRoutes,
  addSpecialsRoutes,
  buildBaseRoute,
  buildCheckoutRoute,
  buildDispensariesRoute,
  buildExternalTemplateRoute,
  buildHomeRoute,
  buildStoreFrontRoute,
  isStoreFrontRouteRoot,
} from 'shared/helpers/route-helpers';
import ROUTES from '../routes';

/**
 * @param {{
 *  brands?: boolean;
 *  category?: { key: string };
 *  categories?: boolean;
 *  info?: boolean;
 *  product?: GqlOrder_Product | GqlProducts;
 *  menuSection?: { id?: string | null};
 *  checkout?: boolean;
 *  subcategories?: boolean;
 *  specials?: boolean;
 *  locations?: boolean;
 *  browse?: boolean;
 *  home?: boolean;
 *  search?: boolean;
 *  dispensaries?: boolean;
 *  id?: string;
 *  dispensary?: GqlOrders_Dispensary | GqlDispensaries;
 * }}
 *
 * @returns {{
 *   href: string;
 *   route: string;
 *   query?: { [x in string]?: string };
 * }}
 */
export default function usePaths({
  brands,
  category,
  categories,
  info,
  product,
  menuSection,
  checkout,
  search,
  subcategory,
  specials,
  locations,
  browse,
  home,
  dispensaries,
  id,
  dispensary: dispensaryFromProps,
} = {}) {
  const { dispensary: dispensaryFromHook } = useDispensary();
  const dispensary = dispensaryFromProps || dispensaryFromHook;
  const { isKiosk, isEmbedded, isStoreFront, isDemo, isEmbeddedCarousel, isMobileEcommApp } = useUI();
  const router = useRouter();
  const { routeRoot } = router.query;
  const menuUrl = dispensary?.menuUrl ?? '';

  let href, route;
  const query = {};

  if (checkout) {
    [href, route] = buildCheckoutRoute({ dispensary, isDemo, isStoreFront, isEmbedded, isMobileEcommApp });

    return { href, route };
  }

  [href, route] = buildBaseRoute({ isDemo, isKiosk, isEmbedded, isStoreFront, isMobileEcommApp, dispensary });
  [href, route] = addDispensaryRoutes({ href, route, dispensary, info });

  if (isEmbeddedCarousel) {
    const sanitizedRouteRoot = sanitizeUrl(routeRoot);
    const routeElements = { routeRoot: sanitizedRouteRoot, brands, category, product, subcategory, specials };

    if (isStoreFrontRouteRoot(routeRoot)) {
      return buildStoreFrontRoute(routeElements);
    }

    const checkedRouteRoot = menuUrl !== sanitizedRouteRoot ? 'about:blank' : sanitizedRouteRoot;
    return buildExternalTemplateRoute({ ...routeElements, routeRoot: checkedRouteRoot });
  }

  if (specials) {
    const currentRoute = router.route;
    const specialId = router?.query?.specialId;
    [href, route] = addSpecialsRoutes({ href, route, id, currentRoute, specialId });
  } else if (product) {
    [href, route] = addProductRoutes({ href, route, product });
  } else if (menuSection) {
    [href, route] = addMenuSectionRoutes({ href, route, menuSection });
  } else if (search) {
    href += ROUTES.ALL_PRODUCTS_SEARCH;
    route += ROUTES.ALL_PRODUCTS_SEARCH;
  } else if (category && category.key) {
    if (subcategory) {
      href += `${ROUTES.PRODUCTS}${category.key}/${subcategory}`;
      route += ROUTES.SUBCATEGORY;
    } else {
      href += `${ROUTES.PRODUCTS}${category.key}`;
      route += ROUTES.PRODUCTS_CATEGORY;
    }
  } else if (locations) {
    href += ROUTES.LOCATIONS;
    route += ROUTES.LOCATIONS;
    if (browse) {
      href += ROUTES.BROWSE;
      route += ROUTES.BROWSE;
    }
  } else if (brands) {
    href += ROUTES.BRANDS;
    route += ROUTES.BRANDS;
  } else if (categories) {
    href += ROUTES.CATEGORIES;
    route += ROUTES.CATEGORIES;
  }

  if (home) {
    [href, route] = buildHomeRoute();
  }

  if (dispensaries) {
    [href, route] = buildDispensariesRoute();
  }

  return { href, route, query };
}
