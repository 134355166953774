export default {
  firstName: {
    missing: 'Please enter your first name',
    missingShort: 'Enter your first name',
    tooLong: 'First name must be less than 100 characters'
  },
  lastName: {
    missing: 'Please enter your last name',
    missingShort: 'Enter your last name',
    tooLong: 'Last name must be less than 100 characters'
  },
  email: {
    missing: 'Please enter your email address',
    invalid: 'Please enter a valid email address',
    duplicate: 'There is an existing account tied to this email address. Please log in.',
    subscriptionRequired: 'Cannot subscribe to emails from the dispensary, without creating an account.',
    tooLong: 'Email must be less than 300 characters'
  },
  phone: {
    missing: 'Please enter a valid phone number'
  },
  driversLicense: {
    missing: 'Please enter your drivers license',
    invalid: 'Please enter a valid drivers license number'
  },
  photoId: {
    missing: 'Please upload your photo ID'
  },
  birthday: {
    missing: 'Please enter a valid birthdate',
    minAge: function minAge(_minAge) {
      return "You must be at least ".concat(_minAge, " to order");
    }
  },
  deliveryOption: {
    missing: 'Please select an order type'
  },
  coupon: {
    noMatch: "We couldn't find a matching coupon",
    missing: 'This coupon is invalid',
    inactive: 'This coupon is no longer active',
    used: 'This coupon has already been used',
    invalid: 'This coupon is no longer valid',
    expired: 'This coupon has expired'
  },
  rewards: {
    notOffered: function notOffered(dispensaryName) {
      return "".concat(dispensaryName, " does not accept rewards");
    }
  },
  customerState: {
    missing: 'You must specify which state you are ordering from'
  },
  purchaseLimits: {},
  products: {
    missing: 'Order must contain at least one product'
  },
  paymentMethod: {
    missing: 'Please select a payment method',
    noCash: "Payment method can't be cash",
    missingCardInfo: 'Card info must exist',
    connectHypur: 'Please connect your hypur account before placing your order.',
    savePaysafe: 'Save your credit card information before placing your order.',
    orderTypeMismatch: function orderTypeMismatch(orderType) {
      return "The selected payment type is not valid for ".concat(orderType, ". Please choose a different payment method.");
    }
  },
  medicalOrder: {
    medicalOnly: function medicalOnly(dispensaryName) {
      return "".concat(dispensaryName, " is medical only Please enter your medical card information");
    },
    medicalAcknowledge: function medicalAcknowledge(dispensaryName) {
      return "Ordering from ".concat(dispensaryName, " is available to medical customers only Please acknowledge you have a valid medical card");
    },
    noMedical: function noMedical(dispensaryName) {
      return "".concat(dispensaryName, " does not offer medical orders at this time");
    }
  },
  medicalCard: {
    stateMatch: 'Medical state must match dispensary',
    validState: 'Please choose a valid state',
    photoMissing: 'Please upload a photo of your medical card',
    expired: 'Your medical card is expired',
    expirationDateMissing: 'Please enter a valid expiration date',
    numberRequired: 'Please enter a valid medical card number',
    tooLong: 'Medical card number must be less than 500 characters',
    numberInvalid: 'Please enter a valid medical card number'
  },
  hours: {
    closed: 'Orders must be placed within open dispensary hours',
    noHours: "Could not retrieve dispensary's hours",
    paused: "We're closed at the moment due to extremely high demand Stay tuned though, we'll reopen soon!"
  },
  delivery: {
    outOfBounds: 'The address provided is not within delivery area',
    noHours: "Could not retrieve dispensary's delivery hours",
    address: 'Cannot submit multiple orders from the same address to this dispensary on the same day',
    customer: 'Cannot submit multiple orders from the same customer to this dispensary on the same day',
    notOffered: function notOffered(dispensaryName) {
      return "".concat(dispensaryName, " does not offer delivery");
    }
  },
  pickup: {
    notOffered: function notOffered(dispensaryName) {
      return "".concat(dispensaryName, " does not offer pickup");
    }
  },
  guestUser: {
    invalid: 'Invalid Guest User'
  },
  user: {
    missing: "We couldn't find a valid user for this userId",
    invalid: 'Invalid User',
    idRequired: 'User ID is a required field',
    loginRequired: 'Please login or signup before placing your order'
  },
  dispensary: {
    invalidId: 'Dispensary ID must be for a valid dispensary'
  },
  password: {
    missing: 'Please enter a password',
    length: 'Password must be at least 8 characters'
  },
  scheduledOrdering: {
    invalidTime: 'Scheduled orders must have a valid reservation time.',
    selectTime: 'Please select a time for this order.',
    unavailableTime: 'The selected time period is no longer available for ordering. Please select another.'
  },
  expectedTotal: {
    mustBeGreaterThanZero: 'Your order total must be greater than $0.00. Please add items to your order and try again.',
    deliveryMinimum: function deliveryMinimum(_deliveryMinimum) {
      return "Please meet the delivery minimum of $".concat(_deliveryMinimum, ".");
    },
    pickupMinimum: function pickupMinimum(_pickupMinimum) {
      return "Please meet the pickup minimum of $".concat(_pickupMinimum, ".");
    },
    mismatch: 'Server Order Data Mismatch.'
  },
  channelSource: {
    invalid: 'Invalid Channel Source'
  }
};