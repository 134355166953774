import React from 'react';
import styled from 'styled-components';

import { Typography } from 'src/typography';

const Label = styled(Typography.Label)`
  color: ${({ theme }) => theme.colors.grey[40]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
`;

type SponsoredTagProps = {
  isSponsored: boolean;
};

export const SponsoredTag = ({ isSponsored }: SponsoredTagProps): JSX.Element => (
  <Container>
    {isSponsored && (
      <Label tag='label' size='xsmall'>
        Sponsored
      </Label>
    )}
  </Container>
);

const Container = styled.div`
  height: 15px;
  margin-bottom: 7px;
`;
