import React from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';

import useUser from 'src/hooks/use-user';
import { Loyalty as LoyaltyIcon } from 'src/assets/loyalty';
import { Button, TextButton } from 'src/components/core';
import { DialogHeading } from 'src/components/modals/components/simple-dialog';
import { Statuses } from 'src/components/loyalty/use-loyalty';

type ConnectProps = {
  onClose: () => void;
  setStatus: (status: Statuses) => void;
  programName: string;
  programDescription: string;
};

export const Connect = ({ onClose, setStatus, programName, programDescription }: ConnectProps): JSX.Element => {
  const User = useUser();
  const firstName = useObserver(() => User.firstName);

  const handleConnect = (): void => {
    setStatus(Statuses.terms);
  };

  return (
    <>
      <StyledHeading fontSize={20}>Welcome, {firstName}</StyledHeading>

      <LoyaltyIcon />

      <SubHeading>Connect your account to {programName}</SubHeading>
      <Content>{programDescription}</Content>

      <Button onClick={handleConnect} mb={16} width='264px'>
        Connect
      </Button>
      <StyledTextButton onClick={onClose}>Not right now</StyledTextButton>
    </>
  );
};

const StyledHeading = styled(DialogHeading)`
  margin-bottom: 24px;
`;

const StyledTextButton = styled(TextButton)`
  font-weight: 600;
`;

const SubHeading = styled.h4`
  margin: 24px 0 8px;
`;
const Content = styled.p`
  color: #646d72;
  margin-bottom: 32px;
`;
