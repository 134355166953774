import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import _once from 'lodash/once';
import gql from 'graphql-tag';

import {
  dispatchOnMostTrackers,
  getDispensaryGaV4Client,
  initGlobalTracker,
  cartItemToUAObject,
} from 'hooks/use-google-analytics';
import { pushGTMDataLayer } from 'hooks/use-google-tag-manager';
import { cartItemToGaV4CartItem, productToGaV4CartItem } from 'utils/analytics/helpers/ga-v4-converters';
import { DEFAULT_CURRENCY } from 'utils/analytics/clients/ga-v4-client';
import { cartItemToGTMCartItem } from 'utils/analytics/trackers/internal-gtm-tracker/helpers/internal-gtm-converters';
import { recursiveKeyWalker } from 'shared/helpers/utils';

/**
 * @fileoverview
 *
 * @deprecated - transitioning to new analytics framework (see {@link ./analytics/README.md})
 */

const createAnalyticsPayload = gql`
  mutation SendAnalyticsPayload($payload: JSONObject!) {
    logAnalyticsPayload(payload: $payload) {
      success
    }
  }
`;

async function dispatchDebugEventTracker(gtmPayload = {}, gaPayload = {}) {
  if (!window?._____APP_STATE_____) {
    return;
  }
  const { apolloClient, Cart } = window._____APP_STATE_____;

  if (!apolloClient?.mutate || !Cart) {
    return;
  }

  let payload = {
    dispensaryId: Cart.dispensary?.id,
    checkoutToken: Cart.checkoutToken,
    gtmPayload,
    gaPayload,
  };

  payload = recursiveKeyWalker(payload, (key) => key.replace(/\./g, '[dot]'));

  if (!payload.gtmPayload.event) {
    return;
  } // Don't send ID events

  await apolloClient.mutate({
    mutation: createAnalyticsPayload,
    variables: { payload },
  });
}

/**
 * Push an ecommerce payload to the datalayer if the GA v4 client isn't
 * operational (and therefore piggypacking the payload on GA v4 events).
 *
 * Without this check, we would transmit duplicate GTM ecommerce events.
 *
 * See ga-v4-client.ts -> attachLegacyEcommercePayload() for more details.
 */
export function maybePushEcommerceToDatalayer(payload) {
  const gaV4ClientIsResonsibleForEcommerceEvents = getDispensaryGaV4Client().isCapturingEcommercePayload();
  if (!gaV4ClientIsResonsibleForEcommerceEvents) {
    pushGTMDataLayer(payload);
  }

  initGlobalTracker();

  (() => {
    const callOnce = _once((gtmPayload, gaPayload) => dispatchDebugEventTracker(gtmPayload, gaPayload));
    window.ga?.((tracker) => {
      const gaPayload = {
        clientId: tracker.get('clientId'),
        referrer: tracker.get('referrer'),
        userId: tracker.get('userId'),
      };
      callOnce(payload, gaPayload);
    });
    callOnce(payload); // We want to always fire this payload if ga is not available, this is a simple dedupe.
  })();
}

export function productImpression(rawProduct, _cart) {
  const gaPayload = {
    id: rawProduct.id,
    name: rawProduct.Name,
    category: rawProduct.type,
    variant: rawProduct.strainType,
    position: 1,
  };

  dispatchOnMostTrackers(`ec:addImpression`, gaPayload);
  dispatchOnMostTrackers('ec:setAction', 'detail');
  dispatchOnMostTrackers(`send`, 'pageview');
  getDispensaryGaV4Client().viewItem({
    items: [productToGaV4CartItem(rawProduct)],
  });

  const gtmPayload = {
    item_id: rawProduct.id,
    item_name: rawProduct.name,
    item_category: rawProduct.type,
  };
  maybePushEcommerceToDatalayer({
    event: 'view_item',
    ecommerce: {
      items: [gtmPayload],
    },
  });
}

export function menuInteraction(dispensary) {
  const gaPayload = {
    dispensaryId: dispensary.id,
    dispensaryName: dispensary.name,
  };

  dispatchOnMostTrackers(`ec:addImpression`, gaPayload);
}

export function productAddedToCart(cartItem, cart) {
  const gaPayload = cartItemToUAObject(cartItem);
  dispatchOnMostTrackers(`ec:addProduct`, gaPayload);
  dispatchOnMostTrackers(`ec:setAction`, 'add');
  dispatchOnMostTrackers(`ec:addImpression`, gaPayload);
  dispatchOnMostTrackers('send', 'event', 'UX', 'click', 'add to cart');

  // deliberately avoiding pattern of cartItem.toXXObject() since we're
  // about to undertake a larger refactor that'll make it redundant.
  getDispensaryGaV4Client().addToCart({
    items: [cartItemToGaV4CartItem(cartItem)],
    currency: DEFAULT_CURRENCY,
    value: cartItem.unitPrice,
  });

  maybePushEcommerceToDatalayer({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'USD',
      value: cart.costBreakdown?.total,
      affiliation: cart.dispensary?.name,
      items: [cartItemToGTMCartItem(cartItem)],
    },
  });
}

export function productRemovedFromCart(cartItem, _cart) {
  const gaPayload = cartItemToUAObject(cartItem);
  dispatchOnMostTrackers(`ec:addProduct`, gaPayload);
  dispatchOnMostTrackers(`ec:setAction`, 'remove');
  dispatchOnMostTrackers('send', 'event', 'UX', 'click', 'remove from cart');

  getDispensaryGaV4Client().removeFromCart({
    items: [cartItemToGaV4CartItem(cartItem)],
    currency: DEFAULT_CURRENCY,
    value: cartItem.unitPrice,
  });

  maybePushEcommerceToDatalayer({
    event: 'remove_from_cart',
    ecommerce: {
      items: [cartItemToGTMCartItem(cartItem)],
    },
  });
}

export function cartDidViewCheckout(cart) {
  _forEach(cart.items, (item) => dispatchOnMostTrackers(`ec:addProduct`, cartItemToUAObject(item)));
  dispatchOnMostTrackers('ec:setAction', 'checkout', {
    step: 1,
    option: cart.isDelivery ? 'delivery' : 'pickup',
  });

  getDispensaryGaV4Client().beginCheckout({
    items: _map(cart.items, (item) => cartItemToGaV4CartItem(item)),
    currency: DEFAULT_CURRENCY,
    value: _reduce(cart.items, (memo, item) => memo + item.unitPrice, 0),
  });

  const gtmObjects = _map(cart.items, cartItemToGTMCartItem);
  maybePushEcommerceToDatalayer({
    event: 'begin_checkout',
    ecommerce: {
      items: gtmObjects,
    },
  });
}

export function cartDidPurchase(cart, serverOrder) {
  _forEach(cart.items, (item) => dispatchOnMostTrackers(`ec:addProduct`, cartItemToUAObject(item)));
  dispatchOnMostTrackers('ec:setAction', 'purchase', {
    affiliation: 'Dutchie - Ecommerce',
    id: serverOrder.orderId || serverOrder.id,
    revenue: serverOrder.totalCost,
    coupon: serverOrder.coupon?.code || '',
  });
  dispatchOnMostTrackers('send', 'event', 'ecommerce', 'purchase', `${serverOrder.orderId}`);

  getDispensaryGaV4Client().purchase({
    transactionId: serverOrder.orderId || serverOrder.id,
    affiliation: 'dutchie',
    value: serverOrder.totalCost,
    items: _map(cart.items, (item) => cartItemToGaV4CartItem(item)),
    currency: DEFAULT_CURRENCY,
  });

  const gtmObjects = _map(cart.items, cartItemToGTMCartItem);
  maybePushEcommerceToDatalayer({
    event: 'purchase',
    ecommerce: {
      transaction_id: serverOrder.orderId || serverOrder.id,
      affiliation: 'dutchie',
      value: serverOrder.totalCost,
      items: gtmObjects,
    },
  });
}
