import { css, createGlobalStyle } from 'styled-components';
import 'focus-visible';

export const focusStyles = css`
  outline: none;
  box-shadow: 0 0 0pt 2pt rgba(11, 153, 230, 0.4);
`;

export const GlobalFontStyles = createGlobalStyle`
  @font-face {
    font-family: Matter;
    font-weight: 300; /* light */
    font-display: block;
    src: url(https://assets2.dutchie.com/platform-assets/Matter/Matter-Light.woff) format('opentype');
  }
  @font-face {
    font-family: Matter;
    font-weight: normal;
    font-display: block;
    src: url(https://assets2.dutchie.com/platform-assets/Matter/Matter-Regular.woff) format('opentype');
  }
  @font-face {
    font-family: Matter;
    font-weight: 600; /* semi-bold */
    font-display: block;
    src: url(https://assets2.dutchie.com/platform-assets/Matter/Matter-SemiBold.woff) format('opentype');
  }
  @font-face {
    font-family: Matter;
    font-weight: bold;
    font-display: block;
    src: url(https://assets2.dutchie.com/platform-assets/Matter/Matter-Bold.woff) format('opentype');
  }

  body {
    font-family: ${({ theme }) => theme.customized.fonts.primary};
  }

  button {
    font-family: inherit;
  }
`;

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    :focus:not(.focus-visible) {
      outline: none;
    }

    .focus-visible,
    button:focus-visible {
      ${focusStyles}
    }
  }

  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  html, body, #__next {
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .iframe--overflow-hidden {
    overflow: hidden;
  }

`;

export const EmbeddedStyles = createGlobalStyle`
  html {
    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
`;
