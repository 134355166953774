import React from 'react';
import _ from 'lodash';
import { getBrand } from 'src/utils/get-brand';
import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import useViewportVisibility from 'hooks/use-viewport-visibility';
import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import usePaths from 'src/hooks/use-paths';
import { defaultFilters } from '../carousels.utils';
import { GenericCarousel } from './generic-carousel';

export function BrandCarousel(props) {
  const { ref, hasBeenVisible } = useViewportVisibility();
  const { brands } = useMenuFilterOptions();

  const { section } = props;
  const { label, brandId } = section;

  const { products, loading } = useDispensaryProducts({
    filters: { ...defaultFilters, brandIds: [brandId] },
  });

  const { href, route } = usePaths({ brands: true });
  const { brand } = getBrand({ brands, brandId });
  const brandSlug = _.kebabCase(brand?.name);

  const hasBrand = products?.length > 0;
  if (!hasBrand && !loading && hasBeenVisible) {
    return null;
  }

  const sectionProps = {
    ...props,
    hasBeenVisible,
    isLoading: loading,
    linkLabel: section.linkLabel,
    label,
    products,
    href: `${href}/${brandSlug}`,
    route: `${route}/[brandId]`,
  };

  return <GenericCarousel ref={ref} {...sectionProps} />;
}
