import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { ElevatedSection } from 'components/core/bottom-drawer';
import { SearchInput, SearchInputShapeVariant } from 'components/core/text-input/search-input';

import { BrandsFilterOptions } from './brands-filter-options';
import { BrandsFilterDrawerProps } from './brands-filter-drawer.types';

export function BrandsFilterDrawer({
  loading,
  inputValue,
  filteredOptions,
  handleSave,
  handleChangeInputValue,
  handleClearInputValue,
  handleClear,
  handleToggle,
  handleIsSelected,
}: BrandsFilterDrawerProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <ElevatedSection pb='20px' pt='20px'>
        <SearchInput
          value={inputValue}
          onChange={handleChangeInputValue}
          onClear={handleClearInputValue}
          placeholder={t('search-brands', 'Search brands...')}
          shapeVariant={SearchInputShapeVariant.marshmallow}
          width='100%'
          withClearButton
        />
      </ElevatedSection>

      <BrandsFilterOptions
        loading={loading}
        filteredOptions={filteredOptions}
        handleClear={handleClear}
        handleSave={handleSave}
        handleToggle={handleToggle}
        handleIsSelected={handleIsSelected}
        handleClearInputValue={handleClearInputValue}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
`;
