import React from 'react';
import styled from 'styled-components';
import Skeleton from './loading-skeleton';

const productDesktopRowLoadingSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonProductDetailContainer>
      <Skeleton height={100} width={100} />
      <SkeletonContentContainer>
        <Skeleton height={8} width={113} />
        <Skeleton height={10} width={269} />
        <Inline>
          <Skeleton height={19} width={58} />
          <Skeleton height={8} width={59} ml={10} mr={10} mt='6px' />
          <Skeleton height={8} width={59} mt='6px' />
        </Inline>
      </SkeletonContentContainer>
    </SkeletonProductDetailContainer>

    <SkeletonTileContainer>
      <Skeleton height={64} width={74} />
      <Skeleton height={64} width={74} ml={13} mr={13} />
      <Skeleton height={64} width={74} />
    </SkeletonTileContainer>
  </SkeletonWrapper>
);

export default productDesktopRowLoadingSkeleton;

const SkeletonWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 140px;
  padding-left: 16px;
  align-items: center;
  justify-content: space-between;
`;

const SkeletonContentContainer = styled.div`
  height: 64px;
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`;

const Inline = styled.div`
  display: flex;
`;

const SkeletonTileContainer = styled.div`
  display: flex;
  align-content: flex-end;
`;

const SkeletonProductDetailContainer = styled.div`
  display: flex;
  align-items: center;
`;
