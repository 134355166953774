import _toConsumableArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _isNil from "lodash/isNil";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _max from "lodash/max";
import _get from "lodash/get";
import _forEach from "lodash/forEach";
import _findIndex from "lodash/findIndex";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Big from 'big.js';
import { calcTaxes, flattenTaxes, layerOrder, relevantTaxRules, sumTaxesAtLayer } from 'shared/helpers/taxes'; // eslint-disable-next-line import/no-cycle

import { getProductWeight } from 'shared/helpers/products'; // eslint-disable-next-line import/no-cycle

import { productSatisfiesSaleWeight } from 'shared/helpers/specials';

var findStageIndex = function findStageIndex(stage) {
  return _findIndex(layerOrder, function (s) {
    return s === stage;
  });
};

export function sumProductTaxes(detail, products, cannabisTaxTotal, salesTaxTotal) {
  var _detail$mixAndMatch, _item$product, _item$product$POSMeta, _item$product$POSMeta2, _item$product$POSMeta3;

  var basePrice = ((_detail$mixAndMatch = detail.mixAndMatch) === null || _detail$mixAndMatch === void 0 ? void 0 : _detail$mixAndMatch.adjustedBasePrice) || detail.basePrice;
  var price = basePrice.times(detail.quantity);
  var qtyWholesalePrice = detail.wholesalePrice.times(detail.quantity);
  var whPrice = qtyWholesalePrice.round(2, 1);
  var item = products["".concat(detail.id, "_").concat(detail.option)];

  var childIndex = _findIndex(item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$POSMeta = _item$product.POSMetaData) === null || _item$product$POSMeta === void 0 ? void 0 : _item$product$POSMeta.children, {
    option: item === null || item === void 0 ? void 0 : item.option
  });

  var child = (_item$product$POSMeta2 = item.product.POSMetaData) === null || _item$product$POSMeta2 === void 0 ? void 0 : (_item$product$POSMeta3 = _item$product$POSMeta2.children) === null || _item$product$POSMeta3 === void 0 ? void 0 : _item$product$POSMeta3[childIndex];
  var cannabisTax = detail.cannabisTax(price, whPrice, item === null || item === void 0 ? void 0 : item.product, item === null || item === void 0 ? void 0 : item.option);
  var salesTax = detail.salesTax(price, whPrice, item === null || item === void 0 ? void 0 : item.product, item === null || item === void 0 ? void 0 : item.option);
  cannabisTaxTotal = cannabisTaxTotal.plus(cannabisTax);
  salesTaxTotal = salesTaxTotal.plus(salesTax);
  return {
    cannabisTaxTotal: cannabisTaxTotal,
    salesTaxTotal: salesTaxTotal
  };
}
export var gatherProductTaxes = function gatherProductTaxes(detailsSorted, products) {
  // we need to gather the total taxes for the products so we can include that in the price that we amortize the
  // discount across in the applyTaxPreDiscount case
  var salesTaxTotal = Big(0);
  var cannabisTaxTotal = Big(0);

  _forEach(detailsSorted, function (productDetail) {
    var productTaxes = sumProductTaxes(productDetail, products, cannabisTaxTotal, salesTaxTotal);
    cannabisTaxTotal = productTaxes.cannabisTaxTotal;
    salesTaxTotal = productTaxes.salesTaxTotal;
  });

  return cannabisTaxTotal.plus(salesTaxTotal);
};
export var taxes = function taxes(cart, dispensary, medicalOrder, isDelivery) {
  var _dispensary$location, _taxExistence$med, _taxExistence$rec, _taxExistence$med2, _taxExistence$rec2, _taxExistence$med3, _taxExistence$rec3;

  var toMenu = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

  // The FE sometimes passes in dispensaries wrapped in mbox proxies which don't don't
  // like the data structues being changed outside of their perview.  We make a top level
  // copy so that we can munge it later on if necessary.
  var taxData = _objectSpread({}, _get(dispensary, "taxConfig", {
    taxes: []
  })); // In CT there is a tax which depends on potency.  We don't have the customer configure it
  // directly but it shows up in the UI so we emulate it here.


  if ((dispensary === null || dispensary === void 0 ? void 0 : (_dispensary$location = dispensary.location) === null || _dispensary$location === void 0 ? void 0 : _dispensary$location.state) === 'CT') {
    taxData.taxes = _toConsumableArray(taxData.taxes);
    taxData.taxes.push({
      name: 'THC Potency Tax',
      type: 'cannabis',
      rate: 'Variable',
      recreational: true,
      medical: false,
      stages: [{
        type: 'pos',
        op: 'noop'
      }, {
        type: 'menu',
        op: 'noop'
      }, {
        type: 'checkout',
        op: 'add'
      }]
    });
  }

  var taxRules = flattenTaxes(taxData);
  var appliedTaxRules;
  var details = [];
  var taxExistence = {
    med: {
      sales: {},
      cannabis: {},
      bottleDeposit: {}
    },
    rec: {
      sales: {},
      cannabis: {},
      bottleDeposit: {}
    }
  };
  var cannabisTax = Big(0),
      salesTax = Big(0),
      bottleDepositTaxCents = Big(0),
      subtotal = Big(0);

  _forEach(cart, function (item) {
    var _dispensary$location2, _item$product$POSMeta4, _item$product2, _item$product3, _item$product3$wholes, _item$product4, _item$product4$specia, _item$product5, _item$product6, _item$product$brandId, _item$product7, _item$product8, _item$product8$brand, _item$product9, _item$product9$brand, _item$product10;

    var thcRange = _get(item, 'product.THCContent.range', []);

    var thcContent = _max(thcRange) || 0; // Default to 0 if range is empty

    var isVapeTaxed = ((_dispensary$location2 = dispensary.location) === null || _dispensary$location2 === void 0 ? void 0 : _dispensary$location2.state) === 'SK' && item.product.vapeTaxApplicable;

    _forEach(['med', 'rec'], function (type) {
      var rules = relevantTaxRules(item.product.type, false, type, taxRules);

      _forEach(['sales', 'cannabis', 'bottleDeposit'], function (taxType) {
        var taxTypeRules = _filter(rules, ['type', taxType]);

        _forEach(['pos', 'menu', 'checkout'], function (stage) {
          var stageIndex = findStageIndex(stage);
          var tax = sumTaxesAtLayer(_filter(taxTypeRules, function (r) {
            var _r$stage;

            return findStageIndex((_r$stage = r.stage) === null || _r$stage === void 0 ? void 0 : _r$stage.type) <= stageIndex;
          }), item);

          if (taxTypeRules.length) {
            taxExistence[type][taxType][stage] = tax.gt(0);
          }
        });
      });
    }); // In the Saskatchwan there are some products which are determined by the government
    // to have a different PST.  These are nominally specific vape products.  We setup the
    // those tax rules to be applied later.


    var vapeTaxRules = _toConsumableArray(taxRules);

    _forEach(vapeTaxRules, function (rule, index) {
      if (rule.name === 'PST') {
        vapeTaxRules[index] = _objectSpread(_objectSpread({}, rule), {}, {
          name: 'Vape',
          rate: 0.2
        });
      }
    }); // In the Saskatchwan there are some products which are determined by the government
    // to have a different PST.  These are nominally specific vape products.  We determine
    // when this is true based on a an attribute on the product.  Someday we might be able
    // to do this by less invasive techniques but for now we're going to hard code this mess.


    appliedTaxRules = isVapeTaxed ? vapeTaxRules : taxRules;
    var menuTaxValues = calcTaxes({
      category: item.product.type,
      hemp: item.product.hemp,
      thcContent: thcContent,
      type: medicalOrder ? "med" : "rec",
      taxRules: appliedTaxRules,
      taxMethod: taxData.calculationMethod,
      stage: toMenu ? "menu" : "checkout",
      isDelivery: isDelivery,
      product: item.product,
      option: item.option,
      dispensary: dispensary,
      quantity: item.quantity
    }); // Given a raw value how do we get to checkout.

    var checkoutTaxValues = calcTaxes({
      category: item.product.type,
      hemp: item.product.hemp,
      thcContent: thcContent,
      type: medicalOrder ? "med" : "rec",
      taxRules: appliedTaxRules,
      taxMethod: taxData.calculationMethod,
      stage: toMenu ? "menu" : "checkout",
      initialStage: "base",
      isDelivery: isDelivery,
      product: item.product,
      option: item.option,
      dispensary: dispensary,
      quantity: item.quantity
    }); // Given a menu value how do we get back to base.

    var posTaxValues = calcTaxes({
      category: item.product.type,
      hemp: item.product.hemp,
      thcContent: thcContent,
      type: medicalOrder ? "med" : "rec",
      taxRules: appliedTaxRules,
      taxMethod: taxData.calculationMethod,
      stage: toMenu ? "pos" : "menu",
      initialStage: "base",
      isDelivery: isDelivery,
      product: item.product,
      option: item.option,
      dispensary: dispensary,
      quantity: item.quantity
    });

    var index = _findIndex(item.product.Options, function (option) {
      return option === item.option;
    });

    var metadata = _find((_item$product$POSMeta4 = item.product.POSMetaData) === null || _item$product$POSMeta4 === void 0 ? void 0 : _item$product$POSMeta4.children, function (child) {
      return [child.option, item.option];
    });

    var price = Big(item.price, "Item Price ".concat(item === null || item === void 0 ? void 0 : (_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : _item$product2._id));
    var wholesalePrice = Big(((_item$product3 = item.product) === null || _item$product3 === void 0 ? void 0 : (_item$product3$wholes = _item$product3.wholesalePrices) === null || _item$product3$wholes === void 0 ? void 0 : _item$product3$wholes[index]) || 0);
    bottleDepositTaxCents = bottleDepositTaxCents.add(menuTaxValues.bottleDepositTaxCents);
    cannabisTax = cannabisTax.add(menuTaxValues.cannabisTax(item.price, wholesalePrice, item.product, item.option));
    salesTax = salesTax.add(menuTaxValues.salesTax(item.price, wholesalePrice, item.product, item.option));
    subtotal = subtotal.add(price);
    var basePrice;

    if (item.product.id === "Adhoc") {
      basePrice = Big(item.product.Prices[index], "Adhoc Base Price");
    } else {
      basePrice = Big( // TODO WEHRMAN COME BACK TO THIS something is borking our index...
      medicalOrder ? item.product.medicalPrices[index < 0 ? 0 : index] : item.product.recPrices[index < 0 ? 0 : index], "Base Price").minus(posTaxValues.bottleDepositTaxCents.div(100)).div(posTaxValues.taxMult);
    }

    var weightInGrams = getProductWeight(item.option);
    var isOnSale = productSatisfiesSaleWeight(item.product, ((_item$product4 = item.product) === null || _item$product4 === void 0 ? void 0 : (_item$product4$specia = _item$product4.specialData) === null || _item$product4$specia === void 0 ? void 0 : _item$product4$specia.saleSpecials) || [], item.option, false);
    details.push({
      type: "product",
      id: item.product._id,
      enterpriseProductId: item === null || item === void 0 ? void 0 : (_item$product5 = item.product) === null || _item$product5 === void 0 ? void 0 : _item$product5.enterpriseProductId,
      posId: (_find(_get(item, "product.POSMetaData.children", []), ["option", item.option]) || {}).canonicalID,
      option: item.option,
      quantity: Number(item.quantity),
      basePrice: basePrice,
      isOnSale: isOnSale,
      key: "".concat(item.product._id, "_").concat(item.option || 'N/A'),
      weightInGrams: weightInGrams,
      weightInMg: Number(weightInGrams * 1000),
      wholesalePrice: wholesalePrice,
      pricingTierData: (_item$product6 = item.product) === null || _item$product6 === void 0 ? void 0 : _item$product6.pricingTierData,
      productBrandId: (_item$product$brandId = (_item$product7 = item.product) === null || _item$product7 === void 0 ? void 0 : _item$product7.brandId) !== null && _item$product$brandId !== void 0 ? _item$product$brandId : item === null || item === void 0 ? void 0 : (_item$product8 = item.product) === null || _item$product8 === void 0 ? void 0 : (_item$product8$brand = _item$product8.brand) === null || _item$product8$brand === void 0 ? void 0 : _item$product8$brand.id,
      productBrand: ((_item$product9 = item.product) === null || _item$product9 === void 0 ? void 0 : (_item$product9$brand = _item$product9.brand) === null || _item$product9$brand === void 0 ? void 0 : _item$product9$brand.name) || ((_item$product10 = item.product) === null || _item$product10 === void 0 ? void 0 : _item$product10.brandName),
      productCategory: item.product.type,
      productSubcategory: item.product.subcategory,
      basePriceMult: posTaxValues.taxMult,
      cannabisTax: checkoutTaxValues.cannabisTax,
      salesTax: checkoutTaxValues.salesTax,
      bottleDepositTaxCents: checkoutTaxValues.bottleDepositTaxCents,
      specialData: item.product.specialData,
      menuBasePrice: item.basePrice || 0,
      menuIndividualPrice: !_isNil(item.discountedBasePrice) ? item.discountedBasePrice : item.basePrice || 0,
      menuPrice: item.price,
      menuCannabisTax: posTaxValues.cannabisTax(item.price, wholesalePrice, item.product, item.option),
      menuSalesTax: posTaxValues.salesTax(item.price, wholesalePrice, item.product, item.option),
      isVapeTaxed: isVapeTaxed,
      canonicalEffectivePotencyMg: metadata === null || metadata === void 0 ? void 0 : metadata.canonicalEffectivePotencyMg,
      useBetterPotencyTaxes: item.product.useBetterPotencyTaxes
    });
  });

  var salesTaxExistence = {
    med: taxExistence === null || taxExistence === void 0 ? void 0 : (_taxExistence$med = taxExistence.med) === null || _taxExistence$med === void 0 ? void 0 : _taxExistence$med.sales,
    rec: taxExistence === null || taxExistence === void 0 ? void 0 : (_taxExistence$rec = taxExistence.rec) === null || _taxExistence$rec === void 0 ? void 0 : _taxExistence$rec.sales
  };
  var cannabisTaxExistence = {
    med: taxExistence === null || taxExistence === void 0 ? void 0 : (_taxExistence$med2 = taxExistence.med) === null || _taxExistence$med2 === void 0 ? void 0 : _taxExistence$med2.cannabis,
    rec: taxExistence === null || taxExistence === void 0 ? void 0 : (_taxExistence$rec2 = taxExistence.rec) === null || _taxExistence$rec2 === void 0 ? void 0 : _taxExistence$rec2.cannabis
  };
  var bottleDepositTaxExistence = {
    med: taxExistence === null || taxExistence === void 0 ? void 0 : (_taxExistence$med3 = taxExistence.med) === null || _taxExistence$med3 === void 0 ? void 0 : _taxExistence$med3.bottleDeposit,
    rec: taxExistence === null || taxExistence === void 0 ? void 0 : (_taxExistence$rec3 = taxExistence.rec) === null || _taxExistence$rec3 === void 0 ? void 0 : _taxExistence$rec3.bottleDeposit
  };
  bottleDepositTaxCents = Big(bottleDepositTaxCents, 'Bottle Deposit Tax');
  cannabisTax = Big(cannabisTax.toFixed(2), 'Cannabis Tax');
  salesTax = Big(salesTax.toFixed(2), 'Sales Tax');
  subtotal = Big(subtotal.toFixed(2), 'Subtotal');
  return {
    bottleDepositTaxCents: bottleDepositTaxCents,
    cannabisTax: cannabisTax,
    salesTax: salesTax,
    subtotal: subtotal,
    details: details,
    salesTaxExistence: salesTaxExistence,
    cannabisTaxExistence: cannabisTaxExistence,
    bottleDepositTaxExistence: bottleDepositTaxExistence
  };
};