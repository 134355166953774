import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import _ from 'lodash';
import scrollIntoView from 'scroll-into-view';
import createContextHelpers from 'shared/helpers/context';
import { ERNIE_TIMEOUTS } from 'shared/constants';
import { getDutchiePayErrorMessage } from '@dutchie/error-messaging';

export class Checkout {
  @observable checkoutId = null;
  @observable variant = `default`;
  @observable deliveryValidationError = null;
  @observable viewingDeliveryErrorModal = false;
  @observable customerCardOpen = false;
  @observable paymentOptions = [];
  @observable returnUrl = null;
  @observable showDutchiePaySignupModal = false;
  @observable showErnie = null;
  @observable openOrderTypeModal = null;
  @observable saveMethods = [];
  @observable hasSucceeded = false;

  constructor() {
    makeObservable(this);
  }

  @action openDeliveryErrorModal = (err) => {
    this.deliveryValidationError = err;
    this.viewingDeliveryErrorModal = true;
  };

  @action closeDeliveryErrorModal = () => {
    this.deliveryValidationError = null;
    this.viewingDeliveryErrorModal = false;
  };

  // variant is set when the context store is initialized
  isVariant = (variant) => {
    if (_.isArray(variant)) {
      // eslint-disable-next-line lodash/prefer-lodash-method
      return variant.includes(this.variant);
    }
    return this.variant === variant;
  };

  /*
   * handle errors
   */
  @observable errors = [];

  /**
   * @param {{ path?: null | string, card?: null | string, match?: boolean }} options
   * @returns {void}
   */
  @action removeError = ({ path = null, card = null, match = false }) => {
    this.errors = _.filter(this.errors, (err) => {
      if (!err) {
        return false;
      }
      if (path && card) {
        return err.path !== path && err.card !== card;
      }
      if (path) {
        return err.path !== path;
      }
      if (card) {
        return err.card !== card;
      }
      if (match) {
        return !_.includes(err.path, match) && !_.includes(err.card, match);
      }
      return true;
    });
  };

  @action addErrors = (errors) => {
    // destructure params onto the main error object. This is mainly used
    // to pull the 'card' value out of params from yup error objects.
    const fullErrors = _.map(errors, (e) => ({ ...e, ...e.params }));
    // fullErrors are first so that a new error message from the server
    // with the same path as an existing error is overwritten.
    const sortedErrors = _.sortBy(fullErrors, [(e) => e.type !== 'len']);
    // sortedErrors puts errors with length first, then server errors. This
    // is so if there is nothing in the input field, then the length error shows.
    this.errors = _.unionBy(sortedErrors, this.errors, (e) => e.path);

    this.showErrorAlert();
    this.attemptToScrollToError();
    this.attemptToOpenErrorModal();
  };

  getError = ({ path = null, card = null, match = false }) => {
    let err = { error: false, message: '' };
    let hasError = _.find(this.errors, _.pickBy({ path, card }));
    if (match) {
      hasError = _.find(this.errors, (e) => _.includes(e.path, match));
    }
    if (hasError) {
      err = { error: true, ...hasError };
    }
    return err;
  };

  showErrorAlert = () => {
    const count = this.errors.length;
    let { message } = this.errors[0];
    const { modalMapPath = false, path, extensions } = this.errors[0];
    const errorTimeout = path === 'dutchiePay' ? ERNIE_TIMEOUTS.LONG : ERNIE_TIMEOUTS.MED;

    // if we show a modal, no need to show ernie
    if (modalMapPath) {
      return;
    }

    if (count > 1 || !message) {
      message = `Please fill out the missing information.`;
    }

    if (path === 'dutchiePay') {
      if (!extensions?.paymentsServiceError) {
        const errorMessage = getDutchiePayErrorMessage(extensions?.reason_code).ecomm;
        const reasonCodeMarkdown = extensions?.reason_code ? `**${extensions.reason_code}**` : '';
        const errorMessageMarkdown = `${errorMessage} ${reasonCodeMarkdown}`;
        message = errorMessageMarkdown;
      } else {
        message = extensions?.paymentsServiceError;
      }
    }

    this.showErnie(message, `danger`, errorTimeout);
  };

  attemptToScrollToError = () => {
    const card = this.errors[0]?.card;
    if (window.innerWidth < 768 && card) {
      const options = { time: 300, align: { topOffset: -75 } };
      scrollIntoView(document.getElementById(card), options);
    }
  };

  attemptToOpenErrorModal = () => {
    const modalMapPath = this.errors[0]?.modalMapPath;
    if (modalMapPath) {
      const modalMap = errorModalMap({
        openDeliveryErrorModal: this.openDeliveryErrorModal,
        openOrderTypeModal: () => this.openOrderTypeModal(`viewingPausedOrdersModal`),
      });
      _.get(modalMap, modalMapPath, () => console.error(`unknown modal called`))();
    }
  };
  /* errors end */
}

export const contextStore = new Checkout();
const CheckoutContext = React.createContext();
export const { CheckoutProvider, useCheckout, withCheckout } = createContextHelpers(CheckoutContext, `checkout`);

const errorModalMap = ({ openDeliveryErrorModal, openOrderTypeModal }) => ({
  delivery: {
    address: () => openDeliveryErrorModal('ADDRESS'),
    customer: () => openDeliveryErrorModal('CUSTOMER'),
  },
  orders: {
    paused: openOrderTypeModal,
  },
});
