// NOTE: This is a shim for next.js router found in this issue:
// https:github.com/pbeshai/use-query-params/issues/13#issuecomment-607785336

import React, { memo, useMemo } from 'react';
import { useRouter } from 'next/router';
import { QueryParamProvider as ContextProvider } from 'use-query-params';

const QueryParamProvider = (props) => {
  const { children, ...rest } = props;
  const router = useRouter();
  const match = router.asPath.match(/[^?]+/);
  const pathname = match ? match[0] : router.asPath;

  const location = useMemo(
    () => ({
      search: router.asPath.replace(/[^?]+/u, ''),
    }),
    [router.asPath]
  );

  const history = useMemo(
    () => ({
      push: ({ search }) => {
        router.push(`${router.pathname}${search}`, { search, pathname }, { shallow: true });
      },
      replace: ({ search }) => {
        router.replace(`${router.pathname}${search}`, { search, pathname }, { shallow: true });
      },
    }),
    [pathname, router.pathname, location]
  );

  return (
    <ContextProvider {...rest} history={history} location={location}>
      {children}
    </ContextProvider>
  );
};

const MemoizedQueryParamProvider = memo(QueryParamProvider);

export default MemoizedQueryParamProvider;
