import { useEffect, useRef, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTheme } from 'styled-components';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useCart from 'hooks/use-cart';
import useUser from 'hooks/use-user';
import { loadCart } from 'hooks/use-cart-controller';
import { getStoredCartKey, normalizeAndUpdateStoredCart } from 'shared/actions/cart';

import { imageToUse } from 'shared/helpers/products';
import { getPersistedValue } from 'shared/utils/persisted-values';
import { LEGACY_AWS_SOURCE, IMGIX_SOURCE, LOCAL_STORAGE_STORED_CART_KEY } from 'shared/constants';

import useUI from 'hooks/use-ui';
import { useApolloClient } from '@apollo/react-hooks';
import { UPDATE_PUSH_NOTIFICATION_TOKEN } from './update-push-notification-token';
import { useGetChainLocations } from './use-get-chain-locations';
import { getIsUserInLegalMarket } from './use-mobile-native-bridge.utils';

import { UserLocation } from './use-mobile-native-bridge.types';

export function callNativeMethod(method: string, params?: any): any {
  // eslint-disable-next-line dot-notation
  return window['flutter_inappwebview'] ? window['flutter_inappwebview'].callHandler(method, params) : null;
}

function registerNativeHandler(name: string, callback: () => Promise<any>): void {
  window[`flutter_handler_${name}`] = callback;
}

function unregisterNativeHandler(name: string): void {
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete window[`flutter_handler_${name}`];
}

export function changeNativeTabToShop(): void {
  callNativeMethod(`onChangeTab`, { tab: `shop` });
}

export function changeNativeTabToCart(): void {
  callNativeMethod(`onChangeTab`, { tab: `cart` });
}

export function changeNativeTabToAccount(): void {
  callNativeMethod(`onChangeTab`, { tab: `account` });
}

function useNativeBridgeHandler(method: string, loading: boolean, value: any): void {
  const promiseRef = useRef<any>();
  const [lastLoading, setLastLoading] = useState<boolean>(loading);

  function newPromiseObject(): { promise: Promise<any>; resolve: (val: any) => void; reject: (err: any) => void } {
    const result: any = {};
    result.promise = new Promise((res, rej) => {
      result.resolve = res;
      result.reject = rej;
    });
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!promiseRef.current) {
    promiseRef.current = newPromiseObject();
  }
  useEffect(() => {
    // if we weren't loading before but now we are
    if (!lastLoading && loading) {
      promiseRef.current = newPromiseObject();
    } else if (!lastLoading && !loading) {
      // not loading, and loading didn't change, so value did
      promiseRef.current = newPromiseObject();
      promiseRef.current.resolve(value);
    } else if (lastLoading && !loading) {
      // we were loading but now we're not loading anymore
      promiseRef.current.resolve(value);
    }
    // loading changes, store new state last
    if (lastLoading !== loading) {
      setLastLoading(loading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, value]); // FIXME: ENG-32714 fix hooks dependency

  useEffect(() => {
    registerNativeHandler(method, async () => promiseRef.current.promise);

    return () => unregisterNativeHandler(method);
  }, [method]);
}

export function useMobileNativeBridge(): void {
  const [location, setLocation] = useState<UserLocation | null>(null);

  const { dispensary, loading: dispensaryLoading, error: dispensaryApolloError } = useDispensary();
  const { chainLocations, chainLocationsLoading, error: chainLocationsApolloError } = useGetChainLocations({
    chain: dispensary?.chain,
    isUserInLegalMarket: location?.isUserInLegalMarket,
    coordinates: { latitude: location?.latitude, longitude: location?.longitude },
  });
  const apolloClient = useApolloClient();
  const Cart = useCart();
  const User = useUser();
  const { isProductDetailPage, isCartPage, isCheckoutPage, setIsMobileEcommAppNotificationsEnabled } = useUI();
  const loadingCart = useRef<boolean>(false);

  async function updateDeviceToken(dispensaryId: string, token: string): Promise<void> {
    let result;
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_PUSH_NOTIFICATION_TOKEN,
        variables: { dispensaryId, token },
      });
      result = data;
    } catch (e) {
      console.log(`Error Updating Device Token`, e);
    }
    return result;
  }

  function setNotificationsEnabled(enabled: boolean): void {
    setIsMobileEcommAppNotificationsEnabled(enabled);
  }

  function setUserLocation(latitude: number, longitude: number, state: string): void {
    setLocation({ isUserInLegalMarket: getIsUserInLegalMarket(state), latitude, longitude, state });
  }

  function reloadCart(): void {
    loadingCart.current = true;
    const storedCarts = normalizeAndUpdateStoredCart(getPersistedValue(LOCAL_STORAGE_STORED_CART_KEY, {}));
    const currentStoredCartKey = getStoredCartKey(dispensary?.id);
    const currentStoredCart = storedCarts[currentStoredCartKey];
    Cart.clearOrder();

    if (currentStoredCart) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      loadCart({ storedDispensaryCart: currentStoredCart, Cart });
    }

    loadingCart.current = false;
  }

  const {
    mostRecentCartItem,
    mostRecentCartItemProduct,
    subtotal,
    mostRecentProduct,
    itemCount,
    cartLoading,
  } = useObserver(() => ({
    mostRecentCartItem: Cart.mostRecentCartItem,
    mostRecentCartItemProduct: Cart.mostRecentCartItem?.product,
    subtotal: Cart.subtotal,
    itemCount: Cart.itemCount,
    mostRecentProduct: Cart.mostRecentProduct,
    cartLoading: !Cart.isReady,
  }));

  const {
    mostRecentProductBrandName,
    mostRecentProductPrice,
    mostRecentProductName,
    mostRecentProductQuantity,
    mostRecentProductImage,
  } = useObserver(() => ({
    mostRecentProductBrandName: mostRecentCartItemProduct?.brand?.name,
    mostRecentProductPrice: mostRecentCartItem?.price,
    mostRecentProductName: mostRecentCartItemProduct?.name,
    mostRecentProductQuantity: mostRecentProduct?.quantity,
    mostRecentProductImage: mostRecentCartItemProduct
      ? imageToUse(mostRecentCartItemProduct)?.replace(LEGACY_AWS_SOURCE, IMGIX_SOURCE)
      : null,
  }));

  const { customized } = useTheme();
  const dispensaryInfo = useObserver(() => ({
    themeColor: customized.colors.buttonsLinks,
    name: dispensary?.name,
    chain: !!dispensary?.chain,
    error: dispensaryApolloError && JSON.stringify(dispensaryApolloError),
  }));
  const { isLoggedIn, userLoading } = useObserver(() => ({
    isLoggedIn: User.isLoggedIn,
    userLoading: User.loading,
  }));
  const chainLocationsInfo = {
    chainLocations,
    error: chainLocationsApolloError && JSON.stringify(chainLocationsApolloError),
  };

  useNativeBridgeHandler(`isLoggedIn`, userLoading, { isLoggedIn });
  useNativeBridgeHandler(`getDispensary`, dispensaryLoading, dispensary);
  useNativeBridgeHandler(`getDispensaryInfo`, dispensaryLoading, dispensaryInfo);
  useNativeBridgeHandler(`getCartQuantity`, cartLoading, { totalCartQuantity: parseInt(Cart.itemCount, 10) });
  useNativeBridgeHandler(`setUserLocation`, false, setUserLocation);
  useNativeBridgeHandler(`getIsUserInLegalMarket`, !location, { isUserInLegalMarket: location?.isUserInLegalMarket });
  useNativeBridgeHandler(`getChainLocations`, chainLocationsLoading, chainLocationsInfo);
  useNativeBridgeHandler(`reloadCart`, cartLoading, reloadCart);
  useNativeBridgeHandler(`clearCart`, cartLoading, Cart.clearOrder);
  useNativeBridgeHandler(`setNotificationsEnabled`, false, setNotificationsEnabled);
  useNativeBridgeHandler(`updateDeviceToken`, false, updateDeviceToken);

  useEffect(() => {
    // something was added to cart, or the most recent thingy was dismissed
    if (mostRecentCartItem && !isCartPage && !isCheckoutPage) {
      // something was added to cart
      if (isProductDetailPage) {
        callNativeMethod(`onAddToCart`, {
          quantityAdded: mostRecentProductQuantity,
          totalCartQuantity: itemCount,
        });
      } else {
        const cartTotalPrice: string = subtotal.toFixed(2).toString();
        const price: string = mostRecentProductPrice.toFixed(2).toString();
        callNativeMethod(`onQuickAddToCart`, {
          cartTotalPrice: `$${cartTotalPrice}`,
          company: mostRecentProductBrandName,
          price: `$${price}`,
          product: mostRecentProductName,
          quantity: mostRecentProductQuantity,
          image: mostRecentProductImage,
          totalCartQuantity: itemCount,
        });
      }
      Cart.clearMostRecentProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostRecentProductQuantity]); // FIXME: ENG-32714 fix hooks dependency

  useEffect(() => {
    if (!cartLoading && !loadingCart.current) {
      callNativeMethod(`onUpdateCart`, {
        totalCartQuantity: itemCount,
      });
    }
  }, [itemCount, cartLoading]);

  useEffect(() => {
    if (userLoading !== null && !userLoading) {
      callNativeMethod(`onAuthChange`, {
        isLoggedIn,
      });
    }
  }, [isLoggedIn, userLoading]);

  useEffect(() => {
    if (!dispensaryLoading) {
      callNativeMethod(`onDispensaryUpdate`, dispensaryInfo);
    }
  }, [dispensaryInfo, dispensaryLoading]);

  useEffect(() => {
    const forwardErrorEventToNative = (event): void => {
      const { error } = event;
      const serializedError = JSON.stringify(error, Object.getOwnPropertyNames(error));

      callNativeMethod(`onError`, { error: serializedError });
    };

    window.addEventListener('error', forwardErrorEventToNative);

    return () => window.removeEventListener('error', forwardErrorEventToNative);
  }, []);
}
