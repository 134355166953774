import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

import useDispensary from 'src/dispensary/hooks/use-dispensary';

import useCart from 'hooks/use-cart';
import filteredSpecials from 'shared/graphql/special/queries/filtered-specials.gql';
import filteredSpecialsV2 from 'shared/graphql/special/queries/filtered-specials-v2.gql';

import {
  parseDates,
  specialIsActive,
  specialMatchesMenuType,
  specialHasRequiredProducts,
} from 'shared/helpers/specials';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useObserver } from 'mobx-react';
import { useStores } from 'contexts/stores';

export default function useDispensarySpecials(props) {
  const dispensaryHookData = useDispensary();
  // These are used to account for the various places where the hook could be called from
  const { UI } = useStores();
  const uiDispensary = useObserver(() => UI?.dispensary);

  const cart = useCart();
  const cartDispensary = useObserver(() => cart.dispensary);

  const dispensary = dispensaryHookData?.dispensary || uiDispensary || cartDispensary;
  const menuType = useObserver(() => cart.menuType);
  const enableLLxSaleDiscountSync = dispensary?.storeSettings?.enableLLxSaleDiscountSync;
  const flags = useFlags();

  const { filteredProducts, specialId, showPaymentRestricted } = props || {};

  const hideProductlessSpecials = flags['eng-38623.hide-product-less-specials'];

  const variables = {
    specialsFilter: {
      dispensaryId: dispensary?.id,
      ...(specialId ? { specialIds: [specialId], current: true, past: true } : { current: true }),
    },
  };

  const useV2Query = flags['fintech.cats.use-filteredSpecialsV2-outside-admin.rollout'];

  // eslint-disable-next-line no-use-before-define
  const { data, ...apolloValues } = useQuery(useV2Query ? filteredSpecialsV2 : filteredSpecials, {
    skip: !dispensary,
    variables,
  });

  const filteredSpecialsData = data?.filteredSpecialsV2?.specials || data?.filteredSpecials?.specials || [];
  const parsedFilteredSpecials = _.map(filteredSpecialsData, parseDates);
  const [currentSpecials] = _.reduce(
    parsedFilteredSpecials,
    (accumulator, special) => {
      const llxEnabled =
        special.source === 'POS' &&
        ((special.specialType === 'sale' && enableLLxSaleDiscountSync) || special.specialType === 'bogo');

      if (special.source !== 'POS' || llxEnabled) {
        accumulator[
          // passing in specialId indicates we should show whatever special data exists, even if the special is not active
          (specialIsActive(special, dispensary?.timezone) || specialId) &&
          specialMatchesMenuType(special, menuType) &&
          (!hideProductlessSpecials || specialHasRequiredProducts(special, filteredProducts)) &&
          (!special.paymentRestrictions?.payByBankSignupIncentive || showPaymentRestricted)
            ? 0
            : 1
        ].push(special);
      }
      return accumulator;
    },
    [[], []]
  );

  const specials = [..._.sortBy(currentSpecials, ({ startStamp }) => startStamp)];
  const hasSpecials = specials?.length > 0;
  return { specials, hasSpecials, ...apolloValues };
}
