import { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';

import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';

export default function usePersistedValue(object, key, storageKey = key, sessionOnly = true) {
  const value = useObserver(() => object[key]);

  // load from storage after ssr runs
  useEffect(() => {
    object[key] = getPersistedValue(storageKey, value);
  }, []);

  // thereafter, store the value continuously
  useEffect(() => {
    setPersistedValue(storageKey, object[key], sessionOnly);
  }, [value]);

  return value;
}
