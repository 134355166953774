import OrderComputationsAsync from 'shared/order/computations-async';
import { datadogLogs } from '@datadog/browser-logs';

async function performCalculations({ cart }) {
  const calculationsInput = {
    ...cart.order,
    discountSyncV2Enabled: cart.dispensary?.storeSettings?.enableLLxSaleDiscountSync,
  };

  try {
    const calculationOutput = await OrderComputationsAsync.compute(calculationsInput);
    cart.setCalculationOutput(calculationOutput);
  } catch (error) {
    datadogLogs.logger.error('Error in performCalculationsAction', error);
    cart.setCalculationOutput({});
  } finally {
    cart.setIsCalculating(false);
  }
}

export default {
  performCalculations,
};
