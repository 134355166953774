import { useGetAddressBasedDispensaryDataQuery, GqlAddressBasedDispensaryDataResult } from 'types/graphql';

import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';

function useFilters(
  cNameOrId: string | null
):
  | {
      dispensaryId: string;
      city?: string;
      state?: string;
      zipcode?: string;
      lat?: number;
      lng?: number;
    }
  | undefined {
  const Cart = useCart();
  const { isKiosk } = useUI();

  if (Cart?.currentAddress && !isKiosk && cNameOrId) {
    return {
      dispensaryId: cNameOrId,
      city: Cart?.currentAddress?.city,
      state: Cart?.currentAddress?.state,
      zipcode: Cart?.currentAddress?.zipcode,
      lat: Cart?.currentAddress?.lat,
      lng: Cart?.currentAddress?.lng,
    };
  }

  return undefined;
}

export default function useDispensaryDeliveryInfo(
  cNameOrId: string | null
): GqlAddressBasedDispensaryDataResult | null | undefined {
  const filters = useFilters(cNameOrId);
  const result = useGetAddressBasedDispensaryDataQuery({
    context: { cell: true },
    variables: { input: filters },
    skip: !cNameOrId || !filters,
  });

  return result.data?.getAddressBasedDispensaryData;
}
