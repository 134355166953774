import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import { mediaQueries } from 'src/theme';

export default function DiscountToCartSection(props) {
  const { t } = useTranslation();
  const { displayTopBorder, specialName, isMobile } = props;

  return (
    <DiscountToCartSectionWrapper isMobile={isMobile} displayTopBorder={displayTopBorder}>
      {displayTopBorder && <StyledLine />}
      <ContentWrapper>
        <SpecialName>{specialName}</SpecialName>
        &nbsp;
        <AppliedBadge>
          <CheckImage alt='Checkmark Icon' src='/icons/green-checkmark.svg' />
          {t('common.discountToCartApplied', 'Applied')}
        </AppliedBadge>
      </ContentWrapper>
    </DiscountToCartSectionWrapper>
  );
}

const StyledLine = styled.hr`
  width: 90%;
  height: 0.669725px;
  background-color: ${({ theme }) => theme.colors.blueGrey[80]};
  border-style: none;
  margin-block-start: unset;
  margin-block-end: unset;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isMobile }) => (isMobile ? `8px 25px` : `10px 30px`)};
`;

const DiscountToCartSectionWrapper = styled.div`
  background: ${({ theme }) => theme.colors.darkBackground};
  font-size: ${({ isMobile }) => (isMobile ? `12px` : `14px`)};
  line-height: 175%;
`;

const CheckImage = styled.img`
  width: 11px;
  height: 9px;
  margin-right: 4px;
`;

const AppliedBadge = styled.span`
  font-weight: bold;
  font-size: 13px;
  line-height: 165%;
  color: ${({ theme }) => theme.colors.primaryGreen};
`;

const SpecialName = styled.span`
  font-weight: bold;
  max-width: 400px;

  @media ${mediaQueries.phone} {
    max-width: 250px;
  }
`;
