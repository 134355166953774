import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import useTranslation from 'hooks/use-translation';

import { Clickable, SmallLoader } from 'shared/components';
import { CheckboxInput, Button } from 'components/core';
import { ElevatedSection } from 'components/core/bottom-drawer';

import { BrandsFilterOptionsProps } from './brands-filter-drawer.types';
import { BrandsFilterEmptyState } from './brands-filter-empty-state';

export const BrandsFilterOptions = observer(
  ({
    loading,
    filteredOptions,
    handleClear,
    handleSave,
    handleToggle,
    handleIsSelected,
    handleClearInputValue,
  }: BrandsFilterOptionsProps): JSX.Element => {
    const { t } = useTranslation();

    if (loading) {
      return <Loader />;
    }

    if (filteredOptions.length) {
      return (
        <>
          <div>
            <ClearAllButton onClick={handleClear}>{t('common.clear-all', 'Clear All')}</ClearAllButton>
            <Options>
              {filteredOptions.map((option) => (
                <CheckboxInput
                  key={option.key}
                  checked={handleIsSelected(option)}
                  option={option}
                  onClick={handleToggle}
                />
              ))}
            </Options>
          </div>

          <ElevatedSection borderPosition='top' pt='20px' mt='auto'>
            <Button onClick={handleSave} height='48px' fluid>
              {t('common.save', 'SAVE')}
            </Button>
          </ElevatedSection>
        </>
      );
    }

    return <BrandsFilterEmptyState handleClearSearch={handleClearInputValue} />;
  }
);

BrandsFilterOptions.displayName = `BrandsFilterOptions`;

const ClearAllButton = styled(Clickable)`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  margin: 10px 0;
  padding: 5px 0;
`;

const Options = styled.div`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Loader = styled(SmallLoader)`
  margin: auto;
`;
