import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { PaySignUpButton } from 'src/payments/components';

import {
  CheckoutLabel,
  CheckoutLabelGrouping,
  CheckoutBody,
  CheckoutSectionHeading,
} from 'checkout/components/styles/common';
import useStores from 'shared/hooks/use-stores';
import { PayButtonPosition } from '../pay-sign-up-button/pay-sign-up-button';

export type ExpressCheckoutProps = {
  textPosition?: PayButtonPosition;
};

export function ExpressCheckout({ textPosition = 'bottom' }: ExpressCheckoutProps): JSX.Element {
  const { t } = useTranslation();
  const { Cart, UI } = useStores();

  return (
    <>
      <CheckoutLabelGrouping id='express-checkout' marginBottom='29px'>
        <CheckoutLabel>
          <CheckoutSectionHeading>{t('payment.express-checkout', 'Express Checkout')}</CheckoutSectionHeading>
        </CheckoutLabel>
        <CheckoutBody>
          <PaySignUpButtonContainer>
            <PaySignUpButton
              textPosition={textPosition}
              cname={Cart.dispensary.cName}
              enrollmentSource={`checkout-page-${String(UI?.variant)}`}
              location='customer-section'
            />
          </PaySignUpButtonContainer>
        </CheckoutBody>
      </CheckoutLabelGrouping>
    </>
  );
}

const PaySignUpButtonContainer = styled.div`
  max-width: 390px;
`;
