import React from 'react';

function SvgMenuIcon(props) {
  return (
    <svg height={17} width={24} {...props}>
      <rect height={3} rx={1.5} width={24} />
      <rect height={3} rx={1.5} width={18} y={7} />
      <rect height={3} rx={1.5} width={24} y={14} />
    </svg>
  );
}

export default SvgMenuIcon;
