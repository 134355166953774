import React, { useState } from 'react';

import useUI from 'hooks/use-ui';
import useCart from 'hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useErnie from 'shared/hooks/use-ernie';
import useTranslation from 'hooks/use-translation';
import { useCheckAllAddressFlags } from 'shared/actions/address';

import DeliveryAddressForm from './delivery-address-form';

const DeliveryAddressFormWrapper = ({ onClose }) => {
  const [isGoodAddress, setIsGoodAddress] = useState({ verified: false });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const UI = useUI();
  const Cart = useCart();
  const { dispensary } = useDispensary();
  const showErnie = useErnie();
  const checkAllAddressFlags = useCheckAllAddressFlags();

  // TODO: There is quite a bit of v2 logic that will need to be included for consumer v3
  const handleOnSubmit = async (location, { apt = '' } = {}) => {
    if (!location || !location.lng || !location.lat) {
      return;
    }
    setLoading(true);
    Cart.tempAddress = location.address;
    // shaping mapbox into gmap like object for helper funcs/actions to work
    UI.tempLocation = {
      ...location,
      apt,
      ln2: apt,
      geometry: {
        coordinates: [location.lng, location.lat],
      },
    };

    const isValidAddress = await checkAllAddressFlags({ location, alert: true }, dispensary);
    const showSuccessMessage = isValidAddress.success;

    let verified = false;
    if (isValidAddress.checkingResidentialStatus) {
      verified = await UI.showResidentialStatusModal();
      if (!verified) {
        setLoading(false);
        return;
      }
    }

    if (!isValidAddress.checkingResidentialStatus && !isValidAddress.success) {
      setLoading(false);
      return;
    }

    if (verified || isValidAddress.success) {
      setIsGoodAddress({ verified: true });
      await Cart.updateAddress({
        location: UI.tempLocation,
        residentialStatus: 'VERIFIED',
      });
      if (showSuccessMessage) {
        showErnie(
          t(
            'residentialVerificationModal.success',
            'Success! The address you entered is within the delivery area for this dispensary!'
          ),
          `success`
        );
      }
      setLoading(false);
      onClose(isGoodAddress);
    }
  };

  return <DeliveryAddressForm handleOnSubmit={handleOnSubmit} loading={loading} />;
};

export default DeliveryAddressFormWrapper;
