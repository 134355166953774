import styled from 'styled-components';
import { fontSize, width } from 'styled-system';

import { Button as _Button, TextButton as _TextButton } from 'components/core';

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up(`md`)} {
    max-width: 600px;
    margin: 0 auto;
  }
`;

export const DialogBanner = styled.span`
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;

  ${({ theme }) => theme.breakpoints.up(`sm`)} {
    font-size: 14px;
    margin-bottom: 48px;
  }
`;

export const DialogText = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-bottom: 25px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up(`sm`)} {
    margin-bottom: 30px;
  }
`;

export const DialogImage = styled.img`
  margin-bottom: 30px;
  line-height: 0;
  max-width: 100%;
  height: auto;

  ${({ theme }) => theme.breakpoints.up(`sm`)} {
    margin-bottom: 44px;
  }
`;

export const DialogIcon = styled.div`
  margin-bottom: 30px;
  line-height: 0;
`;

export const DialogHeading = styled.h3`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.primaryBlack};

  ${({ theme }) => theme.breakpoints.up(`sm`)} {
    font-size: 20px;
  }

  ${fontSize}
`;

export const Button = styled(_Button)`
  font-size: 13px;
  line-height: 1;
  margin-bottom: 20px;
  ${width}

  ${({ theme }) => theme.breakpoints.up(`sm`)} {
    margin-bottom: 27px;
    width: auto;
  }
`;

Button.defaultProps = {
  width: '100%',
};

export const TextButton = styled(_TextButton)`
  text-transform: capitalize;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    font-weight: 700;
  }
`;
