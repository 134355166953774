import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';

import { useModals, ModalNames } from 'components/modals';
import { ModalContainer, ModalExitIcon, ModalFooter } from 'components/core';
import { ModalContent, HeaderText, ModalFooterText, SignUpText } from 'components/modals/components';
import ResetPasswordForm from 'components/forms/reset-password-form';

export const ResetPasswordModal = forwardRef(({ containerProps, onClose }, ref) => {
  const [showEmbeddedError, setShowEmbeddedError] = useState(false);
  const { openModal } = useModals();
  const { t, Trans } = useTranslation();
  const { isMobileEcommApp } = useUI();

  const handleOpenSignUpModal = () => {
    onClose();
    openModal(ModalNames.signUp);
  };

  const handleShowEmbeddedError = () => setShowEmbeddedError(true);

  return (
    <ModalContainer ref={ref} width={420} padding={0} {...containerProps}>
      <ModalExitIcon onClick={onClose} />
      <ModalContent pb={isMobileEcommApp ? 30 : 92} pt={22}>
        <HeaderText>{t('forgotPasswordModal.modalHeader', 'Reset your password')}</HeaderText>

        {showEmbeddedError ? (
          <ErrorInstructionsContainer>
            <ErrorInstructionsText>
              <Trans i18nKey='resetPasswordModal.errorMessage'>
                We couldn't find an account associated with that email. If your account was created on a previous
                ordering system, you'll need to create a new one. It only takes a few seconds.
              </Trans>
            </ErrorInstructionsText>
          </ErrorInstructionsContainer>
        ) : (
          <ResetInstructions>
            <Trans i18nKey='resetPasswordModal.instructions'>
              Enter the email address associated with your account and we'll email you a link to reset your password.
            </Trans>
          </ResetInstructions>
        )}

        <ResetPasswordForm onClose={onClose} onShowEmbeddedError={handleShowEmbeddedError} />
      </ModalContent>

      {!isMobileEcommApp && (
        <ModalFooter>
          <ModalFooterText>{t('resetPasswordModal.footerText', 'New to Dutchie?')}</ModalFooterText>
          <SignUpText onClick={handleOpenSignUpModal}>{t('resetPasswordModal.footerSignUp', 'Sign Up')}</SignUpText>
        </ModalFooter>
      )}
    </ModalContainer>
  );
});

const ResetInstructions = styled.p`
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 13px;
  text-align: left;
  margin: 10px 2px 25px 2px;
  line-height: 157%;
`;

const ErrorInstructionsContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 21px;
  width: 339px;
  height: 98px;
  padding: 15px;

  background: ${({ theme }) => theme.colors.lightRedBackground};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.pinkBorder};
  border-radius: 6px;
`;

const ErrorInstructionsText = styled.p`
  font-size: 13px;
  line-height: 134%;
  color: ${({ theme }) => theme.colors.orange[35]};
  text-align: initial;
`;
