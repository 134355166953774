import React from 'react';
import styled from 'styled-components';
import { callNativeMethod } from 'src/mobile-ecomm/hooks/use-mobile-native-bridge';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

type MobileEcommTermsLinkProps = {
  text: string;
  forAccountPage?: boolean;
};

export function MobileEcommTermsLink({ text, forAccountPage }: MobileEcommTermsLinkProps): JSX.Element {
  const flags = useFlags();
  const { dispensary } = useDispensary();
  const termsOfServiceLink = flags['mobile-ecomm-tos-links']?.dispensaryTosLinks[dispensary?.id];

  return (
    <TermsLink
      forAccountPage={forAccountPage}
      type='button'
      onClick={() => callNativeMethod(`onLaunchUrl`, { url: termsOfServiceLink })}
    >
      {text}
    </TermsLink>
  );
}

const TermsLink = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  color: ${({ theme, forAccountPage }) =>
    forAccountPage ? theme.colors.grey[30] : theme.customized.colors.buttonsLinks};
  cursor: pointer;
  padding: 0px;

  ${({ forAccountPage }) =>
    forAccountPage &&
    `font-weight: 600;
    text-decoration: underline;
    `}
`;
