import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import StoreStatus from 'src/dispensary/core-menu/components/core-menu-header/store-status';
import MenuTypeSelector from 'components/menu-type-selector';

function StatusMenuSelect({ dispensary, ...otherProps }) {
  return (
    <Container {...otherProps}>
      <StoreStatus dispensary={dispensary} inline />
      <MenuTypeSelector dispensary={dispensary} />
    </Container>
  );
}

export default StatusMenuSelect;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 43px;
  padding-left: 21px;
  padding-right: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${space}
`;
