import { useEffect, useRef } from 'react';
import type { RefObject } from 'react';
import _ from 'lodash';

import { Checkout as CheckoutType, useCheckout } from 'checkout/context';
import type CartType from 'state/cart';

import useCart from 'shared/hooks/use-cart';

import { monerisSuccessCode } from './constants';

type MonerisResponseFailure = {
  errorMessage: string;
  responseCode: number[];
};
type MonerisResponseSuccess = {
  responseCode: '001';
  dataKey: string;
};
type MonerisResponse = MonerisResponseFailure | MonerisResponseSuccess;

export class MonerisController {
  iframe: HTMLIFrameElement | null = null;
  checkout: CheckoutType;
  cart: CartType;
  promiseQueue: ((value: MonerisResponse) => void)[] = [];

  constructor(checkout: CheckoutType, cart: CartType) {
    this.checkout = checkout;
    this.cart = cart;
  }

  tokenize = async (baseUrl: string): Promise<MonerisResponse> =>
    new Promise<MonerisResponse>((resolve, reject) => {
      if (!this.iframe) {
        reject(new Error(`No iframe reference found when calling tokenize`));
        return;
      }
      this.promiseQueue.push(resolve);
      this.iframe.contentWindow?.postMessage('tokenize', baseUrl);
    });

  handleMessage = (message): void => {
    if (!_.endsWith(_.trim(message.origin), '.moneris.com')) {
      return;
    }
    try {
      const responseData: MonerisResponse | undefined = JSON.parse(message.data);

      if (responseData?.responseCode === monerisSuccessCode) {
        this.checkout.removeError({ path: `payment.monerisPAN` });
        this.cart.monerisInput.cardToken = responseData.dataKey;
      } else {
        if (this.cart.paymentMethod === 'payOnlineMoneris') {
          this.checkout.addErrors([{ card: `payment` }, { path: `payment.monerisPAN` }]);
        }
        this.cart.monerisInput.cardToken = null;
      }

      if (responseData && this.promiseQueue.length) {
        const responseHandler = this.promiseQueue.pop();
        if (responseHandler) {
          responseHandler(responseData);
        }
      }
    } catch (e) {
      // more than likely a message that we don't handle correctly
      console.error(`Error handling moneris message:`, e, message);
    }
  };

  connect(): void {
    window.addEventListener('message', this.handleMessage, false);
  }
  disconnect(): void {
    window.removeEventListener('message', this.handleMessage, false);
    this.iframe = null;
  }
  setIframe(iframe: HTMLIFrameElement): void {
    if (this.iframe === iframe) {
      return;
    }
    this.iframe = iframe;
  }
}

export function useMonerisController(iframeRef: RefObject<HTMLIFrameElement | null>): MonerisController {
  const Cart = useCart();
  const Checkout = useCheckout();
  const monerisControllerRef = useRef<MonerisController | undefined>();

  if (!monerisControllerRef.current) {
    monerisControllerRef.current = new MonerisController(Checkout, Cart);
  }
  const monerisController: MonerisController = monerisControllerRef.current;

  useEffect(() => {
    monerisController.connect();

    return () => monerisController.disconnect();
  }, [monerisController]);

  const iframe = iframeRef.current;

  useEffect(() => {
    if (!iframe) {
      return;
    }
    monerisController.setIframe(iframe);
  }, [iframe, monerisController]);

  return monerisControllerRef.current;
}
