import React from 'react';
import Head from 'next/head';
import _ from 'lodash';

export default function Meta(props) {
  const { property, content } = props;
  const [prefix, ...nameParts] = _.split(property, ':');
  const name = nameParts.length > 0 ? nameParts.join(':') : prefix;
  return (
    <Head>
      <meta key={property} name={name} property={property} content={content} />
    </Head>
  );
}
