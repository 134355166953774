import { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useObserver } from 'mobx-react-lite';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'src/hooks/use-user';
import useErnie from 'shared/hooks/use-ernie';
import useCart from 'src/hooks/use-cart';
import { formatBirthdayForRewards } from 'shared/helpers/rewards';
import { ERNIE_TYPES } from 'shared/constants';
import createAlpineUser from 'shared/graphql/reward/mutations/create-alpine-user.gql';
import {
  getDefaultRewardsProgramDisplayName,
  getDefaultRewardsProgramDisplayDescription,
} from 'shared/core/helpers/dispensaries';
import getRewardsV2 from 'shared/graphql/reward/queries/get-rewards-V2.gql';
import { GqlGetRewardsV2Query } from 'types/graphql';

export enum Statuses {
  none = 'none',
  loading = 'loading',
  signup = 'signup',
  terms = 'terms',
  pin = 'pin',
  incorrectPin = 'incorrectPin',
  success = 'success',
}

type UseLoyaltyReturn = {
  createUserAndPin: () => Promise<void>;
  status: Statuses;
  setStatus: (status: Statuses) => void;
  programName: string;
  programDescription: string;
  processPin: (pin: string) => Promise<void>;
  resendPin: () => Promise<void>;
};

type UseLoyaltyProps = {
  initialStatus: Statuses;
};

export const useLoyalty = ({ initialStatus }: UseLoyaltyProps): UseLoyaltyReturn => {
  const [status, setStatus] = useState(initialStatus);
  const { dispensary } = useDispensary();
  const Cart = useCart();
  const User = useUser();
  const phone = useObserver(() => User.phone);
  const birthday = useObserver(() => User.birthday);
  const email = useObserver(() => User.email);
  const firstName = useObserver(() => User.firstName);
  const lastName = useObserver(() => User.lastName);
  const dob = formatBirthdayForRewards(birthday);
  const apolloClient = useApolloClient();
  const showErnie = useErnie();
  const programName =
    dispensary?.storeSettings?.rewardsIntegrationConfiguration?.rewardsProgramDisplayName ??
    getDefaultRewardsProgramDisplayName(dispensary);
  const programDescription =
    dispensary?.storeSettings?.rewardsIntegrationConfiguration?.rewardsProgramDisplayDescription ||
    getDefaultRewardsProgramDisplayDescription(dispensary);

  const generatePin = async (): Promise<GqlGetRewardsV2Query | undefined> => {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: getRewardsV2,
      variables: {
        dispensaryId: dispensary.id,
        phoneNumber: phone,
        dob,
        pin: '',
      },
    });

    return data;
  };

  const resendPin = async (): Promise<void> => {
    setStatus(Statuses.loading);

    const data = await generatePin();

    if (!data) {
      showErnie('Error connecting account, please try again.', ERNIE_TYPES.DANGER);
    }

    setStatus(Statuses.pin);
  };

  const createUserAndPin = async (): Promise<void> => {
    setStatus(Statuses.loading);

    try {
      const { data: createdUserData } = await apolloClient.mutate({
        mutation: createAlpineUser,
        variables: {
          dispensaryId: dispensary.id,
          phoneNumber: phone,
          firstName,
          lastName,
          email,
          favoriteStore: dispensary.name,
          program: 'alpineiq',
          acceptedTerms: true,
          address: '',
        },
      });

      if (!createdUserData) {
        showErnie('Error connecting account, please try again.', ERNIE_TYPES.DANGER);
        setStatus(Statuses.terms);
        return;
      }
    } catch (error) {
      console.error(error);

      showErnie('Error connecting account, please try again.', ERNIE_TYPES.DANGER);
      setStatus(Statuses.terms);
      return;
    }

    const data = await generatePin();

    if (!data) {
      showErnie('Error connecting account, please try again.', ERNIE_TYPES.DANGER);
      setStatus(Statuses.terms);
    } else {
      setStatus(Statuses.pin);
    }
  };

  const processPin = async (pin: string): Promise<void> => {
    setStatus(Statuses.loading);

    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: getRewardsV2,
      variables: {
        dispensaryId: dispensary.id,
        phoneNumber: phone,
        dob,
        pin,
      },
    });

    if (!data) {
      showErnie('Error connecting account, please try again.', ERNIE_TYPES.DANGER);
      setStatus(Statuses.pin);
    }

    const rewardsData = data?.getRewardsV2;

    if (rewardsData.auth.pinConfirmed) {
      User.setLoyaltyPin(dispensary.id, pin);
      User.hasLoyaltyAccount = true;

      Cart.availableRewards = rewardsData.rewards.map((reward) => ({
        ...reward,
        brand: rewardsData.rewardBrand,
      }));
      Cart.hasRewardsWallet = rewardsData.userHasWallet;
      Cart.rewardsBalance = rewardsData.balance;
      Cart.rewardBrandName = rewardsData.rewardBrand;
      Cart.rewardAuth = rewardsData.auth;
      Cart.hasCheckedForRewards = true;

      setStatus(Statuses.success);
    } else if (rewardsData.auth.incorrectPinProvided) {
      setStatus(Statuses.incorrectPin);
    }
  };

  return {
    createUserAndPin,
    status,
    setStatus,
    programName,
    programDescription,
    processPin,
    resendPin,
  };
};
