import React from 'react';

import Wrapper from 'components/wrapper';
import MetaTags from 'components/meta-tags';
import LayoutWithHeader from 'components/layouts/with-header';
import { CoreMenuLayout } from 'components/layouts/core-menu-layout';
import useUI from 'hooks/use-ui';

import { WhoopsProps } from './whoops.types';
import { WhoopsContent } from './content';

type EcommLayout = typeof CoreMenuLayout | typeof LayoutWithHeader;

export function Whoops({ reason, children }: WhoopsProps): JSX.Element {
  const { isDutchieMain } = useUI();

  const Layout: EcommLayout = isDutchieMain ? LayoutWithHeader : CoreMenuLayout;

  return (
    <Wrapper>
      <MetaTags customRobotsRule='noindex' variant='whoops' />
      <Layout>
        <WhoopsContent reason={reason}>{children}</WhoopsContent>
      </Layout>
    </Wrapper>
  );
}
