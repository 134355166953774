/* eslint-disable @typescript-eslint/naming-convention */
import { Heading } from './components/heading';
import { Title } from './components/title';
import { Body } from './components/body';
import { Label } from './components/label';

export { Fonts } from './types';

export const Typography = {
  Body,
  Heading,
  Label,
  Title,
};
