import snakeCase from 'lodash/snakeCase';

/**
 * Converts all keys in an object to snake_case. Useful for analytics clients that use snakeCase (like GA).
 *
 * e.g. {fruitName: 'apple', fruitDetails: { retailLocations: [{ shopName: 'Fruiterie', ownerName: 'Sam' }] }}
 *  -> {fruit_name: 'apple', fruit_details: { retail_locations: [{ shop_name: 'Fruiterie', owner_name: 'Sam' }] }}
 *
 * @recursive
 */

type ConversionReturn = Array<unknown> | Record<string, unknown> | unknown;

export const convertKeysToSnakeCase = (obj: Array<unknown> | Record<string, unknown> | unknown): ConversionReturn => {
  if (obj instanceof Array) {
    return obj.map((item) => convertKeysToSnakeCase(item));
  }

  if (
    typeof obj === 'object' &&
    // because `typeof null` is 'object'
    obj !== null
  ) {
    const convertedEntries = Object.entries(obj).map(([k, v]) => [snakeCase(k), convertKeysToSnakeCase(v)]);
    return Object.fromEntries(convertedEntries);
  }

  return obj;
};
