/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import styled, { css } from 'styled-components';

import useStores from 'shared/hooks/use-stores';
import { AccordionBanner } from 'src/payments/components/accordion-banner/accordion-banner';
import FiveDollarDiscount from 'src/assets/five-dollar-discount';
import BoltIcon from 'src/assets/bolt';
import { mediaQueries } from 'shared/styles';
import { CheckoutLabelGrouping } from 'checkout/components/styles/common';
import { DUTCHIE_PAY, PBB_INCENTIVES_TERMS_URL } from 'src/payments/constants';
import { useDutchiePay } from 'src/payments/hooks/use-dutchie-pay/use-dutchie-pay';
import { useOpenDutchiePay } from 'src/payments/hooks/use-open-dutchie-pay/use-open-dutchie-pay';
import { useIncentives, UserType } from 'src/payments/hooks/use-incentives/use-incentives';

export const FiveDollarDiscountBanner = ({ marginBottom }): JSX.Element => (
  <AccordionBanner
    marginBottom={marginBottom}
    borderRadius='4px 4px 0 0'
    graphic={<FiveDollarDiscount />}
    mainContent={
      <span>
        Get $5 off your order the first time you use <strong>Pay by Bank</strong>
      </span>
    }
    toggleContent={
      <span>
        Select “Pay by Bank” as your Payment option, link your bank & checkout. Only valid the first time you use Pay by
        Bank to pay for your order.{' '}
        <a href={PBB_INCENTIVES_TERMS_URL} target='_blank' rel='noreferrer'>
          Terms apply
        </a>
      </span>
    }
  />
);

export const FiveDollarDiscountBannerWithCTA = (): JSX.Element => {
  const { Cart, UI } = useStores();
  const { userType } = useIncentives();
  const { bankAccounts } = useDutchiePay();
  const paymentMethodId = bankAccounts.length > 0 ? bankAccounts[0].id : undefined;

  const { openDutchiePay } = useOpenDutchiePay({
    cname: Cart.dispensary.cName ?? '',
    enrollmentSource: `checkout-page-${UI?.variant}`,
  });

  function setPaymentToPBB(): void {
    Cart.setPaymentMethodId(paymentMethodId);
    Cart.setPaymentMethod(DUTCHIE_PAY);
    if (UI.variant === 'embedded') {
      UI.embeddedEventDispatcher(`iframe:scrollToTop`, { force: true });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  let text;
  if (userType === UserType.UNENROLLED) {
    text = {
      main: 'Save $5 when you use Pay by Bank to checkout.',
      cta: 'Link your bank now.',
    };
  } else if (userType === UserType.QUALIFIED) {
    text = {
      main: 'Save $5 when you use Pay by Bank to checkout.',
      cta: 'Update your payment now.',
    };
  } else {
    text = {
      main: 'Ditch the cash, pay by bank instantly.',
      cta: 'Update your payment now.',
    };
  }

  return (
    <CheckoutLabelGrouping mb='20px'>
      <Container>
        <BoltIcon width='50px' />
        <span>
          {text.main}{' '}
          <TextButton onClick={userType === UserType.UNENROLLED ? openDutchiePay : setPaymentToPBB}>
            {text.cta}
          </TextButton>
        </span>
      </Container>
    </CheckoutLabelGrouping>
  );
};

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    background-color: ${colors.green20};
    border-radius: 4px;
    display: flex;
    height: 65px;
    justify-content: flex-start;
    width: 390px;
    color: ${colors.green90};
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    width: 340px;
    margin-top: 20px;

    span {
      max-width: 250px;
    }

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media ${mediaQueries.largePhone} {
    width: 100%;

    span {
      max-width: 300px;
    }
  }
`}
`;

const TextButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
`;
