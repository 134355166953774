import React from 'react';

import { Product } from 'utils/helpers/product';

import { Container, ProductRowContainer, Scrollable } from './product-table.components';
import { TableHeaderRow } from './table-header-row';
import { TableProductRow } from './table-product-row';
import { useTableColumns } from './use-table-columns';

type ProductTableProps = {
  options: Product[];
  selectedWeight: string | null;
};

export function ProductTable({ options, selectedWeight }: ProductTableProps): JSX.Element {
  const { columnNames } = useTableColumns();

  return (
    <Container>
      <TableHeaderRow columns={columnNames} />
      <Scrollable>
        <ProductRowContainer>
          {options.map((product) => (
            <TableProductRow
              dispensary={product.dispensary}
              product={product}
              weight={selectedWeight}
              key={product.id}
            />
          ))}
        </ProductRowContainer>
      </Scrollable>
    </Container>
  );
}
