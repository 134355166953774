import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import { useConsentManagement } from 'src/utils/fides/hooks/use-consent-management';

import { ARIA_ROLES } from 'shared/constants';
import { EmbeddedExternalLink, Link } from 'components/core';
import CcpaIcon from 'src/assets/ccpa-icon';

export const DutchiePrivacyFooter = observer(() => {
  const { t } = useTranslation();
  const { isEmbedded } = useUI();
  const { isConsentManagementEnabled } = useConsentManagement();
  const LinkWrapper = isEmbedded ? EmbeddedExternalLink : Link;

  return (
    <Container role={ARIA_ROLES.CONTENT_INFO} className='privacy-policy-link'>
      <FooterItem data-testid='privacy-policy'>
        <LinkWrapper href='https://dutchie.com/privacy'>
          <Text>{t('footer.privacy', 'Privacy Policy')}</Text>
        </LinkWrapper>
      </FooterItem>
      {isConsentManagementEnabled && (
        <FooterItem data-testid='privacy-choices' id='fides-modal-link'>
          <CcpaIcon />
          <Text>{t('footer.privacy-choices', 'Your Privacy Choices')}</Text>
        </FooterItem>
      )}
    </Container>
  );
});

const Container = styled.div`
  width: 100vw;
  height: 30px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.blueGrey[80]}`};
  box-shadow: 0px -2.62px 2px rgba(0, 0, 0, 0.042);
  background-color: white;
  z-index: 10;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    position: relative;
    margin-left: 5px;
    padding-left: 10px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 0px;
      width: 1px;
      height: 16px;
      background-color: #d2d7da;
    }
  }

  svg {
    margin-right: 8px;
  }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.grey[35]};
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  margin-right: 5px;
  text-decoration: underline;

  :hover {
    color: #121516;
  }
`;
