import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

const TextInput = forwardRef((props, ref) => {
  const {
    value,
    name,
    defaultValue,
    error,
    onBlur,
    onChange,
    onFocus,
    label,
    placeholder,
    variant,
    startAdornment,
    endAdornment,
    endAdornmentPosition,
    disabled,
    children,
    id,
    labelstyles,
    InputLabelProps,
    type,
    inputRef,
    className,
    addLabelOffset,
    control,
    controlled,
    fullWidth = true,
    ...otherProps
  } = props;

  return (
    <FormControl variant={variant || 'outlined'} fullWidth={fullWidth} ref={ref}>
      <StyledOutlinedInput
        autoComplete='off'
        startAdornment={startAdornment && <InputAdornment position='start'>{startAdornment}</InputAdornment>}
        endAdornment={
          endAdornment && <InputAdornment position={endAdornmentPosition || 'start'}>{endAdornment}</InputAdornment>
        }
        variant={variant}
        id={id}
        value={value}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        name={name}
        notched={false}
        type={type}
        inputRef={inputRef}
        className={className}
        control={controlled && control}
        $addLabelOffset={addLabelOffset}
        {...otherProps}
      />
      {label && (
        <StyledInputLabel htmlFor={id} error={error} labelstyles={labelstyles} {...InputLabelProps}>
          {label}
        </StyledInputLabel>
      )}
      {children}
    </FormControl>
  );
});

export default TextInput;

const StyledInputLabel = styled(InputLabel)`
  &&& {
    margin-left: 14px;
    color: ${({ theme }) => theme.colors.grey[35]};
    font-size: 13px;
    top: 2px;
  }

  &.MuiFormLabel-root {
    font-size: 13px;
    color: ${({ error, theme }) => error && theme.colors.red[60]};

    text-align: start;
  }

  &.Mui-focused {
    color: ${({ error, theme }) => (error ? theme.colors.red[60] : `${theme.colors.grey[35]} !important`)};
  }

  ${({ labelstyles }) => labelstyles && labelstyles}
`;

const largeStyles = css`
  background-color: white;
  border-radius: 11px !important;
  border: 2px solid #6f9e8a;

  & > input,
  textarea {
    padding-top: 27px;
    padding-bottom: 26px;
    font-size: 18px;
    line-height: 20px;

    &::placeholder {
      color: ${({ theme }) => theme.colors.grey[35]};
      opacity: 1;
    }
  }
`;

const smallStyles = css`
  background-color: #f1fcf7;
  &&& {
    border-radius: 8px;
  }

  & > input,
  textarea {
    padding-top: 15px;
    padding-bottom: 14px;
    font-size: 14px;
    line-height: 15px;

    &:placeholder {
      color: #68867c;
    }
  }
`;

const outlineStyles = css`
  background-color: ${({ theme }) => theme.colors.primaryGrey};
  border: 1px solid ${({ theme }) => theme.colors.blueGrey[80]};
  border-radius: 6px !important;

  & > input,
  textarea {
    padding-top: 19px;
    padding-bottom: 20px;
    font-size: 13px;
    line-height: 13px;

    &:placeholder {
      color: #9da6ab;
    }

    ${space}
  }
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  ${({ variant }) => {
    if (variant === 'large') {
      return largeStyles;
    }
    if (variant === 'outlined') {
      return outlineStyles;
    }

    return smallStyles;
  }}

  & > input, textarea {
    &.focus-visible {
      box-shadow: none !important;
    }
    text-overflow: ellipsis;
  }

  & fieldset {
    border: none !important;
  }
`;
