var __jsx = React.createElement;
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

var ButtonGroup = function ButtonGroup(_ref) {
  var options = _ref.options,
      selectedOption = _ref.selectedOption,
      onSelect = _ref.onSelect,
      onDeselect = _ref.onDeselect;
  return __jsx(ButtonContainer, null, options.map(function (option) {
    return __jsx(Option, {
      onClick: function onClick() {
        if (selectedOption === option.value) {
          onDeselect(option.value);
        } else {
          onSelect(option.value);
        }
      },
      selected: selectedOption && selectedOption === option.value,
      key: option.label
    }, option.label);
  }));
};

ButtonGroup.testProps = [{
  options: [{
    value: 'someVal',
    label: 'Hey Look'
  }, {
    value: 'otherVal',
    label: "Hey Don't Look"
  }]
}];
export default ButtonGroup;
var ButtonContainer = styled(Flex).withConfig({
  displayName: "button-group__ButtonContainer",
  componentId: "sc-18p2rsf-0"
})(["background-color:#e1e8ef;height:35px;border-radius:4px;padding-left:7px;padding-right:16px;justify-content:space-around;align-items:center;"]);
var Option = styled.button.withConfig({
  displayName: "button-group__Option",
  componentId: "sc-18p2rsf-1"
})(["appearance:none;border:none;padding:0;background-color:transparent;width:42px;height:27px;border-radius:6px;background-color:", ";font-size:12px;font-weight:bold;color:", ";cursor:pointer;display:flex;justify-content:center;align-items:center;"], function (props) {
  return props.selected ? '#f8fbff' : 'transparent';
}, function (props) {
  return props.selected ? '#4597e0' : '#69829f';
});