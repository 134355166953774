import { useQuery } from '@apollo/react-hooks';
import { useObserver } from 'mobx-react-lite';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getCategoriesForMenuForState } from 'shared/helpers/products';
import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useCart from 'hooks/use-cart';
import { menuFilters } from 'hooks/use-menu-filter-options';

export default function useDispensaryCategories() {
  const { dispensary } = useDispensary();
  const cart = useCart();
  const menuType = useObserver(() => cart.menuType);
  const { isKiosk } = useUI();
  const flags = useFlags();
  const isCustomizationV2CategoriesEnabled = flags['growth.ecomm.menu-customization-v2-categories.rollout'] ?? false;

  let categories = getCategoriesForMenuForState(dispensary?.location?.state);
  let loadedCategories = dispensary?.activeCategories || [];
  const categoriesV2 = dispensary?.webCustomizationSettingsV2?.categories?.items;

  const { data } = useQuery(menuFilters, {
    skip: !dispensary,
    variables: {
      dispensaryId: dispensary?.id,
      pricingType: menuType,
      isKioskMenu: isKiosk,
    },
  });

  const productCategories = _.get(data, `menuFiltersV2.categoriesSubcategories`, []);

  if (productCategories.length) {
    loadedCategories = productCategories.map(({ category }) => category);
  }

  if (loadedCategories.length) {
    categories = _.filter(categories, (category) => _.includes(loadedCategories, category.value));
  }

  if (isCustomizationV2CategoriesEnabled && categoriesV2) {
    const orderMap = new Map(categoriesV2.map((item, index) => [item.category, index]));
    categories.sort((a, b) => (orderMap.get(a.value) ?? 0) - (orderMap.get(b.value) ?? 0));
  }

  return { categories };
}
