import React from 'react';
import styled, { css } from 'styled-components';

import useTranslation from 'hooks/use-translation';

type CircleButtonProps = React.HTMLProps<HTMLButtonElement> & {
  children?: React.ReactNode;
  className?: string;
  disableClickableStyles?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  quantity?: number;
};

export function CircleButton({
  children,
  className,
  disableClickableStyles = false,
  onClick,
  quantity,
  ...props
}: CircleButtonProps): JSX.Element {
  if (disableClickableStyles) {
    return (
      <StyledCircle className={className} {...props}>
        {children ?? <Quantity quantity={quantity} />}
      </StyledCircle>
    );
  }
  return (
    <StyledCircleButton onClick={onClick} className={className} {...props}>
      {children ?? <Quantity quantity={quantity} />}
    </StyledCircleButton>
  );
}

const Quantity = ({ quantity }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <b aria-label={t('quantity', 'quantity')}>{quantity}</b>
      <QuantityX>x</QuantityX>
    </>
  );
};

const sharedCircleStyles = css`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;
  font-size: 13px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 1px 6px ${({ theme }) => theme.colors.basicShadow};
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const StyledCircle = styled.div`
  ${sharedCircleStyles}
`;

const StyledCircleButton = styled.button`
  ${({ theme: { colors, transitions } }) => css`
    ${sharedCircleStyles}
    cursor: pointer;
    transition: ${transitions.create([`background-color`, `box-shadow`], { duration: 200 })};

    :hover {
      background-color: ${colors.grey[95]};
    }

    :active {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    }
  `}
`;

const QuantityX = styled.p`
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.12em;
  text-align: left;
  padding: 1px 0 1px 2px;
  margin-top: -1px;
`;
