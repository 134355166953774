import { useEffect, useMemo } from 'react';
import { action, isAction } from 'mobx';

import { useCheckout } from 'checkout/context';

export function useCheckoutAutoSave(saveMethod: () => Promise<void>): void {
  const Checkout = useCheckout();

  saveMethod = useMemo(() => {
    if (!isAction(saveMethod)) {
      return action(saveMethod);
    }
    return saveMethod;
  }, [saveMethod]);

  useEffect(() => {
    function removeSaveMethod(): void {
      Checkout.saveMethods = Checkout.saveMethods.filter((method) => method !== saveMethod);
    }
    removeSaveMethod();

    Checkout.saveMethods.push(saveMethod);

    return removeSaveMethod;
  }, [saveMethod, Checkout]);
}
