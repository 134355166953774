import useViewport from 'hooks/use-viewport';
import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import styled, { css } from 'styled-components';

import {
  atCarouselStart,
  atCarouselEnd,
  categoryRowSizes,
  defaultCarouselState,
  defaultMargins,
  getCardWidth,
  getCurrentBreakpoint,
  getCurrentMargins,
  productRowSizes,
  scrollTo,
} from './helpers';

import { MemoizedNextButton as NextButton, MemoizedPrevButton as PrevButton } from './carousel-buttons';

const CarouselSlider = (props) => {
  const {
    children,
    isHalfCardDisplay = true,
    margins = defaultMargins,
    carouselProps = {},
    className,
    disableButtons = false,
    isMini = false,
  } = props;
  const rowSizes = isHalfCardDisplay ? productRowSizes : categoryRowSizes;
  const carouselRef = React.useRef();

  const viewport = useViewport();
  const width = useObserver(() => viewport.width);
  const currentBreakpoint = carouselRef?.current ? getCurrentBreakpoint(width) : `desktop`;
  const currentCardMargin = carouselRef?.current ? getCurrentMargins(width, margins) : 0;
  const rowSize = isMini ? 2 : rowSizes[currentBreakpoint];
  const slideWidth = useObserver(() =>
    carouselRef?.current ? getCardWidth(carouselRef.current) + currentCardMargin : currentCardMargin
  );

  // all of this is going to need to be stored in a ref so our event handlers can access the current state
  const [sizes, _setSizes] = useReducer((prev, updates) => ({ ...prev, ...updates }), {
    currentBreakpoint,
    currentCardMargin,
    rowSize,
    slideWidth,
    width,
  });
  const sizesRef = useRef(sizes);
  const setSizes = (data) => {
    sizesRef.current = data;
    _setSizes(data);
  };

  const totalCards = children?.length || 0;
  const enableButtons = !disableButtons && totalCards > rowSize;

  // same deal with the refs for our more general
  const initialState = defaultCarouselState(enableButtons);
  const [carouselState, _setCarouselState] = useReducer((prev, updates) => ({ ...prev, ...updates }), initialState);
  const carouselStateRef = useRef(carouselState);
  const setCarouselState = (data) => {
    carouselStateRef.current = data;
    _setCarouselState(data);
  };

  const numCardsVisible =
    isHalfCardDisplay && sizesRef.current?.currentBreakpoint !== 'mobile'
      ? sizesRef.current?.rowSize + 0.5
      : sizesRef.current?.rowSize;

  const handleEvent = useCallback(
    (e, type = `click`, direction = null) => {
      e.stopPropagation();
      const { current } = sizesRef;
      const { scrollLeft, scrollWidth, offsetWidth } = carouselRef?.current;

      let advance;
      const updates = { ...carouselStateRef.current };

      // if we've clicked, we haven't moved yet, but need to shift the carousel by a row length
      if (type === `click`) {
        // add/subtract row length
        updates.snapIndex = carouselStateRef.current?.snapIndex + direction * current?.rowSize;

        // don't go past the front
        if (updates.snapIndex < 0) {
          updates.snapIndex = 0;
        }

        // don't go past the back
        if (updates.snapIndex >= totalCards - 1) {
          updates.snapIndex = isHalfCardDisplay ? totalCards - current?.rowSize - 0.5 : totalCards - current?.rowSize;
        }

        const carouselEndPosition = current?.slideWidth * totalCards;
        let nextScrollPosition = current?.slideWidth * updates.snapIndex;

        if (nextScrollPosition < 0) {
          nextScrollPosition = 0;
        }

        if (nextScrollPosition >= carouselEndPosition) {
          const { nudge = 0 } = margins || {};
          nextScrollPosition = carouselEndPosition + nudge;
        }

        updates.lastTransitionType = `click`;
        advance = () => scrollTo(carouselRef.current, nextScrollPosition, 400, -1);
      }

      if (totalCards > current?.rowSize) {
        const startOfCarousel =
          (type === `scroll` && atCarouselStart(scrollLeft)) ||
          (type === `click` && atCarouselStart(updates.snapIndex));
        const endOfCarousel =
          (type === `scroll` && atCarouselEnd(scrollLeft, scrollWidth, offsetWidth)) ||
          (type === `click` && atCarouselEnd(updates.snapIndex, totalCards, numCardsVisible));

        // update our gradients and buttons if we're no longer in the middle of the carousel
        if (startOfCarousel) {
          updates.gradient = `right`;
          updates.nextEnabled = true;
          updates.prevEnabled = false;
        }

        if (endOfCarousel) {
          updates.gradient = `left`;
          updates.nextEnabled = false;
          updates.prevEnabled = true;
        }

        if (!startOfCarousel && !endOfCarousel) {
          updates.gradient = `both`;
          updates.nextEnabled = true;
          updates.prevEnabled = true;
        }
      }

      setCarouselState(updates);
      // perform advancement
      if (advance) {
        advance();
      }
    },
    [numCardsVisible, isHalfCardDisplay, margins, totalCards]
  );

  // keep track of sizes whenever our screen changes
  useEffect(() => {
    if (carouselRef?.current) {
      setSizes({
        currentBreakpoint,
        currentCardMargin,
        rowSize,
        slideWidth: carouselRef.current ? getCardWidth(carouselRef.current) + currentCardMargin : currentCardMargin,
        width,
      });
    }
  }, [carouselRef, currentBreakpoint, currentCardMargin, rowSize, width]);

  const wheelEvent = useCallback((e) => handleEvent(e, `scroll`, null), [handleEvent]);

  // setup wheel/touch event listeners
  useEffect(() => {
    const current = carouselRef?.current;
    if (current) {
      // scroll fires a bit more reliably than the touch events in terms of momentum scrolling
      current?.addEventListener('scroll', wheelEvent);

      return () => {
        if (current) {
          current?.removeEventListener('scroll', wheelEvent);
        }
      };
    }
    return _.noop();
  }, [carouselRef, wheelEvent]);

  const displayLeftGradient = _.includes(['left', 'both'], carouselStateRef.current?.gradient);
  const displayRightGradient = _.includes(['right', 'both'], carouselStateRef.current?.gradient);

  return (
    <GradientContainer className={className}>
      <GradientLeft isVisible={displayLeftGradient} data-testid='carousel-gradient-left' />
      <Carousel role='group' aria-roledescription='carousel' currentBreakpoint={currentBreakpoint} {...carouselProps}>
        <Viewport ref={carouselRef}>
          <Container>{children}</Container>
        </Viewport>
        <PrevButton
          enabled={enableButtons && carouselStateRef.current?.prevEnabled}
          onClick={(e) => handleEvent(e, `click`, -1)}
          isMini={isMini}
        />
        <NextButton
          enabled={enableButtons && carouselStateRef.current?.nextEnabled}
          onClick={(e) => handleEvent(e, `click`, 1)}
          isMini={isMini}
        />
      </Carousel>
      <GradientRight isVisible={enableButtons && displayRightGradient} data-testid='carousel-gradient-right' />
    </GradientContainer>
  );
};

export default CarouselSlider;

const mobileWidthStyles = css`
  width: calc(100% + 25px); /* extend past the 25px page margins on both sides */
`;

const GradientContainer = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    --fade-to-color: var(--carousel-gradient-color, ${colors.white});

    position: relative;
    margin-top: 16px;
    display: flex;

    &:after {
      position: absolute;
    }

    ${breakpoints.down('sm')} {
      /* under 600px... */
      ${mobileWidthStyles}
    }
  `}
`;

const GradientBox = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'inherit' : 'none')};
  position: absolute;
  width: 108px;
  height: 100%;
  z-index: 1;
  pointer-events: none;
`;

const GradientLeft = styled(GradientBox)`
  ${({ theme: { breakpoints } }) => css`
    left: -25px;

    ${breakpoints.up('sm')} {
      left: -20px;
    }

    /* over 1240px.... */
    @media (min-width: 1240px) {
      left: -12px;
    }

    background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--fade-to-color) 100%);
  `}
`;

const GradientRight = styled(GradientBox)`
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--fade-to-color) 100%);
`;

const Carousel = styled.div`
  position: relative;
  /* under 600px... */
  ${mobileWidthStyles};
  margin-left: -25px; /* yank this to the left to re-center our entire carousel */

  /* over 600px... */
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: calc(100% + 20px); /* only add our margin left since this last card will be snipped */
    margin-left: -20px; /* re-adjust based on new margins */
  }

  /* over 1240px.... */
  @media (min-width: 1240px) {
    width: calc(100% + 12px); /* allow 12px on the left side so that we don't cut off box-shadows */
    margin-left: -12px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    [class*='ButtonNext'] {
      right: 30px;
    }
  }
`;

const Viewport = styled.div`
  /* make sure we give enough room for that last hover shadow */
  padding: 8px 8px 16px 20px;
  overflow: auto;

  /* hide scrollbars */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* over 600px... */
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-left: 16px; /* re-adjust based on new margins and box-shadow */
  }

  /* over 1240px.... */
  @media (min-width: 1240px) {
    padding-left: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  will-change: transform;
`;
