import _slicedToArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import _values from "lodash/values";
import _some from "lodash/some";
var __jsx = React.createElement;
import React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { width } from 'styled-system';
import Imgix from 'shared/components/imgix';
import { useQuery } from '@apollo/react-hooks';
import { inject } from 'mobx-react';
import { removeTypename } from 'shared/helpers/utils';
import { mediaQueries } from 'shared/styles';
import { paymentMethodsToDisplayNames } from 'shared/constants';
import { isWeightedProduct } from 'shared/helpers/products';
import { getDefaultRewardsProgramDisplayName } from 'shared/core/helpers/dispensaries';
import { VisuallyHidden } from './visually-hidden';
export var SubTotal = function SubTotal(_ref) {
  var order = _ref.order,
      _ref$fontSize = _ref.fontSize,
      fontSize = _ref$fontSize === void 0 ? '13px' : _ref$fontSize,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? '#6d747b' : _ref$color;
  return __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, "Subtotal:"), __jsx(Text, {
    "data-testid": "order-subtotal",
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, order.subtotal ? "$".concat(parseFloat(order.subtotal).toFixed(2)) : "$".concat(Object.keys(order.orders || {}).map(function (o) {
    return order.orders[o].price;
  }).reduce(function (a, b) {
    return a + b;
  }, 0).toFixed(2))));
};

var getTaxExistence = function getTaxExistence(medicalOrder, taxExistence) {
  return removeTypename(taxExistence === null || taxExistence === void 0 ? void 0 : taxExistence[medicalOrder ? 'med' : 'rec']);
};

var getTaxText = function getTaxText(doesChargeExist, chargeValue) {
  return doesChargeExist && parseFloat(chargeValue) > 0 ? "$".concat(parseFloat(chargeValue).toFixed(2)) : 'Included';
};
/**
 * when a dispo has taxesFirst set in their config, the taxes are rolled into the subtotal
 * so the taxes do exist but they're reduced to 0 for the receipt, in this case we want
 * to show taxes 'included'
 */


var shouldTaxDisplay = function shouldTaxDisplay(taxExistence) {
  return taxExistence && _some(_values(taxExistence), function (exists) {
    return !!exists;
  });
};

var getTaxDisplayConfiguration = function getTaxDisplayConfiguration(medicalOrder, taxExistence, taxValue) {
  var updatedTaxExistence = getTaxExistence(medicalOrder, taxExistence);
  return {
    taxExistence: updatedTaxExistence,
    displayTax: shouldTaxDisplay(updatedTaxExistence),
    taxText: getTaxText(!!updatedTaxExistence, taxValue)
  };
};

export var AdditionalCharges = function AdditionalCharges(_ref2) {
  var _receipt$details, _taxBreakdown$vape, _order$appliedRewards;

  var order = _ref2.order,
      _ref2$fontSize = _ref2.fontSize,
      fontSize = _ref2$fontSize === void 0 ? '13px' : _ref2$fontSize,
      _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? '#6d747b' : _ref2$color,
      rewardsDisplayName = _ref2.rewardsDisplayName;
  var medicalOrder = order.medicalOrder,
      deliveryFee = order.deliveryFee,
      bottleDepositTaxCents = order.bottleDepositTaxCents,
      cannabisTax = order.cannabisTax,
      taxAmount = order.taxAmount,
      tipAmount = order.tipAmount,
      paymentFee = order.paymentFee,
      paymentMethod = order.paymentMethod,
      credit = order.credit,
      receipt = order.receipt;
  var taxBreakdown = receipt === null || receipt === void 0 ? void 0 : (_receipt$details = receipt.details) === null || _receipt$details === void 0 ? void 0 : _receipt$details.taxBreakdown;
  var vapourTax = ((_taxBreakdown$vape = taxBreakdown === null || taxBreakdown === void 0 ? void 0 : taxBreakdown.vape) !== null && _taxBreakdown$vape !== void 0 ? _taxBreakdown$vape : 0) / 100;
  var salesTaxAmount = taxAmount - vapourTax; // since vapourTax is a subset of sales tax, sales tax might "exist" but be completely comprised of vape taxed products
  // in this case we will hide the sales tax line and only show the vapour tax line

  var hideSalesTaxLineItem = !!vapourTax && taxAmount === vapourTax;

  var _getTaxDisplayConfigu = getTaxDisplayConfiguration(medicalOrder, order === null || order === void 0 ? void 0 : order.salesTaxExistence, salesTaxAmount),
      salesTaxExistence = _getTaxDisplayConfigu.taxExistence,
      displaySalesTax = _getTaxDisplayConfigu.displayTax,
      salesTaxToDisplay = _getTaxDisplayConfigu.taxText;

  var _getTaxDisplayConfigu2 = getTaxDisplayConfiguration(medicalOrder, order === null || order === void 0 ? void 0 : order.cannabisTaxExistence, cannabisTax),
      cannabisTaxExistence = _getTaxDisplayConfigu2.taxExistence,
      displayCannabisTax = _getTaxDisplayConfigu2.displayTax,
      cannabisTaxToDisplay = _getTaxDisplayConfigu2.taxText;

  var _getTaxDisplayConfigu3 = getTaxDisplayConfiguration(medicalOrder, order === null || order === void 0 ? void 0 : order.bottleDepositTaxExistence, bottleDepositTaxCents / 100),
      bottleDepositTaxExistence = _getTaxDisplayConfigu3.taxExistence,
      displayBottleDepositTax = _getTaxDisplayConfigu3.displayTax,
      bottleDepositTaxToDisplay = _getTaxDisplayConfigu3.taxText;

  var displayVapourTax = !!vapourTax;
  var vapourTaxToDisplay = getTaxText(displayVapourTax, vapourTax);
  var mixAndMatch = (order === null || order === void 0 ? void 0 : order.mixAndMatch) || 0;
  var hasAppliedRewards = (order === null || order === void 0 ? void 0 : (_order$appliedRewards = order.appliedRewards) === null || _order$appliedRewards === void 0 ? void 0 : _order$appliedRewards.length) > 0;
  var reward = null;

  if (hasAppliedRewards) {
    var _order$appliedRewards2 = _slicedToArray(order.appliedRewards, 1);

    reward = _order$appliedRewards2[0];
  }

  return __jsx(React.Fragment, null, mixAndMatch > 0 && __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, "Mix and Match:"), __jsx(Text, {
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, "($".concat(parseFloat(mixAndMatch).toFixed(2), ")"))), order.deliveryFee > 0 && __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, "Delivery Fee:"), __jsx(Text, {
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, "$".concat(parseFloat(deliveryFee).toFixed(2)))), displayBottleDepositTax && __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, "Bottle Deposit Taxes:"), __jsx(Text, {
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, bottleDepositTaxExistence ? bottleDepositTaxToDisplay : 'Not Included')), displayCannabisTax && __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, "Cannabis Taxes:"), __jsx(Text, {
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, cannabisTaxExistence ? cannabisTaxToDisplay : 'Not Included')), displaySalesTax && !hideSalesTaxLineItem && __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, "Sales Taxes:"), __jsx(Text, {
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, salesTaxExistence ? salesTaxToDisplay : 'Not Included')), displayVapourTax && __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, "Vapour Taxes:"), __jsx(Text, {
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, vapourTaxToDisplay)), tipAmount > 0 && __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, "Tip:"), __jsx(Text, {
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, "$".concat(parseFloat(tipAmount).toFixed(2)))), paymentFee > 0 && __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, paymentMethod in paymentMethodsToDisplayNames ? paymentMethodsToDisplayNames[paymentMethod] : 'Payment', ' ', "Fee:"), __jsx(Text, {
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, "$".concat((paymentFee / 100).toFixed(2)))), credit > 0 && __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, "Credit:"), __jsx(Text, {
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, "($".concat(parseFloat(credit).toFixed(2), ")"))), hasAppliedRewards && __jsx(AddCharge, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: color
  }, rewardsDisplayName, ":"), __jsx(Text, {
    fontSize: fontSize,
    color: color,
    fontWeight: "bolder"
  }, reward.operator === '$' && "($".concat(parseFloat(reward.value).toFixed(2), ")"), reward.operator === '%' && "($".concat(parseFloat(reward.value * 0.01 * parseFloat(order.subtotal)).toFixed(2), ")"))));
};
export var Total = function Total(_ref3) {
  var order = _ref3.order,
      _ref3$uppercase = _ref3.uppercase,
      uppercase = _ref3$uppercase === void 0 ? true : _ref3$uppercase,
      _ref3$fontSize = _ref3.fontSize,
      fontSize = _ref3$fontSize === void 0 ? '14px' : _ref3$fontSize;
  return __jsx(Flex, {
    flexDirection: "row",
    width: ['100%', '100%', '400px', '100%'],
    justifyContent: "space-between",
    alignItems: "center",
    mt: "25px",
    mx: "auto"
  }, __jsx(Text, {
    fontSize: fontSize,
    color: "#46494c",
    fontWeight: "bolder"
  }, uppercase ? 'ORDER TOTAL:' : 'Order Total:'), __jsx(Text, {
    "data-testid": "order-total",
    fontSize: fontSize,
    color: "#46494c",
    fontWeight: "bolder"
  }, "$".concat(parseFloat(order.totalCost || 0).toFixed(2))));
};
var DispoQuery = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetLogoForReceipt"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "dispensaryFilter"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "dispensariesFilterInput"
          }
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filteredDispensaries"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "filter"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "dispensaryFilter"
            }
          }
        }],
        "directives": [],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "logoImage"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storeSettings"
            },
            "arguments": [],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "rewardsIntegrationConfiguration"
                },
                "arguments": [],
                "directives": [],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "rewardsProgramDisplayName"
                    },
                    "arguments": [],
                    "directives": []
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "location"
            },
            "arguments": [],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "country"
                },
                "arguments": [],
                "directives": []
              }]
            }
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 313,
    "source": {
      "body": "\n  query GetLogoForReceipt($dispensaryFilter: dispensariesFilterInput!) {\n    filteredDispensaries(filter: $dispensaryFilter) {\n      logoImage\n      storeSettings {\n        rewardsIntegrationConfiguration {\n          rewardsProgramDisplayName\n        }\n      }\n      location {\n        country\n      }\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
export var Receipt = inject('apolloClient')(function (_ref4) {
  var _data$filteredDispens, _data$filteredDispens2, _dispensary$storeSett, _dispensary$storeSett2, _dispensary$storeSett3;

  var order = _ref4.order,
      dispensaryUrl = _ref4.dispensaryUrl,
      apolloClient = _ref4.apolloClient;

  var _useQuery = useQuery(DispoQuery, {
    variables: {
      dispensaryFilter: {
        cNameOrID: order.dispensaryId || order.dispensary._id
      }
    },
    client: apolloClient
  }),
      data = _useQuery.data;

  var dispensary = (_data$filteredDispens = data === null || data === void 0 ? void 0 : (_data$filteredDispens2 = data.filteredDispensaries) === null || _data$filteredDispens2 === void 0 ? void 0 : _data$filteredDispens2[0]) !== null && _data$filteredDispens !== void 0 ? _data$filteredDispens : {};
  var rewardsDisplayName = (_dispensary$storeSett = dispensary === null || dispensary === void 0 ? void 0 : (_dispensary$storeSett2 = dispensary.storeSettings) === null || _dispensary$storeSett2 === void 0 ? void 0 : (_dispensary$storeSett3 = _dispensary$storeSett2.rewardsIntegrationConfiguration) === null || _dispensary$storeSett3 === void 0 ? void 0 : _dispensary$storeSett3.rewardsProgramDisplayName) !== null && _dispensary$storeSett !== void 0 ? _dispensary$storeSett : getDefaultRewardsProgramDisplayName(dispensary);
  var logoImage = dispensary === null || dispensary === void 0 ? void 0 : dispensary.logoImage;
  return __jsx(React.Fragment, null, __jsx(LogoContainer, null, !_isEmpty(logoImage) && __jsx("a", {
    "data-cy": "dispensary-logo-link",
    "data-testid": "dispensary-logo-link",
    href: dispensaryUrl
  }, __jsx(VisuallyHidden, null, "Go to".concat(order.dispensary.name, " homepage")), __jsx(Logo, {
    role: "img",
    title: "dispensary logo",
    height: 69,
    fit: "clip",
    src: logoImage
  })), __jsx(Flex, {
    flexDirection: "column"
  }, __jsx(Text, {
    "data-testid": "dispensary-name",
    color: "#6d747b",
    fontSize: "18px",
    fontWeight: "bold",
    mb: "10px",
    mt: "5px"
  }, order.dispensary.name), __jsx(Text, {
    "data-testid": "dispensary-address",
    color: "#6d747b",
    fontSize: "13px",
    lineHeight: "1.5"
  }, order.dispensary.address))), __jsx(Divider, {
    width: ['100%', '100%', '400px', '100%']
  }), __jsx(Flex, {
    flexDirection: "column",
    width: ['100%', '100%', '400px', '100%'],
    mb: "17px",
    mx: "auto"
  }, __jsx(Flex, {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    mt: "10px"
  }, __jsx(Text, {
    fontSize: "14px",
    color: "#6d747b",
    fontWeight: "bold"
  }, "(".concat(order.orders.length, ") Item").concat(order.orders.length > 1 ? 's' : '')), __jsx(Text, {
    fontSize: "13px",
    color: "#a7aeb4",
    fontWeight: "bold"
  }, "ORDER: ".concat(order.orderId)))), __jsx(Flex, {
    flexDirection: "column",
    width: ['100%', '100%', '400px', '100%'],
    mx: "auto"
  }, _map(order.orders, function (ord, i) {
    var _ord$product$brandNam, _ord$product$brand;

    var brandName = (_ord$product$brandNam = ord.product.brandName) !== null && _ord$product$brandNam !== void 0 ? _ord$product$brandNam : (_ord$product$brand = ord.product.brand) === null || _ord$product$brand === void 0 ? void 0 : _ord$product$brand.name;
    return __jsx(OrderItem, {
      key: i,
      flexDirection: "column",
      width: "100%",
      mx: "auto"
    }, __jsx(Flex, {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start"
    }, !_isEmpty(brandName) && __jsx(NamesContainer, null, __jsx(BrandName, {
      "data-testid": "item-brand"
    }, brandName), __jsx(OrderName, {
      "data-testid": "item-name",
      hasBrand: !_isEmpty(brandName)
    }, ord.product.Name)), _isEmpty(brandName) && __jsx(OrderName, {
      "data-test": "item-name",
      hasBrand: !_isEmpty(brandName)
    }, ord.product.Name), __jsx(OrderQTYContainer, {
      flexDirection: "row",
      width: "99px",
      justifyContent: "space-between",
      alignItems: "center"
    }, __jsx(Text, {
      "data-testid": "item-quantity",
      fontSize: "13px",
      color: "#6d747b"
    }, "Qty: ".concat(ord.quantity || 1)), __jsx(Text, {
      "data-testid": "item-price",
      fontSize: "13px",
      color: "#6d747b",
      fontWeight: "bolder"
    }, "$".concat(ord.price.toFixed(2))))), isWeightedProduct(ord.product) && __jsx(Text, {
      fontSize: "13px",
      color: "#6d747b",
      textAlign: "left"
    }, ord.option.replace('gC', 'g')));
  })), __jsx(Divider, {
    width: ['100%', '100%', '400px', '100%']
  }), __jsx(SubTotal, {
    order: order
  }), __jsx(AdditionalCharges, {
    order: order,
    rewardsDisplayName: rewardsDisplayName
  }), __jsx(Total, {
    order: order
  }));
});
var NamesContainer = styled.div.withConfig({
  displayName: "receipt__NamesContainer",
  componentId: "sc-1nxphoa-0"
})(["display:block;"]);
var BrandName = styled.div.withConfig({
  displayName: "receipt__BrandName",
  componentId: "sc-1nxphoa-1"
})(["font-weight:bold;text-transform:uppercase;color:#949ea8;font-size:12px;margin-bottom:5px;"]);
var OrderName = styled(Text).withConfig({
  displayName: "receipt__OrderName",
  componentId: "sc-1nxphoa-2"
})(["flex-shrink:1;line-height:15px;text-align:left;font-size:13px;padding-right:9px;color:#6d747b;"]);
export var Divider = styled.div.withConfig({
  displayName: "receipt__Divider",
  componentId: "sc-1nxphoa-3"
})(["", " margin:35px 0 32px 0;min-height:1px;background-color:#d3dadf;@media ", "{margin:32px auto 29px;}"], width, mediaQueries.tablet);
var OrderItem = styled(Flex).withConfig({
  displayName: "receipt__OrderItem",
  componentId: "sc-1nxphoa-4"
})(["&:not(:last-of-type){margin-bottom:23px;}"]);
var AddCharge = styled(Flex).withConfig({
  displayName: "receipt__AddCharge",
  componentId: "sc-1nxphoa-5"
})(["&:not(:last-of-type){margin-bottom:23px;}"]);
var LogoContainer = styled(Flex).withConfig({
  displayName: "receipt__LogoContainer",
  componentId: "sc-1nxphoa-6"
})(["flex-direction:row;justify-content:flex-start;align-items:flex-start;@media ", "{flex-direction:column;align-items:center;text-align:center;}"], mediaQueries.tablet);
var Logo = styled(Imgix).withConfig({
  displayName: "receipt__Logo",
  componentId: "sc-1nxphoa-7"
})(["height:69px;margin-right:15px;cursor:pointer;object-fit:contain;object-position:0 50%;@media ", "{margin-right:0px;margin-bottom:15px;}"], mediaQueries.tablet);
var OrderQTYContainer = styled(Flex).withConfig({
  displayName: "receipt__OrderQTYContainer",
  componentId: "sc-1nxphoa-8"
})(["flex-shrink:0;"]);