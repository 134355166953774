import React from 'react';
import styled from 'styled-components';

import Chip from 'components/core/chip';
import { Typography } from 'src/typography';
import useTranslation from 'hooks/use-translation';

export function StaffPickChip(): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledChip
      label={
        <StyledLabel tag='span' size='xsmall' weight='bold'>
          {t('marketplace.staff-picks', 'Staff pick')}
        </StyledLabel>
      }
    />
  );
}

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: ${({ theme }) => theme.customized.colors.staffPickTag};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledLabel = styled(Typography.Label)`
  text-transform: ${({ theme }) => theme.customized.textTransforms.tags};
`;
