import React from 'react';

function SvgGreenCheckLarge(props) {
  return (
    <svg width='35' height='16' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 8.31431L3.95734 4.22565L7.52152 8.22565L17.1296 0L20 3.01142L7.43415 16L0 8.31431Z'
        fill='#4CA667'
      />
    </svg>
  );
}

export default SvgGreenCheckLarge;
