import { GenericEffect } from 'src/generics/data/generic-product/generic-product.types';
import { FrozenMap } from 'src/generics/data/helpers';

export type Option = {
  key: string;
  label: string;
  value: string;
};

export const normalizeEffectString = (effectString: string): string =>
  effectString.toLowerCase().replace(/[\s/_-]/g, '');

enum EffectKey {
  body = 'body',
  calm = 'calm',
  clearMind = 'clearMind',
  clearMind2 = 'clearMind2',
  comfort = 'comfort',
  creative = 'creative',
  energetic = 'energetic',
  euphoric = 'euphoric',
  focused = 'focused',
  happy = 'happy',
  inspired = 'inspired',
  joints = 'joints',
  medicinal = 'medicinal',
  mind = 'mind',
  muscles = 'muscles',
  painRelief = 'painRelief',
  painRelief2 = 'painRelief2',
  relaxed = 'relaxed',
  skinHealth = 'skinHealth',
  skinHealth2 = 'skinHealth2',
  sleepy = 'sleepy',
  uplifted = 'uplifted',
}

const toEffectValue = new FrozenMap<EffectKey, GenericEffect>(
  [EffectKey.body, GenericEffect.body],
  [EffectKey.calm, GenericEffect.calm],
  [EffectKey.clearMind, GenericEffect.clearMind],
  [EffectKey.clearMind2, GenericEffect.clearMind],
  [EffectKey.comfort, GenericEffect.comfort],
  [EffectKey.creative, GenericEffect.creative],
  [EffectKey.energetic, GenericEffect.energetic],
  [EffectKey.euphoric, GenericEffect.euphoric],
  [EffectKey.focused, GenericEffect.focused],
  [EffectKey.happy, GenericEffect.happy],
  [EffectKey.inspired, GenericEffect.inspired],
  [EffectKey.joints, GenericEffect.joints],
  [EffectKey.medicinal, GenericEffect.medicinal],
  [EffectKey.mind, GenericEffect.mind],
  [EffectKey.muscles, GenericEffect.muscles],
  [EffectKey.painRelief, GenericEffect.painRelief],
  [EffectKey.painRelief2, GenericEffect.painRelief],
  [EffectKey.relaxed, GenericEffect.relaxed],
  [EffectKey.skinHealth, GenericEffect.skinHealth],
  [EffectKey.skinHealth2, GenericEffect.skinHealth],
  [EffectKey.sleepy, GenericEffect.sleepy],
  [EffectKey.uplifted, GenericEffect.uplifted]
);

function getEffectOption(effectKey: EffectKey): Option {
  const effectValue = toEffectValue.get(effectKey);

  return {
    key: effectKey,
    label: effectValue.replace(/[_-]/g, ' '),
    value: normalizeEffectString(effectValue),
  };
}

export function getEffectsAsOptions(effects: string[]): Option[] {
  return effects.map((effect) => getEffectOption(effect as EffectKey));
}
