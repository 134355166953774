import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

import useFeatureFlags from 'hooks/use-feature-flags';

export default function useFeatureFlagsController() {
  const flags = useFlags();
  const FeatureFlags = useFeatureFlags();

  React.useEffect(() => {
    FeatureFlags.setFlags(flags);
  }, [flags]);
}
