var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import { colors } from 'shared/styles.js';
var SliderContainer = styled(Box).withConfig({
  displayName: "slider__SliderContainer",
  componentId: "sc-1aeb09r-0"
})(["margin-top:12px;width:100%;height:14px;border:1px solid #abb6bc;border-radius:8px;background-color:#f4f6f7 !important;"]);
var Slider = styled.input.withConfig({
  displayName: "slider__Slider",
  componentId: "sc-1aeb09r-1"
})(["width:100%;height:14px;appearance:none;background:transparent;outline:none !important;outline-width:0 !important;&:focus,&:active{outline:none !important;}&::-webkit-slider-runnable-track{width:96%;height:14px;cursor:pointer;}&::-webkit-slider-thumb{outline:none;border:1px solid #919891;height:20px;width:20px;border-radius:15px;background:", ";cursor:pointer;appearance:none;transform:translate(-2px,-27%);}&::-moz-range-thumb{outline:none;border:1px solid #919891;height:20px;width:20px;border-radius:15px;background:", ";cursor:pointer;appearance:none;transform:translate(-2px,-15%);}&::-moz-range-progress{background-color:transparent;}&::-moz-range-track{background-color:transparent;height:14px;cursor:pointer;width:96%;}&::-ms-fill-lower{background-color:transparent;}&::-ms-fill-upper{background-color:transparent;}&::-moz-focus-outer{border:0;}"], colors.grey2, colors.grey2);

var background = function background(_ref) {
  var _ref$value = _ref.value,
      value = _ref$value === void 0 ? 0.0 : _ref$value,
      _ref$backgroundColor = _ref.backgroundColor,
      backgroundColor = _ref$backgroundColor === void 0 ? '#8ecbb5' : _ref$backgroundColor,
      min = _ref.min,
      max = _ref.max;
  var range = max - min;
  var percent1 = 100 / range * (value - min);
  var percent2 = 100 - percent1; // (100 / range) * (max - value);

  if (value >= (range - min) / 2) {
    return "linear-gradient(90deg, ".concat(backgroundColor, " ").concat(percent1 - 2, "%, #f4f6f7 ").concat(percent2, "%)");
  }

  return "linear-gradient(270deg, #f4f6f7 ".concat(percent2, "%, ").concat(backgroundColor, " ").concat(percent1 + 15, "%)");
};

export default (function (_ref2) {
  var name = _ref2.name,
      defaultValue = _ref2.defaultValue,
      onChange = _ref2.onChange,
      value = _ref2.value,
      backgroundColor = _ref2.backgroundColor,
      min = _ref2.min,
      max = _ref2.max,
      _ref2$label = _ref2.label,
      label = _ref2$label === void 0 ? '' : _ref2$label;
  return __jsx(SliderContainer, {
    style: {
      background: background({
        value: Number(value),
        backgroundColor: backgroundColor,
        min: min,
        max: max
      })
    }
  }, __jsx(Slider, {
    "aria-label": label,
    name: name,
    type: "range",
    min: min,
    max: max,
    defaultValue: defaultValue,
    onChange: onChange
  }));
});