import React, { forwardRef } from 'react';

import { ModalContainer } from 'components/core';
import { LoginModalContent } from './login-modal-content';

export const LoginModalContainer = forwardRef(({ onClose, containerProps, data }, ref) => {
  const analyticsEventLabel = data;
  return (
    <ModalContainer data-testid='login-modal-container' ref={ref} padding={0} width={420} {...containerProps}>
      <LoginModalContent onClose={onClose} analyticsEventLabel={analyticsEventLabel} />
    </ModalContainer>
  );
});
