import React from 'react';
import styled from 'styled-components';

import { LoadingSkeleton } from 'components/core';

export const FilterSideBarSkeletonLong = () => (
  <SkeletonElementContainer>
    <LoadingSkeleton height={8} width={48} mb={15} />
    <LoadingSkeleton height={6} width={128} mb={15} />
    <LoadingSkeleton height={6} width={84} mb={15} />
    <LoadingSkeleton height={6} width={111} mb={15} />
    <LoadingSkeleton height={6} width={128} mb={15} />
    <LoadingSkeleton height={6} width={97} mb={15} />
    <LoadingSkeleton height={6} width={111} mb={15} />
    <LoadingSkeleton height={6} width={128} mb={15} />
    <LoadingSkeleton height={6} width={74} mb={15} />
  </SkeletonElementContainer>
);

export const FilterSideBarSkeletonShort = () => (
  <SkeletonElementContainer>
    <LoadingSkeleton height={8} width={48} mb={15} />
    <LoadingSkeleton height={6} width={128} mb={15} />
    <LoadingSkeleton height={6} width={84} mb={15} />
    <LoadingSkeleton height={6} width={111} mb={15} />
    <LoadingSkeleton height={6} width={128} mb={15} />
    <LoadingSkeleton height={6} width={97} mb={15} />
    <LoadingSkeleton height={6} width={111} mb={15} />
  </SkeletonElementContainer>
);

const SkeletonElementContainer = styled.div`
  width: 190px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.blueGrey[90]}`};
  padding-bottom: 22px;
  padding-top: 31px;
  :last-child {
    border: none;
  }
`;
