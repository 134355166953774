import React from 'react';
import styled from 'styled-components';

import StoreInfo from 'src/dispensary/components/store-info';

export default function StoreInfoTab(props) {
  const { dispensary, onClose } = props;

  return (
    <Container>
      <StoreInfo className='mobile-menu' dispensary={dispensary} onClose={onClose} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 1100px;
  margin-bottom: 100px;
`;
