import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { space, width } from 'styled-system';

import useTranslation, { ExtendedUseTranslationResponse } from 'hooks/use-translation';

import { Button } from 'components/core';
import MedicalCardForm, { CO } from 'components/forms/sign-up-form/medical-card-form';
import { FormTextInput, FormFormattedInput } from 'components/form-inputs';
import Form from 'components/forms/form';
import { birthdayRegEx } from 'components/forms/form-utils';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { EnrollmentSteps } from 'src/payments/constants';
import { useDutchiePayAnalytics } from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import { DutchiePayButtonContainer, EmailDisplay } from 'src/payments/components';
import { yup } from 'shared/core/yup';

export type DutchiePaySignUpFormProps = {
  onFormSubmit: (SignUpFormInputs: SignUpFormProps) => Promise<void>;
  showMedicalCardForm: boolean;
  ageCheck: (e: ChangeEvent<HTMLInputElement>) => void;
  userEmail: string;
};

export type SignUpFormProps = {
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
  birthday: string;
  medicalCardNumber: string;
  expirationDate: string;
  state: string;
};

const signUpFormValidation = (
  t: ExtendedUseTranslationResponse['t'],
  showMedicalCardForm
): yup.SchemaOf<SignUpFormProps> => {
  const medicalCardVerification = showMedicalCardForm
    ? {
        medicalCardNumber: yup.string().when([`state`], {
          is: (state) => state === CO,
          then: yup.string(),
          otherwise: yup.string().required(t('form.medicalCardNumber', 'medical card number is required')),
        }),
        expirationDate: yup
          .string()
          .beforeToday(`MM/DD/YYYY`, `medical card is expired`)
          .required(t('form.expirationDate', 'expiration date is required')),
        state: yup.string().required(t('form.state', 'state is required')),
      }
    : {};

  return yup.object().shape({
    firstName: yup.string().required(t('form.firstNameRequired', 'first name is required')),
    lastName: yup.string().required(t('form.lastNameRequired', 'last name is required')),

    password: yup.string().required(t('form.passwordRequired', 'password is required')).min(8),
    phoneNumber: yup
      .string()
      .required(t('form.phoneNumberRequired', 'phone number is required'))
      .phone(t('form.validPhoneNumber', 'please enter a valid phone number')),
    birthday: yup
      .string()
      .required(t('form.birthdayRequired', 'birthday is required'))
      .isLegalAge(`MM/DD/YYYY`, t('form.isLegalAge', 'You must be at least 18 years old to use dutchie'))
      .matches(birthdayRegEx, t('form.validBirthday', 'please enter a valid birthday')),
    ...medicalCardVerification,
  });
};

export function SignUpForm({
  onFormSubmit,
  showMedicalCardForm,
  ageCheck,
  userEmail,
}: DutchiePaySignUpFormProps): JSX.Element {
  const { t } = useTranslation();
  const { trackEnrollmentStepSubmitted } = useDutchiePayAnalytics();
  const requiredMedicalCardFormFieldKeys = showMedicalCardForm ? [`medicalCardNumber`, `expirationDate`, `state`] : [];
  const DutchiePayEnrollment = useDutchiePayEnrollment();

  const handleSubmit = (vals): void => {
    trackEnrollmentStepSubmitted(EnrollmentSteps.signUpForm);

    void onFormSubmit(vals);
  };

  return (
    <Form onSubmit={handleSubmit} mode='onSubmit' validationSchema={signUpFormValidation(t, showMedicalCardForm)}>
      <FormContainer>
        <EmailDisplay>{userEmail}</EmailDisplay>
        <InlineContainer>
          <InputContainer width='50%' mr={9}>
            <FormTextInput
              autoFocus
              name='firstName'
              label={t('signUp.firstName', 'First name')}
              variant='filled'
              autoComplete='given-name'
              required
            />
          </InputContainer>

          <InputContainer width='50%' ml={9}>
            <FormTextInput
              name='lastName'
              label={t('signUp.lastName', 'Last name')}
              variant='filled'
              autoComplete='family-name'
              required
            />
          </InputContainer>
        </InlineContainer>

        <InputContainer onBlur={ageCheck} mt={16}>
          <FormFormattedInput
            format='date'
            name='birthday'
            label={t('signUp.birthday', 'Date of birth')}
            variant='filled'
            autoComplete='bday'
            required
          />
        </InputContainer>

        <InputContainer mt={16}>
          <FormFormattedInput
            format='phone'
            name='phoneNumber'
            label={t('signUp.phone', 'Mobile phone number')}
            variant='filled'
            autoComplete='tel'
            required
          />
        </InputContainer>

        <InputContainer mt={16}>
          <FormTextInput
            name='password'
            label={t('signUp.password', 'Password')}
            type='password'
            variant='filled'
            autoComplete='new-password'
            required
          />
        </InputContainer>
      </FormContainer>

      {showMedicalCardForm && <MedicalCardForm />}

      <DutchiePayButtonContainer
        showBackLink
        onBackLinkClick={() => DutchiePayEnrollment.setCurrentStep(EnrollmentSteps.emailForm)}
        mt={20}
        requiredFieldKeys={[
          `firstName`,
          `lastName`,
          `password`,
          `phoneNumber`,
          `birthday`,
          ...requiredMedicalCardFormFieldKeys,
        ]}
      />
    </Form>
  );
}

const InlineContainer = styled.div`
  display: flex;

  ${space}
`;

const FormContainer = styled.div`
  ${({ theme: { spaces } }) => css`
    margin: ${spaces[5]} 0;
    display: flex;
    flex-direction: column;
  `}
`;

export const InputContainer = styled.div`
  ${width}
  ${space}

  display: ${({ hideInput }) => (hideInput ? `none` : `inherit`)}
`;

export const StyledButton = styled(Button)`
  width: 95px;
`;
