import React from 'react';
import useTranslation from 'src/hooks/use-translation';
import styled from 'styled-components';
import { CurrentAddressProps } from '../location-settings-popover.types';
import { StyledGreenCheck, StyledGreyCapsText } from './styles';

export function CurrentAddress({ currentAddressString }: CurrentAddressProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <StyledGreyCapsText>{t('locationSettings.currentAddress', 'Current Location')}</StyledGreyCapsText>
      <AddressText>
        <StyledGreenCheck />
        <span>{currentAddressString}</span>
      </AddressText>
    </>
  );
}

const AddressText = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 21px;
  display: flex;
  place-items: baseline;
  line-height: 1.6;

  svg {
    margin-right: 8px;
    width: 13px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      width: 19px;
    }
  }
`;
