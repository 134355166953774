/* eslint-disable max-len */
import React from 'react';

export default function CheckoutSuccess(props) {
  return (
    <svg width='57' height='59' viewBox='0 0 57 59' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Success icon'>
        <path
          id='Fill 1'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M50.525 15.0316V14.7516L50.325 14.3916L42.525 4.31156C42.041 3.67156 41.285 3.29956 40.485 3.31156H9.765C8.965 3.29956 8.209 3.67156 7.725 4.31156L0.045 14.3916V14.7516V15.0316C-0.015 15.3196 -0.015 15.6196 0.045 15.9116V51.3116C0.065 55.5116 3.489 58.8916 7.685 58.8676H7.725H43.725C47.669 58.5556 50.717 55.2676 50.725 51.3116V15.9116C50.721 15.6076 50.653 15.3076 50.525 15.0316Z'
          fill='#5EA68C'
        />
        <path
          id='Fill 3'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M39.0449 8.34998L43.0449 13.39H7.04492L11.0449 8.34998H39.0449Z'
          fill='#B8D3C7'
        />
        <path
          id='Fill 5'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M45.6031 18.4301V51.3101C45.5631 52.7101 44.4471 53.8301 43.0431 53.8301H7.04307C5.72307 53.7541 4.64307 52.6301 4.64307 51.3101V18.4301H45.6031Z'
          fill='#EEFFF8'
        />
        <path
          id='Fill 7'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M39.0449 8.34998L41.0449 10.87H9.04492L11.0449 8.34998H39.0449Z'
          fill='#E1EFE8'
        />
        <path
          id='Fill 9'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.3202 26.2328C12.3202 26.2328 11.6602 41.1848 25.1242 41.1848C38.5882 41.1848 37.9202 26.2328 37.9202 26.2328L35.1522 28.8128C35.1522 28.8128 32.3202 38.8328 25.1202 38.8328C17.9202 38.8328 12.3202 26.2328 12.3202 26.2328Z'
          fill='#B6D6C6'
        />
        <path
          id='Fill 11'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M25.1208 38.8344C32.1288 38.8784 37.8528 33.2424 37.9208 26.2344C37.9208 24.8184 36.7728 23.6744 35.3568 23.6744C33.9488 23.6744 32.8008 24.8224 32.7968 26.2344C32.7968 30.4744 29.3608 33.9144 25.1208 33.9144C20.8768 33.9144 17.4408 30.4744 17.4408 26.2344C17.4408 24.8184 16.2928 23.6704 14.8808 23.6744C13.4648 23.6744 12.3208 24.8184 12.3208 26.2344C12.3848 33.2424 18.1128 38.8784 25.1208 38.8344Z'
          fill='#5EA68C'
        />
        <g id='Group 17'>
          <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='35' y='0' width='22' height='21'>
            <path id='Clip 16' fillRule='evenodd' clipRule='evenodd' d='M35 0H57V21H35V0Z' fill='white' />
          </mask>
          <g mask='url(#mask0)'>
            <path
              id='Fill 15'
              fillRule='evenodd'
              clipRule='evenodd'
              d='M57 10.5024C57 16.2976 52.0748 21 46 21C39.9252 21 35 16.2976 35 10.5024C35 4.70244 39.9252 0 46 0C52.0748 0 57 4.70244 57 10.5024Z'
              fill='#4597E0'
            />
          </g>
        </g>
        <path
          id='Fill 18'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M40 11V12L44.442 16.5374L52 9V8L44.5567 11.3396L40 11Z'
          fill='#3383AA'
        />
        <g id='Fill 20' filter='url(#filter0_d)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M42.398 8.42013L40 10.7252L44.4371 15L52 7.72524L50.3199 6L44.5567 10.615L42.398 8.42013Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_d'
          x='39'
          y='6'
          width='14'
          height='11'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.127661 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
