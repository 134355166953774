import React from 'react';
import styled, { css } from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

import useStores from 'shared/hooks/use-stores';
import { Typography } from 'src/typography';
import { PoweredByPlaid } from 'src/payments/components';
import { PayByBankLogo } from 'src/assets/pay-by-bank-logo';
import LoadingSpinner from 'src/assets/loader/spinner';

type QrCodeContentProps = {
  instoreCartUrl: string | null;
  loading: boolean;
  handlePayLater: () => void;
};

export function QrCodeContent({ instoreCartUrl, loading, handlePayLater }: QrCodeContentProps): JSX.Element {
  const { Cart } = useStores();

  const menuTotalAmount = Cart.costBreakdown?.menuTotal ?? null;

  return (
    <>
      <Content>
        <ContentHeadingContainer>
          <ContentHeading size='large' tag='h1'>
            Scan to pay with your bank
          </ContentHeading>
          <PayByBankContainer>
            <PayByBankIcon width='70' height='13' />
            <PoweredByPlaid />
          </PayByBankContainer>
        </ContentHeadingContainer>
        <QrCodeContainer>
          {instoreCartUrl && !loading ? (
            <QRCodeSVG data-testid='qr-code' value={instoreCartUrl} size={200} />
          ) : (
            <Loader data-testid='loading-indicator'>
              <LoadingSpinner size={100} borderColor='#f3f6f8' />
            </Loader>
          )}
        </QrCodeContainer>
        {menuTotalAmount && (
          <OrderTotal size='medium' tag='h3'>
            Total: ${menuTotalAmount.toFixed(2)}
          </OrderTotal>
        )}
      </Content>
      <PayLaterButton data-testid='pay-later-button' onClick={handlePayLater}>
        Pay later at the counter
      </PayLaterButton>
    </>
  );
}

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 369px;
  min-width: 369px;

  ${({ theme: { spaces, colors } }) => css`
    background: ${colors.white};
    border-radius: 30px;
    margin: ${spaces[6]} 0;
    padding: ${spaces[6]} ${spaces[8]};
  `}
`;

const ContentHeadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  span {
    color: #677882;
    font-size: 12px;
    weight: 400;
    line-height: 12px;
  }
`;

const ContentHeading = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
`;

const PayByBankContainer = styled.div`
  align-items: flex-start;
  display: flex;
  gap: ${({ theme }) => theme.spaces[1]};
  justify-content: center;
`;

const PayByBankIcon = styled(PayByBankLogo)`
  margin-top: 1.25px;
`;

const QrCodeContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spaces[6]} 0;
  min-height: 200px;
  min-width: 200px;
`;

const Loader = styled.div`
  align-items: center;
  border-radius: 100px;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100px;
`;

const OrderTotal = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  line-height: 22px;
  size: 18px;
  weight: 600;
`;

const PayLaterButton = styled.button`
  background: transparent;
  border: none;
  color: #3387e1;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;
