import React from 'react';
import styled from 'styled-components';

export default function Hero(props) {
  return <Container {...props} />;
}

const Container = styled.div`
  height: 489px;
  width: 100%;
`;
