import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Typography } from 'src/typography';
import { ModalContainer } from 'src/components/core';

import { useKioskQrCodeModal } from './use-kiosk-qr-code-modal';
import { QrCodeContent } from './qr-code-content';
import { ErrorContent } from './error-content';

type KioskQrCodeProps = {
  onClose: () => void;
};

export const KioskQrCodeModal = forwardRef((props: KioskQrCodeProps, ref): JSX.Element | null => {
  const { onClose } = props;

  const {
    instoreCartUrl,
    handlePayLater,
    orderNumber,
    isAnonymousKioskCheckout,
    loading,
    error,
    handleOnClose,
    secondsLeft,
  } = useKioskQrCodeModal({ onClose });

  const displayCountdown = secondsLeft < 9;

  return (
    <ModalContainer ref={ref} width={850} padding={0}>
      <ModalBody>
        <OrderHeadingContent>
          <OrderSubmittedHeading size='medium' tag='h3'>
            Order submitted!
          </OrderSubmittedHeading>
          {isAnonymousKioskCheckout && orderNumber && (
            <OrderNumberSubheading data-testid='order-number' size='medium' tag='h4'>
              ORDER #: {orderNumber}
            </OrderNumberSubheading>
          )}
        </OrderHeadingContent>
        {error ? (
          <ErrorContent onClose={handleOnClose} />
        ) : (
          <QrCodeContent instoreCartUrl={instoreCartUrl} loading={loading} handlePayLater={handlePayLater} />
        )}
        {displayCountdown && (
          <SubText size='small'>{`This window will automatically close in ${secondsLeft} seconds...`}</SubText>
        )}
      </ModalBody>
    </ModalContainer>
  );
});

const ModalBody = styled.div`
  align-items: center;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  min-height: 540px;
  justify-content: center;
  padding: 24px;
  background: ${({ theme }) => theme.colors.grey[95]};
`;

const OrderSubmittedHeading = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-weight: 600;
  line-height: 22px;
  size: 18px;
`;

const OrderNumberSubheading = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.grey[50]};
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;
`;

const OrderHeadingContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[1]};
`;

const SubText = styled(Typography.Body)`
  color: #717f89;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
`;
