import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { space } from 'styled-system';
import MuiHoverMenu from 'material-ui-popup-state/HoverMenu';
import MenuItem from '@material-ui/core/MenuItem';

export default function HoverMenu({
  options = null,
  activeOptionKey = null,
  onOptionSelect = _.noop,
  highlight = false,
  children,
  ...props
}) {
  return (
    <StyledHoverMenu {...props} disableAutoFocusItem>
      {children}
      {!children &&
        options?.length > 0 &&
        _.map(_.compact(options), (option) => (
          <HoverMenuItem
            key={option.key}
            selected={activeOptionKey === option.key}
            onClick={() => onOptionSelect(option)}
            highlight={highlight}
          >
            {option.label}
          </HoverMenuItem>
        ))}
    </StyledHoverMenu>
  );
}

const StyledHoverMenu = styled(MuiHoverMenu)`
  ${space}
`;

export const HoverMenuItem = styled(MenuItem)`
  ${({ selected, theme, highlight }) => highlight && selected && `background: ${theme.colors.primaryGrey} !important`};
  &.Mui-focusVisible {
    background: ${({ theme }) => `${theme.colors.primaryGrey} !important`};
  }
`;
