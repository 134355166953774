import React from 'react';
import styled, { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { SmallLoader } from 'shared/components';
import { reactFormIsComplete, FormContext } from './util';

export type DutchiePayButtonProps = React.HTMLProps<HTMLButtonElement> & {
  loading?: boolean;
  type?: 'button' | 'reset' | 'submit';

  // Will disable the button if the form is invalid when used with react-hook-form
  requiredFieldKeys?: string[];
};

export function DutchiePayButton(props: DutchiePayButtonProps): JSX.Element {
  const { loading = false, children = [], disabled = false, requiredFieldKeys = [], ...rest } = props;
  const formContext = useFormContext() as FormContext;
  const reactFormInvalid = !reactFormIsComplete(formContext, requiredFieldKeys);

  const shouldCenter = React.Children.count(children) === 1;
  const isSubmitting = formContext?.formState.isSubmitting ?? false;
  const isLoading = loading || isSubmitting;
  const buttonIsDisabled = disabled || reactFormInvalid;

  const StyledButton = buttonIsDisabled ? DisabledButton : Button;
  const loader = (
    <LoaderContainer>
      <SmallLoader color='white' />
    </LoaderContainer>
  );

  return (
    <StyledButton
      type='submit'
      aria-label='confirm'
      center={isLoading || shouldCenter}
      disabled={isLoading || buttonIsDisabled}
      loading={isLoading}
      {...rest}
    >
      {isLoading ? loader : children}
    </StyledButton>
  );
}

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;

  :first-child {
    scale: 0.55;
  }
`;

// Stops prop bleed into the button compoennt
// eslint-disable-next-line react/button-has-type, @typescript-eslint/no-unused-vars, no-unused-vars
const Button = styled(({ center, loading, ...rest }) => <button {...rest} />)`
  ${({ theme: { spaces, colors }, loading, center }) => css`
    /* Remove Defaults */
    border: 0;

    /* Child Positioning */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${spaces[4]} ${spaces[6]};
    justify-content: ${center ? 'center' : 'space-between'};

    /* Text */
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    color: ${colors.white};

    /* Container */
    border-radius: 100px;
    width: 100%;
    height: 49px;
    background: ${colors.dutchiePayGreen};

    /* Behavior */
    cursor: pointer;
    pointer-events: ${loading ? 'none' : 'auto'};

    :hover {
      background-color: #008a68;
    }
  `}
`;

const DisabledButton = styled(Button)`
  ${({ theme: { colors } }) => css`
    /* Text */
    color: ${colors.grey[45]};

    /* Container */
    background: ${colors.grey[90]};

    /* Behavior */
    cursor: 'inherit';
    pointer-events: none;
  `}
`;
