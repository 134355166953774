import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Button, ModalContainer, ModalExitIcon } from 'src/components/core';
import { Typography } from 'src/typography';
import BasketSuccess from 'src/assets/basket-success-icon';
import { useKioskCheckoutSuccessModalV2 } from './use-kiosk-checkout-success-modal-v2';

const DEFAULT_CONTENT_TEXT = 'Your order will be ready momentarily.';

type KioskCheckoutSuccessModalV2Props = {
  onClose: () => void;
};

export const KioskCheckoutSuccessModalV2 = forwardRef(
  ({ onClose }: KioskCheckoutSuccessModalV2Props, ref): JSX.Element => {
    const {
      secondsLeft,
      handleOnClose,
      isAnonymousKioskCheckout,
      orderNumber,
      kioskInstructions,
    } = useKioskCheckoutSuccessModalV2({ onClose });

    return (
      <ModalContainer ref={ref} width={850} padding={0}>
        <ModalExitIcon onClick={handleOnClose} />
        <ModalBody>
          <OrderHeadingContainer>
            <OrderSubmittedHeading size='medium' tag='h3'>
              Order submitted!
            </OrderSubmittedHeading>
            {isAnonymousKioskCheckout && orderNumber && (
              <OrderNumberSubheading size='medium' tag='h4'>
                ORDER #: {orderNumber}
              </OrderNumberSubheading>
            )}
          </OrderHeadingContainer>
          <Content>
            <BasketSuccess />
            <ContentHeadingContainer>
              <ContentHeading tag='h1' size='medium'>
                Your order is being prepared
              </ContentHeading>
              <ContentMainText size='large'>
                {kioskInstructions.length > 0 ? kioskInstructions : DEFAULT_CONTENT_TEXT}
              </ContentMainText>
            </ContentHeadingContainer>
            <ButtonContainer>
              <CloseButton fluid onClick={handleOnClose} size='large'>
                Close
              </CloseButton>
              <SubText size='small'>{`This window will automatically close in ${secondsLeft} seconds...`}</SubText>
            </ButtonContainer>
          </Content>
        </ModalBody>
      </ModalContainer>
    );
  }
);

const ModalBody = styled.div`
  ${({ theme: { spaces } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    height: 540px;
    justify-content: center;
    padding: ${spaces[9]} ${spaces[8]};
    width: 100%;
    gap: ${spaces[6]};
  `}
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[8]};
  justify-content: center;
  max-width: 390px;
  text-align: center;
`;

const ContentHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]};
`;

const ContentHeading = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
`;

const ContentMainText = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.grey[30]};
  font-size: 16px;
  line-height: 24px;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]};
  width: 100%;
`;

const SubText = styled(Typography.Body)`
  color: #717f89;
  font-size: 13px;
  line-height: 18px;
  weight: 400;
`;

const CloseButton = styled(Button)`
  width: 100%;
`;

const OrderHeadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[1]};
`;

const OrderSubmittedHeading = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-weight: 600;
  line-height: 22px;
  size: 18px;
`;

const OrderNumberSubheading = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.grey[50]};
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;
`;
