import _ from 'lodash';
import React from 'react';

import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import useViewportVisibility from 'hooks/use-viewport-visibility';
import usePaths from 'src/hooks/use-paths';
import { CategoriesForMenu as categories, SubcategoryOptions as subcategories } from 'shared/constants';
import { GenericCarousel } from './generic-carousel';
import { defaultFilters, checkNeedToMakeEdibleIngestible, replaceEdibleWithIngestible } from '../carousels.utils';

export function SubcategoryCarousel(props) {
  const { ref, hasBeenVisible } = useViewportVisibility();
  const { section, dispensaryState } = props;
  const { category, label, subcategory } = section;
  const currentSubcategory = _.find(subcategories[category], [`value`, subcategory]) || subcategory;

  const { loading, products } = useDispensaryProducts({
    filters: { ...defaultFilters, subcategories: [currentSubcategory.key], useCache: true },
    perPage: 25,
  });

  const makeEdibleIngestible = checkNeedToMakeEdibleIngestible(dispensaryState, category);

  const currentCategory = _.find(categories, [`value`, category]) || category;
  const { href, route, query } = usePaths({ category: currentCategory, subcategory });

  if (!products?.length && !loading && hasBeenVisible) {
    return null;
  }

  const sectionProps = {
    ...props,
    hasBeenVisible,
    isLoading: loading,
    linkLabel: section.linkLabel,
    href: makeEdibleIngestible ? replaceEdibleWithIngestible(href) : href,
    query,
    label,
    products,
    route,
  };

  return <GenericCarousel ref={ref} {...sectionProps} />;
}
