import { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';

import usePrevious from 'shared/hooks/use-previous';
import { getQuantityRemaining } from 'shared/helpers/products';

import { Product } from 'utils/helpers/product';

import { useProductPricing, PriceOption } from 'hooks/use-product-pricing';

export type { PriceOption };

export type UseProductOptionsStateReturn = {
  handleQuantityDecrement: () => void;
  handleQuantityIncrement: () => void;
  handleSetSelectOption: (opt: PriceOption) => void;
  hasQuantityRemaining: boolean;
  options: PriceOption[];
  quantity: number;
  quantityRemaining: number;
  selectedOption: PriceOption;
};

export function useProductOptionsState(product: Product): UseProductOptionsStateReturn {
  const { getPriceOptions } = useProductPricing();

  const options = getPriceOptions(product);

  const [selectedOption, setSelectedOption] = useState<PriceOption>(options[0]);
  const [quantity, setQuantity] = useState<number>(1);

  const decrementQuantity = useCallback((): void => setQuantity((prev) => prev - 1), []);
  const incrementQuantity = useCallback((): void => setQuantity((prev) => prev + 1), []);
  const resetQuantity = useCallback((): void => setQuantity(1), []);

  const selectedOptionValue = selectedOption.value;
  const prevSelectedOptionValue = usePrevious(selectedOptionValue);

  const quantityRemaining = getQuantityRemaining(product, selectedOptionValue);
  const hasQuantityRemaining = _.clamp(quantityRemaining, 1, 10) > quantity;

  useEffect(() => {
    if (selectedOptionValue !== prevSelectedOptionValue) {
      resetQuantity();
    }
  }, [resetQuantity, selectedOptionValue, prevSelectedOptionValue]);

  return {
    handleSetSelectOption: setSelectedOption,
    handleQuantityDecrement: decrementQuantity,
    handleQuantityIncrement: incrementQuantity,
    hasQuantityRemaining,
    options,
    quantity,
    quantityRemaining,
    selectedOption,
  };
}
