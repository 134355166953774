import { NextRouter } from 'next/router';
import { GqlDispensaries } from 'types/graphql';
import { useCheckAgeVerification } from '../use-check-age-verification';

type UseRedirectVerifiedCustomerProps = {
  userId: string | undefined;
  dispensary: GqlDispensaries;
  router: NextRouter;
  href: string;
};

export function useRedirectVerifiedCustomers({
  userId,
  dispensary,
  router,
  href,
}: UseRedirectVerifiedCustomerProps): void {
  const { isAgeVerified, loading } = useCheckAgeVerification({ userId, dispensary });

  if (!loading && isAgeVerified) {
    void router.replace(href);
  }
}
