import _includes from 'lodash/includes';
import { NextRouter } from 'next/router';

import ROUTES from 'src/routes';
import { isBrowser } from 'utils/misc-utils';

export enum PageTypes {
  accountPage = 'AccountPage',
  brandsPage = 'BrandsPage',
  categoryPage = 'CategoryPage',
  checkoutPage = 'CheckoutPage',
  homePage = 'HomePage',
  menuSectionPage = 'MenuSectionPage',
  orderConfirmationPage = 'OrderConfirmationPage',
  other = 'Other',
  productPage = 'ProductPage',
  searchResultsPage = 'SearchResultsPage',
  specialsPage = 'SpecialsPage',
}

export function getPageType({ pathname }: NextRouter): PageTypes | null {
  if (!isBrowser()) {
    return null;
  }
  if (_includes(pathname, ROUTES.PRODUCTS_CATEGORY)) {
    return PageTypes.categoryPage;
  }
  if (_includes(pathname, ROUTES.PRODUCT)) {
    return PageTypes.productPage;
  }
  if (_includes(pathname, ROUTES.SPECIALS)) {
    return PageTypes.specialsPage;
  }
  if (_includes(pathname, ROUTES.BRANDS)) {
    return PageTypes.brandsPage;
  }
  if (_includes(pathname, ROUTES.CHECKOUT)) {
    return PageTypes.checkoutPage;
  }
  if (_includes(pathname, ROUTES.ALL_PRODUCTS_SEARCH)) {
    return PageTypes.searchResultsPage;
  }
  if (
    pathname === ROUTES.DISPENSARY_CNAME ||
    pathname === ROUTES.EMBEDDED_MENU_CNAME ||
    pathname === ROUTES.KIOSKS_CNAME
  ) {
    return PageTypes.homePage;
  }
  if (
    _includes(pathname, ROUTES.USER) ||
    _includes(pathname, ROUTES.ACCOUNT) ||
    _includes(pathname, ROUTES.ORDERS) ||
    _includes(pathname, ROUTES.PREFERENCES)
  ) {
    return PageTypes.accountPage;
  }
  if (_includes(pathname, ROUTES.MENU_SECTION_ID)) {
    return PageTypes.menuSectionPage;
  }
  return PageTypes.other;
}
