import _ from 'lodash';

import { hasIsolatedMenus } from 'shared/core/helpers/dispensaries';
import { MENU_TYPE_REC, MENU_TYPE_MED } from 'shared/constants';

import { useRouter } from 'next/router';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useCart from './use-cart';
import useUI from './use-ui';
import useCustomerState from './use-customer-state';
import useProductQueryParams from './use-product-query-params';

export default function useSwitchMenuType() {
  const { dispensary } = useDispensary();
  const cart = useCart();
  const UI = useUI();
  const customerState = useCustomerState();
  const router = useRouter();
  const viewingSpecialsPage = _.includes(router.route, 'specials');
  const { resetQueryParams } = useProductQueryParams();

  async function switchMenuType(type) {
    if (viewingSpecialsPage) {
      resetQueryParams();
    }

    if (!type || !_.includes([MENU_TYPE_REC, MENU_TYPE_MED], type)) {
      console.error(`${type} is not a valid menu type. Cannot switch menu.`);
      return false;
    }

    let canSwitch = true;

    if (hasIsolatedMenus(dispensary) && !_.isEmpty(cart.items) && cart.isMedical) {
      canSwitch = await UI.openMenuSwitchWarningModal();
    }

    if (!canSwitch) {
      return false;
    }

    cart.changePricingType(type, dispensary?.id);
    cart.applyQuantityLimits(type, customerState);

    return true;
  }

  return switchMenuType;
}
