import React from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import _startCase from 'lodash/startCase';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import useCart from 'hooks/use-cart';
import useSearchPreferences from 'hooks/use-search-preferences';
import useTranslation from 'hooks/use-translation';

import { focusStyles } from 'src/theme';

export default function OrderTypeDropdown(props) {
  const { onClose } = props;
  const Cart = useCart();
  const SearchPreferences = useSearchPreferences();
  const { t } = useTranslation();
  const orderType = useObserver(() => Cart.orderType);
  const nextOrderType = orderType === 'delivery' ? `pickup` : `delivery`;

  const handleMenuItemClick = () => {
    onClose();
    Cart.setOrderType(nextOrderType);
    SearchPreferences.setOrderType(nextOrderType);
  };

  return (
    <StyledMenu {...props}>
      <StyledMenuItem onClick={handleMenuItemClick}>
        {t(`common.switchTo`, {
          defaultValue: 'Switch to {{orderType}}',
          orderType: _startCase(nextOrderType),
        })}
      </StyledMenuItem>
    </StyledMenu>
  );
}

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    font-family: ${({ theme }) => theme.customized.fonts.secondary};
    font-size: 14px;
    height: 53px !important;
    justify-content: center;
    line-height: 24px;
    min-width: 177px;
    padding: 14px 47px 14px 29px;
    width: 100%;
  }

  /* :hover, */
  &.Mui-focusVisible {
    background: ${({ theme }) => `${theme.colors.primaryGrey} !important`};
    ${focusStyles}
  }
`;

const StyledMenu = styled(Menu)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  width: 187px;
  position: relative;
  border-radius: 8px;
  margin-top: 8px;

  .MuiPopover-paper {
    max-width: unset;
    overflow: unset;
    overflow-x: unset;
    overflow-y: unset;
  }
`;
