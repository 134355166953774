import React from 'react';
import styled from 'styled-components';

import { Clickable } from 'shared/components';
import ClearIcon from 'src/assets/clear-icon';
import { useAddressAutocomplete } from '../context';

export function AddressAutocompleteClearButton(props): JSX.Element {
  const { clearInput } = useAddressAutocomplete();

  return (
    <ClearButton onClick={clearInput} {...props}>
      <ClearIcon />
    </ClearButton>
  );
}

const ClearButton = styled(Clickable)`
  padding: 0;
  width: 19px;
  height: 19px;
  border-radius: 19px;
  border: none;
  background-color: ${({ theme }) => theme?.colors?.grey[70]};
  svg {
    height: 19px;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.grey[60]};
  }
`;
