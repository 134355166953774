'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
Object.defineProperty(exports, 'appWithTranslation', {
  enumerable: true,
  get: function get() {
    return _appWithTranslation.appWithTranslation;
  },
});
Object.defineProperty(exports, 'withInternals', {
  enumerable: true,
  get: function get() {
    return _withInternals.withInternals;
  },
});

var _appWithTranslation = require('./app-with-translation');

var _withInternals = require('./with-internals');
