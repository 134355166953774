import _ from 'lodash';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

export const dispensaryMenuSections = gql`
  query GetMenuSections($dispensaryId: String!) {
    getMenuSections(dispensaryId: $dispensaryId) {
      id
      brandId
      category
      label
      linkLabel
      position
      subcategory
      sectionType
      products
    }
  }
`;

export const getQuery = () => dispensaryMenuSections;
export const getVariables = (dispensaryId) => ({ dispensaryId });

export const getDispensaryMenuSections = async (apolloClient, props) =>
  apolloClient.query({
    query: getQuery(),
    variables: getVariables(props.params.cName),
    skip: !props.params.cName,
  });

export default function useDispensaryMenuSections() {
  const { dispensary } = useDispensary();

  const { data, ...apolloValues } = useQuery(dispensaryMenuSections, {
    skip: !dispensary?.id,
    variables: { dispensaryId: dispensary?.id },
  });

  const menuSections = _.sortBy(data?.getMenuSections, `position`);
  return { menuSections, ...apolloValues };
}
