import React from 'react';
import Head from 'next/head';
import { useObserver } from 'mobx-react-lite';

import useDispensary from 'src/dispensary/hooks/use-dispensary';

// Referenced from: https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs

import {
  FaviconAppleTouchIcon,
  Favicon16,
  Favicon32,
  FaviconManifest,
  Favicon,
  FaviconThemeColor,
} from 'src/constants/favicons';

type FaviconsProps = {
  isKioskOrStoreFront: boolean;
  variant: string; // TODO: type possible values, see meta-tags.jsx
};

export default function Favicons(props: FaviconsProps): JSX.Element {
  const { dispensary } = useDispensary();
  const logoImage: string | undefined = useObserver(() => dispensary?.logoImage);

  const { variant, isKioskOrStoreFront } = props;

  // Lookup each favicon value for the current variant, fall back to `default` key when missing
  const faviconManifest = FaviconManifest[variant] ?? FaviconManifest.default;
  const faviconThemeColor = FaviconThemeColor[variant] ?? FaviconThemeColor.default;
  let faviconAppleTouchIcon = FaviconAppleTouchIcon[variant] ?? FaviconAppleTouchIcon.default;
  let favicon16 = Favicon16[variant] ?? Favicon16.default;
  let favicon32 = Favicon32[variant] ?? Favicon32.default;
  let favicon = Favicon[variant] ?? Favicon.default;
  

  if (isKioskOrStoreFront && logoImage) {
    faviconAppleTouchIcon = `${logoImage}?w=180&pad=20&fm=png`
    favicon16 = `${logoImage}?w=16&fm=png`
    favicon32 = `${logoImage}?w=32&fm=png`
    favicon = `${logoImage}?w=32&fm=png`
  }

  return (
    <Head>
      <link key='manifest' rel='manifest' href={faviconManifest} />
      <link key='appleTouchIcon' rel='apple-touch-icon' sizes='180x180' href={faviconAppleTouchIcon} />
      <link key='favicon16' rel='icon' type='image/png' sizes='16x16' href={favicon16} />
      <link key='favicon32' rel='icon' type='image/png' sizes='32x32' href={favicon32} />
      <link rel='icon' type='image/png' href={favicon} />
      <meta key='themeColor' name='theme-color' content={faviconThemeColor} />
    </Head>
  );
}
