import styled from 'styled-components';
import { borderRadius, boxShadow, height, minHeight, position, space, width } from 'styled-system';

export default styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
  ${boxShadow}
  ${borderRadius}
  ${height}
  ${minHeight}
  ${position}
  ${space}
  ${width}
`;
