import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Imgix from 'react-imgix';
import _ from 'lodash';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import { DefaultImages } from 'shared/constants';
import { imageToUse } from 'shared/helpers/products';

const LEGACY_STRING = 'https://s3-us-west-2.amazonaws.com/dutchie-images/';
const IMGIX_SOURCE = 'https://images.dutchie.com/';

export default function ProductImage({ product, onLoaded = _.noop, ...imageProps }) {
  const defaultImage = _.get(DefaultImages, product?.type, DefaultImages.Flower);
  const [imageHasError, setImageHasError] = useState(false);
  let resolvedImgSrc = imageToUse(product || {});
  // Replace S3 with imgix.
  if (_.includes(resolvedImgSrc, LEGACY_STRING)) {
    resolvedImgSrc = _.replace(resolvedImgSrc, LEGACY_STRING, IMGIX_SOURCE);
  }
  // Replace http refers with https (mainly for cloudinary)
  if (_.includes(resolvedImgSrc, `http://`)) {
    resolvedImgSrc = _.replace(resolvedImgSrc, `http://`, `https://`);
  }
  const [imageSrc, setImageSrc] = useState(resolvedImgSrc);
  const dispensaryImgData = _.pickBy(product?.imgixSettings?.productCard, _.identity);

  const imageDefaults = {
    type: `img`,
    imgixParams: { fit: `fill`, fill: `solid`, fillColor: `#fff`, ...dispensaryImgData },
  };

  const onImageError = () => {
    setImageHasError(true);
    console.log(`Image failed to load.`, {
      imgSrc: imageSrc,
      product: { id: product?._id },
    });
  };

  const onMounted = useCallback((img) => {
    img.onerror = onImageError;
    if (_.isFunction(onLoaded)) {
      img.onload = () => {
        onLoaded();
      };
    }
  }, []);

  useEffect(() => {
    if (imageHasError) {
      setImageSrc(defaultImage);
    }
  }, [imageHasError, defaultImage]);

  return (
    <LazyLoad
      {...imageDefaults}
      {...imageProps}
      onMounted={onMounted}
      htmlAttributes={{ alt: '' }}
      src={imageSrc || defaultImage}
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes',
      }}
      className={`${imageProps.className} lazyload`}
    />
  );
}

const LazyLoad = styled(Imgix)`
  object-fit: contain;
  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
  &.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
  }
`;
