import _reduce from "lodash/reduce";
import _reduceRight from "lodash/reduceRight";
import _flatMap from "lodash/flatMap";
import _uniqBy from "lodash/uniqBy";
// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import { getAvailableQuantity, determineWinningCombo, setSatisfiersToWinningCombo } from './common';
import getTotalProductCombinations from './get-total-product-combinations';
import { orderProductsForDiscountPrecedence } from '../utilities';

var checkTotalSpend = function checkTotalSpend(_ref) {
  var bogoCalcsData = _ref.bogoCalcsData,
      conditions = _ref.conditions,
      discountBundle = _ref.discountBundle,
      items = _ref.items,
      itemToConsiderLast = _ref.itemToConsiderLast,
      itemsForAPrice = _ref.itemsForAPrice,
      logicOperator = _ref.logicOperator,
      pendingRewardsSatisfiers = _ref.pendingRewardsSatisfiers,
      specialsSettings = _ref.specialsSettings,
      totalQuantity = _ref.totalQuantity,
      totalSpend = _ref.totalSpend,
      specialId = _ref.specialId;
  var spendTarget = totalSpend.minimumSpend,
      spendTargetMax = totalSpend.maximumSpend,
      spendOperator = totalSpend.spendOperator;
  var advancedReward = (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.enabled) || (itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.enabled);
  var accumulateToMaximum = (totalSpend === null || totalSpend === void 0 ? void 0 : totalSpend.spendOperator) === 'greaterThan' && advancedReward;
  var satisfied = false;
  var satisfiers = {};
  var uniqueConditions = conditions.length > 0 ? _uniqBy(_flatMap(conditions, 'eligibleItems'), 'key') : items;
  var eligibleItems = orderProductsForDiscountPrecedence(uniqueConditions, specialsSettings, itemsForAPrice.enabled ? 'rewards' : 'conditions');

  var availableEligibleItems = _reduceRight(eligibleItems, function (accumulator, item) {
    var _item$mixAndMatch;

    var basePrice = Big(((_item$mixAndMatch = item.mixAndMatch) === null || _item$mixAndMatch === void 0 ? void 0 : _item$mixAndMatch.adjustedBasePrice) || (item === null || item === void 0 ? void 0 : item.basePrice) || 0); // If item is weighted

    if (basePrice.gt(0)) {
      var availableQuantity = getAvailableQuantity({
        item: item,
        bogoCalcsData: bogoCalcsData,
        pendingConditionsSatisfiers: satisfiers,
        pendingRewardsSatisfiers: pendingRewardsSatisfiers,
        excludeDefeatedRewardsSatisfiers: itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.enabled,
        specialId: specialId
      });

      if (availableQuantity > 0) {
        accumulator.push({
          item: item,
          key: item.key,
          quantity: availableQuantity,
          basePrice: Number(basePrice.times(100))
        });
      }
    }

    return accumulator;
  }, []);

  if (availableEligibleItems.length < 1) {
    return {
      satisfied: satisfied,
      satisfiers: satisfiers
    };
  }

  var spendTargetInCents = Number(spendTarget * 100); // NOTE: total spend with a between operator doesn't matter here

  var combinations = getTotalProductCombinations({
    availableEligibleItems: availableEligibleItems,
    comparisonOperator: spendOperator,
    numericTarget: spendTargetInCents,
    totalQuantity: totalQuantity,
    propertyName: 'basePrice',
    accumulateToMaximum: accumulateToMaximum
  }); // total spend with a between operator will always win out here

  if (spendOperator === 'between') {
    var combinedTotal = _reduce(availableEligibleItems, function (prev, curr) {
      return +prev + +curr.item.basePrice * curr.quantity;
    }, 0);

    var isTotalBetween = combinedTotal >= spendTarget && combinedTotal <= spendTargetMax;

    if (isTotalBetween) {
      satisfiers = _reduce(availableEligibleItems, function (result, item) {
        result[item.key] = {
          item: item.item,
          quantity: item.quantity
        };
        return result;
      }, {});
      return {
        satisfied: true,
        satisfiers: satisfiers
      };
    }
  }

  if (combinations.length < 1) {
    return {
      satisfied: satisfied,
      satisfiers: satisfiers
    };
  }

  var winningCombination = determineWinningCombo(combinations, itemToConsiderLast, conditions, logicOperator);

  if (winningCombination) {
    satisfied = true;
    satisfiers = setSatisfiersToWinningCombo(winningCombination);
  }

  return {
    satisfied: satisfied,
    satisfiers: satisfiers
  };
};

export default checkTotalSpend;