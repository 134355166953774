import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ActiveModalsMap } from 'src/state/modals';

type UseCloseOnRouteChangeProps = {
  modalName: string;
  activeModals: ActiveModalsMap;
};

export function useCloseOnRouteChange({ modalName, activeModals }: UseCloseOnRouteChangeProps): void {
  const router = useRouter();

  const handleRouteChange = useCallback(() => {
    if (activeModals.has(modalName)) {
      activeModals.delete(modalName);
    }
  }, [activeModals, modalName]);

  useEffect(() => {
    router.events.on(`routeChangeStart`, handleRouteChange);

    return () => {
      router.events.off(`routeChangeStart`, handleRouteChange);
    };
  }, [router, handleRouteChange]);
}
