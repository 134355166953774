/* eslint-disable max-len */
import React from 'react';

function SvgFacebook(props) {
  return (
    <svg width={23} height={23} fill='currentColor' {...props}>
      <path
        fillRule='evenodd'
        d='M22.291 11.214C22.291 5.02 17.301 0 11.146 0 4.99 0 0 5.02 0 11.214 0 16.81 4.076 21.45 9.404 22.29v-7.836h-2.83v-3.241h2.83v-2.47c0-2.811 1.664-4.364 4.21-4.364 1.22 0 2.495.22 2.495.22v2.759h-1.406c-1.384 0-1.816.864-1.816 1.751v2.104h3.091l-.494 3.241h-2.597v7.836c5.328-.841 9.404-5.48 9.404-11.077'
        {...props}
      />
    </svg>
  );
}

export default SvgFacebook;
