import React from 'react';
import styled, { css } from 'styled-components';

import useTranslation from 'src/hooks/use-translation';

import { Button } from 'components/core';
import { BottomDrawer } from 'components/core/bottom-drawer';

import { CurrentlySelected, StoreOptions } from './components';
import { SelectStoreBottomDrawerProps } from '../select-store.types';

export function SelectStoreBottomDrawer({
  dispensaries,
  hasInteracted,
  selectedStore,
  handleClearSelection,
  handleSelect,
  handleSaveClick,
  onClose,
  open,
}: SelectStoreBottomDrawerProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledBottomDrawer
      open={open}
      onClose={onClose}
      max-height={600}
      hasInteracted={hasInteracted}
      heading={t('select-store', 'Select a Store')}
    >
      <Content>
        <CurrentlySelected selectedStore={selectedStore} handleClearSelection={handleClearSelection} />
        <StoreOptions dispensaries={dispensaries} selectedStore={selectedStore} handleSelect={handleSelect} />
        {hasInteracted && (
          <ElevatedSave>
            <SaveButton size='medium' onClick={handleSaveClick}>
              {t('save', 'save')}
            </SaveButton>
          </ElevatedSave>
        )}
      </Content>
    </StyledBottomDrawer>
  );
}

const ElevatedSave = styled.div`
  flex-shrink: 0;
  text-align: center;
  margin: 0px -25px;
  height: 64px;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.grey[90]};
    box-shadow: 0px -3px 1px ${theme.colors.sectionShadow};
  `}
`;

const SaveButton = styled(Button)`
  text-transform: uppercase;
  padding: 14px 27px;
  width: 86%;
  margin-top: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  max-height: 469px;
`;

const StyledBottomDrawer = styled(BottomDrawer)`
  ${({ hasInteracted }) =>
    !hasInteracted &&
    css`
      .makeStyles-paper-2 {
        padding-bottom: 0px !important;
      }
    `}
`;
