import React from 'react';
import styled from 'styled-components';
import { display } from 'styled-system';

import Button from 'components/core/button';

const CloseButton = styled((props) => <Button {...props} />)`
  ${display}
  position: ${({ position }) => position || `absolute`};
  height: 46px;
  opacity: ${({ color }) => (color ? 1 : 0.7)};
  top: ${({ top }) => top || `8px`};
  right: ${({ right }) => right || `6px`};
  width: 46px;
  background-color: ${({ backgroundColor }) => backgroundColor || `transparent`};
  &:before {
    height: 3px;
    width: 50%;
  }
  &:after {
    height: 50%;
    width: 3px;
  }
  &:before,
  &:after {
    background-color: ${({ theme, color }) => color || theme.colors.grey[55]};
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform-origin: center center;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  z-index: 10;
`;

export default CloseButton;
