import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import SectionHeader from '../order-receipt-section-header';
import DetailsTable from '../order-receipt-details-table';

const OrderReceiptDetailsMobile = ({ order }) => {
  const { t } = useTranslation();
  const { orderId } = order;

  return (
    <Container>
      <SectionHeader>
        <span>{t('common.details', 'Details')}</span>

        <Quantity>
          {t('common.order', 'order #:')} &nbsp;
          <span>{orderId}</span>
        </Quantity>
      </SectionHeader>

      <TableContainer>
        <DetailsTable order={order} />
      </TableContainer>
    </Container>
  );
};

const Container = styled.div``;

const TableContainer = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.blueGrey[90]}`};
  margin: 9px 25px 0 25px;
  padding-bottom: 10px;
`;

const Quantity = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[45]};

  > span {
    font-weight: normal;
    color: ${({ theme }) => theme.colors.grey[35]};
  }
`;

export default OrderReceiptDetailsMobile;
