import useViewportVisibility from 'src/hooks/use-viewport-visibility';
import { useSimilarItems, Scenarios, GetRecommendationsFallback } from 'src/recommendations';
import { ProductCarouselProps } from 'src/components/product-carousel';
import { usePrependWithSponsoredProducts } from 'src/utils/ads/hooks/sponsored-products';
import { SponsoredProductsInventoryId } from 'types/graphql';
import { useShouldSkipAds, useShouldSkipLegacyPersonalization } from 'src/dispensary/hooks/use-skip-ads-rules';
import { useExperiment } from 'hooks/use-experiment';

type UseRelatedItemsCarouselParams = {
  category: string;
  productId: string;
};

type UseRelatedItemsCarouselReturn = Omit<ProductCarouselProps, 'heading'>;

export function useRelatedItemsCarousel({
  category,
  productId,
}: UseRelatedItemsCarouselParams): UseRelatedItemsCarouselReturn {
  const carouselExperiment = useExperiment('growth.ads.show_sponsored_products_in_carousels.experiment');

  const { value: flagValue } = carouselExperiment;
  const validExperimentAdsPlacements = new Set(['pdp', 'both']);
  const validExperimentAdsPlacement = validExperimentAdsPlacements.has(flagValue);

  const viewportVisibility = useViewportVisibility();
  const skipAds = useShouldSkipAds() || !validExperimentAdsPlacement;
  const skipPersonalization = useShouldSkipLegacyPersonalization();

  const getProductsFallback: GetRecommendationsFallback = (getProducts) =>
    getProducts({
      sortBy: `popularSortIdx`,
      types: [category],
    });

  const { data: organicRecommendations, loading: organicLoading } = useSimilarItems(
    getProductsFallback,
    productId,
    Scenarios.productDetailsPage1,
    {
      skip: skipPersonalization || !viewportVisibility.hasBeenVisible,
      limit: 25,
    }
  );

  const { products: sponsoredAndOrganicProducts, loading: sponsoredLoading } = usePrependWithSponsoredProducts({
    skip: skipAds || !productId,
    products: organicRecommendations ?? [],
    loading: organicLoading,
    error: undefined,
    limit: 5,
    inventoryId: SponsoredProductsInventoryId.pdpProductAuc,
    searchQuery: undefined,
    productId,
  });

  const hideSponsoredTags = skipAds || !sponsoredAndOrganicProducts.some((product) => !!product.adTrackers);

  return {
    products: sponsoredAndOrganicProducts,
    loading: organicLoading || sponsoredLoading,
    viewportVisibility,
    placement: 'product-detail-page-related-items-carousel',
    hideSponsoredTags,
    carouselExperiment,
  };
}
