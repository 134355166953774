import { getRangeForQuantityDropdown, optionValMap } from 'shared/helpers/products';
import { Product } from 'utils/helpers/product';

type Prices = {
  basePrice: number;
  discountPrice?: number;
};

type ProductAndWeightArgs = {
  product: Product;
  weightOption?: string | null;
  defaultLimit?: number;
};

type NormalizeWeightArgs = {
  product: Product;
  weightOption: string;
};

const gramToOunceMap = {
  '3.5g': '1/8oz',
  '7g': '1/4oz',
  '14g': '1/2oz',
  '28g': '1oz',
};

const ounceToGramMap = {
  '1/8oz': '3.5g',
  '1/4oz': '7g',
  '1/2oz': '14g',
  '1oz': '28g',
};

const weightedCategories = [
  'Flower',
  'FLOWER',
  'Pre-Rolls',
  'PRE-ROLLS',
  'Vaporizers',
  'VAPORIZER',
  'Concentrates',
  'Concentrate',
  'CONCENTRATE',
];

const DEFAULT_FOR_FLOWER = '1/8oz';
const CATEGORY_FLOWER = [`Flower`, `FLOWER`, `flower`];

const DEFAULT_FOR_CONCENTRATES = '1g';
const CATERGORY_CONCENTRATES = [`Concentrate`, `Concentrates`, `CONCENTRATE`, `concententrate`];

export const getQuantityRangeForBuyingOptions = ({
  product,
  weightOption,
  defaultLimit = 10,
}: ProductAndWeightArgs): number[] =>
  getRangeForQuantityDropdown({ product, option: weightOption, quantityInCart: 0, isKiosk: false, defaultLimit });

export function getPriceForBuyingOption({ product, weightOption = null }: ProductAndWeightArgs): Prices {
  // TODO return discountPrice as well-- will need to be resolved in gql layer
  const { Prices, Options } = product;

  if (!Prices || !Options) {
    throw new Error('Invalid data-- buying option needs price and weight option!');
  }

  if (!weightOption && typeof Prices[0] === `number`) {
    return { basePrice: Prices[0] };
  }

  if (!weightOption) {
    throw new Error('Not able to find pricing information for this product');
  }

  const productHasOption = Options.includes(weightOption);
  const indexOfOption = productHasOption
    ? Options.indexOf(weightOption)
    : Options.indexOf(ounceToGramMap[weightOption]);

  const basePrice = Prices[indexOfOption];

  if (!basePrice) {
    throw new Error('Not able to find pricing information for this product');
  }

  return { basePrice };
}

export function aggregateWeightOptions(products: Product[]): string[] {
  const allOptions = products.map((product) => product.Options).flat();
  const mappedWeightOptions = allOptions
    .filter((option) => typeof option === 'string')
    .map((option: string): string => (option && gramToOunceMap[option] ? gramToOunceMap[option] : option));

  const weightOptionsSet = new Set<string>(mappedWeightOptions);

  return sortWeightOptions(Array.from(weightOptionsSet));
}

export const sortWeightOptions = (weights: string[]): string[] =>
  weights.sort((a, b) => optionValMap[a] - optionValMap[b]);

export function filterOptionsByWeightSelected(products: Product[], selectedWeight: string | null): Product[] {
  if (!selectedWeight) {
    return products;
  }

  return products.filter(
    (product) =>
      product.Options &&
      (product.Options.includes(selectedWeight) || product.Options.includes(ounceToGramMap[selectedWeight]))
  );
}

export function getInitialWeight(category: string, weights: string[]): string {
  if (CATEGORY_FLOWER.includes(category) && weights.includes(DEFAULT_FOR_FLOWER)) {
    return DEFAULT_FOR_FLOWER;
  }
  if (CATERGORY_CONCENTRATES.includes(category) && weights.includes(DEFAULT_FOR_CONCENTRATES)) {
    return DEFAULT_FOR_CONCENTRATES;
  }
  return weights[0];
}

export function getWeightForCart({ product, weightOption }: ProductAndWeightArgs): string | undefined {
  if (!weightOption && product.Options && product.Options[0]) {
    return product.Options[0];
  }
  return weightOption ? normalizeWeight({ product, weightOption }) : undefined;
}

export const normalizeWeight = ({ product, weightOption }: NormalizeWeightArgs): string =>
  product.Options?.includes(weightOption) ? weightOption : ounceToGramMap[weightOption];

export const isWeightedCategory = (category: string): boolean => weightedCategories.includes(category);
