import { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useDutchiePay } from 'src/payments/hooks';
import useUser from 'shared/hooks/use-user';
import useStores from 'shared/hooks/use-stores';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { tracker } from '..';

export const useViewedCheckoutMonitor = (): void => {
  const { isEnrolledDutchiePay, isDutchiePayEnabledForDispo, loading: dutchiePayLoading } = useDutchiePay();
  const user = useUser();
  const userId = useObserver(() => user.id);
  const customerId = userId;
  const loggedIn = useObserver(() => user.isLoggedIn);
  const flags = useFlags();
  const isGoogleLoginEnabled = flags[`growth.ecomm.sign-in-with-google.rollout`] ?? false;
  const isSsoUser = useObserver(() => user.isSsoUser);
  const isLoggedInViaExternalProvider = isGoogleLoginEnabled && isSsoUser;
  const userLoading = useObserver(() => user.loading);
  const { Cart } = useStores();
  const checkoutToken = useObserver(() => Cart.checkoutToken);

  const { dispensary } = Cart.order ?? {};
  const { id: dispensaryId, name: dispensaryName } = dispensary ?? {};

  useEffect(() => {
    if (userId === undefined || dispensary === undefined || Cart.order === undefined) {
      return;
    }

    tracker.setContext({
      activeUser: {
        id: userId,
      },
      activeDispensary: dispensary,
      activeCart: { subtotal: Cart.costBreakdown.subtotal, ...Cart.order.cart },
      activeOrder: Cart.order,
      checkoutToken: Cart.checkoutToken,
    });
  }, [userId, dispensary, Cart.checkoutToken, Cart.order, Cart.costBreakdown.subtotal]);

  useEffect(() => {
    const payloadProps = [
      checkoutToken,
      dispensaryId,
      dispensaryName,
      isDutchiePayEnabledForDispo,
      isEnrolledDutchiePay,
    ];

    if (payloadProps.some((value) => value === undefined)) {
      return;
    }

    tracker.viewedCheckout({
      checkoutToken,
      dispensaryId,
      dispensaryName,
      isDutchiePayEnabledForDispo,
      isEnrolledDutchiePay,
    });
  }, [checkoutToken, dispensaryId, dispensaryName, isDutchiePayEnabledForDispo, isEnrolledDutchiePay]);

  useEffect(() => {
    const payloadProps = [
      checkoutToken,
      dispensaryId,
      dispensaryName,
      isDutchiePayEnabledForDispo,
      isEnrolledDutchiePay,
      customerId,
      loggedIn,
    ];

    if (payloadProps.some((value) => value === undefined)) {
      return;
    }

    if (loggedIn && !dutchiePayLoading && userLoading === false && checkoutToken && !isLoggedInViaExternalProvider) {
      tracker.checkoutSession({
        checkoutToken,
        dispensaryId,
        dispensaryName,
        isDutchiePayEnabledForDispo,
        isEnrolledDutchiePay,
        customerId,
        loggedIn,
      });
    }
  }, [
    checkoutToken,
    dispensaryId,
    dispensaryName,
    isDutchiePayEnabledForDispo,
    isEnrolledDutchiePay,
    customerId,
    loggedIn,
    dutchiePayLoading,
    userLoading,
    isLoggedInViaExternalProvider,
  ]);
};
