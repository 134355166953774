'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
Object.defineProperty(exports, 'wrapRouter', {
  enumerable: true,
  get: function get() {
    return _wrapRouter.wrapRouter;
  },
});

var _wrapRouter = require('./wrap-router');
