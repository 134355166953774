import React from 'react';
import styled, { css } from 'styled-components';

import BagIcon from 'assets/bag-icon';
import DeliveryIcon from 'assets/delivery-icon-2';
import GreenCheckmarkBadge from 'assets/green-check-mark-badge';
import { BottomDrawer, BottomDrawerProps } from 'components/core/bottom-drawer';
import useTranslation from 'hooks/use-translation';
import { Clickable } from 'shared/components';
import { Button } from 'components/core';

export type OrderTypeBottomDrawerProps = Omit<BottomDrawerProps, 'children'> & {
  onDeliveryClick: () => void;
  onPickupClick: () => void;
  onSave: () => void;
  deliverySelected: boolean;
  pickupSelected: boolean;
};

export function OrderTypeBottomDrawer({
  deliverySelected,
  onClose,
  onDeliveryClick,
  onPickupClick,
  onSave,
  open,
  pickupSelected,
}: OrderTypeBottomDrawerProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <BottomDrawer open={open} onClose={onClose} heading={t('order-type', 'Order Type')}>
      <ButtonsContainer>
        <OrderTypeButton
          aria-label={
            deliverySelected ? t('unselect-delivery', 'Unselect delivery') : t('select-delivery', 'Select delivery')
          }
          selected={deliverySelected}
          onClick={onDeliveryClick}
        >
          {deliverySelected && <StyledCheckmarkBadge alt={t('delivery-selected', 'Delivery is selected')} />}
          <DeliveryIcon alt='' />
          <OrderTypeCopy>{t('delivery', 'Delivery')}</OrderTypeCopy>
        </OrderTypeButton>
        <OrderTypeButton
          aria-label={pickupSelected ? t('unselect-pickup', 'Unselect pickup') : t('select-pickup', 'Select pickup')}
          selected={pickupSelected}
          onClick={onPickupClick}
        >
          {pickupSelected && <StyledCheckmarkBadge alt={t('pickup-selected', 'Pickup is selected')} />}
          <BagIcon alt='' />
          <OrderTypeCopy>{t('pickup', 'Pickup')}</OrderTypeCopy>
        </OrderTypeButton>
      </ButtonsContainer>
      <Button fontSize={13} size='large' onClick={onSave}>
        {t('save', 'Save')}
      </Button>
    </BottomDrawer>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  margin: 25px 0;
`;

const OrderTypeButton = styled(Clickable)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey[90]};
  padding: 23px 0;
  :first-of-type {
    margin-right: 23px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[95]};
    `}
`;

const StyledCheckmarkBadge = styled(GreenCheckmarkBadge)`
  position: absolute;
  right: 6px;
  top: 6px;
`;

const OrderTypeCopy = styled.span`
  margin-top: 11px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[35]};
`;
