import useViewportVisibility from 'src/hooks/use-viewport-visibility';
import { ProductCarouselProps } from 'src/components/product-carousel';
import { useRecentlyViewedProductsV2Query } from 'types/graphql';
import { ApolloError } from 'apollo-client';
import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';

type UseRecentlyViewedCarouselParams = {
  dispensaryId: string;
  userId: string;
};

type UseRecentlyViewedCarouselReturn = Omit<ProductCarouselProps, 'heading'>;

export function useRecentlyViewedCarousel({
  dispensaryId,
}: UseRecentlyViewedCarouselParams): UseRecentlyViewedCarouselReturn {
  const viewportVisibility = useViewportVisibility();

  const { data: productIdsData, loading: productIdsLoading, error: productIdsError } = useRecentlyViewedProductsV2Query(
    {
      variables: {
        dispensaryId,
      },
      fetchPolicy: `network-only`,
      /*
       * The fetchPolicy of "network-only" is used in order to force the query to always be called due to the current user
       * being checked on the backend. Otherwise the previously cached products will appear.
       */
    }
  );

  const productIds = productIdsData?.getRecentlyViewedProductsV2?.map((product) => product.id) ?? [];

  const { products, loading: productsLoading } = useDispensaryProducts({
    filters: {
      productIds,
    },
    skip: productIdsLoading || productIdsError instanceof ApolloError || !productIds.length,
  });

  return {
    products,
    loading: productIdsLoading || productsLoading,
    viewportVisibility,
  };
}
