import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

import useTranslation from 'hooks/use-translation';
import useOrderType from 'hooks/use-order-type';

import { ModalExitIcon } from 'components/core';
import { bold, paragraph } from 'components/core/typography';
import { Clickable } from 'shared/components';
import { WeightTiles } from '../components/weight-tiles';
import { OptionsList } from '../components/options/options-list';
import { BuyingOptionsProps } from '../buying-options.types';
import { useWeightedBuyingOptions } from '../hooks/use-weighted-buying-options';

export function BuyingOptionsDrawer({ buyingOptions, product, onClose, open }: BuyingOptionsProps): JSX.Element {
  const { t } = useTranslation();
  const { getOrderType } = useOrderType();
  const { category } = product;

  const {
    filteredBuyingOptions,
    handleWeightChange,
    isWeighted: showWeightTiles,
    selectedWeight,
    weights,
  } = useWeightedBuyingOptions({
    category,
    buyingOptions,
  });

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Clickable onClick={onClose}>
        <ModalExitIcon height='20px' />
      </Clickable>
      <Header>
        {typeof product.image === `string` && <img src={product.image} alt={product.name} />}
        <CopyContainer>
          <Subheading>{product.brand?.name}</Subheading>
          <Heading>{product.name}</Heading>
        </CopyContainer>
      </Header>
      <Content>
        {showWeightTiles && (
          <>
            <Label>{t('common.weight', 'Weight')}</Label>
            <WeightTileContainer>
              <WeightTiles
                handleWeightChange={handleWeightChange}
                selectedWeight={selectedWeight}
                weightOptions={weights}
              />
            </WeightTileContainer>
          </>
        )}
        <div>
          <InfoContainer>
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions  */}
            <Label data-testid={`${buyingOptions.length}-buying-options`}>
              {t('buying-options.locationsAvailable', {
                defaultValue: `{{count}} buying option`,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                defaultValue_plural: `{{count}} buying options`,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                defaultValue_0: '0 buying options',
                count: buyingOptions.length,
              })}
            </Label>
            <SortedByContainer>
              <SortedByCopy>{t('common.sort', 'Sorted by:')}</SortedByCopy>
              <SortTypeCopy>
                {getOrderType() === 'delivery'
                  ? t('sortedBy.deliveryTime', 'Delivery time')
                  : t('sortedBy.distance', 'Distance')}
              </SortTypeCopy>
            </SortedByContainer>
          </InfoContainer>
          <OptionsList options={filteredBuyingOptions} selectedWeight={selectedWeight} />
        </div>
      </Content>
    </Dialog>
  );
}

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75%;
  && {
    place-items: flex-start;
  }
`;

const Content = styled.div`
  padding: 25px 25px 0;
`;

const Header = styled.div`
  display: flex;
  place-content: flex-start;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.038);
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  padding: 12px 25px 12px 18px;
  img {
    max-height: 60px;
    max-width: 60px;
    width: 100%;
    height: auto;
    margin-right: 6px;
  }
  div {
    display: flex;
    place-items: center;
    place-content: center;
  }
`;

const Heading = styled.h3`
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const Subheading = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.grey[45]};
`;

const Label = styled.p`
  ${bold}
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 11px;
  line-height: 11px;
  margin-top: 2px;
  text-transform: uppercase;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const SortedByContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SortedByCopy = styled.span`
  ${paragraph}
  color: ${({ theme }) => theme.colors.grey[45]};
  margin-right: 5px;
`;

const SortTypeCopy = styled.p`
  ${bold}
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 13px;
`;

const WeightTileContainer = styled.div`
  display: flex;
  margin: 15px -25px 20px;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ::before {
    content: '';
    padding-left: 25px;
  }
  ::after {
    content: '';
    padding-right: 25px;
  }
`;
