import React from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';

import { useDutchiePay } from 'src/payments/hooks';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { ARIA_ROLES } from 'shared/constants';

type WhiteLabelLogoProps = {
  variant: 'dark' | 'light';
  height?: string;
};

export const WhiteLabelLogo = ({ variant, height }: WhiteLabelLogoProps): JSX.Element => {
  const {
    whiteLabelConfig: {
      brandedDutchiePayLogoDark: darkLogoFromHook,
      brandedDutchiePayLogoLight: lightLogoFromHook,
      brandedDutchiePayName: nameFromHook,
    },
  } = useDutchiePay();

  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const {
    brandedDutchiePayLogoDark: darkLogoFromStore,
    brandedDutchiePayLogoLight: lightLogoFromStore,
    brandedDutchiePayName: nameFromStore,
  } = useObserver(() => DutchiePayEnrollment.dispensary);

  const logoDark = darkLogoFromStore || darkLogoFromHook;
  const logoLight = lightLogoFromStore || lightLogoFromHook;
  const name = nameFromStore || nameFromHook;
  const imgSrc = variant === 'light' ? logoLight : logoDark;

  return <Logo src={imgSrc} alt={`${String(name)} Logo`} role={ARIA_ROLES.IMG} height={height} />;
};

const Logo = styled.img`
  max-height: 25px;
  max-width: 125px;
  object-fit: contain;
`;
