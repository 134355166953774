import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import EmptyCartImage from 'assets/empty-cart-image';
import { TextButton } from 'components/core';

import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';

const CartEmptyState = observer(({ onClose, onShop }) => {
  const { t } = useTranslation();
  const { isMarketplace } = useUI();

  return (
    <EmptyCart>
      <EmptyCartImage />
      <h1>{t('common.yourCartIsEmpty', 'Your Cart is Empty')}</h1>

      <TextButton onClick={isMarketplace ? onShop : onClose}>
        <h2>{t('common.addSomeProductsBang', 'Add some products!')}</h2>
      </TextButton>
    </EmptyCart>
  );
});

export default CartEmptyState;

const EmptyCart = styled.div`
  background: ${({ theme }) => theme.colors.primaryGrey};
  border-radius: 25px;
  height: 375px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.grey[30]};
  margin: 20px;
  padding: 80px 0px 78px 0px;

  h1 {
    font-size: 26px;
    line-height: 1.2;
    margin-top: 35px;
  }

  h2 {
    font-size: 14px;
    color: ${({ theme }) => theme.customized.colors.buttonsLinks};
    margin-top: 7px;
    cursor: pointer;
  }

  @media (min-width: 600px) {
    margin: 40px 50px;
    width: 450px;
  }
`;
