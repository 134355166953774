import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["children"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';

function DividerWithText(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx(Flex, _extends({
    alignItems: "center"
  }, props), __jsx(Border, null), __jsx(Content, null, children), __jsx(Border, null));
}

export default DividerWithText;
var Border = styled.div.withConfig({
  displayName: "divider-with-text__Border",
  componentId: "upxy5-0"
})(["border-bottom:", ";width:100%;"], function (_ref2) {
  var _theme$colors;

  var theme = _ref2.theme;
  return "1px solid ".concat((_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.greyBorder);
});
var Content = styled.div.withConfig({
  displayName: "divider-with-text__Content",
  componentId: "upxy5-1"
})(["padding:0 19px 0 19px;color:", ";font-size:11px;line-height:16px;font-weight:bold;"], function (_ref3) {
  var _theme$colors2;

  var theme = _ref3.theme;
  return (_theme$colors2 = theme.colors) === null || _theme$colors2 === void 0 ? void 0 : _theme$colors2.grey[60];
});