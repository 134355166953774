import { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'src/hooks/use-user';
import useUI from 'src/hooks/use-ui';
import { useLoyaltyModal } from 'src/components/modals/loyalty';

export const LoyaltyAccountDetection = ({ children }): JSX.Element => {
  const { isEmbedded, isStoreFront, isEmbeddedCarousel } = useUI();
  const isEmbeddedOrStoreFront = (isEmbedded || isStoreFront) && !isEmbeddedCarousel;
  const User = useUser();
  const isUserLoading = useObserver(() => User.loading);
  const isLoggedIn = useObserver(() => User.isLoggedIn);
  const isUserLoadedAndLoggedIn = !isUserLoading && isLoggedIn;
  const phone = useObserver(() => User.phone);
  const { dispensary } = useDispensary();
  const isAlpineEnabled = dispensary?.consumerDispensaryIntegrations?.alpineiq?.enabled;
  const dispensaryId = dispensary?.id;
  const pin = User.getLoyaltyPin(dispensaryId);
  const flags = useFlags();
  const isShowLoyaltyFlagEnabled = flags['growth.ecomm.show-loyalty-rewards.rollout'] ?? false;
  const { open: showLoyaltyModal } = useLoyaltyModal();

  useEffect(() => {
    if (
      isEmbeddedOrStoreFront &&
      isUserLoadedAndLoggedIn &&
      isAlpineEnabled &&
      dispensaryId &&
      !pin &&
      isShowLoyaltyFlagEnabled &&
      phone
    ) {
      void showLoyaltyModal();
    }
  }, [
    dispensaryId,
    isAlpineEnabled,
    isEmbeddedOrStoreFront,
    isShowLoyaltyFlagEnabled,
    isUserLoadedAndLoggedIn,
    phone,
    pin,
    showLoyaltyModal,
  ]);

  return children;
};
