import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { TextButton } from 'components/core';
import { bold } from 'components/core/typography';
import SvgGreenCheck from 'src/assets/green-check';

import { DispensaryInfo } from '../../components/dispensary-info';
import { CurrentlySelectedProps } from '../../select-store.types';

export function CurrentlySelected({ selectedStore, handleClearSelection }: CurrentlySelectedProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <HeaderContainer>
        <Header>{t('currently-selected', 'Currently Selected')}</Header>
        {selectedStore && <StyledTextButton onClick={handleClearSelection}>{t('clear', 'Clear')}</StyledTextButton>}
      </HeaderContainer>
      {selectedStore ? (
        <SelectionContainer>
          <DispensaryInfo dispensary={selectedStore} />
        </SelectionContainer>
      ) : (
        <NoSelectionContainer>
          <StyledGreenCheck width={14} height={11.45} viewBox='0 0 10 10' />
          <AllStores>{t('all-stores', 'All Nearby Stores')}</AllStores>
        </NoSelectionContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  flex-shrink: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
`;

const Header = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[45]};
  ${bold};
`;

const NoSelectionContainer = styled.div`
  padding-bottom: 20px;
`;

const AllStores = styled.span`
  ${bold};
  font-size: 15px;
`;

const StyledGreenCheck = styled(SvgGreenCheck)`
  margin-right: 10px;
`;

const StyledTextButton = styled(TextButton)`
  ${bold}
  font-size: 12px;
`;
