import React from 'react';

import { TypographyProps, Sizes } from '../../types';
import { useTypography, makeFontSizes } from '../../utils';

import { Typography } from '../typography';

const fontsSizes = makeFontSizes<Sizes>({
  xxlarge: { base: 'font-size-90' },
  xlarge: { base: 'font-size-80' },
  large: { base: 'font-size-70' },
  medium: { base: 'font-size-60' },
  small: { base: 'font-size-50' },
  xsmall: { base: 'font-size-30' },
  xxsmall: { base: 'font-size-20' },
});

export function Title({
  tag,
  children,
  className,
  size,
  casing = 'none',
  weight = 'bold',
  ...rest
}: TypographyProps<Sizes>): JSX.Element {
  const styles = useTypography({
    fontsSizes,
    size,
    casing,
    weight,
    lineHeight: 1.2,
  });

  return (
    <Typography className={className} styles={styles} as={tag} {...rest}>
      {children}
    </Typography>
  );
}
