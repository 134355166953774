import React from 'react';
import styled from 'styled-components';
import { callNativeMethod } from 'src/mobile-ecomm/hooks/use-mobile-native-bridge';

export function MobileEcommLinxInfoLink(): JSX.Element {
  return (
    <StyledMobileEcommLinxInfoLink
      type='button'
      onClick={() => callNativeMethod(`onLaunchUrl`, { url: `https://linx.us/` })}
    >
      ?
    </StyledMobileEcommLinxInfoLink>
  );
}

const StyledMobileEcommLinxInfoLink = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[40]};
`;
