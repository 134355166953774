import useMediaQuery from '@material-ui/core/useMediaQuery';
import dynamic from 'next/dynamic';
import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import { VisuallyHidden } from 'src/components/core/visually-hidden';
import { GqlDispensaries } from 'types/graphql';

import StoreSidebar from './store-info-sidebar';
import StoreContent from './store-info-content';

const StoreLocation = dynamic(() => import('./store-location-map'), { ssr: false });

type StoreInfoProps = {
  className?: string;
  dispensary: GqlDispensaries;
};

export default function StoreInfo({ className, dispensary }: StoreInfoProps): JSX.Element {
  const { t } = useTranslation();
  const isDesktopMenuSize = useMediaQuery((theme: { breakpoints: { up: (bp: string) => string } }) =>
    theme.breakpoints.up(`md`)
  );
  const hideLocation = !!dispensary.storeSettings.hideAddressFromDutchieMain;
  const headerDescription = t('storeInfo.storeInfoHeader', 'Dispensary Hours and Location');

  return (
    <Wrapper>
      <Layout>
        <VisuallyHidden aria-label={headerDescription}>
          <h1>{headerDescription}</h1>
        </VisuallyHidden>
        {!isDesktopMenuSize && !hideLocation && <StoreLocation className={className} dispensary={dispensary} />}
        <StoreSidebar dispensary={dispensary} />
        <ContentContainer>
          {isDesktopMenuSize && !hideLocation && <StoreLocation className={className} dispensary={dispensary} />}
          <StoreContent dispensary={dispensary} />
        </ContentContainer>
      </Layout>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media (min-width: 960px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 960px) {
    flex-grow: 2;
    flex-basis: 60%;
    padding-left: 40px;
  }
`;

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
`;
