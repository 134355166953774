import _isEmpty from "lodash/isEmpty";
import _reduce from "lodash/reduce";
import _forEach from "lodash/forEach";
import ConditionsManager from './conditions-manager';
import { mergeSatisfiers } from './common';
import issueItemsForAPriceRewards from './issue-items-for-a-price-rewards';
import issueStandardRewards from './issue-standard-rewards';
import issueDiscountToCartRewards from './issue-discount-to-cart-rewards';
import issueDiscountBundleRewards from './issue-discount-bundle-rewards';

var addSatisfiedBy = function addSatisfiedBy(conditionsSatisfiers, rewardsSatisfiers) {
  _forEach(rewardsSatisfiers, function (satisfierOfRewards) {
    _forEach(satisfierOfRewards.rewards, function (reward) {
      reward.satisfiedBy = _reduce(conditionsSatisfiers, function (satisfiedBy, satisfierOfConditions, key) {
        satisfiedBy[key] = {
          productId: satisfierOfConditions.item.id,
          quantity: satisfierOfConditions.quantity
        };
        return satisfiedBy;
      }, {});
    });
  });

  return rewardsSatisfiers;
};

var determineRewards = function determineRewards(bogoCalcsData, bogoSpecial, discountTaxOrder, items, globalSpecialsSettings) {
  var _bogoSpecial$redempti;

  var redemptionLimit = (_bogoSpecial$redempti = bogoSpecial === null || bogoSpecial === void 0 ? void 0 : bogoSpecial.redemptionLimit) !== null && _bogoSpecial$redempti !== void 0 ? _bogoSpecial$redempti : 1; // 0 redemptionLimits aren't allowed, no coalescing here

  var attemptRedemption = function attemptRedemption(redemptionIndex) {
    var conditionsManager = new ConditionsManager(bogoCalcsData, bogoSpecial, items, globalSpecialsSettings);
    var discountToCart = bogoSpecial.discountToCart,
        itemsForAPrice = bogoSpecial.itemsForAPrice,
        discountBundle = bogoSpecial.discountBundle,
        specialId = bogoSpecial.specialId;

    if (conditionsManager.satisfied) {
      var rewardsSatisfiers;
      var discountToCartRewards;

      if (discountToCart !== null && discountToCart !== void 0 && discountToCart.enabled) {
        discountToCartRewards = issueDiscountToCartRewards(bogoCalcsData, bogoSpecial, discountTaxOrder, items);
      } else if (discountBundle !== null && discountBundle !== void 0 && discountBundle.enabled) {
        rewardsSatisfiers = issueDiscountBundleRewards(bogoCalcsData, bogoSpecial, conditionsManager);
      } else if (itemsForAPrice !== null && itemsForAPrice !== void 0 && itemsForAPrice.enabled) {
        rewardsSatisfiers = issueItemsForAPriceRewards(bogoCalcsData, bogoSpecial, conditionsManager, discountTaxOrder, items, redemptionIndex);
      } else {
        rewardsSatisfiers = issueStandardRewards(bogoCalcsData, bogoSpecial, conditionsManager, items);
      }

      if (!_isEmpty(rewardsSatisfiers) || !_isEmpty(discountToCartRewards)) {
        // don't add items to condition satisfiers so they can be considered
        // for rewards or other conditions
        if (!(itemsForAPrice !== null && itemsForAPrice !== void 0 && itemsForAPrice.enabled) && !(discountToCart !== null && discountToCart !== void 0 && discountToCart.enabled) && !(discountBundle !== null && discountBundle !== void 0 && discountBundle.enabled)) {
          bogoCalcsData.conditionsSatisfiers = mergeSatisfiers(bogoCalcsData.conditionsSatisfiers, conditionsManager.satisfiers);
          rewardsSatisfiers = addSatisfiedBy(conditionsManager.satisfiers, rewardsSatisfiers);
        }

        if (discountToCart !== null && discountToCart !== void 0 && discountToCart.enabled) {
          var _bogoCalcsData$discou;

          bogoCalcsData.discountToCartSatisfiers = (_bogoCalcsData$discou = bogoCalcsData.discountToCartSatisfiers) !== null && _bogoCalcsData$discou !== void 0 ? _bogoCalcsData$discou : {};
          bogoCalcsData.discountToCartSatisfiers[specialId] = mergeSatisfiers(bogoCalcsData.discountToCartSatisfiers[specialId], conditionsManager.satisfiers);
        }

        bogoCalcsData.rewardsSatisfiers = mergeSatisfiers(bogoCalcsData.rewardsSatisfiers, rewardsSatisfiers);
        bogoCalcsData.discountToCartRewards = mergeSatisfiers(bogoCalcsData.discountToCartRewards, discountToCartRewards);

        if (redemptionIndex + 1 < redemptionLimit) {
          attemptRedemption(redemptionIndex + 1);
        }
      }
    }
  };

  attemptRedemption(0);
  return bogoCalcsData;
};

export default determineRewards;