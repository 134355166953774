import React from 'react';

import { useLoyalty, Statuses } from 'src/components/loyalty/use-loyalty';

import { SmallLoader } from 'shared/components';
import { DialogContainer } from 'src/components/modals/components/simple-dialog';
import { Connect } from './connect';
import { Terms } from './terms';
import { Pin } from './pin';

type LoyaltyProps = {
  onClose: () => void;
};

export const Loyalty = ({ onClose }: LoyaltyProps): JSX.Element => {
  const { status, setStatus, programName, programDescription, createUserAndPin, processPin, resendPin } = useLoyalty({
    initialStatus: Statuses.signup,
  });

  return (
    <DialogContainer>
      {status === Statuses.loading && <SmallLoader />}
      {status === Statuses.signup && (
        <Connect
          onClose={onClose}
          setStatus={setStatus}
          programName={programName}
          programDescription={programDescription}
        />
      )}
      {status === Statuses.terms && (
        <Terms onClose={onClose} programName={programName} createUserAndPin={createUserAndPin} />
      )}
      {[Statuses.pin, Statuses.incorrectPin, Statuses.success].includes(status) && (
        <Pin
          onClose={onClose}
          status={status}
          programName={programName}
          processPin={processPin}
          resendPin={resendPin}
        />
      )}
    </DialogContainer>
  );
};
