export const terpenes = [
  {
    id: 1,
    name: 'Myrcene',
    description:
      // eslint-disable-next-line max-len
      'One of the most common terpenes found in cannabis. Myrcene is known to increase the effects of the psychoactive properties of THC yet hold the health benefits found in CBD. Commonly found in parsley, mangoes & hops, Mycrene is a good option for those looking to add a little relaxation to their overall experience.',
    aromas: ['Cloves', 'Musky', 'Earthy'],
    effects: ['Calm', 'Relaxed', 'Sleepy'],
    value: 1.003,
    unit: 'PERCENTAGE',
  },
  {
    id: 2,
    name: 'Caryophyllene',
    description:
      // eslint-disable-next-line max-len
      "Caryophyllene, or more specifically Beta-caryophyllene is known for it's wide variety of potential health benefits both physically & mentally. This terpene has a unique ability to bind to the CB2 receptors; CB2 receptors are targeted when treating pain & inflammation with cannabis. Beta-caryophyllene is also approved for use in food by the FDA & is commonly found in black pepper, cinnamon, & basil.",
    aromas: ['Spice', 'Sharp', 'Sweet'],
    effects: [],
    value: 0.645,
    unit: 'PERCENTAGE',
  },
  {
    id: 3,
    name: 'Humulene',
    description:
      // eslint-disable-next-line max-len
      'Commonly found in ginseng, ginger, & hops, Humulene is known to lend its robust characteristics to the different scents of cannabis. This terpene has been used in holistic practices for hundreds of years due to its strong anti-inflammatory properties. Humulene has also been reported to act as an appetite suppressant.',
    aromas: ['Pine', 'Spice', 'Earthy', 'Herbal', 'Woody'],
    effects: ['Relaxed'],
    value: 0.849,
    unit: 'PERCENTAGE',
  },
  {
    id: 4,
    name: 'Limonene',
    description:
      // eslint-disable-next-line max-len
      'Limonene is found in citrus rinds and is the second most occurring terpene in nature. This terpene has been used in high dosages as a catalyst in topical products to allow other chemical compounds to pass through the skin for absorption in the blood.',
    aromas: ['Citrus', 'Herbal', 'Lemon'],
    effects: ['Energetic', 'Inspired', 'Clear Mind', 'Focused'],
    value: 0.053,
    unit: 'PERCENTAGE',
  },
  {
    id: 5,
    name: 'Linalool',
    description:
      // eslint-disable-next-line max-len
      'Linalool is a multi-use terpene that carries a strong calming effect as well as an ability to act as an anticonvulsant in epileptic patients. Found in the plants rosewood, corianders, & lavender, Linalool is a popular terpene commonly present in over 200 species of plants across the world. Linalool is a good addition to your terpene selection when looking for a little R & R.',
    aromas: ['Floral', 'Herbal', 'Lavender'],
    effects: ['Calm', 'Relaxed', 'Sleepy'],
    value: 0.221,
    unit: 'PERCENTAGE',
  },
  {
    id: 6,
    name: 'b-Pinene',
    description:
      // eslint-disable-next-line max-len
      'beta- Pinene is a monoterpene and one of the two isomer compounds that make up Pinene. This terpene is commonly associated with use in turpentine and luxury goods like perfumes and colognes. Users report an increase in mental clarity and executive function when using strains high in beta- Pinene.',
    aromas: [],
    effects: ['Clear Mind', 'Inspired', 'Uplifted'],
    value: 0.038,
    unit: 'PERCENTAGE',
  },
  {
    id: 7,
    name: 'Terpinolene',
    description:
      // eslint-disable-next-line max-len
      'Terpinolene is a cannabis terpene with strong aromatic properties which makes it a popular ingredient in perfumes, lotions & soaps. While this may not be a prevailing terpene such as myrcene or linalool, it still has the potential to positively effect the endocannabinoid system through its numerous potential health benefits. Also found natural occurring in apples, tea trees & sage.',
    aromas: ['Orange', 'Citrus', 'Spice', 'Woody'],
    effects: [],
    value: null,
    unit: 'PERCENTAGE',
  },
];

export const colorTerpenes = [
  {
    id: '4d09ddee-7cb7-47d1-a1a3-e06108e7a7d9',
    terpeneId: '4860618d-2305-44d5-b0b1-5f1081120e48',
    libraryTerpene: {
      name: 'Caryophyllene',
      description:
        // eslint-disable-next-line max-len
        "Caryophyllene, or more specifically Beta-caryophyllene is known for it's wide variety of potential health benefits both physically & mentally. This terpene has a unique ability to bind to the CB2 receptors; CB2 receptors are targeted when treating pain & inflammation with cannabis. Beta-caryophyllene is also approved for use in food by the FDA & is commonly found in black pepper, cinnamon, & basil.",
      aromas: ['Spice', 'Sharp', 'Sweet'],
      effects: ['Happy', 'Creative', 'Relaxed'],
    },
    value: 0.564,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#005b49',
  },
  {
    id: 'c8e6a23d-6b9a-4e44-84ec-99c07f6fda69',
    terpeneId: '9198c971-3871-4510-a2f4-1b514cbecf76',
    libraryTerpene: {
      name: 'Humulene',
      description:
        // eslint-disable-next-line max-len
        'Commonly found in ginseng, ginger, & hops, Humulene is known to lend its robust characteristics to the different scents of cannabis. This terpene has been used in holistic practices for hundreds of years due to its strong anti-inflammatory properties. Humulene has also been reported to act as an appetite suppressant.',
      aromas: [],
      effects: [],
    },
    value: 0.45,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#007967',
  },
  {
    id: 'b19085a9-55fd-496e-a28b-2839812ecb2e',
    terpeneId: '209478fb-2f85-4d46-86ba-8292b9219d52',
    libraryTerpene: {
      name: 'Beta Caryophyllene',
      description:
        // eslint-disable-next-line max-len
        "Beta-caryophyllene is known for it's wide variety of potential health benefits both physically & mentally. This terpene has a unique ability to bind to the CB2 receptors; CB2 receptors are targeted when treating pain & inflammation with cannabis. Beta-caryophyllene is also approved for use in food by the FDA & is commonly found in black pepper, cinnamon, & basil. ",
      aromas: ['Sharp', 'Spice', 'Sweet'],
      effects: ['Energetic', 'Relaxed', 'Creative', 'Focused'],
    },
    value: 1.003,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#0b9785',
  },
  {
    id: '3604155b-20aa-4e2a-a2a1-ac4ce90236e2',
    terpeneId: '04d99050-7d95-489d-8484-655152cd1c7d',
    libraryTerpene: {
      name: 'Myrcene',
      description: null,
      aromas: [],
      effects: [],
    },
    value: 0.654,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#29B5A3',
  },
  {
    id: '90f4c903-dd47-42c6-90f7-86b1e6cb61cc',
    terpeneId: '4fdc29c2-7460-44ae-87d6-19d340696bde',
    libraryTerpene: {
      name: 'Limonene',
      description: null,
      aromas: [],
      effects: [],
    },
    value: 0.003,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#47d3c1',
  },
  {
    id: 'ca9523da-7837-4325-a61b-3234029fdc00',
    terpeneId: 'f3673180-983b-44f6-9d1e-f323c8d637ee',
    libraryTerpene: {
      name: 'Alpha-Pinene',
      description: null,
      aromas: [],
      effects: [],
    },
    value: 0.009,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#65f1df',
  },
  {
    id: '8f9d2014-f68b-4f57-805f-bb6b25de6c9d',
    terpeneId: '3c5914e9-89aa-4387-8a89-fc9daca9a9d3',
    libraryTerpene: {
      name: 'beta-Pinene',
      description: null,
      aromas: [],
      effects: [],
    },
    value: 0.065,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#83fffd',
  },
];

export const shuffledTerpenes = [
  {
    id: '90f4c903-dd47-42c6-90f7-86b1e6cb61cc',
    terpeneId: '4fdc29c2-7460-44ae-87d6-19d340696bde',
    libraryTerpene: {
      name: 'Limonene',
      description: null,
      aromas: [],
      effects: [],
    },
    value: 0.003,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#83fffd',
  },
  {
    id: 'b19085a9-55fd-496e-a28b-2839812ecb2e',
    terpeneId: '209478fb-2f85-4d46-86ba-8292b9219d52',
    libraryTerpene: {
      name: 'Beta Caryophyllene',
      description:
        // eslint-disable-next-line max-len
        "Beta-caryophyllene is known for it's wide variety of potential health benefits both physically & mentally. This terpene has a unique ability to bind to the CB2 receptors; CB2 receptors are targeted when treating pain & inflammation with cannabis. Beta-caryophyllene is also approved for use in food by the FDA & is commonly found in black pepper, cinnamon, & basil. ",
      aromas: ['Sharp', 'Spice', 'Sweet'],
      effects: ['Energetic', 'Relaxed', 'Creative', 'Focused'],
    },
    value: 1.003,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#29B5A3',
  },
  {
    id: '4d09ddee-7cb7-47d1-a1a3-e06108e7a7d9',
    terpeneId: '4860618d-2305-44d5-b0b1-5f1081120e48',
    libraryTerpene: {
      name: 'Caryophyllene',
      description: null,
      aromas: [],
      effects: [],
    },
    value: 0.564,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#47d3c1',
  },
  {
    id: '3604155b-20aa-4e2a-a2a1-ac4ce90236e2',
    terpeneId: '04d99050-7d95-489d-8484-655152cd1c7d',
    libraryTerpene: {
      name: 'Myrcene',
      description: null,
      aromas: [],
      effects: [],
    },
    value: 0.654,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#0b9785',
  },
  {
    id: 'ca9523da-7837-4325-a61b-3234029fdc00',
    terpeneId: 'f3673180-983b-44f6-9d1e-f323c8d637ee',
    libraryTerpene: {
      name: 'Alpha-Pinene',
      description: null,
      aromas: [],
      effects: [],
    },
    value: 0.009,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#005b49',
  },
  {
    id: 'c8e6a23d-6b9a-4e44-84ec-99c07f6fda69',
    terpeneId: '9198c971-3871-4510-a2f4-1b514cbecf76',
    libraryTerpene: {
      name: 'Humulene',
      description: null,
      aromas: [],
      effects: [],
    },
    value: 0.45,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#65f1df',
  },
  {
    id: '8f9d2014-f68b-4f57-805f-bb6b25de6c9d',
    terpeneId: '3c5914e9-89aa-4387-8a89-fc9daca9a9d3',
    libraryTerpene: {
      name: 'beta-Pinene',
      description: null,
      aromas: [],
      effects: [],
    },
    value: 0.065,
    unit: 'MILLIGRAMS',
    unitSymbol: 'mg',
    __typename: 'Products_Terpene',
    color: '#007967',
  },
];
