var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
export function VisuallyHidden(_ref) {
  var children = _ref.children;
  return __jsx(StyledVisuallyHidden, null, children);
}
var StyledVisuallyHidden = styled.span.withConfig({
  displayName: "visually-hidden__StyledVisuallyHidden",
  componentId: "sc-8aci6l-0"
})(["border-width:0;clip:rect(0,0,0,0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;white-space:nowrap;width:1px;"]);