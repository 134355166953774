import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from 'react';
export default function RadioUncheckedIcon(props) {
  return __jsx("svg", _extends({
    height: "16.593",
    viewBox: "0 0 17 16.593",
    width: "17",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("ellipse", {
    cx: "8.5",
    cy: "8.297",
    fill: "#fff",
    rx: "8",
    ry: "7.797",
    stroke: "#b7bec3"
  }));
}