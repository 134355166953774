import { useCallback, useEffect } from 'react';
import useStores from 'shared/hooks/use-stores';
import { useCountdownTimer } from 'src/hooks/use-countdown-timer';

type UsePayByBankKioskOrderSuccessModalParams = {
  onClose: () => void;
};

type UsePayByBankKioskOrderSuccessModalReturn = {
  secondsLeft: number;
  handleDutchiePayOrderSuccessClose: () => void;
};

export function usePayByBankKioskOrderSuccessModal({
  onClose,
}: UsePayByBankKioskOrderSuccessModalParams): UsePayByBankKioskOrderSuccessModalReturn {
  const { Cart, UI } = useStores();

  const handleDutchiePayOrderSuccessClose = useCallback(() => {
    Cart.setAnonymousOrderId('');
    Cart.setIsAnonymousKioskCheckout(false);
    UI.updateShowDutchiePayKioskOrderSuccess(false);
    onClose();
  }, [Cart, UI, onClose]);

  const { startTimer, secondsLeft } = useCountdownTimer({
    initialSeconds: 8,
    onTimerStop: handleDutchiePayOrderSuccessClose,
  });

  useEffect(() => {
    startTimer();
  }, [startTimer]);

  return {
    secondsLeft,
    handleDutchiePayOrderSuccessClose,
  };
}
