import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import usePaths from 'src/hooks/use-paths';
import ROUTES from 'src/routes';

export const InlineCategoriesList = (): JSX.Element => {
  const { categories } = useDispensaryCategories();
  const { href: baseHref } = usePaths();

  return (
    <Wrapper>
      <Heading>Categories</Heading>
      <List>
        {categories.map((category) => (
          <li key={category.key}>
            <Link href={`${baseHref}${String(ROUTES.PRODUCTS)}${category.key}`}>
              <Anchor href={`${baseHref}${String(ROUTES.PRODUCTS)}${category.key}`} data-testid='category-list-link'>
                {category.label}
              </Anchor>
            </Link>
          </li>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
  padding: 48px 24px 24px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
    gap: 12px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    border-top: 1px solid #e3e7e9;
    justify-content: center;
    padding: 56px 40px;
  }
`;

const Heading = styled.h2`
  color: #2c3236;
  font-size: 16px;
  line-height: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    line-height: ${33 / 16};
  }
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Anchor = styled.a`
  border: 2px solid #e3e7e9;
  border-radius: ${({ theme }) => theme.customized.radius.buttons};
  color: #121516;
  display: block;
  font-size: 13px;
  line-height: 1;
  padding: 8px 12px;
  transition: border 0.3s;

  &:hover {
    border-color: #646d72;
  }
`;
