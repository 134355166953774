import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import { useColors } from 'contexts/colors';

import SvgSearchIcon from 'assets/search-icon';
import { Dropdown } from 'components/core';
import { Clickable, DesktopOnly, MobileOnly } from 'shared/components';
import { SearchResult } from './result';

export function SearchDropdown({ results, searchStr, dispensary }): JSX.Element {
  const { t, Trans } = useTranslation();
  const colors = useColors();

  // @TODO hook up clear your cart modal to open here
  const handleOpenClearYourCartModal = (): void => console.log(`opening clear cart modal...`);

  const DesktopNoResultsRow = (): JSX.Element => (
    <InfoRow>
      <SvgSearchIcon fill={colors.grey[70]} height='17px' alt={t('search-icon', 'Search Icon')} />
      <InfoCopy>
        <Trans i18nKey='info.noResults'>
          No results for <BoldText>{searchStr}</BoldText>
        </Trans>
      </InfoCopy>
    </InfoRow>
  );

  const DesktopResults = (): JSX.Element => (
    <>
      {results.map((resultProps, key: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <SearchResult key={key} {...resultProps} />
      ))}
    </>
  );

  return (
    <>
      <DesktopOnly display='unset'>
        <StyledDropdown>
          {dispensary?.name && (
            <InfoRow>
              <SvgSearchIcon fill={colors.grey[70]} height='17px' alt={t('search-icon', 'Search Icon')} />
              <InfoCopy>
                <Trans i18nKey='info.searchingStoreName'>
                  Searching <BoldText>{dispensary.name}</BoldText>
                </Trans>
              </InfoCopy>
              <SearchAllStores onClick={handleOpenClearYourCartModal}>
                {t('dropdown.searchAllStores', 'Search all stores')}
              </SearchAllStores>
            </InfoRow>
          )}
          {results.length ? <DesktopResults /> : <DesktopNoResultsRow />}
        </StyledDropdown>
      </DesktopOnly>

      <MobileOnly>
        <MobileSearchResultsContainer>
          <ResultsCopy>{t('dropdown.results', 'Results')}</ResultsCopy>
          <SearchResult>
            <Trans i18nKey='dropdown.Search'>
              Search&nbsp;<BoldText>"{searchStr}"</BoldText>
            </Trans>
          </SearchResult>
          {results.map((resultProps, key: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <SearchResult key={key} {...resultProps} />
          ))}
        </MobileSearchResultsContainer>
      </MobileOnly>
    </>
  );
}

const StyledDropdown = styled(Dropdown)`
  margin-top: 17px;
  min-width: 500px;
  padding-bottom: 9px;
  width: 100%;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.grey[25]};
  cursor: pointer;
  font-size: 15px;
  height: 64px;
  padding-left: 22px;
  svg {
    margin-right: 13px;
  }
`;

const InfoCopy = styled.h3`
  flex-grow: 1;
  font-weight: normal;
  font-size: 15px;
  line-height: 43px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  :hover {
    cursor: default;
  }
`;

const SearchAllStores = styled(Clickable)`
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  padding-right: 22px;
`;

const MobileSearchResultsContainer = styled.div`
  position: absolute;
  top: 133px;
`;

const ResultsCopy = styled.h3`
  font-weight: bold;
  font-size: 11px;
  line-height: 11px;
  color: ${({ theme }) => theme.colors.grey[45]};
  text-transform: uppercase;
`;

const BoldText = styled.span`
  font-weight: bold;
`;
