import React, { SVGAttributes } from 'react';
import useTranslation from 'src/hooks/use-translation';

function SvgSearchClear(props: SVGAttributes<unknown> & { alt?: string }): JSX.Element {
  const { t } = useTranslation();
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' {...props}>
      <title>{t('clear-icon-title', 'Clear Icon')}</title>
      <circle cx='11' cy='11' r='11' />
      <path
        // eslint-disable-next-line max-len
        d='M13.9897 7.23506C14.3795 6.84523 15.0116 6.84523 15.4014 7.23506C15.7912 7.62489 15.7912 8.25692 15.4014 8.64675L8.34295 15.7052C7.95313 16.095 7.32109 16.095 6.93127 15.7052C6.54144 15.3153 6.54144 14.6833 6.93127 14.2935L13.9897 7.23506Z'
        fill='white'
      />
      <path
        // eslint-disable-next-line max-len
        d='M15.4014 14.2935C15.7912 14.6833 15.7912 15.3153 15.4014 15.7052C15.0116 16.095 14.3795 16.095 13.9897 15.7052L6.93126 8.64674C6.54144 8.25692 6.54144 7.62488 6.93126 7.23506C7.32109 6.84523 7.95312 6.84523 8.34295 7.23506L15.4014 14.2935Z'
        fill='white'
      />
    </svg>
  );
}

export default SvgSearchClear;
