import React from 'react';
import useUI from 'hooks/use-ui';

export default function EmbeddedExternalLink({ children, href, ...props }) {
  const UI = useUI();

  const handleClick = (e) => {
    console.log('cache');
    // only change window.location.href if not optening in new tab
    if (!e.ctrlKey && !e.shiftKey && !e.metaKey && !(e.button && e.button === 1)) {
      UI.embeddedEventDispatcher(`route:changing`, { url: href });
    }
  };

  return (
    <a href={href} onClick={handleClick} {...props}>
      {children}
    </a>
  );
}
