/* eslint-disable max-len */
import React from 'react';

export function PayByBankLogo({ width = '803', height = '138', fill = '#00A47C', ...props }): JSX.Element {
  return (
    <svg width={width} height={height} viewBox='0 0 803 138' fill={fill} xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Pay by Bank Logo</title>
      <path d='M0.0625 3.15289C0.0625 1.9317 1.05793 0.936273 2.27912 0.936273H41.0084C61.9432 0.936273 75.4892 13.2508 75.4892 32.1845C75.4892 51.1181 61.9432 63.4327 41.0084 63.4327H14.0703V107.476C14.0703 108.697 13.0749 109.692 11.8537 109.692H2.27912C1.05793 109.692 0.0625 108.697 0.0625 107.476V3.15289ZM40.5466 50.8102C53.323 50.8102 61.0196 43.8833 61.0196 32.1845C61.0196 20.4856 53.323 13.4048 40.5466 13.4048H14.0703V50.8102H40.5466Z' />
      <path d='M113.589 41.8086C103.563 41.8086 96.2872 47.4219 94.594 56.8528C94.4195 57.7969 93.5267 58.4332 92.5826 58.2485L83.87 56.5963C82.9157 56.4116 82.3102 55.4777 82.5052 54.5336C85.4914 40.1358 97.9086 30.8794 113.599 30.8794C132.379 30.8794 145.463 41.9625 145.463 59.9725V107.969C145.463 108.923 144.694 109.693 143.739 109.693H134.257C133.303 109.693 132.533 108.923 132.533 107.969V96.4544C128.069 105.382 117.91 110.924 106.672 110.924C91.1254 110.924 80.5041 101.38 80.5041 87.9881C80.5041 73.0567 92.6647 64.8983 117.448 62.4354L132.533 60.7422V59.2029C132.533 48.5816 124.99 41.8086 113.599 41.8086H113.589ZM108.663 100.149C123.749 100.149 132.523 90.1432 132.523 74.5961V70.9017L117.437 72.5949C101.736 74.2882 93.578 79.6758 93.578 87.8342C93.578 95.069 99.7353 100.149 108.663 100.149Z' />
      <path d='M225.867 35.1282L186.933 136.292C186.605 137.144 185.784 137.709 184.86 137.709H176.219C174.659 137.709 173.592 136.149 174.157 134.691L184.234 108.615L155.11 35.1488C154.535 33.6915 155.603 32.1112 157.173 32.1112H166.542C167.455 32.1112 168.287 32.6756 168.615 33.5376L190.853 91.5289L213.091 33.5376C213.419 32.6756 214.24 32.1112 215.164 32.1112H223.805C225.365 32.1112 226.432 33.671 225.878 35.1282H225.867Z' />
      <path d='M286.753 98.4553V107.476C286.753 108.697 285.757 109.692 284.536 109.692H275.885C274.664 109.692 273.668 108.697 273.668 107.476V2.92512C273.668 1.70392 274.664 0.708496 275.885 0.708496H284.536C285.757 0.708496 286.753 1.70392 286.753 2.92512V43.1937C291.678 35.9589 301.838 30.8792 312.151 30.8792C336.165 30.8792 349.403 48.1195 349.403 70.9015C349.403 93.6834 336.165 110.924 311.998 110.924C300.607 110.924 290.293 105.536 286.753 98.4553ZM310.766 99.225C326.005 99.225 336.011 87.9879 336.011 70.9015C336.011 53.815 326.005 42.578 310.766 42.578C295.527 42.578 285.521 53.815 285.521 70.9015C285.521 87.9879 295.527 99.225 310.766 99.225Z' />
      <path d='M425.364 35.1282L386.429 136.292C386.101 137.144 385.28 137.709 384.356 137.709H375.716C374.156 137.709 373.088 136.149 373.653 134.691L383.73 108.615L354.606 35.1488C354.032 33.6915 355.099 32.1112 356.669 32.1112H366.038C366.952 32.1112 367.783 32.6756 368.111 33.5376L390.349 91.5289L412.587 33.5376C412.916 32.6756 413.737 32.1112 414.66 32.1112H423.301C424.861 32.1112 425.928 33.671 425.374 35.1282H425.364Z' />
      <path d='M473.78 3.15313C473.78 1.93194 474.776 0.936513 475.997 0.936513H512.879C533.198 0.936513 545.82 11.096 545.82 28.6443C545.82 38.6498 538.894 46.6543 531.659 49.733C542.588 53.5813 550.592 62.2014 550.592 77.2868C550.592 96.6822 537.354 109.693 515.188 109.693H475.997C474.776 109.693 473.78 108.697 473.78 107.476V3.15313ZM512.263 45.2689C525.04 45.2689 531.967 39.8813 531.967 29.1061C531.967 18.3308 525.04 12.9432 512.263 12.9432H487.788V45.2689H512.263ZM512.879 97.0701C528.58 97.0701 536.585 89.4474 536.585 76.9789C536.585 64.5104 528.58 57.5835 512.879 57.5835H487.788V97.0701H512.879Z' />
      <path d='M594.925 41.8087C584.899 41.8087 577.624 47.4221 575.93 56.853C575.756 57.7971 574.863 58.4333 573.919 58.2486L565.206 56.5964C564.252 56.4117 563.646 55.4778 563.841 54.5337C566.828 40.1359 579.245 30.8795 594.936 30.8795C613.716 30.8795 626.8 41.9626 626.8 59.9726V107.969C626.8 108.923 626.03 109.693 625.076 109.693H615.593C614.639 109.693 613.869 108.923 613.869 107.969V96.4545C609.405 105.383 599.246 110.924 588.009 110.924C572.462 110.924 561.84 101.38 561.84 87.9883C561.84 73.0569 574.001 64.8985 598.784 62.4356L613.869 60.7423V59.203C613.869 48.5817 606.327 41.8087 594.936 41.8087H594.925ZM590 100.149C605.085 100.149 613.859 90.1433 613.859 74.5962V70.9018L598.774 72.5951C583.073 74.2883 574.914 79.6759 574.914 87.8343C574.914 95.0691 581.072 100.149 590 100.149Z' />
      <path d='M648.71 32.111H657.361C658.582 32.111 659.577 33.1064 659.577 34.3276V46.7345C664.965 37.4986 674.355 30.8795 686.207 30.8795C702.37 30.8795 713.915 41.8087 713.915 58.2794V107.476C713.915 108.697 712.92 109.693 711.699 109.693H703.048C701.826 109.693 700.831 108.697 700.831 107.476V60.7423C700.831 50.2749 694.212 42.7323 683.283 42.7323C668.813 42.7323 659.577 55.2008 659.577 75.6737V107.476C659.577 108.697 658.582 109.693 657.361 109.693H648.71C647.488 109.693 646.493 108.697 646.493 107.476V34.3276C646.493 33.1064 647.488 32.111 648.71 32.111Z' />
      <path d='M733.618 107.476V2.92512C733.618 1.70392 734.613 0.708496 735.835 0.708496H744.64C745.861 0.708496 746.856 1.70392 746.856 2.92512V63.6667L782.096 32.6648C782.497 32.3056 783.02 32.1106 783.564 32.1106H796.812C797.992 32.1106 798.557 33.5576 797.684 34.358L766.878 62.7431L801.153 107.609C801.8 108.461 801.194 109.682 800.127 109.682H787.515C786.827 109.682 786.17 109.364 785.76 108.81L757.18 71.507L746.866 80.8968V107.465C746.866 108.687 745.871 109.682 744.65 109.682H735.845C734.624 109.682 733.628 108.687 733.628 107.465L733.618 107.476Z' />
    </svg>
  );
}
