'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.addSubpath = void 0;

var addSubpath = function addSubpath(url, subpath) {
  return url
    .replace('/', '/'.concat(subpath, '/'))
    .replace(/(https?:\/\/)|(\/)+/g, '$1$2')
    .replace(/\/$/, '');
};

exports.addSubpath = addSubpath;
