import { css } from 'styled-components';

export const bold = css`
  font-weight: bold;
`;

const commonHeadingStyles = css`
  ${bold}
`;

export const heading1 = css`
  ${commonHeadingStyles}
  font-size: 40px;
`;

export const heading2 = css`
  ${commonHeadingStyles}
  font-size: 26px;
`;

export const heading3 = css`
  ${commonHeadingStyles}
  font-size: 20px;
`;

export const heading4 = css`
  ${commonHeadingStyles}
  font-size: 16px;
`;

export const heading5 = css`
  ${commonHeadingStyles}
  font-size: 14px;
`;

export const paragraph = css`
  font-size: 13px;
`;

export const largeParagraph = css`
  font-size: 14px;
  line-height: 24px;
`;

export const subparagraph = css`
  font-size: 12px;
`;

export const paragraphLink = css`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 13px;

  :hover {
    text-decoration: underline;
  }
`;

const commonCapsStyles = css`
  ${bold}
  text-transform: uppercase;
`;

export const largeCapsSubtext = css`
  ${commonCapsStyles}
  font-size: 13px;
  line-height: 13px;
`;

export const smallCapsSubtext = css`
  ${commonCapsStyles}
  font-size: 11px;
  line-height: 12px;
`;

export const smallSubtext = css`
  ${bold};
  font-size: 11px;
  line-height: 17px;
`;
