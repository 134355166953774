import { useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  GqlDispensaries,
  GqlWebCustomizationSettings,
  GqlColorSettings,
  Maybe,
  TextTransformOption,
  RadiusOption,
} from 'types/graphql';
import { fontsV2 as availableFonts } from 'shared/constants/menu-fonts';
import { textTransforms as menuTransforms, radius as menuRadius } from 'shared/constants/customization';
import { navigationBarColors, navigationBarColorsV2, linkColors, linkColorsV2 } from 'shared/constants/menu-colors';
import { mergeWithoutNull } from 'src/utils/misc-utils';

type RadiusStyle = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  tags: string;
  cards: string;
  buttons: string;
};

export type Color = {
  background: string;
  border: string;
  color: string;
  key: string;
};

type WebCustomizations = {
  colors: {
    navBar: string | null;
    buttonsLinks: string | null;
  };
  fonts: {
    primary: string | null;
    secondary: string | null;
  };
  textTransforms?: {
    buttons: string | null;
    links: string | null;
    tags: string | null;
  };
  radius?: RadiusStyle | null;
};

type UseWebCustomizationSettingsReturn = () => WebCustomizations;

const getFallbackCustomization = (
  webCustomizationSettings: Maybe<GqlWebCustomizationSettings> | undefined,
  legacyColorSettings: Maybe<GqlColorSettings> | undefined
): WebCustomizations => {
  if (webCustomizationSettings) {
    const { linkColor, navBarColor } = webCustomizationSettings.colorSettings;
    const { family } = webCustomizationSettings.fontSettings;

    return {
      colors: {
        navBar: navigationBarColorsV2[navBarColor].background,
        buttonsLinks: linkColorsV2[linkColor].background,
      },
      fonts: {
        primary: availableFonts[family].fontFamily,
        secondary: availableFonts[family].fontFamily,
      },
    };
  }

  const { linkColor, navBarColor } = legacyColorSettings ?? {};

  return {
    colors: {
      navBar: navigationBarColors.find(({ key }) => key === navBarColor)?.background ?? null,
      buttonsLinks: linkColors.find(({ key }) => key === linkColor)?.background ?? null,
    },
    fonts: {
      primary: null,
      secondary: null,
    },
  };
};

export function useGetWebCustomizationSettings(
  dispensary: GqlDispensaries | undefined
): UseWebCustomizationSettingsReturn {
  const flags = useFlags();
  const isCustomizationV2Enabled = flags['growth.ecomm.menu-customization-v2.rollout'] ?? false;
  const isCustomizationTagsEnabled = flags['growth.ecomm.menu-customization-v2-tags.rollout'] ?? false;

  return useCallback(() => {
    const webCustomizationSettingsV2 = dispensary?.webCustomizationSettingsV2;

    const fallbackCustomization = getFallbackCustomization(
      dispensary?.webCustomizationSettings,
      dispensary?.colorSettings
    );

    if (webCustomizationSettingsV2 && isCustomizationV2Enabled) {
      const { colors, fonts, textTransforms, radius } = webCustomizationSettingsV2;

      const customization = {
        colors: {
          navBar: colors?.navBar,
          buttonsLinks: colors?.buttonsLinks,
          staffPickTag: isCustomizationTagsEnabled ? colors?.staffPickTag : null,
          discountTag: isCustomizationTagsEnabled ? colors?.discountTag : null,
        },
        fonts: {
          primary: fonts?.heading,
          secondary: fonts?.body,
        },
        textTransforms: {
          buttons: getTextTransformStyle(textTransforms?.buttons),
          links: getTextTransformStyle(textTransforms?.links),
          tags: getTextTransformStyle(textTransforms?.tags),
        },
        radius: getRadiusStyle(radius?.default),
      };

      return mergeWithoutNull(fallbackCustomization, customization);
    }

    return fallbackCustomization;
  }, [
    dispensary?.colorSettings,
    dispensary?.webCustomizationSettings,
    dispensary?.webCustomizationSettingsV2,
    isCustomizationTagsEnabled,
    isCustomizationV2Enabled,
  ]);
}

export const getFullFontFamily = (font: string | null): string | null => {
  if (!font) {
    return null;
  }

  const fontOption =
    Object.values(availableFonts).find((option) => option.fontFamily === font) ?? availableFonts.MATTER;

  return [`'${fontOption.fontFamily}'`, ...fontOption.fallbacks].join(', ');
};

export const getEncodedFonts = (selectedFonts: Array<string>): string => {
  const nonLocalFonts = Object.values(availableFonts).filter((font) => !font.localFont);

  const filteredFonts = new Set(
    nonLocalFonts.filter(
      (fontOption) => Object.values(selectedFonts).includes(fontOption.fontFamily) && fontOption.fontFamilyEncoded
    )
  );

  return Array.from(filteredFonts)
    .map((fontOption) => `family=${String(fontOption.fontFamilyEncoded)}`)
    .join('&');
};

export const getTextTransformStyle = (option?: TextTransformOption | null): string | null => {
  if (!option) {
    return null;
  }

  return menuTransforms.find((transform) => transform.value === option)?.style ?? null;
};

export const getRadiusStyle = (option?: RadiusOption | null): RadiusStyle | null => {
  if (!option) {
    return null;
  }

  return menuRadius.find((radius) => radius.value === option)?.style ?? null;
};
