import React, { SVGAttributes } from 'react';

/* eslint-disable max-len */

export function Warning(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='18' viewBox='0 0 20 18' role='img' {...props}>
      <title>Warning Icon</title>
      <path
        fill='#B5B187'
        fillRule='evenodd'
        d='M11.043 15.841a1.47 1.47 0 0 1-1.043.44c-.39 0-.771-.161-1.046-.44a1.516 1.516 0 0 1 0-2.116 1.466 1.466 0 0 1 2.09 0 1.507 1.507 0 0 1-.001 2.116M10 4.651c1.207 0 1.5.92 1.427 1.446l-.724 5.703a.705.705 0 0 1-.703.712c-.317 0-.675-.17-.703-.712l-.724-5.703C8.5 5.556 8.801 4.652 10 4.652m9.71 10.677L11.46.856A1.685 1.685 0 0 0 9.998 0c-.604 0-1.162.326-1.464.856L.226 15.433a1.731 1.731 0 0 0 0 1.711c.302.53.86.856 1.463.856h16.622A1.7 1.7 0 0 0 20 16.289a1.72 1.72 0 0 0-.29-.96'
      />
    </svg>
  );
}
