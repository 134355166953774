import React from 'react';
import styled from 'styled-components';

import { PRODUCT_GROUP_SIZES } from '../product-list/product-list.constants';

const { md, lg, xl } = PRODUCT_GROUP_SIZES.grid;

type ProductCardsGridProps = {
  children: React.ReactNode;
  dataTestId?: string;
};

export function ProductCardsGrid({ children, dataTestId }: ProductCardsGridProps): JSX.Element {
  return <Grid data-testid={dataTestId}>{children}</Grid>;
}

const Grid = styled.div`
  margin: 25px 0 20px 0;
  display: grid;
  row-gap: 37px;
  column-gap: 37px;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${md.breakpoint}px) {
    grid-template-columns: repeat(${md.rowSize}, 1fr);
    row-gap: 0;
    column-gap: 0;
  }

  @media (min-width: ${lg.breakpoint}px) {
    grid-template-columns: repeat(${lg.rowSize}, 1fr);
  }

  @media (min-width: ${xl.breakpoint}px) {
    grid-template-columns: repeat(${xl.rowSize}, 1fr);
  }
`;
