import React from 'react';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { ModalObserver } from '../modal-observer';
import { AddressRequiredModal } from './address-required-modal';

export const AddressRequired = ({ name }) => (
  <ModalObserver name={name}>
    {(props) => (
      <ModalWrapper {...props}>
        <AddressRequiredModal {...props} />
      </ModalWrapper>
    )}
  </ModalObserver>
);
