import { useEffect, useRef } from 'react';
import { useStores } from 'src/contexts/stores';
import { useIsMounted } from './use-is-mounted';

export default function useSearchPreferences() {
  const { searchPreferences } = useStores();
  const isMounted = useIsMounted();

  const { initialize, isInitialized } = searchPreferences;
  const initializeRef = useRef(initialize);

  useEffect(() => {
    if (isMounted && !isInitialized) {
      initializeRef.current();
    }
  }, [isMounted, isInitialized]);

  return searchPreferences;
}
