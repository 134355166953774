import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { observer } from 'mobx-react';

import useUser from 'src/hooks/use-user';
import useUI from 'src/hooks/use-ui';
import { useCompleteAccountModal } from 'src/components/modals/complete-account';

export const IncompleteAccountDetection = observer(
  ({ children }): JSX.Element => {
    const { open: openCompleteAccountModal } = useCompleteAccountModal();

    const flags = useFlags();
    const isGoogleLoginEnabled = flags[`growth.ecomm.sign-in-with-google.rollout`] ?? false;

    const { firstName, lastName, birthday, phone, loading, isLoggedIn, isSsoUser } = useUser();
    const isMissingInfo = !firstName || !lastName || !birthday || !phone;
    const isLoggedInUserMissingInfo = !loading && isLoggedIn && isMissingInfo;

    const { isEmbeddedCarousel } = useUI();
    const { route, isReady } = useRouter();

    useEffect(() => {
      if (!isReady || !isGoogleLoginEnabled || isEmbeddedCarousel) {
        return;
      }

      const isDutchiePayRoute = route.startsWith('/pay/');
      const isOauthRoute = route.startsWith('/oauth/');

      if (isLoggedInUserMissingInfo && isSsoUser && !isDutchiePayRoute && !isOauthRoute) {
        void openCompleteAccountModal();
      }
    }, [
      isEmbeddedCarousel,
      isGoogleLoginEnabled,
      isLoggedInUserMissingInfo,
      isReady,
      isSsoUser,
      openCompleteAccountModal,
      route,
    ]);

    return children;
  }
);
