import { useApolloClient } from '@apollo/react-hooks';
import React, { useState } from 'react';
import styled from 'styled-components';
import { space, width } from 'styled-system';
import { object, string } from 'yup';
import _ from 'lodash';

import useTranslation from 'hooks/use-translation';
import useUser from 'hooks/use-user';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { consumerLogin } from 'shared/actions/users';
import useErnie from 'shared/hooks/use-ernie';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import ShowEnrollment from 'src/payments/graphql/queries/show-enrollment.gql';

import { useModals, ModalNames } from 'components/modals';
import { TextButton, Button } from 'components/core';
import { paragraph, subparagraph } from 'components/core/typography';
import useUI from 'src/hooks/use-ui';
import { callNativeMethod } from 'src/mobile-ecomm/hooks/use-mobile-native-bridge';
import { tracker } from 'utils/analytics';
import { FormTextInput } from '../../form-inputs';
import Form from '../form';
import { emailRegEx } from '../form-utils';

const loginFormValidation = (t) =>
  object().shape({
    email: string()
      .required(t('form.emailRequired', 'email is required'))
      .matches(emailRegEx, t('form.validEmail', 'please enter a valid email address')),
    password: string().required(t('form.passwordRequired', 'password is required')),
  });

const trackAccountLogin = (dispensary, userId, analyticsEventLabel) => {
  tracker.setContext({ activeDispensary: dispensary });
  tracker.accountLogin({ newAccount: false, customerId: userId, analyticsEventLabel });
};

/**
 * @param {{ analyticsEventLabel?: null | string, onClose: function() }}
 */
const LoginForm = ({ onClose, analyticsEventLabel = null }) => {
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();
  const { dispensary } = useDispensary();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { t } = useTranslation();
  const { openModal } = useModals();
  const { isMobileEcommApp, isEmbedded } = useUI();
  const user = useUser();
  const showErnie = useErnie();

  // eslint-disable-next-line consistent-return
  const handleLogin = async ({ email, password }) => {
    try {
      setLoading(true);
      const result = await consumerLogin(
        apolloClient,
        user,
        email,
        password,
        DutchiePayEnrollment,
        ShowEnrollment,
        isEmbedded
      );

      // This is an SSO login so will redirect
      if (result.success && result?.sso) {
        return null;
      }

      if (result.success) {
        trackAccountLogin(dispensary, user.id, analyticsEventLabel);
        const loginSuccessMessage = t('login.success', `You've been successfully logged in`);

        if (isMobileEcommApp) {
          callNativeMethod(`onAuthMessage`, { isSuccess: true, message: loginSuccessMessage });
        } else {
          showErnie(loginSuccessMessage, `success`);
        }
      } else {
        const message = _.get(
          result,
          `error.graphQLErrors[0].extensions.errors[0].detail`,
          t('login.fail', "You've entered an incorrect email or password. Please try again.")
        );
        return isMobileEcommApp
          ? callNativeMethod(`onAuthMessage`, { isSuccess: false, message })
          : showErnie(message, `danger`);
      }

      onClose(result.success);
    } catch (err) {
      console.error(err);
      if (isMobileEcommApp) {
        callNativeMethod(`onAuthMessage`, { isSuccess: false, message: err.message });
      } else {
        showErnie(err, `danger`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenForgotPasswordModal = () => {
    if (!isMobileEcommApp) {
      onClose();
    }
    openModal(ModalNames.resetPassword);
  };

  return (
    <Form onSubmit={handleLogin} validationSchema={loginFormValidation(t)}>
      <FormTextInput
        data-cy='login-form-email-input'
        data-test='login-form-email-input'
        data-testid='login-form-email-input'
        name='email'
        label={t('loginModal.emailLabel', 'Email')}
        variant='filled'
        autoComplete='email'
        type='email'
        required
      />

      <InputContainer mt={15}>
        <FormTextInput
          data-cy='login-form-password-input'
          data-test='login-form-password-input'
          data-testid='login-form-password-input'
          name='password'
          type='password'
          label='Password'
          variant='filled'
          autoComplete='current-password'
          required
        />
      </InputContainer>

      <LoginOptionsContainer>
        <ForgotPasswordText
          type='button'
          data-testid='login-form-forgot-password-link'
          onClick={handleOpenForgotPasswordModal}
        >
          {t('loginModal.forgotYourPassword', 'Forgot your password?')}
        </ForgotPasswordText>
        <StyledButton
          variant='flora'
          loading={loading}
          type='submit'
          size='medium'
          data-cy='login-form-submit-button'
          data-testid='login-form-submit-button'
        >
          {t('loginModal.login', 'Log in')}
        </StyledButton>
      </LoginOptionsContainer>
    </Form>
  );
};

export default LoginForm;

export const LoginOptionsContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  display: flex;
`;

export const SignUpText = styled(TextButton)`
  margin-left: 5px;
  ${({ xSmall }) => xSmall && `font-size: 11px;`}
`;

export const ForgotPasswordText = styled(TextButton)`
  display: flex;
  align-items: center;
  margin-left: 3px;
  color: ${({ theme }) => theme.colors.grey[45]};
  font-weight: bold;
  font-size: ${({ larger }) => (larger ? paragraph : subparagraph)};

  &:hover {
    text-decoration: none;
  }
`;

export const StyledButton = styled(Button)`
  width: 95px;
  background-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;

export const InputContainer = styled.div`
  ${width}
  ${space}
`;
