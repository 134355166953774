import { useEffect, useState } from 'react';

import { isWeightedProduct } from 'shared/helpers/products';
import { tracker } from 'utils/analytics';

import useUI from 'hooks/use-ui';
import useAddToCart from 'hooks/use-add-to-cart';
import useTranslation from 'hooks/use-translation';

import {
  UseAddToCartControlsProps,
  UseAddToCartControlsReturn,
  AddToCartDataParam,
  AddToCartHandler,
} from '../add-to-cart.types';

import { usePrerequisiteChecks } from './use-prerequisite-checks';

type UseAddToCartHandlerProps = Pick<
  AddToCartDataParam,
  'additionalOption' | 'dispensary' | 'product' | 'trackerSource'
> & {
  onClose: () => void;
};

function useAddToCartHandler({
  product,
  dispensary,
  additionalOption,
  trackerSource,
  onClose,
}: UseAddToCartHandlerProps): AddToCartHandler {
  const addToCart = useAddToCart(dispensary);
  const { isMobileEcommApp, openCartDrawer } = useUI();

  return async ({ option, quantity }) => {
    try {
      const added = await addToCart({ product, option, quantity, additionalOption, trackerSource });

      onClose();
      if (added) {
        tracker.addedProductToCart({ quantity, product, option, trackerSource });
        // we don't want to show any add-to-cart UI on mobile ecomm app bc we have native UI for it
        if (!isMobileEcommApp) {
          openCartDrawer();
        }
      }
    } catch (err) {
      // user cancelled
      throw new Error(err);
    }
  };
}

export function useAddToCartControls({ data, onClose }: UseAddToCartControlsProps): UseAddToCartControlsReturn {
  const { dispensary, product, option, quantity, additionalOption, trackerSource } = data;

  const [shouldSelectProductOption, setShouldSelectProductOption] = useState(false);

  const handleAddToCart = useAddToCartHandler({ dispensary, product, additionalOption, trackerSource, onClose });

  const handlePrerequisiteChecks = usePrerequisiteChecks(data);

  const { t } = useTranslation();
  const heading = shouldSelectProductOption ? t('select-weight', 'Select weight') : '';

  useEffect(() => {
    void (async (): Promise<void> => {
      if (!(await handlePrerequisiteChecks())) {
        onClose();
        return;
      }

      // full-add
      if (option && quantity) {
        void handleAddToCart({
          option,
          quantity,
        });
      } else if (!isWeightedProduct(product) && product.Options?.length) {
        // quick-add
        void handleAddToCart({
          option: product.Options[0],
          quantity: quantity ?? 1,
        });
      } else {
        // quick-add and select product options
        setShouldSelectProductOption(true);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only run once on mount

  return {
    heading,
    handleAddToCart,
    shouldSelectProductOption,
  };
}
