import React, { forwardRef } from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { Button, ModalContainer } from 'components/core';
import ClosedSign from 'assets/closed-sign.jsx';

const PausedOrdersModal = forwardRef(({ onClose, containerProps }, ref) => {
  const { Trans } = useTranslation();
  return (
    <ModalContainer ref={ref} isOpen width={495} {...containerProps}>
      <ClosedSign />
      <DispensaryClosedText>
        <Trans i18nKey='pausedOrders.sorryTempClosed'>
          Sorry, online ordering is temporarily closed due to extremely high demand.
        </Trans>
      </DispensaryClosedText>
      <Container>
        <OrderTypeText>
          <Trans i18nKey='pausedOrders.stayTuned'>Stay tuned though, we'll reopen soon!</Trans>
        </OrderTypeText>
        <StyledButton mt='25px' onClick={onClose} autoFocus>
          <Trans i18nKey='pausedOrders.gotIt'>Got it!</Trans>
        </StyledButton>
      </Container>
    </ModalContainer>
  );
});

export default PausedOrdersModal;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const DispensaryClosedText = styled.h1`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin-top: 30px;
  margin-bottom: 25px;
`;

const OrderTypeText = styled.p`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[60]};
  line-height: 22px;
  text-align: left;
`;

const StyledButton = styled(Button)`
  width: fit-content;
`;
