/* eslint-disable max-len */
import React from 'react';

const BoltIcon = (props): JSX.Element => (
  <svg width='14' height='21' viewBox='0 0 14 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.00017 0.254883V8.25488H12.8649C13.7128 8.25488 14.1759 9.24374 13.6332 9.89507L5 20.2549V12.2549H1.13506C0.287223 12.2549 -0.175932 11.266 0.366849 10.6147L9.00017 0.254883Z'
      fill='url(#paint0_linear_4325_17070)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_4325_17070'
        x1='0.733062'
        y1='6.50488'
        x2='14.1843'
        y2='7.27868'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#95D2C3' />
        <stop offset='1' stopColor='#004232' />
      </linearGradient>
    </defs>
  </svg>
);

export default BoltIcon;
