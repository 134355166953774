import React from 'react';
import InfoIconFilled from 'src/assets/info-icon-filled';
import styled, { css } from 'styled-components';
import { Typography } from 'src/typography';

import { getColors } from 'src/theme';

const themeColors = getColors();

export function Notification({ children }): JSX.Element {
  return (
    <Container>
      <Icon>
        <InfoIconFilled width={16} height={16} color={themeColors.blue[80]} />
      </Icon>
      <Message size='small'>{children}</Message>
    </Container>
  );
}

const Container = styled.div`
  ${({ theme: { colors, spaces } }) => css`
    display: flex;
    gap: ${spaces[2]};
    background-color: ${colors.blue10};
    padding: ${spaces[4]};
    width: 100%;
    border-radius: 8px;
    text-align: left;
  `}
`;

const Icon = styled.div`
  padding-top: 2px;
`;

const Message = styled(Typography.Body)`
  ${({ theme: { colors } }) => css`
    color: ${colors.blue80};
  `}
`;
