import React from 'react';

import useTranslation from 'hooks/use-translation';

import { MobileOnly } from 'shared/components';
import { BottomDrawer } from 'components/core/bottom-drawer';

import { BrandsFilterDrawer } from '../brands-filter-drawer';
import { useMarketplaceBrandsFilter } from './use-marketplace-brands-filter';
import { ModalComponentProps, withModalControlsHook } from '../../with-modal-controls-hook';

export function MarketplaceBrandsFilterDrawerWrapper({ open, onClose }: ModalComponentProps): JSX.Element {
  const { t } = useTranslation();
  const {
    loading,
    inputValue,
    filteredOptions,
    handleSave,
    handleChangeInputValue,
    handleClearInputValue,
    handleClear,
    handleToggle,
    handleIsSelected,
  } = useMarketplaceBrandsFilter();

  return (
    <MobileOnly mediaQuery='md'>
      <BottomDrawer open={open} onClose={onClose} heading={t('brands', 'Brands')}>
        <BrandsFilterDrawer
          loading={loading}
          inputValue={inputValue}
          filteredOptions={filteredOptions}
          handleSave={handleSave}
          handleChangeInputValue={handleChangeInputValue}
          handleClearInputValue={handleClearInputValue}
          handleClear={handleClear}
          handleToggle={handleToggle}
          handleIsSelected={handleIsSelected}
        />
      </BottomDrawer>
    </MobileOnly>
  );
}

export const [MarketplaceBrandsFilterDrawerController, useMarketplaceBrandsFilterDrawer] = withModalControlsHook(
  MarketplaceBrandsFilterDrawerWrapper
);
