import { useEffect } from 'react';

import useCart from 'src/hooks/use-cart';
import useSearchPreferences from 'src/hooks/use-search-preferences';
import { useModals, ModalNames } from 'components/modals';

export type AddressBounds = {
  lat: string;
  lon: string;
  radius: string;
};

export function useAddressBounds(): AddressBounds | null {
  const { address, isReady } = useCart();
  const { distance } = useSearchPreferences();
  const { openModal } = useModals();

  const lat = address?.lat?.toString();
  const lon = address?.lng?.toString();
  const radius = `${distance as number}mi`;

  const hasBounds = Boolean(lat && lon && radius);

  useEffect(() => {
    if (isReady && !hasBounds) {
      void openModal(ModalNames.addressRequired);
    }
  }, [isReady, hasBounds, openModal]);

  return hasBounds ? { lat, lon, radius } : null;
}
