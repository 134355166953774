import React from 'react';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { ModalObserver } from '../modal-observer';
import { EmptyCartModal } from './empty-cart-modal';

export const EmptyCart = ({ name }) => (
  <ModalObserver name={name}>
    {(props) => (
      <ModalWrapper {...props}>
        <EmptyCartModal {...props} />
      </ModalWrapper>
    )}
  </ModalObserver>
);
