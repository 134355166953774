import { useRouter } from 'next/router';
import useMenuFilterOptions from './use-menu-filter-options';

type Subcategory = { key: string; label: string; value: string };

type UseDispensarySubcategoryReturn = {
  subcategory: string | null;
  loading: boolean;
};

export function useDispensarySubcategory(): UseDispensarySubcategoryReturn {
  const { subcategories, apolloValues } = useMenuFilterOptions({ skipCurrentFilters: true });
  const { loading } = apolloValues;

  const {
    query: { subcategory: subcategoryFromPath, subcategories: subcategoriesQueryParam },
  } = useRouter();

  const subcategory = subcategoryFromPath ?? subcategoriesQueryParam;
  const subcategoryExists =
    Array.isArray(subcategories) &&
    (subcategories as Subcategory[]).some((subcategoryFromFilters) => subcategoryFromFilters.key === subcategory);

  if (!subcategory || typeof subcategory !== 'string' || !subcategoryExists) {
    return { subcategory: null, loading };
  }

  return { subcategory, loading };
}
