import { GraphqlError } from 'src/errors';
import useUI from 'hooks/use-ui';
import { useCheckAgeVerificationQuery, GqlDispensaries } from 'types/graphql';

type UseCheckAgeVerificationProps = {
  dispensary: GqlDispensaries | null;
  userId: string | undefined;
};

type UseCheckAgeVerificationReturn = {
  isAgeVerified: boolean | undefined;
  loading: boolean;
};

export function useCheckAgeVerification({
  dispensary,
  userId,
}: UseCheckAgeVerificationProps): UseCheckAgeVerificationReturn {
  const { isEmbedded, isStoreFront } = useUI();
  const isAgeVerificationSettingEnabled = dispensary?.storeSettings.hardAgeGateAgeVerification ?? false;
  const isWrongVariant = !isEmbedded && !isStoreFront;

  const skip = isWrongVariant || !isAgeVerificationSettingEnabled || !userId || !dispensary?.id;
  const variables = {
    dispensaryId: dispensary?.id ?? '',
  };

  const { data, loading, error } = useCheckAgeVerificationQuery({
    fetchPolicy: `no-cache`,
    skip,
    variables,
  });

  if (error) {
    throw new GraphqlError(error);
  }

  const isLoading = loading || skip;

  return { isAgeVerified: data?.checkAgeVerification.isAgeVerified, loading: isLoading };
}
