import React from 'react';

import { BottomDrawer } from 'components/core';
import { ShoppingWithModalProps } from './shopping-with.types';
import { ShoppingWith } from './shopping-with';

export const ShoppingWithDrawer = ({
  dispensary,
  handleContinue,
  onClose,
  open,
}: ShoppingWithModalProps): JSX.Element => (
  <BottomDrawer open={open}>
    <ShoppingWith buttonSize='large' dispensary={dispensary} handleContinue={handleContinue} onClose={onClose} />
  </BottomDrawer>
);
