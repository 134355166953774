import get from 'lodash/get';
import set from 'lodash/set';
import { reaction, autorun } from 'mobx';

/*
This was added because the existing checkout code expected several
values to be on the cart which are not there in v3. This was added
so that those values can be two-way bound from cart to where they
actually live. This could be removed after some checkout code
refactoring.

tldr: Don't use this.
*/

export default function useMobxDataBind(storeA, storeB, pathA, pathB = pathA) {
  const updateStore = (store, path) => (value) => {
    if (get(store, path) !== value) {
      set(store, path, value);
    }
  };

  reaction(() => get(storeA, pathA), updateStore(storeB, pathB));
  reaction(() => get(storeB, pathB), updateStore(storeA, pathA));
  autorun(() => set(storeB, pathB, get(storeA, pathA)));
}
