import { useCallback } from 'react';

import {
  useProductRecommendations,
  GetRecommendations,
  GetRecommendationsFallback,
  Options,
} from './use-product-recommendations';

import { getDispensaryFilter } from './recommendations.utils';
import { Scenarios } from './recommendations.types';

export function useSimilarItems(
  getRecommendationsFallback: GetRecommendationsFallback,
  itemId: string,
  scenario: Scenarios,
  options: Options
): ReturnType<typeof useProductRecommendations> {
  const { limit } = options;

  const getRecommendations = useCallback<GetRecommendations>(
    (client, dispensary) =>
      client.getSimilarItems(itemId, {
        scenario,
        amt: limit,
        filters: [getDispensaryFilter(dispensary)],
      }),
    [itemId, scenario, limit]
  );

  return useProductRecommendations(getRecommendations, getRecommendationsFallback, {
    ...options,
    excludeProducts: [itemId],
  });
}
