import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';

import useErnie from 'shared/hooks/use-ernie';
import useUser from 'shared/hooks/use-user';
import useTranslation from 'hooks/use-translation';

import { ModalContainer, Button, ModalExitIcon } from 'components/core';
import { ModalContent } from 'components/modals/components';
import { TextInput } from 'shared/components';
import { ModalCopy, ModalSecondaryHeader } from 'shared/modals';
import { useUpdateEmail } from 'shared/actions/users';
import type { ActionReturnValue } from 'shared/actions/users';

import { ContainerProps } from '../modal-observer';

type ChangeEmailPasswordPromptProps = {
  containerProps: ContainerProps;
  onClose: (procced: boolean) => void;
};

export const ChangeEmailPasswordPrompt = forwardRef(
  ({ containerProps, onClose }: ChangeEmailPasswordPromptProps, ref) => {
    const [password, setPassword] = useState('');
    const showErnie = useErnie();
    const User = useUser();
    const { t } = useTranslation();
    const updateEmail = useUpdateEmail();

    const handlePasswordChange = async (): Promise<ActionReturnValue> => {
      try {
        const results = await updateEmail(User.email, password);
        if (!results.success) {
          showErnie(
            t(
              'ernie.changeEmailPassword',
              "Sorry you've entered the incorrect password, please try again or contact support."
            ),
            `error`
          );
        }
        return results;
      } catch (e) {
        showErnie(
          t(
            'ernie.changeEmailPassword',
            "Sorry you've entered the incorrect password, please try again or contact support."
          ),
          `error`
        );

        return { success: false };
      }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();
      const shouldProceed = await handlePasswordChange();
      onClose(shouldProceed.success);
    };

    return (
      <ModalContainer
        ref={ref}
        data-testid='change-email-modal'
        onRequestClose={() => onClose(false)}
        p={0}
        height={300}
        width={420}
        {...containerProps}
      >
        <ModalExitIcon onClick={() => onClose(false)} />
        <StyledModalContent>
          <form onSubmit={(e) => handleSubmit(e)}>
            <ModalSecondaryHeader lowercase mb='13px'>
              {t('changeEmail.enterPassword', 'Enter Your Password')}
            </ModalSecondaryHeader>
            <ModalCopy>
              {t('changeEmail.toChange', "To change your email address, enter your password below to verify it's you.")}
            </ModalCopy>
            <TextInput
              name='password'
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type='password'
              data-testid='password-input'
              required
            />
            <Button data-testid='change-email-button'>{t('changeEmail.confirm', 'Confirm')}</Button>
          </form>
        </StyledModalContent>
      </ModalContainer>
    );
  }
);

const StyledModalContent = styled(ModalContent)`
  display: flex;
  place-content: center;
  place-items: center;
  margin: 30px auto 0 auto;
  width: 100%;
  height: 100%;
  form {
    display: flex;
    flex-direction: column;
  }
  button {
    max-width: 88px;
    margin-left: auto;
    margin-right: auto;
  }
  input {
    max-width: 250px;
  }
  button {
    margin-top: 20px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
`;
