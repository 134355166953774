import { useObserver } from 'mobx-react';
import _ from 'lodash';
import useCart from 'hooks/use-cart';
import useCustomerState from 'hooks/use-customer-state';
import useErnie from 'shared/hooks/use-ernie';
import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import useSearchPreferences from 'hooks/use-search-preferences';

import { useModals, ModalNames } from 'components/modals';
import { checkAllLimits } from 'shared/helpers/purchase-limits';
import { cartItemId } from 'utils/helpers/product';
import { getCartAdditionAccess } from 'utils/helpers/dispensary';
import { getQuantityRemaining } from 'shared/helpers/products';
import { getFirstAvailablePickupOption } from 'shared/helpers/dispensaries';
import { isMedOnly, isRecOnly } from 'shared/core/helpers/dispensaries';
import { MENU_TYPE_MED, MENU_TYPE_REC, ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY } from 'shared/constants';
import { useValidateDeliveryAddress } from './use-validate-delivery-address';

export default function useAddToCart(...options) {
  const Cart = useCart();
  const UI = useUI();
  const { openModal } = useModals();
  const SearchPreferences = useSearchPreferences();
  const showErnie = useErnie();
  const customerState = useCustomerState();
  const isCartEmpty = useObserver(() => Cart.isEmpty);
  const orderTypePreference = useObserver(() => SearchPreferences.orderType);
  const { t } = useTranslation();
  const validateDeliveryAddress = useValidateDeliveryAddress();
  const [dispensary] = [...options];

  const addToCart = async (...cartItem) => {
    const [item] = [...cartItem];
    const id = cartItemId(item);
    const cartDispoId = _.get(Cart, `dispensary.id`, false);
    const cartAdditionAccess = getCartAdditionAccess(dispensary, UI.previewMode);

    const { isKiosk, isDutchieMain, isMobileEcommApp } = UI;

    if (UI.viewOnlyMode) {
      return false;
    }

    // inactive dispensary, not preview mode
    if (dispensary.status !== 'open' && !UI.previewMode) {
      showErnie(t('menuActions.dispensaryClosed', 'Sorry, this dispensary is not available for online orders yet!'));
      return false;
    }

    // this should never happen in kiosk, probably qa or local dev, but lets be safe anyway
    if (!isCartEmpty && isKiosk && cartDispoId && cartDispoId !== dispensary.id) {
      Cart.clearOrder();
    }

    // ordering paused
    if (dispensary.ordersArePaused) {
      UI.openPausedOrdersModal();
      return false;
    }

    // closed, after hours unavailable
    if (cartAdditionAccess === 'disabled') {
      UI.showClosedModal = true;
      return false;
    }

    // quantity check
    const matchingProduct = _.find(
      Cart.items,
      // eslint-disable-next-line lodash/matches-shorthand
      (itemInCart) => itemInCart.product.id === item.product.id && itemInCart.option === item.option
    );

    // set cart menu type
    const medOnly = isMedOnly(dispensary);
    const recOnly = isRecOnly(dispensary);

    if (medOnly && !Cart.isMedical) {
      Cart.setMenuType(MENU_TYPE_MED);
    } else if (recOnly && Cart.isMedical) {
      Cart.setMenuType(MENU_TYPE_REC);
    }

    if (matchingProduct) {
      const quantityAvailable = getQuantityRemaining(item.product, item.option, { isKiosk });
      const quantityInCart = parseInt(matchingProduct.quantity, 10);
      const newQuantity = parseInt(quantityInCart, 10) + parseInt(item.quantity, 10);
      let qtyErr = false;
      if (quantityAvailable <= quantityInCart) {
        // one more item was added and it exceeds the quantity remaining with respect to the current cart contents
        qtyErr = `Sorry! You've added the ${quantityAvailable} remaining ${item.product.Name} ${
          item.option && item.option !== 'N/A' ? `${item.option} ` : ''
        }to your cart already.`;
      } else if (quantityAvailable < newQuantity) {
        // more than one item was added and that puts the cart total over the quantity remaining for that item
        qtyErr = `Sorry! Only ${quantityAvailable} of the ${item.product.Name} ${
          item.option && item.option !== 'N/A' ? `${item.option} ` : ''
        }remain in stock. Please choose a lower quantity and try again.`;
      } else if (!Cart.isMedical && item.product.medicalOnly) {
        // med-only item trying to be added to a recreational order
        qtyErr = t(
          'menuActions.addMedicalOnly',
          'Sorry! This item is medical-only and cannot be added to a recreational order.'
        );
      } else if (Cart.isMedical && item.product.recOnly) {
        // rec-only item trying to be added to a medical order
        qtyErr = t(
          'menuActions.addRecOnly',
          'Sorry! This item is recreational-only and cannot be added to a medical order.'
        );
      }
      if (qtyErr) {
        showErnie(qtyErr);
        return false;
      }
    }

    const limitsCheck = checkAllLimits({
      newItemId: id,
      itemBeingAdded: item,
      existingItems: Cart.items,
      medical: Cart.isMedical,
      dispensary,
      customerState,
    });

    // cart exceeds limits
    if (!limitsCheck.withinLimits) {
      showErnie(limitsCheck.message);
      return false;
    }

    // dutchie main or no order type set, empty cart, handle single vs. multiple services
    if ((isDutchieMain || !Cart.orderType) && isCartEmpty) {
      if (dispensary.offerAnyPickupService && dispensary.orderTypesConfig.delivery.enabled) {
        if (isKiosk) {
          Cart.setOrderType(ORDER_TYPE_PICKUP);
        } else if (isDutchieMain || isMobileEcommApp) {
          Cart.setOrderType(orderTypePreference);
        }
      } else {
        const firstAvailableOrderType = dispensary.offerAnyPickupService
          ? getFirstAvailablePickupOption(dispensary)
          : ORDER_TYPE_DELIVERY;

        Cart.setOrderType(firstAvailableOrderType);
      }
    }

    if (Cart.orderType === ORDER_TYPE_DELIVERY) {
      await validateDeliveryAddress();
    }

    Cart.addItem(dispensary, item, id);
    Cart.analyticsProductAddedToCart(item);
    if (!Cart.orderType && isCartEmpty) {
      await openModal(ModalNames.orderType);
    }

    // Reset the tracking source since the product is added to the cart
    UI.activeProductSource = null;

    return true;
  };

  return addToCart;
}
