import { useMemo } from 'react';
import { AggregateTrackerConfig } from 'utils/analytics/trackers/aggregate-tracker';
import { isInternalGTMEnabled, isSiftEnabled } from 'utils/analytics/helpers/is-enabled';
import PublicEnv from 'shared/utils/public-env';

/**
 * Provides the configuration used by the aggregate tracker to create individual trackers.
 */
export const useTrackerConfig = (): AggregateTrackerConfig | null => {
  const siftEnabled = isSiftEnabled();
  const internalGTMEnabled = isInternalGTMEnabled();
  const { siftBeaconKey } = PublicEnv;
  const trackerConfigs = useMemo(
    () => ({
      internalGTMEnabled,
      siftBeaconKey,
      siftEnabled,
    }),
    [internalGTMEnabled, siftBeaconKey, siftEnabled]
  );

  return trackerConfigs;
};
