import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _reduce from "lodash/reduce";
import _isEmpty from "lodash/isEmpty";
import _set from "lodash/set";
import _forEachRight from "lodash/forEachRight";
import _times from "lodash/times";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// import Big from 'big.js';
export var compareStowedDiscounts = function compareStowedDiscounts(_ref) {
  var bogoCalcsData = _ref.bogoCalcsData,
      detail = _ref.detail,
      stowedDiscounts = _ref.stowedDiscounts,
      globalSpecialsSettings = _ref.globalSpecialsSettings;
  var discountsChosen = {};
  var nonStackingBehavior = globalSpecialsSettings.nonStackingBehavior;
  var saleAdjustments = {};
  var stackingDiscounts = {};
  var lostBogoDiscountCount = 0;
  var discountToCartDefeated = false; // Returns true if the challenging discount wins, but false if the champion discount holds its title

  var compareDiscounts = function compareDiscounts(champion, challenger) {
    var _champion$discountDat, _champion$discountDat2, _challenger$discountD, _challenger$discountD2, _challenger$discountD3, _challenger$discountD4;

    var championNonStackingBehavior = (_champion$discountDat = champion === null || champion === void 0 ? void 0 : (_champion$discountDat2 = champion.discountData) === null || _champion$discountDat2 === void 0 ? void 0 : _champion$discountDat2.nonStackingBehavior) !== null && _champion$discountDat !== void 0 ? _champion$discountDat : nonStackingBehavior;
    var challengerNonStackingBehavior = (_challenger$discountD = challenger === null || challenger === void 0 ? void 0 : (_challenger$discountD2 = challenger.discountData) === null || _challenger$discountD2 === void 0 ? void 0 : _challenger$discountD2.nonStackingBehavior) !== null && _challenger$discountD !== void 0 ? _challenger$discountD : nonStackingBehavior;
    var challengerPosStackingBehavior = (challenger === null || challenger === void 0 ? void 0 : (_challenger$discountD3 = challenger.discountData) === null || _challenger$discountD3 === void 0 ? void 0 : _challenger$discountD3.discountMode) || (challenger === null || challenger === void 0 ? void 0 : (_challenger$discountD4 = challenger.discountData) === null || _challenger$discountD4 === void 0 ? void 0 : _challenger$discountD4.stackingMode); // This checks if the standing and incoming specials have the same nonStackingBehavior, if not then we see if it
    // has "favorDispensary". This is in case conflicting nonStackingBehavior's are up against each other, then we
    // favor the favorDispensary since that would be less fallout than if we discounted too much.

    var challengerHasValidNonStackingBehavior = championNonStackingBehavior === challengerNonStackingBehavior || championNonStackingBehavior !== challengerNonStackingBehavior && challengerNonStackingBehavior === 'favorDispensary';
    var challengerQualifiesForGTcomparison = challengerHasValidNonStackingBehavior && (challengerNonStackingBehavior === 'favorCustomer' || challengerPosStackingBehavior === 'non-stacking');
    var challengerQualifiesForLTcomparison = challengerHasValidNonStackingBehavior && challengerNonStackingBehavior === 'favorDispensary';
    return !!(!champion || challengerQualifiesForGTcomparison && challenger.individualDiscount.gt(champion.individualDiscount) || challengerQualifiesForLTcomparison && challenger.individualDiscount.lt(champion.individualDiscount));
  };

  _times(detail.quantity, function () {
    var _winningDiscount, _winningDiscount2;

    var bogoDefeated = false;
    var losingDiscount;
    var saleRunnerUp;
    var winningDiscount;

    _forEachRight(stowedDiscounts, function (discount) {
      var _discount$discountDat, _discount$discountDat2;

      var stackingEnabled = ((_discount$discountDat = discount.discountData) === null || _discount$discountDat === void 0 ? void 0 : _discount$discountDat.stackingMode) === 'stacking' || discount.discountData.discountStacking;

      if (stackingEnabled || (_discount$discountDat2 = discount.discountData) !== null && _discount$discountDat2 !== void 0 && _discount$discountDat2.isDiscountToCartReward) {
        var _stackingDiscounts$di;

        stackingDiscounts[discount.key] = _objectSpread(_objectSpread({}, discount), {}, {
          // We Math.min as a safety net to not add more qty to the discount than is calculated and saved in
          // applicableQuantity
          count: Math.min((((_stackingDiscounts$di = stackingDiscounts[discount.key]) === null || _stackingDiscounts$di === void 0 ? void 0 : _stackingDiscounts$di.count) || 0) + 1, discount.applicableQuantity)
        });
      } else {
        var _discountsChosen$disc, _losingDiscount, _losingDiscount2;

        // Otherwise, compare the discount with other non-stacking specials
        if ((((_discountsChosen$disc = discountsChosen[discount.key]) === null || _discountsChosen$disc === void 0 ? void 0 : _discountsChosen$disc.count) || 0) < discount.applicableQuantity) {
          if (compareDiscounts(winningDiscount, discount)) {
            if (winningDiscount) {
              losingDiscount = winningDiscount;
            }

            winningDiscount = discount;
          } else {
            losingDiscount = discount;
          }
        }

        if (((_losingDiscount = losingDiscount) === null || _losingDiscount === void 0 ? void 0 : _losingDiscount.type) === 'bogo' && losingDiscount.key !== winningDiscount.key) {
          var _losingDiscount$disco;

          if (!((_losingDiscount$disco = losingDiscount.discountData) !== null && _losingDiscount$disco !== void 0 && _losingDiscount$disco.isDiscountToCartReward)) {
            bogoDefeated = true;
          } else {
            var _bogoCalcsData$defeat;

            /*
              We dont' want to add this product to the defeatedRewardSatisfiers like
              when a normal bogo is defeated because then the reward that beat this
              discount to cart reward will be eligible to apply again in the call to
              redetermine bogo discounts. We will instead track this defeated DTC offer
              in it's own structure on the bogoCalcsData: defeatedDiscountToCartRewards
             */
            discountToCartDefeated = true;
            /*
              this structure will be used when the rewards are redetermined to remove this
              product from the list that we spread the discount across
            */

            bogoCalcsData.defeatedDiscountToCartRewards = (_bogoCalcsData$defeat = bogoCalcsData.defeatedDiscountToCartRewards) !== null && _bogoCalcsData$defeat !== void 0 ? _bogoCalcsData$defeat : {};
            bogoCalcsData.defeatedDiscountToCartRewards[losingDiscount.discountData.rewardId] = _defineProperty({}, detail.key, {
              item: detail
            });
          }
        } else if (((_losingDiscount2 = losingDiscount) === null || _losingDiscount2 === void 0 ? void 0 : _losingDiscount2.type) === 'sale') {
          saleRunnerUp = compareDiscounts(saleRunnerUp, discount) ? discount : saleRunnerUp;
        }
      }
    });

    if (bogoDefeated) {
      lostBogoDiscountCount += 1;
    } // We need to adjust cart, cart total, and summary items at checkout if sales are defeated by bogos or coupons


    if (saleRunnerUp && (((_winningDiscount = winningDiscount) === null || _winningDiscount === void 0 ? void 0 : _winningDiscount.type) === 'bogo' || ((_winningDiscount2 = winningDiscount) === null || _winningDiscount2 === void 0 ? void 0 : _winningDiscount2.type) === 'coupon')) {
      var _saleAdjustments$sale;

      saleAdjustments[saleRunnerUp.key] = {
        discount: saleRunnerUp,
        count: (((_saleAdjustments$sale = saleAdjustments[saleRunnerUp.key]) === null || _saleAdjustments$sale === void 0 ? void 0 : _saleAdjustments$sale.count) || 0) + 1
      };
    }

    if (winningDiscount) {
      var _discountsChosen$winn;

      discountsChosen[winningDiscount.key] = _objectSpread(_objectSpread({}, winningDiscount), {}, {
        count: (((_discountsChosen$winn = discountsChosen[winningDiscount.key]) === null || _discountsChosen$winn === void 0 ? void 0 : _discountsChosen$winn.count) || 0) + 1
      });
    }
  });

  if (lostBogoDiscountCount > 0) {
    _set(bogoCalcsData, 'defeatedRewardsSatisfiers', _defineProperty({}, detail.key, {
      item: detail,
      quantity: lostBogoDiscountCount,
      discountToCartDefeated: discountToCartDefeated
    }));

    bogoCalcsData.redetermineDiscounts = true;
  }

  if (discountToCartDefeated) {
    bogoCalcsData.redetermineDiscounts = true;
  }

  if (!_isEmpty(saleAdjustments)) {
    detail.saleAdjustments = saleAdjustments;
  }

  return {
    bogoCalcsData: bogoCalcsData,
    detail: detail,
    stowedDiscounts: _reduce(stowedDiscounts, function (winningDiscounts, discount) {
      var _stackingDiscounts$di2, _discountsChosen$disc2;

      var discountCount = ((_stackingDiscounts$di2 = stackingDiscounts[discount.key]) === null || _stackingDiscounts$di2 === void 0 ? void 0 : _stackingDiscounts$di2.count) || ((_discountsChosen$disc2 = discountsChosen[discount.key]) === null || _discountsChosen$disc2 === void 0 ? void 0 : _discountsChosen$disc2.count) || 0;

      if (discountCount > 0) {
        winningDiscounts.push(_objectSpread(_objectSpread({}, discount), {}, {
          // This Math.min here is another safety net but includes both stacking and non-stacking specials
          quantityToApply: Math.min(discountCount, discount.applicableQuantity),
          type: discount.type
        }));
      }

      return winningDiscounts;
    }, [])
  };
};