import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { ModalContainer, ModalExitIcon } from 'components/core';
import EmptyLoadingState from 'components/empty-loading-state';
import DispensaryHeader from './dispensary-header';
import OrderSummary from './order-summary';
import OrderDetails from './order-receipt-details-desktop';
import OrderTotals from '../order-totals';

export const OrderReceiptDesktop = forwardRef(
  ({ dispensary, onClose, order, loading, handleItemClick, ...props }, ref) => {
    const { taxConfig } = dispensary || {};

    return (
      <Container ref={ref} width={740} isLoading={loading} {...props}>
        <ModalExitIcon onClick={onClose} />

        <EmptyLoadingState isLoading={loading} page='default'>
          <ModalContent>
            {/* Dispensary Header */}
            <DispensaryHeader dispensary={dispensary} />

            {/* Products */}
            <OrderSummary order={order} handleItemClick={handleItemClick} />

            <BottomWrapper>
              {/* Delivery / Pickup Info */}
              <OrderDetails order={order} />

              {/* Subtotals */}
              <OrderTotalsContainer>
                <OrderTotals order={order} taxConfig={taxConfig} />
              </OrderTotalsContainer>
            </BottomWrapper>
          </ModalContent>
        </EmptyLoadingState>
      </Container>
    );
  }
);

const Container = styled(ModalContainer)`
  padding: 40px;
  margin: 100px auto;
  transform: none;
  height: auto;
  overflow: auto;
  top: 0;

  ${({ isLoading }) =>
    isLoading &&
    css`
      display: flex;
      align-items: center;
      min-height: 464px;
    `}
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const OrderTotalsContainer = styled.div`
  width: 100%;
  padding-left: 78px;
`;
