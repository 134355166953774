import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import usePaths from 'hooks/use-paths';
import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';
import { AmplitudeEvents, useAmplitude } from 'shared/hooks/use-amplitude';

export default function useGoToCheckout(location) {
  const cart = useCart();
  const UI = useUI();
  const isKiosk = useObserver(() => UI.isKiosk);
  const router = useRouter();
  const amplitude = useAmplitude();
  const { href: checkoutHref, route: checkoutRoute } = usePaths({ checkout: true });

  function resetState() {
    cart.mostRecentProduct = null;
  }

  return () => {
    if (isKiosk) {
      UI.showKioskCheckoutModal = true;
      resetState();
    } else {
      router.push(checkoutRoute, checkoutHref);
      // this never unlistens, right? consider refacotring...
      router.events.on(`routeChangeComplete`, resetState);
    }

    amplitude.log(AmplitudeEvents.checkout.goToCheckout, {
      location,
      dispensaryId: cart?.dispensary?.id,
      dispensaryName: cart?.dispensary?.name,
      menuVariant: UI.analyticsMenuVariant,
    });

    return true;
  };
}
