import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'shared/helpers/utils';

import useTranslation from 'hooks/use-translation';

export default function CartPriceMinimum(props) {
  const { t } = useTranslation();
  const { className, minimumInDollars, value } = props;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value) || isNaN(minimumInDollars) || value > minimumInDollars) {
    return null;
  }
  const difference = minimumInDollars - value;
  const formattedMinimum = formatCurrency(minimumInDollars, { trimZeroCents: true });

  return (
    <StyledPriceMinimum className={className}>
      <BlueText>
        {t('common.add', 'Add')}&nbsp;${difference.toFixed(2)}
      </BlueText>
      &nbsp;
      {t('common.toMeetMinimum', 'to meet the minimum of')}
      &nbsp;{formattedMinimum}
    </StyledPriceMinimum>
  );
}

const StyledPriceMinimum = styled.div``;

const BlueText = styled.span`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-weight: bold;
`;
