import React, { useRef, useLayoutEffect, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { color, flexbox, layout } from 'styled-system';
import { useObserver } from 'mobx-react-lite';
import ResizeDetector from 'element-resize-detector';

import useBackwardsCompatibility from 'hooks/use-backwards-compatibility';
import useUI from 'hooks/use-ui';
import { useDispensaryGA } from 'hooks/use-google-analytics';
import { useDispensaryTagManager } from 'hooks/use-google-tag-manager';
import { useSpecialsClickTracking } from 'hooks/use-specials-click-tracking';
import useViewport from 'hooks/use-viewport';
import { isBrowser } from 'utils/misc-utils';

function useElementEffect(fn, element, deps) {
  let hasRun = false;
  let id = null;
  const cancel = () => {
    clearTimeout(id);
  };
  const attemptToRun = () => {
    if (hasRun) {
      return cancel;
    }
    if (element.current) {
      hasRun = true;
      cancel();
      return fn(element.current);
    }
    cancel();
    id = setTimeout(attemptToRun, 100);
    return cancel;
  };
  useLayoutEffect(attemptToRun, [element.current, ...deps]);
}

export default function Wrapper(props) {
  // Can be safely removed once we have fully transitioned to consumer 2.0
  useBackwardsCompatibility();
  useSpecialsClickTracking();

  const { children } = props;
  const router = useRouter();
  const UI = useUI();
  const isIframeSDK = useObserver(() => UI.isIframeSDK);
  const iframeHeightOverride = useObserver(() => UI.iframeHeightOverride);
  const containerRef = useRef();
  const resizeDetectorRef = useRef();
  const { height = 0, offsetTop = 0 } = useViewport();

  const minHeight = height - Math.max(0, offsetTop);

  const handleResize = useCallback(() => {
    // +4 is for safari
    const newHeight = document.body.clientHeight + 4;

    if (UI.iframeHeightOverride && UI.iframeHeight !== UI.iframeHeightOverride) {
      UI.iframeHeight = UI.iframeHeightOverride;
    }

    if (!UI.iframeHeightOverride && UI.iframeHeight !== newHeight) {
      UI.embeddedEventDispatcher('iframe:resize', { height: newHeight });
      UI.iframeHeight = newHeight;
    }
  }, [UI]);

  useDispensaryGA();
  useDispensaryTagManager();

  useEffect(() => {
    if (!isIframeSDK) {
      return undefined;
    }
    function handleScroll() {
      if (containerRef.current) {
        handleResize(containerRef.current);
      }
      window.scrollTo(0, 0);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [isIframeSDK, handleResize]);

  useElementEffect(
    (element) => {
      if (!element || !isIframeSDK || !isBrowser()) {
        return undefined;
      }
      if (!resizeDetectorRef.current) {
        resizeDetectorRef.current = ResizeDetector({
          strategy: `scroll`,
        });
      }
      const erd = resizeDetectorRef.current;
      const el = element;

      erd.listenTo(el, handleResize);
      handleResize(el);

      return () => erd.uninstall(el);
    },
    containerRef,
    [resizeDetectorRef.current, isIframeSDK]
  );

  useElementEffect(handleResize, containerRef, [router.asPath, iframeHeightOverride]);

  return (
    <Container ref={containerRef} style={{ minHeight }} isKiosk={UI.isKiosk}>
      <Content {...props}>{children}</Content>
    </Container>
  );
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 100%;
  ${({ isKiosk }) => !isKiosk && `overflow: hidden;`}
  // In Windows and Linux, the iframe causes a scrollbar to appear and disappear
  // erratically in some breakpoints. We don't want to show that ever.
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1 0 0%;
  min-width: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  ${color}
  ${flexbox}
  ${layout}
`;
