import React from 'react';
import styled, { css } from 'styled-components';

import Imgix from 'shared/components/imgix';
import { DispensaryOrderingDetails } from 'src/components/dispensary-ordering-details';

import { DispensaryInfoProps } from '../select-store.types';

export function DispensaryInfo({ dispensary, isDesktop = false }: DispensaryInfoProps): JSX.Element {
  const { name, listImage } = dispensary;

  return (
    <Container isDesktop={isDesktop}>
      <ImageContainer>
        <Image height={isDesktop ? 73 : 52} htmlAttributes={{ alt: name }} src={listImage} />
      </ImageContainer>
      <DispensaryOrderingDetails variant={isDesktop ? 'small' : 'xs'} dispensary={dispensary} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;

  ${({ isDesktop }) =>
    isDesktop
      ? css`
          .offering-info-container > span {
            font-size: 13px;
          }

          .service-info-container {
            margin-top: 1px;

            span {
              font-size: 11px;
            }
          }
        `
      : css`
          p {
            font-size: 14px;
          }

          span {
            font-size: 11px;
          }

          .service-info-container {
            margin-top: 0px;
          }
        `}
`;

const ImageContainer = styled.div`
  position: relative;
  margin-right: 20px;
`;

const Image = styled(Imgix)`
  border-radius: 5px;
  width: 100%;
  height: auto;
`;
