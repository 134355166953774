import React from 'react';
import styled from 'styled-components';
import MuiPopover from '@material-ui/core/Popover';
import Fade from '@material-ui/core/Fade';

export function Popover({ children, ...props }): JSX.Element {
  const popoverProps = {
    // it can't be "Grow", the default, because that messes up tab line positioning
    // eslint-disable-next-line @typescript-eslint/naming-convention
    TransitionComponent: Fade,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 50,
    },
  };

  return (
    <StyledPopover style={{ overflow: 'visible' }} {...props} {...popoverProps}>
      {children}
    </StyledPopover>
  );
}

const StyledPopover = styled(MuiPopover)`
  .MuiPopover-paper {
    overflow: visible;
    margin-top: 21px;
    border-radius: 14px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-top: 5px;
      max-width: none;
      left: 5px !important;
      right: 5px !important;
      // need to set this because the generated value from MUI gets weird
      // when mobile keyboards come into view
      top: 91px !important;
    }
  }

  &.MuiPopover-root {
    background-color: rgba(69, 78, 80, 0.35);

    /* global-header z-index - 100 so that the backdrop doesn't cover it */
    z-index: 1100 !important;
  }
`;
