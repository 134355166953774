/* eslint-disable max-len */
import React from 'react';

function ShoppingBagIcon(): JSX.Element {
  return (
    <svg viewBox='0 0 16 17' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.18343 6.79874V14.226C2.18343 14.8703 2.70577 15.3926 3.3501 15.3926H12.6499C13.2942 15.3926 13.8165 14.8703 13.8165 14.226V6.79874H2.18343ZM1.68343 5.4654C1.22319 5.4654 0.850098 5.8385 0.850098 6.29874V14.226C0.850098 15.6067 1.96938 16.726 3.3501 16.726H12.6499C14.0306 16.726 15.1499 15.6067 15.1499 14.226V6.29874C15.1499 5.8385 14.7768 5.4654 14.3165 5.4654H1.68343Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.1308 2.4418C5.74145 2.94272 5.55354 3.60582 5.55354 4.19359V8.87292C5.55354 9.24111 5.25506 9.53958 4.88687 9.53958C4.51868 9.53958 4.2202 9.24111 4.2202 8.87292V4.19359C4.2202 3.35561 4.48042 2.39244 5.07808 1.62354C5.69265 0.832864 6.64673 0.274391 7.93869 0.274414C10.3464 0.274457 11.8049 2.11875 11.8049 4.19359V8.87239L10.4716 8.87289V4.19359C10.4716 2.7589 9.51729 1.60778 7.93867 1.60775C7.07507 1.60773 6.50324 1.96264 6.1308 2.4418ZM11.1382 9.53959C10.77 9.53959 10.4716 9.24108 10.4716 8.87289L11.8049 8.87239C11.8049 9.24058 11.5064 9.53959 11.1382 9.53959Z'
      />
    </svg>
  );
}

export default ShoppingBagIcon;
