/* eslint-disable max-len */
import React from 'react';

function CalendarIcon(): JSX.Element {
  return (
    <svg viewBox='0 0 16 17' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.502103 3.28775C0.823594 2.96626 1.25963 2.78564 1.71429 2.78564C2.02988 2.78564 2.28571 3.04148 2.28571 3.35707C2.28571 3.67266 2.02988 3.9285 1.71429 3.9285C1.56273 3.9285 1.41739 3.98871 1.31022 4.09587C1.20306 4.20303 1.14286 4.34838 1.14286 4.49993V14.7856C1.14286 14.9372 1.20306 15.0825 1.31022 15.1897C1.41739 15.2969 1.56273 15.3571 1.71429 15.3571H14.2857C14.4373 15.3571 14.5826 15.2969 14.6898 15.1897C14.7969 15.0825 14.8571 14.9372 14.8571 14.7856V4.49993C14.8571 4.34838 14.7969 4.20303 14.6898 4.09587C14.5826 3.98871 14.4373 3.9285 14.2857 3.9285H12C11.6844 3.9285 11.4286 3.67266 11.4286 3.35707C11.4286 3.04148 11.6844 2.78564 12 2.78564H14.2857C14.7404 2.78564 15.1764 2.96626 15.4979 3.28775C15.8194 3.60924 16 4.04527 16 4.49993V14.7856C16 15.2403 15.8194 15.6763 15.4979 15.9978C15.1764 16.3193 14.7404 16.4999 14.2857 16.4999H1.71429C1.25963 16.4999 0.823593 16.3193 0.502103 15.9978C0.180612 15.6763 0 15.2403 0 14.7856V4.49993C0 4.04527 0.180612 3.60924 0.502103 3.28775Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 7.92836C0 7.61277 0.255837 7.35693 0.571429 7.35693H15.4286C15.7442 7.35693 16 7.61277 16 7.92836C16 8.24395 15.7442 8.49979 15.4286 8.49979H0.571429C0.255837 8.49979 0 8.24395 0 7.92836Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.00014 0.5C4.31573 0.5 4.57157 0.755837 4.57157 1.07143V5.64286C4.57157 5.95845 4.31573 6.21428 4.00014 6.21428C3.68455 6.21428 3.42871 5.95845 3.42871 5.64286V1.07143C3.42871 0.755837 3.68455 0.5 4.00014 0.5Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0001 0.5C12.3157 0.5 12.5716 0.755837 12.5716 1.07143V5.64286C12.5716 5.95845 12.3157 6.21428 12.0001 6.21428C11.6845 6.21428 11.4287 5.95845 11.4287 5.64286V1.07143C11.4287 0.755837 11.6845 0.5 12.0001 0.5Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.42871 3.35707C3.42871 3.04148 3.68455 2.78564 4.00014 2.78564H9.71443C10.03 2.78564 10.2859 3.04148 10.2859 3.35707C10.2859 3.67266 10.03 3.9285 9.71443 3.9285H4.00014C3.68455 3.9285 3.42871 3.67266 3.42871 3.35707Z'
      />
    </svg>
  );
}

export default CalendarIcon;
