import useTranslation from 'hooks/use-translation';
import { useRouter } from 'next/router';

import useErnie from 'shared/hooks/use-ernie';
import { formatName } from 'shared/helpers/users';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'hooks/use-user';
import useUI from 'hooks/use-ui';
import { useModals, ModalNames } from 'components/modals';

import { tracker } from 'src/utils/analytics';
import ROUTES from 'src/routes';
import { GqlIdentityConsumer } from 'types/graphql';

const { ACCOUNT, PREFERENCES, ORDERS, AGE_VERIFICATION_GATE } = ROUTES;

type MenuRoutes = {
  embedded: string;
  storeFront: string;
};

type UseMyAccountPopoverParams = {
  anchorEl: HTMLButtonElement | null;
};

export type ConsumerUser = Pick<GqlIdentityConsumer, 'profile'> & {
  id: string;
  viewOrderId?: string[] | string | null;
  createdAt: string | null;
  exists: boolean;
  loading: boolean;
  token?: string;
  logout: () => void;
};

type UseMyAccountPopoverReturn = {
  handleOptionClick: (value: string) => void;
  handleOnLogout: () => void;
  popoverProps: {
    anchorOrigin: {
      vertical: string;
      horizontal: string;
    };
    transformOrigin: {
      vertical: string;
      horizontal: string;
    };
    open: HTMLButtonElement | null;
  };
  userDisplayName: string;
};

function getMenuRoutes(cName: string[] | string | undefined, isReady: boolean): MenuRoutes | undefined {
  if (typeof cName !== 'string') {
    throw new Error(`Error: cName needs to be a string, got ${typeof cName} instead.`);
  }

  if (!isReady) {
    return undefined;
  }

  return {
    embedded: `/embedded-menu/${cName}`,
    storeFront: `/stores/${cName}`,
  };
}

function formatNameWithLastInitial(user: ConsumerUser): string {
  const { profile: userProfile } = user;

  const formattedName = formatName(userProfile);
  const [firstName, lastName] = formattedName.split(' ');

  // fullName could possibly be one name with no spaces. formatName can also just return 'unknown';
  if (typeof firstName !== 'string') {
    throw new Error(`Error: user firstName needs to be of type string, got ${typeof firstName} instead.`);
  }

  const truncateFirstName = firstName.length > 10;
  const firstNameDisplay = truncateFirstName ? `${firstName.slice(0, 10)}...` : firstName;

  if (!lastName || typeof lastName !== 'string') {
    return firstNameDisplay;
  }

  return `${firstNameDisplay} ${lastName[0]}.`;
}

export function useMyAccountPopover({ anchorEl }: UseMyAccountPopoverParams): UseMyAccountPopoverReturn {
  const { t } = useTranslation();
  const user: ConsumerUser = useUser();
  const { openModal } = useModals();
  const { dispensary } = useDispensary();
  const ernie = useErnie();
  const UI = useUI();
  const router = useRouter();
  const isAgeVerificationSettingEnabled = dispensary?.storeSettings.hardAgeGateAgeVerification ?? false;
  const {
    query: { cName },
    route,
    isReady,
  } = router;
  const { isEmbedded, isStoreFront } = UI;

  const onAccountPage = route.includes(ACCOUNT) || route.includes(PREFERENCES) || route.includes(ORDERS);

  const userDisplayName = formatNameWithLastInitial(user);

  const handleOptionClick = async (value: string): Promise<void> => {
    const isLogin = value === 'login';

    await openModal(isLogin ? ModalNames.login : ModalNames.signUp);

    tracker.gaGTMClickEvent({
      eventCategory: 'account',
      eventAction: isLogin ? 'signed in' : 'created account',
      eventLabel: `email: {pageType}`,
    });
  };

  const handleOnLogout = (): void => {
    const menuRoutes = getMenuRoutes(cName, isReady);

    if (isAgeVerificationSettingEnabled && menuRoutes) {
      void router.replace(`${menuRoutes[isEmbedded ? `embedded` : `storeFront`]}${AGE_VERIFICATION_GATE}`);
    } else if (onAccountPage && menuRoutes && isReady) {
      if (isEmbedded) {
        void router.replace(menuRoutes.embedded);
      }
      if (isStoreFront) {
        void router.replace(menuRoutes.storeFront);
      }
    }
    user.logout();
    ernie(t('ernie.logout', "You've been successfully logged out."), 'success');
  };

  const popoverProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    open: anchorEl,
  };

  return {
    handleOptionClick,
    handleOnLogout,
    popoverProps,
    userDisplayName,
  };
}
