import React from 'react';

import { HeaderTableRow } from './product-table.components';
import { Column } from './use-table-columns';

type TableHeaderRowProps = {
  columns: Column[];
};

export function TableHeaderRow({ columns }: TableHeaderRowProps): JSX.Element {
  return (
    <HeaderTableRow>
      {columns.map(({ key, label }) => (
        <p key={key}>{label}</p>
      ))}
    </HeaderTableRow>
  );
}
