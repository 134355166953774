import React from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import Fade from '@material-ui/core/Fade';

import { LoggedInOptionsListItem, LoggedOutOptionsListItem } from './my-account-options-lists';

export default function MyAccountPopover({ isLoggedIn, onOptionClick, onLogout, anchorEL }) {
  const popoverProps = {
    TransitionComponent: Fade,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    open: anchorEL,
  };
  const optionsList = isLoggedIn ? (
    <LoggedInOptionsListItem onLogout={onLogout} />
  ) : (
    <LoggedOutOptionsListItem onOptionClick={onOptionClick} />
  );

  return (
    <Container {...popoverProps} anchorEl={anchorEL}>
      {optionsList}
    </Container>
  );
}

const Container = styled(Popover)`
  .MuiPopover-paper {
    padding: 17px 0 24px;
    margin-top: 10px;
  }

  > div[class*='MuiPaper-rounded'] {
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px 0px;
    border-radius: 8px !important;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .MuiPopover-paper {
      margin-top: 49px;
    }
  }

  &.MuiPopover-root {
    background: transparent;
  }
`;
