import React, { forwardRef } from 'react';

import useUI from 'src/hooks/use-ui';

import GuestCheckoutSuccess from '../guest-checkout-success';
import GuestCheckoutSuccessDutchiePay from '../guest-checkout-success-dutchie-pay';

const GuestCheckoutSuccessModal = forwardRef(({ containerProps, onClose }, ref) => {
  const UI = useUI();
  const isDutchiePayEnabledForDispo = UI.lastDispensary?.paymentTypesAccepted.dutchiePay;

  return isDutchiePayEnabledForDispo ? (
    <GuestCheckoutSuccessDutchiePay containerProps={containerProps} onClose={onClose} ref={ref} />
  ) : (
    <GuestCheckoutSuccess containerProps={containerProps} onClose={onClose} ref={ref} />
  );
});

export default GuestCheckoutSuccessModal;
