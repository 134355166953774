/* eslint-disable lodash/prefer-lodash-method, lodash/prefer-lodash-typecheck */

import tc from 'tinycolor2';

import PublicEnv from 'shared/utils/public-env';

import { brandColors } from './brand-colors';
import { payByBankColors } from './pay-by-bank-colors';

export function getLightness(color) {
  return Math.round(20 * tc(color).toHsl().l) * 5;
}

export function getColors() {
  const primaryGreen = '#26a27b';
  const primaryBlue = '#0b99e6';
  const primaryOrange = '#e97a3b';
  const primaryPurple = '#936bbe';
  const primaryGrey = '#f3f6f8';
  const primaryBlack = '#242526';
  const white = '#fff';

  const colors = {
    // named colors, branding or action oriented
    ...brandColors,
    ...payByBankColors,
    primaryGreen,
    primaryBlue,
    primaryOrange,
    primaryPurple,
    primaryGrey,
    primaryBlack,
    white,

    // backgrounds?
    darkBackground: '#e1e8ec', // "dark-bkgd"
    lightRedBackground: '#ffeeee',

    // borders
    greyBorder: '#e4e8ea',
    pinkBorder: '#d7c1c1',

    // shadows and other rgba colors
    // @TODO: I made these up, need to get input from product for actual colors here ch-47006
    blueShadow: 'rgba(11, 153, 230, 0.4)',
    blueInputFocusShadow: 'rgba(11, 153, 230, 0.27)',
    basicShadow: 'rgba(0, 0, 0, 0.14)',
    lightShadow: 'rgba(0, 0, 0, 0.097543)',
    whiteOverlay: 'rgba(255, 255, 255, 0.8)',
    greyOverlay: 'rgba(69,78, 80, 0.93)',
    chromeAutoFill: 'rgb(232, 240, 254)',
    darkGreyOverlay: 'rgb(33, 38, 42, 0.85)',
    sectionShadow: 'rgba(0, 0, 0, 0.038)',

    // @TODO these v2 colors need to be translated into a DDS color via product.
    v2Border1: '#cbd4d9',
    v2Background1: '#f7f9fb',
    v2TextColor1: '#6d747b',
    v2TextColor2: '#46494c',
    v2Background2: '#ebf0f4',
    v2Border2: '#bec8cd',
    v2ScheduleDemoBG: '#5e92d2',
    v2DarkFooter: '#3e566d',
    v2BlueGreyHeader: '#475d74',
    v2BlueGreySubheader: '#909fb2',
    v2BlueGreyCopyText: '#495867',
    v2Green: '#5ea68c',

    // order type toggle
    orderTypeToggleGrey: '#d0d9df',

    // DutchiePay
    selectedBankAccountBlue: '#EEF6FD',
    selectedBankAccountCardBorder: '#5A95DA',

    // search pin icon
    searchIconFill: '#5E6D79',

    // homepage easy-buying section text for mobile
    easyBuyingGrey: `#58656C`,

    mobileEcommAppBlackText: `#171A1C`,

    // dispensary onboarding theme
    onboardingBlue: {
      75: '#8db8eb',
      70: '#82acdd',
      55: '#618aba',
      40: '#2f629c',
      35: '#255997',
      30: '#1b477c',
    },

    onboardingGreen: {
      70: '#92cda0',
      50: '#3bba5b',
      60: '#74b384',
    },

    // dutchie plus marketing
    dutchiePlusBlue: {
      95: '#E5EDF6',
      45: '#586783',
      15: '#13253E',
      10: '#0C1829',
    },
    dutchiePlusGreen: {
      35: '#00BAAF',
    },
    dutchiePlusGrey: {
      95: '#DCE2FF',
    },
    dutchiePlusPurple: {
      95: '#DAE1FE',
      75: '#8298FC',
      55: '#5E6DBA',
      50: '#4756AD',
    },
    // greens
    green: {
      95: '#f0fffa',
      90: '#d7f0e8',
      85: '#c7efe0',
      65: '#86c1ab',
      55: '#74B56E',
      45: '#43ab92',
      40: '#26A27B',
      30: '#178e6a',
    },
    lime: {
      45: '#4ca667',
      35: '#1c956f',
      30: '#118A67',
      25: '#0E7154',
    },
    moss: {
      85: '#C7EFE0',
      65: '#90b0a5',
      45: '#68867c',
    },

    // blues
    blue: {
      90: '#d3edff',
      55: '#4c94d7',
      50: '#3d85c9',
      40: '#316495',
    },

    // oranges
    orange: {
      85: '#ffd3b8',
      65: '#f58f51',
      55: '#E97A3B',
      35: '#a2460a',
    },

    // "purps" lul
    purple: {
      85: '#d7bbec',
      35: '#572e83',
    },

    // reds
    red: {
      60: '#f3583a',
      55: '#d34a4a',
      45: '#ec0017',
    },

    // greys
    grey: {
      100: '#fcfdfe',
      95: '#f2f6f8',
      90: '#DFE6EB',
      85: '#d8d8d8',
      80: '#caced4',
      75: '#b7bfc7',
      70: '#a3afba',
      60: '#969ea5',
      55: '#888F9B',
      50: '#798891',
      45: '#677882',
      40: '#5d666d',
      35: '#4f5d68',
      30: '#454e50',
      25: '#393d40',
    },

    // blue-greys
    blueGrey: {
      95: '#eaeff2',
      90: '#d7e4eb',
      80: '#bccad2',
      40: '#4c6781',
    },
  };

  if (PublicEnv.appEnv === 'development') {
    // pop an error whenever you use a lightness value that doesn't exist
    Object.keys(colors).forEach((key) => {
      if (typeof colors[key] === 'object') {
        for (let i = 0, l = 100; i < l; ++i) {
          const index = i;
          if (!colors[key][index]) {
            Object.defineProperty(colors[key], index, {
              get: () => {
                console.error(key, 'has no lightness value for', index);
                return undefined;
              },
            });
          }
        }
      }
    });

    Object.keys(colors).forEach((key) => {
      // show a warning whenever the lightness value for a color is not accurate to the actual hsl
      // this might go away at some point
      if (typeof colors[key] === 'object') {
        Object.keys(colors[key]).forEach((lightness) => {
          const actualLightness = `${getLightness(colors[key][lightness])}`;
          if (actualLightness !== lightness) {
            console.warn(
              'This color is marked as lightness',
              lightness,
              'but is actually',
              actualLightness,
              'inside of ',
              key
            );
          }
        });
      }
    });
  }

  return colors;
}

export const isColorDark = (color) => {
  if (!color) {
    return false;
  }

  return tc(color).isDark();
};
