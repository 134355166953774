import React, { createRef, useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { DesktopOnly, MobileOnly } from 'shared/components';

import useCart from 'hooks/use-cart';
import useSearchPreferences from 'hooks/use-search-preferences';
import useUI from 'hooks/use-ui';
import usePaths from 'hooks/use-paths';
import useScroll from 'hooks/use-scroll';

import ROUTES from 'routes';

import MenuNavMobile from './menu-nav-mobile';
import GlobalHeaderDesktop from './global-header-desktop';
import { GlobalHeaderMobile } from './global-header-mobile';
import { useSaveLocationSettings } from './use-save-location-settings';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const router = useRouter();
  const Cart = useCart();
  const SearchPreferences = useSearchPreferences();
  const UI = useUI();
  const inView = useRef(true);
  const scroll = useScroll();
  const globalHeaderRef = createRef();
  const addressButtonRef = useCallback(
    (node) => {
      if (node !== null) {
        return setAnchorEl(node);
      }
      return anchorEl;
    },
    [anchorEl]
  );

  const saveLocationSettings = useSaveLocationSettings();

  const isGlobalHeaderInView = useObserver(() => UI.isGlobalHeaderInView);
  const showCartDrawer = useObserver(() => UI.showCartDrawer);
  const isDispensaryHeaderInView = useObserver(() => UI.isDispensaryHeaderInView);
  const isDispensaryList = useObserver(() =>
    _.includes([ROUTES.DISPENSARIES, ROUTES.CITY_CITY_KEY, ROUTES.COUNTRY_STATE_CITY], UI.route)
  );
  const hideSpacer = useObserver(() => _.includes([ROUTES.SHOP], UI.route));
  const { href, route } = usePaths({ home: isDispensaryList, dispensaries: !isDispensaryList });
  const itemCount = useObserver(() => Cart.itemCount);
  const address = useObserver(() => Cart.formattedAddressShort);
  const orderTypePreference = useObserver(() => SearchPreferences.orderType);
  const isProductPage = router.route === `${ROUTES.DISPENSARY_CNAME}${ROUTES.PRODUCT_ID}`;
  const addMinimumSpace = (router.route === ROUTES.DISPENSARY_CNAME && isDispensaryHeaderInView) || isProductPage;

  const showMenuNavMobile = _.includes(router.route, ROUTES.DISPENSARY_CNAME);
  const badgeVariant = !isDispensaryList ? `white` : `darkBlue`;

  useEffect(() => {
    inView.current = globalHeaderRef?.current?.getBoundingClientRect()?.height >= scroll.y;
    UI.setGlobalHeaderInView(inView?.current);
  }, [globalHeaderRef, scroll, UI]);

  const toggleCartView = () => (showCartDrawer ? UI.closeCartDrawer() : UI.openCartDrawer());

  const cartButtonRef = createRef();
  useEffect(() => {
    UI.setCartButtonRef(cartButtonRef);
    return () => UI.clearCartButtonRef();
  }, [UI, cartButtonRef]);

  return (
    <>
      <DesktopOnly>
        <GlobalHeaderDesktop
          globalHeaderRef={globalHeaderRef}
          addressButtonRef={addressButtonRef}
          anchorEl={anchorEl}
          cartButtonRef={cartButtonRef}
          href={href}
          route={route}
          itemCount={itemCount}
          address={address}
          orderType={orderTypePreference}
          isDispensaryList={isDispensaryList}
          toggleCartView={toggleCartView}
          isGlobalHeaderInView={isGlobalHeaderInView}
          onSaveLocationSettings={saveLocationSettings}
          onAddressTextButtonClick={UI.toggleLocationSettingsDropdown}
        />
      </DesktopOnly>

      <MobileOnly>
        {showMenuNavMobile ? (
          <StickyContainer>
            <MenuNavMobile toggleCartView={toggleCartView} itemCount={itemCount} />
          </StickyContainer>
        ) : (
          <GlobalHeaderMobile
            globalHeaderRef={globalHeaderRef}
            addressButtonRef={addressButtonRef}
            anchorEl={anchorEl}
            href={href}
            route={route}
            address={address}
            orderType={orderTypePreference}
            itemCount={itemCount}
            isDispensaryList={isDispensaryList}
            toggleCartView={toggleCartView}
            badgeVariant={badgeVariant}
            onSaveLocationSettings={saveLocationSettings}
            onAddressTextButtonClick={UI.toggleLocationSettingsDropdown}
          />
        )}

        {/* This spacer pushes down the page content depending on page or state of the global/sub headers */}
        {!hideSpacer && <HeaderSpacer addMinimumSpace={addMinimumSpace} isDispensaryList={isDispensaryList} />}
      </MobileOnly>
    </>
  );
};

export default Header;

const StickyContainer = styled.div`
  position: fixed;
  z-index: 200;
  width: 100%;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.0408458);
`;

const HeaderSpacer = styled.div`
  height: ${({ addMinimumSpace, isDispensaryList }) => {
    if (isDispensaryList) {
      // on dispensaries page
      return `87px`;
    }

    if (addMinimumSpace) {
      // If on homepage and not scrolled down
      return `60px`;
    }

    // on any other page or scrolled down on homepage
    return `113px`;
  }};
`;
