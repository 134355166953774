/* eslint-disable max-len, @typescript-eslint/ban-ts-comment */
import React from 'react';
import { getColors } from 'src/theme/colors';

type IconProps = {
  color?: string;
  height?: string;
  className?: string;
};

export function DutchieLogo({ color = 'black', ...restOfProps }: IconProps): JSX.Element {
  const colors = getColors();

  return (
    <svg
      width='127'
      height='30'
      viewBox='0 0 127 30'
      fill={colors[color] || color}
      xmlns='http://www.w3.org/2000/svg'
      {...restOfProps}
    >
      <title>Dutchie Logo</title>
      <path d='M26.9951 21.0931C25.5071 21.8988 23.9188 22.5033 22.2718 22.8907C20.4782 23.3024 18.6328 23.4413 16.7978 23.3026C13.7162 23.0378 10.7368 21.6213 8.67355 19.2984C8.42142 19.0182 8.21691 18.6749 7.82471 18.5811C7.20279 18.441 6.76436 18.8613 6.61168 19.4413C5.1045 25.0819 10.255 30.4381 15.9083 29.9716C18.4296 29.8093 20.8692 29.0136 23.0015 27.6581C25.1338 26.3026 26.8899 24.4311 28.1072 22.2168C28.1783 22.0931 28.2149 21.9527 28.2135 21.81C28.212 21.6674 28.1724 21.5278 28.0988 21.4056C27.9918 21.2199 27.8166 21.0834 27.6104 21.025C27.4043 20.9667 27.1835 20.9911 26.9951 21.0931Z' />
      <path d='M14.2877 1.5331C15.7306 2.41862 17.0494 3.49187 18.2098 4.72469C19.4589 6.07305 20.4977 7.60194 21.2914 9.25988C22.6025 12.062 22.8672 15.3502 21.8839 18.2939C21.7648 18.6525 21.5715 18.9944 21.6864 19.3867C21.8783 19.9961 22.4624 20.1629 23.0381 20.0087C28.6732 18.5054 30.7421 11.3615 27.5106 6.69877C26.11 4.59499 24.2015 2.87853 21.9617 1.70819C19.7219 0.537839 17.2232 -0.0485907 14.6968 0.00314983C14.5531 0.00410092 14.4123 0.0436044 14.2891 0.117537C14.1659 0.19147 14.0648 0.297121 13.9964 0.423465C13.8905 0.609855 13.8617 0.830279 13.9161 1.03763C13.9706 1.24498 14.104 1.4228 14.2877 1.5331Z' />
      <path d='M15.6352 10.6917C14.6417 10.5448 13.6279 10.7469 12.7666 11.2636C11.9053 11.7804 11.2497 12.5798 10.9115 13.5257C10.5732 14.4716 10.5733 15.5056 10.9117 16.4514C11.25 17.3973 11.9057 18.1966 12.7671 18.7132C13.6285 19.2298 14.6423 19.4318 15.6358 19.2847C16.6294 19.1377 17.5412 18.6506 18.2161 17.9066C18.8909 17.1626 19.287 16.2076 19.337 15.2042C19.3869 14.2008 19.0876 13.2112 18.4899 12.4038C18.1509 11.9438 17.7241 11.5556 17.234 11.2617C16.744 10.9678 16.2006 10.7741 15.6352 10.6917Z' />
      <path d='M14.4109 7.36429C14.7807 7.28724 15.1799 7.28163 15.4559 6.98881C15.8873 6.51806 15.736 5.92822 15.3158 5.5079C11.206 1.37761 3.97969 3.15834 1.55783 8.28899C0.437448 10.5538 -0.0936996 13.0647 0.0135403 15.5894C0.12078 18.114 0.862943 20.5708 2.17135 22.7324C2.24327 22.8556 2.34672 22.9574 2.47105 23.0274C2.59538 23.0973 2.7361 23.1328 2.87872 23.1303C3.09317 23.1311 3.29939 23.0478 3.45312 22.8982C3.60684 22.7486 3.69582 22.5448 3.70095 22.3303C3.74758 20.6395 4.01794 18.9623 4.50496 17.3426C5.04584 15.5828 5.84858 13.9146 6.8862 12.3941C8.65532 9.85816 11.3713 7.98916 14.4109 7.36429Z' />
      <path d='M37.2302 16.1152C37.2302 11.8 39.7235 8.82975 43.8977 8.82975C45.6626 8.82975 47.1768 9.5583 47.9892 10.6231V3.73693C47.989 3.68383 47.9993 3.63121 48.0195 3.58209C48.0397 3.53297 48.0694 3.48833 48.1069 3.45071C48.1443 3.41309 48.1889 3.38324 48.2379 3.36288C48.287 3.34251 48.3395 3.33203 48.3926 3.33203H51.1142C51.2216 3.33203 51.3246 3.37469 51.4005 3.45062C51.4764 3.52656 51.519 3.62955 51.519 3.73693V22.7688C51.519 22.8219 51.5086 22.8745 51.4882 22.9235C51.4678 22.9726 51.438 23.0171 51.4004 23.0546C51.3628 23.0921 51.3181 23.1218 51.269 23.142C51.2199 23.1622 51.1673 23.1725 51.1142 23.1723H48.3926C48.2856 23.1723 48.183 23.1298 48.1074 23.0541C48.0317 22.9784 47.9892 22.8758 47.9892 22.7688V21.5471C47.2888 22.6679 45.7186 23.3965 43.9537 23.3965C39.7235 23.4007 37.2302 20.4262 37.2302 16.1152ZM48.2133 16.1152C48.2133 13.5653 46.7272 11.884 44.458 11.884C42.2168 11.884 40.732 13.5653 40.732 16.1152C40.732 18.6651 42.2168 20.3464 44.458 20.3464C46.7272 20.3422 48.2133 18.6609 48.2133 16.1152Z' />
      <path d='M66.1875 23.1724H63.4659C63.3589 23.1724 63.2563 23.1299 63.1806 23.0542C63.105 22.9785 63.0625 22.8759 63.0625 22.7689V21.0428C62.166 22.4158 60.5692 23.3966 58.5802 23.3966C55.5266 23.3966 53.4255 21.379 53.4255 18.2967V9.45331C53.4255 9.4002 53.436 9.34762 53.4563 9.29857C53.4767 9.24952 53.5065 9.20498 53.5441 9.16749C53.5817 9.13001 53.6264 9.10031 53.6755 9.08012C53.7246 9.05992 53.7772 9.04962 53.8303 9.04981H56.5519C56.6589 9.04981 56.7615 9.09232 56.8372 9.16799C56.9128 9.24366 56.9553 9.34629 56.9553 9.45331V17.4813C56.9553 19.0085 58.1894 20.2834 59.7147 20.2582C61.8565 20.219 63.0639 18.3752 63.0639 15.2424V9.45331C63.0639 9.34629 63.1064 9.24366 63.182 9.16799C63.2577 9.09232 63.3603 9.04981 63.4673 9.04981H66.1889C66.242 9.04962 66.2946 9.05992 66.3437 9.08012C66.3928 9.10031 66.4375 9.13001 66.4751 9.16749C66.5127 9.20498 66.5425 9.24952 66.5629 9.29857C66.5832 9.34762 66.5937 9.4002 66.5937 9.45331V22.7633C66.5945 22.817 66.5845 22.8703 66.5644 22.9201C66.5443 22.9699 66.5144 23.0151 66.4766 23.0532C66.4388 23.0913 66.3937 23.1215 66.3441 23.142C66.2944 23.1624 66.2412 23.1728 66.1875 23.1724Z' />
      <path d='M75.138 23.4006C72.2791 23.4006 70.15 21.8594 70.15 18.553V12.0199H68.1736C68.0662 12.0199 67.9633 11.9772 67.8874 11.9013C67.8114 11.8253 67.7688 11.7224 67.7688 11.615V9.45315C67.7688 9.40004 67.7793 9.34745 67.7996 9.29841C67.82 9.24936 67.8498 9.20482 67.8875 9.16733C67.9251 9.12984 67.9697 9.10015 68.0188 9.07995C68.0679 9.05976 68.1205 9.04946 68.1736 9.04964H70.15V5.30604C70.1519 5.19974 70.1953 5.09839 70.271 5.02375C70.3466 4.9491 70.4486 4.90709 70.5548 4.90674H73.2765C73.3824 4.90746 73.4838 4.94967 73.5589 5.02432C73.6341 5.09897 73.677 5.20011 73.6785 5.30604V9.04964H76.8329C76.94 9.04964 77.0428 9.09211 77.1186 9.16774C77.1945 9.24337 77.2373 9.346 77.2377 9.45315V11.615C77.2373 11.7222 77.1946 11.825 77.1187 11.9009C77.0429 11.9767 76.9402 12.0195 76.8329 12.0199H73.6785V18.2167C73.6785 19.5617 74.4082 20.2903 75.4994 20.2903C75.8982 20.2933 76.2955 20.2414 76.6802 20.1362C76.7371 20.1186 76.7972 20.1139 76.856 20.1226C76.9149 20.1312 76.9711 20.1529 77.0205 20.1861C77.0699 20.2193 77.1113 20.2631 77.1416 20.3143C77.1719 20.3656 77.1904 20.4229 77.1957 20.4822L77.3778 22.7239C77.3847 22.8167 77.3596 22.9091 77.3065 22.9857C77.2535 23.0622 77.1758 23.1181 77.0864 23.1442C76.4527 23.3231 75.7964 23.4094 75.138 23.4006Z' />
      <path d='M77.8275 16.1153C77.8275 11.8001 80.769 8.82983 84.9992 8.82983C88.4464 8.82983 90.9257 10.5377 91.5602 13.2978C91.5721 13.3506 91.5732 13.4052 91.5635 13.4584C91.5539 13.5116 91.5336 13.5623 91.5039 13.6075C91.4743 13.6528 91.4358 13.6916 91.3909 13.7216C91.3459 13.7517 91.2954 13.7724 91.2423 13.7825L88.5683 14.3051C88.4686 14.3244 88.3654 14.3051 88.2795 14.2509C88.1937 14.1968 88.1316 14.112 88.1061 14.0137C87.7363 12.729 86.6213 11.8813 85.1057 11.8813C82.9206 11.8813 81.4918 13.5346 81.4918 16.1125C81.4918 18.6904 82.9206 20.3437 85.1057 20.3437C86.6255 20.3437 87.6704 19.5871 88.0906 18.2897C88.1205 18.1936 88.1848 18.1119 88.2711 18.0603C88.3574 18.0086 88.4597 17.9905 88.5585 18.0095L91.2325 18.5531C91.286 18.5638 91.3368 18.5853 91.3819 18.6162C91.4269 18.647 91.4652 18.6867 91.4945 18.7328C91.5238 18.7788 91.5435 18.8304 91.5524 18.8842C91.5613 18.9381 91.5592 18.9932 91.5462 19.0463C90.8655 21.7685 88.3862 23.4008 84.9992 23.4008C80.769 23.4008 77.8275 20.4263 77.8275 16.1153Z' />
      <path d='M93.4176 3.33203H96.1378C96.2452 3.33203 96.3482 3.37469 96.4241 3.45062C96.5 3.52656 96.5426 3.62955 96.5426 3.73693V11.2354C97.4671 9.6942 99.064 8.82555 101.025 8.82555C104.192 8.82555 106.293 10.8431 106.293 13.9254V22.7688C106.293 22.8759 106.25 22.9786 106.174 23.0542C106.098 23.1298 105.995 23.1723 105.888 23.1723H103.167C103.06 23.1723 102.957 23.1298 102.881 23.0542C102.805 22.9786 102.762 22.8759 102.762 22.7688V14.794C102.765 14.0532 102.477 13.3409 101.959 12.8112C101.441 12.2816 100.736 11.9772 99.9954 11.9639C97.7893 11.9429 96.5426 13.8245 96.5426 17.0357V22.7688C96.5426 22.8219 96.5322 22.8745 96.5118 22.9235C96.4914 22.9726 96.4616 23.0171 96.424 23.0546C96.3864 23.0921 96.3418 23.1218 96.2926 23.142C96.2435 23.1622 96.1909 23.1725 96.1378 23.1723H93.4176C93.3645 23.1725 93.3119 23.1622 93.2628 23.142C93.2137 23.1218 93.1691 23.0921 93.1315 23.0546C93.0939 23.0171 93.064 22.9726 93.0437 22.9235C93.0233 22.8745 93.0128 22.8219 93.0128 22.7688V3.73693C93.0128 3.62955 93.0555 3.52656 93.1314 3.45062C93.2073 3.37469 93.3103 3.33203 93.4176 3.33203Z' />
      <path d='M107.576 5.02872C107.572 4.74193 107.626 4.45731 107.735 4.19194C107.844 3.92657 108.005 3.68592 108.209 3.48442C108.413 3.28292 108.656 3.12473 108.923 3.01934C109.189 2.91395 109.475 2.86353 109.761 2.8711C111.05 2.8711 112.002 3.82382 112.002 5.02872C112.002 6.26164 111.05 7.21436 109.761 7.21436C109.472 7.22107 109.185 7.16912 108.917 7.06166C108.649 6.95419 108.405 6.79345 108.201 6.58914C107.997 6.38483 107.836 6.14121 107.729 5.873C107.621 5.60479 107.569 5.31758 107.576 5.02872ZM108.401 9.04973H111.121C111.174 9.04955 111.227 9.05985 111.276 9.08004C111.325 9.10024 111.37 9.12993 111.408 9.16742C111.445 9.20491 111.475 9.24945 111.495 9.2985C111.516 9.34754 111.526 9.40013 111.526 9.45323V22.7632C111.526 22.8163 111.516 22.8689 111.495 22.9179C111.475 22.967 111.445 23.0115 111.408 23.049C111.37 23.0865 111.325 23.1162 111.276 23.1364C111.227 23.1566 111.174 23.1669 111.121 23.1667H108.407C108.354 23.1669 108.301 23.1566 108.252 23.1364C108.203 23.1162 108.158 23.0865 108.121 23.049C108.083 23.0115 108.053 22.967 108.033 22.9179C108.012 22.8689 108.002 22.8163 108.002 22.7632V9.45323C108.002 9.40013 108.012 9.34754 108.033 9.2985C108.053 9.24945 108.083 9.20491 108.121 9.16742C108.158 9.12993 108.203 9.10024 108.252 9.08004C108.301 9.05985 108.354 9.04955 108.407 9.04973H108.401Z' />
      <path d='M126.564 17.0357H116.462C116.771 19.1653 118.143 20.4263 120.162 20.4263C121.676 20.4263 122.791 19.7678 123.243 18.6049C123.281 18.514 123.35 18.44 123.438 18.397C123.527 18.3541 123.628 18.3453 123.722 18.3723L126.244 19.056C126.298 19.07 126.35 19.0954 126.394 19.1304C126.439 19.1654 126.475 19.2093 126.501 19.2594C126.528 19.3094 126.543 19.3644 126.547 19.4208C126.551 19.4773 126.543 19.5339 126.524 19.587C125.613 21.994 123.238 23.3993 120.108 23.3993C115.429 23.3993 112.935 19.7846 112.935 16.1138C112.935 12.4431 115.232 8.82837 119.912 8.82837C124.675 8.82837 127 12.359 127 15.6375C127 15.9709 126.987 16.3548 126.972 16.6574C126.965 16.7606 126.919 16.8573 126.843 16.9277C126.768 16.998 126.668 17.0367 126.564 17.0357ZM123.215 14.4578C123.047 12.7765 121.955 11.5156 119.91 11.5156C118.173 11.5156 116.939 12.5524 116.548 14.4578H123.215Z' />
    </svg>
  );
}
