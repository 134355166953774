import { object, string, ref } from 'yup';
import { yup } from 'shared/core/yup';

// format: email@email.com || email+123@email.com || @
export const emailRegEx = new RegExp('@');

// format: mm/dd/yyyy
export const birthdayRegEx = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/;

export const userFormSchema = (t, fields) =>
  object().shape({
    birthday:
      fields.birthday &&
      yup
        .string()
        .required(t('form.birthdayRequired', 'Birthday is required'))
        .isLegalAge(`MM/DD/YYYY`, t('form.isLegalAge', 'You must be at least 18 to use dutchie'))
        .matches(birthdayRegEx, t('form.validBirthday', 'Please enter a valid birthday')),
    email:
      fields.email &&
      yup
        .string()
        .required(t('form.emailRequired', 'Email is required'))
        .matches(emailRegEx, t('form.validEmail', 'Please enter a valid email address')),
    phone:
      fields.phone &&
      yup
        .string()
        .phone(t('form.validPhoneNumber', 'Please enter a valid phone number'))
        .required(t('form.phoneNumberRequired', 'Mobile phone number is required')),
    // eslint-disable-next-line lodash/prefer-lodash-method
    firstName: fields.firstName && string().trim().required(t('form.firstNameRequired', 'First name is required')),
    // eslint-disable-next-line lodash/prefer-lodash-method
    lastName: fields.lastName && string().trim().required(t('form.lastNameRequired', 'Last name is required')),
    oldPassword: fields.oldPassword && string().required(t('form.passwordRequired', 'Password is required.')),
    newPassword:
      fields.newPassword &&
      string()
        .notOneOf([ref(`oldPassword`)], t('form.duplicatePassword', "New password can't match your old password"))
        .required(t('form.passwordRequired', 'Password is required.'))
        .min(8, t('form.passwordMin', 'Your password must be at least 8 characters')),
    confirmPassword:
      fields.confirmPassword &&
      string()
        .required(t('form.passwordRequired', 'Password is required'))
        .oneOf([ref(`newPassword`)], t('form.passwordMatch', 'Your confirmation password doesn’t match')),
  });
