import React, { SVGAttributes } from 'react';

function SvgDeliveryIcon(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg viewBox='0 0 23 14' {...props}>
      <g fill='#f58f52' fillRule='evenodd' {...props}>
        <path d='M12.253 5.19H8.31l.01-1.493 3.272.02.66 1.473zm1.392-.042l-.788-1.837a1.36 1.36 0 00-1.25-.935H8.138a1.15 1.15 0 00-1.15 1.15v1.817c0 .633.517 1.15 1.15 1.15h4.542c.317 0 .623-.153.82-.408l.004-.006a1.06 1.06 0 00.14-.93z' />
        <path d='M16.42 12.52l-.104-.014c-1.036-.01-1.878-.868-1.88-1.921a1.797 1.797 0 011.887-1.886c1.053 0 1.91.857 1.91 1.91 0 1.025-.828 1.882-1.813 1.91m5.552-3.572c-.302-1.525-1.1-2.33-2.037-3.15-1.595-1.334-3.997-1.94-4.412-2.014L14.254 1.12a1.243 1.243 0 00-1.117-.752H5.533c-.412 0-.628.331-.628.659 0 .313.24.663.586.663l7.579-.003 1.268 2.662c.186.44.617.736 1.044.736h.006c.024.005 2.398.466 3.76 1.647.419.27 1.569 1.434 1.569 2.804 0 .338.262.62.607.656l.006.497h-1.818l.002-.078a3.223 3.223 0 00-3.191-3.229h-.078c-1.685 0-3.083 1.34-3.136 3.024a2.913 2.913 0 000 .206l.002.077H9.119a.665.665 0 00-.646.664c0 .323.248.657.662.657h4.29a3.176 3.176 0 003.972 1.642 3.2 3.2 0 001.802-1.642h2.412a1.03 1.03 0 001.022-1.032v-1.07c0-.432-.267-.812-.661-.961M1.11 5.178h3.71a.686.686 0 000-1.371H1.11a.686.686 0 000 1.371M5.923 8.5h-3.71a.686.686 0 000 1.37h3.71a.686.686 0 000-1.37m-.842-1.661a.686.686 0 00-.686-.686H.685a.686.686 0 000 1.371h3.71a.686.686 0 00.686-.685' />
      </g>
    </svg>
  );
}

export default SvgDeliveryIcon;
