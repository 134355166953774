import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useErnie from 'shared/hooks/use-ernie';
import { ERNIE_DEFAULT_MESSAGES, ERNIE_TIMEOUTS, ERNIE_TYPES } from 'shared/constants';

export const userExistsCheck = gql`
  query UserExistsCheck($input: userIdExistsInput!) {
    userExistsCheck(input: $input) {
      exists
      _id
    }
  }
`;

type UseUserExistsResult = {
  userExistsCheckQuery: ({ email }: { email: string }) => Promise<void>;
};

export const useUserExistsCheck = (): UseUserExistsResult => {
  const apolloClient = useApolloClient();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const showErnie = useErnie();

  const userExistsCheckQuery = async ({ email }): Promise<void> => {
    try {
      const result = await apolloClient.query({
        query: userExistsCheck,
        variables: { input: { email } },
      });

      const { exists } = result.data.userExistsCheck;

      DutchiePayEnrollment.setExistingUser(exists);
      DutchiePayEnrollment.emailStepCompleted(email);
    } catch (err) {
      console.error(err);
      showErnie(ERNIE_DEFAULT_MESSAGES.ERROR, ERNIE_TYPES.DANGER, ERNIE_TIMEOUTS.LONG);
    }
  };

  return {
    userExistsCheckQuery,
  };
};
