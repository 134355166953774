import React from 'react';

import { Footer } from 'components/core';
import GlobalFooterContent from 'components/global-footer-rebrand/content';

export default function GlobalFooter(props) {
  return (
    <Footer {...props}>
      <GlobalFooterContent {...props} />
    </Footer>
  );
}
