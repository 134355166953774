import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { CheckboxOld } from 'shared/components';
import { ModalContainer } from 'components/core';
import { DutchieWordmark } from 'assets/marketing/rebrand';
import useTranslation from 'hooks/use-translation';

export const StyledModalContainer = styled(ModalContainer)`
  max-width: 420px;
  text-align: left;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 90px;
  border-radius: 28px;
  /* add z-index to solve odd behavior for Chrome on Google Pixel */
  z-index: 1;

  & .MuiFormControl-root {
    margin-top: 15px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    border-radius: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 30%;
    flex-direction: column;
    height: 100vh;
  }
`;

export const DutchieLogo = styled(DutchieWordmark)`
  width: 76px;
  height: 17px;
  margin-bottom: 10px;
`;

export const Heading = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const RememberMe = forwardRef((props, ref) => {
  const { onChange, value, ...otherProps } = props;
  const { Trans } = useTranslation();
  function handleChange() {
    onChange(!value);
  }
  return (
    <CheckboxOld
      onChange={handleChange}
      checked={value}
      ref={ref}
      label={
        <RememberMeText>
          <Trans i18nKey='age-restrictions.remember-me'>
            <b>Remember me</b> for 30 days.
          </Trans>
        </RememberMeText>
      }
      {...otherProps}
    />
  );
});

const RememberMeText = styled.div`
  width: 100%;
  white-space: nowrap;
  display: inline-block;
  font-size: 12px;

  b {
    font-weight: bold;
  }
`;

export function TermsAndPrivacy(props) {
  const { actionType = `click` } = props;
  const { Trans } = useTranslation();

  if (actionType === 'continue') {
    return (
      <TermsAndPrivacyContainer>
        <Trans i18nKey='age-restrictions.terms-continue'>
          By continuing, you agree to the&nbsp;
          <ExternalLink target='_blank' href='/terms'>
            Terms
          </ExternalLink>
          &nbsp;and&nbsp;
          <ExternalLink target='_blank' href='/privacy'>
            Privacy Policy
          </ExternalLink>
          .
        </Trans>
      </TermsAndPrivacyContainer>
    );
  }

  return (
    <TermsAndPrivacyContainer>
      <Trans i18nKey='age-restrictions.terms-click'>
        By clicking Yes, you agree to the&nbsp;
        <ExternalLink target='_blank' href='/terms'>
          Terms
        </ExternalLink>
        &nbsp;and&nbsp;
        <ExternalLink target='_blank' href='/privacy'>
          Privacy Policy
        </ExternalLink>
        .
      </Trans>
    </TermsAndPrivacyContainer>
  );
}

export const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.sapphire};
  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

export const TermsAndPrivacyContainer = styled.div`
  position: absolute;
  height: 50px;
  width: 100%;
  bottom: 0;
  left: 0;

  background: #f3f6f8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: #677882;
  border-radius: 0 0 24px 24px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    border-radius: 0;
  }
`;
