import React from 'react';

import { getComponentDisplayName } from 'utils/get-component-display-name';

export function withCompositeHook<ControllerProps, CompositeHookReturn>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WrappedComponent: React.FC<CompositeHookReturn & ControllerProps>,
  useCompositeHook: (props: ControllerProps) => CompositeHookReturn
): React.FC<ControllerProps> {
  const WithCompositeHook: React.FC<ControllerProps> = (props) => (
    <WrappedComponent {...props} {...useCompositeHook(props)} />
  );

  WithCompositeHook.displayName = `WithCompositeHook(${getComponentDisplayName(WrappedComponent)})`;

  return WithCompositeHook;
}
