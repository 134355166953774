import React from 'react';
import styled from 'styled-components';

import { MenuType } from 'src/dispensary/core-menu/components/header/menu-type';
import { StoreStatus } from 'src/dispensary/core-menu/components/header/store-status';

export const InfoBar = (): JSX.Element => (
  <InfoContainer>
    <StoreStatus />
    <MenuType />
  </InfoContainer>
);

const InfoContainer = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #c8d2db;
  color: #485055;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 8px 12px;
  width: 100%;
`;
