import _ from 'lodash';
import { GqlBrand } from 'types/graphql';

type GetBrandProps = {
  brands: GqlBrand[];
  brandId: string;
};

type GetBrandReturn = {
  brand: GqlBrand | null;
};

export const getBrand = ({ brands, brandId }: GetBrandProps): GetBrandReturn => {
  const brand = _.find(brands, (curr) => _.kebabCase(curr.name) === brandId || curr.id === brandId) ?? null;
  return {
    brand,
  };
};
