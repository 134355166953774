import React, { useEffect } from 'react';
import Head from 'next/head';
import { isSiftEnabled } from 'utils/analytics/helpers/is-enabled';
import { initTracker } from './trackers';
import { useTrackerConfig } from './use-tracker-config';

/**
 * The analytics scripts component;
 *
 * Responsibilities:
 *  - adding <script /> tags for trackers
 *
 */
export const AnalyticsScripts: React.FC = () => {
  // set the config for the tracker
  const config = useTrackerConfig();
  useEffect(() => {
    if (!config) {
      return;
    }
    initTracker(config);
  }, [config]);

  const siftEnabled = isSiftEnabled();

  return (
    <Head>
      {/* TODO: Use <Script> element after next.js 11 upgrade */}
      {/* FLOW(add-a-tracker).STEP_4b2: [optional] add <script /> tags for trackers here */}
      {siftEnabled && <script async src='https://cdn.sift.com/s.js' key='sift-sdk' />}
    </Head>
  );
};
