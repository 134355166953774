import React from 'react';
import useTranslation from 'hooks/use-translation';

import { ProductRecommendationSection } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { ProductCarousel } from 'src/components/product-carousel';
import { useRelatedItemsCarousel } from './use-related-items-carousel';

export type RelatedItemsCarouselProps = {
  productId: string;
  category: string;
};

export function RelatedItemsCarousel(props: RelatedItemsCarouselProps): JSX.Element {
  const { t } = useTranslation();
  const carouselProps = useRelatedItemsCarousel(props);

  return (
    <ProductCarousel
      heading={t(`common.relatedItems`, `Related Items`)}
      trackerSection={ProductRecommendationSection.relatedItems}
      {...carouselProps}
    />
  );
}
