import { useGetKioskTerminalsQuery, GqlGetKioskTerminalsQuery } from 'types/graphql';
import { useGetDegradedPollInterval } from 'shared/hooks/use-get-degraded-poll-interval';

// 60 seconds
const defaultPollInterval = 60000;
const fallbackArray = [];

export default function useKioskTerminals(dispensary): GqlGetKioskTerminalsQuery['getKioskTerminals'] {
  const directedOrders = dispensary?.orderTypesConfigV2?.kiosk?.directedOrders;
  const pollInterval = useGetDegradedPollInterval({ milliseconds: defaultPollInterval, degradationMultiplier: 10 });

  const { data } = useGetKioskTerminalsQuery({
    fetchPolicy: 'network-only',
    pollInterval,
    skip: !directedOrders,
    variables: { dispensaryId: dispensary?.id },
  });

  return data?.getKioskTerminals ?? fallbackArray;
}
