/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export function MoneyIcon(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M3 14.5L21 14.5V1.5H3V14.5Z' fill='#BCCAD2' />
      <path
        d='M21.3228 0H2.68097C2.26347 0 1.92188 0.356571 1.92188 0.79238V14.1704C1.92188 14.6062 2.26347 14.9628 2.68097 14.9628L21.3228 14.9628C21.7403 14.9628 22.0819 14.6062 22.0819 14.1704V0.79238C22.0819 0.356571 21.7403 0 21.3228 0ZM20.5637 13.378H3.44006V1.58476H20.5637V13.378Z'
        fill='#64737F'
      />
      <path d='M1 19H23V4H1V19Z' fill='#EAEFF2' />
      <path
        d='M23.2409 3H0.759112C0.341611 3 1.87159e-05 3.35657 1.87159e-05 3.79238L0 19.1986C0 19.6344 0.341592 19.9909 0.759093 19.9909H23.2409C23.6584 19.9909 24 19.6344 24 19.1986L24 3.79238C24 3.35657 23.6584 3 23.2409 3ZM22.4818 18.4062H1.51819L1.51821 4.58476H22.4818L22.4818 18.4062Z'
        fill='#64737F'
      />
      <path
        d='M5.11066 12.2879H6.99574C7.41324 12.2879 7.75483 11.9313 7.75483 11.4955C7.75483 11.0597 7.41324 10.7031 6.99574 10.7031H5.11066C4.69315 10.7031 4.35156 11.0597 4.35156 11.4955C4.35156 11.9313 4.68683 12.2879 5.11066 12.2879Z'
        fill='#64737F'
      />
      <path
        d='M17.0052 12.2879H18.8903C19.3078 12.2879 19.6494 11.9313 19.6494 11.4955C19.6494 11.0597 19.3078 10.7031 18.8903 10.7031H17.0052C16.5877 10.7031 16.2461 11.0597 16.2461 11.4955C16.2461 11.9313 16.5877 12.2879 17.0052 12.2879Z'
        fill='#64737F'
      />
      <path
        d='M11.406 12.2782H12.5952C12.8925 12.2782 13.1392 12.5291 13.1392 12.8461C13.1392 13.163 12.8988 13.4139 12.5952 13.4139H10.4634C10.0459 13.4139 9.70432 13.7705 9.70432 14.2063C9.70432 14.6421 10.0459 14.9987 10.4634 14.9987H11.2415V15.5203C11.2415 15.9561 11.5831 16.3127 12.0006 16.3127C12.4181 16.3127 12.7597 15.9561 12.7597 15.5203V14.9921C13.8161 14.9062 14.6574 13.9752 14.6574 12.8461C14.6574 11.6575 13.7338 10.6934 12.5952 10.6934H11.406C11.1086 10.6934 10.8619 10.4425 10.8619 10.1256C10.8619 9.81521 11.1023 9.55769 11.406 9.55769H13.4998C13.9173 9.55769 14.2589 9.20112 14.2589 8.76531C14.2589 8.3295 13.9173 7.97293 13.4998 7.97293H12.7597V7.47109C12.7597 7.03528 12.4181 6.67871 12.0006 6.67871C11.5831 6.67871 11.2415 7.03528 11.2415 7.47109V7.98613C10.1851 8.07198 9.34375 9.00302 9.34375 10.1322C9.34375 11.3141 10.2673 12.2782 11.406 12.2782Z'
        fill='#64737F'
      />
    </svg>
  );
}
