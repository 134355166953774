import { useMemo, useState, useEffect } from 'react';
import { isBlank } from 'underscore.string';

import { getHtmlPlainText } from 'shared/utils/misc-utils';

import useDispensary from 'src/dispensary/hooks/use-dispensary';

export type DispensaryDisclaimerReturn = {
  disclaimerIsBlank: boolean;
  disclaimerTextHtml?: string;
};

export function useDispensaryDisclaimer(): DispensaryDisclaimerReturn {
  const [isSsr, setIsSsr] = useState(true);
  const { dispensary } = useDispensary();
  const DOMParser = typeof window !== 'undefined' ? window.DOMParser : null;
  const disclaimerIsBlank: boolean = useMemo(
    () =>
      isSsr ||
      !dispensary?.embedSettings?.disclaimerTextHtml ||
      isBlank(getHtmlPlainText(dispensary?.embedSettings?.disclaimerTextHtml, DOMParser)),
    [dispensary?.embedSettings?.disclaimerTextHtml, DOMParser, isSsr]
  );

  useEffect(() => {
    setIsSsr(false);
  }, []);

  return { disclaimerIsBlank, disclaimerTextHtml: dispensary?.embedSettings?.disclaimerTextHtml };
}
