import React from 'react';

type SvgInfoIconAltProps = {
  width?: number;
  height?: number;
  color?: string;
};

export const InfoIconAlt = ({ width = 16, height = 17, color = `#AAB0B5` }: SvgInfoIconAltProps): JSX.Element => (
  <svg width={width} height={height} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>Info Alt Icon</title>
    <path
      fill={color}
      fillRule='evenodd'
      stroke={color}
      strokeWidth='1.75'
      d='M8 2.39a6 6 0 1 0 0 12 6 6 0 0 0 0-12Z'
      clipRule='evenodd'
    />
    <rect width='1.714' height='4.286' x='8.857' y='9.246' fill='#fff' rx='.857' transform='rotate(-180 8.857 9.246)' />
    <rect
      width='1.714'
      height='1.714'
      x='8.857'
      y='11.818'
      fill='#fff'
      rx='.857'
      transform='rotate(-180 8.857 11.818)'
    />
  </svg>
);
