import React, { useEffect, useCallback, useRef, useState } from 'react';
import ResizeDetector from 'element-resize-detector';

import useIframeHeightOverride from 'hooks/use-iframe-height-override';

export default function IframeHeightOverride(props) {
  const resizeDetectorRef = useRef();
  const elementRef = useRef();
  const [currentHeight, setCurrentHeight] = useState(0);

  useIframeHeightOverride(currentHeight);

  const handleResize = useCallback(
    (element) => {
      const newHeight = element.clientHeight;
      if (newHeight !== currentHeight) {
        setCurrentHeight(newHeight);
      }
    },
    [currentHeight]
  );

  useEffect(() => {
    if (!resizeDetectorRef.current) {
      resizeDetectorRef.current = ResizeDetector({
        strategy: `scroll`,
      });
    }
    const erd = resizeDetectorRef.current;
    const el = elementRef.current;

    if (!el) {
      return undefined;
    }

    erd.listenTo(el, handleResize);
    handleResize(el);

    return () => erd.uninstall(el);
  }, [elementRef]);

  return <div ref={elementRef} {...props} />;
}
