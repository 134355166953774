import React from 'react';
import { ModalContainer, BottomDrawer } from 'components/core';
import { ModalWrapper } from 'shared/modals/v2-wrapper';
import useCart from 'src/hooks/use-cart';
import { DesktopOnly, MobileOnly } from 'shared/components';
import { withModalControlsHook, ModalComponentProps } from 'components/modals/with-modal-controls-hook';
import { ClearYourCart } from './clear-your-cart';

export type ClearYourCartCloseResolvedValue = { cartCleared: boolean };

const ClearYourCartModal = ({
  onClose,
}: ModalComponentProps<undefined, ClearYourCartCloseResolvedValue>): JSX.Element => {
  const { dispensary, clearOrder } = useCart();

  if (!dispensary) {
    throw new Error(`ClearYourCart modal was opened without a dispensary in cart.`);
  }

  const handleClose = (): void => {
    onClose({ cartCleared: false });
  };

  const handleClearCart = (): void => {
    clearOrder();
    onClose({ cartCleared: true });
  };

  return (
    <>
      <DesktopOnly mediaQuery='sm'>
        <ModalWrapper open>
          <ModalContainer width='502px' p='45px'>
            <ClearYourCart currentDispensary={dispensary} onClose={handleClose} onClearCart={handleClearCart} />
          </ModalContainer>
        </ModalWrapper>
      </DesktopOnly>
      <MobileOnly mediaQuery='xs'>
        <BottomDrawer open onClose={handleClose}>
          <ClearYourCart currentDispensary={dispensary} onClose={handleClose} onClearCart={handleClearCart} />
        </BottomDrawer>
      </MobileOnly>
    </>
  );
};

const [ClearYourCartController, useClearYourCartModal] = withModalControlsHook<
  undefined,
  ClearYourCartCloseResolvedValue
>(ClearYourCartModal);

export { useClearYourCartModal, ClearYourCartController };
