import React from 'react';

const SvgUnionIcon = (props) => (
  <svg width='25' height='27' viewBox='0 0 25 27' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.74891 0C4.38154 0 4.03475 0.16341 3.8092 0.442793L0 5.1613V22.9391C0 24.8395 1.59898 26.38 3.57143 26.38H21.4286C23.401 26.38 25 24.8395 25 22.9391V5.1613L21.1908 0.442793C20.9653 0.16341 20.6185 0 20.2511 0H4.74891ZM5.07936 1.62866H19.9206L22.619 5.18422H2.38095L5.07936 1.62866ZM12.5 14.9103C15.433 14.927 17.8304 12.795 17.8571 10.144C17.8571 9.6084 17.5926 9.17565 17 9.17565C16.409 9.17565 16.3571 9.77303 16.3571 10.1314C16.3571 11.5651 14.7768 13.6679 12.5 13.6679C10.2232 13.6679 8.64286 11.5651 8.64286 10.1314C8.64286 9.86263 8.48382 9.17565 7.89286 9.17565C7.30022 9.17565 7.14286 9.6084 7.14286 10.144C7.16964 12.795 9.56696 14.927 12.5 14.9103Z'
      fill='#EAEFF2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.74891 0C4.38154 0 4.03475 0.16341 3.8092 0.442793L0 5.1613V22.9391C0 24.8395 1.59898 26.38 3.57143 26.38H21.4286C23.401 26.38 25 24.8395 25 22.9391V5.1613L21.1908 0.442793C20.9653 0.16341 20.6185 0 20.2511 0H4.74891ZM23.274 6.88174H1.72642V21.7922C1.78571 23.5126 2.38095 24.6596 4.10738 24.6596H20.8931C22.619 24.6596 23.2143 23.5126 23.274 21.7922V6.88174ZM5.07936 1.62866H19.9206L22.619 5.18422H2.38095L5.07936 1.62866ZM12.5 14.9103C15.433 14.927 17.8304 12.795 17.8571 10.144C17.8571 9.6084 17.5926 9.17565 17 9.17565C16.409 9.17565 16.3571 9.77302 16.3571 10.1314C16.3571 11.5651 14.7768 13.4 12.5 13.4C10.2232 13.4 8.64286 11.5651 8.64286 10.1314C8.64286 9.86263 8.48382 9.17565 7.89286 9.17565C7.30022 9.17565 7.14286 9.6084 7.14286 10.144C7.16964 12.795 9.56696 14.927 12.5 14.9103Z'
      fill='#5E6D79'
    />
  </svg>
);

export default SvgUnionIcon;
