import React from 'react';
import { DesktopOnly, MobileOnly } from 'shared/components';
import { GqlMarketplaceProduct } from 'types/graphql';

import { ModalWrapper } from 'shared/modals/v2-wrapper';

import { useGetBuyingOptions } from './hooks/use-get-buying-options';

import { ModalObserver, ModalObserverRenderProps } from '../modal-observer';
import { BuyingOptionsModal } from './modal';
import { BuyingOptionsDrawer } from './drawer';

export const BuyingOptionsWrapper = ({ data, ...props }: ModalObserverRenderProps): JSX.Element | null => {
  const product = data as GqlMarketplaceProduct;

  if (!product.id) {
    throw new Error(`Need a valid product id!`);
  }

  const { buyingOptions, loading } = useGetBuyingOptions(product.id);

  if (loading) {
    // TODO: render a spinner/loading state while we wait for data
    return null;
  }

  // no longer loading but no options were returned-- should never happen and is an indication of bad data
  if (!buyingOptions) {
    throw new Error(`Unable to find buying options for this product!`);
  }

  return (
    <>
      <MobileOnly>
        <BuyingOptionsDrawer buyingOptions={buyingOptions} product={product} {...props} />
      </MobileOnly>

      <DesktopOnly>
        <BuyingOptionsModal buyingOptions={buyingOptions} product={product} {...props} />
      </DesktopOnly>
    </>
  );
};

export const BuyingOptionsController = ({ name }): JSX.Element => (
  <ModalObserver name={name}>
    {(props) => (
      <ModalWrapper {...props}>
        <BuyingOptionsWrapper {...props} />
      </ModalWrapper>
    )}
  </ModalObserver>
);
