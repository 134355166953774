import { ModalsStore } from 'src/state/modals';
import { useStores } from 'src/contexts/stores';

type ModalsStoreInstance = InstanceType<typeof ModalsStore>;

export function useModals(): ModalsStoreInstance {
  const stores = useStores();
  const modals: ModalsStoreInstance = stores.Modals;

  return modals;
}
