/* eslint-disable max-len */
import React from 'react';

function ClosedSign() {
  return (
    <svg height='99' viewBox='0 0 97 99' width='97' xmlns='http://www.w3.org/2000/svg'>
      <mask id='a' fill='#fff'>
        <path d='m0 .033h97v98.914h-97z' fill='#fff' fillRule='evenodd' />
      </mask>
      <g fill='none' fillRule='evenodd'>
        <path
          d='m5.272 87.91v-40.26c.005-3.157 2.542-5.715 5.672-5.721h75.07c3.138-.018 5.696 2.534 5.714 5.7v40.28c-.006 3.166-2.554 5.727-5.693 5.721h-75.049c-3.138.017-5.696-2.534-5.714-5.7zm38.072-69.333a9.755 9.755 0 0 0 10.312 0l16.194 18.035h-42.743zm5.156-13.207c2.562.005 4.635 2.106 4.629 4.69s-2.088 4.674-4.65 4.668c-2.558-.007-4.629-2.1-4.629-4.679.007-2.586 2.086-4.679 4.65-4.679zm48.5 82.54v-40.26c-.006-6.102-4.915-11.044-10.965-11.038h-9.068l-19.695-21.905c2.554-4.89.695-10.941-4.152-13.518-4.848-2.577-10.848-.7-13.403 4.189a10.08 10.08 0 0 0 0 9.329l-19.526 21.671c-.06.075-.117.153-.169.234h-9.036c-6.05-.018-10.968 4.915-10.986 11.017v40.28c.005 6.102 4.915 11.044 10.965 11.038h75.049c6.05.017 10.968-4.915 10.986-11.017z'
          fill='#d75d5d'
          mask='url(#a)'
        />
        <path
          d='m13.227 74.882c-.192-2.15-.012-4.202.545-6.158.554-1.958 1.361-3.632 2.425-5.026a13.403 13.403 0 0 1 3.78-3.408c1.456-.876 2.989-1.386 4.6-1.528 1.61-.142 2.933.168 3.972.932 1.037.765 1.627 1.936 1.768 3.517.142 1.58-.346 3.027-1.457 4.338-1.115 1.313-2.618 2.237-4.506 2.773a7.413 7.413 0 0 1 -.676.125c-.8.07-1.228-.206-1.283-.83-.021-.236.073-.46.282-.675.21-.214.705-.463 1.486-.748 1.2-.454 2.14-1.079 2.82-1.876s.979-1.674.893-2.63c-.085-.958-.423-1.626-1.013-2.008s-1.37-.529-2.342-.442c-2.356.209-4.394 1.607-6.111 4.198-1.718 2.59-2.43 5.518-2.138 8.787.22 2.451.885 4.293 1.998 5.529 1.11 1.234 2.608 1.767 4.488 1.6 1.88-.165 3.538-.806 4.974-1.92a12.45 12.45 0 0 0 3.438-4.123c.073-.157.233-.248.482-.27s.511.052.789.223c.276.17.422.331.435.482.013.15.002.27-.033.36-.77 2.127-2.125 3.898-4.066 5.318-1.944 1.417-4.087 2.23-6.43 2.439-2.347.206-4.38-.437-6.104-1.932-1.725-1.494-2.73-3.842-3.016-7.047'
          fill='#d75d5d'
        />
        <path d='m13.227 74.882c-.192-2.15-.012-4.202.545-6.158.554-1.958 1.361-3.632 2.425-5.026a13.403 13.403 0 0 1 3.78-3.408c1.456-.876 2.989-1.386 4.6-1.528 1.61-.142 2.933.168 3.972.932 1.037.765 1.627 1.936 1.768 3.517.142 1.58-.346 3.027-1.457 4.338-1.115 1.313-2.618 2.237-4.506 2.773a7.413 7.413 0 0 1 -.676.125c-.8.07-1.228-.206-1.283-.83-.021-.236.073-.46.282-.675.21-.214.705-.463 1.486-.748 1.2-.454 2.14-1.079 2.82-1.876s.979-1.674.893-2.63c-.085-.958-.423-1.626-1.013-2.008s-1.37-.529-2.342-.442c-2.356.209-4.394 1.607-6.111 4.198-1.718 2.59-2.43 5.518-2.138 8.787.22 2.451.885 4.293 1.998 5.529 1.11 1.234 2.608 1.767 4.488 1.6 1.88-.165 3.538-.806 4.974-1.92a12.45 12.45 0 0 0 3.438-4.123c.073-.157.233-.248.482-.27s.511.052.789.223c.276.17.422.331.435.482.013.15.002.27-.033.36-.77 2.127-2.125 3.898-4.066 5.318-1.944 1.417-4.087 2.23-6.43 2.439-2.347.206-4.38-.437-6.104-1.932-1.725-1.494-2.73-3.842-3.016-7.047z' />
        <path
          d='m39.49 61.47c-.973.082-1.844 1.318-2.609 3.713s-1.221 4.643-1.371 6.746c1.233-1.54 2.335-3.264 3.31-5.175.976-1.911 1.417-3.472 1.324-4.684-.041-.432-.258-.632-.653-.6m-2.459 19.057c-1.16.097-2.095-.268-2.805-1.09-.71-.824-1.137-1.991-1.282-3.504a11.46 11.46 0 0 1 -.064-1.04c.002-3.16.572-6.496 1.709-10.01.564-1.681 1.33-3.1 2.294-4.258.966-1.16 2.04-1.787 3.223-1.885.695-.058 1.274.107 1.731.493.457.387.717.894.777 1.52l.025.26c-.025 2.203-.758 4.562-2.2 7.076-1.444 2.517-3.106 4.592-4.983 6.23-.027.7-.023 1.234.013 1.6.167 1.75.807 2.58 1.92 2.487 1.16-.095 2.376-.96 3.647-2.589a.845.845 0 0 1 .588-.277c.243-.02.483.053.722.218.237.164.37.383.395.653.026.27-.087.557-.338.86-1.586 2.006-3.377 3.091-5.372 3.256'
          fill='#d75d5d'
        />
        <path d='m39.49 61.47c-.973.082-1.844 1.318-2.609 3.713s-1.221 4.643-1.371 6.746c1.233-1.54 2.335-3.264 3.31-5.175.976-1.911 1.417-3.472 1.324-4.684-.041-.432-.258-.632-.653-.6zm-2.458 19.057c-1.16.097-2.095-.268-2.805-1.09-.71-.824-1.137-1.991-1.282-3.504a11.46 11.46 0 0 1 -.064-1.04c.002-3.16.572-6.496 1.709-10.01.564-1.681 1.33-3.1 2.294-4.258.966-1.16 2.04-1.787 3.223-1.885.695-.058 1.274.107 1.731.493.457.387.717.894.777 1.52l.025.26c-.025 2.203-.758 4.562-2.2 7.076-1.444 2.517-3.106 4.592-4.983 6.23-.027.7-.023 1.234.013 1.6.167 1.75.807 2.58 1.92 2.487 1.16-.095 2.376-.96 3.647-2.589a.845.845 0 0 1 .588-.277c.243-.02.483.053.722.218.237.164.37.383.395.653.026.27-.087.557-.338.86-1.586 2.006-3.377 3.091-5.372 3.256z' />
        <path
          d='m43.665 78.278c.538-.053 1.015-.435 1.427-1.144.411-.711.623-1.401.63-2.072-.795-.29-1.215-.723-1.262-1.296-.046-.572.177-1.17.67-1.799-.045-.294-.13-.506-.254-.632-.126-.127-.292-.179-.499-.159-.787.078-1.418.655-1.895 1.729-.477 1.075-.672 2.161-.583 3.262.121 1.511.71 2.216 1.766 2.111m-.289 2.247c-1.035.1-1.916-.229-2.646-.987-.73-.76-1.143-1.757-1.242-2.996-.152-1.878.268-3.6 1.26-5.163.989-1.564 2.23-2.42 3.722-2.567.869-.086 1.626.186 2.273.816.644.629 1.008 1.47 1.093 2.524.019.23.007.6-.036 1.112 1.325-.407 2.584-1.501 3.774-3.281a.43.43 0 0 1 .317-.17c.134-.014.282.082.445.285.162.203.251.414.27.632.016.218-.012.377-.087.476-1.235 2.04-2.852 3.468-4.852 4.289-.439 3.114-1.869 4.79-4.29 5.03'
          fill='#d75d5d'
        />
        <path d='m43.665 78.278c.538-.053 1.015-.435 1.427-1.144.411-.711.623-1.401.63-2.072-.795-.29-1.215-.723-1.262-1.296-.046-.572.177-1.17.67-1.799-.045-.294-.13-.506-.254-.632-.126-.127-.292-.179-.499-.159-.787.078-1.418.655-1.895 1.729-.477 1.075-.672 2.161-.583 3.262.121 1.511.71 2.216 1.766 2.111zm-.289 2.247c-1.035.1-1.916-.229-2.646-.987-.73-.76-1.143-1.757-1.242-2.996-.152-1.878.268-3.6 1.26-5.163.989-1.564 2.23-2.42 3.722-2.567.869-.086 1.626.186 2.273.816.644.629 1.008 1.47 1.093 2.524.019.23.007.6-.036 1.112 1.325-.407 2.584-1.501 3.774-3.281a.43.43 0 0 1 .317-.17c.134-.014.282.082.445.285.162.203.251.414.27.632.016.218-.012.377-.087.476-1.235 2.04-2.852 3.468-4.852 4.289-.439 3.114-1.869 4.79-4.29 5.03z' />
        <path
          d='m54.243 76.952.131-.013c1.09-.102 1.591-.665 1.505-1.69-.088-1.028-.723-2.3-1.908-3.822l-1.767 4.784c.816.543 1.494.792 2.04.74m.984 1.872c-1.352.128-2.558-.042-3.618-.507-1.061-.464-1.824-1.1-2.288-1.906.81-1.822 1.866-4.517 3.167-8.085.288-.74.76-1.14 1.414-1.2.326-.031.614.051.86.247.248.195.385.464.415.805.026.32-.015.6-.126.84s-.159.475-.139.702c.02.23.296.627.828 1.198.53.57 1.063 1.212 1.596 1.92.532.709.822 1.342.87 1.902.047.559-.031 1.053-.237 1.486.973-.435 1.964-1.447 2.972-3.035a.64.64 0 0 1 .456-.248c.185-.018.405.116.66.403s.392.539.411.756c.018.216-.01.42-.08.61-.544 1.084-1.423 2-2.634 2.745-1.21.744-2.72 1.2-4.527 1.367'
          fill='#d75d5d'
        />
        <path d='m54.243 76.952.131-.013c1.09-.102 1.591-.665 1.505-1.69-.088-1.028-.723-2.3-1.908-3.822l-1.767 4.784c.816.543 1.494.792 2.04.74zm.985 1.871c-1.352.128-2.558-.042-3.618-.507-1.061-.464-1.824-1.1-2.288-1.906.81-1.822 1.866-4.517 3.167-8.085.288-.74.76-1.14 1.414-1.2.326-.031.614.051.86.247.248.195.385.464.415.805.026.32-.015.6-.126.84s-.159.475-.139.702c.02.23.296.627.828 1.198.53.57 1.063 1.212 1.596 1.92.532.709.822 1.342.87 1.902.047.559-.031 1.053-.237 1.486.973-.435 1.964-1.447 2.972-3.035a.64.64 0 0 1 .456-.248c.185-.018.405.116.66.403s.392.539.411.756c.018.216-.01.42-.08.61-.544 1.084-1.423 2-2.634 2.745-1.21.744-2.72 1.2-4.527 1.367z' />
        <path
          d='m64.447 69.381c-.728.068-1.393.563-1.991 1.49-.598.925-.855 1.886-.769 2.876 2.719-1.247 4.031-2.413 3.938-3.499-.056-.644-.448-.934-1.178-.867m-5.23 5.22c-.15-1.706.259-3.341 1.22-4.906.961-1.563 2.217-2.416 3.765-2.557.92-.084 1.706.112 2.357.587.653.473 1.018 1.161 1.095 2.06.112 1.269-.306 2.34-1.248 3.217-.94.877-2.415 1.628-4.419 2.253.4.963 1.251 1.384 2.552 1.265 1.795-.164 3.423-1.103 4.88-2.817.082-.1.218-.159.409-.177.19-.017.377.047.56.193a.79.79 0 0 1 .303.564c.02.232-.04.47-.177.715-.49.857-1.313 1.684-2.464 2.473-1.154.792-2.387 1.247-3.7 1.367-1.312.121-2.464-.176-3.452-.887-.989-.711-1.55-1.827-1.682-3.35'
          fill='#d75d5d'
        />
        <path d='m64.447 69.381c-.728.068-1.393.563-1.991 1.49-.598.925-.855 1.886-.769 2.876 2.719-1.247 4.031-2.413 3.938-3.499-.056-.644-.448-.934-1.178-.867zm-5.23 5.22c-.15-1.706.259-3.341 1.22-4.906.961-1.563 2.217-2.416 3.765-2.557.92-.084 1.706.112 2.357.587.653.473 1.018 1.161 1.095 2.06.112 1.269-.306 2.34-1.248 3.217-.94.877-2.415 1.628-4.419 2.253.4.963 1.251 1.384 2.552 1.265 1.795-.164 3.423-1.103 4.88-2.817.082-.1.218-.159.409-.177.19-.017.377.047.56.193a.79.79 0 0 1 .303.564c.02.232-.04.47-.177.715-.49.857-1.313 1.684-2.464 2.473-1.154.792-2.387 1.247-3.7 1.367-1.312.121-2.464-.176-3.452-.887-.989-.711-1.55-1.827-1.682-3.35z' />
        <path
          d='m79.693 56.584c-.696.067-1.203.735-1.522 2.002a24.995 24.995 0 0 0 -.523 2.338c-.168.921-.333 3.247-.491 6.975.071-.145.142-.313.213-.502.037-.05.256-.489.655-1.319.398-.828.747-1.601 1.05-2.317.3-.717.614-1.676.94-2.877.326-1.199.453-2.226.382-3.079-.07-.853-.304-1.26-.704-1.22m-6.78 18.151c.59-.057 1.045-.404 1.364-1.042.319-.637.516-1.763.597-3.376l.164-2.629c-1.115.107-2 .697-2.654 1.769s-.931 2.221-.83 3.449l.03.375c.151 1.04.593 1.525 1.33 1.454m-3.832-1.317c-.152-1.864.254-3.617 1.222-5.257.969-1.64 2.158-2.529 3.569-2.664.356-.034.73-.002 1.12.099.347-7.643 1.993-11.607 4.94-11.89a2.091 2.091 0 0 1 1.65.529c.47.414.75.924.843 1.535.033.158.063.396.09.713.165 2.002-.202 4.11-1.1 6.329a63.395 63.395 0 0 1 -1.185 2.83c-.296.648-.82 1.684-1.573 3.108a285.15 285.15 0 0 0 -1.293 2.462l.025.307c.16 1.957.726 2.887 1.694 2.794 1.095-.105 2.162-1.044 3.204-2.817.096-.125.26-.197.492-.22.23-.022.461.059.688.244a.956.956 0 0 1 .375.685c.022.273-.04.543-.188.81-1.287 2.14-2.837 3.298-4.646 3.471-1.411.136-2.506-.549-3.288-2.055-.477 1.673-1.516 2.587-3.115 2.74-.906.086-1.695-.215-2.366-.907-.672-.691-1.058-1.64-1.158-2.846'
          fill='#d75d5d'
        />
        <path d='m79.693 56.584c-.696.067-1.203.735-1.522 2.002a24.995 24.995 0 0 0 -.523 2.338c-.168.921-.333 3.247-.491 6.975.071-.145.142-.313.213-.502.037-.05.256-.489.655-1.319.398-.828.747-1.601 1.05-2.317.3-.717.614-1.676.94-2.877.326-1.199.453-2.226.382-3.079-.07-.853-.304-1.26-.704-1.22zm-6.78 18.152c.59-.057 1.045-.404 1.364-1.042.319-.637.516-1.763.597-3.376l.164-2.629c-1.115.107-2 .697-2.654 1.769s-.931 2.221-.83 3.449l.03.375c.151 1.04.593 1.525 1.33 1.454zm-3.831-1.317c-.152-1.864.254-3.617 1.222-5.257.969-1.64 2.158-2.529 3.569-2.664.356-.034.73-.002 1.12.099.347-7.643 1.993-11.607 4.94-11.89a2.091 2.091 0 0 1 1.65.529c.47.414.75.924.843 1.535.033.158.063.396.09.713.165 2.002-.202 4.11-1.1 6.329a63.395 63.395 0 0 1 -1.185 2.83c-.296.648-.82 1.684-1.573 3.108a285.15 285.15 0 0 0 -1.293 2.462l.025.307c.16 1.957.726 2.887 1.694 2.794 1.095-.105 2.162-1.044 3.204-2.817.096-.125.26-.197.492-.22.23-.022.461.059.688.244a.956.956 0 0 1 .375.685c.022.273-.04.543-.188.81-1.287 2.14-2.837 3.298-4.646 3.471-1.411.136-2.506-.549-3.288-2.055-.477 1.673-1.516 2.587-3.115 2.74-.906.086-1.695-.215-2.366-.907-.672-.691-1.058-1.64-1.158-2.846z' />
      </g>
    </svg>
  );
}

export default ClosedSign;
