import { ChangeEvent, useState, useRef } from 'react';
import _ from 'lodash';

import useMapboxPlacesAutocomplete from 'src/hooks/use-mapbox-places-autocomplete';
import { AddressAutocompleteContextValue, AddressAutocompleteProps, SearchMode } from '../types';
import { useGeolocation } from './use-geolocation';

export function useSetupContext({
  suggestionTypes,
  onSelect,
}: Omit<AddressAutocompleteProps, 'children'>): AddressAutocompleteContextValue & { handleSelect: (val: any) => void } {
  const inputRef = useRef<HTMLInputElement>();
  const [displayValue, setDisplayValue] = useState<string>('');
  const [searchMode, setSearchMode] = useState<SearchMode>(SearchMode.userInput);

  const {
    setValue: setMapboxSearchTerm,
    suggestions: { data: mapboxSuggestions },
    clearSuggestions: clearMapboxSuggestions,
  } = useMapboxPlacesAutocomplete({
    requestOptions: {
      ...(suggestionTypes && { types: suggestionTypes }),
    },
  });

  const { geolocationLoading, geolocationSuggestions, handleGeolocation } = useGeolocation({
    setSearchMode,
  });

  const suggestions = _.uniqBy(
    searchMode === SearchMode.geolocation ? geolocationSuggestions : mapboxSuggestions,
    `address`
  );

  function handleSelect(value: any): void {
    onSelect(suggestions.find((s) => s.address === value));
    setDisplayValue(value);
    clearMapboxSuggestions();
    setSearchMode(SearchMode.userInput);
  }

  function clearInput(): void {
    clearMapboxSuggestions();
    setMapboxSearchTerm('');
    setDisplayValue('');
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>): void {
    setDisplayValue(e.target.value);
    setMapboxSearchTerm(e.target.value);
    setSearchMode(SearchMode.userInput);
  }

  return {
    inputRef,
    clearInput,
    displayValue,
    geolocationLoading,
    handleGeolocation,
    handleInputChange,
    suggestions,
    handleSelect,
  };
}
