import React from 'react';
import styled from 'styled-components';

import { Tab, Tabs } from 'components/core';
import useTranslation from 'hooks/use-translation';
import { ORDER_TYPE_DELIVERY, ORDER_TYPE_PICKUP } from 'shared/constants';
import { OrderTypeTabsProps } from '../location-settings-popover.types';

export function OrderTypeTabs({ orderType, onChangeOrderType }: OrderTypeTabsProps): JSX.Element {
  const { t } = useTranslation();

  const pickupText = t('common.pickup', 'Pickup');
  const deliveryText = t('common.delivery', 'Delivery');

  return (
    <StyledTabs centered value={orderType} onChange={onChangeOrderType}>
      <Tab label={pickupText} aria-label={pickupText} value={ORDER_TYPE_PICKUP} />
      <Tab label={deliveryText} aria-label={deliveryText} value={ORDER_TYPE_DELIVERY} />
    </StyledTabs>
  );
}

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    height: 3px;
    background-color: rgb(21, 63, 102);
  }

  .MuiTab-textColorInherit {
    color: ${({ theme }) => theme.colors.grey[30]};
  }

  .MuiTab-textColorInherit.Mui-selected {
    color: rgb(21, 63, 102);
  }

  .Mui-focusVisible {
    outline: ${({ theme }) => `2px solid ${theme.colors.blueShadow as string}`};
  }

  .MuiTab-root {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 0 17px 10px;
  }
`;
