import React from 'react';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { ModalContainer } from 'components/core';
import { ShoppingWithModalProps } from './shopping-with.types';
import { ShoppingWith } from './shopping-with';

export const ShoppingWithModal = ({
  dispensary,
  handleContinue,
  onClose,
  open,
}: ShoppingWithModalProps): JSX.Element => (
  <ModalWrapper open={open}>
    <ModalContainer width='502px' pt='45px'>
      <ShoppingWith buttonSize='medium' dispensary={dispensary} handleContinue={handleContinue} onClose={onClose} />
    </ModalContainer>
  </ModalWrapper>
);
