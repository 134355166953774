import styled, { css } from 'styled-components';

import { ModalContainer, ModalExitIcon } from 'shared/modals/v2-container';
import { Typography } from 'src/typography';

export const StyledModalContainer = styled(ModalContainer)`
  display: flex;
  overflow: hidden;
  text-align: left;
  width: 740px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    &&& {
      top: 28px;
      ${({ isIframe }) =>
        isIframe
          ? css`
              width: ${({ viewportWidth }) => viewportWidth * 0.85}px;
            `
          : css`
              transform: none;
              width: 85vw;
            `}
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    padding: 0;
  }
`;

export const StyledModalExitIcon = styled(ModalExitIcon)`
  fill: #fff;
  right: 15px;
  top: 15px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    fill: ${({ theme }) => theme.colors.searchIconFill};
  }
`;

export const OrderSubmitted = styled(Typography.Heading)`
  font-weight: bold;
  line-height: 1.3;
  max-width: 350px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 500px;
  }
`;

export const OrderInstructions = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.grey[35]};
  margin-top: 15px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

export const OrderDetailsWrapper = styled.div`
  margin-top: 35px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 40px;
  }
`;

export const OrderDetail = styled.div`
  display: flex;
  margin-bottom: 30px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: 45px;
  }
`;

export const DetailIcon = styled.div`
  align-items: center;
  background-color: #eaedef;
  border-radius: 100%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 18px;
  width: 40px;

  & > svg {
    width: 17px;

    & > path {
      fill: ${({ theme }) => theme.colors.grey[30]};
    }
  }
`;

export const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailTitle = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.grey[30]};
  font-size: 11px;
  text-transform: uppercase;
`;

export const DetailText = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.grey[35]};
  font-size: 14px;
  line-height: 1;
  margin-top: 8px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 16px;
  }
`;

export const SuccessContainer = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  padding: 35px 40px 0 35px;
  width: auto;

  img {
    margin-left: 9px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 45px 47px 30px;
    width: 65%;
  }
`;

export const BannerContainer = styled.div`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 40%;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 268px;
  }
`;
