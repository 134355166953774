import { NextRouter } from 'next/router';
import { AnalyticsContext } from 'src/utils/analytics/events';
import {
  ItemListName,
  GTMCartItem,
  ProductRecommendationSection,
  ProductRecommendationSource,
} from '../internal-gtm-tracker.types';
import { PageTypes, getPageType } from './get-page-type';

type BuildGTMNavigationBreadcrumbsArgs = {
  category?: { value: string; key: string } | null;
  currentSpecial?: { name: string; specialType: string } | null;
  heading: string;
  pageType: PageTypes | null;
};

export function buildGTMNavigationBreadcrumbs({
  category,
  currentSpecial,
  heading,
  pageType,
}: BuildGTMNavigationBreadcrumbsArgs): string | null {
  if (pageType === PageTypes.categoryPage) {
    if (category?.value) {
      return `Category > ${category.value} > ${heading}`;
    }
  }

  if (pageType === PageTypes.searchResultsPage) {
    return `Search Results`;
  }

  if (pageType === PageTypes.brandsPage) {
    return heading === 'Brands' ? heading : `Brands > ${heading}`;
  }

  if (pageType === PageTypes.specialsPage) {
    if (currentSpecial) {
      return `Specials > ${currentSpecial.specialType === 'bogo' ? `Offer` : `Sale`} > ${currentSpecial.name}`;
    }
    return `Specials`;
  }

  return null;
}

type InjectItemIndexArgs = {
  context: AnalyticsContext;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  GTMItem: GTMCartItem & { affiliation?: string | null };
};
// eslint-disable-next-line @typescript-eslint/naming-convention
export function injectItemIndex({ context, GTMItem }: InjectItemIndexArgs): void {
  if (context.activeProductPosition != null) {
    GTMItem.index = context.activeProductPosition;
  }
}

type GetItemListNameArgs = {
  router: NextRouter;
  source: ProductRecommendationSource;
  section: ProductRecommendationSection | string;
};

export const getItemListName = ({ router, source, section }: GetItemListNameArgs): ItemListName =>
  [getPageType(router), source, section].join(' > ') as ItemListName;
