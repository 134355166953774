import { useEffect } from 'react';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useDutchiePay } from 'src/payments/hooks';

import { tracker } from '..';

type ReturnValue = {
  loading: boolean;
};

export const useDispensaryMonitor = (): ReturnValue => {
  const { dispensary, loading } = useDispensary();
  const { isDutchiePayEnabledForDispo } = useDutchiePay();

  useEffect(() => {
    if (!loading && dispensary) {
      tracker.setContext({ activeDispensary: { isDutchiePayEnabledForDispo, ...dispensary } });
    }
  }, [loading, dispensary, isDutchiePayEnabledForDispo]);
  return { loading };
};
