import React from 'react';
import _ from 'lodash';
import { useObserver } from 'mobx-react';

import useCart from 'hooks/use-cart';
import useSearchPreferences from 'hooks/use-search-preferences';
import { useDurationEstimates } from 'hooks/use-duration-estimates';
import { getDeliveryFee, dispensaryStatusForUser } from 'shared/helpers/dispensaries';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { convertMetersToMiles } from 'shared/helpers/address';

import { DispensaryOrderingDetails } from './dispensary-ordering-details';
import { DispensaryOrderingDetailsControllerProps } from './dispensary-ordering-details.types';

export function DispensaryOrderingDetailsController({
  dispensary,
  variant = `default`,
}: DispensaryOrderingDetailsControllerProps): JSX.Element {
  const Cart = useCart();
  const SearchPreferences = useSearchPreferences();
  const { durationEstimates } = useDurationEstimates(dispensary);
  const isPickup = useObserver(() => SearchPreferences.isPickup);
  const orderType = useObserver(() => SearchPreferences.orderType);

  const { distance, name } = dispensary;

  const deliveryFee: string = getDeliveryFee(dispensary, Cart.hasDeliveryAddress()) || '0';

  const formattedDeliveryFee = deliveryFee.includes('%')
    ? Number(deliveryFee.replace(/%/, ''))
    : Number(deliveryFee.replace(/\D/, ''));
  const showDeliveryFee = formattedDeliveryFee > 0 && !isPickup;
  const showFreeDeliveryIcon = formattedDeliveryFee === 0 && !isPickup;

  const milesFromUser = Math.round(convertMetersToMiles(Number(distance))) || 1;

  const openInfo = openInfoForDispensary(dispensary);
  const offeringString = dispensaryStatusForUser(dispensary, openInfo);
  const isOpen = _.some(openInfo, `isOpen`);

  const lowInMinutes = durationEstimates[orderType]?.lowInMinutes;
  const highInMinutes = durationEstimates[orderType]?.highInMinutes;
  const estimatesAreValid = Number.isFinite(lowInMinutes) && Number.isFinite(highInMinutes);
  const showEstimates = isPickup
    ? estimatesAreValid
    : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      estimatesAreValid && !dispensary.featureFlags?.hideDeliveryEstimate;

  return (
    <DispensaryOrderingDetails
      dispensaryName={name}
      showEstimates={showEstimates}
      lowInMinutes={lowInMinutes}
      highInMinutes={highInMinutes}
      isOpen={isOpen}
      isPickup={isPickup}
      milesFromUser={milesFromUser}
      offeringString={offeringString}
      showDeliveryFee={showDeliveryFee}
      deliveryFee={deliveryFee}
      showFreeDeliveryIcon={showFreeDeliveryIcon}
      variant={variant}
    />
  );
}
