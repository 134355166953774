import { useCallback, useEffect } from 'react';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useStores from 'shared/hooks/use-stores';
import { useCountdownTimer } from 'src/hooks/use-countdown-timer';

type UseKioskCheckoutSuccessModalV2Params = {
  onClose: () => void;
};

type UseKioskCheckoutSuccessModalV2Return = {
  secondsLeft: number;
  handleOnClose: () => void;
  isAnonymousKioskCheckout: boolean;
  orderNumber: string | null;
  kioskInstructions: string;
};

export function useKioskCheckoutSuccessModalV2({
  onClose,
}: UseKioskCheckoutSuccessModalV2Params): UseKioskCheckoutSuccessModalV2Return {
  const { Cart } = useStores();
  const { dispensary } = useDispensary();
  const isAnonymousKioskCheckout = Cart?.isAnonymousKioskCheckout ?? false;
  const kioskInstructions = dispensary?.orderTypesConfigV2?.kiosk?.kioskInstructions ?? '';
  const orderNumber = Cart?.anonymousOrderId ?? null;

  const handleOnClose = useCallback((): void => {
    Cart.setAnonymousOrderId('');
    Cart.setIsAnonymousKioskCheckout(false);
    onClose();
  }, [Cart, onClose]);

  const { startTimer, secondsLeft } = useCountdownTimer({
    initialSeconds: 8,
    onTimerStop: handleOnClose,
  });

  useEffect(() => {
    startTimer();
  }, [startTimer]);

  return {
    secondsLeft,
    handleOnClose,
    isAnonymousKioskCheckout,
    orderNumber,
    kioskInstructions,
  };
}
