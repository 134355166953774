import { useEffect, useState } from 'react';

/*
  This hook is intended to be used inside other hooks that need to skip some aspect of rendering or
  data loading when SSR is being run.

  e.g.

  function SomeComponent() {
    const isFirstRender = useIsFirstRender();

    const { data } = useQuery(someQueryWeDontWantToRunInSSR, { skip: isFirstRender });

    return (...);
  }
 */
export function useIsFirstRender(): boolean {
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);
  return isFirstRender;
}
