/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import TagManager from 'react-gtm-module';
import _once from 'lodash/once';
import _map from 'lodash/map';
import _mapKeys from 'lodash/mapKeys';
import _ from 'lodash';
import { noCase, snakeCase } from 'change-case-all';
import noop from 'lodash/noop';
import router from 'next/router';

import PublicEnv from 'shared/utils/public-env';
import { ORDER_TYPE_DELIVERY, ORDER_TYPE_PICKUP } from 'shared/constants';
import { removeNullishValues } from 'shared/utils/remove-nullish-values';

import { tracker } from 'src/utils/analytics';
import { getCategory } from 'src/hooks/use-dispensary-category';
import { AnalyticsContext, EventTracker } from '../../events';
import {
  cartItemContext,
  cartItemToGTMCartItem,
  ecommerceGTMMetaData,
  getDispensaryContext,
  getExperimentContext,
  getUserContext,
  getCartContext,
} from './helpers/internal-gtm-converters';
import { getPageType } from './helpers/get-page-type';
import { injectItemIndex } from './helpers/item-list-name';
import { injectPageType } from './helpers/inject-page-type';
import { ContextCache, GA4_EVENTS, Payload } from './internal-gtm-tracker.types';
import { convertPromotionToGTM } from './helpers/convert-promotion-to-gtm';
import { createCreativeSlot } from './helpers/create-creative-slot';

export const DATA_LAYER_KEY = 'EcommDataLayer';

const setupClient = _once(() =>
  TagManager.initialize({
    gtmId: PublicEnv.gtmContainerKey,
    dataLayerName: DATA_LAYER_KEY,
    dataLayer: {
      'gtm.whitelist': ['google', 'customPixels', 'flc', 'fls', 'twitter_website_tag', 'customScripts'],
    },
  })
);

const initInternalGTMTracker = (enabled): any => {
  if (!enabled) {
    return;
  }

  setupClient();
};

// Generally you shouldn't be using this directly, but we needed it for a one-off case of tracking the experiment framework ENG-37271
export function pushInternalGTMDataLayer(payload: Payload, key: string): void {
  if (!window[key]) {
    window[key] = [];
  }
  if (window[key]) {
    window[key].push(payload);
  }
}

type TrackerArgs = {
  enabled: boolean;
};

export const createInternalGTMTracker = ({ enabled }: TrackerArgs): EventTracker => {
  initInternalGTMTracker(enabled);

  function pushToDataLayer(payload: Payload, key = DATA_LAYER_KEY): void {
    if (enabled) {
      // clearing ecommerce data if it exists
      // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
      if (payload.ecommerce) {
        window[key].push({ ecommerce: null });
      }

      pushInternalGTMDataLayer(payload, key);
    } else {
      console.warn('Error pushing to datalayer, no GTM instance available.');
    }
  }

  const contextCache: ContextCache = {
    activeCart: null,
    activeDispensary: null,
    activePath: null,
    activeUser: null,
    activeMenuVariant: '',
    activeOrder: null,
    activeExperiments: null,
    activePathHasChanged: false,
  };

  function updateContextCache({ contextUpdates }): void {
    Object.keys(contextUpdates).forEach((key) => {
      if (key in contextCache) {
        contextCache[key] = contextUpdates[key];
      }
    });
  }

  function getCategoryValue(context: AnalyticsContext): string | null {
    return (
      getCategory(
        router.query.category?.toString()?.toLowerCase(),
        context.activeDispensary ?? contextCache.activeDispensary
      )?.value ?? null
    );
  }

  // eslint-disable-next-line consistent-return
  return {
    onContextChange: (updates, newContext, oldContext) => {
      updateContextCache({ contextUpdates: updates });
      const dispensaryContextNotLoadedYet = !newContext.activeDispensary;
      const dispensaryNeededAndAvailable = newContext.activeDispensary;
      const userContextNotLoadedYet = !newContext.activeUser;
      const userNeededAndAvailable = newContext.activeUser;
      const cartContextNotLoadedYet = !newContext.activeCart;
      const cartNeededAndAvailable = newContext.activeCart;

      if (newContext.activePath?.concrete !== oldContext.activePath?.concrete) {
        contextCache.activePathHasChanged = true;
      }

      if (contextCache.activePathHasChanged) {
        const dispensaryContext =
          !dispensaryContextNotLoadedYet || dispensaryNeededAndAvailable
            ? getDispensaryContext(newContext, contextCache)
            : {};

        const userContext =
          !userContextNotLoadedYet || userNeededAndAvailable ? getUserContext(newContext, contextCache) : {};

        const cartContext =
          !cartContextNotLoadedYet || cartNeededAndAvailable ? getCartContext(newContext, contextCache) : {};

        const payload: Payload = {
          event: `page_view`,
          ...removeNullishValues(dispensaryContext),
          ...removeNullishValues(userContext),
          ...removeNullishValues(cartContext),
          page_url: window.location.href,
          page_path: newContext.activePath?.concrete,
          data_source: newContext.activeMenuVariant ?? updates.activeMenuVariant ?? `default`,
          page_title: document.title,
          page_type: getPageType(router),
        };
        pushInternalGTMDataLayer(payload, DATA_LAYER_KEY);
        contextCache.activePathHasChanged = false;
      }
    },
    experimentImpression: (payload, context) => {
      pushToDataLayer({
        event: GA4_EVENTS.experimentImpression,
        experiment_id: payload.flag,
        variation_name: payload.value,
        data_source: payload.source,
        ...getDispensaryContext(context, contextCache),
        ...getExperimentContext(context, contextCache),
        ...getUserContext(context, contextCache),
      });
    },

    // Login Events
    accountLogin(payload, context) {
      const { newAccount, customerId, analyticsEventLabel } = payload;
      const page = getPageType(router);
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: `account`,
        event_action: newAccount ? `created account` : `signed in`,
        event_label: analyticsEventLabel ?? page?.toLowerCase(),
        customer_id: customerId,
        ...getDispensaryContext(context, contextCache),
      });
    },

    // Menu Events
    addedProductToCart(payload, context) {
      const itemAdded = {
        ...cartItemToGTMCartItem(payload),
        affiliation: context.activeDispensary?.name,
      };

      injectItemIndex({ context, GTMItem: itemAdded });

      // if product was added from a promotion, add the promotion info to the item
      if (context.activePromotion) {
        itemAdded.item_list_id = context.activePromotion.campaignId;
        itemAdded.item_list_name = context.activePromotion.trackerSource;
        itemAdded.creative_slot = createCreativeSlot({ router, category: getCategoryValue(context) });
        tracker.setContext({ activePromotion: null });
      }

      updateContextCache({ contextUpdates: context });
      pushToDataLayer({
        event: GA4_EVENTS.addToCart,
        ...getDispensaryContext(context, contextCache),
        ...getExperimentContext(context, contextCache),
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache }),
          items: [itemAdded],
        },
      });
    },
    cartViewed(payload, context) {
      const items = _map(payload.products, (item) => ({
        ...cartItemContext(context, contextCache, payload),
        ...cartItemToGTMCartItem(item),
      }));
      pushToDataLayer({
        event: GA4_EVENTS.viewCart,
        ...getDispensaryContext(context, contextCache),
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache, payload }),
          items,
        },
      });
    },
    productClicked(payload, context) {
      const productSelected = {
        ...cartItemToGTMCartItem(payload),
        affiliation: context.activeDispensary?.name,
      };

      injectItemIndex({ context, GTMItem: productSelected });

      pushToDataLayer({
        event: GA4_EVENTS.selectItem,
        page_type: getPageType(router),
        ...getDispensaryContext(context, contextCache),
        ...getExperimentContext(context, contextCache),
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache }),
          items: [productSelected],
        },
      });
    },
    removedProductFromCart(payload, context) {
      pushToDataLayer({
        event: GA4_EVENTS.removeFromCart,
        ...getDispensaryContext(context, contextCache),
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache }),
          items: [cartItemToGTMCartItem(payload)],
        },
      });
    },
    searchedForProduct(payload) {
      pushToDataLayer({
        event: GA4_EVENTS.search,
        search_term: payload.query,
      });
    },
    searchResults(payload, context) {
      const { query, hasResults } = payload;

      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: `search`,
        event_action: hasResults ? `search results` : `no search results`,
        event_label: query.toLowerCase(),
        ...getDispensaryContext(context, contextCache),
      });
    },
    viewedProduct(payload, context) {
      const productSelected = {
        ...cartItemToGTMCartItem(payload),
        ...cartItemContext(context, contextCache),
        affiliation: context.activeDispensary?.name,
      };

      injectItemIndex({ context, GTMItem: productSelected });

      pushToDataLayer({
        event: GA4_EVENTS.viewItem,
        ...getDispensaryContext(context, contextCache),
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache }),
          items: [productSelected],
        },
      });
    },
    // Menu Events > Ads & Personalization
    buyItAgainImpression(payload, context) {
      payload.analyticsPage = createCreativeSlot({ router, category: getCategoryValue(context) });
      const convertedPayload = convertPromotionToGTM(payload);

      pushToDataLayer({
        event: GA4_EVENTS.viewItemList,
        ...getUserContext(context, contextCache),
        ...getDispensaryContext(context, contextCache),
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache }),
          ...convertedPayload,
        },
      });
    },
    buyItAgainViewAllClicked(payload, context) {
      const { destinationUrl, trackerSource } = payload;
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'content',
        event_action: 'click',
        event_label: 'buy it again view all',
        click_url: destinationUrl,
        data_source: trackerSource,
        ...getUserContext(context, contextCache),
        ...getDispensaryContext(context, contextCache),
      });
    },
    sponsoredProductImpression(payload, context) {
      payload.analyticsPage = createCreativeSlot({ router, category: getCategoryValue(context) });
      const convertedPayload = convertPromotionToGTM(payload);

      pushToDataLayer({
        event: GA4_EVENTS.viewPromotion,
        ...getDispensaryContext(context, contextCache),
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache }),
          ...convertedPayload,
        },
      });
    },
    sponsoredProductClicked(payload, context) {
      payload.analyticsPage = createCreativeSlot({ router, category: getCategoryValue(context) });
      const convertedPayload = convertPromotionToGTM(payload);

      pushToDataLayer({
        event: GA4_EVENTS.selectPromotion,
        ...getDispensaryContext(context, contextCache),
        ...getExperimentContext(context, contextCache),
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache }),
          ...convertedPayload,
        },
      });
    },
    sponsoredBannerClicked: noop,
    sponsoredBannerImpression(payload, context) {
      payload.analyticsPage = createCreativeSlot({ router, category: getCategoryValue(context) });
      const convertedPayload = convertPromotionToGTM(payload);

      pushToDataLayer({
        event: GA4_EVENTS.viewItemList,
        ...getDispensaryContext(context, contextCache),
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache }),
          ...convertedPayload,
        },
      });
    },
    privacyPreferenceUpdated(payload, context) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'privacy',
        event_action: payload.action,
        event_label: payload.preference,
        ...getUserContext(context, contextCache),
        ...getDispensaryContext(context, contextCache),
      });
    },

    // Checkout Events
    placedOrder(payload, context) {
      const items = _map(context.activeOrder?.cart, (item) => ({
        ...cartItemContext(context, contextCache),
        ...cartItemToGTMCartItem(item),
      }));
      pushToDataLayer({
        event: GA4_EVENTS.purchase,
        ...getDispensaryContext(context, contextCache),
        ...getExperimentContext(context, contextCache),
        checkout_token: payload.checkoutToken,
        payment_type: payload.order.paymentMethod ?? null,
        delivery_method: payload.order.deliveryInfo?.deliveryOption ? ORDER_TYPE_DELIVERY : ORDER_TYPE_PICKUP,
        purchase_type: payload.order.medicalOrder ? 'medicinal' : 'recreational',
        user_enrolled_dutchie_pay: payload.isEnrolledDutchiePay,
        dutchie_pay_enabled: payload.isDutchiePayEnabledForDispo,
        email: payload.order.customer?.emails?.[0] ?? payload.order.guestCustomer?.email ?? null,
        ecommerce: {
          transaction_id: payload.order.orderId,
          tax: payload.order.taxAmount,
          shipping: payload.order.deliveryFee ?? null,
          coupon: context.activeOrder?.coupon?.code,
          ...ecommerceGTMMetaData({ context, contextCache }),
          items,
        },
      });
    },
    viewedCheckout(payload, context) {
      updateContextCache({ contextUpdates: context });
      const items = _map(context.activeOrder?.cart, (item) => ({
        ...cartItemContext(context, contextCache),
        ...cartItemToGTMCartItem(item),
      }));
      pushToDataLayer({
        event: GA4_EVENTS.beginCheckout,
        ...getDispensaryContext(context, contextCache),
        checkout_token: payload.checkoutToken,
        dutchie_pay_enabled: payload.isDutchiePayEnabledForDispo,
        user_enrolled_dutchie_pay: payload.isEnrolledDutchiePay,
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache }),
          coupon: context.activeOrder?.coupon?.code,
          items,
        },
      });
    },
    checkoutSession: _.once((payload, context) => {
      updateContextCache({ contextUpdates: context });
      const items = _map(context.activeOrder?.cart, (item) => ({
        ...cartItemContext(context, contextCache),
        ...cartItemToGTMCartItem(item),
      }));
      pushToDataLayer({
        event: GA4_EVENTS.checkoutSession,
        ...getDispensaryContext(context, contextCache),
        ...getUserContext(context, contextCache),
        ...getCartContext(context, contextCache),
        checkout_token: payload.checkoutToken,
        dispensary_id: payload.dispensaryId,
        dispensary_name: payload.dispensaryName,
        dutchie_pay_enabled: payload.isDutchiePayEnabledForDispo,
        user_enrolled_dutchie_pay: payload.isEnrolledDutchiePay,
        customer_id: payload.customerId,
        logged_in: payload.loggedIn,
        ecommerce: {
          ...ecommerceGTMMetaData({ context, contextCache }),
          coupon: context.activeOrder?.coupon?.code,
          items,
        },
      });
    }),
    imageBannerClicked(_payload, context) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'content',
        event_action: 'click',
        event_label: 'image banner',
        ...getDispensaryContext(context, contextCache),
      });
    },
    checkoutStarted: noop,
    paymentsError(payload) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: 'payments error',
        event_label: payload.reasonCode,
        ..._mapKeys(payload.meta, (_value, key) => snakeCase(key)),
      });
    },
    dutchiePayEnrollmentButtonClicked(payload, context) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: `enrollment button clicked`,
        event_label: '',
        ...getDispensaryContext(context, contextCache),
        checkout_token: context.activeOrder?.checkoutToken ?? contextCache.activeOrder?.checkoutToken,
        dutchie_pay_session_token: payload.dutchiePaySessionToken,
        dutchie_pay_enabled: true,
        button_location: payload.buttonLocation,
      });
    },
    dutchiePayEnrollmentStep(payload, context) {
      if (!payload.step) {
        return;
      }

      const isEnrolledDutchiePay =
        context.activeIsUserEnrolledDutchiePay ||
        (payload.action === 'Succeeded' && payload.step === 'Complete Enrollment');

      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: `enrollment step ${noCase(payload.action)}`,
        event_label: `step: ${noCase(payload.step)}`,
        ...getDispensaryContext(context, contextCache),
        checkout_token: context.activeOrder?.checkoutToken ?? contextCache.activeOrder?.checkoutToken,
        dutchie_pay_session_token: payload.dutchiePaySessionToken,
        dutchie_pay_enabled: true,
        user_enrolled_dutchie_pay: isEnrolledDutchiePay,
        dutchie_pay_enrollment_source: payload.enrollmentSource,
      });
    },
    dutchiePayLearnMoreButtonClicked: noop,
    dutchiePayCTAButtonClicked(payload, context) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: `learn more cta button clicked`,
        event_label: '',
        ...getDispensaryContext(context, contextCache),
        checkout_token: context.activeOrder?.checkoutToken ?? contextCache.activeOrder?.checkoutToken,
        dutchie_pay_session_token: payload.dutchiePaySessionToken,
        dutchie_pay_enabled: true,
      });
    },
    dutchiePayConnectBankStep(payload, context) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: `connect bank step`,
        event_label: `step: ${noCase(payload.action)}`,
        ...getDispensaryContext(context, contextCache),
        checkout_token: context.activeOrder?.checkoutToken ?? contextCache.activeOrder?.checkoutToken,
        dutchie_pay_session_token: payload.dutchiePaySessionToken,
        dutchie_pay_enabled: true,
        source: payload.source,
      });
    },

    dutchiePayPlaidOnEvent(payload, context) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: `connect bank step: plaid onEvent`,
        event_label: `${noCase(payload.eventName)}`,
        ...getDispensaryContext(context, contextCache),
        checkout_token: context.activeOrder?.checkoutToken ?? contextCache.activeOrder?.checkoutToken,
        dutchie_pay_session_token: payload.dutchiePaySessionToken,
        dutchie_pay_enabled: true,
        ...payload.eventMetadata,
      });
    },
    dutchiePayInstoreAccountLinkViewed(payload) {
      const linkMethod = payload.showBarcode ? 'barcode' : 'manual code';

      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: 'instore cart account link viewed',
        event_label: `method: ${linkMethod}`,
        instore_cart_id: payload.instoreCartId,
        instore_cart_token: payload.instoreCartToken,
      });
    },
    dutchiePayInstoreCartApprovalClicked(payload) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: 'instore cart approval clicked',
        event_label: '',
        dispensary_id: payload.dispensary.id,
        dispensary_name: payload.dispensary.name,
        instore_cart_id: payload.instoreCartId,
        instore_cart_token: payload.instoreCartToken,
      });
    },
    dutchiePayInstoreCartApprovalFailed(payload) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: 'instore cart approval failed',
        event_label: '',
        dispensary_id: payload.dispensary.id,
        dispensary_name: payload.dispensary.name,
        instore_cart_id: payload.instoreCartId,
        instore_cart_token: payload.instoreCartToken,
      });
    },
    dutchiePayInstoreCartApprovalSucceeded(payload) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: 'instore cart approval succeeded',
        event_label: '',
        dispensary_id: payload.dispensary.id,
        dispensary_name: payload.dispensary.name,
        instore_cart_id: payload.instoreCartId,
        instore_cart_token: payload.instoreCartToken,
      });
    },
    dutchiePayInstoreCartViewed(payload) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: 'instore cart viewed',
        event_label: '',
        dispensary_id: payload.dispensary.id,
        dispensary_name: payload.dispensary.name,
        instore_cart_token: payload.instoreCartToken,
      });
    },
    dutchiePayInstoreLoginViewed(payload) {
      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: 'dutchiepay',
        event_action: 'instore cart login viewed',
        event_label: `step: ${noCase(payload.loginStep)}`,
        instore_cart_token: payload.instoreCartToken,
      });
    },
    gaGTMClickEvent(payload, context) {
      const pageType = getPageType(router);

      pushToDataLayer({
        event: GA4_EVENTS.gtmEvent,
        event_category: injectPageType(payload.eventCategory, pageType)?.toLowerCase(),
        event_action: injectPageType(payload.eventAction, pageType)?.toLowerCase(),
        event_label: injectPageType(payload.eventLabel, pageType)?.toLowerCase(),
        ...getUserContext(context, contextCache),
        ...getDispensaryContext(context, contextCache),
      });
    },
  };
};
