import React from 'react';
import styled from 'styled-components';

import Imgix from 'shared/components/imgix';
import { getOrderDateAndTime } from 'shared/helpers/orders';

const OrderReceiptDispensaryBanner = ({ bannerImage, logoImage, name, order }) => (
  <Container bannerImage={bannerImage}>
    <ImageContainer>
      <Imgix htmlAttributes={{ alt: `${name} logo` }} src={logoImage} height={57} />
    </ImageContainer>

    <DispensaryDetails>
      <DispensaryName>{name}</DispensaryName>
      <PurchaseDateTime>{getOrderDateAndTime(order)}</PurchaseDateTime>
    </DispensaryDetails>
  </Container>
);

const Container = styled.div`
  display: flex;
  height: 107px;
  background: ${({ bannerImage }) => `url(${bannerImage})`};
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 0 25px;
`;

const ImageContainer = styled.div`
  margin-right: 19px;
  display: flex;
  align-items: center;
`;

const DispensaryDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  height: 100%;
  min-width: 0;
  padding: 25px 0;
`;

const DispensaryName = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 120%;
  flex: 2;
`;

const PurchaseDateTime = styled.div`
  margin-top: 6px;
  font-size: 13px;
  line-height: 13px;
  flex: 1;
`;

export default OrderReceiptDispensaryBanner;
