/* eslint-disable @typescript-eslint/unbound-method */
import { action, observable, makeObservable } from 'mobx';

export type ActiveModal = {
  data?: unknown;
  resolve: (data: any) => void;
};

export type ActiveModalsMap = Map<string, ActiveModal>;

export class ModalsStore {
  @observable activeModals: ActiveModalsMap = new Map();

  constructor() {
    makeObservable(this);
  }

  @action
  openModal = <OpenProps = unknown, CloseProps = unknown>(name: string, data?: OpenProps): Promise<CloseProps> =>
    new Promise((resolve) => {
      if (this.activeModals.has(name)) {
        console.error(`Failed to open modal. modal ${name} is already active`);
        return;
      }

      this.activeModals.set(name, { data, resolve });
    });

  @action
  closeModal = <CloseProps = undefined>(name: string, data: CloseProps): void => {
    const modal = this.activeModals.get(name);

    if (!modal) {
      console.error(`Failed to close modal. no active modals found`);
      return;
    }

    this.activeModals.delete(name);
    modal.resolve(data);
  };
}
