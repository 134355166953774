import { Text } from 'rebass/styled-components';
import { mediaQueries } from 'shared/styles';
import styled from 'styled-components';
import { space, width, fontSize } from 'styled-system';

export const CheckoutSection = styled.div`
  border-top: ${({ theme, noBorder }) => (noBorder ? 'none' : `1px solid ${theme.colors.greyBorder}`)};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  max-width: 100%;
  padding: 31px 30px 37px;
`;

export const CheckoutLabelGrouping = styled.div`
  display: flex;
  margin-bottom: 25px;
  ${space}

  :last-child {
    margin-bottom: 0;
  }

  width: 600px;

  @media ${mediaQueries.largePhone} {
    display: block;
    width: 100%;
    margin-bottom: 29px;
  }
`;

export const CheckoutLabel = styled.div`
  flex: 0 0 calc(1 / 5 * 100%);
  margin-bottom: 0;
  min-width: 0;
  padding: ${({ card }) => (card ? '0' : '12px')} 0 0 0;
  height: ${({ card }) => (card ? '54px' : 'auto')};
  display: ${({ card }) => (card ? 'flex' : 'block')};
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  max-width: 75px;

  @media ${mediaQueries.largePhone} {
    display: block;
    margin-bottom: 16px;
    padding: 0;
    text-align: left;
    height: auto;
    max-width: 100%;
  }
`;

export const CheckoutBody = styled.div`
  flex: 0 0 calc(4 / 5 * 100%);
  padding-left: 36px;
  // max-width: 390px;
  min-width: 0;

  @media ${mediaQueries.largePhone} {
    display: block;
    padding-left: 0;
  }
`;

export const CheckoutSectionHeading = styled.span`
  color: #43474a;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;

  @media ${mediaQueries.largePhone} {
    font-size: 18px;
  }
`;

export const CheckoutSectionHeader = styled.div`
  margin-bottom: 3px;
  padding-top: 11px;
  text-align: right;
  ${space}

  @media ${mediaQueries.phone} {
    margin-bottom: 16px;
    padding-top: 0;
    text-align: left;
  }
`;

export const CheckoutFieldHeader = styled(Text)`
  color: #6d747b;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
  ${space}
`;

export const CheckoutText = styled(Text)`
  color: #6d747b;
  font-size: 13px;
  line-height: 24px;
  ${space}
  ${width}
  ${fontSize}
`;

export const FooterText = styled(Text)`
  font-size: 11px;
  line-height: 14px;
  color: #6d747b;
  width: 80%;
  ${space}

  @media ${mediaQueries.tablet} {
    text-align: center;
  }
`;
