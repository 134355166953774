import React, { useContext, forwardRef } from 'react';

export default function createContextHelpers(Context, name) {
  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
  const { Provider } = Context;

  function useMethod() {
    return useContext(Context);
  }

  const withMethod = (component) =>
    forwardRef((props, ref) => {
      const value = useMethod();
      props[name] = value;
      return <component ref={ref} {...props} />;
    });

  return {
    [`${name}Context`]: Context,
    [`${capitalizedName}Provider`]: Provider,
    [`use${capitalizedName}`]: useMethod,
    [`with${capitalizedName}`]: withMethod,
  };
}
