/* eslint-disable max-len */

export const oregrown = {
  id: 'SbnMPug4uHJZu5tG3',
  customDomainSettings: {
    domain: null,
    __typename: 'Dispensaries_profile_customDomain_settings',
  },
  plusSettings: null,
  distance: '200',
  name: 'Oregrown (Bend) OR',
  cName: 'oregrown',
  timezone: 'America/Los_Angeles',
  phone: '(541) 323-3800',
  address: '1199 NW Wall St, Bend, OR 97701, USA',
  description:
    "HELLO THERE\nOregrown is Oregon's premier farm-to- table cannabis company.  We control every facet of our supply chain.  We grow our own organic, indoor cannabis and we process our own world-class extracts right here in Oregon.  Our Bend-based Oregrown Dispensary is a beautiful, clean environment where you can safely access some of the best cannabis products in the world. Come in for your own personal experience with our friendly and knowledgeable staff.\n\nOur drivers appreciate and accept cash tips!",
  status: 'open',
  chain: 'oregrown',
  location: {
    ln1: '1199 NW Wall St',
    ln2: '',
    city: 'Bend',
    state: 'OR',
    country: 'United States',
    geometry: {
      coordinates: [-121.3116487, 44.0616776],
      __typename: 'Dispensaries_profile_location_geometry',
    },
    __typename: 'Dispensaries_profile_location',
  },
  temporalLocation: null,
  deliveryHours: {
    Monday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Tuesday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Wednesday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Thursday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Friday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Saturday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Sunday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    __typename: 'Dispensaries_profile_hours',
  },
  pickupHours: {
    Monday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Tuesday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Wednesday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Thursday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Friday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Saturday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Sunday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    __typename: 'Dispensaries_profile_hours',
  },
  categoryPhotos: [
    {
      src: 'https://images.dutchie.com/category-stock-photos/edibles/edibles-5.png',
      category: 'Edible',
      __typename: 'CategoryPhoto',
    },
    {
      src: 'https://images.dutchie.com/category-stock-photos/vaporizers/vaporizers-1.png',
      category: 'Vaporizers',
      __typename: 'CategoryPhoto',
    },
    {
      src: 'https://s3-us-west-2.amazonaws.com/dutchie-images/ce9759a78a6933df694f572efbbf75f0',
      category: 'Concentrate',
      __typename: 'CategoryPhoto',
    },
    {
      src: 'https://s3-us-west-2.amazonaws.com/dutchie-images/aaf4a31672b206e462ec2ca8adccf4cd',
      category: 'Accessories',
      __typename: 'CategoryPhoto',
    },
    {
      src: 'https://images.dutchie.com/category-stock-photos/flower/flower-1.png',
      category: 'Flower',
      __typename: 'CategoryPhoto',
    },
    {
      src: 'https://images.dutchie.com/category-stock-photos/tinctures/tinctures-2.png',
      category: 'Tincture',
      __typename: 'CategoryPhoto',
    },
    {
      src: 'https://images.dutchie.com/category-stock-photos/topicals/topicals-1.png',
      category: 'Topicals',
      __typename: 'CategoryPhoto',
    },
    {
      src: 'https://images.dutchie.com/category-stock-photos/pre-rolls/pre-rolls-1.png',
      category: 'Pre-Rolls',
      __typename: 'CategoryPhoto',
    },
  ],
  customCategoryPhotos: [
    {
      url: 'https://s3-us-west-2.amazonaws.com/dutchie-images/89e4324c40c6196ea320fa95321b9de8',
      category: 'Flower',
      __typename: 'CustomCategoryPhoto',
    },
    {
      url: 'https://s3-us-west-2.amazonaws.com/dutchie-images/0f290e17124e7a041b4d814b50f58497',
      category: 'Pre-Rolls',
      __typename: 'CustomCategoryPhoto',
    },
    {
      url: 'https://s3-us-west-2.amazonaws.com/dutchie-images/4b4a5f5a50f32242fbf630ebe7d7e40b',
      category: 'Flower',
      __typename: 'CustomCategoryPhoto',
    },
    {
      url: 'https://s3-us-west-2.amazonaws.com/dutchie-images/ce9759a78a6933df694f572efbbf75f0',
      category: 'Concentrate',
      __typename: 'CustomCategoryPhoto',
    },
    {
      url: 'https://s3-us-west-2.amazonaws.com/dutchie-images/aaf4a31672b206e462ec2ca8adccf4cd',
      category: 'Accessories',
      __typename: 'CustomCategoryPhoto',
    },
    {
      url: 'https://s3-us-west-2.amazonaws.com/dutchie-images/3fe7f8d954ddb405acf00a9d8e2cf0aa',
      category: 'Pre-Rolls',
      __typename: 'CustomCategoryPhoto',
    },
  ],
  specialHours: [
    {
      name: '4th of July',
      startDate: '2020-07-04T12:00:00-07:00',
      endDate: '2020-07-04T12:00:00-07:00',
      hoursPerDay: [
        {
          date: '2020-07-04T12:00:00-07:00',
          pickupHours: {
            active: true,
            start: '9:00 AM',
            end: '7:45 PM',
            __typename: 'dayBounds',
          },
          deliveryHours: {
            active: true,
            start: '9:00 AM',
            end: '7:00 PM',
            __typename: 'dayBounds',
          },
          __typename: 'SpecialHoursDay',
        },
      ],
      __typename: 'SpecialHours',
    },
    {
      name: 'Danksgiving Day',
      startDate: '2020-11-26T12:00:00-08:00',
      endDate: '2020-11-26T12:00:00-08:00',
      hoursPerDay: [
        {
          date: '2020-11-26T12:00:00-08:00',
          pickupHours: {
            active: true,
            start: '10:00 AM',
            end: '4:00 PM',
            __typename: 'dayBounds',
          },
          deliveryHours: {
            active: true,
            start: '9:00 AM',
            end: '9:00 PM',
            __typename: 'dayBounds',
          },
          __typename: 'SpecialHoursDay',
        },
      ],
      __typename: 'SpecialHours',
    },
    {
      name: 'Christmas Day Hours',
      startDate: '2020-12-25T12:00:00-08:00',
      endDate: '2020-12-25T12:00:00-08:00',
      hoursPerDay: [
        {
          date: '2020-12-25T12:00:00-08:00',
          pickupHours: {
            active: true,
            start: '2:00 PM',
            end: '8:00 PM',
            __typename: 'dayBounds',
          },
          deliveryHours: {
            active: true,
            start: '2:00 PM',
            end: '8:00 PM',
            __typename: 'dayBounds',
          },
          __typename: 'SpecialHoursDay',
        },
      ],
      __typename: 'SpecialHours',
    },
    {
      name: 'New Years Day',
      startDate: '2021-01-01T12:00:00-08:00',
      endDate: '2021-01-01T12:00:00-08:00',
      hoursPerDay: [
        {
          date: '2021-01-01T12:00:00-08:00',
          pickupHours: {
            active: true,
            start: '10:00 AM',
            end: '9:30 PM',
            __typename: 'dayBounds',
          },
          deliveryHours: {
            active: true,
            start: '10:00 AM',
            end: '9:00 PM',
            __typename: 'dayBounds',
          },
          __typename: 'SpecialHoursDay',
        },
      ],
      __typename: 'SpecialHours',
    },
    {
      name: '4/20! ',
      startDate: '2021-04-20T12:00:00-07:00',
      endDate: '2021-04-20T12:00:00-07:00',
      hoursPerDay: [
        {
          date: '2021-04-20T12:00:00-07:00',
          pickupHours: {
            active: true,
            start: '7:00 AM',
            end: '9:30 PM',
            __typename: 'dayBounds',
          },
          deliveryHours: {
            active: true,
            start: '9:00 AM',
            end: '9:00 PM',
            __typename: 'dayBounds',
          },
          __typename: 'SpecialHoursDay',
        },
      ],
      __typename: 'SpecialHours',
    },
  ],
  effectiveHours: {
    deliveryHours: {
      Monday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Tuesday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Wednesday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Thursday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Friday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Saturday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Sunday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      __typename: 'Dispensaries_profile_hours',
    },
    pickupHours: {
      Monday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Tuesday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Wednesday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Thursday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Friday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Saturday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Sunday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      __typename: 'Dispensaries_profile_hours',
    },
    __typename: 'EffectiveHours',
  },
  offerAnyPickupService: true,
  offerPickup: true,
  offerCurbsidePickup: true,
  offerDriveThruPickup: false,
  offerDelivery: true,
  listImage: 'https://zah-dutchie.imgix.net/oregrown-list-image.jpg',
  bannerImage: 'https://zah-dutchie.imgix.net/oregrown-banner-dark.png',
  logoImage: 'https://images.dutchie.com/28b6cd29cf280cc2417731a84201bb4f',
  embeddedLogoImage: 'https://images.dutchie.com/d54d8cf82285faf43a394d0686463ca9',
  embeddedMenuUrl: 'https://oregrown.com/pages/bend-delivery',
  SpecialLogoImage: 'https://images.dutchie.com/35ad60c1dde8c7340bc8a2ce2bc841df',
  embedBackUrl: 'https://oregrown.com/',
  embedSettings: {
    disclaimerTextHtml:
      '<p>This is a legal disclaimer. It includes some legal text This is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal textThis is a legal disclaimer. It includes some legal text</p>',
    disablePageLoadsAtTop: null,
    autoGTM: null,
    __typename: 'Dispensaries_profile_embed_settings',
  },
  alt36: true,
  check: true,
  creditCardAtDoor: true,
  payOnlineHypur: false,
  payOnlineMerrco: true,
  creditCardByPhone: true,
  debitOnly: true,
  cashOnly: false,
  cashless: false,
  linx: true,
  canPay: true,
  paytender: true,
  aeropay: true,
  recDispensary: true,
  medicalDispensary: true,
  maxDeliveryDistance: 0,
  mixAndMatchPricingWeights: ['1/8oz', '1/4oz', '1/2oz', '1oz'],
  feeTiers: [
    {
      fee: '0',
      feeType: 'flat',
      max: '1000',
      min: '50',
      percentFee: null,
      __typename: 'Dispensaries_profile_feeTiers',
    },
  ],
  acceptsTips: true,
  acceptsDutchiePayTips: true,
  requiresDriversLicense: null,
  requiresDriversLicenseForPickup: false,
  requirePhotoIdForDelivery: true,
  requirePhotoIdForPickup: false,
  removeMedicalCardFieldsAtCheckout: null,
  menuOrder: 'custom',
  email: null,
  emailConfirmation: true,
  stealthMode: null,
  actionEstimates: {
    pickup: {
      readyInMinutes: 121.37002121963631,
      rangeInMinutes: 5,
      __typename: 'ActionEstimatesValues',
    },
    delivery: {
      readyInMinutes: 26.098421624118444,
      rangeInMinutes: 10,
      deliveryTimeInMinutes: 33.75720996485041,
      __typename: 'ActionEstimatesValues',
    },
    __typename: 'ActionEstimates',
  },
  durationEstimateOverrides: {
    delivery: {
      enabled: false,
      lowInMinutes: 10,
      highInMinutes: 20,
      __typename: 'DurationEstimateRange',
    },
    pickup: {
      enabled: false,
      lowInMinutes: 10,
      highInMinutes: 20,
      __typename: 'DurationEstimateRange',
    },
    __typename: 'DurationEstimates',
  },
  paymentFees: [],
  ordersConfig: {
    posItemNames: true,
    autoConfirm: true,
    autoClose: false,
    terminalReceiptOptions: {
      customerNameOption: 'fullName',
      birthdate: true,
      deliveryAddress: true,
      phone: true,
      medical: true,
      __typename: 'TerminalReceiptOptions',
    },
    __typename: 'Dispensaries_ordersConfig',
  },
  kioskOrderingEnabled: true,
  kioskInstructions: "If you've selected pay by phone please contact us at the shop so that we can run your card info!",
  kioskConfig: {
    fullNameOnly: true,
    phoneRequired: true,
    notesField: true,
    directedOrders: null,
    hidePhoneField: null,
    hideEmailField: true,
    showBirthdateField: true,
    anonymousCheckout: false,
    __typename: 'Dispensaries_kioskConfig',
  },
  categoryLimits: [
    {
      name: 'Topicals',
      value: '5',
      __typename: 'Dispensaries_categoryLimits',
    },
    {
      name: 'Edible',
      value: 'Unlimited',
      __typename: 'Dispensaries_categoryLimits',
    },
    {
      name: 'Concentrate',
      value: 'Unlimited',
      __typename: 'Dispensaries_categoryLimits',
    },
    {
      name: 'Vaporizers',
      value: '10',
      __typename: 'Dispensaries_categoryLimits',
    },
    {
      name: 'Accessories',
      value: '0',
      __typename: 'Dispensaries_categoryLimits',
    },
    {
      name: 'Tincture',
      value: 'Unlimited',
      __typename: 'Dispensaries_categoryLimits',
    },
    {
      name: 'Seeds',
      value: 'Unlimited',
      __typename: 'Dispensaries_categoryLimits',
    },
    {
      name: 'Pre-Rolls',
      value: 'Unlimited',
      __typename: 'Dispensaries_categoryLimits',
    },
    {
      name: 'Apparel',
      value: 'Unlimited',
      __typename: 'Dispensaries_categoryLimits',
    },
    {
      name: 'Flower',
      value: '5',
      __typename: 'Dispensaries_categoryLimits',
    },
  ],
  categoryLimitsEnabled: false,
  specialsSettings: {
    discountBehavior: 'compounding',
    discountStacking: true,
    __typename: 'SpecialsSettings',
  },
  storeSettings: {
    isolatedMenus: false,
    customerMessagingTermsAccepted: true,
    defaultViewStyle: 'list',
    disablePurchaseLimits: false,
    disableGuestDOB: false,
    displayPhoneConfirmation: false,
    requireEmailAddressForGuestCheckout: false,
    requireMedCardPhotoForPickup: false,
    hideAddressFromDutchieMain: false,
    requireMedCardPhotoForDelivery: false,
    enableAfterHoursOrderingForPickup: false,
    enableAfterHoursOrderingForDelivery: false,
    enableLLxSaleDiscountSync: false,
    enableMixAndMatchPricingForPickup: true,
    enableMixAndMatchPricingForDelivery: true,
    enableScheduledOrderingForPickup: false,
    enableScheduledOrderingForDelivery: false,
    enableLimitPerCustomer: true,
    quantityLimit: 25,
    enableOrderStatusEmails: false,
    enableStorefrontAgeGate: false,
    disableGuestCheckout: false,
    stealthMode: false,
    dontCombineWeightedProducts: false,
    keepUncombinedWeights: false,
    dontMapSubcategoriesByProductName: false,
    prioritizeStaffPicksInSearchResults: false,
    provideBackInstockEmailNotifications: false,
    hideEffects: false,
    hideFilters: false,
    subscriptions: {
      enabled: true,
      __typename: 'Dispensaries_subscriptions',
    },
    rewardsIntegrationConfiguration: {
      rewardsProgramDisplayName: 'Rewards',
      __typename: 'Rewards_integration_configuration',
    },
    __typename: 'Dispensaries_storeSettings',
  },
  featureFlags: {
    hideStoreHours: true,
    hideDeliveryEstimate: false,
    enablePausedOrders: false,
    enableAfterHoursOrdering: false,
    enableScheduledOrdering: null,
    enableArrivals: true,
    enableMenuImport: false,
    enableMixAndMatch: true,
    enableNonTerminalAutoConfirm: null,
    __typename: 'Dispensaries_featureFlags',
  },
  menuScore: 3.857087378640777,
  menuScoresByCategory: [
    {
      category: 'Seeds',
      value: 5,
      __typename: 'MenuScore',
    },
    {
      category: 'Pre-Rolls',
      value: 4.995930232558139,
      __typename: 'MenuScore',
    },
    {
      category: 'Apparel',
      value: 4.398818897637796,
      __typename: 'MenuScore',
    },
    {
      category: 'Topicals',
      value: 3.7532258064516126,
      __typename: 'MenuScore',
    },
    {
      category: 'Concentrate',
      value: 3.2111940298507466,
      __typename: 'MenuScore',
    },
    {
      category: 'Accessories',
      value: 4.3943396226415095,
      __typename: 'MenuScore',
    },
    {
      category: 'Edible',
      value: 3.8883561643835614,
      __typename: 'MenuScore',
    },
    {
      category: 'Flower',
      value: 4.984090909090909,
      __typename: 'MenuScore',
    },
    {
      category: 'Tincture',
      value: 4.1296875,
      __typename: 'MenuScore',
    },
  ],
  productCategorySummary: [
    {
      category: 'Pre-Rolls',
      value: 86,
      __typename: 'CategorySummary',
    },
    {
      category: 'Seeds',
      value: 5,
      __typename: 'CategorySummary',
    },
    {
      category: 'Topicals',
      value: 62,
      __typename: 'CategorySummary',
    },
    {
      category: 'Apparel',
      value: 127,
      __typename: 'CategorySummary',
    },
    {
      category: 'Concentrate',
      value: 402,
      __typename: 'CategorySummary',
    },
    {
      category: 'Edible',
      value: 219,
      __typename: 'CategorySummary',
    },
    {
      category: 'Tincture',
      value: 32,
      __typename: 'CategorySummary',
    },
    {
      category: 'Flower',
      value: 44,
      __typename: 'CategorySummary',
    },
    {
      category: 'Accessories',
      value: 53,
      __typename: 'CategorySummary',
    },
  ],
  updatedAt: '1546627950693',
  updatedAtISO: null,
  updatedBy: '0',
  terminals: [
    {
      _id: '6063bca6f72f3802785f7b3a',
      nickname: 'click me! :D',
      versionNumber: '2.6.10',
      lastSeenAt: 'Mon Apr 05 2021 16:29:50 GMT-0700 (Pacific Daylight Time)',
      lastSeenAtISO: '2021-04-05T23:29:50.589Z',
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '6066593acf137300986a51c6',
      nickname: 'z',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '606659ceefb2bc00996a9787',
      nickname: 'zxcv',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60665a5bcf137300a46a5320',
      nickname: 'k',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60665b342f60cd00a386581a',
      nickname: 'xczv',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60665b76772ed000bd955583',
      nickname: 'cxvz',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60665bb72f60cd00988658c7',
      nickname: 'xzcv',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60665c7ecf137300b56a547a',
      nickname: 'zxvc',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60665cef772ed0283895537c',
      nickname: 'vxcz',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60665daa2f60cd2be18656c0',
      nickname: 'z',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60665e9eefb2bc27b16a9834',
      nickname: 'z',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '6066636f2d730a00a1502a4a',
      nickname: 'zxcv',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '606663a78076cb00b6d69d4c',
      nickname: 'Terminal 96174',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60675e8007a5a000a86754b2',
      nickname: 'xzcv',
      versionNumber: null,
      lastSeenAt: 'undefined',
      lastSeenAtISO: null,
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60676ee907a5a02e36675843',
      nickname: 'b',
      versionNumber: '2.6.8',
      lastSeenAt: 'Fri Apr 02 2021 15:54:13 GMT-0700 (Pacific Daylight Time)',
      lastSeenAtISO: '2021-04-02T22:54:13.613Z',
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '6078b9c77b95b832f3c8d720',
      nickname: 'Terminal 96689',
      versionNumber: '2.6.8',
      lastSeenAt: 'Thu Apr 15 2021 15:21:29 GMT-0700 (Pacific Daylight Time)',
      lastSeenAtISO: '2021-04-15T22:21:29.339Z',
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60886007b15ad4050d957d14',
      nickname: 'x',
      versionNumber: '2.10.0',
      lastSeenAt: 'Thu May 27 2021 10:33:32 GMT-0700 (Pacific Daylight Time)',
      lastSeenAtISO: '2021-05-27T17:33:32.385Z',
      config: null,
      __typename: 'Dispensaries_terminals',
    },
    {
      _id: '60a42d6c28d42700a9da33ac',
      nickname: 'x',
      versionNumber: '2.9.0',
      lastSeenAt: 'Wed May 19 2021 11:35:32 GMT-0700 (Pacific Daylight Time)',
      lastSeenAtISO: '2021-05-19T18:35:32.156Z',
      config: null,
      __typename: 'Dispensaries_terminals',
    },
  ],
  ordersArePaused: false,
  menuBannerHtml:
    '<p>Delivery available from 11am-9pm every day - Bend city limits!</p><p><strong>Please wear a mask to meet the driver!</strong></p><p>If you do not receive a confirmation text message feel free to call and confirm your order!</p><p>Curbside Pick-up Available: Everyone <strong>must </strong>be over 21 - please: <strong>NO </strong>children in the car. <strong>Wear a mask</strong>.</p><p>Thank you!</p>',
  menuBannerColor: 'red',
  menuUrl: 'https://chrisinajar.com',
  colorSettings: {
    linkColor: 'aqua-2',
    navBarColor: 'white',
    __typename: 'ColorSettings',
  },
  webCustomizationSettings: {
    colorSettings: {
      linkColor: 'GREEN_4',
      navBarColor: 'LIGHT_BROWN',
    },
    fontSettings: {
      family: 'EXO',
    },
  },
  pickupMinimum: {
    enabled: false,
    minimumInCents: null,
    __typename: 'PickupMinimum',
  },
  orderTypesConfig: {
    pickup: {
      enabled: true,
      paymentTypes: {
        alt36: false,
        check: false,
        creditCardAtDoor: true,
        payOnlineChase: false,
        payOnlineHypur: false,
        payOnlineMerrco: false,
        payOnlineMoneris: false,
        creditCardByPhone: false,
        debit: false,
        cash: true,
        linx: true,
        canPay: true,
        paytender: false,
        aeropay: false,
        __typename: 'PaymentTypesConfig',
      },
      __typename: 'OrderTypeConfig',
    },
    curbsidePickup: {
      enabled: true,
      paymentTypes: {
        alt36: false,
        check: false,
        creditCardAtDoor: false,
        payOnlineChase: null,
        payOnlineHypur: false,
        payOnlineMerrco: true,
        payOnlineMoneris: false,
        creditCardByPhone: false,
        debit: true,
        cash: true,
        linx: false,
        canPay: false,
        paytender: false,
        aeropay: false,
        __typename: 'PaymentTypesConfig',
      },
      __typename: 'OrderTypeConfig',
    },
    driveThruPickup: {
      enabled: false,
      paymentTypes: {
        alt36: null,
        check: null,
        creditCardAtDoor: null,
        payOnlineChase: null,
        payOnlineHypur: null,
        payOnlineMerrco: false,
        payOnlineMoneris: false,
        creditCardByPhone: null,
        debit: null,
        cash: true,
        linx: null,
        canPay: null,
        paytender: null,
        aeropay: null,
        __typename: 'PaymentTypesConfig',
      },
      __typename: 'OrderTypeConfig',
    },
    delivery: {
      enabled: true,
      paymentTypes: {
        alt36: false,
        check: true,
        creditCardAtDoor: false,
        payOnlineChase: null,
        payOnlineHypur: false,
        payOnlineMerrco: true,
        payOnlineMoneris: false,
        creditCardByPhone: true,
        debit: false,
        cash: true,
        linx: false,
        canPay: false,
        paytender: false,
        aeropay: false,
        __typename: 'PaymentTypesConfig',
      },
      __typename: 'OrderTypeConfig',
    },
    kiosk: {
      enabled: true,
      paymentTypes: {
        alt36: true,
        check: true,
        creditCardAtDoor: false,
        payOnlineChase: null,
        payOnlineHypur: null,
        payOnlineMerrco: true,
        payOnlineMoneris: false,
        creditCardByPhone: true,
        debit: true,
        cash: true,
        linx: true,
        canPay: true,
        paytender: true,
        aeropay: true,
        __typename: 'PaymentTypesConfig',
      },
      __typename: 'OrderTypeConfig',
    },
    offerAnyPickupService: true,
    offerDeliveryService: true,
    __typename: 'OrderTypesConfig',
  },
  paymentTypesAccepted: {
    alt36: true,
    check: true,
    creditCardAtDoor: true,
    payOnlineChase: null,
    payOnlineHypur: null,
    payOnlineMerrco: true,
    payOnlineMoneris: false,
    creditCardByPhone: true,
    debit: true,
    cash: true,
    linx: true,
    canPay: true,
    paytender: true,
    aeropay: true,
    __typename: 'PaymentTypesConfig',
  },
  __typename: 'Dispensaries',
  activeCategories: [
    'Pre-Rolls',
    'Seeds',
    'Topicals',
    'Apparel',
    'Concentrate',
    'Edible',
    'Tincture',
    'Flower',
    'Accessories',
  ],
  consumerDispensaryIntegrations: {
    alpineiq: {
      enabled: true,
      flags: {
        combineDiscounts: true,
        __typename: 'Dispensaries_fleetManagementIntegration_flags',
      },
      __typename: 'Dispensaries_integrations',
    },
    springbig: null,
    __typename: 'ConsumerDispensaryIntegrations',
  },
  deliveryInfo: {
    withinBounds: false,
    fee: null,
    minimum: 2000,
    feeVaries: null,
    minimumVaries: null,
    feeType: null,
    percentFee: null,
    __typename: 'Dispensaries_deliveryInfo',
  },
  googleAnalyticsID: '5645665445',
  googleTagManager: {
    gtmID: 'GTM-NK2LM5R',
    flags: {},
    __typename: 'Dispensaries_GoogleTagManagerConfig',
  },
  hoursSettings: {
    inStorePickup: {
      hours: {
        Friday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Monday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Saturday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Sunday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Thursday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Tuesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Wednesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        __typename: 'WeeklyHours',
      },
      specialHours: [],
      __typename: 'HoursSettingsForOrderType',
      effectiveHours: {
        Friday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Monday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Saturday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Sunday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Thursday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Tuesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Wednesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        __typename: 'WeeklyHours',
      },
    },
    curbsidePickup: {
      hours: {
        Friday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Monday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Saturday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Sunday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Thursday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Tuesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Wednesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        __typename: 'WeeklyHours',
      },
      specialHours: [],
      __typename: 'HoursSettingsForOrderType',
      effectiveHours: {
        Friday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Monday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Saturday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Sunday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Thursday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Tuesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Wednesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        __typename: 'WeeklyHours',
      },
    },
    driveThruPickup: {
      hours: {
        Friday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Monday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Saturday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Sunday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Thursday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Tuesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Wednesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        __typename: 'WeeklyHours',
      },
      specialHours: [],
      __typename: 'HoursSettingsForOrderType',
      effectiveHours: {
        Friday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Monday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Saturday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Sunday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Thursday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Tuesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Wednesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        __typename: 'WeeklyHours',
      },
    },
    delivery: {
      hours: {
        Friday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Monday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Saturday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Sunday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Thursday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Tuesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Wednesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        __typename: 'WeeklyHours',
      },
      specialHours: [],
      __typename: 'HoursSettingsForOrderType',
      effectiveHours: {
        Friday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Monday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Saturday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Sunday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Thursday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Tuesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        Wednesday: {
          active: false,
          end: 'open',
          start: 'open',
          __typename: 'dayBounds',
        },
        __typename: 'WeeklyHours',
      },
    },
    __typename: 'DispensaryHoursSettings',
  },
  orderTypesConfigV2: {
    inStorePickup: {
      enableASAPOrdering: true,
      enableScheduledOrdering: false,
      enableAfterHoursOrdering: false,
      paymentTypes: {
        alt36: false,
        check: false,
        creditCardAtDoor: true,
        payOnlineChase: false,
        payOnlineHypur: false,
        payOnlineMerrco: false,
        payOnlineMoneris: false,
        creditCardByPhone: false,
        debit: false,
        cash: true,
        linx: true,
        canPay: true,
        paytender: false,
        aeropay: false,
        __typename: 'PaymentTypesConfig',
      },
      scheduledOrderingConfigV2: {
        advancedDayOrderingEnabled: false,
        advancedDayOrderingLimit: null,
        incrementInMinutes: 30,
        nextAvailableInMinutes: 15,
        orderLimitsEnabled: true,
        __typename: 'ScheduledOrderingConfigV2',
      },
      __typename: 'InStorePickupOrderingSettings',
      orderMinimum: {
        enabled: false,
        minimumInCents: null,
        __typename: 'OrderMinimum',
      },
    },
    curbsidePickup: {
      enableASAPOrdering: true,
      enableScheduledOrdering: false,
      enableAfterHoursOrdering: false,
      paymentTypes: {
        alt36: false,
        check: false,
        creditCardAtDoor: false,
        payOnlineChase: null,
        payOnlineHypur: false,
        payOnlineMerrco: true,
        payOnlineMoneris: false,
        creditCardByPhone: false,
        debit: true,
        cash: true,
        linx: false,
        canPay: false,
        paytender: false,
        aeropay: false,
        __typename: 'PaymentTypesConfig',
      },
      scheduledOrderingConfigV2: {
        advancedDayOrderingEnabled: false,
        advancedDayOrderingLimit: null,
        incrementInMinutes: 30,
        nextAvailableInMinutes: 15,
        orderLimitsEnabled: true,
        __typename: 'ScheduledOrderingConfigV2',
      },
      __typename: 'CurbsidePickupOrderingSettings',
      orderMinimum: {
        enabled: false,
        minimumInCents: null,
        __typename: 'OrderMinimum',
      },
      enableCurbsideArrivals: true,
      arrivalInformationInstructions: null,
    },
    driveThruPickup: {
      enableASAPOrdering: false,
      enableScheduledOrdering: false,
      enableAfterHoursOrdering: false,
      paymentTypes: {
        alt36: null,
        check: null,
        creditCardAtDoor: null,
        payOnlineChase: null,
        payOnlineHypur: null,
        payOnlineMerrco: false,
        payOnlineMoneris: false,
        creditCardByPhone: null,
        debit: null,
        cash: true,
        linx: null,
        canPay: null,
        paytender: null,
        aeropay: null,
        __typename: 'PaymentTypesConfig',
      },
      scheduledOrderingConfigV2: {
        advancedDayOrderingEnabled: false,
        advancedDayOrderingLimit: null,
        incrementInMinutes: 30,
        nextAvailableInMinutes: 15,
        orderLimitsEnabled: true,
        __typename: 'ScheduledOrderingConfigV2',
      },
      __typename: 'DriveThruPickupOrderingSettings',
      orderMinimum: {
        enabled: false,
        minimumInCents: null,
        __typename: 'OrderMinimum',
      },
    },
    delivery: {
      enableASAPOrdering: true,
      enableScheduledOrdering: false,
      enableAfterHoursOrdering: false,
      paymentTypes: {
        alt36: false,
        check: true,
        creditCardAtDoor: false,
        payOnlineChase: null,
        payOnlineHypur: false,
        payOnlineMerrco: true,
        payOnlineMoneris: false,
        creditCardByPhone: true,
        debit: false,
        cash: true,
        linx: false,
        canPay: false,
        paytender: false,
        aeropay: false,
        __typename: 'PaymentTypesConfig',
      },
      scheduledOrderingConfigV2: {
        advancedDayOrderingEnabled: false,
        advancedDayOrderingLimit: null,
        incrementInMinutes: 60,
        nextAvailableInMinutes: 20,
        orderLimitsEnabled: true,
        __typename: 'ScheduledOrderingConfigV2',
      },
      __typename: 'DeliveryOrderingSettings',
      deliveryFeeTiers: [
        {
          feeInCents: 0,
          maximumInCents: 1000,
          minimumInCents: 50,
          feeType: 'flat',
          percentFee: 0,
          __typename: 'DeliveryFeeTiers',
        },
      ],
      deliveryMode: 'cityLimits',
      deliveryRadius: {
        orderMinimum: {
          enabled: true,
          minimumInCents: 2000,
          __typename: 'OrderMinimum',
        },
        radiusInMiles: 0,
        __typename: 'DeliveryRadius',
      },
      deliveryZipCodes: [
        {
          zipCode: '80205',
          feeInCents: 0,
          minimumInCents: 0,
          feeType: 'flat',
          percentFee: 0,
          __typename: 'DeliveryZipCodes',
        },
      ],
      deliveryZones: [],
    },
    kiosk: {
      enabled: true,
      fullNameOnly: true,
      phoneRequired: true,
      notesField: true,
      directedOrders: null,
      hideEmailField: true,
      hidePhoneField: null,
      showBirthdateField: true,
      kioskInstructions: null,
      anonymousCheckout: false,
      paymentTypes: {
        alt36: true,
        check: true,
        creditCardAtDoor: false,
        payOnlineChase: null,
        payOnlineHypur: null,
        payOnlineMerrco: true,
        payOnlineMoneris: false,
        creditCardByPhone: true,
        debit: true,
        cash: true,
        linx: true,
        canPay: true,
        paytender: true,
        aeropay: true,
        __typename: 'PaymentTypesConfig',
      },
      __typename: 'KioskOrderingSettings',
    },
    offerAnyPickupService: true,
    offerDeliveryService: true,
    __typename: 'OrderTypesConfigV2',
  },
  enabledOrderTypes: {
    curbsidePickup: true,
    delivery: true,
    driveThruPickup: false,
    inStorePickup: true,
    kiosk: true,
    pickup: true,
    __typename: 'EnabledOrderTypes',
  },
  merrcoPublicToken:
    'T1QtNDU2OTEwOkItcWEyLTAtNWVjZWU3MDItMC0zMDJkMDIxNTAwOGI3Zjg2YjlhNzcyYWU1NTMxNzc2ZmE2YjYxNmMzNmQzMmZhZjc0NTAyMTQ2YmM3MmIzMDY5NTYyNTRiMTFmZmFhNDJiMmRjNmVkY2Y5N2YxNzk5',
  messagingSettings: {
    disableReadyForPickup: false,
    disableStartDelivery: false,
  },
  springbigEnabled: false,
  taxConfig: {
    calculationMethod: 'cumulative',
    discountTaxOrder: 'discountsFirst',
    taxes: [
      {
        id: '5cdf2f01-9381-4454-a748-c19e4418775c',
        type: 'sales',
        name: 'sales tax',
        rate: 0.1,
        potencyRate: null,
        potency: null,
        medical: false,
        recreational: true,
        taxBasis: 'retail',
        stages: [
          {
            op: 'noop',
            type: 'pos',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
          {
            op: 'noop',
            type: 'menu',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
          {
            op: 'add',
            type: 'checkout',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
        ],
        applyTo: {
          types: [
            'Flower',
            'Pre-Rolls',
            'Vaporizers',
            'Concentrate',
            'Edible',
            'Topicals',
            'Tincture',
            'CBD',
            'Clones',
            'Seeds',
            'Accessories',
            'Apparel',
          ],
          hemp: false,
          __typename: 'Dispensaries_TaxConfig_Tax_ApplyTo',
        },
        order: null,
        deliveryPolicy: 'originBased',
        includeStateSalesTaxInDestinationRate: true,
        __typename: 'Dispensaries_TaxConfig_Tax',
        destinationRate: null,
      },
      {
        id: 'e19bbd83-002f-4c8d-8bff-7d93faa4dde6',
        type: 'cannabis',
        name: 'cannabis tax',
        rate: 0.1,
        potencyRate: null,
        potency: null,
        medical: false,
        recreational: true,
        taxBasis: 'retail',
        stages: [
          {
            op: 'noop',
            type: 'pos',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
          {
            op: 'noop',
            type: 'menu',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
          {
            op: 'add',
            type: 'checkout',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
        ],
        applyTo: {
          types: [
            'Flower',
            'Pre-Rolls',
            'Vaporizers',
            'Concentrate',
            'Edible',
            'Topicals',
            'Tincture',
            'CBD',
            'Clones',
            'Seeds',
            'Accessories',
            'Apparel',
          ],
          hemp: false,
          __typename: 'Dispensaries_TaxConfig_Tax_ApplyTo',
        },
        order: null,
        deliveryPolicy: null,
        includeStateSalesTaxInDestinationRate: true,
        __typename: 'Dispensaries_TaxConfig_Tax',
        destinationRate: null,
      },
    ],
    version: 2,
    __typename: 'Dispensaries_TaxConfig',
  },
};
