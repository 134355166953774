import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import ChevronIcon from 'assets/chevron-icon';

const PrevButton = ({ className, enabled, onClick, isMini = false }) => (
  <ButtonPrev
    aria-label='Previous'
    className={`${className} --left`}
    data-cy='carousel-prev-button'
    data-test='carousel-prev-button'
    disabled={!enabled}
    onClick={onClick}
    isMini={isMini}
  >
    <BackArrow onClick={onClick} width='100%' height='100%' />
  </ButtonPrev>
);

const NextButton = ({ className, enabled, onClick, isMini = false }) => (
  <ButtonNext
    aria-label='Next'
    className={`${className} --right`}
    data-cy='carousel-next-button'
    data-test='carousel-next-button'
    disabled={!enabled}
    onClick={onClick}
    isMini={isMini}
  >
    <ForwardArrow onClick={onClick} width='100%' height='100%' />
  </ButtonNext>
);

export const MemoizedPrevButton = memo(PrevButton);
export const MemoizedNextButton = memo(NextButton);

const CircleArrowStyles = css`
  height: 12px;
  width: 19px;
  fill: ${({ theme }) => theme.colors.grey[35]};
`;

const CircleContainer = css`
  background: ${({ theme }) => theme.colors.blueGrey[95]};
  border-radius: 50%;
  height: ${(props) => (props.isMini ? `40px` : `54px`)};
  width: ${(props) => (props.isMini ? `40px` : `54px`)};
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.17);

  &:hover {
    background: ${({ theme }) => theme.colors.blueGrey[90]};
  }
`;

export const sharedButtonStyles = css`
  background-color: transparent;
  position: absolute;
  padding: 0;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  outline: 0;
  border-radius: 35px;
  border: 0;
  width: ${(props) => (props.variant !== 'default' ? `50px` : `18px`)};
  height: ${(props) => (props.variant !== 'default' ? `50px` : `18px`)};
  ${(props) => (props.variant !== 'default' ? CircleContainer : '')};
  transition: opacity 0.25s, visibility 0.25s;
  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: block;

    &:not(:disabled) {
      cursor: pointer;
      opacity: 1;
      visibility: visible;
    }

    &:disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

const ButtonPrev = styled.button`
  ${sharedButtonStyles}
  left: ${(props) => (props.isMini ? `4px` : `20px`)};

  @media (min-width: 1340px) {
    left: ${(props) => (props.isMini ? `4px` : `-7px`)};

    &.offers {
      left: -19px;
    }
  }
`;

const ButtonNext = styled.button`
  ${sharedButtonStyles}
  right: ${(props) => (props.isMini ? `-8px` : `0`)};

  @media (min-width: 1340px) {
    right: ${(props) => (props.isMini ? `-8px` : `-27px`)};
  }
`;

export const ForwardArrow = styled(ChevronIcon)`
  transform: rotate(-90deg);
  ${(props) => (props.variant !== 'default' ? CircleArrowStyles : '')};
  margin-left: 3px;
  width: 17px;
  height: 10px;
  margin-top: 7px;
`;

export const BackArrow = styled(ChevronIcon)`
  transform: rotate(90deg);
  ${(props) => (props.variant !== 'default' ? CircleArrowStyles : '')};
  margin-left: -3px;
  width: 17px;
  height: 10px;
  margin-top: 7px;
`;
