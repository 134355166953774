import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { useContext } from 'react';
import { withContext } from 'with-context';
export default function createContextHelpers(Context, name) {
  var _ref;

  var capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
  var Provider = Context.Provider;

  function useMethod() {
    return useContext(Context);
  }

  return _ref = {}, _defineProperty(_ref, "".concat(name, "Context"), Context), _defineProperty(_ref, "".concat(capitalizedName, "Provider"), Provider), _defineProperty(_ref, "use".concat(capitalizedName), useMethod), _defineProperty(_ref, "with".concat(capitalizedName), withContext(Context, name)), _ref;
}