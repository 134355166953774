/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default function StoresIcon(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='126' height='95' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Dispensary exterior</title>
      <path
        opacity='.4'
        d='M114.713 51.1495v16.2839h-3.497V25.9901l-16.0886-8.7083v14.4387h-5.595v-10.089H75.5427v-5.4781h3.9041c.5577-.01 1.0028-.437.9918-.9547v-2.8972c.011-.5178-.4341-.9448-.9918-.9547h-3.9041l-5.1157-6.132-5.3764 6.132h-4.6032c-.5589.0099-1.0028.4369-.9929.9547v2.8961c-.0099.5177.434.9447.9929.9547h4.6032v13.5515h-9.7929v-5.9959h2.7986v-4.7103H13.9897v4.7103h3.4967v39.1136l-4.896-.1107V49.6594L0 55.1696v26.5145h125.904V51.0632l-11.191.0863z'
        fill='#B0C4CC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M77.641 24.6416c-2.7036 0-4.8959 2.1782-4.8959 4.8664l9.7918.0011c0-2.6882-2.1922-4.8675-4.8959-4.8675zm-28.3991 1.8286H24.9003v4.8675h24.3416v-4.8675zM3.4966 58.7051h6.2952v3.4758H3.4966v-3.4758zm6.2952 6.9516H3.4966v3.4758h6.2952v-3.4758zm-6.2952 6.9517h6.2952v3.4758H3.4966v-3.4758zm97.9264-44.491h-2.7979v6.257h2.7979v-6.257zm2.797 0h2.799v6.257h-2.798l-.001-6.257zm-2.797 10.4285h-2.7979v6.2558h2.7979v-6.2558zm2.797-1.3915h2.799v6.2569h-2.798l-.001-6.2569zm-2.797 7.6861h-2.7979v6.2558h2.7979v-6.2558zm2.797 1.3518h2.799v6.2569h-2.798l-.001-6.2569zm-2.797 7.684h-2.7979v6.2569h2.7979v-6.2569zm2.797 1.3529h2.799v6.257h-2.798l-.001-6.257zm18.887-.6571h-5.596v3.4759h5.596v-3.4759zm-5.596 6.9517h5.596v3.4758h-5.596v-3.4758zm5.596 6.9516h-5.596v3.4759h5.596v-3.4759zm-24.4819-5.561h2.7979v6.2569h-2.7979v-6.2569zm8.3939 1.3518h-2.799l.001 6.2569h2.798v-6.2569z'
        fill='#fff'
      />
      <path
        d='M96.1292 86.0947h-69.338c-9.8956 0-14.5651.718-14.5651 1.5985 0 .8817.4351 2.0079 11.7665 2.0079l7.7586-.0166v.0166h42.094v-.0266l25.3908.0266c12.559 0 13.043-1.1206 13.043-2.0056 0-.885-5.177-1.6008-16.1476-1.6008h-.0022z'
        fill='#D7E4EB'
      />
      <path
        d='M101.626 86.5763V43.9472c1.182-.1613 2.065-1.1684 2.073-2.363v-1.7545l-6.7372-7.5026h-68.736l-6.737 7.5026v1.7545c.0067 1.3285 1.0857 2.4009 2.412 2.3972l.3501 1.3906v41.2043'
        fill='#fff'
      />
      <path d='M24.4819 44.1436v2.4071l76.2421 1.0698v-3.4758l-76.2421-.0011z' fill='#BCCAD2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.7817 88.6367h25.531v-.0022c.5798 0 1.0492-.4669 1.0492-1.0432 0-.5753-.4694-1.0421-1.0492-1.0421H25.8801V43.7961c0-.5763-.4693-1.0432-1.0492-1.0432-.5787 0-1.0492.468-1.0492 1.0432v44.8406zm52.8103-.0001h25.531V43.7972c0-.5764-.47-1.0432-1.049-1.0432-.58 0-1.049.468-1.049 1.0432v42.7531H76.592c-.5798 0-1.0492.4668-1.0492 1.0421.0006.2779.1115.5442.3084.7401.1968.1959.4633.3053.7408.3041z'
        fill='#405D67'
      />
      <path
        d='M66.1 78.9031a1.0445 1.0445 0 01-.7401-.3034 1.0475 1.0475 0 01-.3091-.7387v-8.3422c0-.5753.4694-1.0432 1.0492-1.0432.5798 0 1.0492.4668 1.0492 1.0432v8.3411c0 .5763-.4694 1.0432-1.0492 1.0432z'
        fill='#BCCAD2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.2578 88.6369h15.3891V59.4375H55.2578v29.1994zm13.2906-2.0864H57.3562V61.5228h11.1922v25.0277z'
        fill='#405D67'
      />
      <path d='M56.3066 56.3105h13.2896v5.5611H56.3066v-5.5611z' fill='#fff' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.2578 62.9141h15.3891v-7.6475H55.2578v7.6475zm13.2906-2.0853H57.3562v-3.4769h11.1922v3.4769z'
        fill='#405D67'
      />
      <path d='M35.3228 54.9209h11.1911v25.7202H35.3228V54.9209z' fill='#EAF0F2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.2734 81.6845h13.2907V53.8779H34.2734v27.8066zm11.1923-2.0852h-9.0939V55.9632h9.0939v23.6361z'
        fill='#405D67'
      />
      <path d='M24.8306 54.9209h10.492v25.7202h-10.492V54.9209z' fill='#EAF0F2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.7817 81.6845h12.5905V53.8779H23.7817v27.8066zm10.4921-2.0852h-8.3937V55.9632h8.3937v23.6361z'
        fill='#405D67'
      />
      <path d='M90.5815 54.9209h10.4925v25.7202H90.5815V54.9209z' fill='#EAF0F2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M89.5322 81.6845h12.5908V53.8779H89.5322v27.8066zm10.4918-2.0852h-8.3934V55.9632h8.3934v23.6361z'
        fill='#405D67'
      />
      <path d='M79.3892 54.9209h11.1911v25.7202H79.3892V54.9209z' fill='#EAF0F2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M78.3403 81.6845H91.631V53.8779H78.3403v27.8066zm11.1923-2.0852h-9.0939V55.9632h9.0939v23.6361z'
        fill='#405D67'
      />
      <path
        d='M59.7293 53.5292l-3.4227-2.5311v-2.5842l3.4227-2.5311h6.4454l3.4226 2.5311v2.5842l-3.4226 2.5311h-6.4454z'
        fill='#BCCAD2'
      />
      <path
        d='M24.8311 52.1396h23.0824v2.0853a.6977.6977 0 01-.2057.4926.695.695 0 01-.4934.2021H24.8311v-2.78z'
        fill='#405D67'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.7817 55.9631h23.4326v-.0012c.9653 0 1.7483-.7776 1.7483-1.7368v-3.1284H23.7817v4.8664zm23.0825-2.0854H25.8801v-.6958h20.9841v.6958z'
        fill='#405D67'
      />
      <path
        d='M77.9912 52.1396h23.0828v2.78H78.6903a.695.695 0 01-.4933-.2021.6973.6973 0 01-.2058-.4926v-2.0853z'
        fill='#405D67'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M78.6902 55.9631h23.4328v-4.8664H76.9419v3.1284c.0017.4627.1869.9058.5148 1.2317.3279.3259.7716.508 1.2335.5063zm21.3338-2.0854H79.0403v-.6958h20.9837v.6958z'
        fill='#405D67'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.9058 60.4821c5.4117-4.1716 2.0487-4.8664-4.0367.6947l.0111 10.0801c0 2.0853 3.3475.3474 7.4085-3.1284 1.9626-1.6808 2.0272-1.4109 2.1822-.7626.1657.6925.4346 1.8166 3.2295 1.4573 5.4127-.6947 6.7657-2.78 6.7657-2.78v-2.7811s-9.4815 3.4758-5.4184-.6947c4.0621-4.1717 3.3851-5.5622-6.0853.6947-8.9901 5.9394-9.0431 1.062-4.0566-2.78zm55.43 1.3596c2.0984-2.3729 1.1884-4.0942-4.897-.7909v7.3742c0 1.239 4.0444.2057 8.1042-1.8596 1.9011-.9666 1.9717-.0655 2.0648 1.1236.1058 1.3504.2407 3.0722 3.1183 2.8522 3.0416-.1272 5.6215-2.2633 6.2949-5.2137v-1.6516s-8.7699 2.0653-4.7123-.4127c3.5673-2.1759 1.9184-4.0201-6.0853.4127-6.6862 3.7026-8.0148 2.8342-3.8876-1.8342z'
        fill='#fff'
      />
      <path
        d='M48.3328 75.428l4.0753-4.1717h-3.8743l2.9444-3.4758H48.092l-1.9272-3.4758-1.9239 3.4758h-3.3862l2.9444 3.4758h-3.8743l4.0753 4.1706h-4.8893l5.8866 5.394a1.765 1.765 0 002.337 0l5.8866-5.394h-4.8871l-.0011.0011z'
        fill='#44A788'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.8879 81.3379h-8.3958v6.2536h8.3958v-6.2536zm-32.8754 0h-8.3958v5.5578h8.3958v-5.5578z'
        fill='#677882'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.8013 88.6367h4.3769v-.0011c1.2132.0374 2.2848-.7866 2.5622-1.9703l1.639-7.0667H39.6001l1.6301 7.0214c.2622 1.2035 1.3417 2.0503 2.5711 2.0167zm-.5157-2.4393l-1.0492-4.5135h7.5056l-1.0525 4.5566a.497.497 0 01-.508.3098h-4.3769c-.2352.0199-.4528-.1283-.519-.3529z'
        fill='#405D67'
      />
      <path
        d='M81.2083 75.428l4.0753-4.1717h-3.8743l2.9433-3.4758h-3.3851l-1.9272-3.4758-1.925 3.4758h-3.384l2.9444 3.4758h-3.8754l4.0753 4.1706h-4.8893l5.8866 5.394a1.765 1.765 0 002.337 0l5.8866-5.394h-4.8882v.0011z'
        fill='#44A788'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M76.6768 88.6367h4.3769v-.0012c1.2132.0375 2.2848-.7865 2.5622-1.9702l1.6379-7.0667H72.4756l1.629 7.0214c.2623 1.2039 1.3424 2.0508 2.5722 2.0167zm-.5157-2.4393l-1.0493-4.5135h7.509l-1.0558 4.5566a.497.497 0 01-.508.3098h-4.3769c-.2363.0199-.4528-.1283-.519-.3529zM20.9839 40.322l6.9943-7.9949h69.2474l6.9944 7.9949v1.3961c-.009 1.3438-1.099 2.4283-2.441 2.4271H23.4258c-1.3421.0018-2.4334-1.0829-2.4419-2.4271V40.322zm13.5983 41.0587c.1968.1957.4632.305.7404.3038.5799 0 1.0492-.4668 1.0492-1.0432V54.92c0-.5753-.4693-1.0421-1.0492-1.0421-.5787 0-1.0492.4668-1.0492 1.0432v25.7202c.0009.2777.112.5437.3088.7394z'
        fill='#405D67'
      />
      <path d='M99.0458 38.5836H26.1582l3.5673-4.1705h65.7552l3.5651 4.1705z' fill='#9CAAB4' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M91.6311 53.8779h-2.0985v26.4172h2.0985V53.8779zM43.7167 88.6373h38.4715v-.0011c.5787 0 1.0492-.4669 1.0492-1.0432 0-.5753-.4705-1.0421-1.0492-1.0421H43.7167c-.5787 0-1.0492.4668-1.0492 1.0421.0006.2779.1115.5442.3083.7401a1.044 1.044 0 00.7409.3042z'
        fill='#405D67'
      />
    </svg>
  );
}
