import React, { forwardRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { mediaSizes } from 'theme';
import { hasIsolatedMenus } from 'shared/core/helpers/dispensaries';
import { ModalSecondaryHeader } from 'shared/modals';
import { Clickable } from 'shared/components';
import { ModalContainer, Button } from 'components/core';

const RECREATIONAL = `Recreational`;
const MEDICAL = `Medical`;
const CHECKOUT = 'checkout';
const MENU = 'menu';

const MenuTypeSwitchWarningModal = forwardRef(({ containerProps, t, router, cart, onFinish }, ref) => {
  const { hasMenuTypeInclusiveProducts, isMedical, dispensary } = cart;
  const view = _.includes(router.route, CHECKOUT) ? CHECKOUT : MENU;
  const otherMenuType = isMedical ? RECREATIONAL : MEDICAL;
  const currentMenuType = isMedical ? MEDICAL : RECREATIONAL;

  let copy = '';

  if (view === MENU) {
    copy = t('regulations.med-rec-switch', {
      defaultValue: `If you'd like to change this from a {{currentMenuType}} order to a {{otherMenuType}} order, we'll adjust your cart to ensure your order isn't over the {{otherMenuType}} purchase limit.`,
      otherMenuType,
      currentMenuType,
    });
  } else if (hasMenuTypeInclusiveProducts && !hasIsolatedMenus(dispensary)) {
    copy = t('regulations.stayInCheckout', {
      defaultValue: `If you'd like to change your order to {{otherMenuType}}, we'll adjust your cart to include only {{otherMenuType}} items.`,
      otherMenuType,
    });
  } else {
    copy = t('regulations.returnToMenu', {
      defaultValue: `If you'd like to change your order to {{otherMenuType}}, we'll remove {{currentMenuType}} items from your cart and take you back to browse the {{otherMenuType}} menu.`,
      otherMenuType,
      currentMenuType,
    });
  }

  if (view) {
    return (
      <StyledModalContainer ref={ref} height={315} width={495} {...containerProps}>
        <ModalSecondaryHeader lowercase mb='18px'>
          {t('regulations.med-rec-switch-header', {
            defaultValue: `Switch to a {{otherMenuType}} Order?`,
            otherMenuType,
          })}
        </ModalSecondaryHeader>
        <Copy data-testid='copy'>
          {t(
            'regulations.stateRegulations',
            'Due to state regulations, recreational and medical items must be sold separately. '
          )}
          {copy}
        </Copy>
        <YesButton onClick={() => onFinish(true)} autoFocus>
          {t(`common.continue`, `Continue`)}
        </YesButton>
        <Nevermind onClick={() => onFinish(false)}>
          {t('regulations.med-rec-switch-nevermind', {
            defaultValue: `Nevermind, keep it {{currentMenuType}}`,
            currentMenuType,
          })}
        </Nevermind>
      </StyledModalContainer>
    );
  }
});

const StyledModalContainer = styled(ModalContainer)`
  padding: 44px 58px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mediaSizes.phone}px) {
    height: 375px;
  }
`;

const Nevermind = styled(Clickable)`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 12px;
  margin-top: 31px;
`;

const YesButton = styled(Button)`
  max-width: 102px;
  margin-top: 30px;
`;

const Copy = styled.p`
  color: ${({ theme }) => theme.colors.v2TextColor1};
  font-size: 13px;
  line-height: 23.25px;
  line-height: 20px;
`;

export default MenuTypeSwitchWarningModal;
