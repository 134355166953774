import React from 'react';

export function PurpleBoltIconSmall(): JSX.Element {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Frame 29694'>
        <path
          id='Vector 2'
          d='M2.29904 6.71136L6.12127 1.60181C6.26528 1.4093 6.57146 1.51115 6.57146 1.75156V4.63889C6.57146 4.77696 6.68339 4.88889 6.82146 4.88889H9.50078C9.70665 4.88889 9.82428 5.12379 9.70097 5.28864L5.87879 10.3982C5.73479 10.5907 5.4286 10.4888 5.42861 10.2484L5.42865 7.36111C5.42865 7.22304 5.31672 7.11111 5.17865 7.11111H2.49922C2.29336 7.11111 2.17572 6.87621 2.29904 6.71136Z'
          fill='#3C0059'
        />
      </g>
    </svg>
  );
}
