/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export const CartV2 = (props: SVGAttributes<unknown>): JSX.Element => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.5 19.2004H9.12016C8.78514 19.2005 8.46098 19.0834 8.20492 18.87C7.94904 18.6565 7.77792 18.3606 7.72184 18.0345L5.22866 3.56535C5.1724 3.23948 5.00118 2.94371 4.74522 2.73045C4.48934 2.5172 4.16518 2.4003 3.83034 2.40039H2.9'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.82814 14.4H18.2987C18.879 14.3999 19.4425 14.1913 19.8999 13.8072C20.3572 13.4233 20.6821 12.8858 20.8229 12.2805L22.0807 6.86945C22.1046 6.76628 22.1065 6.65856 22.086 6.5545C22.0654 6.45043 22.0231 6.3528 21.9622 6.26899C21.9013 6.18519 21.8235 6.11736 21.7346 6.07077C21.6457 6.02419 21.5481 5.99991 21.4492 6H6.5001'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='1.2' cy='1.2' r='1.2' transform='matrix(-1 0 0 1 10.1001 20.4004)' fill='currentColor' />
    <circle cx='1.2' cy='1.2' r='1.2' transform='matrix(-1 0 0 1 18.5 20.4004)' fill='currentColor' />
  </svg>
);
