import React from 'react';
import { callNativeMethod } from 'src/mobile-ecomm/hooks/use-mobile-native-bridge';
import { DutchiePayButton } from 'src/payments/components';
import styled from 'styled-components';
import { PBB_NAME } from 'src/payments/constants';
import { StyledButtonText } from './cta-modal.styles';

const onMobileEcommLinkClick = (): void => callNativeMethod(`onLaunchUrl`, { url: `https://dutchie.com` });
export const MobileEcommDutchiePayCta = ({ initialSignup }: { initialSignup?: boolean }): JSX.Element => (
  <div>
    Log in to your{' '}
    <StyledButtonText onClick={onMobileEcommLinkClick} onKeyDown={onMobileEcommLinkClick} role='button' tabIndex='0'>
      dutchie.com
    </StyledButtonText>{' '}
    account in a browser to {initialSignup ? 'start' : 'continue'} using {PBB_NAME}.
  </div>
);

export const MobileEcommDutchiePayButton = (): JSX.Element => (
  <Button onClick={onMobileEcommLinkClick}>Visit dutchie.com</Button>
);

const Button = styled(DutchiePayButton)`
  font-size: 16px;
`;
