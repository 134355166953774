import styled, { css } from 'styled-components';
import { ModalContent as _ModalContent } from 'components/modals/components';

import { DutchiePayLogo as _DutchiePayLogo } from 'assets/dutchie-pay-logo';
import { Text } from 'rebass';
import { DutchiePayButton } from 'src/payments/components';

export const DutchiePayLogo = styled(_DutchiePayLogo)`
  ${({ theme: { colors } }) => css`
    color: ${colors.dutchiePayGreen};
  `}
`;
export const HeaderContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const ContentContainer = styled.div`
  ${({ theme: { spaces } }) => css`
    margin: ${spaces[1]} 0 0 0;
  `}
`;

export const Heading = styled.h2`
  ${({ theme: { spaces, colors } }) => css`
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    margin: ${spaces[5]} 0 0 0;
    color: ${colors.dutchiePayBlack};
  `}
`;

export const BodyText = styled(Text)`
  ${({ theme: { colors, spaces } }) => css`
    text-align: left;
    font-size: 14px;
    line-height: 23px;
    padding-bottom: ${spaces[2]};
    color: ${colors.grey[30]};
    :last-child {
      padding-bottom: ${spaces[6]};
    }
    :first-child {
      padding-top: ${spaces[4]};
    }
  `}
`;

export const Button = styled(DutchiePayButton)`
  ${({ theme: { spaces } }) => css`
    font-size: 14px;
    padding: ${spaces[3]} ${spaces[6]};
    height: auto;

    ~ * {
      margin-top: ${spaces[6]};
    }
  `}
`;

export const LinkAccount = styled.button`
  ${({ theme: { colors } }) => css`
    background: none;
    border: none;
    color: ${colors.dutchiePayGreen};
    font-size: 14px;
    font-weight: 700;

    :hover {
      cursor: pointer;
      color: ${colors.green[30]};
    }
  `}
`;

export const ModalContent = styled(_ModalContent)`
  ${({ theme: { spaces } }) => css`
    padding: ${spaces[8]} ${spaces[7]};
  `}
`;
