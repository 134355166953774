import memoize from 'lodash/memoize';
import React from 'react';
import { isBrowser } from 'utils/misc-utils';
import noop from 'lodash/noop';

/**
 * WARNING: not fully typesafe, so use with caution.
 *
 * Command reference: https://developers.google.com/gtagjs/reference/api
 *                    https://developers.google.com/analytics/devguides/collection/ga4/tag-guide
 */
type GTag = (command: 'config' | 'event' | 'get' | 'js' | 'set', ...args: any) => void;

/**
 * Adapted from https://developers.google.com/analytics/devguides/collection/gtagjs
 */
function initAndGetGtag(): GTag {
  if (!isBrowser()) {
    return noop;
  }

  window.dataLayer = window.dataLayer ?? [];
  window.gtag = function gtag() {
    // We need to use the arguments object here rather than rest params because GTM is expecting an object, not an array.
    /* eslint-disable prefer-rest-params */
    window.dataLayer?.push(arguments);
    embeddedEventDispatcher('dataLayer', 'push', arguments);
    /* eslint-disable prefer-rest-params */
  };
  // initializes the tracker
  window.gtag('js', new Date());
  return window.gtag;
}

export const getGtag = memoize(initAndGetGtag);

export function embeddedEventDispatcher(type: 'dataLayer', event: 'push', data: any): boolean {
  if (window.parent === window) {
    return true;
  }

  window.parent.postMessage(
    JSON.stringify({
      event: `analytics:${type}`,
      payload: { event, payload: data },
    }),
    '*'
  );
  return true;
}

export function GtagScript(): JSX.Element {
  return <script async src='https://www.googletagmanager.com/gtag/js' />;
}
