import React from 'react';

import useTranslation from 'hooks/use-translation';

import {
  ProductRecommendationSource,
  ProductRecommendationSection,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { ProductCarousel } from 'src/components/product-carousel';
import { useCartRecommendationsCarousel } from './use-cart-recommendations-carousel';

export function CartRecommendationsCarousel(): JSX.Element {
  const { t } = useTranslation();
  const carouselProps = useCartRecommendationsCarousel();

  return (
    <ProductCarousel
      isMini
      heading={t(`common.beforeYouGo`, 'Before You Go')}
      trackerSource={ProductRecommendationSource.crossingMinds}
      trackerSection={ProductRecommendationSection.cartRecommendations}
      {...carouselProps}
    />
  );
}
