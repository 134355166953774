/* eslint-disable @typescript-eslint/naming-convention */
import { useGetCustomerDetailsQuery } from 'types/graphql';
import useStores from 'shared/hooks/use-stores';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useDutchiePay } from 'src/payments/hooks/use-dutchie-pay/use-dutchie-pay';
import useDispenarySpecials from 'src/dispensary/hooks/use-dispensary-specials';
import { DUTCHIE_PAY } from 'src/payments/constants';

export type IncentivesResponse = {
  incentivesActive: boolean;
  showPaymentBanner: boolean;
  showCTABanner: boolean;
  userType: UserType;
  qualifiedPBBUser: boolean;
};

export enum UserType {
  UNENROLLED = 'unenrolled',
  QUALIFIED = 'qualified',
  NOT_QUALIFIED = 'not-qualified',
}

export function useIncentives(): IncentivesResponse {
  const flags = useFlags();
  const payByBankIncentivesEnabled = flags['fintech.platform.pay-by-bank-incentives'] ?? false;

  const { isEnrolledDutchiePay } = useDutchiePay();
  const { Cart, User } = useStores();
  const { specials } = useDispenarySpecials({ showPaymentRestricted: true, hideProductlessSpecials: false });
  const isGuestUser = !User?.exists;

  const { data: customerDetailsData } = useGetCustomerDetailsQuery({
    skip: !payByBankIncentivesEnabled || isGuestUser || !isEnrolledDutchiePay,
  });

  if (!payByBankIncentivesEnabled) {
    return {
      incentivesActive: false,
      showPaymentBanner: false,
      showCTABanner: false,
      userType: UserType.NOT_QUALIFIED,
      qualifiedPBBUser: false,
    };
  }

  const acceptsPBB = Cart.order?.dispensary?.paymentTypesAccepted?.dutchiePay;
  const qualifiedPBBUser = customerDetailsData?.getCustomerDetails.successfulOrderCount === 0;

  let userType;
  if (isGuestUser || !isEnrolledDutchiePay) {
    userType = UserType.UNENROLLED;
  } else if (qualifiedPBBUser) {
    userType = UserType.QUALIFIED;
  } else {
    userType = UserType.NOT_QUALIFIED;
  }

  const payByBankIncentiveActive = specials.some((special) => special.paymentRestrictions?.payByBankSignupIncentive);
  const incentivesActive = payByBankIncentivesEnabled && payByBankIncentiveActive && acceptsPBB;

  const showPaymentBanner = incentivesActive && (userType === UserType.UNENROLLED || userType === UserType.QUALIFIED);
  const showCTABanner = incentivesActive && Cart.paymentMethod !== DUTCHIE_PAY;

  return { incentivesActive, showPaymentBanner, showCTABanner, userType, qualifiedPBBUser };
}
