import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["display", "height", "width", "src", "margin", "color"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Image } from 'rebass/styled-components';
import { width as styledWidth, height as styledHeight, margin as styledMargin, display as styledDisplay } from 'styled-system';
export default (function (_ref) {
  var _ref$display = _ref.display,
      display = _ref$display === void 0 ? 'block' : _ref$display,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? '15px' : _ref$height,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? '15px' : _ref$width,
      _ref$src = _ref.src,
      src = _ref$src === void 0 ? '/icons/question-mark.svg' : _ref$src,
      margin = _ref.margin,
      color = _ref.color,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx(Icon, _extends({
    height: height,
    width: width,
    margin: margin,
    src: color === 'grey' ? '/icons/question-mark-grey.svg' : src,
    display: display
  }, props));
});
var Icon = styled(function (_ref2) {
  var props = _extends({}, _ref2);

  return __jsx(Image, props);
}).withConfig({
  displayName: "tooltip-icon__Icon",
  componentId: "sc-1nlczqh-0"
})(["margin-left:7px;", " ", " ", " ", ""], styledHeight, styledWidth, styledMargin, styledDisplay);