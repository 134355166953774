/* eslint-disable @typescript-eslint/naming-convention */
import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { getPotencyStrings } from 'utils/helpers/product';
import { smallCapsSubtext } from 'src/components/core/typography';
import { Typography } from 'src/typography';
import { ProductCardProduct } from './product-card.types';

export type StrainDetailsProps = {
  product: ProductCardProduct;
  hidePotency?: boolean;
};
export const StrainDetails = memo(
  ({ product, hidePotency = false, ...props }: StrainDetailsProps): JSX.Element | null => {
    const { strainType } = product;

    // TODO: possibly remove once serialization is figured out
    const productForPotencyHelpers =
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'thcContent' in product
        ? { ...product, THCContent: product.thcContent, CBDContent: product.cbdContent }
        : product;
    const { THC: THCString, CBD: CBDString, TAC: TACString } = getPotencyStrings(productForPotencyHelpers);

    const showTAC = 'cannabinoidsV2' in product && !!product.cannabinoidsV2?.length;

    const details: string[] = [];

    if (typeof strainType === 'string' && strainType !== 'N/A') {
      details.push(strainType);
    }

    if (!hidePotency && showTAC && typeof TACString === 'string') {
      details.push(`TAC:${' '}${TACString}`);
    }

    if (!hidePotency && typeof THCString === 'string') {
      details.push(`THC:${' '}${THCString}`);
    }

    if (!hidePotency && typeof CBDString === 'string') {
      details.push(`CBD:${' '}${CBDString}`);
    }

    if (details.length === 0) {
      return null;
    }

    const hasRanges = details.some((detail) => detail.includes(' - '));

    return (
      <Container {...props}>
        {details.map((detail, index) => (
          <StyledGreyCapsText
            key={detail}
            separateLines={hasRanges}
            tag='span'
            size='xsmall'
            weight='bold'
            casing='uppercase'
          >
            {detail}
            {index !== details.length - 1 && !hasRanges && ' •'}&nbsp;
          </StyledGreyCapsText>
        ))}
      </Container>
    );
  }
);

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.grey[45]};
  margin-top: auto;

  b {
    line-height: 127%;
  }
`;

export const StyledGreyCapsText = styled(Typography.Label)`
  ${smallCapsSubtext};
  color: ${({ theme }) => theme.colors.grey[45]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};

  ${({ separateLines }) =>
    separateLines &&
    css`
      display: block;
      line-height: 125%;
      padding-bottom: 6px;

      &:first-child {
        padding-top: 1px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    `}
`;
