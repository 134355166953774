import React from 'react';
import styled, { css } from 'styled-components';
import StarIcon from 'assets/full-star';

export default function DutchieScore({ value, size = 'medium', ...props }) {
  return (
    <StyledBadge aria-label='Dutchie score' size={size} {...props}>
      <StyledText size={size}>{value}</StyledText>
      <Star size={size} />
    </StyledBadge>
  );
}

const Star = ({ size }) => {
  const dimensions = {
    large: '18px',
    medium: '11px',
    small: '10px',
  };
  return <StarIcon height={dimensions[size]} width={dimensions[size]} alt='Star' />;
};

const BadgeStyles = {
  large: css`
    border-radius: 11px;
    font-size: 21px;
    height: 50px;
    width: 102px;

    span {
      line-height: 24px;
      margin-top: 2px;
    }

    svg {
      margin-bottom: 5px;
    }
  `,
  medium: css`
    border-radius: 8px;
    font-size: 14px;
    height: 27px;
    width: 57px;

    span {
      line-height: 17px;
      margin-top: 2px;
    }

    svg {
      // margin-bottom: 2px;
    }
  `,
  small: css`
    border-radius: 6px;
    font-size: 12.5px;
    height: 24px;
    width: 51px;

    span {
      line-height: 14px;
      margin-top: 2px;
    }

    svg {
      margin-bottom: 2px;
    }
  `,
};

const StyledBadge = styled.span`
  ${({ size }) => BadgeStyles[size]}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryGreen};
  display: flex;
  justify-content: space-evenly;
`;

const StyledText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  flex: 0 0 auto;
`;
