import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _reduce from "lodash/reduce";
import _reject from "lodash/reject";
import _split from "lodash/split";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { isBlank } from 'underscore.string';
import { canadianProvincesMapToShortened, countryMapToShortened, USStatesMapToShortened } from 'shared/core/constants/geography';
import { getCityToDisplay } from './address';

var parseStateShortCode = function parseStateShortCode(option1, option2) {
  var splitByDash = _split(option1 || option2, '-');

  return splitByDash[splitByDash.length - 1];
};

var formatAddressForLocationSearch = function formatAddressForLocationSearch(location) {
  var ln1 = location.ln1,
      ln2 = location.ln2,
      state = location.state,
      stateLong = location.stateLong,
      zipcode = location.zipcode,
      country = location.country,
      countryLong = location.countryLong,
      rootType = location.rootType;
  var countryPretty = rootType === 'region' || rootType === 'country' ? countryLong : "".concat(country === 'US' ? 'USA' : country);
  var statePretty = rootType === 'place' || rootType === 'region' ? stateLong : state;
  var cityPretty = getCityToDisplay(location);
  return _reject([_reject([ln1, ln2], isBlank).join(' '), cityPretty, _reject([statePretty, zipcode], isBlank).join(' '), countryPretty], isBlank).join(', ');
};

export function buildLocationObjectFromMapboxFeature(data) {
  var id = data.id,
      center = data.center,
      addressLong = data.place_name;

  var _center = _slicedToArray(center, 2),
      lng = _center[0],
      lat = _center[1];

  var locationDetails = _reduce([_objectSpread(_objectSpread({}, data), {}, {
    isRoot: true
  })].concat(_toConsumableArray(data.context || [])), function (accumulator, current) {
    // eslint-disable-next-line camelcase
    var address = current.address,
        currentId = current.id,
        text = current.text,
        short_code = current.short_code,
        properties = current.properties;

    var _split2 = _split(currentId, '.'),
        _split3 = _slicedToArray(_split2, 1),
        type = _split3[0];

    if (current.isRoot) {
      accumulator.rootType = type;
    }

    if (type === 'country') {
      accumulator.country = countryMapToShortened[text];
      accumulator.countryLong = text;
    } else if (type === 'region') {
      var _ref, _USStatesMapToShorten;

      accumulator.state = (_ref = (_USStatesMapToShorten = USStatesMapToShortened[text]) !== null && _USStatesMapToShorten !== void 0 ? _USStatesMapToShorten : canadianProvincesMapToShortened[text]) !== null && _ref !== void 0 ? _ref : parseStateShortCode(short_code, properties === null || properties === void 0 ? void 0 : properties.short_code);
      accumulator.stateLong = text;
    } else if (type === 'postcode') {
      accumulator.zipcode = text;
    } else if (type === 'district') {
      accumulator.county = text;
    } else if (type === 'locality') {
      accumulator.locality = text;
    } else if (type === 'place') {
      accumulator.city = text;
    } else if (type === 'neighborhood') {
      accumulator.neighborhood = text;
    } else if (type === 'address') {
      // TODO: see if there's a way to get ln2 (Ex: Apt 111) in Mapbox
      accumulator.ln1 = "".concat(address ? "".concat(address, " ") : '').concat(text);
    }

    return accumulator;
  }, {});

  return _objectSpread(_objectSpread({
    id: id
  }, locationDetails), {}, {
    lat: lat,
    lng: lng,
    address: formatAddressForLocationSearch(locationDetails),
    addressLong: addressLong
  });
}