import React from 'react';
import _times from 'lodash/times';

import { HybridProductCardLoadingSkeleton } from 'components/core/loading-skeletons';
import Skeleton from 'components/core/loading-skeletons/loading-skeleton';
import { HybridHeader } from 'components/hybrid-carousel/hybrid-carousel-header';
import { HybridSlider } from 'components/hybrid-carousel/hybrid-carousel-slider';
import { Content } from 'components/hybrid-carousel/hybrid-carousel.styles';
import { Item } from 'components/hybrid-carousel/hybrid-carousel-card/hybrid-carousel-card.styles';

const HybridSkeletonCarousel = (props) => {
  const {
    dimensions: { gutter },
    carouselPadding,
    noHeader,
  } = props;
  return (
    <Content {...props}>
      {!noHeader && (
        <HybridHeader carouselPadding={carouselPadding}>
          <Skeleton width={200} height={20} {...props} />
        </HybridHeader>
      )}
      <HybridSlider carouselPadding={carouselPadding}>
        {_times(8, (key) => (
          <Item gutter={gutter} key={key}>
            <HybridProductCardLoadingSkeleton {...props} />
          </Item>
        ))}
      </HybridSlider>
    </Content>
  );
};

export default HybridSkeletonCarousel;
