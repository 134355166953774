import React, { forwardRef } from 'react';
import { useObserver } from 'mobx-react';
import styled from 'styled-components';

import ChevronIcon from 'assets/chevron-icon';
import { Clickable } from 'shared/components';
import useTranslation from 'hooks/use-translation';
import useCart from 'hooks/use-cart';

const MenuTypeModalButton = forwardRef(({ onClick, disabled }, ref) => {
  const cart = useCart();
  const menuType = useObserver(() => cart.menuType);
  const { t } = useTranslation();

  return (
    <MenuTypeButton ref={ref} disabled={disabled} onClick={disabled ? () => {} : onClick}>
      {t('common.menu', 'Menu')}:
      <MedRecText>{menuType === 'med' ? t('common.med', 'Med') : t('common.rec', 'Rec')}</MedRecText>
      {!disabled && <StyledChevronIcon />}
    </MenuTypeButton>
  );
});

export default MenuTypeModalButton;

export const MedRecText = styled.span`
  font-weight: bold;
  margin-left: 4px;
`;

export const MenuTypeButton = styled(Clickable)`
  cursor: ${({ disabled }) => `${disabled ? `initial` : `pointer`} !important`};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[45]};
`;

export const StyledChevronIcon = styled(ChevronIcon).attrs({
  height: `7px`,
  width: `11px`,
})`
  margin-left: 7px;
  fill: ${({ theme }) => theme.colors.grey[45]};
  transform: ${({ direction }) => (direction === `up` ? `rotate(-180deg)` : `none`)};
`;
