import { useState, useEffect, useMemo } from 'react';
import omit from 'lodash/omit';
import { useObserver } from 'mobx-react-lite';
import { useTheme } from 'styled-components';

import useDebounce from 'shared/hooks/use-debounce';

import useViewport from 'hooks/use-viewport';
import useUI from 'hooks/use-ui';
import useUser from 'hooks/use-user';
import useCart from 'hooks/use-cart';
import { useModals, ModalNames } from 'src/components/modals';

export function useEmbeddedEvents() {
  const UI = useUI();
  const User = useUser();
  const Cart = useCart();
  const { closeModal } = useModals();

  const isEmbedded = useObserver(() => UI.isEmbedded);
  const isIframe = useObserver(() => UI.isIframe);
  const [initialized, setInitialized] = useState(false);
  const [currentViewport, setCurrentViewport] = useState({});
  const { customized } = useTheme();

  useEffect(() => {
    if (!isEmbedded) {
      return undefined;
    }
    function handler(event) {
      let payload = {};
      try {
        payload = JSON.parse(event.data);
      } catch (e) {
        // console.log('Unable to parse iframe data', event.data);
      }

      if (payload.event === 'embed:events:viewport') {
        setCurrentViewport(payload.viewport);
      } else if (payload.event === 'embed:events:scroll-start') {
        UI.isScrolling = true;
      } else if (payload.event === 'embed:events:scroll-end') {
        UI.isScrolling = false;
      } else if (payload.event === 'embed:previewMode') {
        UI.previewMode = payload.previewMode;
      } else if (payload.event === 'embed:ready') {
        UI.isIframeSDK = true;
      } else if (payload.event === 'embed:registerClickHandler:login') {
        UI.isUsingExternalClickHandlerLogin = true;
      } else if (payload.event === 'embed:setUserDetails') {
        const userDetails = omit(payload.userDetails, [`medicalCard`]);
        User.setUserDetailsFromExternalSource(userDetails);
        Cart.setMedicalCard(payload.userDetails?.medicalCard);
      } else if (payload.event === 'embed:setParentUrl') {
        UI.iframeParentUrl = payload.url;
        UI.iframeParentHref = payload.href;
      } else if (payload.event === 'embed:oAuthLogin') {
        User.updateToken(payload.token);
        // Either modal could be open, so attempt to close both
        closeModal(ModalNames.login);
        closeModal(ModalNames.signUp);
      }
    }
    window.addEventListener('message', handler);

    if (!initialized) {
      setInitialized(true);
      UI.embeddedEventDispatcher(`iframe:ready`, {
        primaryColor: customized.colors.buttonsLinks,
      });
    }
    return () => window.removeEventListener('message', handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentViewportMemo = useMemo(() => currentViewport, [
    currentViewport.height,
    currentViewport.width,
    currentViewport.offset,
    currentViewport.parentOffset,
    currentViewport.offsetTop,
  ]);
  const viewport = useDebounce(currentViewportMemo, 90);
  const { setViewport } = useViewport();

  useEffect(() => {
    if (!isIframe) {
      return;
    }

    setViewport(viewport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewport.height, viewport.width, viewport.offset, viewport.parentOffset, viewport.offsetTop]);
}
