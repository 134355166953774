import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
export var MuiTabs = {
  root: {
    minHeight: 32,
    borderBottom: '1px solid #e5e8ec'
  },
  indicator: {
    backgroundColor: '#4597e0'
  }
};
export var MuiTab = {
  root: {
    textTransform: 'none',
    minHeight: 30,
    padding: '6px 10px',
    margin: '0px 9px',
    minWidth: 'auto !important',
    letterSpacing: 0
  }
};
export { Tabs, Tab };