import React, { useState } from 'react';
import styled from 'styled-components';
import { space, width } from 'styled-system';
import { yup } from 'shared/core/yup';
import moment from 'moment';
import _ from 'lodash';
import { useApolloClient } from '@apollo/react-hooks';

import useCart from 'hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useErnie from 'shared/hooks/use-ernie';
import useGeoinfo from 'hooks/use-geoinfo';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import useUser from 'hooks/use-user';

import { useModals, ModalNames } from 'components/modals';
import { Button } from 'components/core';
import { paragraph, bold } from 'components/core/typography';
import { consumerSignup } from 'shared/actions/users';
import { callNativeMethod } from 'src/mobile-ecomm/hooks/use-mobile-native-bridge';
import { tracker } from 'utils/analytics';
import { LoginOptionsContainer, SecondaryOptionText as LoginText } from '../components';
import { FormTextInput, FormFormattedInput } from '../../form-inputs';
import Form from '../form';
import { emailRegEx, birthdayRegEx } from '../form-utils';
import MedicalCardForm, { CO } from './medical-card-form';

const signUpFormValidation = (t, showMedicalCardForm) => {
  const medicalCardVerification = showMedicalCardForm
    ? {
        medicalCardNumber: yup.string().when([`state`], {
          is: (state) => state === CO,
          then: yup.string(),
          otherwise: yup.string().required(t('form.medicalCardNumber', 'medical card number is required')),
        }),
        expirationDate: yup
          .string()
          .beforeToday(`MM/DD/YYYY`, `medical card is expired`)
          .required(t('form.expirationDate', 'expiration date is required')),
        state: yup.string().required(t('form.state', 'state is required')),
      }
    : {};

  return yup.object().shape({
    firstName: yup.string().required(t('form.firstNameRequired', 'first name is required')),
    lastName: yup.string().required(t('form.lastNameRequired', 'last name is required')),
    email: yup
      .string()
      .required(t('form.emailRequired', 'email is required'))
      .matches(emailRegEx, t('form.validEmail', 'please enter a valid email address')),
    password: yup.string().required(t('form.passwordRequired', 'password is required')).min(8),
    phoneNumber: yup
      .string()
      .required(t('form.phoneNumberRequired', 'phone number is required'))
      .phone(t('form.validPhoneNumber', 'please enter a valid phone number')),
    birthday: yup
      .string()
      .required(t('form.birthdayRequired', 'birthday is required'))
      .isLegalAge(`MM/DD/YYYY`, t('form.isLegalAge', 'You must be at least 18 years old to use dutchie'))
      .matches(birthdayRegEx, t('form.validBirthday', 'please enter a valid birthday')),
    ...medicalCardVerification,
  });
};

const SignUpForm = ({ onClose, analyticsEventLabel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMedicalCardForm, setShowMedicalCardForm] = useState(false);
  const UI = useUI();
  const { isMobileEcommApp, isEmbedded, isStoreFront } = UI;
  const Cart = useCart();
  const { openModal } = useModals();
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const { dispensary } = useDispensary();
  const showErnie = useErnie();
  const User = useUser();
  const [geoinfo] = useGeoinfo();
  const country = geoinfo?.country;

  const handleOpenLoginModal = () => {
    onClose();
    openModal(ModalNames.login);
  };

  const ageCheck = (e) => {
    if (!e || e.currentTarget?.contains(e.relatedTarget)) {
      return;
    }
    const birthday = e.target.value;
    const formattedBirthday = moment(birthday, `MM/DD/YYYY`, true);

    if (!formattedBirthday.isValid()) {
      return;
    }

    const yearDiff = moment().diff(formattedBirthday, `years`);

    if (!showMedicalCardForm && yearDiff >= 18 && yearDiff < 21 && country !== 'CA') {
      setShowMedicalCardForm(true);
    }

    if (moment().diff(formattedBirthday, `years`) < 18) {
      showErnie(t('signUp.ageGate', 'You must be 18 years of age or older to use dutchie'), `danger`);
      setShowMedicalCardForm(false);
    }

    if (showMedicalCardForm && yearDiff >= 21) {
      setShowMedicalCardForm(false);
    }
  };

  const trackAccountSignup = (dispensary, userId, analyticsEventLabel) => {
    tracker.setContext({ activeDispensary: dispensary });
    tracker.accountLogin({ newAccount: true, customerId: userId, analyticsEventLabel });
  };

  const handleFormSubmit = async ({
    firstName,
    lastName,
    email,
    password,
    phoneNumber,
    birthday,
    medicalCardNumber,
    expirationDate,
    state,
  }) => {
    setIsLoading(true);
    try {
      const profile = {
        email,
        password,
        profile: {
          firstName: _.trim(firstName),
          lastName: _.trim(lastName),
          phone: phoneNumber,
          birthday,
          textNotifications: true,
          emailNotifications: true,
          medicalCard: showMedicalCardForm
            ? {
                number: state !== CO ? medicalCardNumber : '',
                expirationDate,
                state,
              }
            : null,
        },
      };

      const userCreationData = {
        dispensaryId: dispensary?.id,
        embedded: isEmbedded || isStoreFront,
      };
      const { success, error, user } = await consumerSignup(apolloClient, User, Cart, UI, profile, userCreationData);
      if (success) {
        trackAccountSignup(dispensary, user._id, analyticsEventLabel);
        const successMessage = t('signUp.success', 'Success! Your account has been created.');

        if (isMobileEcommApp) {
          callNativeMethod(`onAuthMessage`, { isSuccess: true, message: successMessage });
        } else {
          showErnie(successMessage, `success`);
        }
        onClose();
      } else if (error.message) {
        if (isMobileEcommApp) {
          callNativeMethod(`onAuthMessage`, { isSuccess: false, message: error.message });
        } else {
          showErnie(error.message, `danger`);
        }
      } else {
        const errorMessage = t('signUp.fail', 'We are unable to create your account. Please contact support.');
        if (isMobileEcommApp) {
          callNativeMethod(`onAuthMessage`, { isSuccess: false, message: errorMessage });
        } else {
          showErnie(errorMessage, `danger`);
        }
      }
    } catch (err) {
      const errorMessage = err.message;
      console.error(errorMessage);
      if (isMobileEcommApp) {
        callNativeMethod(`onAuthMessage`, { isSuccess: false, message: errorMessage });
      } else {
        showErnie(errorMessage, `danger`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} mode='onSubmit' validationSchema={signUpFormValidation(t, showMedicalCardForm)}>
      <FormContainer>
        <InlineContainer>
          <InputContainer width='50%' mr={15}>
            <FormTextInput
              name='firstName'
              label={t('signUp.firstName', 'First Name')}
              variant='filled'
              autoComplete='given-name'
              data-testid='sign-up-firstname'
              required
            />
          </InputContainer>

          <InputContainer width='50%' ml={15}>
            <FormTextInput
              name='lastName'
              label={t('signUp.lastName', 'Last Name')}
              variant='filled'
              autoComplete='family-name'
              data-testid='sign-up-lastname'
              required
            />
          </InputContainer>
        </InlineContainer>

        <InputContainer mt={18}>
          <FormFormattedInput
            format='phone'
            name='phoneNumber'
            label={t('signUp.phone', 'Mobile Phone Number')}
            variant='filled'
            autoComplete='tel'
            data-testid='sign-up-phone'
            required
          />
        </InputContainer>

        <InputContainer onBlur={ageCheck} mt={18}>
          <FormFormattedInput
            format='date'
            name='birthday'
            label={t('signUp.birthday', 'Birthday')}
            variant='filled'
            autoComplete='bday'
            data-testid='sign-up-birthday'
            required
          />
        </InputContainer>

        <InputContainer mt={18}>
          <FormTextInput
            name='email'
            label={t('signUp.email', 'Email Address')}
            variant='filled'
            autoComplete='email'
            type='email'
            data-testid='sign-up-email'
            required
          />
        </InputContainer>

        <InputContainer mt={18}>
          <FormTextInput
            name='password'
            label={t('signUp.password', 'Password')}
            type='password'
            variant='filled'
            autoComplete='new-password'
            data-testid='sign-up-password'
            required
          />
        </InputContainer>
      </FormContainer>

      {showMedicalCardForm && <MedicalCardForm />}

      <LoginOptionsContainer>
        <SecondaryOptionTextContainer>
          {!isMobileEcommApp && (
            <AlreadyHaveAnAccountText larger>
              {t('signUpModal.alreadyHaveAnAccount', 'Already have an account? ')}
              <LoginText
                margin={0}
                padding={0}
                larger
                blueText
                onClick={handleOpenLoginModal}
                type='button'
                data-cy='login-link'
                data-testid='sign-up-login-link'
              >
                {t('signUpModal.loginText', 'Log in')}
              </LoginText>
            </AlreadyHaveAnAccountText>
          )}
        </SecondaryOptionTextContainer>
        <StyledButton data-testid='sign-up-submit-button' type='submit' variant='flora' loading={isLoading}>
          {t('signUpModal.signUpText', 'Sign Up')}
        </StyledButton>
      </LoginOptionsContainer>
    </Form>
  );
};

export default SignUpForm;

const SecondaryOptionTextContainer = styled.div`
  display: flex;
`;

const InlineContainer = styled.div`
  display: flex;

  ${space}
`;

const FormContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  ${width}
  ${space}
`;

const AlreadyHaveAnAccountText = styled.p`
  color: ${({ theme }) => theme.colors.grey[45]};
  text-align: initial;
  line-height: 18.6px;
  ${paragraph};
  ${bold};
`;

export const StyledButton = styled(Button)`
  width: 95px;
  background-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;
