import React from 'react';
import styled, { css } from 'styled-components';
import { MenuTypes } from 'types/graphql';

import useTranslation from 'hooks/use-translation';

import GreenCheckmarkBadge from 'assets/green-check-mark-badge';
import { MedIcon } from 'assets/med-icon';
import { RecIcon } from 'assets/rec-icon';
import { BottomDrawer, BottomDrawerProps } from 'components/core/bottom-drawer';
import { Button } from 'components/core';
import { Clickable } from 'shared/components';

export type StoreTypeBottomDrawerProps = Omit<BottomDrawerProps, 'children' | 'heading' | 'subheading'> & {
  medSelected: boolean;
  onSave: () => void;
  onSelect: (storeType: MenuTypes) => void;
  recSelected: boolean;
};

export function StoreTypeBottomDrawer({
  medSelected,
  onClose,
  onSave,
  onSelect,
  open,
  recSelected,
}: StoreTypeBottomDrawerProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <BottomDrawer open={open} onClose={onClose} heading={t('store-type', 'Store Type')}>
      <ButtonsContainer>
        <StoreTypeButton
          aria-label={medSelected ? t('unselect-medical', 'Unselect medical') : t('select-medical', 'Select medical')}
          selected={medSelected}
          onClick={() => onSelect(MenuTypes.medical)}
        >
          {medSelected && <StyledCheckmarkBadge alt={t('medical-selected', 'Medical is selected')} />}
          <MedIcon />
          <StoreTypeCopy>{t('medical', 'Medical')}</StoreTypeCopy>
        </StoreTypeButton>
        <StoreTypeButton
          aria-label={
            recSelected
              ? t('unselect-recreational', 'Unselect recreational')
              : t('select-recreational', 'Select recreational')
          }
          selected={recSelected}
          onClick={() => onSelect(MenuTypes.recreational)}
        >
          {recSelected && <StyledCheckmarkBadge alt={t('recreational-selected', 'Recreational is selected')} />}
          <RecIcon />
          <StoreTypeCopy>{t('recreational', 'Recreational')}</StoreTypeCopy>
        </StoreTypeButton>
      </ButtonsContainer>
      <Button fontSize={13} size='large' onClick={onSave}>
        {t('save', 'Save')}
      </Button>
    </BottomDrawer>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  margin: 25px 0;
`;

const StoreTypeButton = styled(Clickable)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey[90]};
  padding: 23px 0;
  :first-of-type {
    margin-right: 23px;
  }
  :last-child {
    svg {
      margin-left: 12px;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[95]};
    `}
`;

const StyledCheckmarkBadge = styled(GreenCheckmarkBadge)`
  position: absolute;
  right: 6px;
  top: 6px;
`;

const StoreTypeCopy = styled.span`
  margin-top: 11px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[35]};
`;
