/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export function CarIcon(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='152' height='80' viewBox='0 0 152 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M118.023 75.3213H31.3167C18.9435 75.3213 13.1035 76.2513 13.1035 77.3963C13.1035 78.54 13.649 80 27.8149 80L37.5163 79.9788V80H90.1538V79.9663L121.906 80C137.611 80 138.216 78.5463 138.216 77.3988C138.216 76.25 131.738 75.3213 118.023 75.3213Z'
        fill='#E3E6E7'
      />
      <path
        d='M152 56.19H144.889V18.975L138.528 13.5562V0.94625H130.472V8.3625L118.8 0.20625L95.2044 18.9025V28.0563L87.4288 45.0025L87.7489 35.8775V11L69.2809 0L51.9936 10.4213V32.1087L55.6862 51.455L53.3061 48.1288L44.4162 50.7275V13.9725L24.59 0.22125L15.2483 8.94375V1.0125H7.91225V14.4413L5.33517 17.1688V56.19H0V60.0925H29.429L41.7517 65.555H65.7778L61.8685 60.0925H151.999L152 56.19Z'
        fill='#E3E6E7'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60.1438 24.4451H66.7066V17.7788H60.1438V24.4451ZM73.2656 24.4451H79.8284V17.7788H73.2656V24.4451ZM133.41 32.2213H123.569V23.3338H133.41V32.2213ZM125.757 38.8888H113.727V45.1113L125.757 46.6663V38.8888ZM28.432 48.8876H19.6837V34.4451H28.432V48.8876ZM106.071 32.2213H115.914L115.913 23.3338H106.071V32.2213ZM14.2168 18.8888H20.7771V25.5551H14.2168V18.8888ZM34.9948 18.8888H28.4321V25.5551H34.9948V18.8888Z'
        fill='white'
      />
      <path
        d='M134.579 52.7075C134.09 49.94 132.29 47.2963 124.962 46.5238L112.656 44.2038C110.755 44.2038 108.225 43.9188 107.467 42.2638C105.017 36.945 97.7526 25.2 94.0144 25.2L35.7843 24.1038C36.0109 25.4325 37.2299 26.4138 38.668 26.4238H41.8915C39.7183 28.1788 24.7902 42.6575 24.7902 46.5238V64.3038C24.7705 66.8475 26.9572 68.9238 29.6772 68.9425H131.476C132.334 68.9424 133.16 68.6159 133.791 68.0275C134.402 67.4475 134.74 66.665 134.734 65.85V54.2538C134.726 53.7348 134.674 53.2175 134.579 52.7075Z'
        fill='white'
      />
      <path d='M108.613 44.6951L96.9851 29.9126L76.9619 29.7976L83.8239 44.6951H108.613Z' fill='#C9CDCF' />
      <path
        d='M128.675 65C128.821 68.722 126.957 72.2291 123.814 74.15C120.681 76.0582 116.767 76.0582 113.635 74.15C110.491 72.2291 108.628 68.722 108.774 65'
        fill='#153F66'
      />
      <path
        d='M118.614 75.8089C112.332 75.6914 107.327 70.3964 107.424 63.9689C107.424 63.2689 107.978 62.7002 108.664 62.7002C109.349 62.7002 109.904 63.2677 109.904 63.9689C109.76 67.2374 111.388 70.3243 114.146 72.0164C116.895 73.6972 120.334 73.6972 123.083 72.0164C125.841 70.3243 127.468 67.2374 127.324 63.9689C127.324 63.2689 127.88 62.7002 128.564 62.7002C129.25 62.7002 129.804 63.2677 129.804 63.9689C129.903 70.3964 124.896 75.6914 118.614 75.8089Z'
        fill='#153F66'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M108.776 65C108.092 65 107.536 65.5687 107.536 66.2687C107.439 72.6962 112.444 77.5537 118.726 77.6712C125.01 77.5537 130.015 72.6962 129.917 66.2687C129.917 65.5675 129.362 65 128.678 65C127.992 65 127.438 65.5687 127.438 66.2687C127.581 69.5375 125.953 72.6245 123.195 74.3162C120.446 75.997 117.007 75.997 114.258 74.3162C111.5 72.6241 109.873 69.5372 110.016 66.2687C110.016 65.5675 109.461 65 108.776 65ZM72.0999 75.4C75.2436 73.4791 77.1068 69.972 76.961 66.25H57.0597C56.9139 69.972 58.7771 73.4791 61.9208 75.4C65.0535 77.3082 68.9672 77.3082 72.0999 75.4Z'
        fill='#153F66'
      />
      <path
        d='M55.5356 66.2687C55.5356 65.5687 56.0897 65 56.7755 65C57.4601 65 58.0154 65.5675 58.0154 66.2687C57.8718 69.5372 59.4989 72.6241 62.2572 74.3162C65.006 75.997 68.445 75.997 71.1939 74.3162C73.9521 72.6241 75.5792 69.5372 75.4356 66.2687C75.4356 65.5687 75.991 65 76.6756 65C77.3602 65 77.9155 65.5675 77.9155 66.2687C78.014 72.6962 73.0075 77.5537 66.7255 77.6712C60.4435 77.5537 55.4383 72.6962 55.5356 66.2687Z'
        fill='#153F66'
      />
      <path
        d='M113.067 57.804H134.312C134.312 57.804 136.143 68.7127 132.193 69.0752C128.243 69.4377 114.206 69.2927 114.206 69.2927L111.123 69.1314C111.123 69.1314 113.068 69.4389 113.068 66.2189V62.6939'
        fill='#E3E6E7'
      />
      <path
        d='M24.7907 54.1015V52.2352C24.7907 52.0977 112.415 58.1915 112.415 57.864C112.415 50.8465 113.565 61.9277 113.565 61.9277C113.565 61.9277 115.076 68.0827 112.863 69.0402C110.648 69.9965 70.4641 69.5302 70.4641 69.5302H29.7983C28.0783 69.2508 26.4333 68.6151 24.9655 67.6627C23.1309 66.2627 24.3807 61.359 24.3807 61.359C24.3807 61.359 23.7047 56.7465 23.9682 54.724C24.6171 49.644 24.7907 54.1015 24.7907 54.1015Z'
        fill='#E3E6E7'
      />
      <path
        d='M104.769 37.5063H106.332C107.407 37.5063 108.281 38.0751 108.281 38.7751C108.281 39.4751 107.41 40.0439 106.334 40.0439'
        fill='#153F66'
      />
      <path
        d='M107.159 42.4839C106.829 42.4826 106.513 42.3482 106.281 42.1102C106.048 41.8723 105.918 41.5503 105.919 41.2152C105.919 40.5152 106.474 39.9464 107.159 39.9464H105.596C105.265 39.9454 104.949 39.8111 104.716 39.5732C104.483 39.3352 104.353 39.013 104.355 38.6777C104.355 37.9777 104.91 37.4102 105.594 37.4102H107.159C108.947 37.4102 110.348 38.5227 110.348 39.9477C110.348 41.3702 108.947 42.4839 107.159 42.4839Z'
        fill='#153F66'
      />
      <path
        d='M102.605 36.0689C96.8019 32.0851 93.9527 32.0564 98.3619 35.8151C100.677 37.7901 101.342 39.6701 92.5748 35.8151C88.5522 34.0451 83.6197 33.1326 87.4896 36.1214C91.3571 39.1089 80.8936 36.0639 80.8936 36.0639L80.1758 37.3814C82.8717 38.7195 85.7887 39.5383 88.7788 39.7964C93.9367 40.2951 90.2502 36.6164 94.1189 39.1051C97.9876 41.5951 104.975 41.1776 104.975 39.6826'
        fill='white'
      />
      <path
        d='M115.721 64.1464C116.406 64.1464 116.961 63.3892 116.961 62.4552C116.961 61.5211 116.406 60.7639 115.721 60.7639C115.037 60.7639 114.481 61.5211 114.481 62.4552C114.481 63.3892 115.037 64.1464 115.721 64.1464Z'
        fill='white'
      />
      <path
        d='M115.721 65.4151C117.091 65.4151 118.201 64.0899 118.201 62.4551C118.201 60.8204 117.091 59.4951 115.721 59.4951C114.351 59.4951 113.241 60.8204 113.241 62.4551C113.241 64.0899 114.351 65.4151 115.721 65.4151Z'
        fill='white'
      />
      <path
        d='M114.962 55.4614L108.507 54.4189C106.906 54.1614 105.768 52.9364 105.962 51.6826L106.128 50.6101L115.481 52.1189C116.395 52.2564 117.025 53.1251 116.891 54.0589C116.819 54.5049 116.575 54.9034 116.213 55.1665C115.851 55.4297 115.401 55.5357 114.962 55.4614Z'
        fill='#FF3E51'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M104.641 51.9638C104.473 53.9225 106.201 55.6813 108.495 55.885H108.494L112.402 56.2325C112.98 56.2825 113.845 56.27 115 56.195C115.768 56.2601 116.53 56.0128 117.118 55.5076C117.706 55.0024 118.072 54.2807 118.136 53.5013C118.277 51.8738 117.337 50.7538 115.746 50.6125C114.182 50.2813 113.068 50.085 112.402 50.0263C110.973 49.8988 108.863 49.8 106.076 49.7275C105.395 49.67 104.795 50.185 104.735 50.8825L104.641 51.9638ZM108.708 53.3575C108.04 53.3638 107.425 52.98 107.125 52.3675L115.287 53.0925C115.515 53.1137 115.683 53.3184 115.662 53.55C115.653 53.6614 115.601 53.7646 115.517 53.8368C115.433 53.909 115.324 53.9444 115.214 53.935L108.708 53.3575Z'
        fill='#FF3E51'
      />
      <path
        d='M134.321 52.8127V54.8439H131.669C130.687 54.9032 129.842 54.1477 129.775 53.1514C129.846 52.1514 130.691 51.3977 131.669 51.4602H134.177C134.266 51.9064 134.313 52.3602 134.321 52.8139V52.8127Z'
        fill='#FF3E51'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M131.669 56.1127H134.321V56.1114C135.007 56.1114 135.561 55.5439 135.561 54.8439V52.8139C135.554 52.2789 135.498 51.7464 135.396 51.2214C135.283 50.6239 134.772 50.1914 134.177 50.1914H131.669C130.578 50.1046 129.532 50.6496 128.965 51.6002C128.394 52.5536 128.394 53.7504 128.965 54.7039C129.532 55.6545 130.578 56.1995 131.669 56.1127ZM131.015 53.1514C131.015 52.9526 131.295 52.7289 131.669 52.7289H133.081V52.8126V53.5751H131.669C131.295 53.5751 131.015 53.3514 131.015 53.1514Z'
        fill='#FF3E51'
      />
      <path
        d='M131.427 64.1464C132.112 64.1464 132.667 63.3892 132.667 62.4552C132.667 61.5211 132.112 60.7639 131.427 60.7639C130.743 60.7639 130.188 61.5211 130.188 62.4552C130.188 63.3892 130.743 64.1464 131.427 64.1464Z'
        fill='white'
      />
      <path
        d='M131.427 65.4151C132.797 65.4151 133.907 64.0899 133.907 62.4551C133.907 60.8204 132.797 59.4951 131.427 59.4951C130.058 59.4951 128.947 60.8204 128.947 62.4551C128.947 64.0899 130.058 65.4151 131.427 65.4151Z'
        fill='white'
      />
      <path
        d='M26.7123 51.0313C28.0766 51.1525 29.2562 50.4025 29.3461 49.355C29.4359 48.3075 28.4029 47.3613 27.0386 47.24L25.1449 47.0713L24.8174 50.8625L26.7123 51.0313Z'
        fill='#153F66'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.7089 52.1264L26.6027 52.2951H26.6039C28.6811 52.4801 30.4283 51.2364 30.5809 49.4639C30.7336 47.6926 29.2253 46.1614 27.1469 45.9764L25.2532 45.8076C24.9269 45.7789 24.6006 45.8839 24.3482 46.1001C24.0957 46.3176 23.9381 46.6264 23.9098 46.9626L23.5823 50.7539C23.5203 51.4486 24.0246 52.063 24.7089 52.1264ZM26.8205 49.7676L26.1618 49.7089L26.2714 48.4451L26.9289 48.5039C27.696 48.5714 28.1294 49.0226 28.1109 49.2451C28.0912 49.4676 27.5876 49.8351 26.8205 49.7676Z'
        fill='#153F66'
      />
      <path
        d='M77.2824 29.1289H46.9188C45.7392 29.1289 44.6311 29.6539 43.9342 30.5439C42.6561 32.0852 34.2969 43.8189 34.2969 43.8189H83.8944'
        fill='#E3E6E7'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M97.5596 69.3488C97.5596 69.9788 98.1149 70.4888 98.8007 70.4888H131.841C133.895 70.4863 135.558 68.955 135.561 67.0675V54.9C135.561 54.8775 135.542 52.5188 135.386 51.6688C135.379 51.6325 135.371 51.5967 135.361 51.5613C134.49 48.5888 132.277 47.1725 126.56 45.9413L109.786 42.8038C109.392 42.7213 109.049 42.4988 108.832 42.1863C107.406 40.0996 105.15 36.9226 102.95 33.8236L102.809 33.625C101.603 31.9284 100.434 30.5186 99.4497 29.3314C98.6507 28.3677 97.9734 27.5508 97.4968 26.8463C96.6706 25.6175 95.209 24.8725 93.6392 24.8775L42.3942 23.5413C42.2818 23.5413 42.6689 23.5551 43.3943 23.581C47.0791 23.7127 59.4919 24.1561 59.4919 24.6825C59.4919 25.3125 60.0472 25.8225 60.7318 25.8225L93.6392 27.1588C94.3533 27.1538 95.0194 27.4938 95.395 28.0525C95.8797 28.7685 96.5638 29.5942 97.369 30.5659C98.3575 31.7591 99.5284 33.1723 100.736 34.8725L100.84 35.0189C103.047 38.1268 105.314 41.3189 106.738 43.4C107.313 44.2325 108.225 44.8213 109.274 45.0325L126.029 48.17C131.688 49.3888 132.476 50.5225 132.949 52.0975C133.039 52.68 133.081 54.4288 133.081 54.9V67.0675C133.081 67.6975 132.526 68.2075 131.841 68.2075H98.8007C98.1149 68.2075 97.5596 68.7188 97.5596 69.3488ZM57.8555 50.615H63.6426C64.3284 50.615 64.8825 50.0462 64.8825 49.3462C64.8825 48.645 64.3284 48.0775 63.6438 48.0775H57.8567C57.1709 48.0775 56.6168 48.6462 56.6168 49.3462C56.6141 50.0437 57.1685 50.6115 57.8555 50.615Z'
        fill='#153F66'
      />
      <path
        d='M76.2063 31.0627C75.901 31.0688 75.6063 30.9487 75.3899 30.73C75.1735 30.5113 75.054 30.2126 75.0587 29.9027C75.0587 29.2614 75.5402 28.7427 76.2063 28.7427H97.5704C98.2352 28.7427 98.7746 29.2614 98.7746 29.9027C98.7746 30.5427 98.2352 31.0614 97.5704 31.0614H76.2063V31.0627Z'
        fill='#153F66'
      />
      <path
        d='M7.10449 64.9913C7.52008 64.249 8.02284 63.5606 8.60174 62.9413C9.20836 62.2694 9.94091 61.7272 10.7565 61.3463C11.7393 60.896 12.8626 60.8824 13.8556 61.3088C14.6806 61.6963 15.444 62.2088 16.1175 62.8313C17.2084 63.8563 18.3979 64.7663 19.6673 65.5488C21.0414 66.2914 22.4804 66.9032 23.9657 67.3763C22.3244 67.8783 20.5747 67.8848 18.9298 67.395C17.2936 66.8554 15.7919 65.9624 14.5279 64.7775C14.0215 64.3017 13.4733 63.8737 12.8903 63.4988C12.4229 63.2208 11.8699 63.1303 11.3401 63.245C9.87243 63.6725 8.45275 64.2575 7.10449 64.9913H7.10449Z'
        fill='#C9CDCF'
      />
      <path
        d='M1.64453 65.8376C2.77608 66.3539 3.93473 66.8039 5.11676 67.1839C6.0951 67.4691 7.13038 67.4864 8.11741 67.2339C8.6284 67.0926 9.12707 66.9064 9.60604 66.6764L10.0148 66.4851C10.1958 66.4014 10.3817 66.3214 10.5652 66.2464C10.7585 66.1839 10.9592 66.1314 11.1538 66.0764C11.3483 66.0226 11.5662 66.0239 11.7657 66.0014C12.5672 65.9607 13.3587 66.1963 14.0116 66.6701C14.6014 67.0889 15.0508 67.6826 15.2983 68.3701C14.3206 67.7933 13.1525 67.6495 12.0674 67.9726C11.8555 68.0618 11.6502 68.1662 11.453 68.2851C11.3557 68.3601 11.2609 68.4326 11.1612 68.4951L10.7425 68.7789C10.1209 69.1856 9.44095 69.4922 8.7269 69.6877C7.2256 70.0742 5.63399 69.8232 4.31889 68.9926C3.13984 68.2325 2.20919 67.1346 1.64453 65.8376Z'
        fill='#C9CDCF'
      />
      <rect x='121.095' y='61.6089' width='4.95965' height='1.69126' rx='0.41712' fill='#677F86' />
      <rect x='119.854' y='60.3401' width='7.43945' height='4.22874' rx='1.74504' fill='#A0A8AB' />
      <path
        d='M29.336 69.2199H20.2441V65.8374H24.3776C24.2645 66.766 24.5672 67.6971 25.2025 68.3749C26.2159 69.4099 30.9896 69.2199 29.3372 69.2199H29.336Z'
        fill='#153F66'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.9019 72.0723C32.0353 72.0813 32.1278 72.0876 32.1622 72.09V72.0887C32.4103 72.1239 32.4633 72.0696 32.5383 71.9929C32.5965 71.9333 32.6679 71.8602 32.8541 71.805C33.359 71.5537 33.3848 70.9262 33.2777 70.3625C33.1127 69.6837 29.5937 69.235 28.914 69.3087L28.3366 69.2687C27.4254 69.205 26.5327 68.9812 25.6979 68.6062C25.3667 68.19 25.2288 67.6475 25.3224 67.1187C25.3987 66.7587 25.3199 66.3825 25.1032 66.0875C24.8877 65.7925 24.5577 65.6087 24.1982 65.5837L20.0746 65.2912C19.3912 65.2425 18.8002 65.7687 18.751 66.4687L19.7557 69.9312C20.5334 71.2659 20.5657 71.268 22.0089 71.3634C22.0646 71.3671 22.1225 71.3709 22.1826 71.375L28.8007 71.8462C29.0209 71.8771 31.136 72.0204 31.9019 72.0723ZM23.0469 68.8937L21.0806 68.755H21.0793L21.1372 67.9112L22.8512 68.0312C22.8878 68.3246 22.9534 68.6136 23.0469 68.8937Z'
        fill='#153F66'
      />
      <path
        d='M38.8428 37.025C57.6224 40.6525 64.4536 41.0738 55.5957 37.9438C46.7378 34.8138 58.0558 34.885 64.4523 37.4963C70.695 40.0438 83.3206 35.7 79.4531 36.9138L78.6565 33.97C78.6565 33.97 71.4904 39.1975 61.9356 34.1238C57.6975 31.8738 46.7846 31.005 47.8459 33.4713C49.6486 37.6663 43.3641 36.6363 40.0433 35.7413'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.1367 66.3201C23.165 68.6438 25.1979 70.5088 27.6839 70.4888V70.4876H110.348C112.401 70.4851 114.066 68.9288 114.069 67.0088V52.3201C114.069 49.0338 111.435 47.3213 103.939 45.7638L84.4819 43.4938C84.42 43.4037 84.197 42.4509 84.0735 41.9236C84.0618 41.8736 84.051 41.8275 84.0414 41.7862C84.0388 41.7265 84.0253 41.6537 83.9931 41.5776C78.5816 28.7876 74.8446 24.1026 71.4955 24.1026L35.537 23.3301C35.1726 23.3301 34.8266 23.4801 34.5902 23.7401C34.3572 23.9921 34.255 24.341 34.3143 24.6813C34.648 26.5801 36.4063 27.9726 38.4626 27.9688H39.0043L25.0021 42.9376C23.8016 44.2213 23.138 45.8688 23.1367 47.5751V66.3201ZM110.348 68.1701H53.3094V68.1713H27.6838C26.5732 68.1801 25.6571 67.3588 25.6177 66.3213V47.5776C25.6242 46.4145 26.0723 45.2987 26.8687 44.4626L42.6698 27.5726C42.9881 27.2425 43.0666 26.7445 42.8656 26.3301C42.6745 25.936 42.2645 25.6758 41.8058 25.6519L71.4955 26.4251C72.4251 26.4251 75.278 28.0588 81.6881 43.2051C81.8009 43.4727 81.8813 43.6764 81.9451 43.8381C81.9537 43.8599 81.962 43.881 81.9701 43.9013C82.1162 44.3783 82.3813 44.8089 82.7396 45.1513C83.0831 45.4888 83.5387 45.7101 84.0312 45.7763L103.466 48.0413C111.589 49.7301 111.589 51.2901 111.589 52.3226V67.0101C111.589 67.6501 111.033 68.1701 110.348 68.1701ZM41.7365 25.6501C41.7597 25.6501 41.7828 25.6507 41.8058 25.6519L41.7365 25.6501Z'
        fill='#153F66'
      />
      <path
        d='M46.1761 68.7976L41.7656 76.8314H48.6695C52.0346 76.8314 54.869 73.4564 55.8183 68.7976'
        fill='#7F9AA2'
      />
      <path
        d='M48.8408 77.4725C46.9606 77.8913 44.3823 78.1 41.1046 78.1C40.6836 78.0936 40.3002 77.8524 40.1072 77.4725C39.9001 77.0788 39.8964 76.607 40.0974 76.21L45.5101 68.1763L47.5245 69.4188L44.3823 75.5625L48.8408 74.935C51.7134 74.0625 53.5837 72.675 54.3668 68.5388L56.6287 69.0563C55.603 74.4638 52.4177 76.8413 48.8408 77.4725Z'
        fill='#153F66'
      />
      <path
        d='M94.6214 69.2014L90.2109 75.9852H97.1148C100.48 75.9852 103.314 73.1352 104.264 69.2014'
        fill='#7F9AA2'
      />
      <path
        d='M98.5276 77.2477C97.6546 77.6652 95.3841 77.8827 91.7173 77.8977C91.2765 77.8977 89.3729 77.6352 89.1501 77.2477C88.9271 76.8584 88.9224 76.3788 89.1378 75.9852L94.9519 67.9514L97.114 69.1927L93.7403 75.3377L98.5276 74.7102C101.612 73.8377 103.621 72.4502 104.462 68.3139L106.89 68.8314C105.79 74.2389 102.369 76.6164 98.5276 77.2477Z'
        fill='#153F66'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.0388 71.0809C83.8965 75.3601 88.108 78.0591 92.6829 77.9014V77.9026C98.7772 77.7885 103.632 72.6525 103.539 66.4171C103.539 61.7338 100.76 57.5189 96.5167 55.7629C92.2709 54.0069 87.3994 55.0626 84.23 58.4255C81.0444 61.8052 80.1794 66.801 82.0388 71.0809ZM82.4321 66.6088C82.4321 72.2775 86.7584 76.8722 92.0936 76.8722C97.4288 76.8722 101.753 72.2763 101.753 66.6088C101.753 60.9401 97.4288 56.3454 92.0936 56.3454C86.7584 56.3454 82.4321 60.9413 82.4321 66.6088Z'
        fill='#153F66'
      />
      <path
        d='M92.3743 74.7025C96.9556 74.7025 100.669 70.7302 100.669 65.83C100.669 60.9299 96.9556 56.9575 92.3743 56.9575C87.793 56.9575 84.0791 60.9299 84.0791 65.83C84.0791 70.7302 87.793 74.7025 92.3743 74.7025Z'
        fill='#A0A8AB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.9005 70.7266C84.605 74.6414 88.488 77.1058 92.7012 76.9468C98.2756 76.8273 102.705 72.1161 102.605 66.4128C102.605 62.1119 100.052 58.2422 96.1523 56.6346C92.2505 55.0279 87.7777 56.0076 84.8755 59.1044C81.9597 62.2151 81.1799 66.804 82.9005 70.7266ZM85.2759 63.1015C86.5881 60.1691 89.5327 58.3492 92.7012 58.5123V58.511C96.8546 58.6292 100.133 62.1613 100.032 66.4115C100.038 69.6393 98.1117 72.5471 95.1648 73.7588C92.2244 74.9578 88.8606 74.2031 86.6919 71.8577C84.5119 69.5018 83.9523 66.0413 85.2759 63.1015Z'
        fill='#153F66'
      />
      <path
        d='M92.178 70.7513C94.7988 70.7513 96.9234 68.5776 96.9234 65.8963C96.9234 63.2149 94.7988 61.0413 92.178 61.0413C89.5572 61.0413 87.4326 63.2149 87.4326 65.8963C87.4326 68.5776 89.5572 70.7513 92.178 70.7513Z'
        fill='white'
      />
      <path
        d='M82.2418 42.4837H75.9303C74.6214 42.4837 73.5625 41.6325 73.5625 40.5812C73.5625 39.53 74.6214 38.6775 75.9303 38.6775H78.2968C79.6044 38.6775 80.6633 39.53 80.6633 40.5812C80.8308 41.0587 81.1004 41.4925 81.4514 41.85C81.6902 42.0925 81.9549 42.305 82.2418 42.4837Z'
        fill='#153F66'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M75.929 43.7525H82.2418V43.7513C82.7971 43.7513 83.2847 43.3738 83.4349 42.8263C83.5851 42.28 83.361 41.6975 82.8882 41.4013C82.6863 41.2763 82.5003 41.1275 82.3341 40.9575C82.1482 40.7688 81.998 40.5488 81.8896 40.305C81.5898 38.5539 80.0447 37.3089 78.2967 37.41H75.929C73.9404 37.41 72.3213 38.8325 72.3213 40.5813C72.3213 42.3288 73.9404 43.7525 75.929 43.7525ZM74.8023 40.5813C74.8023 40.3275 75.2517 39.9463 75.9289 39.9463H78.2967C78.9739 39.9463 79.4233 40.3263 79.4233 40.58C79.4233 40.7238 79.447 40.8667 79.4935 41.0025C79.5189 41.0738 79.5456 41.1447 79.5735 41.215H75.9289C75.2517 41.215 74.8023 40.8338 74.8023 40.5813Z'
        fill='#153F66'
      />
      <path
        d='M43.3905 76.8327C48.8857 76.8327 53.3405 72.0998 53.3405 66.2614C53.3405 60.4231 48.8857 55.6902 43.3905 55.6902C37.8952 55.6902 33.4404 60.4231 33.4404 66.2614C33.4404 72.0998 37.8952 76.8327 43.3905 76.8327Z'
        fill='#BDCCCE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.6583 58.0251C30.3751 61.5086 29.4832 66.6573 31.3989 71.0689L31.4001 71.0676C33.3148 75.4801 37.6551 78.2614 42.3709 78.0989C48.6542 77.9826 53.6594 72.6864 53.5633 66.2601C53.5633 61.4314 50.6981 57.0864 46.3233 55.2764C41.946 53.4676 36.9245 54.5574 33.6583 58.0251ZM33.6891 62.4214C35.2072 58.9659 38.6484 56.8006 42.3721 56.9576C47.285 57.0739 51.1808 61.2339 51.0823 66.2614C51.0894 70.0529 48.8312 73.4701 45.3728 74.9014C41.9216 76.3182 37.9679 75.4453 35.408 72.7014C32.8355 69.9452 32.1566 65.885 33.6891 62.4214Z'
        fill='#153F66'
      />
      <path
        d='M42.37 75.1401C46.9553 75.1401 50.6725 71.1644 50.6725 66.2601C50.6725 61.3558 46.9553 57.3801 42.37 57.3801C37.7846 57.3801 34.0674 61.3558 34.0674 66.2601C34.0674 71.1644 37.7846 75.1401 42.37 75.1401Z'
        fill='#A0A8AB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.8324 59.2202C32.0228 62.2169 31.2712 66.6382 32.9288 70.4177H32.9276C34.57 74.1898 38.3119 76.5641 42.3716 76.4102C47.7412 76.2939 52.0089 71.7552 51.9141 66.2602C51.9141 62.1177 49.4527 58.3889 45.6961 56.8402C41.9371 55.2924 37.6281 56.2364 34.8324 59.2202ZM36.5808 71.5089C34.4803 69.2386 33.9416 65.9038 35.2178 63.0714H35.2165C36.4803 60.2458 39.3174 58.4919 42.3703 58.6489C46.372 58.7626 49.5303 62.1664 49.433 66.2601C49.439 69.3699 47.5834 72.1714 44.7442 73.3389C41.9116 74.4943 38.6708 73.7678 36.5808 71.5089Z'
        fill='#153F66'
      />
      <path
        d='M41.9577 71.3338C44.6968 71.3338 46.9173 69.0622 46.9173 66.26C46.9173 63.4579 44.6968 61.1863 41.9577 61.1863C39.2185 61.1863 36.998 63.4579 36.998 66.26C36.998 69.0622 39.2185 71.3338 41.9577 71.3338Z'
        fill='white'
      />
      <path
        d='M54.7572 44.0114C54.6587 44.0114 54.5615 44.0014 54.4667 43.9814C54.1581 43.9209 53.8836 43.744 53.6983 43.4864C53.5283 43.2465 53.474 42.9415 53.5506 42.6564L57.0634 29.6564C57.2235 29.0676 57.8921 28.7039 58.5582 28.8451C59.2244 28.9864 59.6356 29.5801 59.4755 30.1701L55.9627 43.1689C55.8285 43.6626 55.3298 44.0114 54.756 44.0114H54.7572Z'
        fill='#153F66'
      />
      <path
        d='M83.8947 45.7502L34.2985 44.1315C33.8417 44.1315 33.4206 43.8965 33.2051 43.519C32.9894 43.1455 33.0136 42.6774 33.2667 42.329C33.609 41.849 41.6653 31.3915 42.9569 29.834C43.8877 28.6615 45.3579 27.9702 46.9204 27.9702L77.284 28.7427C77.9686 28.7427 78.5239 29.2615 78.5239 29.9027C78.5239 30.5427 77.9698 31.0615 77.284 31.0615L46.9204 30.289C46.1533 30.2834 45.4246 30.6291 44.9368 31.2302C43.985 32.3765 39.5844 38.389 37.192 41.7352L83.8947 43.4315C84.5793 43.4315 85.1347 43.9502 85.1347 44.5915C85.1347 45.2315 84.5793 45.7502 83.8947 45.7502Z'
        fill='#153F66'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M120.216 55.7389C120.448 55.9769 120.764 56.1113 121.094 56.1126V56.1114C121.78 56.1114 122.334 55.5439 122.334 54.8439V52.3063C122.334 51.6051 121.779 51.0376 121.094 51.0376C120.41 51.0376 119.855 51.6063 119.855 52.3063V54.8439C119.853 55.179 119.983 55.501 120.216 55.7389ZM124.349 55.7389C124.582 55.9769 124.898 56.1113 125.228 56.1126V56.1114C125.914 56.1114 126.468 55.5439 126.468 54.8439V52.3063C126.468 51.6051 125.912 51.0376 125.228 51.0376C124.542 51.0376 123.988 51.6063 123.988 52.3063V54.8439C123.987 55.179 124.116 55.501 124.349 55.7389Z'
        fill='#A0A8AB'
      />
    </svg>
  );
}
