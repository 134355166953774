import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _values from "lodash/values";
import _isNil from "lodash/isNil";
import _forEach from "lodash/forEach";
import _reduce from "lodash/reduce";
import _reject from "lodash/reject";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import { getBogoIndividualDiscount, sortSpecials, isCumulative } from 'shared/order/utilities';

var getRewardsToApply = function getRewardsToApply(applicableBogoRewards, applyStowedDiscounts, stowedDiscounts) {
  if (!applyStowedDiscounts) {
    return _reject(applicableBogoRewards, function (_ref) {
      var isSecpoDtc = _ref.isSecpoDtc;
      return isSecpoDtc;
    });
  }

  return _reduce(stowedDiscounts, function (bogoDiscounts, discount) {
    if (discount.type === 'bogo') {
      bogoDiscounts.push(_objectSpread(_objectSpread({}, discount.discountData), {}, {
        _quantityToApply: discount.quantityToApply || 0
      }));
    }

    return bogoDiscounts;
  }, []);
};

var calculateBogoDiscounts = function calculateBogoDiscounts(_ref2) {
  var applicableBogoRewards = _ref2.applicableBogoRewards,
      applyStowedDiscounts = _ref2.applyStowedDiscounts,
      _ref2$bogoRewardsAppl = _ref2.bogoRewardsApplied,
      bogoRewardsApplied = _ref2$bogoRewardsAppl === void 0 ? {} : _ref2$bogoRewardsAppl,
      compositePrice = _ref2.compositePrice,
      credit = _ref2.credit,
      detail = _ref2.detail,
      dtcIndividualPrice = _ref2.dtcIndividualPrice,
      discountTaxOrder = _ref2.discountTaxOrder,
      originalCompositePrice = _ref2.originalCompositePrice,
      productTotal = _ref2.productTotal,
      receiptDetail = _ref2.receiptDetail,
      globalSpecialsSettings = _ref2.globalSpecialsSettings,
      stowDiscounts = _ref2.stowDiscounts,
      stowedDiscounts = _ref2.stowedDiscounts;
  var quantity = detail.quantity;
  var rewardsToApply = getRewardsToApply(applicableBogoRewards, applyStowedDiscounts, stowedDiscounts);
  rewardsToApply = sortSpecials(rewardsToApply, {});

  if (rewardsToApply.length > 0) {
    var bogoSavings = {
      individual: [],
      total: Big(0)
    };

    _forEach(rewardsToApply, function (bogoReward) {
      var individualPrice;

      if (dtcIndividualPrice) {
        individualPrice = dtcIndividualPrice;
      } else {
        var _ref3, _bogoReward$discountS;

        var stackingBehaviorIsCumulative = isCumulative(bogoReward.stackingBehavior, globalSpecialsSettings.stackingBehavior);
        var startingPrice;
        var discountStackingEnabled = (_ref3 = (_bogoReward$discountS = bogoReward === null || bogoReward === void 0 ? void 0 : bogoReward.discountStacking) !== null && _bogoReward$discountS !== void 0 ? _bogoReward$discountS : globalSpecialsSettings.discountStacking) !== null && _ref3 !== void 0 ? _ref3 : false;
        startingPrice = originalCompositePrice;

        if (discountStackingEnabled && !stackingBehaviorIsCumulative) {
          startingPrice = compositePrice;
        }

        individualPrice = startingPrice.div(detail.quantity);
      }

      var individualDiscount = getBogoIndividualDiscount(bogoReward, individualPrice);
      var applicableQuantity;

      if (bogoReward !== null && bogoReward !== void 0 && bogoReward.isDiscountToCartReward) {
        applicableQuantity = quantity;
      } else if (!_isNil(bogoReward === null || bogoReward === void 0 ? void 0 : bogoReward._quantityToApply)) {
        applicableQuantity = bogoReward._quantityToApply;
      } else {
        applicableQuantity = (bogoReward === null || bogoReward === void 0 ? void 0 : bogoReward.applicableQuantity) || 0;
      }

      var additionalCredit = individualDiscount.times(applicableQuantity);

      if (stowDiscounts) {
        stowedDiscounts.push({
          applicableQuantity: applicableQuantity,
          conditionQuantity: (bogoReward === null || bogoReward === void 0 ? void 0 : bogoReward.conditionQuantity) || 0,
          discountData: bogoReward,
          individualDiscount: individualDiscount,
          key: "".concat(bogoReward.specialId, "_").concat(bogoReward.rewardId),
          type: "bogo"
        });
      } else {
        bogoRewardsApplied["rid_".concat(bogoReward.rewardId)] = (bogoRewardsApplied["rid_".concat(bogoReward.rewardId)] || 0) + applicableQuantity;
        bogoRewardsApplied["sid_".concat(bogoReward.specialId)] = (bogoRewardsApplied["sid_".concat(bogoReward.specialId)] || 0) + applicableQuantity;
        bogoSavings.individual.push(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
          conditionQuantity: (bogoReward === null || bogoReward === void 0 ? void 0 : bogoReward.conditionQuantity) || 0,
          discountAmount: individualDiscount
        }, bogoReward !== null && bogoReward !== void 0 && bogoReward.dollarDiscount ? {
          dollarDiscount: bogoReward.dollarDiscount || 0
        } : {}), bogoReward !== null && bogoReward !== void 0 && bogoReward.percentDiscount ? {
          percentDiscount: bogoReward.percentDiscount || 0
        } : {}), bogoReward !== null && bogoReward !== void 0 && bogoReward.targetPrice ? {
          targetPrice: bogoReward.targetPrice || 0
        } : {}), {}, {
          isDiscountToCartReward: bogoReward === null || bogoReward === void 0 ? void 0 : bogoReward.isDiscountToCartReward,
          displayAsPercentDiscount: bogoReward === null || bogoReward === void 0 ? void 0 : bogoReward.displayAsPercentDiscount,
          maxApplicable: applicableQuantity,
          specialId: bogoReward.specialId,
          stackingBehavior: bogoReward.stackingBehavior,
          nonStackingBehavior: bogoReward.nonStackingBehavior,
          discountStacking: bogoReward.discountStacking
        }));
        bogoSavings.total = bogoSavings.total.add(additionalCredit);
        compositePrice = compositePrice.minus(additionalCredit); // Depending on tax config, add the credit into the total. This is to make the subtotal on the checkout ticket
        // look more like the menu.

        if (discountTaxOrder === 'taxesFirst' || Big(detail.basePriceMult, "Base Price Mult").eq(1)) {
          credit = credit.plus(additionalCredit);
        } else {
          productTotal = productTotal.minus(additionalCredit);
        }

        var specialSettings = {
          discountStacking: bogoReward.discountStacking,
          discountPrecedence: bogoReward.discountPrecedence,
          stackingBehavior: bogoReward.stackingBehavior,
          settingsDerivedFrom: bogoReward.settingsDerivedFrom
        };

        if (receiptDetail && additionalCredit.gt(0)) {
          receiptDetail.addDiscount({
            type: "bogo",
            value: additionalCredit.times(100),
            id: bogoReward.specialId,
            name: bogoReward.specialName,
            additionalAttributes: {
              satisfiedBy: _values(bogoReward.satisfiedBy),
              specialSettings: specialSettings
            }
          });
        }
      }
    });

    if (!stowDiscounts) {
      detail.bogoSavings = bogoSavings;
    }
  }

  return {
    bogoRewardsApplied: bogoRewardsApplied,
    compositePrice: compositePrice,
    credit: credit,
    productTotal: productTotal,
    stowedDiscounts: stowedDiscounts
  };
};

export default calculateBogoDiscounts;