import { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import _ from 'lodash';

import PublicEnv from 'shared/utils/public-env';
import SharedBootHelpers from 'shared/utils/shared-boot-helpers';

const initLogRocket = _.once((adminPreview) => {
  const logrocketId = adminPreview ? PublicEnv.logrocketIdAdmin : PublicEnv.logrocketIdMarketplace;
  SharedBootHelpers.initLogRocket(logrocketId);
});

export function useInitLogRocket(UI) {
  const isCheckoutPage = useObserver(() => UI.isCheckoutPage);
  const isIframeSDK = useObserver(() => UI.isIframeSDK);
  const previewMode = useObserver(() => UI.previewMode);
  const isDutchieMain = useObserver(() => UI.isDutchieMain);
  const isKiosk = useObserver(() => UI.isKiosk);
  const isStoreFront = useObserver(() => UI.isStoreFront);

  useEffect(() => {
    if (isCheckoutPage) {
      return;
    }
    if (isDutchieMain || isKiosk || isStoreFront) {
      initLogRocket(false);
    } else if (isIframeSDK) {
      initLogRocket(previewMode);
    }
  }, [isIframeSDK, isDutchieMain, isKiosk, isStoreFront, previewMode]);
}
