import React from 'react';

import useViewport from 'hooks/use-viewport';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { ModalObserver } from '../modal-observer';
import { SignUpModalContainer } from './sign-up-modal-container';

export const SignUp = ({ name }: { name: string }): JSX.Element => {
  const { parentOffset } = useViewport();
  const additionalMobileCSS = `margin-top: -${parentOffset as number}px;`;

  return (
    <ModalObserver name={name}>
      {(props) => (
        <ModalWrapper {...props}>
          <SignUpModalContainer {...props} additionalMobileCSS={additionalMobileCSS} />
        </ModalWrapper>
      )}
    </ModalObserver>
  );
};
