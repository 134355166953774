import React from 'react';

import EmptyGoodsIcon from 'assets/empty-goods-icon';
import useTranslation from 'hooks/use-translation';
import useProductQueryParams from 'hooks/use-product-query-params';
import { useDispensaryCategory } from 'hooks/use-dispensary-category';

import { EmptyStateContainer, StyledHeader, StyledSubtext, StyledTextButton } from './empty-state-shared-styles';

const CategoryPageEmptyState = () => {
  const { t } = useTranslation();
  const category = useDispensaryCategory();
  const { resetQueryParams } = useProductQueryParams();

  const headerText = category
    ? `We're sorry, no products in this category are currently available.`
    : `We couldn't find what you're looking for`;

  const subText = category
    ? `View all available product categories below.`
    : `There aren't any products that match your search. Try again!`;

  return (
    <EmptyStateContainer>
      <EmptyGoodsIcon />
      <StyledHeader>{t('categoryPageEmptyState.header', headerText)}</StyledHeader>
      <StyledSubtext>{t('categoryPageEmptyState.subtext', subText)}</StyledSubtext>

      {!category && (
        <StyledTextButton onClick={resetQueryParams}>
          {t('categoryPageEmptyState.clearYourSearch', 'Clear your search')}
        </StyledTextButton>
      )}
    </EmptyStateContainer>
  );
};

export default CategoryPageEmptyState;
