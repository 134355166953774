import React from 'react';

type SvgWarningProps = {
  width?: number;
  height?: number;
};

const UnsupportedBankAccount = ({ width = 43, height = 42 }: SvgWarningProps): JSX.Element => (
  <svg width={width} height={height} viewBox='0 0 43 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>Unsupported Bank Account</title>
    <g clipPath='url(#a)' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'>
      <path
        d='M21.17 40.616c10.94 0 19.809-8.87 19.809-19.81S32.11.996 21.169.996C10.23.997 1.36 9.867 1.36 20.807s8.869 19.81 19.81 19.81ZM7.15 34.825 35.19 6.787'
        stroke='#E5E8EA'
      />
      <path
        d='M12.333 30h18M15.333 26v-7M19.333 26v-7M23.333 26v-7M27.333 26v-7M21.333 10l8 5h-16l8-5Z'
        stroke='#5E6D79'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' transform='translate(.5)' d='M0 0h42v42H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default UnsupportedBankAccount;
