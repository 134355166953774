import { DispensarySortByType, useDispensarySearchQuery } from 'types/graphql';
import { useMarketplaceQueryParams } from 'shop/hooks/use-marketplace-query-params';
import { MENU_TYPE_MED, MENU_TYPE_REC, ORDER_TYPE_PICKUP } from 'shared/constants';
import useCart from 'hooks/use-cart';
import { useSortDispensariesByOpenStatus } from 'src/hooks/use-sort-dispensaries-by-open-status';
import { UseMarketplaceDispensariesReturn, UseDispensarySearchParamsReturn, Dispensary } from './select-store.types';

export function useMarketplaceDispensaries(): UseMarketplaceDispensariesReturn {
  const dispensarySearchParams = useDispensarySearchParams();
  const { data, loading } = useDispensarySearchQuery(dispensarySearchParams);
  const unsortedDispensaries = data?.filteredDispensaries ?? [];
  const sortedDispensaries = useSortDispensariesByOpenStatus(unsortedDispensaries);

  return { dispensaries: sortedDispensaries as Dispensary[], loading };
}

function useDispensarySearchParams(): UseDispensarySearchParamsReturn {
  const { currentAddress, orderType, menuType } = useCart();
  const { queryParams } = useMarketplaceQueryParams();

  const commonFilters = {
    medical: menuType === MENU_TYPE_MED,
    recreational: menuType === MENU_TYPE_REC,
    activeOnly: true,
    city: currentAddress?.city,
    country: currentAddress?.countryLong,
    nearLat: currentAddress?.lat,
    nearLng: currentAddress?.lng,
    destinationTaxState: currentAddress?.state,
    destinationTaxZipcode: currentAddress?.zipcode,
  };

  const pickupFilters = {
    ...commonFilters,
    sortBy: DispensarySortByType.distance,
    distance: queryParams.distance,
    offerPickup: true,
  };

  const deliveryFilters = {
    ...commonFilters,
    sortBy: DispensarySortByType.deliveryEstimate,
    deliversToAddress: true,
    distance: 50,
  };

  const dispensaryFilter = orderType === ORDER_TYPE_PICKUP ? pickupFilters : deliveryFilters;

  return { skip: !currentAddress?.lat || !currentAddress?.lng, variables: { dispensaryFilter } };
}
