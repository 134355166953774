import React from 'react';
import Link from 'next/link';

export default function BetterLink(props) {
  const { href, route, children, scroll = true, prefetch, ...urlProps } = props;
  const linkProps = {
    prefetch,
    scroll,
  };

  return (
    <Link
      href={{
        ...urlProps,
        pathname: route || href,
      }}
      as={{
        ...urlProps,
        pathname: href,
      }}
      passHref
      {...linkProps}
    >
      {children}
    </Link>
  );
}
