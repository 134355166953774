import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { bold } from 'components/core/typography';

import { Clickable } from 'shared/components';
import { DispensaryInfo } from '../../components/dispensary-info';
import { StoreOptionsProps } from '../../select-store.types';

export function StoreOptions({ dispensaries, selectedStore, handleSelect }: StoreOptionsProps): JSX.Element {
  const { t } = useTranslation();

  const dispensariesToDisplay = selectedStore ? dispensaries.filter(({ id }) => id !== selectedStore.id) : dispensaries;

  return (
    <Container>
      <Header>{selectedStore ? t('another-store', 'Select Another Store') : t('other-stores', 'Other Stores')}</Header>
      <Dispensaries>
        {dispensariesToDisplay.map((dispensary) => (
          <StyledListItem key={dispensary.id}>
            <Clickable onClick={() => handleSelect(dispensary)}>
              <DispensaryInfo dispensary={dispensary} />
            </Clickable>
          </StyledListItem>
        ))}
      </Dispensaries>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 20px;
  overflow-y: auto;
`;

const Header = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[45]};
  ${bold};
  text-align: left;
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  padding: 27px 0 25px;
  justify-content: space-between;
  align-items: center;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  }
`;

const Dispensaries = styled.div`
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;
