import React from 'react';
import { useRouter } from 'next/router';

import EmptyGoodsIcon from 'assets/empty-goods-icon';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import { useCheckAgeVerification } from 'dispensary/core-menu/age-verification-gate/hooks';
import useUser from 'hooks/use-user';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { changeNativeTabToShop } from 'src/mobile-ecomm/hooks/use-mobile-native-bridge';

import ROUTES from 'src/routes';
import { EmptyStateContainer, StyledHeader, StyledTextButton } from './empty-state-shared-styles';

const { DISPENSARIES, EMBEDDED_MENU, STORES, HOME } = ROUTES;

function getDispensaryMenuRoute(isEmbedded, isStoreFront, cName) {
  if (isEmbedded) {
    return `${EMBEDDED_MENU}/${cName}`;
  }
  if (isStoreFront) {
    return `${STORES}/${cName}`;
  }
  return HOME;
}

const OrdersEmptyState = () => {
  const { t } = useTranslation();
  const {
    replace,
    push,
    query: { cName },
  } = useRouter();
  const { dispensary } = useDispensary();
  const user = useUser();

  const { isEmbedded, isStoreFront, isMobileEcommApp } = useUI();
  const shouldRouteToDispensaryMenu = isEmbedded || isStoreFront;
  const linkText =
    shouldRouteToDispensaryMenu || isMobileEcommApp
      ? t('accountsPage.viewDispensaryMenu', 'Start shopping items.')
      : t('accountsPage.findADispensary', 'Find a dispensary');

  const { isAgeVerified, loading } = useCheckAgeVerification({ dispensary, userId: user?.id });
  const hideRouteToMenuButton = !loading && !isAgeVerified;

  const handleDispensaryNavigation = () => {
    if (shouldRouteToDispensaryMenu) {
      replace(getDispensaryMenuRoute(isEmbedded, isStoreFront, cName));
      return;
    }

    if (isMobileEcommApp) {
      changeNativeTabToShop();
      return;
    }

    push(DISPENSARIES, DISPENSARIES);
  };

  return (
    <EmptyStateContainer>
      <EmptyGoodsIcon />
      <StyledHeader>{t('accountsPage.noPastOrders', 'You have no past orders!')}</StyledHeader>
      {!hideRouteToMenuButton && (
        <StyledTextButton mb={53} onClick={handleDispensaryNavigation}>
          {linkText}
        </StyledTextButton>
      )}
    </EmptyStateContainer>
  );
};

export default OrdersEmptyState;
