import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { Link } from 'components/core';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { PLAID_TERMS_URL } from '../constants';

export function LegalFooter(): JSX.Element {
  const { t } = useTranslation();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { dispensary } = DutchiePayEnrollment;

  return (
    <FooterContainer>
      <>
        {t(
          'dutchiePay.moreInfo',
          `{{company}} uses {{aggregator}} to connect with your bank. By creating an account, you agree to these`,
          {
            aggregator: 'Plaid',
            company: dispensary?.useWhiteLabel ? dispensary.brandedDutchiePayName : `Dutchie`,
          }
        )}
        <Link href={PLAID_TERMS_URL}>
          <LinkText href={PLAID_TERMS_URL} target='_blank' aria-label='terms'>
            {t('common.moreInfo', 'terms')}
          </LinkText>
        </Link>
      </>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme?.colors?.grey[30]};
  font-size: 13px;
  margin-top: 20px;
  line-height: 20px;
`;

const LinkText = styled.a`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  margin-left: 3px;
`;
