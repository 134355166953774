import { tracker } from 'src/utils/analytics';

type UseHeaderReturn = {
  trackEvent: (label: string) => void;
};

export const useHeader = (): UseHeaderReturn => {
  const trackEvent = (label: string): void => {
    tracker.gaGTMClickEvent({
      eventCategory: 'navigation click',
      eventAction: 'main menu',
      eventLabel: label,
    });
  };

  return {
    trackEvent,
  };
};
