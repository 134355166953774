import styled from 'styled-components';

import GreenCheck from 'assets/green-check';
import { GreyCapsText } from 'components/core';

// When the text-input is focused we hide the distance slider
// and the "show results" button. While they're hidden,
// we still want to be able to reach them via keyboard.
// Using CSS opacity: 0 & max-height: 0 to hide the contained elements
// means they'll still be rendered and can be accessed via the keyboard.
export const CollapsibleSection = styled.div`
  max-height: ${({ collapsed }) => (collapsed ? `0` : `none`)};
  * {
    opacity: ${({ collapsed }) => (collapsed ? 0 : 1)};
  }
`;

export const StyledGreenCheck = styled(GreenCheck)`
  &&& {
    min-width: 11px;
  }
`;

export const ContentWrapper = styled.div`
  padding-top: 22px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 366px;
  }
`;

export const SectionContainer = styled.div`
  padding: 30px 31px 30px 30px;
  .MuiSlider-root {
    padding-top: 15px;
    padding-bottom: 18px;
  }
`;

export const StyledGreyCapsText = styled(GreyCapsText)`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.grey[70]};
`;
