import { useMemo } from 'react';
import { useGetRecommendedProductsQuery } from 'types/graphql';
import { Product, parseProduct } from 'src/utils/helpers/product';
import { ApolloError } from 'apollo-client';

type UseRecommendedProductsParams = {
  dispensaryId: string;
};

type UseRecommendedProductsReturn = {
  error?: ApolloError;
  loading: boolean;
  products: Product[];
};

export function useRecommendedProducts({ dispensaryId }: UseRecommendedProductsParams): UseRecommendedProductsReturn {
  const { data, loading, error } = useGetRecommendedProductsQuery({
    fetchPolicy: `network-only`,
    skip: !dispensaryId,
    variables: {
      dispensaryId,
    },
  });

  const normalizedProducts = useMemo(
    () => data?.getRecommendedProducts.map((item): Product => parseProduct(item)) ?? [],
    [data?.getRecommendedProducts]
  );

  return {
    products: normalizedProducts,
    loading,
    error,
  };
}
