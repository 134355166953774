import useDispensary from 'src/dispensary/hooks/use-dispensary';

type UseHardAgeGateReturn = {
  hardAgeGate: boolean;
  dispensaryLogoImage: string;
};

export function useHardAgeGate(): UseHardAgeGateReturn {
  const { dispensary } = useDispensary();
  const dispensaryLogoImage = dispensary ? dispensary.logoImage : null;
  const hardAgeGate = dispensary?.storeSettings?.hardAgeGateHideMenuContent ?? false;
  return { hardAgeGate, dispensaryLogoImage };
}
