import React from 'react';
import TinyColor from 'tinycolor2';
import styled, { css, keyframes } from 'styled-components';
import { space } from 'styled-system';

/**
 * @type {React.FC<{
 *  height?: number | string;
 *  width?: number | string;
 *  circle?: boolean;
 *  rounded?: boolean;
 *  mr?: number | string
 * }>}
 */
const Skeleton = (props) => {
  const { carouselTheme } = props;

  return (
    <div>
      <StyledSkeleton loadingColor={carouselTheme?.loadingColor} {...props}>
        &zwnj;
      </StyledSkeleton>
    </div>
  );
};

Skeleton.defaultProps = {
  width: null,
  height: null,
  circle: false,
};

export default Skeleton;

const loadingColorProgression = (colorSteps) => keyframes`
  0% {
    background-color: ${colorSteps[0]};
  }
  25% {
    background-color: ${colorSteps[1]};
  }
  50% {
    background-color: ${colorSteps[2]};
  }
  75% {
    background-color: ${colorSteps[1]};
  }
  100% {
    background-color: ${colorSteps[0]};
  }
`;

const animations = (loadingColor) => {
  let colorSteps = [`#d9dfe1`, `#e8ecee`, `#eef1f2`];

  if (loadingColor && TinyColor(loadingColor).isValid) {
    colorSteps = [
      TinyColor(loadingColor).lighten(15).toString(),
      loadingColor,
      TinyColor(loadingColor).darken(15).toString(),
    ];
  }

  return css`
    animation: ${({ theme }) => theme.animations.create(loadingColorProgression(colorSteps), `1s linear infinite`)};
  `;
};

export const StyledSkeleton = styled.div`
  ${space}
  ${({ loadingColor }) => animations(loadingColor)}
  background-color: ${({ theme, loadingColor }) => loadingColor ?? theme.colors.grey[95]};
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1;
  width: 100%;
  height: 100%;

  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};

  border-radius: ${({ width, height, circle, rounded }) => {
    if (width && height && circle) {
      return `50%`;
    }
    if (rounded) {
      return `30px`;
    }
    return `10px`;
  }};
`;
