import React from 'react';
import { useRouter } from 'next/router';

import useUI from 'hooks/use-ui';

import PageTitle from './title';
import PageDescription from './description';
import Favicons from './favicons';
import ProductTags from './product-tags';
import DispensaryTags from './dispensary-tags';
import Meta from './meta';
import CanonicalTag from './canonical-tag';
import AppleTouchIcons from './apple-touch-icons';

import { getPageVariant, isDutchieMainProductDetailPage } from './utils.ts';

function useMetaRobotsContent(customRobotsRule, query, asPath) {
  const UI = useUI();

  if (customRobotsRule) {
    return customRobotsRule;
  }

  if (UI.isVariant(`embedded`)) {
    return `noindex,indexifembedded`;
  }

  if (UI.isVariant(`kiosk`) || isDutchieMainProductDetailPage(query, asPath)) {
    return `noindex,follow`;
  }

  return `index,follow`;
}

export default function MetaTags(props) {
  const { query, asPath } = useRouter();
  const UI = useUI();
  const { variant = getPageVariant(query, asPath), location, customRobotsRule = undefined } = props;
  const robotsContent = useMetaRobotsContent(customRobotsRule, query, asPath);
  const isEmbeddedOrStoreFront = UI.isVariant(`store-front`) || UI.isVariant(`embedded`);

  return (
    <>
      <PageTitle variant={variant} location={location} isEmbeddedOrStoreFront={isEmbeddedOrStoreFront} />
      <PageDescription variant={variant} location={location} isEmbeddedOrStoreFront={isEmbeddedOrStoreFront} />
      <Favicons variant={variant} isKioskOrStoreFront={UI.isVariant(`kiosk`) || UI.isVariant(`store-front`)} />
      <Meta property='viewport' content='width=device-width,initial-scale=1' />
      <Meta property='robots' content={robotsContent} />
      <Meta property='googlebot' content={robotsContent} />
      <CanonicalTag />
      <AppleTouchIcons />
      {(variant === 'product' || variant === 'dispensaryProduct') && <ProductTags url={asPath} />}
      {variant === 'dispensary' && <DispensaryTags url={asPath} />}
    </>
  );
}
