import _values from "lodash/values";
import _get from "lodash/get";
import _keys from "lodash/keys";
import _includes from "lodash/includes";
import { canadianProvincesMap, JamaicanParishesMap, saintVincentAndTheGrenadinesParishesMap } from '../constants/geography';
import { stateComplianceRules, canadaDefaults, jamaicanDefaults, saintVincentAndTheGrenadinesDefaults } from '../constants/compliance';
export var getComplianceConfig = function getComplianceConfig(state, key) {
  // all of canada has the same compliance info except for Alberta and Ontario
  var stateHasConfig = _includes(_keys(stateComplianceRules), state);

  if (!stateHasConfig && canadianProvincesMap[state]) {
    return _get(canadaDefaults, key, _get(stateComplianceRules.DEFAULT, key));
  } // Jamaica
  // we only have one jamaican dispo currently!


  if (!stateHasConfig && _includes(_values(JamaicanParishesMap), state)) {
    return _get(jamaicanDefaults, key, _get(stateComplianceRules.DEFAULT, key));
  } // Saint Vincent and the Grenadines


  if (!stateHasConfig && _includes(_keys(saintVincentAndTheGrenadinesParishesMap), state)) {
    return _get(saintVincentAndTheGrenadinesDefaults, key, _get(stateComplianceRules.DEFAULT, key));
  }

  return _get(stateComplianceRules, "".concat(state, ".").concat(key), _get(stateComplianceRules.DEFAULT, key));
};