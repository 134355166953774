import React, { useState, forwardRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { flexbox, space } from 'styled-system';

import { Clickable } from 'shared/components';

import ChevronIcon from 'assets/chevron-icon';
/**
 * These are not all the props. The rest should be added if/when they're used in a TS file.
 * @type {React.FC<{
 *   children: ReactNode;
 *   title?: string;
 *   description?: string;
 *   collapsible?: boolean;
 *   useCssToHide?: boolean
 * }>}
 */
export const CollapsibleSection = forwardRef(
  ({ title, description, collapsible, children, useCssToHide, ...props }, ref) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
      <SectionWrapper {...props} ref={ref}>
        <SectionHeader
          aria-expanded={collapsible ? !collapsed : null}
          onClick={collapsible ? () => setCollapsed(!collapsed) : _.noop}
        >
          {title && <Subheading>{title}</Subheading>}
          {collapsible && <StyledChevronIcon direction={collapsed ? `down` : `up`} />}
        </SectionHeader>
        {description && <SectionDescription>{description}</SectionDescription>}

        {useCssToHide && <Hidden hide={collapsed}>{children}</Hidden>}
        {!useCssToHide && !collapsed && children}
      </SectionWrapper>
    );
  }
);

const SectionHeader = styled(Clickable)`
  display: flex;
`;

const SectionWrapper = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  border-top: ${({ noBorder, theme }) => !noBorder && `1px solid ${theme.colors.blueGrey[95]}`};
  ${flexbox}
  ${space}
`;

const StyledChevronIcon = styled(ChevronIcon)`
  height: 8.5px;
  width: 14px;
  margin-left: 7px;
  fill: ${({ theme }) => theme.colors.grey[45]};
  transform: ${({ direction }) => (direction === `up` ? `rotate(-180deg)` : `none`)};
`;

export const SectionDescription = styled.p`
  font-size: 12px;
  margin: 8px 0;
`;

export const Subheading = styled.span`
  color: ${({ theme }) => theme.colors.grey[25]};
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  margin-right: auto;
`;

// bugfix to prevent redownloading imgs on each toggle
export const Hidden = styled.span`
  visibility: ${({ hide }) => (hide ? `hidden` : `visible`)};
  height: ${({ hide }) => (hide ? `0px` : `inherit`)};
`;
