import React from 'react';
import useTranslation from 'src/hooks/use-translation';

function SvgAddIcon(props) {
  const { t } = useTranslation();
  return (
    <svg width={11} height={11} viewBox='0 0 10 10' {...props}>
      <title>{t('add-icon', 'Add icon')}</title>
      {/* eslint-disable-next-line max-len */}
      <path d='M9.176 5c0-.407-.031-.723-.438-.723l-3.022.007.007-3.022c0-.407-.326-.428-.722-.438-.407 0-.723.03-.722.436l.003 3.012-3.022.007c-.406 0-.426.325-.436.722-.01.396.031.722.438.722l3.022-.007.003 3.012c0 .407.326.427.723.438.407 0 .722-.03.721-.437l-.003-3.011 3.012.003c.406 0 .437-.315.436-.722z' />
    </svg>
  );
}

export default SvgAddIcon;
