import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { SmallLoader } from 'shared/components/loading';
import { WidthProps, HeightProps, SpaceProps, SizeProps } from 'styled-system';
import buttonStyles from './styles';

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledButton = styled.button`
  ${buttonStyles}

  ${(props) =>
    props.isLoading &&
    css`
      /* hide text */
      color: transparent;

      /* hide all other content */
      & > *:not(${LoaderContainer}) {
        opacity: 0;
      }
    `}

    ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
  svg {
    margin-right: 13px;
    vertical-align: middle;
  }
`;
type StyleProps = HeightProps & SizeProps & SpaceProps & WidthProps;

type ButtonProps = StyleProps & {
  children: React.ReactNode;
  className?: string;
  variant?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: 'button' | 'reset' | 'submit';
  onClick?: () => void;
  fluid?: boolean;
  noDim?: boolean;
  ref?: React.Ref<unknown>;
  fontSize?: number;
};

export const Button = forwardRef<JSX.Element, ButtonProps>(
  ({ className, children, disabled, loading, size, variant, ...buttonProps }, ref) => (
    <StyledButton
      isLoading={loading}
      disabled={disabled ?? loading}
      ref={ref}
      size={size ?? 'medium'}
      variant={variant ?? 'primary'}
      className={className}
      {...buttonProps}
    >
      {children}
      {loading && (
        <LoaderContainer>
          <SmallLoader height={20} color='#fff' aria-label='Loading' />
        </LoaderContainer>
      )}
    </StyledButton>
  )
);

export default Button;
