import React from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import Link from 'next/link';

import useUser from 'src/hooks/use-user';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import usePaths from 'src/hooks/use-paths';
import { Loyalty as LoyaltyIcon } from 'src/assets/loyalty';
import { DialogHeading } from 'src/components/modals/components/simple-dialog';
import { TextButton } from 'src/components/core';
import { AnchorButton } from 'src/components/core/button';
import { PinField } from 'shared/components';
import { Statuses } from 'src/components/loyalty/use-loyalty';

type PinProps = {
  onClose: () => void;
  status: Statuses;
  programName: string;
  processPin: (pin: string) => void;
  resendPin: () => Promise<void>;
};

export const Pin = ({ onClose, status, programName, processPin, resendPin }: PinProps): JSX.Element => {
  const User = useUser();
  const phone = useObserver(() => User.phone);
  const { dispensary } = useDispensary();
  const hasError = status === Statuses.incorrectPin;
  const isSuccessful = status === Statuses.success;
  const { href: baseHref } = usePaths();

  return (
    <>
      <StyledHeading fontSize={20}>Connect to {programName}</StyledHeading>

      <StyledLoyaltyIcon />

      {!isSuccessful && <PinField onComplete={processPin} />}

      {hasError && <Error>Invalid code, try again</Error>}

      {isSuccessful ? (
        <>
          <Content>
            <Success>Success!</Success>
          </Content>
          <Link href={`${baseHref}/user/loyalty`}>
            <AnchorButton href={`${baseHref}/user/loyalty`} width='264px'>
              View My Account
            </AnchorButton>
          </Link>
        </>
      ) : (
        <>
          <Content>
            Enter the code we sent to (***) *** - {phone.slice(-4)} to connect your {dispensary.name} {programName}{' '}
            account.
          </Content>
          <StyledTextButton onClick={resendPin}>Resend Code</StyledTextButton>
        </>
      )}

      <CancelButton onClick={onClose}>{isSuccessful ? 'Close' : 'Cancel'}</CancelButton>
    </>
  );
};

const StyledHeading = styled(DialogHeading)`
  margin-bottom: 24px;
`;

const StyledLoyaltyIcon = styled(LoyaltyIcon)`
  margin-bottom: 24px;
`;

const Error = styled.div`
  color: ${({ theme }) => theme.colors.red[55]};
  font-weight: 600;
  margin: 24px 0 8px;
`;

const Content = styled.div`
  color: #646d72;
  margin: 24px 0 48px;
`;

const Success = styled.div`
  color: ${({ theme }) => theme.colors.green[40]};
  font-weight: 600;
`;

const StyledTextButton = styled(TextButton)`
  font-weight: 600;
`;

const CancelButton = styled(TextButton)`
  color: #646d72;
  font-weight: 600;
  margin-top: 16px;
`;
