import React from 'react';
import styled from 'styled-components';
import { border, color, shadow, space, flexbox } from 'styled-system';

const Container = styled.div`
  ${border}
  ${color}
  ${shadow}
  ${space}
  position: relative;
  flex-grow: 1;
  ${flexbox}
  max-width: 100vw;
`;

const Content = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 960px) {
    padding: 0;
  }
`;

// TODO: Use mui container with breakpoints?
export default function ContentWrapper(props) {
  const { children, ...otherProps } = props;
  return (
    <Container {...otherProps}>
      <Content className='content-wrapper'>{children}</Content>
    </Container>
  );
}
