import styled from 'styled-components';

const Footer = styled.footer`
  background-color: ${({ color, theme }) => color || theme.colors.veryLightGrey};
  color: ${({ theme }) => theme.colors.blue[90]};
  font-size: 13px;
  line-height: 22px;
  width: 100%;
  align-self: flex-end;
`;
export default Footer;

export const FooterDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #446583;
  margin-top: 45px;
  margin-bottom: 18px;
`;

export const FooterHeader = styled.p`
  color: #70a0ca;
  font-size: 16px !important;
  font-weight: bold;
  margin: 0 0 11px 0;
`;
