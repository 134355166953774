import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { useColors } from 'contexts/colors';
import SearchIcon from 'assets/search-icon';
import { Link } from 'components/core';

// @TODO better type for linkProps once we have search results data to work with
type SearchResultProps = {
  linkProps?: any;
  copy?: string;
  children?: JSX.Element;
};

export function SearchResult({ linkProps, copy, children }: SearchResultProps): JSX.Element {
  const { t } = useTranslation();
  const colors = useColors();
  return (
    <Link {...linkProps}>
      <Result>
        <SearchIcon fill={colors.grey[70]} height='17px' alt={t('search-icon', 'Search Icon')} />
        {copy ?? children}
      </Result>
    </Link>
  );
}

const Result = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryBlack};
  cursor: pointer;
  display: flex;
  font-size: 15px;
  height: 44px;
  padding: 12px 0 12px 22px;

  &:active,
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryGrey};
  }

  svg {
    margin-right: 13px;
  }

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    cursor: default;
    padding-left: 5px;

    :first-of-type {
      padding-top: 20px;
    }

    &:active,
    &:hover {
      background-color: unset;
    }

    svg {
      height: 13px;
      width: 13px;
      margin-right: 10px;
    }
  }
`;
