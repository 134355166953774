import React, { useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import PaymentOptions from 'checkout/components/sections/payment/options';

import useTranslation from 'hooks/use-translation';
import useStores from 'shared/hooks/use-stores';
import useDispensaryPaymentMethods from 'shared/hooks/use-dispensary-payment-methods';
import { CheckoutText } from 'checkout/components/styles/common';
import { StyledLink } from 'shared/components';

import { Header } from './components';

export default function PaymentSection(props) {
  const { dispensary } = props;
  const { orderTypesConfig } = dispensary;
  const { cart } = useStores();
  const { t } = useTranslation();
  const paymentOptions = _.reject(useDispensaryPaymentMethods(), { disabled: true });

  const hasDutchiePay = paymentOptions.some((option) => option.value === 'dutchiePay');

  useEffect(() => {
    // ensure payment method doesnt just default to cash
    if (paymentOptions?.length === 1) {
      cart.setPaymentMethod(paymentOptions[0].value);
    }
  });

  useEffect(() => {
    // if duchie pay is available then set it to selected
    if (hasDutchiePay) {
      cart.setPaymentMethod('dutchiePay');
    }
  }, [hasDutchiePay, cart]);

  if (!dispensary) {
    // shared checkout components aren't meant to work without dispo object
    // also useBackwardsCompatibility is called in kiosk-checkout, don't worry
    return null;
  }

  return (
    <Container>
      <Header>{t('checkout.select-payment', 'Select Payment')}</Header>
      {orderTypesConfig.kiosk.paymentTypes.payOnlineMerrco || paymentOptions?.length > 1 ? (
        <PaymentOptions
          Summary={() => null}
          Actions={Actions}
          dispensary={dispensary}
          defaultValue={_.get(cart, `order.paymentMethod`)}
          setPaymentMethod={cart.setPaymentMethod}
        />
      ) : (
        <CheckoutText>
          {!orderTypesConfig.kiosk.paymentTypes.linx &&
            `Payment will be made upon pickup. This dispensary accepts ${paymentOptions[0]?.label} only.`}
          {orderTypesConfig.kiosk.paymentTypes.linx && (
            <>
              Payment will be made upon pickup. This dispensary accepts Linx only.
              <StyledLink href='http://www.linxkiosk.com/#card' target='_blank'>
                {' '}
                (?)
              </StyledLink>
            </>
          )}
        </CheckoutText>
      )}
    </Container>
  );
}

const Container = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[80]};
  padding: 30px 45px;
`;

const Actions = styled.div``;
