/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export const InfoV2 = (props: SVGAttributes<unknown>): JSX.Element => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1755_69)'>
      <circle cx='12' cy='12' r='11' stroke='currentColor' strokeWidth='2' />
      <path d='M12 12L12 16' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <circle cx='12' cy='8.5' r='1.5' fill='currentColor' />
    </g>
    <defs>
      <clipPath id='clip0_1755_69'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
