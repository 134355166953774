import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import { withDefault, NumberParam, StringParam, BooleanParam } from 'use-query-params';

import useQueryParamsHookFactory from 'shared/hooks/use-query-params-hook-factory';
import useSearchPreferences from 'hooks/use-search-preferences';

export const defaults = {
  distance: 15,
  distActive: false,
  page: 1,
  preview: `false`,
  recreational: false,
  medical: false,
  freeDelivery: false,
  openNow: false,
  offerCurbsidePickup: false,
  acceptsCreditCards: false,
  acceptsDutchiePay: false,
  noMinimum: false,
  pickup: false,
  delivery: false,
};

const config = {
  // ideally we could use a BooleanParam here but it was constantly removed for an unknown reason
  preview: withDefault(StringParam, defaults.preview),
  sortBy: withDefault(StringParam, defaults.sortBy),
  page: withDefault(NumberParam, defaults.page),
  distance: withDefault(NumberParam, defaults.distance),
  distActive: withDefault(BooleanParam, defaults.distActive),
  recreational: withDefault(BooleanParam, defaults.recreational),
  medical: withDefault(BooleanParam, defaults.medical),
  freeDelivery: withDefault(BooleanParam, defaults.freeDelivery),
  openNow: withDefault(BooleanParam, defaults.openNow),
  offerCurbsidePickup: withDefault(BooleanParam, defaults.offerCurbsidePickup),
  acceptsCreditCards: withDefault(BooleanParam, defaults.acceptsCreditCards),
  acceptsDutchiePay: withDefault(BooleanParam, defaults.acceptsDutchiePay),
  noMinimum: withDefault(BooleanParam, defaults.noMinimum),
  pickup: withDefault(BooleanParam, defaults.pickup),
  delivery: withDefault(BooleanParam, defaults.delivery),
};

export function useDispensariesQueryParams() {
  const SearchPreferences = useSearchPreferences();
  const isPickup = useObserver(() => SearchPreferences.isPickup);
  const defaultSortBy = isPickup ? `distance` : `deliveryEstimate`;
  return _.partial(
    useQueryParamsHookFactory,
    { ...config, sortBy: withDefault(StringParam, defaultSortBy) },
    { ...defaults, sortBy: defaultSortBy }
  )();
}
