import React from 'react';
import { Flex } from 'rebass/styled-components';

import { PlaidLogoSmall } from 'assets/plaid-logo-small';

export function PoweredByPlaid(): JSX.Element {
  return (
    <Flex alignItems='center'>
      <span>powered by&nbsp;</span>
      <PlaidLogoSmall width='42' height='14' />
    </Flex>
  );
}
