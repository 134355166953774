import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _isEmpty from "lodash/isEmpty";
import _upperCase from "lodash/upperCase";
import _capitalize from "lodash/capitalize";
import _head from "lodash/head";
import _replace from "lodash/replace";
import _find from "lodash/find";
import _isFinite from "lodash/isFinite";
import _get from "lodash/get";
var _excluded = ["id"];
import Big from 'big.js';
import moment from 'moment-timezone';
import { isBlank } from 'underscore.string';
import { remove as removeDiacritics } from 'diacritics';
import { formatName } from 'shared/helpers/users';
import { integrationDisplayName } from 'shared/helpers/integrations';
import { defaultDurationEstimates, archiveReasons, paymentMethodsToDisplayNamesAdmin, CURBSIDE_PICKUP_SPECIAL_INSTRUCTIONS, DRIVE_THRU_PICKUP_SPECIAL_INSTRUCTIONS, SPECIAL_INSTRUCTIONS_MAX_LENGTH } from 'shared/constants';
import { getDefaultRewardsProgramDisplayName } from 'shared/core/helpers/dispensaries';
import { hasNonCashPaymentOptions } from './dispensaries';
export var getCustomerEmail = function getCustomerEmail(order) {
  var _order$guestCustomer;

  var emailAddress = _get(order, 'customer.profile.email', null);

  if (emailAddress) {
    return emailAddress;
  }

  emailAddress = _get(order, 'customer.emails[0].address', null);

  if (emailAddress) {
    return emailAddress;
  }

  if ((_order$guestCustomer = order.guestCustomer) !== null && _order$guestCustomer !== void 0 && _order$guestCustomer.email) {
    return order.guestCustomer.email;
  }

  return null;
};
export var getCustomerNameForOrder = function getCustomerNameForOrder(order) {
  var _order$customer;

  if ((_order$customer = order.customer) !== null && _order$customer !== void 0 && _order$customer.profile) {
    var _order$customer2;

    return formatName((_order$customer2 = order.customer) === null || _order$customer2 === void 0 ? void 0 : _order$customer2.profile);
  }

  if (order.guestCustomer) {
    return formatName(order.guestCustomer);
  }

  return 'unknown';
};
export var getCustomerPhoneForOrder = function getCustomerPhoneForOrder(order) {
  var _order$customer3, _order$customer3$prof, _order$guestCustomer2;

  if ((_order$customer3 = order.customer) !== null && _order$customer3 !== void 0 && (_order$customer3$prof = _order$customer3.profile) !== null && _order$customer3$prof !== void 0 && _order$customer3$prof.phone) {
    return order.customer.profile.phone;
  }

  if ((_order$guestCustomer2 = order.guestCustomer) !== null && _order$guestCustomer2 !== void 0 && _order$guestCustomer2.phone) {
    return order.guestCustomer.phone;
  }

  return 'Unknown';
};
export var getOrderDateAndTime = function getOrderDateAndTime(order) {
  var _order$dispensary;

  var timezone = order.dispoTimezone || ((_order$dispensary = order.dispensary) === null || _order$dispensary === void 0 ? void 0 : _order$dispensary.timezone) || "America/Los_Angeles";
  var orderTime;

  if (order.createdAt instanceof Date) {
    orderTime = moment.tz(order.createdAt, timezone);
  } else {
    orderTime = moment.tz(parseInt(order.createdAt, 10), timezone);
  }

  var date = orderTime.format("M/DD/YYYY");
  var time = orderTime.format("h:mm A");
  return "".concat(date, " at ").concat(time);
};
export var getDurationDisplayValue = function getDurationDisplayValue(durationMinutes) {
  var shortened = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!_isFinite(durationMinutes)) {
    return '-';
  }

  if (durationMinutes === 0 || durationMinutes < 2) {
    return shortened ? '1m' : '1 min';
  }

  if (durationMinutes > 60) {
    return "".concat(moment.duration(durationMinutes, 'minutes').hours(), "h ").concat(durationMinutes % 60).concat(shortened ? 'm' : 'mins');
  }

  return "".concat(durationMinutes).concat(shortened ? ' m' : ' mins');
};
export var getTimestampInfo = function getTimestampInfo(order) {
  var _order$dispensary2;

  var timezone = order.dispoTimezone || ((_order$dispensary2 = order.dispensary) === null || _order$dispensary2 === void 0 ? void 0 : _order$dispensary2.timezone) || 'America/Los_Angeles';
  var orderTime = moment.tz(order.createdAt, timezone);
  var timeDisplay;

  if (moment().diff(orderTime, 'minutes') < 1) {
    timeDisplay = "".concat(moment().diff(orderTime, 'seconds'), " seconds ago");

    if (timeDisplay.charAt(0) === '-') {
      timeDisplay = 'just now';
    }
  } else {
    var duration = moment.duration(moment().diff(orderTime, 'minutes'), 'minutes');

    if (duration.years() > 0 || duration.months() > 0) {
      timeDisplay = "> ".concat(duration.humanize());
    } else if (duration.days() > 0) {
      timeDisplay = "".concat(duration.days(), "d ").concat(duration.hours(), "h ").concat(duration.minutes(), "m ago");
    } else if (duration.hours() > 0) {
      timeDisplay = "".concat(duration.hours(), "h ").concat(duration.minutes(), "m ago");
    } else {
      timeDisplay = "".concat(duration.minutes(), " minutes ago");
    }
  }

  var estimate;

  if (order.durationEstimates) {
    estimate = order.durationEstimates[order.deliveryInfo.deliveryOption ? 'delivery' : 'pickup'];
  }

  if (!estimate) {
    estimate = defaultDurationEstimates[order.deliveryInfo.deliveryOption ? 'delivery' : 'pickup'];
  }

  var estimateCeiling = orderTime.clone().add(estimate.highInMinutes, 'minutes');
  return {
    timeDisplay: timeDisplay,
    isLate: estimateCeiling.isBefore(moment())
  };
};
export var getProductNameForLineItem = function getProductNameForLineItem(item, dispensary) {
  var _dispensary$ordersCon;

  var product = item.product,
      _item$option = item.option,
      option = _item$option === void 0 ? 'N/A' : _item$option;

  if (dispensary !== null && dispensary !== void 0 && (_dispensary$ordersCon = dispensary.ordersConfig) !== null && _dispensary$ordersCon !== void 0 && _dispensary$ordersCon.posItemNames) {
    var _product$POSMetaData, _product$POSMetaData$, _product$POSMetaData2;

    if ((_product$POSMetaData = product.POSMetaData) !== null && _product$POSMetaData !== void 0 && (_product$POSMetaData$ = _product$POSMetaData.children) !== null && _product$POSMetaData$ !== void 0 && _product$POSMetaData$.length) {
      var childProduct = _find(product.POSMetaData.children, {
        option: option
      });

      if (childProduct && !isBlank(childProduct.canonicalName)) {
        return childProduct.canonicalName;
      }
    }

    if (!isBlank((_product$POSMetaData2 = product.POSMetaData) === null || _product$POSMetaData2 === void 0 ? void 0 : _product$POSMetaData2.canonicalName)) {
      return product.POSMetaData.canonicalName;
    }

    if (!isBlank(product.integrationKey)) {
      return product.integrationKey;
    }
  }

  return product.Name || '';
};
export var getMedCardString = function getMedCardString(order) {
  var medCard = order.medicalCard || _get(order, 'customer.profile.medicalCard', null);

  if (!medCard) {
    var orderType = order.deliveryInfo.deliveryOption ? 'delivery' : 'pickup';
    return "Will present upon ".concat(orderType);
  }

  var number = medCard.number;
  var expirationDate = medCard === null || medCard === void 0 ? void 0 : medCard.expirationDate;

  if (expirationDate) {
    return "".concat(number, " - Exp: ").concat(expirationDate);
  }

  return '';
};
export var getStatusText = function getStatusText(order) {
  var reopened = order.reopened,
      status = order.status;
  var suffix = reopened ? ' (reopened)' : '';

  if (status === 'inTransit') {
    if (!order.deliveryInfo.deliveryOption) {
      return 'Awaiting Pickup';
    }

    return "In Transit".concat(suffix);
  }

  if (status === 'confirmed') {
    return "Confirmed".concat(suffix);
  }

  if (status === 'closed') {
    return 'Closed';
  }

  if (status === 'open') {
    return 'New';
  }

  return '';
};
export var getCustomerDOB = function getCustomerDOB(order) {
  if (order.isGuestOrder) {
    var _order$guestCustomer3 = order.guestCustomer,
        birthMonth = _order$guestCustomer3.birthMonth,
        birthDay = _order$guestCustomer3.birthDay,
        birthYear = _order$guestCustomer3.birthYear;

    if (birthMonth && birthDay && birthYear) {
      return "".concat(birthMonth, "/").concat(birthDay, "/").concat(birthYear);
    }

    return null;
  }

  return order.customer.profile.birthday;
};
export var getCustomerBirthdayForOrder = function getCustomerBirthdayForOrder(order) {
  var _order$customer4, _order$customer4$prof;

  if ((_order$customer4 = order.customer) !== null && _order$customer4 !== void 0 && (_order$customer4$prof = _order$customer4.profile) !== null && _order$customer4$prof !== void 0 && _order$customer4$prof.birthday) {
    return moment(order.customer.profile.birthday, 'M-D-YYYY');
  }

  if (order.guestCustomer) {
    var guestCustomer = order.guestCustomer;
    return moment("".concat(guestCustomer.birthMonth, "-").concat(guestCustomer.birthDay, "-").concat(guestCustomer.birthYear), 'M-D-YYYY');
  }

  return null;
};
export var getCustomerIdForOrder = function getCustomerIdForOrder(order) {
  var _order$customer5, _order$customer5$prof, _order$guestCustomer4;

  if ((_order$customer5 = order.customer) !== null && _order$customer5 !== void 0 && (_order$customer5$prof = _order$customer5.profile) !== null && _order$customer5$prof !== void 0 && _order$customer5$prof.photoId) {
    return order.customer.profile.photoId;
  }

  if ((_order$guestCustomer4 = order.guestCustomer) !== null && _order$guestCustomer4 !== void 0 && _order$guestCustomer4.photoId) {
    return order.guestCustomer.photoId;
  }

  return null;
};
export var getCustomerDriversLicenceForOrder = function getCustomerDriversLicenceForOrder(order) {
  var _order$customer6, _order$customer6$prof;

  if ((_order$customer6 = order.customer) !== null && _order$customer6 !== void 0 && (_order$customer6$prof = _order$customer6.profile) !== null && _order$customer6$prof !== void 0 && _order$customer6$prof.driversLicense) {
    return order.customer.profile.driversLicense;
  }

  if (order.driversLicense) {
    return order.driversLicense;
  }

  return null;
};
export var getCustomerInfoFieldsForOrder = function getCustomerInfoFieldsForOrder(order) {
  var customerName = getCustomerNameForOrder(order);
  var customerPhone = getCustomerPhoneForOrder(order);
  var customerEmail = getCustomerEmail(order);
  var customerId = getCustomerIdForOrder(order);
  var customerBirthday = getCustomerBirthdayForOrder(order);
  var customerDriversLicense = getCustomerDriversLicenceForOrder(order);
  return {
    customerName: customerName,
    customerPhone: customerPhone,
    customerEmail: customerEmail,
    customerId: customerId,
    customerBirthday: customerBirthday,
    customerDriversLicense: customerDriversLicense
  };
};
/** @typedef {{ id: string | null, reason: string | null, customerMessage: string | null }} ArchiveReason  */

/**
 * @returns {ArchiveReason}
 */

export var getArchiveReason = function getArchiveReason(_ref) {
  var id = _ref.id,
      props = _objectWithoutProperties(_ref, _excluded);

  return _find(archiveReasons(props), {
    id: id
  }) || {
    id: null,
    reason: null,
    customerMessage: null
  };
};
export var getUserContext = function getUserContext(context) {
  var by = context.user._id || context.user.deviceId;
  var byType = context.user._id ? 'user' : 'device';
  var agentName = context.user.agentName;
  return {
    by: by,
    byType: byType,
    agentName: agentName
  };
};
export var getScheduledOrderTime = function getScheduledOrderTime(_ref2) {
  var reservation = _ref2.reservation,
      timezone = _ref2.timezone;

  if (!(reservation !== null && reservation !== void 0 && reservation.startTimeISO)) {
    return false;
  }

  var startTimeISO = reservation.startTimeISO,
      endTimeISO = reservation.endTimeISO;
  var startTimeMoment = moment.tz(startTimeISO, timezone);
  var endTimeMoment = moment.tz(endTimeISO, timezone);
  var prefix = startTimeMoment.format('M/D');
  var withMinsFormat = 'h:mma';
  var withoutMinsFormat = 'ha';

  var showMinutesFormat = function showMinutesFormat(time) {
    return time.minutes() !== 0;
  };

  if (startTimeMoment.isSame(moment(), 'day')) {
    prefix = 'Today';
  } else if (startTimeMoment.isSame(moment().add(1, 'day'), 'day')) {
    prefix = 'Tomorrow';
  }

  return {
    dayOfWeek: startTimeMoment.format('dddd'),
    prefix: prefix,
    startTime: startTimeMoment.format(showMinutesFormat(startTimeMoment) ? withMinsFormat : withoutMinsFormat),
    endTime: endTimeMoment.format(showMinutesFormat(endTimeMoment) ? withMinsFormat : withoutMinsFormat)
  };
};
export var cleanStringForPrinter = function cleanStringForPrinter(str) {
  if (!str) {
    return '';
  }

  try {
    // replace accented characters with the most similar ascii character
    var cleanedString = removeDiacritics(str); // replace common fraction characters with ascii

    cleanedString = _replace(cleanedString, /¼/g, ' 1/4');
    cleanedString = _replace(cleanedString, /½/g, ' 1/2');
    cleanedString = _replace(cleanedString, /¾/g, ' 3/4'); // remove any remaining non-ascii
    // eslint-disable-next-line

    cleanedString = _replace(cleanedString, /[^\x00-\x7F]/g, '');
    return cleanedString;
  } catch (e) {
    console.error(e);
    return '';
  }
};
export var formatReward = function formatReward(reward, _ref3) {
  var _storeSettings$reward, _storeSettings$reward2;

  var storeSettings = _ref3.storeSettings,
      location = _ref3.location;

  if (!reward) {
    return '';
  }

  var copy = reward.copy,
      operator = reward.operator,
      value = reward.value;
  var rewardPrefix = (_storeSettings$reward = storeSettings === null || storeSettings === void 0 ? void 0 : (_storeSettings$reward2 = storeSettings.rewardsIntegrationConfiguration) === null || _storeSettings$reward2 === void 0 ? void 0 : _storeSettings$reward2.rewardsProgramDisplayName) !== null && _storeSettings$reward !== void 0 ? _storeSettings$reward : getDefaultRewardsProgramDisplayName({
    location: location
  });
  var dollarText = operator === '$' ? operator : '';
  var percentText = operator === '%' ? operator : ''; // Catch everything that is non-numeric, 0, or falsey

  rewardPrefix += !+value ? '' : " ".concat(dollarText).concat(value).concat(percentText); // TODO: add "for x pts" at the end here, if available (not currently in data)

  return "".concat(rewardPrefix, " discount applied (").concat(copy, ")");
};
export var formatRewardStatus = function formatRewardStatus(reward, showRewardStatus) {
  if (!reward || !showRewardStatus) {
    return '';
  }

  var redemptionAttempts = reward.redemptionAttempts;

  var _ref4 = _head(redemptionAttempts) || {},
      status = _ref4.status,
      redeemBy = _ref4.redeemBy;

  if (!status || !redeemBy) {
    return '';
  }

  var redeemStatus = status === 'pending' ? '- Redeemable at' : 'by';
  return "Reward ".concat(_capitalize(status), " ").concat(redeemStatus, " ").concat(_upperCase(redeemBy));
};
export var getFormattedRewardData = function getFormattedRewardData(_ref5, showRewardStatus, dispensary) {
  var appliedRewards = _ref5.appliedRewards;

  var reward = _head(appliedRewards);

  var formattedReward = formatReward(reward, dispensary);
  var formattedRewardStatus = formatRewardStatus(reward, showRewardStatus);
  return {
    formattedReward: formattedReward,
    formattedRewardStatus: formattedRewardStatus
  };
};
export var getSpecialInstructions = function getSpecialInstructions(order, curbsideArrivalInfo, showRewardStatus, dispensary) {
  var curbsideMessage = "".concat(CURBSIDE_PICKUP_SPECIAL_INSTRUCTIONS, " ").concat(cleanStringForPrinter(curbsideArrivalInfo === null || curbsideArrivalInfo === void 0 ? void 0 : curbsideArrivalInfo.arrivalInformation), " ");
  var driveThruMessage = "".concat(DRIVE_THRU_PICKUP_SPECIAL_INSTRUCTIONS, " ");

  var _getFormattedRewardDa = getFormattedRewardData(order, showRewardStatus, dispensary),
      formattedReward = _getFormattedRewardDa.formattedReward,
      formattedRewardStatus = _getFormattedRewardDa.formattedRewardStatus;

  var specialInstructions = "".concat(cleanStringForPrinter(order.specialInstructions));

  if (!isBlank(formattedRewardStatus)) {
    specialInstructions = formattedRewardStatus.concat(specialInstructions);
  }

  if (!isBlank(formattedReward)) {
    specialInstructions = "".concat(formattedReward, " ").concat(specialInstructions);
  }

  if (order.isCurbsidePickupOrder) {
    specialInstructions = curbsideMessage.concat(specialInstructions);
  }

  if (order.isDriveThruPickupOrder) {
    specialInstructions = driveThruMessage.concat(specialInstructions);
  }

  return specialInstructions.substring(0, SPECIAL_INSTRUCTIONS_MAX_LENGTH);
};
export var formatPaymentMethod = function formatPaymentMethod(_ref6, dispensary) {
  var card = _ref6.card,
      paymentMethod = _ref6.paymentMethod;
  var debitOrder = !dispensary.paymentTypesAccepted.creditCardAtDoor && !dispensary.paymentTypesAccepted.creditCardByPhone && !dispensary.paymentTypesAccepted.linx && dispensary.paymentTypesAccepted.debit; // eslint-disable-line
  // old format payment info

  if (card) {
    return card === 'cash' ? 'Cash' : "".concat(debitOrder ? 'Debit' : 'Credit', " Card");
  }

  var method = _get(paymentMethodsToDisplayNamesAdmin, paymentMethod, paymentMethod);

  if (method === 'Credit Card' && debitOrder) {
    method = 'Debit Card';
  }

  return method;
};

var acceptsDetailedPaymentTypes = function acceptsDetailedPaymentTypes(dispensary) {
  return dispensary.paymentTypesAccepted.payOnlineMerrco || dispensary.paymentTypesAccepted.payOnlineMoneris || dispensary.paymentTypesAccepted.payOnlineHypur || dispensary.paymentTypesAccepted.dutchiePay || dispensary.paymentTypesAccepted.payOnlineChase || dispensary.paymentTypesAccepted.rethinkPay;
};

var hasDetailedPaymentInfo = function hasDetailedPaymentInfo(order) {
  return order && (!_isEmpty(order.paysafe) || !_isEmpty(order.hypur) || !_isEmpty(order.moneris));
};

export var showFullPaymentOptions = function showFullPaymentOptions(_ref7, dispensary) {
  var isDelivery = _ref7.isDelivery,
      order = _ref7.order;
  return isDelivery && hasNonCashPaymentOptions(dispensary) || hasDetailedPaymentInfo(order) || acceptsDetailedPaymentTypes(dispensary) || hasNonCashPaymentOptions(dispensary) && !_isEmpty(dispensary.paymentFees);
};
export var getOrderSyncFailureText = function getOrderSyncFailureText(_ref8) {
  var pos = _ref8.pos;
  var posName = integrationDisplayName(pos.name);
  return "this order couldn't be sent to ".concat(posName, ".").concat(!isBlank(pos === null || pos === void 0 ? void 0 : pos.humanReadableError) ? " ".concat(pos.humanReadableError, " ") : ' ', "The order must be entered manually into ").concat(posName, ".");
};
export var isWithinThreshold = function isWithinThreshold(_ref9) {
  var _ref9$actual = _ref9.actual,
      actual = _ref9$actual === void 0 ? 0 : _ref9$actual,
      _ref9$expected = _ref9.expected,
      expected = _ref9$expected === void 0 ? 0 : _ref9$expected,
      _ref9$threshold = _ref9.threshold,
      threshold = _ref9$threshold === void 0 ? 0 : _ref9$threshold;
  var diff = Big(Big(expected).minus(actual)).abs();
  return diff.lte(Big(threshold));
};
export var willSendOrderReadySMS = function willSendOrderReadySMS(dispensary, order, textNotifications) {
  var deliveryInfo = order.deliveryInfo,
      guestCustomer = order.guestCustomer;
  var messagingSettings = dispensary.messagingSettings;
  var disableReadyForPickup = messagingSettings.disableReadyForPickup,
      disableStartDelivery = messagingSettings.disableStartDelivery;
  var serviceType = deliveryInfo !== null && deliveryInfo !== void 0 && deliveryInfo.deliveryOption ? "delivery" : "pickup";
  var customerCanBeNotified = textNotifications || (guestCustomer === null || guestCustomer === void 0 ? void 0 : guestCustomer.phone);
  var willSendPickupText = customerCanBeNotified && !disableReadyForPickup && serviceType !== 'delivery';
  var willSendDeliveryText = customerCanBeNotified && !disableStartDelivery && serviceType === 'delivery';
  return willSendPickupText || willSendDeliveryText;
};