import React, { forwardRef } from 'react';

import { ModalObserverRenderProps } from 'components/modals/modal-observer';
import { ModalContainer } from 'components/core';
import { SignupModalContent } from './sign-up-modal-content';

type SignUpModalProps = ModalObserverRenderProps & {
  additionalMobileCSS: string;
};

export const SignUpModalContainer = forwardRef<HTMLElement, SignUpModalProps>(
  ({ containerProps, onClose, additionalMobileCSS, data }, ref) => {
    const analyticsEventLabel = data as string;
    return (
      <ModalContainer
        ref={ref}
        width={457}
        padding={0}
        {...containerProps}
        additionalMobileCSS={additionalMobileCSS}
        data-cy='sign-up-modal'
        data-testid='sign-up-modal'
      >
        <SignupModalContent onClose={onClose} analyticsEventLabel={analyticsEventLabel} />
      </ModalContainer>
    );
  }
);
