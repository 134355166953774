import { createContext, useContext } from 'react';
import { AddressAutocompleteContextValue } from './types';

export const AddressAutocompleteContext = createContext<AddressAutocompleteContextValue>(
  {} as AddressAutocompleteContextValue
);

export function useAddressAutocomplete(): AddressAutocompleteContextValue {
  return useContext(AddressAutocompleteContext);
}
