import qs from 'qs';
import Router from 'next/router';

/**
 * Wrapper around Router.push, to handle query params and scroll to the top of the page if needed because it will not
 * do this by default (see: https://github.com/zeit/next.js/issues/3249)
 *
 * Accepted options:
 * @param {object} params
 * @param {string} params.url See https://nextjs.org/docs/api-reference/next/router#router-api for details
 * @param {string=} params.as See https://nextjs.org/docs/api-reference/next/router#router-api for details
 * @param {object=} params.options See https://nextjs.org/docs/api-reference/next/router#router-api for details
 * @param {object=} params.queryParams accepts an object used by qs lib to automatically stringify and append to the end of url / as
 * @param {boolean=} params.scrollReset if set to true this will scroll to the top of the page after routing
 */
export default function routeTo({ url, as = null, options = undefined, queryParams = undefined, scrollReset = false }) {
  const stringifiedQueryParams = queryParams ? `?${qs.stringify(queryParams)}` : '';
  const asPath = as ? `${as}${stringifiedQueryParams}` : undefined;
  const route = `${url}${stringifiedQueryParams}`;

  Router.push(route, asPath, options).then(() => {
    if (scrollReset) {
      window.scrollTo(0, 0);
    }
  });
}
