var logicOperatorMap = {
  and: 'AND',
  or: 'OR'
};
export var FIELD_DEFAULTS = {
  applicationMethod: 'AUTOMATIC',
  applyToNoGroups: null,
  code: null,
  consumerGroupIds: null,
  dispensaryIds: null,
  firstTimeConsumerOnly: null,
  loyaltyPointsToRedeem: null,
  manualDefaultApplyTo: null,
  redemptionLimitPerUser: null,
  specialsStatus: 'ACTIVE'
};
export var ENUM_FIELDS = [{
  v3dot5field: 'bogoConditionLogicOperator',
  v4Field: 'conditionLogicOperator',
  enumMap: logicOperatorMap
}, {
  v3dot5field: 'bogoRewardLogicOperator',
  v4Field: 'rewardLogicOperator',
  enumMap: logicOperatorMap
}, {
  v3dot5field: 'discountPrecedence',
  v4Field: 'precedence',
  enumMap: {
    highToLow: 'HIGH_TO_LOW',
    lowToHigh: 'LOW_TO_HIGH'
  }
}, {
  v3dot5field: 'specialType',
  v4Field: 'specialType',
  enumMap: {
    sale: 'SALE',
    bogo: 'OFFER'
  }
}];
export var DIRECT_COPY_FIELDS = [{
  v3dot5field: 'name',
  v4Field: 'name'
}, {
  v3dot5field: 'redemptionLimit',
  v4Field: 'redemptionLimitPerCart'
}, {
  v3dot5field: 'scope',
  v4Field: 'scope'
}, {
  v3dot5field: 'excludeConditionItemsFromRewards',
  v4Field: 'excludeConditionItemsFromRewards'
}];