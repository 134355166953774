import React from 'react';
import { observer } from 'mobx-react-lite';

import { ContainerProps } from './modal-observer.types';
import { useModalObserver } from './use-modal-observer';

type ModalComponent = React.FC<ModalObserverProps>;

type ModalObservedComponent = React.FC<ObservedModalProps>;

type ObservedModalProps = {
  name: string;
};

export type ModalObserverProps = {
  open: boolean;
  onClose: (data?: unknown) => void;
  containerProps: ContainerProps;
  data?: unknown;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export function withModalObserver(WrappedComponent: ModalComponent): ModalObservedComponent {
  const displayName = (WrappedComponent.displayName ?? WrappedComponent.name) || `Component`;

  const EnhancedComponent: ModalObservedComponent = observer(({ name }) => {
    const props = useModalObserver({ name });

    return props.open ? <WrappedComponent {...props} /> : null;
  });

  EnhancedComponent.displayName = `withModalObserver[${displayName}]`;
  return EnhancedComponent;
}
