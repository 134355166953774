import { useRouter } from 'next/router';
import { GenericCategoryRoute, isGenericCategoryRoute } from 'src/generics/data';
import { RouteParamsError } from 'src/errors';

export type UseCategoryRouteParamReturn = {
  isReady: boolean;
  category: GenericCategoryRoute | null;
};
export type UseCategoryRouteParamOptions = {
  strict?: boolean;
};

const defaultOptions = {
  strict: true,
};

// Pass `strict: false` to use this in places where `category` route param isn't always available.
export function useCategoryRouteParam({
  strict,
}: UseCategoryRouteParamOptions = defaultOptions): UseCategoryRouteParamReturn {
  const {
    isReady,
    query: { category },
  } = useRouter();

  if (!isReady || (!strict && !category)) {
    return { isReady, category: null };
  }

  if (!category || !isGenericCategoryRoute(category)) {
    throw new RouteParamsError({ category });
  }

  return { isReady, category };
}
