import React, { forwardRef } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { space } from 'styled-system';

import useTranslation from 'hooks/use-translation';

import CartIcon from 'assets/cart';
import { Clickable } from 'shared/components';
import { heading4 } from 'components/core/typography';

export enum CartButtonVariant {
  grey = 'grey',
  green = 'green',
  blue = 'blue',
  darkBlue = 'darkBlue',
  orange = 'orange',
}

type CartButtonRef = HTMLButtonElement;

type CartButtonProps = {
  itemCount: number;
  onClick: () => void;
  showCountColor?: boolean;
  variant: CartButtonVariant;
};

const CartButton = forwardRef<CartButtonRef, CartButtonProps>(
  ({ itemCount = 0, showCountColor = false, variant = CartButtonVariant.grey, ...props }, ref): JSX.Element => {
    const { t } = useTranslation();
    const hasItems = itemCount > 0;
    const singularLabel = t('common.cartItemsSingular', 'item in your shopping cart');
    const pluralLabel = t('common.cartItems', 'items in your shopping cart');
    const label = `${itemCount} ${itemCount === 1 ? singularLabel : pluralLabel}`;

    return (
      <Cart
        data-testid='cartButton'
        aria-label={label}
        ref={ref}
        variant={variant}
        hasItems={hasItems}
        pr={hasItems && showCountColor ? `7px` : undefined}
        {...props}
      >
        <CartIcon alt='' />
        <ItemCount
          data-testid='cart-item-count'
          variant={variant}
          showCountColor={hasItems && showCountColor}
          wide={itemCount > 9}
        >
          {itemCount}
        </ItemCount>
      </Cart>
    );
  }
);

export default CartButton;

const variantStyles = ({ variant, theme }): FlattenSimpleInterpolation =>
  ({
    green: css`
      background-color: ${theme.colors.green[30]};
      * {
        color: ${theme.colors.green[95]};
        fill: ${theme.colors.green[95]};
      }
    `,
    grey: css`
      background-color: ${theme.colors.blueGrey[80]};
    `,
    blue: css`
      background-color: ${theme.customized.colors.buttonsLinks};
    `,
    darkBlue: css`
      background-color: #0b2033;
    `,
    orange: css`
      background-color: ${theme.colors.primaryOrange};
    `,
  }[variant]);

const Cart = styled(Clickable)`
  display: flex;
  align-items: center;
  border-radius: 7px;
  font-size: 16px;
  font-weight: bold;
  text-shadow: ${({ variant }) => variant === 'blue' && `0px 3px 2px rgba(0, 0, 0, 0.0579928)`};
  padding: 10px 14px 10px 12px;
  ${variantStyles};

  svg {
    fill: ${({ theme }) => theme.colors.white};
    height: 24px;
    width: 24px;
  }

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    border-radius: 4px;
    padding: 7px 9px 7px 8px;
    svg {
      height: 21px;
      width: 21px;
    }
  }

  ${space}
`;

const ItemCount = styled.div<{ variant: CartButtonVariant; wide: boolean; showCountColor: boolean }>`
  ${heading4}
  width: ${({ wide }) => (wide ? `18px` : `10px`)};
  display: flex;
  place-content: center;
  align-items: center;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.white};
  ${({ showCountColor, theme }) =>
    showCountColor &&
    css`
      border-radius: 50%;
      background-color: ${theme.colors.primaryOrange}};
      height: 26px;
      width: 26px;
      margin-left: 8px;
    `};

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin-left: 8px;
  }
`;
