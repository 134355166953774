import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import useTranslation from 'hooks/use-translation';

import ListItem from './order-receipt-list-item';

const OrderReceiptItemsMobile = ({ order, handleItemClick }) => {
  const { t } = useTranslation();
  const { dispensary, orders } = order;
  const numberOfItems = orders.length;

  return (
    <>
      <ListHeader>
        {t('common.items', 'items')} ({numberOfItems})
      </ListHeader>

      <ListContainer>
        {_.map(orders, (item, index) => (
          <ListItem
            key={item.product._id}
            item={item}
            index={index}
            dispensary={dispensary}
            onClick={handleItemClick}
          />
        ))}
      </ListContainer>
    </>
  );
};

const ListHeader = styled.div`
  height: 45px;
  background-color: ${({ theme }) => theme.colors.primaryGrey};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.blueGrey[90]}`};
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
`;

const ListContainer = styled.div`
  padding: 0 25px 10px 25px;
`;

export default OrderReceiptItemsMobile;
