import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { ContentWrapper, LoadingSkeleton } from 'components/core';

import { MobileOnly } from 'shared/components';
import CategorySkeletonCarousel from './category-skeleton-carousel';

const CategoriesCarouselLoadingState = () => (
  <Container>
    <CarouselGroupContainer data-testid='skeleton-tiles'>
      <LoadingSkeleton height={13} width={183} mb={30} />
      <CategorySkeletonCarousel />
      <MobileOnly mediaQuery='xs'>
        <LoadingSkeleton height={48} mt={30} />
      </MobileOnly>
    </CarouselGroupContainer>
  </Container>
);

export default CategoriesCarouselLoadingState;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const CarouselGroupContainer = styled(ContentWrapper)`
  padding: 60px 0 30px;
  position: relative;
  margin: ${(props) => props.margin};
  :last-child {
    border-bottom: 0;
  }
  ${space}
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
`;
