import { Product } from 'utils/helpers/product';

import { AddToCartDataParam } from 'components/modals/add-to-cart';

import { useShoppingWithControls } from '../../shopping-with/shopping-with.controller';
import { getWeightForCart } from '../helpers';

export type { AddToCartDataParam };

type UseAddBuyingOptionToCartReturn = {
  handleAddToCart: (args: HandleAddToCartArgs) => void;
};

type HandleAddToCartArgs = {
  product: Product;
  quantity: number;
  weight?: string | null;
};

export function useAddBuyingOptionToCart(): UseAddBuyingOptionToCartReturn {
  const { open: openShoppingWith } = useShoppingWithControls();

  const handleAddToCart = ({ product, quantity, weight = null }: HandleAddToCartArgs): void => {
    const { dispensary } = product;

    if (!dispensary) {
      throw new Error(`Unable to add to cart-- product needs dispensary data!`);
    }

    const addToCartData: AddToCartDataParam = {
      dispensary,
      product,
      quantity,
      option: getWeightForCart({ product, weightOption: weight }),
    };

    void openShoppingWith(addToCartData);
  };

  return { handleAddToCart };
}
