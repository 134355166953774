import React from 'react';

function SvgBackArrow(props) {
  return (
    <svg viewBox='0 0 46 86' {...props}>
      <path d='M45 80.09A3.48 3.48 0 0145 85a3.4 3.4 0 01-4.84 0L1 45.45a3.49 3.49 0 010-4.89L40.15 1A3.4 3.4 0 0145 1a3.49 3.49 0 010 4.89L9.29 43z' />
    </svg>
  );
}

export default SvgBackArrow;
