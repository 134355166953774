import React from 'react';
import styled from 'styled-components';
import _times from 'lodash/times';

import { ProductDesktopRowLoadingSkeleton } from 'components/core';

const CategoryPageListLoadingState = () => (
  <Container>
    {_times(10, (key) => (
      <ProductDesktopRowLoadingSkeleton key={key} />
    ))}
  </Container>
);

export default CategoryPageListLoadingState;

const Container = styled.div`
  padding-bottom: 72px;
`;
