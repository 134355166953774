import React from 'react';

function SvgGreenCheckBadge(props) {
  return (
    <svg width='20' height='20' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
      <path d='M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10z' fill='#4CA667' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.041 6.667l-4.323 3.598-1.605-1.75-1.78 1.79 3.346 3.361 5.654-5.682-1.292-1.317z'
        fill='#fff'
      />
    </svg>
  );
}

export default SvgGreenCheckBadge;
