import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';

const StyledDrawer = styled(Drawer)``;

export default StyledDrawer;

// mui theme overrides
export const MuiDrawer = {
  root: {
    '& > .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: '5px 0 7px 0 rgba(0,0,0,0.07)',
    borderRight: '1px solid #D9DDE4',
  },
};
