import React from 'react';
import styled from 'styled-components';

import { Link } from 'components/core';
import { getColors } from 'src/theme';
import useTranslation from 'hooks/use-translation';
import usePaths from 'hooks/use-paths';

import { formatCurrency } from 'shared/helpers/utils';
import { getCategoryForProduct } from 'shared/helpers/products';
import Imgix from 'shared/components/imgix';

const { primaryBlue } = getColors();

const OrderReceiptListItem = ({ item, index, dispensary, onClick }) => {
  const { t } = useTranslation();
  const { product, quantity, option, price } = item;
  const { Image, Name, brand, brandName, Status } = product;
  const { href, route } = usePaths({ category: getCategoryForProduct(product), product, dispensary });
  const productBrand = brandName ?? brand?.name;

  return (
    <Container data-testid='order-receipt-list-item'>
      <ImageContainer height={51} htmlAttributes={{ alt: `${Name} product image` }} src={Image} />

      <ProductDetails>
        <ProductName>{Name}</ProductName>
        {productBrand && <ProductBrand>{productBrand}</ProductBrand>}
        <QuantityWeightContainer>
          <span>
            <span>{t('common.qty', 'qty:')}&nbsp;</span>
            {quantity}
          </span>

          <span>
            <span>{t('common.weight', 'weight:')}&nbsp;</span>
            {option}
          </span>
        </QuantityWeightContainer>
        {Status === 'Archived' ? (
          <ProductStatus>Product unavailable</ProductStatus>
        ) : (
          <Link href={href} route={route}>
            <ProductLink href={href} onClick={() => onClick(product, index)}>
              View Item
            </ProductLink>
          </Link>
        )}
      </ProductDetails>
      <ProductPrice>{formatCurrency(price)}</ProductPrice>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-top: 31px;
  padding-bottom: 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.blueGrey[90]}`};

  &:last-child {
    border: none;
  }
`;

const ImageContainer = styled(Imgix)`
  min-width: 72px;
  margin-right: 15px;
`;

const ProductDetails = styled.div`
  flex: 1;
`;

const ProductName = styled.div`
  font-size: 14px;
  padding-right: 15px;
  font-weight: bold;
  line-height: ${20 / 14};
  flex: 1;
`;

const ProductBrand = styled.div`
  margin-top: 5px;
  font-size: 12px;
  line-height: ${16 / 12};
`;

const ProductStatus = styled.div`
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 12px;
  margin-top: 12px;
`;

const ProductLink = styled.a`
  display: block;
  color: ${primaryBlue};
  font-size: 12px;
  margin-top: 12px;
  max-width: 60px;
`;

const QuantityWeightContainer = styled.div`
  display: flex;
  margin-top: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[45]};
  line-height: ${18 / 12};

  > span {
    margin-right: 15px;

    > span {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`;

const ProductPrice = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[30]};
  min-width: 43px;
`;

export default OrderReceiptListItem;
