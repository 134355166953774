import { hasSaleSpecialForMenuType } from 'shared/helpers/specials';
import { observable, computed, action, toJS, makeObservable } from 'mobx';
import _isNil from 'lodash/isNil';
import _find from 'lodash/find';

export default class CartItem {
  /** @type {any} */
  @observable product = {
    Options: [],
    rawOptions: [],
    Prices: [],
  };
  @observable quantity = 0;
  @observable option = '';
  @observable rawOption = '';
  @observable id = null;
  @observable trackerSource = null;

  constructor(cart, item, key) {
    makeObservable(this);

    this.cart = cart;
    this.product = item.product;
    this.quantity = Number(item.quantity);
    this.option = item.option;
    this.rawOption = item.product?.rawOptions?.[this.optionIndex];
    this.additionalOption = item.additionalOption;
    if (!item.additionalOption && item?.product?.AdditionalOptions?.[0]) {
      this.additionalOption = item?.product?.AdditionalOptions[0];
    }
    this.id = item.product?.id;
    this.key = key;
    this.trackerSource = item.trackerSource;
  }

  @action
  add(item) {
    if (this.product.id !== item.product.id || this.option !== item.option) {
      return;
    }
    this.quantity += item.quantity;
  }

  @computed
  get optionIndex() {
    return this.product.Options.indexOf(this.option);
  }

  @computed
  get basePrice() {
    return parseFloat((this.cart.isMedical ? this.product.medicalPrices : this.product.recPrices)[this.optionIndex]);
  }

  getBreakdownItem() {
    return _find(
      this.cart?.costBreakdown?.details || [],
      (detail) => this.id === detail.id && (this.option || `N/A`) === (detail.option || `N/A`)
    );
  }

  @computed
  get bogoDiscount() {
    const breakdownItem = this.getBreakdownItem();
    const bogoDiscount = breakdownItem?.bogoSavings?.menuBogoDiscount;
    return !_isNil(bogoDiscount) ? bogoDiscount : null;
  }

  @computed
  get discountToCartApplied() {
    const breakdownItem = this.getBreakdownItem();
    const isDiscountToCart = breakdownItem?.bogoSavings?.isDiscountToCartReward;
    return !_isNil(isDiscountToCart) ? isDiscountToCart : null;
  }

  @computed
  get discountedBasePrice() {
    if (hasSaleSpecialForMenuType({ product: this.product, medicalOrder: this.cart?.isMedical })) {
      return parseFloat(
        (this.cart.isMedical ? this.product.medicalSpecialPrices : this.product.recSpecialPrices)[this.optionIndex]
      );
    }
    return null;
  }

  @computed
  get individualPrice() {
    return !_isNil(this.discountedBasePrice) ? this.discountedBasePrice : this.basePrice;
  }

  @computed
  get price() {
    return this.unitPrice * this.quantity;
  }

  @computed
  get saleAdjustment() {
    const breakdownItem = this.getBreakdownItem();
    return breakdownItem?.menuSaleAdjustment ?? null;
  }

  @computed
  get unitPrice() {
    return this.discountedBasePrice ?? this.basePrice;
  }

  toJSON() {
    const product = toJS(this.product);
    product._id = product.id;
    return {
      product,
      quantity: this.quantity,
      option: this.option,
      rawOption: this.rawOption,
      additionalOption: this.additionalOption,
      basePrice: this.basePrice,
      discountedBasePrice: this.discountedBasePrice,
      price: this.price,
      key: this.key,
      trackerSource: this.trackerSource,
    };
  }
}
