/* eslint-disable max-len */
import React from 'react';

function EmptyCartImage() {
  return (
    <svg width='126' height='115' viewBox='0 0 126 115' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <ellipse opacity='0.59' cx='61.3673' cy='112.535' rx='35.232' ry='2.41028' fill='#BDCCCE' />
      <path
        d='M108.809 34.7854C108.806 33.6502 107.888 32.7316 106.756 32.7316H20.9775L18.5736 17.8165C18.4129 16.8192 17.5544 16.0862 16.5476 16.0862H5.82421C4.69076 16.0862 3.77197 17.0081 3.77197 18.1453C3.77197 19.2826 4.69076 20.2045 5.82421 20.2045H14.8005L17.1988 35.085L19.111 49.9019C19.1112 49.9037 19.1116 49.9056 19.1119 49.9074C19.1123 49.9107 19.1127 49.914 19.1131 49.9173L24.0071 85.7098C24.5107 89.3943 27.571 92.2023 31.2035 92.3821C29.527 94.3958 28.5154 96.9971 28.5154 99.8338C28.5154 106.208 33.6247 111.395 39.9045 111.395C46.1844 111.395 51.2935 106.209 51.2935 99.8338C51.2935 97.0014 50.2848 94.4038 48.613 92.3914H73.8441C72.1723 94.4038 71.1637 97.0016 71.1637 99.8338C71.1637 106.208 76.2727 111.395 82.5528 111.395C88.8326 111.395 93.9417 106.209 93.9417 99.8338C93.9417 96.9343 92.8848 94.2809 91.1414 92.2493C95.792 91.4827 99.5593 87.6891 100.224 82.8305L104.48 51.6962H106.794C107.34 51.6962 107.862 51.4786 108.247 51.0912C108.632 50.7039 108.848 50.1788 108.847 49.6317L108.809 34.7854ZM47.189 99.834C47.189 103.938 43.9212 107.277 39.9045 107.277C35.8879 107.277 32.6199 103.938 32.6199 99.834C32.6199 95.7301 35.8879 92.3916 39.9045 92.3916C43.9212 92.3914 47.189 95.7301 47.189 99.834ZM82.5526 107.276C78.5359 107.276 75.2679 103.938 75.2679 99.8338C75.2679 95.7299 78.5359 92.3914 82.5526 92.3914C86.5692 92.3914 89.837 95.7301 89.837 99.8338C89.837 103.937 86.5692 107.276 82.5526 107.276ZM96.1569 82.271C95.6892 85.6927 92.7869 88.2731 89.4062 88.2731H31.5741C29.8219 88.2731 28.3168 86.9305 28.0734 85.1501L23.4995 51.6964H100.337L96.1569 82.271Z'
        fill='#C8CFD1'
      />
      <path
        d='M108.809 34.7854C108.806 33.6502 107.888 32.7316 106.756 32.7316H20.9775L18.5736 17.8165C18.4129 16.8192 17.5544 16.0862 16.5476 16.0862H5.82421C4.69076 16.0862 3.77197 17.0081 3.77197 18.1453C3.77197 19.2826 4.69076 20.2045 5.82421 20.2045H14.8005L17.1988 35.085L19.111 49.9019C19.1112 49.9037 19.1116 49.9056 19.1119 49.9074C19.1123 49.9107 19.1127 49.914 19.1131 49.9173L24.0071 85.7098C24.5107 89.3943 27.571 92.2023 31.2035 92.3821C29.527 94.3958 28.5154 96.9971 28.5154 99.8338C28.5154 106.208 33.6247 111.395 39.9045 111.395C46.1844 111.395 51.2935 106.209 51.2935 99.8338C51.2935 97.0014 50.2848 94.4038 48.613 92.3914H73.8441C72.1723 94.4038 71.1637 97.0016 71.1637 99.8338C71.1637 106.208 76.2727 111.395 82.5528 111.395C88.8326 111.395 93.9417 106.209 93.9417 99.8338C93.9417 96.9343 92.8848 94.2809 91.1414 92.2493C95.792 91.4827 99.5593 87.6891 100.224 82.8305L104.48 51.6962H106.794C107.34 51.6962 107.862 51.4786 108.247 51.0912C108.632 50.7039 108.848 50.1788 108.847 49.6317L108.809 34.7854ZM104.709 36.8499L104.737 47.5781H22.95L21.5655 36.8499H104.709ZM47.189 99.834C47.189 103.938 43.9212 107.277 39.9045 107.277C35.8879 107.277 32.6199 103.938 32.6199 99.834C32.6199 95.7301 35.8879 92.3916 39.9045 92.3916C43.9212 92.3914 47.189 95.7301 47.189 99.834ZM82.5526 107.276C78.5359 107.276 75.2679 103.938 75.2679 99.8338C75.2679 95.7299 78.5359 92.3914 82.5526 92.3914C86.5692 92.3914 89.837 95.7301 89.837 99.8338C89.837 103.937 86.5692 107.276 82.5526 107.276Z'
        fill='white'
      />
      <path
        d='M108.809 34.7854C108.806 33.6502 107.888 32.7316 106.756 32.7316H20.9775L18.5736 17.8165C18.4129 16.8192 17.5544 16.0862 16.5476 16.0862H5.82421C4.69076 16.0862 3.77197 17.0081 3.77197 18.1453C3.77197 19.2826 4.69076 20.2045 5.82421 20.2045H14.8005L17.1988 35.085L19.111 49.9019C19.1112 49.9037 19.1116 49.9056 19.1119 49.9074C19.1123 49.9107 19.1127 49.914 19.1131 49.9173L24.0071 85.7098C24.5107 89.3943 27.571 92.2023 31.2035 92.3821C29.527 94.3958 28.5154 96.9971 28.5154 99.8338C28.5154 106.208 33.6247 111.395 39.9045 111.395C46.1844 111.395 51.2935 106.209 51.2935 99.8338C51.2935 97.0014 50.2848 94.4038 48.613 92.3914H73.8441C72.1723 94.4038 71.1637 97.0016 71.1637 99.8338C71.1637 106.208 76.2727 111.395 82.5528 111.395C88.8326 111.395 93.9417 106.209 93.9417 99.8338C93.9417 96.9343 92.8848 94.2809 91.1414 92.2493C95.792 91.4827 99.5593 87.6891 100.224 82.8305L104.48 51.6962H106.794C107.34 51.6962 107.862 51.4786 108.247 51.0912C108.632 50.7039 108.848 50.1788 108.847 49.6317L108.809 34.7854ZM104.709 36.8499L104.737 47.5781H22.95L21.5655 36.8499H104.709ZM96.1569 82.271C95.6892 85.6927 92.7869 88.2731 89.4062 88.2731H31.5741C29.8219 88.2731 28.3168 86.9305 28.0734 85.1501L23.4995 51.6964H100.337L96.1569 82.271Z'
        fill='white'
      />
      <path
        d='M77.3824 105.561C83.7882 103.295 86.9049 99.6798 85.3896 91.6455L92.7101 98.2055L86.9049 108.294L77.3824 105.561Z'
        fill='#E3E7E8'
      />
      <path d='M20.5308 36.2091L105.808 35.8074V42.2348L21.3315 39.0211L20.5308 36.2091Z' fill='#A1ACAF' />
      <path
        d='M34.1431 105.561C40.5489 103.295 43.6656 99.6798 42.1504 91.6455L49.4709 98.2055L43.6656 108.294L34.1431 105.561Z'
        fill='#E3E7E8'
      />
      <path d='M100.203 61.5172L23.333 88.8337H96.1992L100.203 61.5172Z' fill='#C8CFD1' fillOpacity='0.5' />
      <path
        d='M108.809 34.7854C108.806 33.6502 107.888 32.7316 106.756 32.7316H20.9775L18.5736 17.8165C18.4129 16.8192 17.5544 16.0862 16.5476 16.0862H5.82421C4.69076 16.0862 3.77197 17.0081 3.77197 18.1453C3.77197 19.2826 4.69076 20.2045 5.82421 20.2045H14.8005L17.1988 35.085L19.111 49.9019C19.1112 49.9037 19.1116 49.9056 19.1119 49.9074C19.1123 49.9107 19.1127 49.914 19.1131 49.9173L24.0071 85.7098C24.5107 89.3943 27.571 92.2023 31.2035 92.3821C29.527 94.3958 28.5154 96.9971 28.5154 99.8338C28.5154 106.208 33.6247 111.395 39.9045 111.395C46.1844 111.395 51.2935 106.209 51.2935 99.8338C51.2935 97.0014 50.2848 94.4038 48.613 92.3914H73.8441C72.1723 94.4038 71.1637 97.0016 71.1637 99.8338C71.1637 106.208 76.2727 111.395 82.5528 111.395C88.8326 111.395 93.9417 106.209 93.9417 99.8338C93.9417 96.9343 92.8848 94.2809 91.1414 92.2493C95.792 91.4827 99.5593 87.6891 100.224 82.8305L104.48 51.6962H106.794C107.34 51.6962 107.862 51.4786 108.247 51.0912C108.632 50.7039 108.848 50.1788 108.847 49.6317L108.809 34.7854ZM104.709 36.8499L104.737 47.5781H22.95L21.5655 36.8499H104.709ZM47.189 99.834C47.189 103.938 43.9212 107.277 39.9045 107.277C35.8879 107.277 32.6199 103.938 32.6199 99.834C32.6199 95.7301 35.8879 92.3916 39.9045 92.3916C43.9212 92.3914 47.189 95.7301 47.189 99.834ZM82.5526 107.276C78.5359 107.276 75.2679 103.938 75.2679 99.8338C75.2679 95.7299 78.5359 92.3914 82.5526 92.3914C86.5692 92.3914 89.837 95.7301 89.837 99.8338C89.837 103.937 86.5692 107.276 82.5526 107.276ZM96.1569 82.271C95.6892 85.6927 92.7869 88.2731 89.4062 88.2731H31.5741C29.8219 88.2731 28.3168 86.9305 28.0734 85.1501L23.4995 51.6964H100.337L96.1569 82.271Z'
        fill='#788387'
      />
      <ellipse cx='3.71512' cy='52.2775' rx='3.20291' ry='3.21371' fill='#788387' fillOpacity='0.5' />
      <ellipse cx='71.7772' cy='16.9269' rx='2.40219' ry='2.41028' fill='#788387' fillOpacity='0.3' />
      <ellipse cx='111.413' cy='8.49101' rx='2.80255' ry='2.81199' fill='#788387' fillOpacity='0.3' />
      <g opacity='0.3'>
        <path
          d='M29.3386 0.791421C29.3386 0.384678 29.6971 0.0549469 30.1393 0.0549469C30.5816 0.0549469 30.9401 0.384678 30.9401 0.791421V8.15616C30.9401 8.56291 30.5816 8.89264 30.1393 8.89264C29.6971 8.89264 29.3386 8.56291 29.3386 8.15616V0.791421Z'
          fill='#5E6D79'
        />
        <path
          d='M34.143 3.73732C34.5852 3.73732 34.9437 4.06705 34.9437 4.47379C34.9437 4.88054 34.5852 5.21027 34.143 5.21027H26.1357C25.6935 5.21027 25.335 4.88054 25.335 4.47379C25.335 4.06705 25.6935 3.73732 26.1357 3.73732H34.143Z'
          fill='#5E6D79'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M118.886 64.396C118.886 63.9153 119.304 63.5256 119.82 63.5256C120.336 63.5256 120.755 63.9153 120.755 64.396V73.0998C120.755 73.5805 120.336 73.9702 119.82 73.9702C119.304 73.9702 118.886 73.5805 118.886 73.0998V64.396Z'
          fill='#5E6D79'
        />
        <path
          d='M124.491 67.8775C125.007 67.8775 125.426 68.2672 125.426 68.7479C125.426 69.2286 125.007 69.6183 124.491 69.6183H115.15C114.634 69.6183 114.215 69.2286 114.215 68.7479C114.215 68.2672 114.634 67.8775 115.15 67.8775H124.491Z'
          fill='#5E6D79'
        />
      </g>
    </svg>
  );
}

export default EmptyCartImage;
