/* eslint-disable lodash/prefer-lodash-method */
import moment from 'moment-timezone';

import { getEffectiveHours } from 'shared/core/helpers/dispensaries';
import { generateAcceptedPaymentsString } from 'shared/helpers/dispensaries';

const COUNTRY_ISO_MAP = {
  Canada: `CA`,
  Jamaica: `JM`,
  'Puerto Rico': `PR`,
  'Saint Vincent and the Grenadines': `VC`,
  'United States': `US`,
};

const militaryTime = (hours) => moment(hours, `hh:mm a`).format(`HH:mm`);

const buildOpeningHoursSpecification = (hours) =>
  Object.keys(hours)
    .filter((dayOfWeek) => hours[dayOfWeek]?.active)
    .map((dayOfWeek) => {
      const { end, start } = hours[dayOfWeek];

      return {
        '@type': `OpeningHoursSpecification`,
        dayOfWeek,
        opens: militaryTime(start),
        closes: militaryTime(end),
      };
    });

const buildDispensaryJsonLd = (dispensaryData) => {
  const {
    id,
    name,
    location,
    description,
    listImage,
    bannerImage,
    logoImage,
    embeddedLogoImage,
    SpecialLogoImage,
    url,
    phone,
    timezone,
    deliveryHours,
    pickupHours,
    specialHours,
  } = dispensaryData;
  const { ln1, ln2, city, state, geometry, country } = location;
  const [longitude, latitude] = geometry?.coordinates;

  const paymentAccepted = generateAcceptedPaymentsString(dispensaryData);
  const { pickupHours: effectivePickupHours } = getEffectiveHours(pickupHours, deliveryHours, specialHours, timezone);
  const openingHoursSpecification = buildOpeningHoursSpecification(effectivePickupHours);

  return {
    '@context': `https://schema.org`,
    // TODO: possibly add more types, i.e. alt. medicine, florist, etc.
    // https://schema.org/Store
    '@type': `Store`,
    // TODO: verify this works, has to be a url
    logo: logoImage,
    image: [
      // TODO: verify how/if we pass these images,
      listImage,
      bannerImage,
      logoImage,
      embeddedLogoImage,
      SpecialLogoImage,
    ],
    '@id': id,
    name,
    address: {
      '@type': `PostalAddress`,
      streetAddress: `${ln1} ${ln2}`,
      addressLocality: city,
      addressRegion: state,
      addressCountry: COUNTRY_ISO_MAP[country],
    },
    geo: {
      '@type': `GeoCoordinates`,
      latitude,
      longitude,
    },
    // TODO: apply google map url,a few ways to do it
    //  https://developers.google.com/maps/documentation/urls/guide
    hasMap: '',
    // TODO: apply usd/ca, does this field even matter?
    currenciesAccepted: 'USD',
    paymentAccepted,
    description,
    url,
    telephone: phone,
    // TODO: these are for pickup hours, have to look into delivery hours as well
    openingHoursSpecification,
  };
};

export default buildDispensaryJsonLd;
