import React from 'react';
import { NoSsr } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Slider from './material-ui-slider';

/**
 * @type {React.FC<{
 *  max: number;
 *  min: number;
 *  onChange: (e: any, value: number) => void;
 *  onChangeCommitted?: (e: any, value: number) => void;
 *  value: number;
 * }>}
 */
const RangeSlider = withStyles((theme) => ({
  mark: {
    background: theme.palette.grey[75],
    height: 9,
    width: 2,
  },
  markActive: {
    background: theme.palette.white,
  },
  rail: {
    background: theme.palette.blueGrey[90],
    border: `1px solid ${theme.palette.grey[75]}`,
    borderRadius: 7,
    height: 9,
    opacity: 1,
  },
  root: {
    height: 9,
    padding: '11px 0',
  },
  thumb: {
    background: `url('/icons/slider-handle.svg') no-repeat center center`,
    backgroundSize: 'contain',
    border: 'none',
    height: 20,
    marginTop: -6,
    width: 20,
    '&:active, &:focus, &:hover': {
      boxShadow: '0px 0px 0px 4px rgba(65, 160, 223, .35)',
    },
  },
  track: {
    background: theme.palette.customized.colors.buttonsLinks,
    border: 'none',
    borderRadius: 7,
    height: 9,
  },
  trackInverted: {
    '& $track': {
      background: theme.palette.blueGrey[90],
      border: `1px solid ${theme.palette.grey[75]}`,
    },
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
}))(Slider);

// Supports all the props @material-ui/core/slider does: https://material-ui.com/components/slider/
export default function NoSsrRangeSlider(props) {
  return (
    <NoSsr>
      <RangeSlider {...props} />
    </NoSsr>
  );
}
