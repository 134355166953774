import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { ModalContainer } from 'components/core';
import { ModalButton } from 'shared/modals';
import SuccessIconKiosk from 'assets/success-icon-kiosk';
import useTranslation from 'hooks/use-translation';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useStores from 'shared/hooks/use-stores';

const KioskCheckoutSuccess = forwardRef((props, ref) => {
  const { onClose, ...containerProps } = props;
  const { t } = useTranslation();
  const useDispensaryData = useDispensary();
  const { Cart } = useStores();

  const { dispensary } = props.dispensary ? props : useDispensaryData;

  const handleOnClose = () => {
    Cart.setAnonymousOrderId('');
    Cart.setIsAnonymousKioskCheckout(false);
    onClose();
  };

  return (
    <ModalContainer ref={ref} {...containerProps} width='495px' padding={0}>
      <Content>
        <SuccessIconKiosk width='57px' height='59px' mt='5px' />
        <Header>{t('checkout.order-submitted', 'Order Submitted!')}</Header>
        {Cart.isAnonymousKioskCheckout && (
          <>
            <StyledText fontSize='13px' color='#47494c' mt='20px'>
              {t('checkout.anonymous-kiosk-order', 'Your order number is: ')}
              {Cart.anonymousOrderId}
            </StyledText>
          </>
        )}
        {dispensary?.kioskInstructions && (
          <>
            <StyledText fontSize='13px' color='#47494c' mt='20px'>
              {dispensary?.kioskInstructions}
            </StyledText>
          </>
        )}
        <ModalButton buttonContainerWidth='135px' width='100%' onClick={handleOnClose}>
          {t('common.close', 'Close')}
        </ModalButton>
      </Content>
    </ModalContainer>
  );
});

export default KioskCheckoutSuccess;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
`;

const Header = styled.span`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.green[45]};
  margin-top: 22px;
  font-weight: bold;
`;

const StyledText = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[30]};
  margin: 19px 25px 7px;
  line-height: 24.5px;
`;
