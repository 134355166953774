import { useObserver } from 'mobx-react-lite';

import useCart from 'hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useSearchPreferences from 'hooks/use-search-preferences';

type SimpleOrderType = 'delivery' | 'pickup';

type GetOrderType = () => SimpleOrderType;
type SetOrderType = (type: SimpleOrderType) => void;

type OrderTypeReturn = {
  setOrderType: SetOrderType;
  getOrderType: GetOrderType;
};

export default function useOrderType(): OrderTypeReturn {
  const Cart = useCart();
  const SearchPreferences = useSearchPreferences();

  const { dispensary } = useDispensary();
  const { setOrderType: setCartOrderType } = Cart;
  const { setOrderType: setOrderTypePreference } = SearchPreferences;

  const cartOrderType = useObserver(() => Cart.simplifiedOrderType);
  const orderTypePreference = useObserver(() => SearchPreferences.orderType);
  const isCartEmpty = useObserver(() => Cart.isEmpty);
  const currentDispoId = useObserver(() => dispensary?.id);
  const cartDispoId = useObserver(() => Cart.dispensary?.id);

  const setOrderType = (type: SimpleOrderType): void => {
    if (!isCartEmpty && currentDispoId === cartDispoId) {
      setCartOrderType(type);
    }

    setOrderTypePreference(type);
  };

  const getOrderType = (): SimpleOrderType => {
    if (!isCartEmpty && currentDispoId === cartDispoId && cartOrderType) {
      return cartOrderType;
    }

    return orderTypePreference;
  };

  return { setOrderType, getOrderType };
}
