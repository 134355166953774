import useUI from 'hooks/use-ui';
import { GqlDispensaries, GqlOrders } from 'types/graphql';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useOrderForConsumerView from 'hooks/use-order-for-consumer-view';

type UseOrderReceiptModalReturn = {
  order: GqlOrders;
  dispensary: GqlDispensaries;
  loading: boolean;
  useMobileOrderReceiptV2: boolean;
};

export function useOrderReceiptModal(): UseOrderReceiptModalReturn {
  const { order, loading: orderLoading } = useOrderForConsumerView({ includeProductStatus: true });
  const { dispensary, loading: dispensaryLoading } = useDispensary(order?.dispensary?._id);
  const { isEmbedded, isStoreFront } = useUI();

  const useMobileOrderReceiptV2 = isEmbedded || isStoreFront;
  const loading = dispensaryLoading || orderLoading;

  return {
    order,
    dispensary,
    loading,
    useMobileOrderReceiptV2,
  };
}
