import React from 'react';
import _times from 'lodash/times';
import styled from 'styled-components';

import { HybridProductCardLoadingSkeleton } from 'components/core/loading-skeletons';
import Skeleton from 'components/core/loading-skeletons/loading-skeleton';

import { HybridSlider } from 'components/hybrid-carousel/hybrid-carousel-slider';
import { Item } from 'components/hybrid-carousel/hybrid-carousel-card/hybrid-carousel-card.styles';

const PersonalizedCarouselSkeleton = (props) => {
  const {
    dimensions: { gutter },
    carouselPadding,
    noHeader,
    productCount = 8,
    tabbedHeader = false,
  } = props;

  return (
    <>
      {!noHeader && (
        <HeaderSkeleton>
          <Skeleton width={40} height={40} circle />
          <HeaderSkeletonText>
            <Skeleton width={200} height={20} />
            <Skeleton width={100} height={10} />
          </HeaderSkeletonText>
        </HeaderSkeleton>
      )}

      {tabbedHeader && (
        <TabSkeletonWrapper>
          <TabSkeleton height={32} />
          <TabSkeleton height={32} />
        </TabSkeletonWrapper>
      )}

      <HybridSlider carouselPadding={carouselPadding}>
        {_times(productCount, (key) => (
          <Item gutter={gutter} key={key}>
            <HybridProductCardLoadingSkeleton {...props} />
          </Item>
        ))}
      </HybridSlider>
    </>
  );
};

export default PersonalizedCarouselSkeleton;

const HeaderSkeleton = styled.div`
  display: flex;
  padding: 20px 20px 0;
`;

const HeaderSkeletonText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 45px;
  margin-left: 15px;
`;

const TabSkeletonWrapper = styled.div`
  display: flex;
  padding: 20px 20px 0;

  & > div {
    width: 100%;
    max-width: 162px;
  }

  & > div:first-child {
    margin-right: 10px;
  }
`;

const TabSkeleton = styled(Skeleton)`
  border-radius: 100px;
`;
