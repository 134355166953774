import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useDebouncedCallback } from 'use-debounce';
import { useObserver } from 'mobx-react-lite';

import { DesktopOnly, MobileOnly, Clickable } from 'shared/components';
import useProductQueryParams from 'hooks/use-product-query-params';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useColors } from 'src/contexts/colors';

import SearchIcon from 'assets/search-icon';
import { SearchInput } from 'components/core';
import { menuInteraction } from 'utils/ecommerce-analytics';
import { tracker } from 'utils/analytics';
import usePaths from 'src/hooks/use-paths';
import useFeatureFlags from 'hooks/use-feature-flags';

const debounceTime = 400;

export default function Search({ alwaysDisplay, onClear }) {
  const colors = useColors();
  const { t } = useTranslation();
  const router = useRouter();
  const { href } = usePaths({ search: true });
  const UI = useUI();
  const { isDutchieMain } = UI;
  const showSearchInput = useObserver(() => UI.showSearchInput) || alwaysDisplay;
  const { queryParams, resetQueryParams } = useProductQueryParams();
  const [search, setSearch] = useState(queryParams.search ?? '');
  const { dispensary } = useDispensary();
  const featureFlags = useFeatureFlags();
  const shouldDisableSearch = featureFlags?.flags['ecomm.configuration.disable-product-search'];

  useEffect(() => {
    if (!shouldDisableSearch) {
      setSearch(queryParams.search || '');
    }
  }, [queryParams.search]);

  useEffect(() => {
    if (shouldDisableSearch && search && search.length > 0) {
      setSearch('');
    }
  }, [shouldDisableSearch, search]);

  function setSearchCallBack() {
    menuInteraction(dispensary);
    tracker.searchedForProduct({ query: search });
    router.push({ pathname: href, query: search ? { search } : {} });
  }

  const [debouncedSetSearchCallback] = useDebouncedCallback(setSearchCallBack, debounceTime);

  function handleChange(value) {
    setSearch(value);

    // Only perform the actual search if the value is at least 3 characters
    if (value.length < 3) {
      return;
    }

    debouncedSetSearchCallback();
  }

  function handleClear() {
    resetQueryParams();
    onClear?.();
    setSearch('');
  }

  function handleClickOutside() {
    if (_.isEmpty(search)) {
      UI.showSearchInput = false;
    }
  }
  const inputProps = {
    autoFocus: true,
    onBlur: handleClickOutside,
    onClear: handleClear,
    onChange: (e) => handleChange(e.target.value),
    value: search,
  };

  if (shouldDisableSearch) {
    // for spacing
    return <Container />;
  }

  return (
    <Container expanded={showSearchInput}>
      {!showSearchInput && search.length === 0 && (
        <SearchButton
          aria-label={t('common.searchForMenuItems', 'search for menu items')}
          onClick={() => (UI.showSearchInput = true)}
        >
          <SearchIconContainer>
            {isDutchieMain && <SearchIcon fill={colors.grey[30]} />}
            {!isDutchieMain && (
              <>
                <MobileOnly>
                  <SearchIcon width={24} height={24} fill={colors.grey[75]} />
                </MobileOnly>
                <DesktopOnly>
                  <SearchIcon width={17} height={17} fill={colors.grey[40]} />
                </DesktopOnly>
              </>
            )}
          </SearchIconContainer>
          <DesktopOnly as='span'>
            <SearchLabel isDutchieMain={isDutchieMain}>{t('common.search', 'Search')}</SearchLabel>
          </DesktopOnly>
        </SearchButton>
      )}
      {(showSearchInput || search.length > 0) && (
        <>
          <DesktopOnly>
            <SearchInput
              placeholder={t('common.searchDispoName', {
                defaultValue: 'Search {{dispensaryName}}',
                dispensaryName: _.truncate(dispensary?.name, { length: 25 }),
              })}
              withClearButton
              {...inputProps}
            />
          </DesktopOnly>
          <StyledMobileOnly>
            <SearchInput
              placeholder={t('common.searchDispoName', {
                defaultValue: 'Search {{dispensaryName}}',
                dispensaryName: dispensary?.name,
              })}
              withClearButton={!isDutchieMain}
              mx={isDutchieMain ? '0' : '15px'}
              width='100%'
              {...inputProps}
            />
          </StyledMobileOnly>
        </>
      )}
    </Container>
  );
}

const SearchLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  border: 0;
  background: transparent;
  cursor: pointer;

  color: ${({ theme, isDutchieMain }) => (isDutchieMain ? theme.colors.grey[30] : theme.colors.primaryBlack)};
`;

const SearchIconContainer = styled.div`
  margin-left: auto;
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    padding-top: 3px;
  }
`;

const SearchButton = styled(Clickable)`
  margin: 0 20px 0 0;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  min-width: 40px;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin: 0 16px 0 auto;
    span {
      margin-left: auto;
    }
    svg {
      margin-top: 8px;
    }
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  width: 100%;

  input[placeholder],
  input::placeholder {
    text-overflow: ellipsis;
  }
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    padding: ${({ expanded }) => (expanded ? `0 12px 0 0` : `0 12px`)};
    width: ${({ expanded }) => (expanded ? `278px` : `unset`)};
    > div {
      width: 100%;
    }
  }
`;

const StyledMobileOnly = styled(MobileOnly)`
  width: 100%;
`;
