import type { LDContext, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { action, computed, observable, toJS, makeObservable } from 'mobx';

export default class FeatureFlagsStore {
  @observable _flags: LDFlagSet = {};
  @observable loading = true;
  @observable targets = new Set<string>();

  constructor() {
    makeObservable(this);
  }

  @action
  setFlags(newFlags: LDFlagSet): void {
    if (Object.keys(newFlags).length) {
      this.loading = false;
    }
    this._flags = newFlags;
  }

  @action
  identify(context: LDContext): void {
    Object.keys(context).forEach((target) => {
      this.targets.add(target);
    });
  }

  @computed
  get flags(): LDFlagSet {
    return toJS(this._flags);
  }

  @computed
  get hasTargetUser(): boolean {
    return this.targets.has('user');
  }

  @computed
  get hasTargetDispensary(): boolean {
    return this.targets.has('ecomm-dispensary');
  }

  @computed
  get hasTargetEnterprise(): boolean {
    return this.targets.has('ecomm-enterprise');
  }
}
