const buildproductJsonLd = (productData) => {
  const { name, Image, description, brand, url, recPrices } = productData;
  let { price } = productData;

  if (!price && recPrices?.length) {
    [price] = recPrices;
  }

  const data = {
    '@context': `http://schema.org`,
    '@type': `Product`,
    description,
    name,
    image: Image,
    offers: {
      '@type': `Offer`,
      availability: 'https://schema.org/InStock',
      price,
      priceCurrency: 'USD', // TODO: make this work for CA
      url,
    },
  };

  if (brand?.name?.length) {
    data.brand = {
      name: brand.name,
      description: brand.description,
      logo: brand.imageUrl,
    };
  }

  return data;
};

export default buildproductJsonLd;
