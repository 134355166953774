import { useFlags } from 'launchdarkly-react-client-sdk';

type UseGetDegradedPollIntervalParams = {
  milliseconds: number;
  degradationMultiplier: number;
};

export function useGetDegradedPollInterval({
  milliseconds,
  degradationMultiplier,
}: UseGetDegradedPollIntervalParams): number {
  const flags = useFlags();
  const increasePollInterval = !!flags[`ecomm.configuration.significiantly-increase-poll-interval`];
  const degradedPollInterval = increasePollInterval ? milliseconds * degradationMultiplier : milliseconds;

  return degradedPollInterval;
}
