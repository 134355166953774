import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';

import { LoadingSkeleton } from 'components/core';
import { Content } from 'components/product/layout';

const ProductPageLoadingState = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));
  const isSmallMobile = useMediaQuery((theme) => theme.breakpoints.down(`xs`));

  const line1 = isMobile ? '100%' : 558;
  const line2 = isMobile ? '89%' : 500;
  const line3 = isMobile ? '24%' : 118;

  return (
    <Content isMobile={isMobile}>
      <Image isMobile={isMobile} isSmallMobile={isSmallMobile}>
        <LoadingSkeleton height={344} width={isSmallMobile ? '100%' : 344} />
      </Image>
      <Details isMobile={isMobile}>
        <LoadingSkeleton height={11} width={113} mb={22} />
        <LoadingSkeleton height={20} width={isSmallMobile ? '100%' : 321} mb={27} />
        <LoadingSkeleton height={11} width={84} mb={42} />

        <Wrap>
          <LoadingSkeleton height={51} width={100} mr={14} rounded />
          <LoadingSkeleton height={51} width={166} mb={28} rounded />
        </Wrap>

        <LoadingSkeleton height={11} width={148} mb={38} />

        <LoadingSkeleton height={1} mb={38} />

        <Wrap>
          <LoadingSkeleton height={35} width={79} mr={10} rounded />
          <LoadingSkeleton height={35} width={79} mr={10} rounded />
          <LoadingSkeleton height={35} width={79} mb={43} rounded />
        </Wrap>

        <Paragraphs>
          <LoadingSkeleton height={8} width={line1} mb={13} />
          <LoadingSkeleton height={8} width={line1} mb={13} />
          <LoadingSkeleton height={8} width={line2} mb={13} />
          <LoadingSkeleton height={8} width={line1} mb={13} />
          <LoadingSkeleton height={8} width={line2} mb={13} />
          <LoadingSkeleton height={8} width={line1} mb={13} />
          <LoadingSkeleton height={8} width={line2} mb={13} />
          <LoadingSkeleton height={8} width={line1} mb={13} />
          <LoadingSkeleton height={8} width={line3} mb={41} />
        </Paragraphs>

        <LoadingSkeleton height={1} mb={42} />

        <LoadingSkeleton height={8} width={120} mb={13} />

        <Paragraphs>
          <LoadingSkeleton height={8} width={line1} mb={13} />
          <LoadingSkeleton height={8} width={line1} mb={13} />
          <LoadingSkeleton height={8} width={line2} mb={13} />
          <LoadingSkeleton height={8} width={line1} mb={13} />
          <LoadingSkeleton height={8} width={line2} mb={13} />
          <LoadingSkeleton height={8} width={line1} mb={13} />
          <LoadingSkeleton height={8} width={line2} mb={13} />
          <LoadingSkeleton height={8} width={line1} mb={13} />
          <LoadingSkeleton height={8} width={line3} mb={41} />
        </Paragraphs>
      </Details>
    </Content>
  );
};

export default ProductPageLoadingState;

const Image = styled.div`
  margin: ${({ isMobile }) => (isMobile ? `0 auto` : `unset`)};
  width: ${({ isSmallMobile }) => (isSmallMobile ? `100%` : `unset`)};
  text-align: center;
  margin-right: ${({ isMobile }) => (isMobile ? `auto` : `62px`)};
  padding-top: 16px;
  padding-bottom: ${({ isMobile }) => (isMobile ? `18px` : 0)};
`;

const Details = styled.div`
  flex: 1 0 0%;
  min-width: 0;
  margin: ${({ isMobile }) => (isMobile ? `0 28px` : 0)};
  margin-top: ${({ isMobile }) => (isMobile ? `28px` : `15px`)};
  width: 100%;
`;

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Paragraphs = styled.div`
  width: 100% !important;
  overflow: hidden;
`;
