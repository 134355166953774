var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
export default function ImageUpload(props) {
  var onChange = props.onChange,
      className = props.className;
  return __jsx(React.Fragment, null, __jsx(EditButton, {
    className: className,
    htmlFor: "image-input"
  }, "Upload new"), __jsx(HiddenInput, {
    id: "image-input",
    type: "file",
    onChange: onChange
  }));
}
var EditButton = styled.label.withConfig({
  displayName: "image-upload__EditButton",
  componentId: "sc-1tonyev-0"
})(["color:#4597e0;font-size:13px;cursor:pointer;&:hover{text-decoration:underline;}"]);
var HiddenInput = styled.input.withConfig({
  displayName: "image-upload__HiddenInput",
  componentId: "sc-1tonyev-1"
})(["display:none;"]);