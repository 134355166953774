import React from 'react';

import { UseAddToCartControlsReturn, AddToCartModalProps } from './add-to-cart.types';

import { AddToCartLayout } from './components/add-to-cart-layout';
import { SelectWeight } from './components/select-weight';

type AddToCartContentProps = AddToCartModalProps & UseAddToCartControlsReturn;

export function AddToCart({
  data,
  handleAddToCart,
  onClose,
  shouldSelectProductOption,
  ...layoutProps
}: AddToCartContentProps): JSX.Element | null {
  const { product } = data;

  if (!shouldSelectProductOption) {
    return null;
  }

  return (
    <AddToCartLayout onClose={onClose} {...layoutProps}>
      <SelectWeight onClose={onClose} handleAddToCart={handleAddToCart} product={product} />
    </AddToCartLayout>
  );
}
