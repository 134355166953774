import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useHeader } from 'src/dispensary/core-menu/components/header/use-header';

import { InfoV2 } from 'src/assets/info-v2';
import { VisuallyHidden } from 'src/components/core/visually-hidden';
import { useStoreStatus } from './use-store-status';

export const StoreStatus = (): JSX.Element => {
  const { dispensary } = useDispensary();
  const { infoHref, isOpen, orderingType, isAcceptingPreOrder, isComingSoon, openClosedText } = useStoreStatus({
    dispensary,
  });
  const { trackEvent } = useHeader();

  return (
    <Container>
      <Link href={`${infoHref}`}>
        <InfoAnchor href={`${infoHref}`} onClick={() => trackEvent('dispensary info')}>
          <InfoV2 />
          <VisuallyHidden>Dispensary Info</VisuallyHidden>
        </InfoAnchor>
      </Link>

      <Status>
        <OpenClosed $isOpen={isOpen} $isAcceptingPreOrder={isAcceptingPreOrder} $isComingSoon={isComingSoon}>
          {openClosedText}
        </OpenClosed>
        {orderingType}
      </Status>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  gap: 8px;
  justify-content: space-between;
  line-height: ${18 / 13};

  @media (min-width: 1020px) {
    gap: 12px;
  }
`;

const InfoAnchor = styled.a`
  display: block;

  /* Keep the click area 24px/24px on smaller screens */
  @media (max-width: 879px) {
    position: relative;

    &::before {
      bottom: -4px;
      content: '';
      left: -4px;
      position: absolute;
      right: -4px;
      top: -4px;
    }
  }

  svg {
    display: block;

    @media (max-width: 879px) {
      height: 16px;
      width: 16px;
    }
  }
`;

const Status = styled.div`
  @media (max-width: 1019px) {
    align-items: center;
    display: flex;
    font-family: ${({ theme }) => theme.customized.fonts.secondary};
    gap: 8px;
  }
`;

const OpenClosed = styled.div<{ $isOpen: boolean; $isAcceptingPreOrder: boolean; $isComingSoon: boolean }>`
  color: ${({ theme, $isOpen, $isAcceptingPreOrder, $isComingSoon }) => {
    if ($isComingSoon) {
      return theme.colors.orange[65];
    }

    if ($isOpen) {
      return theme.colors.green[55];
    }

    if ($isAcceptingPreOrder) {
      return '#a0a7ab';
    }

    return theme.colors.red[55];
  }};

  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-weight: 700;
  line-height: ${20 / 13};
  text-transform: uppercase;
`;
