/* eslint-disable max-len */
import React from 'react';

type BankIconProps = {
  width?: number;
  height?: number;
  stroke?: string;
};

export function BankIconOutline({ width = 20, height = 20, stroke = 'white', ...props }: BankIconProps): JSX.Element {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M1.92508 18.575H19.0749' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M19.0749 6.32515H1.92508L9.83766 1.61952C10.2412 1.36022 10.7588 1.36022 11.1623 1.61952L19.0749 6.32515Z'
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M17.8499 8.77515V16.1251H15.4V8.77515' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.725 8.77515V16.1251H9.27499V8.77515' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.60001 8.77515V16.1251H3.15009V8.77515' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
