import React, { forwardRef } from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import DeliveryAddressForm from 'components/forms/delivery-address-form';
import { ModalContainer, ModalExitIcon } from 'components/core';
import { bold } from 'components/core/typography';
import { ModalObserverRenderProps } from 'components/modals/modal-observer';
import { ModalContent } from '../components';

export const DeliveryAddressModal = forwardRef<HTMLElement, Omit<ModalObserverRenderProps, 'open'>>(
  ({ containerProps, onClose }, ref) => {
    const { t } = useTranslation();

    return (
      <ModalContainer ref={ref} width={502} padding={0} {...containerProps}>
        <Header>{t('deliveryAddress.EnterDelivery', 'Enter a Delivery Address')}</Header>
        <ModalExitIcon onClick={() => onClose(false)} />

        <ModalContent pb={40}>
          <DeliveryAddressForm onClose={() => onClose(true)} />
        </ModalContent>
      </ModalContainer>
    );
  }
);

const Header = styled.h3`
  padding-top: 40px;
  margin-bottom: 25px;
  font-size: 16px;
  ${bold};
  text-align: center;
`;
