/* eslint-disable @typescript-eslint/naming-convention */
import { FrozenMap } from '../helpers';

import { GenericStrainType, GenericEffect } from './generic-product.types';
import { StrainType, Effect } from './data.types';
import { GenericCategory, Category } from '../generic-category';

export const toGenericStrainType = new FrozenMap<StrainType, GenericStrainType>(
  [StrainType.highCbd, GenericStrainType.highCbd],
  [StrainType.hybrid, GenericStrainType.hybrid],
  [StrainType.indica, GenericStrainType.indica],
  [StrainType.nA, GenericStrainType.nA],
  [StrainType.sativa, GenericStrainType.sativa],
  [StrainType.seed, GenericStrainType.seed]
);

export const fromGenericStrainType = FrozenMap.reverse(toGenericStrainType);

export const toGenericCategory = new FrozenMap<Category, GenericCategory>(
  [Category.accessories, GenericCategory.accessories],
  [Category.apparel, GenericCategory.apparel],
  [Category.cbd, GenericCategory.cbd],
  [Category.clones, GenericCategory.clones],
  [Category.concentrate, GenericCategory.concentrate],
  [Category.edible, GenericCategory.edible],
  [Category.flower, GenericCategory.flower],
  [Category.nA, GenericCategory.nA],
  [Category.preRolls, GenericCategory.preRolls],
  [Category.seeds, GenericCategory.seeds],
  [Category.tincture, GenericCategory.tincture],
  [Category.topicals, GenericCategory.topicals],
  [Category.vaporizers, GenericCategory.vaporizers]
);

export const fromGenericCategory = FrozenMap.reverse(toGenericCategory);

export const toGenericEffect = new FrozenMap<Effect, GenericEffect>(
  [Effect.body, GenericEffect.body],
  [Effect.calm, GenericEffect.calm],
  [Effect.clearMind, GenericEffect.clearMind],
  [Effect.clearMind2, GenericEffect.clearMind],
  [Effect.comfort, GenericEffect.comfort],
  [Effect.creative, GenericEffect.creative],
  [Effect.energetic, GenericEffect.energetic],
  [Effect.euphoric, GenericEffect.euphoric],
  [Effect.focused, GenericEffect.focused],
  [Effect.happy, GenericEffect.happy],
  [Effect.inspired, GenericEffect.inspired],
  [Effect.joints, GenericEffect.joints],
  [Effect.medicinal, GenericEffect.medicinal],
  [Effect.mind, GenericEffect.mind],
  [Effect.muscles, GenericEffect.muscles],
  [Effect.painRelief, GenericEffect.painRelief],
  [Effect.painRelief2, GenericEffect.painRelief],
  [Effect.relaxed, GenericEffect.relaxed],
  [Effect.skinHealth, GenericEffect.skinHealth],
  [Effect.skinHealth2, GenericEffect.skinHealth],
  [Effect.sleepy, GenericEffect.sleepy],
  [Effect.uplifted, GenericEffect.uplifted]
);

export const fromGenericEffect = FrozenMap.reverse(toGenericEffect);
