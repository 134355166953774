import React from 'react';

import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import useViewportVisibility from 'hooks/use-viewport-visibility';
import { GenericCarousel } from './generic-carousel';

export function StaffPicksCarousel(props) {
  const { ref, hasBeenVisible } = useViewportVisibility();
  const { loading, products } = useDispensaryProducts({
    filters: {
      sortBy: `popularSortIdx`,
      staffPicks: true,
    },
    perPage: 25,
  });

  if (!products?.length && !loading && hasBeenVisible) {
    return null;
  }

  const sectionProps = {
    ...props,
    hasBeenVisible,
    isLoading: loading,
    label: `Staff Picks`,
    products,
  };

  return <GenericCarousel ref={ref} {...sectionProps} />;
}
