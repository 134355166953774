import React from 'react';
import _times from 'lodash/times';
import styled from 'styled-components';

import { ProductCardLoadingSkeleton, LoadingSkeleton } from 'components/core';
import CarouselSlider from 'components/carousel-slider';
import { CardCarouselItem, GroupHeader, Section } from 'src/components/carousels';
import { CARD_SIZES } from 'components/product-card/product-card.constants';

const ProductSkeletonCarousel = (props) => (
  <Section noBorder {...props}>
    <GroupHeader>
      <LoadingSkeleton width={183} height={13} />
    </GroupHeader>
    <CarouselSlider>
      {_times(6, (key) => (
        <CardCarouselItem key={key}>
          <ProductCardLoadingSkeleton cardSize='large' dimensions={CARD_SIZES.default} />
        </CardCarouselItem>
      ))}
    </CarouselSlider>
    <StyledLine />
  </Section>
);

export default ProductSkeletonCarousel;

const StyledLine = styled.hr`
  background-color: ${({ theme }) => theme.colors.blueGrey[90]};
  height: 1px;
  border: none;
  margin: 30px 0 0;
`;
