import _ from 'lodash';
import { NextRouter } from 'next/router';

const getDispensaryPageVariant = (query: NextRouter['query'], asPath: NextRouter['asPath']): string => {
  if (query.productId) {
    return 'dispensaryProduct';
  }

  if (_.includes(asPath, '/brands')) {
    if (query.brandId) {
      return 'dispensaryBrand';
    }

    return 'dispensaryBrands';
  }

  if (_.includes(asPath, '/specials')) {
    if (query.specialId) {
      return 'dispensarySpecial';
    }

    return 'dispensarySpecials';
  }

  if (_.includes(asPath, '/info')) {
    return 'dispensaryInfo';
  }

  if (_.includes(asPath, '/categories')) {
    return 'dispensaryProductCategories';
  }

  if (_.includes(asPath, '/products') && query.category) {
    if (query.subcategory) {
      return 'dispensaryProductSubcategory';
    }

    return 'dispensaryProductCategory';
  }

  if (_.includes(asPath, '/products') && query.search) {
    return 'dispensarySearch';
  }

  if (_.includes(asPath, '/products') && !query.category && !query.subcategory && !query.search) {
    return 'dispensaryAllProducts';
  }

  return 'dispensary';
};

export const getPageVariant = (query: NextRouter['query'], asPath: NextRouter['asPath']): string => {
  if (query.cName) {
    return getDispensaryPageVariant(query, asPath);
  }

  if (query.checkoutId && query.dispensaryId) {
    return 'isolatedCheckout';
  }

  // [city] is the old city page param
  if (query.stateCodeAndCityName || query.city) {
    return 'city';
  }

  if (asPath === '/cities') {
    return 'cities';
  }

  if (asPath === '/') {
    return 'home';
  }

  if (asPath.startsWith('/curbside-arrivals/')) {
    return 'curbsideArrivals';
  }

  return 'default';
};

export const isDutchieMainProductDetailPage = (query: NextRouter['query'], asPath: NextRouter['asPath']): boolean => {
  const splitPath = asPath.split('/');
  const variant = getPageVariant(query, asPath);

  return splitPath[1] === 'dispensary' && variant === 'dispensaryProduct';
};
