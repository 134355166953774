import { MutableRefObject, Dispatch, SetStateAction, ChangeEvent, ReactNode } from 'react';

import { LocationObject } from 'shared/helpers/mapbox';

export enum SearchMode {
  userInput = 'userInput',
  geolocation = 'geolocation',
}

export type AddressAutocompleteContextValue = {
  inputRef: MutableRefObject<HTMLInputElement | undefined>;
  clearInput: () => void;
  displayValue: string;
  geolocationLoading: boolean;
  handleGeolocation: () => void;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  suggestions: LocationObject[];
};
export type AddressAutocompleteProps = {
  children: ReactNode;
  onSelect: (loc?: LocationObject) => void;
  suggestionTypes?: SuggestionTypes;
};

export type UseGeolocationReturn = {
  geolocationLoading: boolean;
  handleGeolocation: () => void;
  geolocationSuggestions: LocationObject[];
};

export type UseGeolocationArgs = {
  setSearchMode: Dispatch<SetStateAction<SearchMode>>;
};

export type SuggestionTypes =
  | 'address'
  | 'country'
  | 'district'
  | 'locality'
  | 'neighborhood'
  | 'place'
  | 'poi.landmark'
  | 'poi'
  | 'postcode'
  | 'region';
