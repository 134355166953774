import styled, { css } from 'styled-components';
import { Popover } from '@material-ui/core';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

export const buttonResetStyles = css`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: left;
`;

const popoverStyles = css<{ $offsetTop: boolean }>`
  .MuiPopover-paper {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px 0 #00000033;
    margin-top: ${({ $offsetTop }) => ($offsetTop ? '8px' : 0)};
    min-width: 187px;
    padding: 8px 0;
  }
`;

export const StyledPopover = styled(Popover)`
  ${popoverStyles}
`;

const popoverNavItemStyles = css<{ $isActive: boolean }>`
  color: #47494c;
  display: block;
  font-size: 14px;
  font-weight: ${({ $isActive }) => ($isActive ? 700 : 400)};
  line-height: ${16 / 14};
  padding: 12px 24px;
  text-align: left;
  transition: all 0.2s;
  width: 100%;
`;

const popoverNavItemHoverStyles = css`
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.colors.grey[95]};
  }
`;

export const PopoverNavItem = styled.div`
  ${popoverNavItemStyles}
`;

export const PopoverNavButton = styled.button`
  ${buttonResetStyles}
  ${popoverNavItemStyles}
  ${popoverNavItemHoverStyles}
`;

export const PopoverNavLink = styled.a`
  ${popoverNavItemStyles}
  ${popoverNavItemHoverStyles}
`;

export const StyledHoverPopover = styled(HoverPopover)`
  ${popoverStyles}
`;

export const DesktopMq = styled.div`
  @media (max-width: 879px) {
    display: none;
  }
`;

export const MobileMq = styled.div`
  @media (min-width: 880px) {
    display: none;
  }
`;

export const InfoBarMq = styled.div`
  @media (min-width: 1020px) {
    display: none;
  }
`;
