import window from 'global/window';
import { isBrowser } from 'utils/misc-utils';

type SiftEvent = [string, string?];
type SiftQueue = SiftEvent[];

export const getSiftQueue = (): SiftQueue => {
  if (!isBrowser()) {
    return [];
  }

  if (window._sift) {
    return window._sift;
  }

  window._sift = [];

  return window._sift;
};
