import styled from 'styled-components';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ChevronIcon from 'assets/chevron-icon';
import { paragraph } from 'components/core/typography';

const defaultMenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

const StyledChevronIcon = styled(ChevronIcon).attrs({
  height: `7px`,
  width: `12px`,
})`
  margin-top: 9px;
  margin-right: 8px;
  fill: ${({ theme }) => theme.colors.grey[45]};
`;

const StyledSelect = styled(Select).attrs((props) => ({
  IconComponent: StyledChevronIcon,
  MenuProps: { ...defaultMenuProps, ...props.MenuProps },
}))`
  border: 1px solid ${({ theme }) => theme.colors.grey[70]} !important;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 21px !important;

  &.Mui-focused {
    border-color: ${({ theme }) => theme.colors.grey[70]} !important;
  }

  &.MuiOutlinedInput-root {
    padding: 0;
  }
  .MuiSelect-root {
    height: 34px;
    width: 100%;
    padding: 0;
    padding-left: 15px;
    border-color: ${({ theme }) => theme.colors.grey[45]} !important;
    color: ${({ theme }) => theme.colors.primaryBlack};
    line-height: 20px;
    ${paragraph};
    border-radius: 21px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root:hover {
    background-color: ${({ theme }) => theme.colors.blueGrey[95]};
  }

  & *:focus {
    background-color: transparent !important;
  }
`;

const Option = styled(MenuItem)`
  font-size: 13px !important;
`;

export { StyledSelect as Select, Option };
