/* eslint-disable max-len */
import React from 'react';

export default function SuccessIconKiosk(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='57'
      height='59'
      viewBox='0 0 57 59'
      {...props}
    >
      <defs>
        <path id='a' d='M42.398 8.42L40 10.725 44.437 15 52 7.725 50.32 6l-5.763 4.615z' />
        <filter id='b' width='133.3%' height='144.4%' x='-16.7%' y='-11.1%'>
          <feOffset dy='1' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='0.5' />
          <feColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.127660779 0' />
        </filter>
        <mask id='c' fill='#fff'>
          <path fillRule='evenodd' d='M0 0h22v21H0z' />
        </mask>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#5ea68c'
          d='M50.525 15.032v-.28l-.2-.36-7.8-10.08a2.51 2.51 0 00-2.04-1H9.765a2.51 2.51 0 00-2.04 1l-7.68 10.08v.64c-.06.288-.06.588 0 .88v35.4a7.593 7.593 0 007.64 7.556h36.04a7.599 7.599 0 007-7.556v-35.4a2.16 2.16 0 00-.2-.88'
        />
        <path fill='#b8d3c7' d='M39.045 8.35l4 5.04h-36l4-5.04z' />
        <path
          fill='#eefff8'
          d='M45.603 18.43v32.88c-.04 1.4-1.156 2.52-2.56 2.52h-36c-1.32-.076-2.4-1.2-2.4-2.52V18.43z'
        />
        <path fill='#e1efe8' d='M39.045 8.35l2 2.52h-32l2-2.52z' />
        <path
          fill='#b6d6c6'
          d='M12.32 26.233s-.66 14.952 12.804 14.952S37.92 26.233 37.92 26.233l-2.768 2.58s-2.832 10.02-10.032 10.02-12.8-12.6-12.8-12.6'
        />
        <path
          fill='#5ea68c'
          d='M25.12 38.834c7.009.044 12.733-5.592 12.8-12.6a2.56 2.56 0 00-2.563-2.56 2.568 2.568 0 00-2.56 2.56 7.679 7.679 0 11-15.356 0 2.557 2.557 0 00-2.56-2.56 2.557 2.557 0 00-2.56 2.56c.064 7.008 5.792 12.644 12.8 12.6'
        />
        <path
          fill='#4597e0'
          d='M22 10.502C22 16.298 17.075 21 11 21S0 16.298 0 10.502C0 4.702 4.925 0 11 0s11 4.702 11 10.502'
          mask='url(#c)'
          transform='translate(35)'
        />
        <path fill='#3383aa' d='M40 11v1l4.442 4.537L52 9V8l-7.443 3.34z' />
        <use fill='#000' filter='url(#b)' xlinkHref='#a' />
        <use fill='#fff' xlinkHref='#a' />
      </g>
    </svg>
  );
}
