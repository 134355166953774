/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export function OrderBagIcon(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='118' height='93' viewBox='0 0 118 93' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M117.802 4.32568V83.6086H0V4.32568H117.802Z' fill='#E3E6E7' />
      <rect x='50.2397' y='22.9558' width='14.0053' height='14.2815' rx='2.55816' fill='white' />
      <rect x='71.2476' y='22.9558' width='14.0053' height='14.2815' rx='2.55816' fill='white' />
      <rect x='50.2397' y='43.0991' width='14.0053' height='14.2815' rx='2.55816' fill='#E4EDF0' />
      <rect x='29.2319' y='22.9558' width='14.0053' height='14.2815' rx='2.55816' fill='white' />
      <rect x='29.2319' y='43.0991' width='14.0053' height='14.2815' rx='2.55816' fill='#E4EDF0' />
      <rect x='71.2476' y='44.3789' width='14.0053' height='14.2815' rx='2.55816' fill='white' />
      <rect x='71.2476' y='63.1211' width='14.0053' height='14.2815' rx='2.55816' fill='#E4EDF0' />
      <rect x='91.6665' y='22.9558' width='14.0053' height='14.2815' rx='2.55816' fill='white' />
      <rect x='91.6665' y='44.3789' width='14.0053' height='14.2815' rx='2.55816' fill='white' />
      <rect x='91.6665' y='64.3994' width='14.0053' height='14.2815' rx='2.55816' fill='#E1EAEE' />
      <path
        d='M113.752 10.2046C113.232 9.45895 112.363 9.04975 111.469 9.12914H30.6285C29.0787 9.12914 27.8798 10.1499 28.3994 11.2574C28.9191 12.002 29.7869 12.4116 30.6795 12.3329H111.519C113.073 12.3329 114.273 11.3121 113.752 10.2046Z'
        fill='white'
      />
      <path
        d='M6.28279 13.1349C7.58399 13.1349 8.63882 12.059 8.63882 10.7318C8.63882 9.40454 7.58399 8.32861 6.28279 8.32861C4.98159 8.32861 3.92676 9.40454 3.92676 10.7318C3.92676 12.059 4.98159 13.1349 6.28279 13.1349Z'
        fill='white'
      />
      <path
        d='M13.3507 13.1349C14.6519 13.1349 15.7067 12.059 15.7067 10.7318C15.7067 9.40454 14.6519 8.32861 13.3507 8.32861C12.0495 8.32861 10.9946 9.40454 10.9946 10.7318C10.9946 12.059 12.0495 13.1349 13.3507 13.1349Z'
        fill='white'
      />
      <path
        d='M20.419 13.1349C21.7202 13.1349 22.7751 12.059 22.7751 10.7318C22.7751 9.40454 21.7202 8.32861 20.419 8.32861C19.1178 8.32861 18.063 9.40454 18.063 10.7318C18.063 12.059 19.1178 13.1349 20.419 13.1349Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.593 26.6426H9.89537C8.9961 26.6426 8.26709 25.8994 8.26709 24.9827C8.26709 24.0659 8.9961 23.3228 9.89537 23.3228H15.593C16.4923 23.3228 17.2213 24.0659 17.2213 24.9827C17.2213 25.8994 16.4923 26.6426 15.593 26.6426ZM17.22 34.1123H9.89537C8.9961 34.1123 8.26709 33.3691 8.26709 32.4524C8.26709 31.5356 8.9961 30.7924 9.89537 30.7924H17.22C17.8017 30.7924 18.3393 31.1088 18.6301 31.6224C18.921 32.136 18.921 32.7687 18.6301 33.2823C18.3393 33.7959 17.8017 34.1123 17.22 34.1123ZM9.89537 49.3279H17.22C18.1193 49.3279 18.8483 48.5847 18.8483 47.6679C18.8483 46.7512 18.1193 46.008 17.22 46.008H9.89537C8.9961 46.008 8.26709 46.7512 8.26709 47.6679C8.26709 48.5847 8.9961 49.3279 9.89537 49.3279ZM15.594 41.5806H9.8963C9.01536 41.5552 8.31418 40.8198 8.31418 39.9214C8.31418 39.0229 9.01536 38.2876 9.8963 38.2621H15.594C16.4749 38.2876 17.1761 39.0229 17.1761 39.9214C17.1761 40.8198 16.4749 41.5552 15.594 41.5806ZM9.89537 56.7976H15.593C16.4923 56.7976 17.2213 56.0544 17.2213 55.1376C17.2213 54.2209 16.4923 53.4777 15.593 53.4777H9.89537C8.9961 53.4777 8.26709 54.2209 8.26709 55.1376C8.26709 56.0544 8.9961 56.7976 9.89537 56.7976Z'
        fill='white'
      />
      <path
        opacity='0.3'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.6192 30.6899H56.1821C56.6337 30.6899 57.0001 31.1063 57.0001 31.62V34.5262H41.7998V31.62C41.7998 31.1063 42.1663 30.6899 42.6192 30.6899ZM44.3339 34.5262H54.4674V40.6215C54.4674 41.4928 53.868 42.1987 53.1284 42.1987H45.6729C44.9333 42.1987 44.3339 41.4915 44.3339 40.6215V34.5262ZM75.2502 30.6899H62.8182C62.4033 30.6899 62.0669 31.1063 62.0669 31.62V34.5262H76.0015V31.62C76.0015 31.1063 75.6651 30.6899 75.2502 30.6899ZM44.3339 57.5437H54.4674V63.9259C54.4674 64.6384 53.868 65.2162 53.1284 65.2162H45.6729C44.9333 65.2162 44.3339 64.6384 44.3339 63.9259V57.5437ZM56.1821 52.4292H42.6192C42.1663 52.4292 41.7998 52.8455 41.7998 53.3592V56.2654H57.0001V53.3592C57.0001 52.8455 56.6337 52.4292 56.1821 52.4292ZM63.334 57.5437H73.4676V63.9259C73.4676 64.6384 72.8681 65.2162 72.1285 65.2162H64.673C63.9335 65.2162 63.334 64.6384 63.334 63.9259V57.5437ZM75.2502 52.4292H62.8182C62.4033 52.4292 62.0669 52.8455 62.0669 53.3592V56.2654H76.0015V53.3592C76.0015 52.8455 75.6651 52.4292 75.2502 52.4292Z'
        fill='#BDCCCE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M79.8301 34.1274V34.2928H79.8315L80.194 35.1655C82.2791 52.5694 85.4584 85.0074 85.4257 86.5819L85.4192 86.9555H21.5327V86.5726C24.1597 62.0126 29.9189 27.6451 38.2867 24.3306L38.3051 25.254L39.6297 24.2959H78.6888C82.0959 24.2959 84.8681 25.338 84.8681 28.4991C84.7935 31.3359 82.6692 33.7111 79.8301 34.1274ZM37.5224 25.8211C37.3679 25.8184 37.1742 25.8184 37.2684 25.8251L37.4805 25.8518L37.5224 25.8211Z'
        fill='white'
      />
      <path d='M38.0013 23.6579H39.2657C39.3076 23.6512 37.9568 23.6579 38.0013 23.6579Z' fill='#E4EDF0' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.6675 31.2704C31.6675 35.0489 36.0069 35.8044 40.3468 35.8045L40.0458 31.145C41.2332 30.007 41.8723 28.3959 41.7945 26.7363C41.7945 23.714 38.9018 22.9574 37.0327 23.0215C35.1623 23.0842 31.6675 27.4929 31.6675 31.2704ZM78.3327 77.8018C79.5945 73.7628 80.1324 69.4862 79.9164 65.2163L83.601 86.9555H73.4675C75.6551 84.254 77.3074 81.1453 78.3327 77.8018Z'
        fill='#C9CDCF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.59961 88.6595C7.59961 89.6002 8.18338 90.7918 23.3589 90.7918L83.6013 90.7558L79.6432 86.9556H27.1102C13.8562 86.9556 7.59961 87.7188 7.59961 88.6595ZM111.468 90.3675C111.468 89.4254 111.035 88.2338 99.7755 88.2338L91.2008 88.2699V92.0701H96.9914C106.827 92.0701 111.468 91.3082 111.468 90.3675Z'
        fill='#E3E6E7'
      />
      <path
        d='M85.1616 88.2339C73.9023 88.2339 73.4678 89.4255 73.4678 90.3675C73.4678 91.3082 78.1105 92.0701 87.9443 92.0701H93.7349V88.2699L85.1616 88.2339Z'
        fill='#E3E6E7'
      />
      <path
        d='M35.2294 30.6899C36.4572 33.3453 38.5933 29.026 39.376 32.2751C42.6208 45.7467 45.6012 79.0307 45.6012 86.9554C42.1117 86.8887 36.4231 86.822 32.9336 86.7539C32.9336 86.7539 34.0815 33.5081 35.2294 30.6899Z'
        fill='#E3E6E7'
      />
      <path d='M39.2671 37.2671V34.5264H79.8013L81.1521 38.3626L39.2671 37.2671Z' fill='#A0A8AB' />
      <path
        d='M45.7281 86.9553C45.1414 86.9525 44.6524 86.4966 44.5972 85.9012C44.5514 85.4395 40.0684 39.5726 38.0658 33.528C37.929 33.1312 38.0104 32.6902 38.2791 32.3712C38.5465 32.0519 38.9627 31.9058 39.3662 31.9896C39.7696 32.0733 40.0967 32.3737 40.2202 32.7741C42.304 39.0722 46.6771 83.7716 46.8616 85.669C46.8945 85.9966 46.7901 86.3231 46.5743 86.568C46.3584 86.813 46.0511 86.9536 45.7281 86.9553Z'
        fill='#153F66'
      />
      <path
        d='M86.1041 88.2311C85.8823 88.2475 85.662 88.1832 85.4823 88.0497H24.2817C23.5197 88.0507 22.7908 87.7323 22.266 87.169C21.7486 86.6129 21.4857 85.8598 21.5422 85.0954C22.249 75.6643 26.2752 28.3456 36.1732 23.0176L37.2897 25.0912C35.287 26.168 31.3839 31.2292 27.715 53.015C25.3014 67.3419 24.1064 82.3987 23.8904 85.2716C23.8821 85.3814 23.9202 85.4897 23.9951 85.5691C24.0697 85.6492 24.1744 85.6946 24.2831 85.6932H84.9117L84.8973 85.5371C82.9719 65.2964 81.1695 47.8258 79.5438 33.6096L81.8842 33.3414C83.5124 47.5736 85.3148 65.0642 87.2428 85.3143L87.2795 85.6919H87.2926L87.3855 86.7674C87.3855 86.8034 87.3907 86.8381 87.392 86.8741V86.9035C87.4321 87.2205 87.3416 87.5403 87.142 87.7868C86.9401 88.0363 86.648 88.1921 86.3318 88.2191C86.2481 88.2285 86.1748 88.2325 86.1041 88.2325V88.2311Z'
        fill='#153F66'
      />
      <path
        d='M79.906 33.2479H40.4294C39.788 33.2479 39.2671 32.6741 39.2671 31.9682C39.2671 31.2624 39.7867 30.6899 40.4281 30.6899H79.906C80.5474 30.6899 81.0671 31.2637 81.0671 31.9682C81.0671 32.6754 80.5474 33.2479 79.906 33.2479Z'
        fill='#153F66'
      />
      <path
        d='M80.9531 70.26H103.983V86.3629C103.983 88.1416 102.609 89.584 100.913 89.584H84.0238C82.3275 89.584 80.9531 88.1416 80.9531 86.3629V70.26Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M84.0238 90.7917H100.913C103.244 90.789 105.133 88.8075 105.135 86.363V70.2601C105.135 69.5929 104.619 69.0525 103.983 69.0525H80.9531C80.317 69.0525 79.8013 69.5929 79.8013 70.2601V86.363C79.8039 88.8075 81.6926 90.789 84.0238 90.7917ZM82.1037 86.363V71.4677H102.832V86.363C102.832 87.4745 101.973 88.3752 100.913 88.3752H84.0239C82.9636 88.3752 82.1037 87.4745 82.1037 86.363Z'
        fill='#153F66'
      />
      <path
        d='M36.6587 23.59C39.804 23.5193 42.4166 25.8384 42.4964 28.7726C42.4964 31.6335 40.677 33.9539 37.4519 33.9539H81.244C83.2938 34.046 85.2309 33.0786 86.2833 31.4373C87.3374 29.8229 87.3374 27.721 86.2833 26.1066C85.2309 24.4653 83.2938 23.4979 81.244 23.59H37.4519'
        fill='#C9CDCF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.4505 35.6083H81.2439C83.7203 35.7017 86.0528 34.5235 87.3225 32.5366C88.5908 30.5829 88.5908 28.0455 87.3225 26.0917C86.0541 24.1076 83.7203 22.9293 81.2439 23.0227H37.4505C37.3745 23.0227 37.2986 23.0294 37.2227 23.0428C37.0355 23.0294 36.8471 23.0227 36.6559 23.0227C35.9989 23.0227 35.4648 23.5191 35.4648 24.1329C35.4648 24.7467 35.9989 25.2431 36.6559 25.2431C39.1429 25.1777 41.2201 26.9978 41.3026 29.3155C41.3026 31.193 40.2934 33.3866 37.4505 33.3866C36.7934 33.3866 36.2594 33.8843 36.2594 34.4981C36.2594 35.1106 36.7934 35.6083 37.4505 35.6083ZM81.2439 33.3853H42.3562C43.2478 32.2258 43.7177 30.7872 43.6861 29.3142C43.6822 27.8117 43.0866 26.3626 42.0107 25.2418H81.2439C82.8696 25.1457 84.418 25.901 85.2622 27.2006C86.1062 28.4783 86.1062 30.1501 85.2622 31.4278C84.418 32.7275 82.8696 33.4814 81.2439 33.3853Z'
        fill='#153F66'
      />
      <path
        d='M81.2895 62.4995H104.913C105.782 62.4995 106.487 63.1667 106.487 63.9913V69.2126H79.7148V63.9913C79.7148 63.1667 80.4203 62.4995 81.2895 62.4995Z'
        fill='#C9CDCF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M79.7153 70.3307H106.488C106.796 70.3327 107.095 70.2159 107.323 70.0038C107.541 69.8007 107.666 69.5138 107.668 69.2125V63.9912C107.668 62.5488 106.434 61.3799 104.913 61.3799H81.2899C79.769 61.3799 78.5347 62.5488 78.5347 63.9912V69.2125C78.5347 69.8303 79.0635 70.3307 79.7153 70.3307ZM105.306 68.093H80.8972V63.9912C80.8972 63.7844 81.0726 63.6176 81.2899 63.6176H104.913C105.13 63.6176 105.306 63.7844 105.306 63.9912V68.093Z'
        fill='#153F66'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.1339 70.3307C85.4349 70.3307 84.8682 69.8303 84.8682 69.2125V62.4994C84.8682 61.8803 85.4349 61.3799 86.1339 61.3799C86.8341 61.3799 87.4009 61.8803 87.4009 62.4994V69.2112C87.4009 69.8303 86.8341 70.332 86.1339 70.332V70.3307ZM92.4677 70.3307C91.7687 70.3307 91.2007 69.8303 91.2007 69.2125V62.4994C91.2007 61.8803 91.7687 61.3799 92.4677 61.3799C93.168 61.3799 93.7347 61.8803 93.7347 62.4994V69.2112C93.7347 69.8303 93.168 70.332 92.4677 70.332V70.3307ZM98.8015 69.2125C98.8015 69.8303 99.3682 70.3307 100.069 70.3307V70.332C100.767 70.332 101.334 69.8303 101.334 69.2112V62.4994C101.334 61.8803 100.767 61.3799 100.069 61.3799C99.3682 61.3799 98.8015 61.8803 98.8015 62.4994V69.2125Z'
        fill='#153F66'
      />
      <path d='M82.1865 77.8765H92.6185V83.2472H82.1865L82.1865 77.8765Z' fill='#FF3E51' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M81.0674 84.3976H93.735V76.7251H81.0674V84.3976ZM91.4994 82.0958H83.303V79.0268H91.4994V82.0958Z'
        fill='#153F66'
      />
      <path d='M82.0854 72.355V70.5283H102.832V73.1143L82.0854 72.355Z' fill='#ADB4B7' />
      <path
        d='M37.4949 34.6063C36.9452 34.629 36.4216 34.3768 36.101 33.9338C35.781 33.4943 35.7072 32.9171 35.9059 32.4086L41.0526 31.0103L37.4949 34.6063Z'
        fill='#153F66'
      />
      <path
        d='M37.4937 35.8046C36.6182 35.7967 35.7855 35.4177 35.1953 34.7584C34.6328 34.0165 34.4581 33.0426 34.7267 32.1458C34.828 31.7074 35.159 31.3623 35.5866 31.2491L41.8 29.4117C42.3288 29.2663 41.821 29.9321 42.0984 30.3991C42.3755 30.8668 42.2995 31.4675 41.9152 31.8482L39.2673 35.8046C39.0395 36.0354 37.8183 35.8046 37.4937 35.8046Z'
        fill='#153F66'
      />
      <path
        d='M33.0946 86.9556C32.3092 86.9556 31.6718 86.4458 31.6718 85.8174C31.6665 84.5444 31.5658 54.5589 32.6273 45.7843C32.677 45.3799 32.9925 45.0263 33.4545 44.8582C33.9213 44.6902 34.4359 44.7304 34.8721 44.969C35.2857 45.2051 35.5095 45.6001 35.4611 46.0044C34.4126 54.6643 34.5186 85.4998 34.5186 85.8094C34.5186 86.4378 33.8812 86.9476 33.0946 86.9476V86.9556Z'
        fill='#153F66'
      />
    </svg>
  );
}
