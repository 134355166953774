import { useEffect, useState } from 'react';

import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';

type UseDistanceFilterBottomDrawerProps = {
  onClose: () => void;
  open: boolean;
};

type UseDistanceFilterBottomDrawerReturn = {
  distance: number;
  handleDistanceChange: (newDistance: number) => void;
  handleSave: () => void;
};

export function useDistanceFilterBottomDrawer({
  onClose,
  open,
}: UseDistanceFilterBottomDrawerProps): UseDistanceFilterBottomDrawerReturn {
  const { setQueryParams, queryParams } = useDispensariesQueryParams();

  const [distance, setDistance] = useState<number>(15);

  useEffect(() => {
    if (open) {
      setDistance(queryParams.distance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]); // FIXME: ENG-32714 fix hooks dependency

  function handleDistanceChange(newDistance: number): void {
    setDistance(newDistance);
  }

  function handleSave(): void {
    setQueryParams({ distance, distActive: true });
    onClose();
  }

  return {
    distance,
    handleDistanceChange,
    handleSave,
  };
}
