import React from 'react';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { DesktopOnly, MobileOnly } from 'shared/components';

import { ModalContainer, BottomDrawer } from 'src/components/core';
import { withModalControlsHook, ModalComponentProps } from 'src/components/modals/with-modal-controls-hook';
import { Loyalty } from 'src/components/loyalty/modal';

const LoyaltyModal = ({ onClose, containerProps }: ModalComponentProps): JSX.Element => (
  <>
    <DesktopOnly mediaQuery='sm'>
      <ModalWrapper open>
        <ModalContainer width='470px' p='32px' {...containerProps}>
          <Loyalty onClose={onClose} />
        </ModalContainer>
      </ModalWrapper>
    </DesktopOnly>
    <MobileOnly mediaQuery='xs'>
      <BottomDrawer open onClose={onClose} shouldCalculateIframeBottom {...containerProps}>
        <Loyalty onClose={onClose} />
      </BottomDrawer>
    </MobileOnly>
  </>
);

const [LoyaltyController, useLoyaltyModal] = withModalControlsHook(LoyaltyModal);

export { useLoyaltyModal, LoyaltyController };
