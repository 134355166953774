import _toLower from "lodash/toLower";
import _find from "lodash/find";
export var isAdvancedRewardEnabled = function isAdvancedRewardEnabled(_ref) {
  var discountBundle = _ref.discountBundle,
      discountToCart = _ref.discountToCart,
      itemsForAPrice = _ref.itemsForAPrice;
  return (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.enabled) || (discountToCart === null || discountToCart === void 0 ? void 0 : discountToCart.enabled) || (itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.enabled);
};
export var doRewardsHaveRestrictions = function doRewardsHaveRestrictions(v4Rewards) {
  return (v4Rewards === null || v4Rewards === void 0 ? void 0 : v4Rewards.length) && !!_find(v4Rewards, function (reward) {
    var _reward$inclusions, _reward$exclusions;

    return ((_reward$inclusions = reward.inclusions) === null || _reward$inclusions === void 0 ? void 0 : _reward$inclusions.length) || ((_reward$exclusions = reward.exclusions) === null || _reward$exclusions === void 0 ? void 0 : _reward$exclusions.length);
  });
}; // TODO: do we even need this function for future ecomm 4.0 specials?

export var shouldApplyRewardsToConditions = function shouldApplyRewardsToConditions(_ref2) {
  var discountBundle = _ref2.discountBundle,
      itemsForAPrice = _ref2.itemsForAPrice,
      source = _ref2.source;
  return source !== 'POS' && ((discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.enabled) || (itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.enabled));
};
export var shouldUseWeightRestrictions = function shouldUseWeightRestrictions(weights, weightOperator) {
  return (weights === null || weights === void 0 ? void 0 : weights.length) >= 1 && weightOperator === 'equalTo' && _toLower(weights[0]) !== 'any weight';
};
export var shouldUseWeightThreshold = function shouldUseWeightThreshold() {
  var weights = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var weightOperator = arguments.length > 1 ? arguments[1] : undefined;
  return !shouldUseWeightRestrictions(weights, weightOperator) && _toLower(weights[0]) !== 'any weight';
};