import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import chunk from 'lodash/chunk';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FooterHeader } from 'components/core/footer-rebrand/footer';

export function FooterList(props) {
  const { title, children, chunkSize = 6 } = props;
  const childList = chunk(children, chunkSize);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));
  function renderGroup(group, i) {
    return <FooterListContainer key={i}>{group}</FooterListContainer>;
  }

  return (
    <Container isMobile={isMobile}>
      <FooterHeader>{title}</FooterHeader>

      <FooterListsWrapper>{_.map(childList, renderGroup)}</FooterListsWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: ${({ isMobile }) => isMobile && `33%`};
`;

export const FooterListsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: #e3e6e7;
`;

export const FooterListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 140px;
  margin-top: 4px;

  a {
    margin-bottom: 8px;
  }
`;
