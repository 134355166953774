import { ProductCategory } from 'types/graphql';
import { FrozenMap } from '../helpers';

import { GenericCategoryRoute } from '../generic-category';

export const toGenericCategoryRoute = new FrozenMap<ProductCategory, GenericCategoryRoute>(
  [ProductCategory.accessories, GenericCategoryRoute.accessories],
  [ProductCategory.apparel, GenericCategoryRoute.apparel],
  [ProductCategory.cbd, GenericCategoryRoute.cbd],
  [ProductCategory.clones, GenericCategoryRoute.clones],
  [ProductCategory.concentrate, GenericCategoryRoute.concentrate],
  [ProductCategory.edible, GenericCategoryRoute.edible],
  [ProductCategory.flower, GenericCategoryRoute.flower],
  [ProductCategory.nA, GenericCategoryRoute.nA],
  [ProductCategory.preRolls, GenericCategoryRoute.preRolls],
  [ProductCategory.seeds, GenericCategoryRoute.seeds],
  [ProductCategory.tincture, GenericCategoryRoute.tincture],
  [ProductCategory.topicals, GenericCategoryRoute.topicals],
  [ProductCategory.vaporizers, GenericCategoryRoute.vaporizers]
);

export const fromGenericCategoryRoute = FrozenMap.reverse(toGenericCategoryRoute);
