import React from 'react';
import { RethinkPayOrderProcessingModal } from 'src/payments/modals/rethink-pay/order-processing-modal';
import noop from 'lodash/noop';
import { ModalObserver } from 'components/modals/modal-observer';
import { ModalWrapper } from 'shared/modals/v2-wrapper';

export const RethinkPayOrderProcessing = ({ name }: { name: string }): JSX.Element => (
  <ModalObserver name={name}>
    {(props) => (
      <ModalWrapper {...props} onClose={() => noop}>
        <RethinkPayOrderProcessingModal {...props} />
      </ModalWrapper>
    )}
  </ModalObserver>
);
