import React from 'react';

const SvgUserIcon = (props) => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.5 24.9998C10.5305 24.9819 8.59201 24.5079 6.83656 23.6148C5.08111 22.7218 3.55652 21.4341 2.38245 19.8527C0.823197 17.7192 -0.0116992 15.1424 0.00012386 12.4999C0.00012386 9.1847 1.31707 6.00531 3.66125 3.66113C6.00543 1.31695 9.18483 0 12.5 0C15.8152 0 18.9946 1.31695 21.3387 3.66113C23.6829 6.00531 24.9999 9.1847 24.9999 12.4999C25.0117 15.1424 24.1768 17.7192 22.6175 19.8527C21.4529 21.4439 19.9302 22.7389 18.1727 23.633C16.4151 24.5271 14.4719 24.9953 12.5 24.9998Z'
      fill='#EAEFF2'
    />
    <path
      d='M19.8522 19.8528C19.8522 22.5601 16.6956 24.0194 12.4994 24.0194C8.30311 24.0194 5.14648 22.5601 5.14648 19.8528C5.14648 17.1456 8.30311 13.9705 12.4994 13.9705C16.6956 13.9705 19.8522 17.1456 19.8522 19.8528Z'
      fill='#BCCAD2'
    />
    <path
      d='M12.5 24.9998C10.5305 24.9819 8.59201 24.5079 6.83656 23.6148C5.08111 22.7218 3.55652 21.4341 2.38245 19.8527C0.823197 17.7192 -0.0116992 15.1424 0.00012386 12.4999C0.00012386 9.1847 1.31707 6.00531 3.66125 3.66113C6.00543 1.31695 9.18483 0 12.5 0C15.8152 0 18.9946 1.31695 21.3387 3.66113C23.6829 6.00531 24.9999 9.1847 24.9999 12.4999C25.0117 15.1424 24.1768 17.7192 22.6175 19.8527C21.4529 21.4439 19.9302 22.7389 18.1727 23.633C16.4151 24.5271 14.4719 24.9953 12.5 24.9998ZM12.5 1.47057C9.57604 1.47446 6.77296 2.63773 4.7054 4.70528C2.63785 6.77283 1.47459 9.57592 1.4707 12.4999C1.46239 14.8252 2.19749 17.0924 3.56872 18.9704C4.60243 20.3703 5.94733 21.5108 7.4973 22.302C9.04726 23.0931 10.7599 23.5132 12.5 23.5292C14.242 23.5236 15.9583 23.108 17.5099 22.316C19.0615 21.524 20.4048 20.3779 21.4313 18.9704C22.8025 17.0924 23.5376 14.8252 23.5293 12.4999C23.5254 9.57592 22.3622 6.77283 20.2946 4.70528C18.227 2.63773 15.424 1.47446 12.5 1.47057Z'
      fill='#5E6D79'
    />
    <path
      d='M12.4997 11.5195C11.7241 11.5195 10.9659 11.2895 10.321 10.8586C9.67608 10.4277 9.17345 9.81519 8.87664 9.09863C8.57982 8.38206 8.50216 7.59357 8.65348 6.83287C8.80479 6.07217 9.17828 5.37342 9.72672 4.82498C10.2752 4.27655 10.9739 3.90306 11.7346 3.75174C12.4953 3.60043 13.2838 3.67809 14.0004 3.9749C14.7169 4.27171 15.3294 4.77435 15.7603 5.41924C16.1912 6.06413 16.4212 6.82232 16.4212 7.59792C16.4212 8.63798 16.008 9.63543 15.2726 10.3709C14.5372 11.1063 13.5397 11.5195 12.4997 11.5195Z'
      fill='white'
    />
    <path
      d='M12.4997 11.5195C11.7241 11.5195 10.9659 11.2895 10.321 10.8586C9.67608 10.4277 9.17345 9.81519 8.87664 9.09863C8.57982 8.38206 8.50216 7.59357 8.65348 6.83287C8.80479 6.07217 9.17828 5.37342 9.72672 4.82498C10.2752 4.27655 10.9739 3.90306 11.7346 3.75174C12.4953 3.60043 13.2838 3.67809 14.0004 3.9749C14.7169 4.27171 15.3294 4.77435 15.7603 5.41924C16.1912 6.06413 16.4212 6.82232 16.4212 7.59792C16.4212 8.63798 16.008 9.63543 15.2726 10.3709C14.5372 11.1063 13.5397 11.5195 12.4997 11.5195ZM12.4997 5.14697C12.0149 5.14697 11.541 5.29071 11.138 5.56003C10.7349 5.82934 10.4208 6.21213 10.2353 6.65998C10.0498 7.10784 10.0012 7.60064 10.0958 8.07608C10.1904 8.55152 10.4238 8.98824 10.7666 9.33101C11.1093 9.67378 11.5461 9.90721 12.0215 10.0018C12.4969 10.0964 12.9897 10.0478 13.4376 9.86231C13.8855 9.6768 14.2682 9.36266 14.5376 8.9596C14.8069 8.55654 14.9506 8.08268 14.9506 7.59792C14.9506 6.94789 14.6924 6.32448 14.2327 5.86484C13.7731 5.40519 13.1497 5.14697 12.4997 5.14697Z'
      fill='#5E6D79'
    />
    <path
      d='M20.8326 21.5145C20.6667 21.5144 20.5056 21.4582 20.3757 21.3549C20.2458 21.2517 20.1546 21.1075 20.117 20.9459C19.7141 19.2226 18.7403 17.6862 17.3538 16.5864C15.9672 15.4866 14.2495 14.8881 12.4798 14.8881C10.71 14.8881 8.99229 15.4866 7.60577 16.5864C6.21924 17.6862 5.24542 19.2226 4.84259 20.9459C4.8236 21.0428 4.78529 21.1349 4.72994 21.2166C4.67459 21.2984 4.60334 21.3682 4.52044 21.4218C4.43753 21.4755 4.34467 21.5119 4.2474 21.5288C4.15012 21.5458 4.05043 21.543 3.95426 21.5206C3.85809 21.4982 3.76742 21.4567 3.68765 21.3985C3.60788 21.3403 3.54065 21.2666 3.48997 21.1819C3.43929 21.0971 3.4062 21.003 3.39268 20.9052C3.37915 20.8074 3.38546 20.7079 3.41123 20.6126C3.89008 18.5667 5.04662 16.7429 6.69294 15.4374C8.33927 14.132 10.3787 13.4215 12.4798 13.4215C14.5809 13.4215 16.6203 14.132 18.2666 15.4374C19.9129 16.7429 21.0695 18.5667 21.5483 20.6126C21.5734 20.7204 21.5738 20.8324 21.5496 20.9404C21.5253 21.0484 21.477 21.1495 21.4081 21.2363C21.3393 21.323 21.2519 21.393 21.1522 21.4412C21.0526 21.4894 20.9433 21.5144 20.8326 21.5145Z'
      fill='#5E6D79'
    />
  </svg>
);

export default SvgUserIcon;
