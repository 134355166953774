import { useState, useRef, useEffect, RefObject } from 'react';
import { useObserver } from 'mobx-react-lite';
import useUser from 'hooks/use-user';
import { useIsMobile } from 'hooks/use-is-mobile';

type UseMyAccountLinkReturn = {
  showDropdown: boolean;
  toggleDropdown: () => void;
  isLoggedIn: boolean;
  anchorEl: HTMLButtonElement | null;
  myAccountRef: RefObject<HTMLButtonElement>;
  isMobile: boolean;
};

export function useMyAccountLink(): UseMyAccountLinkReturn {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [anchorEl, setAnchorEL] = useState<HTMLButtonElement | null>(null);
  const user = useUser();
  const isLoggedIn = useObserver(() => user?.isLoggedIn);
  const myAccountRef = useRef<HTMLButtonElement>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (showDropdown) {
      setAnchorEL(myAccountRef.current);
    } else {
      setAnchorEL(null);
    }
  }, [showDropdown, myAccountRef]);

  const toggleDropdown = (): void => setShowDropdown(!showDropdown);

  return {
    showDropdown,
    toggleDropdown,
    isLoggedIn,
    anchorEl,
    myAccountRef,
    isMobile,
  };
}
