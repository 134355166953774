import React, { useState, useEffect } from 'react';

import useTranslation from 'src/hooks/use-translation';
import { VisuallyHidden } from 'src/components/core/visually-hidden';
import { HybridSliderRef } from '../hybrid-carousel-slider/hybrid-carousel-slider';

import { ButtonPrev, ButtonNext } from './hybrid-carousel-buttons.styles';
import { ForwardArrow, BackArrow } from '../../carousel-slider/carousel-buttons';

type HybridCarouselButtonsProps = {
  startWatcher?: IntersectionObserverEntry;
  endWatcher?: IntersectionObserverEntry;
  sliderRef: React.RefObject<HybridSliderRef>;
};

export const HybridCarouselButtons = ({
  startWatcher,
  endWatcher,
  sliderRef,
}: HybridCarouselButtonsProps): JSX.Element => {
  const { t } = useTranslation();
  const [buttonsState, setButtonsState] = useState({
    visible: false,
    prevEnabled: false,
    nextEnabled: false,
  });

  useEffect(() => {
    if (startWatcher && endWatcher) {
      setButtonsState({
        visible: !startWatcher.isIntersecting || !endWatcher.isIntersecting,
        prevEnabled: !startWatcher.isIntersecting,
        nextEnabled: !endWatcher.isIntersecting,
      });
    }
  }, [startWatcher, endWatcher, setButtonsState]);

  const onButtonClick = (direction = 1): void => {
    const { viewportRef, cardsWrapperRef } = sliderRef.current ?? {};

    if (!viewportRef?.current || !cardsWrapperRef?.current) {
      return;
    }

    const { clientWidth: containerWidth, scrollLeft: containerLeft } = viewportRef.current;

    const elements = [...cardsWrapperRef.current.children].filter((child) => child.childNodes.length > 0);

    let target;
    let scrollToLeft = 0;
    const buffer = 20;

    if (direction === 1) {
      target = elements.find((el: HTMLElement) => {
        const { offsetLeft, clientWidth } = el;
        return Number(offsetLeft) + Number(clientWidth) - containerLeft > containerWidth;
      });

      if (!target) {
        return;
      }

      scrollToLeft = target.offsetLeft - buffer;
    } else if (direction === -1) {
      target = elements.reverse().find((el: HTMLElement) => {
        const { offsetLeft } = el;
        return offsetLeft < containerLeft;
      });

      if (!target) {
        return;
      }

      scrollToLeft = Number(target.offsetLeft) + Number(target.clientWidth) - containerWidth - buffer;
    }

    viewportRef.current.scrollTo({ left: scrollToLeft, behavior: 'smooth' });
  };

  return (
    <>
      <ButtonPrev
        className='carousel-prev-button'
        disabled={!buttonsState.prevEnabled}
        isVisible={buttonsState.visible}
        onClick={() => onButtonClick(-1)}
      >
        <VisuallyHidden>{t('carousel.previousButtonLabel', 'Previous')}</VisuallyHidden>
        <BackArrow />
      </ButtonPrev>

      <ButtonNext
        className='carousel-next-button'
        disabled={!buttonsState.nextEnabled}
        isVisible={buttonsState.visible}
        onClick={() => onButtonClick(1)}
      >
        <VisuallyHidden>{t('carousel.nextButtonLabel', 'Next')}</VisuallyHidden>
        <ForwardArrow />
      </ButtonNext>
    </>
  );
};
