export function isBrowser() {
  return typeof window !== 'undefined';
}

export function mergeWithoutNull(target, source) {
  Object.keys(source).forEach((key) => {
    if (key === '__proto__' || key === 'constructor') {
      return;
    }

    const sourceValue = source[key];
    const targetValue = target[key];

    // eslint-disable-next-line lodash/prefer-is-nil
    if (sourceValue !== null && sourceValue !== undefined) {
      // eslint-disable-next-line lodash/prefer-lodash-typecheck
      if (typeof sourceValue === 'object' && !Array.isArray(sourceValue) && sourceValue !== null) {
        target[key] = mergeWithoutNull(
          // eslint-disable-next-line lodash/prefer-lodash-typecheck
          targetValue && typeof targetValue === 'object' ? targetValue : {},
          sourceValue
        );
      } else {
        target[key] = sourceValue;
      }
    }
  });
  return target;
}
