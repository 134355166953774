import { Product } from 'src/utils/helpers/product';
import { normalizeEffectString } from 'src/utils/helpers/effects-filtering';

type UseFilterProductsQueryByEffectsParams = {
  effectsFromParams: string[];
  perPage: number;
  products: Product[];
  totalCount: number;
  totalPages: number;
};

function filterProductsByEffects(products: Product[], effectsFromParams: string[]): Product[] {
  const productsWithEffectsMatch: Product[] = [];

  products.forEach((product) => {
    const normalizedProductEffects = product.effects.map((effect) => normalizeEffectString(effect));
    const productEffectSet = new Set(normalizedProductEffects);
    const includeProduct = effectsFromParams.some((effect) => productEffectSet.has(effect));

    if (includeProduct) {
      productsWithEffectsMatch.push(product);
    }
  });

  return productsWithEffectsMatch;
}

export function useFilterProductsQueryByEffects(
  params: UseFilterProductsQueryByEffectsParams
): Omit<UseFilterProductsQueryByEffectsParams, 'effectsFromParams' | 'perPage'> {
  const { products, totalCount, totalPages, perPage, effectsFromParams } = params;

  if (effectsFromParams.length) {
    const productsFilteredByEffects = filterProductsByEffects(products, effectsFromParams);
    const adjustedTotalPages = Math.ceil(productsFilteredByEffects.length / perPage);

    return {
      products: productsFilteredByEffects,
      totalCount: productsFilteredByEffects.length,
      totalPages: adjustedTotalPages,
    };
  }

  return {
    products,
    totalCount,
    totalPages,
  };
}
