import React from 'react';
import styled from 'styled-components';

import { Button } from 'components/core';
import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import { reasonMapping } from './constants';
import { WhoopsProps, WhoopsReason } from './whoops.types';

export function WhoopsContent({ reason = WhoopsReason.default, children }: WhoopsProps): JSX.Element {
  const { t } = useTranslation();
  const { isIframe } = useUI();

  const mappedReason = reasonMapping[reason];
  const { topText, bottomText, onButtonClick, buttonText, IconComponent } = mappedReason;

  return (
    <Container>
      <IconComponent />
      <TopText>{topText(t)}</TopText>
      {bottomText && <BottomText>{bottomText(t)}</BottomText>}

      {!isIframe && buttonText && (
        <Button mt='30px' onClick={onButtonClick}>
          {buttonText(t)}
        </Button>
      )}

      {children}
    </Container>
  );
}

const Container = styled.div`
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const TopText = styled.p`
  margin-top: 35px;
  font-size: 18px;
  color: rgb(99, 118, 132);
  font-weight: bold;
  text-align: center;
`;

const BottomText = styled.p`
  margin-top: 10px;
  font-size: 13px;
  color: rgb(99, 118, 132);
  text-align: center;
  line-height: 20px;
`;
