import { useEffect, useMemo, useState } from 'react';
import { Product } from 'utils/helpers/product';
import {
  aggregateWeightOptions,
  isWeightedCategory,
  filterOptionsByWeightSelected,
  getInitialWeight,
} from '../helpers';

type UseBuyingOptionsProps = {
  category: string;
  buyingOptions: Product[];
};

export type UseBuyingOptionsReturn = {
  filteredBuyingOptions: Product[];
  handleWeightChange: (weight: string) => void;
  isWeighted: boolean;
  selectedWeight: string | null;
  weights: string[];
};

export function useWeightedBuyingOptions({ category, buyingOptions }: UseBuyingOptionsProps): UseBuyingOptionsReturn {
  const [selectedWeight, setSelectedWeight] = useState<string | null>(null);

  const weights = useMemo(() => aggregateWeightOptions(buyingOptions), [buyingOptions]);

  const isWeighted = isWeightedCategory(category);

  const filteredBuyingOptions = filterOptionsByWeightSelected(buyingOptions, selectedWeight);

  useEffect(() => {
    if (isWeighted) {
      setSelectedWeight(getInitialWeight(category, weights));
    } else {
      setSelectedWeight(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, isWeighted]);

  function handleWeightChange(weight: string): void {
    setSelectedWeight(weight);
  }

  return {
    filteredBuyingOptions,
    handleWeightChange,
    isWeighted,
    selectedWeight,
    weights,
  };
}
