import { EnrollmentSteps } from 'src/payments/constants';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { useObserver } from 'mobx-react';
import useUser from 'hooks/use-user';
import useErnie from 'shared/hooks/use-ernie';
import { useApolloClient } from '@apollo/react-hooks';
import { ERNIE_DEFAULT_MESSAGES, ERNIE_TIMEOUTS, ERNIE_TYPES } from 'shared/constants';
import createMxEnrollment from 'src/payments/graphql/mutations/create-mx-enrollment.gql';
import createBankAccount from 'src/payments/graphql/mutations/create-bank-account.gql';
import {
  EVENT_ACTIONS,
  ENROLLMENT_EVENTS,
  useDutchiePayAnalytics,
} from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';

type CreateBankAccountResult = {
  createMxBankAccount: (mxAcctGuid: string) => Promise<void>;
};

export const useCreateBankAccount = (): CreateBankAccountResult => {
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { dutchiePayEventTracker } = useDutchiePayAnalytics();
  const User = useUser();
  const showErnie = useErnie();
  const apolloClient = useApolloClient();

  const mxBillingAddressId = useObserver(() => DutchiePayEnrollment.mxBillingAddressId);
  const mxMemberGuid = useObserver(() => DutchiePayEnrollment.mxMemberGuid);
  const mxSelectedAccountGuid = useObserver(() => DutchiePayEnrollment.mxSelectedAccountGuid);

  const createMxBankAccount = async (mxAcctGuid: string): Promise<void> => {
    try {
      DutchiePayEnrollment.createBankAccountRequested();

      const acctGuid = mxSelectedAccountGuid || mxAcctGuid;

      const createBankAccountProps = {
        // member_guid comes from MX, we pass it as aggregatorToken
        aggregatorToken: `${String(mxMemberGuid)}${String(acctGuid)}`,
        adapter: `mx`,
        entityId: User.id,
        entityType: `User`,
        billingAddressId: mxBillingAddressId,
      };

      const { data, errors } = await apolloClient.mutate({
        mutation: createBankAccount,
        variables: createBankAccountProps,
      });

      if (errors) {
        showErnie(ERNIE_DEFAULT_MESSAGES.ERROR, ERNIE_TYPES.DANGER, ERNIE_TIMEOUTS.LONG);
        return;
      }

      const { ownerName: ownerNames, bankName, lastDigits } = data.createBankAccount.bankAccount.bankAccount;
      const { id } = data.createBankAccount.bankAccount;
      const [firstOwnerName] = ownerNames;
      DutchiePayEnrollment.setConnectedBankAccountDetails({ ownerName: firstOwnerName, bankName, lastDigits, id });
      DutchiePayEnrollment.createBankAccountCompleted();
    } catch (err) {
      console.error(err);
      dutchiePayEventTracker(ENROLLMENT_EVENTS.ENROLLMENT_STEP, {
        step: EnrollmentSteps.completeEnrollment,
        action: EVENT_ACTIONS.FAILED,
      });
      showErnie(ERNIE_DEFAULT_MESSAGES.ERROR, ERNIE_TYPES.DANGER, ERNIE_TIMEOUTS.LONG);
    }
  };

  return {
    createMxBankAccount,
  };
};

type CreateMxEnrollmentResult = {
  createMxEnrollmentMutation: (address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
  }) => Promise<void>;
};

export const useCreateMxEnrollment = (): CreateMxEnrollmentResult => {
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { dutchiePayEventTracker } = useDutchiePayAnalytics();
  const User = useUser();
  const showErnie = useErnie();
  const apolloClient = useApolloClient();

  const createMxEnrollmentMutation = async (address): Promise<void> => {
    const accountSelectorStep = DutchiePayEnrollment.currentStep === EnrollmentSteps.bankAccountSelector;
    const { addressLine1, addressLine2, city, state, zip } = address;
    if (!accountSelectorStep) {
      void DutchiePayEnrollment.setAddress(address);
    }

    const createMxEnrollmentProps = {
      billingAddress: {
        status: `Active`,
        line1: addressLine1,
        line2: addressLine2,
        city,
        state,
        // TODO: will find a way around nullable value from geoinfo
        // https://dutchie.atlassian.net/browse/ENG-14413
        country: `United States`,
        postalCode: zip,
      },
      entityId: User.id,
      entityName: User.fullName,
      entityType: `User`,
      entityEmail: User.email,
      isMobileApp: false,
    };

    try {
      const response = await apolloClient.mutate({
        mutation: createMxEnrollment,
        variables: createMxEnrollmentProps,
      });

      const mxWidgetUrl = response.data.createMxEnrollment?.url;
      const billingAddressId = response.data.createMxEnrollment?.billingAddress?.id;

      if (response.errors || !mxWidgetUrl || !billingAddressId) {
        dutchiePayEventTracker(ENROLLMENT_EVENTS.ENROLLMENT_STEP, {
          step: DutchiePayEnrollment.currentStep,
          action: EVENT_ACTIONS.FAILED,
        });
        showErnie(ERNIE_DEFAULT_MESSAGES.ERROR, ERNIE_TYPES.DANGER, ERNIE_TIMEOUTS.LONG);
        return;
      }

      if (accountSelectorStep) {
        void DutchiePayEnrollment.clearMemberDetails();
      }

      DutchiePayEnrollment.addressStepCompleted({ mxWidgetUrl, billingAddressId });
    } catch (err) {
      console.error(err);
      dutchiePayEventTracker(ENROLLMENT_EVENTS.ENROLLMENT_STEP, {
        step: DutchiePayEnrollment.currentStep,
        action: EVENT_ACTIONS.FAILED,
      });
    }
  };

  return {
    createMxEnrollmentMutation,
  };
};
