import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import SectionHeader from '../order-receipt-section-header';
import DetailsTable from '../order-receipt-details-table';

const OrderDetails = ({ order }) => {
  const { t } = useTranslation();
  const { orderId } = order;

  return (
    <Details>
      <SectionHeader isDesktop>
        {t('common.details', 'details')}
        <OrderNumber>
          <span>{t('common.orderNumberAbbreviated', 'order #')}:</span> {orderId}
        </OrderNumber>
      </SectionHeader>

      <DetailsTableContainer>
        <DetailsTable order={order} />
      </DetailsTableContainer>
    </Details>
  );
};

/* TODO can we check with product on this color?
     there's actually a whole lot of different font colors going on in this modal
     that could probably be standardized. some of these primaryBlack look a bit much when in all caps
  */
const Details = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlack};
  min-width: 350px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
`;

const DetailsTableContainer = styled.div`
  padding: 8px 25px 17px 25px;
`;

const OrderNumber = styled.div`
  /* TODO this color was flagged SERIOUS[ly] below the contrast threshold FYI */
  color: ${({ theme }) => theme.colors.grey[35]};
  font-size: 13px;
  font-weight: normal;

  span {
    color: ${({ theme }) => theme.colors.grey[45]};
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export default OrderDetails;
