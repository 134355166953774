import React from 'react';
import styled, { css } from 'styled-components';

import useTranslation from 'src/hooks/use-translation';

import { ModalContainer, ModalExitIcon, Button } from 'components/core';
import { bold } from 'components/core/typography';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { CurrentlySelected, StoreOptions } from './components';

import { SelectStoreModalProps } from '../select-store.types';

export function SelectStoreModal({
  dispensaries,
  selectedStore,
  handleClearSelection,
  handleSelect,
  handleSaveClick,
  onClose,
}: SelectStoreModalProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <ModalWrapper open onClose={onClose}>
      {/* Padding is 0 because it needs to be handled within the children in order for the scroll bar to show in correct
      spot  */}
      <ModalContainer width={650} height={660} padding={0}>
        <Header>{t('select-store', 'Select a Store')}</Header>
        <ModalExitIcon onClick={onClose} />
        <Content>
          <CurrentlySelected selectedStore={selectedStore} handleClearSelection={handleClearSelection} />
          <StoreOptions dispensaries={dispensaries} selectedStore={selectedStore} handleSelect={handleSelect} />
          <ElevatedSave>
            <SaveButton size='medium' onClick={handleSaveClick}>
              {t('save', 'save')}
            </SaveButton>
          </ElevatedSave>
        </Content>
      </ModalContainer>
    </ModalWrapper>
  );
}

const Header = styled.h3`
  margin-bottom: 25px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  ${bold};
  text-align: left;
  padding: 30px 30px 0px 30px;
`;

const ElevatedSave = styled.div`
  height: 97px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 25px;
  flex-shrink: 0;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.grey[90]};
    box-shadow: 0px -3px 1px ${theme.colors.sectionShadow};
  `}
`;

const SaveButton = styled(Button)`
  text-transform: uppercase;
  padding: 14px 27px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 580px;
`;
