import styled from 'styled-components';

import { Typography } from 'src/typography';
import { fullWidthStyles } from '../hybrid-carousel.styles';

export const GroupHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 32px;
  padding: ${({ carouselPadding }: { carouselPadding: string }) => carouselPadding && `0 ${carouselPadding}px`};
  ${({ fullWidth }) => fullWidth && fullWidthStyles}
`;

export const GroupHeaderInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

export const GroupHeaderIcon = styled.div`
  margin-right: 15px;
`;

export const GroupHeaderText = styled.div`
  padding-right: 16px;
`;

export const GroupHeaderActions = styled.div`
  align-items: center;
  display: flex;
`;

export const GroupHeaderLink = styled.a`
  color: ${({ primaryColor, textColor }) => textColor ?? primaryColor};
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: ${({ theme }) => theme.customized.textTransforms.links};
  transition: color 0.2s;
`;

export const Heading = styled(Typography.Heading)`
  font-size: 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 20px;
    line-height: 1.2;
  }
`;

export const Subheading = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.grey[40]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  margin-bottom: 0;
`;
