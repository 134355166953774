import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Imgix from 'react-imgix';
import useEmblaCarousel from 'embla-carousel-react';

import { mediaSizes } from 'src/theme';

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import { imgixProductImageURL, getFirstActiveImage } from 'shared/helpers/products';
import { Clickable } from 'shared/components';

export type ProductImageFields = {
  url: string;
  active: boolean;
  label?: string;
  description?: string;
};

export type ProductImagesProps = {
  images: ProductImageFields[];
};

const carouselSettings = {
  draggable: true,
  loop: false,
  startIndex: 0,
  slidesToScroll: 1,
};

const imageDefaults = {
  type: `img`,
  imgixParams: { fit: `fill`, fill: `solid`, fillColor: `#fff` },
};

const cleanImageSources = (images: ProductImageFields[]): ProductImageFields[] =>
  images
    .filter((img) => img.active)
    .map((image) => {
      let resolvedImageSource = imgixProductImageURL(image.url);
      if (resolvedImageSource.includes(`http://`)) {
        resolvedImageSource = resolvedImageSource.replace(`http://`, `https://`);
      }
      return {
        ...image,
        url: resolvedImageSource,
      };
    })
    .slice(0, 9);

export default function ProductImages(props: ProductImagesProps): JSX.Element {
  const [emblaRef, embla] = useEmblaCarousel(carouselSettings);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { images: imagesFromProps } = props;
  const images = useMemo(() => cleanImageSources(imagesFromProps), [imagesFromProps]);
  const isSingleImage = images.filter((img) => img.active).length === 1;
  const firstActiveImage = getFirstActiveImage(images);

  useEffect(() => {
    if (!embla) {
      return;
    }

    if (images.length > 1) {
      embla.on(`select`, () => setSelectedIndex(embla.selectedScrollSnap()));
    }
  }, [embla, images.length]);

  const onClickThumbnail = (index): void => {
    setSelectedIndex(index);
    embla?.scrollTo(index);
  };

  return (
    <Container data-testid='product-images'>
      {isSingleImage && (
        <MainImageScrollContainer data-testid='main-product-image-scroll-container'>
          <LazyMainImage
            {...imageDefaults}
            key={firstActiveImage.url}
            htmlAttributes={{ alt: firstActiveImage.description }}
            src={firstActiveImage.url}
            attributeConfig={{
              src: `data-src`,
              srcSet: `data-srcset`,
              sizes: `data-sizes`,
            }}
            className='product-image lazyload'
          />
        </MainImageScrollContainer>
      )}
      {!isSingleImage && (
        <>
          <Viewport ref={(el) => emblaRef(el)}>
            <MainImageScrollContainer data-testid='main-product-image-scroll-container'>
              {images.map(
                (image, index) =>
                  image.active && (
                    <LazyMainImage
                      key={image.url}
                      {...imageDefaults}
                      htmlAttributes={{ alt: image.description }}
                      src={image.url}
                      attributeConfig={{
                        src: `data-src`,
                        srcSet: `data-srcset`,
                        sizes: `data-sizes`,
                      }}
                      className={`product-image-${index} lazyload ${selectedIndex === index ? 'is-selected' : ''}`}
                    />
                  )
              )}
            </MainImageScrollContainer>
          </Viewport>
          <ThumbnailsContainer data-testid='thumbnails-container' gridLength={images.length < 4 ? images.length : 4}>
            {images.map(
              (image, index) =>
                image.active && (
                  <ThumbnailImageContainer
                    key={image.url}
                    selected={selectedIndex === index}
                    onClick={() => onClickThumbnail(index)}
                    data-testid='product-thumbnail'
                  >
                    <LazyThumbnailImage
                      {...imageDefaults}
                      htmlAttributes={{ alt: '' }}
                      src={image.url}
                      attributeConfig={{
                        src: `data-src`,
                        srcSet: `data-srcset`,
                        sizes: `data-sizes`,
                      }}
                      className={`product-thumbnail-${index} lazyload`}
                    />
                  </ThumbnailImageContainer>
                )
            )}
          </ThumbnailsContainer>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  overflow: hidden;
  max-width: 344px;
  @media (max-width: ${mediaSizes.phone}px) {
    max-width: 325px;
  }
`;

const Viewport = styled.div`
  &.is-draggable {
    cursor: move;
    cursor: grab;
  }
`;

const MainImageScrollContainer = styled.div`
  width: 100%;
  height: 245px;
  display: flex;
  will-change: transform;
  margin-bottom: 20px;
`;

const LazyMainImage = styled(Imgix)`
  object-fit: contain;
  min-width: 344px;
  width: 100%;
  height: auto;
  @media (max-width: ${mediaSizes.phone}px) {
    width: 325px;
  }
  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
  &.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
  }
`;

const ThumbnailsContainer = styled.div<{ gridLength: number }>`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(${({ gridLength }) => gridLength}, 75px);
  grid-column-gap: 12px;
  grid-row-gap: 15px;
  @media (max-width: ${mediaSizes.phone}px) {
    grid-template-columns: repeat(${({ gridLength }) => gridLength}, 75px);
    grid-column-gap: 8px;
    grid-row-gap: 20px;
  }
`;

const ThumbnailImageContainer = styled(Clickable)<{ selected: boolean }>`
  width: 74px;
  height: 74px;
  @media (max-width: ${mediaSizes.phone}px) {
    width: 69px;
    height: 69px;
  }
  border-radius: 5px;
  border: ${({ selected }) => (selected ? `1.5px solid #a3afba` : `1.5px solid transparent`)};
  cursor: pointer;
  text-align: center;
  overflow: hidden;
`;

const LazyThumbnailImage = styled(Imgix)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
  &.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
  }
`;
