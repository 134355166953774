import { useEffect } from 'react';
import useCart from 'shared/hooks/use-cart';
import { tracker } from '..';

export const useCartViewedMonitor = (): void => {
  const Cart = useCart();

  const { checkoutToken: cartId, dispensary, cart } = Cart?.order ?? {};
  const { id: dispensaryId, name: dispensaryName } = dispensary ?? {};

  useEffect(() => {
    if (!cartId || !cart) {
      return;
    }

    tracker.setContext({
      activeCart: {
        ...Cart.order.cart,
        subtotal: Cart.costBreakdown?.subtotal,
        checkoutToken: cartId,
      },
    }); // need to set the tracker context with the most recent cart data.
    tracker.cartViewed({
      cartId,
      products: cart,
      dispensaryId,
      dispensaryName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // FIXME: ENG-32714 fix hooks dependency
};
