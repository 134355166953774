import { useCallback } from 'react';
import useUI from 'hooks/use-ui';

import { GqlMarketplaceProductOption } from 'types/graphql';
import { MarketplaceProduct, DispensaryProduct } from 'shop/category';

type Prices = {
  standardPrice: number;
  specialPrice: null;
  discountPercentage: null;
  displayOption?: string;
  weightedSpecialPrices: null;
};

type GetPrices<T> = (product: T) => Prices;

type UseMarketplacePrices = () => GetPrices<MarketplaceProduct>;

const useMarketplacePrices: UseMarketplacePrices = () =>
  useCallback((product) => {
    const minOption = product.options.reduce(
      (prev, curr: GqlMarketplaceProductOption) => (prev.price < curr.price ? prev : curr),
      { price: Infinity }
    );

    return {
      standardPrice: minOption.price,
      specialPrice: null,
      discountPercentage: null,
      weightedSpecialPrices: null,
    };
  }, []);

type UseDispensaryPrices = () => GetPrices<DispensaryProduct>;

const useDispensaryPrices: UseDispensaryPrices = () =>
  useCallback((product) => {
    if (!Array.isArray(product.Prices)) {
      throw new Error(`could not find prices for product`);
    }

    const standardPrice = product.Prices.reduce<number>(
      (prev: number, curr: number) => (prev < curr ? prev : curr),
      Infinity
    );

    return {
      standardPrice,
      specialPrice: null,
      discountPercentage: null,
      weightedSpecialPrices: null,
    };
  }, []);

export function useMarketplaceProductPrices(): GetPrices<DispensaryProduct | MarketplaceProduct> {
  const { isMarketplaceDispensary } = useUI();

  const getMarketplacePrices = useMarketplacePrices();
  const getDispensaryPrices = useDispensaryPrices();

  return isMarketplaceDispensary ? getDispensaryPrices : getMarketplacePrices;
}
