import styled from 'styled-components';
import { buttonResetStyles } from '../header.styles';

export const HeaderWrapper = styled.div`
  background: ${({ theme }) => theme.customized.colors.navBar};
  border-bottom: 1px solid #c8d2db;
  color: ${({ theme }) => (theme.customized.utils.isNavBarDark ? '#fff' : '#485055')};
  padding: 12px;
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

export const UtilityNav = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
`;

export const MenuButton = styled.button`
  ${buttonResetStyles}
  align-self: stretch;
  align-items: center;
  color: currentColor;
  display: flex;
  font-weight: 600;
  line-height: ${24 / 16};
  gap: 8px;
`;
