import React from 'react';
import { useObserver } from 'mobx-react';
import useTranslation from 'hooks/use-translation';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import UnsupportedBankAccount from 'assets/unsupported-bank-account';
import { useCreateMxEnrollment } from 'src/payments/utils';

import {
  CenterItems,
  DutchiePayButtonContainer,
  DutchiePayHeader,
  DutchiePayTextBody,
  IconContainer,
} from '../../../../components';

export function UnsupportedAccount(): JSX.Element {
  const { t } = useTranslation();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { createMxEnrollmentMutation } = useCreateMxEnrollment();

  const address = useObserver(() => DutchiePayEnrollment.address);
  return (
    <>
      <CenterItems>
        <IconContainer>
          <UnsupportedBankAccount />
        </IconContainer>
        <DutchiePayHeader>{t(`dutchiePay.unsupportedAccount`, 'Unsupported account')}</DutchiePayHeader>
        <DutchiePayTextBody>
          You selected an unsupported account. Please select a new <strong>checking</strong> or <strong>savings</strong>{' '}
          account.
        </DutchiePayTextBody>
      </CenterItems>
      <DutchiePayButtonContainer
        buttonText='Select new account'
        showBackLink={false}
        onClick={() => void createMxEnrollmentMutation(address)}
      />
    </>
  );
}
