import React from 'react';
import NoSsr from '@material-ui/core/NoSsr';
import { useObserver } from 'mobx-react-lite';

import Ernie from 'shared/components/ernie';
import useUI from 'hooks/use-ui';
import useViewport from 'hooks/use-viewport';

export default function MarketplaceErnie() {
  const UI = useUI();
  const isIframe = useObserver(() => UI.isIframe);
  const viewport = useViewport();

  return (
    <NoSsr>
      <Ernie isIframe={isIframe} iframeOffset={viewport.offset} parentOffset={viewport.parentOffset} />
    </NoSsr>
  );
}
