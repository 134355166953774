import React from 'react';

type CreditCardIconProps = {
  width?: number;
  height?: number;
};

const CreditCardIcon = ({ width = 45, height = 36 }: CreditCardIconProps): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 45 36' fill='none'>
    <title>Credit Card Icon</title>
    <path
      fill='#171A1C'
      fillRule='evenodd'
      d='M4.821 4.036c-.887 0-1.607.735-1.607 1.643v6.571h38.572V5.679c0-.908-.72-1.643-1.607-1.643H4.82ZM0 5.679V30.32c0 2.722 2.159 4.929 4.821 4.929H40.18c2.662 0 4.821-2.207 4.821-4.929V5.68C45 2.957 42.841.75 40.179.75H4.82C2.16.75 0 2.957 0 5.679ZM41.786 30.32V15.536H3.214V30.32c0 .908.72 1.643 1.607 1.643H40.18c.887 0 1.607-.735 1.607-1.643Zm-11.25-6.571c-.888 0-1.607.735-1.607 1.643 0 .907.72 1.643 1.607 1.643h4.821c.888 0 1.607-.736 1.607-1.643 0-.908-.72-1.643-1.607-1.643h-4.821Z'
      clipRule='evenodd'
    />
  </svg>
);

export default CreditCardIcon;
