import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { space, width } from 'styled-system';

import useTranslation from 'hooks/use-translation';

import { Button } from 'components/core';

import { FormTextInput, FormPlacesAutoCompleteInput } from '../../form-inputs';
import Form from '../form';

const DeliveryAddressForm = ({ handleOnSubmit, loading }) => {
  const [address, setAddress] = useState({});
  const [apt, setApt] = useState('');
  const { t } = useTranslation();

  const handleSubmit = () => handleOnSubmit(address, { apt });

  const handleOnAddressSelect = (location) => {
    setAddress(location);
    document.getElementById('apt-input').focus();
  };

  const handleAptInputChange = ({ target: { value } }) => setApt(value);

  return (
    <Form onSubmit={handleSubmit}>
      <InputContainer mt={15} mb={20}>
        <FormPlacesAutoCompleteInput
          required
          name='streetAddress'
          label={t('common.streetAddress', 'Street address')}
          variant='outlined'
          onChange={handleOnAddressSelect}
          isLocationSettings
        />
      </InputContainer>
      <InputContainer mt={15} mb={30}>
        <FormTextInput
          name='apt'
          label={t('common.apartment', 'Apartment or suite number')}
          variant='filled'
          labelstyles={labelstyles}
          id='apt-input'
          onChange={handleAptInputChange}
        />
      </InputContainer>
      <div>
        <StyledButton loading={loading}>{t('common.save', 'Save')}</StyledButton>
      </div>
    </Form>
  );
};

export default DeliveryAddressForm;

export const StyledButton = styled(Button)`
  width: 95px;
`;

export const InputContainer = styled.div`
  ${width}
  ${space}
  position: relative;
`;

const labelstyles = css`
  ${({ theme }) => theme.breakpoints.up('md')} {
    &&& {
      &.MuiFormLabel-root {
        width: 100%;
        text-align: center;
        margin-left: 0;
        padding-right: 28px;
      }

      &.MuiFormLabel-root.Mui-focused {
        margin-left: 14px;
        text-align: left !important;
      }

      &.MuiFormLabel-root.MuiFormLabel-filled:not(.Mui-focused) {
        margin-left: 40px;
      }
    }
  }
`;
