import { useMemo } from 'react';
import { isBlank } from 'underscore.string';
import { NextRouter, useRouter } from 'next/router';

import { getHtmlPlainText } from 'shared/utils/misc-utils';
import { GqlDispensaries } from 'types/graphql';

import useUser from 'hooks/use-user';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import usePaths from 'hooks/use-paths';

import { useRedirectIfAgeVerificationNotApplicable } from '../use-redirect-if-age-verification-not-applicable';
import { useRedirectVerifiedCustomers } from '../use-redirect-verified-customers';

type UseAgeVerificationGateReturn = {
  dispensary: GqlDispensaries;
  isLoggedIn?: boolean;
  showMenuBanner: boolean;
};

export function useAgeVerificationGate(): UseAgeVerificationGateReturn {
  const { dispensary } = useDispensary();
  const user = useUser();
  const router: NextRouter = useRouter();
  const { href } = usePaths({ dispensary });

  useRedirectIfAgeVerificationNotApplicable({ dispensary, router, href });
  useRedirectVerifiedCustomers({ userId: user?.id, dispensary, router, href });

  const DOMParser = typeof window !== 'undefined' ? window.DOMParser : null;
  const bannerIsBlank = useMemo(
    () =>
      !dispensary?.ageVerificationBannerHtml ||
      isBlank(getHtmlPlainText(dispensary?.ageVerificationBannerHtml, DOMParser)),
    [dispensary?.ageVerificationBannerHtml, DOMParser]
  );

  const showMenuBanner = !bannerIsBlank;

  return { dispensary, isLoggedIn: user?.isLoggedIn, showMenuBanner };
}
