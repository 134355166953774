import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from 'rebass/styled-components';
import WarningIcon from 'shared/assets/warning-icon';
import { callNativeMethod } from 'src/mobile-ecomm/hooks/use-mobile-native-bridge';
import useUI from 'hooks/use-ui';

export default function CaliforniaWarning(props) {
  const { isKiosk = false } = props;

  return (
    <WarningContainer withOtherContent={isKiosk}>
      <WarningText>
        <WarningIcon fill='#A3AFBA' height='9' />
        <b>WARNING:</b> Cannabis products may cause cancer and reproductive harm according to the state of California.
        <br />
        For more: <Prop65URL />
      </WarningText>
    </WarningContainer>
  );
}

function Prop65URL() {
  const { isMobileEcommApp } = useUI();
  const onClick = () => callNativeMethod(`onLaunchUrl`, { url: `https://p65warnings.ca.gov` });
  return isMobileEcommApp ? (
    <StyledButtonText onClick={onClick} onKeyDown={onClick} role='button' tabIndex='0'>
      p65warnings.ca.gov
    </StyledButtonText>
  ) : (
    <StyledLink rel='noopener noreferrer' target='_blank' href='https://p65warnings.ca.gov'>
      p65warnings.ca.gov
    </StyledLink>
  );
}

const StyledLink = styled.a`
  color: #0b99e6;
`;

const StyledButtonText = styled.span`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;

const WarningText = styled(Text)`
  color: #909090;
  text-align: center;
  font-size: 11px;
  line-height: 16px;
`;

const WarningContainer = styled.div`
  ${({ withOtherContent }) =>
    withOtherContent &&
    css`
      border-bottom: 1px solid #d3dadf;
      padding-bottom: 19px;
      margin: 15px 0 6px;
    `}

  svg {
    padding-right: 2px;
  }
`;
