import React from 'react';
import useTranslation from 'hooks/use-translation';

import { DispensaryOrderingDetails } from 'components/dispensary-ordering-details';
import { Option } from 'components/core';
import { GqlDispensaries as Dispensary } from 'types/graphql';
import { Product } from 'utils/helpers/product';

import {
  ProductTableRow,
  DispensaryInfoContainer,
  PotencyText,
  StrandTypeText,
  PriceText,
  QuantitySelect,
  OptionText,
  AddToCartButton,
} from './product-table.components';
import { PotencyDivider } from './potency-divider';
import { useTableProductRow } from './use-table-product-row';

type TableProductRowProps = {
  dispensary: Dispensary | null | undefined;
  product: Product;
  weight: string | null;
};

export function TableProductRow({ dispensary, product, weight }: TableProductRowProps): JSX.Element | null {
  const {
    cbdRange,
    handleChange,
    handleClickAddToCart,
    basePriceToDisplay,
    quantityRange,
    quantitySelectClasses,
    quantitySelected,
    thcRange,
  } = useTableProductRow({ product, weight });

  const { t } = useTranslation();

  if (!dispensary) {
    console.error(
      product.id
        ? `Unable to find dispensary associated with ${product.id}`
        : `Unable to find dispensary associated with product`
    );
    return null;
  }

  return (
    <ProductTableRow>
      <DispensaryInfoContainer>
        <DispensaryOrderingDetails dispensary={dispensary} variant='small' />
      </DispensaryInfoContainer>

      <PotencyText>
        {product.strainType && <StrandTypeText>{product.strainType}</StrandTypeText>}
        {thcRange && t('buying-options.thcPotency', { defaultValue: 'THC: {{potency}}', potency: thcRange })}
        {thcRange && cbdRange && <PotencyDivider potencyCopyLength={thcRange.length + cbdRange.length} />}
        {cbdRange && t('buying-options.cbdPotency', { defaultValue: 'CBD: {{potency}}', potency: cbdRange })}
      </PotencyText>
      {/* TODO add discountPrice markup when it becomes available from the API */}
      <PriceText>{basePriceToDisplay}</PriceText>

      <QuantitySelect
        SelectDisplayProps={{
          'aria-label': t('common.quantity', 'Quantity'),
        }}
        onChange={handleChange}
        value={quantitySelected}
        MenuProps={{ classes: { paper: quantitySelectClasses.paper, list: quantitySelectClasses.list } }}
      >
        {quantityRange.map((num) => (
          <Option key={num} value={num}>
            <OptionText>{num}</OptionText>
          </Option>
        ))}
      </QuantitySelect>

      <AddToCartButton onClick={handleClickAddToCart}>{t('common.add-to-cart', 'Add to cart')}</AddToCartButton>
    </ProductTableRow>
  );
}
