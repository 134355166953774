import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { ModalExitIcon } from 'components/core';
import EmptyLoadingState from 'components/empty-loading-state';
import MobileItems from './order-receipt-items';
import DispensaryBanner from './order-receipt-dispensary-banner';
import Details from './order-receipt-mobile-details';
import Totals from '../order-totals';

export const OrderReceiptMobile = ({ dispensary, onClose, order, loading, handleItemClick }) => {
  const { t } = useTranslation();
  const { bannerImage, logoImage, name, taxConfig } = dispensary || {};

  return (
    <Container>
      <Header>
        {t('common.yourReceipt', 'your receipt')}
        <StyledExitIcon onClick={onClose} />
      </Header>
      <EmptyLoadingState isLoading={loading} page='default'>
        <DispensaryBanner bannerImage={bannerImage} logoImage={logoImage} name={name} order={order} />

        <MobileItems order={order} handleItemClick={handleItemClick} />

        <Details order={order} />

        <TotalsContainer>
          <Totals order={order} taxConfig={taxConfig} />
        </TotalsContainer>
      </EmptyLoadingState>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const Header = styled.div`
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[35]};
  padding-left: 22px;
  padding-right: 17px;
  text-transform: capitalize;
`;

const TotalsContainer = styled.div`
  padding: 20px 25px 25px 25px;
`;

const StyledExitIcon = styled(ModalExitIcon)`
  position: relative;
  top: 0;
  left: 0;
`;
