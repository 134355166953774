import _ from 'lodash';
import {
  ArrayParam,
  StringParam,
  NumberParam,
  withDefault,
  encodeDelimitedArray,
  decodeDelimitedArray,
} from 'use-query-params';

import { CategoryPotencyRanges } from 'shared/constants';

import { menuInteraction } from 'utils/ecommerce-analytics';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useQueryParamsHookFactory from 'shared/hooks/use-query-params-hook-factory';
import { useDispensaryCategory } from './use-dispensary-category';

function isPotencyRange(range) {
  return range && Array.isArray(range) && range.length === 2;
}

const PotencyRangeParam = (defaultValue) => ({
  encode(array) {
    if (isPotencyRange(array)) {
      return encodeDelimitedArray(array, ',');
    }
    return encodeDelimitedArray(defaultValue, ',');
  },

  decode(arrayStr) {
    const range = decodeDelimitedArray(arrayStr, ',');
    if (isPotencyRange(range)) {
      return [Number(range[0]), Number(range[1])];
    }
    return defaultValue;
  },
});

const defaults = {
  brands: [],
  category: undefined,
  page: 1,
  menuType: undefined,
  search: undefined,
  sortby: `default`,
  subcategories: [],
  straintypes: [],
  weight: undefined,
  effects: [],
};

const config = {
  brands: withDefault(ArrayParam, defaults.brands),
  category: withDefault(StringParam, defaults.category),
  page: withDefault(NumberParam, defaults.page),
  menuType: withDefault(StringParam, defaults.menuType),
  search: withDefault(StringParam, defaults.search),
  subcategories: withDefault(ArrayParam, defaults.subcategories),
  straintypes: withDefault(ArrayParam, defaults.straintypes),
  weight: withDefault(StringParam, defaults.weight),
  sortby: withDefault(StringParam, defaults.sortby),
  effects: withDefault(ArrayParam, defaults.effects),
};

function getSortByParam(dispensary) {
  if (config.sortby === 'default' && _.isString(config.search)) {
    return null;
  }

  return config.sortby || dispensary?.menuOrder;
}

export default function useProductQueryParams() {
  const category = useDispensaryCategory();
  const { dispensary } = useDispensary();
  const defaultPotencyRange = _.get(CategoryPotencyRanges, category?.key);
  const sortby = getSortByParam(dispensary);

  const value = _.partial(
    useQueryParamsHookFactory,
    {
      ...config,
      sortby,
      potencythc: withDefault(PotencyRangeParam(defaultPotencyRange), defaultPotencyRange),
      potencycbd: withDefault(PotencyRangeParam(defaultPotencyRange), defaultPotencyRange),
    },
    {
      ...defaults,
      potencythc: defaultPotencyRange,
      potencycbd: defaultPotencyRange,
    }
  )();

  return {
    ...value,
    setQueryParams: (params) => {
      value.setQueryParams(params);
      menuInteraction(dispensary);
    },
  };
}
