import React from 'react';
import styled from 'styled-components';
import { object, string, SchemaOf } from 'yup';

import { FormTextInput } from 'components/form-inputs';
import Form from 'components/forms/form';
import useTranslation, { ExtendedUseTranslationResponse } from 'hooks/use-translation';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { emailRegEx } from 'components/forms/form-utils';
import { EnrollmentSteps } from 'src/payments/constants';
import { useDutchiePayAnalytics } from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import { DutchiePayButtonContainer, DutchiePayHeader, DutchiePayTextBody } from 'src/payments/components';
import { EmailInput } from '../email-form/email-form';

export type DutchiePayEmailFormProps = {
  onSubmit: ({ email }: EmailInput) => Promise<void>;
  onBackClick: () => void;
};

const EmailFormValidation = (t: ExtendedUseTranslationResponse['t']): SchemaOf<EmailInput> =>
  object().shape({
    email: string()
      .required(t('form.emailRequired', 'email is required'))
      .matches(emailRegEx, t('form.validEmail', 'please enter a valid email address')),
  });

export function ResetPasswordForm({ onSubmit, onBackClick }: DutchiePayEmailFormProps): JSX.Element {
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { t } = useTranslation();
  const { trackEnrollmentStepSubmitted } = useDutchiePayAnalytics();

  const handleSubmit = (val): void => {
    trackEnrollmentStepSubmitted(EnrollmentSteps.forgotPasswordForm);

    void onSubmit(val);
  };

  return (
    <>
      <DutchiePayHeader>{t('dutchiePay.forgotPassword', 'Reset your password')}</DutchiePayHeader>
      <DutchiePayTextBody>
        {t('dutchiePay.passwordResetLink', `Email yourself a password reset link.`)}
      </DutchiePayTextBody>

      <Form
        onSubmit={handleSubmit}
        mode='onTouched'
        validationSchema={EmailFormValidation(t)}
        prefilledValues={{ email: DutchiePayEnrollment.userEmail }}
      >
        <FormInputsContainer>
          <FormTextInput label='Email address' name='email' variant='filled' />
        </FormInputsContainer>

        <DutchiePayButtonContainer
          showBackLink
          onBackLinkClick={onBackClick}
          buttonText='submit'
          requiredFieldKeys={['email']}
        />
      </Form>
    </>
  );
}

const FormInputsContainer = styled.div`
  margin-bottom: 20px;
`;
