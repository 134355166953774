export type TranslatedText = {
  key: string;
  default: string;
};

export const NOW_SHOPPING: TranslatedText = {
  key: `now-shopping.now-shopping`,
  default: `You're now shopping with`,
};

export const DESCRIPTION: TranslatedText = {
  key: `now-shopping.description`,
  default: `Dutchie orders can be placed with one store at a time.
We’ll filter down your shopping experience to only show products from this store.`,
};

export const CONTINUE: TranslatedText = {
  key: `continue`,
  default: `Continue`,
};

export const CANCEL: TranslatedText = {
  key: `cancel-order`,
  default: `Cancel Order`,
};
