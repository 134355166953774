import { useState, useEffect } from 'react';

import useProductQueryParams from 'src/hooks/use-product-query-params';

import { DispensaryStrainTypeOption } from 'shop/types/strain-type.types';
import {
  UseDispensaryStrainTypeProps,
  UseDispensaryStrainTypeReturn,
} from './dispensary-strain-type-filter-drawer.types';

export function useDispensaryStrainTypeFilter({ open }: UseDispensaryStrainTypeProps): UseDispensaryStrainTypeReturn {
  const [selectedOptions, setSelectedOptions] = useState<DispensaryStrainTypeOption[]>([]);
  const { queryParams, setQueryParams } = useProductQueryParams();

  useEffect(() => {
    if (open) {
      setSelectedOptions(queryParams.straintypes);
    }
  }, [open, queryParams.straintypes]);

  function handleSave(): void {
    setQueryParams({ straintypes: selectedOptions });
  }

  function handleSelect(option: DispensaryStrainTypeOption): void {
    setSelectedOptions((previousOptions) =>
      previousOptions.includes(option)
        ? previousOptions.filter((selected) => selected !== option)
        : [...previousOptions, option]
    );
  }

  return { handleSave, handleSelect, selectedOptions };
}
