import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useIncrementSpecialClicksMutation } from 'types/graphql';

export function useSpecialsClickTracking(): void {
  const router = useRouter();
  const { special } = router.query;
  const [incrementSpecialClicksMutation] = useIncrementSpecialClicksMutation();

  useEffect(() => {
    if (special && typeof special === 'string') {
      void incrementSpecialClicksMutation({ variables: { input: { _id: special } } });
    }
  }, [special, incrementSpecialClicksMutation]);
}
