import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useObserver } from 'mobx-react-lite';

import useTranslation from 'hooks/use-translation';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import useLogRocket from 'src/hooks/use-logrocket';

import { Button } from 'components/core';
import { space } from 'styled-system';
import { ArrowIcon } from '../../components';

export type DutchiePayLeaveButtonProps = {
  onCloseModal: () => void;
};

export const DutchiePayLeaveButton = forwardRef<JSX.Element, DutchiePayLeaveButtonProps>(({ onCloseModal }, ref) => {
  const { t } = useTranslation();
  const router = useRouter();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { track } = useLogRocket();
  const refUrl = useObserver(() => DutchiePayEnrollment.refUrl);
  const onLeaveButtonClick = (): void => {
    track(`DutchiePay_exited_enrollment`, {
      lastEnrollmentStep: DutchiePayEnrollment.currentStep,
    });
    DutchiePayEnrollment.cancelEnrollment();
    onCloseModal();
    void router.push(refUrl);
  };

  return (
    <Container ref={ref}>
      <StayLink aria-label='back' onClick={onCloseModal}>
        <ArrowIcon />
        {t('common.stay', 'Stay')}
      </StayLink>

      <DutchiePayLeaveNowButton aria-label='leave' onClick={onLeaveButtonClick}>
        {t('common.leave', 'Leave Now')}
      </DutchiePayLeaveNowButton>
    </Container>
  );
});

const StayLink = styled.button`
  ${({ theme: { colors } }) => css`
    color: ${colors.grey[45]};
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    background: transparent;
    border: none;

    &:hover {
      color: ${colors.grey[30]};
      cursor: pointer;
    }
  `}
`;

const DutchiePayLeaveNowButton = styled(Button)`
  background: ${({ theme }) => theme.colors.red[55]};
  padding: 16px 24px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ${DutchiePayLeaveNowButton} {
    width: 70%;
  }

  ${space}
`;
