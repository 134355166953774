export enum WhoopsReason {
  default = 'default',
  noProducts = 'noProducts',
  pageNotFound = 'notFound',
  serverError = 'serverError',
  loadError = 'loadError',
  ssoError = 'ssoError',
  adminSsoError = 'adminSsoError',
}

export type WhoopsProps = {
  reason: WhoopsReason;
  children?: React.ReactNode;
};
