import React from 'react';
import { EnrollmentSteps } from 'src/payments/constants';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';

import { ResetPasswordForm } from './reset-password-form';
import { useResetPassword } from './use-password-reset';

export function ResetPasswordFormWrapper(): JSX.Element {
  const { resetPassword } = useResetPassword();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const handleBackClick = (): void => DutchiePayEnrollment.setCurrentStep(EnrollmentSteps.passwordForm);

  return <ResetPasswordForm onSubmit={resetPassword} onBackClick={handleBackClick} />;
}
