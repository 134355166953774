import React from 'react';
import styled from 'styled-components';

import { useDispensaryDisclaimer } from 'src/dispensary/hooks/use-dispensary-disclaimer';

import DisclaimerText from 'src/dispensary/components/disclaimer-text';
import { DutchiePrivacyFooter } from 'src/dispensary/core-menu/components/dutchie-privacy-footer';
import { InlineCategoriesList } from 'src/components/inline-categories-list';

/**
 * @param {{
 *   displayDisclaimer?: boolean;
 *   hideCategories?: boolean;
 * }}
 */
export default function CoreMenuFooter({ displayDisclaimer = true, hideCategories }) {
  const { disclaimerIsBlank, disclaimerTextHtml } = useDispensaryDisclaimer();

  const displayDisclaimerAbove = displayDisclaimer && !disclaimerIsBlank && hideCategories;
  const displayDisclaimerBelow = displayDisclaimer && !disclaimerIsBlank && !hideCategories;

  return (
    <>
      {displayDisclaimerAbove && <DisclaimerText m='50px auto' html={disclaimerTextHtml} />}

      {!hideCategories && <InlineCategoriesList />}

      {displayDisclaimerBelow && (
        <BottomDisclaimerContainer>
          <DisclaimerText m='50px auto' className='disclaimer-text' html={disclaimerTextHtml} />
        </BottomDisclaimerContainer>
      )}

      <DutchiePrivacyFooter />
    </>
  );
}

const BottomDisclaimerContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.colors.grey[95]};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .disclaimer-text {
      margin: 25px auto 25px;
    }
    background: ${({ theme }) => theme.colors.white};
  }
`;
