import React from 'react';
import styled from 'styled-components';
import { space, width } from 'styled-system';

import useTranslation from 'src/hooks/use-translation';
import { FormTextInput, FormFormattedInput } from 'src/components/form-inputs';
import Form from 'src/components/forms/form';
import useUser from 'src/hooks/use-user';
import { Button } from 'src/components/core';
import { Typography } from 'src/typography';
import { useCompleteAccount } from './use-complete-account';

type CompleteAccountProps = {
  close: () => void;
};

export const CompleteAccount = ({ close }: CompleteAccountProps): JSX.Element => {
  const { t } = useTranslation();
  const { email, firstName, lastName, birthday, formatedBirthdate, phone } = useUser();
  const { handleSubmit, isLoading, completeAccountValidation } = useCompleteAccount({ close });

  const formValues = {
    firstName,
    lastName,
    phone,
    birthday: birthday ? formatedBirthdate : null,
    email,
  };

  return (
    <>
      <Typography.Heading tag='h2' size='medium'>
        Complete your account
      </Typography.Heading>

      <SubText size='medium'>Enter the required info below to complete your account.</SubText>

      <Form
        defaultValues={formValues}
        onSubmit={handleSubmit}
        mode='onSubmit'
        validationSchema={completeAccountValidation(t)}
      >
        <InlineContainer>
          <InputContainer width='50%' mr='8px'>
            <FormTextInput
              name='firstName'
              label={t('signUp.firstName', 'First Name')}
              variant='filled'
              autoComplete='given-name'
              required
              autoFocus
            />
          </InputContainer>

          <InputContainer width='50%' ml='8px'>
            <FormTextInput
              name='lastName'
              label={t('signUp.lastName', 'Last Name')}
              variant='filled'
              autoComplete='family-name'
              required
            />
          </InputContainer>
        </InlineContainer>

        <InputContainer mt={16}>
          <FormFormattedInput
            format='phone'
            name='phone'
            label={t('signUp.phone', 'Mobile Phone Number')}
            variant='filled'
            autoComplete='tel'
            required
          />
        </InputContainer>

        <InputContainer mt={16}>
          <FormFormattedInput
            format='date'
            name='birthday'
            label={t('signUp.birthday', 'Birthday')}
            variant='filled'
            autoComplete='bday'
            required
          />
        </InputContainer>

        <InputContainer mt={16}>
          <FormTextInput
            name='email'
            label={t('signUp.email', 'Email Address')}
            variant='filled'
            autoComplete='email'
            type='email'
            disabled
          />
        </InputContainer>

        <ButtonContainer>
          <Button type='submit' mt={16} loading={isLoading}>
            Save
          </Button>
        </ButtonContainer>
      </Form>
    </>
  );
};

const SubText = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.grey[30]};
  margin: 24px 0;
`;

const InputContainer = styled.div`
  ${width}
  ${space}
`;

const InlineContainer = styled.div`
  display: flex;
  ${space}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
