/* eslint-disable max-len */

export const diemPortland = {
  id: 'kgtGfbPx92Z2zMb8X',
  distance: null,
  name: 'Diem Delivery',
  cName: 'diem-portland',
  timezone: 'America/Los_Angeles',
  phone: '(503) 610-9019',
  address: '305 SE 3rd Ave, Portland, OR 97214, USA',
  description:
    'Diem’s grand vision is to see cannabis improving lives…everyone’s lives. We believe that cannabis is a natural medicine, with something to offer all humans, and our mission is to shine a light onto this miraculous plant.\n\nWe are here to help you seize the day, working hard to provide relief for medical patients, fun and relaxation for recreational cannabis users, and to give back to our community.',
  status: 'open',
  chain: 'diem',
  location: {
    ln1: '305 SE 3rd Ave',
    ln2: '',
    city: 'Portland',
    state: 'OR',
    geometry: {
      coordinates: [-122.663022, 45.5206251],
      __typename: 'Dispensaries_profile_location_geometry',
    },
    __typename: 'Dispensaries_profile_location',
  },
  deliveryHours: {
    Monday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Tuesday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Wednesday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Thursday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Friday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Saturday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    Sunday: {
      active: true,
      end: 'open',
      start: 'open',
      __typename: 'dayBounds',
    },
    __typename: 'Dispensaries_profile_hours',
  },
  pickupHours: {
    Monday: {
      active: true,
      end: '7:30 PM',
      start: '11:30 AM',
      __typename: 'dayBounds',
    },
    Tuesday: {
      active: true,
      end: '7:30 PM',
      start: '11:30 AM',
      __typename: 'dayBounds',
    },
    Wednesday: {
      active: true,
      end: '7:30 PM',
      start: '11:30 AM',
      __typename: 'dayBounds',
    },
    Thursday: {
      active: true,
      end: '7:30 PM',
      start: '11:30 AM',
      __typename: 'dayBounds',
    },
    Friday: {
      active: true,
      end: '7:30 PM',
      start: '11:30 AM',
      __typename: 'dayBounds',
    },
    Saturday: {
      active: true,
      end: '7:30 PM',
      start: '11:30 AM',
      __typename: 'dayBounds',
    },
    Sunday: {
      active: true,
      end: '7:30 PM',
      start: '11:30 AM',
      __typename: 'dayBounds',
    },
    __typename: 'Dispensaries_profile_hours',
  },
  pickupMinimum: {
    enabled: false,
    minimumInCents: null,
    __typename: 'PickupMinimum',
  },
  specialHours: [],
  effectiveHours: {
    deliveryHours: {
      Monday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Tuesday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Wednesday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Thursday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Friday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Saturday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      Sunday: {
        active: true,
        end: 'open',
        start: 'open',
        __typename: 'dayBounds',
      },
      __typename: 'Dispensaries_profile_hours',
    },
    pickupHours: {
      Monday: {
        active: true,
        end: '7:30 PM',
        start: '11:30 AM',
        __typename: 'dayBounds',
      },
      Tuesday: {
        active: true,
        end: '7:30 PM',
        start: '11:30 AM',
        __typename: 'dayBounds',
      },
      Wednesday: {
        active: true,
        end: '7:30 PM',
        start: '11:30 AM',
        __typename: 'dayBounds',
      },
      Thursday: {
        active: true,
        end: '7:30 PM',
        start: '11:30 AM',
        __typename: 'dayBounds',
      },
      Friday: {
        active: true,
        end: '7:30 PM',
        start: '11:30 AM',
        __typename: 'dayBounds',
      },
      Saturday: {
        active: true,
        end: '7:30 PM',
        start: '11:30 AM',
        __typename: 'dayBounds',
      },
      Sunday: {
        active: true,
        end: '7:30 PM',
        start: '11:30 AM',
        __typename: 'dayBounds',
      },
      __typename: 'Dispensaries_profile_hours',
    },
    __typename: 'EffectiveHours',
  },
  offerPickup: false,
  offerDelivery: true,
  listImage: 'https://zah-dutchie.imgix.net/diem-salem-list.jpg',
  bannerImage: 'https://zah-dutchie.imgix.net/diem-salem-banner.jpg',
  logoImage: 'https://zah-dutchie.imgix.net/diem-logo.png',
  embeddedLogoImage: 'https://zah-dutchie.imgix.net/diem-embedded.png',
  SpecialLogoImage: 'https://zah-dutchie.imgix.net/diem-specials.jpg',
  embedBackUrl: 'http://hellodiem.com',
  creditCard: null,
  creditCardAtDoor: true,
  debitOnly: true,
  cashOnly: null,
  recDispensary: true,
  medicalDispensary: true,
  linx: null,
  maxDeliveryDistance: 0,
  feeTiers: [
    {
      fee: '0',
      max: '1000',
      min: '0',
      __typename: 'Dispensaries_profile_feeTiers',
    },
  ],
  acceptsTips: false,
  acceptsDutchiePayTips: false,
  requiresDriversLicense: null,
  requirePhotoIdForDelivery: null,
  requirePhotoIdForPickup: null,
  removeMedicalCardFieldsAtCheckout: null,
  menuOrder: 'custom',
  email: null,
  emailConfirmation: false,
  stealthMode: null,
  actionEstimates: {
    pickup: {
      readyInMinutes: 45.51625666666667,
      rangeInMinutes: 5,
      __typename: 'ActionEstimatesValues',
    },
    delivery: {
      readyInMinutes: 35.80709406132922,
      rangeInMinutes: 10,
      deliveryTimeInMinutes: 20,
      __typename: 'ActionEstimatesValues',
    },
    __typename: 'ActionEstimates',
  },
  durationEstimateOverrides: {
    enabled: false,
    delivery: {
      lowInMinutes: 45,
      highInMinutes: 75,
      __typename: 'DurationEstimateRange',
    },
    pickup: null,
    __typename: 'DurationEstimates',
  },
  guestEmailRequired: null,
  paymentFees: [
    {
      fee: 5,
      feeType: 'percent',
      paymentType: 'creditCardAtDoor',
      __typename: 'Dispensaries_paymentFees',
    },
  ],
  categoryLimits: null,
  categoryLimitsEnabled: null,
  storeSettings: {
    isolatedMenus: false,
    defaultViewStyle: 'list',
    disablePurchaseLimits: false,
    disableGuestDOB: false,
    requireMedCardPhotoForPickup: false,
    hideAddressFromDutchieMain: false,
    requireMedCardPhotoForDelivery: false,
    enableLimitPerCustomer: false,
    disableGuestCheckout: false,
    stealthMode: false,
    dontCombineWeightedProducts: false,
    keepUncombinedWeights: false,
    dontMapSubcategoriesByProductName: false,
    prioritizeStaffPicksInSearchResults: false,
    provideBackInstockEmailNotifications: false,
    hideEffects: false,
    hideFilters: false,
    enableAfterHoursOrderingForPickup: false,
    __typename: 'Dispensaries_storeSettings',
  },
  featureFlags: {
    hideStoreHours: false,
    hideDeliveryEstimate: false,
    enablePausedOrders: null,
    __typename: 'Dispensaries_featureFlags',
  },
  menuScore: 2.514627659574468,
  menuScoresByCategory: [
    {
      category: 'Accessories',
      value: 3.3125,
      __typename: 'MenuScore',
    },
    {
      category: 'Concentrate',
      value: 2.375,
      __typename: 'MenuScore',
    },
    {
      category: 'Topicals',
      value: 2.340909090909091,
      __typename: 'MenuScore',
    },
    {
      category: 'Flower',
      value: 3.326923076923077,
      __typename: 'MenuScore',
    },
    {
      category: 'Edible',
      value: 2.4125,
      __typename: 'MenuScore',
    },
    {
      category: 'Vaporizers',
      value: 2.0686274509803924,
      __typename: 'MenuScore',
    },
    {
      category: 'Pre-Rolls',
      value: 3.28125,
      __typename: 'MenuScore',
    },
    {
      category: 'Tincture',
      value: 1.75,
      __typename: 'MenuScore',
    },
  ],
  productCategorySummary: [
    {
      category: 'Pre-Rolls',
      value: 16,
      __typename: 'CategorySummary',
    },
    {
      category: 'Tincture',
      value: 3,
      __typename: 'CategorySummary',
    },
    {
      category: 'Concentrate',
      value: 26,
      __typename: 'CategorySummary',
    },
    {
      category: 'Topicals',
      value: 22,
      __typename: 'CategorySummary',
    },
    {
      category: 'Flower',
      value: 26,
      __typename: 'CategorySummary',
    },
    {
      category: 'Edible',
      value: 40,
      __typename: 'CategorySummary',
    },
    {
      category: 'Vaporizers',
      value: 51,
      __typename: 'CategorySummary',
    },
    {
      category: 'Accessories',
      value: 4,
      __typename: 'CategorySummary',
    },
  ],
  ordersArePaused: false,
  activeCategories: [
    'Pre-Rolls',
    'Tincture',
    'Concentrate',
    'Topicals',
    'Flower',
    'Edible',
    'Vaporizers',
    'Accessories',
  ],
  googleAnalyticsID: '',
  deliveryInfo: {
    withinBounds: true,
    fee: 0,
    minimum: 0,
    feeVaries: false,
    minimumVaries: false,
    __typename: 'Dispensaries_deliveryInfo',
  },
  taxConfig: {
    version: 2,
    calculationMethod: 'cumulative',
    discountTaxOrder: 'discountsFirst',
    taxes: [
      {
        id: 'f3d3bc18-f36c-4efa-968d-26b3586ead52',
        type: 'cannabis',
        name: 'State excise tax',
        rate: 0.17,
        potencyRate: null,
        potency: null,
        medical: false,
        recreational: true,
        taxBasis: null,
        stages: [
          {
            op: 'noop',
            type: 'pos',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
          {
            op: 'noop',
            type: 'menu',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
          {
            op: 'add',
            type: 'checkout',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
        ],
        applyTo: {
          types: [
            'Flower',
            'Pre-Rolls',
            'Vaporizers',
            'Concentrate',
            'Edible',
            'Topicals',
            'Tincture',
            'CBD',
            'Clones',
            'Seeds',
          ],
          hemp: false,
          __typename: 'Dispensaries_TaxConfig_Tax_ApplyTo',
        },
        order: null,
        deliveryPolicy: null,
        __typename: 'Dispensaries_TaxConfig_Tax',
        destinationRate: null,
      },
      {
        id: '1b283700-8ae7-4acd-84c2-63be4d613e6a',
        type: 'cannabis',
        name: 'Local Excise',
        rate: 0.03,
        potencyRate: null,
        potency: null,
        medical: false,
        recreational: true,
        taxBasis: null,
        stages: [
          {
            op: 'noop',
            type: 'pos',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
          {
            op: 'noop',
            type: 'menu',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
          {
            op: 'add',
            type: 'checkout',
            __typename: 'Dispensaries_TaxConfig_Tax_Stage',
          },
        ],
        applyTo: {
          types: [
            'Flower',
            'Pre-Rolls',
            'Vaporizers',
            'Concentrate',
            'Edible',
            'Topicals',
            'Tincture',
            'CBD',
            'Clones',
            'Seeds',
          ],
          hemp: false,
          __typename: 'Dispensaries_TaxConfig_Tax_ApplyTo',
        },
        order: null,
        deliveryPolicy: null,
        __typename: 'Dispensaries_TaxConfig_Tax',
        destinationRate: null,
      },
    ],
    __typename: 'Dispensaries_TaxConfig',
  },
  orderTypesConfig: {
    pickup: {
      enabled: true,
      paymentTypes: {
        cash: true,
        debit: true,
        payOnlineMerrco: true,
      },
      __typename: 'OrderTypeConfig',
    },
    curbsidePickup: {
      enabled: true,
      paymentTypes: {
        cash: true,
        debit: true,
        payOnlineMerrco: true,
      },
      __typename: 'OrderTypeConfig',
    },
    driveThruPickup: {
      enabled: true,
      paymentTypes: {
        cash: true,
        debit: true,
      },
      __typename: 'OrderTypeConfig',
    },
    delivery: {
      enabled: true,
      paymentTypes: {
        cash: true,
        debit: true,
      },
      __typename: 'OrderTypeConfig',
    },
    kiosk: {
      enabled: true,
      paymentTypes: {
        cash: true,
        debit: true,
      },
      __typename: 'OrderTypeConfig',
    },
    __typename: 'OrderTypesConfig',
  },
  orderTypesConfigV2: {
    pickup: {
      enabled: true,
      paymentTypes: {
        cash: true,
        debit: true,
        payOnlineMerrco: true,
      },
      __typename: 'OrderTypeConfig',
    },
    curbsidePickup: {
      enabled: true,
      paymentTypes: {
        cash: true,
        debit: true,
        payOnlineMerrco: true,
      },
      __typename: 'OrderTypeConfig',
    },
    driveThruPickup: {
      enabled: true,
      paymentTypes: {
        cash: true,
        debit: true,
      },
      __typename: 'OrderTypeConfig',
    },
    delivery: {
      enabled: true,
      paymentTypes: {
        cash: true,
        debit: true,
      },
      __typename: 'OrderTypeConfig',
    },
    kiosk: {
      enabled: true,
      paymentTypes: {
        cash: true,
        debit: true,
      },
      __typename: 'OrderTypeConfig',
    },
    offerAnyPickupService: true,
    offerDeliveryService: true,
    __typename: 'OrderTypesConfigV2',
  },
  paymentTypesAccepted: {
    cash: true,
    debit: true,
    payOnlineMerrco: true,
    __typename: 'PaymentTypesConfig',
  },
  colorSettings: {
    linkColor: 'aqua-2',
    navBarColor: 'white',
    __typename: 'ColorSettings',
  },
  __typename: 'Dispensaries',
};
