/* eslint-disable max-len */

import React from 'react';

function arrowLeft(props) {
  return (
    <svg width='16' height='11' viewBox='0 0 16 11' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Arrow Left</title>
      <path d='M2.81478 4.46874H15.0145C15.4892 4.46874 15.8739 4.85348 15.8739 5.32811C15.8739 5.80275 15.4892 6.18749 15.0145 6.18749H2.81478L5.82956 9.18773C6.16596 9.52255 6.16725 10.0667 5.83244 10.4031C5.49758 10.7395 4.95342 10.7407 4.61707 10.406L0.378177 6.18749C-0.126099 5.82812 -0.12607 4.82812 0.378242 4.46874L4.61715 0.250247C4.95355 -0.0845222 5.49771 -0.0832764 5.83252 0.253168C6.16734 0.58957 6.16605 1.13368 5.82964 1.46849L2.81478 4.46874Z' />
    </svg>
  );
}

export default arrowLeft;
