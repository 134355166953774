import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';

import useTranslation from 'hooks/use-translation';
import useCart from 'hooks/use-cart';

import { DesktopOnly, MobileOnly } from 'shared/components';
import { useColors } from 'contexts/colors';
import { SearchInput } from 'components/core/text-input';
import { SearchInputShapeVariant } from 'components/core/text-input/search-input';
import { SearchDropdown } from './dropdown';

// @TODO give these better types once we know what data looks like
const useResults = (searchStr: string): any[] => {
  const [results, setResults] = useState<any[]>([]);

  useEffect(() => {
    // TODO: mock/get data
    setResults([
      {
        linkProps: {
          href: `/shop/search/[searchStr]`,
          query: {
            search: searchStr,
          },
        },
        copy: searchStr,
      },
    ]);
  }, [searchStr]);

  return results;
};

export function Search(): JSX.Element {
  const [searchStr, setSearchStr] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inFocus, setInFocus] = useState(false);
  const { t } = useTranslation();
  const results = useResults(searchStr);
  const Cart = useCart();
  const dispensary = Cart.dispensary ?? null;
  const colors = useColors();

  const handleChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchStr(value);
      if (value.length > 2) {
        setIsDropdownOpen(true);
      } else if (value.length === 0) {
        setIsDropdownOpen(false);
      }
    },
    [setSearchStr, setIsDropdownOpen]
  );

  const handleFocus = (): void => {
    setInFocus(true);
    if (searchStr.length > 2) {
      setIsDropdownOpen(true);
    }
  };

  const handleBlur = (): void => {
    setInFocus(false);
    setIsDropdownOpen(false);
  };

  const handleClearInput = (): void => {
    setSearchStr('');
    setIsDropdownOpen(false);
  };

  const commonSearchInputProps = {
    inFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    onClear: handleClearInput,
    onFocus: handleFocus,
    placeholder: t('search.placeholder', 'Search stores, products, brands...'),
    shapeVariant: SearchInputShapeVariant.marshmallow,
    value: searchStr,
  };

  return (
    <Container>
      <MobileOnly>
        <StyledSearchInput {...commonSearchInputProps} withClearButton />
      </MobileOnly>
      <DesktopOnly>
        <StyledSearchInput {...commonSearchInputProps} searchIconColor={inFocus ? colors.grey[70] : colors.moss[45]} />
      </DesktopOnly>
      {isDropdownOpen && <SearchDropdown results={results} searchStr={searchStr} dispensary={dispensary} />}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  position: relative;
  min-width: 300px;
  width: 100%;
  height: 44px;
  margin-right: 26px;
  border-radius: 8px;
  .MuiFormControl-root {
    height: inherit;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  ${({ theme: { colors, breakpoints }, inFocus }) => css`
    &&& {
      ${breakpoints.down('sm')} {
        width: calc(100vw - 105px);
      }

      ${breakpoints.up('md')} {
        background: ${inFocus ? colors.white : colors.green[95]};
        border: none;
        border-radius: 8px;
        padding-left: 23px;

        input::placeholder {
          color: ${colors.moss[45]};
          opacity: 1;
        }

        #search-icon {
          height: 19px;
          width: 19px;
          margin-right: 6px;
        }
      }
    }
  `}
`;
