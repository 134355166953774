import React from 'react';
import styled from 'styled-components';

import { Link } from 'components/core';
import { DutchieLogo } from 'assets/marketing/rebrand';

type HeaderLogoProps = {
  href?: string;
  route?: string;
};

export function HeaderLogo({ href, route }: HeaderLogoProps): JSX.Element {
  return (
    <Link href={href} route={route}>
      <LogoLink data-testid='dutchie-icon' href={href}>
        <DutchieLogo height='33px' color='white' />
      </LogoLink>
    </Link>
  );
}

const LogoLink = styled.a`
  line-height: 1;
  padding-top: 3px;
`;
