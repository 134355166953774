import React from 'react';

import useTranslation from 'src/hooks/use-translation';

import StoresIcon from 'assets/stores-icon';
import {
  Button,
  DialogContainer,
  DialogHeading,
  DialogIcon,
  DialogText,
  TextButton,
} from '../components/simple-dialog';

export type ClearYourCartProps = {
  onClearCart: () => void;
  onClose: () => void;
  currentDispensary: {
    name: string;
  };
};

export const ClearYourCart = ({ onClearCart, onClose, currentDispensary }: ClearYourCartProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { name } = currentDispensary;

  return (
    <DialogContainer>
      <DialogIcon>
        <StoresIcon />
      </DialogIcon>
      <DialogHeading fontSize={20}>{t('clear-your-cart.heading', 'Clear your cart?')}</DialogHeading>

      <DialogText data-testid='clear-your-cart-description'>
        {t(
          'clear-your-cart.description',
          `You are currently shopping at {{name}}.
          Searching all stores will clear your cart. Are you sure you want to continue?`,
          { name }
        )}
      </DialogText>

      <Button size='large' onClick={onClearCart}>
        {t('continue', 'Continue')}
      </Button>
      <TextButton onClick={onClose}>{t('cancel', 'Cancel')}</TextButton>
    </DialogContainer>
  );
};
