import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';

import { isMedOnly, isRecOnly } from 'shared/core/helpers/dispensaries';
import MenuTypeModal from 'components/modals/menu-type';

import MenuTypeModalButton from './menu-type-modal-button';

const MenuTypeSelector = ({ dispensary }) => {
  const [showMenuTypeModal, setShowMenuTypeModal] = useState(false);
  const menuTypeButtonRef = useRef();
  const cantSwitchMenuType = isMedOnly(dispensary) || isRecOnly(dispensary);

  return (
    <>
      <MenuTypeModalButton
        disabled={cantSwitchMenuType}
        onClick={() => setShowMenuTypeModal(!showMenuTypeModal)}
        ref={menuTypeButtonRef}
      />
      <PopoverContainer
        open={showMenuTypeModal}
        anchorEl={menuTypeButtonRef.current}
        onClose={() => setShowMenuTypeModal(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuTypeModal onClose={() => setShowMenuTypeModal(false)} />
      </PopoverContainer>
    </>
  );
};

export default MenuTypeSelector;

const PopoverContainer = styled(Popover)`
  .MuiPopover-paper {
    margin-top: 10px;
  }
`;
