import { ApolloError } from 'apollo-client';
import { DispensarySortByType, GqlGetChainDispensariesQuery, useGetChainDispensariesQuery } from 'types/graphql';

type UseGetChainLocationsArgs = {
  chain?: string;
  isUserInLegalMarket?: boolean;
  coordinates: { latitude?: number; longitude?: number };
};

type ChainLocations = GqlGetChainDispensariesQuery['filteredDispensaries'];

type UseGetChainLocationsReturn = {
  chainLocations: TransformedChainListItem[] | null;
  chainLocationsLoading: boolean;
  error: ApolloError | undefined;
};

type TransformedChainListItem = {
  cName: string;
  distance: number;
  distanceUnit: string;
  id: string;
  isDelivery: boolean;
  isPickup: boolean;
  name: string;
};

function transformChainLocations(chainLocations: ChainLocations): TransformedChainListItem[] | null {
  if (!chainLocations) {
    return null;
  }

  const metersPerMile = 1609.34;

  return chainLocations.reduce<TransformedChainListItem[]>((array, location) => {
    if (!location) {
      return array;
    }
    const { cName, distance, id, name, orderTypesConfigV2 } = location;

    if (
      !cName ||
      !distance ||
      !id ||
      typeof orderTypesConfigV2?.offerDeliveryService !== 'boolean' ||
      typeof orderTypesConfigV2.offerAnyPickupService !== 'boolean' ||
      !name
    ) {
      return array;
    }

    // if we have a dispensary w valid data, push to chain list
    array.push({
      cName,
      distance: parseFloat((distance / metersPerMile).toFixed(2)),
      distanceUnit: `mi`,
      id,
      isDelivery: orderTypesConfigV2.offerDeliveryService,
      isPickup: orderTypesConfigV2.offerAnyPickupService,
      name,
    });
    return array;
  }, []);
}

export function useGetChainLocations({
  chain,
  coordinates,
  isUserInLegalMarket,
}: UseGetChainLocationsArgs): UseGetChainLocationsReturn {
  const { latitude, longitude } = coordinates;

  const variables = {
    dispensaryFilter: {
      activeOnly: true,
      chain,
      distance: 1000000,
      nearLat: coordinates.latitude,
      nearLng: coordinates.longitude,
      sortBy: DispensarySortByType.distance,
    },
  };

  const hasValidCoordinates = !!latitude && !!longitude;

  const skip = !chain || !isUserInLegalMarket || !hasValidCoordinates;

  const { data, loading, error } = useGetChainDispensariesQuery({
    skip,
    variables,
  });

  const chainLocations = data?.filteredDispensaries ?? null;

  return { error, chainLocations: transformChainLocations(chainLocations), chainLocationsLoading: loading };
}
