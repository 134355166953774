import React from 'react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useTranslation from 'hooks/use-translation';

import { ModalExitIcon, ModalFooter } from 'components/core';
import { ModalContent, HeaderText, ModalFooterText, SignUpText as TermsText } from 'components/modals/components';
import { MobileEcommTermsLink } from 'src/mobile-ecomm/components/terms-link';
import SignUpForm from 'components/forms/sign-up-form';
import useUI from 'src/hooks/use-ui';
import { Divider } from 'src/components/divider';
import { GoogleLogin } from 'src/components/google-login';

export type SignupModalContentProps = {
  onClose: () => void;
  analyticsEventLabel?: string;
};

export function SignupModalContent({ onClose, analyticsEventLabel }: SignupModalContentProps): JSX.Element {
  const { t } = useTranslation();
  const UI = useUI();
  const { isMobileEcommApp } = UI;
  const flags = useFlags();
  const isGoogleLoginEnabled = flags[`growth.ecomm.sign-in-with-google.rollout`] ?? false;

  return (
    <>
      {!isMobileEcommApp && <ModalExitIcon onClick={onClose} />}
      <ModalContent pb={isMobileEcommApp ? 30 : 92}>
        {!isMobileEcommApp && (
          <HeaderText data-testid='sign-up-modal-header' pt={26} mb={25} mt={0}>
            {t('signUpModal.headerText', 'Create an Account')}
          </HeaderText>
        )}

        {isGoogleLoginEnabled && (
          <>
            <GoogleLogin redirectUrl={window.location.href} />
            <Divider text='or enter your information' />
          </>
        )}

        <SignUpForm onClose={onClose} analyticsEventLabel={analyticsEventLabel} />
      </ModalContent>
      <ModalFooter includeBackgroundColor={!isMobileEcommApp}>
        <ModalFooterText xSmall>
          {isMobileEcommApp
            ? t('signUpModal.footerText', `By creating an account, you agree to our`)
            : t('signUpModal.footerText', `By creating an account, you agree to Dutchie's`)}
        </ModalFooterText>
        <StyledTermsText xSmall isMobileEcommApp>
          {isMobileEcommApp ? (
            <MobileEcommTermsLink text={t('signUpModal.terms', 'Terms')} />
          ) : (
            <a data-testid='terms-link' href='/terms' target='_blank' rel='noopener noreferrer'>
              {t('signUpModal.terms', 'Terms')}
            </a>
          )}
        </StyledTermsText>
      </ModalFooter>
    </>
  );
}

const StyledTermsText = styled(TermsText)`
  margin-left: 0px;
  padding-left: ${({ isMobileEcommApp }) => (isMobileEcommApp ? '3px' : '5px')};
`;
