import useUI from 'hooks/use-ui';
import useViewport from 'hooks/use-viewport';

export type ContainerProps = {
  isIframe: boolean;
  iframeOffset: number;
  parentOffset: number;
  viewportHeight: number;
  viewportWidth: number;
  iframeHeight: number;
};

export function useContainerProps(): ContainerProps {
  const UI = useUI();
  const viewport = useViewport();

  return {
    isIframe: UI.isIframe,
    iframeOffset: viewport.offset,
    parentOffset: viewport.parentOffset,
    viewportHeight: viewport.height,
    viewportWidth: viewport.width,
    iframeHeight: UI.iframeHeight,
  };
}
