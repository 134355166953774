import React, { useState, useEffect } from 'react';
import { RadioGroup, Radio } from 'react-radio-group';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import useStyledTheme from 'shared/hooks/use-styled-theme';
import { Label } from 'shared/components/legacy-rebass';
import RadioCheckedIcon from 'shared/assets/radio-checked';
import RadioUncheckedIcon from 'shared/assets/radio-unchecked';
import { BankAccountDetails } from './bank-account-details';

type AccountSelectorRadioGroupProps = {
  checked: string;
  options: {
    accountAlias: string;
    accountGuid: string;
    bankName: string;
    lastDigits: string;
    availableBalance: number;
  }[];
  onChange: (value) => void;
  defaultValue?: string;
  name: string;
  required: boolean;
};

export function AccountSelectorRadioGroup({
  checked,
  options = [],
  onChange,
  defaultValue = '',
  name,
  required = false,
}: AccountSelectorRadioGroupProps): JSX.Element {
  const theme = useStyledTheme();
  // keep track of the current value
  const [currentValue, setCurrentValue] = useState('');

  function handleChange(value): void {
    setCurrentValue(value);
    onChange(value);
  }

  useEffect(() => {
    if (!_.isNil(checked)) {
      // set currentValue to checked prop if one is provided
      setCurrentValue(checked);
    } else if (currentValue === '' && defaultValue !== '' && !_.isNil(defaultValue)) {
      // if we don't start with a default value but then get one, we should default to that
      setCurrentValue(defaultValue);
    }
  }, [checked, currentValue, defaultValue]);

  return (
    <RadioGroup name={name} onChange={handleChange} selectedValue={currentValue} role='radiogroup' aria-label={name}>
      {_.map(options, (option) => {
        const { accountGuid } = option;
        const selected = accountGuid === currentValue;
        return (
          <RadioLabel key={accountGuid} selected={selected} name={name}>
            {accountGuid === currentValue && <RadioCheckedIcon color={theme.customized.colors.buttonsLinks} />}
            {accountGuid !== currentValue && <RadioUncheckedIcon />}
            <StyledRadio name='radio' value={accountGuid} aria-checked={selected} role='radio' required={required} />
            <AccountDetails>
              <BankAccountDetails bankAccountDetails={option} />
            </AccountDetails>
          </RadioLabel>
        );
      })}
    </RadioGroup>
  );
}

const RadioLabel = styled(Label)`
  ${({ theme: { colors }, selected }) => css`
    --border: 1px solid ${colors.grey[90]};

    border-top: var(--border);
    background: ${selected ? colors.selectedBankAccountBlue : colors.white};
    cursor: pointer;
    margin-bottom: 0;
    padding: 16px;

    :last-child {
      border-bottom: var(--border);
    }
    & > svg {
      width: 18px;
    }
    :hover {
      background: ${colors.grey[95]};
    }
  `}
`;

const StyledRadio = styled(Radio)`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

const AccountDetails = styled.p`
  padding-left: 16px;
  width: 100%;
  text-align: left;
`;
