import styled from 'styled-components';
import { width, space } from 'styled-system';
import { TextButton, Button, TextInput } from 'components/core';
import { heading3, paragraph, subparagraph, paragraphLink } from 'components/core/typography';

export const StyledTextInput = styled(TextInput)`
  background: ${({ theme }) => theme.colors.primaryGrey};
  border: ${({ theme }) => `1px solid ${theme.colors.grey[80]}`};
  color: ${({ theme }) => theme.colors.grey[35]};
  height: 55px;

  & > input {
    padding-top: 30px;
    padding-left: 25px;
  }

  &.Mui-focused {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.blue[55]};
    box-shadow: ${({ theme }) => `0px 1px 5px ${theme.colors.blueShadow}`};
    background-color: ${({ theme }) => theme.colors.white};
  }

  &.Mui-error {
    border: 1px solid ${({ theme }) => theme.colors.red[60]};
    &.MuiFormLabel-root {
      color: ${({ theme }) => `${theme.colors.red[60]} !important`};
    }
  }
`;

export const InputContainer = styled.div`
  ${width}
  ${space}
`;

export const HeaderText = styled.h3`
  ${heading3};
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 16px 2px 2px;
  text-align: left;

  ${space}
`;

export const ModalContent = styled.div`
  padding-left: 40px;
  padding-right: 40px;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    padding-left: 34px;
    padding-right: 34px;
  }

  ${space}
`;

export const LoginOptionsContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  display: flex;
`;

export const ModalFooterText = styled.p`
  ${paragraph};
  color: ${({ theme }) => theme.colors.grey[45]};
  ${({ xSmall }) => xSmall && `font-size: 11px;`}
`;

export const SignUpText = styled(TextButton)`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.sapphire};
  ${({ xSmall }) => xSmall && `font-size: 11px;`};
`;

export const SecondaryOptionText = styled(TextButton)`
  display: flex;
  align-items: center;
  margin-left: 3px;
  color: ${({ theme }) => theme.colors.grey[45]};
  font-weight: bold;
  font-size: ${({ larger }) => (larger ? paragraph : subparagraph)};
  ${({ blueText }) => blueText && paragraphLink};

  &:hover {
    text-decoration: none;
  }
`;

export const StyledButton = styled(Button)`
  width: 95px;
`;
