import React from 'react';
import { useObserver } from 'mobx-react-lite';

import useDispensary from 'src/dispensary/hooks/use-dispensary';

import JsonLDScript from './json-ld';
import Meta from './meta';

export default function DispensaryTags({ url }) {
  const { dispensary } = useDispensary();
  const listImage = useObserver(() => dispensary?.listImage);
  const dispensaryNotActive = dispensary?.status !== 'open';

  if (!dispensary) {
    return null;
  }

  return (
    <>
      <Meta property='og:image' content={listImage} />
      {dispensaryNotActive && (
        <>
          <Meta property='robots' content='noindex,nofollow' />
          <Meta property='googlebot' content='noindex,nofollow' />
        </>
      )}
      <JsonLDScript variant='dispensary' jsonLdData={{ ...dispensary, url }} />
    </>
  );
}
