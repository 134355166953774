import React from 'react';
import { observer } from 'mobx-react-lite';

import { useLocationSettings } from './location-settings.hooks';
import { LocationSettingsPopover } from './location-settings-popover';
import { LocationSettingsControllerProps } from './location-settings-popover.types';

export const LocationSettingsController = observer(
  ({ onSave, anchorEl }: LocationSettingsControllerProps): JSX.Element => (
    <LocationSettingsPopover anchorEl={anchorEl} {...useLocationSettings({ onSave })} />
  )
);
