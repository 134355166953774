import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Image = styled.img`
  width: 160px;
`;

export function DefaultIcon(): ReactElement {
  return <Image alt='404' src='/icons/404-face.svg' />;
}
