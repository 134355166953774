import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["color", "top", "right"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { ResetButton } from 'shared/components';
import { display } from 'styled-system';
export default styled(function (_ref) {
  var color = _ref.color,
      top = _ref.top,
      right = _ref.right,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx(ResetButton, _extends({}, props, {
    "aria-label": "Close"
  }));
}).withConfig({
  displayName: "close",
  componentId: "x77rqs-0"
})(["", " position:", ";height:36px;opacity:", ";top:", ";right:", ";width:36px;&:before{height:3px;width:50%;}&:after{height:50%;width:3px;}&:before,&:after{background-color:", ";content:'';display:block;left:50%;position:absolute;top:50%;transform-origin:center center;transform:translateX(-50%) translateY(-50%) rotate(45deg);}"], display, function (props) {
  return props.position || 'absolute';
}, function (props) {
  return props.color ? 1 : 0.7;
}, function (props) {
  return props.top || '8px';
}, function (props) {
  return props.right || '6px';
}, function (props) {
  return props.color || '#7f8683';
});