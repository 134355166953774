// From https://github.com/juliencrn/usehooks-ts package, but requires node 16

import { RefObject, useEffect, useState } from 'react';

type Args = IntersectionObserverInit & {
  freezeOnceVisible?: boolean;
};

export function useIntersectionObserver(
  elementRef: RefObject<Element>,
  options?: Args
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false } = options ?? {};

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!hasIOSupport || frozen || !node) {
      return;
    }

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
}
