import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Backdrop from '@material-ui/core/Backdrop';

import ExitIcon from 'shared/assets/exit-icon';
import { ModalContainer } from 'shared/modals/v2-container';
import { DutchiePayHeader } from 'src/payments/components';

type DutchiePayInfoModalProps = {
  children: React.ReactNode;
  onClose: () => void;
  title: string;
};

export const DutchiePayInfoModal = forwardRef<JSX.Element, DutchiePayInfoModalProps>((props, ref) => {
  const { children, onClose, title } = props;

  return (
    <StyledModalContainer ref={ref}>
      <ModalBody>
        <HeaderBar data-testid='header-bar'>
          <Header>{title}</Header>
          <StyledExitIcon
            data-testid='exit-icon'
            onClick={onClose}
            style={{
              // Positions the edge of the 'X' right against the edge of the container
              // while allowing vertical centering
              position: 'absolute',
              right: '26px',
            }}
          />
        </HeaderBar>
        {children}
      </ModalBody>
    </StyledModalContainer>
  );
});

const StyledExitIcon = styled(ExitIcon)`
  ${({ theme: { colors } }) => css`
    height: 25px;
    width: 25px;
    cursor: pointer;

    fill: ${colors.grey[40]};
    stroke: ${colors.grey[40]};
  `}
`;

const Header = styled(DutchiePayHeader)`
  margin-bottom: 0;
  font-size: 20px;
`;

const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalBody = styled.div`
  ${({ theme: { spaces } }) => css`
    padding: ${spaces[9]} ${spaces[8]};

    display: flex;
    flex-direction: column;
    gap: 20px;
  `}
`;

export const InfoBackdropComponent = styled(Backdrop)`
  &.MuiBackdrop-root {
    opacity: 0.5 !important;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  max-width: 343px;
  padding: 0;
  text-align: left;
`;
