import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import Document from 'global/document';

import { orderTypeToIndex, ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY } from 'shared/constants';

import useUI from 'hooks/use-ui';
import useHighlightAddressSuggestions from 'hooks/use-highlight-address-suggestions';
import useMapboxPlacesAutocomplete from 'hooks/use-mapbox-places-autocomplete';
import useShortcut from 'hooks/use-shortcut';
import useTranslation from 'hooks/use-translation';
import getLocationsFromCoordinates from 'utils/get-locations-from-coordinates';
import routeTo from 'utils/route-to';
import useErnie from 'shared/hooks/use-ernie';

import ClearIcon from 'assets/clear-icon';
import { Button, Dropdown, Link, Tab, Tabs } from 'components/core';
import { Clickable } from 'shared/components';
import ROUTES from 'src/routes';
import PlacesList from './places-list';
import TextInput from './text-input';

export default function PlacesAutocompleteInput(props) {
  /**
   * requestOptions.types takes an array of the options listed
   * here: https://docs.mapbox.com/api/search/#data-types
   *
   * The default uses every type but 'poi', which appears
   * to return results close to Google's 'geocode' setting
   * with the exception of county suggestions.
   *
   * 'address' should be used in situations where a complete street
   * address is required, such as for delivery orders.
   *
   * For other requestOptions, check out the config object here:
   * https://github.com/mapbox/mapbox-sdk-js/blob/master/docs/services.md#forwardgeocode
   */
  const {
    hasOrderTypeSelection,
    orderType = ORDER_TYPE_PICKUP,
    onChangeOrderType,
    onClear: onClearFromProps,
    suggestionTypes,
    onChange,
    variant = `primary`,
    displayEndAdornment = false,
    useDropdown = true,
    onChangeFocus = _.noop,
    navigateToDispensariesListOnSelect,
    listContainerStyles,
    currentLocationStyles,
    children,
    hideList,
    isHomepage = false,
    ...otherProps
  } = props;
  const { isDutchieMain } = useUI();
  const { t } = useTranslation();
  const [showPlaces, setShowPlaces] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [geolocationSuggestions, setGeolocationSuggestions] = useState([]);
  const [currentLocationLoading, setCurrentLocationLoading] = useState(false);
  const [currentMethod, setCurrentMethod] = useState();
  const [autoFillDetected, setAutoFillDetected] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isPlacesListFocused, setIsPlacesListFocused] = useState(false);
  const textInputEl = useRef(null);
  const containerRef = useRef(null);
  const showErnie = useErnie();

  const {
    ready: autocompleteReady,
    value: addressValue,
    suggestions: { data },
    setValue: setAddressValue,
    clearSuggestions: clearAutocompleteSuggestions,
  } = useMapboxPlacesAutocomplete({
    requestOptions: {
      ...(suggestionTypes && { types: suggestionTypes }),
    },
  });

  useEffect(() => {
    onChangeFocus(showPlaces);
  }, [showPlaces, onChangeFocus]);

  const handleAutoFill = ({ animationName }) => {
    setAutoFillDetected(animationName === 'mui-auto-fill');
  };

  const reset = () => {
    onClearFromProps?.();
    clearAutocompleteSuggestions();
    setGeolocationSuggestions([]);
    setShowPlaces(false);
    setDisplayError(false);
  };

  const saveAddress = (location) => {
    if (location) {
      setSelectedIndex(-1);
      setShowPlaces(false);
      onChange(location);
      if (navigateToDispensariesListOnSelect) {
        routeTo({ url: ROUTES.DISPENSARIES });
      }
    } else {
      showErnie(
        t('addressSearch.invalidAddressSelection', 'An invalid address was selected. Please try a different address.'),
        `danger`
      );
    }
  };

  const handleAddressSelected = (location) => {
    const { address } = location;
    setAddressValue(address, false);
    saveAddress(location);
  };

  const handleChange = (event) => {
    if (_.isEmpty(event.target.value)) {
      setSelectedIndex(-1);
      clearAutocompleteSuggestions();
    }
    setAddressValue(event.target.value);
    setCurrentMethod(`manual`);
    setShowPlaces(true);
  };

  const handleCurrentLocationSelected = () => {
    setCurrentLocationLoading(true);
    setDisplayError(false);
    setCurrentMethod(`geolocation`);
    navigator.geolocation.getCurrentPosition(async (location) => {
      const { latitude, longitude } = location.coords;
      const response = await getLocationsFromCoordinates({ latitude, longitude });
      const { data: suggestions, status: responseStatus } = response;
      if (responseStatus === 'OK' || responseStatus === 200) {
        setGeolocationSuggestions(suggestions);
        setCurrentLocationLoading(false);
        setSelectedIndex(data.length > 0 ? 0 : -1);
      } else {
        showErnie(
          t(
            'addressSearch.currentLocationError',
            'An issue occurred while trying to use your current location. Please try again later.'
          ),
          `danger`
        );
      }
    });
  };

  const handleGeolocationSuggestionSelected = async (location) => {
    const { address } = location;
    setAddressValue(address, false);
    saveAddress(location);
  };

  const handleKeyDown = (e) => {
    if (!isInputFocused && !isPlacesListFocused) {
      return;
    }
    e.preventDefault();
    setSelectedIndex((selectedSuggestion) => {
      const newValue = selectedSuggestion + 1;
      const suggestions = currentMethod === 'manual' ? data : geolocationSuggestions;

      if (suggestions.length <= 0) {
        return -1;
      }

      if (newValue <= suggestions.length - 1) {
        return newValue;
      }

      return selectedSuggestion;
    });
  };

  const handleKeyUp = (e) => {
    if (!isInputFocused && !isPlacesListFocused) {
      return;
    }
    e.preventDefault();
    setSelectedIndex((selectedSuggestion) => {
      const minValue = navigator.geolocation ? -1 : 0;
      const newValue = selectedSuggestion - 1;

      if (newValue >= minValue) {
        return newValue;
      }

      return selectedSuggestion;
    });
  };

  const suggestionConfig = {
    // eslint-disable-next-line max-len
    suggestionsForSearch: currentMethod === 'geolocation' ? geolocationSuggestions : data, // this gets mutated down the chain depending on if its a manual search (for highlighting results).
    mapboxSuggestions: currentMethod === 'geolocation' ? geolocationSuggestions : data,
    method: (v) =>
      currentMethod === 'geolocation' ? handleGeolocationSuggestionSelected(v) : handleAddressSelected(v),
  };

  const handleKeyEnter = (e) => {
    if (e.key === 'Enter') {
      if (selectedIndex < 0) {
        setDisplayError(true);
        setShowPlaces(true);
        return;
      }
      handleAddressSelected(
        suggestionConfig.mapboxSuggestions?.[selectedIndex] || suggestionConfig.suggestionsForSearch[selectedIndex]
      );
      if (isDutchieMain && selectedIndex >= 0 && addressValue && navigateToDispensariesListOnSelect) {
        routeTo({ url: ROUTES.DISPENSARIES });
      }
    }
  };

  const handleKeyEscape = () => {
    if (showPlaces) {
      document.getElementById('auto-complete').focus();
      setSelectedIndex(-1);
      setShowPlaces(false);
    }
  };

  const validateInput = useCallback(
    _.debounce((hasError) => {
      setDisplayError(hasError);
    }, 500),
    []
  );
  const highlightedSuggestions = useHighlightAddressSuggestions(addressValue, suggestionConfig.mapboxSuggestions);
  if (currentMethod !== 'geolocation') {
    suggestionConfig.suggestionsForSearch = highlightedSuggestions;
  }
  useShortcut(`down`, handleKeyDown);
  useShortcut(`up`, handleKeyUp);
  useShortcut(`escape`, handleKeyEscape);

  useEffect(() => {
    const determineIfDropdownShouldClose = (event) => {
      if (showPlaces) {
        const componentRoot = _.get(containerRef, `current`);
        const target = _.get(event, `target`);
        if (!componentRoot || !target || !componentRoot?.contains(target)) {
          setShowPlaces(false);
          setDisplayError(false);
        }
      }
    };
    window.addEventListener(`click`, determineIfDropdownShouldClose);
    window.addEventListener(`keyup`, determineIfDropdownShouldClose);

    return () => {
      window.removeEventListener(`click`, determineIfDropdownShouldClose);
      window.removeEventListener(`keyup`, determineIfDropdownShouldClose);
    };
  }, [showPlaces, textInputEl]);

  useEffect(() => {
    const hasError =
      addressValue.length > 3 &&
      autocompleteReady &&
      _.isEmpty(suggestionConfig.mapboxSuggestions) &&
      selectedIndex > -1;
    validateInput(hasError);
    return () => setDisplayError(false);
  }, [addressValue, autocompleteReady, selectedIndex, suggestionConfig.mapboxSuggestions, validateInput]);

  const errorConfig = {
    hasError: displayError,
    errorMessage: _.isEmpty(suggestionConfig.mapboxSuggestions)
      ? t('addressInput.pleaseEnterValid', 'Please enter a valid address.')
      : t('addressInput.pleaseSelectAddress', 'Please select an address below.'),
  };

  const renderCTA = (displayLink = false) => {
    if (displayLink) {
      return (
        <Link href={ROUTES.DISPENSARIES}>
          <StyledShoppingButton variant='flora' onClick={reset}>
            {t('home.hero.action-button', 'Start shopping')}
          </StyledShoppingButton>
        </Link>
      );
    }
    return (
      <div>
        <StyledShoppingButton
          variant='flora'
          onClick={() => {
            setDisplayError(true);
            setShowPlaces(true);
          }}
          className='cta-button'
        >
          {t('home.hero.action-button', 'Start shopping')}
        </StyledShoppingButton>
      </div>
    );
  };

  const renderClearButton = () =>
    addressValue && (
      <ClearButton
        onClick={() => {
          reset();
          setAddressValue('', false);
        }}
      >
        <ClearIcon />
      </ClearButton>
    );

  const renderPlacesList = () => {
    if (useDropdown) {
      return (
        <StyledDropdown
          className='dropdown'
          width='100%'
          py='9px'
          borderRadius='8px'
          zIndex={2}
          error={errorConfig.hasError}
        >
          {hasOrderTypeSelection && (
            <TabContainer isHomepage={isHomepage}>
              <Tabs value={orderTypeToIndex[orderType] || 0} onChange={onChangeOrderType}>
                <Tab aria-label={ORDER_TYPE_PICKUP} label={t('common.pickup', 'Pickup')} />
                <Tab aria-label={ORDER_TYPE_DELIVERY} label={t('common.delivery', 'Delivery')} />
              </Tabs>
            </TabContainer>
          )}
          <PlacesList
            selectedIndex={selectedIndex}
            suggestionConfig={suggestionConfig}
            currentLocationLoading={currentLocationLoading}
            handleCurrentLocationSelected={handleCurrentLocationSelected}
            errorConfig={errorConfig}
            listContainerStyles={listContainerStyles}
            currentLocationStyles={currentLocationStyles}
            setIsPlacesListFocused={setIsPlacesListFocused}
            onKeyPress={handleKeyEnter}
            isHomepage={isHomepage}
          />
        </StyledDropdown>
      );
    }

    return (
      <PlacesListContainer>
        <PlacesList
          selectedIndex={selectedIndex}
          suggestionConfig={suggestionConfig}
          currentLocationLoading={currentLocationLoading}
          handleCurrentLocationSelected={handleCurrentLocationSelected}
          listContainerStyles={listContainerStyles}
          currentLocationStyles={currentLocationStyles}
          errorConfig={errorConfig}
          isInDropdown={false}
          setIsPlacesListFocused={setIsPlacesListFocused}
          onKeyPress={handleKeyEnter}
          isHomepage={isHomepage}
        />
      </PlacesListContainer>
    );
  };

  const shouldFocus = Document.activeElement === textInputEl.current;

  return (
    <div ref={containerRef}>
      <StyledTextInput
        {...otherProps}
        _autoFillDetected={autoFillDetected}
        _isFocused={shouldFocus || showPlaces}
        _inputVariant={variant}
        onKeyPress={handleKeyEnter}
        disabled={!autocompleteReady}
        value={addressValue}
        onChange={handleChange}
        onFocus={() => {
          setShowPlaces(true);
          setIsInputFocused(true);
        }}
        onBlur={() => setIsInputFocused(false)}
        inputRef={textInputEl}
        id='auto-complete'
        endAdornment={displayEndAdornment ? renderCTA(!!addressValue && selectedIndex >= 0) : renderClearButton()}
        // the position for the clear button needs to be 'end'
        endAdornmentPosition={!displayEndAdornment && `end`}
        onAnimationStart={handleAutoFill}
        autoComplete='off'
        inputProps={{
          role: 'combobox',
          'aria-autocomplete': 'list',
        }}
      />
      {/* TODO: refactor and decouple later to ensure backwards compatibility  CH-73252 */}
      {!hideList && showPlaces
        ? renderPlacesList()
        : children?.({
            suggestionConfig,
            currentLocationLoading,
            handleCurrentLocationSelected,
            errorConfig,
          })}
    </div>
  );
}

const ClearButton = styled(Clickable)`
  border: none;
  padding: 0;
  width: 19px;
  height: 19px;
  z-index: 10;
  border-radius: 19px;
  background-color: ${({ theme }) => theme?.colors?.grey[70]};
  svg {
    height: 19px;
  }
`;

const PrimaryInputStyles = css`
  background-color: ${({ theme }) => theme.colors.white};
  height: 74px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.primaryGrey};
  &&& {
    border-radius: 14px;
  }
`;

const StyledShoppingButton = styled(Button)`
  text-transform: none;
  left: 5px;
`;

const SecondaryInputStyles = css`
  background: ${({ theme }) => theme.colors.primaryGrey};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGrey[80]};
  height: 56px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
`;

const FocusedInputStyles = css`
  /* Appending 40% alpha to the hex color */
  box-shadow: 0 0 0 2px ${({ theme }) => theme.customized.colors.buttonsLinks}66 !important;
`;

const StyledTextInput = styled(
  forwardRef(({ _autoFillDetected, _inputVariant, _isFocused, ...props }, ref) => <TextInput {...props} ref={ref} />)
)`
  ${({ _inputVariant }) => (_inputVariant === 'secondary' ? SecondaryInputStyles : PrimaryInputStyles)}
  position: relative;
  cursor: pointer;
  .MuiOutlinedInput-adornedStart,
  .MuiOutlinedInput-adornedEnd {
    padding: 0;
  }
  &&& > input {
    ::placeholder {
      color: ${({ theme }) => theme.colors.grey[35]} !important;
      opacity: 1;
    }
  }
  background-color: ${({ _autoFillDetected, theme }) =>
    _autoFillDetected && `${theme.colors.chromeAutoFill} !important`};

  [class*='positionStart'] {
    fill: ${({ theme }) => theme.colors.grey[70]};
    ${({ theme }) => theme.breakpoints.down(`xs`)} {
      margin-left: 8px;
      height: 28px;
      width: 28px;
    }
  }

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    height: 60px;
    max-width: ${({ isLocationSettings }) => (isLocationSettings ? '100%' : `324px`)};
    margin: 0 auto;
    input {
      font-size: 16px;
      ::placeholder {
        font-size: 13px;
      }
    }
  }
  ${({ _isFocused }) => _isFocused && FocusedInputStyles};
  .MuiOutlinedInput-notchedOutline {
    display: none !important;
  }
`;

const TabContainer = styled.div`
  margin-bottom: 19px;
  .MuiTabs-flexContainer {
    margin: 12px 0;
  }
  .Mui-focusVisible {
    box-shadow: 0 0 0 2pt rgb(11 153 230 / 40%);
  }
  .MuiTab-textColorInherit {
    color: ${({ theme, isHomepage }) => (isHomepage ? theme.colors.grey[25] : theme.colors.grey[30])};
  }
  .MuiTab-textColorInherit.Mui-selected {
    color: ${({ theme, isHomepage }) => (isHomepage ? theme.colors.opal : theme.colors.green[40])};
  }
  .MuiTabs-indicator {
    background-color: ${({ theme, isHomepage }) => (isHomepage ? theme.colors.opal : theme.colors.green[40])};
  }
  > div {
    padding-left: 24px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${({ error, theme }) => error && `border: 1px solid ${theme.colors.red[55]}`};

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    max-width: 325px;
  }
  margin-top: 2px !important;
`;

const PlacesListContainer = styled.div`
  width: 100%;
  padding-top: 22px;
  button {
    width: 100%;
  }
`;
