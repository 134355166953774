import { useEffect } from 'react';

import useUI from 'hooks/use-ui';

export default function useIframeHeightOverride(height) {
  const UI = useUI();

  useEffect(() => {
    if (!height) {
      return;
    }
    UI.iframeHeightOverride = height;

    UI.embeddedEventDispatcher('iframe:resize:override', { height });

    return () => (UI.iframeHeightOverride = null);
  }, [UI, height]);
}
