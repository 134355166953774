/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export function PlaidLogoSmall(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='54' height='16' viewBox='0 0 54 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_3792_33868)'>
        <path
          d='M15.258 14.7101L16.5212 9.74172L14.7528 7.9733L16.5212 6.20488L15.258 1.23646L10.2054 -0.0267029L8.43699 1.74172L6.66857 -0.0267029L1.61594 1.23646L0.352783 6.20488L2.1212 7.9733L0.352783 9.74172L1.61594 14.7101L6.66857 15.9733L8.43699 14.2049L10.2054 15.9733L15.258 14.7101ZM9.44752 13.1943L11.1317 11.5101L13.237 13.6154L10.6265 14.2891L9.44752 13.1943ZM6.75278 5.27856L8.43699 3.59435L10.1212 5.27856L8.43699 6.96277L6.75278 5.27856ZM7.42647 7.9733L5.74226 9.65751L4.05805 7.9733L5.74226 6.28909L7.42647 7.9733ZM11.1317 6.28909L12.8159 7.9733L11.1317 9.65751L9.44752 7.9733L11.1317 6.28909ZM10.1212 10.5838L8.43699 12.268L6.75278 10.5838L8.43699 8.89961L10.1212 10.5838ZM6.33173 14.3733L3.7212 13.6996L5.82647 11.5943L7.51068 13.2786L6.33173 14.3733ZM3.13173 8.89961L4.81594 10.5838L2.71068 12.6891L2.03699 10.0786L3.13173 8.89961ZM2.62647 3.1733L4.73173 5.27856L3.04752 6.96277L1.95278 5.86803L2.62647 3.1733ZM7.42647 2.66803L5.74226 4.35224L3.63699 2.24698L6.24752 1.5733L7.42647 2.66803ZM10.5423 1.5733L13.1528 2.24698L11.0475 4.35224L9.36331 2.75224L10.5423 1.5733ZM13.7423 6.96277L12.058 5.27856L14.1633 3.1733L14.837 5.78382L13.7423 6.96277ZM14.2475 12.6891L12.1423 10.5838L13.8265 8.89961L14.9212 9.99435L14.2475 12.6891Z'
          fill='#242526'
        />
        <path
          d='M21.8351 7.68627H22.6979C23.1684 7.68627 23.4822 7.60784 23.7175 7.45098C23.9528 7.29412 24.0312 7.05882 24.0312 6.7451C24.0312 6.43137 23.8743 6.19608 23.639 6.03922C23.4037 5.88235 23.0116 5.80392 22.6194 5.80392H21.8351V7.68627ZM21.8351 9.4902V12H19.9528V4H22.6194C23.7959 4 24.5802 4.23529 25.1292 4.62745C25.6782 5.17647 25.992 5.88235 25.992 6.7451C25.992 7.60784 25.6782 8.31373 25.1292 8.78431C24.6586 9.2549 23.8743 9.4902 22.7763 9.4902H21.8351ZM29.3645 10.2745H31.7959V12H27.4037V4H29.443V10.2745H29.3645ZM35.7959 9.2549L37.5214 9.33333H37.5998V9.2549L36.7371 6.5098L35.7959 9.2549ZM35.2469 10.8235L34.7763 12H32.5802L35.7959 4H37.3645L40.5802 12H38.541L38.1488 10.902H35.2469V10.8235ZM47.7175 10.2745H48.6586C49.3645 10.2745 49.8351 10.1176 50.1488 9.72549C50.4626 9.33333 50.6194 8.78431 50.6194 8.07843C50.6194 7.37255 50.4626 6.82353 50.0704 6.43137C49.6783 6.03922 49.2077 5.80392 48.5802 5.80392H47.639V10.2745H47.7175ZM51.8743 5.56863C52.4233 6.19608 52.6586 7.05882 52.6586 8C52.6586 9.2549 52.2665 10.1961 51.639 10.902C50.9331 11.6078 49.992 12 48.8939 12H45.7567V4H48.4233C49.5214 4 50.3841 4.15686 50.9332 4.54902C51.3253 4.86275 51.639 5.17647 51.8743 5.56863ZM43.7959 4V12H41.7567V4H43.7959Z'
          fill='#242526'
        />
      </g>
      <defs>
        <clipPath id='clip0_3792_33868'>
          <rect width='53.3333' height='16' fill='white' transform='translate(0.666626)' />
        </clipPath>
      </defs>
    </svg>
  );
}
