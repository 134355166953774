import React from 'react';
import styled from 'styled-components';

import { formatDiscountForDisplay } from 'shared/helpers/specials';
import OrigDiscountBadge from 'shared/components/discount-badge/discount-badge';

import { Product } from 'utils/helpers/product';

import ProductImage from 'components/product-image';
import { QuantityControls } from 'components/quantity-controls';

export const TEST_IDS = {
  quantityControls: `quantityControls`,
  productBrandName: `productBrandName`,
};

export enum BadgeType {
  offer = 'offer',
  sale = 'sale',
}

export type DiscountBadgeProps = {
  type: BadgeType;
  text: string;
};

export type CartItemDetailsProps = {
  badge?: DiscountBadgeProps;
  disableDecrementing: boolean;
  disableIncrementing: boolean;
  formattedOption: string;
  formattedSpecialPrice?: string;
  formattedStandardPrice: string;
  handleDecrement: () => void;
  handleIncrement: () => void;
  product: Product;
  quantity: number;
};

export function CartItemDetails({
  badge,
  disableDecrementing,
  disableIncrementing,
  handleDecrement,
  handleIncrement,
  product,
  quantity,
  formattedOption,
  formattedSpecialPrice,
  formattedStandardPrice,
}: CartItemDetailsProps): JSX.Element {
  return (
    <Container>
      <ProductImage product={product} src={product.Image} width={85} height={85} />

      <ProductDetailsContainer>
        {badge && (
          <DiscountBadge
            type={badge.type}
            displayText={formatDiscountForDisplay(
              parseFloat(formattedStandardPrice),
              formattedSpecialPrice === undefined ? formattedSpecialPrice : parseFloat(formattedSpecialPrice),
              product,
              null,
              formattedOption
            )}
          />
        )}

        {product.brand.name && (
          <ProductBrandName data-testid={TEST_IDS.productBrandName}>{product.brand.name}</ProductBrandName>
        )}

        <ProductName>{product.name}</ProductName>

        <ProductOptionAndPrice>
          {formattedOption} @ {formattedSpecialPrice ?? formattedStandardPrice}&nbsp;/ea&nbsp;
          {formattedSpecialPrice && <StrikedOutPrice>{formattedStandardPrice}</StrikedOutPrice>}
        </ProductOptionAndPrice>
      </ProductDetailsContainer>

      <QuantityControlsContainer>
        <StyledQuantityControls
          data-testid={TEST_IDS.quantityControls}
          disableIncrementing={disableIncrementing}
          disableDecrementing={disableDecrementing}
          onDecrement={handleDecrement}
          onIncrement={handleIncrement}
          quantity={quantity}
          isVisible
        />
      </QuantityControlsContainer>
    </Container>
  );
}

const StyledQuantityControls = styled(QuantityControls)`
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
`;

const QuantityControlsContainer = styled.div`
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  position: relative;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 85px auto 40px;
  grid-gap: 5px;
  position: relative;
  text-align: left;
`;

const ProductDetailsContainer = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  justify-items: start;
  align-content: center;
`;

const ProductBrandName = styled.span`
  color: ${({ theme }) => theme.colors.grey[35]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 13px;
  line-height: 21px;
`;

const ProductName = styled.span`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
`;

const ProductOptionAndPrice = styled.span`
  color: ${({ theme }) => theme.colors.grey[45]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
`;

const StrikedOutPrice = styled.span`
  color: ${({ theme }) => theme.colors.grey[70]};
  text-decoration-line: line-through;
`;

const DiscountBadge = styled(OrigDiscountBadge)`
  height: auto;
  margin-bottom: 3px;
`;
