import React from 'react';

import { Weight } from './weight';

export type WeightTilesProps = {
  handleWeightChange: (option: string) => void;
  selectedWeight: string | null;
  weightOptions: string[];
};

export function WeightTiles({ handleWeightChange, selectedWeight, weightOptions }: WeightTilesProps): JSX.Element {
  return (
    <>
      {weightOptions.map((option: string) => (
        <Weight
          key={option}
          onClick={() => handleWeightChange(option)}
          selected={selectedWeight === option}
          weight={option}
        />
      ))}
    </>
  );
}
