import React from 'react';
import styled from 'styled-components';

import { ContentWrapper } from 'components/core';

export default function Subheader(props) {
  const { children } = props;
  return (
    <ContentWrapper bg='white' borderY='1px solid' borderColor='grey.80' boxShadow='subheader' {...props}>
      <Container>{children}</Container>
    </ContentWrapper>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 63px;
  flex-grow: 0;
`;
