import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import useTranslation from 'hooks/use-translation';

import { Grid, Link } from 'components/core';
import { FooterList, FooterDivider } from 'components/core/footer-rebrand';
import FacebookIcon from 'assets/marketing/facebook';
import InstagramIcon from 'assets/marketing/instagram';
import TwitterIcon from 'assets/marketing/twitter';

import ROUTES from 'src/routes';
import { REFER_A_DISPENSARY_LINK, RELEASES_LINK, HELP_LINK, PRODUCT_SUPPORT_LINK } from 'src/constants/external-links';
import { VisuallyHidden } from '../core/visually-hidden';

export default function GlobalFooterContent(props) {
  const { t } = useTranslation();

  const CompanyLinks = [
    {
      label: t('common.aboutUs', 'About'),
      href: ROUTES.ABOUT,
    },
    {
      label: t('common.careers', 'Careers'),
      href: ROUTES.CAREERS,
    },
    {
      label: t('common.customerSupport', 'Customer Support'),
      href: HELP_LINK,
    },
    {
      label: t('common.productSupport', 'Product Support'),
      href: PRODUCT_SUPPORT_LINK,
    },
    {
      label: t('common.pressMedia', 'Press & Media'),
      href: 'mailto:press@dutchie.com',
    },
  ];

  const LegalLinks = [
    {
      label: t('common.privacy', 'Privacy Policy'),
      href: ROUTES.PRIVACY,
    },
    {
      label: t('common.terms', 'Terms of Service'),
      href: ROUTES.TERMS,
    },
    {
      label: t('common.accessibility', 'Accessibility'),
      href: ROUTES.ACCESSIBILITY,
    },
    {
      label: t('common.security', 'Security'),
      href: `https://trust.dutchie.com`,
    },
    {
      label: t('common.dispoTerms', 'Dispensary Terms'),
      href: `https://try.dutchie.com/merchant-agreement`,
    },
  ];

  const ResourceLinks = [
    {
      label: t('common.learn', 'Blog'),
      href: ROUTES.BLOG,
    },
    {
      label: t('common.productUpdates', 'Product Updates'),
      href: RELEASES_LINK,
    },
    {
      label: t('common.ReferADispensary', 'Refer a Dispensary'),
      href: REFER_A_DISPENSARY_LINK,
    },
    {
      label: t('common.shopForCannabis', 'Shop for Cannabis'),
      href: ROUTES.HOME,
    },
    {
      label: t('common.dispensariesByCity', 'Dispensaries by City'),
      href: ROUTES.CITIES,
    },
  ];

  const SolutionsForDispensaryLinks1 = [
    {
      label: t('common.dispensaryHomepage', 'Home'),
      href: ROUTES.DISPENSARY_B2B,
    },
    {
      label: t('common.pos', 'Point of Sale'),
      href: ROUTES.DISPENSARY_POINT_OF_SALE,
    },
    {
      label: t('common.ecommerce', 'Ecommerce'),
      href: ROUTES.DISPENSARY_ECOMMERCE,
    },
    {
      label: t('common.payments', 'Payments'),
      href: ROUTES.DISPENSARY_PAYMENTS,
    },
    {
      label: t('common.integrations', 'Integrations'),
      href: ROUTES.DISPENSARY_INTEGRATIONS,
    },
  ];

  // TODO: add back at second wave of  rebrand
  // const SolutionsForDispensaryLinks2 = [
  //   {
  //     label: t('common.singleDispensary', 'Single Dispensary'),
  //     href: ROUTES.SINGLE_DISPENSARY,
  //   },
  //   {
  //     label: t('common.verticallyIntegrated', 'Vertically Integrated'),
  //     href: ROUTES.VERTICALLY_INTEGRAGED,
  //   },
  //   {
  //     label: t('common.multipleLocations', 'Multiple Locations'),
  //     href: ROUTES.MULTIPLE_LOCATIONS,
  //   },
  //   {
  //     label: t('common.multiStateOperator', 'Multi-State Operator'),
  //     href: ROUTES.MULTIPLE_STATE_OPERATOR,
  //   },
  // ];

  const FooterLinkList = () => (
    <FooterLinks>
      {_.map(LegalLinks, (entry) => (
        <Link href={entry.href} key={entry.label}>
          {/* adding in href as it is a warning in CircleCI */}
          <FooterLink href={entry.href}>{entry.label}</FooterLink>
        </Link>
      ))}
    </FooterLinks>
  );

  return (
    <Container className='footer-container'>
      <ListsHolder>
        <FooterListContainer gridArea='company'>
          <FooterList title={t('common.company', 'Company')}>
            {_.map(CompanyLinks, (entry) => (
              <Link key={entry.href} href={entry.href}>
                {/* adding in href as it is a warning in CircleCI */}
                <a href={entry.href}>{entry.label}</a>
              </Link>
            ))}
          </FooterList>
        </FooterListContainer>

        <SolutionsContainer gridArea='solutions'>
          <Heading>{t('footer.solutions', 'Solutions for Dispensaries')}</Heading>
          <Grid container columns={1}>
            <NoHeaderFooterList item xs={8}>
              {_.map(SolutionsForDispensaryLinks1, ({ label, href }) => (
                <Link href={href} key={label}>
                  {label}
                </Link>
              ))}
            </NoHeaderFooterList>

            {/* TODO: add back at second wave of  rebrand */}
            {/* <NoHeaderFooterList item xs={8}>
              {_.map(SolutionsForDispensaryLinks2, ({ key, label }) => (
                <Link href={`${ROUTES.CITY}/${key}`} key={key}>
                  {label}
                </Link>
              ))}
            </NoHeaderFooterList> */}
          </Grid>
        </SolutionsContainer>
        <FooterListContainer gridArea='resources'>
          <FooterList title={t('common.resources', 'Resources')}>
            {_.map(ResourceLinks, (entry) => (
              <Link key={entry.href} href={entry.href}>
                {/* adding in href as it is a warning in CircleCI */}
                <a href={entry.href}>{entry.label}</a>
              </Link>
            ))}
          </FooterList>
        </FooterListContainer>

        <FollowUsContainer>
          <FlexWrapper>
            <SocialLinks>
              <a
                href='https://www.instagram.com/wearedutchie'
                target='_blank'
                rel='noopener noreferrer'
                aria-label={t('footer.linkToInstagram', "link to dutchie's Instagram")}
              >
                <InstagramIcon fill='#e3e6e7' />
                <VisuallyHidden>{t('footer.linkToInstagram', "link to dutchie's Instagram")}</VisuallyHidden>
              </a>
              <a
                href='https://www.facebook.com/getdutchie/'
                target='_blank'
                rel='noopener noreferrer'
                aria-label={t('footer.linkToFacebook', "link to dutchie's Facebook")}
              >
                <FacebookIcon fill='#e3e6e7' />
                <VisuallyHidden>{t('footer.linkToFacebook', "link to dutchie's Facebook")}</VisuallyHidden>
              </a>
              <a
                href='https://twitter.com/getdutchie'
                target='_blank'
                rel='noopener noreferrer'
                aria-label={t('footer.linkToTwitter', "link to dutchie's Twitter")}
              >
                <TwitterIcon fill='#e3e6e7' />
                <VisuallyHidden>{t('footer.linkToTwitter', "link to dutchie's Twitter")}</VisuallyHidden>
              </a>
            </SocialLinks>
          </FlexWrapper>
        </FollowUsContainer>
      </ListsHolder>
      <FooterDivider {...props} />
      <FinalTagline>
        <Copyright>
          <CopyrightSymbol>Ⓒ</CopyrightSymbol>{' '}
          {`dutchie ${new Date().getFullYear()}. ${t('common.allRightsReserved', 'All Rights Reserved')}`}
        </Copyright>
        <FooterLinkList />
      </FinalTagline>
    </Container>
  );
}

const SolutionsContainer = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
  padding-top: 2px;
  margin-right: 38px;
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin-top: 24px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 55px 84px 31px 82px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding: 40px 31px 32px;
  }

  a {
    white-space: nowrap;
  }
`;

const ListsHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  grid-template-areas: 'company solutions resources follow';

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'company solutions resources'
      'follow . .';
  }

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    grid-template-columns: 1fr 1fr;

    grid-template-areas:
      'company resources '
      'solutions solutions'
      'follow . ';
  }
`;

const FooterListContainer = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
`;

const FlexWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    flex-direction: column;
  }
`;

const FollowUsContainer = styled.div`
  grid-area: follow;
  justify-self: end;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    justify-self: start;
    margin-top: 55px;
  }
`;

const SocialLinks = styled.div`
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin-top: 23px;
  }
  & > a:not(:first-of-type) {
    margin-left: 21px;
  }
`;

const FinalTagline = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  color: #e3e6e7;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-top: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'links'
      'copyright';
  }
`;

const FooterLink = styled.a`
  &:not(:first-child),
  &:not(:nth-of-type(4)) {
    margin-left: 40px;
  }

  &:nth-of-type(3):after,
  &:first-child:before {
    content: '';
    margin: 0;
  }

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-bottom: 8px;

    &:not(:first-child),
    &:not(:nth-of-type(4)) {
      margin-left: 0;
    }
  }
`;

const FooterLinks = styled.div`
  display: flex;
  grid-area: links;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Copyright = styled.span`
  grid-area: 'copyright';
`;

const CopyrightSymbol = styled.span``;

const Heading = styled.p`
  font-weight: bold;
  line-height: 19px;
  max-width: 261px;
  white-space: nowrap;

  color: #70a0ca;
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    font-size: 13px;
    line-height: 16px;
  }
  &&& {
    font-size: 16px;
  }
`;

const NoHeaderFooterList = styled(FooterList)`
  margin-top: 35px !important;
  color: ${({ theme }) => theme.colors.grey[30]};
`;
