import { NextRouter } from 'next/router';
import { getPageType, PageTypes } from './get-page-type';

// In order to track GA4 events on Sponsored Ads in category pages.
// Return data i.g. CategoryPage > Edible > Page 1

type CreateCreativeSlotArgs = {
  router: NextRouter;
  category?: string | null;
};

function pageFormat(pageNumber: string): string {
  return `Page ${pageNumber}`;
}

export function createCreativeSlot({ router, category = `N/A` }: CreateCreativeSlotArgs): string {
  const { page } = router.query;
  const pageType = getPageType(router) ?? 'N/A';
  const pageNumber = page?.toString() ?? '1';

  if (pageType === PageTypes.categoryPage) {
    return [pageType, category ?? `N/A`, pageFormat(pageNumber)].join(' > ');
  }

  return pageType;
}
