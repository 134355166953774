import { useEffect, useState } from 'react';
import useIsInViewport from 'use-is-in-viewport';

import { isPrerenderIoBot, isSearchEngineBot } from 'src/utils/bots';

export default function useViewportVisibility(threshold = 20) {
  const [isVisible, ref] = useIsInViewport({ threshold });
  const [hasBeenVisible, setHasBeenVisible] = useState(false);

  useEffect(() => {
    // Disable lazy loading for prerender.io bot and search engine bots
    if (isPrerenderIoBot() || isSearchEngineBot() || (isVisible && !hasBeenVisible)) {
      setHasBeenVisible(true);
    }
  }, [hasBeenVisible, isVisible]);

  return { hasBeenVisible, isVisible, ref };
}
