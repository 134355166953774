import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import useSearchPreferences from 'hooks/use-search-preferences';
import useUI from 'hooks/use-ui';
import useCart from 'hooks/use-cart';
import { useResettableState } from 'hooks/use-resettable-state';

import { UserLocation } from 'src/types';
import { OrderTypes } from 'state/search-preferences';
import {
  UseAddressFieldFocused,
  UseDistanceReturn,
  UseOrderTypeReturn,
  UseTempLocationReturn,
  UseLocationSettingsReturn,
} from './location-settings-popover.types';

export function useLocationSettings({ onSave }): UseLocationSettingsReturn {
  const { formattedAddress: currentAddressString, address: cartAddress } = useCart();
  const { toggleLocationSettingsDropdown, showLocationSettingsDropdown: open } = useUI();

  const { distance, resetDistance, onChangeDistance } = useDistance();
  const { orderType, resetOrderType, onChangeOrderType } = useOrderType();
  const { tempLocation, resetTempLocation, onChangeTempLocation } = useTempLocation();
  const { addressFieldFocused, resetAddressFieldFocused } = useAddressFieldFocused();

  const onClose = (): void => {
    toggleLocationSettingsDropdown();
    resetDistance();
    resetOrderType();
    resetTempLocation();
    resetAddressFieldFocused();
  };

  const handleSave = (): void => onSave({ address: tempLocation ?? cartAddress, orderType, distance });

  useCloseOnRouteChange();

  return {
    open,
    currentAddressString,
    addressFieldFocused,
    distance,
    orderType,
    onClose,
    onSave: handleSave,
    onChangeOrderType,
    onChangeTempLocation,
    onChangeDistance,
  };
}

export function useOrderType(): UseOrderTypeReturn {
  const { orderType: searchPreferencesOrderType } = useSearchPreferences();
  const [orderType, setOrderType, resetOrderType] = useResettableState<OrderTypes>(searchPreferencesOrderType);
  const onChangeOrderType = (_e: React.ChangeEvent, value: OrderTypes): void => setOrderType(value);
  return { orderType, resetOrderType, onChangeOrderType };
}

export function useDistance(): UseDistanceReturn {
  const { distance: searchPreferencesDistance } = useSearchPreferences();
  const [distance, setDistance, resetDistance] = useResettableState<number>(searchPreferencesDistance);
  const onChangeDistance = (_e: React.ChangeEvent, newDistance: number): void => setDistance(newDistance);
  return { distance, resetDistance, onChangeDistance };
}

export function useTempLocation(): UseTempLocationReturn {
  const [tempLocation, setTempLocation, resetTempLocation] = useResettableState<UserLocation | undefined>(undefined);
  const onChangeTempLocation = (location: UserLocation): void => setTempLocation(location);
  return { tempLocation, resetTempLocation, onChangeTempLocation };
}

export function useAddressFieldFocused(): UseAddressFieldFocused {
  const [addressFieldFocused, setAddressFieldFocused, resetAddressFieldFocused] = useResettableState<boolean>(false);
  const onChangeAddressFieldFocused = (value: boolean): void => setAddressFieldFocused(value);
  return { addressFieldFocused, resetAddressFieldFocused, onChangeAddressFieldFocused };
}

export function useCloseOnRouteChange(): void {
  const Router = useRouter();
  const UI = useUI();
  useEffect(() => {
    const handleRouteChange = (): void => {
      UI.showLocationSettingsDropdown = false;
    };
    Router.events.on(`routeChangeStart`, handleRouteChange);
    return () => {
      Router.events.off(`routeChangeStart`, handleRouteChange);
    };
  }, [Router.events, UI]);
}
