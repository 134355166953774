import React, { forwardRef } from 'react';

import useTranslation from 'hooks/use-translation';

import { ModalContainer, ModalExitIcon } from 'components/core';
import ChangePasswordForm from 'components/forms/change-password-form';
import { ModalContent, HeaderText } from 'components/modals/components';
import { ModalObserverRenderProps } from 'components/modals/modal-observer';

export const ChangePasswordModal = forwardRef<HTMLElement, ModalObserverRenderProps>(
  ({ containerProps, onClose }, ref) => {
    const { t } = useTranslation();

    return (
      <ModalContainer ref={ref} width={420} padding={0} {...containerProps}>
        <ModalExitIcon onClick={onClose} />
        <ModalContent pt={24} pb={37}>
          <HeaderText mb={24}>{t('changePasswordModal.header', 'Change your password')}</HeaderText>

          <ChangePasswordForm onClose={onClose} />
        </ModalContent>
      </ModalContainer>
    );
  }
);
