import React from 'react';
import { MENU_TYPE_REC, MENU_TYPE_MED } from 'shared/constants';
import EmptyGoodsIcon from 'assets/empty-goods-icon';
import { useRouter } from 'next/router';

import useTranslation from 'hooks/use-translation';
import useProductQueryParams from 'hooks/use-product-query-params';
import usePaths from 'hooks/use-paths';
import useCart from 'hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useSwitchMenuType from 'hooks/use-switch-menu-type';

import { EmptyStateContainer, StyledHeader, StyledTextButton } from './empty-state-shared-styles';

const SpecialsPageEmptyState = ({ productFiltersApplied, onCurrentSpecialPage, showExpiredSpecial = false }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { href } = usePaths();
  const specialId = router.query?.specialId;
  const { menuType } = useCart();
  const { resetQueryParams } = useProductQueryParams();
  const switchMenuType = useSwitchMenuType();
  const { dispensary } = useDispensary();

  let headerCopy;
  let buttonCopy;
  const currentMenuType = menuType === MENU_TYPE_MED ? `medical` : `recreational`;
  if (productFiltersApplied) {
    headerCopy = t('specialPageEmptyState.header', `There are no products on special that match your filters.`);
    buttonCopy = t(`categoryPageEmptyState.clearFilters`, `Clear filters`);
  } else if (!specialId) {
    headerCopy = t(
      'specialPageEmptyState.header',
      `Sorry, but there aren't any {{currentMenuType}} specials right now.`,
      { currentMenuType }
    );
    buttonCopy = t(`specialPageEmptyState.otherMenuTypeSpecials`, `Back to available {{otherMenuType}} specials`, {
      otherMenuType: menuType === MENU_TYPE_MED ? `recreational` : `medical`,
    });
  } else if (onCurrentSpecialPage) {
    headerCopy = t(
      'specialPageEmptyState.header',
      `Sorry, but there are no products currently in stock for this special. Check back soon!`
    );
    buttonCopy = t(`categoryPageEmptyState.backToSpecials`, `See available {{currentMenuType}} specials`, {
      currentMenuType,
    });
  } else {
    headerCopy = t('specialPageEmptyState.header', `Sorry, this special isn't available for {{currentMenuType}}.`, {
      currentMenuType,
    });
    buttonCopy = t(`categoryPageEmptyState.backToSpecials`, `See available {{currentMenuType}} specials`, {
      currentMenuType,
    });
  }

  if (showExpiredSpecial) {
    if (dispensary.medicalDispensary && dispensary.recDispensary) {
      headerCopy = t(
        'specialPageEmptyState.header',
        `Sorry, this special is no longer available for {{currentMenuType}}.`,
        {
          currentMenuType,
        }
      );
      buttonCopy = t('categoryPageEmptyState.backToSpecials', `See available {{currentMenuType}} specials`, {
        currentMenuType,
      });
    } else {
      headerCopy = t('specialPageEmptyState.header', `Sorry, this special is no longer available.`);
      buttonCopy = t('categoryPageEmptyState.backToSpecials', `See all available specials`);
    }
  }

  const handleClick = () => {
    if (productFiltersApplied) {
      return resetQueryParams();
    }
    if (specialId) {
      return router.push(`${href}/specials`);
    }
    return switchMenuType(menuType === MENU_TYPE_MED ? MENU_TYPE_REC : MENU_TYPE_MED);
  };

  return (
    <EmptyStateContainer showExpiredSpecial={showExpiredSpecial}>
      <EmptyGoodsIcon />
      <StyledHeader>{headerCopy}</StyledHeader>
      <StyledTextButton onClick={handleClick}>{buttonCopy}</StyledTextButton>
    </EmptyStateContainer>
  );
};

export default SpecialsPageEmptyState;
