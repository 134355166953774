/* eslint-disable max-len */
import React from 'react';

const SvgClosedButSign = (props) => (
  <svg width='97' height='99' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <mask id='a' maskUnits='userSpaceOnUse' x='0' y='0' width='97' height='99'>
      <path d='M0 .033h97v98.914H0V.033z' fill='#fff' />
    </mask>
    <g mask='url(#a)'>
      <path
        d='M5.272 87.91V47.65c.005-3.157 2.542-5.715 5.672-5.721h75.07c3.139-.018 5.696 2.534 5.714 5.7v40.28c-.006 3.166-2.554 5.727-5.693 5.721H10.986c-3.138.017-5.696-2.534-5.714-5.7v-.02zm38.072-69.333a9.755 9.755 0 0010.312 0L69.85 36.612H27.107l16.237-18.035zM48.5 5.37c2.562.005 4.635 2.106 4.629 4.69-.006 2.584-2.088 4.674-4.65 4.668-2.558-.007-4.629-2.1-4.629-4.679.007-2.586 2.086-4.679 4.65-4.679zM97 87.91V47.65c-.006-6.102-4.915-11.044-10.965-11.038H76.967L57.272 14.707c2.554-4.89.695-10.941-4.152-13.518-4.848-2.577-10.848-.7-13.403 4.189a10.08 10.08 0 000 9.329L20.191 36.378c-.06.075-.117.153-.169.234h-9.036C4.936 36.594.018 41.527 0 47.629v40.28c.005 6.102 4.915 11.044 10.965 11.038h75.049c6.05.017 10.968-4.915 10.986-11.017v-.02z'
        fill='#E98F3B'
      />
    </g>
    <path
      d='M13.227 69.882c-.193-2.15-.012-4.202.544-6.158.555-1.958 1.362-3.632 2.425-5.026a13.402 13.402 0 013.78-3.408c1.457-.876 2.99-1.386 4.601-1.528 1.61-.142 2.932.168 3.972.932 1.037.764 1.627 1.936 1.768 3.517.141 1.58-.346 3.027-1.458 4.338-1.115 1.313-2.617 2.237-4.505 2.773a7.409 7.409 0 01-.677.125c-.8.07-1.227-.206-1.282-.83-.021-.236.073-.46.282-.675.209-.214.704-.463 1.486-.748 1.2-.454 2.14-1.079 2.82-1.876.68-.797.979-1.674.893-2.63-.085-.958-.423-1.626-1.013-2.008-.59-.382-1.37-.529-2.343-.442-2.355.209-4.393 1.607-6.11 4.198-1.718 2.59-2.43 5.518-2.138 8.787.22 2.451.885 4.293 1.998 5.528 1.11 1.235 2.608 1.768 4.488 1.602 1.88-.166 3.538-.807 4.974-1.92a12.449 12.449 0 003.438-4.124c.072-.157.233-.248.482-.27.249-.022.511.052.788.223.277.17.422.331.436.482.013.15.002.27-.033.36-.77 2.127-2.125 3.898-4.067 5.318-1.943 1.417-4.087 2.23-6.43 2.439-2.346.206-4.38-.437-6.103-1.932-1.725-1.494-2.73-3.842-3.016-7.047zM39.491 56.47c-.974.082-1.845 1.318-2.61 3.713-.764 2.395-1.22 4.643-1.37 6.746 1.232-1.54 2.334-3.264 3.31-5.175.975-1.911 1.416-3.472 1.324-4.684-.042-.432-.258-.632-.654-.6zm-2.458 19.057c-1.161.097-2.095-.267-2.805-1.09-.71-.824-1.138-1.991-1.283-3.504a11.481 11.481 0 01-.063-1.04c.001-3.16.571-6.496 1.709-10.01.563-1.681 1.33-3.1 2.293-4.258.966-1.16 2.04-1.787 3.224-1.885.695-.058 1.273.107 1.73.493.457.387.718.894.777 1.52l.025.26c-.025 2.203-.757 4.562-2.2 7.076-1.444 2.517-3.105 4.592-4.983 6.23-.027.7-.022 1.234.014 1.6.167 1.75.807 2.58 1.92 2.487 1.16-.095 2.375-.96 3.646-2.589a.845.845 0 01.589-.277c.242-.02.482.053.721.218.238.164.37.383.396.653.026.27-.087.557-.339.86-1.585 2.006-3.376 3.091-5.371 3.256z'
      fill='#E98F3B'
    />
    <path
      d='M43.665 73.278c.538-.053 1.015-.435 1.428-1.144.41-.711.622-1.401.63-2.072-.795-.29-1.216-.723-1.262-1.296-.046-.572.177-1.17.669-1.799-.045-.294-.129-.506-.254-.632-.125-.127-.291-.179-.499-.159-.786.078-1.418.655-1.895 1.729-.476 1.075-.671 2.161-.583 3.262.122 1.511.71 2.216 1.766 2.111zm-.288 2.246c-1.035.102-1.916-.228-2.647-.986-.73-.76-1.142-1.757-1.242-2.996-.152-1.878.269-3.6 1.26-5.163.99-1.564 2.23-2.42 3.722-2.567.869-.086 1.626.186 2.273.816.645.629 1.009 1.47 1.093 2.524.02.23.007.6-.036 1.112 1.326-.407 2.584-1.501 3.775-3.281a.43.43 0 01.317-.17c.133-.014.281.082.444.285.162.203.252.414.27.632.017.218-.011.377-.087.476-1.235 2.04-2.851 3.468-4.852 4.289-.438 3.114-1.868 4.79-4.29 5.03z'
      fill='#E98F3B'
    />
    <path
      d='M54.243 71.952l.132-.013c1.089-.101 1.59-.665 1.504-1.69-.088-1.028-.723-2.3-1.908-3.822l-1.766 4.784c.815.543 1.493.791 2.038.74zm.985 1.871c-1.352.128-2.558-.042-3.618-.507-1.06-.464-1.824-1.1-2.288-1.906.811-1.822 1.866-4.517 3.167-8.085.288-.74.76-1.14 1.415-1.2.326-.031.614.051.86.247.247.195.384.464.414.805.027.32-.015.6-.126.84s-.159.475-.139.702c.02.23.296.627.828 1.198a24.32 24.32 0 011.597 1.92c.531.709.821 1.342.869 1.902.047.559-.031 1.053-.236 1.486.972-.435 1.963-1.447 2.971-3.035a.64.64 0 01.456-.248c.185-.018.406.116.66.403.255.287.392.538.411.756.018.216-.01.42-.08.61-.544 1.084-1.423 2-2.634 2.745-1.21.744-2.72 1.2-4.527 1.367z'
      fill='#E98F3B'
    />
    <path
      d='M64.447 64.381c-.728.067-1.393.563-1.991 1.49-.598.925-.855 1.886-.769 2.876 2.719-1.247 4.032-2.413 3.938-3.499-.056-.644-.448-.934-1.178-.867zm-5.23 5.22c-.15-1.706.259-3.341 1.22-4.906.962-1.563 2.217-2.416 3.765-2.557.92-.084 1.706.112 2.357.587.653.473 1.018 1.161 1.095 2.06.112 1.269-.306 2.34-1.248 3.217-.94.877-2.415 1.627-4.419 2.253.4.963 1.252 1.384 2.552 1.265 1.795-.164 3.423-1.103 4.88-2.817.082-.1.218-.159.409-.177.19-.017.377.047.56.193a.79.79 0 01.303.564c.02.232-.04.47-.177.715-.49.857-1.313 1.684-2.464 2.474-1.154.791-2.387 1.246-3.7 1.366-1.312.121-2.464-.176-3.452-.887-.989-.711-1.55-1.827-1.682-3.35z'
      fill='#E98F3B'
    />
    <path
      d='M79.693 51.584c-.696.067-1.203.735-1.522 2.002a24.992 24.992 0 00-.524 2.338c-.167.921-.332 3.247-.49 6.975.071-.145.142-.313.212-.502.038-.05.257-.489.656-1.319.397-.828.747-1.601 1.05-2.317.3-.717.614-1.676.94-2.877.326-1.199.453-2.226.382-3.079-.07-.853-.304-1.26-.704-1.22zm-6.78 18.152c.59-.057 1.045-.404 1.364-1.042.319-.637.516-1.763.597-3.376l.164-2.629c-1.115.107-2 .697-2.654 1.769-.654 1.073-.931 2.221-.83 3.449l.03.375c.151 1.04.593 1.525 1.33 1.454zm-3.831-1.317c-.152-1.864.254-3.617 1.222-5.257.969-1.64 2.158-2.529 3.569-2.664.356-.034.73-.002 1.12.099.347-7.643 1.993-11.607 4.94-11.89a2.09 2.09 0 011.65.529c.47.414.75.924.843 1.535a6.7 6.7 0 01.09.713c.165 2.002-.202 4.11-1.101 6.329a63.339 63.339 0 01-1.184 2.83c-.296.648-.82 1.684-1.573 3.108a284.564 284.564 0 00-1.293 2.462l.025.307c.16 1.957.726 2.887 1.694 2.794 1.095-.105 2.162-1.044 3.204-2.817.096-.125.26-.197.492-.22.23-.022.461.059.688.244a.956.956 0 01.375.685c.022.273-.04.543-.188.81-1.287 2.14-2.837 3.298-4.646 3.471-1.412.136-2.507-.549-3.288-2.055-.477 1.673-1.516 2.587-3.115 2.74-.906.086-1.695-.215-2.366-.907-.672-.692-1.058-1.64-1.158-2.846z'
      fill='#E98F3B'
    />
    <g fill='#E98F3B'>
      <path d='M58.633 87v-8.004h1.523v2.953c.45-.593 1.045-.89 1.787-.89.754 0 1.372.275 1.852.826.48.547.72 1.283.72 2.209 0 .945-.24 1.691-.72 2.238-.48.543-1.098.815-1.852.815-.718 0-1.314-.297-1.787-.891V87h-1.523zm1.523-1.857c.137.183.328.338.575.463.25.12.497.181.744.181.441 0 .794-.156 1.06-.469.27-.312.405-.72.405-1.224 0-.496-.135-.899-.405-1.207-.265-.313-.619-.469-1.06-.469-.247 0-.495.064-.745.193a1.552 1.552 0 00-.574.475v2.057zM65.664 85.318v-4.113h1.523v3.516c0 .71.356 1.066 1.067 1.066.535 0 .977-.219 1.324-.656v-3.926h1.524V87h-1.524v-.732c-.535.585-1.215.879-2.039.879-.625 0-1.094-.16-1.406-.481-.313-.32-.469-.77-.469-1.348zM71.963 82.535v-1.33h.96v-1.588h1.524v1.588h1.178v1.33h-1.178v2.608c0 .191.045.347.135.468a.475.475 0 00.393.176c.246 0 .423-.06.533-.181l.322 1.166c-.281.25-.705.374-1.271.374-1.09 0-1.635-.533-1.635-1.6v-3.01h-.961zM76.486 86.162c0-.266.094-.494.282-.685a.929.929 0 01.68-.288c.265 0 .493.096.685.288.191.191.287.42.287.685a.922.922 0 01-.287.674.945.945 0 01-.686.281.929.929 0 01-.68-.287.925.925 0 01-.28-.668zm3.076 0c0-.266.094-.494.282-.685a.91.91 0 01.674-.288c.265 0 .494.096.685.288.191.191.287.42.287.685a.922.922 0 01-.287.674.945.945 0 01-.685.281.91.91 0 01-.674-.287.925.925 0 01-.281-.668zm3.07 0c0-.266.095-.494.282-.685a.91.91 0 01.674-.288c.266 0 .494.096.685.288.196.191.293.42.293.685a.913.913 0 01-.293.674.945.945 0 01-.685.281.91.91 0 01-.674-.287.925.925 0 01-.281-.668z' />
    </g>
  </svg>
);

export default SvgClosedButSign;
