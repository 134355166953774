import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import usePaths from 'src/hooks/use-paths';
import useUI from 'src/hooks/use-ui';

import { Button } from 'components/core';
import { PaySignUpButton } from 'src/payments/components/pay-sign-up-button/pay-sign-up-button';
import { InterfaceVariants } from 'src/constants/interface-variants';
import { Footer, Subtotal, FooterSubtotal, Taxes } from '../cart-dropdown.styles';

type DutchiePayCheckoutProps = {
  isEmbedded: boolean;
  isMobile: boolean;
  handleGoToCheckout: () => void;
  subtotal: number;
  taxInclusionNote: string;
  isEnrolledDutchiePay: boolean;
};

export function DutchiePayCheckout({
  isEmbedded,
  isMobile,
  handleGoToCheckout,
  subtotal,
  taxInclusionNote,
  isEnrolledDutchiePay,
}: DutchiePayCheckoutProps): JSX.Element {
  const { t } = useTranslation();
  const { href: checkoutHref } = usePaths({ checkout: true });
  const { variant } = useUI();

  const isKiosk = variant === InterfaceVariants.kiosk;

  return (
    <Container isMobile={isMobile}>
      {!isMobile && (
        <TotalRow>
          <Subtotal data-testid='cart-dropdown-subtotal'>
            {t('common.subtotal', 'Subtotal')}: <strong>${subtotal.toFixed(2)}</strong>
          </Subtotal>
          <Taxes isEmbedded={isEmbedded}>{taxInclusionNote}</Taxes>
        </TotalRow>
      )}
      <ButtonContainer isMobile={isMobile}>
        {isMobile ? (
          <ProcessToCheckOutButtonMobile
            variant='primary'
            size='large'
            onClick={() => handleGoToCheckout()}
            data-testid='cart-checkout-button'
            isEmbedded={isEmbedded}
          >
            {t('common.proceedToCheckout', `Proceed to checkout - $${subtotal.toFixed(2)}`)}
          </ProcessToCheckOutButtonMobile>
        ) : (
          <ProcessToCheckOutButton
            variant='primary'
            size='large'
            onClick={() => handleGoToCheckout()}
            data-testid='cart-checkout-button'
            isEmbedded={isEmbedded}
          >
            {t('common.proceedToCheckout', 'Proceed to checkout')}
          </ProcessToCheckOutButton>
        )}
        {!isKiosk && (
          <DutchiePayButtonContainer>
            {isEnrolledDutchiePay ? (
              <PaySignUpButton goToCheckout={handleGoToCheckout} location='cart-rail' />
            ) : (
              <PaySignUpButton
                refHref={checkoutHref}
                enrollmentSource={`cart-rail-${String(variant)}`}
                location='cart-rail'
              />
            )}
          </DutchiePayButtonContainer>
        )}
      </ButtonContainer>
    </Container>
  );
}

const Container = styled(Footer)`
  height: ${({ isMobile }) => (isMobile ? `154px` : `268px`)};
  flex: 1;
  flex-direction: column;
  padding: 0;

  @media (min-width: 600px) {
    padding: 0;
  }
`;

const TotalRow = styled(FooterSubtotal)`
  width: 100%;
  padding: 14px;
  margin-bottom: 16px;
  background: #f3f6f8;
`;

const ButtonContainer = styled.div`
  width: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ isMobile }) => (isMobile ? `16px` : `34px`)};
`;

const DutchiePayButtonContainer = styled.div`
  margin-top: 20px;
`;

const ProcessToCheckOutButton = styled(Button)`
  border: 2px solid ${({ theme }) => theme.customized.colors.buttonsLinks};
  background: inherit;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  line-height: 15px;
`;

const ProcessToCheckOutButtonMobile = styled(ProcessToCheckOutButton)`
  margin-top: 16px;
  height: 43px;
  padding: 10px 22px;
  font-size: 13px;
`;
