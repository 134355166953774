import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

type LoaderProps = {
  size?: number;
  borderColor?: string;
};

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoaderWrapper = styled.div<{ size: number; borderColor: string }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: 2px solid ${({ borderColor }) => borderColor};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotate} 1s linear infinite;
`;

const LoadingSpinner: FC<LoaderProps> = ({ size = 48, borderColor = `#FFF` }) => (
  <LoaderWrapper size={size} borderColor={borderColor} />
);
export default LoadingSpinner;
