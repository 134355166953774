import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useFormContext, Controller } from 'react-hook-form';

import ChevronIcon from 'assets/chevron-icon';
import { getErrors } from './form-input-utils';

const StyledChevronIcon = styled(ChevronIcon).attrs({
  height: `7px`,
  width: `12px`,
})`
  margin-top: 9px;
  margin-right: 8px;
  fill: ${({ theme }) => theme.colors.grey[45]};
`;

const FormSelect = ({ defaultValue, disableEmptyOption = false, name, label, children, ...props }) => {
  const { errors, register, setValue, getValues, clearErrors } = useFormContext();
  const { hasError, errorLabel } = getErrors({ name, errors });

  useEffect(() => {
    // Mui Select doesn't play well with React Form Hooks
    // It's not a native component and doesn't use refs
    // so registering has to be manual
    register({ name });
  }, [register, name]);

  const value = getValues(name);

  const handleOnChange = (e) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  return (
    <StyledSelectWithLabel {...props}>
      <FormControl variant='filled' fullWidth classes={{ root: `mui-dt-root` }}>
        <InputLabel
          classes={{
            root: `mui-dt-label-root`,
            focused: `mui-dt-label-focused`,
            error: `mui-dt-label-error`,
            shrink: `mui-dt-label-shrink`,
          }}
          className={hasError ? 'mui-dt-label-error' : ''}
        >
          {hasError && errorLabel ? errorLabel : label}
        </InputLabel>
        <Controller
          as={
            <Select
              native
              error={hasError}
              value={value}
              onChange={handleOnChange}
              input={
                <FilledInput
                  name={name}
                  classes={{
                    root: `mui-dt-input-root`,
                    input: `mui-dt-input-input`,
                    error: `mui-dt-input-error`,
                    underline: `mui-dt-input-underline`,
                    inputAdornedEnd: `mui-dt-input-icon`,
                  }}
                />
              }
              IconComponent={StyledChevronIcon}
              {...props}
            >
              <option value='' disabled={disableEmptyOption} />
              {children}
            </Select>
          }
          name={name}
          defaultValue={defaultValue}
        />
      </FormControl>
    </StyledSelectWithLabel>
  );
};

export default FormSelect;

const labelStyles = css`
  .mui-dt-label-root {
    margin-left: 14px;
    font-size: 13px;
    color: ${({ error, theme }) => error && theme.colors.red[60]};
    top: 2px !important;

    &.mui-dt-label-shrink {
      transform: translate(14px, 10px);
      font-size: 10px;
    }

    &.mui-dt-label-error {
      color: ${({ theme }) => `${theme.colors.red[60]} !important`};
    }

    &.mui-dt-label-focused {
      color: ${({ error, theme }) => (error ? theme.colors.red[60] : `${theme.colors.grey[35]} !important`)};
      font-size: 10px;
    }
  }
`;

// TODO: fix hover/focus styles
const StyledSelectWithLabel = styled.div`
  ${labelStyles}

  .mui-dt-input-root {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primaryGrey};
    border: ${({ theme }) => `1px solid ${theme.colors.grey[80]}`};
    color: ${({ theme }) => theme.colors.grey[30]} !important;
    height: 55px;
    border-radius: 6px !important;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryGrey};
    }

    &:focus {
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.blue[55]};
      box-shadow: ${({ theme }) => `0px 1px 5px ${theme.colors.blueShadow}`};
    }

    &.mui-dt-input-error {
      border: 1px solid ${({ theme }) => theme.colors.red[60]};
    }

    input:-webkit-autofill {
      border-color: ${({ theme }) => theme.colors.blue[55]};
    }
  }

  .mui-dt-input-input {
    font-size: 16px;
    font-weight: bold;
    padding: 22px 5px 6px 26px;
    height: 24px;
    border-radius: 6px !important;

    &:focus {
      background-color: transparent;
    }
  }

  .mui-dt-input-underline:before {
    display: none;
  }

  .mui-dt-input-underline:after {
    transform: scale(0) !important; // remove underline highlight
  }
`;
