import React from 'react';
import { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { StyledPlacesAutocompleteInput } from './components';
// import PinIcon from 'assets/pin-icon';

const FormPlacesAutoCompleteInput = ({ name, type, label: labelFromProps, variant, ...props }) => {
  const { register } = useFormContext();

  return (
    <StyledPlacesAutocompleteInput
      name={name}
      type={type}
      label={labelFromProps}
      inputRef={register}
      variant={variant}
      // eslint-disable-next-line no-use-before-define
      labelstyles={labelstyles}
      currentLocationStyles={{ mt: 12 }}
      // There's currently a bug with label shrink + adornment - https://github.com/mui-org/material-ui/pull/14126
      // startAdornment={<PinIcon fill={colors.grey[70]} height='22px' />}
      {...props}
    />
  );
};

export default FormPlacesAutoCompleteInput;

const labelstyles = css`
  &.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(12px, 10px) scale(0.75);
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    &&& {
      &.MuiFormLabel-root {
        width: 100%;
        text-align: center;
        margin-left: 0;
        padding-right: 28px;
      }

      &.MuiFormLabel-root.Mui-focused {
        margin-left: 14px;
        text-align: left !important;
      }

      &.MuiFormLabel-root.MuiFormLabel-filled:not(.Mui-focused) {
        margin-left: 40px;
      }
    }
  }
`;
