import React, { ChangeEvent, createRef, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useDebouncedCallback } from 'use-debounce';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import usePaths from 'src/hooks/use-paths';
import { useHeader } from 'src/dispensary/core-menu/components/header/use-header';

import { tracker } from 'src/utils/analytics';
import { menuInteraction } from 'src/utils/ecommerce-analytics';

type UseSearchReturn = {
  isSearchDisabled: boolean;
  isSearchOpen: boolean;
  handleOpenSearch: () => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: () => void;
  searchTerm: string;
  searchInputRef: React.RefObject<HTMLInputElement>;
  dispensaryName: string;
};

export const useSearch = (): UseSearchReturn => {
  const router = useRouter();
  const { href } = usePaths({ search: true });
  const { dispensary } = useDispensary();
  const flags = useFlags();
  const isSearchDisabled = flags['ecomm.configuration.disable-product-search'] ?? false;
  const searchInputRef = createRef<HTMLInputElement>();
  const [searchTerm, setSearchTerm] = useState(router.query.search as string);
  const [isSearchOpen, setIsSearchOpen] = useState(!!searchTerm);
  const { trackEvent } = useHeader();

  const searchForTerm = (): void => {
    menuInteraction(dispensary);
    tracker.searchedForProduct({ query: searchTerm });
    void router.push({ pathname: href, query: searchTerm ? { search: searchTerm } : {} });
  };

  const [debounceSearchForTerm] = useDebouncedCallback(searchForTerm, 400);

  // TODO is scroll restoration needed?

  useEffect(() => {
    if (isSearchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchOpen, searchInputRef]);

  useEffect(() => {
    if (!isSearchDisabled && !router.query.search) {
      setSearchTerm('');
      setIsSearchOpen(false);
    }
  }, [router.query.search, isSearchDisabled]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    setSearchTerm(value);

    if (value.length < 3) {
      return;
    }

    debounceSearchForTerm();
  };

  const handleBlur = (): void => {
    if (searchTerm) {
      return;
    }

    setIsSearchOpen(false);
  };

  const handleOpenSearch = (): void => {
    setIsSearchOpen(true);
    trackEvent('open search');
  };

  return {
    isSearchDisabled,
    isSearchOpen,
    handleOpenSearch,
    handleChange,
    handleBlur,
    searchTerm,
    searchInputRef,
    dispensaryName: dispensary.name,
  };
};
