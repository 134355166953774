import _map from "lodash/map";
import _flatMap from "lodash/flatMap";
import _filter from "lodash/filter";
import numeral from 'numeral';
import { CannabinoidAbbrev } from 'shared/constants/cannabinoids';
import { NUMERALS_DOLLAR_FORMAT } from 'shared/constants';

function extractValidationErrors(graphQLErrors) {
  var errors = _filter(graphQLErrors, function (e) {
    return e.extensions.code === 'BAD_USER_INPUT' || e.extensions.code === 'UNPROCESSABLE_ENTITY';
  });

  return _flatMap(errors, function (error) {
    return _map(error.extensions.errors, 'detail');
  });
}

var formatPrice = function formatPrice(price) {
  return numeral(parseFloat(price).toFixed(2)).format(NUMERALS_DOLLAR_FORMAT);
};

var formatFromCents = function formatFromCents(priceInCents) {
  return formatPrice(priceInCents / 100);
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function getBufferFromBase64Image() {
  var image = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return Buffer.from(image.replace(/^data:image\/\w+;base64,/, ''), 'base64');
}

function isBrowser() {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
} // Check if an elem is visible on screen, and whether its above or below a certain offset


function isScrolledIntoView(elem) {
  var threshold = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var mode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'visible';
  var rect = elem.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  var above = rect.bottom - threshold < 0;
  var below = rect.top - viewHeight + threshold >= 0;
  return mode === 'above' ? above : mode === 'below' ? below : !above && !below;
}

export function getHtmlPlainText(html, Parser) {
  if (!Parser) {
    console.log('DOMParser not available in this context. Is this a browser?');
    return '';
  }

  try {
    var parserInst = new Parser();
    return parserInst.parseFromString(html, 'text/html').documentElement.textContent;
  } catch (e) {
    console.log('Error found with dom parser.', e);
    return '';
  }
} // normalizes noid abbrev, like THC-D9 -> D9-THC

var mapToStandardAbbreviation = function mapToStandardAbbreviation(abbrev) {
  return CannabinoidAbbrev[abbrev];
}; // Extracts noid abbrev from full text
// ex. '(CBDA) Cannablahblah' to 'CBDA' or '"TAC" - Total Noids' to 'TAC'


export var parseNoidAbbreviation = function parseNoidAbbreviation(noidName) {
  var _mapToStandardAbbrevi, _$exec;

  return (_mapToStandardAbbrevi = mapToStandardAbbreviation((_$exec = /[\w-]+/.exec(noidName)) === null || _$exec === void 0 ? void 0 : _$exec[0])) !== null && _mapToStandardAbbrevi !== void 0 ? _mapToStandardAbbrevi : noidName;
}; // Extracts noid long name from full text
// ex. '(CBDA) Cannablahblah' to 'Cannablahblah' or '"TAC" - Total Noids' to 'Total Noids'

export var parseNoidLongName = function parseNoidLongName(noidName) {
  var _$exec$, _$exec2;

  return (_$exec$ = (_$exec2 = /(?:\(|\W\s)+(.+\b)(?:\)|$)/.exec(noidName)) === null || _$exec2 === void 0 ? void 0 : _$exec2[1]) !== null && _$exec$ !== void 0 ? _$exec$ : noidName;
};
export default {
  extractValidationErrors: extractValidationErrors,
  formatFromCents: formatFromCents,
  formatPrice: formatPrice,
  getBufferFromBase64Image: getBufferFromBase64Image,
  getDisplayName: getDisplayName,
  isBrowser: isBrowser,
  isScrolledIntoView: isScrolledIntoView
};