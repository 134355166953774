import React from 'react';
import { useObserver } from 'mobx-react-lite';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useUI from 'hooks/use-ui';

import CategoryPageMobileLoadingState from './category-page-mobile-loading-state';
import CategoryPageListLoadingState from './category-page-list-loading-state';
import { ProductCardsGridLoadingState } from '../product-cards-grid-loading-state';

const CategoryPageLoadingState = (props) => {
  const UI = useUI();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`xs`));

  const menuLayout = useObserver(() => UI.menuLayout);
  const cardView = menuLayout === 'cards';
  if (isMobile) {
    return <CategoryPageMobileLoadingState {...props} />;
  }
  if (cardView) {
    return <ProductCardsGridLoadingState />;
  }
  return <CategoryPageListLoadingState {...props} />;
};

export default CategoryPageLoadingState;
