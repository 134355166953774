import { Category } from 'src/generics/data/generic-category';

export const OFTEN_PURCHASED_WITH_FILTERS: Record<Category, Category[]> = {
  [Category.flower]: [Category.preRolls],
  [Category.vaporizers]: [Category.flower],
  [Category.concentrate]: [Category.flower],
  [Category.preRolls]: [Category.flower],
  [Category.edible]: [Category.flower],
  [Category.topicals]: [Category.edible],
  [Category.tincture]: [Category.edible],
  [Category.accessories]: [Category.vaporizers],
  [Category.apparel]: [Category.flower],
  [Category.cbd]: [Category.flower],
  [Category.clones]: [Category.flower],
  [Category.nA]: [Category.flower],
  [Category.orals]: [Category.flower],
  [Category.seeds]: [Category.flower],
};

export const MIN_PRODUCTS = 4;
export const MAX_PRODUCTS = 15;
