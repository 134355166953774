import React from 'react';
import useTranslation from 'hooks/use-translation';

import { StoreIcon } from 'assets/marketing/rebrand';
import {
  Button,
  DialogContainer,
  DialogHeading,
  DialogIcon,
  DialogText,
  TextButton,
} from '../components/simple-dialog';

export type StoreSwitchedProps = {
  currentDispensaryName: string;
  onClearCart: () => void;
  onClose: () => void;
};

export const StoreSwitched = ({ currentDispensaryName, onClose, onClearCart }: StoreSwitchedProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DialogContainer>
      <DialogIcon>
        <StoreIcon />
      </DialogIcon>

      <DialogHeading fontSize={20}>{t('store-switched.heading', `Clear your cart to continue`)}</DialogHeading>

      <DialogText data-testid='clear-your-cart-to-continue-description'>
        {t(
          'store-switched.description',
          `You’re currently shopping with {{currentDispensaryName}}. To add a product from this dispensary, you must clear your cart. Do you want to continue?`,
          { currentDispensaryName }
        )}
      </DialogText>

      <Button size='large' onClick={onClearCart}>
        {t('clear-my-cart', `Clear My Cart`)}
      </Button>
      <TextButton onClick={onClose}>{t('cancel', `Cancel`)}</TextButton>
    </DialogContainer>
  );
};
