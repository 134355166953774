import React from 'react';
import styled from 'styled-components';

const OrderReceiptSectionHeader = ({ children }) => <Container>{children}</Container>;

const Container = styled.div`
  height: 45px;
  background-color: ${({ theme }) => theme.colors.primaryGrey};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.blueGrey[90]}`};
  border-top: ${({ theme }) => `1px solid ${theme.colors.blueGrey[90]}`};
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;

  ${({ theme }) => theme.breakpoints.up(`sm`)} {
    border: 8px 8px 0 0;
    height: 40px;
    border-top: none;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
`;

export default OrderReceiptSectionHeader;
