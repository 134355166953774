import styled from 'styled-components';

// NOTE: this gets used in a variety of contexts
// and so we want to be explicit about its role in each of those contexts
// keep this as a div, and assign a 'content-info' role to this component
// if it is being used as a footer by itself.
const Footer = styled.div`
  color: #e3e6e7;
  font-size: 13px;
  line-height: 22px;
  width: 100%;
  align-self: flex-end;
  background-color: ${({ color, theme }) => color ?? theme.colors.opal12};
`;
export default Footer;

export const FooterDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #446583;
  margin-top: 35px;
  margin-bottom: 25px;
`;

export const FooterHeader = styled.p`
  font-size: 16px !important;
  font-weight: bold;
  margin: 0 0 11px 0;
  color: #70a0ca;
`;
