import useCart from 'hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'hooks/use-user';
import useUI from 'hooks/use-ui';

import { requiresCustomerState } from 'shared/core/helpers/dispensaries';

export default function useCustomerState() {
  const Cart = useCart();
  const { dispensary } = useDispensary();
  const user = useUser();
  const UI = useUI();

  function getCustomerState() {
    const customerStateRequired = !UI.isKiosk && requiresCustomerState(dispensary);

    if (!Cart.isPickup && Cart.address?.state) {
      return Cart.address.state;
    }
    if (customerStateRequired && user?.profile?.address?.state) {
      return user.profile.address.state;
    }
    return dispensary?.location.state;
  }

  return getCustomerState();
}
