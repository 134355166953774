import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { fontSize, space, textAlign } from 'styled-system';

const Text = styled.button`
  // override default button styles
  appearance: none;
  background-color: transparent;
  border: none;

  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  cursor: pointer;
  display: block;
  font-size: 13px;
  text-transform: ${({ theme }) => theme.customized.textTransforms.links};

  ${fontSize}
  ${space}
  ${textAlign};
`;

type TextButtonProps = {
  className?: string;
  children: React.ReactNode;
  size?: string;
  variant?: string;
  onClick?: () => void;
};

const TextButton = forwardRef((props: TextButtonProps, ref) => {
  const { className, ...buttonProps } = props;
  return <Text ref={ref} className={className} {...buttonProps} />;
});

export default TextButton;
