import React from 'react';
import { ModalContainer } from 'shared/modals/v2-container';
import styled, { css } from 'styled-components';
import { ModalContent as _ModalContent } from 'components/modals/components';
import CreditCardIcon from 'assets/credit-card-icon';
import { Typography } from 'src/typography';
import useTranslation from 'hooks/use-translation';
import { ModalObserverRenderProps } from 'components/modals/modal-observer/modal-observer.types';

export const RethinkPayPaymentProcessingModal = ({ containerProps }: ModalObserverRenderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ModalContainer padding={0} width={420} {...containerProps}>
      <ModalContent>
        <IconContainer>
          <CreditCardIcon />
        </IconContainer>
        <Heading size='small'>{t('common.paymentInProgress', 'Payment in progress...')}</Heading>
        <StyledText size='medium'>
          {t(
            'common.navigateToPayment',
            'Navigate to the payment window to finalize your purchase. Once the payment is complete this window will update to reflect order status.'
          )}
        </StyledText>
      </ModalContent>
    </ModalContainer>
  );
};
const ModalContent = styled(_ModalContent)`
  ${({ theme: { spaces } }) => css`
    padding: ${spaces[14]} ${spaces[10]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${spaces[4]};
  `}
`;

const Heading = styled(Typography.Heading)`
  ${({ theme: { colors } }) => css`
    color: ${colors.dutchiePayBlack};
  `}
`;

const StyledText = styled(Typography.Body)`
  ${({ theme: { colors } }) => css`
    color: ${colors.grey[35]};
  `}
`;

const IconContainer = styled.div`
  position: relative;
`;
