/* eslint-disable dot-notation */
import { ApolloError } from 'apollo-client';

import useFeatureFlags from 'hooks/use-feature-flags';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { LoadFailureError } from 'src/errors';

enum PerformanceMode {
  normal = 'normal',
  disabled = 'disabled',
}

function useLoadPerformanceMode(): void {
  const { flags } = useFeatureFlags();
  const { error } = useDispensary();

  const performanceFlagValue = flags['ecomm.config.low-performance-mode']; // This LD should return, disabled as string.
  const shouldAutomaticallyDisable = flags['ecomm.rollout.automatic-site-disabling']; // This LD should return boolean.

  const requestFailed =
    error instanceof ApolloError &&
    'networkError' in error &&
    error.networkError &&
    error.networkError['statusCode'] >= 502 &&
    error.networkError['statusCode'] <= 511;

  if (performanceFlagValue === PerformanceMode.disabled || (requestFailed && shouldAutomaticallyDisable)) {
    throw new LoadFailureError();
  }
}

export function LoadPerformanceMode(): null {
  useLoadPerformanceMode();

  return null;
}
