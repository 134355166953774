import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';

import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import { Clickable } from 'shared/components';
import ChevronIcon from 'assets/chevron-icon';

// note, this function is duplicated line for line in api/src/env/endpoints/embedded-v3/embed-src/back-to-top.js
// any changes to this should be made there as well so that behavior is the same
export const createScrollHandler = (setVisible, offsetTop = 0) => {
  let prevScrollY = window.pageYOffset;
  let deadZoneScrollPos = window.pageYOffset;
  let wasScrollingUp = false;

  return () => {
    const newScroll = window.pageYOffset;
    let isScrollingUp = newScroll > offsetTop + 20 && newScroll < prevScrollY;
    prevScrollY = newScroll;
    if (isScrollingUp !== wasScrollingUp) {
      // scroll direction has changed, check the deadzone
      if (Math.abs(newScroll - deadZoneScrollPos) < 20) {
        isScrollingUp = wasScrollingUp;
      }
    } else {
      deadZoneScrollPos = newScroll;
    }
    if (isScrollingUp !== wasScrollingUp) {
      wasScrollingUp = isScrollingUp;
      setVisible(isScrollingUp);
    }
  };
};

export default function BackToTop() {
  const [visible, setVisible] = useState();
  const UI = useUI();
  const { t } = useTranslation();
  const isIframe = useObserver(() => UI.isIframe);

  useEffect(() => {
    const scrollHandler = createScrollHandler(setVisible);
    document.addEventListener('scroll', scrollHandler);
    return () => document.removeEventListener('scroll', scrollHandler);
  }, []);

  if (isIframe) {
    // back to top is handled by embed-src script in iframes
    return null;
  }

  function scrollToTop() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  return (
    <Container
      aria-label={t('common.scroll_to_top', 'Scroll to top')}
      className={visible ? 'visible' : ''}
      onClick={scrollToTop}
    >
      <ChevronIcon />
    </Container>
  );
}

const Container = styled(Clickable)`
  align-items: center;
  background-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  border-radius: ${({ theme }) => theme.customized.radius.sm};
  bottom: 19px;
  display: flex;
  height: 40px;
  justify-content: center;
  left: calc(50% - 20px);
  opacity: 0;
  pointer-events: none;
  position: fixed;
  text-align: center;
  transition: opacity 0.2s;
  width: 40px;
  z-index: 1001;

  &.visible {
    opacity: 1;
    pointer-events: initial;
    cursor: pointer;
  }

  svg {
    color: #fff;
    height: 8.5px;
    margin-bottom: 1px;
    position: relative;
    transform: rotate(-180deg);
    width: 14px;
  }
`;
