import { useApolloClient } from '@apollo/react-hooks';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import _ from 'lodash';
import useTranslation from 'hooks/use-translation';
import useUser from 'hooks/use-user';
import { ShowEnrollment } from 'src/payments/graphql/queries/show-enrollment.gql';
import { consumerLogin } from 'shared/actions/users';
import { ERNIE_DEFAULT_MESSAGES, ERNIE_TIMEOUTS, ERNIE_TYPES } from 'shared/constants';
import useErnie from 'shared/hooks/use-ernie';

type UseSubmitPasswordResult = {
  submitPassword: ({ password }: { password: string }) => Promise<void>;
};

export const useSubmitPassword = (): UseSubmitPasswordResult => {
  const apolloClient = useApolloClient();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const showErnie = useErnie();

  const { t } = useTranslation();
  const User = useUser();

  const submitPassword = async ({ password }): Promise<void> => {
    try {
      const result = await consumerLogin(
        apolloClient,
        User,
        DutchiePayEnrollment.userEmail,
        password,
        DutchiePayEnrollment,
        ShowEnrollment
      );

      if (result.success) {
        DutchiePayEnrollment.passwordStepCompleted();
      } else {
        const message = _.get(
          result,
          `error.graphQLErrors[0].extensions.errors[0].detail`,
          t('login.fail', '{{message}}', { message: ERNIE_DEFAULT_MESSAGES.LOGIN })
        );

        showErnie(message, ERNIE_TYPES.DANGER, ERNIE_TIMEOUTS.LONG);
      }
    } catch (err) {
      console.error(err);
      showErnie(err.message, ERNIE_TYPES.DANGER, ERNIE_TIMEOUTS.LONG);
    }
  };

  return {
    submitPassword,
  };
};
