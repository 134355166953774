import React from 'react';

import { TypographyProps, Sizes } from '../../types';
import { useTypography, makeFontSizes } from '../../utils';

import { Typography } from '../typography';

type HeadingSizes = Exclude<Sizes, 'xxlarge'>;

const fontsSizes = makeFontSizes<HeadingSizes>({
  xlarge: { base: 'font-size-110', md: 'font-size-130' },
  large: { base: 'font-size-90', md: 'font-size-110' },
  medium: {
    base: 'font-size-80',
    md: 'font-size-90',
  },
  small: { base: 'font-size-60', md: 'font-size-80' },
  xsmall: { base: 'font-size-60' },
  xxsmall: { base: 'font-size-20' },
});

export function Heading({
  tag,
  children,
  className,
  size,
  casing = 'none',
  weight = 'bold',
  ...rest
}: TypographyProps<HeadingSizes>): JSX.Element {
  const styles = useTypography({
    fontsSizes,
    size,
    casing,
    weight,
    lineHeight: 1.2,
  });

  return (
    <Typography className={className} styles={styles} as={tag} {...rest}>
      {children}
    </Typography>
  );
}
