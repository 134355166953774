import React, { forwardRef } from 'react';
import { ModalContainer, ModalExitIcon } from 'shared/modals/v2-container';
import useTranslation from 'hooks/use-translation';
import useLogRocket from 'src/hooks/use-logrocket';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import useInstoreCart from 'src/hooks/use-instore-cart';
import useStores from 'shared/hooks/use-stores';
import { PBB_NAME, relinkBankAccount } from 'src/payments/constants';
import { useOpenDutchiePay } from 'src/payments/hooks/use-open-dutchie-pay/use-open-dutchie-pay';
import useUI from 'src/hooks/use-ui';
import {
  BodyText,
  Button,
  ContentContainer,
  DutchiePayLogo,
  HeaderContainer,
  Heading,
  LinkAccount,
  ModalContent,
} from 'components/modals/dutchie-pay/modal.styles';
import PlusSymbol from 'assets/plus-symbol';
import { PlaidLogo } from 'assets/plaid-logo';
import { ModalObserverRenderProps } from '../modal-observer/modal-observer.types';
import { MobileEcommDutchiePayButton, MobileEcommDutchiePayCta } from './mobile-ecomm-cta';

type RelinkBankAccountModalProps = ModalObserverRenderProps & {
  isInstoreCart: boolean;
};

export const RelinkBankAccountModal = forwardRef(
  ({ onClose, triggerOnClose, containerProps, isInstoreCart }: RelinkBankAccountModalProps, ref) => {
    const { t, Trans } = useTranslation();
    const DutchiePayEnrollment = useDutchiePayEnrollment();
    const { Cart } = useStores();
    const { isMobileEcommApp } = useUI();
    const { requiresAggregatorMigration } = DutchiePayEnrollment;

    const InstoreCart = useInstoreCart();
    const cart = isInstoreCart ? InstoreCart : Cart;
    const { openDutchiePay } = useOpenDutchiePay({
      cname: cart.dispensary.cName,
      onCloseModal: triggerOnClose,
      step: relinkBankAccount,
    });

    const { bankName, lastDigits } = DutchiePayEnrollment.activeBankAccount;

    const { track } = useLogRocket();

    const onRelinkAccountClick = (): void => {
      openDutchiePay();
      track(`DutchiePay_reconnecting`);
      DutchiePayEnrollment.reconnectBankAccount();
    };

    let modalContent = {
      title: t('payByBank.bankAccountUnlinked', 'Bank account unlinked'),
      bodyContent: [
        <Trans i18nKey='payByBank.bankUnlinkedAccountDetails'>
          Your <strong>{bankName}</strong> account ending in <strong>{lastDigits}</strong> was recently unlinked as a
          security measure to keep your account safe.
        </Trans>,
        isMobileEcommApp ? (
          <MobileEcommDutchiePayCta />
        ) : (
          t(
            'payByBank.relinkAccountParagraph',
            `Please relink your bank account to continue using ${PBB_NAME} for this purchase.`
          )
        ),
      ],
      cta: {
        onClick: onRelinkAccountClick,
        text: t('payByBank.relinkAccount', 'Relink Account'),
      },
    };

    if (requiresAggregatorMigration) {
      const updatedModalContent = {
        title: t('payByBank.bankAccountOnPlaid', `${PBB_NAME} is now partnering with Plaid!`),
        bodyContent: [
          <Trans i18nKey='payByBank.bankUnlinkedAccountDetails'>
            Your <strong>bank account</strong> has been unlinked because we’ve switched over to using{' '}
            <strong>Plaid</strong>.
          </Trans>,
          isMobileEcommApp ? (
            <MobileEcommDutchiePayCta />
          ) : (
            t(
              'payByBank.relinkAccountParagraph',
              'Relink your bank account to continue checking out. Don’t worry, it’s fast and easy.'
            )
          ),
        ],
      };
      modalContent = { ...modalContent, ...updatedModalContent };
    }

    const { title, cta, bodyContent } = modalContent;
    const { onClick, text } = cta;

    return (
      <ModalContainer data-test='dutchie-pay-relink-bank-modal' ref={ref} padding={0} width={335} {...containerProps}>
        <ModalExitIcon onClick={onClose} />
        <ModalContent>
          <HeaderContainer>
            <DutchiePayLogo width='110' height='20' />{' '}
            {requiresAggregatorMigration && (
              <>
                <PlusSymbol fill='#A3AFBA' height={15} width={15} />
                <PlaidLogo height={24} width={64} />
              </>
            )}
          </HeaderContainer>
          <Heading>{title}</Heading>
          <ContentContainer>
            {bodyContent.map((paragraph) => (
              <BodyText key={paragraph}>{paragraph}</BodyText>
            ))}
          </ContentContainer>
          {isMobileEcommApp ? <MobileEcommDutchiePayButton /> : <Button onClick={onClick}>{text}</Button>}
          <LinkAccount
            onClick={() => {
              DutchiePayEnrollment.reconnectBankAccount();
              openDutchiePay();
            }}
          >
            {t('payByBank.relinkBankAccount.linkAnotherAccount', 'Link to another bank account')}
          </LinkAccount>
        </ModalContent>
      </ModalContainer>
    );
  }
);
