import { useRouter } from 'next/router';

import { useAddToCartModal } from 'components/modals/add-to-cart';
import ROUTES from 'src/routes';
import { UseShoppingWithParams, UseShoppingWithReturn } from './shopping-with.types';

const { DISPENSARY_CNAME } = ROUTES;

export function useShoppingWith({ addToCartData }: UseShoppingWithParams): UseShoppingWithReturn {
  const { open: openAddToCartModal } = useAddToCartModal();
  const router = useRouter();
  const { dispensary } = addToCartData;

  async function handleContinue(): Promise<void> {
    if (!dispensary.cName) {
      throw new Error(`Dispensary data required in order to add item to cart.`);
    }
    await router.push({ pathname: DISPENSARY_CNAME, query: { cName: dispensary.cName } });
    void openAddToCartModal(addToCartData);
  }

  return { handleContinue };
}
