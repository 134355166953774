import React from 'react';

const SvgEmailIcon = (props) => (
  <svg width='25' height='19' viewBox='0 0 25 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M22.8027 0H2.19727C0.987207 0 0 0.946065 0 2.10799V16.1612C0 17.3192 0.982812 18.2692 2.19727 18.2692H22.8027C24.0098 18.2692 25 17.3264 25 16.1612V2.10799C25 0.95 24.0172 0 22.8027 0ZM22.4993 1.40533L12.5466 10.9537L2.50776 1.40533H22.4993Z'
      fill='#EAEFF2'
    />
    <path
      d='M22.8027 0H2.19727C0.987207 0 0 0.946065 0 2.10799V16.1612C0 17.3192 0.982812 18.2692 2.19727 18.2692H22.8027C24.0098 18.2692 25 17.3264 25 16.1612V2.10799C25 0.95 24.0172 0 22.8027 0ZM1.46484 15.8702V2.39229L8.51948 9.10225L1.46484 15.8702ZM2.50063 16.8639L9.55972 10.0916L12.0332 12.4443C12.3195 12.7166 12.7816 12.7157 13.0667 12.4421L15.4785 10.1283L22.4994 16.8639H2.50063ZM23.5352 15.8702L16.5143 9.13461L23.5352 2.39898V15.8702Z'
      fill='#BCCAD2'
    />
    <path
      d='M22.8027 0H2.19727C0.987207 0 0 0.946065 0 2.10799V16.1612C0 17.3192 0.982813 18.2692 2.19727 18.2692H22.8027C24.0098 18.2692 25 17.3264 25 16.1612V2.10799C25 0.95 24.0172 0 22.8027 0ZM22.4993 1.40533L12.5466 10.9537L2.50776 1.40533H22.4993ZM1.46484 15.8702V2.39229L8.51948 9.10225L1.46484 15.8702ZM2.50063 16.8639L9.55972 10.0916L12.0332 12.4443C12.3195 12.7166 12.7816 12.7157 13.0667 12.4421L15.4785 10.1283L22.4994 16.8639H2.50063ZM23.5352 15.8702L16.5143 9.13461L23.5352 2.39898V15.8702Z'
      fill='#5E6D79'
    />
  </svg>
);

export default SvgEmailIcon;
