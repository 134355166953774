/* eslint-disable @typescript-eslint/naming-convention */
import { convertKeysToSnakeCase } from 'utils/analytics/helpers/convert-keys-to-snake-case';
import { createDelayedCallProxy } from 'utils/analytics/helpers/delayed-call-proxy';
import { getGtag } from 'utils/analytics/helpers/gtag';
import { isAnalyticsDebugMode } from 'utils/analytics/helpers/is-enabled';
import noop from 'lodash/noop';
import { PageTypes } from '../trackers/internal-gtm-tracker/helpers/get-page-type';

export const DEFAULT_CURRENCY = 'USD';

/**
 * @returns a client for GAv4 if id is provided. If id is null, returns a client-shaped object that noops.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createGaV4ClientOrStub = (id: string | null) => {
  const isOperational = id !== null;
  const gtag = isOperational ? getGtag() : noop;

  /**
   * send_to sends to a specific account
   * see: https://developers.google.com/gtagjs/reference/parameter
   */
  const forTracker = (obj: Record<string, unknown> | unknown): Record<string, unknown> | unknown =>
    typeof obj === 'object' ? { ...obj, send_to: id } : obj;

  /**
   * Keeps backwards-compatibility for ecommerce events via GTM
   * as described in https://developers.google.com/tag-manager/ecommerce-ga4
   *
   * Those events are intended for GA v4 use within GTM, which on the surface
   * make this seem redundant (why have GA v4 through GTM and directly?), but
   * it's possible they're being used as hooks for other ecommerce frameworks.
   *
   * Unfortunately these share the same event type as GA v4 but have a different
   * payload structure, so we can't decouple the two without firing duplicate events
   * that could mess up some GTM integrations.
   *
   * Luckily the payloads for ecommerce events match those required for GA v4 so we
   * can use "set" to attach them to the payload without any transformation.
   *
   * Ideally we'd migrate dispensaries that rely on GTM ecommerce events away to a
   * namespaced version (e.g. ecommerce_view_item, ecommerce_add_to_cart, ...) which
   * will decouple them from sharing events with GA v4.
   */
  const attachEcommercePayloadToNextEvent = (payload: unknown): void => {
    gtag('set', { ecommerce: payload });
  };

  // https://developers.google.com/gtagjs/reference/api#config
  gtag('config', id, { debug_mode: isAnalyticsDebugMode(), cookie_flags: 'SameSite=None;Secure' });

  return {
    /**
     * Whether attachLegacyEcommercePayload() is actively capturing ecommerce events.
     */
    isCapturingEcommercePayload() {
      return isOperational;
    },

    // https://developers.google.com/gtagjs/reference/api#event
    customEvent(name: string, payload: Record<string, unknown> = {}) {
      gtag('event', name, forTracker(payload));
    },

    // https://developers.google.com/gtagjs/reference/ga4-events#view_item
    viewItem(props: { currency?: string; items?: GaV4CartItem[]; value?: number }) {
      const payload = convertKeysToSnakeCase(props);

      attachEcommercePayloadToNextEvent(payload);
      gtag('event', 'view_item', forTracker(payload));
    },

    // https://developers.google.com/gtagjs/reference/ga4-events#add_to_cart
    addToCart(props: { currency?: string; value?: number; items?: GaV4CartItem[] }) {
      const payload = convertKeysToSnakeCase(props);

      attachEcommercePayloadToNextEvent(payload);
      gtag('event', 'add_to_cart', forTracker(payload));
    },

    // https://developers.google.com/gtagjs/reference/ga4-events#remove_from_cart
    removeFromCart(props: { currency?: string; value?: number; items?: GaV4CartItem[] }) {
      const payload = convertKeysToSnakeCase(props);

      attachEcommercePayloadToNextEvent(payload);
      gtag('event', 'remove_from_cart', forTracker(payload));
    },

    // https://developers.google.com/gtagjs/reference/ga4-events#begin_checkout
    beginCheckout(props: { coupon?: string; currency?: string; value?: number; items?: GaV4CartItem[] }) {
      const payload = convertKeysToSnakeCase(props);

      attachEcommercePayloadToNextEvent(payload);
      gtag('event', 'begin_checkout', forTracker(payload));
    },

    // https://developers.google.com/gtagjs/reference/ga4-events#purchase
    purchase(props: {
      affiliation?: string;
      coupon?: string;
      currency?: string;
      items?: GaV4CartItemWithTax[];
      transactionId?: string;
      shipping?: number;
      tax?: number;
      value?: number;
    }) {
      const payload = convertKeysToSnakeCase(props);

      attachEcommercePayloadToNextEvent(payload);
      gtag('event', 'purchase', forTracker(payload));
    },

    // https://developers.google.com/gtagjs/reference/ga4-events#view_promotion
    viewPromotion(props: {
      promotionId?: string;
      promotionName?: string;
      creativeName?: string;
      creativeSlot?: string;
      items: GaV4SponsoredItem[];
    }) {
      const payload = convertKeysToSnakeCase(props);

      attachEcommercePayloadToNextEvent(payload);
      gtag('event', 'view_promotion', forTracker(payload));
    },

    // https://developers.google.com/gtagjs/reference/ga4-events#select_promotion
    selectPromotion(props: {
      promotionId?: string;
      promotionName?: string;
      creativeName?: string;
      creativeSlot?: string;
      items: GaV4SponsoredItem[];
    }) {
      const payload = convertKeysToSnakeCase(props);

      attachEcommercePayloadToNextEvent(payload);
      gtag('event', 'select_promotion', forTracker(payload));
    },
  };
};

export const createGaV4Client = (id: string): ReturnType<typeof createGaV4ClientOrStub> => createGaV4ClientOrStub(id);

export type GaV4Client = ReturnType<typeof createGaV4Client> & { replay?: (client: GaV4Client) => void };

// TODO(analytics-team): remove once new analytics framework is operational
export const createGaV4DelayedCallClient = (): GaV4Client =>
  createDelayedCallProxy(createGaV4ClientOrStub(null), { passthrough: ['isCapturingEcommercePayload'] });

type GaV4CartItemWithTax = GaV4CartItem & { tax?: number };

export type GaV4CartItem = {
  itemId: string;
  itemName: string;
  quantity?: number;
  affiliation?: string;
  coupon?: string;
  discount?: number;
  itemBrand?: string;
  itemCategory?: string;
  itemCategory2?: string;
  itemCategory3?: string;
  itemCategory4?: string;
  itemCategory5?: string;
  itemVariant?: string;
  price?: number;
  currency?: string;
  itemListName?: string | null;
};

// Dutchie AD sponsored product tracking info
// https://dutchie.atlassian.net/wiki/spaces/GC/pages/20261864160/Brand+carousel+GA4+event+tracking#Data-sent-to-GA4
export type GaV4SponsoredItem = {
  itemId: string;
  itemName: string;
  creativeName: AdTypes;
  creativeSlot: PageTypes;
  itemBrand: string;
  itemCategory: string;
  index?: number;
  price?: number | null;
  promotionId: string;
  promotionName: string;
  quantity?: number | null;
  itemListName?: string | null;
};

export enum AdTypes {
  ad = 'Ad',
}
