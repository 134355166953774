import React from 'react';
import styled from 'styled-components';

import { Product } from 'utils/helpers/product';

import useColors from 'hooks/use-colors';
import useTranslation from 'hooks/use-translation';

import PlusSymbol from 'assets/plus-symbol';
import { DispensaryOrderingDetails } from 'components/dispensary-ordering-details';
import { Button, HorizontalDivider, GreyCapsText } from 'components/core';
import { useOptionCard } from './use-option-card';

export type OptionCardProps = {
  product: Product;
  weight: string | null;
};

export function OptionCard({ product, weight }: OptionCardProps): JSX.Element | null {
  const colors = useColors();
  const { t } = useTranslation();
  const { cbdRange, handleClickAddToCart, basePriceToDisplay, thcRange, showMetaInfoSection } = useOptionCard({
    product,
    weight,
  });

  const { strainType, dispensary } = product;

  if (!dispensary) {
    console.error(
      product.id
        ? `Unable to find dispensary associated with ${product.id}`
        : `Unable to find dispensary associated with product`
    );
    return null;
  }

  return (
    <Card>
      <DispensaryDetailsContainer>
        <DispensaryOrderingDetails dispensary={dispensary} variant='xs' displayAvailString={false} />
        <PricingContainer>
          <PriceTextContainer>
            {/* TODO add back in discount UI when we have discount data */}
            {/* {discountedPrice && <StrikeThrough>{basePriceToDisplay}</StrikeThrough>} */}
            {/* <PriceText>{formattedDiscountPrice ?? basePriceToDisplay}</PriceText> */}
            <PriceText>{basePriceToDisplay}</PriceText>
          </PriceTextContainer>
          <Button size='small' onClick={handleClickAddToCart}>
            <div>
              <PlusSymbol fill={colors.white} />
            </div>
            <span>{t('addToCartButton.add', 'ADD')}</span>
          </Button>
        </PricingContainer>
      </DispensaryDetailsContainer>
      {showMetaInfoSection && (
        <>
          <HorizontalDivider height='2px' width='100%' />
          <ProductMetaContainer>
            {strainType && (
              <MetaPill>
                <GreyCapsText>{strainType}</GreyCapsText>
              </MetaPill>
            )}
            {thcRange && (
              <MetaPill>
                <GreyCapsText>
                  {t('buying-options.thcRange', { defaultValue: 'THC: {{thcRange}}', thcRange })}
                </GreyCapsText>
              </MetaPill>
            )}
            {cbdRange && (
              <MetaPill>
                <GreyCapsText>
                  {t('buying-options.cbdRange', { defaultValue: 'CBD: {{cbdRange}}', cbdRange })}{' '}
                </GreyCapsText>
              </MetaPill>
            )}
          </ProductMetaContainer>
        </>
      )}
    </Card>
  );
}

const Card = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.097543);
  height: auto;
  max-width: 100%;
`;

const DispensaryDetailsContainer = styled.div`
  display: flex;
  place-content: space-between;
  padding: 20px 15px 16px 20px;
  button {
    text-transform: uppercase;
    max-height: 28px;
    display: flex;
    padding-left: 6px;
    place-items: center;
    div {
      margin-right: 6px;
      background: ${({ theme: { colors } }) => colors.blue[40]};
      border-radius: 50%;
      width: 17px;
      height: 17px;
      display: flex;
      place-content: center;
      place-items: center;
      svg {
        margin: 0;
        width: 9px;
      }
    }
  }
`;

const MetaPill = styled.div`
  padding: 6px 8px;
  display: flex;
  place-content: center;
  place-items: center;
  background: ${({ theme: { colors } }) => colors.blueGrey[95]};
  border-radius: 4px;
  span {
    color: ${({ theme: { colors } }) => colors.grey[35]};
    font-size: 10px;
  }
`;

const ProductMetaContainer = styled.div`
  display: flex;
  padding: 14px 20px 20px;
  > div:not(:last-of-type) {
    margin-right: 6px;
  }
`;

const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-items: flex-end;
`;

const PriceTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

const PriceText = styled.span`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
`;

// const StrikeThrough = styled(GreyCapsText)`
//   line-height: 14px;
//   margin-right: 6px;
//   text-decoration: line-through;
// `;
