import React from 'react';

function SvgLocationIcon({ fill = '#677882', ...props }) {
  return (
    <svg width={21} height={21} fill={fill} viewBox='0 0 21 21' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 10.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm-1.5 0a3 3 0 11-6 0 3 3 0 016 0z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M11.25 18.716a8.252 8.252 0 007.466-7.466h1.534a.75.75 0 000-1.5h-1.534a8.252 8.252 0 00-7.466-7.466V.75a.75.75 0 00-1.5 0v1.534A8.252 8.252 0 002.284 9.75H.75a.75.75 0 000 1.5h1.534a8.252 8.252 0 007.466 7.466v1.534a.75.75 0 001.5 0v-1.534zM3.75 10.5a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0z'
      />
    </svg>
  );
}

export default SvgLocationIcon;
