import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import toDate from 'validator/lib/toDate';
import moment from 'moment';

import { Button, Option } from 'components/core';
import { canadianProvincesMap } from 'shared/core/constants/geography';
import { DateInput } from 'shared/components/text-input-with-label';
import { StyledSelect, StyledTextInput } from 'components/form-inputs/components';
import useTranslation from 'hooks/use-translation';
import { StyledModalContainer, DutchieLogo, Heading, RememberMe, TermsAndPrivacy } from './components';

const CanadaTerritoryPickerModal = forwardRef((props, ref) => {
  const {
    onContinue,
    geoinfo: { region },
    onRegionChange,
    hideDutchieBranding,
  } = props;
  const { t } = useTranslation();
  const [ready, setReady] = useState();
  const [rememberMe, setRememberMe] = useState(true);
  const hasValue = !!region;

  function handleBirthdayChange(e) {
    const { value } = e.target;
    let isReady = true;
    if (value.endsWith('y')) {
      // not ready
      isReady = false;
    }
    const birthday = toDate(value);
    if (!birthday) {
      isReady = false;
    }

    if (ready !== isReady) {
      setReady(isReady && birthday);
    }
  }

  function handleContinue() {
    onContinue(moment().diff(ready, 'years'), rememberMe);
  }

  const headerCopy = hideDutchieBranding
    ? t('age-restrictions.canada-header-storefront', 'We need to check your ID first!')
    : t('age-restrictions.canada-header-dutchie-main', 'Welcome to dutchie. We need to check your ID first!');

  return (
    <StyledModalContainer ref={ref} data-testid='canada-territory-picker-modal'>
      {!hideDutchieBranding && <DutchieLogo alt='Dutchie Logo' />}
      <Heading>{headerCopy}</Heading>

      <FormControl fullWidth variant='outlined'>
        <StyledInputLabel shrink={hasValue} htmlFor='territory-selection'>
          {t('age-restrictions.select-territory', 'Select province or territory')}
        </StyledInputLabel>
        <TerritorySelection
          id='territory-selection'
          onChange={(e) => onRegionChange(e.target.value)}
          value={region || ''}
          autoFocus
        >
          {!hasValue && <Option disabled value='' />}
          {Object.entries(canadianProvincesMap).map(([key, value]) => (
            <Option key={key} value={value}>
              {value}
            </Option>
          ))}
        </TerritorySelection>
      </FormControl>
      {hasValue && (
        <DateInput
          variant='filled'
          InputLabelProps={{ shrink: true }}
          customInput={StyledTextInput}
          onChange={handleBirthdayChange}
          label={t('age-restrictions.birthday', 'Enter your birthday')}
        />
      )}
      <GridContainer container>
        <LeftGrid item xs={12} sm={8}>
          <RememberMe value={rememberMe} onChange={setRememberMe} />
        </LeftGrid>
        <RightGrid item xs={12} sm={4}>
          <Button onClick={handleContinue} disabled={!ready}>
            {t('common.continue', 'Continue')}
          </Button>
        </RightGrid>
      </GridContainer>
      <TermsAndPrivacy actionType='continue' />
    </StyledModalContainer>
  );
});

export default CanadaTerritoryPickerModal;

const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    color: #858788;
    font-size: 13px;
    line-height: 20px;
  }
  &.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(18px, 9px) scale(0.75);

    ${({ theme }) => theme.breakpoints.down('sm')} {
      transform: translate(18px, 12px) scale(0.75);
    }
  }
`;

const GridContainer = styled(Grid)`
  margin-top: 20px;
  height: 40px;
`;

const LeftGrid = styled(Grid)`
  align-items: center;
  display: flex;
`;

const RightGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 20px;
    justify-content: flex-start;
  }
`;

const TerritorySelection = styled(StyledSelect)`
  /* Make up for the label here which isn't usually there */
  & .MuiOutlinedInput-input {
    margin-top: 8px;
  }
  & #territory-selection {
    box-shadow: none;
  }
`;
