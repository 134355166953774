import React from 'react';
import styled from 'styled-components';
import { GqlDispensaries } from 'types/graphql';

type DispensaryHeaderProps = {
  dispensary: GqlDispensaries;
};

export function DispensaryHeader({ dispensary }: DispensaryHeaderProps): JSX.Element {
  const { address, logoImage, name } = dispensary;

  return (
    <Header>
      <LogoImage alt={name} src={logoImage} />

      <Dispensary>
        <NameContainer>
          <Name>{name}</Name>
        </NameContainer>
        <Address>{address}</Address>
      </Dispensary>
    </Header>
  );
}

const Header = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.grey[30]};
  justify-content: flex-start;
  padding-left: 16px;
  width: 100%;
`;

const LogoImage = styled.img`
  height: 49.5px;
  width: auto;
  padding-right: 16px;
`;

const Dispensary = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const NameContainer = styled.div`
  display: flex;
  height: 25px;
  align-items: center;
`;

const Address = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 21.45px;
  max-width: 90%;
  color: ${({ theme }) => theme.colors.primaryBlack};
`;
