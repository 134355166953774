import React, { useState } from 'react';
import { GqlDispensaries } from 'types/graphql';

import { useHeader } from 'src/dispensary/core-menu/components/header/use-header';

import SvgChevronIcon from 'src/assets/chevron-icon';
import MenuDrawer from 'src/dispensary/core-menu/components/core-menu-header/menu-drawer';
import { CartButton } from 'src/dispensary/core-menu/components/header/cart-button';
import { UserButton } from 'src/dispensary/core-menu/components/header/user-button';
import { Search } from 'src/dispensary/core-menu/components/header/search';

import { HeaderContainer, HeaderWrapper, MenuButton, UtilityNav } from './mobile-header.styles';

type MobileHeaderProps = {
  dispensary: GqlDispensaries;
  isAccountEnabled: boolean;
};

export const MobileHeader = ({ dispensary, isAccountEnabled }: MobileHeaderProps): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { trackEvent } = useHeader();

  const handleOpenClick = (): void => {
    setIsDrawerOpen(true);
    trackEvent('open menu');
  };

  const handleCloseClick = (): void => {
    setIsDrawerOpen(false);
    trackEvent('close menu');
  };

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <MenuButton onClick={handleOpenClick}>
          <SvgChevronIcon height='7px' width='12px' />
          Menu
        </MenuButton>
        <MenuDrawer dispensary={dispensary} open={isDrawerOpen} onClose={handleCloseClick} />

        <Search />

        <UtilityNav>
          {isAccountEnabled && <UserButton />}
          <CartButton />
        </UtilityNav>
      </HeaderContainer>
    </HeaderWrapper>
  );
};
