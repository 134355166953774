import React from 'react';
import styled from 'styled-components';
import { layout } from 'styled-system';
import useTranslation from 'hooks/use-translation';

/**
 * These are not all the props. The rest should be added if/when they're used in a TS file.
 * @type {React.FC<{onClick: (option: object) => void; checked: boolean; option: object; height?: string}>}
 */
const CheckboxInput = React.forwardRef((props, ref) => {
  const { height, onBlur, onClick, checked, option } = props;
  const { t } = useTranslation();

  const label = option.labelTranslationKey ? t(option.labelTranslationKey, option.label) : option.label;

  return (
    <Container height={height}>
      <Input
        checked={checked}
        onBlur={onBlur}
        onChange={() => onClick(option)}
        type='checkbox'
        id={option.key}
        name={option.name}
        link={option.link}
        ref={ref}
      />
      <Text checked={checked} htmlFor={option.key}>
        {label}
      </Text>
    </Container>
  );
});

const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 13px;
  height: 40px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 30px;
    ${layout}
  }
  ${layout}
`;

const Text = styled.label`
  background-repeat: no-repeat;
  color: ${({ theme }) => theme.colors.primaryBack};
  display: inline-block;
  padding: 0 0 0 28px;
  position: relative;
  z-index: auto;
  cursor: pointer;
  background-size: 15px;
  background-position: left 2px;
  background-image: ${({ checked }) =>
    checked ? `url('/icons/checked-icon.svg')` : `url('/icons/unchecked-icon.svg')`};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 13px;
  }
  line-height: 1.65;
`;

const Input = styled.input`
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  cursor: pointer;

  // if there is a link make the link clickable
  pointer-events: ${(props) => (props.link ? `none` : `inherit`)};
  // apply focus styles to label
  &.focus-visible + ${Text} {
    box-shadow: 0 0 0 2pt rgba(11, 153, 230, 0.4) !important;
  }
`;

export default CheckboxInput;
