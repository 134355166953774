import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import { width } from 'styled-system';
import _ from 'lodash';

import useStores from 'shared/hooks/use-stores';
import useTranslation from 'hooks/use-translation';
import { getEnabledV2OrderTypes, openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { Button, ModalContainer } from 'components/core';
import ClosedSign from 'assets/closed-sign.jsx';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

const ClosedModal = forwardRef(({ containerProps }, ref) => {
  const { t } = useTranslation();
  const { UI } = useStores();
  const { dispensary } = useDispensary();

  const dispensaryName = useObserver(() => dispensary?.name) || '';

  // V2 ordering settings
  const enabledOrderTypes = getEnabledV2OrderTypes(dispensary);
  const { inStorePickup, curbsidePickup, driveThruPickup, delivery: deliveryV2 } = openInfoForDispensary(dispensary, {
    userTimezone: UI?.timezone,
    previewMode: UI?.previewMode,
  });

  return (
    <ModalContainer ref={ref} width={495} height='auto' p={40} {...containerProps}>
      <ClosedSign />
      <DispensaryClosedText>
        {t('closed.sorry', { defaultValue: 'Sorry, {{dispensaryName}} is closed!', dispensaryName })}
      </DispensaryClosedText>
      <ReopenText>{t('closed.reopen', "They'll reopen")}:</ReopenText>

      <Container>
        <StyledOrderTypeText>
          <OpenHoursGrid>
            {_.includes(enabledOrderTypes, 'inStorePickup') && (
              <>
                <RightAlignText>{t('common.inStorePickup', 'In-Store Pickup')}:</RightAlignText>
                <div>{inStorePickup.nextService.openString}</div>
              </>
            )}
            {_.includes(enabledOrderTypes, 'curbsidePickup') && (
              <>
                <RightAlignText>{t('common.curbsidePickup', 'Curbside Pickup')}:</RightAlignText>
                <div>{curbsidePickup.nextService.openString}</div>
              </>
            )}
            {_.includes(enabledOrderTypes, 'driveThruPickup') && (
              <>
                <RightAlignText>{t('common.driveThruPickup', 'Drive-Thru Pickup')}:</RightAlignText>
                <div>{driveThruPickup.nextService.openString}</div>
              </>
            )}
            {_.includes(enabledOrderTypes, 'delivery') && (
              <>
                <RightAlignText>{t('common.delivery', 'Delivery')}:</RightAlignText>
                <div>{deliveryV2.nextService.openString}</div>
              </>
            )}
          </OpenHoursGrid>
        </StyledOrderTypeText>
      </Container>
      <ModalButton onClick={() => (UI.showClosedModal = false)} autoFocus>
        {t('common.gotIt', 'GOT IT')}
      </ModalButton>
    </ModalContainer>
  );
});

export default ClosedModal;

const ModalButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
  line-height: 1.4;
  margin-top: 30px;
`;

export const DispensaryClosedText = styled.h1`
  font-weight: bold;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 25px;
`;

const ReopenText = styled.h2`
  font-size: 13px;
  color: #8b8f94;
  line-height: 22px;
  ${width}
  margin-bottom: 6px;
`;

const StyledOrderTypeText = styled.div`
  display: inline-block;
  font-size: 13px;
  color: #8b8f94;
  line-height: 22px;
  width: fit-content;
  text-align: left;
  overflow-x: visible;
`;

const Container = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const OpenHoursGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
`;

const RightAlignText = styled.div`
  text-align: right;
`;
