import { useMemo } from 'react';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import { isBrowser } from 'utils/misc-utils';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';

import possibleTypes from 'shared/schemas/fragment-types';
import PublicEnv from 'shared/utils/public-env';
import LogRocket from 'shared/logrocket';

import { apolloSessionLink } from 'utils/session';
import { networkStatusLink } from './network-status';
import { cellRoutedFetch, setCellContext } from './cell-routing';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: possibleTypes,
});

let globalApolloClient = null;

let { consumerUrl } = PublicEnv;

if (isBrowser()) {
  consumerUrl = ``;
}

export const EXTENSION_ERRORS = {
  AUTHERROR: 'AUTHENTICATION_ERROR',
  SESSIONEXPIRED: 'SESSION_INVALID',
};

const handleSessionExpired = (error) => {
  if (isBrowser()) {
    console.error(error);
    // "Simply remove token when session expires. Redirects should be handled at the page level."
    setPersistedValue('access-token', false);
  }
};

const errorLinkFactory = ({ onSessionExpired, onAuthError } = {}) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    // console.error(`Error in the following operation: ${operation}`);

    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        console.error(error);
        if (error.extensions?.code === EXTENSION_ERRORS.AUTHERROR) {
          // console.error('Auth error');
          onAuthError?.call(this, error);
        }
        if (error.extensions?.code === EXTENSION_ERRORS.SESSIONEXPIRED) {
          // console.error('Session expired');
          onSessionExpired?.call(this, error);
        }
      });
    }

    if (networkError) {
      console.error(`Network Error: ${networkError.message} (${networkError.statusCode})`);
    }
  });

function setHeaders(operation, forward) {
  const headers = {};
  if (isBrowser()) {
    const token = getPersistedValue('access-token');
    headers.url = window.location.href;

    if (token) {
      headers.authorization = token;
    }

    if (LogRocket) {
      LogRocket.getSessionURL((sessionURL) => {
        headers['X-LogRocket-URL'] = sessionURL;
      });
    }
  }

  operation.setContext(() => ({
    headers: { ...operation.getContext().headers, ...headers },
  }));

  return forward(operation);
}

function dataIdFromObject(object) {
  const dataId = defaultDataIdFromObject(object);

  //  Store sponsored products under a different cache id to avoid conflicts
  if (object.__typename === 'Products' && object.adTrackers !== null) {
    return `Sponsored${dataId}`;
  }

  return dataId;
}

/**
 * Creates and configures the ApolloClient
 */
export function createApolloClient() {
  const name = `Marketplace (${PublicEnv.appEnv})`;

  const errorLink = errorLinkFactory({ onSessionExpired: handleSessionExpired, onAuthError: handleSessionExpired });

  const httpLink = new HttpLink({
    credentials: 'include',
    fetch: cellRoutedFetch,
    uri: `${consumerUrl}/graphql`,
    useGETForHashedQueries: true,
  });

  return new ApolloClient({
    connectToDevTools: isBrowser(),
    ssrMode: !isBrowser(),
    name,
    version: PublicEnv.currentVersion,
    cache: new InMemoryCache({ dataIdFromObject, fragmentMatcher, assumeImmutableResults: true, freezeResults: true }),
    link: ApolloLink.from([
      setHeaders,
      apolloSessionLink,
      errorLink,
      createPersistedQueryLink({ useGETForHashedQueries: true }),
      setCellContext,
      networkStatusLink,
      httpLink,
    ]),
  });
}

export function initializeApollo(initialState = null) {
  const apolloClient = globalApolloClient || createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    // TODO: What does that mean? --^
    const existingCache = apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!globalApolloClient) {
    globalApolloClient = apolloClient;
  }
  return apolloClient;
}

export function useApollo(initialState) {
  const client = useMemo(() => initializeApollo(initialState), [initialState]);
  return client;
}
