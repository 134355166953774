import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["children"],
    _excluded2 = ["icon", "copy", "textButton", "textButtonFunc", "close", "centered", "disabled"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Flex, Image } from 'rebass';
import { ModalClose } from './index';
import { ResetButton } from '../components';
import { colors } from '../styles';
var Container = styled(function (props) {
  return __jsx(Flex, props);
}).withConfig({
  displayName: "fancy-header__Container",
  componentId: "sc-17a0ayg-0"
})(["z-index:5;background-color:#ecf0f3;border-color:#d1d5da;height:", ";align-items:center;border-bottom:1px solid #dbdbdb;border-top-left-radius:10px;border-top-right-radius:10px;position:absolute;top:0;left:0;right:0;justify-content:", ";padding:", ";"], function (props) {
  return props.height ? props.height : '60px';
}, function (props) {
  if (props.centered) {
    return 'center';
  }

  if (props.textButton) {
    return 'space-between';
  }

  return 'flex-start';
}, function (props) {
  if (props.textButton) {
    return '4px 60px 0 33px';
  }

  return '1px 33px 0 33px';
});
var Header = styled(function (_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx("h2", props, children);
}).withConfig({
  displayName: "fancy-header__Header",
  componentId: "sc-17a0ayg-1"
})(["color:#6d747b;font-weight:bold;font-size:16px;", " ", ""], function (props) {
  return props.icon && "\n    margin-left: 12px;\n  ";
}, function (props) {
  return props.centered && "\n    font-size: 20px;\n  ";
});
var Close = styled(ModalClose).withConfig({
  displayName: "fancy-header__Close",
  componentId: "sc-17a0ayg-2"
})(["top:12px;right:15px;"]);
var TextButton = styled(function (props) {
  return __jsx(ResetButton, _extends({
    color: colors.blue
  }, props));
}).withConfig({
  displayName: "fancy-header__TextButton",
  componentId: "sc-17a0ayg-3"
})(["&:hover{text-decoration:underline;}"]);
export default (function (
/** @type {textButton: string | boolean | undefined} */
_ref2) {
  var _ref2$icon = _ref2.icon,
      icon = _ref2$icon === void 0 ? false : _ref2$icon,
      copy = _ref2.copy,
      _ref2$textButton = _ref2.textButton,
      textButton = _ref2$textButton === void 0 ? false : _ref2$textButton,
      _ref2$textButtonFunc = _ref2.textButtonFunc,
      textButtonFunc = _ref2$textButtonFunc === void 0 ? false : _ref2$textButtonFunc,
      close = _ref2.close,
      _ref2$centered = _ref2.centered,
      centered = _ref2$centered === void 0 ? false : _ref2$centered,
      _ref2$disabled = _ref2.disabled,
      disabled = _ref2$disabled === void 0 ? false : _ref2$disabled,
      props = _objectWithoutProperties(_ref2, _excluded2);

  return __jsx(Container, _extends({
    textButton: textButton,
    centered: centered
  }, props), icon && __jsx(Image, {
    width: "24px",
    src: icon
  }), __jsx(Header, {
    centered: centered || undefined,
    icon: icon || undefined
  }, copy), textButton && __jsx(TextButton, {
    onClick: textButtonFunc
  }, textButton), !disabled && __jsx(Close, {
    "data-testid": "modal-x",
    onClick: close
  }));
});