import React from 'react';

import { MARKETPLACE_STRAIN_TYPE_OPTIONS } from 'shop/constants/strain-type.constants';

import { ModalComponentProps, withModalControlsHook } from '../../with-modal-controls-hook';

import { useMarketplaceStrainTypeFilter } from './use-marketplace-strain-type-filter';
import { StrainTypeFilterDrawer } from '../strain-type-filter-drawer';

export function MarketplaceStrainTypeFilterDrawerWrapper(props: ModalComponentProps): JSX.Element {
  const { open } = props;
  const { handleSave, handleSelect, selectedOptions } = useMarketplaceStrainTypeFilter({ open });

  return (
    <StrainTypeFilterDrawer
      onSave={handleSave}
      onSelect={handleSelect}
      options={MARKETPLACE_STRAIN_TYPE_OPTIONS}
      selectedOptions={selectedOptions}
      {...props}
    />
  );
}

export const [
  MarketplaceStrainTypeFilterDrawerController,
  useMarketplaceStrainTypeFilterDrawer,
] = withModalControlsHook(MarketplaceStrainTypeFilterDrawerWrapper);
