export enum Category {
  accessories = 'Accessories',
  apparel = 'Apparel',
  cbd = 'CBD',
  clones = 'Clones',
  concentrate = 'Concentrate',
  edible = 'Edible',
  flower = 'Flower',
  nA = 'N/A',
  preRolls = 'Pre-Rolls',
  seeds = 'Seeds',
  tincture = 'Tincture',
  topicals = 'Topicals',
  vaporizers = 'Vaporizers',
  orals = 'Oral',
}
