import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import ProductRow from './product-row';

const OrderSummary = ({ order, handleItemClick }) => {
  const { orders } = order;
  const numberOfOrders = orders.length;
  const { dispensary } = order;

  return (
    <Products numberOfOrders={numberOfOrders}>
      {_.map(orders, (item, index) => (
        <ProductRow
          key={item.product._id}
          item={item}
          index={index}
          dispensary={dispensary}
          onClick={handleItemClick}
        />
      ))}
    </Products>
  );
};

export default OrderSummary;

const Products = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  border-radius: 8px;
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 41px;
`;
