import React from 'react';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { ModalObserver } from '../modal-observer';
import { OrderReceiptModal } from './order-receipt-modal';

export const OrderReceipt = ({ name }) => (
  <ModalObserver name={name}>
    {(props) => (
      <ModalWrapper {...props}>
        <OrderReceiptModal {...props} />
      </ModalWrapper>
    )}
  </ModalObserver>
);
