import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';

import { ModalContainer } from './modal';

const useStyles = makeStyles(() => ({
  paper: {
    background: 'transparent',
    boxShadow: 'none',
    height: '90vh',
    width: '1090px',
    margin: '0 auto',
  },
}));

export const PopUpModalContainer = styled(ModalContainer)`
  transform: none;
  top: initial;
  height: 100%;
  width: 1090px;
  max-width: 90%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  text-align: left;
  padding: 30px 0px;
`;

export const PopUpModalDrawer = (props) => {
  const { children, open, ...otherProps } = props;
  const [canOpen, setCanOpen] = useState(false);
  const classes = useStyles();

  // there's a visual bug if it's open on first render where it doesn't slide until reopened once
  useEffect(() => {
    setCanOpen(true);
  }, []);

  return (
    <Drawer classes={classes} open={canOpen && open} anchor='bottom' {...otherProps}>
      {children}
    </Drawer>
  );
};
