import { action, observable, makeObservable } from 'mobx';

type CardType = {
  token: string;
  cardholderFirstName: string;
  cardholderLastName: string;
  billingPostalCode: string;
};

export const MOCK_RETHINK_URL = `https://www.rethinkpay.io/V1/payment/popup?rethink_token=F3LdvwlvIxN2gwZDvjHRZX65q2KYOl3z`;
export const MOCK_RETHINK_URL_EMBEDDED = `https://www.rethinkpay.io/V1/payment/popup?rethink_token=5Av=CXKVG3OkaeRYzAv==A9XMVgxZ1WiK6eixAv=o9`;
export const MOCK_RETHINK_URL_CUSTOM = `https://www.rethinkpay.io/V1/payment/popup?rethink_token=FAv=3LdvwlvIxN2gwAv==ZDvjHRZX65q2KYOlAv=3z`;

export default class Payments {
  // To be replaced once CreatePayment query is implemented
  @observable rethinkUrl = MOCK_RETHINK_URL;

  @observable rethinkToken = '';

  @observable paymentId = '';

  @observable rethinkAuthorized = false;

  @observable showRethinkPay = false;

  @observable rethinkWindow: Window | null = null;

  @observable card: CardType = {
    token: '',
    cardholderFirstName: '',
    cardholderLastName: '',
    billingPostalCode: '',
  };

  constructor() {
    makeObservable(this);
  }

  @action
  setRethinkUrl(rethinkUrl: string): void {
    this.rethinkUrl = rethinkUrl;
  }

  @action
  setShowRethinkPay(status: boolean): void {
    this.showRethinkPay = status;
  }
  @action
  setCard(card: CardType): void {
    this.card = card;
  }
  @action
  setRethinkToken(rethinkToken: string): void {
    this.rethinkToken = rethinkToken;
  }

  @action
  setPaymentId(paymentId: string): void {
    this.paymentId = paymentId;
  }

  @action
  setRethinkAuthorized(status: boolean): void {
    this.rethinkAuthorized = status;
  }

  @action
  resetPaymentData(): void {
    this.rethinkAuthorized = false;
    this.rethinkToken = '';
    this.paymentId = '';
    this.card = {
      token: '',
      cardholderFirstName: '',
      cardholderLastName: '',
      billingPostalCode: '',
    };
    this.rethinkUrl = '';
    this.rethinkWindow = null;
  }

  @action
  resetAuthData(): void {
    this.rethinkAuthorized = false;
    this.rethinkToken = '';
    this.paymentId = '';
    this.rethinkUrl = '';
    this.rethinkWindow = null;
  }
}
