import styled, { css } from 'styled-components';
import { width, space } from 'styled-system';
import { mediaQueries } from 'shared/styles';
export var textInputStyles = css(["appearance:none;background:none;background-color:#fcfdfe;border-radius:2px;box-shadow:none;color:#707478;font-size:13px;border:1px solid #d1d5da;padding:14px 21px 13px;margin:5px auto;", " width:100%;", " box-sizing:border-box;outline:0;&:active,&:focus{border:1px solid #7fb1f4;}&:hover{@media ", "{border:1px solid #7fb1f4;outline:none;}}&::placeholder{color:#aeafaf;}&[disabled]{background-color:#ecf0f3;}", " ", " @media ", "{font-size:16px;}"], space, width, mediaQueries.desktop, function (props) {
  return props.type === 'number' && "\n    -moz-appearance: textfield;\n    ::-webkit-outer-spin-button,\n    ::-webkit-inner-spin-button {\n      -webkit-appearance: none;\n    }\n  ";
}, function (props) {
  return props.disabled && "\n    background-color: whitesmoke;\n    border-color: whitesmoke;\n    box-shadow: none;\n    color: #7a7a7a;\n    cursor: not-allowed;\n  ";
}, mediaQueries.largePhone);
export default styled.input.withConfig({
  displayName: "text-input",
  componentId: "en9akj-0"
})(["", ""], textInputStyles);