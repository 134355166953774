/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import uuid from 'uuid';

import { useModals } from 'components/modals/use-modals';
import { getComponentDisplayName } from 'utils/get-component-display-name';
import { useContainerProps, useCloseOnRouteChange } from './utils';
import { UseModalControlsReturn, ModalComponent, WithModalControlsHookReturn } from './with-modal-controls-hook.types';

export function withModalControlsHook<OnOpenDataValue = undefined, OnCloseResolvedValue = undefined>(
  Component: ModalComponent<OnOpenDataValue, OnCloseResolvedValue>
): WithModalControlsHookReturn<OnOpenDataValue, OnCloseResolvedValue> {
  const useModalControls = makeModalControlsHook<OnOpenDataValue, OnCloseResolvedValue>();

  const EnhancedModalComponent = observer((): JSX.Element | null => {
    const containerProps = useContainerProps();
    const { isOpen, close, data } = useModalControls();

    return isOpen ? <Component containerProps={containerProps} open={isOpen} onClose={close} data={data} /> : null;
  });

  EnhancedModalComponent.displayName = `WithModalControls(${getComponentDisplayName(Component)})`;

  return [EnhancedModalComponent, useModalControls];
}

function makeModalControlsHook<OnOpenDataValue, OnCloseResolvedValue>(): () => UseModalControlsReturn<
  OnOpenDataValue,
  OnCloseResolvedValue
> {
  const modalName = uuid.v4();

  function useModalControls(): UseModalControlsReturn<OnOpenDataValue, OnCloseResolvedValue> {
    const { openModal, closeModal, activeModals } = useModals();

    const isOpen = activeModals.has(modalName);
    const data = activeModals.get(modalName)?.data as OnOpenDataValue;

    const handleOpen = useCallback(
      (args: OnOpenDataValue) => openModal<OnOpenDataValue, OnCloseResolvedValue>(modalName, args),
      [openModal]
    );

    const handleClose = useCallback((args: OnCloseResolvedValue) => closeModal(modalName, args), [closeModal]);

    useCloseOnRouteChange({ modalName, activeModals });

    return {
      isOpen,
      open: handleOpen,
      close: handleClose,
      data,
    };
  }

  return useModalControls;
}
