import _ from 'lodash';
import { useRouter } from 'next/router';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { getCategoryOptionsForRetailer } from 'shared/helpers/products';

export function getCategory(categoryName, dispensary) {
  const category = _.find(getCategoryOptionsForRetailer(dispensary), { key: categoryName });

  if (!category) {
    return null;
  }

  return category;
}

export function useDispensaryCategory() {
  const router = useRouter();
  const { dispensary } = useDispensary();
  return getCategory(_.toLower(router.query.category), dispensary);
}
