import { LEGACY_AWS_SOURCE, IMGIX_SOURCE } from 'shared/constants';
import { buildExternalTemplateRoute } from 'shared/helpers/route-helpers';

type HeadNode = {
  tag: 'script' | 'style';
  type: 'application/ld+json' | 'text/css';
  content: string;
};

type EmbedHrefParams = {
  embeddedPath: string;
  embeddedQueryParams: URLSearchParams;
};

function getEmbeddedHrefParams(href: string, dispensaryCName: string): EmbedHrefParams {
  const embedPath = `/embedded-menu/${dispensaryCName}/`;
  const embeddedPathWithParams = href.split(embedPath)[1] ?? href.split(embedPath)[0];

  const queryParamStartIndex = embeddedPathWithParams.indexOf('?');

  const paramsString =
    queryParamStartIndex && queryParamStartIndex > -1
      ? embeddedPathWithParams.slice(queryParamStartIndex, embeddedPathWithParams.length)
      : '';

  const embeddedPath = embeddedPathWithParams.slice(
    0,
    paramsString ? queryParamStartIndex : embeddedPathWithParams.length
  );
  const embeddedQueryParams = new URLSearchParams(paramsString);

  return {
    embeddedPath,
    embeddedQueryParams,
  };
}

function getParsedQueryParams(
  embeddedPath: string,
  embedQueryParams: URLSearchParams,
  hasChainLocations: boolean,
  dispensaryCName: string
): URLSearchParams {
  const parsedQueryParams = new URLSearchParams();

  const isCategoryPageLink = embeddedPath.includes('products') && embeddedPath.split('/').length === 2;

  const isProductPageLink = embeddedPath.split('/')[0] === 'product' && embeddedPath.split('/').length === 2;

  if (!embeddedPath.includes('menu') && !isCategoryPageLink && !isProductPageLink && embeddedPath) {
    parsedQueryParams.append(`dtche[path]`, embeddedPath);
  }

  if (isProductPageLink && embeddedPath) {
    const productNameValue = embeddedPath.split('/')[1];
    parsedQueryParams.append(`dtche[product]`, productNameValue);
  }

  if (isCategoryPageLink && embeddedPath) {
    const categoryValue = embeddedPath.split('/')[1];
    parsedQueryParams.append(`dtche[category]`, categoryValue);
  }

  if (embedQueryParams.toString().length) {
    embedQueryParams.forEach((value, key) => {
      parsedQueryParams.append(`dtche[${key}]`, value);
    });
  }

  if (!embeddedPath.includes('locations') && hasChainLocations) {
    parsedQueryParams.append(`dtche[location]`, dispensaryCName);
  }

  return parsedQueryParams;
}

function parseLinks(
  links: NodeListOf<Element>,
  rootPath: string,
  dispensaryCName: string,
  hasChainLocations: boolean
): void {
  links.forEach((linkElement: HTMLAnchorElement | HTMLDivElement) => {
    const embeddedHref = linkElement.getAttribute(`href`);

    if (embeddedHref) {
      const { embeddedPath, embeddedQueryParams } = getEmbeddedHrefParams(embeddedHref, dispensaryCName);
      const hasNoFilterParams = !embeddedQueryParams.get(`weight`) && !embeddedQueryParams.get(`sortby`);

      if (hasNoFilterParams) {
        const parsedQueryParams = getParsedQueryParams(
          embeddedPath,
          embeddedQueryParams,
          hasChainLocations,
          dispensaryCName
        );

        const parsedHref = `${rootPath}${parsedQueryParams.toString()}`;
        linkElement.setAttribute(`href`, parsedHref);
      } else {
        linkElement.removeAttribute(`href`);
      }
    }
  });
}

function parseStructuredData(data: string, dispensaryCName: string, embeddedMenuUrl: string): string {
  if (!data) {
    return '';
  }

  const regex = new RegExp(LEGACY_AWS_SOURCE, `g`);
  const jsonData = JSON.parse(data.replace(regex, IMGIX_SOURCE));

  if (jsonData?.url) {
    jsonData.url = embeddedMenuUrl;
  }

  if (jsonData?.offers?.url) {
    const { embeddedPath, embeddedQueryParams } = getEmbeddedHrefParams(jsonData.offers.url, dispensaryCName);

    const hasChainLocations = getHasChainLocations();

    const parsedQueryParams = getParsedQueryParams(
      embeddedPath,
      embeddedQueryParams,
      hasChainLocations,
      dispensaryCName
    );

    jsonData.offers.url = `${embeddedMenuUrl}?${parsedQueryParams.toString()}`;
  }

  return JSON.stringify(jsonData);
}

function getHasChainLocations(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(`chainLocations`);
}

export function parseBody(body: HTMLElement | null, dispensaryCName: string, embeddedMenuUrl: string): string | null {
  if (!body) {
    return null;
  }

  const links = body.querySelectorAll(`a[href^="/embedded-menu"], div[href^="/embedded-menu"]`);
  const rootPath = `${embeddedMenuUrl}?`;
  const hasChainLocations = getHasChainLocations();

  parseLinks(links, rootPath, dispensaryCName, hasChainLocations);

  return body.innerHTML;
}

export function parseHead(
  head: HTMLHeadElement | null,
  stylesheets: StyleSheetList,
  dispensaryCName: string,
  embeddedMenuUrl: string
): HeadNode[] {
  const headNodes: HeadNode[] = getCssNodes(stylesheets);

  if (head) {
    const structuredDatas = head.querySelectorAll(`script[type="application/ld+json"]`);
    structuredDatas.forEach((node) => {
      headNodes.push({
        tag: `script`,
        type: `application/ld+json`,
        content: parseStructuredData(node.innerHTML, dispensaryCName, embeddedMenuUrl),
      });
    });
  }

  return headNodes;
}

function getCssNodes(stylesheets: StyleSheetList): HeadNode[] {
  const headNodes: HeadNode[] = [];

  Array.from(stylesheets)
    .filter((style) => style.href === null)
    .forEach((style) => {
      let css = '';

      Array.from(style.cssRules).forEach((rule) => {
        css += rule.cssText;
      });

      headNodes.push({
        tag: `style`,
        type: `text/css`,
        content: css.trim(),
      });
    });

  return headNodes;
}

export function getCanonicalUrl(iframeParentUrl: string, isSpecialPage: boolean, isSearchPage: boolean): string | null {
  if (isSpecialPage) {
    const { href } = buildExternalTemplateRoute({
      routeRoot: iframeParentUrl,
      specials: true,
      brands: false,
      category: false,
      product: false,
      subcategory: false,
    });

    // Need to encode the query params
    const url = new URL(href);

    return `${url.origin}${url.pathname}?${url.searchParams.toString()}`;
  }

  if (isSearchPage) {
    return iframeParentUrl;
  }

  return null;
}
