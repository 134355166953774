import styled from 'styled-components';
import { space, width } from 'styled-system';
import { mediaQueries } from 'shared/styles';
export var NotifyBlock = styled.div.withConfig({
  displayName: "notify-block__NotifyBlock",
  componentId: "sc-1yzh5oi-0"
})(["display:flex;background-color:rgb(239,247,239);margin:0px 0px 20px 0px;border:1px solid rgb(191,222,210);width:100%;align-items:center;justify-content:flex-start;color:#6d747b;font-size:13px;width:400px;padding:10px 19px;margin-bottom:25px;line-height:16px;button{font-size:13px;}@media ", "{width:100%;justify-content:center;display:inline-block;button{display:inline-block;}}"], mediaQueries.largePhone);
export var WhiteNotifyBlock = styled(NotifyBlock).withConfig({
  displayName: "notify-block__WhiteNotifyBlock",
  componentId: "sc-1yzh5oi-1"
})(["background-color:white;border:none;text-align:center;align-items:center;justify-content:center;line-height:15px;padding:0;", ""], space);
export var YellowNotifyBlock = styled(NotifyBlock).withConfig({
  displayName: "notify-block__YellowNotifyBlock",
  componentId: "sc-1yzh5oi-2"
})(["background-color:#fffdeb;border:1px solid #d8d5ba;text-align:", ";width:100%;font-size:12px;padding:11px 14px 11px 19px;color:#75725b;border-radius:4px;", ""], function (_ref) {
  var align = _ref.align;
  return align || 'left';
}, space);
export var RedNotifyBlock = styled(NotifyBlock).withConfig({
  displayName: "notify-block__RedNotifyBlock",
  componentId: "sc-1yzh5oi-3"
})(["background-color:#fff0f0;border:1px solid #d8c1c1;width:100%;padding:11px 14px 11px 19px;color:#986c6c;border-radius:4px;", " ", ""], space, width);