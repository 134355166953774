'use strict';

var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');

Object.defineProperty(exports, '__esModule', {
  value: true,
});
Object.defineProperty(exports, 'Link', {
  enumerable: true,
  get: function get() {
    return _Link['default'];
  },
});
Object.defineProperty(exports, 'NextStaticProvider', {
  enumerable: true,
  get: function get() {
    return _NextStaticProvider['default'];
  },
});

var _Link = _interopRequireDefault(require('./Link'));

var _NextStaticProvider = _interopRequireDefault(require('./NextStaticProvider'));
