/* eslint-disable max-len */
import React from 'react';

const SvgEmptyGoodsIcon = (props) => (
  <svg width='189' height='141' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
    <path d='M185.533 0v121.417H1.933V0h183.6z' fill='#EAEFF2' />
    <rect x='80.234' y='28.531' width='21.828' height='21.871' rx='2.558' fill='#fff' />
    <rect x='112.976' y='28.531' width='21.828' height='21.871' rx='2.558' fill='#fff' />
    <rect x='80.234' y='59.379' width='21.828' height='21.871' rx='2.558' fill='#E4EDF0' />
    <rect x='47.492' y='28.531' width='21.828' height='21.871' rx='2.558' fill='#fff' />
    <rect x='47.492' y='59.379' width='21.828' height='21.871' rx='2.558' fill='#E4EDF0' />
    <rect x='112.976' y='61.34' width='21.828' height='21.871' rx='2.558' fill='#fff' />
    <rect x='112.976' y='90.043' width='21.828' height='21.871' rx='2.558' fill='#E4EDF0' />
    <rect x='144.8' y='28.531' width='21.828' height='21.871' rx='2.558' fill='#fff' />
    <rect x='144.8' y='61.34' width='21.828' height='21.871' rx='2.558' fill='#fff' />
    <rect x='144.8' y='92' width='21.828' height='21.871' rx='2.558' fill='#E1EAEE' />
    <path
      d='M179.221 9.002a3.943 3.943 0 00-3.558-1.647H49.669c-2.416 0-4.284 1.563-3.474 3.26a3.944 3.944 0 003.553 1.646h125.993c2.421 0 4.292-1.563 3.48-3.26zM11.725 13.49a3.676 3.676 0 003.672-3.68 3.676 3.676 0 00-3.672-3.681 3.676 3.676 0 00-3.672 3.68 3.676 3.676 0 003.672 3.68zM22.74 13.49a3.676 3.676 0 003.673-3.68 3.676 3.676 0 00-3.672-3.681 3.676 3.676 0 00-3.672 3.68 3.676 3.676 0 003.672 3.68zM33.757 13.49a3.676 3.676 0 003.672-3.68 3.676 3.676 0 00-3.672-3.681 3.676 3.676 0 00-3.672 3.68 3.676 3.676 0 003.672 3.68z'
      fill='#fff'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.235 34.178h-8.88a2.54 2.54 0 01-2.538-2.542 2.54 2.54 0 012.538-2.542h8.88a2.54 2.54 0 012.538 2.542 2.54 2.54 0 01-2.538 2.542zm2.536 11.44H17.355a2.54 2.54 0 01-2.538-2.543 2.54 2.54 0 012.538-2.542h11.416c.907 0 1.744.485 2.198 1.271a2.546 2.546 0 010 2.542 2.537 2.537 0 01-2.198 1.271zM17.355 68.918h11.416a2.54 2.54 0 002.538-2.542 2.54 2.54 0 00-2.538-2.542H17.355a2.54 2.54 0 00-2.538 2.542 2.54 2.54 0 002.538 2.542zm8.88-11.864h-8.88a2.54 2.54 0 01-2.466-2.541 2.54 2.54 0 012.466-2.542h8.88a2.54 2.54 0 012.466 2.542 2.54 2.54 0 01-2.466 2.54zm-8.88 23.304h8.88a2.54 2.54 0 002.538-2.543 2.54 2.54 0 00-2.538-2.542h-8.88a2.54 2.54 0 00-2.538 2.542 2.54 2.54 0 002.538 2.543z'
      fill='#fff'
    />
    <path
      opacity='.3'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M68.357 47h21.139c.704 0 1.275.638 1.275 1.424v4.451h-3.948v9.335c0 1.334-.934 2.415-2.087 2.415h-11.62c-1.152 0-2.086-1.083-2.086-2.415v-9.335h-3.95v-4.45c0-.787.571-1.425 1.277-1.425zm50.857 0H99.838c-.646 0-1.17.638-1.17 1.424v4.451h21.717v-4.45c0-.787-.524-1.425-1.171-1.425zM71.03 88.125h15.793v9.774c0 1.091-.934 1.976-2.087 1.976h-11.62c-1.152 0-2.086-.885-2.086-1.976v-9.774zm18.466-7.833H68.357c-.706 0-1.277.638-1.277 1.425v4.45h23.69v-4.45c0-.787-.57-1.425-1.274-1.425zm11.146 7.833h15.794v9.774c0 1.091-.934 1.976-2.087 1.976h-11.62c-1.152 0-2.087-.885-2.087-1.976v-9.774zm18.572-7.833H99.838c-.646 0-1.17.638-1.17 1.425v4.45h21.717v-4.45c0-.787-.524-1.425-1.171-1.425z'
      fill='#BDCCCE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M126.352 52.263v.254h.002l.565 1.336c3.25 26.653 8.205 76.33 8.154 78.742l-.01.572h-99.57v-.587c4.094-37.612 13.07-90.244 26.112-95.32l.028 1.414 2.065-1.467h60.875c5.311 0 9.631 1.596 9.631 6.437-.116 4.344-3.427 7.982-7.852 8.62zm-65.939-12.72c-.24-.004-.542-.004-.395.006l.33.04.065-.046z'
      fill='#fff'
    />
    <path d='M61.16 36.231h1.97c.066-.01-2.04 0-1.97 0z' fill='#E4EDF0' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.288 47.888c0 5.787 6.764 6.944 13.528 6.944l-.47-7.136a8.705 8.705 0 002.726-6.751c0-4.629-4.509-5.788-7.422-5.69-2.915.097-8.361 6.848-8.361 12.633zm72.731 71.261a54.474 54.474 0 002.468-19.275l5.743 33.293h-15.794a41.476 41.476 0 007.583-14.018z'
      fill='#C8CFD1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.777 135.777c0 1.441.91 3.266 24.562 3.266l93.891-.055-6.169-5.82H44.185c-20.657 0-30.408 1.169-30.408 2.609zm161.884 2.616c0-1.443-.675-3.267-18.223-3.267l-13.364.055v5.82h9.025c15.328 0 22.562-1.167 22.562-2.608z'
      fill='#D7E4EB'
    />
    <path
      d='M134.661 135.125c-17.548 0-18.225 1.825-18.225 3.268 0 1.44 7.236 2.607 22.562 2.607h9.025v-5.82l-13.362-.055z'
      fill='#D7E4EB'
    />
    <path
      d='M56.84 47c1.913 4.066 5.242-2.548 6.462 2.428 5.057 20.63 9.702 71.603 9.702 83.739-5.438-.102-14.304-.204-19.743-.308 0 0 1.79-81.543 3.578-85.859z'
      fill='#EAEFF2'
    />
    <path d='M63.133 57.072v-4.197h63.174l2.106 5.875-65.28-1.678z' fill='#BCCAD2' />
    <path
      d='M73.202 133.166a1.781 1.781 0 01-1.762-1.615c-.072-.707-7.059-70.95-10.18-80.206a1.805 1.805 0 01.333-1.772 1.763 1.763 0 013.025.617c3.248 9.646 10.063 78.1 10.35 81.006a1.786 1.786 0 01-1.767 1.97z'
      fill='#405D67'
    />
    <path
      d='M136.13 135.121a1.46 1.46 0 01-.969-.278H39.777a4.319 4.319 0 01-3.142-1.349 4.163 4.163 0 01-1.128-3.175c1.102-14.444 7.377-86.91 22.803-95.069l1.74 3.176c-3.121 1.649-9.204 9.4-14.922 42.763-3.762 21.941-5.625 45-5.961 49.399a.609.609 0 00.612.646h94.493l-.023-.239c-3.001-30.997-5.81-57.753-8.343-79.524l3.647-.41c2.538 21.795 5.347 48.581 8.352 79.593l.057.578h.02l.145 1.647c0 .055.008.109.01.164v.045c.063.485-.078.975-.389 1.352a1.84 1.84 0 01-1.263.663c-.131.014-.245.02-.355.02v-.002z'
      fill='#405D67'
    />
    <path
      d='M126.471 50.917H64.944c-1 0-1.811-.878-1.811-1.96 0-1.08.81-1.957 1.81-1.957h61.528c.999 0 1.809.879 1.809 1.958 0 1.083-.81 1.96-1.809 1.96z'
      fill='#405D67'
    />
    <path
      d='M128.103 107.598h35.893v24.66c0 2.724-2.142 4.933-4.785 4.933h-26.323c-2.643 0-4.785-2.209-4.785-4.933v-24.66z'
      fill='#fff'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M132.889 139.042h26.322c3.633-.004 6.577-3.038 6.581-6.782v-24.661c0-1.021-.804-1.849-1.796-1.849h-35.893c-.992 0-1.796.828-1.796 1.849v24.661c.005 3.744 2.948 6.778 6.582 6.782zm-2.993-6.782v-22.811h32.305v22.811c0 1.702-1.338 3.082-2.99 3.082h-26.322c-1.653 0-2.993-1.38-2.993-3.082z'
      fill='#405D67'
    />
    <path
      d='M59.067 36.126c4.902-.108 8.974 3.444 9.098 7.937 0 4.381-2.835 7.935-7.862 7.935h68.253c3.194.141 6.213-1.34 7.854-3.854a7.38 7.38 0 000-8.164c-1.641-2.513-4.66-3.995-7.854-3.854H60.303'
      fill='#EAEFF2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M60.301 54.532h68.255c3.859.143 7.494-1.661 9.473-4.704a8.954 8.954 0 000-9.87c-1.976-3.039-5.614-4.843-9.473-4.7H60.301c-.118 0-.236.01-.355.03-.291-.02-.585-.03-.883-.03-1.024 0-1.857.76-1.857 1.7s.833 1.7 1.857 1.7c3.876-.1 7.113 2.687 7.242 6.237 0 2.875-1.573 6.235-6.004 6.235-1.024 0-1.856.762-1.856 1.702 0 .938.832 1.7 1.856 1.7zm68.255-3.404H67.947a9.77 9.77 0 002.073-6.235c-.006-2.301-.934-4.52-2.611-6.237h61.147c2.533-.147 4.947 1.01 6.262 3a5.801 5.801 0 010 6.474c-1.315 1.99-3.729 3.145-6.262 2.997z'
      fill='#405D67'
    />
    <path
      d='M128.627 95.715h36.818c1.354 0 2.454 1.022 2.454 2.284v7.997h-41.726V98c0-1.263 1.099-2.285 2.454-2.285z'
      fill='#F1F9FC'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M126.173 107.708h41.726c.481.003.946-.176 1.302-.501.34-.311.535-.75.538-1.212v-7.996c0-2.209-1.924-3.999-4.294-3.999h-36.818c-2.371 0-4.294 1.79-4.294 4v7.995c0 .946.824 1.713 1.84 1.713zm39.884-3.427h-38.042v-6.282c0-.317.273-.572.612-.572h36.818c.338 0 .612.255.612.572v6.282z'
      fill='#405D67'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M136.177 107.708c-1.089 0-1.973-.767-1.973-1.713v-10.28c0-.949.884-1.715 1.973-1.715 1.091 0 1.975.766 1.975 1.715v10.278c0 .948-.884 1.717-1.975 1.717v-.002zm9.872 0c-1.09 0-1.975-.767-1.975-1.713v-10.28c0-.949.885-1.715 1.975-1.715 1.091 0 1.974.766 1.974 1.715v10.278c0 .948-.883 1.717-1.974 1.717v-.002zm9.871-1.713c0 .946.883 1.713 1.975 1.713v.002c1.089 0 1.973-.769 1.973-1.717V95.715c0-.949-.884-1.715-1.973-1.715-1.092 0-1.975.766-1.975 1.715v10.28z'
      fill='#405D67'
    />
    <path d='M130.024 119.262h16.259v8.225h-16.259v-8.225z' fill='#9CAAB4' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M128.28 129.25h19.743V117.5H128.28v11.75zm16.259-3.525h-12.774v-4.7h12.774v4.7z'
      fill='#4F5D68'
    />
    <path d='M129.867 110.809v-2.797h32.334v3.96l-32.334-1.163z' fill='#BECBCF' />
    <path d='M60.37 53a2.566 2.566 0 01-2.172-1.03 2.42 2.42 0 01-.304-2.336l8.021-2.142L60.371 53z' fill='#405D67' />
    <path
      d='M60.369 54.833a4.905 4.905 0 01-3.583-1.602 4.434 4.434 0 01-.73-4.001c.158-.671.674-1.2 1.34-1.373l9.684-2.814c.824-.223.033.797.465 1.512a1.8 1.8 0 01-.285 2.22l-4.127 6.058c-.355.354-2.259 0-2.764 0zM53.512 133.166c-1.224 0-2.217-.781-2.217-1.744-.009-1.949-.166-47.87 1.489-61.308.077-.62.57-1.16 1.29-1.418a2.738 2.738 0 012.209.17c.644.361.993.966.917 1.585-1.634 13.262-1.468 60.485-1.468 60.959 0 .963-.994 1.743-2.22 1.743v.013z'
      fill='#405D67'
    />
  </svg>
);

export default SvgEmptyGoodsIcon;
