const NextI18Next = require('./src/next-i18next').default;

const ENGLISH = 'en';
const SPANISH = 'es';
// const FRENCH = 'fr';

const i18n = new NextI18Next({
  defaultLanguage: ENGLISH,
  otherLanguages: [SPANISH],
});

/*
const i18n = new NextI18Next({
  defaultLanguage: ENGLISH,
  otherLanguages: [SPANISH, FRENCH],
  localeSubpaths: {
    [ENGLISH]: ENGLISH,
    [SPANISH]: SPANISH,
    [FRENCH]: FRENCH,
  },
  saveMissing: true,
  // debug: true,
});
*/

module.exports = i18n;
