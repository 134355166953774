import React from 'react';
import styled from 'styled-components';

import { ProductCardsGrid } from 'src/components/product-card';
import Skeleton from 'src/components/core/loading-skeletons/loading-skeleton';
import {
  productCardContainerStyles,
  productCardImageStyles,
} from 'src/components/product-card/product-card.components';
import { listViewProductCardImageStyles } from 'src/components/product-card/list-view-product-card';

export function ProductCardsGridLoadingState(): JSX.Element {
  return (
    <ProductCardsGrid>
      {Array.from({ length: 30 }, (_, i) => (
        <Container key={i}>
          <ProductImageLoadingSkeleton />
          <Skeleton width='27%' height={8} />
          <Skeleton width='85%' height={10} />
          <Skeleton width='59%' height={8} />
          <HorizontalFlexContainer>
            <Skeleton width={50} height={8} mr={11} />
            <Skeleton width={50} height={8} />
          </HorizontalFlexContainer>
        </Container>
      ))}
    </ProductCardsGrid>
  );
}

const Container = styled.div`
  ${productCardContainerStyles}
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0;
  }
`;

const ProductImageLoadingSkeleton = styled(Skeleton)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 160px;
    width: 160px;
  }
  ${productCardImageStyles}
  ${listViewProductCardImageStyles}
  border-radius: 12px;
`;

const HorizontalFlexContainer = styled.div`
  display: flex;
`;
