import { useEffect, useRef } from 'react';

import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import * as utils from 'src/utils/bots';
import { useApolloNetworkStatus } from 'utils/apollo/network-status';
import { getCanonicalUrl, parseBody, parseHead } from './use-seo-menu.utils';

export function useSeoMenu(): void {
  const {
    isEmbedded,
    embeddedEventDispatcher,
    iframeParentUrl,
    iframeParentHref,
    isSpecialPage,
    onSearchPage,
  } = useUI();
  const { dispensary } = useDispensary();
  const dispensaryCName = dispensary?.cName;
  const seoSettingsEnabled = dispensary?.seoSettings?.enabled !== false;
  const { numPendingQueries } = useApolloNetworkStatus();

  const didRun = useRef(false);

  const isSearchEngineBot = utils.isSearchEngineBot();

  useEffect(() => {
    if (!isEmbedded || !isSearchEngineBot || !dispensaryCName || !iframeParentUrl || !seoSettingsEnabled) {
      return;
    }

    if (numPendingQueries > 0) {
      return;
    }

    const handleSeoMenu = (): void => {
      if (didRun.current) {
        return;
      }

      const payload = {
        title: window.document.title,
        description: window.document.head.querySelector(`meta[name="description"]`)?.getAttribute(`content`),
        body: parseBody(window.document.getElementById('__next'), dispensaryCName, iframeParentUrl),
        head: parseHead(window.document.head, window.document.styleSheets, dispensaryCName, iframeParentUrl),
        canonicalUrl: getCanonicalUrl(iframeParentUrl, isSpecialPage, onSearchPage),
      };

      embeddedEventDispatcher(`seobot:elevate`, payload);
      didRun.current = true;
    };

    /*
      The hook will rerender on any change to numPendingQueries. a rerender will cancel the prev timer.
    */

    const timer = setTimeout(() => {
      handleSeoMenu();
    }, 600);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timer);
    };
  }, [
    dispensaryCName,
    embeddedEventDispatcher,
    iframeParentHref,
    iframeParentUrl,
    isEmbedded,
    isSearchEngineBot,
    isSpecialPage,
    numPendingQueries,
    onSearchPage,
    seoSettingsEnabled,
  ]);
}
