import { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';

import useStores from 'shared/hooks/use-stores';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';
import { LOCAL_STORAGE_STORED_CART_KEY } from 'shared/constants';
import { tracker } from '..';

export function useCheckoutStartedMonitor(): void {
  const stores = useStores();
  const { Cart } = stores;
  const isCartReady = useObserver(() => Cart.isReady);
  const { checkoutToken, dispensary, costBreakdown, cart } = Cart;
  const storedCart = getPersistedValue(LOCAL_STORAGE_STORED_CART_KEY, {});
  const { currentCheckout } = storedCart;
  const persistedDispensaryId = currentCheckout?.dispensaryId;
  const persistedCheckoutToken = currentCheckout?.checkoutToken;

  const { total, subtotal: revenue, tax } = costBreakdown;
  const { discount = null } = costBreakdown.receipt?.summary ?? {};
  const currency = dispensary?.location.country === 'Canada' ? 'CAD' : 'USD';

  useEffect(() => {
    if (!checkoutToken || !dispensary?.id) {
      return;
    }

    const isSameCheckoutSession = persistedDispensaryId === dispensary?.id && persistedCheckoutToken === checkoutToken;

    if (isSameCheckoutSession) {
      return;
    }
    setPersistedValue(LOCAL_STORAGE_STORED_CART_KEY, {
      ...storedCart,
      currentCheckout: {
        ...currentCheckout,
        dispensaryId: dispensary?.id,
        checkoutToken,
      },
    });
  }, [checkoutToken, currentCheckout, dispensary?.id, persistedCheckoutToken, persistedDispensaryId, storedCart]);

  useEffect(() => {
    if (!isCartReady || !checkoutToken || !dispensary?.id || !costBreakdown || !cart) {
      return;
    }

    const isSameCheckoutSession = persistedDispensaryId === dispensary?.id && persistedCheckoutToken === checkoutToken;

    if (isSameCheckoutSession) {
      return;
    }

    // eslint-disable-next-line lodash/prefer-lodash-method
    const products = Object.values(cart).map((cartItem: any) => {
      const { product } = cartItem;
      return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        product_id: product.id,
        category: product.type,
        name: product.name,
        brand: product.brandName,
        variant: cartItem.option,
        price: cartItem.price,
        quantity: cartItem.quantity,
      };
    });

    tracker.checkoutStarted({ total, revenue, tax, discount, currency, products });
  }, [
    cart,
    checkoutToken,
    costBreakdown,
    currency,
    discount,
    dispensary?.id,
    isCartReady,
    persistedCheckoutToken,
    persistedDispensaryId,
    revenue,
    tax,
    total,
  ]);
}
