import React, { forwardRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import useCart from 'hooks/use-cart';
import useSearchPreferences from 'hooks/use-search-preferences';
import useFormattedAddress from 'hooks/use-formatted-address';
import useColors from 'hooks/use-colors';
import useTranslation from 'hooks/use-translation';
import routeTo from 'utils/route-to';
import ROUTES from 'routes';

import { ModalContainer, ModalExitIcon, PlacesAutocompleteInput } from 'components/core';
import PinIcon from 'assets/pin-icon';
import { indexToOrderType } from 'shared/constants';

export const AddressRequiredModal = forwardRef((props, ref) => {
  const { containerProps, onClose } = props;
  const { t, Trans } = useTranslation();
  const [address] = useFormattedAddress();
  const cart = useCart();
  const SearchPreferences = useSearchPreferences();
  const orderType = useObserver(() => SearchPreferences.orderType);
  const colors = useColors();
  const router = useRouter();

  function handleChangeAddress(location) {
    cart.address = location;

    const routeOnClose = _.includes(router.pathname, ROUTES.SHOP) ? ROUTES.SHOP : ROUTES.DISPENSARIES;
    routeTo({ url: routeOnClose });
    onClose();
  }

  function handleChangeOrderType(event, v) {
    SearchPreferences.setOrderType(indexToOrderType[v]);
  }

  return (
    <StyledModalContainer ref={ref} width={505} padding='32px 46px 35px' {...containerProps}>
      <ModalExitIcon onClick={onClose} />
      <b>{t('addressRequired.startShopping', 'Start Shopping')}</b>
      <Text>
        <Trans i18nKey='addressRequired.enterYourAddress'>
          Enter your address or city below and we’ll show you the best products and dispensaries in your area.
        </Trans>
      </Text>

      <InputHolder>
        <PlacesAutocompleteInput
          placeholder={t('addressRequired.getStarted', 'Enter your address or city to get started...')}
          value={address}
          onChange={handleChangeAddress}
          startAdornment={<StyledPinIcon fill={colors.grey[70]} height='22px' />}
          hasOrderTypeSelection
          orderType={orderType}
          onChangeOrderType={handleChangeOrderType}
          variant='secondary'
        />
      </InputHolder>
    </StyledModalContainer>
  );
});

const InputHolder = styled.div`
  padding: 16px 0 20px;
  width: 100%;
  position: relative;
  b,
  span {
    white-space: pre;
  }
  .dropdown {
    margin: 0 auto;
    height: auto;
    overflow: auto;
    position: absolute;
    max-height: 300px;
    width: 100%;
    ${({ theme }) => theme.breakpoints.down(`xs`)} {
      max-width: 413px;
    }
  }
`;

const StyledPinIcon = styled(PinIcon)`
  margin-left: 12px;
`;

const StyledModalContainer = styled(ModalContainer)`
  border-radius: 28px;
`;

const Text = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey[30]};
  width: 100%;
  max-width: 306px;
  margin: 12px auto;
`;
