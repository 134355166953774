import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { Option } from 'components/core';
import useTranslation from 'hooks/use-translation';
import { FormSelect } from './form-input';
import useKioskTerminals from './use-kiosk-terminals';
import { InputContainer, InputLabel } from './components';

export default function DirectOrders(props): JSX.Element | null {
  const { dispensary } = props;
  const kioskTerminals = useKioskTerminals(dispensary);
  const { t } = useTranslation();

  if (_.isEmpty(kioskTerminals)) {
    return null;
  }

  const defaultValue = kioskTerminals[0].id;

  return (
    <Container>
      <InputContainer>
        <InputLabel>{t('kiosk.pickup-at', 'Pickup at:')}</InputLabel>
        <FormSelect name='destinationTerminal' defaultValue={defaultValue}>
          <Option value='' disabled>
            {t('kiosk.select-terminal', 'Select Terminal')}
          </Option>

          {_.map(kioskTerminals, (terminal) => (
            <Option key={terminal.id} value={terminal.id}>
              {terminal.nickname ?? terminal.id}
            </Option>
          ))}
        </FormSelect>
      </InputContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 45px;
  margin-bottom: 30px;
`;
