/* eslint-disable lodash/prefer-lodash-method */
import React, { useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { ThemeProvider as StyledProvider } from 'styled-components';
import { ThemeProvider as MuiProvider } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';

import { RadiusOption, TextTransformOption } from 'types/graphql';

import {
  getStyledTheme,
  getMuiTheme,
  getColors,
  isColorDark,
  shadows,
  fonts as baseFonts,
  buildScale,
} from 'src/theme';
import { ColorsProvider } from 'contexts/colors';
import useUI from 'hooks/use-ui';
import useCart from 'src/hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import PublicEnv from 'shared/utils/public-env';
import themes from 'theme/demo';

import { defaultTagColors } from 'shared/constants/menu-colors';
import { mergeWithoutNull } from 'src/utils/misc-utils';
import { GlobalFontStyles } from './global-styles';
import {
  getEncodedFonts,
  getFullFontFamily,
  getRadiusStyle,
  getTextTransformStyle,
  useGetWebCustomizationSettings,
} from './theme.utils';

export function ThemeProvider(props) {
  const { children } = props;
  const cart = useCart();
  const useDispensaryHook = useDispensary();
  const UI = useUI();

  let {
    query: { cName },
  } = useRouter();

  const isEmbedded = useObserver(() => UI.isEmbedded);
  const isDutchieMain = useObserver(() => UI.isDutchieMain);
  const isKiosk = useObserver(() => UI.isKiosk);
  const isMobileEcommApp = useObserver(() => UI.isMobileEcommApp);
  const dispensaryFromCart = useObserver(() => cart.dispensary);
  const dispensaryFromHook = useObserver(() => useDispensaryHook.dispensary);
  const dispensary = dispensaryFromCart || dispensaryFromHook;

  const encodedFontsRef = useRef();

  const highPerformanceMode = useDispensaryFlag(`rollout.high-performance-kiosks`, dispensary?.id) && isKiosk;
  const getWebCustomizationSettings = useGetWebCustomizationSettings(dispensary);

  if (!cName && dispensary) {
    cName = dispensary.cName;
  }

  const shouldUseDemoSkins = PublicEnv.appEnv === 'demo' || PublicEnv.appEnv === 'development';

  // memoize the themes so they don't get regenerated
  const stores = useMemo(() => {
    // when we're outside of embedded or don't know what dispensary we're in
    // set theme to default and load from there...
    if (!shouldUseDemoSkins && !dispensary && isEmbedded) {
      // isEmbedded should always have a dispensary, so lets wait for one...
      return null;
    }

    if (!dispensary && isKiosk) {
      // wait for dispensary in kiosk mode too, we need this for high performance mode to *always* work
      return null;
    }

    if (!shouldUseDemoSkins || !isEmbedded || !isMobileEcommApp || !cName) {
      cName = `default`;
    }

    const theme = themes[cName] || themes.default;
    const colors = { ...getColors(), ...theme.colors };

    let customized = {
      colors: {
        buttonsLinks: colors.primaryBlue,
        navBar: colors.primaryGrey,
        staffPickTag: defaultTagColors.staffPick,
        discountTag: defaultTagColors.discount,
      },
      fonts: {
        primary: baseFonts.join(', '),
        secondary: baseFonts.join(', '),
      },
      textTransforms: {
        buttons: getTextTransformStyle(TextTransformOption.upper),
        links: getTextTransformStyle(TextTransformOption.title),
        tags: getTextTransformStyle(TextTransformOption.title),
      },
      radius: getRadiusStyle(RadiusOption.rounded),
      utils: {
        isNavBarDark: false,
      },
    };

    if (!isDutchieMain) {
      const { colors: customColors, fonts: customFonts, textTransforms, radius } = getWebCustomizationSettings();

      const fullFamilies = {
        primary: getFullFontFamily(customFonts.primary),
        secondary: getFullFontFamily(customFonts.secondary),
      };

      customized = mergeWithoutNull(customized, {
        colors: customColors,
        fonts: fullFamilies,
        textTransforms,
        radius,
        utils: {
          isNavBarDark: isColorDark(customColors.navBar),
        },
      });

      encodedFontsRef.current = getEncodedFonts(customFonts);
    }

    const embeddedMenu = { height: theme.banner?.height || 0 };
    const muiTheme = getMuiTheme({ colors, customized, uiVariant: UI.variant, highPerformanceMode });
    const styledTheme = getStyledTheme({
      colors,
      embeddedMenu,
      shadows,
      breakpoints: muiTheme.breakpoints,
      highPerformanceMode,
      spaces: buildScale(4, 20),
      customized,
    });

    return {
      theme,
      colors,
      styledTheme,
      muiTheme,
    };
  }, [cName, isEmbedded, getWebCustomizationSettings]);

  if (!stores) {
    return null;
  }

  return (
    <>
      <ColorsProvider value={stores.colors}>
        <StyledProvider theme={stores.styledTheme}>
          <GlobalFontStyles />
          {!isDutchieMain && encodedFontsRef.current && (
            <>
              <link rel='preconnect' href='https://fonts.googleapis.com' />
              <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
              <link
                id='customFont'
                rel='stylesheet'
                href={`https://fonts.googleapis.com/css2?${encodedFontsRef.current}&display=swap`}
              />
            </>
          )}
          <MuiProvider theme={stores.muiTheme}>{children}</MuiProvider>
        </StyledProvider>
      </ColorsProvider>
    </>
  );
}
