import React from 'react';

function SvgGreenCheck(props) {
  return (
    <svg height={9} width={11} {...props}>
      <path d='M0 4.677l2.177-2.3 1.96 2.25L9.421 0 11 1.694 4.089 9z' fill='#4ca667' fillRule='evenodd' />
    </svg>
  );
}

export default SvgGreenCheck;
