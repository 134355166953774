import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { capitalize, map } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from 'styled-components';

import useTranslation from 'hooks/use-translation';
import useViewport from 'hooks/use-viewport';
import usePaths from 'hooks/use-paths';

import { DesktopOnly, MobileOnly } from 'shared/components';

import ExitIcon from 'assets/exit-icon';
import SvgChevronIcon from 'assets/chevron-icon';
import { TextButton, Link } from 'components/core';
import EmptyLoadingState from 'src/components/empty-loading-state';
import OrderTypeDropdown from 'src/components/order-type-dropdown';
import DiscountToCartSection from 'components/cart/discount-to-cart';

import { useDutchiePay } from 'src/payments/hooks';
import { CartRecommendationsCarousel } from './cart-recommendations-carousel';

import {
  Container,
  Header,
  ShoppingCartHeader,
  CloseButton,
  StyledCartPriceMinimum,
  DetailContainer,
  DispensaryHeaderLink,
  Dispensary,
  DispensaryImage,
  DispensaryName,
  ShoppingText,
  CapsText,
  OrderType,
  OrderTypeSpan,
  DropdownContainer,
  EstimateText,
  MobileCheckoutButtonContainer,
  CheckoutButton,
  Divider,
  ProductsContainer,
  CartItems,
  Footer,
  ContinueShoppingButton,
  FooterSubtotal,
  Subtotal,
  Taxes,
  HeaderSubtotal,
} from './cart-dropdown.styles';

import CartItem from '../item';

import { useCartDropdown } from './use-cart-dropdown';
import { DutchiePayCheckout } from './dutchie-pay-checkout';

const popoverProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

/**
 * @type {
 *  React.FC<{
 *    onClose: () => void;
 *    className?: string;
 *    hideHeader?: boolean;
 *  }>
 * }
 */
export const CartDropdown = observer(({ className, onClose, hideHeader, children }) => {
  const {
    appliedOffers,
    discountToCartApplied,
    dispensary,
    displayOrderTypeSwitch,
    durationEstimates,
    handleCloseOrderTypeDropdown,
    handleGoToCheckout,
    handleOpenOrderTypeDropdown,
    handleQuantityChange,
    handleRemoveItem,
    handleWeightChange,
    hasNotMetMinimum,
    isCalculating,
    isEmbedded,
    isKiosk,
    isOrderTypeDropdownOpen,
    itemCount,
    items,
    menuType,
    minimum,
    showEstimate,
    showShop,
    simplifiedOrderType,
    subtotal,
    taxInclusionNote,
  } = useCartDropdown({ onClose });

  const orderTypeButtonRef = useRef();
  const { customized } = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`xs`));
  const { t } = useTranslation();
  const { height, offset, parentOffset } = useViewport();
  const { href, route } = usePaths({ dispensary });
  const { isDutchiePayEnabledForDispo, isEnrolledDutchiePay } = useDutchiePay();

  return (
    <Container
      isEmbedded={isEmbedded}
      isMobile={isMobile}
      className={className}
      height={height}
      offset={offset}
      parentOffset={parentOffset}
      data-testid='cart-dropdown'
    >
      {!hideHeader && (
        <Header>
          <div data-testid='cart-dropdown-header-left'>
            <ShoppingCartHeader aria-live='assertive'>{t('common.shoppingCart', 'Shopping Cart')}</ShoppingCartHeader>
            {itemCount > 0 && !isMobile && (
              <HeaderSubtotal>
                {t('common.subtotal', 'Subtotal')}:&nbsp;
                {isCalculating ? <em>{t('common.calculating', 'Calculating...')}</em> : `$${subtotal.toFixed(2)}`}
              </HeaderSubtotal>
            )}
          </div>
          <CloseButton aria-label='shopping-cart-close' onClick={onClose} autoFocus data-testid='cart-close-button'>
            <ExitIcon fill={customized.colors.buttonsLinks} height='20px' width='20px' />
            &nbsp;{t('common.close', 'Close')}
          </CloseButton>
        </Header>
      )}

      {hasNotMetMinimum && <StyledCartPriceMinimum isMobile={isMobile} minimumInDollars={minimum} value={subtotal} />}

      {discountToCartApplied &&
        map(appliedOffers, ({ name }) => (
          <DiscountToCartSection
            isMobile={isMobile}
            specialName={name}
            displayTopBorder={hasNotMetMinimum || appliedOffers.length > 1}
          />
        ))}
      <EmptyLoadingState isEmpty={itemCount === 0} page='cart' onClose={onClose} onShop={showShop}>
        {dispensary && (
          <>
            <DetailContainer>
              <Link href={href} route={route}>
                <DispensaryHeaderLink href={href}>
                  <Dispensary>
                    <DispensaryImage src={dispensary.logoImage} alt={dispensary.name} />

                    <ShoppingText>
                      <CapsText>{t('common.shoppingAt', 'Shopping at')}:</CapsText>

                      <DispensaryName title={dispensary.name}>{dispensary.name}</DispensaryName>
                    </ShoppingText>
                  </Dispensary>
                </DispensaryHeaderLink>
              </Link>
              <OrderType showEstimate={showEstimate}>
                <div>
                  <OrderTypeSpan role='region' aria-live='polite'>
                    {capitalize(simplifiedOrderType)}
                  </OrderTypeSpan>
                  {displayOrderTypeSwitch && (
                    <DropdownContainer>
                      <>
                        &nbsp;•&nbsp;
                        <TextButton
                          aria-controls='order-type-dropdown'
                          aria-haspopup
                          onClick={handleOpenOrderTypeDropdown}
                          ref={orderTypeButtonRef}
                          pl={0}
                        >
                          {t('common.edit', 'Edit')}
                        </TextButton>
                        <OrderTypeDropdown
                          id='order-type-dropdown'
                          anchorEl={orderTypeButtonRef.current}
                          getContentAnchorEl={null}
                          open={isOrderTypeDropdownOpen}
                          onClose={handleCloseOrderTypeDropdown}
                          {...popoverProps}
                        />
                      </>
                    </DropdownContainer>
                  )}
                </div>
                {showEstimate && durationEstimates && (
                  <p>
                    {!isMobile && `${t('common.estimate', 'Estimate')}: `}
                    <EstimateText>
                      {durationEstimates[simplifiedOrderType]?.lowInMinutes} -{' '}
                      {durationEstimates[simplifiedOrderType]?.highInMinutes} {t('common.mins', 'mins')}
                    </EstimateText>
                  </p>
                )}
              </OrderType>
            </DetailContainer>
            <MobileOnly display='block'>
              {isDutchiePayEnabledForDispo ? (
                <DutchiePayCheckout
                  isEmbedded={isEmbedded}
                  isMobile={isMobile}
                  handleGoToCheckout={handleGoToCheckout}
                  taxInclusionNote={taxInclusionNote}
                  subtotal={subtotal}
                  isEnrolledDutchiePay={isEnrolledDutchiePay}
                />
              ) : (
                <MobileCheckoutButtonContainer isEmbedded={isEmbedded}>
                  <CheckoutButton
                    className='mobile-header'
                    variant='primary'
                    size='large'
                    onClick={handleGoToCheckout}
                    data-testid='cart-checkout-button'
                  >
                    {t('common.proceedToCheckout', 'Proceed to checkout')} - ${subtotal.toFixed(2)}
                  </CheckoutButton>

                  {isEmbedded && (
                    <ContinueShoppingButton onClick={onClose}>
                      {t('common.continueShopping', 'Continue shopping')}
                    </ContinueShoppingButton>
                  )}
                </MobileCheckoutButtonContainer>
              )}
            </MobileOnly>
          </>
        )}
        <MobileOnly>
          <Divider />
        </MobileOnly>
        <ProductsContainer
          height={height}
          offset={offset}
          isEmbedded={isEmbedded}
          hasntMetMinimum={hasNotMetMinimum}
          data-testid='cart-products-container'
          isDutchiePayEnabledForDispo={isDutchiePayEnabledForDispo}
        >
          <CartItems>
            {map(items, (item, key) => (
              <CartItem
                key={key}
                onWeightChange={handleWeightChange(item)}
                onQuantityChange={handleQuantityChange(item)}
                onRemoveItem={handleRemoveItem(item)}
                item={item}
                isKiosk={isKiosk}
                menuType={menuType}
                dispensary={dispensary}
                closeCheckoutModal={onClose}
              />
            ))}
          </CartItems>
          <CartRecommendationsCarousel />
        </ProductsContainer>

        <DesktopOnly>
          {isDutchiePayEnabledForDispo ? (
            <DutchiePayCheckout
              isEmbedded={isEmbedded}
              isMobile={isMobile}
              handleGoToCheckout={handleGoToCheckout}
              taxInclusionNote={taxInclusionNote}
              subtotal={subtotal}
              isEnrolledDutchiePay={isEnrolledDutchiePay}
            />
          ) : (
            <Footer isEmbedded={isEmbedded}>
              {isEmbedded && (
                <ContinueShoppingButton onClick={onClose}>
                  <SvgChevronIcon height={12} width={12} />
                  <span>{t('common.continueShopping', 'Continue shopping')}</span>
                </ContinueShoppingButton>
              )}

              <CheckoutButton
                variant='primary'
                size='large'
                onClick={() => (isMobile ? onClose() : handleGoToCheckout())}
                data-testid='cart-checkout-button'
                isEmbedded={isEmbedded}
              >
                {isMobile
                  ? t('common.continueShopping', 'Continue shopping')
                  : t('common.proceedToCheckout', 'Proceed to checkout')}
              </CheckoutButton>

              <FooterSubtotal isEmbedded={isEmbedded}>
                <Subtotal data-testid='cart-dropdown-subtotal'>
                  {t('common.subtotal', 'Subtotal')}:&nbsp;
                  {isCalculating ? (
                    <em>{t('common.calculating', 'Calculating...')}</em>
                  ) : (
                    <strong>${subtotal.toFixed(2)}</strong>
                  )}
                </Subtotal>
                <Taxes isEmbedded={isEmbedded}>{taxInclusionNote}</Taxes>
              </FooterSubtotal>
            </Footer>
          )}
        </DesktopOnly>
      </EmptyLoadingState>
      {children}
    </Container>
  );
});
