import { useCallback, useRef } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';
import { GqlCrossingMindsAuthResponse } from 'types/graphql';

import { XMindsClient, getInstance } from './xminds';

export const XMINDS_AUTH_KEY = 'xminds';

const loginMutation = gql`
  mutation {
    crossingMindsLogin {
      jwtToken
      refreshToken
    }
  }
`;

export function useXMinds(): XMindsClient | null {
  const client = useApolloClient();

  const getToken = useCallback(async (): Promise<GqlCrossingMindsAuthResponse> => {
    const { data } = await client.mutate({ mutation: loginMutation });
    const auth = data.crossingMindsLogin as GqlCrossingMindsAuthResponse;

    setPersistedValue(XMINDS_AUTH_KEY, auth);
    return auth;
  }, [client]);

  const persistedAuth = useRef<Partial<GqlCrossingMindsAuthResponse>>(getPersistedValue(XMINDS_AUTH_KEY, {}));

  const { jwtToken } = persistedAuth.current;

  return getInstance({ getToken, jwtToken });
}
