import React from 'react';
import Head from 'next/head';
import _ from 'lodash';

import buildDispensaryJsonLd from './build-dispensary-json-ld';
import buildproductJsonLd from './build-product-json-ld';

const JsonLDScript = ({ variant, jsonLdData }) => {
  let buildJsonLd;

  if (_.isEmpty(jsonLdData)) {
    console.error(`Empty ${variant} data passed to JsonLDScript`);

    return null;
  }

  if (variant === 'dispensary') {
    buildJsonLd = buildDispensaryJsonLd(jsonLdData);
  } else {
    buildJsonLd = buildproductJsonLd(jsonLdData);
  }

  return (
    <Head>
      <script
        key='json-ld-script'
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(buildJsonLd) }}
      />
    </Head>
  );
};

export default JsonLDScript;
