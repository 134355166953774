import React from 'react';

export function PayByBankKioskError(): JSX.Element {
  return (
    <svg width='152' height='153' viewBox='0 0 152 153' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4941_17375)'>
        <circle opacity='0.08' cx='76' cy='76.5' r='76' fill='#8DA1B4' />
        <circle opacity='0.1' cx='76' cy='76.5' r='61' fill='#446583' />
        <path
          d='M80.8295 41.636C80.4512 40.9515 79.8964 40.3808 79.223 39.9834C78.5495 39.586 77.7818 39.3764 76.9999 39.3764C76.2176 39.3764 75.45 39.586 74.7765 39.9834C74.1031 40.3808 73.5483 40.9515 73.1703 41.636L44.7327 94.136C44.365 94.8021 44.1773 95.5526 44.1882 96.3133C44.1991 97.0739 44.4082 97.8188 44.7949 98.4739C45.1816 99.1293 45.7324 99.6724 46.3931 100.05C47.0538 100.427 47.8015 100.626 48.5623 100.626H105.437C106.198 100.626 106.946 100.427 107.607 100.05C108.267 99.6724 108.818 99.1293 109.205 98.4739C109.591 97.8188 109.8 97.0739 109.812 96.3133C109.822 95.5526 109.634 94.8021 109.267 94.136L80.8295 41.636Z'
          fill='white'
          stroke='#91A1B1'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M77 82V59' stroke='#E97A3B' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M77 92C75.8955 92 75 91.1045 75 90C75 88.8955 75.8955 88 77 88' fill='#E97A3B' />
        <path d='M77 92C78.1045 92 79 91.1045 79 90C79 88.8955 78.1045 88 77 88' fill='#E97A3B' />
      </g>
      <defs>
        <clipPath id='clip0_4941_17375'>
          <rect width='152' height='152' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
