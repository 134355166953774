/* eslint-disable react/style-prop-object */
import React from 'react';

function BasketSuccess(props) {
  return (
    <svg width='131' height='132' viewBox='0 0 131 132' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='68.0024' cy='69.5' r='62.5' fill='#E6EAF1' />
      <path
        d='M111.679 102.879C111.446 104.019 110.827 105.043 109.925 105.778C109.023 106.514 107.895 106.915 106.731 106.913H29.2636C28.1001 106.915 26.9718 106.514 26.0701 105.778C25.1684 105.043 24.5487 104.019 24.316 102.879L15.041 56.4785H120.954L111.679 102.879Z'
        fill='white'
        stroke='#91A1B1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask id='mask0_1583_4299' maskUnits='userSpaceOnUse' x='15' y='54' width='109' height='53'>
        <path
          d='M113.465 101.457C113.232 102.587 112.612 103.602 111.709 104.331C110.807 105.06 109.678 105.458 108.513 105.457H30.9818C29.8173 105.458 28.6882 105.06 27.7857 104.331C26.8833 103.602 26.263 102.587 26.0301 101.457L16.7476 55.4565H122.748L113.465 101.457Z'
          fill='white'
          stroke='#91A1B1'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </mask>
      <g mask='url(#mask0_1583_4299)'>
        <path
          d='M0.473145 98.3199C0.473144 101.85 1.1829 105.345 2.56189 108.606C3.94088 111.868 5.96209 114.831 8.51013 117.327C11.0582 119.823 14.0831 121.803 17.4123 123.154C20.7415 124.505 24.3097 125.2 27.9131 125.2C31.5166 125.2 35.0848 124.505 38.414 123.154C41.7432 121.803 44.7681 119.823 47.3162 117.327C49.8642 114.831 51.8854 111.868 53.2644 108.606C54.6434 105.345 55.3531 101.85 55.3531 98.3199C55.3531 94.79 54.6434 91.2946 53.2644 88.0334C51.8854 84.7722 49.8642 81.8089 47.3162 79.3129C44.7681 76.8169 41.7432 74.8369 38.414 73.4861C35.0848 72.1352 31.5166 71.4399 27.9131 71.4399C24.3097 71.4399 20.7415 72.1352 17.4123 73.4861C14.0831 74.8369 11.0582 76.8169 8.51013 79.3129C5.96209 81.8089 3.94088 84.7722 2.56189 88.0334C1.1829 91.2946 0.473144 94.79 0.473145 98.3199Z'
          fill='#F5F6F8'
        />
      </g>
      <path
        d='M15.041 41.3481H120.954C120.954 41.3481 125.998 41.3481 125.998 46.3916V51.4351C125.998 51.4351 125.998 56.4786 120.954 56.4786H15.041C15.041 56.4786 9.99756 56.4786 9.99756 51.4351V46.3916C9.99756 46.3916 9.99756 41.3481 15.041 41.3481Z'
        fill='#91A1B1'
        stroke='#91A1B1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.1279 31.2609L55.3888 1'
        stroke='#91A1B1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M110.867 31.2609L80.6062 1'
        stroke='#91A1B1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M75.5627 66.5649V91.7823'
        stroke='#91A1B1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M95.7363 66.5649V91.7823'
        stroke='#91A1B1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M60.4324 66.5649V91.7823'
        stroke='#91A1B1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40.2588 66.5649V91.7823'
        stroke='#91A1B1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.497559 103.413C0.497559 106.63 1.13127 109.816 2.36251 112.789C3.59375 115.761 5.39841 118.462 7.67344 120.737C9.94848 123.012 12.6493 124.817 15.6218 126.048C18.5943 127.279 21.7802 127.913 24.9976 127.913C28.2149 127.913 31.4008 127.279 34.3733 126.048C37.3458 124.817 40.0466 123.012 42.3217 120.737C44.5967 118.462 46.4014 115.761 47.6326 112.789C48.8638 109.816 49.4976 106.63 49.4976 103.413C49.4976 100.196 48.8638 97.0098 47.6326 94.0373C46.4014 91.0649 44.5967 88.364 42.3217 86.089C40.0466 83.8139 37.3458 82.0093 34.3733 80.778C31.4008 79.5468 28.2149 78.9131 24.9976 78.9131C21.7802 78.9131 18.5943 79.5468 15.6218 80.778C12.6493 82.0093 9.94848 83.8139 7.67344 86.089C5.39841 88.364 3.59375 91.0649 2.36251 94.0373C1.13127 97.0098 0.497559 100.196 0.497559 103.413Z'
        fill='white'
        stroke='#91A1B1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.7801 92.7607L24.1879 111.21C24.0168 111.443 23.8002 111.639 23.5511 111.785C23.3021 111.932 23.0258 112.026 22.7392 112.062C22.4565 112.099 22.1694 112.078 21.8949 112.001C21.6205 111.924 21.3642 111.793 21.1414 111.615L12.2148 104.478'
        stroke='#26A27B'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default BasketSuccess;
