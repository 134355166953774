import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;

/* eslint-disable max-len */
import React from 'react';

var SecureLockIcon = function SecureLockIcon(props) {
  return __jsx("svg", _extends({
    width: "17",
    height: "19",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("title", null, "Secure Lock Icon"), __jsx("rect", {
    x: "1",
    y: "6.309",
    width: "14.15",
    height: "10.75",
    rx: "2",
    stroke: "#A3AFBA",
    strokeWidth: "2"
  }), __jsx("path", {
    d: "M4.25 6.159c0-1.7 0-5.1 3.825-5.1s3.825 3.4 3.825 5.1",
    stroke: "#A3AFBA",
    strokeWidth: "2"
  }), __jsx("path", {
    d: "m10.255 9.714-2.808 3.114-.47.521-.47-.521-.762-.845a.077.077 0 0 0-.025-.02.02.02 0 0 0-.008-.002.02.02 0 0 0-.008.002.077.077 0 0 0-.025.02c-.06.067-.06.2 0 .267l1.265 1.403c.012.014.021.018.025.02a.02.02 0 0 0 .008.002.02.02 0 0 0 .008-.002.078.078 0 0 0 .025-.02l3.311-3.671-.066-.268Zm0 0a.078.078 0 0 1 .025-.02.019.019 0 0 1 .008-.002l.008.002a.078.078 0 0 1 .025.02m-.066 0h.066m0 0c.06.067.06.2 0 .268v-.268Z",
    fill: "currentColor",
    stroke: "currentColor",
    strokeWidth: "1.267"
  }));
};

export default SecureLockIcon;