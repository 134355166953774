import { useState, useEffect } from 'react';

import { useMarketplaceQueryParams } from 'shop/hooks/use-marketplace-query-params';

import { MarketplaceStrainTypeOption } from 'shop/types/strain-type.types';
import {
  UseMarketplaceStrainTypeProps,
  UseMarketplaceStrainTypeReturn,
} from './marketplace-strain-type-filter-drawer.types';

export function useMarketplaceStrainTypeFilter({
  open,
}: UseMarketplaceStrainTypeProps): UseMarketplaceStrainTypeReturn {
  const [selectedOptions, setSelectedOptions] = useState<MarketplaceStrainTypeOption[]>([]);
  const { queryParams, setQueryParams } = useMarketplaceQueryParams();

  useEffect(() => {
    if (open) {
      setSelectedOptions(queryParams.straintypes);
    }
    // queryParams.straintypes in the deps causes infinite re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function handleSave(): void {
    setQueryParams({ straintypes: selectedOptions });
  }

  function handleSelect(option: MarketplaceStrainTypeOption): void {
    setSelectedOptions((previousOptions) =>
      previousOptions.includes(option)
        ? previousOptions.filter((selected) => selected !== option)
        : [...previousOptions, option]
    );
  }

  return { handleSave, handleSelect, selectedOptions };
}
