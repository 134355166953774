import React from 'react';
import Head from 'next/head';
import { NextRouter, useRouter } from 'next/router';

import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import PublicEnv from 'shared/utils/public-env';

const getDispensaryPathEquivalent = (path: string): string => {
  const splitPath = path.split('/');
  splitPath.splice(1, 1, `dispensary`);

  return splitPath.join('/');
};

type SanitizePathForSeo = {
  sanitizedPath: string;
};

function sanitizePathForSeo({ asPath }: Partial<NextRouter>): SanitizePathForSeo {
  let sanitizedPath = asPath ?? '';
  /* 
    We need to sanitized the path and remove the query params.
    For seo purposes they are unecessarily creating duplicate indices.
  */
  if (asPath) {
    [sanitizedPath] = asPath.split('?');
  }
  return { sanitizedPath };
}

export default function CanonicalTag(): JSX.Element {
  const router = useRouter();
  const UI = useUI();
  const { dispensary } = useDispensary();

  const getCanonicalUrl = (): string => {
    const hasCustomDomain = !!dispensary?.customDomainSettings?.domain;
    const { sanitizedPath } = sanitizePathForSeo(router);

    /*
      If viewing storeFront variant (regardless of domain it's served from), prefer dispensary's custom
      domain as canonical, if it exists. In all other cases (dutchie.com, no custom domain exists, etc.),
      default to marketplace URL from env.
    */
    if (UI.isVariant(`store-front`) && hasCustomDomain) {
      const customDomain: string = dispensary.customDomainSettings.domain;
      return `https://${customDomain}${sanitizedPath}`;
    }

    // For SEO, we want /stores, /embedded-menu, and /kiosks to link back to their /dispensary equivalents
    if (UI.isVariant(`store-front`) || UI.isVariant(`embedded`) || UI.isVariant(`kiosk`)) {
      return `${PublicEnv.consumerUrl}${getDispensaryPathEquivalent(sanitizedPath)}`;
    }

    // All other cases, we self-reference
    return `${PublicEnv.consumerUrl}${sanitizedPath}`;
  };

  return (
    <Head>
      <link rel='canonical' href={getCanonicalUrl()} />
    </Head>
  );
}
