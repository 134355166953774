import React from 'react';
import styled, { css } from 'styled-components';

import useTranslation from 'hooks/use-translation';
import useFeatureFlags from 'src/hooks/use-feature-flags';

import ROUTES from 'src/routes';
import { Link } from 'components/core';

const { ACCOUNT, PREFERENCES, ORDERS, BANK_ACCOUNT } = ROUTES;

export const LoggedOutOptionsListItem = ({ onOptionClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <OptionItem onClick={() => onOptionClick(`createAnAccount`)}>
        {t('accountOptions.createAccount', 'Create an Account')}
      </OptionItem>
      <OptionItem onClick={() => onOptionClick(`login`)}>{t('accountOptions.login', 'Login')}</OptionItem>
    </>
  );
};

export const LoggedInOptionsListItem = ({ onLogout }) => {
  const { t } = useTranslation();
  const featureFlags = useFeatureFlags();
  const isAccountSettingsDutchiePayEnabled = featureFlags.flags['ecomm.menu.account-manage-dutchie-pay.rollout'];

  return (
    <>
      {isAccountSettingsDutchiePayEnabled && (
        <Link href={BANK_ACCOUNT}>
          <Anchor href={BANK_ACCOUNT}>{t('accountOptions.bankAccount', 'Bank Account')}</Anchor>
        </Link>
      )}
      <Link href={ACCOUNT}>
        <Anchor href={ACCOUNT}>{t('accountOptions.accountDetails', 'Account Details')}</Anchor>
      </Link>
      <Link href={PREFERENCES}>
        <Anchor href={PREFERENCES}>{t('accountOptions.preferences', 'Preferences')}</Anchor>
      </Link>
      <Link href={ORDERS}>
        <Anchor href={ORDERS}>{t('accountOptions.orders', 'Orders')}</Anchor>
      </Link>

      <OptionItem onClick={onLogout}>{t('accountOptions.logout', 'Logout')}</OptionItem>
    </>
  );
};

const listItemStyles = css`
  width: 187px;
  font-size: 14px;
  line-height: 175%;
  color: ${({ theme }) => theme.colors.grey[30]};
  cursor: pointer;
  text-align: left;
  padding-left: 30px;
  height: 37px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryGrey};
  }
`;

const OptionItem = styled.div`
  ${listItemStyles}
`;

const Anchor = styled.a`
  ${listItemStyles}
`;
