import { useMarketplaceBrandsQuery, GqlMarketplaceBrandsQueryVariables } from 'types/graphql';
import { toGenericCategoryRoute } from 'src/generics/data/generic-marketplace-category';
import { GraphqlError } from 'src/errors';
import { sortByName } from 'shop/utils';

import { useMarketplaceQueryParams } from 'shop/hooks/use-marketplace-query-params';
import { useCategoryRouteParam } from 'shop/hooks/use-category-route-param';
import { useAddressBounds } from 'shop/hooks/use-address-bounds';

import { TransformBrandsParams } from './marketplace-brands-filter-drawer.types';

import { useBrandsSearch, useBrandsSelection, useFilteredOptions } from '../brands-filter-drawer.utils';

import { TransformBrandsReturn, UseBrandsFilterReturn } from '../brands-filter-drawer.types';

function useTransformBrandsForFiltering({ data, category }: TransformBrandsParams): TransformBrandsReturn {
  if (!data?.searchProductFilters.length) {
    return undefined;
  }

  const brands = data.searchProductFilters.find((item) => toGenericCategoryRoute.get(item.category) === category)
    ?.brands;

  if (!brands) {
    console.error(`brands data not found for category (${String(category)})`);
    return undefined;
  }

  return brands.map(({ id, name }) => ({ key: id, label: name, name })).sort(sortByName);
}

function useVariables(): GqlMarketplaceBrandsQueryVariables | undefined {
  const bounds = useAddressBounds();

  return bounds ? { bounds } : undefined;
}

export function useMarketplaceBrandsFilter(): UseBrandsFilterReturn {
  const {
    isReady,
    setQueryParams,
    queryParams: { brands },
  } = useMarketplaceQueryParams();

  const { category } = useCategoryRouteParam();
  const { handleToggle, handleClear, handleIsSelected, selectedBrands } = useBrandsSelection({ brands });
  const { inputValue, debouncedValue, handleChangeInputValue, handleClearInputValue } = useBrandsSearch();

  const variables = useVariables();

  const { data, loading, error } = useMarketplaceBrandsQuery({
    skip: !isReady || !variables,
    variables,
  });

  if (error) {
    throw new GraphqlError(error);
  }

  const options = useTransformBrandsForFiltering({ data, category });

  const filteredOptions = useFilteredOptions({ options, debouncedValue });

  const handleSave = (): void => {
    setQueryParams({ brands: Array.from(selectedBrands) });
  };

  return {
    loading,
    inputValue,
    filteredOptions,
    handleToggle,
    handleClear,
    handleSave,
    handleChangeInputValue,
    handleClearInputValue,
    handleIsSelected,
  };
}
