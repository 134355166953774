import React from 'react';
import styled, { css } from 'styled-components';

import useColors from 'hooks/use-colors';

import { VerticalDivider, ContentWrapper } from 'components/core';
import { CartButton } from 'components/cart';

import { LocationSettingsPopover, AddressTextButton } from 'components/location-settings-popover';
import MyAccountLink from './my-account-link';
import { HeaderLogo } from './header-logo';

const GlobalHeader = ({
  addressButtonRef,
  anchorEl,
  cartButtonRef,
  globalHeaderRef,
  address,
  orderType,
  href,
  route,
  itemCount,
  isDispensaryList,
  toggleCartView,
  onSaveLocationSettings,
  onAddressTextButtonClick,
}) => {
  const colors = useColors();

  return (
    <Wrapper id='global-header' ref={globalHeaderRef} isDispensaryList={isDispensaryList}>
      <ContentWrapper bg={colors.opal04} flexGrow={0} maxWidth={1400}>
        <Container>
          <HeaderLogo href={href} route={route} />

          <ContentGroup>
            <HeaderDivider mx='30px' />
            <DropdownContainer ref={addressButtonRef} data-testid='dropdown-web'>
              <AddressTextButton onClick={onAddressTextButtonClick} address={address} orderType={orderType} />
              <LocationSettingsPopover onSave={onSaveLocationSettings} anchorEl={anchorEl} />
            </DropdownContainer>
          </ContentGroup>

          <ContentGroup>
            <HeaderDivider mx='23px' />
            <DropdownContainer>
              <MyAccountLink />
            </DropdownContainer>

            <DropdownContainer>
              <CartButton
                ref={cartButtonRef}
                onClick={toggleCartView}
                variant='darkBlue'
                itemCount={itemCount}
                showCountColor
              />
            </DropdownContainer>
          </ContentGroup>
        </Container>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.0579928);
  width: 100%;

  /* location-settings-modal mui backdrop z-index + 100. So the backdrop doesn't cover the global nav */
  z-index: 1200;

  .content-wrapper {
    max-width: 1536px;
  }

  ${({ isDispensaryList }) =>
    isDispensaryList &&
    css`
      position: fixed;
      width: 100%;
    `}
`;

const Container = styled.header`
  position: relative;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.opal04};
  display: flex;
  height: 70px;
  align-items: center;
  padding-left: 34px;
  padding-right: 24px;
  * {
    .focus-visible {
      box-shadow: ${({ theme }) => `0 0 0pt 2pt ${theme.colors.whiteOverlay}`};
    }
  }
  div:nth-child(2) {
    flex: 1;
  }
`;

const ContentGroup = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderDivider = styled(VerticalDivider)`
  border-color: ${({ theme }) => theme.colors.opal05};
  height: 34px;
  margin-bottom: 1px;
`;

const DropdownContainer = styled.div`
  align-items: center;
  position: relative;
`;

export default GlobalHeader;
