import useStores from './use-stores';
/**
 * @returns {(msg?: string, type?: 'danger' | 'error' | 'info' | 'success', timeout?: number, reasonCode?: string) => void}
 */

export default function useErnie() {
  var _stores$UI;

  var stores = useStores();
  return stores === null || stores === void 0 ? void 0 : (_stores$UI = stores.UI) === null || _stores$UI === void 0 ? void 0 : _stores$UI.showErnie;
}