import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import { Button } from 'components/core';

export function ShowResultsButton({ onClick }): JSX.Element {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledButton data-testid='show-results-button' onClick={onClick}>
        {t('locationSettings.showResults', 'Show Results')}
      </StyledButton>
    </Container>
  );
}

export const Container = styled.div`
  margin-bottom: 45px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 10px;
    margin-bottom: 50px;
  }
`;
export const StyledButton = styled(Button)`
  padding: 12px 15px;
  height: 37px;
  width: 127px;
`;
