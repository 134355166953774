import React, { SVGAttributes } from 'react';

/* eslint-disable max-len */

export function ErrorExclamation(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      role='img'
      {...props}
    >
      <title>Exclamation Icon</title>
      <path
        d='M10 4.23001e-05C7.32322 0.0418082 4.77015 1.13427 2.89168 3.04171C1.95668 3.97658 1.21894 5.08969 0.72222 6.31504C0.225498 7.54038 -0.0200934 8.853 1.27685e-05 10.175C-0.00108523 11.4663 0.252573 12.7451 0.746458 13.9381C1.24034 15.1312 1.96475 16.2151 2.87819 17.1278C3.79162 18.0404 4.87614 18.7639 6.06963 19.2568C7.26311 19.7497 8.5421 20.0022 9.83335 20H10C12.6751 19.9758 15.2311 18.8905 17.1062 16.9825C18.9814 15.0745 20.0222 12.5001 20 9.82504C20.0025 8.5193 19.7442 7.22621 19.2403 6.02161C18.7365 4.817 17.9972 3.72513 17.0657 2.81001C16.1343 1.8949 15.0296 1.17497 13.8163 0.692452C12.603 0.209929 11.3055 -0.0254758 10 4.23001e-05ZM8.75001 13.7834C8.7432 13.6158 8.77062 13.4487 8.83059 13.2921C8.89057 13.1355 8.98184 12.9928 9.09883 12.8727C9.21582 12.7526 9.35608 12.6576 9.51103 12.5935C9.66597 12.5294 9.83236 12.4976 10 12.5C10.3282 12.5019 10.6431 12.6303 10.879 12.8585C11.115 13.0867 11.2538 13.3971 11.2667 13.725C11.2734 13.8901 11.2467 14.0548 11.1882 14.2092C11.1296 14.3636 11.0405 14.5047 10.9261 14.6238C10.8116 14.7429 10.6743 14.8376 10.5223 14.9023C10.3703 14.9669 10.2068 15.0002 10.0417 15C9.71053 15.0047 9.39055 14.8804 9.14947 14.6533C8.90839 14.4262 8.76513 14.1142 8.75001 13.7834ZM9.16668 10.45V5.45004C9.16668 5.22903 9.25448 5.01707 9.41076 4.86079C9.56704 4.70451 9.779 4.61671 10 4.61671C10.221 4.61671 10.433 4.70451 10.5893 4.86079C10.7455 5.01707 10.8333 5.22903 10.8333 5.45004V10.45C10.8333 10.6711 10.7455 10.883 10.5893 11.0393C10.433 11.1956 10.221 11.2834 10 11.2834C9.779 11.2834 9.56704 11.1956 9.41076 11.0393C9.25448 10.883 9.16668 10.6711 9.16668 10.45Z'
        fill='#B79090'
      />
    </svg>
  );
}
