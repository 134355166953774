import Cookies from 'js-cookie';
import noop from 'lodash/noop';
import { v4 as uuidv4 } from 'uuid';
import { getSiftQueue } from 'utils/analytics/helpers/sift';

export type SiftClient = {
  trackPageView: () => void;
  setUserId: (userId: string) => void;
};

const SIFT_PAGEVIEW_EVENT = '_trackPageview';
const SIFT_ACCOUNT_EVENT = '_setAccount';
const SIFT_SESSION_EVENT = '_setSessionId';
const SIFT_USER_EVENT = '_setUserId';
const SESSION_COOKIE_NAME = 'dsid';

const generateSessionId = (): string => {
  const sessionId = uuidv4();
  Cookies.set(SESSION_COOKIE_NAME, sessionId);

  return sessionId;
};

const getSessionId = (): string => {
  const sessionId = Cookies.get(SESSION_COOKIE_NAME);

  if (sessionId) {
    return sessionId;
  }

  return generateSessionId();
};

export const createSiftClient = (beaconKey: string): SiftClient => {
  const sessionId = getSessionId();
  const siftQueue = getSiftQueue();

  siftQueue.push([SIFT_ACCOUNT_EVENT, beaconKey]);
  siftQueue.push([SIFT_SESSION_EVENT, sessionId]);

  return {
    trackPageView: () => {
      siftQueue.push([SIFT_PAGEVIEW_EVENT]);
    },

    setUserId: (userId) => {
      siftQueue.push([SIFT_USER_EVENT, userId]);
    },
  };
};

export const createSiftNoopClient = (): SiftClient => ({
  trackPageView: noop,
  setUserId: noop,
});
