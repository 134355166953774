import React, { ReactElement } from 'react';
import Link, { LinkProps } from 'next/link';

type TabLinkProps = LinkProps & {
  children: ReactElement;
  // pass a value to ensure that MUI applies our styles to our Link
  value: string;
};

export const TabLink = ({ children, ...rest }: TabLinkProps): ReactElement => (
  <Link {...rest} passHref>
    {/* pass all of these values down to our children since MUI applies all sorts of secrets */}
    {React.cloneElement(children, { ...rest })}
  </Link>
);
