/* eslint-disable max-len */
import React from 'react';

type Props = {
  color?: string;
  width?: number;
  height?: number;
};

function InfoIconFilled({ color = '#242526', ...rest }: Props): JSX.Element {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <g clipPath='url(#clip0_763_36359)'>
        <path
          d='M8 0.625C6.41775 0.625 4.87104 1.09419 3.55544 1.97324C2.23985 2.85229 1.21447 4.10172 0.608967 5.56353C0.00346629 7.02534 -0.15496 8.63387 0.153721 10.1857C0.462403 11.7376 1.22433 13.163 2.34315 14.2819C3.46197 15.4007 4.88743 16.1626 6.43928 16.4713C7.99113 16.78 9.59966 16.6215 11.0615 16.016C12.5233 15.4105 13.7727 14.3852 14.6518 13.0696C15.5308 11.754 16 10.2072 16 8.625C16 6.50327 15.1571 4.46844 13.6569 2.96815C12.1566 1.46785 10.1217 0.625 8 0.625V0.625ZM8.16667 3.95833C8.36445 3.95833 8.55779 4.01698 8.72224 4.12686C8.88669 4.23674 9.01486 4.39292 9.09055 4.57565C9.16624 4.75838 9.18604 4.95944 9.14746 5.15342C9.10887 5.3474 9.01363 5.52559 8.87378 5.66544C8.73392 5.80529 8.55574 5.90053 8.36176 5.93912C8.16778 5.9777 7.96671 5.9579 7.78399 5.88221C7.60126 5.80653 7.44508 5.67835 7.3352 5.5139C7.22532 5.34945 7.16667 5.15611 7.16667 4.95833C7.16667 4.69312 7.27203 4.43876 7.45956 4.25123C7.6471 4.06369 7.90145 3.95833 8.16667 3.95833ZM9.66667 12.9583H7C6.82319 12.9583 6.65362 12.8881 6.5286 12.7631C6.40358 12.638 6.33334 12.4685 6.33334 12.2917C6.33334 12.1149 6.40358 11.9453 6.5286 11.8203C6.65362 11.6952 6.82319 11.625 7 11.625H7.5C7.54421 11.625 7.5866 11.6074 7.61786 11.5762C7.64911 11.5449 7.66667 11.5025 7.66667 11.4583V8.45833C7.66667 8.41413 7.64911 8.37174 7.61786 8.34048C7.5866 8.30923 7.54421 8.29167 7.5 8.29167H7C6.82319 8.29167 6.65362 8.22143 6.5286 8.0964C6.40358 7.97138 6.33334 7.80181 6.33334 7.625C6.33334 7.44819 6.40358 7.27862 6.5286 7.1536C6.65362 7.02857 6.82319 6.95833 7 6.95833H7.66667C8.02029 6.95833 8.35943 7.09881 8.60948 7.34886C8.85953 7.59891 9 7.93804 9 8.29167V11.4583C9 11.5025 9.01756 11.5449 9.04882 11.5762C9.08008 11.6074 9.12247 11.625 9.16667 11.625H9.66667C9.84348 11.625 10.0131 11.6952 10.1381 11.8203C10.2631 11.9453 10.3333 12.1149 10.3333 12.2917C10.3333 12.4685 10.2631 12.638 10.1381 12.7631C10.0131 12.8881 9.84348 12.9583 9.66667 12.9583Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_763_36359'>
          <rect width='16' height='16' fill='white' transform='translate(0 0.625)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InfoIconFilled;
