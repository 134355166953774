export const isPrerenderIoBot = (): boolean =>
  typeof navigator !== 'undefined' && /prerender/i.test(navigator.userAgent);

export const isGooglebot = (): boolean => typeof navigator !== 'undefined' && /googlebot/i.test(navigator.userAgent);

export const isBingbot = (): boolean => typeof navigator !== 'undefined' && /bingbot/i.test(navigator.userAgent);

export const isDuckduckgobot = (): boolean =>
  typeof navigator !== 'undefined' && /duckduckbot/i.test(navigator.userAgent);

export const isSearchEngineBot = (): boolean => isGooglebot() || isBingbot() || isDuckduckgobot();
