import React from 'react';
import styled from 'styled-components';
import { GqlOrders } from 'types/graphql';
import useTranslation from 'hooks/use-translation';
import DetailsTable from '../order-receipt-details-table';

type OrderDetailsProps = {
  order: GqlOrders | null;
};

export function OrderDetails({ order }: OrderDetailsProps): JSX.Element | null {
  const { t } = useTranslation();

  if (!order) {
    return null;
  }

  const { orderId } = order;

  return (
    <Details>
      <SectionHeader>
        {t('common.details', 'details')}
        <OrderNumber>
          <span>{t('common.orderNumberAbbreviated', 'order #')}:</span> {orderId}
        </OrderNumber>
      </SectionHeader>

      <DetailsTableContainer>
        <DetailsTable order={order} />
      </DetailsTableContainer>
    </Details>
  );
}

const Details = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlack};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  width: 90%;
`;

const DetailsTableContainer = styled.div`
  padding: 8px 25px 17px 25px;
`;

const OrderNumber = styled.div`
  color: ${({ theme }) => theme.colors.grey[35]};
  font-size: 13px;
  font-weight: normal;

  span {
    color: ${({ theme }) => theme.colors.grey[45]};
    text-transform: uppercase;
    font-weight: bold;
  }
`;

const SectionHeader = styled.div`
  height: 45px;
  background-color: ${({ theme }) => theme.colors.primaryGrey};
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;
