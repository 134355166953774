/* eslint-disable max-len */
import React from 'react';

function SvgQuestionCircleIcon(props): JSX.Element {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Question Mark Circle</title>
      <path
        d='M7.99998 15.4286C12.1027 15.4286 15.4286 12.1027 15.4286 8.00001C15.4286 3.89733 12.1027 0.571442 7.99998 0.571442C3.8973 0.571442 0.571411 3.89733 0.571411 8.00001C0.571411 12.1027 3.8973 15.4286 7.99998 15.4286Z'
        stroke='#4597E0'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.28564 6.28573C6.28564 5.94667 6.38619 5.61524 6.57455 5.33332C6.76292 5.05141 7.03066 4.83168 7.3439 4.70193C7.65715 4.57218 8.00183 4.53824 8.33437 4.60438C8.66691 4.67053 8.97237 4.8338 9.21211 5.07355C9.45186 5.31329 9.61513 5.61875 9.68128 5.95129C9.74742 6.28383 9.71347 6.62851 9.58372 6.94176C9.45397 7.255 9.23425 7.52274 8.95234 7.7111C8.67042 7.89947 8.33898 8.00001 7.99993 8.00001V9.14287'
        stroke='#4597E0'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.99997 10.8571C7.83044 10.8571 7.66472 10.9074 7.52376 11.0016C7.38281 11.0958 7.27294 11.2297 7.20807 11.3863C7.14319 11.5429 7.12622 11.7152 7.15929 11.8815C7.19237 12.0478 7.274 12.2005 7.39387 12.3204C7.51375 12.4403 7.66648 12.5219 7.83274 12.555C7.99901 12.588 8.17136 12.5711 8.32798 12.5062C8.4846 12.4413 8.61847 12.3314 8.71265 12.1905C8.80684 12.0495 8.85711 11.8838 8.85711 11.7143C8.85415 11.4879 8.76289 11.2716 8.60278 11.1115C8.44268 10.9514 8.22637 10.8601 7.99997 10.8571Z'
        fill='#4597E0'
      />
    </svg>
  );
}

export default SvgQuestionCircleIcon;
