import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { PhoneNumberInput, DateInput } from 'shared/components/text-input-with-label';
import { StyledSelect, StyledTextInput } from 'components/form-inputs/components';

function useCommonProps(props) {
  const { name, label, required, ...otherProps } = props;
  const { register, errors } = useFormContext();

  return {
    error: errors[name],
    inputRef: register({ required }),
    'aria-label': `${label || name} input`,
    autoComplete: `off`,
    name,
    label,
    ...otherProps,
  };
}

export function FormTextInput(props) {
  return <StyledTextInput variant='filled' {...useCommonProps(props)} />;
}

export function FormPhoneNumberInput(props) {
  return <PhoneNumberInput customInput={StyledTextInput} {...useCommonProps(props)} />;
}

export function FormDateInput(props) {
  return <DateInput customInput={StyledTextInput} {...useCommonProps(props)} />;
}

export function FormSelect(props) {
  const { required, ...otherProps } = props;
  return <Controller rules={{ required }} {...otherProps} as={<StyledSelect />} />;
}
