import React, { SVGAttributes } from 'react';

export const SvgPickupIcon = (props: SVGAttributes<unknown>): JSX.Element => (
  <svg width='34' height='37' viewBox='0 0 34 37' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='34' height='37'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0 0H34V37H0V0Z' fill='white' />
    </mask>
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8408 23.4963C21.5381 23.5256 25.3775 19.7721 25.4204 15.1048C25.4204 14.1618 24.6536 13.3999 23.7045 13.3999C22.758 13.3999 21.9886 14.1645 21.9886 15.1048C21.9886 17.9286 19.6828 20.2196 16.8408 20.2196C13.9988 20.2196 11.6931 17.9286 11.6931 15.1048C11.6931 14.1618 10.9236 13.3999 9.97713 13.3999C9.02801 13.3999 8.26121 14.1618 8.26121 15.1048C8.30411 19.7721 12.1435 23.5256 16.8408 23.4963ZM30.5682 31.9677C30.5548 32.8974 29.7906 33.646 28.8522 33.646H4.72213C3.82663 33.59 3.12686 32.8601 3.11345 31.9677V10.0673H30.5682V31.9677ZM7.40325 3.35411H26.1711L28.8522 6.71337H4.72213L7.40325 3.35411ZM33.8659 7.80559V7.61911L33.7319 7.37935L28.5037 0.666177C28.1793 0.239944 27.6752 -0.00780427 27.1363 0.000187605H6.54529C6.00907 -0.00780427 5.50234 0.239944 5.17792 0.666177L0.0301626 7.37935V7.61911V7.80559C-0.0100542 7.99739 -0.0100542 8.19719 0.0301626 8.39166V31.9677C0.059655 34.7649 2.36006 37.0159 5.17792 36.9999H29.308C31.9463 36.7815 33.9812 34.597 34 31.9677V8.39166C33.9973 8.18653 33.9517 7.9894 33.8659 7.80559Z'
        fill='#A3B2C1'
      />
    </g>
  </svg>
);
