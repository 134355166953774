import React from 'react';
import styled from 'styled-components';

import HomeIcon from 'assets/home-icon';
import ChevronIcon from 'assets/chevron-icon';
import useTranslation from 'hooks/use-translation';
import { Clickable } from 'shared/components';
import { ARIA_ROLES } from 'shared/constants';
import { VisuallyHidden } from 'src/components/core/visually-hidden';

import CartButtonMobile from '../cart-button-mobile';

export function MenuNavMobile({ dispensaryName, isMenuPage, onIconClick, itemCount }) {
  const { t } = useTranslation();

  return (
    <Container role={ARIA_ROLES.NAV} aria-label='top navigation'>
      {isMenuPage ? (
        <Clickable data-testid='back-icon' onClick={() => onIconClick(`back`)}>
          <VisuallyHidden>{t('globalHeader.menuNavMobile.backButtonLabel', 'Go back button')}</VisuallyHidden>
          <StyledChevronIcon />
        </Clickable>
      ) : (
        <Clickable data-testid='home-icon' onClick={() => onIconClick(`home`)}>
          <VisuallyHidden>{t('globalHeader.menuNavMobile.backButtonLabel', 'Go home button')}</VisuallyHidden>
          <HomeIcon />
        </Clickable>
      )}

      <DispensaryName>{dispensaryName}</DispensaryName>

      <CartButtonMobile
        badgeVariant='white'
        itemCount={itemCount}
        onButtonClick={() => onIconClick(`cart`)}
        variant='grey'
      />
    </Container>
  );
}

const Container = styled.div`
  height: 60px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const DispensaryName = styled.div`
  font-size: 16px;
  font-weight: bold;
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
`;

const StyledChevronIcon = styled(ChevronIcon)`
  fill: ${({ theme }) => theme.colors.grey[60]};
  transform: rotate(90deg);
  height: 11px;
  cursor: pointer;
`;
