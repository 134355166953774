import React from 'react';

import { DISPENSARY_STRAIN_TYPE_OPTIONS } from 'shop/constants/strain-type.constants';

import { ModalComponentProps, withModalControlsHook } from '../../with-modal-controls-hook';

import { useDispensaryStrainTypeFilter } from './use-dispensary-strain-type-filter';
import { StrainTypeFilterDrawer } from '../strain-type-filter-drawer';

export function DispensaryStrainTypeFilterDrawerWrapper(props: ModalComponentProps): JSX.Element {
  const { open } = props;
  const { handleSave, handleSelect, selectedOptions } = useDispensaryStrainTypeFilter({ open });

  return (
    <StrainTypeFilterDrawer
      onSave={handleSave}
      onSelect={handleSelect}
      options={DISPENSARY_STRAIN_TYPE_OPTIONS}
      selectedOptions={selectedOptions}
      {...props}
    />
  );
}

export const [DispensaryStrainTypeFilterDrawerController, useDispensaryStrainTypeFilterDrawer] = withModalControlsHook(
  DispensaryStrainTypeFilterDrawerWrapper
);
