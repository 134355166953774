import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { useApolloClient } from '@apollo/react-hooks';
import { ActivatePaymentMethodV2 as activatePaymentMethodMutation } from 'src/payments/graphql/mutations/activate-payment-method.gql';
import { useObserver } from 'mobx-react';
import { EnrollmentSteps } from 'src/payments/constants';
import ShowEnrollment from 'src/payments/graphql/queries/show-enrollment.gql';
import {
  ENROLLMENT_EVENTS,
  EVENT_ACTIONS,
  useDutchiePayAnalytics,
} from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import useErnie from 'shared/hooks/use-ernie';
import { formatPaymentsError } from 'src/payments/hooks/use-payments-error/use-payments-error';
import { ERNIE_TYPES } from 'shared/constants';
import { useDutchiePay } from 'src/payments/hooks';

const { success: successStep } = EnrollmentSteps;

type ActivatePaymentMethodResult = {
  activatePaymentMethod: () => Promise<void>;
};

export const useActivatePaymentMethod = (): ActivatePaymentMethodResult => {
  const apolloClient = useApolloClient();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const showErnie = useErnie();
  const refUrl = useObserver(() => DutchiePayEnrollment.refUrl);
  const connectedBankAccountDetails = useObserver(() => DutchiePayEnrollment.connectedBankAccountDetails);
  const { dutchiePayEventTracker, trackEnrollmentStepViewed } = useDutchiePayAnalytics();
  const { whiteLabelConfig } = useDutchiePay();

  const activatePaymentMethod = async (): Promise<void> => {
    DutchiePayEnrollment.activateBankAccountRequested();
    const activateBankAccountProps = {
      paymentMethodId: connectedBankAccountDetails.id,
    };

    try {
      const response = await apolloClient.mutate({
        mutation: activatePaymentMethodMutation,
        variables: activateBankAccountProps,
        refetchQueries: [{ query: ShowEnrollment }],
        awaitRefetchQueries: true,
      });

      const success = response.data?.activatePaymentMethodV2?.genericResponse.success;

      if (success) {
        DutchiePayEnrollment.activateBankAccountSucceeded();
        showErnie(`Your account information has been saved!`, ERNIE_TYPES.SUCCESS);
        trackEnrollmentStepViewed(EnrollmentSteps.completeEnrollment);
        if (!refUrl) {
          DutchiePayEnrollment.setCurrentStep(successStep);
        }
      } else {
        throw new Error(`Failed to activate payment method`);
      }
    } catch (err) {
      DutchiePayEnrollment.termsOfServiceError();
      const { errorMarkdown, ernieType, ernieTimeout } = formatPaymentsError(
        err,
        whiteLabelConfig.brandedDutchiePayName
      );
      showErnie(errorMarkdown, ernieType, ernieTimeout);
      dutchiePayEventTracker(ENROLLMENT_EVENTS.ENROLLMENT_STEP, {
        step: EnrollmentSteps.terms,
        action: EVENT_ACTIONS.FAILED,
      });
      console.error(err);
    }
  };

  return {
    activatePaymentMethod,
  };
};
