import { useObserver } from 'mobx-react-lite';
import { filter, includes } from 'lodash';

import useDispensarySpecials from 'dispensary/hooks/use-dispensary-specials';
import useCart from 'hooks/use-cart';

export default function useAppliedOffers() {
  const cart = useCart();
  const discountToCartApplied = useObserver(() => cart.discountToCartApplied);
  const appliedOfferIds = useObserver(() => cart.appliedOffers);
  const { specials } = useDispensarySpecials();
  const onlyDiscountToCartOffers = filter(specials, ({ discountToCart }) => discountToCart?.enabled);
  const appliedOffersNameIds = useObserver(() =>
    filter(onlyDiscountToCartOffers, ({ _id }) => includes(appliedOfferIds, _id))
  );

  return { discountToCartApplied, appliedOffers: appliedOffersNameIds };
}
