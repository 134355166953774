// UI Variants are used to modify the interface based on which context
// is currently loaded.

export enum InterfaceVariants {
  dutchieMain = 'default',
  embedded = 'embedded',
  kiosk = 'kiosk',
  messaging = 'messaging',
  plus = 'plus',
  storeFront = 'store-front',
  mobileEcomm = 'mobile-ecomm',
}
