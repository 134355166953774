import { getFides, Fides } from 'utils/fides';
import { DEFAULT_PRIVACY_PREFERENCES } from 'utils/fides/constants';
import { useConsentManagement } from './use-consent-management';

export function usePrivacyPreferences(): Fides['consent'] {
  const { isConsentManagementEnabled } = useConsentManagement();
  const fides = getFides();
  const userPrivacyPreferences = fides?.consent ?? {};
  const userDataSharingPreference = userPrivacyPreferences.data_sales_and_sharing;

  if (isConsentManagementEnabled && userDataSharingPreference !== undefined) {
    return userPrivacyPreferences;
  }

  return DEFAULT_PRIVACY_PREFERENCES;
}
