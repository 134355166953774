import React from 'react';
import styled from 'styled-components';
import { display, height, space, width, layout } from 'styled-system';

export default function Divider(props) {
  return <StyledDivider {...props} />;
}

const StyledDivider = styled.span`
  border-top: ${({ color, theme }) => `1px solid ${color || theme.colors.blueGrey[95]}`};
  height: 1px;
  max-width: 100%;
  ${display}
  ${height}
  ${layout}
  ${space}
  ${width}
`;

StyledDivider.defaultProps = { display: 'block' };
