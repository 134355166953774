import React from 'react';
import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { DutchiePayCtaModalContainer } from 'components/modals/dutchie-pay/cta-modal';
import { ModalObserver } from '../modal-observer';

export const DutchiePayCtaModal = ({ name }: { name: string }): JSX.Element => (
  <ModalObserver name={name}>
    {(props) => (
      <ModalWrapper {...props}>
        <DutchiePayCtaModalContainer {...props} />
      </ModalWrapper>
    )}
  </ModalObserver>
);
