/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { AdTypes, GaV4SponsoredItem } from 'src/utils/analytics/clients/ga-v4-client';
import { AnalyticsPromotion, Events } from 'src/utils/analytics/events';
import { convertKeysToSnakeCase } from 'src/utils/analytics/helpers/convert-keys-to-snake-case';
import { PageTypes } from './get-page-type';

type ConvertPromotionToGTMReturn = {
  promotion_id: string;
  promotion_name: string;
  creative_name: AdTypes;
  creative_slot: PageTypes;
  items: GaV4SponsoredItem[];
};

type ConvertItemProps = {
  product: AnalyticsPromotion['product'];
  promotionId: string;
  promotionName: string;
  trackerSource: string;
  index: number;
};

function convertItem({
  product,
  promotionId,
  promotionName,
  trackerSource,
  index,
}: ConvertItemProps): GaV4SponsoredItem {
  return {
    itemId: product.id ?? `N/A`,
    itemName: product.Name ?? `N/A`,
    creativeName: AdTypes.ad,
    creativeSlot: PageTypes.homePage,
    itemBrand: product.brandName ?? `N/A`,
    itemCategory: product.type ?? `N/A`,
    price: product.Prices ? product.Prices[0] : 0,
    promotionId,
    promotionName,
    quantity: product.POSMetaData?.children ? product.POSMetaData.children[0]?.quantity : 0,
    itemListName: trackerSource,
    index,
  };
}

export function convertPromotionToGTM(
  payload: AnalyticsPromotion | Events['buyItAgainImpression'] | Events['sponsoredBannerImpression']
): ConvertPromotionToGTMReturn {
  const { campaignId: promotionId = 'N/A', analyticsPage, trackerSource, position } = payload;

  const items = 'products' in payload ? payload.products : [payload.product];
  const promotionName = items[0].brandName ?? 'N/A';

  const promotionPayload = {
    promotionId,
    promotionName,
    creativeName: AdTypes.ad,
    creativeSlot: analyticsPage,
    items: items.map((product, index) =>
      convertItem({ product, promotionId, promotionName, trackerSource, index: position ?? index })
    ),
  };

  return convertKeysToSnakeCase(promotionPayload) as ConvertPromotionToGTMReturn;
}
