import useViewportVisibility from 'src/hooks/use-viewport-visibility';
import { usePersonalizedItems, Scenarios, GetRecommendationsFallback } from 'src/recommendations';
import { ProductCarouselProps } from 'src/components/product-carousel';
import { useShouldSkipLegacyPersonalization } from 'src/dispensary/hooks/use-skip-ads-rules';

import { Category } from 'src/generics/data';
import { MAX_PRODUCTS, OFTEN_PURCHASED_WITH_FILTERS } from './often-purchased-with-carousel.constants';

type UseOftenPurchasedWithCarouselParams = {
  category: Category;
  productId: string;
};

type UseOftenPurchasedWithCarouselReturn = Omit<ProductCarouselProps, 'heading'>;

function useGetProductsFallback(category: Category): GetRecommendationsFallback {
  if (!(category in OFTEN_PURCHASED_WITH_FILTERS)) {
    throw new Error(`missing often purchased with mapping for category: ${category}`);
  }

  return (getProducts) =>
    getProducts({
      sortBy: `type`,
      types: OFTEN_PURCHASED_WITH_FILTERS[category],
    });
}

export function useOftenPurchasedWithCarousel({
  category,
  productId,
}: UseOftenPurchasedWithCarouselParams): UseOftenPurchasedWithCarouselReturn {
  const viewportVisibility = useViewportVisibility();
  const skipPersonalization = useShouldSkipLegacyPersonalization();

  const getProductsFallback = useGetProductsFallback(category);

  const { data, loading } = usePersonalizedItems(getProductsFallback, [productId], Scenarios.productDetailsPage2, {
    skip: skipPersonalization || !viewportVisibility.hasBeenVisible,
    limit: MAX_PRODUCTS,
  });

  return {
    products: data ?? [],
    loading,
    viewportVisibility,
    placement: 'product-detail-page-often-purchased-with-carousel',
  };
}
