/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';

import usePaths from 'hooks/use-paths';

import { Link } from 'components/core';
import { getColors } from 'src/theme';
import ProductImage from 'components/product-image';
import { getCategoryForProduct } from 'shared/helpers/products';
import { formatCurrency } from 'shared/helpers/utils';
import { GqlOrders_Order, GqlDispensaries, GqlOrder_Product } from 'types/graphql';

const WEIGHT_LABEL = `Weight:`;
const QUANTITY_LABEL = `Qty:`;

type OrderItemProps = {
  item: GqlOrders_Order;
  index: number;
  dispensary: GqlDispensaries;
  onClick: (product: GqlOrder_Product, index: number) => void;
};

const { primaryBlue } = getColors();

export function OrderItem({ item, index, dispensary, onClick }: OrderItemProps): JSX.Element | null {
  const { option, product, quantity, price } = item;
  const { href, route } = usePaths({ category: getCategoryForProduct(product), product, dispensary });

  if (!product) {
    return null;
  }

  const { brand, brandName, Name, Status } = product;
  const productBrand = brandName ?? brand?.name;

  return (
    <Container data-testid='order-item'>
      <ImageInfoContainer>
        <Image alt={Name} product={product} />
        <InfoContainer>
          <ProductName>{Name}</ProductName>
          {productBrand && <BrandName>{productBrand}</BrandName>}
          <MeasurementContainer>
            <WeightLabel>{WEIGHT_LABEL}</WeightLabel>
            <MeasurementValue>{option}</MeasurementValue>
            <QuantityLabel>{QUANTITY_LABEL}</QuantityLabel>
            <MeasurementValue>{quantity}</MeasurementValue>
          </MeasurementContainer>
          {Status === 'Archived' ? (
            <ProductStatus>Product unavailable</ProductStatus>
          ) : (
            <Link href={href} route={route}>
              <ProductLink href={href} onClick={() => onClick(product, index)}>
                View Item
              </ProductLink>
            </Link>
          )}
        </InfoContainer>
      </ImageInfoContainer>
      <PriceContainer>
        <Price>{formatCurrency(price)}</Price>
      </PriceContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  @media (max-width: 350px) {
    flex-direction: column;
  }
`;

const ImageInfoContainer = styled.div`
  display: flex;
`;

const Image = styled(ProductImage)`
  width: 55px;
`;

const InfoContainer = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  padding-left: 12.58px;
`;

const ProductName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryBlack};
  line-height: 1.4;
  max-width: 160px;
`;

const BrandName = styled.div`
  font-size: 12px;
  padding-top: 5px;
  color: #252526;
`;

const ProductStatus = styled.div`
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 12px;
  margin-top: 12px;
`;

const ProductLink = styled.a`
  display: block;
  color: ${primaryBlue};
  font-size: 12px;
  margin-top: 12px;
  max-width: 60px;
`;

const MeasurementContainer = styled.div`
  display: flex;
  margin-top: 8px;
  max-width: 160px;
  max-height: 21px;
`;

const WeightLabel = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-right: 2px;
`;

const QuantityLabel = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-right: 4px;
  margin-left: 16px;
`;

const MeasurementValue = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[30]};
`;

const PriceContainer = styled.div`
  display: flex;
  height: 25px;
  align-items: center;
  @media (max-width: 350px) {
    justify-content: left;
  }
`;

const Price = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #454e50;
  color: ${({ theme }) => theme.colors.grey[30]};
  @media (max-width: 350px) {
    padding-left: 30%;
  }
`;
