import { useState, useEffect } from 'react';
export default function useDelayedRender(delay) {
  var _useState = useState(true),
      delayed = _useState[0],
      setDelayed = _useState[1];

  useEffect(function () {
    var timeout = setTimeout(function () {
      return setDelayed(false);
    }, delay);
    return function () {
      return clearTimeout(timeout);
    };
  }, [delay]);
  return function (fn) {
    return !delayed && fn();
  };
}