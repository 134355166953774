import React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import { DesktopOnly } from 'shared/components';
import GreenCheckLarge from 'assets/green-check-large';
import PinIcon from 'assets/pin-icon';
import { useColors } from 'contexts/colors';

export default function AddressSuggestion({
  onClick,
  selected = false,
  showCheckmark = false,
  suggestion,
  value,
  ...props
}) {
  const colors = useColors();

  return (
    <Container selected={selected} showCheckmark={showCheckmark} onClick={() => onClick(value)} {...props}>
      <DesktopOnly mediaQuery='sm'>
        <StyledPinIcon fill={colors.grey[70]} height='21px' />
      </DesktopOnly>
      {suggestion.address ? (
        <SuggestionText selected={selected}>
          {suggestion.bold && (
            // we only want to show ellipsis on the bold portion when suggestion.address is empty to avoid two sets of ellipsis
            <BoldSuggestionText showEllipsis={false}>{suggestion.bold}</BoldSuggestionText>
          )}
          {suggestion.address}
        </SuggestionText>
      ) : (
        suggestion.bold && <BoldSuggestionText showEllipsis>{suggestion.bold}</BoldSuggestionText>
      )}
      {showCheckmark && <StyledGreenCheckmark />}
    </Container>
  );
}

const Container = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 43px;
  margin-top: 13px;
  border-radius: 5px;
  padding: 0 8px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 0 16px;
    &:active,
    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryGrey};
    }
  }

  ${({ showCheckmark }) =>
    showCheckmark &&
    css`
      padding-right: 2px;
    `}
`;

const sharedSuggestionTextStyles = css`
  white-space: pre;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.grey[25]};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 14px;
  }
`;

const BoldSuggestionText = styled.span`
  ${sharedSuggestionTextStyles}
  font-weight: bold;

  ${({ showEllipsis }) =>
    showEllipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `};
`;

const SuggestionText = styled.span`
  ${sharedSuggestionTextStyles}
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ selected }) =>
    selected &&
    css`
      font-weight: bold;
    `}
`;

const StyledPinIcon = styled(PinIcon)`
  margin-right: 14px;
`;

const StyledGreenCheckmark = styled(GreenCheckLarge)`
  margin-left: auto;
`;
