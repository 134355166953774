import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import get from 'lodash/get';
import { useRouter } from 'next/router';

import archivedProductLookup from 'shared/graphql/product/archived-product-lookup.gql';
import individualFilteredProduct from 'shared/graphql/product/individual-filtered-product.gql';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';

import { useHardAgeGate } from 'hooks/use-hard-age-gate';
import { useHidePotencies } from 'hooks/use-hide-potencies';
import useDispensary, { getDispensary } from 'src/dispensary/hooks/use-dispensary';
import { products as mockProducts } from 'src/tests';
import { parseProduct } from 'utils/helpers/product';
import useUI from './use-ui';

export const getVariables = (productId, dispensary, isKiosk, includeTerpenes, includeCannabinoids) => ({
  productsFilter: {
    cName: productId,
    dispensaryId: dispensary?.id,
    removeProductsBelowOptionThresholds: false,
    isKioskMenu: isKiosk,
    bypassKioskThresholds: isKiosk,
    bypassOnlineThresholds: true,
    Status: `All`,
  },
  includeTerpenes,
  includeCannabinoids,
});

export const getProduct = async (apolloClient, props) => {
  const { data } = await getDispensary(apolloClient, props);
  const dispensary = get(data, `filteredDispensaries[0]`);

  return apolloClient.query({
    query: individualFilteredProduct,
    variables: getVariables(props.params.productId, dispensary),
  });
};

export default function useProduct() {
  const { query } = useRouter();
  const { dispensary } = useDispensary();
  const { hardAgeGate } = useHardAgeGate();
  const { menuHidePotencies } = useHidePotencies();
  const terpeneFlagOn = useDispensaryFlag('rollout.terpenes', dispensary?.id);
  const includeTerpenes = !hardAgeGate && terpeneFlagOn;
  const includeCannabinoids = !menuHidePotencies;
  const UI = useUI();
  const { productId } = query;

  const variables = getVariables(productId, dispensary, UI.isKiosk, includeTerpenes, includeCannabinoids);

  const provideBackInstockEmailNotifications = _.get(
    dispensary,
    'storeSettings.provideBackInstockEmailNotifications',
    false
  );
  const hideOutOfStockProducts = _.get(dispensary, 'storeSettings.hideOutOfStockProducts', false);
  const showOutOfStockNotification = provideBackInstockEmailNotifications && !hideOutOfStockProducts;
  // eslint-disable-next-line no-use-before-define
  const { data: individualFilteredProductData, ...apolloValues } = useQuery(individualFilteredProduct, {
    variables,
    skip: !productId,
  });

  // Currently the data that comes back from line 55, only queries out products that are Active or Inactive, but not Archived. We are going to be needing to do a another query to get the archived products. This will only get fired if the product is not found in the first query.
  const { data: archivedProductData, loading: archivedProductDataLoading } = useQuery(archivedProductLookup, {
    variables: { cname: productId, dispensaryId: dispensary?.id },
    skip:
      !productId || !showOutOfStockNotification || individualFilteredProductData?.filteredProducts?.products.length > 0,
  });

  const product = parseProduct(
    get(individualFilteredProductData, 'filteredProducts.products[0]', _.find(mockProducts, { id: productId }))
  );

  return { product, individualFilteredProductData, ...apolloValues, archivedProductData, archivedProductDataLoading };
}
