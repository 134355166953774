import useTranslation from 'hooks/use-translation';

import { ORDER_TYPE_DELIVERY } from 'shared/constants';
import useUI from 'hooks/use-ui';
import { OrderTypes } from 'state/search-preferences';

type UseAddressTextButtonProps = {
  address?: string | null;
  orderType: OrderTypes;
};

type UseAddressTextButtonReturn = {
  addressCopy: string;
  desktopOrderTypeCopy: string;
  isMarketplace: boolean;
  mobileOrderTypeCopy: string;
};

export function useAddressTextButton({
  address = '',
  orderType,
}: UseAddressTextButtonProps): UseAddressTextButtonReturn {
  const { t } = useTranslation();
  const { isMarketplace } = useUI();

  const startShoppingCopy = t('locationSettings.startShopping', 'Start Shopping');

  const noAddressText = isMarketplace
    ? t('locationSettings.enterAddress', 'Enter a new address')
    : t('locationSettings.enterAddress', 'Enter a new address or city...');

  const desktopOrderTypeText =
    orderType === ORDER_TYPE_DELIVERY
      ? t('locationSettings.deliveryTo', 'Delivery to')
      : t('locationSettings.pickupNear', 'Pickup near');

  const mobileOrderTypeText =
    orderType === ORDER_TYPE_DELIVERY
      ? t('locationSettings.delivery', 'Delivery')
      : t('locationSettings.pickup', 'Pickup');

  const marketplaceOrderTypeText =
    orderType === ORDER_TYPE_DELIVERY
      ? t('locationSettings.delivery', 'Delivery')
      : t('locationSettings.pickup', 'Pickup');

  if (address) {
    return {
      addressCopy: address,
      desktopOrderTypeCopy: isMarketplace ? marketplaceOrderTypeText : desktopOrderTypeText,
      isMarketplace,
      mobileOrderTypeCopy: mobileOrderTypeText,
    };
  }

  return {
    addressCopy: noAddressText,
    desktopOrderTypeCopy: startShoppingCopy,
    isMarketplace,
    mobileOrderTypeCopy: startShoppingCopy,
  };
}
