import _ from 'lodash';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { useObserver } from 'mobx-react-lite';
import styled from 'styled-components';
import useViewport from 'hooks/use-viewport';

import {
  atCarouselEnd,
  getCardWidth,
  offersRowSizes,
  defaultCarouselState,
  defaultOffersMargins,
  getCurrentBreakpoint,
  getCurrentMargins,
  gradients,
  roundHalf,
  scrollTo,
} from '../helpers';

import { MemoizedPrevButton as PrevButton, MemoizedNextButton as NextButton } from '../carousel-buttons';

const OffersSlider = (props) => {
  const { carouselProps = {}, children, margins = defaultOffersMargins } = props;
  const { className, displayGradient = false, isSingularCard } = carouselProps;
  const rowSizes = offersRowSizes;
  const carouselRef = React.useRef();

  const viewport = useViewport();
  const width = useObserver(() => viewport.width);
  const currentBreakpoint = useObserver(() => (carouselRef?.current ? getCurrentBreakpoint(width) : `desktop`));
  const currentCardMargin = useObserver(() => (carouselRef?.current ? getCurrentMargins(width, margins) : 0));
  const rowSize = useObserver(() => rowSizes[currentBreakpoint]);
  const slideWidth = useObserver(() =>
    carouselRef?.current ? getCardWidth(carouselRef.current) + currentCardMargin : currentCardMargin
  );

  // all of this is going to need to be stored in a ref so our event handlers can access the current state
  const [sizes, _setSizes] = useReducer((prev, updates) => ({ ...prev, ...updates }), {
    currentBreakpoint,
    currentCardMargin,
    rowSize,
    slideWidth,
    width,
  });
  const sizesRef = useRef(sizes);
  const setSizes = (data) => {
    sizesRef.current = data;
    _setSizes(data);
  };

  const totalCards = children?.length || 0;
  const enableButtons = totalCards > rowSize; // TODO double check jira req

  // same deal with the refs for our more general
  const initialState = defaultCarouselState(enableButtons);
  const [carouselState, _setCarouselState] = useReducer((prev, updates) => ({ ...prev, ...updates }), initialState);
  const carouselStateRef = useRef(carouselState);
  const setCarouselState = (data) => {
    carouselStateRef.current = data;
    _setCarouselState(data);
  };

  const handleEvent = useCallback(
    (e, type = `click`, direction = null) => {
      e.stopPropagation();
      const { current } = sizesRef;
      let advance;
      const updates = { ...carouselStateRef.current };

      // if we were dragging or scrolling
      if (type === `scroll`) {
        updates.snapIndex = Math.floor(roundHalf(carouselRef.current.scrollLeft / current.slideWidth));
        updates.lastTransitionType = `scroll`;
      }

      // if we've clicked, we haven't moved yet, but need to shift the carousel by a row length
      if (type === `click`) {
        // add/subtract row length
        updates.snapIndex = carouselStateRef.current.snapIndex + direction;

        // don't go past the front
        if (updates.snapIndex < 0) {
          updates.snapIndex = 0;
        }

        // don't go past the back
        if (atCarouselEnd(updates.snapIndex, totalCards, current.rowSize)) {
          updates.snapIndex = totalCards - 1;
        }

        const endOfCarouselPx = current.slideWidth * totalCards - 1 * current.slideWidth;
        let nextScrollPosition = current.slideWidth * updates.snapIndex;

        if (nextScrollPosition < 0) {
          nextScrollPosition = 0;
        }

        if (nextScrollPosition >= endOfCarouselPx) {
          const { nudge = 0 } = margins || {};
          nextScrollPosition = endOfCarouselPx + nudge;
        }

        updates.lastTransitionType = `click`;
        carouselRef.current.style.scrollSnapType = 'unset';
        advance = () => scrollTo(carouselRef.current, nextScrollPosition, 400, -1, true);
      }

      if (totalCards > current.rowSize) {
        // ensure that our new snap index isn't beyond the bounds of the carousel
        const endOfCarousel = atCarouselEnd(updates.snapIndex, totalCards, current.rowSize);
        const startOfCarousel = updates.snapIndex === 0;

        // update our buttons if we're no longer in the middle of the carousel
        if (startOfCarousel) {
          updates.nextEnabled = true;
          updates.prevEnabled = false;
        }

        if (endOfCarousel) {
          updates.nextEnabled = false;
          updates.prevEnabled = true;
        }

        if (!startOfCarousel && !endOfCarousel) {
          updates.nextEnabled = true;
          updates.prevEnabled = true;
        }
      }

      // if we're at the front of the carousel, disable scroll snap so that we don't mess with margin alignments
      if (updates.snapIndex < 1) {
        carouselRef.current.style.scrollSnapType = 'unset';
      }

      setCarouselState(updates);
      // perform advancement
      if (advance) {
        advance();
      }
    },
    [margins, totalCards]
  );

  // keep track of sizes whenever our screen changes
  useEffect(() => {
    if (carouselRef?.current) {
      setSizes({
        currentBreakpoint,
        currentCardMargin,
        rowSize,
        slideWidth: carouselRef.current ? getCardWidth(carouselRef.current) + currentCardMargin : currentCardMargin,
        width,
      });
    }
  }, [carouselRef, currentBreakpoint, currentCardMargin, rowSize, width]);

  // chill with all those scroll events
  const debounceWheelEvent = _.debounce((e) => handleEvent(e, `scroll`, null, 100));

  // setup wheel/touch event listeners
  useEffect(() => {
    const current = carouselRef?.current;
    if (current) {
      // scroll fires a bit more reliably than the touch events in terms of momentum scrolling
      current.addEventListener('scroll', debounceWheelEvent);

      return () => {
        if (current) {
          current.removeEventListener('scroll', debounceWheelEvent);
        }
      };
    }
    return _.noop();
  }, [carouselRef, debounceWheelEvent]);

  return (
    <GradientContainer
      className={className}
      displayGradient={displayGradient}
      gradient={carouselStateRef.current.gradient}
      isSingularCard={isSingularCard}
    >
      <Carousel role='group' aria-roledescription='carousel' {...carouselProps}>
        <Viewport ref={carouselRef} snapIndex={carouselStateRef.current.snapIndex} {...carouselProps}>
          <Container data-cy='offers-carousel' data-test='offers-carousel'>
            {children}
          </Container>
        </Viewport>
        <PrevButton
          data-cy='offers-prev-button'
          data-test='offers-prev-button'
          className='offers'
          enabled={enableButtons && carouselStateRef.current.prevEnabled}
          onClick={(e) => handleEvent(e, `click`, -1)}
        />
        <NextButton
          data-cy='offers-next-button'
          data-test='offers-next-button'
          className='offers'
          enabled={enableButtons && carouselStateRef.current.nextEnabled}
          onClick={(e) => handleEvent(e, `click`, 1)}
        />
      </Carousel>
    </GradientContainer>
  );
};

export default OffersSlider;

const GradientContainer = styled.div`
  position: relative;
  margin-top: 15px;

  &:after {
    position: absolute;
    top: 0;
    left: -21px;
    right: 0;
    bottom: 0;
    content: '';
    pointer-events: none;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      transition: all 3s ease;
      background: ${({ displayGradient, gradient }) => (displayGradient ? gradients[gradient] : `transparent`)};
    }
  }
`;

const Carousel = styled.div`
  position: relative;
  width: calc(100% + 50px); /* extend past the 25px page margins on both sides */
  margin-left: -25px; /* yank this to the left to re-center our entire carousel */

  /* over 600px... */
  @media (min-width: 600px) {
    width: 100%;
    margin-left: unset;
  }
`;

const Viewport = styled.div`
  /* under 600px... */
  overflow: auto;
  padding-left: 25px;
  /* toggle this off when clicking so that we still preserve some movement */
  /* toggle this off when in the first position so we don't screw up margin alignment */
  scroll-snap-type: ${({ snapIndex }) => (snapIndex < 1 ? 'unset' : 'x mandatory')};

  /* hide scrollbars */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* over 600px... */
  @media (min-width: 600px) {
    padding-left: unset;
  }

  /* over 1187px... */
  @media (min-width: 1187px) {
    ${({ className }) =>
      _.includes(className, `offers`) &&
      `
      /* overflow out of our containers a bit to give the card box shadows some room */
      padding-top: 9px;
      padding-bottom: 19px;
      margin-left: -14px;
      padding-left: 14px;
      margin-right: -14px;
      padding-right: 14px;
    `}
  }
`;

const Container = styled.div`
  display: flex;
  will-change: transform;
  margin-bottom: 35px;

  /* over 966.5px.... */
  @media (min-width: 966.5px) {
    /* now our box-shadow is enabled so account for it's extra padding */
    margin-bottom: 20px;
  }
`;
