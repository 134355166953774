import { useEffect, useState, useMemo } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { AnalyticsExperimentDetails } from 'src/utils/analytics/events';
import { tracker } from '..';

export function useExperimentsMonitor(): boolean {
  const flags = useFlags();
  const client = useLDClient();
  const [isReady, setIsReady] = useState(false);

  const experimentFlags = useMemo(() => ['growth.ads.show_sponsored_products_in_carousels.experiment'] as const, []);

  useEffect(() => {
    async function waitForLDClient(): Promise<void> {
      await client?.waitUntilReady();
      setIsReady(true);
    }

    if (client) {
      void waitForLDClient();
    }
  }, [client]);

  useEffect(() => {
    if (!client || !isReady) {
      return;
    }

    const activeExperiments = {};

    experimentFlags.forEach((flag) => {
      const details = client.variationDetail(flag) as AnalyticsExperimentDetails | undefined;

      if (!details) {
        return;
      }

      const { value, reason } = details;

      if (reason?.inExperiment || reason?.kind === 'TARGET_MATCH') {
        activeExperiments[flag] = { value, reason };
      }
    });

    if (Object.keys(activeExperiments).length > 0) {
      tracker.setContext({ activeExperiments });
    }
  }, [isReady, flags, client, experimentFlags]);

  return isReady;
}
