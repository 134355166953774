import React from 'react';
import useViewportVisibility from 'src/hooks/use-viewport-visibility';
import { useRecommendedProducts } from './use-recommended-products';
import { GenericCarousel } from '../generic-carousel';

type MostPurchasedSectionProps = {
  dispensaryId: string;
};

export function MostPurchasedCarousel({ dispensaryId }: MostPurchasedSectionProps): JSX.Element | null {
  const { products, loading, error } = useRecommendedProducts({ dispensaryId });
  const { ref, hasBeenVisible } = useViewportVisibility();

  if (hasBeenVisible && !loading && (error || !products.length)) {
    return null;
  }

  const sectionProps = {
    section: {
      hideTitle: false,
    },
    hasBeenVisible,
    isLoading: loading,
    label: `Buy It Again`,
    products,
  };

  return <GenericCarousel ref={ref} {...sectionProps} />;
}
