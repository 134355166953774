import styled, { css } from 'styled-components';

import { fullWidthStyles } from '../hybrid-carousel.styles';

export const SliderContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

export const Slider = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const Viewport = styled.div`
  padding: 10px 0 20px;
  overflow: auto;
  width: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  ${({ fullWidth }) => fullWidth && fullWidthStyles}

  // Start Watcher
  & > :first-child {
    padding-left: ${({ carouselPadding }) => carouselPadding}px;

    ${({ fullWidth }) =>
      fullWidth &&
      `
      @media (min-width: 1320px) {
        padding-left: 0;
      }
    `}
  }

  // End Watcher
  // Needs to subtract 15px to account for the padding-right on the last card
  & > :last-child {
    padding-right: 5px;
  }
`;

export const Gradient = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: block;
    height: 100%;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.1s;
    width: 32px;
    z-index: 10;

    ${breakpoints.up('sm')} {
      width: 64px;
    }
  `}
`;

export const GradientLeft = styled(Gradient)`
  background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--fade-to-color) 100%);
  left: 0;
`;

export const GradientRight = styled(Gradient)`
  background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--fade-to-color) 100%);
  right: 0;
`;
