export type MarketplaceStrainTypeOption = 'HYBRID' | 'INDICA' | 'SATIVA';

export enum MarketplaceStrainTypeOptionsQueryParamMap {
  indica = `INDICA`,
  sativa = `SATIVA`,
  hybrid = `HYBRID`,
}

export type DispensaryStrainTypeOption = 'Hybrid' | 'Indica' | 'Sativa';

export enum DispensaryStrainTypeOptionsQueryParamMap {
  indica = `Indica`,
  sativa = `Sativa`,
  hybrid = `Hybrid`,
}
