import React from 'react';
import styled from 'styled-components';
import { LockIcon } from 'assets/lock-icon';
import { BankIcon } from 'assets/bank-icon';
import useTranslation from 'hooks/use-translation';
import { EllipsisLoader } from './ellipsis-loader';
import { TextCarousel } from './text-carousel';

type DutchiePayLoadingModalProps = {
  steps: string[];
};

export function DutchiePayLoadingView({ steps }: DutchiePayLoadingModalProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <LoadingContainer>
      <AnimationSection>
        <LockIcon />
        <LoaderContainer>
          <EllipsisLoader />
        </LoaderContainer>
        <BankIcon />
      </AnimationSection>
      <TextCarouselContainer>
        <TextCarousel steps={steps} />
      </TextCarouselContainer>
      <TextSection> {t('dutchiePay.takeAwhile', 'This may take awhile, please be patient.')}</TextSection>
    </LoadingContainer>
  );
}
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AnimationSection = styled.div`
  display: flex;
  align-items: center;
`;

const LoaderContainer = styled.div`
  margin: 0 10px;
`;

const TextCarouselContainer = styled.div`
  margin: 10px 0 15px 0;
`;

const TextSection = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[40]};
`;
