import { useEffect, useState } from 'react';
import _ from 'lodash';

export default function useHighlightAddressSuggestions(searchStr, results) {
  const [highlightedValues, setHighlightedValues] = useState();
  useEffect(() => {
    if (!searchStr && !results) {
      return;
    }
    const values = _.map(results, (result) => {
      const { address } = result;
      const normalizedAddress = _.toLower(address);
      const normalizedSearchStr = _.toLower(searchStr);
      if (_.includes(normalizedAddress, normalizedSearchStr)) {
        const splitAddress = _.split(address, RegExp(searchStr, `gi`));
        const formattedBoldText = searchStr.replace(/\w+/g, _.capitalize);
        return {
          ...result,
          bold: formattedBoldText,
          address: splitAddress[1],
        };
      }
      return {
        ...result,
      };
    });
    setHighlightedValues(values);
  }, [searchStr, results]);
  return highlightedValues;
}
