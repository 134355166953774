import React from 'react';
import Head from 'next/head';

export default function AppleTouchIcons() {
  return (
    <Head>
      <link rel='apple-touch-startup-image' href='/ItunesArtwork-dutchie@2x.png' />
    </Head>
  );
}
