import React from 'react';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { ModalObserver } from '../modal-observer';
import { ChangePasswordModal } from './change-password-modal';

export const ChangePassword = ({ name }: { name: string }): JSX.Element => (
  <ModalObserver name={name}>
    {(props) => (
      <ModalWrapper {...props}>
        <ChangePasswordModal {...props} />
      </ModalWrapper>
    )}
  </ModalObserver>
);
