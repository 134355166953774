import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["spinnerSize", "height", "width", "color"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { Flex } from 'rebass/styled-components';
export function FullPageLoader(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? '#61a58b' : _ref$color;
  return __jsx(Flex, {
    justifyContent: "center",
    alignItems: "center",
    style: {
      height: '50vh',
      minHeight: '700px'
    }
  }, __jsx(ClipLoader, {
    color: color,
    size: 100
  }));
}
/**
 * @type {JSX.Element}
 *
 */

export function SmallLoader(_ref2) {
  var _ref2$spinnerSize = _ref2.spinnerSize,
      spinnerSize = _ref2$spinnerSize === void 0 ? null : _ref2$spinnerSize,
      _ref2$height = _ref2.height,
      height = _ref2$height === void 0 ? 60 : _ref2$height,
      _ref2$width = _ref2.width,
      width = _ref2$width === void 0 ? null : _ref2$width,
      _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? '#61a58b' : _ref2$color,
      props = _objectWithoutProperties(_ref2, _excluded);

  return __jsx(Container, _extends({
    height: height,
    width: width
  }, props), __jsx(ClipLoader, {
    color: color,
    size: spinnerSize || height
  }));
}
var Container = styled(Flex).withConfig({
  displayName: "loading__Container",
  componentId: "sc-1w869cj-0"
})(["width:", ";height:", ";display:flex;justify-content:center;align-items:center;"], function (_ref3) {
  var width = _ref3.width;
  return width ? "".concat(width, "px") : '100%';
}, function (_ref4) {
  var height = _ref4.height;
  return "".concat(height - 10, "px");
});