import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { ModalContainer } from 'shared/modals/v2-container';
import { ModalContent as _ModalContent } from 'components/modals/components';
import { ModalObserver } from 'components/modals/modal-observer';
import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { TermsView } from 'src/payments/dutchie-pay/signup/views';
import { ModalObserverRenderProps } from 'src/components/modals/modal-observer/modal-observer.types';

export const TermsModal = ({ name }: { name: string }): JSX.Element => (
  <ModalObserver name={name}>
    {(props) => (
      <ModalWrapper disableBackdropClick disableEscapeKeyDown {...props}>
        <TermsModalContainer {...props} />
      </ModalWrapper>
    )}
  </ModalObserver>
);

const TermsModalContainer = forwardRef(({ containerProps }: ModalObserverRenderProps, ref) => (
  <ModalContainer ref={ref} {...containerProps} width='fit-content'>
    <ModalContent>
      <TermsView />
    </ModalContent>
  </ModalContainer>
));

const ModalContent = styled(_ModalContent)`
  max-width: 500px;
  text-align: left;
  padding-left: 0;
  padding-right: 0;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    padding-left: 0;
    padding-right: 0;
  }
`;
