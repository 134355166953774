import React from 'react';
import styled from 'styled-components';
import { space, width } from 'styled-system';
import _map from 'lodash/map';
import { useFormContext } from 'react-hook-form';

import useTranslation from 'hooks/use-translation';

import { statesMap } from 'shared/core/constants/geography';
import { FormTextInput, FormFormattedInput, FormSelectInput } from '../../form-inputs';

export const CO = `CO`;

const MedicalCardForm = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  const state = getValues(`state`);
  const showMedicalCardNumber = state !== CO;

  return (
    <MedicalCardFormContainer mt={29}>
      <InputContainer>
        <MedicalCardFormHeader>{t('signUp.medicalAge', 'Your age requires a Medical Card')}</MedicalCardFormHeader>
        {showMedicalCardNumber && (
          <FormTextInput
            name='medicalCardNumber'
            label={t('signUp.medicalCardNumber', 'Medical Card Number')}
            variant='filled'
            data-testid='medical-card-number'
          />
        )}
        <InlineContainer mt={18}>
          <InputContainer width='50%' mr={9}>
            <FormFormattedInput
              name='expirationDate'
              format='date'
              label={t('signUp.expirationDate', 'Expiration Date')}
              variant='filled'
              data-testid='expiration-date'
            />
          </InputContainer>

          <InputContainer width='50%' ml={9}>
            <FormSelectInput
              data-testid='state-select'
              name='state-select'
              label={t('stateSelect.state', `Issue State`)}
              variant='filled'
            >
              {_map(statesMap, (value, key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </FormSelectInput>
          </InputContainer>
        </InlineContainer>
      </InputContainer>
    </MedicalCardFormContainer>
  );
};

export default MedicalCardForm;

export const InputContainer = styled.div`
  ${width}
  ${space}
`;

const MedicalCardFormContainer = styled.div`
  ${space}
`;

const MedicalCardFormHeader = styled.p`
  margin-bottom: 18px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[35]};
  line-height: 150%;
  text-align: left;
`;

const InlineContainer = styled.div`
  display: flex;

  ${space}
`;
