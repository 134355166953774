import { useCallback } from 'react';

import useErnie from 'shared/hooks/use-ernie';
import { checkAllLimits } from 'shared/helpers/purchase-limits';

import { getCartAdditionAccess } from 'utils/helpers/dispensary';
import { cartItemId } from 'utils/helpers/product';

import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';
import useCustomerState from 'hooks/use-customer-state';

import { useCloseButModal } from 'components/modals/closed-but';
import { useStoreSwitchedModal } from 'components/modals/store-switched';

import { GqlDispensaries } from 'types/graphql';
import { AddToCartDataParam } from '../add-to-cart.types';

type Cart = {
  isEmpty: boolean;
  isMedical: boolean;
  dispensary?: GqlDispensaries;
  items: any;
};

function useStoreClosedHandler(cart: Cart, dispensary: GqlDispensaries): () => Promise<boolean> {
  const { isEmpty } = cart;
  const cartDispensaryId = cart.dispensary?.id;
  const dispensaryId = dispensary.id;
  const { previewMode } = useUI();
  const { open: openCloseButModal } = useCloseButModal();

  const cartAdditionAccess = getCartAdditionAccess(dispensary, previewMode);

  const isStoreClosed = cartAdditionAccess === 'afterHours';
  const isDifferentDispensaryCart = cartDispensaryId !== dispensaryId;

  return useCallback(
    async () =>
      (isStoreClosed && isEmpty) || (isStoreClosed && !isEmpty && isDifferentDispensaryCart)
        ? openCloseButModal()
        : true,
    [openCloseButModal, isEmpty, isStoreClosed, isDifferentDispensaryCart]
  );
}

function useStoreSwitchedHandler(cart: Cart, dispensary: GqlDispensaries): () => Promise<boolean> {
  const { isEmpty } = cart;
  const cartDispensaryId = cart.dispensary?.id;
  const dispensaryId = dispensary.id;

  const { open: openStoreSwitchedModal } = useStoreSwitchedModal();

  return useCallback(async () => (!isEmpty && cartDispensaryId !== dispensaryId ? openStoreSwitchedModal() : true), [
    cartDispensaryId,
    dispensaryId,
    isEmpty,
    openStoreSwitchedModal,
  ]);
}

function useLimitCheckHandler(cart: Cart, itemBeingAdded: AddToCartDataParam): () => boolean {
  const customerState = useCustomerState();
  const showErnie = useErnie();

  const { isMedical: medical, items: existingItems } = cart;
  const { dispensary, quantity, option } = itemBeingAdded;

  return useCallback(() => {
    const newItemId = cartItemId(itemBeingAdded);

    if (!quantity || !option) {
      return true;
    }

    const { withinLimits, message }: { withinLimits: boolean; message?: string } = checkAllLimits({
      newItemId,
      itemBeingAdded,
      existingItems,
      medical,
      dispensary,
      customerState,
    });

    if (!withinLimits) {
      showErnie(message);
      return false;
    }

    return true;
  }, [customerState, dispensary, existingItems, itemBeingAdded, medical, option, quantity, showErnie]);
}

export function usePrerequisiteChecks(data: AddToCartDataParam): () => Promise<boolean> {
  const cart: Cart = useCart();

  const { dispensary } = data;

  const handleCheckLimits = useLimitCheckHandler(cart, data);
  const handleStoreSwitched = useStoreSwitchedHandler(cart, dispensary);
  const handleStoreClosed = useStoreClosedHandler(cart, dispensary);

  // bail on first false
  return useCallback(async () => (await handleStoreClosed()) && (await handleStoreSwitched()) && handleCheckLimits(), [
    handleCheckLimits,
    handleStoreClosed,
    handleStoreSwitched,
  ]);
}
