import React, { useState } from 'react';
import styled from 'styled-components';

import { GreyCapsText, RangeSlider } from 'components/core';
import useTranslation from 'src/hooks/use-translation';
import { DistanceSliderProps } from '../location-settings-popover.types';

export function DistanceSlider({ distance: initialValue, onChangeDistance }: DistanceSliderProps): JSX.Element {
  const { t } = useTranslation();

  const [localValue, setLocalValue] = useState(initialValue);
  const valueText = t('locationSettings.distanceMiles', '{{localValue}} miles', { localValue });

  function handleChange(_e: React.ChangeEvent, value: number): void {
    setLocalValue(value);
  }

  return (
    <Container>
      <StyledGreyCapsText>{t('locationSettings.currentAddress', 'Distance')}</StyledGreyCapsText>
      <RangeSlider
        aria-labelledby='distance-text'
        getAriaLabel={() => valueText}
        getAriaValueText={() => valueText}
        min={1}
        max={50}
        onChange={handleChange}
        onChangeCommitted={onChangeDistance}
        value={localValue}
      />
      <DistanceText id='distance-text'>
        {t('locationSettings.shopFromDispensariesWithin', 'Shop from dispensaries within')} <b>{valueText}</b>
      </DistanceText>
    </Container>
  );
}

const Container = styled.div`
  padding: 30px 0;
  margin: 0 30px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.blueGrey[95] as string}`};
  .MuiSlider-root {
    padding-top: 15px;
    padding-bottom: 18px;
  }
`;

const StyledGreyCapsText = styled(GreyCapsText)`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.grey[70]};
`;

const DistanceText = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 14px;
`;
