import _ from 'lodash';
import { getProductWeight, saleSatisfiesWeightCondition } from 'shared/helpers/products';
import { Product } from 'utils/helpers/product';

import { MenuType } from './use-product-pricing.types';

enum DiscountType {
  dollarDiscount = 'dollarDiscount',
  percentDiscount = 'percentDiscount',
  targetPrice = 'targetPrice',
}

export type SpecialRestriction = {
  appliesToAnyWeight: boolean;
  discount?: number;
  discountType?: string;
  isPercentDiscount: boolean;
  weight?: number;
  weights?: number[];
  weightOperator?: 'equalTo' | 'greaterThan' | 'greaterThanEqualTo';
};

export function getIsSpecialApplicable(weight: string, specialRestrictions: SpecialRestriction[]): boolean {
  const weightValue = getProductWeight(weight);
  return specialRestrictions.some((special) =>
    saleSatisfiesWeightCondition(
      weightValue,
      special.weightOperator,
      special.weights ?? special.weight,
      special.appliesToAnyWeight
    )
  );
}

export type TransformSpecialRestrictionsProps = {
  menuType: MenuType;
  productSpecialsKeys: Set<string>;
  specialData: Product['specialData'];
};

export function transformSpecialRestrictions({
  menuType,
  productSpecialsKeys,
  specialData,
}: TransformSpecialRestrictionsProps): SpecialRestriction[] {
  const fullMenuType = { med: `medical`, rec: `recreational` };
  const restrictions: SpecialRestriction[] = [];

  if (!specialData?.saleSpecials || !Array.isArray(specialData.saleSpecials)) {
    return restrictions;
  }

  specialData.saleSpecials.forEach((special) => {
    // specials v3.5 support
    if (!_.isEmpty(special?.saleDiscounts)) {
      const saleDiscount = special?.saleDiscounts?.[0];
      if (
        !_.isEmpty(special?.eligibleProductOptions) &&
        _.includes(['both', menuType, fullMenuType[menuType]], special?.menuType)
      ) {
        restrictions.push({
          appliesToAnyWeight:
            _.isEmpty(saleDiscount?.weights) &&
            _.isEmpty(_.flatMap(saleDiscount?.exclusions, (exclusion) => exclusion?.weights ?? [])),
          discountType: saleDiscount?.discountType as DiscountType,
          isPercentDiscount: saleDiscount?.discountType === 'percentDiscount',
          weights: special?.eligibleProductOptions?.map((weight) => getProductWeight(weight)),
          weightOperator: 'equalTo',
        });
      }
      // < 3.5 specials
    } else if (special?.specialRestrictions) {
      const specialRestrictions = special.specialRestrictions ?? {};
      const discount = special.discount ?? undefined;
      const isPercentDiscount = special.percentDiscount ?? false;

      Object.entries(specialRestrictions).forEach((item: [string, any]) => {
        const [key, { weight, weights, weightOperator, discountType }] = item;

        const parsedWeights =
          weights?.length > 0
            ? weights?.map((currentWeight) => getProductWeight(currentWeight))
            : [getProductWeight(weight)];

        if (productSpecialsKeys.has(key) && _.includes(['both', menuType, fullMenuType[menuType]], special.menuType)) {
          restrictions.push({
            appliesToAnyWeight: !weight && !weights && !weightOperator,
            discount,
            discountType,
            isPercentDiscount,
            weight: weight && getProductWeight(weight),
            weights: parsedWeights,
            weightOperator,
          });
        }
      });
    }
  });

  return restrictions;
}

export function getProductSpecialsKeys({
  brandId,
  brand,
  brandName,
  type,
  subcategory,
  _id,
  id,
}: Product): Set<string> {
  const productSpecialsKeys = [brandId ?? brand.id, brandName ?? brand.name, type, subcategory, _id ?? id];
  const productSpecialsKeysSet = new Set<string>();

  productSpecialsKeys.forEach((key) => {
    if (typeof key === 'string') {
      productSpecialsKeysSet.add(key);
    }
  });

  return productSpecialsKeysSet;
}

export const calcDiscountPercentage = (specialPrice: number, price: number): number =>
  Math.round((1 - specialPrice / price) * 100);
