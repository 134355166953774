import styled from 'styled-components';
import { Flex } from 'rebass';
import { mediaQueries } from 'shared/styles';
import { position } from 'styled-system';
import ModalContainer from './container';
import ModalSecondaryHeader from './header';
import ModalClose from './close';
import ModalButton from './button';
import ModalCopy from './copy';
import FloatingModalCloseButton from './floating-close-button';
import { NotifyBlock, WhiteNotifyBlock, YellowNotifyBlock, RedNotifyBlock } from './notify-block';
import ModalPrimaryHeader from './fancy-header';
var ElementContainer = styled(Flex).withConfig({
  displayName: "modals__ElementContainer",
  componentId: "sc-18uzo0n-0"
})(["flex-direction:row;align-items:center;justify-content:flex-start;width:100%;margin-bottom:10px;", " @media ", "{flex-direction:column;align-items:flex-start;min-height:80px;}"], position, mediaQueries.largePhone);
var Label = styled.p.withConfig({
  displayName: "modals__Label",
  componentId: "sc-18uzo0n-1"
})(["font-weight:bold;color:#707478;font-size:13px;width:125px;flex-shrink:0;text-align:right;padding-right:35px;@media ", "{text-align:left;margin:10px 0px 3px 0px;}"], mediaQueries.largePhone);
export { ModalContainer, ModalSecondaryHeader, ModalPrimaryHeader, ModalClose, ModalButton, ModalCopy, FloatingModalCloseButton, ElementContainer, Label, NotifyBlock, WhiteNotifyBlock, YellowNotifyBlock, RedNotifyBlock };