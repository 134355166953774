import React from 'react';
import styled from 'styled-components';
import Button from 'components/core/button';
import InfoIcon from 'src/assets/info-icon';

const InfoButton = styled((props) => (
  <Button role='button' {...props}>
    <InfoIcon />
  </Button>
))`
  height: 18px;
  width: 18px;
  padding: 0;
  position: relative;
  left: 2px;
  top: -2px;
  display: inline-block;
  background-color: ${({ backgroundColor }) => backgroundColor || `transparent`};
  z-index: 10;
`;

export default InfoButton;
