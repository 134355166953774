import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import useErnie from 'shared/hooks/use-ernie';
import { Button } from 'components/core';
import useTranslation from 'hooks/use-translation';
import { StyledModalContainer, DutchieLogo, Heading, RememberMe, TermsAndPrivacy } from './components';

export default function AgeConfirmationModal(props) {
  const { onContinue, ageRestriction, hideDutchieBranding } = props;
  const [rememberMe, setRememberMe] = useState(true);
  const showErnie = useErnie();
  const { t } = useTranslation();

  // This effect removes focus-visible styles from button that is autoFocused when user navs to dutchie.com
  // via keyboard (entering URL in browser bar) and this modal pops. This only runs one time after component
  // initially renders and does not prevent focus-visible styles once user initiates keyboard interaction
  useEffect(() => {
    const buttonFocused = document.querySelector(`button.focus-visible`);
    if (buttonFocused) {
      buttonFocused.classList.remove(`focus-visible`);
    }
  }, []);

  function handleYes() {
    onContinue(ageRestriction, rememberMe);
  }
  function handleNo() {
    showErnie(
      t(
        'age-restrictions.error',
        "We're sorry, you must be at least 21 yrs old or a valid medical patient to use this site!"
      )
    );
  }

  return (
    <StyledModalContainer important isOpen data-testid='age-confirmation-modal'>
      {!hideDutchieBranding && <DutchieLogo src='/icons/dutchie-wordmark.svg' alt='Dutchie Logo' />}
      <Heading>
        {t('age-restrictions.usa-header', {
          defaultValue: 'Are you at least {{ageRestriction}} yrs old or a valid medical patient?',
          ageRestriction,
        })}
      </Heading>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <StyledButton
            aria-label='yes button'
            data-testid='age-restriction-yes'
            variant='flora'
            onClick={handleYes}
            autoFocus
          >
            {t('common.yes', 'Yes')}
          </StyledButton>
        </Grid>
        <Grid item xs={6}>
          <StyledButton
            onClick={handleNo}
            aria-label='no button'
            data-cy='age-restriction-no'
            data-test='age-restriction-no'
            variant='outline'
          >
            {t('common.no', 'No')}
          </StyledButton>
        </Grid>
      </Grid>
      <RememberMe value={rememberMe} onChange={setRememberMe} />
      <TermsAndPrivacy />
    </StyledModalContainer>
  );
}

const StyledButton = styled(Button)`
  width: 100%;
  height: 49px;
  margin-bottom: 27px;
`;
