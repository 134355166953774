/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';

import { NextRouter, useRouter } from 'next/router';
import { useObserver } from 'mobx-react-lite';

import { useStores } from 'contexts/stores';
import useErnie from 'shared/hooks/use-ernie';

import { parsePlaidMetaData } from 'src/payments/utils/parse-meta-data';
import completeAggregatorLink from 'src/payments/graphql/mutations/complete-aggretator-link.gql';
import { useDutchiePay, WhiteLabelConfig } from 'src/payments/hooks/use-dutchie-pay/use-dutchie-pay';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import {
  AGGREGATORS,
  ConnectedBankAccountDetails,
  ConnectionStatus,
  DUTCHIE_PAY_QUERY_PARMAS_KEY,
  editBankAccount,
  EnrollmentSteps,
  PLAID_OAUTH_PATH,
  PLAID_STORAGE_KEY,
} from 'src/payments/constants';
import {
  ENROLLMENT_EVENTS,
  EVENT_ACTIONS,
  useDutchiePayAnalytics,
  DutchiePayEventTracker,
} from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import { formatPaymentsError } from 'src/payments/hooks/use-payments-error/use-payments-error';

type SuccessConfig = {
  publicToken: string;
  metadata: PlaidLinkOnSuccessMetadata;
};

export type OnSuccessArgs = {
  apolloClient: any;
  clearPlaidData: () => void;
  completeAggregatorLinkStarted: () => void;
  completeAggregatorLinkSucceeded: (details: ConnectedBankAccountDetails) => void;
  dutchiePayEventTracker: DutchiePayEventTracker;
  handleEmbeddedDutchiePayLink: () => void;
  isOAuthRedirect: boolean;
  metadata: PlaidLinkOnSuccessMetadata;
  paymentMethodId?: string;
  plaidLinkExited: () => void;
  publicToken: string;
  queryParams: string;
  router: NextRouter;
  showErnie: (
    msg?: string | undefined,
    type?: 'danger' | 'error' | 'info' | 'success' | undefined,
    timeout?: number | undefined,
    reasonCode?: string | undefined
  ) => void;
  userFullName: string;
  usePaymentMethod: boolean;
  whiteLabelConfig: WhiteLabelConfig;
};

type PlaidLinkUtils = {
  handleSuccess: ({ publicToken, metadata }: SuccessConfig) => Promise<void>;
};

export const onSuccess = async ({
  apolloClient,
  clearPlaidData,
  completeAggregatorLinkStarted,
  completeAggregatorLinkSucceeded,
  dutchiePayEventTracker,
  handleEmbeddedDutchiePayLink = () => {},
  isOAuthRedirect,
  metadata,
  paymentMethodId = '',
  plaidLinkExited,
  publicToken,
  queryParams,
  router,
  showErnie,
  userFullName,
  usePaymentMethod,
  whiteLabelConfig,
}: OnSuccessArgs): Promise<void> => {
  completeAggregatorLinkStarted();
  const formattedMetaData = parsePlaidMetaData({ metadata });

  try {
    const response = await apolloClient.mutate({
      mutation: completeAggregatorLink,
      variables: {
        aggregatorToken: publicToken,
        aggregator: AGGREGATORS.PLAID,
        plaid: { ...formattedMetaData },
        paymentMethodId: usePaymentMethod ? paymentMethodId : '',
      },
    });

    const pendingPaymentMethod = response.data.completeAggregatorLink.paymentMethods.filter(
      (method) => method.status === 'pending'
    );

    if (pendingPaymentMethod) {
      completeAggregatorLinkSucceeded({
        lastDigits: metadata.accounts[0].mask,
        bankName: metadata.institution?.name ?? '',
        id: pendingPaymentMethod[0].id,
        ownerName: userFullName,
        accountName: metadata.accounts[0].name,
      });
    }

    dutchiePayEventTracker(ENROLLMENT_EVENTS.ENROLLMENT_STEP, {
      step: EnrollmentSteps.connectBank,
      action: EVENT_ACTIONS.SUCCEEDED,
    });

    handleEmbeddedDutchiePayLink();

    if (isOAuthRedirect && queryParams) {
      void router.replace({
        query: { ...router.query, ...JSON.parse(queryParams) },
      });
    }
  } catch (err) {
    console.error(err);
    const { errorMarkdown, ernieType, ernieTimeout } = formatPaymentsError(err, whiteLabelConfig.brandedDutchiePayName);
    showErnie(errorMarkdown, ernieType, ernieTimeout);
    plaidLinkExited();
  } finally {
    clearPlaidData();
  }
};

export const usePlaidLinkUtils = ({ handleEmbeddedDutchiePayLink }): PlaidLinkUtils => {
  const apolloClient = useApolloClient();
  const queryParams = localStorage.getItem(DUTCHIE_PAY_QUERY_PARMAS_KEY);

  const { completeAggregatorLinkSucceeded, plaidLinkExited, completeAggregatorLinkStarted } = useDutchiePayEnrollment();
  const { paymentMethodId, connectionStatus, whiteLabelConfig } = useDutchiePay();
  const { dutchiePayEventTracker } = useDutchiePayAnalytics();

  const router = useRouter();
  const isOAuthRedirect = router.asPath.includes(PLAID_OAUTH_PATH);

  const { User } = useStores();
  const userFullName = useObserver(() => User.fullName);

  const editBankAccountEnabled = router.query.step === editBankAccount;
  const usePaymentMethod = Boolean(
    paymentMethodId && !editBankAccountEnabled && connectionStatus !== ConnectionStatus.revoked
  );

  const showErnie = useErnie();

  const clearPlaidData = (): void => {
    localStorage.removeItem(DUTCHIE_PAY_QUERY_PARMAS_KEY);
    localStorage.removeItem(PLAID_STORAGE_KEY);
  };

  const handleSuccess: ({ publicToken, metadata }) => Promise<void> = useCallback(
    ({ publicToken, metadata }) =>
      onSuccess({
        apolloClient,
        clearPlaidData,
        completeAggregatorLinkStarted,
        completeAggregatorLinkSucceeded,
        dutchiePayEventTracker,
        handleEmbeddedDutchiePayLink,
        isOAuthRedirect,
        metadata,
        paymentMethodId,
        plaidLinkExited,
        publicToken,
        queryParams: queryParams ?? '',
        router,
        showErnie,
        userFullName,
        usePaymentMethod,
        whiteLabelConfig,
      }),
    [
      apolloClient,
      completeAggregatorLinkStarted,
      completeAggregatorLinkSucceeded,
      dutchiePayEventTracker,
      handleEmbeddedDutchiePayLink,
      isOAuthRedirect,
      paymentMethodId,
      plaidLinkExited,
      queryParams,
      router,
      showErnie,
      userFullName,
      usePaymentMethod,
      whiteLabelConfig,
    ]
  );

  return {
    handleSuccess,
  };
};
