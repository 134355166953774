import _get from "lodash/get";
import styled, { css } from 'styled-components';
var backgroundColorMapping = {
  danger: '#da5347',
  error: '#da5347',
  info: '#1b9edb',
  success: '#75ba50'
};
export var Container = styled.div.withConfig({
  displayName: "styled-components__Container",
  componentId: "sc-1szy0r8-0"
})(["box-sizing:border-box;align-items:center;background-color:", ";bottom:0;display:flex;flex-direction:row;height:61px;left:0;justify-content:flex-start;opacity:", ";padding:15px;position:fixed;right:0;top:-61px;transform:", ";transition:0.2s transform,0.2s opacity;z-index:9999;", ""], function (props) {
  return _get(backgroundColorMapping, props.type, backgroundColorMapping.danger);
}, function (props) {
  return props.visible ? 1 : 0;
}, function (props) {
  return props.visible ? 'translate3d(0, 61px, 0)' : 'translate3d(0, 0, 0)';
}, function (_ref) {
  var isIframe = _ref.isIframe,
      iframeOffset = _ref.iframeOffset,
      parentOffset = _ref.parentOffset;
  return isIframe && css(["position:absolute;top:", "px;"], parentOffset + iframeOffset - 61);
});
export var IconContainer = styled.div.withConfig({
  displayName: "styled-components__IconContainer",
  componentId: "sc-1szy0r8-1"
})(["align-items:center;background-color:rgba(0,0,0,0.3);border-radius:3px;display:flex;height:32px;justify-content:center;margin-right:15px;width:32px;"]);
export var Message = styled.span.withConfig({
  displayName: "styled-components__Message",
  componentId: "sc-1szy0r8-2"
})(["color:#fff;font-size:14px;a{text-decoration:underline;}"]);
export default {
  Container: Container,
  IconContainer: IconContainer,
  Message: Message
};