import React from 'react';
import _ from 'lodash';
import { useRouter } from 'next/router';
import moment from 'moment';
import { useObserver } from 'mobx-react-lite';

import { formatName } from 'shared/helpers/users';

import useUser from 'hooks/use-user';
import useErnie from 'shared/hooks/use-ernie';
import useTranslation from 'hooks/use-translation';
import useFeatureFlags from 'src/hooks/use-feature-flags';

import ROUTES from 'src/routes';
import MyAccountDrawer from './my-account-drawer';

export default function MyAccountDrawerWrapper({ onClose, open }) {
  const { route } = useRouter();
  const user = useUser();
  const showErnie = useErnie();
  const { t } = useTranslation();
  const { ACCOUNT, PREFERENCES, ORDERS } = ROUTES;
  const shouldReplaceHistory = _.includes([ACCOUNT, PREFERENCES, ORDERS], route);

  const userName = useObserver(() => formatName(user.profile));
  const joinDate = useObserver(() => user.createdAt) || `2017-07-15T00:00:00.000Z`;
  const joinedDateString = moment(joinDate).format(`MMMM, YYYY`);

  const featureFlags = useFeatureFlags();
  const isAccountSettingsDutchiePayEnabled = featureFlags.flags['ecomm.menu.account-manage-dutchie-pay.rollout'];

  const handleLogout = () => {
    user.logout();
    showErnie(t('ernie.logout', "You've been successfully logged out."), 'success');
  };

  return (
    <MyAccountDrawer
      userName={userName}
      joinedDateString={joinedDateString}
      onClose={onClose}
      open={open}
      onLogout={handleLogout}
      shouldReplaceHistory={shouldReplaceHistory}
      isAccountSettingsDutchiePayEnabled={isAccountSettingsDutchiePayEnabled}
    />
  );
}
