import React from 'react';
import styled from 'styled-components';

import { LocationObject } from 'shared/helpers/mapbox';

type AddressTextProps = { suggestion: LocationObject; searchStr: string; wrapText?: boolean };
export function AddressText({ suggestion, searchStr, wrapText = true }: AddressTextProps): JSX.Element {
  const ln1Display = getLn1Display(suggestion, wrapText);
  const ln2Display = getLn2Display(suggestion, wrapText);
  return (
    <TextWrapper>
      {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
      <Ln1 dangerouslySetInnerHTML={{ __html: boldString(ln1Display, searchStr) }} />
      {ln2Display && <Ln2>{ln2Display}</Ln2>}
    </TextWrapper>
  );
}

function boldString(str: string, find: string): string {
  const reg = new RegExp(`(${find})`, `gi`);
  return str.replace(reg, `<b>$1</b>`);
}

function getLn1Display(suggestion: LocationObject, wrapText: boolean): string {
  const { ln1, address } = suggestion;
  return wrapText && ln1 ? ln1 : address;
}

function getLn2Display(suggestion: LocationObject, wrapText: boolean): string | null {
  const { ln1, city, state, zipcode } = suggestion;
  return wrapText && ln1 ? `${city && `${city}, `}${state && `${state} `}${zipcode && zipcode}` : null;
}

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Ln1 = styled.div`
  font-size: 14px;
  line-height: 22px;
`;

const Ln2 = styled.span`
  font-size: 13px;
  line-height: 17px;
`;
