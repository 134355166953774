import React from 'react';
import styled from 'styled-components';

import { Tabs, Tab } from './tabs';
import TabLabel from './tab-label';

export const VerticalTabs = ({ value, onChange, ...props }) => (
  <StyledTabs value={value} orientation='vertical' onChange={onChange} {...props} />
);

export const VerticalTab = ({ icon, label, value, ...props }) => (
  <StyledTab label={<TabLabel icon={icon} label={label} />} value={value} {...props} />
);

const StyledTabs = styled(Tabs)`
  width: 100%;

  &.MuiTabs-root {
    border-bottom: unset;
  }

  .MuiTabs-indicator {
    background-color: ${({ theme }) => `${theme.customized.colors.buttonsLinks} !important`};
  }
`;

const StyledTab = styled(Tab)`
  height: 47px;

  &.MuiTab-root {
    margin-bottom: 15px;
  }

  &.Mui-selected {
    color: ${({ theme }) => `${theme.customized.colors.buttonsLinks} !important`};
  }
`;
