import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Button } from 'src/components/core';
import { PayByBankKioskError } from 'src/assets/pay-by-bank-kiosk-error';
import { Typography } from 'src/typography';
import { useCountdownTimer } from 'src/hooks/use-countdown-timer';

type ErrorContentProps = {
  onClose: () => void;
};

export function ErrorContent({ onClose }: ErrorContentProps): JSX.Element {
  const { secondsLeft, startTimer } = useCountdownTimer({ initialSeconds: 8, onTimerStop: onClose });

  useEffect(() => {
    startTimer();
  }, [startTimer]);

  return (
    <Content data-testid='error-content'>
      <PayByBankKioskError />
      <TextContainer>
        <Heading tag='h1' size='medium'>
          Something went wrong with your payment selection, please pay at the counter.
        </Heading>
        <MainText size='large'>Your order is being prepared, and will be ready momentarily.</MainText>
      </TextContainer>
      <ButtonContainer>
        <CloseButton data-testid='error-content-close-button' onClick={onClose} size='large'>
          Close
        </CloseButton>
        <SubText size='small'>{`This window will automatically close in ${secondsLeft} seconds...`}</SubText>
      </ButtonContainer>
    </Content>
  );
}

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[8]};
  justify-content: center;
  margin-top ${({ theme }) => theme.spaces[6]};
  max-width: 390px;
  text-align: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]};
`;

const Heading = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
`;

const MainText = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.grey[30]};
  font-size: 16px;
  line-height: 24px;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]};
  width: 100%;
`;

const CloseButton = styled(Button)`
  width: 80%;
`;

const SubText = styled(Typography.Body)`
  color: #717f89;
  font-size: 13px;
  line-height: 18px;
  weight: 400;
`;
