import _ from 'lodash';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const captureParams = _.once((params, Cart, viewingDispensary) => {
  Cart.setCampaignData(params, viewingDispensary.id);
});

export function useUTM({ viewingDispensary, Cart }) {
  const { query } = useRouter();

  useEffect(() => {
    if (viewingDispensary) {
      captureParams(query, Cart, viewingDispensary);
    }
    // deps list intentionally only has dispensary, as we generally don't want this running more than once
    // we only have dispensary here because it cannot run without it, so if it's not there first render we
    // need to try again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewingDispensary]);
}
