import { useCallback } from 'react';

import { productIsPartOfActiveSpecial } from 'shared/helpers/specials';
import { getBogoDiscountForAddedItems } from 'shared/order/bogo';

import { Product } from 'utils/helpers/product';
import { GqlDispensaries } from 'types/graphql';

import useCart from 'hooks/use-cart';

import { PriceOption } from 'hooks/use-product-pricing';

export type Offer = {
  price: number;
  discount: number;
  formattedDiscount: string;
  formattedPrice: string;
};

export type DiscountOffer = {
  price: number;
  offer: Offer;
  formattedPrice: string;
};

type UseProductOfferingReturn = {
  getProductHasOffers: (product: Product) => boolean;
  getProductDiscountOffer: (
    product: Product,
    dispensary: GqlDispensaries,
    priceOption: PriceOption,
    quantity: number
  ) => DiscountOffer | null;
};

function getPriceForOffersDiscount(priceOption: PriceOption, discountBehavior?: string): number {
  const standardPrice = priceOption.price;
  const specialPrice = priceOption.special?.price;
  const listPrice = specialPrice ?? standardPrice;

  return discountBehavior === 'compounding' ? listPrice : standardPrice;
}

export function useProductOffering(): UseProductOfferingReturn {
  const { menuType, costBreakdown } = useCart();

  const costBreakdownDetails = costBreakdown?.details;

  const getProductHasOffers = useCallback(
    (product: Product): boolean => productIsPartOfActiveSpecial(product, menuType),
    [menuType]
  );

  const getProductDiscountOffer = useCallback(
    (
      product: Product,
      dispensary: GqlDispensaries,
      priceOption: PriceOption,
      quantity: number
    ): DiscountOffer | null => {
      const { specialsSettings } = dispensary;
      const discountBehavior = specialsSettings?.discountBehavior ?? undefined;
      const price = getPriceForOffersDiscount(priceOption, discountBehavior);

      const discountBig = getBogoDiscountForAddedItems(
        costBreakdownDetails,
        price,
        product,
        priceOption.value,
        quantity,
        specialsSettings
      );

      if (!discountBig) {
        return null;
      }

      const discount = Number(discountBig);
      const discountPrice = Math.round(price - discount);

      return {
        price,
        formattedPrice: `$${price.toFixed(2)}`,
        offer: {
          price: discountPrice,
          formattedPrice: `$${discountPrice.toFixed(2)}`,
          discount,
          formattedDiscount: `$${discount.toFixed(2)} discount applied!`,
        },
      };
    },
    [costBreakdownDetails]
  );

  return {
    getProductHasOffers,
    getProductDiscountOffer,
  };
}
