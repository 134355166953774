import React from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react';

import useTranslation from 'hooks/use-translation';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { useCreateBankAccount, useCreateMxEnrollment } from 'src/payments/utils';

import { DutchiePayButtonContainer, DutchiePayHeader, DutchiePayTextBody } from 'src/payments/components';
import { AccountSelectorRadioGroup } from './account-selector-radio-group';

export function BankAccountSelector(): JSX.Element {
  const { t, Trans } = useTranslation();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { createMxBankAccount } = useCreateBankAccount();
  const { createMxEnrollmentMutation } = useCreateMxEnrollment();

  const mxBankAccounts = useObserver(() => DutchiePayEnrollment.mxBankAccounts);
  const mxSelectedAccountGuid = useObserver(() => DutchiePayEnrollment.mxSelectedAccountGuid);

  const onRadioChange = (value): void => {
    DutchiePayEnrollment.mxAccountSelected(value);
  };
  const address = useObserver(() => DutchiePayEnrollment.address);
  return (
    <>
      <DutchiePayHeader>{t(`dutchiePay.selectAccount`, 'Select account')}</DutchiePayHeader>
      <DutchiePayTextBody>
        <Trans i18nKey='dutchiePay.selectAccountSubText'>Choose the account you'll use for payments.</Trans>
      </DutchiePayTextBody>
      <FormInputsContainer>
        <AccountSelectorRadioGroup
          options={mxBankAccounts}
          checked={mxSelectedAccountGuid}
          onChange={(e) => onRadioChange(e)}
          name='bankAccountRadio'
          required
        />
      </FormInputsContainer>
      <DutchiePayButtonContainer
        onClick={createMxBankAccount}
        disabled={!mxSelectedAccountGuid}
        showBackLink
        onBackLinkClick={() => createMxEnrollmentMutation(address)}
      >
        {t('common.continue', 'Continue')}
      </DutchiePayButtonContainer>
    </>
  );
}

export const FormInputsContainer = styled.div`
  max-height: 345px;
  overflow: auto;
  margin: 0 -24px 20px;

  // For Firefox
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grey[80]};
    border-radius: 6px;
  }
`;
