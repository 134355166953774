import React from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { useRouter } from 'next/router';

import useUI from 'src/hooks/use-ui';
import { useIdleWarningModal } from 'src/components/modals/idle-warning';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

export function IdleDetection({ children }: { children: JSX.Element }): JSX.Element {
  const { isKiosk } = useUI();
  const { open: openIdleWarningdModal } = useIdleWarningModal();
  const { pathname } = useRouter();
  const { dispensary: { orderTypesConfigV2 = {} } = {} } = useDispensary();
  const { kiosk } = orderTypesConfigV2;
  const isTimeoutEnabled = kiosk?.timeout?.enabled;
  const timeoutDurationInSeconds = kiosk?.timeout?.duration ?? 30;
  const timeoutDurationInMilliseconds = timeoutDurationInSeconds * 1000;

  const onIdle = (): void => {
    void openIdleWarningdModal();
  };

  // Don't use idle detection if it's not kiosk,
  // not enabled in admin,
  // or if we are already on the kiosk lockscreen
  if (!isKiosk || !isTimeoutEnabled || pathname === `/kiosks/[cName]/lock`) {
    return children;
  }

  return (
    <IdleTimerProvider timeout={timeoutDurationInMilliseconds} onIdle={onIdle}>
      {children}
    </IdleTimerProvider>
  );
}
