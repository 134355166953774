import { useEffect, useState, useCallback } from 'react';

type UseCountdownTimerParams = {
  initialSeconds: number;
  onTimerStop: () => void;
};

type UseCountdownTimerReturn = {
  secondsLeft: number;
  startTimer: () => void;
};

export function useCountdownTimer({ initialSeconds, onTimerStop }: UseCountdownTimerParams): UseCountdownTimerReturn {
  const [secondsLeft, setSecondsLeft] = useState(initialSeconds);
  const [timerRunning, setTimerRunning] = useState(false);

  useEffect(() => {
    if (!timerRunning) {
      return undefined;
    }

    const interval = setInterval(() => {
      setSecondsLeft(secondsLeft - 1);
    }, 1_000);

    return () => clearInterval(interval);
  }, [secondsLeft, timerRunning]);

  const startTimer = useCallback(() => {
    setTimerRunning(true);
  }, []);

  if (secondsLeft <= 0) {
    onTimerStop();
  }

  return {
    secondsLeft,
    startTimer,
  };
}
