import { observer } from 'mobx-react-lite';

import { ModalObserverRenderProps } from './modal-observer.types';
import { useModalObserver } from './use-modal-observer';

type ModalObserverProps = {
  name: string;
  children: (props: ModalObserverRenderProps) => JSX.Element;
};

export const ModalObserver = observer(({ name, children }: ModalObserverProps): JSX.Element | null => {
  const props = useModalObserver({ name });

  return props.open ? children(props) : null;
});
