import React, { forwardRef } from 'react';

import useTranslation from 'hooks/use-translation';
import { BaseModal } from '../base-modal';
import { DutchiePayHeader, DutchiePayTextBody } from '../../components';
import { DutchiePayLeaveButton } from './leave-now-button';

type LearnMoreModalProps = {
  onCloseModal: () => void;
};

export const DutchiePayCancelModal = forwardRef<JSX.Element, LearnMoreModalProps>(({ onCloseModal }, ref) => {
  const { t, Trans } = useTranslation();

  return (
    <BaseModal ref={ref} onCloseModal={onCloseModal}>
      <DutchiePayHeader>{t('dutchiePay.leaveSignUp', 'Leave sign up?')}</DutchiePayHeader>
      <DutchiePayTextBody>
        <Trans i18nKey='dutchiePay.returnedToCart'>
          You will be returned to your cart and you can use other payment options.
        </Trans>
      </DutchiePayTextBody>

      <DutchiePayLeaveButton onCloseModal={onCloseModal} />
    </BaseModal>
  );
});
