import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import geocodingService from '@mapbox/mapbox-sdk/services/geocoding';
import { useCallback, useEffect, useRef, useState } from 'react';

import { buildLocationObjectFromMapboxFeature } from 'shared/helpers/mapbox';
import { supportedCountries } from 'shared/core/constants/geography';
import { MAPBOX_TOKEN } from 'shared/constants';
import useGeoinfo from 'hooks/use-geoinfo';

const geocodingClient = geocodingService({ accessToken: MAPBOX_TOKEN });

const useLatest = (val) => {
  const ref = useRef(val);

  useEffect(() => {
    ref.current = val;
  }, [val]);

  return ref;
};

/**
 * This hook is designed similarly to https://www.npmjs.com/package/use-places-autocomplete and can be used almost
 * the exact same way. The requestOptions are only a bit different due to Mapbox
 *
 * To view possible requestOptions, look at the config object here:
 * https://github.com/mapbox/mapbox-sdk-js/blob/master/docs/services.md#forwardgeocode
 *
 * debounce controls the amount of time between input change before additional requests
 * are mare to Mapbox's places API
 *
 * formatResults will make use of buildLocationObjectFromMapboxFeature to return a list
 * of suggestions formatted to the location object style we use in our apps
 */
const useMapboxPlacesAutocomplete = ({ debounce = 300, formatResults = true, requestOptions } = {}) => {
  // eslint-disable-next-line no-unused-vars
  const [geoinfo, _setRegion, loading] = useGeoinfo();

  const [value, setVal] = useState('');
  const [initialFetch, setInitialFetch] = useState(true);
  const [suggestions, setSuggestions] = useState({
    loading: false,
    status: '',
    data: [],
  });
  const requestOptionsRef = useLatest(requestOptions);

  const clearSuggestions = useCallback(() => {
    setSuggestions({ loading: false, status: '', data: [] });
    setInitialFetch(false);
  }, []);

  const fetchSuggestions = useCallback(
    _debounce(async (val) => {
      if (!val) {
        clearSuggestions();
        return;
      }

      setSuggestions((prevState) => ({ ...prevState, loading: true }));
      const request = geocodingClient.forwardGeocode({
        countries: supportedCountries,
        proximity: (() => {
          if (geoinfo?.loc) {
            const [lat, lon] = geoinfo.loc.split(',');
            return [parseFloat(lon), parseFloat(lat)];
          }
          return undefined;
        })(),
        types: ['address', 'country', 'district', 'locality', 'neighborhood', 'place', 'postcode', 'region'],
        ...requestOptionsRef.current,
        query: val,
      });
      const response = await request.send();
      if (!response || !response.statusCode === 200) {
        console.error('Problem attempting to get Mapbox location suggestions: ', response);
      } else {
        const features = _get(response, 'body.features', []);
        setSuggestions({
          data: formatResults ? features.map((feature) => buildLocationObjectFromMapboxFeature(feature)) : features,
          loading: false,
          status: _get(response, 'statusCode', 200),
        });
        setInitialFetch(false);
      }
    }, debounce),
    [clearSuggestions, debounce]
  );

  const setValue = useCallback(
    (val, shouldFetchData = true) => {
      setVal(val);
      if (shouldFetchData) {
        fetchSuggestions(val);
      }
    },
    [fetchSuggestions]
  );

  return { ready: !loading, value, suggestions, setValue, clearSuggestions, initialFetch };
};

export default useMapboxPlacesAutocomplete;
