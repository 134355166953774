import React from 'react';
import styled from 'styled-components';

export const SkipLink = (): JSX.Element => <StyledAnchor href='#main-content'>Skip Navigation</StyledAnchor>;

const StyledAnchor = styled.a`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  z-index: 9999;

  &:focus {
    background: ${({ theme }) => theme.customized.colors.buttonsLinks};
    color: #fff;
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    padding: 4px 8px;
    width: auto;
  }
`;
