import React from 'react';
import { DesktopOnly, MobileOnly } from 'shared/components';

import { withModalObserver, ModalObserverRenderProps } from '../modal-observer';
import { SelectStoreModal } from './modal';
import { SelectStoreBottomDrawer } from './drawer';
import { useSelectStore } from './use-select-store';
import { useMarketplaceDispensaries } from './use-marketplace-dispensaries';

export const SelectStoreWrapper = ({ ...props }: ModalObserverRenderProps): JSX.Element => {
  const selectStoreProps = useSelectStore();
  const { dispensaries } = useMarketplaceDispensaries();

  return (
    <>
      <MobileOnly>
        <SelectStoreBottomDrawer dispensaries={dispensaries} {...selectStoreProps} {...props} />
      </MobileOnly>

      <DesktopOnly>
        <SelectStoreModal dispensaries={dispensaries} {...selectStoreProps} {...props} />
      </DesktopOnly>
    </>
  );
};

export const SelectStoreController = withModalObserver(SelectStoreWrapper);
