import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _includes from "lodash/includes";
var _excluded = ["className", "displayImage", "displayName", "enableRightMargin", "isSingularCard", "specialId"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Truncate from 'react-truncate';
import { observer } from 'mobx-react-lite';
import { DEFAULT_SPECIALS_CARD_IMG } from 'shared/constants';
import { replaceAWSSourceWithImgix } from 'shared/helpers/imgix';
import { EmbeddedExternalLink, Link } from 'components/core';
import useUI from 'hooks/use-ui';
import usePaths from 'hooks/use-paths';

function OffersCarouselCard(_ref) {
  var _ref$className = _ref.className,
      className = _ref$className === void 0 ? "offers" : _ref$className,
      displayImage = _ref.displayImage,
      displayName = _ref.displayName,
      _ref$enableRightMargi = _ref.enableRightMargin,
      enableRightMargin = _ref$enableRightMargi === void 0 ? true : _ref$enableRightMargi,
      isSingularCard = _ref.isSingularCard,
      specialId = _ref.specialId,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx(AspectRatioOuter, {
    className: className,
    enableRightMargin: enableRightMargin,
    isSingularCard: isSingularCard
  }, __jsx(AspectRatioInner, null, __jsx(LinkComponent, {
    specialId: specialId
  }, __jsx(OffersCardContainer, _extends({
    className: className,
    "data-cy": "offers-carousel-card",
    "data-test": "offers-carousel-card",
    imageUrl: replaceAWSSourceWithImgix(displayImage) || DEFAULT_SPECIALS_CARD_IMG,
    isSingularCard: isSingularCard
  }, props), __jsx(TextContainer, null, __jsx(Title, null, __jsx(Truncate, {
    lines: 2
  }, displayName))), __jsx(ActionsContainer, null, __jsx(ShopButton, null, "Shop"))))));
}

var LinkComponent = observer(function (_ref2) {
  var children = _ref2.children,
      specialId = _ref2.specialId;

  var _useUI = useUI(),
      isEmbeddedCarousel = _useUI.isEmbeddedCarousel;

  var _usePaths = usePaths({
    specials: true
  }),
      href = _usePaths.href;

  var offerUrl = "".concat(href, "/offer/").concat(specialId);

  if (isEmbeddedCarousel) {
    return __jsx(EmbeddedExternalLink, {
      href: offerUrl
    }, children);
  }

  return __jsx(Link, {
    href: offerUrl
  }, children);
});
var ShopButton = styled.button.withConfig({
  displayName: "offers-carousel-card__ShopButton",
  componentId: "sc-1qoq2h7-0"
})(["background:transparent;border-radius:", ";border:2px solid #ffffff;color:#ffffff;font-size:12px;font-weight:bold;height:32px;line-height:13px;text-transform:", ";width:65px;&:hover{cursor:pointer;}"], function (_ref3) {
  var _theme$customized$rad, _theme$customized, _theme$customized$rad2;

  var theme = _ref3.theme;
  return (_theme$customized$rad = theme === null || theme === void 0 ? void 0 : (_theme$customized = theme.customized) === null || _theme$customized === void 0 ? void 0 : (_theme$customized$rad2 = _theme$customized.radius) === null || _theme$customized$rad2 === void 0 ? void 0 : _theme$customized$rad2.buttons) !== null && _theme$customized$rad !== void 0 ? _theme$customized$rad : '20px';
}, function (_ref4) {
  var _theme$customized$tex, _theme$customized2, _theme$customized2$te;

  var theme = _ref4.theme;
  return (_theme$customized$tex = theme === null || theme === void 0 ? void 0 : (_theme$customized2 = theme.customized) === null || _theme$customized2 === void 0 ? void 0 : (_theme$customized2$te = _theme$customized2.textTransforms) === null || _theme$customized2$te === void 0 ? void 0 : _theme$customized2$te.buttons) !== null && _theme$customized$tex !== void 0 ? _theme$customized$tex : 'uppercase';
});
var ActionsContainer = styled.div.withConfig({
  displayName: "offers-carousel-card__ActionsContainer",
  componentId: "sc-1qoq2h7-1"
})([""]);
var Title = styled.p.withConfig({
  displayName: "offers-carousel-card__Title",
  componentId: "sc-1qoq2h7-2"
})(["font-weight:bold;font-size:16px;line-height:120%;color:#ffffff;text-shadow:0px 2px 4px rgba(152,163,173,0.36);width:100%;word-break:break-word;"]);
var TextContainer = styled.div.withConfig({
  displayName: "offers-carousel-card__TextContainer",
  componentId: "sc-1qoq2h7-3"
})(["display:flex;align-items:center;justify-content:center;padding-bottom:24px;max-width:232px;"]);
var AspectRatioOuter = styled.div.withConfig({
  displayName: "offers-carousel-card__AspectRatioOuter",
  componentId: "sc-1qoq2h7-4"
})(["border-radius:20px;position:relative;width:100%;min-width:310px;margin-right:14px;scroll-margin:14px;scroll-snap-align:start;scroll-snap-stop:normal;&.last-card{&:after{content:'';display:block;background:transparent;position:absolute;right:-14px;top:0px;bottom:0;left:100%;}}@media (max-width:600px){", "}@media (min-width:600px){min-width:260.25px;width:260.25px;&.last-card{&:after{right:", ";}}}@media (min-width:750.5px){min-width:240.833px;width:240.833px;}@media (min-width:966.5px){min-width:312.833px;width:312.833px;}@media (min-width:1187px){min-width:377.5px;width:377.5px;margin-right:27px;box-shadow:0px 4px 14px rgba(0,0,0,0.25);&.last-card{&:after{content:'';display:block;background:transparent;position:absolute;right:", ";top:0px;bottom:0;left:100%;}}}"], function (_ref5) {
  var className = _ref5.className,
      isSingularCard = _ref5.isSingularCard;
  return (
    /* single card offers can stretch full width up to 600 */
    _includes(className, "offers") && isSingularCard && "\n      min-width: unset;\n      margin-right: 25px;\n    "
  );
}, function (_ref6) {
  var enableRightMargin = _ref6.enableRightMargin;
  return enableRightMargin ? "-14px" : 0;
}, function (_ref7) {
  var enableRightMargin = _ref7.enableRightMargin;
  return enableRightMargin ? "-27px" : 0;
});
var AspectRatioInner = styled.div.withConfig({
  displayName: "offers-carousel-card__AspectRatioInner",
  componentId: "sc-1qoq2h7-5"
})(["position:relative;padding-bottom:50%;border-radius:20px;@media (min-width:600px){padding-bottom:58%;}@media (min-width:750.5px){padding-bottom:63%;}@media (min-width:966.5px){padding-bottom:50%;}@media (min-width:1187px){padding-bottom:43.77%;}"]);
var OffersCardContainer = styled.div.withConfig({
  displayName: "offers-carousel-card__OffersCardContainer",
  componentId: "sc-1qoq2h7-6"
})(["padding:29px;background:", ";background-position:center left;background-size:cover;border-radius:", ";position:absolute;top:0;bottom:0;left:0;right:0;display:flex;flex-direction:column;justify-content:flex-end;&:hover{cursor:pointer;button{border:2px solid #fff;background-color:#fff;color:#242526;}}"], function (_ref8) {
  var imageUrl = _ref8.imageUrl;
  return "linear-gradient(180deg, rgba(11, 31, 50, 0.432) 33.75%, rgba(11, 31, 50, 0.9) 100%), url(".concat(imageUrl, ")");
}, function (_ref9) {
  var _theme$customized$rad3;

  var theme = _ref9.theme;
  return (_theme$customized$rad3 = theme.customized.radius.cards) !== null && _theme$customized$rad3 !== void 0 ? _theme$customized$rad3 : '20px';
});
export default OffersCarouselCard;