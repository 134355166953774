import { useTranslation, Trans } from 'i18n';
import type { UseTranslationResponse } from 'react-i18next';

export type ExtendedUseTranslationResponse = UseTranslationResponse & {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Trans: typeof Trans;
};

export default function useTranslationWrapper(): ExtendedUseTranslationResponse {
  const i18n: ExtendedUseTranslationResponse = useTranslation() as ExtendedUseTranslationResponse;
  i18n.Trans = Trans;

  return i18n;
}
