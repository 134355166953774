import React from 'react';

function PlusSymbol({ fill, ...props }) {
  return (
    <svg width='10' height='11' viewBox='0 0 10 11' fill={fill} {...props}>
      <rect y='4.38281' width='9.75' height='2' rx='1' fill={fill} />
      <rect x='3.875' y='10.2578' width='9.75' height='2' rx='1' transform='rotate(-90 3.875 10.2578)' fill={fill} />
    </svg>
  );
}

export default PlusSymbol;
