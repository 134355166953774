var __jsx = React.createElement;
import React from 'react';
import { Button } from 'rebass/styled-components';
import styled from 'styled-components';
export default styled(function (props) {
  return __jsx(Button, props);
}).withConfig({
  displayName: "reset-button",
  componentId: "sc-3ygcki-0"
})(["appearance:none;background:none;border:none;border-radius:0;padding:0;font-weight:400;font-size:", ";display:flex;align-items:center;justify-content:center;color:", ";&:hover,&:focus,&:active{cursor:pointer;color:", ";outline:none;border:none;box-shadow:none;}"], function (props) {
  var _props$fontSize;

  return (_props$fontSize = props.fontSize) !== null && _props$fontSize !== void 0 ? _props$fontSize : '13px';
}, function (props) {
  return props.color;
}, function (props) {
  return props.color ? props.color : '#363636';
});