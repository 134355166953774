import React, { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';

import useSearchPreferences from 'hooks/use-search-preferences';
import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';
import {
  DISPENSARIES_FILTER_OPTIONS,
  PICKUP_FILTER_OPTION_KEYS,
  DELIVERY_FILTER_OPTION_KEYS,
} from 'src/dispensaries/constants';
import { DispensaryListFilterDrawerControllerProps, FilterOption, FilterOptionValues } from './types';

import { DispensaryListFiltersBottomDrawer as DispensaryListFiltersDrawer } from './dispensary-list-filters-bottom-drawer';

export function DispensaryListFilterDrawerController({
  open,
  onClose,
}: DispensaryListFilterDrawerControllerProps): JSX.Element {
  const SearchPreferences = useSearchPreferences();
  const { setQueryParams, queryParamsMinusDefaults, defaults } = useDispensariesQueryParams();
  const [selectedFilters, setSelectedFilters] = useState<string[]>(Object.keys(queryParamsMinusDefaults));
  const orderType = useObserver(() => SearchPreferences.orderType);

  useEffect(() => {
    if (open) {
      const filtersToSet = Object.keys(queryParamsMinusDefaults).filter((param) =>
        orderType === `pickup` ? PICKUP_FILTER_OPTION_KEYS.includes(param) : DELIVERY_FILTER_OPTION_KEYS.includes(param)
      );
      setSelectedFilters(filtersToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]); // FIXME: ENG-32714 fix hooks dependency

  function handleClearFilters(): void {
    setSelectedFilters([]);
  }

  function handleSelect(option: FilterOptionValues): void {
    const newSelectedFilters = selectedFilters.includes(option)
      ? [...selectedFilters.filter((selected) => selected !== option)]
      : [...selectedFilters, option];
    setSelectedFilters(newSelectedFilters);
  }

  function handleSave(): void {
    const paramsToUpdate = {};
    const optionsToCheck = DISPENSARIES_FILTER_OPTIONS[orderType].map((option: FilterOption) => option.value);
    optionsToCheck.forEach((value: FilterOptionValues) => {
      paramsToUpdate[value] = selectedFilters.includes(value) ? true : defaults[value];
    });
    setQueryParams(paramsToUpdate);
    onClose();
  }

  return (
    <DispensaryListFiltersDrawer
      open={open}
      onClose={onClose}
      onClearFilters={handleClearFilters}
      onSave={handleSave}
      onSelect={handleSelect}
      selectedOptions={selectedFilters}
      orderType={orderType}
    />
  );
}
