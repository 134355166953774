import React from 'react';
import _ from 'lodash';

import useTranslation from 'hooks/use-translation';

import { getCityToDisplay } from 'shared/helpers/address';
import { useMetaInfo } from './use-meta-info';

import Meta from './meta';

// default meta description values
export const useDescription = (variant = `default`, isEmbeddedOrStoreFront, options = {}) => {
  const { t } = useTranslation();

  /* eslint-disable max-len */
  const dutchieDescriptions = {
    default: t(
      'head.title.default',
      'Dutchie creates technology that powers e-commerce and point of sale solutions for dispensaries. We also offer the dutchie marketplace, where consumers can order their favorite cannabis products online for pickup and delivery.'
    ),
    blog: t(
      'head.title.blog',
      'Read the latest articles published by dutchie. Hear about topics like cannabis news, trends in tech, life at dutchie, and more.'
    ),
    cities: t(
      'head.title.cities',
      'The best dispensaries in the US and Canada. Browse state and local marijuana shops for pickup and delivery of cannabis products: edibles, flower, vapes, pre-rolls, concentrates, topicals, CBD, and more. Order online now >>>'
    ),
    city: t(
      'head.title.city',
      'Find a {{city}} dispensary near you for medicinal or recreational cannabis. Order online for delivery, pickup, or curbside pickup. The best dispensaries in {{city}} {{state}} open now >>>',
      options
    ),
    dispensary: t(
      'head.title.dispensary',
      'Order cannabis online for delivery or pick up from {{dispensaryName}} a {{menuTypes}} dispensary in {{dispensaryCity}}, {{dispensaryState}}. View the dispensary menu, photos, hours, and more. Shop now >>>',
      options
    ),
    dispensaryBrand: t(
      'head.title.dispensaryBrand',
      'Find {{brandName}} in {{dispensaryCity}} {{dispensaryState}} at {{dispensaryName}}. Order {{brandName}} online for pickup or delivery. Shop now >>>',
      options
    ),
    dispensaryBrands: t(
      'head.title.dispensaryBrands',
      'The most popular cannabis brands sold in {{dispensaryCity}}, {{dispensaryState}} are at {{dispensaryName}}. Find your favorite edibles, pre-rolls, flower, vapes and more at {{dispensaryName}}. Shop now >>>',
      options
    ),
    dispensaryInfo: t(
      'head.title.dispensaryInfo',
      'Find hours, address, payments accepted, and more for {{dispensaryName}} dispensary in {{dispensaryCity}}, {{dispensaryState}}. Order online for pickup or delivery. Shop now >>',
      options
    ),
    dispensaryProduct: t(
      'head.title.dispensaryProduct',
      '{{productName}} by {{productBrand}} available at {{dispensaryName}}, a {{dispensaryCity}} {{dispensaryState}} cannabis dispensary. Order online for pickup or delivery. Shop now >>>',
      options
    ),
    dispensaryProductCategories: t(
      'head.title.dispensaryProductCategories',
      'Browse for cannabis products and order online at {{dispensaryName}}, a {{dispensaryCity}}, {{dispensaryState}} dispensary. Browse by Flower, Pre-Rolls, Vaporizers, Concentrates, Edibles, Tinctures, Topicals, CBD, Accessories. Shop now >>>',
      options
    ),
    dispensaryProductCategory: t(
      'head.title.dispensaryProductCategory',
      'Shop cannabis {{productCategory}} at {{dispensaryName}}. Order online for pickup or delivery in {{dispensaryCity}}, {{dispensaryState}}. Shop all cannabis {{productSubcategories}} now >>>',
      options
    ),
    dispensaryProductSubcategory: t(
      'head.title.dispensaryProductSubcategory',
      'Shop cannabis {{productSubcategory}} at {{dispensaryName}}. Order online for pickup or delivery in {{dispensaryCity}}, {{dispensaryState}}. Shop all cannabis {{productCategory}} and {{productSubcategory}} now >>>',
      options
    ),
    dispensarySpecials: t(
      'head.title.dispensarySpecials',
      'Shop cannabis daily deals and specials at {{dispensaryName}}, a {{dispensaryCity}}, {{dispensaryState}} dispensary. See all discounts and sales on pre-rolls, edibles, vaporizers, flowers, CBD and more. Shop now >>>',
      options
    ),
    careers: t(
      'head.title.careers',
      'View our posted career opportunities with dutchie. Are you looking for a new job in the marijuana & dispensary industry? View our career postings for additional information and contact us today to learn more.'
    ),
    about: t(
      'head.title.about',
      'dutchie is the most convenient way to order marijuana online through the most reputable local dispensaries in your area. We simplify the process of choosing the right dispensary & make ordering easy. To learn more about us, contact us today.'
    ),
    help: t(
      'head.title.help',
      'Experiencing a problem? Have a question? We have customer support representatives standing by to help!'
    ),
    home: t(
      'head.title.home',
      'Order online from local cannabis dispensaries for in-store pickup, curbside pickup, or delivery. Your favorite cannabis products and strains are all on Dutchie. Shop now >>>'
    ),
    news: t(
      'head.title.news',
      'Learn about recent news from the cannabis & marijuana industries at dutchie. Read through our informative news articles to discover current trends in the medical & recreational cannabis industry.'
    ),
    privacy: t(
      'head.title.privacy',
      "dutchie Privacy Policy. View our comprehensive privacy policy to learn about how we protect our customers' privacy. For more information, contact us today."
    ),
    terms: t(
      'head.title.terms',
      'dutchie Terms of Service. View our comprehensive terms of service policy to learn about how our website operates. For more information, contact us today.'
    ),
    messaging: t('head.title.messaging', 'Order Messaging'),
    curbsideArrivals: t('head.title.curbsideArrivals', 'Curbside Arrival'),
    whoops: '',
  };

  const EmbeddedAndStoreFrontDescriptions = {
    ...dutchieDescriptions,
    dispensary: t(
      'head.title.dispensary',
      'Online ordering menu for {{dispensaryName}}, a dispensary located at {{dispensaryStreet}}, {{dispensaryCity}}, {{dispensaryState}}.',
      options
    ),
    dispensaryProduct: t(
      'head.title.dispensaryProduct',
      'Get {{productBrand}} {{productCategory}} at {{dispensaryName}}, {{dispensaryStreet}}, {{dispensaryCity}}, {{dispensaryState}}, {{dispensaryZip}}. Online ordering available for {{productName}}.',
      options
    ),
    dispensarySpecials: t(
      'head.title.dispensarySpecials',
      'Get all the sales, deals, promos, specials, and more at {{dispensaryName}}, {{dispensaryStreet}}, {{dispensaryCity}}, {{dispensaryState}}, {{dispensaryZip}}. Save money on cannabis at {{dispensaryName}}.',
      options
    ),
    dispensarySpecial: t(
      'head.title.dispensarySpecial',
      'Get all the sales, deals, promos, specials, and more at {{dispensaryName}}, {{dispensaryStreet}}, {{dispensaryCity}}, {{dispensaryState}}, {{dispensaryZip}}. Save money on cannabis at {{dispensaryName}}.',
      options
    ),
    dispensaryBrands: t(
      'head.title.dispensaryBrands',
      'Get top cannabis brands at {{dispensaryName}}, {{dispensaryStreet}}, {{dispensaryCity}}, {{dispensaryState}}, {{dispensaryZip}}. Online ordering available for the best cannabis brands sold in {{dispensaryCity}}, {{dispensaryState}}.',
      options
    ),
    dispensaryBrand: t(
      'head.title.dispensaryBrand',
      'Get {{brandName}} at {{dispensaryName}}, {{dispensaryStreet}}, {{dispensaryCity}}, {{dispensaryState}}, {{dispensaryZip}}. Online ordering available for cannabis products and {{brandName}}.',
      options
    ),
    dispensaryAllProducts: t(
      'Get top cannabis products at {{dispensaryName}}, {{dispensaryStreet}}, {{dispensaryCity}}, {{dispensaryState}}, {{dispensaryZip}}. Online ordering available for the best cannabis products sold in {{dispensaryCity}}, {{dispensaryState}}.',
      options
    ),
    dispensaryProductCategory: t(
      'head.title.dispensaryProductCategory',
      'Get {{productCategory}} at {{dispensaryName}}, {{dispensaryStreet}}, {{dispensaryCity}}, {{dispensaryState}}, {{dispensaryZip}}. Online ordering available for cannabis {{productCategory}}.',
      options
    ),
    dispensaryProductSubcategory: t(
      'head.title.dispensaryProductSubcategory',
      'Get {{productSubcategory}} in the {{productCategory}} section at {{dispensaryName}}, {{dispensaryStreet}}, {{dispensaryCity}}, {{dispensaryState}}, {{dispensaryZip}}. Online ordering available for cannabis {{productSubcategory}}. ',
      options
    ),
    dispensarySearch: t(
      'head.title.dispensarySearch',
      'Online ordering menu for {{dispensaryName}}, a dispensary located at {{dispensaryStreet}}, {{dispensaryCity}}, {{dispensaryState}}.',
      options
    ),
  };

  const descriptions = isEmbeddedOrStoreFront ? EmbeddedAndStoreFrontDescriptions : dutchieDescriptions;

  if (!descriptions[variant]) {
    variant = `default`;
  }

  return descriptions[variant];
};

export default function PageDescription(props) {
  const { variant, location, isEmbeddedOrStoreFront } = props;
  const metaInfo = useMetaInfo();

  const description = useDescription(variant, isEmbeddedOrStoreFront, {
    ...metaInfo,
    productSubcategory: _.toLower(metaInfo.productSubcategory),
    productCategory: _.toLower(metaInfo.productCategory),
    city: getCityToDisplay(location),
    state: location?.state,
  });
  return (
    <>
      <Meta property='og:description' content={description} />
    </>
  );
}
