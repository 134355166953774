import React from 'react';
import styled from 'styled-components';

import { useCardDimensions } from 'src/components/product-card/product-card.utils';
import PersonalizedCarouselSkeleton from './personalized-carousel-skeleton';

const PersonalizedCarouselLoadingState = (props) => {
  const defaultDimensions = useCardDimensions({ gutter: 12 });
  const { dimensions = defaultDimensions } = props;

  return (
    <Container>
      <PersonalizedCarouselSkeleton dimensions={dimensions} {...props} />
    </Container>
  );
};

export default PersonalizedCarouselLoadingState;

const Container = styled.div`
  overflow: hidden;

  .carousel-prev-button,
  .carousel-next-button {
    display: none;
  }
`;
