import _slicedToArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _isArray from "lodash/isArray";
import _reduce from "lodash/reduce";
import _isNil from "lodash/isNil";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import _some from "lodash/some";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Big from 'big.js';
import { hasSaleSpecialForMenuType } from 'shared/helpers/specials';
import { amortizeDollarDiscount, isCumulative } from './utilities';
import { sumProductTaxes } from './taxes';
export function couponApplicableCategoriesMatchProductDetail(coupon, detail) {
  return _some(coupon === null || coupon === void 0 ? void 0 : coupon.applicableCategories, function (categoryObject) {
    return categoryObject.category === detail.productCategory && (categoryObject.subcategory === 'all' || categoryObject.subcategory === detail.productSubcategory);
  });
}
export var couponIsApplicable = function couponIsApplicable(detail, coupon) {
  var _coupon$restrictions;

  if (!coupon || detail.isOnSale && coupon !== null && coupon !== void 0 && (_coupon$restrictions = coupon.restrictions) !== null && _coupon$restrictions !== void 0 && _coupon$restrictions.noSpecials) {
    return false;
  }

  if (!_isEmpty(coupon === null || coupon === void 0 ? void 0 : coupon.applicableCategories)) {
    return couponApplicableCategoriesMatchProductDetail(coupon, detail);
  }

  return true;
};
export var calculateCouponDiscounts = function calculateCouponDiscounts(_ref) {
  var applyStowedDiscounts = _ref.applyStowedDiscounts,
      compositePrice = _ref.compositePrice,
      coupon = _ref.coupon,
      couponApplicableSubtotal = _ref.couponApplicableSubtotal,
      couponApplicableSubtotalPlusTaxes = _ref.couponApplicableSubtotalPlusTaxes,
      couponLeftOver = _ref.couponLeftOver,
      credit = _ref.credit,
      detail = _ref.detail,
      discountTaxOrder = _ref.discountTaxOrder,
      originalCompositePrice = _ref.originalCompositePrice,
      productTotal = _ref.productTotal,
      receiptDetail = _ref.receiptDetail,
      globalSpecialsSettings = _ref.globalSpecialsSettings,
      stowDiscounts = _ref.stowDiscounts,
      stowedDiscounts = _ref.stowedDiscounts;

  var couponToApply = function () {
    if (applyStowedDiscounts) {
      var couponDiscount = _find(stowedDiscounts, ['type', 'coupon']);

      return couponDiscount ? _objectSpread(_objectSpread({}, couponDiscount.discountData), {}, {
        _quantityToApply: couponDiscount.quantityToApply
      }) : null;
    }

    return coupon;
  }();

  if (couponIsApplicable(detail, couponToApply)) {
    var stackingBehaviorIsCumulative = isCumulative(null, globalSpecialsSettings.stackingBehavior);
    var startingPrice = stackingBehaviorIsCumulative ? originalCompositePrice : compositePrice;
    var individualPrice = startingPrice.div(detail.quantity);
    var quantityToApply = !_isNil(couponToApply === null || couponToApply === void 0 ? void 0 : couponToApply._quantityToApply) ? couponToApply._quantityToApply : detail.quantity || 0;
    var additionalCredit = Big(0);

    if (couponToApply !== null && couponToApply !== void 0 && couponToApply.percent) {
      additionalCredit = Big(individualPrice.times(Big(couponToApply.percent)).times(quantityToApply));
    } else if (couponToApply !== null && couponToApply !== void 0 && couponToApply.dollar) {
      // TODO: consider a better approach for dollar discount coupons in the no-stacking case when combined with a BOGO
      var couponData = couponToApply;

      if (discountTaxOrder === 'taxesFirst') {
        var maxDollarDiscount = Big(Math.min(Number(couponApplicableSubtotalPlusTaxes), (couponToApply === null || couponToApply === void 0 ? void 0 : couponToApply.dollar) || 0));
        couponData = {
          dollar: Number(maxDollarDiscount)
        };
      }

      var _amortizeDollarDiscou = amortizeDollarDiscount(couponData, couponLeftOver, discountTaxOrder === 'taxesFirst' ? couponApplicableSubtotalPlusTaxes : couponApplicableSubtotal, originalCompositePrice),
          amortizedDiscount = _amortizeDollarDiscou.amortizedDiscount,
          leftOver = _amortizeDollarDiscou.leftOver;
      /*
        if the amortized discount is greater than the discounted product price
        we want to try and roll the remaining coupon discount to the next item
       */


      if (amortizedDiscount.gt(compositePrice)) {
        // add remaining coupon discount to next left over
        couponLeftOver = amortizedDiscount.minus(compositePrice); // add as much as we can to this product

        additionalCredit = compositePrice;
      } else {
        additionalCredit = Big(amortizedDiscount);
      }

      if (!stowDiscounts) {
        couponLeftOver.plus(leftOver);
      }
    }

    if (stowDiscounts) {
      stowedDiscounts.push({
        applicableQuantity: detail.quantity,
        discountData: couponToApply,
        individualDiscount: additionalCredit.div(detail.quantity),
        key: couponToApply._id,
        type: 'coupon'
      });
    } else {
      compositePrice = compositePrice.minus(additionalCredit);

      if (discountTaxOrder !== 'discountsFirst' || Big(detail.basePriceMult, 'Base Price Mult').eq(1)) {
        credit = credit.plus(additionalCredit);
      } else {
        productTotal = productTotal.minus(additionalCredit);
      }

      if (receiptDetail && additionalCredit.gt(0)) {
        receiptDetail.addDiscount({
          type: 'coupon',
          id: couponToApply._id,
          name: couponToApply.code,
          value: additionalCredit.times(100)
        });
      }
    }
  }

  return {
    compositePrice: compositePrice,
    couponLeftOver: couponLeftOver,
    credit: credit,
    productTotal: productTotal,
    stowedDiscounts: stowedDiscounts
  };
};
export function productDetailHasSpecialsConflicts(detail, products, medicalOrder, coupon) {
  var _coupon$restrictions2;

  return hasSaleSpecialForMenuType({
    product: products["".concat(detail.id, "_").concat(detail.option)].product,
    medicalOrder: medicalOrder
  }) && (coupon === null || coupon === void 0 ? void 0 : (_coupon$restrictions2 = coupon.restrictions) === null || _coupon$restrictions2 === void 0 ? void 0 : _coupon$restrictions2.noSpecials);
}
export function getCouponApplicableSubtotal(details, products, medicalOrder, coupon) {
  return _reduce(details, function (accum, detail) {
    var _detail$mixAndMatch;

    var _couponAppliesToProdu = couponAppliesToProduct(detail, products, medicalOrder, coupon),
        specialAndCouponRestrictionConflict = _couponAppliesToProdu.specialAndCouponRestrictionConflict,
        couponAcceptsAllCategoriesOrMatchesRestriction = _couponAppliesToProdu.couponAcceptsAllCategoriesOrMatchesRestriction;

    return accum.add(!specialAndCouponRestrictionConflict && couponAcceptsAllCategoriesOrMatchesRestriction ? (((_detail$mixAndMatch = detail.mixAndMatch) === null || _detail$mixAndMatch === void 0 ? void 0 : _detail$mixAndMatch.adjustedBasePrice) || detail.basePrice).times(detail.quantity) : 0);
  }, Big(0));
}
export var prepareCoupon = function prepareCoupon(_ref2) {
  var _coupon;

  var couponValue = _ref2.couponValue,
      details = _ref2.details,
      discountTaxOrder = _ref2.discountTaxOrder,
      medicalOrder = _ref2.medicalOrder,
      products = _ref2.products;
  var coupon;

  if (couponValue) {
    if (_isArray(couponValue)) {
      var _couponValue = _slicedToArray(couponValue, 1);

      coupon = _couponValue[0];
    } else {
      coupon = _objectSpread({}, couponValue);
    }
  }

  var couponApplicableSubtotal = getCouponApplicableSubtotal(details, products, medicalOrder, coupon); // TODO: consider doing this here, so couponApplicableSubtotalPlusTaxes isn't needed (tests would need updated)
  // Add total taxes to couponApplicableSubtotal for taxesFirst setting
  // if (discountTaxOrder === 'taxesFirst') {
  //   couponApplicableSubtotal = couponApplicableSubtotal.add(gatherProductTaxes(details, products));
  // }
  // If dollar discount totals more than possible subtotal swap the dollar value

  if ((_coupon = coupon) !== null && _coupon !== void 0 && _coupon.dollar && (discountTaxOrder === 'both' || discountTaxOrder === 'discountsFirst')) {
    coupon.dollar = Math.min(Number(couponApplicableSubtotal), coupon.dollar);
  }

  return {
    coupon: coupon,
    couponApplicableSubtotal: couponApplicableSubtotal,
    couponApplicableSubtotalPlusTaxes: couponApplicableSubtotal.plus(gatherTaxesForCouponApplicableProducts(details, products, medicalOrder, coupon)),
    couponLeftOver: Big(0)
  };
};

function couponAppliesToProduct(detail, products, medicalOrder, coupon) {
  var specialAndCouponRestrictionConflict = productDetailHasSpecialsConflicts(detail, products, medicalOrder, coupon);
  var couponAcceptsAllCategoriesOrMatchesRestriction = true;

  if (!_isEmpty(coupon === null || coupon === void 0 ? void 0 : coupon.applicableCategories)) {
    couponAcceptsAllCategoriesOrMatchesRestriction = couponApplicableCategoriesMatchProductDetail(coupon, detail);
  }

  return {
    specialAndCouponRestrictionConflict: specialAndCouponRestrictionConflict,
    couponAcceptsAllCategoriesOrMatchesRestriction: couponAcceptsAllCategoriesOrMatchesRestriction
  };
}

function gatherTaxesForCouponApplicableProducts(details, products, medicalOrder, coupon) {
  return _reduce(details, function (accum, detail) {
    var _couponAppliesToProdu2 = couponAppliesToProduct(detail, products, medicalOrder, coupon),
        specialAndCouponRestrictionConflict = _couponAppliesToProdu2.specialAndCouponRestrictionConflict,
        couponAcceptsAllCategoriesOrMatchesRestriction = _couponAppliesToProdu2.couponAcceptsAllCategoriesOrMatchesRestriction;

    var totalProductTax = Big(0);

    if (!specialAndCouponRestrictionConflict && couponAcceptsAllCategoriesOrMatchesRestriction) {
      var productTaxes = sumProductTaxes(detail, products, Big(0), Big(0));
      totalProductTax = totalProductTax.plus(productTaxes.cannabisTaxTotal).plus(productTaxes.salesTaxTotal);
    }

    return accum.add(totalProductTax);
  }, Big(0));
}