import React from 'react';
import styled, { css } from 'styled-components';

import useUI from 'hooks/use-ui';

import CoreMenuHeader from './core-menu-header';
import StoreFrontHeader from './store-front-header';

export default function Header(props) {
  const { isStoreFront, isKiosk } = useUI();

  return (
    <Container isKiosk={isKiosk}>
      {(isStoreFront || isKiosk) && <StoreFrontHeader />}
      <CoreMenuHeader {...props} />
    </Container>
  );
}

const Container = styled.header`
  width: 100%;
  ${({ isKiosk }) =>
    isKiosk &&
    css`
      position: sticky;
      z-index: 20;
      top: 0;
    `}
`;
