import { FrozenMap } from '../helpers';

import { GenericCategoryRoute, Category } from '../generic-category';

export const toGenericCategoryRoute = new FrozenMap<Category, GenericCategoryRoute>(
  [Category.accessories, GenericCategoryRoute.accessories],
  [Category.apparel, GenericCategoryRoute.apparel],
  [Category.cbd, GenericCategoryRoute.cbd],
  [Category.clones, GenericCategoryRoute.clones],
  [Category.concentrate, GenericCategoryRoute.concentrate],
  [Category.edible, GenericCategoryRoute.edible],
  [Category.flower, GenericCategoryRoute.flower],
  [Category.nA, GenericCategoryRoute.nA],
  [Category.preRolls, GenericCategoryRoute.preRolls],
  [Category.seeds, GenericCategoryRoute.seeds],
  [Category.tincture, GenericCategoryRoute.tincture],
  [Category.topicals, GenericCategoryRoute.topicals],
  [Category.vaporizers, GenericCategoryRoute.vaporizers],
  [Category.orals, GenericCategoryRoute.orals]
);

export const fromGenericCategoryRoute = FrozenMap.reverse(toGenericCategoryRoute);
