import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _reduce from "lodash/reduce";
import _values from "lodash/values";
import _forEach from "lodash/forEach";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import getAllBogoSpecialsForCart from './get-all-bogo-specials-for-cart';
import determineRewards from './determine-rewards';
import { mergeSatisfiers } from './common'; // Determine possible BOGO discounts to be applied later. NOTE: bogoCalcsData will be mutated

var determineBogoDiscounts = function determineBogoDiscounts(_ref) {
  var bogoCalcsData = _ref.bogoCalcsData,
      items = _ref.detailsSorted,
      discountTaxOrder = _ref.discountTaxOrder,
      medicalOrder = _ref.medicalOrder,
      globalSpecialsSettings = _ref.globalSpecialsSettings;

  if (!bogoCalcsData.allBogoSpecials) {
    // Attach to bogoCalcsData for reruns
    bogoCalcsData.allBogoSpecials = getAllBogoSpecialsForCart(items, medicalOrder, globalSpecialsSettings);
  }

  var allBogoSpecials = bogoCalcsData.allBogoSpecials;

  if (allBogoSpecials.length > 0) {
    _forEach(allBogoSpecials, function (bogoSpecial) {
      /*
        Here we want to group the items by their matching SKU if it is
        required by the offer (LLx). When the SKU is present we only want to qualify products that
        match in SKU, so below we separate the items into an array of arrays that are
        grouped by SKU
       */
      var itemGroups = [items];

      if (bogoSpecial !== null && bogoSpecial !== void 0 && bogoSpecial.requireSingleSKU) {
        itemGroups = _values(_reduce(items, function (skuGroups, item) {
          if (!skuGroups[item.cannonicalSKU]) {
            skuGroups[item.cannonicalSKU] = [];
          }

          skuGroups[item.cannonicalSKU].push(item);
          return skuGroups;
        }, {}));
      }

      _forEach(itemGroups, function (itemGroup) {
        determineRewards(bogoCalcsData, bogoSpecial, discountTaxOrder, itemGroup, globalSpecialsSettings);
      });
    });

    var applicableRewards = {}; // here we need to merge classic rewards and discount to cart rewards

    var mergedRewards = mergeSatisfiers(bogoCalcsData.rewardsSatisfiers, bogoCalcsData.discountToCartRewards, true);

    _forEach(mergedRewards, function (satisfier, itemKey) {
      applicableRewards[itemKey] = _reduce(satisfier.rewards, function (rewards, current) {
        var _current$reward$disco, _current$reward$disco2, _bogoCalcsData$condit, _bogoCalcsData$condit2, _current$reward, _current$reward$disco3, _current$reward2, _current$reward3, _current$reward4, _current$reward$disco4, _current$reward$disco5, _current$reward5;

        // SECPO = stacking enabled compounding percent off
        // DTC = discount to cart
        var isSecpoDtc = !!((_current$reward$disco = current.reward.discountToCart) !== null && _current$reward$disco !== void 0 && _current$reward$disco.enabled) && current.discountStacking && ((_current$reward$disco2 = current.reward.discountToCart) === null || _current$reward$disco2 === void 0 ? void 0 : _current$reward$disco2.discountType) === 'percentDiscount' && current.stackingBehavior === 'compounding';
        bogoCalcsData.containsStackingPercentageCompoundingDtc = bogoCalcsData.containsStackingPercentageCompoundingDtc || isSecpoDtc;
        rewards.push(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
          applicableQuantity: current.quantity,
          conditionQuantity: _reduce(((_bogoCalcsData$condit = bogoCalcsData.conditionsSatisfiers) === null || _bogoCalcsData$condit === void 0 ? void 0 : (_bogoCalcsData$condit2 = _bogoCalcsData$condit[itemKey]) === null || _bogoCalcsData$condit2 === void 0 ? void 0 : _bogoCalcsData$condit2.conditions) || [], function (quantity, condition) {
            return condition.specialId === current.specialId ? quantity + condition.quantity : quantity;
          }, 0),
          isItemsForAPriceReward: !!((_current$reward = current.reward) !== null && _current$reward !== void 0 && _current$reward.isItemsForAPriceReward),
          isDiscountToCartReward: !!((_current$reward$disco3 = current.reward.discountToCart) !== null && _current$reward$disco3 !== void 0 && _current$reward$disco3.enabled)
        }, (_current$reward2 = current.reward) !== null && _current$reward2 !== void 0 && _current$reward2.dollarDiscount ? {
          dollarDiscount: Big(current.reward.dollarDiscount).times(current.reward.redemptionMultiplier || 1).toString()
        } : {}), (_current$reward3 = current.reward) !== null && _current$reward3 !== void 0 && _current$reward3.percentDiscount ? {
          percentDiscount: current.reward.percentDiscount
        } : {}), (_current$reward4 = current.reward) !== null && _current$reward4 !== void 0 && _current$reward4.targetPrice ? {
          targetPrice: current.reward.targetPrice
        } : {}), isSecpoDtc ? {
          redemptionMultiplier: current.reward.redemptionMultiplier
        } : {}), {}, {
          displayAsPercentDiscount: !!((_current$reward$disco4 = current.reward.discountToCart) !== null && _current$reward$disco4 !== void 0 && _current$reward$disco4.enabled) && ((_current$reward$disco5 = current.reward.discountToCart) === null || _current$reward$disco5 === void 0 ? void 0 : _current$reward$disco5.discountType) === 'percentDiscount',
          isSecpoDtc: isSecpoDtc,
          rewardId: (_current$reward5 = current.reward) === null || _current$reward5 === void 0 ? void 0 : _current$reward5._id,
          satisfiedBy: current.satisfiedBy || {},
          specialId: current.specialId,
          specialType: 'bogo',
          stackingMode: current.stackingMode,
          discountStacking: current.discountStacking,
          stackingBehavior: current.stackingBehavior,
          nonStackingBehavior: current.nonStackingBehavior,
          discountPrecedence: current.discountPrecedence,
          settingsDerivedFrom: current.settingsDerivedFrom
        }));
        return rewards;
      }, []);
    });

    bogoCalcsData.applicableRewards = applicableRewards;
  }

  bogoCalcsData.redetermineDiscounts = false;
  return bogoCalcsData;
};

export default determineBogoDiscounts;