import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _map from "lodash/map";
import _reduce from "lodash/reduce";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _keys from "lodash/keys";
import _forEach from "lodash/forEach";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _isNil from 'lodash/isNil';
import _orderBy from 'lodash/orderBy';
import _times from 'lodash/times'; // eslint-disable-next-line import/no-extraneous-dependencies

import Big from 'big.js';
import { getProductWeight } from 'shared/helpers/products'; // Adds pricing adjustment data to the passed-in adjustments object

var addAdjustments = function addAdjustments(_ref) {
  var adjustments = _ref.adjustments,
      allFlowerDetailsHash = _ref.allFlowerDetailsHash,
      possibleAdjustments = _ref.possibleAdjustments,
      targetWeight = _ref.targetWeight,
      tierId = _ref.tierId;

  _forEach(_keys(possibleAdjustments[tierId][targetWeight].items), function (itemKey) {
    var _adjustments$itemKey;

    var weight = allFlowerDetailsHash[itemKey].weight;
    var tierUnitPrice = possibleAdjustments[tierId][targetWeight].tierUnitPrice;
    var adjustedBasePrice = tierUnitPrice.times(weight);
    var quantityDiscounted = possibleAdjustments[tierId][targetWeight].items[itemKey];
    var discountAmount = ((_adjustments$itemKey = adjustments[itemKey]) === null || _adjustments$itemKey === void 0 ? void 0 : _adjustments$itemKey.discountAmount) || 0;
    var totalDiscountAmountForDetail = Big(discountAmount).add(Big(allFlowerDetailsHash[itemKey].basePrice).times(quantityDiscounted).minus(adjustedBasePrice.times(quantityDiscounted)));

    if (totalDiscountAmountForDetail.gt(0)) {
      var _adjustments$itemKey2;

      adjustments[itemKey] = {
        discountAmount: totalDiscountAmountForDetail,
        quantity: (((_adjustments$itemKey2 = adjustments[itemKey]) === null || _adjustments$itemKey2 === void 0 ? void 0 : _adjustments$itemKey2.quantity) || 0) + quantityDiscounted
      };
    }
  });
}; // Given tiers data and the targetWeight, returns the gram unit price for the tier in dollars


export var getTierUnitPrice = function getTierUnitPrice(detail, targetWeight) {
  var _detail$pricingTierDa;

  var matchingTier = _find(((_detail$pricingTierDa = detail.pricingTierData) === null || _detail$pricingTierDa === void 0 ? void 0 : _detail$pricingTierDa.tiers) || [], function (tier) {
    var weightUOM = tier.weightUOM === 'each' ? '' : tier.weightUOM;
    var lower = _isNil(tier.startWeight) ? -Infinity : getProductWeight("".concat(tier.startWeight).concat(weightUOM));
    var upper = _isNil(tier.endWeight) ? Infinity : getProductWeight("".concat(tier.endWeight).concat(weightUOM)); // in this case a tier has a specific value and not a range

    if (lower === upper) {
      return targetWeight === lower;
    } // if it's a range, we check if the target weight is in the range, lower bound inclusive, upper bound exclusive


    return targetWeight >= lower && targetWeight < upper;
  });

  if (matchingTier) {
    var pricePerGram = !_isNil(matchingTier.pricePerGram) ? Big(matchingTier.pricePerGram) : Big(matchingTier.price || 0).div(targetWeight); // Need to divide by basePriceMult first to account for POS taxes

    return pricePerGram.div(detail.basePriceMult).div(100);
  }

  return Big(detail.basePrice || 0); // Return regular basePrice if no tier was matched
}; // Determines mix and match pricing adjustments and adds mixAndMatch data to provided details

export var determineMixAndMatchAdjustments = function determineMixAndMatchAdjustments(allDetails, dispensary, isDelivery) {
  var _dispensary$storeSett, _dispensary$storeSett2;

  if (isDelivery && !(dispensary !== null && dispensary !== void 0 && (_dispensary$storeSett = dispensary.storeSettings) !== null && _dispensary$storeSett !== void 0 && _dispensary$storeSett.enableMixAndMatchPricingForDelivery)) {
    return allDetails;
  }

  if (!isDelivery && !(dispensary !== null && dispensary !== void 0 && (_dispensary$storeSett2 = dispensary.storeSettings) !== null && _dispensary$storeSett2 !== void 0 && _dispensary$storeSett2.enableMixAndMatchPricingForPickup)) {
    return allDetails;
  }

  var allFlowerDetails = _orderBy(_filter(allDetails, ['productCategory', 'Flower']), function (detail) {
    return getProductWeight(detail.option);
  }, 'desc');

  var allFlowerDetailsHash = _reduce(allFlowerDetails, function (obj, detail) {
    obj[detail.key] = _objectSpread(_objectSpread({}, detail), {}, {
      weight: getProductWeight(detail.option)
    });
    return obj;
  }, {});

  var mixAndMatchWeights = _orderBy(_map((dispensary === null || dispensary === void 0 ? void 0 : dispensary.mixAndMatchPricingWeights) || [], function (weight) {
    return getProductWeight(weight);
  }), [], 'desc');

  var adjustments = {};
  var possibleAdjustments = {};

  _forEach(mixAndMatchWeights, function (targetWeight) {
    // eslint-disable-next-line consistent-return
    _forEach(allFlowerDetails, function (detail) {
      var _detail$pricingTierDa2, _detail$pricingTierDa3;

      var tierId = ((_detail$pricingTierDa2 = detail.pricingTierData) === null || _detail$pricingTierDa2 === void 0 ? void 0 : _detail$pricingTierDa2.tiersId) || ((_detail$pricingTierDa3 = detail.pricingTierData) === null || _detail$pricingTierDa3 === void 0 ? void 0 : _detail$pricingTierDa3.generatedTiersId);

      if (!tierId) {
        return false;
      } // create the mapping that we use later to tally the appropriate adjustments


      if (!possibleAdjustments[tierId]) {
        possibleAdjustments[tierId] = {};
      }

      if (!possibleAdjustments[tierId][targetWeight]) {
        possibleAdjustments[tierId][targetWeight] = {
          items: {},
          remainingWeightUntilDiscount: targetWeight,
          tierUnitPrice: getTierUnitPrice(detail, targetWeight)
        };
      }

      var detailKey = detail.key;
      var detailWeight = allFlowerDetailsHash[detailKey].weight;

      if (detailWeight < targetWeight) {
        var _adjustments$detailKe;

        _times(Math.max(detail.quantity - (((_adjustments$detailKe = adjustments[detailKey]) === null || _adjustments$detailKe === void 0 ? void 0 : _adjustments$detailKe.quantity) || 0), 0), function () {
          var remainingWeight = possibleAdjustments[tierId][targetWeight].remainingWeightUntilDiscount;
          remainingWeight -= detailWeight;

          if (remainingWeight >= 0) {
            // tally the possible adjustments for each tier, aiming to hit remainingWeight === 0
            if (!possibleAdjustments[tierId][targetWeight].items[detailKey]) {
              possibleAdjustments[tierId][targetWeight].items[detailKey] = 1;
            } else {
              possibleAdjustments[tierId][targetWeight].items[detailKey] += 1;
            } // if the remaining weight is 0 we know to apply the adjustments


            if (remainingWeight === 0) {
              addAdjustments({
                adjustments: adjustments,
                allFlowerDetailsHash: allFlowerDetailsHash,
                possibleAdjustments: possibleAdjustments,
                targetWeight: targetWeight,
                tierId: tierId
              });
              possibleAdjustments[tierId][targetWeight].items = {};
              possibleAdjustments[tierId][targetWeight].remainingWeightUntilDiscount = targetWeight;
            } else {
              possibleAdjustments[tierId][targetWeight].remainingWeightUntilDiscount = remainingWeight;
            }
          }
        });
      }
    });
  });

  _forEach(allFlowerDetails, function (detail) {
    var detailKey = detail.key;

    if (adjustments[detailKey]) {
      var discountAmount = Number(adjustments[detailKey].discountAmount.round(2));
      detail.mixAndMatch = {
        adjustedBasePrice: Big(detail.basePrice).times(detail.quantity).minus(discountAmount).div(detail.quantity),
        discountAmount: discountAmount
      };
    }
  });

  return allDetails;
};