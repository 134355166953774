import React, { useRef, useState } from 'react';
import {
  BasisTheoryApiError,
  BasisTheoryProvider,
  BasisTheoryValidationError,
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
  useBasisTheory,
} from '@basis-theory/basis-theory-react';
import styled, { css } from 'styled-components';
import { DefaultInput } from 'shared/components/text-input-with-label';
import { Button } from 'shared/components';
import useTranslation from 'hooks/use-translation';
import PublicEnv from 'shared/utils/public-env';
import useStores from 'shared/hooks/use-stores';
import { useCheckoutAutoSave } from 'checkout/hooks/use-checkout-auto-save';
import { Typography } from 'src/typography';

type FormData = {
  firstName: string;
  lastName: string;
  zipCode: string;
};

function BasisTheory({ onSave }: { onSave: () => void }): JSX.Element {
  const { basisTheoryKey } = PublicEnv;
  const { Payments } = useStores();
  const { t, Trans } = useTranslation();
  const [cardBrand, setCardBrand] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    zipCode: '',
  });

  const { bt } = useBasisTheory(basisTheoryKey, { elements: true });
  const cardNumberRef = useRef<any>(null);
  const cardExpirationDateRef = useRef<any>(null);
  const cardVerificationCodeRef = useRef<any>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const cardNumber = cardNumberRef.current;
  const cardExpirationDate = cardExpirationDateRef.current;
  const cardVerificationCode = cardVerificationCodeRef.current;

  const handleSubmit = async (): Promise<void> => {
    setErrorMessage('');
    const invalidCardInfo =
      !cardNumberRef.current?.metadata?.valid ||
      !cardExpirationDateRef.current?.metadata?.valid ||
      !cardVerificationCodeRef.current?.metadata?.valid;
    if (invalidCardInfo) {
      setErrorMessage(`Invalid card information. Please verify your information and try again.`);
    }
    try {
      const token = await bt?.tokens.create({
        type: `card`,
        data: {
          number: cardNumber,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          expiration_month: cardExpirationDate.month(),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          expiration_year: cardExpirationDate.year(),
          cvc: cardVerificationCode,
        },
        mask: {
          number: `{{ data.number | reveal_last: 4 }}`,
          last4: `{{ data.number | last4 }}`,
          expirationMonth: `{{ data.expiration_month }}`,
          expirationYear: `{{ data.expiration_year }}`,
        },
        metadata: {
          cardBrand,
        },
      });

      const basisTheoryTokenId = token?.id;
      const card = {
        token: basisTheoryTokenId,
        cardholderFirstName: formData.firstName,
        cardholderLastName: formData.lastName,
        billingPostalCode: formData.zipCode,
      };
      Payments.setCard(card);
      onSave();
    } catch (error) {
      if (error instanceof BasisTheoryValidationError || error instanceof BasisTheoryApiError) {
        setErrorMessage(error.message);
      }
    }
  };

  const saveButtonDisabled = !formData.firstName || !formData.lastName || !formData.zipCode;

  useCheckoutAutoSave(handleSubmit);

  const TM = String.fromCharCode(8482);

  return (
    <BasisTheoryProvider bt={bt}>
      <Container error={errorMessage} data-testid='basis-theory-container'>
        <HeaderText>{t('common.cardInfo', 'Enter Card Information')}</HeaderText>
        {errorMessage && <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>}

        <InputContainer>
          <Input
            label={t('common.firstName', 'First Name')}
            aria-label='First Name Input'
            data-cy='first-name-input'
            data-test='first-name-input'
            name='firstName'
            required
            value={formData.firstName}
            onChange={handleInputChange}
          />
          <Input
            label={t('common.lastName', 'Last Name')}
            aria-label='Last Name Input'
            data-cy='last-name-input'
            data-test='last-name-input'
            name='lastName'
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </InputContainer>
        <BasisTheoryInputWrapper>
          <CardNumberElement
            style={BasisTheoryStyle}
            id='cardNumber'
            ref={cardNumberRef}
            onChange={(e) => setCardBrand(e.cardBrand)}
          />
        </BasisTheoryInputWrapper>
        <InputContainer>
          <BasisTheoryInputWrapper>
            <CardExpirationDateElement
              style={BasisTheoryStyle}
              id='cardExpirationDate'
              ref={cardExpirationDateRef}
              placeholder={t('common.exp', 'Exp Date')}
            />
          </BasisTheoryInputWrapper>
          <BasisTheoryInputWrapper>
            <CardVerificationCodeElement
              style={BasisTheoryStyle}
              id='cardVerificationCode'
              ref={cardVerificationCodeRef}
            />
          </BasisTheoryInputWrapper>
        </InputContainer>
        <Input
          label={t('common.zip', 'Zip/Postal Code')}
          aria-label='Zip/Postal Code input'
          data-cy='zip-code-input'
          data-test='zip-code-input'
          name='zipCode'
          value={formData.zipCode}
          onChange={handleInputChange}
        />
        <Button disabled={saveButtonDisabled} width='73px' height='33px' mr='15px' onClick={() => handleSubmit()}>
          {t('common.save', 'Save')}
        </Button>

        <Disclaimer>
          <Trans i18nKey='checkout.basisTheory.rethinkDisclaimer'>{`By clicking SAVE, you acknowledge that your card information will be collected and shared with Rethink for the
          purchase of ReThink Asset Markers (RAM${TM}). RAMs${TM}; can only be used within the ReThink Financial
          Ecosystem with existing KYC/KYB users`}</Trans>
        </Disclaimer>
      </Container>
    </BasisTheoryProvider>
  );
}

export default BasisTheory;

const BasisTheoryInputWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.white};
    border: 1px solid ${colors.grey[80]};
    border-radius: 4px;
    padding: 7px 16px;
    font-size: 13px;
    width: 100%;
    font-family: arial, sans-serif;
    height: 43px;
  `}
`;

const InputContainer = styled.div`
  display: flex;
  gap: 16px;
`;

// matched these colors from the paysafe form, and they don't exist in out theme so left the hexcodes
const Container = styled.div<{ error: string }>`
  background: #f0f3f6;
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  border: ${({ error }) => (error ? '1px solid #e25241' : 'none')};
`;

const Input = styled(DefaultInput)`
  width: 100%;
`;

const HeaderText = styled.div<{ error: string }>`
  ${({ theme: { colors } }) => css`
    font-size: 13px;
    line-height: 15px;
    color: ${(props) => (props.error ? `#e25241` : colors.grey[40])};
  `}
`;

const Disclaimer = styled(Typography.Body).attrs({
  size: 'xxsmall',
})`
  color: ${({ theme: { colors } }) => colors.grey[45]};
  text-align: center;
`;

// matched these colors from the paysafe form, and they don't exist in our theme so left the hexcodes
export const ErrorMessageContainer = styled.div`
  background-color: #fff0f0;
  border: 1px solid #d8c1c1;
  align-items: center;
  font-size: 13px;
  padding: 12px 8px 9px 15px;
  margin-bottom: 1em;
  border-radius: 4px;
  line-height: 20px;
  color: #8e5656;
`;

const BasisTheoryStyle = {
  base: {
    fontSize: `13px`,
    color: `#5d666d`,
    '::placeholder': {
      color: `#868788`,
    },
  },
  invalid: {
    color: `#e25241`,
  },
};
