import { isBrowser } from 'utils/misc-utils';

/**
 * Creates an object that records method calls which can be played back at a later time.
 *
 * Useful for analytics clients that need to capture events before they're initialized.
 *
 * Usage:
 *   const noopFooClient = createNoopFooClient();
 *   this.fooClient = createDelayedCallClient(noopFooClient);
 *
 *   ... waiting period: use fooClient as you would an actual foo client ...
 *
 *   ... when clientId is available
 *   const actualFooClient = createFooClient(clientId);
 *   // replays all method-calls captured during the waiting period
 *   delayedCallFooClient.updateClient(actualFooClient);
 *   this.fooClient = actualFooClient;
 *
 * @param origClient A no-op version of the client. Used to extract the client's shape and as a fallback if
 *                   recording ability isn't present.
 * @param passthrough Methods to call immediately on origClient (and return their value)
 */
export const createDelayedCallProxy = <
  // Rationale: we want the generic "Function" type here
  // eslint-disable-next-line @typescript-eslint/ban-types
  ConcreteClient extends Record<string, Function>
>(
  origClient: ConcreteClient,
  { passthrough = [] as Array<keyof ConcreteClient> } = {}
): ConcreteClient & { updateClient: (newClient: ConcreteClient) => void } => {
  let actualClient: ConcreteClient | null = null;

  // rationale: safety check
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!isBrowser() || !window.Proxy) {
    // fallback in case Proxy isn't supported
    const proxy = {
      ...origClient,
      updateClient: (newClient: ConcreteClient) => {
        Object.entries(newClient).forEach(([k, v]) => ((proxy as Record<string, unknown>)[k] = v));
      },
    };
    return proxy;
  }

  const calls: [keyof ConcreteClient, any[]][] = [];
  const proxy = (key: keyof ConcreteClient) => (...args: any[]) => {
    if (actualClient) {
      actualClient[key].bind(actualClient)(...args);
    } else {
      calls.push([key, args]);
    }
  };
  const updateClient = (targetClient: ConcreteClient): void => {
    actualClient = targetClient;
    calls.forEach(([fnName, args]) => proxy(fnName)(...args));
  };

  return new Proxy(origClient, {
    get(target, prop: symbol | keyof ConcreteClient) {
      if (prop === 'updateClient') {
        return updateClient;
      }
      if (!isKeyOfObject(prop, target)) {
        return undefined;
      }

      const original = target[prop];
      if (typeof original !== 'function' || passthrough.includes(prop)) {
        return original;
      }

      return proxy(prop);
    },
  }) as ConcreteClient & { updateClient: (newClient: ConcreteClient) => void };
};

function isKeyOfObject<T>(key: PropertyKey, obj: T): key is keyof T {
  return key in obj;
}
