import React, { memo } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { isBlank } from 'underscore.string';

import RichTextHtmlWrapper from 'shared/components/rich-text-html-wrapper';

const DisclaimerText = ({ html, ...props }) => {
  if (isBlank(html)) {
    return null;
  }
  return (
    <Container {...props}>
      <RichTextHtmlWrapper html={html} />
    </Container>
  );
};

export default memo(DisclaimerText);

const Container = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[45]};
  line-height: 20px;
  text-align: center;
  ${space};
  max-width: 900px;
  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    font-size: 10px;
    line-height: 14px;
    padding: 0 25px;
  }
`;
