import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';

import useTranslation from 'src/hooks/use-translation';
import { InlineBanner } from 'src/components/inline-banner';

enum OrderType {
  delivery = 'delivery',
  pickup = 'pickup',
}

type SMSUpdateBannerProps = {
  orderType: OrderType;
  mb?: string | null;
  mt?: string | null;
};

export function SMSUpdateBanner({ orderType, mb = `20px`, mt = `0px` }: SMSUpdateBannerProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <StatusHeaderContainer data-testid='sms-update-banner' width={['100%', '100%', '400px', '100%']} mb={mb} mt={mt}>
      <InlineBanner border={false} padding='16px 18px'>
        <Text fontSize='13px'>
          <strong>{t(`orderStatus.textUpdateNotice`, `Text Update. `)}</strong>
          {t(`orderStatus.textUpdateNotice.${orderType}`, {
            defaultValue: `You’ll receive a text message when your order is {{readyStatus}}!`,
            readyStatus: orderType === 'delivery' ? 'out for delivery' : 'ready for pickup',
          })}
        </Text>
      </InlineBanner>
    </StatusHeaderContainer>
  );
}

const StatusHeaderContainer = styled(Flex)`
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
`;
