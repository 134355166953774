import React from 'react';
import styled from 'styled-components';
import chunk from 'lodash/chunk';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FooterHeader } from 'components/core/footer/footer';

export function FooterList(props) {
  const { title, children, chunkSize = 6 } = props;
  const childList = chunk(children, chunkSize);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));
  function renderGroup(group, i) {
    return <FooterListContainer key={i}>{group}</FooterListContainer>;
  }

  return (
    <Container isMobile={isMobile}>
      <FooterHeader>{title}</FooterHeader>

      <FooterListsWrapper>{childList.map(renderGroup)}</FooterListsWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: ${({ isMobile }) => isMobile && `50%`};
`;

export const FooterListsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FooterListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
  margin-top: 4px;

  a {
    margin-bottom: 8px;
  }
`;
