import React from 'react';

function SvgExitIcon(props) {
  return (
    <svg viewBox='0 0 44 44' {...props}>
      <path d='M29.071 11.393a2.5 2.5 0 013.536 3.536L14.929 32.607a2.5 2.5 0 01-3.536-3.536z' />
      <path d='M32.607 29.071a2.5 2.5 0 01-3.536 3.536L11.393 14.929a2.5 2.5 0 013.536-3.536z' />
    </svg>
  );
}

export default SvgExitIcon;
