import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components';

import useTranslation from 'hooks/use-translation';
import useLogRocket from 'src/hooks/use-logrocket';
import { ARIA_ROLES } from 'shared/constants';
import useStores from 'shared/hooks/use-stores';
import { useOpenDutchiePay } from 'src/payments/hooks/use-open-dutchie-pay/use-open-dutchie-pay';
import useUI from 'src/hooks/use-ui';
import {
  useDutchiePayAnalytics,
  ENROLLMENT_EVENTS,
} from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import { useDutchiePay } from 'src/payments/hooks/use-dutchie-pay/use-dutchie-pay';
import { PBB_NAME } from 'src/payments/constants';

import { CheckIcon } from 'assets/check';
import { ModalObserverRenderProps } from '../modal-observer/modal-observer.types';

import {
  Button,
  DesktopPayByBankLogo,
  DesktopWhiteLabelLogo,
  DesktopImage,
  ImageContainer,
  MobilePayByBankLogo,
  MobileWhiteLabelLogo,
  MobileImage,
  ModalContent,
  ModalWrapper,
  StyledBody,
  StyledHeading,
  StyledIntro,
  StyledList,
  StyledListItem,
  StyledModalContainer,
  StyledModalExitIcon,
  StyledTitle,
} from './cta-modal.styles';
import { MobileEcommDutchiePayButton, MobileEcommDutchiePayCta } from './mobile-ecomm-cta';

export const DutchiePayCtaModalContainer = forwardRef(({ onClose, containerProps }: ModalObserverRenderProps, ref) => {
  const { t, Trans } = useTranslation();
  const { Cart } = useStores();
  const { customized } = useTheme();
  const { track } = useLogRocket();
  const { dutchiePayEventTracker } = useDutchiePayAnalytics();
  const { variant, isMobileEcommApp } = useUI();
  const {
    whiteLabelConfig: { brandedDutchiePayName, useWhiteLabel },
  } = useDutchiePay();
  const { openDutchiePay } = useOpenDutchiePay({
    cname: Cart.dispensary.cName,
    onCloseModal: onClose,
    enrollmentSource: `learn-more-${String(variant)}`,
  });

  const onCtaClick = (): void => {
    // TODO: Setup LogRocket in the FE Analytics framework https://dutchie.atlassian.net/browse/ENG-31004
    dutchiePayEventTracker(ENROLLMENT_EVENTS.CTA_BUTTON_CLICKED);
    openDutchiePay();
    // TODO: Setup LogRocket in the FE Analytics framework https://dutchie.atlassian.net/browse/ENG-31004
    track(`DutchiePay CTA Button Clicked`);
  };

  const bullets = [`Sign up in minutes`, `Pay online or in store`, `Faster & more secure than cash`];

  return (
    <StyledModalContainer data-cy='dutchie-pay-cta-modal' ref={ref} padding={0} {...containerProps}>
      <ModalWrapper>
        <ImageContainer>
          <DesktopImage
            htmlAttributes={{ alt: t('common.modals.dutchie-pay-logo-use', `Image of ${PBB_NAME} in use`) }}
            src='https://images.dutchie.com/pay/dutchie-pay-cta-modal-desktop.jpg'
          />
          <MobileImage
            htmlAttributes={{ alt: t('common.modals.dutchie-pay-logo-use', `Image of ${PBB_NAME} in use`) }}
            src='https://images.dutchie.com/pay/dutchie-pay-cta-modal-mobile.jpg'
          />

          {useWhiteLabel ? (
            <MobileWhiteLabelLogo />
          ) : (
            <MobilePayByBankLogo
              role={ARIA_ROLES.IMG}
              title={t('common.modals.dutchie-pay-logo', `${PBB_NAME} Logo`)}
              width='128'
              height='25'
              fill='#fff'
            />
          )}
        </ImageContainer>

        <ModalContent>
          <StyledModalExitIcon onClick={onClose} />

          {useWhiteLabel ? <DesktopWhiteLabelLogo /> : <DesktopPayByBankLogo width='128' height='25' />}

          <Trans i18nKey='payByBank.easierWayToPay'>
            <StyledHeading size='medium' tag='h1'>
              Want an easier way to pay?
              <br />
              Choose {useWhiteLabel ? brandedDutchiePayName : PBB_NAME}.
            </StyledHeading>
          </Trans>

          <StyledIntro size='large'>
            {t(
              'payByBank.connectYourBank',
              'Connect your bank and make secure, cashless payments with just a few clicks.'
            )}
          </StyledIntro>

          <StyledTitle size='xsmall' casing='uppercase'>
            {t('payByBank.whyPayByBank', 'Why {{name}}', {
              name: useWhiteLabel ? brandedDutchiePayName : PBB_NAME,
            })}
          </StyledTitle>

          <StyledList>
            {bullets.map((bullet) => (
              <StyledListItem key={bullet} tag='li' size='large'>
                <CheckIcon width={20} height={20} />
                {bullet}
              </StyledListItem>
            ))}
          </StyledList>
          {isMobileEcommApp ? (
            <div>
              <StyledBody size='large'>
                <MobileEcommDutchiePayCta initialSignup />
              </StyledBody>
              <MobileEcommDutchiePayButton />
            </div>
          ) : (
            <Button
              style={{ backgroundColor: useWhiteLabel ? customized.colors.buttonsLinks : null }}
              onClick={onCtaClick}
            >
              {t('common.signup', 'Sign Up')}
            </Button>
          )}
        </ModalContent>
      </ModalWrapper>
    </StyledModalContainer>
  );
});
