import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { HorizontalDivider, Link, BottomDrawer } from 'components/core';
import ROUTES from 'src/routes';

import UserIcon from 'assets/user-icon';
import EmailIcon from 'assets/email-icon';
import UnionIcon from 'assets/union-icon';
import { MoneyIcon } from 'assets/money-icon';
import { PBB_NAME } from 'src/payments/constants';

export default function MyAccountDrawer({
  userName,
  joinedDateString,
  onClose,
  open,
  onLogout,
  shouldReplaceHistory,
  isAccountSettingsDutchiePayEnabled,
}) {
  const { t } = useTranslation();

  return (
    <BottomDrawer onClose={onClose} open={open} anchor='bottom'>
      <Header>
        <div>
          <UserName>{userName}</UserName>
          <JoinDate>
            {t('accountModal.joined', 'joined ')}
            {joinedDateString}
          </JoinDate>
        </div>
      </Header>

      <HorizontalDivider />

      <LinkList>
        {isAccountSettingsDutchiePayEnabled && (
          <Link href={ROUTES.DUTCHIE_PAY} replace={shouldReplaceHistory}>
            <LinkItem href={ROUTES.DUTCHIE_PAY}>
              <MoneyIcon /> {t('accountModal.payByBank', `${PBB_NAME}`)}
            </LinkItem>
          </Link>
        )}

        <Link href={ROUTES.ACCOUNT} replace={shouldReplaceHistory}>
          <LinkItem href={ROUTES.ACCOUNT}>
            <UserIcon /> {t('accountModal.accountDetails', 'Account Details')}
          </LinkItem>
        </Link>

        <Link href={ROUTES.PREFERENCES} replace={shouldReplaceHistory}>
          <LinkItem href={ROUTES.PREFERENCES}>
            <EmailIcon /> {t('accountModal.preferences', 'Preferences')}
          </LinkItem>
        </Link>

        <Link href={ROUTES.ORDERS} replace={shouldReplaceHistory}>
          <LinkItem href={ROUTES.ORDERS}>
            <UnionIcon /> {t('accountModal.orders', 'Orders')}
          </LinkItem>
        </Link>
      </LinkList>

      <HorizontalDivider />

      <LogoutContainer onClick={onLogout}>{t('accountModal.logout', 'Logout')}</LogoutContainer>
    </BottomDrawer>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
`;

const UserName = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 6px;
`;

const JoinDate = styled.div`
  font-size: 13px;
  margin-bottom: 25px;
  text-align: initial;
  text-transform: capitalize;
`;

const LinkList = styled.div`
  padding: 14px 0;
`;

const LinkItem = styled.a`
  font-size: 15px;
  font-weight: bold;
  padding: 24px 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 6px;
    margin-right: 19px;
  }
`;

const LogoutContainer = styled.div`
  padding: 25px 0 0 5px;
  font-size: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;
