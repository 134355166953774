/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useTheme } from 'styled-components';

import { fontSizeTokens, fontWeightTokens } from './system';
import { FontSizeTokens, Fonts, FontsSizes, Sizes, TypographyCssProps, Cases, Weights } from './types';

function isValidFontFamily<S extends Sizes>(fontFamily: any, fontsSizes: FontsSizes<S>): fontFamily is Fonts {
  return fontFamily in fontsSizes;
}

function useTypographyFontSize<S extends Sizes>(fontsSizes: FontsSizes<S>, size: S): [string, Array<[string, string]>] {
  const theme = useTheme();
  const { fontFamily } = theme;

  if (!isValidFontFamily(fontFamily, fontsSizes)) {
    throw new Error('invalid fontFamily');
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!size) {
    throw new Error('size prop is required');
  }

  const { base, ...breakpoints } = fontsSizes[fontFamily][size];

  const baseFontSize = fontSizeTokens[base];

  return [baseFontSize, Object.entries(breakpoints).map(([key, token]) => [key, fontSizeTokens[token]])];
}

function useTypographyCss({ baseFontSize, breakpoints, weight, casing, lineHeight }: TypographyCssProps): string {
  const theme = useTheme();

  let css = `
    font-size: ${baseFontSize};
    font-weight: ${weight};
    text-transform: ${casing};
    line-height: ${lineHeight};
  `;

  breakpoints.forEach(([bp, fontSize]) => {
    css += `
        ${theme.breakpoints.up(bp)} {
          font-size: ${fontSize};
        }
      `;
  });

  return css;
}

export function useTypography<S extends Sizes>({
  fontsSizes,
  size,
  casing,
  weight,
  lineHeight,
}: {
  fontsSizes: FontsSizes<S>;
  size: S;
  casing: Cases;
  weight: Weights;
  lineHeight: number;
}): string {
  const [baseFontSize, breakpoints] = useTypographyFontSize(fontsSizes, size);

  const css = useTypographyCss({
    baseFontSize,
    breakpoints,
    casing,
    weight: fontWeightTokens[weight],
    lineHeight,
  });

  return css;
}

type Overrides<T extends Sizes> = Partial<Record<Fonts, Partial<FontSizeTokens<T>>>>;

export function makeFontSizes<T extends Sizes>(
  defaults: FontSizeTokens<T>,
  overrides: Overrides<T> = {}
): FontsSizes<T> {
  const fontSizes = {};

  Object.values(Fonts).forEach((fontFamily) => {
    fontSizes[fontFamily] = {
      ...defaults,
      ...overrides[fontFamily],
    };
  });

  return fontSizes as FontsSizes<T>;
}
