import React from 'react';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { DesktopOnly, MobileOnly } from 'shared/components';

import useCart from 'src/hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

import { ModalContainer, BottomDrawer } from 'components/core';
import { withModalControlsHook, ModalComponentProps } from 'components/modals/with-modal-controls-hook';
import { StoreSwitched } from './store-switched';

type StoreSwitchedModalProps = ModalComponentProps<undefined, boolean>;

type UseStoreSwitched = Pick<StoreSwitchedModalProps, 'onClose'>;

type UseStoreSwitchedReturn = {
  dispensaryName?: string;
  handleClose: () => void;
  handleClearCart: () => void;
};

function useStoreSwitched({ onClose }: UseStoreSwitched): UseStoreSwitchedReturn {
  const { clearOrder } = useCart();
  const { dispensary } = useDispensary();

  const handleClose = (): void => {
    onClose(false);
  };

  const handleClearCart = (): void => {
    clearOrder();
    onClose(true);
  };

  return {
    dispensaryName: dispensary?.name,
    handleClose,
    handleClearCart,
  };
}

const StoreSwitchedModal = ({ onClose }: StoreSwitchedModalProps): JSX.Element | null => {
  const { dispensaryName, handleClose, handleClearCart } = useStoreSwitched({ onClose });

  return dispensaryName ? (
    <>
      <DesktopOnly mediaQuery='sm'>
        <ModalWrapper open>
          <ModalContainer width='468px'>
            <StoreSwitched currentDispensaryName={dispensaryName} onClose={handleClose} onClearCart={handleClearCart} />
          </ModalContainer>
        </ModalWrapper>
      </DesktopOnly>
      <MobileOnly mediaQuery='xs'>
        <BottomDrawer onClose={handleClose} open>
          <StoreSwitched currentDispensaryName={dispensaryName} onClose={handleClose} onClearCart={handleClearCart} />
        </BottomDrawer>
      </MobileOnly>
    </>
  ) : null;
};

const [StoreSwitchedController, useStoreSwitchedModal] = withModalControlsHook<undefined, boolean>(StoreSwitchedModal);

export { useStoreSwitchedModal, StoreSwitchedController };
