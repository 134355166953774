import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { formatCurrency } from 'shared/helpers/utils';
import { getPotencyStrings, Product } from 'src/utils/helpers/product';

import { useAddBuyingOptionToCart } from '../../hooks/use-add-buying-option-to-cart';
import { getPriceForBuyingOption, getQuantityRangeForBuyingOptions } from '../../helpers';

type UseTableProductRowProps = {
  product: Product;
  weight: string | null;
};

type UseTableProductRowReturn = {
  cbdRange: string | null;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleClickAddToCart: () => void;
  basePriceToDisplay: string;
  quantityRange: number[];
  quantitySelectClasses: any;
  quantitySelected: number;
  thcRange: string | null;
};

const makeQuantitySelectStyles = makeStyles({
  paper: {
    width: 55,
    marginTop: 5,
    marginLeft: 6,
  },
  list: {
    margin: `-6px 0`,
    '& li': {
      height: `35px`,
      minHeight: `0`,
      paddingLeft: `23px`,
    },
  },
});

export function useTableProductRow({ product, weight }: UseTableProductRowProps): UseTableProductRowReturn {
  const [quantitySelected, setQuantitySelected] = useState<number>(1);
  const { handleAddToCart } = useAddBuyingOptionToCart();

  useEffect(() => {
    setQuantitySelected(1);
  }, [weight]);

  const quantitySelectClasses = makeQuantitySelectStyles();

  const { CBD: cbdRange, THC: thcRange } = getPotencyStrings({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    CBDContent: product.CBDContent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    THCContent: product.THCContent,
  });

  const quantityRange = getQuantityRangeForBuyingOptions({ product, weightOption: weight });

  const { basePrice } = getPriceForBuyingOption({ product, weightOption: weight });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => setQuantitySelected(Number(e.target.value));

  const handleClickAddToCart = (): void => handleAddToCart({ product, quantity: quantitySelected, weight });

  return {
    cbdRange,
    handleChange,
    handleClickAddToCart,
    basePriceToDisplay: formatCurrency(basePrice),
    quantityRange,
    quantitySelectClasses,
    quantitySelected,
    thcRange,
  };
}
