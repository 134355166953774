type ParsePlaidMetaDataType = { institution: InstitutionDataType; accounts: [AccountsDataType]; linkSessionId: string };
type InstitutionDataType = { institutionId: string; name: string };
type AccountsDataType = { id: string; mask: string | null; name: string; subtype: string; type: string };
export const parsePlaidMetaData = ({ metadata }): ParsePlaidMetaDataType => {
  const filteredAccounts = metadata.accounts.map(({ id, name, mask, type, subtype }) => ({
    id,
    name,
    mask,
    type,
    subtype,
  }));
  return {
    institution: {
      name: metadata.institution.name,
      institutionId: metadata.institution.institution_id,
    },
    accounts: filteredAccounts,
    linkSessionId: metadata.link_session_id,
  };
};
