import useCartController from 'hooks/use-cart-controller';
import useFeatureFlagsController from 'hooks/use-feature-flags-controller';
import useUserController from 'hooks/use-user-controller';
import useUIController from 'hooks/use-ui-controller';
import useSearchPreferencesController from 'hooks/use-search-preferences-controller';

export default function GlobalControllers() {
  useCartController();
  useFeatureFlagsController();
  useUserController();
  useUIController();
  useSearchPreferencesController();

  return null;
}
