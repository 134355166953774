import React from 'react';
import styled from 'styled-components';

import { Tab } from './tabs';

export const HorizontalTab = ({ label, value, ...props }) => <StyledTab label={label} value={value} {...props} />;

const StyledTab = styled(Tab)`
  .MuiTab-wrapper {
    padding: 12px 0px;
    font-size: 14px;
  }

  &.MuiTab-root {
    margin: 0px;
  }
`;
