import React from 'react';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { ModalObserver } from '../modal-observer';
import { DeliveryAddressModal } from './delivery-address-modal';

export const DeliveryAddress = ({ name }: { name: string }): JSX.Element => (
  <ModalObserver name={name}>
    {({ open, onClose, containerProps }) => (
      <ModalWrapper open={open} onClose={() => onClose(false)}>
        <DeliveryAddressModal onClose={onClose} containerProps={containerProps} />
      </ModalWrapper>
    )}
  </ModalObserver>
);
