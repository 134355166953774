import React from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';

import useUI from 'src/hooks/use-ui';
import useCart from 'src/hooks/use-cart';
import { useHeader } from 'src/dispensary/core-menu/components/header/use-header';

import { CartV2 } from 'src/assets/cart-v2';
import { VisuallyHidden } from 'src/components/core/visually-hidden';
import { buttonResetStyles } from 'src/dispensary/core-menu/components/header/header.styles';

export const CartButton = observer((): JSX.Element | null => {
  const UI = useUI();
  const { itemCount } = useCart();
  const { trackEvent } = useHeader();

  if (UI.viewOnlyMode) {
    return null;
  }

  const handleClick = (): void => {
    UI.openCartDrawer();
    trackEvent('open cart');
  };

  return (
    <Button onClick={handleClick} data-testid='cartButton' $hasItems={itemCount > 0}>
      <ButtonWrapper>
        <CartV2 />
        <span data-testid='cart-item-count'>{itemCount}</span>
        <VisuallyHidden>item{itemCount === 1 ? '' : 's'} in your shopping cart</VisuallyHidden>
      </ButtonWrapper>
    </Button>
  );
});

const Button = styled.button<{ $hasItems: boolean }>`
  ${buttonResetStyles}

  border-radius: ${({ theme }) => theme.customized.radius.sm};
  flex: 0 0 59px;
  font-weight: 600;
  height: 32px;
  overflow: hidden;
  width: 59px;

  ${({ $hasItems }) =>
    $hasItems
      ? css`
          background: ${({ theme }) => theme.customized.colors.buttonsLinks};
          color: #fff;
        `
      : css`
          background: #c8d2db;
          color: #485055;
        `}

  @media (max-width: 879px) {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  @media (min-width: 880px) {
    flex: 0 0 67px;
    height: 44px;
    width: 67px;
  }
`;

const ButtonWrapper = styled.span`
  align-items: center;
  background-blend-mode: multiply;
  background: none;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 8px;
  transition: all 0.2s;
  width: 100%;

  @media (min-width: 880px) {
    padding: 0 12px;
  }

  &:hover {
    background: #00000033;
  }
`;
