import React from 'react';
import { useUserExistsCheck } from 'src/payments/signup/views/email-form/use-user-exists-check';
import { ViewTextProps } from 'src/payments/dutchie-pay/signup';
import { EmailForm } from './email-form';

export function EmailFormWrapper({ titleText, subtitleText }: ViewTextProps): JSX.Element {
  const { userExistsCheckQuery } = useUserExistsCheck();

  return <EmailForm titleText={titleText} subtitleText={subtitleText} onSubmit={userExistsCheckQuery} />;
}
