import React from 'react';
import styled, { css } from 'styled-components';

import useColors from 'hooks/use-colors';
import useTranslation from 'hooks/use-translation';

import CartIcon from 'assets/cart';
import { Clickable } from 'shared/components';
import { VisuallyHidden } from 'src/components/core/visually-hidden';

export default function CartButtonMobile({ onButtonClick, variant = `green`, badgeVariant = `green`, itemCount }) {
  const colors = useColors();
  const { t } = useTranslation();

  const cartColors = {
    green: colors.moss[85],
    grey: colors.grey[60],
    white: colors.white,
  };

  const badgeColors = {
    green: colors.lime[30],
    white: colors.white,
  };

  return (
    <CartButton onClick={onButtonClick} data-testid='cart-button'>
      <VisuallyHidden>{t('globalHeader.menuNavMobile.cartButton', 'Display Cart')}</VisuallyHidden>
      <CartIcon
        pathStyles={{
          fill: cartColors[variant],
        }}
      />
      {itemCount > 0 && (
        <ItemCountBadge borderColor={badgeColors[badgeVariant]} large={itemCount >= 10}>
          {itemCount}
        </ItemCountBadge>
      )}
    </CartButton>
  );
}

const CartButton = styled(Clickable)`
  display: flex;
  height: auto;
  align-items: center;
  position: relative;
  svg {
    width: 28px;
    height: 27px;
  }
`;

const ItemCountBadge = styled.div`
  background: ${({ theme }) => theme.colors.primaryOrange};
  border: ${({ borderColor }) => `2px solid ${borderColor}`};
  border-radius: 50%;
  font-size: 15px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.green[95]};

  position: absolute;
  display: flex;
  place-content: center;
  place-items: center;

  left: 12px;

  ${({ large }) =>
    large
      ? css`
          top: -9px;
          width: 26px;
          height: 26px;
        `
      : css`
          top: -7px;
          width: 23px;
          height: 23px;
        `}
`;
