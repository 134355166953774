import { useRouter } from 'next/router';
import { useStores } from 'contexts/stores';
import { PBB_NAME } from 'src/payments/constants';

type OpenDutchiePayType = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  cname: string;
  step?: string;
  onCloseModal?: () => void;
  refHref?: string;
  enrollmentSource?: string;
};

export const useOpenDutchiePay = ({
  step = '',
  onCloseModal,
  refHref,
  enrollmentSource = '',
}: OpenDutchiePayType): { openDutchiePay: () => void } => {
  const router = useRouter();
  const { UI, Cart } = useStores();
  const cname = Cart?.dispensary?.cName;

  const queryOptions = {
    ...(['default', 'mobile-ecomm', 'plus', 'embedded', 'store-front'].includes(UI.variant) && {
      refUrl: refHref ?? router.asPath,
      ...(cname && { cname }),
      ...(step && { step }),
      ...(enrollmentSource && { enrollmentSource }),
    }),
  };

  const openDutchiePay = (): void => {
    if (UI.isMobileEcommApp) {
      // eslint-disable-next-line no-alert
      alert(`Log in to your account on dutchie.com to sign up for ${PBB_NAME}.`);
    } else {
      void router.push({
        pathname: `/pay/signup`,
        query: queryOptions,
      });
      if (onCloseModal) {
        onCloseModal();
      }
    }
  };
  return {
    openDutchiePay,
  };
};
