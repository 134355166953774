import React from 'react';
import styled from 'styled-components';

import { Link } from 'components/core';
import { getColors } from 'src/theme';
import ProductImage from 'components/product-image';
import { formatCurrency } from 'shared/helpers/utils';
import { getCategoryForProduct } from 'shared/helpers/products';
import useTranslation from 'hooks/use-translation';
import usePaths from 'hooks/use-paths';

const { primaryBlue } = getColors();

export default function ProductRow({ item, index, dispensary, onClick }) {
  const { t } = useTranslation();
  const { option, product, quantity, price } = item;
  const { brand, brandName, Name, Status } = product;
  const { href, route } = usePaths({ category: getCategoryForProduct(product), product, dispensary });
  const productBrand = brandName ?? brand?.name;

  return (
    <Row data-testid='product-row'>
      <Image alt={Name} product={product} />
      <ProductInfo>
        <ProductName>{Name}</ProductName>
        {productBrand && <ProductBrand>{productBrand}</ProductBrand>}
        {Status === 'Archived' ? (
          <ProductStatus>Product unavailable</ProductStatus>
        ) : (
          <Link href={href} route={route}>
            <ProductLink href={href} onClick={() => onClick(product, index)}>
              View Item
            </ProductLink>
          </Link>
        )}
      </ProductInfo>
      <Weight>
        <span>{t('common.weight', 'weight')}:</span>&nbsp;{option}
      </Weight>
      <Quantity>
        <span>{t('common.qty', 'qty')}:</span>&nbsp;{quantity}
      </Quantity>
      <Price>{formatCurrency(price)}</Price>
    </Row>
  );
}

const Row = styled.div`
  width: 100%;
  padding: 20px 0;
  color: ${({ theme }) => theme.colors.grey[30]};
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};

  span {
    text-transform: capitalize;
  }

  &:last-of-type {
    border-bottom: 0;
  }
`;

const Image = styled(ProductImage)`
  width: 75px;
  height: 54px;
  margin-right: 10px;
`;

const ProductInfo = styled.div`
  font-size: 12px;
  line-height: 165%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  max-width: 270px;
  width: 100%;
`;

const ProductName = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 3px;
`;

const ProductBrand = styled.div`
  font-size: 12px;
  line-height: 165%;
`;

const ProductStatus = styled.div`
  color: ${({ theme }) => theme.colors.grey[45]};
`;

const ProductLink = styled.a`
  color: ${primaryBlue};
  max-width: 60px;
`;

const Weight = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 165%;
  margin-left: 20px;
  width: 100px;

  span {
    font-weight: bold;
  }
`;

const Quantity = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 165%;
  margin-left: 20px;
  width: 50px;

  span {
    font-weight: bold;
  }
`;

const Price = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 13px;
  line-height: 165%;
  margin-left: 20px;

  span {
    font-weight: bold;
  }
`;
