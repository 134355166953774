import {
  MarketplaceStrainTypeOptionsQueryParamMap,
  DispensaryStrainTypeOptionsQueryParamMap,
} from 'shop/types/strain-type.types';

export const MARKETPLACE_STRAIN_TYPE_OPTIONS = [
  {
    key: `indica`,
    label: `Indica`,
    labelTranslationKey: `strainType.indica`,
    value: MarketplaceStrainTypeOptionsQueryParamMap.indica,
  },
  {
    key: `sativa`,
    label: `Sativa`,
    labelTranslationKey: `strainType.sativa`,
    value: MarketplaceStrainTypeOptionsQueryParamMap.sativa,
  },
  {
    key: `hybrid`,
    label: `Hybrid`,
    labelTranslationKey: `strainType.hybrid`,
    value: MarketplaceStrainTypeOptionsQueryParamMap.hybrid,
  },
];

export const DISPENSARY_STRAIN_TYPE_OPTIONS = [
  {
    key: `indica`,
    label: `Indica`,
    labelTranslationKey: `strainType.indica`,
    value: DispensaryStrainTypeOptionsQueryParamMap.indica,
  },
  {
    key: `sativa`,
    label: `Sativa`,
    labelTranslationKey: `strainType.sativa`,
    value: DispensaryStrainTypeOptionsQueryParamMap.sativa,
  },
  {
    key: `hybrid`,
    label: `Hybrid`,
    labelTranslationKey: `strainType.hybrid`,
    value: DispensaryStrainTypeOptionsQueryParamMap.hybrid,
  },
];
