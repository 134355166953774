import styled from 'styled-components';

import { Select, Button } from 'components/core';
import { heading4, largeCapsSubtext, subparagraph } from 'components/core/typography';

export const Scrollable = styled.div`
  height: calc(90vh - 165px);
  overflow: scroll;
  padding-top: 15px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ProductRowContainer = styled.div`
  height: fit-content;
  padding-bottom: 15px;
`;

export const BaseRow = styled.div`
  display: grid;
  padding: 0 46px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  align-items: flex-start;
  grid-template-columns: 36% 28% 10% 10% 16%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTableRow = styled(BaseRow)`
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
  height: 39px;
  ${subparagraph}
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey[45]};
  align-items: center;
`;

export const ProductTableRow = styled(BaseRow)`
  padding: 17px 46px 17px;
`;

export const DispensaryInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StrandTypeText = styled.p`
  ${largeCapsSubtext};
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-bottom: 8px;
`;

export const PotencyText = styled.div`
  margin-top: 2px;
  ${subparagraph};
  color: ${({ theme }) => theme.colors.grey[30]};
`;

export const PriceText = styled.p`
  ${heading4};
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  margin-top: 12px;
`;

export const StyledSelect = styled(Select)`
  width: 75px;
  height: 42px;
`;

export const QuantitySelect = styled(Select)`
  height: 42px;
  width: 75px;

  .MuiSelect-root {
    position: relative;
    left: -7px;
  }

  > svg {
    right: 14px;
    width: 10px;
  }
`;

export const OptionText = styled.div`
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AddToCartButton = styled(Button)`
  justify-self: flex-end;
  width: 115px;
`;
