/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { ModalContainer, ModalExitIcon } from 'components/core';
import { HorizontalDivider } from 'components/core/divider';
import { GqlDispensaries, GqlOrders, GqlOrder_Product } from 'types/graphql';

import { DispensaryHeader } from './dispensary-header';
import { OrderItem } from './order-item';
import { OrderDetails } from './order-details';
import OrderTotals from '../order-totals';

const POSITION_RATIO = 0.5;
const TOP_ADJUST = 20;

type OrderReceiptMobileProps = {
  dispensary: GqlDispensaries;
  onClose: () => void;
  order: GqlOrders | null;
  loading: boolean;
  handleItemClick: (product: GqlOrder_Product, index: number) => void;
};

export function OrderReceiptMobileV2(props: OrderReceiptMobileProps): JSX.Element | null {
  const modalContainerRef = useRef<HTMLDivElement | null>();
  const [topValue, setTopValue] = useState<number>(TOP_ADJUST);
  const { dispensary, onClose, order, loading, handleItemClick } = props;
  const { taxConfig } = dispensary;

  useEffect(() => {
    if (modalContainerRef.current && topValue === TOP_ADJUST) {
      const { clientHeight } = modalContainerRef.current;
      const top = clientHeight - clientHeight * POSITION_RATIO + TOP_ADJUST;
      setTopValue(top);
    }
  }, [modalContainerRef, topValue, loading]);

  if (!order || loading) {
    return null;
  }

  const { orders } = order;

  return (
    <Container ref={modalContainerRef} isLoading={loading} top={topValue}>
      <ExitButton onClick={onClose} />
      <DispensaryHeader dispensary={dispensary} />
      <ItemsContainer>
        {orders.map((item, index) => (
          <ItemContainer key={item.product?._id}>
            <OrderItem item={item} index={index} dispensary={dispensary} onClick={handleItemClick} />
            <Divider />
          </ItemContainer>
        ))}
      </ItemsContainer>
      <OrderDetails order={order} />
      <TotalsContainer>
        <OrderTotals order={order} taxConfig={taxConfig} />
      </TotalsContainer>
    </Container>
  );
}

const Container = styled(ModalContainer)`
  overflow: auto;
  max-width: 85%;
  display: flex;
  flex-direction: column;
  padding: unset;
  border-radius: 14px;
  padding-top: 24px;
  align-items: center;
  margin-bottom: 40px;
`;

const ExitButton = styled(ModalExitIcon)`
  right: 16px;
  @media (max-width: 350px) {
    right: 10px;
  }
`;

const ItemsContainer = styled.div`
  width: 90%;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ItemContainer = styled.div`
  width: 100%;
`;

const Divider = styled(HorizontalDivider)``;

const TotalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 24px;
  margin-bottom: 40px;
`;
