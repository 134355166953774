import { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';

import useCart from 'hooks/use-cart';
import { durationEstimatesForOrder } from 'shared/helpers/order-estimates';
import { defaultDurationEstimates } from 'shared/constants';
import { GqlDurationEstimates as DurationEstimates } from 'types/graphql';

export type DurationEstimatesReturn = {
  durationEstimates: DurationEstimates;
};

export function useDurationEstimates(dispensary): DurationEstimatesReturn {
  const Cart = useCart();
  const [durationEstimates, setDurationEstimates] = useState<DurationEstimates>(defaultDurationEstimates);
  const address = useObserver(() => Cart.address);

  useEffect(() => {
    async function getEstimates(): Promise<void> {
      if (address) {
        const estimates = await durationEstimatesForOrder(dispensary, { skipMatrixCheck: true });
        setDurationEstimates(estimates);
      }
    }
    void getEstimates();
  }, [dispensary, address]);

  return { durationEstimates };
}
