/* eslint-disable max-classes-per-file */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */

const BASE_URL = `https://api.crossingminds.com`;

let instance: XMindsClient | null = null;

export function getInstance(config: Config): XMindsClient {
  if (!instance) {
    instance = new XMindsClient(config);
  }
  return instance;
}

// for testing usage only
export function setInstance(client: XMindsClient | null): void {
  instance = client;
}

type GetToken = () => Promise<{ jwtToken: string }>;

export type Config = {
  getToken: GetToken;
  jwtToken?: string;
};

export type RecommendationsResponse = {
  items_id: string[];
  next_cursor: string;
};

export type Filter = {
  property_name: string;
  op: 'eq' | 'gt' | 'gte' | 'in' | 'lt' | 'lte' | 'neq' | 'notempty' | 'notin';
  value: string;
};

type CommonParams = {
  scenario: string;
  amt?: number;
  filters?: Filter[];
};

class ResponseError extends Error {
  response: Response;
  constructor(resp: Response) {
    super(`request failed with status: ${resp.status} url: ${resp.url}`);
    this.response = resp;
  }
}

function handleResponse<T>(resp: Response): Promise<T> {
  if (resp.ok) {
    return resp.json();
  }
  throw new ResponseError(resp);
}

async function handleRequest(
  client: XMindsClient,
  request: (...args: any[]) => Promise<unknown>,
  authExpired = false,
  authTries = 0
): Promise<unknown> {
  if (authTries > 1) {
    throw new Error(`exceeded max auth renewal tries`);
  }

  if (!client.isAuthenticated || authExpired) {
    await client.authenticate();
  }

  try {
    return await request();
  } catch (error) {
    if (error instanceof ResponseError && error.response.status === 401) {
      return handleRequest(client, request, true, authTries + 1);
    }
    throw error;
  }
}

function lazyAuth(
  target: XMindsClient,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<(...args: any[]) => Promise<unknown>>
): PropertyDescriptor {
  if (!descriptor.value) {
    return descriptor;
  }

  const method = descriptor.value;

  descriptor.value = async function decorator(...args) {
    return handleRequest(this, method.bind(this, ...args));
  };

  return descriptor;
}

function getURL(path: string, params?: Record<string, any>): string {
  const url = BASE_URL + path;

  if (!params) {
    return url;
  }

  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      queryParams.set(key, String(value));
    }
  });

  return `${url}?${queryParams.toString()}`;
}

const filterToString = ({ property_name, op, value }: Filter): string => `${property_name}:${op}:${value}`;

export class XMindsClient {
  getToken: GetToken;
  jwtToken?: string;

  constructor({ getToken, jwtToken }: Config) {
    this.getToken = getToken;
    this.jwtToken = jwtToken;
  }

  private getHeaders(): Record<string, string> {
    if (!this.jwtToken) {
      throw new Error(`client should initialize before making requests`);
    }
    return {
      'Content-Type': `application/json`,
      authorization: `Bearer ${this.jwtToken}`,
    };
  }

  get isAuthenticated(): boolean {
    return typeof this.jwtToken === 'string';
  }

  async authenticate(): Promise<void> {
    const { jwtToken } = await this.getToken();
    this.jwtToken = jwtToken;
  }

  @lazyAuth
  async getPersonalizedItems(
    userId: string,
    contextItems: string[],
    params: CommonParams
  ): Promise<RecommendationsResponse> {
    /* 
      Ref: https://docs.api.crossingminds.com/endpoints/reco.html#get-profile-based-items-recommendations-with-context-items
    */
    const { amt, scenario, filters } = params;

    const resp = await fetch(getURL(`/recommendation/context-items/users/${userId}/items/`), {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({
        amt,
        scenario,
        filters,
        context_items: contextItems.map((item_id) => ({ item_id })),
      }),
    });

    return handleResponse<RecommendationsResponse>(resp);
  }

  @lazyAuth
  async getSimilarItems(itemId: string, { amt, scenario, filters }: CommonParams): Promise<RecommendationsResponse> {
    /* 
      Ref: https://docs.api.crossingminds.com/endpoints/reco.html#get-similar-items-recommendations
    */
    const params = {
      amt,
      scenario,
      filters: filters && filterToString(filters[0]),
    };

    const resp = await fetch(getURL(`/recommendation/items/${itemId}/items/`, params), {
      headers: this.getHeaders(),
    });

    return handleResponse<RecommendationsResponse>(resp);
  }
}
