import { isBrowser } from 'utils/misc-utils';

export const isAnalyticsDebugMode = (): boolean =>
  process.env.NODE_ENV !== 'production' ||
  (isBrowser() && new URLSearchParams(window.location.search).has('debugAnalytics'));

export const isInternalGTMEnabled = (): boolean =>
  // Confirmed that it's ok to send events in development as they're filtered out, but still adding this includes
  // structure (instead of just assuming true all the time) so it's clear and simply to modify if desired
  ['development', 'qa', 'production'].includes(process.env.NODE_ENV);

export const isSiftEnabled = (): boolean => ['qa', 'production'].includes(process.env.NODE_ENV);
