import React from 'react';
import styled from 'styled-components';
import _map from 'lodash/map';

import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import useTranslation from 'hooks/use-translation';
import useViewport from 'hooks/use-viewport';

import EmptyLoadingState from 'components/empty-loading-state';
import CategoryTile from 'components/category-tile';
import Carousel from 'components/carousel';
import { heading2, heading3 } from 'components/core/typography';
import { ContentWrapper, HorizontalDivider } from 'components/core';

export const responsive = {
  largeDesktopA: {
    breakpoint: {
      max: 4000,
      min: 1170,
    },
    items: 5,
  },
  largeDesktop: {
    breakpoint: {
      max: 1170,
      min: 960,
    },
    items: 4,
  },
  desktop: {
    breakpoint: {
      max: 960,
      min: 860,
    },
    items: 4,
  },
  tablet: {
    breakpoint: {
      max: 860,
      min: 666,
    },
    items: 4,
  },
  largeMobileD: {
    breakpoint: {
      max: 666,
      min: 574,
    },
    items: 3,
  },
  mobile: {
    breakpoint: {
      max: 599,
      min: 0,
    },
    items: 2,
  },
};

export default function CategoryTilesCarousel({ className, ...props }) {
  const { t } = useTranslation();
  const { width } = useViewport();

  const { categories, loading } = useDispensaryCategories();

  if (!categories.length && !loading) {
    return null;
  }

  return (
    <EmptyLoadingState isLoading={loading} page='categoriesCarousel'>
      <Container data-testid='category-tiles'>
        <HeadingContainer>
          <Heading>{t('common.category_plural', 'Categories')}</Heading>
        </HeadingContainer>
        <StyledCarousel
          className={className}
          displayGradient={false}
          itemClass='carousel-item'
          partialVisible={false}
          position='relative'
          responsive={responsive}
          variant='embedded'
          cardWidth={width > 960 ? 246 : 184}
          {...props}
        >
          {_map(categories, (category, i) => (
            <CategoryTile category={category} key={category.key} className={`item item-${i}`} item />
          ))}
        </StyledCarousel>
        <HorizontalDivider />
      </Container>
    </EmptyLoadingState>
  );
}

const Container = styled(ContentWrapper)`
  position: relative;
  :last-child {
    border-bottom: 0;
  }
  max-width: 1200px;
  margin: 60px auto 0;
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 40px auto 0;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 23px;
  margin-bottom: 20px;
`;

const Heading = styled.h2`
  ${heading2}
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${heading3}
  }
`;

export const StyledCarousel = styled(Carousel)`
  left: -20px;

  &.--right {
    right: -46px;
  }

  &.--left {
    left: -10px;
  }

  .react-multi-carousel-list {
    padding: 10px 10px 50px;
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    position: initial;

    &.footer {
      margin-left: -5px;
    }

    li {
      margin-right: 30px !important;
      width: 154px !important;
      height: 135px;
    }
  }

  @media (min-width: 960px) {
    .react-multi-carousel-list {
      li {
        margin-right: 32px !important;
        width: 214px !important;
        height: 187px;
      }
    }
  }
`;
