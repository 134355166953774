import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIdleTimerContext } from 'react-idle-timer';

import { Button, TextButton } from 'src/components/core';
import SvgEmptyGoodsIcon from 'src/assets/empty-goods-icon';
import { DialogContainer, DialogHeading, DialogIcon } from 'src/components/modals/components/simple-dialog';

export type IdleWarningProps = {
  startNewSession: () => void;
  closeAndContinue: () => void;
};

export const IdleWarning = ({ startNewSession, closeAndContinue }: IdleWarningProps): JSX.Element => {
  const idleTimer = useIdleTimerContext();
  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    idleTimer.pause();
  }, [idleTimer]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1_000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  if (timeLeft <= 0) {
    startNewSession();
  }

  return (
    <DialogContainer>
      <Heading fontSize={20}>Are you still there?</Heading>
      <Countdown>{timeLeft}</Countdown>
      <IconContainer>
        <SvgEmptyGoodsIcon width={121} height={91} viewBox='0 0 189 141' />
      </IconContainer>

      <Button onClick={closeAndContinue} size='large' mb={24} fluid data-testid='idle-warning-continue-shopping'>
        Continue Shopping
      </Button>
      <StyledTextButton onClick={startNewSession} data-testid='idle-warning-new-session'>
        Empty cart and start new session
      </StyledTextButton>
    </DialogContainer>
  );
};

const Heading = styled(DialogHeading)`
  margin-bottom: 0;
`;

const Countdown = styled.div`
  color: #828a8f;
  font-size: 18px;
  font-weight: 600;
  font-weight: bold;
  line-height: (22 / 18);
  margin: 4px 0 40px;
`;

const IconContainer = styled(DialogIcon)`
  margin-bottom: 40px;
`;

const StyledTextButton = styled(TextButton)`
  font-size: 16px;
  font-weight: 600;
`;
