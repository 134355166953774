/* eslint-disable react/button-has-type */
import React from 'react';
import styled, { useTheme } from 'styled-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';

import ChevronIcon from 'src/assets/chevron-icon';

import useColors from 'hooks/use-colors';

export type AccordionBannerProps = {
  graphic?: React.ReactNode;
  mainContent: React.ReactNode;
  toggleContent?: React.ReactNode;
  borderRadius?: string;
  marginBottom?: string;
};

export const AccordionBanner: React.FC<AccordionBannerProps> = ({
  graphic,
  mainContent,
  toggleContent,
  borderRadius,
  marginBottom,
}) => {
  const theme = useTheme();
  const colors = useColors();

  const useStyles = makeStyles(() => ({
    root: {
      width: '100%',
      backgroundColor: theme.colors.green20,
      boxShadow: 'none',
      border: 'none',
      minHeight: '68px',
      fontSize: '13px',
      lineHeight: '18px',
      borderRadius: borderRadius ?? '4px',
      marginBottom: marginBottom ?? '',
      zIndex: 1,
      overflow: 'hidden',

      '&.Mui-expanded': {
        marginBottom: marginBottom ?? '',
      },

      '& a': {
        color: theme.colors.green90,
        textDecoration: 'underline',
      },
    },
    summary: {
      margin: 0,
      paddingTop: theme.spaces[2],
      paddingRight: theme.spaces[4],
      paddingBottom: theme.spaces[2],
      paddingLeft: 0,
      height: '68px',
      gap: theme.spaces[2],
    },
  }));

  const classes = useStyles();

  return (
    <Accordion className={classes.root} square>
      <AccordionSummary
        className={classes.summary}
        expandIcon={toggleContent && <ChevronIcon height={10} width={10} fill={colors.green90} />}
      >
        {graphic}
        <MainContent>{mainContent}</MainContent>
      </AccordionSummary>
      {toggleContent && <AccordionDetails>{toggleContent}</AccordionDetails>}
    </Accordion>
  );
};

const MainContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spaces[3]};
`;
