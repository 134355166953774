/* eslint-disable max-len */
import React from 'react';

type BankIconProps = {
  width?: number;
  height?: number;
};

export function BankIcon({ width = 48, height = 48, ...props }: BankIconProps): JSX.Element {
  return (
    <svg width={width} height={height} viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4535 11.6543H6.62891V29.962H11.4535V11.6543Z'
        fill='#EAEFF2'
        stroke='#5E6D79'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.3754 11.6543H24.5508V29.962H29.3754V11.6543Z'
        fill='#EAEFF2'
        stroke='#5E6D79'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.4145 11.6543H15.5898V29.962H20.4145V11.6543Z'
        fill='#EAEFF2'
        stroke='#5E6D79'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M32 30.5H4' stroke='#5E6D79' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.1335 12.0831H3.875L18.0473 2.5L32.1335 12.0831Z'
        fill='#BCCAD2'
        stroke='#5E6D79'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
