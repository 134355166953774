import moment from 'moment';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import usePaths from 'hooks/use-paths';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import {
  openInfoForPickup as getOpenInfoForPickup,
  acceptingAfterHoursForDelivery,
  acceptingAfterHoursForPickup,
} from 'shared/helpers/dispensaries';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import InfoIcon from 'src/assets/info-icon';
import AccessibleLinkText from 'shared/components/accessible-link-text';
import { ARIA_ROLES } from 'shared/constants';
import { Link } from 'components/core';

import OpenInfoText from './open-info-text';

function StoreStatus({ dispensary, ...props }) {
  const { t } = useTranslation();
  const UI = useUI();
  const { href, route } = usePaths({ info: true });
  const isDesktopMenuSize = useMediaQuery((theme) => theme.breakpoints.up(`md`));
  const checkOnDuty = useDispensaryFlag(`dynamic-delivery-on-duty-check`, dispensary?.id);

  const MemoizedOpenInfoText = useMemo(() => {
    const openInfo = openInfoForDispensary(dispensary, {});
    const openInfoForPickup = getOpenInfoForPickup(openInfo).info;
    const openInfoForDelivery = openInfo.delivery;

    const { offerAnyPickupService, offerDelivery, status } = dispensary;
    const dispensaryIsActive = status === 'open';

    const offersPickupAndDelivery = offerAnyPickupService && offerDelivery;
    const onlyOffersPickup = offerAnyPickupService && !offerDelivery;
    const onlyOffersDelivery = offerDelivery && !offerAnyPickupService;

    const pickupReopenMoment = dispensaryIsActive ? openInfoForPickup.nextService.openMoment : null;
    const deliveryReopenMoment = dispensaryIsActive ? openInfoForDelivery.nextService.openMoment : null;
    const knownReopenMoment = pickupReopenMoment || deliveryReopenMoment;
    let reopenDate;

    if (offersPickupAndDelivery) {
      reopenDate = pickupReopenMoment?.isBefore(deliveryReopenMoment) ? pickupReopenMoment : deliveryReopenMoment;
    }

    if (onlyOffersPickup) {
      reopenDate = pickupReopenMoment;
    }

    if (onlyOffersDelivery) {
      reopenDate = deliveryReopenMoment;
    }
    if (!reopenDate) {
      return undefined;
    }
    const reopenHour = parseInt(reopenDate.format('H'), 10);
    const today = moment();
    const currentTime = parseInt(moment().format('H'), 10);
    const tomorrow = today.add(1, 'days');
    // show the date only if we're past this point in time tomorrow or onwards
    const orderingReopens = t('common.orderingReopens', {
      defaultValue: 'Ordering reopens {{date}} at {{time}}',
      date: reopenDate.format(`M/DD`),
      time: reopenDate.format(`h:mma`).replace(':00', ''),
    });
    const orderingReopensToday = t('common.orderingReopensToday', {
      defaultValue: 'Ordering reopens at {{time}}',
      time: reopenDate.format(`h:mma`).replace(':00', ''),
    });
    const nextOpensAt =
      reopenDate !== today && ((reopenDate === tomorrow && reopenHour > currentTime) || reopenDate.isAfter(tomorrow))
        ? orderingReopens
        : orderingReopensToday;

    const acceptAfterHoursDelivery = acceptingAfterHoursForDelivery(dispensary);
    const acceptAfterHoursPickup = acceptingAfterHoursForPickup(dispensary);

    const openForPickup = openInfoForPickup.isOpen;
    let openForDelivery = openInfoForDelivery.isOpen;
    const nearbyEnabled = dispensary?.temporalLocation?.nearbyEnabled;

    if (checkOnDuty && nearbyEnabled) {
      openForDelivery = openInfoForDelivery.isOpen && dispensary?.temporalLocation?.onDuty !== false;
    }

    return (
      <OpenInfoText
        dispensaryIsActive={dispensaryIsActive}
        acceptingAfterHoursForDelivery={acceptAfterHoursDelivery}
        acceptingAfterHoursForPickup={acceptAfterHoursPickup}
        offersDelivery={offerDelivery}
        offersPickup={offerAnyPickupService}
        openForDelivery={openForDelivery}
        openForPickup={openForPickup}
        nextOpensAt={knownReopenMoment ? nextOpensAt : null}
        {...props}
      />
    );
  }, [checkOnDuty, dispensary, props, t]);

  const { omitInfoLink } = props;
  const showInfoLink = !UI.viewOnlyMode && isDesktopMenuSize && !omitInfoLink;

  return (
    <Wrapper role={ARIA_ROLES.REGION} aria-label='Dispensary Open/Closed Status'>
      {MemoizedOpenInfoText}
      {showInfoLink && (
        <Link route={route} href={href}>
          <Anchor href={href} route={route}>
            <AccessibleLinkText>{t('common.dispensaryInformation', 'Dispensary information')}</AccessibleLinkText>
            <InfoIcon />
          </Anchor>
        </Link>
      )}
    </Wrapper>
  );
}

export default memo(StoreStatus);

const Anchor = styled.a`
  position: relative;
  left: 10px;
  margin-right: 7px;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
