import React, { useRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useObserver } from 'mobx-react';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';

import { useColors } from 'contexts/colors';
import useTranslation from 'hooks/use-translation';
import useCart from 'hooks/use-cart';
import useSwitchMenuType from 'hooks/use-switch-menu-type';

import ChevronIcon from 'assets/chevron-icon';
import { heading4 } from 'components/core/typography';
import { Button, HoverMenu } from 'components/core';

export default function MenuTypeDropdown() {
  const colors = useColors();
  const { t } = useTranslation();
  const popupState = usePopupState({ variant: `popover`, popupId: `menuType` });
  const cart = useCart();
  const menuType = useObserver(() => cart.menuType);
  const switchMenuType = useSwitchMenuType();
  const buttonRef = useRef(null);

  const handleTypeSelect = (option) => {
    if (menuType !== option.key) {
      switchMenuType(option.key);
    }
    popupState.close();
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      popupState.toggle(e.target);
    }
  };

  const options = [
    { key: `rec`, label: `Recreational` },
    { key: `med`, label: `Medical` },
  ];

  const buttonLabel = menuType === `med` ? t('common.medical', 'Medical') : t('common.recreational', 'Recreational');

  // direct focus back to the button upon popup close - https://www.w3.org/TR/wai-aria-practices-1.1/examples/menu-button/menu-button-links.html
  const handleExit = () =>
    _.defer(() => {
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    });

  return (
    <>
      <StyledButton ref={buttonRef} {...bindHover(popupState)} onKeyDown={handleEnterKey} tabIndex={0}>
        {buttonLabel} <StyledChevronIcon fill={colors.grey[45]} height='6px' width='11px' />
      </StyledButton>
      <StyledHoverMenu
        {...bindMenu(popupState)}
        onExit={handleExit}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        options={options}
        activeOptionKey={menuType}
        onOptionSelect={handleTypeSelect}
      />
    </>
  );
}

const StyledHoverMenu = styled(HoverMenu)`
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  ${heading4}
  padding: 0;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 13px;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[30]};
  display: flex;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;

  ::after {
    position: absolute;
    width: 100%;
    top: 100%;
    right: 0;
    height: 20px;
    background: transparent;
    content: '';
  }
`;

const StyledChevronIcon = styled(ChevronIcon)`
  margin-left: 8px;
  margin-right: 0 !important;
`;
