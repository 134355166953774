import React, { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import moment from 'moment';

import useStores from 'shared/hooks/use-stores';

type UseAgeReturn = [number, React.Dispatch<React.SetStateAction<number>>];

export function useAge(): UseAgeReturn {
  const [age, setAge] = useState(0);
  const { user } = useStores();
  const ageOverride = useObserver(() => user.ageOverride);
  const birthday = useObserver(() => user.profile?.birthday);

  useEffect(() => {
    const ageDiff = birthday ? moment().diff(moment(birthday, `MM/DD/YYYY`), `years`) : 0;

    if (ageOverride && moment(ageOverride.expires).isAfter(moment()) && ageOverride.age > ageDiff) {
      setAge(ageOverride.age);
    } else if (ageDiff > age) {
      setAge(ageDiff);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ageOverride?.age, birthday]); // FIXME: ENG-32714 fix hooks dependency

  return [age, setAge];
}
