import { useFlags } from 'launchdarkly-react-client-sdk';
import { GqlPersonalizationSettings } from 'types/graphql';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'src/hooks/use-ui';

export function participatingInPersonalization(
  flags: Record<string, unknown>,
  showPersonalizationByMenuVariant: boolean
): boolean {
  const isPersonalizationSettingActive =
    flags['growth.ads.sponsored-brand-carousel.use-personalization-by-menu-variant'] ?? false;
  const isPersonalizationCarouselAdActive = flags['growth.ads.personalization-carousel.placement'] !== 'none';

  if (isPersonalizationSettingActive) {
    return isPersonalizationCarouselAdActive && showPersonalizationByMenuVariant;
  }

  return isPersonalizationCarouselAdActive;
}

export function useParticipatingInPersonalization(): boolean {
  const flags = useFlags();
  const showPersonalizationByMenuVariant = useIsDispensaryMenuVariantEnabledForPersonalization();

  return participatingInPersonalization(flags, showPersonalizationByMenuVariant);
}

export function useIsDispensaryMenuVariantEnabledForPersonalization(): boolean {
  const { dispensary } = useDispensary();
  const { variant } = useUI();

  return getDispensaryMenuVariantEnabledForPersonalization(variant, dispensary?.personalizationSettings);
}

export function getDispensaryMenuVariantEnabledForPersonalization(
  variant: string,
  personalizationSettings?: GqlPersonalizationSettings
): boolean {
  const personalizationSettingsMenuVariantMap = {
    default: 'enablePersonalizationForDefault',
    embedded: 'enablePersonalizationForEmbedded',
    kiosk: 'enablePersonalizationForKiosk',
    'store-front': 'enablePersonalizationForStoreFront',
  };

  const currentMenu = personalizationSettingsMenuVariantMap[variant];

  return personalizationSettings?.[currentMenu] ?? false;
}
