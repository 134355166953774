import { murmur3 } from 'murmurhash-js';
import fetch from 'isomorphic-unfetch';

import type { Operation, Observable } from 'apollo-link';

// const { IsomorphicResponse } = fetch;

export const CELL_COUNT = 5;

type NullOrString = string | null;
type ResolveFunction = (value: CellContext) => void;
type ResolvablePromise<T> = Promise<T> & { resolve: ResolveFunction };
type NullOrStringPromise = ResolvablePromise<NullOrString>;
export type CellContext = NullOrString | NullOrStringPromise;

function IsResolvablePromise<T>(value: NullOrString | Promise<T>): value is ResolvablePromise<T> {
  if (!value || typeof value !== 'object') {
    return false;
  }
  return 'resolve' in value;
}

let currentDispensaryContext: CellContext = null;
let cellRoutingEnabled = false;

export function setCellRoutingEnabled(enabled: boolean): void {
  cellRoutingEnabled = enabled;
}

export function setApolloCellLoading(): void {
  // ECMA2024 includes this new API, but we don't have it yet
  // const { promise: newPromise, resolve: outerResolve } = Promise.withResolvers<NullOrString>();

  // instead we're forced to do this...
  let outerResolve: ResolveFunction | null = null;
  const newPromise = new Promise<NullOrString>((resolve) => {
    outerResolve = resolve;
  });

  const resolvableNewPromise = newPromise as ResolvablePromise<NullOrString>;
  resolvableNewPromise.resolve = (outerResolve as unknown) as ResolveFunction;

  // cancel this promise after 5 seconds
  // just in case navigation etc cancels the chain of request -> response
  const timerId = setTimeout(() => resolvableNewPromise.resolve(null), 5000);
  resolvableNewPromise.then(() => clearTimeout(timerId));

  if (IsResolvablePromise(currentDispensaryContext)) {
    resolvableNewPromise.then(currentDispensaryContext.resolve);
  }

  currentDispensaryContext = resolvableNewPromise;
}

export async function setApolloCellId(id: CellContext): Promise<void> {
  const oldValue = currentDispensaryContext;
  currentDispensaryContext = id;

  if (IsResolvablePromise(oldValue)) {
    oldValue.resolve(await id);
  }
}

export function getApolloCellId(): CellContext {
  return currentDispensaryContext;
}

export function setCellContext<T>(operation: Operation, forward): Observable<T> {
  if (!cellRoutingEnabled) {
    return forward(operation);
  }
  const { cell } = operation.getContext();
  if (!currentDispensaryContext && cell) {
    setApolloCellLoading();
  }

  const context = operation.getContext();

  operation.setContext({
    fetchOptions: {
      ...context.fetchOptions,
      cellContext: currentDispensaryContext,
      operationName: operation.operationName,
    },
  });

  return forward(operation);
}

export async function cellRoutedFetch(url: string, options): Promise<Response> {
  const { cellContext } = options;
  const cellId = await cellContext;

  if (!cellId) {
    return fetch(url, options);
  }
  // murmur3 outputs a raw numeric value
  const hashAsNumber: number = murmur3(cellId, '');
  const bucket = hashAsNumber % CELL_COUNT;

  const urlPrefix = `/api-${bucket}`;

  return fetch(`${urlPrefix}${url}`, options);
}
