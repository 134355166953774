import { GqlMarketplaceProductSearchResult, ProductConsumerType } from 'types/graphql';

export const marketplaceProduct = {
  description: '',
  options: [{ price: 1, unit: 'oz', weight: '1/8' }],
  id: 'foo-bar-product-id',
  name: 'Ice Cream Cake #420',
  strainType: 'Hybrid',
  brandId: 'bc56b5e3-a408-224c-440e-785cd8a9a6d9',
  brand: {
    _id: 'bc56b5e3-a408-224c-440e-785cd8a9a6d9',
    id: 'bc56b5e3-a408-224c-440e-785cd8a9a6d9',
    name: 'Space Monkey',
    imageUrl: 'http://example.com/space_monkey.png',
    aliasList: [],
    isGlobal: true,
    libraryProductsCount: 14,
    productsCount: 1207,
  },
  brandLogo: 'http://example.com/space_monkey.png',
  brandName: 'Space Monkey',
  category: 'Flower',
  locations: [
    {
      point: {
        lat: 122,
        lon: 45,
      },
      id: 'foo-id',
      consumerType: ProductConsumerType.med,
    },
  ],
  minPrice: 12.34,
  cbdContent: {
    unit: 'PERCENTAGE',
    range: [0.2, 0.85],
  },
  thcContent: {
    unit: 'PERCENTAGE',
    range: [20, 24],
  },
  stockImage: 'http://example.com/flower-stock.png',
} as GqlMarketplaceProductSearchResult;
