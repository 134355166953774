import React from 'react';
import styled from 'styled-components';

import { ModalWrapper } from 'shared/modals/v2-wrapper';

import useUI from 'src/hooks/use-ui';
import { useIsMobile } from 'src/hooks/use-is-mobile';

import { bold } from 'components/core/typography';
import { BottomDrawer, ModalContainer, ModalExitIcon } from 'components/core';
import { ModalComponentProps } from 'components/modals/with-modal-controls-hook';

export type AddToCartLayoutProps = Omit<ModalComponentProps, 'data'> & {
  heading: string;
  children: React.ReactNode;
};

export function AddToCartLayout({
  open,
  onClose,
  containerProps,
  heading,
  children,
}: AddToCartLayoutProps): JSX.Element {
  const isMobile = useIsMobile();
  const { isEmbedded } = useUI();

  if (isMobile && !isEmbedded) {
    return (
      <BottomDrawer open={open} onClose={onClose} heading={heading}>
        {children}
      </BottomDrawer>
    );
  }

  return (
    <Modal open={open} onClose={onClose} containerProps={containerProps} heading={heading}>
      {children}
    </Modal>
  );
}

const Modal = ({ open, onClose, containerProps, heading, children }: AddToCartLayoutProps): JSX.Element => (
  <ModalWrapper
    open={open}
    onClose={onClose}
    // WORKAROUND: update e2e tests to look for checkout string or testid
    className='added-to-cart__Container added-to-cart__Content'
  >
    <AddToCartModalContainer width={465} padding={30} {...containerProps}>
      <Header>{heading}</Header>
      <ModalExitIcon onClick={onClose} />
      {children}
    </AddToCartModalContainer>
  </ModalWrapper>
);

const Header = styled.h3`
  margin-bottom: 25px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  ${bold};
  text-align: left;
`;

const AddToCartModalContainer = styled(ModalContainer)`
  ${({ theme }) => theme?.breakpoints?.down('sm')} {
    margin-top: 56px;
  }
`;
