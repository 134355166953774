/* eslint-disable max-len */
import React from 'react';

function InfoIcon(props) {
  return (
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' {...props}>
      <path
        d='M18.2 9.1C18.2 14.1258 14.1258 18.2 9.1 18.2C4.07421 18.2 0 14.1258 0 9.1C0 4.07421 4.07421 0 9.1 0C14.1258 0 18.2 4.07421 18.2 9.1ZM1.82 9.1C1.82 13.1206 5.07937 16.38 9.1 16.38C13.1206 16.38 16.38 13.1206 16.38 9.1C16.38 5.07937 13.1206 1.82 9.1 1.82C5.07937 1.82 1.82 5.07937 1.82 9.1Z'
        fill='#A3AFBA'
      />
      <rect x='7.88666' y='7.28' width='2.42667' height='6.67333' rx='1.21333' fill='#A3AFBA' />
      <circle cx='9.10002' cy='5.15666' r='1.1375' fill='#A3AFBA' />
    </svg>
  );
}

export default InfoIcon;
