import { useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';

import useUI from 'hooks/use-ui';
import useViewport from 'hooks/use-viewport';
import { useModals } from 'components/modals/use-modals';
import { ModalObserverRenderProps, ContainerProps } from './modal-observer.types';

type UseCloseOnRouteChangeProps = {
  isOpen: boolean;
  onClose: (data?: unknown) => void;
};

function useContainerProps(): ContainerProps {
  const UI = useUI();
  const viewport = useViewport();

  return {
    isIframe: UI.isIframe,
    iframeOffset: viewport.offset,
    parentOffset: viewport.parentOffset,
    viewportHeight: viewport.height,
    viewportWidth: viewport.width,
    iframeHeight: UI.iframeHeight,
  };
}

export function useCloseOnRouteChange({ isOpen, onClose }: UseCloseOnRouteChangeProps): void {
  const router = useRouter();

  const handleRouteChange = useCallback(() => {
    if (isOpen) {
      onClose();
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    router.events.on(`routeChangeStart`, handleRouteChange);

    return () => {
      router.events.off(`routeChangeStart`, handleRouteChange);
    };
  }, [router, handleRouteChange]);
}

export function useModalObserver({ name }): ModalObserverRenderProps {
  const containerProps = useContainerProps();
  const { closeModal, activeModals } = useModals();

  const isOpen = activeModals.has(name);
  const data = activeModals.get(name)?.data;

  const handleClose = (d?: unknown): void => {
    closeModal(name, d);
  };

  useCloseOnRouteChange({ isOpen, onClose: handleClose });

  return {
    containerProps,
    open: isOpen,
    onClose: handleClose,
    data,
  };
}
