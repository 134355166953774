import styled from 'styled-components';
import { heading4 } from 'components/core/typography';

export const Header = styled.div`
  ${heading4};
  color: ${({ theme }) => theme.colors.grey[30]};

  margin-bottom: 30px;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  height: 55px;

  margin-top: 15px;

  &:first-of-type {
    margin-top: 0px;
  }

  && > * {
    margin-right: 15px;

    &:last-child {
      margin-right: 0px;
    }
  }

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  & .MuiInputLabel-root {
    color: ${({ theme }) => theme.colors.grey[60]};
  }
`;

export const InputLabel = styled.div`
  flex: auto 0 0;
  width: 80px;
  text-align: right;
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[45]};

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    text-align: left;
  }

  & + div {
    flex-grow: 1;
  }
`;
export const Star = styled.span`
  color: ${({ theme }) => theme.colors.grey[55]};
  font-size: 14px;
`;
