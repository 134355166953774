/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export function MedIcon(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='42' viewBox='0 0 44 42' fill='none' {...props}>
      <path
        d='M36.411 6.2019H35.9637C35.1411 6.2019 34.4744 6.86868 34.4744 7.6912V12.2384H37.9002V7.6912C37.9002 6.86868 37.2335 6.2019 36.411 6.2019Z'
        fill='#BCCAD2'
      />
      <path
        d='M7.13413 6.2019H6.6868C5.86429 6.2019 5.19751 6.86868 5.19751 7.6912V12.2384H8.62333V7.6912C8.62342 6.86868 7.95664 6.2019 7.13413 6.2019Z'
        fill='#BCCAD2'
      />
      <path
        d='M30.039 15.9423H13.0587C12.0359 15.9423 11.2068 15.1131 11.2068 14.0903V6.15899C11.2068 3.11096 13.6865 0.631226 16.7346 0.631226H26.3632C29.4112 0.631226 31.8909 3.11096 31.8909 6.15899V14.0903C31.8909 15.1132 31.0619 15.9423 30.039 15.9423ZM14.9107 12.2384H28.1871V6.15899C28.1871 5.15331 27.3689 4.33509 26.3632 4.33509H16.7346C15.729 4.33509 14.9107 5.15331 14.9107 6.15899V12.2384H14.9107Z'
        fill='#BCCAD2'
      />
      <path
        d='M28.0088 4.33508H26.3633C27.369 4.33508 28.1872 5.1533 28.1872 6.15898V12.2384H29.8326V6.15898C29.8327 5.1533 29.0145 4.33508 28.0088 4.33508Z'
        fill='#93A8B4'
      />
      <path
        d='M12.8522 14.0903V6.15899C12.8522 3.11096 15.332 0.631226 18.38 0.631226H16.7346C13.6865 0.631226 11.2068 3.11096 11.2068 6.15899V14.0903C11.2068 15.1131 12.0359 15.9423 13.0587 15.9423H14.7042C13.6813 15.9423 12.8522 15.1132 12.8522 14.0903Z'
        fill='#9FB3BE'
      />
      <path
        d='M35.78 9.85645H7.31792C3.625 9.85645 0.631348 12.8501 0.631348 16.543V34.682C0.631348 38.375 3.625 41.3686 7.31792 41.3686H35.78C39.4729 41.3686 42.4665 38.375 42.4665 34.682V16.543C42.4665 12.8502 39.4729 9.85645 35.78 9.85645Z'
        fill='#EAEFF2'
      />
      <path
        d='M9.73613 41.3691H7.31767C3.62475 41.3691 0.631348 38.3749 0.631348 34.6828V16.5431C0.631348 12.8502 3.62475 9.85681 7.31767 9.85681H9.73613C6.0432 9.85681 3.04896 12.8502 3.04896 16.5431V34.6828C3.04896 38.3749 6.0432 41.3691 9.73613 41.3691Z'
        fill='#BCCAD2'
      />
      <path
        d='M30.8736 21.4629H25.6986V16.2879C25.6986 15.3524 24.9403 14.5941 24.0049 14.5941H19.0928C18.1573 14.5941 17.399 15.3525 17.399 16.2879V21.4629H12.224C11.2886 21.4629 10.5303 22.2212 10.5303 23.1566V28.0687C10.5303 29.0042 11.2886 29.7625 12.224 29.7625H17.399V34.9374C17.399 35.8729 18.1574 36.6312 19.0928 36.6312H24.0049C24.9403 36.6312 25.6986 35.8728 25.6986 34.9374V29.7625H30.8736C31.8091 29.7625 32.5674 29.0041 32.5674 28.0687V23.1566C32.5674 22.2212 31.8091 21.4629 30.8736 21.4629Z'
        fill='#BCCAD2'
      />
      <path
        d='M38.5312 9.76247V7.691C38.5312 6.52175 37.5799 5.57053 36.4107 5.57053H35.9637C34.7945 5.57053 33.8432 6.52175 33.8432 7.691V9.22515H32.5225V6.15936C32.5225 2.76308 29.7594 0 26.3631 0H19.2292C18.8806 0 18.5979 0.282672 18.5979 0.631341C18.5979 0.980009 18.8806 1.26268 19.2292 1.26268H26.3631C29.0631 1.26268 31.2598 3.45933 31.2598 6.15936V9.22515H28.8186V6.15894C28.8186 4.80501 27.717 3.70345 26.3631 3.70345H16.7347C15.3808 3.70345 14.2792 4.80492 14.2792 6.15894V9.22515H11.8381V6.15936C11.8381 3.45933 14.0347 1.26268 16.7347 1.26268H22.7039C23.0525 1.26268 23.3352 0.980009 23.3352 0.631341C23.3352 0.282672 23.0525 0 22.7039 0H16.7347C13.3385 0 10.5754 2.76308 10.5754 6.15936V9.22515H9.25462V7.691C9.25462 6.52175 8.3034 5.57053 7.13415 5.57053H6.68716C5.51792 5.57053 4.5667 6.52175 4.5667 7.691V9.76238C1.89116 10.8521 0 13.4806 0 16.5431V34.6821C0 38.7172 3.2828 42 7.31791 42H35.7799C39.8151 42 43.0979 38.7172 43.0979 34.6821V16.5431C43.0979 13.4806 41.2067 10.8521 38.5312 9.76247ZM35.1059 7.691C35.1059 7.218 35.4907 6.83321 35.9637 6.83321H36.4107C36.8837 6.83321 37.2685 7.218 37.2685 7.691V9.37743C36.7877 9.27768 36.2898 9.22507 35.7799 9.22507H35.1059V7.691ZM15.5419 6.15894C15.5419 5.50125 16.077 4.96613 16.7347 4.96613H26.3631C27.0208 4.96613 27.5559 5.50117 27.5559 6.15894V9.22515H15.5419V6.15894ZM5.82938 7.691C5.82938 7.218 6.21416 6.83321 6.68716 6.83321H7.13415C7.60715 6.83321 7.99193 7.218 7.99193 7.691V9.22515H7.31791C6.80796 9.22515 6.31013 9.27777 5.82938 9.37752V7.691ZM41.8352 34.6822C41.8352 38.021 39.1188 40.7374 35.7799 40.7374H7.31791C3.97905 40.7374 1.26268 38.021 1.26268 34.6822V16.5431C1.26268 13.2042 3.97905 10.4878 7.31791 10.4878H35.7799C39.1188 10.4878 41.8352 13.2042 41.8352 16.5431V34.6822Z'
        fill='#405D67'
      />
      <path
        d='M30.8732 20.8318H26.33V16.2878C26.33 15.0057 25.2871 13.9628 24.005 13.9628H19.0923C17.8104 13.9628 16.7673 15.0057 16.7673 16.2878V20.8318H12.2242C10.9421 20.8318 9.89917 21.8747 9.89917 23.1568V28.0686C9.89917 29.3506 10.9421 30.3936 12.2242 30.3936H13.5306C13.8793 30.3936 14.162 30.111 14.162 29.7623C14.162 29.4136 13.8793 29.1309 13.5306 29.1309H12.2242C11.6384 29.1309 11.1619 28.6544 11.1619 28.0686V23.1568C11.1619 22.571 11.6384 22.0944 12.2242 22.0944H17.3987C17.7473 22.0944 18.03 21.8118 18.03 21.4631V16.2878C18.03 15.702 18.5065 15.2255 19.0923 15.2255H24.005C24.5907 15.2255 25.0673 15.702 25.0673 16.2878V21.4631C25.0673 21.8118 25.35 22.0944 25.6987 22.0944H30.8732C31.459 22.0944 31.9355 22.571 31.9355 23.1568V28.0686C31.9355 28.6543 31.459 29.1309 30.8732 29.1309H25.6987C25.35 29.1309 25.0673 29.4136 25.0673 29.7623V34.9376C25.0673 35.5234 24.5908 35.9999 24.005 35.9999H19.0923C18.5066 35.9999 18.03 35.5234 18.03 34.9376V29.7623C18.03 29.4136 17.7473 29.1309 17.3987 29.1309H13.056C12.7073 29.1309 12.4247 29.4136 12.4247 29.7623C12.4247 30.111 12.7073 30.3936 13.056 30.3936H16.7673V34.9376C16.7673 36.2196 17.8103 37.2626 19.0923 37.2626H24.005C25.287 37.2626 26.33 36.2196 26.33 34.9376V30.3936H30.8732C32.1552 30.3936 33.1982 29.3507 33.1982 28.0686V23.1568C33.1982 21.8748 32.1552 20.8318 30.8732 20.8318Z'
        fill='#405D67'
      />
    </svg>
  );
}
