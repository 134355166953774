import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';

import useTranslation from 'hooks/use-translation';

import { removeTypename } from 'shared/helpers/utils';
import { dispensaryHoursForDay } from 'shared/helpers/dispensaries';
import { getEnabledV2OrderTypes } from 'shared/core/helpers/dispensaries';
import { ARIA_ROLES, orderTypesToDisplayNames, orderTypesToShortNamesV2 } from 'shared/constants';
import { daysLongToShortNames as days } from 'shared/constants/time';
import { DesktopOnly, MobileOnly, Select } from 'shared/components';
import { paragraph } from 'components/core/typography';

import { Tabs, HorizontalTab } from 'components/core';

import { focusStyles } from 'src/theme';

import { GqlDispensaries, GqlDispensaryHoursSettings, GqlHoursSettingsForOrderType } from 'types/graphql';

type OrderingHoursV2Props = {
  dispensary: GqlDispensaries;
  children: ReactNode;
};

type OrderTypeString = string & keyof GqlDispensaryHoursSettings;

export function OrderingHours(props: OrderingHoursV2Props): JSX.Element {
  const { dispensary, children } = props;
  const { status } = dispensary;
  const hoursSettings = dispensary.hoursSettings ?? {};
  const enabledV2OrderTypes = getEnabledV2OrderTypes(dispensary);
  const initialOrderType = enabledV2OrderTypes[0] as OrderTypeString;

  const { t } = useTranslation();
  const [selectedOrderType, setSelectedOrderType] = useState(initialOrderType);

  const hoursForOrderType: GqlHoursSettingsForOrderType = removeTypename(hoursSettings[selectedOrderType]) ?? {};
  const effectiveHoursForOrderType = hoursForOrderType.effectiveHours ?? [];
  const closedText = t('storeInfo.closed', 'Closed');

  const handleOrderTypeChange = (e: React.ChangeEvent<HTMLInputElement>, value: string): void => {
    const newValue = e.target.value || value;
    setSelectedOrderType(newValue as OrderTypeString);
  };

  // NOTE: in embedded, its possible (although not probable) for us to render this component twice
  // once for the actual info route, and another time if they expand the menu and click on the info link
  // this just keeps our set of ids unique
  const instanceId = v4();

  return (
    <OrderingHoursContainer>
      <HoursMenu>
        <DesktopOnly>
          <HoursTabs onChange={handleOrderTypeChange} orientation='horizontal' value={selectedOrderType}>
            {enabledV2OrderTypes.map((orderType: string) => (
              <HoursTab
                key={`${instanceId}-hours-display-${orderType}`}
                id={`${instanceId}-hours-display-${orderType}`}
                aria-controls={`${instanceId}-hours-display-${orderType}-tabpanel`}
                aria-selected={selectedOrderType === orderType}
                label={orderTypesToShortNamesV2[orderType]}
                role={ARIA_ROLES.TAB}
                value={orderType}
              />
            ))}
          </HoursTabs>
        </DesktopOnly>
        <MobileOnly>
          <HoursSelect aria-label='Ordering Hours' onChange={handleOrderTypeChange}>
            {enabledV2OrderTypes.map((orderType: string) => (
              <option
                key={`${instanceId}-hours-display-${orderType}`}
                id={`${instanceId}-hours-display-${orderType}`}
                aria-controls={`${instanceId}-hours-display-${orderType}-tabpanel`}
                aria-selected={selectedOrderType === orderType}
                value={orderType}
              >
                {orderTypesToDisplayNames[orderType]}
              </option>
            ))}
          </HoursSelect>
        </MobileOnly>
      </HoursMenu>
      <HoursContent id={`${instanceId}-hours-display-${selectedOrderType}-tabpanel`}>
        {children}
        <OrderingHoursTable cellSpacing={0}>
          <tbody>
            {Object.keys(days).map((day) => {
              const abbrevDay = days[day];
              return (
                <TableRow key={abbrevDay}>
                  <BodyHeader scope='row' firstColumn>{`${day}:`}</BodyHeader>
                  <BodyCell>
                    {status !== 'open' ? closedText : dispensaryHoursForDay(effectiveHoursForOrderType[day])}
                  </BodyCell>
                </TableRow>
              );
            })}
          </tbody>
        </OrderingHoursTable>
      </HoursContent>
    </OrderingHoursContainer>
  );
}

export const OrderingHoursContainer = styled.div`
  width: 95%;
  max-width: 455px;
`;

const OrderingHoursTable = styled.table`
  table-layout: fixed;
`;

const HoursMenu = styled.div`
  margin-bottom: 20px;
`;

const HoursSelect = styled(Select)`
  margin-top: 10px;
`;

const HoursTabs = styled(Tabs)`
  &.MuiTabs-root {
    overflow: visible;
  }

  .MuiTabs-flexContainer {
    max-width: 430px;
  }

  .MuiTab-root {
    padding: 0;
    margin-right: 9%;

    ${({ theme }) => theme.breakpoints.up(`lg`)} {
      margin-right: 12%;
    }
  }

  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.primaryBlack};
  }

  /* Override inline style so the focus style is visible */
  .MuiTabs-scroller.MuiTabs-fixed {
    overflow: visible !important;
  }

  .Mui-focusVisible {
    ${focusStyles}
  }
`;

const HoursTab = styled(HorizontalTab)`
  .MuiTab-wrapper {
    color: ${({ selected, theme }) => (selected ? theme.colors.primaryBlack : theme.colors.grey[45])};
    font-weight: ${({ selected }) => (selected ? `bold` : `normal`)};

    &:hover {
      color: ${({ theme }) => theme.colors.primaryBlack};
    }
  }
`;

const HoursContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  min-width: 0;
`;

const TableRow = styled.tr`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  ${({ theme }) => theme.breakpoints.up(`md`)} {
    display: block;
  }
`;

const cellStyles = css`
  max-width: 120px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 8px 0;

  ${({ firstColumn }) =>
    firstColumn
      ? `
    font-weight: bold;
    max-width: 80px;
  `
      : `
    max-width: 120px;
  `}

  ${({ theme }) => theme.breakpoints.up(`md`)} {
    max-width: ${({ firstColumn }) => (firstColumn ? '140px' : '125px')};
  }
`;

const BodyCell = styled.td`
  ${cellStyles}
  ${paragraph}
`;

const BodyHeader = styled.th`
  ${cellStyles}
  ${paragraph}
`;
