import React from 'react';
import styled from 'styled-components';
import Skeleton from './loading-skeleton';

const productMobileRowLoadingSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonTopSection>
      <SkeletonDetails>
        <Skeleton height={8} width={67} mb='12px' />
        <Skeleton height={10} width={159} mb='12px' />
        <Inline>
          <Skeleton height={8} width={35} mr='6px' />
          <Skeleton height={8} width={35} />
        </Inline>
      </SkeletonDetails>

      <Skeleton height={85} width={85} />
    </SkeletonTopSection>

    <SkeletonBottomSection>
      <Skeleton height={59} width={66} />
      <Skeleton height={59} width={66} ml={13} mr={13} />
      <Skeleton height={59} width={66} />
    </SkeletonBottomSection>
  </SkeletonWrapper>
);

export default productMobileRowLoadingSkeleton;

const SkeletonWrapper = styled.div`
  width: 100%;
  height: 192px;
  margin-top: 14px;
  padding-bottom: 25px;
`;

const SkeletonTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SkeletonDetails = styled.div``;

const Inline = styled.div`
  display: flex;
`;

const SkeletonBottomSection = styled.div`
  margin-top: 10px;
  display: flex;
`;
