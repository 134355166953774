import Big from 'big.js';
import createPayment from 'src/payments/graphql/mutations/create-payment.gql';
import { useStores } from 'contexts/stores';
import { formatPaymentsError } from 'src/payments/hooks/use-payments-error/use-payments-error';
import useErnie from 'shared/hooks/use-ernie';
import useApolloClient from 'shared/hooks/use-apollo-client';
import { ModalNames, useModals } from 'components/modals';
import { ERROR_TYPES } from 'src/payments/constants';
import { GqlListRetailerPaymentTypesStatusQuery, useListRetailerPaymentTypesStatusQuery } from 'types/graphql';
import { useDutchiePay } from 'src/payments/hooks';

const useRethinkPay = (): {
  handleRethinkPay: (callback: () => void) => void;
  getRethinkUrl: () => Promise<void>;
  enrollmentStatus: GqlListRetailerPaymentTypesStatusQuery | undefined;
  watchForPaymentsOverlayClose: () => void;
} => {
  const apolloClient = useApolloClient();

  const { Cart, Payments, User } = useStores();
  const { openModal, closeModal } = useModals();
  const showErnie = useErnie();
  const { whiteLabelConfig } = useDutchiePay();

  const openRethinkPayWindow = (): void => {
    Payments.rethinkWindow = window.open(`${window.location.origin}/payments/loading`, `_blank`);
    void openModal(ModalNames.rethinkPayPaymentProcessing);
  };

  const { data: enrollmentStatus, refetch: refetchRetailerPaymentTypeStatus } = useListRetailerPaymentTypesStatusQuery({
    variables: { ecommLocationId: Cart.dispensary?.id },
    skip: !Cart.dispensary?.paymentTypesAccepted.rethinkPay,
  });

  const getRethinkUrl = async (): Promise<void> => {
    void refetchRetailerPaymentTypeStatus();
    try {
      const cardValuesHasEmptyField = Object.values(Payments.card).includes('');
      if (cardValuesHasEmptyField) {
        showErnie(`There was an error processing your credit card. Please confirm credit card details.`, `danger`);
        void closeModal(ModalNames.rethinkPayPaymentProcessing, {});
        Payments.rethinkWindow.close();
        return;
      }
      const response = await apolloClient.mutate({
        mutation: createPayment,
        variables: {
          amount: Number(
            Big(Cart.costBreakdown.total - Cart.costBreakdown.tip)
              .times(100)
              .toFixed(0)
          ),
          tipAmount: Cart.tipValue?.dollar ? Number(Big(Cart.tipValue?.dollar).times(100).toFixed(0)) : null,
          checkoutToken: Cart.checkoutToken,
          currency: 'USD',
          ecommLocationId: Cart.dispensary.id,
          card: { ...Payments.card },
          customerDetails: {
            firstName: User.exists ? User.firstName : Cart.guestCustomer.firstName,
            lastName: User.exists ? User.lastName : Cart.guestCustomer.lastName,
            phone: User.exists ? User.phone : Cart.guestCustomer.phone,
          },
          redirectUrl: `${window.location.origin}/payments/redirect`,
        },
      });

      if (response.data.createPayment.redirectUrl && Payments.rethinkWindow !== null) {
        Payments.setPaymentId(response.data.createPayment.payment.id);
        Payments.rethinkWindow.location = response.data.createPayment.redirectUrl;
      }
    } catch (err) {
      console.error(err);
      if (Payments.rethinkWindow) {
        void closeModal(ModalNames.rethinkPayPaymentProcessing, {});
        void refetchRetailerPaymentTypeStatus();
        Payments.rethinkWindow.close();
      }

      const { errorMarkdown, ernieType, ernieTimeout } = formatPaymentsError(
        err,
        whiteLabelConfig.brandedDutchiePayName,
        ERROR_TYPES.CREDIT
      );
      showErnie(errorMarkdown, ernieType, ernieTimeout);
    }
  };

  const watchForPaymentsOverlayClose = (): void => {
    if (Payments.rethinkWindow && !Payments.rethinkWindow.closed) {
      const checkWindowInterval = setInterval(() => {
        if (Payments.rethinkWindow?.closed) {
          void closeModal(ModalNames.rethinkPayPaymentProcessing, {});
          clearInterval(checkWindowInterval);
        }
      }, 500);
    }
  };

  const handleRethinkPay = (callback: () => void): void => {
    openRethinkPayWindow();
    callback();
  };

  return { getRethinkUrl, handleRethinkPay, watchForPaymentsOverlayClose, enrollmentStatus };
};

export default useRethinkPay;
