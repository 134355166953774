import React from 'react';
import styled from 'styled-components';

import useColors from 'hooks/use-colors';
import useTranslation from 'hooks/use-translation';
import useOrderType from 'hooks/use-order-type';

import { PopUpModalDrawer, PopUpModalContainer, VerticalDivider } from 'components/core';
import { paragraph, bold } from 'components/core/typography';
import { WeightTiles } from '../components/weight-tiles';
import { ProductTable } from '../components/product-table';
import { BuyingOptionsProps } from '../buying-options.types';
import { useWeightedBuyingOptions } from '../hooks/use-weighted-buying-options';

export function BuyingOptionsModal({ buyingOptions, onClose, open, product }: BuyingOptionsProps): JSX.Element {
  const colors = useColors();
  const { t } = useTranslation();
  const { getOrderType } = useOrderType();
  const { category } = product;

  const {
    filteredBuyingOptions,
    handleWeightChange,
    isWeighted: showWeightTiles,
    selectedWeight,
    weights,
  } = useWeightedBuyingOptions({
    category,
    buyingOptions,
  });

  return (
    <StyledPopUpModalDrawer open={open} onClose={onClose}>
      <StyledPopUpModalContainer>
        <Header>
          <div>
            {product.image && <img src={product.image} alt={product.name} />}
            <CopyContainer>
              <Subheading>{product.brand?.name}</Subheading>
              <Heading>{product.name}</Heading>
            </CopyContainer>
          </div>

          <div>
            {showWeightTiles && (
              <FiltersContainer>
                <OptionsContainer>
                  <Label>{t('common.weight', 'Weight:')}</Label>
                  <WeightTiles
                    handleWeightChange={handleWeightChange}
                    selectedWeight={selectedWeight}
                    weightOptions={weights}
                  />
                </OptionsContainer>
                <VerticalDivider width={2} height={50} m='-3px 22px 0' _color={colors.grey[90]} />
              </FiltersContainer>
            )}

            <SortedByCopy>{t('common.sort', 'Sorted by:')}</SortedByCopy>
            <Label>
              {getOrderType() === 'delivery'
                ? t('sortedBy.deliveryTime', 'Delivery time')
                : t('sortedBy.distance', 'Distance')}
            </Label>
          </div>
        </Header>
        <ProductTable options={filteredBuyingOptions} selectedWeight={selectedWeight} />
      </StyledPopUpModalContainer>
    </StyledPopUpModalDrawer>
  );
}

const StyledPopUpModalDrawer = styled(PopUpModalDrawer)`
  .MuiPaper-root {
    align-items: center;
    display: flex;
    overflow: hidden;
    width: fit-content;
  }
`;

const StyledPopUpModalContainer = styled(PopUpModalContainer)`
  max-width: 1090px;
  width: 90vw;
`;

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  && {
    place-items: flex-start;
  }
`;

const FiltersContainer = styled.div`
  margin-right: 8px;
`;

const Header = styled.div`
  display: flex;
  place-content: space-between;
  padding: 8px 30px 24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[80]};
  img {
    max-height: 63px;
    max-width: 90px;
    width: 100%;
    height: auto;
    margin-right: 6px;
  }
  div {
    display: flex;
    place-items: center;
    place-content: center;
  }
`;

const Heading = styled.h3`
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const Subheading = styled.p`
  font-size: 11px;
  font-weight: 700;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.grey[45]};
`;

const Label = styled.span`
  ${bold}
  ${paragraph}
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-right: 9px;
  min-width: fit-content;
`;

const SortedByCopy = styled.span`
  ${paragraph}
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-right: 9px;
  min-width: fit-content;
`;

const OptionsContainer = styled.div`
  display: flex;
  place-items: center;
  margin-right: 8px;
`;
