import { noop, isUndefined } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useApolloQuery from 'shared/hooks/use-apollo-query';
import getPaymentsCheckoutCredentials from 'shared/graphql/payments/queries/get-checkout-credentials.gql';
import getMonerisTokenizationProfile from 'shared/graphql/payments/queries/get-moneris-tokenization-profile.gql';
import useCart from 'shared/hooks/use-cart';

export function useCheckoutCredentials(adapter) {
  const flags = useFlags();
  const Cart = useCart();
  const {
    order: { dispensary },
  } = Cart;

  const useNewCheckoutCredentialsQuery =
    flags['migration.use-adapter-pattern-payments-checkout-credentials'] || adapter !== 'moneris';
  const query = useNewCheckoutCredentialsQuery ? getPaymentsCheckoutCredentials : getMonerisTokenizationProfile;
  const variables = useNewCheckoutCredentialsQuery
    ? {
        adapter,
        dispensaryId: dispensary?.id,
      }
    : { dispensaryId: dispensary?.id };
  const dataKey = useNewCheckoutCredentialsQuery ? 'getPaymentsCheckoutCredentials' : 'getMonerisTokenizationProfile';

  const { data = {}, loading, refetch } = useApolloQuery(query, variables, {
    skip: !dispensary || isUndefined(useNewCheckoutCredentialsQuery),
  });

  return [data[dataKey], loading ? noop : refetch];
}
