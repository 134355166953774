import { useCallback } from 'react';

import { useAddToCartModal, findSingleWeightItem } from 'src/components/modals/add-to-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { Product } from 'src/utils/helpers/product';

type UseQuickAddToCartReturn = {
  handleAddToCart: (product: Product, trackerSource: string | null) => Promise<void>;
};

export const useQuickAddToCart = (): UseQuickAddToCartReturn => {
  const { dispensary } = useDispensary();
  const { open: openAddToCartModal } = useAddToCartModal();

  const handleAddToCart = useCallback(
    async (product: Product, trackerSource: string | null = null) => {
      void openAddToCartModal({ ...findSingleWeightItem(product), product, dispensary, trackerSource });
    },
    [dispensary, openAddToCartModal]
  );

  return { handleAddToCart };
};
