export const brandColors = {
  // Brand
  flora: '#FF3E51',
  opal: '#153F66',
  moss: '#1F4F40',
  digitalMoss: '#297C2C',
  lavender: '#E3D8FB',
  cedar: '#FFFF76',
  terra: '#F8A867',
  white: '#FFF',
  sapphire: '#0075E0',
  dutchiePayGreen: '#00A47C',
  dutchiePayBlack: '#43474A',

  // GreyTones
  // TODO: Map these scaled grey shades to a numbered range
  veryLightGrey: '#F1F3F5',
  lightGrey: '#D1D2D3',
  mediumGrey: '#929394',
  darkGrey: '#767676',
  black: '#272727',

  // Product
  opal01: '#F2F4F6',
  opal02: '#8DA1B4',
  opal03: '#446583',
  opal04: '#102F4D',
  opal05: '#0B2033',
  opal12: '#0b2033',

  blue10: '#EDF1FA',
  blue80: '#083A7E',
};
