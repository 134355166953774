import React from 'react';

import { withModalObserver, ModalObserverProps } from 'components/modals/modal-observer';
import { MobileOnly } from 'shared/components';

import { useDistanceFilterBottomDrawer } from './use-distance-filter-bottom-drawer';
import { DistanceFilterBottomDrawer } from '../distance-filter-bottom-drawer';

export function DistanceFilterBottomDrawerWrapper({ open, onClose }: ModalObserverProps): JSX.Element {
  const { distance, handleDistanceChange, handleSave } = useDistanceFilterBottomDrawer({ onClose, open });

  return (
    <MobileOnly>
      <DistanceFilterBottomDrawer
        onChange={handleDistanceChange}
        onClose={onClose}
        onSave={handleSave}
        open={open}
        value={distance}
      />
    </MobileOnly>
  );
}

export const DistanceFilterBottomDrawerController = withModalObserver(DistanceFilterBottomDrawerWrapper);
