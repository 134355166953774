import _ from 'lodash';
import { css } from 'styled-components';

export const breakpoints = {
  mobile: 415,
  smallTablet: 534.5, // 2 1/2 cards (in actuality this is 600 on up)
  tablet: 750.5, // 3 1/2 cards
  largeTablet: 966.5, // 4 1/2 cards
  desktop: 1187, // 5 1/2 cards
};

export const defaultCarouselState = (nextEnabled) => ({
  snapIndex: 0,
  gradient: `right`,
  lastTransitionType: `drag`,
  nextEnabled,
  prevEnabled: false,
  currentBreakpoint: `desktop`,
});

/**
 * Determines carousel breakpoint based on current viewport width
 * @param  {Number} width viewport width
 * @return {String} breakpoint
 */
export const getCurrentBreakpoint = (width) => {
  const { tablet, largeTablet, desktop, mobile } = breakpoints;
  let breakpoint = `desktop`;

  if (width < desktop && width >= largeTablet) {
    breakpoint = `largeTablet`;
  } else if (width < largeTablet && width >= tablet) {
    breakpoint = `tablet`;
  } else if (width < tablet && width > mobile) {
    breakpoint = `smallTablet`;
  } else if (width <= mobile) {
    breakpoint = `mobile`;
  }

  return breakpoint;
};

/**
 * Determines carousel margins based on current card width and breakpoints
 * @param  {Number} width product card width
 * @param  {Object} margins carousel-specific presets
 * @return {Number} margin
 */
export const getCurrentMargins = (width, margins) => {
  const { tablet, largeTablet, desktop, mobile } = breakpoints;
  let margin = margins.desktop;

  if (width < desktop && width >= largeTablet) {
    margin = margins.largeTablet;
  }

  if (width < largeTablet && width >= tablet) {
    margin = margins.tablet;
  }

  if (width < tablet && width >= mobile) {
    margin = margins.smallTablet;
  }

  return margin;
};

export const gradients = {
  left: css`linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 10%)`,
  right: css`linear-gradient(90deg, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%)`,
  both: css`linear-gradient(90deg,
    rgba(255,255,255,1) 0%,
    rgba(255,255,255,0) 10%,
    rgba(255,255,255,0) 90%,
    rgba(255,255,255,1) 100%)`,
};

export const productRowSizes = {
  mobile: 2,
  smallTablet: 2,
  tablet: 3,
  largeTablet: 4,
  desktop: 5,
};

export const categoryRowSizes = {
  mobile: 2,
  smallTablet: 3,
  tablet: 4,
  largeTablet: 4,
  desktop: 5,
};

export const dispensariesRowSizes = {
  mobile: 1,
  smallTablet: 1,
  tablet: 2,
  largeTablet: 2,
  desktop: 4,
};

export const offersRowSizes = {
  mobile: 2,
  smallTablet: 2,
  tablet: 3,
  largeTablet: 3,
  desktop: 3,
};

export const hybridRowSizes = {
  mobile: 2,
  smallTablet: 3,
  tablet: 5,
  largeTablet: 6,
  desktop: 7,
};

export const defaultMargins = {
  // this lets us widen our last card a bit more to let the drop-shadow show on hover
  nudge: 14,
  mobile: 0,
  smallTablet: 0,
  tablet: 0,
  largeTablet: 0,
  desktop: 0,
};

export const defaultOffersMargins = {
  // this lets us widen our last card a bit more to let the drop-shadow show on hover
  nudge: 0,
  mobile: 0,
  smallTablet: 14,
  tablet: 14,
  largeTablet: 14,
  desktop: 27,
};

export const defaultDispensariesMargins = {
  // this lets us widen our last card a bit more to let the drop-shadow show on hover
  nudge: 45,
  mobile: 0,
  smallTablet: 0,
  tablet: 20,
  largeTablet: 0,
  desktop: 40,
};

/**
 * Determines carousel card width based on the target element
 * @param  {Object} target HTMLInputElement
 * @return {Number} card width
 */
export const getCardWidth = (target) => target?.childNodes?.[0]?.childNodes[0]?.clientWidth || 0;

export const atCarouselStart = (position) => position === 0;

export const atCarouselEnd = (position, maximum, offset) => position >= maximum - offset;

export const settings = (slidesToScroll = 1, startIndex = 0) => ({
  align: `start`,
  containScroll: `trimSnaps`,
  draggable: false,
  loop: false,
  slidesToScroll,
  speed: 5,
  startIndex,
});

export const parseTransform = (str = '') => Math.abs(parseInt(_.split(_.split(str, `translate3d(`)[1], `%`)[0], 10));

// accelerate until halfway, then decelerate
export const easeInOutQuad = (time, initialValue, changedValue, duration) => {
  time /= duration / 2;
  if (time < 1) {
    return (changedValue / 2) * time * time + initialValue;
  }
  time -= 1;
  return (-changedValue / 2) * (time * (time - 2) - 1) + initialValue;
};

export const scrollTo = (element, to, duration, direction = 1) => {
  const start = element.scrollLeft;
  const change = to + direction * start;
  let currentTime = 0;
  const increment = 20;

  const animateScroll = () => {
    currentTime += increment;
    element.scrollLeft = easeInOutQuad(currentTime, start, change, duration);
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    } else {
      element.style.scrollSnapType = `x mandatory`;
    }
  };
  animateScroll();
};

// note: this isn't even used anymore, but we can use this same method
// to animate to a given position by translating px on the container
export const dragTo = (element, start, to, duration, direction = 1) => {
  const change = to + direction * start;
  let currentTime = 0;
  const increment = 20;

  const animateDrag = () => {
    currentTime += increment;
    const val = Math.abs(easeInOutQuad(currentTime, start, change, duration));
    element.style.transform = `translate3d(-${val}px, 0px, 0px)`;

    if (currentTime < duration) {
      setTimeout(animateDrag, increment);
    } else if (!to) {
      scrollTo(element.parentNode, 0, duration, -1);
    }
  };
  animateDrag();
};

export const roundHalf = (num) => Math.round(num * 2) / 2;
