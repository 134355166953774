export class FrozenMap<Key, Value> {
  private readonly _map: Map<Key, Value>;

  constructor(...entries: Array<[Key, Value]>) {
    this._map = new Map<Key, Value>(entries);
  }

  get(key: Key): Value {
    const value = this._map.get(key);

    if (value === undefined) {
      throw new Error(`FrozenMap should always return Value for a given Key`);
    }

    return value;
  }

  has(key: any): key is Key {
    return this._map.has(key);
  }

  entries(): Array<[Key, Value]> {
    return Array.from(this._map.entries());
  }

  static reverse<K, V>(map: FrozenMap<K, V>): FrozenMap<V, K> {
    return new FrozenMap<V, K>(...map.entries().map(([key, value]): [V, K] => [value, key]));
  }
}

export const valueOrNull = <T>(value: T | null | undefined): T | null => value ?? null;
