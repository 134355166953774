export enum GenericCategoryRoute {
  flower = 'flower',
  preRolls = 'pre-rolls',
  edible = 'edible',
  vaporizers = 'vaporizers',
  concentrate = 'concentrate',
  cbd = 'cbd',
  seeds = 'seeds',
  clones = 'clones',
  topicals = 'topicals',
  accessories = 'accessories',
  tincture = 'tincture',
  apparel = 'apparel',
  nA = 'n-a',
  orals = 'orals',
}

export enum GenericCategory {
  flower = 'Flower',
  preRolls = 'Pre-Rolls',
  edible = 'Edible',
  vaporizers = 'Vaporizers',
  concentrate = 'Concentrate',
  cbd = 'CBD',
  seeds = 'Seeds',
  clones = 'Clones',
  topicals = 'Topicals',
  accessories = 'Accessories',
  tincture = 'Tincture',
  apparel = 'Apparel',
  nA = 'N/A',
}
