import React from 'react';
import styled from 'styled-components';

import { MagnifyingGlass } from 'src/assets/magnifying-glass';
import { VisuallyHidden } from 'src/components/core/visually-hidden';
import { buttonResetStyles } from 'src/dispensary/core-menu/components/header/header.styles';
import { useSearch } from './use-search';

export const Search = (): JSX.Element | null => {
  const {
    isSearchDisabled,
    isSearchOpen,
    handleOpenSearch,
    handleChange,
    handleBlur,
    searchTerm,
    searchInputRef,
    dispensaryName,
  } = useSearch();

  if (isSearchDisabled) {
    return null;
  }

  return (
    <SearchContainer>
      <NavButton onClick={handleOpenSearch}>
        <MagnifyingGlass />
        Search
      </NavButton>

      {isSearchOpen && (
        <SearchForm>
          <VisuallyHidden>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
            <label htmlFor='header-search'>Search</label>
          </VisuallyHidden>

          <SearchInputMagnifyingGlass />
          <SearchInput
            type='text'
            id='header-search'
            name='header-search'
            placeholder={`Search ${String(dispensaryName)}`}
            onBlur={handleBlur}
            onChange={handleChange}
            ref={searchInputRef}
            value={searchTerm}
          />
        </SearchForm>
      )}
    </SearchContainer>
  );
};

const NavButton = styled.button`
  ${buttonResetStyles}

  align-items: center;
  color: currentColor;
  display: block;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  gap: 8px;
  justify-content: space-between;
  line-height: ${20 / 14};
  padding: 8px 0;
`;

const SearchContainer = styled.div`
  flex: 1;

  @media (min-width: 880px) {
    position: relative;
  }
`;

const SearchForm = styled.div`
  left: 20px;
  position: absolute;
  right: 75px;
  top: 50%;
  transform: translateY(-50%);

  @media (min-width: 880px) {
    left: 0;
    right: 0;
  }
`;

const SearchInput = styled.input`
  border: 1px solid #c8d2db;
  border-radius: ${({ theme }) => theme.customized.radius.buttons};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  height: 40px;
  padding: 0 0 0 40px;
  transition: all 0.2s;
  width: 100%;

  &:focus {
    border-color: ${({ theme }) => theme.colors.blue[55]};
    box-shadow: 0 1px 5px #0b99e666;
    outline: none;
  }
`;

const SearchInputMagnifyingGlass = styled(MagnifyingGlass)`
  color: #485055;
  left: 12px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
