import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

import useUI from 'hooks/use-ui';
import useDispensaryDeliveryInfo from 'dispensary/hooks/use-dispensary-delivery-info';

import { consumerDispensaries } from 'shared/graphql/dispensary/queries';

export const getQuery = () => consumerDispensaries;

export const getVariables = (dispensaryId, filters) => ({
  dispensaryFilter: {
    cNameOrID: dispensaryId,
    ...filters,
  },
});

export const getDispensary = async (apolloClient, props) =>
  apolloClient.query({
    query: getQuery(),
    variables: getVariables(props.params.cName),
    skip: !props.params.cName,
  });

function getError(query) {
  // eslint-disable-next-line lodash/prefer-lodash-method
  const params = Object.keys(query).join(', ');
  const error = new Error(
    `useDispensary may only be called from a route with a cName parameter. Found keys: ${params}`
  );
  error.query = query;

  return error;
}

export default function useDispensary(id) {
  const { isFirstRender } = useUI();
  const { isReady, query } = useRouter();
  const { cName, dispensaryId: dispoIdFromQuery } = query;

  const dispensaryId = id || cName || dispoIdFromQuery;

  const variables = getVariables(dispensaryId);

  const { data, loading, error, ...apolloValues } = useQuery(consumerDispensaries, {
    variables,
    skip: isFirstRender || !isReady || !dispensaryId,
  });

  const dispensary =
    _.find(data?.filteredDispensaries, (d) => d.cName === cName || d.id === dispensaryId) ||
    _.head(data?.filteredDispensaries);

  const deliveryInfoResult = useDispensaryDeliveryInfo(isReady ? dispensaryId : null);

  const finalDispensary = useMemo(() => {
    if (!deliveryInfoResult || !dispensary) {
      return dispensary;
    }
    return {
      ...dispensary,
      deliveryInfo: deliveryInfoResult.deliveryInfo || dispensary.deliveryInfo,
      taxConfig: {
        ...dispensary.taxConfig,
        taxes: deliveryInfoResult.taxes || dispensary.taxConfig.taxes,
      },
    };
  }, [dispensary, deliveryInfoResult]);

  if (isReady && !dispensaryId) {
    return {
      data,
      loading,
      error: getError(query),
      dispensary: finalDispensary,
      ...apolloValues,
    };
  }

  return {
    data,
    loading: isFirstRender || !isReady || loading,
    error,
    dispensary: finalDispensary,
    dispensaryId,
    ...apolloValues,
  };
}
