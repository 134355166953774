import React from 'react';
import styled from 'styled-components';
import { space, justifyContent, layout } from 'styled-system';
import _ from 'lodash';
import ClipLoader from 'react-spinners/ClipLoader';
import LocationIcon from 'assets/location-icon';
import useTranslation from 'hooks/use-translation';
import { useColors } from 'contexts/colors';
import AddressSuggestion from './address-suggestion';

export default function PlacesList(props) {
  const {
    selectedIndex,
    suggestionConfig,
    currentLocationLoading,
    handleCurrentLocationSelected,
    errorConfig,
    listContainerStyles,
    currentLocationStyles,
    isInDropdown = true,
    showCheckmarkForSelectedAddress,
    setIsPlacesListFocused,
    onKeyPress,
    isHomepage,
  } = props;
  const { t } = useTranslation();
  const colors = useColors();

  return (
    <>
      {navigator.geolocation && (
        <CurrentLocation
          {...currentLocationStyles}
          isInDropdown={isInDropdown}
          isHomepage={isHomepage}
          hasSuggestions={!_.isEmpty(suggestionConfig.suggestionsForSearch)}
          justifyContent={currentLocationLoading ? `center` : `flex-start`}
          onClick={(e) => {
            e.stopPropagation();
            handleCurrentLocationSelected();
          }}
        >
          {!currentLocationLoading && (
            <>
              <LocationIcon height={isInDropdown ? '21px' : '19px'} width={isInDropdown ? '21px' : '19px'} />
              {t('common.use_current_location', 'Use Current Location')}
            </>
          )}
          {currentLocationLoading && <ClipLoader color={colors.grey[45]} size={21} />}
        </CurrentLocation>
      )}
      {errorConfig.hasError && <ErrorContainer isInDropdown={isInDropdown}>{errorConfig.errorMessage}</ErrorContainer>}
      {!_.isEmpty(suggestionConfig.suggestionsForSearch) && (
        <ListContainer
          {...listContainerStyles}
          onFocus={() => setIsPlacesListFocused(true)}
          onBlur={() => setIsPlacesListFocused(false)}
          onKeyPress={onKeyPress}
          isHomepage={isHomepage}
          hasSuggestions={!_.isEmpty(suggestionConfig.suggestionsForSearch)}
        >
          {_.map(suggestionConfig.suggestionsForSearch, (suggestion, index) => (
            <AddressSuggestion
              data-testid='address-autocomplete-option'
              key={`${suggestion.id}.${index}`}
              selected={selectedIndex === index}
              suggestion={suggestion}
              fullWidth={!isInDropdown}
              onClick={(v) => suggestionConfig.method(v)}
              value={suggestionConfig.mapboxSuggestions?.[index] || suggestion}
              showCheckmark={selectedIndex === index && showCheckmarkForSelectedAddress}
            />
          ))}
        </ListContainer>
      )}
    </>
  );
}

const CurrentLocation = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryGrey};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.grey[45]};
  cursor: pointer;
  display: flex;
  font-weight: bold;
  border: 0;

  :focus,
  :active {
    box-shadow: ${({ theme }) => `0 0 5px 0 ${theme.colors.blue[55]}`};
    z-index: 5000;
  }

  font-size: ${({ isInDropdown }) => (isInDropdown ? `15px` : ` 13px`)};
  height: ${({ isInDropdown }) => (isInDropdown ? `50px` : ` 40px`)};
  padding: ${({ isInDropdown }) => (isInDropdown ? `25px 16px` : `0 13px`)};
  margin: ${({ isInDropdown }) => (isInDropdown ? `0 22px 9px` : ` 0 0 9px`)};
  margin-bottom: ${({ isHomepage, hasSuggestions }) => (isHomepage && hasSuggestions ? `2px` : `9px`)};

  ${justifyContent}

  svg {
    margin-right: 10px;
  }

  ${space}
  ${layout}
`;

const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.colors.red[55]};
  margin: 7px 0 2px;
  line-height: 43px;
  font-size: 13px;
  padding-left: ${({ isInDropdown }) => (isInDropdown ? `39px` : `0`)};
  text-align: left;
  height: auto;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    padding-left: 8px;
  }
`;

const ListContainer = styled.button`
  padding: 0;
  margin: 0 22px;
  ${space}
  background: inherit;
  border: none;
  padding-bottom: ${({ isHomepage, hasSuggestions }) => (isHomepage && hasSuggestions ? `21px` : `0`)};
`;
