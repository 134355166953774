import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { formatCurrency } from 'shared/helpers/utils';
import { getDefaultRewardsProgramDisplayName } from 'shared/core/helpers/dispensaries';
import { paymentMethodsToDisplayNames } from 'shared/constants';

const DiscountRow = ({ credit, t }) => (
  <tr>
    <td>{t('common.discounts', 'discounts')}:</td>
    <Spacer />
    <td>{`-${formatCurrency(credit, { trimZeroCents: false })}`}</td>
  </tr>
);

const RewardRow = ({ order, dispensary, t }) => {
  const [reward] = order.appliedRewards;

  const rewardsDisplayName =
    dispensary?.storeSettings?.rewardsIntegrationConfiguration?.rewardsProgramDisplayName ??
    getDefaultRewardsProgramDisplayName(dispensary);

  return (
    <tr>
      <td>{t('common.appliedRewards', rewardsDisplayName)}:</td>
      <Spacer />
      <td>
        {reward.operator === '$' && `(${(formatCurrency(reward.value), { trimZeroCents: false })})`}
        {reward.operator === '%' &&
          `(${formatCurrency(reward.value * 0.01 * parseFloat(order.subtotal), { trimZeroCents: false })})`}
      </td>
    </tr>
  );
};

const OrderTotals = ({ order, taxConfig }) => {
  const { t } = useTranslation();
  const {
    cannabisTax,
    deliveryFee,
    dispensary,
    paymentFee,
    subtotal,
    taxAmount,
    totalCost,
    mixAndMatch,
    appliedRewards,
    bottleDepositTaxCents,
    credit,
    tipAmount,
    paymentMethod,
  } = order;
  const combinedTaxes = parseFloat(cannabisTax, 10) + parseFloat(taxAmount, 10) + parseFloat(bottleDepositTaxCents, 10);
  const applyDiscountPreTax = _.includes([`both`, `discountsFirst`], taxConfig?.discountTaxOrder);
  const hasAppliedRewards = appliedRewards?.length > 0;
  const paymentMethodDisplayName =
    paymentMethod in paymentMethodsToDisplayNames
      ? `${paymentMethodsToDisplayNames[paymentMethod]} Fee`
      : `Payment Fee`;

  return (
    <>
      <SubtotalTable>
        <tbody>
          <tr>
            <td>{t('common.subtotal', 'subtotal')}:</td>
            <Spacer />
            <td>{formatCurrency(subtotal, { trimZeroCents: false })}</td>
          </tr>

          {mixAndMatch > 0 && (
            <tr>
              <td>{t('common.mixAndMatch', 'mix and match')}:</td>
              <Spacer />
              <td>-{formatCurrency(mixAndMatch, { trimZeroCents: false })}</td>
            </tr>
          )}

          {applyDiscountPreTax && credit > 0 && <DiscountRow credit={credit} t={t} />}
          {applyDiscountPreTax && hasAppliedRewards && <RewardRow order={order} dispensary={dispensary} t={t} />}

          {combinedTaxes > 0 && (
            <tr>
              <td>{t('common.taxes', 'taxes')}:</td>
              <Spacer />
              <td>{formatCurrency(combinedTaxes, { trimZeroCents: false })}</td>
            </tr>
          )}

          {!applyDiscountPreTax && hasAppliedRewards && <RewardRow order={order} dispensary={dispensary} t={t} />}
          {!applyDiscountPreTax && credit > 0 && <DiscountRow credit={credit} t={t} />}

          {deliveryFee > 0 && (
            <tr>
              <td>{t('common.deliveryFee', 'delivery fee')}:</td>
              <Spacer />
              <td>{formatCurrency(deliveryFee, { trimZeroCents: false })}</td>
            </tr>
          )}

          {paymentFee > 0 && (
            <tr>
              <td>{t('common.paymentFee', paymentMethodDisplayName)}:</td>
              <Spacer />
              {/*  paymentfee is in cents, so we convert it to dollars */}
              <td>{formatCurrency(paymentFee / 100, { trimZeroCents: false })}</td>
            </tr>
          )}

          {tipAmount > 0 && (
            <tr>
              <td>{t('common.tips', 'tips')}:</td>
              <Spacer />
              <td>{formatCurrency(tipAmount, { trimZeroCents: false })}</td>
            </tr>
          )}
        </tbody>
      </SubtotalTable>

      <Total>
        <span>{t('common.orderTotal', 'order total')}:</span>
        {formatCurrency(totalCost, { trimZeroCents: false })}
      </Total>
    </>
  );
};

export default OrderTotals;

const SubtotalTable = styled.table`
  border: 0;
  text-align: left;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-bottom: 10px;
  margin-top: -10px;
  border-spacing: 0 10px;
  line-height: 135%;

  tbody > tr > td {
    vertical-align: top;
    white-space: nowrap;

    &:first-child {
      text-transform: capitalize;
    }

    &:last-child {
      font-weight: bold;
      text-align: right;
    }
  }
`;

const Spacer = styled.td`
  width: 100%;
`;

const Total = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  color: ${({ theme }) => theme.colors.grey[30]};
  padding-top: 20px;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  font-size: 16px;

  span {
    text-transform: uppercase;
    white-space: nowrap;
    width: 110px;
  }
`;
