import React, { useRef, useCallback } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Button } from 'shared/components';
import { DefaultInput, CreditCardInput, ExpirationDateInput, CvvInput } from 'shared/components/text-input-with-label';
import { withScript } from 'shared/hooks/use-script';
import { mediaQueries } from 'shared/styles';
import PublicEnv from 'shared/utils/public-env';
import { useCheckoutAutoSave } from 'checkout/hooks/use-checkout-auto-save';

import useCart from 'shared/hooks/use-cart';

const options = {
  environment: PublicEnv.appEnv === 'production' || PublicEnv.paysafeEnvironment === 'live' ? 'LIVE' : 'TEST',
  fields: {
    cardNumber: {
      selector: '.cardNumber',
      placeholder: '0000 0000 0000 0000',
    },
    expiryDate: {
      selector: '.expirationDate',
      placeholder: 'MM/YY',
    },
    cvv: {
      selector: '.cvvInput',
    },
  },
  style: {
    input: {
      'font-family': 'arial',
      'font-size': '13px',
      color: '#5d666d',
    },
  },
};

class PaysafeForm extends React.Component {
  constructor(props) {
    super(props);
    this.instance = null;
    this.state = {
      focused: {
        // naming here set by paysafe
        CardNumber: false,
        ExpiryDate: false,
        Cvv: false,
      },
      cardType: '',
      paysafeError: false,
      fieldsError: false,
      zipCode: '',
      loading: false,
      scriptLoaded: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { scriptLoading, merrcoPublicToken } = this.props;
    // make sure the script has finished loading before
    // trying to run the setup
    if (prevProps.scriptLoading && !scriptLoading) {
      // this conditional will not allow for an infinite loop, and we need to
      // know when the script has loaded
      this.setState({ scriptLoaded: true }, this.attemptToSetupPaysafe);
    }

    // if we didn't have a public token available at some point,
    // attempt to run the setup once we do.
    if (!prevProps.merrcoPublicToken && merrcoPublicToken) {
      // this conditional also ensures the following is only called once
      this.attemptToSetupPaysafe();
    }
  }

  attemptToSetupPaysafe = () => {
    const { merrcoPublicToken } = this.props;
    const { scriptLoaded } = this.state;
    // if the script has loaded and we have a token available, run the setup helper.
    if (scriptLoaded && merrcoPublicToken) {
      if (!window.paysafe) {
        this.setState({ paysafeError: true });
        return;
      }
      const mql = window.matchMedia(mediaQueries.largePhone);
      if (mql.matches) {
        options.style.input['font-size'] = '16px';
      }
      window.paysafe.fields.setup(merrcoPublicToken, options, (paysafeInstance, error) => {
        if (error) {
          this.setState({ paysafeError: true });
          console.error(`Setup error: ${error.code} ${error.detailedMessage}`);
        } else {
          this.instance = paysafeInstance;
          this.setupEventHandlers();
        }
      });
    }
  };

  setupEventHandlers = () => {
    // on focus, shrink mui input label
    this.instance.fields('cvv cardNumber expiryDate').on('Focus', (_inst, event) => {
      this.setState({ focused: { ...this.state.focused, [event.target.fieldName]: true } });
    });

    // on blur, if empty, remove focus and unshrink mui input label.
    this.instance.fields('cvv cardNumber expiryDate').on('Blur', (_inst, event) => {
      if (event.data.isEmpty) {
        this.setState({ focused: { ...this.state.focused, [event.target.fieldName]: false } });
      }
    });

    // update credit card image on value change
    this.instance.cardBrandRecognition((_inst, event) => {
      this.setState({ cardType: _.lowerCase(event.data.cardBrand) });
    });
  };

  handleSave = () => {
    const { handleError, onToken } = this.props;
    const { instance } = this;
    const { zipCode, cardType } = this.state;

    return new Promise((resolve, reject) => {
      if (!instance) {
        this.setState({ paysafeError: true });
        handleError('There was an error loading this payment type. Please reload the page and try again.');
        reject(new Error('No paysafe instance'));
        return;
      }
      if (!instance.areAllFieldsValid() || zipCode.length < 5) {
        this.setState({ fieldsError: true });
        handleError('Invalid card information. Please verify your information and try again.');
        reject(new Error('Invalid paysafe fields'));
        return;
      }

      this.setState({ loading: true });
      instance.tokenize((_paysafeInstance, error, result) => {
        if (error) {
          this.setState({ paysafeError: true });
          handleError('There was an error loading this payment type. Please reload the page and try again.');
          console.error(`Tokenization error: ${error.code} ${error.detailedMessage}`, error);
          reject(error);
        } else {
          // clear errors
          this.setState({ fieldsError: false, paysafeError: false, loading: false });
          handleError('');
          onToken(result.token, zipCode, cardType);
          resolve();
        }
      });
    });
  };

  handleClick = async () => {
    await this.handleSave();
  };

  render() {
    const { hideSave, scriptError } = this.props;
    const { paysafeError, fieldsError, focused, zipCode, loading, cardType } = this.state;

    const showError = paysafeError || scriptError || fieldsError;
    return (
      <Container error={showError}>
        <HeaderText>Enter credit card information</HeaderText>
        <CreditCardInput
          containerClassName={`cardNumber iframe-input ${!focused.CardNumber && 'unfocused'}`}
          mt='16px'
          label='Card Number'
          aria-label='card number input'
          data-cy='card-number-input'
          data-test='card-number-input'
          width='100%'
          cardType={cardType}
          InputLabelProps={{ shrink: focused.CardNumber }}
        />
        <InputsContainer>
          <ExpirationDateInput
            containerClassName={`expirationDate iframe-input ${!focused.ExpiryDate && 'unfocused'}`}
            label='Exp Date'
            aria-label='expiration date input'
            data-cy='expiration-date-input'
            data-test='expiration-date-input'
            InputLabelProps={{ shrink: focused.ExpiryDate }}
            mr='8px'
            width='calc(100% - 8px)'
          />
          <CvvInput
            containerClassName={`cvvInput iframe-input ${!focused.Cvv && 'unfocused'}`}
            placeholder='CVV'
            label='CVV'
            aria-label='cvv input'
            data-cy='cvv-input'
            data-test='cvv-input'
            InputLabelProps={{ shrink: focused.Cvv }}
            ml='8px'
            width='calc(100% - 8px)'
          />
        </InputsContainer>
        <InputsContainer>
          <ZipCodeInput
            label='Zip/Postal Code'
            aria-label='Zip/Postal Code input'
            data-cy='zip-code-input'
            data-test='zip-code-input'
            value={zipCode}
            onChange={({ target }) => this.setState({ zipCode: target.value })}
          />
          <div />
        </InputsContainer>

        {!hideSave && (
          <Button mt='25px' width='73px' height='33px' mr='15px' onClick={this.handleClick} loading={loading}>
            Save
          </Button>
        )}
      </Container>
    );
  }
}

const PaysafeFormWithScript = withScript(PaysafeForm, `https://hosted.paysafe.com/js/v1/latest/paysafe.min.js`);

export default function WrappedPaysafeForm(props) {
  const paysafeFormRef = useRef();
  const Cart = useCart();

  const saveHandler = useCallback(async () => {
    if (paysafeFormRef.current && Cart.order.paymentMethod === 'payOnlineMerrco') {
      await paysafeFormRef.current.handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paysafeFormRef.current]);

  useCheckoutAutoSave(saveHandler);

  return <PaysafeFormWithScript ref={paysafeFormRef} {...props} />;
}

const Container = styled.div`
  background: #f0f3f6;
  border-radius: 8px;
  width: 100%;
  padding: 18px;
  border: ${({ error }) => (error ? '1px solid #e25241' : 'none')};
`;

const HeaderText = styled.div`
  font-size: 13px;
  line-height: 15px;
  color: ${(props) => (props.error ? '#e25241' : '#5d666d')};
`;

const InputsContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ZipCodeInput = styled(DefaultInput)`
  width: 100%;
  /* setting this to match iframed inputs */
  input.mui-dt-input-input {
    font-family: arial !important;
    -webkit-font-smoothing: auto !important;
  }
`;
