import React, { forwardRef } from 'react';
import _ from 'lodash';
import { Box } from 'rebass';

import { formatEstimate, getAfterHoursWindow, formatAfterHoursEstimate } from 'shared/helpers/order-estimates';
import { getScheduledOrderTime, willSendOrderReadySMS } from 'shared/helpers/orders';

import useCart from 'src/hooks/use-cart';
import useUser from 'src/hooks/use-user';
import useTranslation from 'src/hooks/use-translation';

import CalendarIcon from 'assets/calendar-icon';
import PinIcon from 'assets/pin-icon';
import ShoppingBagIcon from 'assets/bag-icon-2';

import { SMSUpdateBanner } from 'src/components/modals/components/sms-update-banner';
import DutchiePayCheckoutSuccessCTA from 'src/payments/components/checkout-success-cta';
import useStyledTheme from 'shared/hooks/use-styled-theme';
import { CreditAuthNotification } from 'src/payments/components';

import {
  BannerContainer,
  DetailContent,
  DetailIcon,
  DetailTitle,
  DetailText,
  OrderDetail,
  OrderDetailsWrapper,
  OrderInstructions,
  OrderSubmitted,
  StyledModalContainer,
  StyledModalExitIcon,
  SuccessContainer,
} from './guest-checkout-success-dutchie-pay.styles';

const GuestCheckoutSuccessDutchiePay = forwardRef(({ containerProps, onClose }, ref) => {
  const cart = useCart();
  const User = useUser();
  const { t } = useTranslation();
  const theme = useStyledTheme();

  const { stashedOrder } = cart;

  const {
    deliveryOption: serviceType,
    dispensary,
    isAfterHoursOrder,
    isDelivery,
    reservation,
    openInfo,
    guestCustomer,
    orderId,
  } = stashedOrder;

  const showOrderReadySMSNotice = willSendOrderReadySMS(dispensary, stashedOrder);

  const serviceTypeToTextMap = {
    curbsidePickup: `curbside pickup`,
    driveThruPickup: `drive thru pickup`,
  };

  const shouldShowEstimate =
    !reservation && !(serviceType === 'delivery' && dispensary.featureFlags?.hideDeliveryEstimate);

  const detailedServiceType = serviceType === 'pickup' ? `inStorePickup` : serviceType;

  const estimateTypeText =
    isAfterHoursOrder || detailedServiceType === 'inStorePickup'
      ? `Pickup time `
      : `Estimated ${serviceTypeToTextMap[detailedServiceType] || detailedServiceType} time `;

  const estimateTime = isAfterHoursOrder
    ? _.upperFirst(formatAfterHoursEstimate(getAfterHoursWindow(openInfo, detailedServiceType)))
    : formatEstimate(_.get(cart, `durationEstimates[${serviceType}]`), serviceType);

  let scheduledOrderTime, formattedScheduledOrderType, formattedScheduledOrderTime;
  if (reservation) {
    scheduledOrderTime = getScheduledOrderTime({ reservation, timezone: dispensary.timezone });
    formattedScheduledOrderType = `${serviceTypeToTextMap[serviceType] || serviceType} time`;
    formattedScheduledOrderTime = `${scheduledOrderTime.prefix}, ${scheduledOrderTime.startTime} - ${scheduledOrderTime.endTime}`;
  }

  const showFulfillmentTime = shouldShowEstimate || scheduledOrderTime;

  return (
    <StyledModalContainer
      ref={ref}
      height={User.hasExternalUser ? 216 : 'auto'}
      width={541}
      p='0'
      data-cy='guest-checkout-success'
      data-test='guest-checkout-success'
      data-testid='guest-checkout-success'
      {...containerProps}
    >
      <SuccessContainer>
        <OrderSubmitted size='medium' tag='h2'>
          {t('checkout.guest-order-submitted', 'Thanks, {{firstName}}! Your order has been submitted.', {
            firstName: guestCustomer.firstName,
          })}
        </OrderSubmitted>
        <OrderInstructions size='large' tag='p'>
          {t(
            'checkout.order-details-below',
            'The details of your order are below. If you have questions please let us know!'
          )}
        </OrderInstructions>

        {showOrderReadySMSNotice && <SMSUpdateBanner orderType={serviceType} mb='0px' mt='20px' />}

        {cart.order.paymentMethod === 'rethinkPay' ? (
          <Box mt={theme.spaces[7]}>
            <CreditAuthNotification />
          </Box>
        ) : null}

        <OrderDetailsWrapper>
          {showFulfillmentTime && (
            <OrderDetail>
              <DetailIcon>
                <CalendarIcon />
              </DetailIcon>
              <DetailContent>
                <DetailTitle size='xsmall' tag='h3'>
                  {shouldShowEstimate && estimateTypeText}
                  {scheduledOrderTime && formattedScheduledOrderType}
                </DetailTitle>
                <DetailText size='large' tag='p'>
                  {shouldShowEstimate && estimateTime}
                  {scheduledOrderTime && formattedScheduledOrderTime}
                </DetailText>
              </DetailContent>
            </OrderDetail>
          )}
          {!isDelivery && (
            <OrderDetail>
              <DetailIcon>
                <PinIcon />
              </DetailIcon>
              <DetailContent>
                <DetailTitle size='xsmall' tag='h3'>
                  Pickup Location
                </DetailTitle>
                <DetailText size='large' tag='p'>
                  {dispensary.name}
                </DetailText>
              </DetailContent>
            </OrderDetail>
          )}
          <OrderDetail>
            <DetailIcon>
              <ShoppingBagIcon />
            </DetailIcon>
            <DetailContent>
              <DetailTitle size='xsmall' tag='h3'>
                Order Number
              </DetailTitle>
              <DetailText size='large' tag='p'>
                {orderId}
              </DetailText>
            </DetailContent>
          </OrderDetail>
        </OrderDetailsWrapper>
      </SuccessContainer>
      <BannerContainer>
        <StyledModalExitIcon onClick={onClose} />
        <DutchiePayCheckoutSuccessCTA dispensary={dispensary} onClose={onClose} isGuestOrder />
      </BannerContainer>
    </StyledModalContainer>
  );
});

export default GuestCheckoutSuccessDutchiePay;
