import React from 'react';

import { DesktopOnly, MobileOnly } from 'shared/components';
import { withModalControlsHook, ModalComponentProps } from 'components/modals/with-modal-controls-hook';
import { AddToCartDataParam } from '../buying-options/hooks/use-add-buying-option-to-cart';

import { useShoppingWith } from './use-shopping-with';
import { ShoppingWithModal } from './shopping-with-modal';
import { ShoppingWithDrawer } from './shopping-with-drawer';

export const ShoppingWithWrapper = ({
  data: addToCartData,
  onClose,
  open,
}: ModalComponentProps<AddToCartDataParam>): JSX.Element => {
  const { dispensary } = addToCartData;

  const { handleContinue } = useShoppingWith({ addToCartData });

  return (
    <>
      <DesktopOnly>
        <ShoppingWithModal dispensary={dispensary} handleContinue={handleContinue} onClose={onClose} open={open} />
      </DesktopOnly>
      <MobileOnly>
        <ShoppingWithDrawer dispensary={dispensary} handleContinue={handleContinue} onClose={onClose} open={open} />
      </MobileOnly>
    </>
  );
};

export const [ShoppingWithController, useShoppingWithControls] = withModalControlsHook<AddToCartDataParam>(
  ShoppingWithWrapper
);
