import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["as"];
var __jsx = React.createElement;
import React from 'react';
import { fontSize, position, top, right, left, display, fontWeight, space, color } from 'styled-system';
import { Link } from 'rebass';
import styled, { css } from 'styled-components';
export default (function (props) {
  var as = props.as,
      otherProps = _objectWithoutProperties(props, _excluded);

  if (as === 'button') {
    return __jsx(StyledButton, otherProps);
  }

  return __jsx(StyledLink, otherProps);
});
var styles = css(["color:", ";cursor:pointer;", " ", " ", " ", " ", " ", " ", " ", " ", ""], function (_ref) {
  var _ref2, _theme$customized$col, _theme$customized, _theme$customized$col2, _theme$colors;

  var theme = _ref.theme;
  return (_ref2 = (_theme$customized$col = theme === null || theme === void 0 ? void 0 : (_theme$customized = theme.customized) === null || _theme$customized === void 0 ? void 0 : (_theme$customized$col2 = _theme$customized.colors) === null || _theme$customized$col2 === void 0 ? void 0 : _theme$customized$col2.buttonsLinks) !== null && _theme$customized$col !== void 0 ? _theme$customized$col : theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.linkColor) !== null && _ref2 !== void 0 ? _ref2 : '#4597e0';
}, fontSize, fontWeight, position, top, right, left, display, space, color);
var StyledLink = styled(Link).withConfig({
  displayName: "link__StyledLink",
  componentId: "hbyqoc-0"
})(["", ""], styles);
var StyledButton = styled.button.withConfig({
  displayName: "link__StyledButton",
  componentId: "hbyqoc-1"
})(["appearance:none;border:none;padding:0;background-color:transparent;", ""], styles);