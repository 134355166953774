import { useState, useEffect, useMemo } from 'react';
import { observable } from 'mobx';
import { useDebouncedCallback } from 'use-debounce/lib';

import {
  UseBrandsSearchReturn,
  UseBrandsSelectionParams,
  UseBrandsSelectionReturn,
  UseFilteredOptionsParams,
  UseFilteredOptionsReturn,
  Option,
} from './brands-filter-drawer.types';

export function useBrandsSearch(): UseBrandsSearchReturn {
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const handleChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
    debouncedUpdate(e.currentTarget.value);
  };

  const [debouncedUpdate] = useDebouncedCallback((value: string) => setDebouncedValue(value), 300);

  const handleClearInputValue = (): void => {
    setInputValue('');
    setDebouncedValue('');
  };

  return {
    inputValue,
    debouncedValue,
    handleChangeInputValue,
    handleClearInputValue,
  };
}

export function useBrandsSelection({ brands }: UseBrandsSelectionParams): UseBrandsSelectionReturn {
  const [selectedBrands] = useState(observable(new Set<string>()));

  useEffect(() => {
    brands.forEach((id) => selectedBrands.add(id));
  }, [brands, selectedBrands]);

  const handleIsSelected = ({ key }: Option): boolean => selectedBrands.has(key);

  const handleToggle = ({ key }: Option): void => {
    if (!selectedBrands.delete(key)) {
      selectedBrands.add(key);
    }
  };

  const handleClear = (): void => {
    selectedBrands.clear();
  };

  return {
    handleToggle,
    handleClear,
    handleIsSelected,
    selectedBrands,
  };
}

export function useFilteredOptions({
  debouncedValue,
  options = [],
}: UseFilteredOptionsParams): UseFilteredOptionsReturn {
  return useMemo(
    () => options.filter(({ name }) => name.toLocaleLowerCase().startsWith(debouncedValue.toLocaleLowerCase())),
    [debouncedValue, options]
  );
}
