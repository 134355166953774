import styled from 'styled-components';
import { space } from 'styled-system';

import { focusStyles } from 'src/theme';

import { ContentWrapper } from 'components/core';
import CarouselSlider from 'components/carousel-slider';

export const Heading = styled.h2`
  font-size: ${({ isMini }) => (isMini ? '16px' : '26px')};
  font-weight: bold;
  line-height: 120%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ isMini }) =>
      !isMini &&
      `
        font-size: 20px;
        line-height: 29px;
      `}
  }
`;

export const GroupHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled(ContentWrapper)`
  ${space};
  margin: 0 auto;
  max-width: 534.5px;
  padding: 40px 0 15px;
  position: relative;
  z-index: 10; /* ensure that our swipeable drawer doesn't snip this off */

  border-bottom: ${({ theme }) => `1px solid ${String(theme.colors.blueGrey[95])}`};
  border-top: ${({ theme }) => `1px solid ${String(theme.colors.blueGrey[95])}`};

  ${({ isMini, theme }) =>
    isMini &&
    `
    background-color: ${String(theme.colors.white)};
    margin: 24px 0;
    padding: 24px 24px 0;
  `}

  @media (min-width: 750.5px) {
    max-width: 750.5px;
  }

  @media (min-width: 966.5px) {
    max-width: 966.5px;
  }

  @media (min-width: 1187px) {
    max-width: 1187px;
  }
`;

export const Slider = styled(CarouselSlider)`
  ${({ isMini }) =>
    isMini &&
    `
    margin-top: 0;
  `}
`;

export const Item = styled.div`
  padding: 7px 5.5px 5px;

  &:last-of-type {
    padding-right: 14px;
  }

  /* shut this down, its the wrong the container */
  .focus-visible {
    outline: none;
    box-shadow: none !important;
    > div {
      ${focusStyles}
    }
  }
`;
