import React, { forwardRef } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import useSearchPreferences from 'hooks/use-search-preferences';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useOrderType from 'hooks/use-order-type';

import ROUTES from 'src/routes';
import { ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY } from 'shared/constants';
import OutsideDeliveryAreaModal from './outside-of-delivery-area';

const OutsideDeliveryArea = forwardRef(({ onClose, ...props }, ref) => {
  const router = useRouter();
  const SearchPreferences = useSearchPreferences();
  const { dispensary } = useDispensary();
  const { setOrderType } = useOrderType();
  const { setOrderType: setOrderTypePreference } = SearchPreferences;
  const dispensaryName = useObserver(() => dispensary?.name);

  const handleClose = () => {
    setOrderType(ORDER_TYPE_PICKUP);
    onClose(false);
  };

  const handleDispensaryListRedirect = async () => {
    await router.replace(ROUTES.DISPENSARIES);

    setOrderTypePreference(ORDER_TYPE_DELIVERY);
    onClose();
  };

  return (
    <OutsideDeliveryAreaModal
      ref={ref}
      dispensaryName={dispensaryName}
      onClose={handleClose}
      onDispensaryListRedirect={handleDispensaryListRedirect}
      {...props}
    />
  );
});

export default OutsideDeliveryArea;
