import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { Option, TextInput } from 'src/components/core';
import { QuantitySelect } from 'src/components/quantity-select';

type QuantityProps = {
  onQuantityChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  quantity: number;
  totalQuantityAvailable: number;
  setHasQuantityError: (value: boolean) => void;
};

export function Quantity({
  onQuantityChange: changeQuantity,
  quantity,
  totalQuantityAvailable,
  setHasQuantityError,
}: QuantityProps): JSX.Element {
  const maxQuantityDropdownOptions = 10;
  const quantityDropdownOptions = _.range(1, _.clamp(totalQuantityAvailable, 1, maxQuantityDropdownOptions) + 1);
  const enableMaxQuantityOption = totalQuantityAvailable > maxQuantityDropdownOptions;

  const [enableNumberInput, setEnableNumberInput] = useState(
    enableMaxQuantityOption && quantity > maxQuantityDropdownOptions
  );
  const [isInManualEditMode, setIsInManualEditMode] = useState(false);
  const [tempQuantity, setTempQuantity] = useState(quantity);

  const handleManualInputChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { target } = event;
    const parsedQuantity = Number(target.value);
    const isQuantityOverMax = parsedQuantity > totalQuantityAvailable;

    // This will reset the quantity number to the max if they try and go over
    const newQuantity = isQuantityOverMax ? totalQuantityAvailable : parsedQuantity;

    setTempQuantity(newQuantity);

    if (parsedQuantity > 0) {
      setHasQuantityError(isQuantityOverMax);

      changeQuantity(
        _.merge(event, {
          target: { value: newQuantity },
        })
      );
    }
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const newQuantity = Number(event.target.value);

    if (newQuantity > 0) {
      changeQuantity(event);
    } else {
      setTempQuantity(0);
    }
  };

  const handleMaxClick = (): void => {
    setEnableNumberInput(true);
    setIsInManualEditMode(true);
  };

  return (
    <>
      {enableNumberInput ? (
        <StyledNumberInput
          type='number'
          inputProps={{ min: 1, max: totalQuantityAvailable, 'data-testid': 'max-quantity-input' }}
          placeholder='1'
          onChange={handleManualInputChange}
          value={tempQuantity > 0 && tempQuantity}
          fullWidth={false}
          autoFocus={isInManualEditMode}
        />
      ) : (
        <QuantitySelect
          onQuantityChange={handleQuantityChange}
          quantity={quantity}
          quantitiesAvailableForSelection={quantityDropdownOptions}
        >
          {enableMaxQuantityOption && (
            <Option onClick={handleMaxClick} value={0} data-testid='quantity-select-option-max'>
              {maxQuantityDropdownOptions}+
            </Option>
          )}
        </QuantitySelect>
      )}
    </>
  );
}

const StyledNumberInput = styled(TextInput)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 21px !important;
  border: 1px solid ${({ theme }) => theme.colors.grey[70]} !important;
  height: 32px;
  width: 55px;

  input {
    /* Inherited styles hide the text overflow */
    text-overflow: unset;

    // disable the arrows on the number input
    appearance: textfield;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }
`;
