import styled, { css } from 'styled-components';

export const DutchiePayTextBody = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[35]};
  line-height: 150%;
`;

export const DutchiePayTextFooter = styled.p`
  padding: 25px 0 38px 0;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[35]};
  line-height: 150%;
`;

export const LinkText = styled.a`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  margin-left: 3px;
`;

export const EmailDisplay = styled.div.attrs({
  'data-private': true,
})`
  width: 100%;
  height: 54px;
  background-color: #f2f6f8;
  margin-bottom: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 13px;
`;

export const TruncateText = styled.span`
  ${({ maxWidth }) => css`
    display: block;
    max-width: ${maxWidth}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;
