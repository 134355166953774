import React from 'react';
import { DividerWithText } from 'shared/components';
import styled from 'styled-components';
import { ExpressCheckout } from 'src/payments/components';
import { CheckoutSection } from 'checkout/components/styles/common';
import useTranslation from 'hooks/use-translation';

export function DutchiePaySection(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <CheckoutSection noBorder>
        <ExpressCheckout />
      </CheckoutSection>
      <ExpressCheckoutDivider>{t('common.or', 'or')}</ExpressCheckoutDivider>
    </>
  );
}

const ExpressCheckoutDivider = styled(DividerWithText)`
  margin-top: -7px;
  margin-bottom: -8px;
  text-transform: uppercase;
`;
