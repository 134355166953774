/* eslint-disable @typescript-eslint/naming-convention, */
import { fonts } from 'shared/themes.js';
import PublicEnv from 'shared/utils/public-env';
import { mediaQueries } from 'shared/styles';
import _ from 'lodash';

export const isProduction = PublicEnv.appEnv === 'production';

export const baseMonerisIframeUrl = isProduction
  ? 'https://www3.moneris.com/HPPtoken/index.php'
  : 'https://esqa.moneris.com/HPPtoken/index.php';

export const isBrowser = (): boolean => typeof window !== 'undefined' && !window.isTestEnv;
export const mobile = isBrowser() && window.matchMedia(mediaQueries.tablet).matches;
export const monerisStyles = {
  enable_exp: '0',
  enable_cvd: '0',
  display_labels: '2',
  css_body: {
    'font-family': _.join(fonts, ','),
    color: 'rgb(93, 102, 109)',
    'font-weight': 400,
    'font-size': '16px',
    '-webkit-font-smoothing': 'antialiased',
  },
  css_textbox: {
    border: '1px solid #caced4',
    'border-radius': '4px',
    height: '45px',
    'font-size': mobile ? '16px' : '13px',
    padding: '18px',
    'font-weight': 400,
    'background-color': '#fcfdfe',
    color: 'rgb(93, 102, 109)',
  },
  css_textbox_pan: {
    width: '100%',
  },
};

export const flattenStyles = (styles: { [x in string]: number | string }): string =>
  _.map(styles, (value, key) => `${key}:${value}`).join(';');

export const styleParams = _.map(monerisStyles, (value, key) => {
  const styles = _.isObject(value) ? flattenStyles(value) : value;
  return `${encodeURIComponent(key)}=${encodeURIComponent(styles)}`;
}).join('&');

export const monerisSuccessCode = '001';
export const messagesForMonerisErrorCodes: { [x in number]: string } = {
  888: 'postal code',
  940: 'Invalid profile id (on tokenization request)',
  941: 'Error generating token',
  942: 'Invalid Profile ID, or source URL',
  943: 'card number',
  944: 'expiration date',
  945: 'CVV',
};

export function errorMessage(codeSet: number[]): string {
  // convert set to array
  const codes = Array.from(codeSet);

  if (!codes.length) {
    return '';
  }

  const incorrectMonerisProfileIDError = _.includes(codes, 942);

  if (incorrectMonerisProfileIDError) {
    return `An invalid Profile ID has been used.  Please update settings and try again.`;
  }

  const messages = _.map(codes, (code) => messagesForMonerisErrorCodes[code]);

  if (messages.length === 1) {
    return `Your ${messages[0]} is invalid. Please update and try again.`;
  }
  if (messages.length === 2) {
    return `Your ${messages[0]} and ${messages[1]} are invalid. Please update and try again.`;
  }
  // messages.length is greater than or equal to 3
  const lastWord = messages[messages.length - 1];
  const allTheRest = messages.slice(0, -1);
  return `Your ${_.join(allTheRest, ', ')}, and ${lastWord} are invalid. Please update and try again.`;
}

export const validatePostalCode = (postalCode: string): boolean => !!(postalCode && postalCode.length > 4);

export const validateExpiry = (expiryDate: string): boolean => {
  if (!expiryDate) {
    return false;
  }
  const today = new Date().getTime();
  const parsedInputDate: string[] = _.split(expiryDate, '/');
  if (parsedInputDate.length < 2) {
    return false;
  }
  const inputDate = new Date(Number(`20${parsedInputDate[1]}`), Number(parsedInputDate[0]) - 1).getTime();
  return inputDate > today;
};

export const validateCvv = (cvv): boolean => cvv && cvv.length > 2;
