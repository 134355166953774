import _ from 'lodash';
import Pusher from 'pusher-js';

export default class PusherState {
  constructor(pusherKey) {
    this._pusherKey = pusherKey;
    this._socket = null;
  }

  _getSocketLazy = () => {
    if (this._socket) {
      return this._socket;
    }

    return (this._socket = new Pusher(this._pusherKey, {
      cluster: `us3`,
      forceTLS: true,
    }));
  };

  subscribe = (channelName) => {
    const socket = this._getSocketLazy();
    const channel = socket.subscribe(channelName);

    return channel;
  };

  unsubscribe = (channelName) => {
    if (_.isEmpty(this._socket)) {
      throw new Error(`PusherState: The Pusher socket has not been initialized, did you forget to call subscribe??`);
    }

    if (_.isEmpty(this._socket.channels.channels[channelName])) {
      throw new Error(`PusherState: The channel '${channelName}' doesn't exist, did you forget to call subscribe??`);
    }

    this._socket.unsubscribe(channelName);
  };
}
