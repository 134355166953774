/* eslint-disable @typescript-eslint/naming-convention */
import { getPersistedValue } from 'shared/utils/persisted-values';

import { isBrowser } from 'utils/misc-utils';
import { Session } from 'utils/session';

type Options = Partial<{
  resetSessionOnSuccess: boolean;
}>;

type Data = {
  type: string;
  payload: Record<string, unknown>;
};

export type TelemetryClient = {
  post(data: Data, options?: Options): Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = (): Promise<void> => Promise.resolve();

async function post(data: Data, options: Options = {}): Promise<void> {
  const session = Session.getInstance();
  const accessToken = getPersistedValue('access-token');

  const headers = {
    'Content-Type': 'application/json',
    Authorization: accessToken,
    ...session.header,
  };

  const body = JSON.stringify(data);

  try {
    const resp = await fetch('/telemetry', {
      method: 'POST',
      headers,
      body,
    });

    if (resp.ok && options.resetSessionOnSuccess) {
      session.reset();
    }
  } catch (err) {
    /* nothing to do */
  }
}

export const createTelemetryClient = (): TelemetryClient => ({
  post: isBrowser() ? post : noop,
});
