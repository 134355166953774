import useTranslation from 'hooks/use-translation';

export type Column = {
  label: string | null;
  key: string;
};

type UseTableColumnsReturn = {
  columnNames: Column[];
};

export function useTableColumns(): UseTableColumnsReturn {
  const { t } = useTranslation();

  const columnNames = [
    {
      label: t('productTable-column.dispensary', 'Dispensary'),
      key: 'dispensary',
    },
    {
      label: t('productTable-column.productInfo', 'Product info'),
      key: 'product-info',
    },
    {
      label: t('productTable-column.price', 'Price'),
      key: 'price',
    },
    {
      label: t('productTable-column.quantity', 'Quantity'),
      key: 'quantity',
    },
    {
      label: null,
      key: 'add-to-cart',
    },
  ];

  return { columnNames };
}
