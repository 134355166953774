import React, { forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { space, layout, position } from 'styled-system';
import Carousel from 'react-multi-carousel';
import useViewport from 'hooks/use-viewport';

import { mediaSizes } from 'theme';
import ChevronIcon from 'assets/chevron-icon';

const gradients = {
  left: css`linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0) 20%)`,
  right: css`linear-gradient(90deg, rgba(255,255,255,0) 80%, rgba(255,255,255,0.9) 100%)`,
  both: css`linear-gradient(90deg,
    rgba(255,255,255,0.9) 0%,
    rgba(255,255,255,0) 20%,
    rgba(255,255,255,0) 80%,
    rgba(255,255,255,0.9) 100%)}`,
};

const BasicCarousel = forwardRef((props, ref) => {
  const {
    variant = `default`,
    responsive: responsiveProps,
    alwaysShowButtons = false,
    useCustomButtonGroup = false,
    slidesToSlide,
    skipBeforeChange = false,
  } = props;
  const [displayNavigationArrows, setDisplayNavigationArrows] = useState(false);
  const [gradient, setGradient] = useState(gradients.right);
  const [slideXOffset, setSlideXOffset] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const { width } = useViewport();

  /** cardWidth must include the space between cards for sliding to work
   *  as expected. If cards are a different width than defined here (e.g.
   *  the categories list, pass the card width as a prop).
   */
  const cardWidth = props.cardWidth || (width < 600 ? 158 : 200);
  const responsive = {
    desktop: {
      breakpoint: {
        max: 4000,
        min: 860,
      },
      items: 4,
    },
    tablet: {
      breakpoint: {
        max: 860,
        min: 666,
      },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 666, min: 0 },
      items: 1,
    },
  };

  const CustomRightArrow = ({ carouselState, isVisible, onClick }) => {
    const { containerWidth, currentSlide, totalItems } = carouselState;
    const slidesToShow = Math.floor(containerWidth / cardWidth);
    const amountToSlide = slidesToSlide || slidesToShow;
    const currentVisibleSlides = (currentSlide + 1) * amountToSlide;
    const moreSlidesToShow = currentVisibleSlides < totalItems;

    return (
      <ButtonContainer
        className='--right'
        isVisible={moreSlidesToShow && isVisible}
        onClick={() => onClick()}
        variant={variant}
      >
        <ForwardArrow onClick={() => onClick()} width='100%' height='100%' variant={variant} />
      </ButtonContainer>
    );
  };

  const CustomLeftArrow = ({ isVisible, onClick }) => (
    <ButtonContainer className='--left' isVisible={isVisible} onClick={() => onClick()} variant={variant}>
      <BackArrow onClick={() => onClick()} width='100%' height='100%' variant={variant} />
    </ButtonContainer>
  );

  const CustomButtonGroup = ({ carouselState, next, previous }) => {
    const { currentSlide } = carouselState;

    return (
      <div className='carousel-button-group'>
        <CustomLeftArrow
          className={currentSlide === 0 ? 'disable' : ''}
          isVisible={alwaysShowButtons || displayNavigationArrows}
          onClick={() => previous()}
          variant={variant}
        />
        <CustomRightArrow
          carouselState={carouselState}
          isVisible={alwaysShowButtons || displayNavigationArrows}
          variant={variant}
          onClick={() => next()}
        />
      </div>
    );
  };

  const handleBeforeChange = (nextSlide, { totalItems, containerWidth, deviceType }) => {
    if (!['mobile', 'tablet'].includes(deviceType)) {
      // NOTE: don't you trust this slidesToShow prop in carousel state!! it pulls from our responsive object
      // definition that is now ~~UNRELIABLE~~ go calculate this yourself manually anywhere you need to use it!
      // ALSO, don't trust that itemWidth! It will default to the ENTIRE CONTAINER WIDTH NOW
      const slidesToShow = containerWidth / cardWidth;
      if (nextSlide === 0) {
        setGradient(gradients.right);
        setSlideXOffset(0);
      }
      if (nextSlide !== 0) {
        // progress carousel by the entire ROW size
        // if you're at the end of the carousel, don't show any extra whitespace
        const itemsRemaining = totalItems - slidesToShow * nextSlide;
        const rowWidthToShift = itemsRemaining < slidesToShow ? cardWidth * itemsRemaining : containerWidth;
        const previousWidth = containerWidth * (nextSlide - 1);
        const marginFudge = itemsRemaining < slidesToShow ? -40 : 40 * nextSlide;
        const widthToShift = (previousWidth + rowWidthToShift) * -1 + marginFudge;
        setGradient(itemsRemaining < slidesToShow ? gradients.left : gradients.both);
        setSlideXOffset(widthToShift);
      }
    }
    setCurrentSlideIndex(nextSlide);
  };

  return (
    <GradientContainer
      gradient={gradient}
      onMouseOver={() => setDisplayNavigationArrows(true)}
      onMouseLeave={() => setDisplayNavigationArrows(false)}
      onFocus={() => setDisplayNavigationArrows(true)}
      onBlur={() => setDisplayNavigationArrows(false)}
      {...props}
    >
      <StyledCarousel
        ref={ref}
        beforeChange={(nextSlide, carouselInfo) =>
          !skipBeforeChange ? handleBeforeChange(nextSlide, carouselInfo) : false
        }
        customButtonGroup={useCustomButtonGroup && <CustomButtonGroup />}
        customLeftArrow={<CustomLeftArrow isVisible={alwaysShowButtons || displayNavigationArrows} variant={variant} />}
        currentSlideIndex={currentSlideIndex}
        customRightArrow={
          <CustomRightArrow isVisible={alwaysShowButtons || displayNavigationArrows} variant={variant} />
        }
        draggable
        infinite={false}
        responsive={responsiveProps || responsive}
        skipBeforeChange={skipBeforeChange}
        slideXOffset={slideXOffset}
        swipeable
        {...props}
      />
    </GradientContainer>
  );
});

export default BasicCarousel;

const CircleArrowStyles = css`
  height: 12px;
  width: 19px;
  fill: ${({ theme }) => theme.colors.grey[35]};
`;

const ForwardArrow = styled(ChevronIcon)`
  transform: rotate(-90deg);
  ${(props) => (props.variant !== 'default' ? CircleArrowStyles : '')};
  margin-left: 3px;
`;

const BackArrow = styled(ChevronIcon)`
  transform: rotate(90deg);
  ${(props) => (props.variant !== 'default' ? CircleArrowStyles : '')};
  margin-left: -3px;
`;

const StyledCarousel = styled(Carousel)`
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  height: auto;
  /* extend this past our 25px side page margins */
  width: calc(100% + 50px);
  img,
  svg {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  ${layout}
  ${space}
  .react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: flex-end;
    padding: 0;
    margin: 0 0 5px 0;
    list-style: none;
    text-align: center;
    height: 100%;
  }
  .react-multi-carousel-dot--active button {
    background: ${({ theme }) => theme.customized.colors.buttonsLinks};
    &::before {
      content: '';
      background: rgba(11, 153, 230, 0.2);
      height: 22px;
      width: 22px;
      display: block;
      border-radius: 50%;
      position: absolute;
    }
  }
  .react-multi-carousel-dot button {
    border: none;
    background: ${({ theme }) => theme.customized.colors.buttonsLinks} !important;
    height: 11px;
    width: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 22px;
  }
  ul.react-multi-carousel-track {
    transform: ${({ skipBeforeChange, slideXOffset }) =>
      !skipBeforeChange && `translate3d(${slideXOffset}px, 0px, 0px)`} !important;
  }

  li.react-multi-carousel-item {
    @media (max-width: ${mediaSizes.phone}px) {
      width: unset !important;
    }
  }

  .--right {
    right: -20px;
    top: 34%;
  }
  .--left {
    left: 20px;
    top: 34%;
  }

  @media (min-width: 1200px) {
    .--right {
      right: -46px;
    }
    .--left {
      left: -10px;
    }
  }

  /* stop bleeding to the sides at this point */
  @media (min-width: 1220px) {
    width: calc(100% + 40px);
    margin-left: 0;
  }
`;

const CircleContainer = css`
  background: ${({ theme }) => theme.colors.blueGrey[80]};
  border-radius: 50%;
  height: 54px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.colors.grey[70]};
  }
`;

const ButtonContainer = styled.div`
  display: none !important;
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  width: ${(props) => (props.variant !== 'default' ? `54px` : `18px`)};
  height: ${(props) => (props.variant !== 'default' ? `54px` : `18px`)};
  opacity: 1;
  cursor: pointer;
  visibility: ${(props) => (props.isVisible ? `visible` : `hidden`)};
  height: 12px;
  ${(props) => (props.variant !== 'default' ? CircleContainer : '')};

  @media (min-width: 960px) {
     {
      ${({ isVisible }) => (isVisible ? 'display: flex !important;' : '')}
    }
  }
`;

const GradientContainer = styled.div`
  top: 0;
  left: 0;
  height: auto;
  ${position}

  /* white haze effect */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -50px;
    left: -25px;
    width: 100vw;
    height: 100%;
    pointer-events: none;
  }

  @media (min-width: 1220px) {
    &::after {
      width: calc(100% + 50px);
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    &::after {
      background: ${({ displayGradient, gradient }) => (displayGradient ? gradient : `none`)};
    }
  }
`;

GradientContainer.defaultProps = {
  position: `absolute`,
  displayGradient: true,
};
