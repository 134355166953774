/* eslint-disable max-len */
import React from 'react';

function BagIcon(props) {
  return (
    <svg width='43' height='41' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='.3'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.605 4.75h8.505c.283 0 .513.256.513.573v1.793h-9.532V5.323c0-.317.23-.573.514-.573zm1.076 2.367h6.354v3.76c0 .537-.375.972-.84.972h-4.674c-.464 0-.84-.436-.84-.973v-3.76zM34.068 4.75h-7.796c-.26 0-.471.257-.471.574v1.793h8.738V5.324c0-.317-.21-.574-.471-.574zM14.68 21.314h6.354v3.936c0 .44-.375.796-.84.796h-4.674c-.464 0-.84-.356-.84-.796v-3.936zm7.43-3.155h-8.505c-.284 0-.514.257-.514.574v1.792h9.531v-1.792c0-.317-.23-.574-.513-.574zm4.484 3.155h6.355v3.936c0 .44-.376.796-.84.796h-4.675c-.464 0-.84-.356-.84-.796v-3.936zm7.473-3.155h-7.796c-.26 0-.471.257-.471.574v1.792h8.738v-1.792c0-.317-.21-.574-.471-.574z'
        fill='#BDCCCE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.94 6.87v.102l.228.539c1.307 10.734 3.3 30.742 3.28 31.714l-.004.23H.383v-.236C2.03 24.07 5.642 2.872 10.889.828l.011.57.83-.592h24.494c2.136 0 3.875.643 3.875 2.593-.047 1.75-1.38 3.214-3.16 3.471zM10.41 1.746c-.097-.002-.218-.002-.16.002l.134.017.026-.02z'
        fill='#fff'
      />
      <path d='M10.71.412h.793c.026-.004-.821 0-.793 0z' fill='#E4EDF0' />
      <path
        d='M8.971 4.75c.77 1.637 2.11-1.027 2.6.977 2.035 8.31 3.904 28.84 3.904 33.727-2.188-.04-5.755-.082-7.943-.124 0 0 .72-32.842 1.44-34.58z'
        fill='#EAEFF2'
      />
      <path d='M11.504 8.807v-1.69h25.418l.847 2.366-26.265-.676z' fill='#BCCAD2' />
      <path
        d='M15.555 39.455a.717.717 0 01-.71-.65c-.028-.285-2.84-28.576-4.095-32.304a.728.728 0 01.134-.714.709.709 0 011.217.249c1.307 3.885 4.049 31.455 4.165 32.626a.722.722 0 01-.711.793z'
        fill='#405D67'
      />
      <ellipse cx='10.312' cy='4.066' rx='3.177' ry='3.581' fill='#DBE3E8' />
      <path
        d='M40.873 40.24a.588.588 0 01-.39-.111H2.106c-.478 0-.935-.196-1.264-.543a1.678 1.678 0 01-.454-1.28C.831 32.49 3.356 3.304 9.563.017l.7 1.28C9.007 1.96 6.56 5.082 4.26 18.519c-1.513 8.837-2.263 18.124-2.398 19.896a.245.245 0 00.246.26h38.019l-.01-.096C38.91 26.094 37.78 15.32 36.76 6.55l1.467-.165c1.021 8.779 2.151 19.567 3.36 32.058l.023.233h.008l.059.663c0 .022.003.044.004.066v.018a.714.714 0 01-.157.545.738.738 0 01-.508.266 1.306 1.306 0 01-.143.009v-.001z'
        fill='#405D67'
      />
      <path
        d='M36.987 6.327H12.233c-.402 0-.73-.354-.73-.789 0-.435.327-.788.729-.788h24.755c.403 0 .728.353.728.788 0 .436-.325.79-.728.79z'
        fill='#405D67'
      />
      <path
        d='M9.868.37c1.972-.043 3.61 1.387 3.66 3.197 0 1.764-1.14 3.196-3.163 3.196h27.46c1.286.056 2.501-.54 3.16-1.553a2.974 2.974 0 000-3.288C40.327.91 39.112.313 37.826.37h-27.46'
        fill='#EAEFF2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.364 7.783h27.462c1.553.058 3.015-.669 3.811-1.894a3.61 3.61 0 000-3.976C40.842.69 39.38-.036 37.826.022H10.364a.837.837 0 00-.143.012A5.074 5.074 0 009.866.02c-.412 0-.747.306-.747.684 0 .379.335.685.747.685 1.56-.04 2.862 1.082 2.914 2.512 0 1.158-.633 2.511-2.416 2.511-.412 0-.747.307-.747.686 0 .377.335.684.747.684zm27.462-1.37H13.44a3.939 3.939 0 00.833-2.512 3.609 3.609 0 00-1.05-2.512h24.602c1.02-.06 1.99.407 2.52 1.208.529.788.529 1.82 0 2.608-.53.801-1.5 1.266-2.52 1.207z'
        fill='#405D67'
      />
      <path d='M10.392 7.166a1.032 1.032 0 01-.874-.414.976.976 0 01-.122-.941l3.227-.863-2.23 2.218z' fill='#405D67' />
      <path
        d='M10.392 7.905a1.972 1.972 0 01-1.442-.646 1.787 1.787 0 01-.294-1.611.748.748 0 01.54-.553l3.896-1.133c.332-.09.013.32.187.609a.726.726 0 01-.115.893l-1.66 2.44c-.143.143-.909 0-1.112 0zM7.633 39.455c-.492 0-.892-.314-.892-.702-.003-.785-.067-19.28.599-24.693.031-.249.229-.467.519-.57a1.1 1.1 0 01.889.067c.259.146.4.39.369.64-.657 5.34-.591 24.36-.591 24.551 0 .388-.4.702-.893.702v.005z'
        fill='#405D67'
      />
    </svg>
  );
}

export default BagIcon;
