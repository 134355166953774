import React from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';

import { MyAccountOptionsList } from './my-account-options-list';

export type MyAccountPopoverProps = {
  popoverProps: {
    anchorOrigin: {
      vertical: string;
      horizontal: string;
    };
    transformOrigin: {
      vertical: string;
      horizontal: string;
    };
    open: HTMLButtonElement | null;
  };
  anchorEl: HTMLButtonElement | null;
  isLoggedIn: boolean;
  handleOnLogout: () => void;
  handleOptionClick: (value: string) => void;
  userDisplayName: string;
};

export function MyAccountPopover({
  popoverProps,
  isLoggedIn,
  anchorEl,
  handleOnLogout,
  handleOptionClick,
  userDisplayName,
}: MyAccountPopoverProps): JSX.Element {
  return (
    <Container {...popoverProps} anchorEl={anchorEl}>
      <MyAccountOptionsList
        isLoggedIn={isLoggedIn}
        onLogout={handleOnLogout}
        onOptionClick={handleOptionClick}
        userDisplayName={userDisplayName}
      />
    </Container>
  );
}

const Container = styled(Popover)`
  .MuiPopover-paper {
    margin-top: 8px;
  }

  > div[class*='MuiPaper-rounded'] {
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px 0px;
    border-radius: 8px !important;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .MuiPopover-paper {
      margin-top: 49px;
    }
  }

  &.MuiPopover-root {
    background: transparent;
  }
`;
