import { GqlGetRewardsV2Query, useGetRewardsV2Query } from 'types/graphql';
import { formatBirthdayForRewards } from 'shared/helpers/rewards';

type UseGetRewardsProps = {
  dispensaryId: string;
  birthday: string;
  pin: string;
  phone: string;
  skip?: boolean;
  customerId?: string;
};

type UseGetRewardsReturn = {
  data: GqlGetRewardsV2Query | undefined;
  loading: boolean;
};

export const useGetRewards = ({
  dispensaryId,
  birthday,
  pin = '',
  phone,
  skip = false,
  customerId,
}: UseGetRewardsProps): UseGetRewardsReturn => {
  const dob = formatBirthdayForRewards(birthday);

  const { data, loading } = useGetRewardsV2Query({
    variables: {
      phoneNumber: phone,
      dispensaryId,
      dob,
      pin,
      customerId,
    },
    fetchPolicy: 'no-cache',
    skip,
  });

  return {
    data,
    loading,
  };
};
