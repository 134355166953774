import React from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';

import useClickOut from 'shared/hooks/use-click-out';
import useTranslation from 'hooks/use-translation';
import useUser from 'hooks/use-user';

import { DesktopOnly, MobileOnly, Clickable } from 'shared/components';
import { Button } from 'components/core';
import AccountIcon from 'assets/account-icon-rebrand';
import MyAccountPopover from './my-account-popover';
import MyAccountDrawer from './my-account-drawer';

export function MyAccountLink({ displayAccountIcon = true, displayMobileTitle = false, isOnLight = false }) {
  const { t } = useTranslation();
  const user = useUser();
  const isLoggedIn = useObserver(() => user?.isLoggedIn);

  const [ref, showDropdown, setShowDropdown] = useClickOut();
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <Account ref={ref} onClick={toggleDropdown} isOnLight={isOnLight}>
      {displayAccountIcon && <AccountIcon />}
      <DesktopOnly data-testid='my-account-link'>{t('common.myAccount', 'My Account')}</DesktopOnly>
      {displayMobileTitle && (
        <MobileOnly data-testid='my-account-link' className='my-account-link'>
          <ButtonWrapper>{t('common.myAccount', 'My account')}</ButtonWrapper>
        </MobileOnly>
      )}
      {showDropdown && (
        <>
          <DesktopOnly>
            <MyAccountPopover isLoggedIn={isLoggedIn} toggleDropdown={toggleDropdown} anchorEL={ref.current} />
          </DesktopOnly>

          <MobileOnly display='block'>{!isLoggedIn && <MyAccountPopover anchorEL={ref.current} />}</MobileOnly>
        </>
      )}

      <MobileOnly>
        <MyAccountDrawer open={showDropdown && isLoggedIn} />
      </MobileOnly>
    </Account>
  );
}

const Account = styled(Clickable)`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 100%;
  margin-right: 26px;

  &&& {
    color: ${({ theme, isOnLight }) => (isOnLight ? theme.colors.black : theme.colors.green[95])};
  }

  svg {
    margin-right: 11px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-right: 5px;
  }
`;

const ButtonWrapper = styled(Button)`
  padding: 5px 15px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  box-sizing: border-box;
  border-radius: 36px;
  font-size: 14px !important;
  line-height: 175%;
  min-width: 106px;
  text-transform: none;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black} !important;
  background: transparent;
`;
