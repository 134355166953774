import React, { useCallback, useEffect, useRef, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import Script from 'next/script';

import PublicEnv from 'shared/utils/public-env';
import useUI from 'src/hooks/use-ui';
import { tracker } from 'src/utils/analytics';
import { useConsentManagement } from './hooks/use-consent-management';
import { usePrivacyModalSetup } from './hooks/use-privacy-modal-setup';
import { FidesStyles } from './fides.styles';
import { getFides } from './helpers';
import type { Fides, FidesSDK } from './types';

const isProd = PublicEnv.appEnv === 'production';
const privacyCenterUrl = isProd ? 'https://privacy.dutchie.com' : 'https://privacy.dutchie.dev';
const FidesJSUrl = `${privacyCenterUrl}/fides.js`;

export function FidesController(): FidesSDK {
  const [isFidesLoaded, setIsFidesLoaded] = useState(false);
  const [isModalReadyForSetup, setIsModalReadyForSetup] = useState(false);
  const [currentPreferences, setCurrentPreferences] = useState<Fides['consent']>({});

  const { isConsentManagementEnabled } = useConsentManagement();
  const { variant: menuVariant } = useUI();

  const consentRef = useRef<Fides['consent']>(currentPreferences);
  usePrivacyModalSetup(isModalReadyForSetup);

  const onFidesLoaded = useCallback(() => {
    if (!isFidesLoaded) {
      setIsFidesLoaded(true);
    }
  }, [isFidesLoaded]);

  const onFidesInitialized = useCallback(() => {
    const fides = getFides();

    if (!fides) {
      return;
    }

    // Prepare for modal setup
    const hasModalExperience = fides.experience?.experience_config?.component === 'modal';
    if (!isModalReadyForSetup && hasModalExperience) {
      setIsModalReadyForSetup(true);
    }

    // Store initial consent preferences in local state
    setCurrentPreferences(fides.consent);
  }, [isModalReadyForSetup]);

  const onFidesUpdated = useCallback(() => {
    const fides = getFides();

    if (!fides) {
      return;
    }

    trackConsentUpdate('data_sales_and_sharing', fides, consentRef.current, menuVariant);
    setCurrentPreferences(fides.consent);
  }, [menuVariant]);

  const onFidesError = useCallback(() => {
    datadogLogs.logger.error('Fides: SDK Failed to load');
  }, []);

  // Modal Event Listener
  useEffect((): (() => void) | void => {
    if (isFidesLoaded) {
      window.addEventListener('FidesInitialized', onFidesInitialized);

      return () => {
        window.removeEventListener('FidesInitialized', onFidesInitialized);
      };
    }

    return undefined;
  }, [isFidesLoaded, onFidesInitialized]);

  // Consent Update Event Listener
  useEffect((): (() => void) | void => {
    if (isFidesLoaded) {
      window.addEventListener('FidesUpdated', onFidesUpdated);

      return () => {
        window.removeEventListener('FidesUpdated', onFidesUpdated);
      };
    }

    return undefined;
  }, [isFidesLoaded, onFidesUpdated]);

  // Because we're using a window event listener that can't subscribe to
  // useState changes, we need to use a ref to keep track of the current
  // preferences, otherwise opt-in/out events won't be tracked correctly
  useEffect(() => {
    consentRef.current = currentPreferences;
  }, [currentPreferences]);

  if (!isConsentManagementEnabled) {
    return null;
  }

  return (
    <>
      <Script key='fides-sdk' src={FidesJSUrl} onLoad={onFidesLoaded} onError={onFidesError} />
      <style data-testid='fides-css'>{FidesStyles}</style>
    </>
  );
}

function trackConsentUpdate(
  preference: string,
  fides: Fides,
  previousPreferences: Fides['consent'],
  menuVariant: string
): void {
  const { consent, experience } = fides;
  const { region } = experience ?? {};
  const newPreference = consent[preference];
  const previousPreference = previousPreferences[preference];

  if (newPreference === previousPreference || !region) {
    return;
  }

  const action = newPreference ? 'opt-in' : 'opt-out';
  tracker.privacyPreferenceUpdated({ preference, action, region });
  datadogLogs.logger.info(`Fides: Data sales and sharing ${action}`, { menuVariant, region });
}
