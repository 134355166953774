import React from 'react';
import styled, { css } from 'styled-components';

import { Clickable } from 'shared/components';

export function Weight({ onClick, selected, weight }): JSX.Element {
  return (
    <WeightTile onClick={onClick} selected={selected}>
      {weight}
    </WeightTile>
  );
}

const WeightTile = styled(Clickable)`
  ${({ selected, theme: { customized, colors } }) => css`
    align-items: center;
    border-radius: 7px;
    border: ${selected
      ? `2px solid ${String(customized.colors.buttonsLinks)}`
      : `1px solid ${String(colors.grey[70])}`};
    color: ${selected ? customized.colors.buttonsLinks : colors.primaryBlack};
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: ${selected ? `bold` : `normal`};
    height: 42px;
    justify-content: center;
    line-height: 2.5;
    margin: 0;
    outline: none;
    min-width: 51px;

    :not(:last-of-type) {
      margin: 0 10px 0 0;
    }
  `}
`;
