import React, { forwardRef } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import useCart from 'hooks/use-cart';

import MenuTypeSwitchWarningModal from './menu-type-switch-warning-modal';

const MenuTypeSwitch = forwardRef(({ ...props }, ref) => {
  const UI = useUI();
  const { t } = useTranslation();
  const router = useRouter();
  const cart = useCart();
  const menuTypeSwitchWarningObj = useObserver(() => UI.menuTypeSwitchWarningObj);
  const { resolve } = menuTypeSwitchWarningObj;

  const onFinish = (result) => {
    menuTypeSwitchWarningObj.open = false;
    resolve(result);
  };

  return <MenuTypeSwitchWarningModal ref={ref} t={t} router={router} cart={cart} onFinish={onFinish} {...props} />;
});

export default MenuTypeSwitch;
