import _ from 'lodash';
import React from 'react';
import { useObserver } from 'mobx-react-lite';

import useCart from 'shared/hooks/use-cart';
import useDispensarySpecials from 'src/dispensary/hooks/use-dispensary-specials';
import useViewportVisibility from 'hooks/use-viewport-visibility';
import usePaths from 'src/hooks/use-paths';
import { OffersCarousel } from './offers-carousel';

export function SpecialOffersCarousel(props) {
  const cart = useCart();
  const isMedical = useObserver(() => cart?.isMedical);
  const { section } = props;
  const { ref, hasBeenVisible } = useViewportVisibility();
  const { loading, specials } = useDispensarySpecials();
  const offers = _.filter(specials, [`specialType`, `bogo`]);
  const { href, route } = usePaths({ specials: true });

  if (!offers?.length && !loading && hasBeenVisible) {
    return null;
  }

  const sectionProps = {
    ...props,
    carouselType: `offers`,
    displayGradient: false,
    hasBeenVisible,
    isLoading: loading,
    isMedical,
    linkLabel: section.linkLabel,
    label: `Offers`,
    specials: offers,
    href,
    route,
  };
  return <OffersCarousel ref={ref} {...sectionProps} />;
}
