import React from 'react';
import { ORDER_TYPE_PICKUP } from 'shared/constants';

import {
  AutoCompleteInputList,
  CurrentAddress,
  OrderTypeTabs,
  DistanceSlider,
  Popover,
  ShowResultsButton,
  CollapsibleSection,
  ContentWrapper,
  SectionContainer,
} from './components';

import { LocationSettingsPopoverProps } from './location-settings-popover.types';

export function LocationSettingsPopover({
  open,
  anchorEl,
  orderType,
  distance,
  addressFieldFocused,
  currentAddressString,
  onClose,
  onSave,
  onChangeDistance,
  onChangeOrderType,
  onChangeTempLocation,
}: LocationSettingsPopoverProps): JSX.Element {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      data-cy='location-settings-popover'
      data-test='location-settings-popover'
      data-testid='location-settings-popover'
    >
      <ContentWrapper>
        <OrderTypeTabs orderType={orderType} onChangeOrderType={onChangeOrderType} />
        <SectionContainer>
          {currentAddressString && currentAddressString.length > 0 && (
            <CurrentAddress currentAddressString={currentAddressString} />
          )}
          <AutoCompleteInputList onChangeTempLocation={onChangeTempLocation} />
        </SectionContainer>
        <CollapsibleSection collapsed={addressFieldFocused}>
          {orderType === ORDER_TYPE_PICKUP && (
            <DistanceSlider distance={distance} onChangeDistance={onChangeDistance} />
          )}
          <ShowResultsButton onClick={onSave} />
        </CollapsibleSection>
      </ContentWrapper>
    </Popover>
  );
}
