import React from 'react';
import { Combobox } from '@reach/combobox';

import { AddressAutocompleteProps } from './types';
import { AddressAutocompleteContext } from './context';
import { useSetupContext } from './hooks/use-setup-context';

export function AddressAutocomplete({ children, suggestionTypes, onSelect }: AddressAutocompleteProps): JSX.Element {
  const { handleSelect, ...context } = useSetupContext({ suggestionTypes, onSelect });

  return (
    <AddressAutocompleteContext.Provider value={context}>
      <Combobox openOnFocus onSelect={handleSelect}>
        {children}
      </Combobox>
    </AddressAutocompleteContext.Provider>
  );
}
