import React from 'react';
import styled, { css } from 'styled-components';

import useTranslation from 'hooks/use-translation';
import useUI from 'src/hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useFeatureFlags from 'src/hooks/use-feature-flags';

import ROUTES from 'src/routes';

import { HorizontalDivider, Link } from 'components/core';
import { Clickable } from 'shared/components';
import { GoogleLogin } from 'src/components/google-login';
import { tracker } from 'src/utils/analytics';

const { EMBEDDED_MENU, STORES } = ROUTES;

type AccountRoutes = {
  account: string;
  preferences: string;
  orders: string;
  bankAccount: string;
};

export type MyAccountOptionsListProps = {
  isLoggedIn: boolean;
  onLogout: () => void;
  onOptionClick: (value: string) => void;
  userDisplayName: string;
};

function buildAccountRoutes(cName: string, menuType: string): AccountRoutes {
  return {
    account: `${menuType}/${cName}/user/account`,
    preferences: `${menuType}/${cName}/user/preferences`,
    orders: `${menuType}/${cName}/user/orders`,
    bankAccount: `${menuType}/${cName}/user/bank-account`,
  };
}

export function MyAccountOptionsList({
  isLoggedIn,
  onLogout,
  onOptionClick,
  userDisplayName,
}: MyAccountOptionsListProps): JSX.Element {
  const { t } = useTranslation();
  const UI = useUI();
  const { dispensary } = useDispensary();
  const { isEmbedded } = UI;
  const menuType = isEmbedded ? EMBEDDED_MENU : STORES;
  const accountRoutes = buildAccountRoutes(dispensary.cName, menuType);
  const { account, preferences, orders, bankAccount } = accountRoutes;
  const featureFlags = useFeatureFlags();
  const isAccountSettingsDutchiePayEnabled = featureFlags.flags['ecomm.menu.account-manage-dutchie-pay.rollout'];
  const isLoginPlacementsFlagEnabled = featureFlags.flags['growth.ecomm.login-placements.rollout'] ?? false;

  const handleGoogleLoginSubmit = (): void => {
    tracker.gaGTMClickEvent({
      eventCategory: 'account',
      eventAction: 'signed in',
      eventLabel: 'sso: {pageType}',
    });
  };

  if (isLoggedIn) {
    return (
      <>
        <UserNameDisplayContainer>
          <UserNameDisplayContent>{`Hi, ${userDisplayName}`}</UserNameDisplayContent>
        </UserNameDisplayContainer>
        <HorizontalDivider width='120px' ml='30px' />
        <LoggedInOptionsContainer>
          {isAccountSettingsDutchiePayEnabled && (
            <Link href={bankAccount}>
              <Anchor href={bankAccount}>{t('accountOptions.bankAccount', 'Bank Account')}</Anchor>
            </Link>
          )}
          <Link href={account}>
            <Anchor href={account}>{t('accountOptions.accountDetails', 'Account Details')}</Anchor>
          </Link>
          <Link href={preferences}>
            <Anchor href={preferences}>{t('accountOptions.preferences', 'Preferences')}</Anchor>
          </Link>
          <Link href={orders}>
            <Anchor href={orders}>{t('accountOptions.orders', 'Orders')}</Anchor>
          </Link>
        </LoggedInOptionsContainer>
        <HorizontalDivider width='120px' ml='30px' />
        <LogoutOption onClick={onLogout}>{t('accountOptions.logout', 'Logout')}</LogoutOption>
      </>
    );
  }

  return (
    <>
      <CreateAccountOption onClick={() => onOptionClick(`createAnAccount`)}>
        {t('accountOptions.createAccount', 'Create an Account')}
      </CreateAccountOption>
      <LoginOption onClick={() => onOptionClick(`login`)}>{t('accountOptions.login', 'Login')}</LoginOption>
      {isLoginPlacementsFlagEnabled && (
        <StyledGoogleLogin redirectUrl={window.location.href} onSubmit={handleGoogleLoginSubmit} />
      )}
    </>
  );
}

const loggedInOptionStyles = css`
  width: 187px;
  font-size: 14px;
  line-height: 175%;
  color: ${({ theme }) => theme.colors.grey[30]};
  cursor: pointer;
  text-align: left;
  padding-left: 30px;
  display: flex;
  align-items: center;
`;

const loggedOutOptionStyles = css`
  width: 225px;
  font-size: 14px;
  line-height: 175%;
  cursor: pointer;
  text-align: left;
  padding-left: 30px;
  display: flex;
  align-items: center;
  height: 45px;
  color: ${({ theme }) => theme.colors.grey[30]};
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryGrey};
  }
`;

const LoggedInOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 115px;
  justify-content: space-evenly;
`;

const CreateAccountOption = styled(Clickable)`
  ${loggedOutOptionStyles}
`;

const LoginOption = styled(Clickable)`
  ${loggedOutOptionStyles}
`;

const LogoutOption = styled(Clickable)`
  cursor: pointer;
  width: 187px;
  font-size: 14px;
  line-height: 175%;
  text-align: left;
  padding-left: 30px;
  height: 43px;
  display: flex;
  align-items: center;
  color: #0b99e6;
`;

const UserNameDisplayContainer = styled.div`
  width: 187px;
  font-size: 14px;
  text-align: left;
  padding: 5px 30px 0px;
  height: 47px;
  display: flex;
  align-items: center;
`;

const UserNameDisplayContent = styled.div`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Anchor = styled.a`
  ${loggedInOptionStyles}
`;

const StyledGoogleLogin = styled(GoogleLogin)`
  background: none;
  border: none;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.grey[30]};
  height: 45px;
  justify-content: flex-start;
  margin-top: 1px;
  padding: 0 0 0 30px;
  position: relative;

  &::before {
    background: #c8d2db;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    top: -1px;
    transform: translateX(-50%);
    width: 165px;
  }

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryGrey};
    box-shadow: none;
  }
`;
