import React, { useRef } from 'react';
import styled from 'styled-components';
import { Button } from 'shared/components';
import PublicEnv from 'shared/utils/public-env';
import { withScript } from 'shared/hooks/use-script';
import { useCheckoutCredentials } from 'checkout/components/sections/payment/use-checkout-credentials';

const isProduction = PublicEnv.appEnv === 'production';
const chaseIframeScript = isProduction
  ? 'https://www.chasepaymentechhostedpay.com/hpf/js/hpfParent.min.js'
  : 'https://www.chasepaymentechhostedpay-var.com/hpf/js/hpfParent.min.js';

const baseChaseIframeUrl = isProduction
  ? 'https://www.chasepaymentechhostedpay.com/hpf/1_1/?uID='
  : 'https://www.chasepaymentechhostedpay-var.com/hpf/1_1/?uID=';

function ChaseHostedPaymentForm(props) {
  const iframeRef = useRef();
  const [credentials] = useCheckoutCredentials('chase');
  const uID = credentials?.uID;
  const { onSave } = props;

  if (!uID) {
    return <StyledButton loading>Loading Chase Payments Configuration</StyledButton>;
  }

  const getChaseIframeUrl = `${baseChaseIframeUrl}${uID}`;
  return (
    <div>
      <iframe
        title='Chase Card Payment Form'
        ref={iframeRef}
        src={getChaseIframeUrl}
        frameBorder='0'
        height='190px'
        width='100%'
      />
      <StyledButton onClick={onSave}>Save</StyledButton>
    </div>
  );
}

export default withScript(ChaseHostedPaymentForm, chaseIframeScript);
const StyledButton = styled(Button)`
  margin-top: 25px;
  width: 73px;
  height: 33px;
  margin-right: 15px;
`;
