import React from 'react';
import styled from 'styled-components';

import { FilterSideBarSkeletonLong, FilterSideBarSkeletonShort } from 'components/core';

const FilterSidebarLoadingState = () => (
  <SkeletonContainer>
    <FilterSideBarSkeletonLong />
    <FilterSideBarSkeletonLong />
    <FilterSideBarSkeletonShort />
    <FilterSideBarSkeletonShort />
  </SkeletonContainer>
);

export default FilterSidebarLoadingState;

const SkeletonContainer = styled.div`
  height: 955px;
`;
