import { withCompositeHook } from 'src/hoc';

import { SelectWeight } from './select-weight';
import { useSelectWeight } from './use-select-weight';
import { UseSelectWeightReturn, SelectWeightControllerProps } from './select-weight.types';

export const SelectWeightController = withCompositeHook<SelectWeightControllerProps, UseSelectWeightReturn>(
  SelectWeight,
  useSelectWeight
);
