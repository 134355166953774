/* eslint-disable max-len */
export default {
  Status: 'Active',
  isBelowThreshold: false,
  effects: ['Energetic', 'Creative', 'Clear Mind'],
  id: 'test-flower-product',
  subcategory: '',
  name: 'Test Flower Product',
  Name: 'Test Flower Product',
  type: 'Flower',
  strainType: 'Hybrid',
  Image: 'https://s3-us-west-2.amazonaws.com/dutchie-images/0ee4b0f3ae6296012715128a5b3ff1e1',
  brand: {
    description:
      'Oregrown description completely made up, not real at all. The best weed ever, smoke it, eat it, drink it, love it. Oregrown description completely made up, not real at all. The best weed ever, smoke it, eat it, drink it, love it.',
    name: 'Oregrown',
  },
  price: 18.7,
  THCContent: {
    range: [20.76, 22.19],
    unit: 'PERCENTAGE',
  },
  CBDContent: {
    range: [2.34, 3.51],
    unit: 'PERCENTAGE',
  },
  description: [
    // eslint-disable-next-line max-len
    'According to actor and comedian Seth Rogen, the Pineapple Express cultivar didn’t exist until after the 2008 movie of the same name brought the name into the spotlight. Inspired by the film, growers took it upon themselves to create a potent cultivar that the film described as “the dopest dope.”',
    // eslint-disable-next-line max-len
    'Despite the nebulous marketing and inconsistent reported effects, most cuts of Pineapple Express are sought-after cultivars noted for their fluffy, lean flowers that shine with a lime and yellow coloring. Consumers select Pineapple Express for a tropical, citrus aroma that exudes from its typically higher limonene content.',
  ].join('\n'),
  Options: ['1g', '1/8 oz', '1/4 oz', '1/2 oz', '1oz', '2oz'],
  // optionsBelowThreshold: ['1/8 oz'],
  Prices: [8, 25, 50, 100, 200, 300.55],
  medicalPrices: [7, 20, 45, 85, 175, 300.55],
  recPrices: [8, 25, 50, 100, 200, 300.55],
  medicalSpecialPrices: [6, 20, 45, 70, 130, 300.55],
  recSpecialPrices: [5, 10, 20, 30, 50, 300.55],
  special: true,
  POSMetaData: {
    children: [
      { option: '1g', quantity: 100 },
      { option: '1/8 oz', quantity: 100 },
      { option: '1/4 oz', quantity: 100 },
      { option: '1/2 oz', quantity: 100 },
      { option: '1oz', quantity: 100 },
      { option: '2oz', quantity: 100 },
    ],
  },
};
