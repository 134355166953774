import React from 'react';
import { useMyAccountLink } from './use-my-account-link';
import { MyAccountLink } from './my-account-link';

export function MyAccountLinkController(): JSX.Element {
  const { showDropdown, toggleDropdown, isLoggedIn, anchorEl, myAccountRef, isMobile } = useMyAccountLink();

  return (
    <MyAccountLink
      showDropdown={showDropdown}
      toggleDropdown={toggleDropdown}
      isLoggedIn={isLoggedIn}
      anchorEl={anchorEl}
      myAccountRef={myAccountRef}
      isMobile={isMobile}
    />
  );
}
