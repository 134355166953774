import { useEffect } from 'react';

import useUser from 'src/hooks/use-user';
import { useDutchiePay } from 'src/payments/hooks';

import { tracker } from '..';

export const useUserMonitor = (): void => {
  const { user } = useUser();
  const { isEnrolledDutchiePay } = useDutchiePay();

  useEffect(() => {
    // FIXME: we see instances that the user.id is null, do we want to exclude these tracker calls?
    tracker.setContext({ activeUser: { isEnrolledDutchiePay, ...user } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnrolledDutchiePay, user.id /* only update when the userId changes */]);
};
