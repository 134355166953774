import { useCallback, useEffect, useState } from 'react';

import useTranslation from 'hooks/use-translation';
import useErnie from 'shared/hooks/use-ernie';
import getLocationsFromCoordinates, {
  GetLocationsFromCoordinatesResults,
} from 'src/utils/get-locations-from-coordinates';
import { LocationObject } from 'shared/helpers/mapbox';
import { SearchMode, UseGeolocationArgs, UseGeolocationReturn } from '../types';

export type Coordinates = { latitude: number; longitude: number };
export function useGeolocation({ setSearchMode }: UseGeolocationArgs): UseGeolocationReturn {
  const showErnie = useErnie();
  const { t } = useTranslation();

  const [userCoords, setUserCoords] = useState<Coordinates | null>(null);
  const [geolocationSuggestions, setGeolocationSuggestions] = useState<LocationObject[]>([]);
  const [geolocationLoading, setGeolocationLoading] = useState(false);

  const showError = useCallback(
    (): void =>
      showErnie(
        t(
          'addressSearch.currentLocationError',
          'An issue occurred while trying to use your current location. Please try again later.'
        ),
        `danger`
      ),
    [showErnie, t]
  );

  const handleGeolocation = (): void => {
    setSearchMode(SearchMode.geolocation);

    if (geolocationSuggestions.length) {
      return;
    }

    setGeolocationLoading(true);

    const success = (location: { coords: Coordinates }): void => setUserCoords(location.coords);
    const failure = (): void => {
      showError();
      setGeolocationLoading(false);
    };
    navigator.geolocation.getCurrentPosition(success, failure);
  };

  const handleGetLocationsResponse = useCallback(
    ({ data, status: responseStatus }: GetLocationsFromCoordinatesResults): void => {
      if (responseStatus === 200) {
        setGeolocationSuggestions(data);
      } else {
        showError();
      }
    },
    [showError]
  );

  useEffect(() => {
    if (userCoords) {
      void (async (coords: Coordinates): Promise<void> => {
        const response = await getLocationsFromCoordinates(coords);
        handleGetLocationsResponse(response);
        setGeolocationLoading(false);
      })(userCoords);
    }
  }, [handleGetLocationsResponse, userCoords]);

  return { geolocationLoading, geolocationSuggestions, handleGeolocation };
}
