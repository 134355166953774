import { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import PublicEnv from 'shared/utils/public-env';

export function useInitDataDogLogs() {
  const configs = {
    clientToken: 'pub6f5efdaab22141c2cd59685f6a22f6cb',
    env: PublicEnv.appEnv,
    forwardErrorsToLogs: false,
    service: 'ecomm-consumer-react',
    sessionSampleRate: 100,
    site: 'datadoghq.com',
  };

  useEffect(() => {
    if (PublicEnv.appEnv === 'production') {
      datadogLogs.init(configs);
    }
  }, []);
}
