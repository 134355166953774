import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { getOrderDateAndTime } from 'shared/helpers/orders';

const OrderReceiptDetailsTable = ({ order }) => {
  const { t } = useTranslation();

  const { deliveryInfo, dispensary, specialInstructions } = order;
  const { address, deliveryOption } = deliveryInfo;
  const type = deliveryOption ? t('common.delivery', 'delivery') : t('common.pickup', 'pickup');

  /* TODO:
        - does any of this information change for delivery vs pickup?
        - is there any other pickup related fields to include?
        - if we have a delivery estimate enabled should we show that here as well?
    */
  return (
    <Content>
      <tbody>
        <StyledRow>
          <DetailLabelCell>{t('common.date', 'date')}:</DetailLabelCell>
          <Spacer />
          <DetailDataCell>{getOrderDateAndTime(order)}</DetailDataCell>
        </StyledRow>

        <tr>
          <DetailLabelCell>{t('common.type', 'type')}:</DetailLabelCell>
          <Spacer />
          <DetailDataCell className='capitalize'>{type}</DetailDataCell>
        </tr>

        <tr>
          <DetailLabelCell>{t('common.address', 'address')}:</DetailLabelCell>
          <Spacer />
          <DetailDataCell>{deliveryOption ? address : dispensary.address}</DetailDataCell>
        </tr>

        {specialInstructions?.length ? (
          <tr>
            <DetailLabelCell>{t('common.specialInstructions', 'special instructions')}:</DetailLabelCell>
            <Spacer />
            <DetailDataCell>{specialInstructions}</DetailDataCell>
          </tr>
        ) : null}
      </tbody>
    </Content>
  );
};

const Content = styled.table`
  border: 0;
  text-align: left;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[30]};
  border-spacing: 0 10px;
  line-height: 135%;

  .capitalize {
    text-transform: capitalize;
  }
`;

const DetailLabelCell = styled.td`
  vertical-align: top;
  font-weight: bold;
  text-transform: capitalize;
  min-width: 84px;
`;

const DetailDataCell = styled.td`
  min-width: 184px;
  @media (max-width: 350px) {
    min-width: unset;
  }
`;

const Spacer = styled.td`
  width: 100%;
`;

const StyledRow = styled.tr``;

export default OrderReceiptDetailsTable;
