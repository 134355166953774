import _compact from "lodash/compact";
import _find from "lodash/find";
import moment from 'moment-timezone';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import flattenDeep from 'lodash/fp/flattenDeep'; // Returns an empty string if can't produce an ISO string - may not be what you want!!

/* eslint-disable */

export var toISOString = function toISOString(date) {
  return date && typeof date.toISOString === 'function' ? date.toISOString() : '';
};
/* eslint-enable */

export var convertDate = function convertDate(time, timezone) {
  var date = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var daysOffset = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var hoursOffset = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
  var minutesOffset = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;

  if (time === 'Immediately') {
    var _rv = moment().add(daysOffset, 'days').add(hoursOffset, 'hours').add(minutesOffset, 'minutes');

    return _rv;
  }

  var requestedDay = date ? moment.tz(date, timezone).format('MM-DD-YY') : moment().tz(timezone).format('MM-DD-YY');
  var requested = "".concat(requestedDay, " ").concat(time);
  var rv = moment.tz(requested, 'MM-DD-YY hh:mmA', timezone).add(daysOffset, 'days').add(hoursOffset, 'hours').add(minutesOffset, 'minutes');
  return rv;
};
export var formatDate = function formatDate(passedDate, format) {
  var date = passedDate instanceof Date ? passedDate.toISOString() : passedDate;
  return moment(date).format(format);
};
/*
 * Get hours, by type, for the next openn day, accounting for
 * special hours
 */

export var findNextOpenDay = function findNextOpenDay(_ref) {
  var _ref$serviceType = _ref.serviceType,
      serviceType = _ref$serviceType === void 0 ? 'pickup' : _ref$serviceType,
      _ref$standardHours = _ref.standardHours,
      standardHours = _ref$standardHours === void 0 ? {} : _ref$standardHours,
      _ref$specialHours = _ref.specialHours,
      specialHours = _ref$specialHours === void 0 ? {} : _ref$specialHours,
      _ref$timezone = _ref.timezone,
      timezone = _ref$timezone === void 0 ? 'US/Pacific' : _ref$timezone,
      now = _ref.now,
      _ref$addedDays = _ref.addedDays,
      addedDays = _ref$addedDays === void 0 ? 0 : _ref$addedDays;

  if (addedDays > 7 || !standardHours) {
    return {};
  }

  var specialHoursDays = flow(map('hoursPerDay'), flattenDeep)(specialHours);
  var nextDay = moment().tz(timezone).startOf('day').add(addedDays, 'days');
  var nextDayHours = standardHours[nextDay.format('dddd')];

  var specialHoursForNextDay = _find(_compact(specialHoursDays), function (_ref2) {
    var date = _ref2.date;
    return moment.tz(date, timezone).isSame(nextDay, 'day');
  });

  if (specialHoursForNextDay) {
    nextDayHours = specialHoursForNextDay.hours;
  }

  var start = nextDayHours.start === 'open' ? '12:00 AM' : nextDayHours.start;
  var end = nextDayHours.end === 'open' ? '12:00 AM' : nextDayHours.end;
  var openMoment = moment.tz("".concat(nextDay.format('MM-DD-YY'), " ").concat(start), 'MM-DD-YY hh:mmA', timezone); // if open 24 hours, close is 12:00 am the next day

  var closeMoment = moment.tz("".concat(nextDay.clone().add(nextDayHours.end === 'open' ? 1 : 0, 'day').format('MM-DD-YY'), " ").concat(end), 'MM-DD-YY hh:mmA', timezone);
  /* return hours for the first day that is either:
   *   a. today and the store has not yet closed
   *   b. after today
   */

  if (nextDayHours.active && (now.isBefore(closeMoment) || !nextDay.isSame(now, 'day'))) {
    return {
      date: nextDay,
      openMoment: openMoment,
      closeMoment: closeMoment
    };
  }

  return findNextOpenDay({
    serviceType: serviceType,
    standardHours: standardHours,
    specialHours: specialHours,
    timezone: timezone,
    now: now,
    addedDays: addedDays + 1
  });
};
export var guessTimezone = function guessTimezone() {
  var guess = moment.tz.guess();

  if (['America/New_York', 'America/Detroit'].includes(guess)) {
    return 'US/Eastern';
  }

  if (guess === 'America/Chicago') {
    return 'US/Central';
  }

  if (['America/Denver', 'America/Boise'].includes(guess)) {
    return 'US/Mountain';
  }

  if (['America/Los_Angeles', 'America/Vancouver'].includes(guess)) {
    return 'US/Pacific';
  }

  return guess;
};
export var formatGhostPublishAt = function formatGhostPublishAt(publishedAt) {
  var now = moment();
  var momentPublishedAt = !publishedAt ? now : moment(publishedAt);
  var publishedAtString = momentPublishedAt.fromNow();
  var daysPassedSincePublish = Math.floor(now.diff(momentPublishedAt, 'days'));

  if (daysPassedSincePublish < 7 && daysPassedSincePublish >= 1) {
    publishedAtString = "".concat(daysPassedSincePublish, " day").concat(daysPassedSincePublish > 1 ? 's' : '', " ago");
  } else if (daysPassedSincePublish >= 7) {
    var weeksPassed = Math.floor(daysPassedSincePublish / 7);
    publishedAtString = "".concat(weeksPassed, " week").concat(weeksPassed > 1 ? 's' : '', " ago");
  }

  return publishedAtString;
};