/* eslint-disable max-len */
import React from 'react';

const InProgress = (props) => (
  <svg height='68' viewBox='0 0 68 68' width='68' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle cx='34' cy='34' fill='#5ea78d' r='34' />
      <circle cx='34' cy='34' fill='none' r='25' stroke='#fff' strokeWidth='10' />
    </g>
  </svg>
);

export default InProgress;
