/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import styled from 'styled-components';

import useScript from 'shared/hooks/use-script';
import { MxWidgetClient, MXWidgetProps } from './mx-widget-client';

export function MXWidget({ mxWidgetUrl, onEvent }: MXWidgetProps): JSX.Element {
  const { loadMXWidget, setMxConnectEventHandler } = MxWidgetClient();

  const [mxScriptLoaded] = useScript(`https://atrium.mx.com/connect.js`);

  useEffect(() => {
    if (mxScriptLoaded) {
      loadMXWidget({ mxWidgetUrl });
    }
  }, [loadMXWidget, mxScriptLoaded, mxWidgetUrl]);

  useEffect(() => {
    setMxConnectEventHandler({ onEvent });
  }, [onEvent, setMxConnectEventHandler]);

  return <ConnectWidgetContainer id='connect-widget' />;
}

const ConnectWidgetContainer = styled.div`
  // The mx widget needs a minimum width of 320px and min height of 550px,
  // this ensures that the widget will surface for all mobile devices :hand_with_index_and_middle_fingers_crossed:
  min-width: 320px;
  min-height: 550px;
  // counteracts the parent's padding
  margin: -24px;
  margin-bottom: -29px;
`;
