/* eslint-disable max-len */
import React from 'react';

function ClearIcon(props) {
  return (
    <svg width='15' height='16' viewBox='0 0 15 16' fill='none' {...props}>
      <path
        d='M10.0822 4.24849C10.4188 3.91183 10.9647 3.91183 11.3014 4.24849C11.638 4.58516 11.638 5.13101 11.3014 5.46768L5.20543 11.5636C4.86877 11.9003 4.32292 11.9003 3.98625 11.5636C3.64958 11.2269 3.64958 10.6811 3.98625 10.3444L10.0822 4.24849Z'
        fill='white'
      />
      <path
        d='M11.3013 10.3444C11.638 10.6811 11.638 11.2269 11.3013 11.5636C10.9647 11.9003 10.4188 11.9003 10.0822 11.5636L3.98625 5.46768C3.64958 5.13101 3.64958 4.58516 3.98625 4.24849C4.32292 3.91182 4.86876 3.91182 5.20543 4.24849L11.3013 10.3444Z'
        fill='white'
      />
    </svg>
  );
}

export default ClearIcon;
