export enum ModalNames {
  addressRequired = 'addressRequired',
  addToCart = 'addToCart',
  buyingOptions = 'buyingOptions',
  changePassword = 'changePassword',
  consumerDistanceFilter = 'consumerDistanceFilter',
  deliveryAddress = 'deliveryAddress',
  emptyCart = 'emptyCart',
  login = 'login',
  marketplaceDistanceFilter = 'marketplaceDistanceFilter',
  orderReceipt = 'orderReceipt',
  orderStatus = 'orderStatus',
  orderType = 'orderType',
  resetPassword = 'resetPassword',
  selectStore = 'selectStore',
  signUp = 'signUp',
  dutchiePayCta = 'dutchiePayCta',
  dutchiePayRelinkBankAccount = 'dutchiePayRelinkBankAccount',
  dutchiePayVisitOnDesktop = 'dutchiePayVisitOnDesktop',
  termsOfService = 'termsOfService',
  rethinkPayOrderProcessing = 'rethinkPayOrderProcessing',
  rethinkPayPaymentProcessing = 'rethinkPayPaymentProcessing',
}
