/* eslint-disable max-len */
import React from 'react';

function SvgSearchIcon(props: React.SVGAttributes<SVGElement> & { alt: string; fill?: string }): JSX.Element {
  return (
    <svg width='19' height='19' viewBox='0 0 18 19' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.04981 2.1699C9.82994 2.25329 12.0633 4.48763 12.1477 7.26775C12.1477 9.96355 9.96354 12.1477 7.26775 12.1477C4.48762 12.0643 2.25328 9.82995 2.1699 7.04982C2.1699 4.35497 4.35496 2.1699 7.04981 2.1699ZM13.2658 10.9253C13.9367 9.82331 14.291 8.55832 14.2891 7.26775C14.2039 3.30034 11.0163 0.100441 7.04982 0C3.15631 0 0 3.15631 0 7.04982C0.08528 11.0277 3.28991 14.2323 7.26775 14.3176C8.5119 14.3214 9.73519 13.9954 10.8116 13.37L15.1609 17.6814C15.5807 18.1049 16.2648 18.1087 16.6884 17.689L17.7667 16.6107C18.1741 16.1843 18.0036 15.6536 17.5961 15.2272L13.2658 10.9253Z'
      />
    </svg>
  );
}

export default SvgSearchIcon;
