import React from 'react';
import styled from 'styled-components';

type DividerProps = {
  text?: string;
};

export const Divider = ({ text = 'or' }: DividerProps): JSX.Element => <StyledDivider>{text}</StyledDivider>;

const StyledDivider = styled.div`
  align-items: center;
  color: #464f53;
  display: flex;
  font-size: 14px;
  line-height: ${23 / 14};
  margin: 20px 0;
  width: 100%;

  &::before,
  &::after {
    border-top: 1px solid ${({ theme }) => theme.colors.blueGrey[80]};
    content: '';
    flex: 1;
  }

  &::before {
    margin-right: 20px;
  }

  &::after {
    margin-left: 20px;
  }
`;
