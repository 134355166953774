import React from 'react';
import styled from 'styled-components';
import styledContainerQuery from 'styled-container-query';
import { useObserver } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useUser from 'src/hooks/use-user';
import useUI from 'src/hooks/use-ui';
import { useModals, ModalNames } from 'src/components/modals';
import { Button } from 'shared/components';
import { Typography } from 'src/typography';
import { GoogleLogin } from 'src/components/google-login';
import { tracker } from 'src/utils/analytics';

type TrackEventProps = {
  signIn: boolean;
  type: 'email' | 'sso';
};

export const Login = (): JSX.Element | null => {
  const { openModal } = useModals();
  const User = useUser();
  const { isKiosk } = useUI();
  const isLoggedIn = useObserver(() => User.isLoggedIn);
  const isLoading = useObserver(() => User.loading);
  const flags = useFlags();
  const isLoginPlacementsFlagEnabled = flags['growth.ecomm.login-placements.rollout'] ?? false;

  if (isKiosk || (isLoggedIn && !isLoading) || !isLoginPlacementsFlagEnabled) {
    return null;
  }

  const handleLogin = (): void => {
    void openModal(ModalNames.login);
    trackEvent({ signIn: true, type: 'email' });
  };

  const handleSignUp = (): void => {
    void openModal(ModalNames.signUp);
    trackEvent({ signIn: false, type: 'email' });
  };

  const trackEvent = ({ signIn, type }: TrackEventProps): void => {
    tracker.gaGTMClickEvent({
      eventCategory: 'account',
      eventAction: signIn ? 'signed in' : 'created account',
      eventLabel: `${type}: {pageType}`,
    });
  };

  return (
    <Wrapper>
      <Typography.Heading size='xsmall' tag='h3'>
        Checkout faster with an account.
      </Typography.Heading>

      <Byline size='medium' weight='semi'>
        Sign up to get started.
      </Byline>

      <Buttons>
        <ButtonWrapper>
          <GoogleLogin redirectUrl={window.location.href} onSubmit={() => trackEvent({ signIn: true, type: 'sso' })} />
        </ButtonWrapper>

        <ButtonWrapper>
          <Button width='100%' height='50px' onClick={handleSignUp}>
            Sign up with email
          </Button>
        </ButtonWrapper>
      </Buttons>

      <Byline size='medium' weight='semi'>
        Already have an account? <InlineLink onClick={handleLogin}>Log in</InlineLink>
      </Byline>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #f5f6f7;
  border-radius: ${({ theme }) => theme.customized.radius.lg};
  padding: 24px;
`;

const Byline = styled(Typography.Label)`
  color: #646d72;
  margin-top: 8px;
`;

// Using this as the container query instead of the Wrapper element just for ease of adapting styles to account for horizontal padding in the query
const Buttons = styledContainerQuery.div`
  align-items: center;
  display: flex;
  margin-top: 24px;
  gap: 20px;

  &:container(max-width: 450px) {
    align-items: stretch;
    flex-direction: column;
    gap: 8px;
  }
`;

const ButtonWrapper = styled.div`
  flex: 1;
`;

const InlineLink = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  cursor: pointer;
  font: inherit;
  padding: 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;
