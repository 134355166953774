import React from 'react';

import EmptyGoodsIcon from 'assets/empty-goods-icon';
import useTranslation from 'hooks/use-translation';
import useProductQueryParams from 'hooks/use-product-query-params';

import { DesktopOnly, MobileOnly } from 'shared/components';
import { Button } from 'components/core';
import { EmptyStateContainer, StyledHeader, StyledSubtext, StyledTextButton } from './empty-state-shared-styles';

export function MarketplaceProductListEmptyState(): JSX.Element {
  const { t } = useTranslation();
  const { resetQueryParams } = useProductQueryParams();
  const startOverString = t('marketplaceProductListPageEmptyState.clearYourSearch', 'Start over');

  return (
    <EmptyStateContainer>
      <EmptyGoodsIcon />
      <StyledHeader fontSize={20} mt={40} px={20}>
        {t('marketplaceProductListEmptyState.header', "We couldn't find what you're looking for")}
      </StyledHeader>
      <StyledSubtext p='10px 25px 0'>
        {t(
          'marketplaceProductListEmptyState.subtext',
          'There aren’t any products that match your search. Please try again.'
        )}
      </StyledSubtext>
      <DesktopOnly>
        <StyledTextButton pt={25} fontWeight='normal' onClick={resetQueryParams}>
          {startOverString}
        </StyledTextButton>
      </DesktopOnly>
      <MobileOnly>
        <Button mt={30} variant='outline'>
          {startOverString}
        </Button>
      </MobileOnly>
    </EmptyStateContainer>
  );
}
