import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

import { DesktopOnly, MobileOnly } from 'shared/components';

import useUI from 'hooks/use-ui';

import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { tracker } from 'src/utils/analytics';

import { useOrderReceiptModal } from './use-order-receipt-modal';
import { OrderReceiptDesktop } from './order-receipt-desktop';
import { OrderReceiptMobile } from './order-receipt-mobile';
import { OrderReceiptMobileV2 } from './order-receipt-mobile-v2';

export const OrderReceiptModal = ({ onClose, ...props }) => {
  const { dispensary, order, loading, useMobileOrderReceiptV2 } = useOrderReceiptModal();
  const router = useRouter();
  const UI = useUI();
  const { isMobileEcommApp } = UI;

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: ProductRecommendationSection.orderReceipt,
  });

  const handleReceiptItemClick = useCallback(
    () => (product, productIndex) => {
      UI.activeProductSource = trackerSource;
      UI.setProductPosition({ productId: product._id, productIndex });
      tracker.setContext({
        activeProductPosition: productIndex,
      });
      tracker.productClicked({ product, position: productIndex, trackerSource });
    },
    [UI, trackerSource]
  );

  return (
    <>
      <StyledDesktopOnly>
        <OrderReceiptDesktop
          dispensary={dispensary}
          onClose={onClose}
          order={order}
          loading={loading}
          handleItemClick={handleReceiptItemClick}
          {...props}
        />
      </StyledDesktopOnly>

      {!useMobileOrderReceiptV2 && (
        <StyledMobileOnly isMobileEcommApp={isMobileEcommApp}>
          <OrderReceiptMobile
            dispensary={dispensary}
            onClose={onClose}
            order={order}
            loading={loading}
            handleItemClick={handleReceiptItemClick}
          />
        </StyledMobileOnly>
      )}
      {useMobileOrderReceiptV2 && (
        <EmbeddedStyledMobile>
          <OrderReceiptMobileV2
            dispensary={dispensary}
            onClose={onClose}
            order={order}
            loading={loading}
            handleItemClick={handleReceiptItemClick}
          />
        </EmbeddedStyledMobile>
      )}
    </>
  );
};

const StyledDesktopOnly = styled(DesktopOnly)`
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 0;
    border: 0;
    height: 0;
    background: transparent;
  }
`;

const StyledMobileOnly = styled(MobileOnly)`
  height: 100%;
  ${({ isMobileEcommApp }) =>
    isMobileEcommApp
      ? null
      : css`
          padding-top: 90px;
        `}
`;

const EmbeddedStyledMobile = styled(MobileOnly)`
  overflow: auto;
  height: 100%;
`;
