import React from 'react';
import styled, { css } from 'styled-components';

import { GqlDispensaries } from 'types/graphql';
import useUI from 'src/hooks/use-ui';

import StoreFrontHeader from 'src/dispensary/core-menu/components/core-menu-header/store-front-header';
import { DesktopHeader } from './desktop-header';
import { MobileHeader } from './mobile-header';
import { InfoBar } from './info-bar';
import { SkipLink } from './skip-link';
import { DesktopMq, InfoBarMq, MobileMq } from './header.styles';

type HeaderProps = {
  dispensary: GqlDispensaries;
};

export const Header = ({ dispensary }: HeaderProps): JSX.Element => {
  const UI = useUI();
  const isAccountEnabled = !UI.isKiosk && !dispensary.featureFlags.hideMyAccount;
  const showStoreFrontHeader = UI.isStoreFront || UI.isKiosk;

  return (
    <Wrapper $isSticky={UI.isKiosk}>
      <SkipLink />
      {showStoreFrontHeader && <StoreFrontHeader />}

      <DesktopMq>
        <DesktopHeader dispensary={dispensary} isAccountEnabled={isAccountEnabled} />
      </DesktopMq>

      <MobileMq>
        <MobileHeader dispensary={dispensary} isAccountEnabled={isAccountEnabled} />
      </MobileMq>

      <InfoBarMq>
        <InfoBar />
      </InfoBarMq>
    </Wrapper>
  );
};

const Wrapper = styled.header<{ $isSticky: boolean }>`
  width: 100%;

  ${({ $isSticky }) =>
    $isSticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 20;
    `}
`;
