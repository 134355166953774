import { GqlDispensaries } from 'types/graphql';
import { MISSING_PRODUCT_BRAND_PLACEHOLDER_ID } from 'shared/constants';
import { Product } from 'src/utils/helpers/product';
import _sortBy from 'lodash/sortBy';

type UseSortProductsByCustomBrandsortParams = {
  products: Product[];
  dispensary: GqlDispensaries | undefined;
  isDefaultSort: boolean;
  menuOrder: string;
  types: string[];
};

export function useSortProductsByCustomBrandsort({
  products,
  dispensary,
  isDefaultSort,
  types,
}: UseSortProductsByCustomBrandsortParams): Omit<
  UseSortProductsByCustomBrandsortParams,
  'dispensary' | 'isDefaultSort' | 'menuOrder' | 'types'
> {
  const { menuOrder, menuSortSettings } = dispensary ?? {};
  const [queryCategory] = types;
  const categorySortSettings = menuSortSettings?.find((sortSettings) => sortSettings.category === queryCategory);

  if (!isDefaultSort || menuOrder !== 'brand') {
    return { products };
  }

  const productsSortedByBrand = _sortBy(products, [
    (product) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const productBrandId = product.brand?.id ?? MISSING_PRODUCT_BRAND_PLACEHOLDER_ID;
      const brandIndex = categorySortSettings?.brands?.indexOf(productBrandId);
      return brandIndex === -1 ? 1000 : brandIndex;
    },
    `weight`,
  ]);

  return { products: productsSortedByBrand };
}
