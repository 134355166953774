import _ from 'lodash';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { GqlDispensarySearchQuery } from 'types/graphql';
import { WhenNotVoid } from 'shared/utils/type-utils';

type Dispensaries = WhenNotVoid<GqlDispensarySearchQuery['filteredDispensaries']>;

export function useSortDispensariesByOpenStatus(dispensaries: Dispensaries): Dispensaries {
  const closedDispensaries: Dispensaries = [];

  const openDispensaries: Dispensaries = dispensaries.filter((dispensary) => {
    if (!dispensary) {
      return false;
    }
    const openInfo = openInfoForDispensary(dispensary);
    if (_.some(openInfo, `isOpen`)) {
      return true;
    }
    closedDispensaries.push(dispensary);
    return false;
  });

  return [...openDispensaries, ...closedDispensaries];
}
