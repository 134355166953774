/* eslint-disable @typescript-eslint/sort-type-union-intersection-members */
import { HTMLAttributes } from 'react';

import { ExtendedUseTranslationResponse } from 'hooks/use-translation';

import { fontSizeTokens, fontWeightTokens } from './system';

export enum Fonts {
  proximaNova = 'Proxima Nova',
}

export type Sizes = 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | 'xxsmall';

export type Weights = keyof typeof fontWeightTokens;

export type Cases = 'capitalize' | 'lowercase' | 'none' | 'uppercase';

export type Tags = 'h1' | 'h2' | 'h3' | 'h4' | 'div' | 'span' | 'p' | 'a' | 'li';

/* Type Utils */
export type FontsSizes<T extends Sizes> = Record<Fonts, FontSizeTokens<T>>;

type FontSizeToken = keyof typeof fontSizeTokens;

export type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type FontSizeTokenBase = Record<'base', FontSizeToken>;

type FontSizeTokenBreakpoints = Partial<Record<Breakpoints, FontSizeToken>>;

export type FontSizeTokens<Key extends Sizes> = Record<Key, FontSizeTokenBase & FontSizeTokenBreakpoints>;

export type TypographyNode = React.ReactNode | ((translation: ExtendedUseTranslationResponse) => string);

export type TypographyProps<TSize extends Sizes> = HTMLAttributes<HTMLElement> & {
  children: TypographyNode;
  size: TSize;
  weight?: Weights;
  casing?: Cases;
  tag?: Tags;
  className?: string;
};

export type TypographyCssProps = {
  baseFontSize: string;
  breakpoints: Array<[string, string]>;
  weight: typeof fontWeightTokens[Weights];
  casing: Cases;
  lineHeight: number;
};
