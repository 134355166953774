/* eslint-disable max-len */
import React from 'react';

type LockIconProps = {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
};

export function LockIcon({ width = 49, height = 48, ...props }: LockIconProps): JSX.Element {
  return (
    <svg width={width} height={height} viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Lock Icon</title>
      <path
        d='M39.134 19.286c.947 0 1.715.767 1.715 1.714v20.571c0 .947-.768 1.715-1.715 1.715H10.85a1.714 1.714 0 0 1-1.715-1.715V21c0-.947.768-1.714 1.715-1.714h28.285Z'
        fill='#EAEFF2'
        stroke='#5E6D79'
        strokeWidth='2.571'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.706 29.143a1.715 1.715 0 1 1-3.43-.001 1.715 1.715 0 0 1 3.43 0Z'
        fill='#5E6D79'
        stroke='#5E6D79'
        strokeWidth='2'
      />
      <path
        clipRule='evenodd'
        d='M13.941 15.286c0-6.076 4.924-11 11-11s11 4.924 11 11v4h-22v-4Z'
        stroke='#5E6D79'
        strokeWidth='2.571'
      />
      <rect x='24.134' y='30.857' width='1.714' height='5.143' rx='.857' fill='#5E6D79' />
    </svg>
  );
}
