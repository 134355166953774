import styled, { css } from 'styled-components';
import { space } from 'styled-system';

export const DutchiePayHeader = styled.h6`
  color: ${({ theme }) => theme.colors.dutchiePayBlack};
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 4px;
`;

export const DutchiePayFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0 38px 0;
`;

export const FooterContainer = styled.div`
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme?.colors?.grey[30]};
  font-size: 13px;
  margin: 20px auto;
  line-height: 20px;
`;

export const InputRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 18px;
  margin-bottom: 18px;
  position: relative;

  &:nth-last-child(2) {
    margin-bottom: 20px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  // because :only-child does not work in Safari
  > *:first-child:last-child {
    grid-column: 1 / -1;
  }

  ${space}
`;

export const CenterItems = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const IconContainer = styled.div`
  ${({ theme: { spaces } }) => css`
    padding: 0 0 ${spaces[3]} 0;
  `}
`;
