import _ from 'lodash';
import React from 'react';

import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import useViewportVisibility from 'hooks/use-viewport-visibility';
import usePaths from 'src/hooks/use-paths';
import { CategoriesForMenu as categories } from 'shared/constants';
import { defaultFilters, replaceEdibleWithIngestible, checkNeedToMakeEdibleIngestible } from '../carousels.utils';
import { GenericCarousel } from './generic-carousel';

export function CategoryCarousel(props) {
  const { section, dispensaryState } = props;
  const { label, category } = section;
  const currentCategory = _.find(categories, [`value`, category]) || category;
  const { ref, hasBeenVisible } = useViewportVisibility();

  const makeEdibleIngestible = checkNeedToMakeEdibleIngestible(dispensaryState, category);

  const { products, loading } = useDispensaryProducts({
    filters: { ...defaultFilters, category },
    perPage: 25,
  });
  const { href, route } = usePaths({ category: currentCategory });
  const hasCategory = products?.length > 0;
  if (!hasCategory && !loading && hasBeenVisible) {
    return null;
  }

  const sectionProps = {
    ...props,
    hasBeenVisible,
    isLoading: loading,
    linkLabel: makeEdibleIngestible ? replaceEdibleWithIngestible(section.linkLabel) : section.linkLabel,
    href: makeEdibleIngestible ? replaceEdibleWithIngestible(href) : href,
    label: makeEdibleIngestible ? replaceEdibleWithIngestible(label) : label,
    products,
    route,
  };

  return <GenericCarousel ref={ref} {...sectionProps} />;
}
