import { dollarsToCents } from 'shared/helpers/utils';

export function centsToDollars(val: number, fixed = 2): string {
  const price = val / 100;
  return `$${price.toFixed(fixed)}`;
}

export function normalizeDollars(value: string): string {
  if (value.trim() === '') {
    return '0';
  }

  const cents = dollarsToCents(parseFloat(value));

  return centsToDollars(cents, 2).substring(1);
}
