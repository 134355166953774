import React from 'react';

import { useDispensaryMonitor } from './monitors/use-dispensary-monitor';
import { usePathMonitor } from './monitors/use-path-monitor';
import { useProductPositionMonitor } from './monitors/use-product-position-monitor';
import { useUserMonitor } from './monitors/use-user-monitor';
import { useMarketingGoogleAnalyticsMonitor } from './monitors/use-marketing-google-analytics-monitor';
import { useOrderMonitor } from './monitors/use-order-monitor';
import { useMenuVariantMonitor } from './monitors/use-menu-variant-monitor';
import { AnalyticsScripts } from './Scripts';
import { useFeatureFlagsMonitor } from './monitors/use-feature-flags-monitor';
import { useExperimentsMonitor } from './monitors/use-experiments-monitor';

/**
 * The root analytics component; present on all consumer pages.
 *
 * Responsibilities:
 *  - adding <script /> tags for trackers
 *  - running monitors that react to global state
 *  - initializing the aggregate tracker with config from useDispensaryMonitor()
 *
 * see the Analytics.tsx section in {@link ./README.md} for more information.
 */
export const Analytics: React.FC = () => {
  /* FLOW(track-an-event): [optional] add global monitors here */
  const { loading: loadingDispensary } = useDispensaryMonitor();
  const loadingFeatureFlags = useFeatureFlagsMonitor(loadingDispensary);

  usePathMonitor(loadingDispensary || loadingFeatureFlags);
  useMenuVariantMonitor();
  useUserMonitor();
  useOrderMonitor();
  useMarketingGoogleAnalyticsMonitor();
  useProductPositionMonitor();
  useExperimentsMonitor();

  return (
    <>
      <AnalyticsScripts />
    </>
  );
};
