import React, { useState, useEffect } from 'react';

import EmptyGoodsIcon from 'assets/empty-goods-icon';
import useTranslation from 'hooks/use-translation';
import { useRouter } from 'next/router';
import { EmptyStateContainer, StyledHeader, StyledSubtext } from './empty-state-shared-styles';

const BrandsPageEmptyState = () => {
  const { t } = useTranslation();
  const { query } = useRouter();
  const [unavailableBrand, setUnavailableBrand] = useState(false);

  useEffect(() => {
    if (query?.brandId) {
      setUnavailableBrand(true);
    }
  }, [query?.brandId]);

  const headerText = unavailableBrand
    ? `We’re sorry, no products are currently available for this brand.`
    : `Select a brand to get started!`;

  return (
    <EmptyStateContainer>
      <EmptyGoodsIcon />
      <StyledHeader>{t('brandsPageEmptyState.header', headerText)}</StyledHeader>
      <StyledSubtext>{t('brandsPageEmptyState.subtext', 'All available brands are listed to the left')}</StyledSubtext>
    </EmptyStateContainer>
  );
};

export default BrandsPageEmptyState;
