import React from 'react';
import { SpaceProps } from 'styled-system';

import {
  GroupHeader,
  GroupHeaderIcon,
  GroupHeaderInner,
  GroupHeaderLink,
  GroupHeaderText,
  Heading,
  Subheading,
  GroupHeaderActions,
} from './hybrid-carousel-header.styles';

import { CarouselTheme } from '../hybrid-carousel';

export type HybridHeaderProps = SpaceProps & {
  carouselPadding?: number;
  carouselTheme: CarouselTheme;
  fullWidth?: boolean;
  heading?: string;
  href?: string;
  icon?: React.ReactNode;
  linkLabel?: string;
  subheading?: string;
  onClickHeaderLink?: () => void;
};

export function HybridHeader({
  heading,
  subheading,
  icon,
  href,
  linkLabel,
  fullWidth = false,
  carouselTheme,
  carouselPadding = 20,
  onClickHeaderLink,
}: HybridHeaderProps): JSX.Element | null {
  return (
    <GroupHeader carouselPadding={carouselPadding} fullWidth={fullWidth}>
      <GroupHeaderInner>
        {icon && <GroupHeaderIcon data-testid='hybrid-carousel-icon'>{icon}</GroupHeaderIcon>}
        <GroupHeaderText>
          {heading && (
            <Heading size='medium' tag='h2'>
              {heading}
            </Heading>
          )}
          {subheading && (
            <Subheading size='xsmall' tag='p'>
              {subheading}
            </Subheading>
          )}
        </GroupHeaderText>
      </GroupHeaderInner>

      {href && linkLabel && (
        <GroupHeaderActions>
          <GroupHeaderLink href={href} {...carouselTheme} onClick={onClickHeaderLink}>
            {linkLabel}
          </GroupHeaderLink>
        </GroupHeaderActions>
      )}
    </GroupHeader>
  );
}
