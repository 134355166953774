import { useObserver } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useQuery, useApolloClient } from '@apollo/react-hooks';

import { filteredOrders } from 'shared/graphql/order/queries.js';
import { PusherConfig } from 'shared/constants';
import { useGetDegradedPollInterval } from 'shared/hooks/use-get-degraded-poll-interval';

import useUser from 'hooks/use-user';
import usePusher from 'hooks/use-pusher';

const defaultPollInterval = 60000;

export default function useOrderForConsumerView({ includeProductStatus } = {}) {
  const user = useUser();
  const pusher = usePusher();
  const apolloClient = useApolloClient();
  const pollInterval = useGetDegradedPollInterval({ milliseconds: defaultPollInterval, degradationMultiplier: 5 });
  const userId = useObserver(() => user.id);
  const viewOrderId = useObserver(() => user.viewOrderId);

  const variables = {
    ordersFilter: { orderId: viewOrderId, userId },
    includeProductStatus: !!includeProductStatus,
  };

  const { data, refetch, networkStatus, loading } = useQuery(filteredOrders, {
    variables,
    fetchPolicy: `cache-and-network`,
    pollInterval,
    client: apolloClient,
    skip: !userId,
  });

  const [order] = data?.filteredOrders?.orders || [];
  const orderId = order?.id;

  useEffect(() => {
    if (orderId) {
      const pusherChannel = pusher.subscribe(PusherConfig.Order.CHANNEL(orderId));
      pusherChannel.bind(PusherConfig.Order.Events.ORDER_UPDATED, refetch);

      return () => {
        pusher.unsubscribe(PusherConfig.Order.CHANNEL(orderId));
        pusherChannel.unbind(PusherConfig.Order.Events.ORDER_UPDATED, refetch);
      };
    }
  }, [pusher, refetch, orderId]);

  return { order, loading: loading && ![4, 6].includes(networkStatus) };
}
