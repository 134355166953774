import React, { memo } from 'react';
import styled from 'styled-components';

import { ComingSoonChip, DiscountChip, StaffPickChip } from 'components/chips';
import useProductPrices from 'hooks/use-product-prices';
import useProductQueryParams from 'hooks/use-product-query-params';
import { formatDiscountForDisplay } from 'shared/helpers/specials';
import { formatCurrency } from 'shared/helpers/utils';
import { Typography } from 'src/typography';
import useDispensary from 'dispensary/hooks/use-dispensary';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';
import { BadgesContainer, ChipContainer, PriceSection } from './product-card.components';
import { ProductCardProduct } from './product-card.types';
import { CarouselTheme } from '../hybrid-carousel';

type PriceProps = {
  carouselTheme?: CarouselTheme;
  product: ProductCardProduct;
  isComingSoon?: boolean;
};

export const Price = memo(
  ({ carouselTheme, product, isComingSoon = false }: PriceProps): JSX.Element => {
    const { dispensary } = useDispensary();
    const hideDiscount = useHideDiscountFromConsumer(dispensary);
    const { queryParams } = useProductQueryParams();

    const { displayOption, standardPrice, specialPrice, discountPercentage } = useProductPrices(
      product,
      queryParams.weight
    );

    const displayPrice = typeof specialPrice === 'number' ? specialPrice : standardPrice;

    const showDiscount = !!discountPercentage && Number.isFinite(discountPercentage) && discountPercentage >= 0;
    let discountDisplay: number | string | null = null;

    if (showDiscount) {
      discountDisplay = formatDiscountForDisplay(
        standardPrice,
        specialPrice ?? undefined,
        product,
        `sale`,
        displayOption
      );
    }

    const showStaffPicksBadge = 'featured' in product && product.featured?.current;

    return (
      <PriceSection {...carouselTheme} data-testid='carousel-product-card-price'>
        <Typography.Label tag='span' size='xlarge' weight='bold'>
          {formatCurrency(displayPrice)}
        </Typography.Label>
        {discountDisplay && !hideDiscount && (
          <StruckOutPrice tag='span' size='xlarge' weight='bold'>
            {formatCurrency(standardPrice)}
          </StruckOutPrice>
        )}
        {(discountDisplay || showStaffPicksBadge || isComingSoon) && (
          <BadgesContainer>
            {discountDisplay && !hideDiscount && (
              <ChipContainer mr='5px'>
                <DiscountChip
                  label={
                    <Typography.Label tag='span' size='xsmall' weight='bold'>
                      {discountDisplay}
                    </Typography.Label>
                  }
                />
              </ChipContainer>
            )}
            {showStaffPicksBadge && (
              <ChipContainer mr='5px'>
                <StaffPickChip />
              </ChipContainer>
            )}
            {isComingSoon && (
              <ChipContainer>
                <ComingSoonChip />
              </ChipContainer>
            )}
          </BadgesContainer>
        )}
      </PriceSection>
    );
  }
);

const StruckOutPrice = styled(Typography.Label)`
  color: ${({ theme }) => theme.colors.grey[70]};
  text-decoration: line-through;
  padding-left: 9px;
`;
