import React from 'react';

function SvgPinIcon(props) {
  return (
    <svg viewBox='0 0 18 22' {...props}>
      <g fillRule='evenodd'>
        <path d='M8.992 20.445c1.856-2.542 3.66-5.015 5.465-7.49.113-.154.225-.31.328-.47.996-1.54 1.33-3.2.969-4.977-.643-3.156-3.896-5.636-7.098-5.383-2.73.215-4.749 1.507-5.913 3.872-1.142 2.323-.913 4.598.59 6.728 1.003 1.421 2.048 2.814 3.078 4.218.843 1.148 1.69 2.293 2.581 3.502M17.25 8.853c-.047 1.695-.577 3.219-1.569 4.593-1.843 2.552-3.707 5.09-5.567 7.632a3.2 3.2 0 01-.485.548c-.387.331-.88.331-1.269.005a2.876 2.876 0 01-.458-.501c-1.877-2.551-3.765-5.095-5.619-7.661C-1.19 8.662 1.53 2.206 7.512 1.019c4.052-.804 8.366 1.85 9.406 5.813.173.66.225 1.347.332 2.021' />
        <path d='M11.564 9.49c0-1.172-.978-2.165-2.153-2.183a2.2 2.2 0 00-2.225 2.208 2.194 2.194 0 002.2 2.178 2.197 2.197 0 002.178-2.203m1.186.004a3.355 3.355 0 01-3.368 3.381A3.352 3.352 0 016 9.511a3.361 3.361 0 013.365-3.386 3.368 3.368 0 013.385 3.369' />
      </g>
    </svg>
  );
}

export default SvgPinIcon;
