import useUI from 'hooks/use-ui';
import useCart from 'hooks/use-cart';

import { usePriceOptions } from './use-price-options';
import { UsePriceOptionsReturn } from './use-product-pricing.types';

export function useProductPricing(): UsePriceOptionsReturn {
  const { isKiosk } = useUI();
  const { menuType } = useCart();

  return {
    ...usePriceOptions({ menuType, isKiosk }),
  };
}
