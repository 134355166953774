import React from 'react';
import styled from 'styled-components';

import useTranslation from 'src/hooks/use-translation';
import {
  AddressAutocomplete,
  AddressAutocompleteClearButton,
  AddressAutocompleteGeolocateButton,
  AddressAutocompleteInput,
  AddressAutocompleteOptionsList,
  AddressAutocompletePopover,
} from 'src/components/address-autocomplete';
import PinIcon from 'assets/pin-icon';
import { AutoCompleteInputListProps } from '../location-settings-popover.types';

export function AutoCompleteInputList({ onChangeTempLocation }: AutoCompleteInputListProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <AddressAutocomplete onSelect={onChangeTempLocation}>
      <Wrapper>
        <StyledPinIcon aria-hidden />
        <StyledInput placeholder={t('locationSettings.enterNewAddress', 'Enter a new address or city...')} />
        <StyledClearButton />
      </Wrapper>
      <AddressAutocompletePopover>
        <StyledGeolocateButton />
        <AddressAutocompleteOptionsList />
      </AddressAutocompletePopover>
    </AddressAutocomplete>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: 40px;
`;

const StyledInput = styled(AddressAutocompleteInput)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  padding: 0 40px;
  font-size: 14px;
  border: ${({ theme }) => `1px solid ${theme.colors.blueGrey[80] as string}`};
  background: ${({ theme }) => theme.colors.primaryGrey};
`;

const StyledClearButton = styled(AddressAutocompleteClearButton)`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledPinIcon = styled(PinIcon)`
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  fill: ${({ theme }) => theme.colors.grey[70]};
  height: 17px;
`;

const StyledGeolocateButton = styled(AddressAutocompleteGeolocateButton)`
  margin: 22px 0 9px 0;
`;
