import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'src/typography';
import { Button, ModalContainer, ModalExitIcon } from 'src/components/core';
import { DutchiePayMobileQrIcon } from 'src/assets/dutchie-pay-mobile-qr-icon';
import { usePayByBankKioskOrderSuccessModal } from './use-pay-by-bank-kiosk-order-success-modal';

type KioskOrderSuccessModalProps = {
  onClose: () => void;
};

export const PayByBankKioskOrderSuccessModal = forwardRef(
  ({ onClose }: KioskOrderSuccessModalProps, ref): JSX.Element => {
    const { secondsLeft, handleDutchiePayOrderSuccessClose } = usePayByBankKioskOrderSuccessModal({ onClose });

    return (
      <ModalContainer ref={ref} width={850} padding={0}>
        <ModalExitIcon data-testid='modal-exit-icon' onClick={handleDutchiePayOrderSuccessClose} />
        <ModalBody>
          <Content>
            <DutchiePayMobileQrIcon />
            <TextContainer>
              <Heading tag='h1' size='medium'>
                Finalize payment on your phone
              </Heading>
              <MainText size='large'>
                Your order will be ready in a few moments, please complete payment on your phone.
              </MainText>
            </TextContainer>
            <ButtonContainer>
              <CloseButton data-testid='close-button' fluid onClick={handleDutchiePayOrderSuccessClose} size='large'>
                Close
              </CloseButton>
              <SubText size='small'>{`This window will automatically close in ${secondsLeft} seconds...`}</SubText>
            </ButtonContainer>
          </Content>
        </ModalBody>
      </ModalContainer>
    );
  }
);

const ModalBody = styled.div`
  ${({ theme: { spaces } }) => css`
    align-items: center;
    display: flex;
    height: 540px;
    justify-content: center;
    padding: ${spaces[9]} ${spaces[8]};
    width: 100%;
  `}
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[8]};
  justify-content: center;
  max-width: 390px;
  text-align: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]};
`;

const Heading = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
`;

const MainText = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.grey[30]};
  font-size: 16px;
  line-height: 24px;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]};
  width: 100%;
`;

const SubText = styled(Typography.Body)`
  color: #717f89;
  font-size: 13px;
  line-height: 18px;
  weight: 400;
`;

const CloseButton = styled(Button)`
  width: 80%;
`;
