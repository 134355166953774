export const SPACES_INTERVAL = 4;
export const SPACES_SCALE_DEGREES = 20;
export const SPACES_SCALE_UNIT = `px`;

type Scale = Record<number, number>;

/* Generate a config object of scale positions that increments equally
 based on the supplied interval

 eg. calling `spaces: buildScale(4,20)` will create
    spaces: {
      '0': '0px',
      '1': '4px',
      '2': '8px',
      '3': '12px',
      '4': '16px',
      '5': '20px',
      '6': '24px',
      '7': '28px',
      '8': '32px',
      '9': '36px',
      '10': '40px',
      '11': '44px',
      '12': '48px',
      '13': '52px',
      '14': '56px',
      '15': '60px',
      '16': '64px',
      '17': '68px',
      '18': '72px',
      '19': '76px',
      '20': '80px',
    },
*/

export function buildScale(
  interval = SPACES_INTERVAL,
  numScaleDegrees = SPACES_SCALE_DEGREES,
  scaleUnit = SPACES_SCALE_UNIT
): Scale {
  const scaleDegrees: number[] = [];
  for (let i = 0; i <= numScaleDegrees; i++) {
    scaleDegrees.push(i);
  }

  return scaleDegrees.reduce((next, index) => ({ ...next, [index]: `${index * interval}${scaleUnit}` }), {});
}
