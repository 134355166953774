import _ from 'lodash';
import { getFides } from './get-fides';
import type { ConsentManagementFlag } from '../types';

export function getDataSharingEnabledForUser(flag: ConsentManagementFlag | undefined): boolean {
  const fides = getFides();
  const dataSalesAndSharing = fides?.consent.data_sales_and_sharing;

  if (flag?.enabled && dataSalesAndSharing !== undefined) {
    return dataSalesAndSharing;
  }

  return true;
}

type GetPrivacyModalOffsetParams = {
  iframeHeight: number;
  isIframe: boolean;
  parentOffset: number;
  viewportHeight: number;
  viewportWidth: number;
};

export function getPrivacyModalOffset({
  iframeHeight,
  isIframe,
  parentOffset,
  viewportHeight,
  viewportWidth,
}: GetPrivacyModalOffsetParams): number {
  if (!isIframe) {
    return 0;
  }

  const lower = viewportHeight / 2;
  const upper = iframeHeight - lower;

  const desktopOffset = _.clamp(parentOffset + lower, lower, upper);
  const mobileOffset = _.clamp(parentOffset, 0, upper);

  const isMobile = viewportWidth <= 900;
  return isMobile ? mobileOffset : desktopOffset;
}
