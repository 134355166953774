export enum AdvancedConditionType {
  totalQuantity = 'totalQuantity',
  totalSpend = 'totalSpend',
  totalWeight = 'totalWeight',
}

export enum AdvancedRewardType {
  discountToCart = 'discountToCart',
  itemsForADiscount = 'itemsForADiscount',
  itemsForAPrice = 'itemsForAPrice',
}

export enum BasicConditionOrRewardType {
  activeBatchTag = 'inventoryTag',
  brand = 'brand',
  category = 'category',
  individual = 'individual',
  productTag = 'productTag',
  strain = 'strain',
  vendor = 'vendor',
}
